import * as Yup from 'yup';

const EventDetailsPartnerEditFormSchema = (intl: any) =>
  Yup.object().shape({
    partner: Yup.string()
      .when('hasPartnerAccessPermission', {
        is: true,
        then: Yup.string().test(
          'campaign-required',
          intl.formatMessage({
            id: 'admin.eventCreate.campaignRequired',
          }),
          function() {
            const partner = this.parent.partner;
            const campaign = this.parent.campaign;
            return !(partner && !campaign);
          }
        ),
      })
      .nullable(true),
    venueBudget: Yup.number().nullable(),
    partnerPromotionTitle: Yup.string()
      .nullable()
      .notRequired()
      .max(
        40,
        intl.formatMessage({
          id: 'admin.eventPartnerInfo.form.partnerPromotionTitleMaxCharacters',
        })
      ),
    partnerPromotionDescription: Yup.string()
      .nullable()
      .notRequired()
      .max(
        250,
        intl.formatMessage({
          id:
            'admin.eventPartnerInfo.form.partnerPromotionDescriptionMaxCharacters',
        })
      ),
    partnerPromotionButtonText: Yup.string()
      .nullable()
      .notRequired()
      .max(
        30,
        intl.formatMessage({
          id:
            'admin.eventPartnerInfo.form.partnerPromotionButtonTextMaxCharacters',
        })
      ),
  });

export default EventDetailsPartnerEditFormSchema;
