import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { LoadingError as BaseLoadingError } from 'app/shared/components/atoms/LoadingError';
import { Spinner } from 'app/shared/components/atoms/SpinnerManualCSS';

const LoadingIndicatorContainer = styled.div`
  text-align: center;
  padding: 40px 30px 60px 30px;
  width: 100%;
`;

export const LoadingIndicator: React.FC = () => {
  return (
    <LoadingIndicatorContainer>
      <Spinner />
    </LoadingIndicatorContainer>
  );
};

export const LoadingError: React.FC = () => {
  const intl = useIntl();

  return (
    <BaseLoadingError
      whatsBeingLoaded={intl.formatMessage({
        id: 'admin.guestlist.attendeeDetails.thisGuest',
      })}
      containerSize="small"
    />
  );
};
