import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

interface LoadingErrorContainerProps {
  size?: string;
}

const LoadingErrorContainer = styled.div<LoadingErrorContainerProps>`
  ${({ size }) => css`
    text-align: center;
    font-size: ${size == 'small' ? '14px' : '20px'};
    font-weight: 600;
    padding: ${size == 'small'
      ? '40px 40px 45px 30px'
      : '120px 50px 180px 50px'};
    white-space: normal;
    width: 100%;
  `};
`;

interface LoadingErrorProps {
  whatsBeingLoaded: string;
  containerSize?: 'small' | 'large';
}

export const LoadingError: React.FC<LoadingErrorProps> = ({
  whatsBeingLoaded,
  containerSize = 'large',
}) => {
  const intl = useIntl();

  return (
    <LoadingErrorContainer size={containerSize}>
      {intl.formatMessage(
        {
          id: 'shared.loadingError',
        },
        {
          whatsBeingLoaded,
        }
      )}
    </LoadingErrorContainer>
  );
};
