import React from 'react';
import { useIntl } from 'react-intl';

import { City } from 'app/typings/cities';
import { dateFormatter } from 'app/shared/utils/datetime';
import {
  capitalize,
  snakeCaseToDash,
  upperFirstLeaveRestUnchanged,
} from 'app/shared/utils/string';
import ListingCard from 'app/admin/components/organisms/ListingCard';
import { ReactComponent as MapsPin2 } from 'icons/streamline-regular/maps-navigation/maps/maps-pin-2.svg';

interface CuratorGroupCardProps {
  id: number;
  name: string;
  city: City;
  curatorGroupType: string;
  status: string;
  index: number;
  onShowDetails: Function;
  createdAt: string;
}

const CuratorGroupCard: React.FC<CuratorGroupCardProps> = ({
  id,
  name,
  curatorGroupType,
  status,
  onShowDetails,
  city,
  createdAt,
}) => {
  const intl = useIntl();

  const keysAndValuesList = [
    {
      key: intl.formatMessage({
        id: 'admin.shared.type',
      }),
      value: capitalize(snakeCaseToDash(curatorGroupType)),
    },
    {
      key: intl.formatMessage({
        id: 'admin.shared.createdAt',
      }),
      value: dateFormatter(createdAt, 'shortMonthDateAndYear'),
    },
    {
      key: intl.formatMessage({
        id: 'shared.status',
      }),
      value: upperFirstLeaveRestUnchanged(status),
    },
  ];

  const controlsInfoList = [
    {
      href: `https://www.google.com/maps/search/?api=1&query=${city.latitude},${city.longitude}`,
      icon: MapsPin2,
      tipText: intl.formatMessage({
        id: 'admin.shared.linkToMap',
      }),
    },
  ];

  const onShowDetailsParams = {
    id,
    name,
  };

  return (
    <ListingCard
      title={name}
      titleTruncateCharLength={17}
      subtitle={city?.title}
      keysAndValuesList={keysAndValuesList}
      controlsInfoList={controlsInfoList}
      onShowDetails={onShowDetails}
      onShowDetailsParams={onShowDetailsParams}
      showDetailsTipText={intl.formatMessage({
        id: 'admin.curatorGroups.details',
      })}
      height="200px"
      dataQaidPrefix="curator-groups"
    />
  );
};

export default CuratorGroupCard;
