import * as Yup from 'yup';

const geUniqueNonEmptyValues = (object: object) => [
  ...new Set(Object.values(object).filter(x => x)),
];

const ArtistFeedbackCreateFormSchema = (intl: any) =>
  Yup.object().shape({
    event: Yup.object()
      .shape({
        id: Yup.number(),
        eventType: Yup.string(),
      })
      .test(
        'Event',
        intl.formatMessage({
          id: 'form.requiredField',
        }),
        function(obj) {
          return !!(obj.eventType && obj.id);
        }
      ),
    feedbackPhrasesIds: Yup.object()
      .nullable()
      .test(
        'feedbackPhrasesIds',
        intl.formatMessage({
          id:
            'admin.artistDirectory.sectionFeedback.form.feedbackPhraseRequired',
        }),
        function() {
          const event = this.parent.event;
          const feedbackPhrasesIds = this.parent.feedbackPhrasesIds;
          return !!(
            event &&
            feedbackPhrasesIds &&
            geUniqueNonEmptyValues(feedbackPhrasesIds).length > 0
          );
        }
      ),
    wouldNotBookAgain: Yup.boolean(),
  });

export default ArtistFeedbackCreateFormSchema;
