import React, { useEffect, useState } from 'react';

import { isEmpty } from 'app/shared/utils/object';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import { Caption } from 'app/shared/components/atoms/TypographyManualCSS';

import ImageCropper from './ImageCropper';
import ImageUploader from './ImageUploader';

interface Props {
  numberOfImages: number;
  imagePreviewShape?: 'square' | 'circle';
  formatAndUploadSizeInfo?: string;
  onChange: (values: any) => void;
  images?: any;
  maxFileSizeInKB?: number;
  cropperRatio?: number;
  errorMsg?: string;
  hideCropButton?: boolean;
  setCropAction?: (func: Function) => Promise<Record<string, any>>;
  previewWithUploader?: boolean;
  setDisplaySubmitButton?: (set: boolean) => void;
  largeCropper?: boolean;
}

export const SUPPORTED_IMAGE_FORMATS = {
  'image/jpg': ['.jpg'],
  'image/jpeg': ['.jpeg'],
  'image/png': ['.png'],
};

const ImageUploaderWithCropper: React.FC<Props> = ({
  numberOfImages,
  imagePreviewShape = 'square',
  formatAndUploadSizeInfo = 'admin.imageUploaderForm.formatAndUploadSizeInfo',
  onChange,
  images,
  maxFileSizeInKB,
  cropperRatio = 1,
  errorMsg,
  hideCropButton = false,
  setCropAction,
  setDisplaySubmitButton,
  previewWithUploader = false,
  largeCropper = false,
}) => {
  const [activeDrag, setActiveDrag] = useState(false);
  const [activeImageUploadsIndex, setActiveImageUploadsIndex] = useState<
    number | undefined
  >();
  const [imageUploads, setImageUploads] = useState(images || {});
  const [activeComponent, setActiveComponent] = useState<string>('uploader');

  useEffect(() => {
    if (setDisplaySubmitButton) {
      setDisplaySubmitButton(false);
    }
  }, [setDisplaySubmitButton]);

  const onCropSubmit = (croppedImage: any) => {
    if (activeImageUploadsIndex || activeImageUploadsIndex === 0) {
      imageUploads[activeImageUploadsIndex] = croppedImage;
      const updatedImagesObject =
        numberOfImages === 1 ? imageUploads[0] : imageUploads;
      onChange(updatedImagesObject);
      setImageUploads(imageUploads);
      setActiveImageUploadsIndex(undefined);
    }
  };

  const handleImageChange = (value: any, index: number) => {
    imageUploads[index] = value;
    const updatedImagesObject =
      numberOfImages === 1 ? imageUploads[0] : imageUploads;
    onChange(updatedImagesObject);
    setImageUploads({ ...imageUploads });

    if (setDisplaySubmitButton) {
      setDisplaySubmitButton(true);
    }
  };

  return (
    <>
      <Grid>
        <Col xs={10}>
          {activeComponent === 'cropper' && (
            <ImageCropper
              largeCropper={largeCropper}
              cropperRatio={cropperRatio}
              previewShape={imagePreviewShape}
              image={
                activeImageUploadsIndex || activeImageUploadsIndex === 0
                  ? imageUploads[activeImageUploadsIndex]
                  : null
              }
              maxFileSizeInKB={maxFileSizeInKB}
              setImageUploads={handleImageChange}
              onCropSubmit={onCropSubmit}
              hideCropButton={hideCropButton}
              setCropAction={setCropAction}
              setActiveComponent={setActiveComponent}
              setDisplaySubmitButton={setDisplaySubmitButton}
            />
          )}
        </Col>
      </Grid>
      <Grid>
        <Col xs={10} sm={4} lg={3}>
          {activeComponent === 'uploader' &&
            [...Array(numberOfImages)].map((_, index) => {
              return (
                <ImageUploader
                  preview={
                    previewWithUploader && !isEmpty(imageUploads[index])
                      ? imageUploads[index].url
                      : null
                  }
                  previewShape={imagePreviewShape}
                  key={`image-upload-${index}`}
                  index={index}
                  activeDrag={activeDrag}
                  setActiveDrag={setActiveDrag}
                  setActiveComponent={setActiveComponent}
                  setImageUploads={handleImageChange}
                  setActiveImageUploadsIndex={() =>
                    setActiveImageUploadsIndex(index)
                  }
                  formatAndUploadSizeInfo={formatAndUploadSizeInfo}
                />
              );
            })}
        </Col>
      </Grid>
      {errorMsg && <Caption style={{ color: 'red' }}>{errorMsg}</Caption>}
    </>
  );
};

export default ImageUploaderWithCropper;
