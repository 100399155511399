import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { User } from 'app/typings/users';
import { get } from 'app/shared/utils/get';
import Typeahead from 'app/shared/components/molecules/Typeahead';
import { userFullNameWithEmail } from 'app/admin/utils/users';
import { GetUsersForUserTypeahead } from 'app/admin/graphql/users/queryHooks';

interface Props {
  setSelectedUser: (user: User | undefined) => void;
}

const UserTypeahead: React.FC<Props> = ({ setSelectedUser }) => {
  const intl = useIntl();

  const [typeaheadSearchString, setTypeaheadSearchString] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const {
    loading: loadingUsers,
    error: errorUsers,
    data: dataUsers,
  } = GetUsersForUserTypeahead({
    userSearch: typeaheadSearchString,
    page: 1,
    perPage: 50,
  });

  const users = get(dataUsers, 'users.users', []);
  const options = users.map((user: User) => {
    return { id: user.id, title: userFullNameWithEmail(user) };
  });

  const onSearch = (searchString: string) => {
    if (searchString.trim() == '') {
      setSelectedUser(undefined);
      setTypeaheadSearchString('');
      setIsOpen(false);
    } else if (searchString !== typeaheadSearchString) {
      setTypeaheadSearchString(searchString);
      setIsOpen(true);
    }
  };

  if (errorUsers) {
    return null;
  }

  const getSelectedUser = (option: any) =>
    users.find((u: User) => u.id == option.id);

  return (
    <>
      <Typeahead
        name="userTypeahead"
        getOptionLabel={(option: any) => {
          return option.title;
        }}
        realTimeSearch={true}
        options={options}
        value={typeaheadSearchString}
        placeholder={intl.formatMessage({
          id: 'form.fields.placeholder.pleaseTypeUserNameOrEmail',
        })}
        isLoading={loadingUsers}
        // @ts-ignore
        handleOptionClick={(value: string | number, option: any) => {
          setSelectedUser(getSelectedUser(option));
          setTypeaheadSearchString(option.title);
          setIsOpen(false);
        }}
        onSearch={onSearch}
        isTypeaheadOpen={isOpen}
      />
    </>
  );
};

export default UserTypeahead;
