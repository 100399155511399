import React from 'react';
import styled, { css } from 'styled-components';

import { withShadow } from 'app/shared/components/styleUtils/manualCSS/withShadow';
import { CloseIcon } from 'app/shared/components/atoms/IconLibrary';
import ThemeWrapper from 'app/shared/components/atoms/ThemeWrapper';
import { Body2 } from 'app/shared/components/atoms/TypographyManualCSS';

interface SnackbarProps {
  children: any;
  onCancel: () => void;
  id?: string;
  'data-qaid'?: string;
}

const SnackbarStyled = styled.div`
  ${({ theme }) => css`
    border-radius: 8px;
    background-color: ${theme.colors.backToBlack};
    display: flex;
    justify-content: space-between;
    padding: ${theme.ruler[4]}px ${theme.ruler[6]}px;

    ${withShadow({ depth: 3 })};
  `}
`;

export const CancelButton = styled.button`
  cursor: pointer;
  border: none;
  background: transparent;
  outline: none;
  box-shadow: none;
  padding: 0;
  margin: 0;
  pointer-events: auto;
`;

export const Snackbar: React.FC<SnackbarProps> = ({
  children,
  onCancel,
  id,
  'data-qaid': qaId = '',
}) => (
  <ThemeWrapper themeName="adminManualCSS">
    <SnackbarStyled id={id} data-qaid={qaId}>
      <Body2 color="#fff" data-qaid={`${qaId}-text`}>
        {children}
      </Body2>

      <CancelButton onClick={onCancel} data-qaid={`${qaId}-cancel`}>
        <CloseIcon iconColor="white" />
      </CancelButton>
    </SnackbarStyled>
  </ThemeWrapper>
);
