import React from 'react';
import styled from 'styled-components';

import { useCurrentTheme } from 'app/shared/theme';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { TagElement } from 'app/admin/components/molecules/TagElementList';

interface TagsWrapperProps {
  extraPaddingRight?: string;
}

const TagsWrapper = styled.div<TagsWrapperProps>`
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 0px ${props => props.extraPaddingRight || '0px'} 0px 0px;
`;

/*
  When displaying Confirm Off and Apply Off tags together, they don't look good on screen sizes
  between 1200px and 1300px because the width of the summary card is a bit smaller. That's why
  we're reducing the font size of the tag by 1px on those screen sizes. It doesn't look 100%
  great, but it's a quick improvement. Mark approved it
*/
const StyledTag = styled(TagElement)`
  text-align: center;
  padding: 1px 5px;
  margin-top: -21px;
  margin-right: -2px;
  height: 18px;
  border-radius: 0px 0px 2px 2px;

  @media screen and (min-width: 1200px) and (max-width: 1300px) {
    font-size: 9px;
  }
`;

export interface ListingCardTopTag {
  tagElementColor: string;
  name: string;
}

interface ListingCardTopTagsProps {
  tags: ListingCardTopTag[];
  isDisabled?: boolean;
  extraPaddingRight?: string;
}

export const ListingCardTopTags: React.FC<ListingCardTopTagsProps> = ({
  tags,
  isDisabled,
  extraPaddingRight,
}) => {
  const theme = useCurrentTheme();

  if (tags.length) {
    return (
      <TagsWrapper extraPaddingRight={extraPaddingRight}>
        {tags.map((tagElement: any, i: number) => (
          <React.Fragment key={i}>
            <StyledTag
              key={i}
              tagElementColor={
                isDisabled ? theme.colors.blueSmoke : tagElement.tagElementColor
              }
              textColor="#fff"
              data-qaid={`tag-element-${i}`}
            >
              {tagElement.name}
            </StyledTag>
            <Spacer mr={1} />
          </React.Fragment>
        ))}
      </TagsWrapper>
    );
  }
  return null;
};
