import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { errorMsgForField } from 'app/shared/utils/form';
import {
  FormGroupContainer,
  FormSectionTitle,
} from 'app/shared/components/atoms/FormContent';
import GenericFormContainer from 'app/shared/components/atoms/GenericFormContainer';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import FormGroup from 'app/shared/components/molecules/FormGroup';
import RadioGroup from 'app/shared/components/molecules/RadioGroup';
import { SEATINGS } from 'app/admin/utils/events';
import { getSeatingOptions } from 'app/admin/utils/optionLists';
import RichTextEditor from 'app/admin/components/molecules/RichTextEditor';

interface FormProps {
  formikProps: any;
  setFormSubmitAction?: (func: Function) => void;
  setDisplayConfirmation?: (set: boolean) => void;
  setIsSubmitting?: (set: boolean) => void;
}

const VenueFanExperienceEditForm: React.FC<FormProps> = ({
  formikProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
}) => {
  const intl = useIntl();

  useEffect(() => {
    setFormSubmitAction && setFormSubmitAction(() => formikProps.submitForm);
    setDisplayConfirmation && setDisplayConfirmation(formikProps.dirty);
    setIsSubmitting && setIsSubmitting(formikProps.isSubmitting);
  }, [
    formikProps.isSubmitting,
    formikProps.submitForm,
    formikProps.dirty,
    setFormSubmitAction,
    setDisplayConfirmation,
    setIsSubmitting,
  ]);

  const { setFieldValue, touched, errors, values } = formikProps;

  return (
    <GenericFormContainer dataQaId="venue-fan-experience-section-edit-form">
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.venueFanExperience.form.title',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="venue-fan-experience-form-external-venue-notes-formgroup"
          label={intl.formatMessage({
            id: 'admin.shared.fanFacingDescription',
          })}
          errorMsg={errorMsgForField('externalVenueNotes', touched, errors)}
        >
          <RichTextEditor
            name="externalVenueNotes"
            currentValue={values.externalVenueNotes}
            onChange={value => {
              setFieldValue('externalVenueNotes', value);
            }}
          />
        </FormGroup>
        <Spacer mb={8} />
        <FormGroup
          data-qaid="venue-fan-experience-form-invite-email-message-formgroup"
          label={intl.formatMessage({
            id: 'admin.shared.inviteEmail',
          })}
          errorMsg={errorMsgForField('inviteEmailMessage', touched, errors)}
        >
          <RichTextEditor
            name="inviteEmailMessage"
            currentValue={values.inviteEmailMessage}
            onChange={value => {
              setFieldValue('inviteEmailMessage', value);
            }}
          />
        </FormGroup>
        <Spacer mb={8} />
        <FormGroup
          data-qaid="venue-fan-experience-form-reveal-address-email-message-formgroup"
          label={intl.formatMessage({
            id: 'admin.shared.addressRevealEmail',
          })}
          errorMsg={errorMsgForField(
            'revealAddressEmailMessage',
            touched,
            errors
          )}
        >
          <RichTextEditor
            name="revealAddressEmailMessage"
            currentValue={values.revealAddressEmailMessage}
            onChange={value => {
              setFieldValue('revealAddressEmailMessage', value);
            }}
          />
        </FormGroup>
        <Spacer mb={8} />
        <FormGroup
          data-qaid="venue-fan-experience-form-seating-formgroup"
          label={intl.formatMessage({
            id: 'admin.shared.seating',
          })}
          errorMsg={errorMsgForField('seating', touched, errors)}
        >
          <RadioGroup
            name="seating"
            options={getSeatingOptions(SEATINGS)}
            selectedValue={values.seating}
            onChange={value => setFieldValue('seating', value)}
          />
        </FormGroup>
        <Spacer mb={4} />
      </FormGroupContainer>
    </GenericFormContainer>
  );
};

export default VenueFanExperienceEditForm;
