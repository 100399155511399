import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { CityDetailData } from 'app/typings/cities';
import { useCurrentTheme } from 'app/shared/theme';
import { objectFilteredByBooleanConditionOnKeys } from 'app/shared/utils/object';
import { dataGetter } from 'app/shared/utils/queries';
import useModal from 'app/shared/utils/useModal';
import usePermission from 'app/shared/utils/usePermission';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import { StreamlineIcon } from 'app/shared/components/atoms/StreamlineIcon';
import ConfirmationModal from 'app/shared/components/molecules/ConfirmationModal';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { GetCityBasicInfo } from 'app/admin/graphql/cities/queryHooks';
import { DeleteNeighborhood } from 'app/admin/graphql/neighborhoods/mutationHooks';
import { DetailsMainContainer } from 'app/admin/components/atoms/DetailContent';
import AccordionSections from 'app/admin/components/organisms/AccordionSections';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';
import { ReactComponent as InformationCircle } from 'icons/streamline-regular/interface-essential/alerts/information-circle.svg';
import { ReactComponent as NavigationMenu } from 'icons/streamline-regular/interface-essential/menu/navigation-menu-2.svg';
import { ReactComponent as CogCircle } from 'icons/streamline-regular/interface-essential/settings/cog-circle.svg';
import { ReactComponent as MapsPin2 } from 'icons/streamline-regular/maps-navigation/maps/maps-pin-2.svg';
import { ReactComponent as MessagesBubbleSquareSmile } from 'icons/streamline-regular/messages-chat-smileys/messages-speech-bubbles/messages-bubble-square-smile.svg';
import { ReactComponent as BrowserPageAccount } from 'icons/streamline-regular/programing-apps-websites/website-development/browser-page-account.svg';

import SectionBasicInfo from './SectionBasicInfo';
import SectionCollections from './SectionCollections';
import SectionEmails from './SectionEmails';
import SectionNeighborhoods from './SectionNeighborhoods';
import SectionSettings from './SectionSettings';

interface Props {
  navigateTo: (routeData: any) => void;
  contentProps: CityDetailData;
  hide: VoidFunction;
}

export const StyledIcon = styled.span`
  margin-right: 7px;
`;

const CityDetails: React.FC<Props> = ({ navigateTo, contentProps, hide }) => {
  const {
    id,
    title,
    cachedSlug,
    createdAt,
    artistsCount,
    venuesCount,
    pastEventsCount,
  } = contentProps;

  const intl = useIntl();
  const theme = useCurrentTheme();

  const hasAccessBasicByCityPermission = usePermission(
    'city.list.accessBasicByCity'
  );
  const hasAccessNeighborhoodPermission = usePermission('neighborhood.access');
  const hasFullEditCityPermission = usePermission('city.full.edit');

  const [headerData, setHeaderData] = useState({
    title,
    imageUrl: '',
    cachedSlug,
    createdAt,
    artistsCount,
    venuesCount,
    pastEventsCount,
    isLoaded: !!title,
  });

  const [neighborhoodDeleteModal, toggleNeighborhoodDeleteModal] = useModal();
  const [neighborhoodData, setNeighborhoodData] = useState<any>(undefined);

  const {
    loading: loadingBasicInfo,
    error: errorBasicInfo,
    data: dataBasicInfo,
    refetch: refetchCity,
  } = GetCityBasicInfo({
    id,
    fetchPolicy: 'no-cache',
  });

  const sectionsConfig = objectFilteredByBooleanConditionOnKeys(
    {
      basicInfo: {
        title: intl.formatMessage({
          id: 'admin.cities.details.sectionTitle.basicInfo',
        }),
        icon: InformationCircle,
        iconColor: 'blueChristmas',
        sectionComponent: SectionBasicInfo,
        dataKey: 'city',
        displayEditIcon: true,
        loading: loadingBasicInfo,
        data: dataBasicInfo ? dataBasicInfo.city : undefined,
        callbacks: {
          toggleSectionEditModal: () =>
            navigateTo({
              routeName: 'city-basic-info-edit',
              routeProps: dataBasicInfo ? dataBasicInfo.city : undefined,
            }),
          toggleCityHeaderImageModal: () =>
            navigateTo({
              routeName: 'city-header-image-uploader',
              routeProps: dataBasicInfo ? dataBasicInfo.city : undefined,
            }),
          toggleCityPromoHeaderImageModal: () =>
            navigateTo({
              routeName: 'city-promo-header-image-uploader',
              routeProps: dataBasicInfo ? dataBasicInfo.city : undefined,
            }),
        },
      },
      neighborhoods: {
        title: intl.formatMessage({
          id: 'shared.neighborhoods',
        }),
        icon: MapsPin2,
        iconColor: 'greenOnions',
        iconCircle: true,
        sectionComponent: SectionNeighborhoods,
        dataKey: 'city',
        displayEditIcon: false,
        loading: loadingBasicInfo,
        data: dataBasicInfo ? dataBasicInfo.city : undefined,
        callbacks: {
          toggleNeighborhoodCreateModal: () =>
            navigateTo({
              routeName: 'neighborhood-create',
              routeProps: {
                city: {
                  id,
                },
              },
            }),
          toggleNeighborhoodEditModal: (neighborhoodData: any) =>
            navigateTo({
              routeName: 'neighborhood-basic-info-edit',
              routeProps: {
                city: {
                  id,
                },
                id: neighborhoodData.id,
                title: neighborhoodData.title,
              },
            }),
          toggleNeighborhoodDeleteAndSetNeighborhoodData: (
            neighborhoodData: any
          ) => {
            setNeighborhoodData(neighborhoodData);
            toggleNeighborhoodDeleteModal();
          },
          toggleNeighborhoodImageEditAndSetNeighborhoodData: () => {},
        },
      },
      emails: {
        title: intl.formatMessage({
          id: 'admin.shared.emails',
        }),
        icon: MessagesBubbleSquareSmile,
        iconColor: 'redRedWine',
        iconCircle: true,
        sectionComponent: SectionEmails,
        dataKey: 'city',
        displayEditIcon: true,
        loading: loadingBasicInfo,
        data: dataBasicInfo ? dataBasicInfo.city : undefined,
        callbacks: {
          toggleSectionEditModal: () =>
            navigateTo({
              routeName: 'city-emails-edit',
              routeProps: dataBasicInfo ? dataBasicInfo.city : undefined,
            }),
        },
      },
      collections: {
        title: intl.formatMessage({
          id: 'admin.cities.details.sectionTitle.collections',
        }),
        icon: NavigationMenu,
        iconColor: 'blueMagic',
        iconCircle: true,
        sectionComponent: SectionCollections,
        dataKey: 'city',
        displayEditIcon: false,
        loading: loadingBasicInfo,
        data: dataBasicInfo ? dataBasicInfo.city : undefined,
        callbacks: {},
      },
      settings: {
        title: intl.formatMessage({
          id: 'admin.cities.details.sectionTitle.settings',
        }),
        icon: CogCircle,
        iconColor: 'orangeCrush',
        sectionComponent: SectionSettings,
        dataKey: 'city',
        displayEditIcon: hasFullEditCityPermission,
        loading: loadingBasicInfo,
        data: dataBasicInfo ? dataBasicInfo.city : undefined,
        callbacks: {
          toggleSectionEditModal: () =>
            navigateTo({
              routeName: 'city-settings-edit',
              routeProps: dataBasicInfo ? dataBasicInfo.city : undefined,
            }),
        },
      },
    },
    {
      ['basicInfo']: !hasAccessBasicByCityPermission,
      ['emails']: !hasAccessBasicByCityPermission,
      ['settings']: !hasAccessBasicByCityPermission,
      ['neighborhoods']: hasAccessNeighborhoodPermission,
    }
  );

  const subheaderText = (intl: any) => (
    <span>
      <StyledIcon>
        <StreamlineIcon
          icon={BrowserPageAccount}
          size={17}
          stroke={theme.colors.green600}
        />
      </StyledIcon>
      {intl.formatMessage({
        id: 'admin.cities.header.publicFacingCityPage',
      })}
    </span>
  );

  const detailsHeaderActionLinksInfo = (intl: any) => {
    return {
      link1: {
        text: subheaderText(intl),
        active: true,
        onClickAction: () =>
          window.open(`/cities/${headerData.cachedSlug}`, '_blank'),
      },
    };
  };

  useEffect(() => {
    const getDataBasicInfo = dataGetter(dataBasicInfo, 'city');
    if (dataBasicInfo) {
      setHeaderData({
        title: getDataBasicInfo('title'),
        imageUrl: getDataBasicInfo('imageUrl'),
        cachedSlug: getDataBasicInfo('cachedSlug'),
        createdAt: getDataBasicInfo('createdAt'),
        artistsCount: getDataBasicInfo('artistsCount'),
        venuesCount: getDataBasicInfo('venuesCount'),
        pastEventsCount: getDataBasicInfo('pastEventsCount'),
        isLoaded: true,
      });
    }
  }, [dataBasicInfo, loadingBasicInfo]);

  const deleteNeighborhoodAction = DeleteNeighborhood();

  const handleDeleteNeighborhood = useSubmitAction({
    submitAction: deleteNeighborhoodAction,
    submitVariables: () => ({
      neighborhoodId: neighborhoodData && neighborhoodData.id,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.cityNeighborhoods.deleteSuccess',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.cityNeighborhoods.deleteFailure',
    }),
    onSuccess: () => {
      neighborhoodDeleteModal.hide();
      refetchCity();
    },
  });

  if ((!loadingBasicInfo && !dataBasicInfo) || errorBasicInfo) {
    hide();
    return null;
  }

  const detailsHeaderSummaryInfoList = (
    artistsCount: number | undefined,
    venuesCount: number | undefined,
    pastEventsCount: number | undefined
  ) => {
    const artistsCountDescription = intl.formatMessage({
      id: 'shared.artists',
    });

    const venuesCountDescription = intl.formatMessage({
      id: 'shared.venues',
    });

    const pastEventsCountDescription = intl.formatMessage({
      id: 'admin.cities.details.pastEventsCount',
    });

    return [
      {
        backgroundColor: '#ebedef',
        mainInfo: artistsCount && artistsCount.toString(),
        description: artistsCountDescription,
      },
      {
        backgroundColor: '#ebf5fb',
        mainInfo: venuesCount && venuesCount.toString(),
        description: venuesCountDescription,
      },
      {
        backgroundColor: '#f3f9ed',
        mainInfo: pastEventsCount && pastEventsCount.toString(),
        description: pastEventsCountDescription,
      },
    ];
  };

  const summaryInfoList = detailsHeaderSummaryInfoList(
    headerData.artistsCount,
    headerData.venuesCount,
    headerData.pastEventsCount
  );

  return (
    <ModalContentContainer data-qaid="city-details-main-modal">
      <DetailsMainContainer>
        <DetailsHeader
          title={headerData.title}
          imageUrl={headerData.imageUrl}
          largeImageUrl={headerData.imageUrl}
          summaryInfoList={summaryInfoList}
          actionLinksInfo={detailsHeaderActionLinksInfo(intl)}
          loading={!headerData.isLoaded}
          imageStyle="square"
          displayEditIcon={false}
        />
        <AccordionSections
          sectionsConfig={sectionsConfig}
          defaultOpenSection={contentProps.defaultOpenSection}
        />
      </DetailsMainContainer>
      {neighborhoodDeleteModal.isShowing && (
        <ConfirmationModal
          onCancel={() => neighborhoodDeleteModal.hide()}
          description={intl.formatMessage(
            {
              id: 'admin.neighborhoodBasicInfo.deleteConfirmation',
            },
            {
              title: neighborhoodData && neighborhoodData.title,
            }
          )}
          onConfirm={handleDeleteNeighborhood}
        />
      )}
    </ModalContentContainer>
  );
};

export default CityDetails;
