import React from 'react';
import Imgix from 'react-imgix';
import styled from 'styled-components';

import 'react';
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';

interface Props {
  src: string;
  sizes: string;
  aspectRatio?: string;
  dataQaid?: string;
  crop?: string;
  onErrorHandler?: (x: any) => void;
}

const Image = styled(Imgix)`
  width: 100%;
  height: 100%;
`;

function cleanSource(source: string) {
  if (source.indexOf('://') === -1) {
    // treat URLs starting with '//' as CDN URLs
    if (source.indexOf('//') === 0) {
      return `https:${source}`;
      // treat URLs starting with '/' as relative paths
    } else if (source.indexOf('/') === 0) {
      const protocol =
        window.location.hostname === 'local.sofarsounds.com'
          ? 'http://'
          : 'https://';
      return `${protocol}${window.location.hostname}${source}`;
    }
  }
  return source;
}

const ImgixImage: React.FC<Props> = ({
  src,
  sizes,
  aspectRatio = '1:1',
  dataQaid = 'lazy-load-image',
  crop = 'top',
  onErrorHandler = () => {},
}) => {
  return (
    <Image
      className="lazyload"
      src={cleanSource(src)}
      sizes={sizes}
      attributeConfig={{
        src: 'data-src',
        srcSet: 'data-srcset',
        sizes: 'data-sizes',
      }}
      htmlAttributes={{
        src: `${src}?blur=200&w=8&h=8&fit=crop&ar=1:1&auto=format`,
        'data-qaid': dataQaid,
        onError: onErrorHandler,
      }}
      disablePathEncoding={true}
      imgixParams={{ fit: 'crop', crop, ar: aspectRatio }}
    />
  );
};

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

// Catch Imgix errors before they break the page.
class LazyImgixErrorBoundary extends React.PureComponent<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  public static getDerivedStateFromError() {
    return { hasError: true };
  }

  public render() {
    if (this.state.hasError) {
      return null;
    }

    return this.props.children;
  }
}

const LazyImgixImage: React.FC<Props> = props => (
  <LazyImgixErrorBoundary>
    <ImgixImage {...props} />
  </LazyImgixErrorBoundary>
);

export default LazyImgixImage;
