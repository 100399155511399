export const extractYoutubeEmbedVideoId = (url = '') => {
  const embedMatch = url.match(/embed\/([0-9a-z_-]{1,20})/i);
  const shortsMatch = url.match(/shorts\/([0-9a-z_-]{1,20})/i);
  const youtubeMatch = url.match(/watch\?v=([0-9a-z_-]{1,20})/i);

  return embedMatch
    ? embedMatch['1']
    : youtubeMatch
    ? youtubeMatch[1]
    : shortsMatch
    ? shortsMatch[1]
    : '';
};
