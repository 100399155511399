import React, { useEffect, useRef, useState } from 'react';
import Cropper from 'react-cropper';
import { FormattedHTMLMessage, useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { makeOpaque } from 'app/shared/utils/colors';
import { isEmpty } from 'app/shared/utils/object';
import useModal from 'app/shared/utils/useModal';
import { PrimaryButton } from 'app/shared/components/atoms/Button';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import { DeleteIcon } from 'app/shared/components/atoms/IconLibrary';
import { Icon } from 'app/shared/components/atoms/IconManualCSS';
import { Spacer, Spacer24 } from 'app/shared/components/atoms/Spacer';
import { H6 } from 'app/shared/components/atoms/TypographyManualCSS';
import ConfirmationModal from 'app/shared/components/molecules/ConfirmationModal';
import IconAndTextLink from 'app/shared/components/molecules/IconAndTextLink';
import { ReactComponent as Rotate } from 'icons/streamline-regular/design/rotate/rotate.svg';

import 'cropperjs/dist/cropper.css';

interface Image {
  name: string;
  url: string;
  contentType: string;
}

interface Props {
  image: Image;
  previewShape: string;
  onCropSubmit: (croppedImage: any) => void;
  cropperRatio?: number;
  setImageUploads: (value: any, index: number) => void;
  maxFileSizeInKB?: number;
  hideCropButton?: boolean;
  setCropAction?: (func: Function) => Promise<Record<string, any>>;
  setActiveComponent?: (value: string) => void;
  setDisplaySubmitButton?: (set: boolean) => void;
  largeCropper?: boolean;
}

const Border = styled.div<{ largeCropper?: boolean }>`
  ${({ theme, largeCropper }) => css`
    ${largeCropper &&
      css`
        ${theme.media.xs`
            margin-top: -65px;
            max-width: 300px;
        `};

        ${theme.media.lg`
            margin-top: -90px;
        `};
      `};

    background-color: ${theme.colors.silverSprings};
    border-radius: 5px;
    display: block;
    padding: 30px;
    min-width: 285px;
  `}
`;

const CropperContainer = styled.div<{ largeCropper?: boolean }>`
  ${({ theme, largeCropper }) => css`
    display: flex;
    flex-direction: column;
    min-width: 225px;
    border-radius: 5px;

    ${largeCropper &&
      css`
        ${theme.media.xs`
          height: 150px;
          width: 100px;
        `};

        ${theme.media.lg`
          height: 400px;
          width: 600px;
        `};
      `}
  `}
`;

const PreviewWrapper = styled.div<{ largeCropper?: boolean }>`
  ${({ largeCropper }) => css`
    ${largeCropper &&
      css`
        margin-top: -50px;
      `}

    width: 400px;
    height: 200px;
    padding: 30px;
  `}
`;

const EditHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const IconWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    color: ${theme.colors.green500};
    cursor: pointer;
    &:hover {
      color: ${theme.colors.primary};
    }
  `}
`;

const DeleteText = styled.div`
  font-size: 14px;
  margin-top: 1px;
  margin-left: 8px;
`;

const ErrorMessage = styled.div`
  ${({ theme }) => css`
    background-color: ${makeOpaque(theme.colors.redRedWine, 0.13)};
    min-width: 285px;
    padding: 10px 0px 1px 0px;
    margin-top: 10px;
  `}
`;

const SpanSpacer = styled.span`
  padding: 5px;
`;

const PreviewImage = styled.div<{ previewShape: string }>`
  ${({ theme, previewShape }) => css`
    background-position: center;
    background-size: cover;
    border: 1px solid ${theme.colors.green600};
    border-radius: ${previewShape === 'circle' ? '50%' : '10px'};
    height: 100%;
    overflow: hidden;
  `}
`;

const StyledGrid = styled(Grid)<{ largeCropper?: boolean }>`
  ${({ largeCropper }) => css`
    ${largeCropper &&
      css`
        grid: none;
      `};
  `}
`;

const ImageCropper: React.FC<Props> = ({
  image,
  onCropSubmit,
  setImageUploads,
  cropperRatio,
  maxFileSizeInKB = 25000,
  previewShape,
  hideCropButton = false,
  setCropAction,
  setActiveComponent,
  setDisplaySubmitButton,
  largeCropper = false,
}) => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<
    { id: string; value: any }[]
  >([]);
  const cropperRef = useRef<HTMLImageElement>(null);
  const [previewConfirmationModal, togglePreviewConfirmationModal] = useModal();

  const formData = (file: any) => {
    const data = new FormData();
    data.append('file', file);
    return data;
  };

  useEffect(() => {
    setErrorMessages([]);
  }, [image]);

  const request = async (file: any, cacheUrl: any) =>
    new Promise(resolve => {
      const xhr = new XMLHttpRequest();

      xhr.open('POST', cacheUrl, true);

      xhr.onload = () => {
        resolve(JSON.parse(xhr.response));
      };
      xhr.onerror = () => {
        resolve(undefined);
        // eslint-disable-next-line
        console.error('** An error occurred during the XMLHttpRequest');
      };
      xhr.send(formData(file));
    });

  const dataURLtoFile = ({
    dataUrl,
    filename,
  }: {
    dataUrl?: string;
    filename: string;
  }) => {
    if (!dataUrl || !dataUrl.includes(',')) {
      return null;
    }

    const arr = dataUrl.split(',');
    const mime = image.contentType;

    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  const handleCropSubmit = async () => {
    const imageElement: any = cropperRef.current;
    const cropper: any = imageElement.cropper;

    if (imageElement) {
      setIsLoading(true);
      const testImage =
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVQYV2NgYAAAAAMAAWgmWQ0AAAAASUVORK5CYII=';

      // Mocking cropper.js and using canvas on jsdom/jest is not a simple task, so this is an approach
      // to avoid having to do all that and just use a test image
      const croppedImage =
        process.env.BYPASS_CANVAS === 'true'
          ? testImage
          : cropper.getCroppedCanvas()?.toDataURL(image.contentType) || '';

      const file = dataURLtoFile({
        dataUrl: croppedImage,
        filename: image.name,
      });
      if (!file) {
        return;
      }

      if (file.size > maxFileSizeInKB * 1024) {
        const existingMessages = [...errorMessages];
        existingMessages.push({
          id: 'imageCropper.maxSizeErrorMessage',
          value: {
            maxFileSize: maxFileSizeInKB / 1000,
          },
        });
        setErrorMessages(existingMessages);
        setIsLoading(false);
        return;
      }

      setErrorMessages([]);
      const response: any = await request(file, '/attachments/cache');
      setIsLoading(false);

      // used when we club Crop & Save button together
      if (setCropAction) {
        return {
          url: croppedImage,
          id: response.id,
          filename: image.name,
          size: file.size,
          contentType: file.type,
        };
      }

      // used when there is a separate Crop button
      onCropSubmit({
        url: croppedImage,
        id: response.id,
        filename: image.name,
        size: file.size,
        contentType: file.type,
      });

      if (setActiveComponent) {
        setActiveComponent('uploader');
      }
    }
    return;
  };

  useEffect(() => {
    if (setCropAction) {
      setCropAction(() => handleCropSubmit);
    }
  }, [setCropAction]);

  if (isEmpty(image)) {
    return null;
  }

  return (
    <>
      <Grid>
        <Col sm={6} md={6}>
          {!!errorMessages.length &&
            errorMessages.map((errorMessage, index) => (
              <ErrorMessage key={index}>
                <H6>
                  <SpanSpacer />
                  <Icon name="alertCircle" size="18px" color="redRedWine" />
                  <SpanSpacer />
                  <FormattedHTMLMessage
                    id={errorMessage.id}
                    values={errorMessage.value}
                  />
                </H6>
                <Spacer mt={4} />
              </ErrorMessage>
            ))}
        </Col>
      </Grid>
      <Spacer mb={4} />
      <StyledGrid largeCropper={largeCropper}>
        <Col sm={8} md={6}>
          <Border largeCropper={largeCropper}>
            <EditHeader>
              <H6 spaceAfter={3}>
                {intl.formatMessage({
                  id: 'imageCropper.dragToReposition',
                })}
              </H6>
              {!largeCropper && (
                <IconWrapper
                  onClick={togglePreviewConfirmationModal}
                  data-qaid="image-cropper-remove"
                >
                  <DeleteIcon iconSize={14} />
                  <DeleteText>
                    {largeCropper
                      ? intl.formatMessage({
                          id: 'shared.deleteThisImage',
                        })
                      : intl.formatMessage({
                          id: 'shared.cancel',
                        })}
                  </DeleteText>
                </IconWrapper>
              )}
            </EditHeader>
            {previewConfirmationModal.isShowing && (
              <ConfirmationModal
                onCancel={() => previewConfirmationModal.hide()}
                description={intl.formatMessage({
                  id: 'imageCropper.deleteConfirmationDescription',
                })}
                onConfirm={() => {
                  setImageUploads({}, 0);
                  previewConfirmationModal.hide();
                  if (setActiveComponent) {
                    setActiveComponent('uploader');
                  }
                  if (setDisplaySubmitButton) {
                    setDisplaySubmitButton(false);
                  }
                }}
                confirmationButtonText={intl.formatMessage({
                  id: 'shared.delete',
                })}
                cancellationButtonText={intl.formatMessage({
                  id: 'shared.cancel',
                })}
              />
            )}
            <CropperContainer
              data-qaid="image-cropper-container"
              largeCropper={largeCropper}
            >
              <Cropper
                ref={cropperRef}
                src={image.url}
                style={{ height: '100%', width: '100%' }}
                aspectRatio={cropperRatio || 1}
                guides={true}
                preview=".image-cropper-preview"
                background={false}
                viewMode={1}
                zoomOnWheel={false}
                autoCropArea={3}
              />
            </CropperContainer>
            <IconAndTextLink
              icon={Rotate}
              text={intl.formatMessage({
                id: 'imageCropper.rotate',
              })}
              onClick={() => (cropperRef?.current as any)?.cropper.rotate(90)}
              dataQaid="image-cropper-rotate"
            />
          </Border>
        </Col>
        <Col sm={4} md={6}>
          <PreviewWrapper largeCropper={largeCropper}>
            <H6 spaceAfter={3}>
              {largeCropper
                ? intl.formatMessage({
                    id: 'imageCropper.previewImage',
                  })
                : intl
                    .formatMessage({
                      id: 'imageCropper.previewImage',
                    })
                    .toUpperCase()}
            </H6>
            <PreviewImage
              previewShape={previewShape}
              data-qaid="image-cropper-preview"
              className="image-cropper-preview"
            />
            {largeCropper && (
              <>
                <Spacer24 />
                <IconWrapper
                  onClick={togglePreviewConfirmationModal}
                  data-qaid="image-cropper-remove"
                >
                  <DeleteIcon iconSize={14} />
                  <DeleteText>
                    {largeCropper
                      ? intl.formatMessage({
                          id: 'shared.deleteThisImage',
                        })
                      : intl.formatMessage({
                          id: 'shared.delete',
                        })}
                  </DeleteText>
                </IconWrapper>
              </>
            )}
          </PreviewWrapper>
        </Col>
      </StyledGrid>
      <Spacer mb={4} />
      {!hideCropButton && (
        <Grid>
          <Col xs={12} sm={6}>
            <PrimaryButton
              type="button"
              data-qaid="image-cropper-crop-button"
              onClick={handleCropSubmit}
              loading={isLoading}
            >
              {intl.formatMessage({
                id: 'imageCropper.cropImage',
              })}
            </PrimaryButton>
          </Col>
        </Grid>
      )}
    </>
  );
};

export default ImageCropper;
