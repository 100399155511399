import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { City } from 'app/typings/cities';
import { errorMsgForField } from 'app/shared/utils/form';
import { get } from 'app/shared/utils/get';
import { objectForObjectId } from 'app/shared/utils/object';
import { citySocialUrls } from 'app/shared/utils/socialLinks';
import usePermission from 'app/shared/utils/usePermission';
import { GetCountries as getCountries } from 'app/shared/graphql/countries/queryHooks';
import {
  FormGroupContainer,
  FormSectionTitle,
} from 'app/shared/components/atoms/FormContent';
import GenericFormContainer from 'app/shared/components/atoms/GenericFormContainer';
import { LoadingError } from 'app/shared/components/atoms/LoadingError';
import { Radio } from 'app/shared/components/atoms/Radio';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Textfield } from 'app/shared/components/atoms/Textfield';
import { Body2 } from 'app/shared/components/atoms/TypographyManualCSS';
import FormGroup from 'app/shared/components/molecules/FormGroup';
import RadioGroup from 'app/shared/components/molecules/RadioGroup';
import Select from 'app/shared/components/molecules/SelectManualCSS';
import { optionsWithNoneOption } from 'app/admin/utils/optionLists';
import { GetBusinessOwners } from 'app/admin/graphql/businessOwners/queryHooks';
import EditFormLoadingBlocks from 'app/admin/components/atoms/EditFormLoadingBlocks';
import { CityTypeahead } from 'app/admin/components/molecules/CityTypeahead';
import { RichTextEditorFormField } from 'app/admin/components/molecules/RichTextEditorFormField';
import SocialLinkFormFields from 'app/admin/components/molecules/SocialLinkFormFields';
import TitleByLocaleFields from 'app/admin/components/molecules/TitleByLocaleFields';

import { NearbySecondaryCities } from './NearbySecondaryCities';
import {
  cityOperatingModelOptions,
  sofarOperatedMarketTypeOptions,
} from './options';

interface FormProps {
  formikProps: any;
  validationErrors?: object;
  isNewCity?: boolean;
}

const StyledTextfield = styled(Textfield)`
  ${({ theme }) => css`
    display: block;
    width: 100%;
    ${theme.media.lg`
      width: 48%;
    `};
  `}
`;

const LatLongTextfield = styled(Textfield)`
  display: block;
  width: 150px;
`;

const CityBasicInfoForm: React.FC<FormProps> = ({
  formikProps,
  validationErrors = {},
  isNewCity = false,
}) => {
  const intl = useIntl();

  const hasFullEditCityPermission = usePermission('city.full.edit');

  const {
    loading: loadingCountries,
    error: errorCountries,
    data: dataCountries,
  } = getCountries({
    skipPagination: true,
  });

  const {
    loading: loadingBusinessOwners,
    error: errorBusinessOwners,
    data: dataBusinessOwners,
  } = GetBusinessOwners({
    archivedStatus: 'not_archived',
    skipPagination: true,
  });

  if (errorCountries || errorBusinessOwners) {
    return <LoadingError whatsBeingLoaded="this form" />;
  }

  if (
    loadingCountries ||
    !dataCountries ||
    loadingBusinessOwners ||
    !dataBusinessOwners
  ) {
    return <EditFormLoadingBlocks />;
  }

  const businessOwnerOptions = optionsWithNoneOption(
    get(dataBusinessOwners, 'businessOwners.businessOwners', [])
  );

  const { setFieldValue, touched, errors, values } = formikProps;

  const isSecondaryCityAlreadySelected = (proposedSecondaryCity: City) =>
    values.nearbySecondaryCities
      .map((existingSecondaryCity: City) => existingSecondaryCity.id)
      .includes(proposedSecondaryCity.id);

  const addSecondaryCity = (city: City | undefined) => {
    if (city && !isSecondaryCityAlreadySelected(city)) {
      setFieldValue('nearbySecondaryCities', [
        ...values.nearbySecondaryCities,
        city,
      ]);
    }
  };

  const removeSecondaryCity = (city: City | undefined) => {
    if (city && isSecondaryCityAlreadySelected(city)) {
      setFieldValue(
        'nearbySecondaryCities',
        values.nearbySecondaryCities.filter((c: City) => c.id !== city.id)
      );
    }
  };

  const countryForCountryId = (countryId: number | null) =>
    objectForObjectId(get(dataCountries, 'countries.countries', []), countryId);

  const businessOwnerForBusinessOwnerId = (businessOwnerId: number | null) =>
    objectForObjectId(
      get(dataBusinessOwners, 'businessOwners.businessOwners', []),
      businessOwnerId
    );

  const optionForField = (options: any, type: string | null): any | undefined =>
    options
      ? options.find((option: any) => option && option.value === type)
      : undefined;

  return (
    <GenericFormContainer dataQaId="city-about-section-edit-form">
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'shared.title',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <TitleByLocaleFields
          setFieldValue={setFieldValue}
          touched={touched}
          errors={errors}
          values={values}
          validationErrors={validationErrors}
          dataQaidPrefix="city-basic-info-form-title"
        />
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.cityBasicInfo.form.country',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          label={intl.formatMessage({
            id: 'admin.cityBasicInfo.form.country',
          })}
          data-qaid="city-basic-info-form-country-formgroup"
          errorMsg={errorMsgForField('countryId', touched, errors)}
          required
        >
          <Select
            data-qaid="city-basic-info-form-country-field"
            searchable
            getOptionLabel={(opt: any) => opt.title}
            defaultValue={countryForCountryId(values.countryId)}
            options={get(dataCountries, 'countries.countries', [])}
            placeholder={intl.formatMessage({
              id: 'admin.cityBasicInfo.form.countryPlaceholder',
            })}
            onChange={country =>
              setFieldValue(
                'countryId',
                country && country.id ? country.id : null
              )
            }
          />
        </FormGroup>
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.cityBasicInfo.form.latitudeAndLongitude',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="city-basic-info-form-latitude-formgroup"
          label={intl.formatMessage({
            id: 'admin.cityBasicInfo.form.latitude',
          })}
          errorMsg={errorMsgForField('latitude', touched, errors)}
          required
        >
          <LatLongTextfield
            data-qaid="city-basic-info-form-latitude-field"
            id="latitude"
            name="latitude"
            value={values.latitude}
            onChange={(e: any) => {
              setFieldValue('latitude', e.target.value);
            }}
            maxLength={12}
          />
        </FormGroup>
        <Spacer mb={6} />
        <FormGroup
          data-qaid="city-basic-info-form-longitude-formgroup"
          label={intl.formatMessage({
            id: 'admin.cityBasicInfo.form.longitude',
          })}
          errorMsg={errorMsgForField('longitude', touched, errors)}
          required
        >
          <LatLongTextfield
            data-qaid="city-basic-info-form-longitude-field"
            id="longitude"
            name="longitude"
            value={values.longitude}
            onChange={(e: any) => {
              setFieldValue('longitude', e.target.value);
            }}
            maxLength={12}
          />
        </FormGroup>
      </FormGroupContainer>
      {hasFullEditCityPermission && (
        <>
          <FormSectionTitle>
            {intl.formatMessage({
              id: 'admin.cities.details.cityOperatingModel',
            })}
          </FormSectionTitle>
          <FormGroupContainer>
            <FormGroup
              data-qaid="city-basic-info-form-city-operating-model-formgroup"
              label={intl.formatMessage({
                id: 'admin.cities.details.cityOperatingModel',
              })}
              errorMsg={errorMsgForField('cityOperatingModel', touched, errors)}
              required
            >
              <Select
                data-qaid="city-basic-info-form-city-operating-model-field"
                name="cityOperatingModel"
                id="cityOperatingModel"
                getOptionLabel={(opt: any) => opt.label}
                defaultValue={optionForField(
                  cityOperatingModelOptions.options,
                  values.cityOperatingModel
                )}
                options={cityOperatingModelOptions.options}
                placeholder={intl.formatMessage({
                  id: 'admin.cityBasicInfo.form.cityOperatingModelPlaceholder',
                })}
                onChange={option =>
                  setFieldValue(
                    'cityOperatingModel',
                    option && option.value ? option.value : null
                  )
                }
                initialWidth="200px"
              />
            </FormGroup>
          </FormGroupContainer>
        </>
      )}
      {hasFullEditCityPermission &&
        ['sofar_operated', 'sofar_operated_and_curator'].includes(
          values.cityOperatingModel
        ) && (
          <>
            <FormSectionTitle>
              {intl.formatMessage({
                id: 'admin.cities.listingCard.marketType',
              })}
            </FormSectionTitle>
            <FormGroupContainer>
              <FormGroup
                data-qaid="city-basic-info-form-sofar-market-type-formgroup"
                label={intl.formatMessage({
                  id: 'admin.cities.listingCard.marketType',
                })}
                errorMsg={errorMsgForField(
                  'sofarOperatedMarketType',
                  touched,
                  errors
                )}
              >
                <Select
                  data-qaid="city-basic-info-form-market-type-field"
                  name="sofarOperatedMarketType"
                  id="sofarOperatedMarketType"
                  getOptionLabel={(opt: any) => opt.label}
                  defaultValue={optionForField(
                    sofarOperatedMarketTypeOptions,
                    values.sofarOperatedMarketType
                  )}
                  options={sofarOperatedMarketTypeOptions}
                  placeholder={intl.formatMessage({
                    id:
                      'admin.cityBasicInfo.form.sofarOperatedMarketTypePlaceholder',
                  })}
                  onChange={option =>
                    setFieldValue(
                      'sofarOperatedMarketType',
                      option && option.value ? option.value : null
                    )
                  }
                  initialWidth="200px"
                />
              </FormGroup>
            </FormGroupContainer>
          </>
        )}
      {hasFullEditCityPermission &&
        ['sofar_operated', 'sofar_operated_and_curator'].includes(
          values.cityOperatingModel
        ) && (
          <>
            <FormSectionTitle>
              {intl.formatMessage({
                id: 'shared.businessOwner',
              })}
            </FormSectionTitle>
            <FormGroupContainer>
              <FormGroup
                label={intl.formatMessage({
                  id: 'shared.businessOwner',
                })}
                data-qaid="city-basic-info-form-business-owner-formgroup"
                errorMsg={errorMsgForField('businessOwnerId', touched, errors)}
              >
                <Select
                  data-qaid="city-basic-info-form-business-owner-field"
                  searchable
                  getOptionLabel={(opt: any) => opt.title}
                  defaultValue={businessOwnerForBusinessOwnerId(
                    values.businessOwnerId
                  )}
                  options={businessOwnerOptions}
                  placeholder={intl.formatMessage({
                    id: 'admin.cityBasicInfo.form.businessOwnerPlaceholder',
                  })}
                  onChange={businessOwner =>
                    setFieldValue(
                      'businessOwnerId',
                      businessOwner && businessOwner.id
                        ? businessOwner.id
                        : null
                    )
                  }
                />
              </FormGroup>
            </FormGroupContainer>
          </>
        )}
      {hasFullEditCityPermission && !isNewCity && (
        <>
          <FormSectionTitle>
            {intl.formatMessage({
              id: 'admin.cityBasicInfo.form.cityStatus',
            })}
          </FormSectionTitle>
          <FormGroupContainer>
            <FormGroup
              data-qaid="city-basic-info-form-city-status-formgroup"
              label=""
            >
              <>
                <Radio
                  id="cityStatusActive"
                  value="active"
                  name="cityStatusActive"
                  checked={values.cityStatus == 'active'}
                  onChange={() => {
                    setFieldValue('cityStatus', 'active');
                  }}
                >
                  {intl.formatMessage({
                    id: 'shared.active',
                  })}
                </Radio>
                <Spacer mt={4} />
                <Radio
                  id="cityStatusInactive"
                  value="inactive"
                  name="cityStatusInactive"
                  checked={values.cityStatus == 'inactive'}
                  onChange={() => {
                    setFieldValue('cityStatus', 'inactive');
                  }}
                >
                  {intl.formatMessage({
                    id: 'admin.cityBasicInfo.form.cityStatusInactive',
                  })}
                </Radio>
              </>
            </FormGroup>
          </FormGroupContainer>
        </>
      )}
      {hasFullEditCityPermission && !isNewCity && (
        <>
          <FormSectionTitle>
            {intl.formatMessage({
              id: 'admin.cityBasicInfo.form.isAcceptingArtistRequests',
            })}
          </FormSectionTitle>
          <FormGroupContainer>
            <RadioGroup
              name="isAcceptingArtistRequests"
              options={[
                {
                  title: intl.formatMessage({
                    id: 'yes',
                  }),
                  value: true,
                },
                {
                  title: intl.formatMessage({
                    id: 'no',
                  }),
                  value: false,
                  selected: true,
                },
              ]}
              selectedValue={values.isAcceptingArtistRequests}
              spaceAboveGroup={false}
              onChange={value =>
                setFieldValue('isAcceptingArtistRequests', value)
              }
            />
          </FormGroupContainer>
        </>
      )}
      {hasFullEditCityPermission && !isNewCity && (
        <>
          <FormSectionTitle>
            {intl.formatMessage({
              id: 'admin.cityBasicInfo.form.cachedSlug',
            })}
          </FormSectionTitle>
          <FormGroupContainer>
            <FormGroup
              data-qaid="city-basic-info-form-cached-slug-formgroup"
              label={intl.formatMessage({
                id: 'admin.cityBasicInfo.form.cachedSlugDescription',
              })}
              errorMsg={
                errorMsgForField('cachedSlug', touched, errors) ||
                // @ts-ignore
                validationErrors.cached_slug
              }
              required
            >
              <StyledTextfield
                data-qaid="city-basic-info-form-cached-slug-field"
                id="cachedSlug"
                name="cachedSlug"
                value={values.cachedSlug}
                onChange={(e: any) => {
                  setFieldValue('cachedSlug', e.target.value);
                }}
                maxLength={100}
              />
            </FormGroup>
          </FormGroupContainer>
        </>
      )}
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.cityBasicInfo.form.aliases',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="city-basic-info-form-aliases-formgroup"
          label={intl.formatMessage({
            id: 'admin.cityBasicInfo.form.aliasesDescription',
          })}
          errorMsg={errorMsgForField('aliases', touched, errors)}
        >
          <StyledTextfield
            data-qaid="city-basic-info-form-aliases-field"
            id="aliases"
            name="aliases"
            value={values.aliases}
            onChange={(e: any) => {
              setFieldValue('aliases', e.target.value);
            }}
            maxLength={100}
          />
        </FormGroup>
      </FormGroupContainer>
      {hasFullEditCityPermission && !isNewCity && (
        <>
          <RichTextEditorFormField
            fieldName="description"
            fieldMaxCharacters={300}
            titleIntlId="admin.cityBasicInfo.form.description"
            descriptionIntlId="admin.cityBasicInfo.form.descriptiveTextDescription"
            labelIntlId="admin.cityBasicInfo.form.descriptionLabel"
            dataQaidPrefix="city-basic-info-form-description"
            {...formikProps}
          />
          <RichTextEditorFormField
            fieldName="whatToExpect"
            fieldMaxCharacters={500}
            titleIntlId="admin.cityBasicInfo.form.whatToExpect"
            descriptionIntlId="admin.cityBasicInfo.form.whatToExpectDescription"
            labelIntlId="admin.cityBasicInfo.form.whatToExpectLabel"
            dataQaidPrefix="city-basic-info-form-whatToExpect"
            {...formikProps}
          />
        </>
      )}
      {hasFullEditCityPermission && !isNewCity && (
        <>
          <FormSectionTitle>
            {intl.formatMessage({
              id: 'admin.cityBasicInfo.form.nearbySecondaryCities',
            })}
          </FormSectionTitle>
          <FormGroupContainer>
            <Body2>
              {intl.formatMessage(
                {
                  id: 'admin.cityBasicInfo.form.secondaryCitiesDescription',
                },
                { city: values.titleEn }
              )}
            </Body2>
            <Spacer mb={6} />
            <FormGroup
              data-qaid="city-basic-info-form-secondary-cities-formgroup"
              label={intl.formatMessage({
                id: 'admin.cityBasicInfo.form.secondaryCitiesInputLabel',
              })}
              errorMsg={errorMsgForField(
                'nearbySecondaryCities',
                touched,
                errors
              )}
            >
              <CityTypeahead
                data-qaid="city-basic-info-form-secondary-cities-city-selector"
                setSelectedCity={(city: City | undefined) => {
                  addSecondaryCity(city);
                }}
                clearFieldOnSelect={true}
                placeholder={intl.formatMessage({
                  id: 'admin.cityBasicInfo.form.secondaryCitiesPlaceholder',
                })}
              />
            </FormGroup>
            {values.nearbySecondaryCities?.length > 0 ? (
              <NearbySecondaryCities
                cities={values.nearbySecondaryCities}
                removeSecondaryCity={removeSecondaryCity}
              />
            ) : null}
          </FormGroupContainer>
        </>
      )}
      <SocialLinkFormFields
        setFieldValue={setFieldValue}
        touched={touched}
        errors={errors}
        values={values}
        socialLinkKeys={citySocialUrls}
        dataQaidPrefix="city-basic-info-form"
      />
    </GenericFormContainer>
  );
};

export default CityBasicInfoForm;
