import React from 'react';
import { useIntl } from 'react-intl';

import { get } from 'app/shared/utils/get';
import usePermission from 'app/shared/utils/usePermission';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { UpdateEvent } from 'app/admin/graphql/events/mutationHooks';
import { DetailsFormMainContainer } from 'app/admin/components/atoms/DetailContent';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

import EventDetailsPartnerEditForm from './EventDetailsPartnerEditForm';
import EventDetailsPartnerEditFormSchema from './EventDetailsPartnerEditFormSchema';

interface Props {
  contentProps?: any;
  setFormSubmitAction: (func: Function) => void;
  setDisplayConfirmation: (set: boolean) => void;
  setIsSubmitting: (set: boolean) => void;
  navigateTo: (routeData: object) => void;
}

const EventDetailsPartnerEdit: React.FC<Props> = ({
  contentProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  navigateTo,
}) => {
  const intl = useIntl();

  const hasPartnerAccessPermission = usePermission('partner.access');

  const localStartsAt = get(contentProps, 'localStartsAt', '');
  const cityTitle = get(contentProps, 'city.title', '');
  const neighborhoodTitle = get(contentProps, 'neighborhood.title', '');
  const eventId = contentProps.id;
  const venueBudget = get(contentProps, 'venueBudget', undefined);

  const formInitialValues = {
    currencySymbol: get(contentProps, 'currencySymbol', '$'),
    competition: get(contentProps, 'competition', undefined),
    competitionsToUnassociate: [],
    campaign: get(contentProps, 'campaign', undefined),
    hasPartnerAccessPermission,
    partner: get(contentProps, 'campaign.partner', undefined),
    sponsorNotes: get(contentProps, 'sponsorNotes', undefined),
    sponsorPostShowSummary: get(
      contentProps,
      'sponsorPostShowSummary',
      undefined
    ),
    venueBudget: venueBudget && venueBudget / 100.0,
    venueBudgetDescription: get(
      contentProps,
      'venueBudgetDescription',
      undefined
    ),
    partnerPromotionTitle: get(
      contentProps,
      'partnerPromotionTitle',
      undefined
    ),
    partnerPromotionDescription: get(
      contentProps,
      'partnerPromotionDescription',
      undefined
    ),
    partnerPromotionButtonText: get(
      contentProps,
      'partnerPromotionButtonText',
      undefined
    ),
    partnerPromotionButtonUrl: get(
      contentProps,
      'partnerPromotionButtonUrl',
      undefined
    ),
    partnerPromotionSectionEnabled: contentProps.partnerPromotionSectionEnabled,
  };

  const updateEventAction = UpdateEvent();
  const handleUpdateEvent = useSubmitAction({
    submitAction: updateEventAction,
    submitVariables: (values: any) => {
      return {
        eventId,
        venueBudget: values.venueBudget
          ? Number(values.venueBudget) * 100
          : null,
        venueBudgetDescription: values.venueBudgetDescription,
        sponsorNotes: values.sponsorNotes,
        sponsorPostShowSummary: values.sponsorPostShowSummary,
        partnerId: values.partner && values.partner.id,
        campaignId: values.campaign && values.campaign.id,
        partnerPromotionTitle: values.partnerPromotionTitle,
        partnerPromotionDescription: values.partnerPromotionDescription,
        partnerPromotionButtonText: values.partnerPromotionButtonText,
        partnerPromotionButtonUrl: values.partnerPromotionButtonUrl,
        partnerPromotionSectionEnabled: values.partnerPromotionSectionEnabled,
      };
    },
    successMsg: intl.formatMessage({
      id: 'admin.eventBasicInfo.successMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.eventBasicInfo.failureMessage',
    }),
    onSuccess: (response: any) => {
      navigateTo({
        routeName: 'event-details',
        routeProps: {
          defaultOpenSection: 'partnerInfo',
          ...response.data.updateEvent.event,
        },
      });
    },
  });

  return (
    <ModalContentContainer>
      <DetailsFormMainContainer>
        <DetailsHeader
          title={cityTitle}
          subTitle={neighborhoodTitle}
          loading={!(!!cityTitle || !!localStartsAt)}
          imageStyle="calendar"
          detailsDate={localStartsAt}
        />
        <GenericForm
          formInitialValues={formInitialValues}
          renderFormComponent={(renderProps: any) => (
            <EventDetailsPartnerEditForm
              formikProps={renderProps.formikProps}
            />
          )}
          onSubmit={handleUpdateEvent}
          formSchema={EventDetailsPartnerEditFormSchema(intl)}
          setFormSubmitAction={setFormSubmitAction}
          setDisplayConfirmation={setDisplayConfirmation}
          setIsSubmitting={setIsSubmitting}
          dataQaId="event-details-partner-edit-form"
        />
      </DetailsFormMainContainer>
    </ModalContentContainer>
  );
};

export default EventDetailsPartnerEdit;
