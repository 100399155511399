import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { User } from 'app/typings/users';
import { GetUserStaffedEventsUpcoming } from 'app/admin/graphql/users/queryHooks';

import StaffedEvents from './StaffedEvents';

interface Props {
  sectionData: User;
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SectionEventsUpcoming: React.FC<Props> = ({ sectionData }) => {
  const intl = useIntl();

  return (
    <MainContainer data-qaid="section-events-upcoming">
      <StaffedEvents
        userId={sectionData.id}
        queryDataHook={GetUserStaffedEventsUpcoming}
        eventFieldKey="upcomingStaffedEvents"
        noEventsMessage={intl.formatMessage({
          id: 'admin.userBasicInfo.noUpcomingConcerts',
        })}
        scope="upcoming"
      />
    </MainContainer>
  );
};

export default SectionEventsUpcoming;
