import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { ArtistDetailData } from 'app/typings/artists';
import { ArtistSetup } from 'app/typings/artistSetups';
import { dateFormatter } from 'app/shared/utils/datetime';
import usePermission from 'app/shared/utils/usePermission';
import Divider from 'app/shared/components/atoms/DividerManualCSS';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import { DeleteIcon, EditIcon } from 'app/shared/components/atoms/IconLibrary';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { getArtistSetupName } from 'app/admin/utils/artists';
import AddIconAndText from 'app/admin/components/atoms/AddIconAndText';
import {
  DetailHeading as Heading,
  DetailValueContainer as ValueContainer,
} from 'app/admin/components/atoms/DetailContent';
import ExpandableText from 'app/admin/components/atoms/ExpandableText';
import { None } from 'app/admin/components/atoms/None';

interface Props {
  sectionData: ArtistDetailData;
  callbacks: { [name: string]: Function };
}

export const Subheader = styled.div`
  ${({ theme }) => css`

  color: ${theme.colors.blueSmoke}
  font-size: 14px;
  margin-bottom: 10px;
  font-style: italic;`}
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const InlineHeading = styled(Heading)`
  display: inline;
  margin-right: 5px;
`;

const DeleteIconContainer = styled.div`
  margin-top: 15px;
  margin-bottom: 10px;
`;

const IconLink = styled.span`
  cursor: pointer;
`;

const GreyDivider = styled(Divider)`
  ${({ theme }) => css`
    background-color: ${theme.colors.macyGrey};
    margin-bottom: 0;
    margin-top: 0;
  `}
`;

const AudioSupportLabel = styled.span`
  text-transform: capitalize;
`;

const AddIconAndTextContainer = styled.div`
  margin-top: 10px;
`;

const ArtistSetupOption: React.FC<any> = ({
  artistSetup,
  dataQaidPrefix,
  callbacks,
  hasBasicAccessArtistsPermission,
}) => {
  const intl = useIntl();

  if (!artistSetup) {
    return null;
  }

  const {
    numPerformers,
    audioSupport,
    additionalSupportNotes,
    instrumentNotes,
    lastUpdatedAt,
  } = artistSetup;

  return (
    <>
      <Col xs={12} sm={12} md={3}>
        <InlineHeading data-qaid={`artist-${dataQaidPrefix}-name`}>
          {getArtistSetupName(artistSetup)}
        </InlineHeading>

        {hasBasicAccessArtistsPermission && (
          <IconLink
            onClick={() => {
              callbacks.toggleArtistSetupEditModal(artistSetup);
            }}
          >
            <EditIcon />
          </IconLink>
        )}

        {lastUpdatedAt && (
          <Subheader>
            {intl.formatMessage({
              id: 'admin.artistDirectory.sectionSetupOptions.updated',
            })}{' '}
            {dateFormatter(lastUpdatedAt, 'shortMonthDateAndYear')}
          </Subheader>
        )}

        {hasBasicAccessArtistsPermission && (
          <DeleteIconContainer>
            <IconLink
              onClick={() => {
                callbacks.toggleDeleteArtistSetupandSetArtistSetupData({
                  id: artistSetup.id,
                  setupName: artistSetup.setupName,
                });
              }}
            >
              <DeleteIcon />
            </IconLink>
          </DeleteIconContainer>
        )}
      </Col>
      <Col xs={12} sm={12} md={3}>
        <Heading mb="2px" data-qaid={`artist-${dataQaidPrefix}-num-performers`}>
          {intl.formatMessage({
            id: 'admin.artistDirectory.sectionSetupOptions.numPerformers',
          })}
        </Heading>
        <ValueContainer>{numPerformers}</ValueContainer>
        <Heading>
          {intl.formatMessage({
            id: 'admin.artistDirectory.sectionSetupOptions.audioSupport',
          })}
        </Heading>
        <ValueContainer data-qaid={`artist-${dataQaidPrefix}-audio-support`}>
          <AudioSupportLabel>
            {audioSupport.replace('_', ' ')}
          </AudioSupportLabel>
        </ValueContainer>
        <Spacer mb={3} />
      </Col>
      <Col xs={12} sm={12} md={3}>
        <Heading
          mb="2px"
          data-qaid={`artist-${dataQaidPrefix}-additional-support-notes`}
        >
          {intl.formatMessage({
            id:
              'admin.artistDirectory.sectionSetupOptions.additionalSupportNotes',
          })}
        </Heading>
        <ValueContainer>
          {additionalSupportNotes ? (
            <ExpandableText
              allowHTML
              text={additionalSupportNotes}
              truncateLength={50}
            />
          ) : (
            <None />
          )}
        </ValueContainer>
      </Col>
      <Col xs={12} sm={12} md={3}>
        <Heading
          mb="2px"
          data-qaid={`artist-${dataQaidPrefix}-instrument-notes`}
        >
          {intl.formatMessage({
            id: 'admin.artistDirectory.sectionSetupOptions.instrumentNotes',
          })}
        </Heading>
        <ValueContainer>
          {instrumentNotes ? (
            <ExpandableText
              allowHTML
              text={instrumentNotes}
              truncateLength={50}
            />
          ) : (
            <None />
          )}
        </ValueContainer>
      </Col>
      <Col xs={12}>
        <GreyDivider />
      </Col>
    </>
  );
};

const SectionSetupOptions: React.FC<Props> = ({ sectionData, callbacks }) => {
  const intl = useIntl();
  const hasBasicAccessArtistsPermission = usePermission(
    'artist.list.accessBasic'
  );

  const artistSetups = sectionData && sectionData.artistSetups;

  return (
    <MainContainer data-qaid="section-setup-options">
      <Grid>
        {artistSetups &&
          artistSetups.length > 0 &&
          artistSetups.map((artistSetup: ArtistSetup, index: number) => {
            return (
              <ArtistSetupOption
                key={index + 1}
                artistSetup={artistSetup}
                dataQaidPrefix={`setup-${index + 1}`}
                callbacks={callbacks}
                hasBasicAccessArtistsPermission={
                  hasBasicAccessArtistsPermission
                }
              />
            );
          })}

        {artistSetups && artistSetups.length == 0 && (
          <>
            <Col xs={12} md={3}>
              <ValueContainer data-qaid="no-setups">
                {intl.formatMessage({
                  id: 'admin.artistDirectory.sectionSetupOptions.noSetup',
                })}
              </ValueContainer>
            </Col>
            <Col xs={12}>
              <GreyDivider />
            </Col>
          </>
        )}
      </Grid>
      {hasBasicAccessArtistsPermission && (
        <AddIconAndTextContainer>
          <AddIconAndText
            text={intl.formatMessage({
              id: 'admin.artistDirectory.sectionSetupOptions.addSetup',
            })}
            textSizeType="large"
            onClick={() => callbacks.toggleArtistSetupCreateModal()}
            dataQaid="artist-details-button"
          />
        </AddIconAndTextContainer>
      )}
    </MainContainer>
  );
};

export default SectionSetupOptions;
