import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { Country } from 'app/typings';
import { errorMsgForField } from 'app/shared/utils/form';
import { GetCountries } from 'app/shared/graphql/countries/queryHooks';
import {
  FormGroupContainer,
  FormSectionTitle,
} from 'app/shared/components/atoms/FormContent';
import GenericFormContainer from 'app/shared/components/atoms/GenericFormContainer';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import { LoadingError } from 'app/shared/components/atoms/LoadingError';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Textfield } from 'app/shared/components/atoms/Textfield';
import FormGroup from 'app/shared/components/molecules/FormGroup';
import Select from 'app/shared/components/molecules/SelectManualCSS';
import EditFormLoadingBlocks from 'app/admin/components/atoms/EditFormLoadingBlocks';

interface FormProps {
  formikProps: any;
  numContactInfos?: number;
  validationErrors?: object;
}

const StyledTextfield = styled(Textfield)`
  ${({ theme }) => css`
    display: block;
    width: 100%;
    ${theme.media.lg`
        width: 48%;
      `};
  `}
`;

const SlackUserIdTextfield = styled(Textfield)`
  ${({ theme }) => css`
    display: block;
    width: 100%;
    ${theme.media.lg`
        width: 30%;
      `};
  `}
`;

const VerticalCenteredText = styled.div`
  font-size: 14px;
  font-style: italic;
`;

const CrewUserBasicInfoForm: React.FC<FormProps> = ({
  formikProps,
  validationErrors = {},
}) => {
  const intl = useIntl();

  const {
    loading: loadingCountries,
    error: errorCountries,
    data: dataCountries,
  } = GetCountries({
    skipPagination: true,
  });

  if (errorCountries) {
    return <LoadingError whatsBeingLoaded="this form" />;
  }

  if (loadingCountries || !dataCountries) {
    return <EditFormLoadingBlocks />;
  }

  const { setFieldValue, touched, errors, values } = formikProps;

  const resolveCountryByCountryCode = (
    countryData: Country[],
    countryCode: string
  ): Country | undefined =>
    countryData.find(country => country && country.countryCode === countryCode);

  return (
    <GenericFormContainer dataQaId="crew-user-about-section-edit-form">
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.crewDirectory.heading.name',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="crew-user-basic-info-form-first-name-formgroup"
          label={intl.formatMessage({
            id: 'admin.crewDirectory.crewFirstName',
          })}
          errorMsg={errorMsgForField('firstName', touched, errors)}
        >
          <StyledTextfield
            data-qaid="crew-user-basic-info-form-first-name-field"
            id="firstName"
            name="firstName"
            value={values.firstName}
            onChange={(e: any) => {
              setFieldValue('firstName', e.target.value);
            }}
            maxLength={200}
          />
        </FormGroup>
        <Spacer mb={6} />
        <FormGroup
          data-qaid="crew-user-basic-info-form-last-name-formgroup"
          label={intl.formatMessage({
            id: 'admin.crewDirectory.crewLastName',
          })}
          errorMsg={errorMsgForField('lastName', touched, errors)}
        >
          <StyledTextfield
            data-qaid="crew-user-basic-info-form-last-name-field"
            id="lastName"
            name="lastName"
            value={values.lastName}
            onChange={(e: any) => {
              setFieldValue('lastName', e.target.value);
            }}
            maxLength={200}
          />
        </FormGroup>
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.crewDirectory.contactInfo',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="crew-user-basic-info-form-email-formgroup"
          label={intl.formatMessage({
            id: 'admin.crewDirectory.crewEmail',
          })}
          errorMsg={
            errorMsgForField('email', touched, errors) ||
            // @ts-ignore
            validationErrors.email
          }
          required
        >
          <StyledTextfield
            data-qaid="crew-user-basic-info-form-email-field"
            id="email"
            name="email"
            value={values.email}
            onChange={(e: any) => {
              setFieldValue('email', e.target.value);
            }}
            maxLength={100}
          />
        </FormGroup>
        <Spacer mb={4} />
        <VerticalCenteredText>
          {intl.formatMessage({
            id: 'admin.crewDirectory.crewEmailDescription',
          })}
        </VerticalCenteredText>
        <Spacer mb={6} />
        <FormGroup
          label={intl.formatMessage({
            id: 'admin.crewDirectory.crewMobile',
          })}
          data-qaid="crew-user-basic-info-form-mobile-formgroup"
          errorMsg={
            errorMsgForField('mobile', touched, errors) ||
            // @ts-ignore
            validationErrors.mobile
          }
        >
          <Grid>
            <Col xs={12} md={4}>
              <Select
                data-qaid="crew-user-basic-info-form-country-field"
                searchable
                getOptionLabel={(opt: any) => opt.title}
                defaultValue={
                  dataCountries &&
                  dataCountries.countries &&
                  dataCountries.countries.countries &&
                  resolveCountryByCountryCode(
                    dataCountries.countries.countries,
                    values.countryCode
                  )
                }
                options={
                  dataCountries &&
                  dataCountries.countries &&
                  dataCountries.countries.countries
                    ? dataCountries.countries.countries
                    : []
                }
                placeholder={intl.formatMessage({
                  id: 'admin.userBasicInfo.countryPlaceholder',
                })}
                onChange={country =>
                  setFieldValue(
                    'countryCode',
                    country && country.countryCode ? country.countryCode : ''
                  )
                }
              />
            </Col>
            <Col xs={12} md={6}>
              <Textfield
                data-qaid="crew-user-basic-info-form-mobile-field"
                type="text"
                name="mobile"
                value={values.mobile}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                placeholder="352 781 160 4792"
              />
            </Col>
          </Grid>
        </FormGroup>
        <Spacer mb={3} />
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.crewDirectory.heading.slackId',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="crew-user-basic-info-form-slack-id-formgroup"
          label={intl.formatMessage({
            id: 'admin.userBasicInfo.heading.slackUserId',
          })}
          errorMsg={errorMsgForField('slackUserId', touched, errors)}
        >
          <SlackUserIdTextfield
            data-qaid="crew-user-basic-info-form-slack-user-id-field"
            id="slackUserId"
            name="slackUserId"
            value={values.slackUserId}
            onChange={(e: any) => {
              setFieldValue('slackUserId', e.target.value);
            }}
            maxLength={15}
          />
        </FormGroup>
      </FormGroupContainer>
    </GenericFormContainer>
  );
};

export default CrewUserBasicInfoForm;
