import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import {
  ArtistApplicationMainDecision,
  ArtistApplicationOfferDecision,
  ArtistApplicationRejectDecision,
} from 'app/typings/artistApplications';
import { City } from 'app/typings/cities';
import { SocialUrls } from 'app/typings/socialUrls';
import { errorMsgForField } from 'app/shared/utils/form';
import usePermission from 'app/shared/utils/usePermission';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import FormGroup from 'app/shared/components/molecules/FormGroup';
import RadioGroup from 'app/shared/components/molecules/RadioGroup';
import { filterRadioOptionsBasedOnPermissions } from 'app/admin/utils/optionLists';
import RichTextEditor from 'app/admin/components/molecules/RichTextEditor';
import SocialIconList from 'app/admin/components/molecules/SocialIconList';

import ArtistApplicationOfferDecisionField from './ArtistApplicationOfferDecisionField';
import ArtistApplicationRejectDecisionField from './ArtistApplicationRejectDecisionField';

interface FormProps {
  city?: City;
  formikProps: any;
  setButtonConfigs?: (set: object) => void;
  setFormSubmitAction?: (func: Function) => void;
  setDisplayConfirmation?: (set: boolean) => void;
  setConfirmationDescription?: (set: string) => void;
  setIsSubmitting?: (set: boolean) => void;
  setArtistApplicationMainDecision: Function;
  setArtistApplicationRejectDecision: Function;
  setArtistApplicationOfferDecision: Function;
  setArtistApplicationSelectedOffer: Function;
  artistApplicationSelectedOffer: any;
  artistApplicationSocialUrls?: SocialUrls;
}

const NoEditorSpaceHolder = styled.div`
  height: 1px;
  width: 350px;

  ${({ theme }) => css`
    ${theme.media.xs`
      width: 320px;
    `};

    ${theme.media.sm`
      width: 550px;
    `};

    ${theme.media.md`
      width: 550px;
    `};

    ${theme.media.lg`
      width: 400px;
    `};

    ${theme.media.xl`
      width: 400px;
    `};
  `};
`;

const acceptWithoutBookingLabel = (intl: any, isCuratorUser: boolean) => {
  if (isCuratorUser) {
    return intl.formatMessage({
      id: 'admin.artistApplication.details.accept',
    });
  }

  return intl.formatMessage({
    id: 'admin.artistApplication.details.acceptWithoutBooking',
  });
};

const ArtistApplicationReviewForm: React.FC<FormProps> = ({
  city,
  formikProps,
  setButtonConfigs,
  setFormSubmitAction,
  setDisplayConfirmation,
  setConfirmationDescription,
  setIsSubmitting,
  setArtistApplicationMainDecision,
  setArtistApplicationRejectDecision,
  setArtistApplicationOfferDecision,
  setArtistApplicationSelectedOffer,
  artistApplicationSelectedOffer,
  artistApplicationSocialUrls = {},
}) => {
  const intl = useIntl();
  const { setFieldValue, errors, touched, values } = formikProps;

  const hasAcceptWithBookingPermission = usePermission(
    'artistApplication.approveWithBooking'
  );
  const hasAcceptWithoutBookingPermission = usePermission(
    'artistApplication.approveWithoutBooking'
  );
  const hasRejectApplicationPermission = usePermission(
    'artistApplication.reject'
  );
  const hasRejectArtistApplicationByCityPermission = usePermission(
    'artistApplication.rejectByCity'
  );
  const hasSoftRejectApplicationPermission = usePermission(
    'artistApplication.softReject'
  );

  const viewArtistApplicationsByCity = usePermission(
    'artistApplication.viewByCity'
  );

  useEffect(() => {
    setFormSubmitAction && setFormSubmitAction(() => formikProps.submitForm);
    setDisplayConfirmation &&
      setDisplayConfirmation(
        !!values.artistApplicationMainDecision ||
          values.customEmailMessage !== ''
      );
    setConfirmationDescription &&
      setConfirmationDescription(
        intl.formatMessage({
          id: 'admin.artistApplication.details.closeModalWithoutSaving',
        })
      );
    setButtonConfigs &&
      setButtonConfigs({
        submit: {
          disabled:
            values.artistApplicationMainDecision === undefined ||
            (values.artistApplicationMainDecision ===
              ArtistApplicationMainDecision.ACCEPT_WITH_BOOKING &&
              values.artistApplicationOfferDecision ===
                ArtistApplicationOfferDecision.ADD_TO_EXISTING_OFFER &&
              artistApplicationSelectedOffer === undefined) ||
            (hasSoftRejectApplicationPermission &&
              values.artistApplicationMainDecision ===
                ArtistApplicationMainDecision.REJECT_OR_SOFT_REJECT &&
              values.artistApplicationRejectDecision === undefined),
        },
      });
    setIsSubmitting && setIsSubmitting(formikProps.isSubmitting);
  }, [
    formikProps.isSubmitting,
    formikProps.submitForm,
    formikProps.dirty,
    setFormSubmitAction,
    setDisplayConfirmation,
    setIsSubmitting,
    hasSoftRejectApplicationPermission,
    values.artistApplicationMainDecision,
    values.artistApplicationRejectDecision,
    values.artistApplicationOfferDecision,
    values.customEmailMessage,
    setConfirmationDescription,
    intl,
    setButtonConfigs,
    artistApplicationSelectedOffer,
  ]);

  const reviewArtistApplicationRadioOptions = filterRadioOptionsBasedOnPermissions(
    [
      {
        title: intl.formatMessage({
          id: 'admin.artistApplication.details.acceptAndSendBooking',
        }),
        value: ArtistApplicationMainDecision.ACCEPT_WITH_BOOKING,
      },
      {
        title: acceptWithoutBookingLabel(intl, viewArtistApplicationsByCity),
        value: ArtistApplicationMainDecision.ACCEPT_WITHOUT_BOOKING,
      },
      {
        title: intl.formatMessage({
          id: 'admin.artistApplication.details.archive',
        }),
        value: ArtistApplicationMainDecision.REJECT_OR_SOFT_REJECT,
      },
    ],
    {
      [ArtistApplicationMainDecision.ACCEPT_WITH_BOOKING]: hasAcceptWithBookingPermission,
      [ArtistApplicationMainDecision.ACCEPT_WITHOUT_BOOKING]: hasAcceptWithoutBookingPermission,
      [ArtistApplicationMainDecision.REJECT_OR_SOFT_REJECT]:
        city?.cityOperatingModel == 'sofar_operated_and_curator'
          ? hasRejectArtistApplicationByCityPermission ||
            hasRejectApplicationPermission
          : hasRejectApplicationPermission,
    }
  );

  const subfields = {
    [ArtistApplicationMainDecision.ACCEPT_WITH_BOOKING]: {
      component: (
        <ArtistApplicationOfferDecisionField
          city={city}
          values={values}
          setFieldValue={setFieldValue}
          setArtistApplicationOfferDecision={setArtistApplicationOfferDecision}
          setArtistApplicationSelectedOffer={setArtistApplicationSelectedOffer}
        />
      ),
      indented: true,
    },
  };

  if (hasSoftRejectApplicationPermission) {
    subfields[ArtistApplicationMainDecision.REJECT_OR_SOFT_REJECT] = {
      component: (
        <ArtistApplicationRejectDecisionField
          values={values}
          setFieldValue={setFieldValue}
          setArtistApplicationRejectDecision={
            setArtistApplicationRejectDecision
          }
        />
      ),
      indented: true,
    };
  }

  return (
    <form
      id="review-artist-application-form"
      onSubmit={(e: any) => {
        e.preventDefault();
      }}
    >
      <FormGroup
        data-qaid="review-artist-application-form-artist-application-decision-formgroup"
        label={intl.formatMessage({
          id: 'admin.artistApplication.details.selectSubmissionOptions',
        })}
        errorMsg={errorMsgForField(
          'artistApplicationDecision',
          touched,
          errors
        )}
      >
        <RadioGroup
          name="artistApplicationMainDecision"
          data-qaid="artist-application-decision-type-options-field"
          options={reviewArtistApplicationRadioOptions}
          subfields={subfields}
          selectedValue={values.artistApplicationMainDecision}
          onChange={value => {
            setFieldValue('artistApplicationMainDecision', value);
            setArtistApplicationMainDecision(value);
          }}
          spaceBetweenOptions={2}
        />
      </FormGroup>
      <Spacer mb={7} />
      {values.artistApplicationMainDecision ===
        ArtistApplicationMainDecision.REJECT_OR_SOFT_REJECT &&
      values.artistApplicationRejectDecision ===
        ArtistApplicationRejectDecision.SOFT_REJECT ? (
        <NoEditorSpaceHolder />
      ) : (
        <>
          <FormGroup
            data-qaid="review-artist-application-form-custom-email-message-formgroup"
            label={intl.formatMessage({
              id: 'admin.artistApplication.details.addCustomNoteEmail',
            })}
            errorMsg={errorMsgForField('customEmailMessage', touched, errors)}
          >
            <RichTextEditor
              name="customEmailMessage"
              currentValue={values.customEmailMessage}
              onChange={value => {
                setFieldValue('customEmailMessage', value);
              }}
            />
          </FormGroup>
        </>
      )}
      <SocialIconList socialUrls={artistApplicationSocialUrls} />
    </form>
  );
};

export default ArtistApplicationReviewForm;
