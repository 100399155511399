import React from 'react';
import styled, { css } from 'styled-components';

import { Icon } from 'app/shared/components/atoms/IconManualCSS';

const ToggleIconWrapper = styled.div<{ rotateIcon: boolean; color?: string }>`
  ${({ theme, rotateIcon, color }) => css`
    transform: ${rotateIcon && 'rotate(-90deg)'};
    transition: transform 0.1s;
    margin-right: ${theme.ruler[3]}px;
    background-color: ${color || theme.colors.green600};
    width: 28px;
    height: 28px;
    min-width: 28px;
    min-height: 28px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
      cursor: pointer;
    }
  `}
`;

const ToggleIcon = styled(Icon)`
  ${({ theme }) => css`
    &::before {
      color: ${theme.colors.whiteDenim};
    }
  `}
`;

interface Props {
  rotate: boolean;
  color?: string;
}

const RotateToggle: React.FC<Props> = ({ rotate, color }) => (
  <ToggleIconWrapper rotateIcon={rotate} color={color}>
    <ToggleIcon name="caretDown" size="10px" />
  </ToggleIconWrapper>
);

export default RotateToggle;
