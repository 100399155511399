import * as Yup from 'yup';

import { isEmpty } from 'app/shared/utils/object';

const eventCreateFormSchema = (intl: any) =>
  Yup.object().shape({
    eventType: Yup.string().required(
      intl.formatMessage({
        id: 'form.requiredField',
      })
    ),
    dates: Yup.array()
      .of(Yup.string())
      .test(
        'dates',
        intl.formatMessage({
          id: 'form.selectAtLeastOneDate',
        }),
        function(arr) {
          return !isEmpty(arr);
        }
      ),
    city: Yup.mixed()
      .nullable()
      .required(
        intl.formatMessage({
          id: 'form.requiredField',
        })
      )
      .test('defined', '{path} must be defined', value => value !== undefined),
    eventStatus: Yup.string().required(
      intl.formatMessage({
        id: 'form.requiredField',
      })
    ),
    partner: Yup.string()
      .nullable()
      .test(
        'campaign-required',
        intl.formatMessage({
          id: 'admin.eventCreate.campaignRequired',
        }),
        function() {
          const partner = this.parent.partner;
          const campaign = this.parent.campaign;
          return !(partner && !campaign);
        }
      ),
  });

export default eventCreateFormSchema;
