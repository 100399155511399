import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import YouTube from 'react-youtube';
import styled, { css } from 'styled-components';

import { ManualCSSTheme } from 'app/shared/theme';
import { useAnalyticsContext } from 'app/shared/utils';
import { extractYoutubeEmbedVideoId } from 'app/shared/utils/videos';
import { Container } from 'app/shared/components/atoms/Container';
import { LoadingBlocks } from 'app/shared/components/atoms/LoadingBlocks';

interface Props {
  url: string;
  'data-qaid'?: string;
  iframeProps?: any;
  borderRadius?: 'default' | 'large';
  noMarginBottom?: boolean;
  size?: string;
  streamType?: string;
  minHeight?: string;
}

interface ContainerProps {
  size?: string;
  theme: ManualCSSTheme;
}

const StyledLoadingBlock = styled(LoadingBlocks.Rectangle)`
  position: absolute;
`;

const WrapperDiv = styled.div<{
  noMarginBottom: boolean;
  streamType?: string;
  minHeight?: string;
}>`
  ${({ theme, noMarginBottom, streamType, minHeight }) => css`
    width: 100%;
    min-width: 200px;
    min-height: 200px;
    padding-bottom: 56.4%;
    display: block;
    position: relative;
    margin-bottom: ${theme.ruler[noMarginBottom ? 0 : 4]}px;

    ${theme.media.lg`
      min-height: ${minHeight || 200}px;
    `}

    ${theme.media.xl`
      min-height: ${minHeight || 200}px;
    `}

    ${streamType == 'Live Stream' &&
      css`
        ${theme.media.lg`
          float: left;
          padding-bottom: 37.5%;
          width: 67%;
        `}

        ${theme.media.xl`
          float: left;
          padding-bottom: 40.5%;
          width: 72%;
        `}
      `}
  `}
`;

const InnerDiv = styled.div<{ borderRadius: string }>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: ${({ theme, borderRadius }) =>
    theme.borderRadius[borderRadius]};
  overflow: hidden;
`;

const StyledContainer = styled(Container)<ContainerProps>`
  ${({ theme, size }) => css`
    ${theme.media.xs`
      width: ${size === 'smaller' && '90%'};
    `};
    ${theme.media.md`
      width: ${size === 'smaller' && '80%'};
    `};
    ${theme.media.lg`
      width: ${size === 'smaller' && '70%'};
    `};
    ${theme.media.xl`
      width: ${size === 'smaller' && '60%'};
    `};
  `}
`;

const YoutubePlayer: React.FC<Props> = ({
  url,
  iframeProps,
  size,
  'data-qaid': qaId = 'player',
  borderRadius = 'large',
  noMarginBottom = false,
  streamType,
  minHeight,
}) => {
  const [loaded, setLoaded] = useState(false);
  const { ref, inView } = useInView({
    rootMargin: '300px',
    threshold: 0,
  });

  useEffect(() => {
    if (inView) {
      setLoaded(true);
    }
  }, [inView]);
  const { trackAnalyticsEvent } = useAnalyticsContext();

  const onStateChange = (e: any) => {
    let players = document.querySelectorAll("[id^='ytplayer']");
    // Start
    if (e.data == -1) {
      trackAnalyticsEvent('Video Started', {
        title: e.target.playerInfo.videoData.title,
        url,
        currentTime: e.target.getCurrentTime(),
      });

      players.forEach(function(player) {
        if (
          player.getAttribute('id') !==
          'ytplayer-' + e.target.getVideoData().video_id
        ) {
          //@ts-ignore
          player.contentWindow.postMessage(
            '{"event":"command","func":"pauseVideo","args":""}',
            '*'
          );
        }
      });
    }

    // Complete
    if (e.data == 0) {
      trackAnalyticsEvent('Video Finished', {
        title: e.target.playerInfo.videoData.title,
        url,
        currentTime: e.target.getCurrentTime(),
      });

      players.forEach(function(player) {
        if (
          player.getAttribute('id') !==
          'ytplayer-' + e.target.getVideoData().video_id
        ) {
          //@ts-ignore
          player.contentWindow.postMessage(
            '{"event":"command","func":"pauseVideo","args":""}',
            '*'
          );
        }
      });
    }

    // Resume
    if (e.data == 1) {
      players.forEach(function(player) {
        if (
          player.getAttribute('id') !==
          'ytplayer-' + e.target.getVideoData().video_id
        ) {
          //@ts-ignore
          player.contentWindow.postMessage(
            '{"event":"command","func":"pauseVideo","args":""}',
            '*'
          );
        }
      });
    }

    // Pause
    if (e.data == 2) {
      trackAnalyticsEvent('Video Paused', {
        title: e.target.playerInfo.videoData.title,
        url,
        currentTime: e.target.getCurrentTime(),
      });
    }
  };

  const videoId = extractYoutubeEmbedVideoId(url);

  const onReadyState = () => {
    let uniqueVideo = document.getElementsByClassName(
      `${qaId}-player-${videoId}`
    ) as HTMLCollectionOf<HTMLElement>;

    if (uniqueVideo) {
      for (let i = 0; i < uniqueVideo.length; i++) {
        uniqueVideo[i].style.display = 'inline';
      }
    }
  };

  let video = (
    <WrapperDiv
      streamType={streamType}
      data-qaid={qaId}
      noMarginBottom={noMarginBottom}
      minHeight={minHeight}
    >
      <InnerDiv
        data-qaid={`${qaId}-innerdiv-player`}
        borderRadius={borderRadius}
      >
        <YouTube
          containerClassName={`${qaId}-player-${videoId}`}
          id={`ytplayer-${videoId}`}
          videoId={videoId || ''}
          onReady={onReadyState}
          opts={{ ...iframeProps, height: '100%', width: '100%' }}
          onStateChange={onStateChange}
        />
      </InnerDiv>
    </WrapperDiv>
  );

  if (size === 'smaller') {
    video = (
      <StyledContainer data-qaid={`${qaId}-styled-container`} size={size}>
        {video}
      </StyledContainer>
    );
  }

  return inView || loaded ? (
    video
  ) : (
    <StyledContainer data-qaid={`${qaId}-styled-container`} size={size}>
      <WrapperDiv
        streamType={streamType}
        data-qaid={qaId}
        noMarginBottom={noMarginBottom}
      >
        <StyledLoadingBlock
          width="100%"
          height="100%"
          data-qaid="loading-video"
          ref={ref}
        />
      </WrapperDiv>
    </StyledContainer>
  );
};

export default YoutubePlayer;
