import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { EventStaffMember } from 'app/typings/events';
import { useCurrentTheme } from 'app/shared/theme';
import { get } from 'app/shared/utils/get';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import { ToolTip, ToolTipContainer } from 'app/shared/components/atoms/ToolTip';
import { Overline } from 'app/shared/components/atoms/TypographyManualCSS';
import { eventStaffConfig } from 'app/admin/utils/crew';
import { TagElement } from 'app/admin/components/molecules/TagElementList';

interface CrewProps {
  show?: boolean;
  staff?: EventStaffMember[];
  eventOrganizedAs?: string;
  canEditEvent?: boolean;
}

const TitleOverline = styled(Overline)`
  margin-bottom: 5px;
`;

const EventCrewWrapper = styled.div`
  height: 160px;
`;

const EventCrewRolesWrapper = styled.div`
  margin-top: 4px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  line-height: 18px;
`;

const StyledTagElement = styled(TagElement)`
  width: fit-content;
  display: inline-flex;
`;

const EventCrew: React.FC<CrewProps> = ({
  show = true,
  staff = [],
  canEditEvent = true,
}) => {
  const intl = useIntl();
  const theme = useCurrentTheme();

  if (!show) {
    return null;
  }

  const staffWithLabels = staff
    .map((staffMember: any) => ({
      ...staffMember,
      ...(eventStaffConfig[get(staffMember, 'role.key', undefined)] || {}),
    }))
    .sort((a, b) => a.position - b.position);

  return (
    <>
      <TitleOverline>
        {intl.formatMessage({
          id: 'admin.eventPlanner.team.label',
        })}
      </TitleOverline>
      <EventCrewWrapper>
        <EventCrewRolesWrapper>
          {staffWithLabels.map((staffMember: any, index: number) =>
            staffMember.labelShortKey ? (
              <ToolTipContainer key={index}>
                <StyledTagElement
                  key={index}
                  tagElementColor={
                    get(staffMember, 'user.id', undefined)
                      ? theme.colors.purpleHaze
                      : '#C5C5C5'
                  }
                  textColor={theme.colors.whiteDenim}
                  marginBottom={0}
                >
                  {intl.formatMessage({
                    id: `admin.eventPlanner.team.${staffMember.labelShortKey}`,
                  })}
                </StyledTagElement>
                {canEditEvent && get(staffMember, 'user', undefined) && (
                  <ToolTip>
                    {get(staffMember, 'user.firstName', undefined) +
                      ' ' +
                      get(staffMember, 'user.lastName', undefined)}
                  </ToolTip>
                )}
              </ToolTipContainer>
            ) : null
          )}
        </EventCrewRolesWrapper>
      </EventCrewWrapper>
      <DottedLine />
    </>
  );
};

export default EventCrew;
