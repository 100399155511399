import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { get } from 'app/shared/utils/get';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { userFullNameOrEmail } from 'app/admin/utils/users';
import { UpdateUserFromAdmin } from 'app/admin/graphql/users/mutationHooks';
import { DetailsFormMainContainer } from 'app/admin/components/atoms/DetailContent';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

import UserBasicInfoForm from './UserBasicInfoForm';
import UserBasicInfoFormSchema from './UserBasicInfoFormSchema';

interface Props {
  contentProps?: any;
  setFormSubmitAction: (func: Function) => void;
  setDisplayConfirmation: (set: boolean) => void;
  setIsSubmitting: (set: boolean) => void;
  navigateTo: (routeData: object) => void;
}

const UserBasicInfoEdit: React.FC<Props> = ({
  contentProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  navigateTo,
}) => {
  const intl = useIntl();

  const [validationErrors, setValidationErrors] = useState<object>({});

  const formInitialValues = {
    firstName: get(contentProps, 'firstName', '') || '',
    lastName: get(contentProps, 'lastName', '') || '',
    homeCity: get(contentProps, 'homeCity', '') || '',
    cityOther: get(contentProps, 'cityOther', '') || '',
    email: get(contentProps, 'email', '') || '',
    isUnluckyEmailEnabled:
      get(contentProps, 'isUnluckyEmailEnabled', false) || false,
    mobile: get(contentProps, 'mobile.numberBody', '') || '',
    countryCode: get(contentProps, 'mobile.countryCodeIso3166', '') || '',
    latitude: get(contentProps, 'latitude', '') || '',
    longitude: get(contentProps, 'longitude', '') || '',
    gender: {
      value: get(contentProps, 'gender', undefined) || '',
      label: get(contentProps, 'gender', undefined),
    },
    birthYear: {
      value: get(contentProps, 'birthYear', undefined) || '',
      label: get(contentProps, 'birthYear', undefined),
    },
    generalNotes: get(contentProps, 'generalNotes', '') || '',
    howHeardAbout: get(contentProps, 'howHeardAbout', '') || '',
    artistIdToAssociate: undefined,
    artistIdsToUnassociate: [],
  };

  const userName = userFullNameOrEmail(contentProps);

  const updateUserFromAdminAction = UpdateUserFromAdmin();

  const handleSubmit = useSubmitAction({
    submitAction: updateUserFromAdminAction,
    submitVariables: (values: any) => ({
      userId: get(contentProps, 'id', undefined),
      firstName: values.firstName || null,
      lastName: values.lastName || null,
      homeCityId: values.homeCity ? values.homeCity.id.toString() : null,
      cityOther: values.cityOther,
      email: values.email,
      isUnluckyEmailEnabled: values.isUnluckyEmailEnabled,
      mobile: {
        countryCode: values.countryCode,
        numberBody: values.mobile,
      },
      latitude: values.latitude,
      longitude: values.longitude,
      gender: get(values, 'gender.value', null),
      birthYear: get(values, 'birthYear.value', null),
      generalNotes: values.generalNotes,
      howHeardAbout: values.howHeardAbout,
      artistIdToAssociate: values.artistIdToAssociate,
      artistIdsToUnassociate:
        values.artistIdsToUnassociate.length > 0
          ? values.artistIdsToUnassociate
          : undefined,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.userBasicInfo.form.successMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.userBasicInfo.form.failureMessage',
    }),
    onSuccess: (response: any) => {
      navigateTo({
        routeName: 'user-details',
        routeProps: {
          defaultOpenSection: 'basicInfo',
          ...response.data.updateUser.user,
        },
      });
    },
    onValidationError: (validationErrors: any) =>
      setValidationErrors(validationErrors),
    validationErrorKeysForDisplay: {
      mobile: 'mobile number',
    },
  });

  return (
    <ModalContentContainer>
      <DetailsFormMainContainer>
        <DetailsHeader title={userName} />
        <GenericForm
          formInitialValues={formInitialValues}
          renderFormComponent={(renderProps: any) => (
            <UserBasicInfoForm
              formikProps={renderProps.formikProps}
              validationErrors={validationErrors}
              associatedArtists={get(contentProps, 'associatedArtists', [])}
            />
          )}
          onSubmit={handleSubmit}
          formSchema={UserBasicInfoFormSchema(intl)}
          setFormSubmitAction={setFormSubmitAction}
          setDisplayConfirmation={setDisplayConfirmation}
          setIsSubmitting={setIsSubmitting}
          dataQaId="user-about-section-edit-form"
        />
      </DetailsFormMainContainer>
    </ModalContentContainer>
  );
};

export default UserBasicInfoEdit;
