import gql from 'graphql-tag';

export const GET_BROADCASTS = gql`
  query GetBroadcasts(
    $broadcastSearch: String
    $scope: String
    $streamType: String
    $excludeFirstNumResults: Int
    $orderBy: String
    $orderDirection: String
    $page: Int!
    $perPage: Int!
  ) {
    broadcasts(
      broadcastSearch: $broadcastSearch
      scope: $scope
      streamType: $streamType
      excludeFirstNumResults: $excludeFirstNumResults
      orderBy: $orderBy
      orderDirection: $orderDirection
      page: $page
      perPage: $perPage
    ) {
      broadcasts {
        id
        scheduledStartTimeUtc
        artistId
        artistTitle
        artistSlug
        artistBio
        artistImage
        artistSocials {
          urlSupport
        }
        location
        streamType
        embedUrl
        extraInfoUrl
        linkType
        partnershipTag
        hideDonation
        donationText
      }
      metadata {
        totalRecords
        currentPage
      }
    }
  }
`;
