import React from 'react';
import styled, { css } from 'styled-components';

import { useIsTShirtSizeBreakpointSize as useIsBreakpointSize } from 'app/shared/utils/useBreakpoint';
import { LinkButton, PrimaryButton } from 'app/shared/components/atoms/Button';
import { FlyoverLoadingIndicator } from 'app/shared/components/atoms/FlyoverContent';

interface FlyoverFooterProps {
  buttonText: string;
  buttonDisabled?: boolean;
  onClickButton?: VoidFunction;
  isSubmitting?: boolean;
  dataQaid?: string;
}

const FooterWrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    border-top: 1px solid ${theme.colors.silverSprings};
    text-align: center;

    ${theme.media.xs`
      display: none;
    `};

    ${theme.media.md`
      display: block;
      position: static;
    `};
  `}
`;

const MobileButtonWrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;

    ${theme.media.xs`
      display: flex;
      padding: 8px 30px 8px 30px;
      display: inline-block;
      flex-grow: 1;
    `};

    ${theme.media.md`
      display: none;
    `};
  `};
`;

const StyledLinkButton = styled(LinkButton)`
  ${({ theme }) => css`
    background: ${theme.colors.whiteDenim};
    &:focus {
      background-color: ${theme.colors.whiteDenim};
    }
  `}
`;

const FlyoverFooter: React.FC<FlyoverFooterProps> = ({
  buttonText,
  buttonDisabled = false,
  onClickButton = () => {},
  isSubmitting = false,
  dataQaid = 'flyover-footer-button',
}) => {
  const { isMobile } = useIsBreakpointSize();

  return (
    <>
      {isMobile ? (
        <>
          <MobileButtonWrapper>
            <PrimaryButton
              block={true}
              onClick={onClickButton}
              data-qaid={dataQaid}
              disabled={buttonDisabled}
            >
              {buttonText}
            </PrimaryButton>
          </MobileButtonWrapper>
        </>
      ) : (
        <FooterWrapper>
          {isSubmitting ? (
            <FlyoverLoadingIndicator />
          ) : (
            <StyledLinkButton
              disabled={buttonDisabled}
              onClick={onClickButton}
              data-qaid={dataQaid}
            >
              {buttonText}
            </StyledLinkButton>
          )}
        </FooterWrapper>
      )}
    </>
  );
};

export default FlyoverFooter;
