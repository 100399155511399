import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { get } from 'app/shared/utils/get';
import { dataGetter } from 'app/shared/utils/queries';
import useModal from 'app/shared/utils/useModal';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import ConfirmationModal from 'app/shared/components/molecules/ConfirmationModal';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { DeleteCountry } from 'app/admin/graphql/countries/mutationHooks';
import { GetCountryFullInfo } from 'app/admin/graphql/countries/queryHooks';
import { DetailsMainContainer } from 'app/admin/components/atoms/DetailContent';
import AccordionSections from 'app/admin/components/organisms/AccordionSections';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';
import { ReactComponent as InformationCircle } from 'icons/streamline-regular/interface-essential/alerts/information-circle.svg';
import { ReactComponent as CogCircle } from 'icons/streamline-regular/interface-essential/settings/cog-circle.svg';

import SectionBasicInfo from './SectionBasicInfo';
import SectionSettings from './SectionSettings';

interface Props {
  navigateTo: (routeData: any) => void;
  contentProps: any;
  hide: (hideProps?: any) => VoidFunction;
}

const detailsHeaderSummaryInfoList = (
  artistsCount: number | undefined,
  venuesCount: number | undefined,
  pastEventsCount: number | undefined,
  intl: any
) => {
  const artistsCountDescription = intl.formatMessage({
    id: 'shared.artists',
  });

  const venuesCountDescription = intl.formatMessage({
    id: 'shared.venues',
  });

  const pastEventsCountDescription = intl.formatMessage({
    id: 'admin.countries.details.pastEventsCount',
  });

  return [
    {
      backgroundColor: '#ebedef',
      mainInfo: artistsCount && artistsCount.toString(),
      description: artistsCountDescription,
    },
    {
      backgroundColor: '#ebf5fb',
      mainInfo: venuesCount && venuesCount.toString(),
      description: venuesCountDescription,
    },
    {
      backgroundColor: '#f3f9ed',
      mainInfo: pastEventsCount && pastEventsCount.toString(),
      description: pastEventsCountDescription,
    },
  ];
};

const detailsHeaderActionLinksInfo = (
  toggleDeleteCountryConfirmationModal: VoidFunction,
  citiesCount: number,
  intl: any
) => {
  return {
    link1: {
      text: intl.formatMessage({
        id: 'admin.countries.details.deleteCountry',
      }),
      active: citiesCount == 0,
      onClickAction: toggleDeleteCountryConfirmationModal,
    },
  };
};

const CountryDetails: React.FC<Props> = ({
  navigateTo,
  contentProps,
  hide,
}) => {
  const { id, title } = contentProps;

  const intl = useIntl();
  const [
    deleteCountryConfirmationModal,
    toggleDeleteCountryConfirmationModal,
  ] = useModal();

  const [headerData, setHeaderData] = useState({
    title,
    isLoaded: !!title,
  });

  const [isDeletingCountry, setIsDeletingCountry] = useState<boolean>(false);

  const {
    loading: loadingBasicInfo,
    error: errorBasicInfo,
    data: dataBasicInfo,
  } = GetCountryFullInfo({
    id,
    fetchPolicy: 'cache-and-network',
  });

  const sectionsConfig = {
    basicInfo: {
      title: intl.formatMessage({
        id: 'admin.countries.details.sectionTitle.basicInfo',
      }),
      icon: InformationCircle,
      iconColor: 'blueChristmas',
      sectionComponent: SectionBasicInfo,
      dataKey: 'country',
      displayEditIcon: true,
      loading: loadingBasicInfo,
      data: dataBasicInfo ? dataBasicInfo.country : undefined,
      callbacks: {
        toggleSectionEditModal: () =>
          navigateTo({
            routeName: 'country-basic-info-edit',
            routeProps: dataBasicInfo ? dataBasicInfo.country : undefined,
          }),
      },
    },
    settings: {
      title: intl.formatMessage({
        id: 'admin.countries.details.sectionTitle.settings',
      }),
      icon: CogCircle,
      iconColor: 'orangeCrush',
      sectionComponent: SectionSettings,
      dataKey: 'country',
      displayEditIcon: true,
      loading: loadingBasicInfo,
      data: dataBasicInfo ? dataBasicInfo.country : undefined,
      callbacks: {
        toggleSectionEditModal: () =>
          navigateTo({
            routeName: 'country-settings-edit',
            routeProps: dataBasicInfo ? dataBasicInfo.country : undefined,
          }),
      },
    },
  };

  useEffect(() => {
    const getDataBasicInfo = dataGetter(dataBasicInfo, 'country');
    if (dataBasicInfo) {
      setHeaderData({
        title: getDataBasicInfo('title'),
        isLoaded: true,
      });
    }
  }, [dataBasicInfo, loadingBasicInfo]);

  const deleteCountryAction = DeleteCountry();

  const handleDeleteCountry = useSubmitAction({
    beforeSubmit: () => setIsDeletingCountry(true),
    submitAction: deleteCountryAction,
    submitVariables: () => ({
      countryId: contentProps.id,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.countries.countryDeleteSuccessMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.countries.countryDeleteFailureMessage',
    }),
    onSuccess: () => {
      setIsDeletingCountry(false);
      hide({ shouldRefetchCountries: true });
    },
  });

  if ((!loadingBasicInfo && !dataBasicInfo) || errorBasicInfo) {
    hide();
    return null;
  }

  const summaryInfoList = detailsHeaderSummaryInfoList(
    get(dataBasicInfo, 'country.artistsCount', 0),
    get(dataBasicInfo, 'country.venuesCount', 0),
    get(dataBasicInfo, 'country.pastEventsCount', 0),
    intl
  );

  return (
    <ModalContentContainer data-qaid="country-details-main-modal">
      <DetailsMainContainer>
        <DetailsHeader
          title={headerData.title}
          summaryInfoList={summaryInfoList}
          actionLinksInfo={detailsHeaderActionLinksInfo(
            toggleDeleteCountryConfirmationModal,
            get(dataBasicInfo, 'country.citiesCount', 0),
            intl
          )}
          loading={!headerData.isLoaded}
        />
        <AccordionSections
          sectionsConfig={sectionsConfig}
          defaultOpenSection={contentProps.defaultOpenSection}
        />
      </DetailsMainContainer>
      {deleteCountryConfirmationModal.isShowing && (
        <ConfirmationModal
          onCancel={() => deleteCountryConfirmationModal.hide()}
          description={intl.formatMessage({
            id: 'admin.countries.countryDeleteConfirmation',
          })}
          onConfirm={handleDeleteCountry}
          isLoading={isDeletingCountry}
          confirmationButtonText={intl.formatMessage({
            id: 'admin.countries.countryDeleteConfirmationText',
          })}
        />
      )}
    </ModalContentContainer>
  );
};

export default CountryDetails;
