import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { get } from 'app/shared/utils/get';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { UpdateCompetitionTemplate } from 'app/admin/graphql/competitionTemplates/mutationHooks';
import { DetailsFormMainContainer } from 'app/admin/components/atoms/DetailContent';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

import CompetitionTemplateEditForm from './CompetitionTemplateEditForm';
import CompetitionTemplateEditFormSchema from './CompetitionTemplateEditFormSchema';

interface Props {
  contentProps?: any;
  setFormSubmitAction: (func: Function) => void;
  setDisplayConfirmation: (set: boolean) => void;
  setIsSubmitting: (set: boolean) => void;
  navigateTo: (routeData: object) => void;
}

const CompetitionTemplateEdit: React.FC<Props> = ({
  contentProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  navigateTo,
}) => {
  const intl = useIntl();

  const [validationErrors, setValidationErrors] = useState<object>({});

  const formInitialValues = {
    templateName: get(contentProps, 'templateName', '') || '',
    title: get(contentProps, 'title', '') || '',
    city: get(contentProps, 'city', '') || '',
    maxWinners: get(contentProps, 'maxWinners', '') || '',
    intro: get(contentProps, 'intro', '') || '',
    thirdPartyCtaText: get(contentProps, 'thirdPartyCtaText', '') || '',
    thirdPartyCtaLink: get(contentProps, 'thirdPartyCtaLink', '') || '',
    subtitle: get(contentProps, 'subtitle', '') || '',
    subtitleTwo: get(contentProps, 'subtitleTwo', '') || '',
    description: get(contentProps, 'description', '') || '',
    postDescription: get(contentProps, 'postDescription', '') || '',
    winnerCopy: get(contentProps, 'winnerCopy', '') || '',
    expiredCopy: get(contentProps, 'expiredCopy', '') || '',
    moreInfoTitle: get(contentProps, 'moreInfoTitle', '') || '',
    moreInfoSubheading: get(contentProps, 'moreInfoSubheading', '') || '',
    moreInfoPreview: get(contentProps, 'moreInfoPreview', '') || '',
    featuredContentCtaText:
      get(contentProps, 'featuredContentCtaText', '') || '',
    featuredContentCtaLink:
      get(contentProps, 'featuredContentCtaLink', '') || '',
    turnOnOptIn: get(contentProps, 'turnOnOptIn', false),
    optInCopy: get(contentProps, 'optInCopy', '') || '',
    shareTitle: get(contentProps, 'shareTitle', '') || '',
    shareCopy: get(contentProps, 'shareCopy', '') || '',
  };

  const updateCompetitionTemplateAction = UpdateCompetitionTemplate();

  const handleUpdateCompetitionTemplate = useSubmitAction({
    submitAction: updateCompetitionTemplateAction,
    submitVariables: (values: any) => ({
      competitionTemplateId: get(contentProps, 'id', undefined),
      templateName: values.templateName,
      title: values.title,
      cityId: values.city ? values.city.id : undefined,
      maxWinners: Number(values.maxWinners),
      intro: values.intro,
      thirdPartyCtaText: values.thirdPartyCtaText,
      thirdPartyCtaLink: values.thirdPartyCtaLink,
      subtitle: values.subtitle,
      subtitleTwo: values.subtitleTwo,
      description: values.description,
      postDescription: values.postDescription,
      winnerCopy: values.winnerCopy,
      expiredCopy: values.expiredCopy,
      moreInfoTitle: values.moreInfoTitle,
      moreInfoSubheading: values.moreInfoSubheading,
      moreInfoPreview: values.moreInfoPreview,
      featuredContentCtaText: values.featuredContentCtaText,
      featuredContentCtaLink: values.featuredContentCtaLink,
      turnOnOptIn: values.turnOnOptIn,
      optInCopy: values.optInCopy,
      shareTitle: values.shareTitle,
      shareCopy: values.shareCopy,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.competitionTemplateEdit.successMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.competitionTemplateEdit.failureMessage',
    }),
    onSuccess: () => {
      navigateTo({
        routeName: 'competition-templates',
        routeProps: {},
      });
    },
    onValidationError: validationErrors =>
      setValidationErrors(validationErrors),
    validationErrorKeysForDisplay: { template_name: 'template name' },
  });

  return (
    <ModalContentContainer>
      <DetailsFormMainContainer>
        <DetailsHeader
          title={intl.formatMessage({
            id: 'admin.competitionTemplateEdit.title',
          })}
        />
        <GenericForm
          formInitialValues={formInitialValues}
          renderFormComponent={(renderProps: any) => (
            <CompetitionTemplateEditForm
              formikProps={renderProps.formikProps}
              validationErrors={validationErrors}
            />
          )}
          onSubmit={handleUpdateCompetitionTemplate}
          formSchema={CompetitionTemplateEditFormSchema(intl)}
          setFormSubmitAction={setFormSubmitAction}
          setDisplayConfirmation={setDisplayConfirmation}
          setIsSubmitting={setIsSubmitting}
          dataQaId="competition-template-edit-form"
        />
      </DetailsFormMainContainer>
    </ModalContentContainer>
  );
};

export default CompetitionTemplateEdit;
