import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { upperFirst } from 'app/shared/utils/string';
import {
  OutlineButton,
  PrimaryButton,
} from 'app/shared/components/atoms/Button';
import CloseButton from 'app/shared/components/atoms/CloseButton';
import { CloseIcon } from 'app/shared/components/atoms/IconLibrary';
import { Radio } from 'app/shared/components/atoms/Radio';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { H3, H5 } from 'app/shared/components/atoms/TypographyManualCSS';
import MultiSelectInput from 'app/shared/components/molecules/MultiSelectInput';
import Select from 'app/shared/components/molecules/SelectManualCSS';

interface Option {
  title: string;
  value: string;
}

interface Props {
  onCancel?: () => void;
  onConfirm: (data: any) => void;
  onClose?: () => void;
  dataQaidPrefix?: string;
  options: Option[];
  title?: string;
  confirmationButtonText?: string;
  cancellationButtonText?: string;
  isLoading?: boolean;
  applyMethods?: string[];
  applyMethodStrings?: object;
  isMultiSelect?: boolean;
  modalType: string;
  changedFields?: string[];
}

// @ts-ignore
const AnchoredCloseButton = styled(CloseButton)`
  ${({ theme }) => css`
    position: absolute;
    top: 0;
    right: 0;
    padding: 24px;
    margin: 0px;

    ${theme.media.xs`
      padding: 15px 17px;
    `}

    ${theme.media.sm`
      padding: 24px;
    `}
  `}
`;

const SelectContainer = styled.div`
  padding-left: 32px;
`;

const ModalContainer = styled.div`
  left: 0;
  top: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 100;
  overflow-y: auto;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ModalMainSection = styled.div`
  ${({ theme }) => css`
    background-color: #f5f5f5;
    padding: 50px 20px;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 3px;
    position: relative;
    max-width: 320px;

    ${theme.media.md`
      padding: 60px 50px;
      width: 60%;
      max-width: 555px;
    `}
    ${theme.media.lg`
      padding: 60px 50px;
      width: 50%;
      max-width: 595px;
    `}

    ${theme.media.xl`
      padding: 60px 50px;
      width: 45%;
      max-width: 595px;
    `}
  `}
`;

const ModalTitle = styled(H3)`
  margin-bottom: 25px;
  font-weight: 400;
`;

const ModalMessage = styled(H5)`
  margin-bottom: 30px;
  font-weight: 400;
  line-height: 24px;
`;

const BreakLineSpan = styled.span`
  display: block;
  text-align: left;
`;

const ModalButtonContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    flex-direction: column-reverse;

    button {
      margin: 8px;
    }

    ${theme.media.md`
      flex-flow: unset;
    `}
  `}
`;

const MarginPrimaryButton = styled(PrimaryButton)`
  margin-right: 10px;
  min-width: 110px;
`;

const UpdatedFields = styled.ul`
  font-weight: 600;
  font-size: 18px;
`;

export const APPLY_TO_ALL = 'apply_to_all';
export const APPLY_TO_FUTURE = 'apply_to_future';
export const APPLY_TO_SELECT = 'apply_to_select';
const defaultApplyMethods = [APPLY_TO_ALL, APPLY_TO_FUTURE, APPLY_TO_SELECT];

const humanReadableFields = (fields: string[]) =>
  fields
    .map((field: string) =>
      (field.match(/[A-Za-z][a-z]*/g) || []).map(upperFirst).join(' ')
    )
    .filter((field: string) => !!field);

const mapChangedField = (field: string) => {
  /* eslint-disable */
  const mappings = {
    'Properties': 'Characteristics',
    'Num Tickets Available For Sale': 'Tickets Available',
    'Mc Notes': 'Notes For MC',
    'Supplies Needed Notes': 'Supplies Needed',
    'Internal Venue Notes': 'Other Internal Notes',
    'External Venue Notes': 'Fan Facing Description',
    'Invite Email Message': 'Invite Email',
    'Reveal Address Email Message': 'Address Reveal Email',
    'Seating Notes': 'Internal Seating Notes',
    'Local Crew Load In At': 'Crew Load In',
    'Local Artist Load In At': 'Artist Load In',
    'Local Guests Arrive At': 'Arrival Time',
    'Local Starts At': 'Music Start',
    'Local Ends At': 'End Time',
    'Timing Notes': 'Time Notes',
  };

  /* eslint-enable */
  return mappings.hasOwnProperty(field) ? mappings[field] : field;
};

const UpdateOptionsModal: React.FC<Props> = ({
  onCancel,
  onConfirm,
  onClose,
  dataQaidPrefix,
  title,
  options,
  confirmationButtonText,
  cancellationButtonText,
  isLoading = false,
  isMultiSelect = true,
  modalType,
  applyMethods = defaultApplyMethods,
  applyMethodStrings = {},
  changedFields = [],
}) => {
  const [applyMethod, setApplyMethod] = useState(APPLY_TO_ALL);
  const [subOptions, setSubOptions] = useState<string[]>([]);
  const intl = useIntl();

  const renderApplyMethodRadioButton = (method: string) => {
    if (method === APPLY_TO_ALL) {
      return (
        <Radio
          id={APPLY_TO_ALL}
          name={APPLY_TO_ALL}
          onChange={() => {
            setApplyMethod(APPLY_TO_ALL);
            setSubOptions([]);
          }}
          checked={applyMethod === APPLY_TO_ALL}
        >
          <span
            dangerouslySetInnerHTML={{
              __html:
                applyMethodStrings[APPLY_TO_ALL] ||
                intl.formatMessage(
                  {
                    id: 'admin.updateOptionsModal.applyToAllConcerts',
                  },
                  {
                    modalType,
                  }
                ),
            }}
          />
        </Radio>
      );
    } else if (method === APPLY_TO_FUTURE) {
      return (
        <Radio
          id={APPLY_TO_FUTURE}
          name={APPLY_TO_FUTURE}
          onChange={() => {
            setApplyMethod(APPLY_TO_FUTURE);
            setSubOptions([]);
          }}
          checked={applyMethod === APPLY_TO_FUTURE}
        >
          <span
            dangerouslySetInnerHTML={{
              __html:
                applyMethodStrings[APPLY_TO_FUTURE] ||
                intl.formatMessage(
                  {
                    id: 'admin.updateOptionsModal.applyToFutureConcerts',
                  },
                  {
                    modalType,
                  }
                ),
            }}
          />
        </Radio>
      );
    } else if (method === APPLY_TO_SELECT) {
      return (
        <>
          <Radio
            id={APPLY_TO_SELECT}
            name={APPLY_TO_SELECT}
            data-qaid="apply-to-select-styled-radio"
            onChange={() => {
              setApplyMethod(APPLY_TO_SELECT);
            }}
            checked={applyMethod === APPLY_TO_SELECT}
          >
            <span
              dangerouslySetInnerHTML={{
                __html:
                  applyMethodStrings[APPLY_TO_SELECT] ||
                  intl.formatMessage({
                    id: 'admin.updateOptionsModal.applyToSelectConcerts',
                  }),
              }}
            />
          </Radio>
          <Spacer mt={4} />
          <SelectContainer>
            {isMultiSelect ? (
              <MultiSelectInput
                placeholder={intl.formatMessage({
                  id: 'admin.updateOptionsModal.selectConcerts',
                })}
                options={options}
                selectedValues={
                  applyMethod === APPLY_TO_SELECT ? subOptions : []
                }
                onApply={values => {
                  setApplyMethod(APPLY_TO_SELECT);
                  setSubOptions(values);
                }}
                disabled={applyMethod !== APPLY_TO_SELECT}
                isActive={applyMethod === APPLY_TO_SELECT}
                submitLabel={intl.formatMessage({
                  id: 'admin.updateOptionsModal.done',
                })}
              />
            ) : (
              <Select
                options={options}
                searchable={false}
                getOptionLabel={(option: any) => option.title}
                onChange={(option: any) => {
                  setApplyMethod(APPLY_TO_SELECT);
                  setSubOptions([option.value.toString()]);
                }}
                placeholder={intl.formatMessage({
                  id: 'admin.updateOptionsModal.selectAConcert',
                })}
              />
            )}
          </SelectContainer>
        </>
      );
    }
    return null;
  };

  return (
    <ModalContainer>
      <ModalMainSection data-qaid={`${modalType}-update-options-modal`}>
        <AnchoredCloseButton
          onClick={onClose ? onClose : onCancel}
          data-qaid={`${dataQaidPrefix}-close-btn`}
        >
          <CloseIcon />
        </AnchoredCloseButton>
        {title && (
          <ModalTitle>
            <BreakLineSpan>
              <div
                data-qaid={`${dataQaidPrefix}-title`}
                dangerouslySetInnerHTML={{
                  __html:
                    title ||
                    intl.formatMessage({
                      id: 'admin.updateOptionsModal.title',
                    }),
                }}
              ></div>
              <Spacer mt={8} />
            </BreakLineSpan>
            {changedFields && (
              <React.Fragment>
                <UpdatedFields>
                  {humanReadableFields(changedFields).map((field, index) => (
                    <li key={index}>
                      {mapChangedField(field)}
                      <Spacer mt={2} />
                    </li>
                  ))}
                </UpdatedFields>
              </React.Fragment>
            )}
          </ModalTitle>
        )}

        <ModalMessage>
          <div>
            <Spacer mt={4} key={0} />
            {applyMethods.map((method: string, index: number) => (
              <React.Fragment key={index}>
                {renderApplyMethodRadioButton(method)}
                <Spacer mt={4} />
              </React.Fragment>
            ))}
          </div>
        </ModalMessage>
        <ModalButtonContainer>
          {onCancel ? (
            <>
              <OutlineButton
                onClick={onCancel}
                data-qaid={`${dataQaidPrefix}-cancel-btn`}
              >
                {cancellationButtonText ||
                  intl.formatMessage({ id: 'admin.updateOptionsModal.cancel' })}
              </OutlineButton>
              <MarginPrimaryButton
                loading={isLoading}
                onClick={() =>
                  onConfirm({
                    applyMethod,
                    selectedValues:
                      applyMethod === APPLY_TO_SELECT ? subOptions : undefined,
                  })
                }
                disabled={
                  applyMethod === APPLY_TO_SELECT && subOptions.length === 0
                }
                data-qaid={`${dataQaidPrefix}-confirm-btn`}
              >
                {confirmationButtonText ||
                  intl.formatMessage({
                    id: 'admin.updateOptionsModal.continue',
                  })}
              </MarginPrimaryButton>
            </>
          ) : (
            <PrimaryButton
              loading={isLoading}
              onClick={() =>
                onConfirm({
                  applyMethod,
                  selectedValues:
                    applyMethod === APPLY_TO_SELECT ? subOptions : undefined,
                })
              }
              disabled={
                applyMethod === APPLY_TO_SELECT && subOptions.length === 0
              }
              data-qaid={`${dataQaidPrefix}-confirm-btn`}
            >
              {confirmationButtonText}
            </PrimaryButton>
          )}
        </ModalButtonContainer>
      </ModalMainSection>
    </ModalContainer>
  );
};

export default UpdateOptionsModal;
