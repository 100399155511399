import { sofarOperatedMarketTypeLabels } from 'app/admin/utils/cities';
import { formatTypeLabels } from 'app/admin/utils/events';

export const sofarOperatedMarketTypeOptions = Object.keys(
  sofarOperatedMarketTypeLabels
).map((key: string) => ({
  label: sofarOperatedMarketTypeLabels[key],
  value: key,
}));

export const formatTypeOptions = Object.keys(formatTypeLabels).map(
  (key: string) => ({
    label: formatTypeLabels[key],
    value: key,
  })
);
