import * as Yup from 'yup';

const artistBasicInfoEditFormSchema = (intl: any) =>
  Yup.object().shape({
    title: Yup.string()
      .trim()
      .required(
        intl.formatMessage({
          id: 'form.requiredField',
        })
      ),
    description: Yup.string().nullable(),
    eligibility: Yup.array()
      .of(Yup.string())
      .nullable(),
    performingRightsOrganisation: Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.string(),
      })
      .nullable(),
    contactInfos: Yup.object().shape({
      primaryContactInfo: Yup.object().shape({
        email: Yup.string()
          .email('Email must be a valid email')
          .required(
            intl.formatMessage({
              id: 'form.requiredField',
            })
          ),
        phoneNumber: Yup.string()
          .trim()
          .matches(/^[-\d().+]+( [-\d.]+)*$/, {
            message: 'Phone number must be valid',
          })
          .when('isDayOfShowContact', {
            is: true,
            then: Yup.string().required(
              intl.formatMessage({
                id: 'form.requiredField',
              })
            ),
            otherwise: Yup.string().nullable(),
          }),
      }),
      secondaryContactInfo: Yup.object().shape({
        email: Yup.string()
          .email('Email must be a valid email')
          .when(['firstName', 'lastName'], {
            is: (firstName, lastName) => firstName || lastName,
            then: Yup.string()
              .email()
              .required(
                intl.formatMessage({
                  id: 'form.requiredField',
                })
              ),
            otherwise: Yup.string().nullable(),
          }),
        phoneNumber: Yup.string()
          .trim()
          .matches(/^[-\d().+]+( [-\d.]+)*$/, {
            message: 'Phone number must be valid',
          })
          .when('isDayOfShowContact', {
            is: true,
            then: Yup.string().required(
              intl.formatMessage({
                id: 'form.requiredField',
              })
            ),
            otherwise: Yup.string().nullable(),
          }),
      }),
      tertiaryContactInfo: Yup.object().shape({
        email: Yup.string()
          .email('Email must be a valid email')
          .when(['firstName', 'lastName'], {
            is: (firstName, lastName) => firstName || lastName,
            then: Yup.string()
              .email()
              .required(
                intl.formatMessage({
                  id: 'form.requiredField',
                })
              ),
            otherwise: Yup.string().nullable(),
          }),
        phoneNumber: Yup.string()
          .trim()
          .matches(/^[-\d().+]+( [-\d.]+)*$/, {
            message: 'Phone number must be valid',
          })
          .when('isDayOfShowContact', {
            is: true,
            then: Yup.string().required(
              intl.formatMessage({
                id: 'form.requiredField',
              })
            ),
            otherwise: Yup.string().nullable(),
          }),
      }),
    }),
    signedStatus: Yup.object().shape({
      label: Yup.string(),
      value: Yup.string(),
    }),
  });

export default artistBasicInfoEditFormSchema;
