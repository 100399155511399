import React from 'react';
import { useIntl } from 'react-intl';

import { get } from 'app/shared/utils/get';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { HRIS_SYSTEMS } from 'app/admin/utils/users';
import { userFullNameOrEmail } from 'app/admin/utils/users';
import { UpdateUserFromAdmin } from 'app/admin/graphql/users/mutationHooks';
import { DetailsFormMainContainer } from 'app/admin/components/atoms/DetailContent';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

import InternalUserBasicInfoForm from './InternalUserBasicInfoForm';
import InternalUserBasicInfoFormSchema from './InternalUserBasicInfoFormSchema';

interface Props {
  contentProps?: any;
  setFormSubmitAction: (func: Function) => void;
  setDisplayConfirmation: (set: boolean) => void;
  setIsSubmitting: (set: boolean) => void;
  navigateTo: (routeData: object) => void;
}

const InternalUserBasicInfoEdit: React.FC<Props> = ({
  contentProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  navigateTo,
}) => {
  const intl = useIntl();

  const hrisSystemKey = get(contentProps, 'hrisSystem.key', undefined);

  const formInitialValues = {
    slackUserId: get(contentProps, 'slackUserId', ''),
    hrisSystem: {
      value: hrisSystemKey,
      label: HRIS_SYSTEMS[hrisSystemKey],
    },
    hrisSystemKey,
    hrisId: get(contentProps, 'hrisId', ''),
    additionalEmail: get(contentProps, 'additionalEmail', ''),
  };

  const userName = userFullNameOrEmail(contentProps);

  const updateUserFromAdminAction = UpdateUserFromAdmin();

  const handleSubmit = useSubmitAction({
    submitAction: updateUserFromAdminAction,
    submitVariables: (values: any) => ({
      userId: get(contentProps, 'id', undefined),
      slackUserId: values.slackUserId || null,
      hrisSystem: values.hrisSystem.value,
      hrisId: values.hrisId,
      additionalEmail: values.additionalEmail || null,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.userBasicInfo.form.successMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.userBasicInfo.form.failureMessage',
    }),
    onSuccess: response => {
      navigateTo({
        routeName: 'internal-user-details',
        routeProps: {
          defaultOpenSection: 'basicInfo',
          ...response.data.updateUser.user,
        },
      });
    },
  });

  return (
    <ModalContentContainer>
      <DetailsFormMainContainer>
        <DetailsHeader title={userName} />
        <GenericForm
          formInitialValues={formInitialValues}
          renderFormComponent={(renderProps: any) => (
            <InternalUserBasicInfoForm formikProps={renderProps.formikProps} />
          )}
          onSubmit={handleSubmit}
          formSchema={InternalUserBasicInfoFormSchema(intl)}
          setFormSubmitAction={setFormSubmitAction}
          setDisplayConfirmation={setDisplayConfirmation}
          setIsSubmitting={setIsSubmitting}
          dataQaId="internal-user-about-section-edit-form"
        />
      </DetailsFormMainContainer>
    </ModalContentContainer>
  );
};

export default InternalUserBasicInfoEdit;
