import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { City } from 'app/typings';
import { KeyValue } from 'app/typings/generics';
import { Venue } from 'app/typings/venues';
import { dateFormatter } from 'app/shared/utils/datetime';
import useModal from 'app/shared/utils/useModal';
import usePermission from 'app/shared/utils/usePermission';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import {
  Body2,
  Overline,
} from 'app/shared/components/atoms/TypographyManualCSS';
import ConfirmationModal from 'app/shared/components/molecules/ConfirmationModal';
import {
  ArchiveVenueApplication,
  CreateVenueFromVenueApplication,
} from 'app/admin/graphql/venueApplications/mutationHooks';
import { IconInfo, IconList } from 'app/admin/components/atoms/IconList';
import { KeyAboveValueInfoList } from 'app/admin/components/molecules/KeyAboveValueInfoList';
import ListingCard from 'app/admin/components/organisms/ListingCard';
import { ReactComponent as Archive } from 'icons/streamline-regular/content/archives/archive.svg';
import { ReactComponent as EmailActionUnread } from 'icons/streamline-regular/emails/email-actions/email-action-unread.svg';
import { ReactComponent as Check2 } from 'icons/streamline-regular/interface-essential/form-validation/check-2.svg';

interface VenueApplicationCardProps {
  id: number;
  index: number;
  firstName: string;
  lastName: string;
  address: string;
  emailAddress: string;
  phoneNumber: string;
  city: City;
  neighbourhood?: string;
  venueType: string;
  capacity: string;
  archived: boolean;
  venue?: Venue;
  showByob: boolean;
  showPet: boolean;
  accessible: boolean;
  isOutdoor: boolean;
  createdAt: string;
  refetchVenueApplications: VoidFunction;
}

const TopContentContainer = styled.div`
  height: 260px;
`;

const FacilitiesNoneContainer = styled.div`
  height: 35px;
`;

const FacilitiesNone = styled(Body2)`
  font-size: 12px !important;
  color: #111111;
  background: #f5f5f5;
`;

const VenueApplicationCard: React.FC<VenueApplicationCardProps> = ({
  id,
  index,
  firstName,
  lastName,
  address,
  emailAddress,
  phoneNumber,
  city,
  neighbourhood,
  venueType,
  capacity,
  archived,
  venue,
  showByob,
  showPet,
  accessible,
  isOutdoor,
  createdAt,
  refetchVenueApplications,
}) => {
  const intl = useIntl();

  const hasEditVenueApplicationPermission = usePermission(
    'venueApplication.edit'
  );
  const hasViewVenuesPermission = usePermission('venue.list.view');
  const [
    createVenueApplicationModal,
    toggleCreateVenueApplicationModal,
  ] = useModal();
  const [
    archiveVenueApplicationModal,
    toggleArchiveVenueApplicationModal,
  ] = useModal();

  const keysAboveValuesList = [
    {
      key: intl.formatMessage({
        id: 'admin.venueApplications.listingCard.city',
      }),
      value: city.title,
    },
    {
      key: intl.formatMessage({
        id: 'shared.neighborhood',
      }),
      value: neighbourhood || 'None',
    },
    {
      key: intl.formatMessage({
        id: 'shared.email',
      }),
      value: emailAddress || 'None',
    },
    {
      key: intl.formatMessage({
        id: 'admin.venueApplications.listingCard.phone',
      }),
      value: phoneNumber || 'None',
    },
  ];

  const keysAndValuesList = [
    {
      key: intl.formatMessage({
        id: 'admin.shared.venueType',
      }),
      value: venueType || 'None',
    },
    {
      key: intl.formatMessage({
        id: 'shared.capacity',
      }),
      value: capacity,
    },
    {
      key: intl.formatMessage({
        id: 'shared.status',
      }),
      value: !archived
        ? intl.formatMessage({
            id: 'admin.shared.review',
          })
        : venue
        ? intl.formatMessage({
            id: 'admin.venueApplications.listingCard.statusApproved',
          })
        : intl.formatMessage({
            id: 'admin.venueApplications.listingCard.statusArchived',
          }),
      url:
        archived && venue && hasViewVenuesPermission
          ? `/admin/venues?venue_id=${venue.id}`
          : undefined,
      valueForUrl: archived && venue ? 'Venue' : undefined,
    },
    {
      key: intl.formatMessage({
        id: 'admin.shared.submitted',
      }),
      value: createdAt
        ? dateFormatter(createdAt, 'shortMonthDateAndYear')
        : 'None',
    },
  ];

  const iconList: IconInfo[] = [];
  if (isOutdoor) {
    iconList.push({
      iconName: 'tree',
      tipText: intl.formatMessage({
        id: 'admin.venueApplications.listingCard.outdoors',
      }),
    });
  }
  if (accessible) {
    iconList.push({
      iconName: 'disabled',
      tipText: intl.formatMessage({
        id: 'admin.venueApplications.listingCard.disabledAccess',
      }),
    });
  }
  if (showByob) {
    iconList.push({
      iconName: 'byob',
      tipText: intl.formatMessage({
        id: 'admin.venueApplications.listingCard.byob',
      }),
    });
  }
  if (showPet) {
    iconList.push({
      iconName: 'pet',
      tipText: intl.formatMessage({
        id: 'admin.venueApplications.listingCard.petPresent',
      }),
    });
  }

  const controlsInfoList = [
    {
      href: emailAddress ? `mailto: ${emailAddress || ''}` : '',
      icon: EmailActionUnread,
      tipText: intl.formatMessage({
        id: 'admin.venueApplications.listingCard.emailApplicant',
      }),
    },
    {
      action:
        archived || !hasEditVenueApplicationPermission
          ? undefined
          : toggleArchiveVenueApplicationModal,
      icon: Archive,
      tipText: intl.formatMessage({
        id: 'admin.venueApplications.listingCard.archiveApplication',
      }),
    },
    {
      action:
        archived || !hasEditVenueApplicationPermission
          ? undefined
          : toggleCreateVenueApplicationModal,
      icon: Check2,
      tipText: intl.formatMessage({
        id: 'admin.venueApplications.listingCard.approveVenue',
      }),
    },
  ];

  const venueName = firstName + ' ' + lastName;

  const createVenueFromVenueApplicationAction = CreateVenueFromVenueApplication();

  const handleCreateVenueApplication = useSubmitAction({
    submitAction: createVenueFromVenueApplicationAction,
    submitVariables: () => ({ id }),
    successMsg: intl.formatMessage(
      {
        id: 'admin.venueApplications.listingCard.approveSuccessMessage',
      },
      { venueName }
    ),
    failureMsg: intl.formatMessage({
      id: 'admin.venueApplications.listingCard.approveFailureMessage',
    }),
    onSuccess: () => {
      createVenueApplicationModal.hide();
      refetchVenueApplications();
    },
  });

  const archiveVenueApplicationAction = ArchiveVenueApplication();

  const handleArchiveVenueApplication = useSubmitAction({
    submitAction: archiveVenueApplicationAction,
    submitVariables: () => ({ id }),
    successMsg: intl.formatMessage(
      {
        id: 'admin.venueApplications.listingCard.archiveSuccessMessage',
      },
      { venueName }
    ),
    failureMsg: intl.formatMessage({
      id: 'admin.venueApplications.listingCard.archiveFailureMessage',
    }),
    onSuccess: () => {
      archiveVenueApplicationModal.hide();
      refetchVenueApplications();
    },
  });

  const renderTopContent = (
    keysAboveValuesList: KeyValue[],
    iconList: IconInfo[]
  ) => {
    return (
      <TopContentContainer>
        <KeyAboveValueInfoList keysAndValues={keysAboveValuesList} />
        <Overline>
          {intl.formatMessage({
            id: 'admin.venueApplications.listingCard.facilities',
          })}
        </Overline>
        {iconList.length > 0 ? (
          <IconList iconList={iconList} />
        ) : (
          <FacilitiesNoneContainer>
            <FacilitiesNone>None</FacilitiesNone>
          </FacilitiesNoneContainer>
        )}
      </TopContentContainer>
    );
  };

  return (
    <>
      <ListingCard
        title={venueName}
        subtitle={address || 'None'}
        topContent={() => renderTopContent(keysAboveValuesList, iconList)}
        keysAndValuesList={keysAndValuesList}
        controlsInfoList={controlsInfoList}
        dataQaidPrefix="venue-applications"
        index={index}
        height="500px"
      />
      {createVenueApplicationModal.isShowing && (
        <ConfirmationModal
          onCancel={() => createVenueApplicationModal.hide()}
          description={intl.formatMessage(
            {
              id: 'admin.venueApplications.listingCard.approveConfirmation',
            },
            { venueName }
          )}
          onConfirm={handleCreateVenueApplication}
        />
      )}
      {archiveVenueApplicationModal.isShowing && (
        <ConfirmationModal
          onCancel={() => archiveVenueApplicationModal.hide()}
          description={intl.formatMessage(
            {
              id: 'admin.venueApplications.listingCard.archiveConfirmation',
            },
            { venueName }
          )}
          // @ts-ignore
          onConfirm={handleArchiveVenueApplication}
        />
      )}
    </>
  );
};

export default VenueApplicationCard;
