import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { CreateBusinessOwner } from 'app/admin/graphql/businessOwners/mutationHooks';
import { DetailsFormMainContainer } from 'app/admin/components/atoms/DetailContent';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

import BusinessOwnerEditForm from './BusinessOwnerEditForm';
import BusinessOwnerEditFormSchema from './BusinessOwnerEditFormSchema';

interface Props {
  contentProps?: any;
  setFormSubmitAction?: (func: Function) => void;
  setDisplayConfirmation?: (set: boolean) => void;
  setIsSubmitting?: (set: boolean) => void;
  hide: VoidFunction;
}

const BusinessOwnerCreate: React.FC<Props> = ({
  contentProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  hide,
}) => {
  const intl = useIntl();

  const [validationErrors, setValidationErrors] = useState<object>({});

  const formInitialValues = {
    title: '',
  };

  const createBusinessOwner = CreateBusinessOwner();

  const handleBusinessOwner = useSubmitAction({
    submitAction: createBusinessOwner,
    submitVariables: (values: any) => ({
      title: values.title,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.businessOwnerCreate.successMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.businessOwnerCreate.failureMessage',
    }),
    onSuccess: () => {
      contentProps.refetchBusinessOwners();
      hide();
    },
    onValidationError: validationErrors =>
      setValidationErrors(validationErrors),
  });

  return (
    <ModalContentContainer>
      <DetailsFormMainContainer>
        <DetailsHeader
          title={intl.formatMessage({
            id: 'admin.businessOwnerCreate.title',
          })}
          isNewForm={true}
        />
        <GenericForm
          formInitialValues={formInitialValues}
          renderFormComponent={(renderProps: any) => (
            <BusinessOwnerEditForm
              formikProps={renderProps.formikProps}
              validationErrors={validationErrors}
            />
          )}
          setFormSubmitAction={setFormSubmitAction}
          setDisplayConfirmation={setDisplayConfirmation}
          setIsSubmitting={setIsSubmitting}
          onSubmit={handleBusinessOwner}
          formSchema={BusinessOwnerEditFormSchema(intl)}
          dataQaId="business-owner-create-form"
        />
      </DetailsFormMainContainer>
    </ModalContentContainer>
  );
};

export default BusinessOwnerCreate;
