import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { get } from 'app/shared/utils/get';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Textfield } from 'app/shared/components/atoms/Textfield';
import FormGroup from 'app/shared/components/molecules/FormGroup';
import Select from 'app/shared/components/molecules/SelectManualCSS';
import { GetCancellationReasons } from 'app/admin/graphql/cancellationReasons/queryHooks';

interface onChangeArgs {
  cancellationReasonId?: number;
  cancellationReasonDescription?: string;
  otherReasonDescription?: string;
}

interface Props {
  cancellableType: string;
  otherReasonDescriptionPlaceholderText: string;
  validationError?: string;
  onChange: ({
    cancellationReasonId,
    cancellationReasonDescription,
    otherReasonDescription,
  }: onChangeArgs) => void;
}

interface Option {
  label: string;
  value: number;
  key: number;
}

const WarningMessage = styled.span`
  font-style: italic;
  font-size: 12px;
  display: block;
`;

// Make sure 'Other' option, if it exists, always appears LAST in list, by forcing to end of list with 'ZZZZZZ'
const sortCancellationReasonOptions = (options: Option[]) => {
  return options.sort((a: Option, b: Option) =>
    (a.label == 'Other' ? 'ZZZZZZ' : a.label) >
    (b.label == 'Other' ? 'ZZZZZZ' : b.label)
      ? 1
      : -1
  );
};

const getCancellationReasonOptions = (
  dataCancellationReasons: any | undefined
) => {
  let options = get(
    dataCancellationReasons,
    'cancellationReasons.cancellationReasons',
    []
  );
  options = options.map((cancellationReason: any) => ({
    label: cancellationReason.description,
    value: cancellationReason.id,
    key: cancellationReason.id,
  }));
  return sortCancellationReasonOptions(options);
};

interface OtherReasonDescriptionFieldProps {
  selectedReasonOption: Option | undefined;
  otherReasonDescriptionPlaceholderText: string;
  validationError?: string;
  onChange: ({
    cancellationReasonId,
    cancellationReasonDescription,
    otherReasonDescription,
  }: onChangeArgs) => void;
}

const OtherReasonDescriptionField: React.FC<OtherReasonDescriptionFieldProps> = ({
  selectedReasonOption,
  otherReasonDescriptionPlaceholderText,
  validationError,
  onChange,
}) => {
  const intl = useIntl();

  return (
    <>
      <Spacer mb={4} />
      <FormGroup
        data-qaid="cancellation-reason-form-other-text-field"
        label=""
        errorMsg={
          validationError == 'missing_other_reason_description'
            ? intl.formatMessage({
                id:
                  'admin.eventPlanner.settings.pleaseEnterACancellationReason',
              })
            : undefined
        }
      >
        <Textfield
          type="text"
          name="other-cancellation-reason-description"
          placeholder={otherReasonDescriptionPlaceholderText}
          onChange={(e: any) =>
            onChange({
              cancellationReasonId: selectedReasonOption?.value,
              cancellationReasonDescription: selectedReasonOption?.label,
              otherReasonDescription: e.currentTarget.value,
            })
          }
          maxLength={100}
        />
      </FormGroup>
    </>
  );
};

const CancellationReasonForm: React.FC<Props> = ({
  cancellableType,
  otherReasonDescriptionPlaceholderText,
  validationError,
  onChange,
}) => {
  const intl = useIntl();

  const [selectedReasonOption, setSelectedReasonOption] = useState<
    Option | undefined
  >(undefined);

  const {
    loading: loadingCancellationReasons,
    error: errorCancellationReasons,
    data: dataCancellationReasons,
  } = GetCancellationReasons({
    cancellableType,
    fetchPolicy: 'cache-and-network',
  });

  if (
    (!loadingCancellationReasons && !dataCancellationReasons) ||
    errorCancellationReasons
  ) {
    return (
      <>
        <WarningMessage>
          {intl.formatMessage(
            {
              id: 'shared.loadingError',
            },
            {
              whatsBeingLoaded: 'the list of cancellation reasons',
            }
          )}
        </WarningMessage>
        <Spacer mt={4} />
      </>
    );
  }

  const cancellationReasonOptions = getCancellationReasonOptions(
    dataCancellationReasons
  );

  return (
    <div data-qaid="cancellation-reason-form">
      <FormGroup
        data-qaid="cancellation-reason-form-group"
        label={intl.formatMessage({
          id: 'admin.eventPlanner.settings.cancellationReason',
        })}
        errorMsg={
          validationError == 'missing_cancellation_reason'
            ? intl.formatMessage({
                id:
                  'admin.eventPlanner.settings.pleaseSelectACancellationReason',
              })
            : undefined
        }
      >
        <Select
          data-qaid="cancellation-reason-select"
          id="cancellation-reason-select"
          name="cancellation-reason-select"
          getOptionLabel={(option: any) => option.label}
          options={cancellationReasonOptions}
          placeholder={intl.formatMessage({
            id: 'admin.eventPlanner.settings.selectAReason',
          })}
          onChange={option => {
            setSelectedReasonOption(option);
            onChange({
              cancellationReasonId: option?.value,
              cancellationReasonDescription: option?.label,
              otherReasonDescription: undefined,
            });
          }}
        />
      </FormGroup>
      {selectedReasonOption && selectedReasonOption.label === 'Other' && (
        <OtherReasonDescriptionField
          selectedReasonOption={selectedReasonOption}
          otherReasonDescriptionPlaceholderText={
            otherReasonDescriptionPlaceholderText
          }
          validationError={validationError}
          onChange={onChange}
        />
      )}
    </div>
  );
};

export default CancellationReasonForm;
