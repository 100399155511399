import React, { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { getIdsSubmitVariable } from 'app/shared/utils/form';
import { get } from 'app/shared/utils/get';
import useModal from 'app/shared/utils/useModal';
import usePermission from 'app/shared/utils/usePermission';
import { GetCities, GetCitiesLite } from 'app/shared/graphql/cities/queryHooks';
import { GetCountries } from 'app/shared/graphql/countries/queryHooks';
import { ListPage, ListPageContext } from 'app/shared/context/ListPage';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import { LoadingBlocks } from 'app/shared/components/atoms/LoadingBlocks';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import RoutableModal from 'app/shared/components/molecules/RoutableModal';
import { GlobalError } from 'app/shared/components/pages/Status';
import { CityInfoForLimitingByCity } from 'app/admin/utils/cityPermissions';
import {
  getBusinessOwnerOptions,
  getCityOptions,
  getCountryOptions,
  getFilterNamesAndOptionsForTitleMapping,
} from 'app/admin/utils/optionLists';
import { GetBusinessOwners } from 'app/admin/graphql/businessOwners/queryHooks';
import { CityTitles as CityTitlesLimitedBy } from 'app/admin/components/atoms/CityTitles';
import ListingFooter from 'app/admin/components/molecules/ListingFooter';
import ListingNoResults from 'app/admin/components/molecules/ListingNoResults';
import CardGrid, { CardType } from 'app/admin/components/organisms/CardGrid';
import CityCard from 'app/admin/components/organisms/CityCard';
import ListingControls from 'app/admin/components/organisms/ListingControls';
import ListingFilter from 'app/admin/components/organisms/ListingFilter';
import ListingHeader from 'app/admin/components/organisms/ListingHeader';
import ListPageTemplate from 'app/admin/components/templates/ListPage';
import Layout from 'app/admin/layouts/ListPage';

import {
  activeOptions,
  cityOperatingModelOptions,
  sofarOperatedMarketTypeOptions,
  sortOptions,
} from './options';
import Analytics from './tracking';

const pageStateConfig = {
  filterNames: [
    'city',
    'country',
    'city_operating_model',
    'sofar_operated_market_type',
    'business_owner',
    'active_status',
  ],
  defaultSort: { by: 'title', direction: 'asc' },
  textSearchParamName: 'city_search',
  idParamName: 'city_id',
};

const AdminCities: React.FC = () => {
  const intl = useIntl();
  const pageState = useContext(ListPageContext);
  const PER_PAGE = 18;

  const hasCreateCityPermission = usePermission('city.create');
  const hasAccessCitiesPermission = usePermission('city.list.access');

  const {
    loading: loadingCities,
    error: errorCities,
    data: dataCities,
  } = GetCitiesLite({
    activeStatus: 'all',
  });

  const {
    loading: loadingCountries,
    error: errorCountries,
    data: dataCountries,
  } = GetCountries({
    skipPagination: true,
  });

  const {
    loading: loadingBusinessOwners,
    error: errorBusinessOwners,
    data: dataBusinessOwners,
  } = GetBusinessOwners({
    archivedStatus: 'not_archived',
    skipPagination: true,
  });

  const { cityIdsToLimitBy, cityTitlesToLimitBy } = CityInfoForLimitingByCity(
    'city.list.viewByCity',
    dataCities
  );

  const cityOptions = getCityOptions(dataCities);
  const countryOptions = getCountryOptions(dataCountries);
  const businessOwnerOptions = getBusinessOwnerOptions(dataBusinessOwners);

  const filterDropdownOptionsInfoList = !hasAccessCitiesPermission
    ? []
    : [
        {
          filterName: 'city',
          dropdownParams: {
            searchBar: true,
            title: intl.formatMessage({
              id: 'admin.cities.filter.city',
            }),
            groupBy: 'country',
            options: cityOptions,
          },
        },
        {
          filterName: 'country',
          dropdownParams: {
            searchBar: true,
            title: intl.formatMessage({
              id: 'admin.cities.filter.country',
            }),
            options: countryOptions,
          },
        },
        {
          filterName: 'city_operating_model',
          dropdownParams: {
            title: intl.formatMessage({
              id: 'admin.cities.filter.cityOperatingModel',
            }),
            options: cityOperatingModelOptions.options,
          },
        },
        {
          filterName: 'sofar_operated_market_type',
          dropdownParams: {
            title: intl.formatMessage({
              id: 'admin.cities.filter.sofarOperatedMarketType',
            }),
            options: sofarOperatedMarketTypeOptions.options,
          },
        },
        {
          filterName: 'business_owner',
          dropdownParams: {
            searchBar: true,
            title: intl.formatMessage({
              id: 'shared.businessOwners',
            }),
            options: businessOwnerOptions,
          },
        },
        {
          filterName: 'active_status',
          dropdownParams: {
            title: intl.formatMessage({
              id: 'admin.cities.filter.activeStatus',
            }),
            options: activeOptions.options,
          },
        },
      ];

  const [addCityModal, toggleAddCityModal] = useModal();

  const { loading, error, data } = GetCities({
    // If user doesn't have hasAccessCitiesPermission AND we haven't loaded cityIdsToLimitBy yet,
    // default citySearch to arbitrarily random long string which guarantees no
    // city exists for that search, which ensures no cities are returned from backend,
    // which ensures page displays no cities
    //
    // TODO: Long-term, use useLazyQuery here to run the query only when we need to (after we know
    // which permissions the viewing user has)
    citySearch:
      !hasAccessCitiesPermission && !cityIdsToLimitBy
        ? 'gjiewohbxkkwempnwegpksgwepczpqx'
        : pageState.textSearchState,
    country: !hasAccessCitiesPermission
      ? undefined
      : pageState.filterListVariable('country'),
    businessOwner: !hasAccessCitiesPermission
      ? undefined
      : pageState.filterListVariable('business_owner'),
    city: !cityIdsToLimitBy ? pageState.filterListVariable('city') : undefined,
    cityIds: getIdsSubmitVariable(cityIdsToLimitBy),
    cityOperatingModel: !hasAccessCitiesPermission
      ? undefined
      : pageState.filterListVariable('city_operating_model') || undefined,
    sofarOperatedMarketType: !hasAccessCitiesPermission
      ? undefined
      : pageState.filterListVariable('sofar_operated_market_type') || undefined,
    activeStatus: !hasAccessCitiesPermission
      ? undefined
      : pageState.filterListVariable('active_status') || undefined,
    orderBy: pageState.sortState.by,
    orderDirection: pageState.sortState.direction,
    page: pageState.page,
    perPage: PER_PAGE,
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    pageState.updateDetailsModal();
  }, []);

  useEffect(() => {
    pageState.updateScrollPositionOnPage();
  }, [pageState.detailsModal.isShowing]);

  useEffect(() => {
    pageState.setupFilterLabelTitleMapping(
      getFilterNamesAndOptionsForTitleMapping(filterDropdownOptionsInfoList)
    );
  }, [dataCities, dataCountries, dataBusinessOwners]);

  useEffect(() => {
    pageState.updatePageUrl();
  }, [
    pageState.page,
    pageState.sortState,
    pageState.filterState,
    pageState.textSearchState,
    pageState.detailData,
    pageState.detailsModal.isShowing,
    pageState.viewModeState,
  ]);

  if (
    (!loading && !data) ||
    error ||
    (!loadingCities && !dataCities) ||
    errorCities ||
    (!loadingCountries && !dataCountries) ||
    errorCountries ||
    (!loadingBusinessOwners && !dataBusinessOwners) ||
    errorBusinessOwners
  ) {
    return <GlobalError />;
  }

  const modalsContent = () => (
    <>
      {pageState.detailsModal.isShowing && (
        <RoutableModal
          hide={pageState.detailsModal.hide}
          initialRouteProps={pageState.detailData}
          initialRouteName="city-details"
          dataQaidSuffix="admin-edit-city-details"
        />
      )}
      {addCityModal.isShowing && (
        <RoutableModal
          hide={addCityModal.hide}
          initialRouteProps={{}}
          initialRouteName="city-create"
          dataQaidSuffix="admin-create-city"
        />
      )}
    </>
  );

  return (
    <Layout scrollDisabled={pageState.detailsModal.isShowing}>
      <ListPageTemplate
        pageTitle={intl.formatMessage({
          id: 'admin.cities.pageTitle',
        })}
        modalsContent={modalsContent}
      >
        <ListingHeader
          pageTitle={intl.formatMessage({
            id: 'admin.cities.pageTitle',
          })}
          addEntityText={
            hasCreateCityPermission
              ? intl.formatMessage({
                  id: 'admin.cities.addCity',
                })
              : undefined
          }
          onClickAddEntity={
            hasCreateCityPermission ? toggleAddCityModal : undefined
          }
          searchPlaceholder={intl.formatMessage({
            id: 'admin.cities.textSearch.placeholder',
          })}
          searchValue={pageState.textSearchState}
          onSearch={
            !hasAccessCitiesPermission ? undefined : pageState.handleTextSearch
          }
          dataQaidPrefix="city"
        />
        <CityTitlesLimitedBy cityTitles={cityTitlesToLimitBy} />
        <div>
          <ListingControls
            sortOptions={sortOptions}
            orderBy={pageState.sortState.by}
            orderDirection={pageState.sortState.direction}
            totalRecords={data && data.cities.metadata.totalRecords}
            loading={loading}
            onSort={pageState.handleSortChange}
            onReset={pageState.handleResetFilters}
            dataQaidPrefix="city"
          />
          {hasAccessCitiesPermission && (
            <ListingFilter
              filterTitle={intl.formatMessage({
                id: 'admin.cities.filterTitle',
              })}
              textSearchString={pageState.textSearchState}
              handleTextSearchLabelClose={pageState.handleTextSearchLabelClose}
              labelTitleMapping={pageState.filterLabelTitleMapping}
              dropdownOptionsInfoList={filterDropdownOptionsInfoList}
              filterState={pageState.filterState}
              handleRemoveFilter={pageState.handleRemoveFilter}
              handleFilterChange={pageState.handleFilterChange}
            />
          )}
        </div>

        <div>
          <CardGrid
            objectData={get(data, 'cities.cities', [])}
            renderCardComponent={(city: CardType, i: number) => (
              // @ts-ignore
              <CityCard
                index={i}
                onShowDetails={pageState.toggleDetailsModalAndSetDetailData}
                {...city}
              />
            )}
            dataQaid="admin-cities-list"
            loading={loading}
            loadingComponent={
              <LoadingBlocks.Rectangle width="100%" height="420px" />
            }
            hideDividerOnSize="xs"
          />
          <DottedLine />
          <Spacer mb={2} />
        </div>

        <ListingNoResults
          entityName={intl.formatMessage({
            id: 'shared.city',
          })}
          numResults={get(data, 'cities.cities.length', undefined)}
          loading={loading}
        />

        <ListingFooter
          numTotalRecords={get(data, 'cities.metadata.totalRecords', undefined)}
          perPage={PER_PAGE}
          currentPage={pageState.page}
          onPageChange={pageState.handlePageChange}
          loading={loading}
          dataQaidPrefix="cities"
        />
      </ListPageTemplate>
    </Layout>
  );
};

const AdminCitiesWrapper: React.FC = () => (
  <ListPage config={pageStateConfig} analytics={Analytics}>
    <AdminCities />
  </ListPage>
);

export default AdminCitiesWrapper;
