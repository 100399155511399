import styled, { css } from 'styled-components';

import { Overline } from 'app/shared/components/atoms/TypographyManualCSS';

export default styled(Overline)<any>`
  ${({ theme, invertColor, showTopAccent }) => css`
    padding: ${theme.ruler[1]}px ${theme.ruler[4]}px;
    background: ${invertColor ? 'transparent' : theme.colors.whiteDenim};
    position: sticky;
    display: block;
    top: 0;
    font-size: 14px;

    color: ${theme.colors.secondaryText};
    ${showTopAccent &&
      theme.isFanFacing &&
      `border-top: solid 6px ${theme.colors.primary};`}
  `}
`;
