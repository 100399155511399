import styled, { css } from 'styled-components';

import { H2, H3 } from 'app/shared/components/atoms/TypographyManualCSS';

interface TitleProps {
  displayCalendar?: boolean;
  nowrap?: boolean;
}

interface SubTitleProps {
  displayCalendar?: boolean;
}

export const Title = styled(H2)<TitleProps>`
  ${({ displayCalendar, nowrap, theme }) => css`
    font-weight: normal;
    margin-bottom: 6px;
    margin-left: ${displayCalendar ? '-10px' : '0px'};
    line-height: 1.2em;
    font-size: 16px;
    white-space: ${nowrap ? 'nowrap' : 'normal'};
    ${theme.media.lg`
      font-size: 34px;
      margin-bottom: 15px;
      margin-left: ${displayCalendar ? '-20px' : '0px'};
    `};
    ${theme.media.xl`
      font-size: 34px;
      margin-bottom: 15px;
      margin-left: ${displayCalendar ? '-20px' : '0px'};
    `};
  `}}
`;

export const SubTitle = styled(H3)<SubTitleProps>`
  ${({ displayCalendar, theme }) => css`
    font-weight: normal;
    margin-bottom: 10px;
    margin-left: ${displayCalendar ? '-10px' : '0px'};
    line-height: 1.2em;
    font-size: 12px;
    ${theme.media.lg`
      font-size: 16px;
      margin-bottom: 15px;
      margin-left: ${displayCalendar ? '-20px' : '0px'};
    `};
    ${theme.media.xl`
      font-size: 16px;
      margin-bottom: 15px;
      margin-left: ${displayCalendar ? '-20px' : '0px'};
    `};
  `}}
`;
