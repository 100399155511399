export const sortOptions = [
  {
    title: 'Newest',
    value: {
      by: 'created_at',
      direction: 'desc',
    },
  },
  {
    title: 'Oldest',
    value: {
      by: 'created_at',
      direction: 'asc',
    },
  },
  {
    title: 'Recipient email (A - Z)',
    value: {
      by: 'recipient',
      direction: 'asc',
    },
  },
  {
    title: 'Recipient email (Z - A)',
    value: {
      by: 'recipient',
      direction: 'desc',
    },
  },
];

export const dateOptions = {
  date: [
    {
      title: 'Sent Today (UTC)',
      value: 'created_today',
    },
    {
      title: 'Sent Last 7 Days',
      value: 'created_last_7_days',
    },
    {
      title: 'Sent Last 30 Days',
      value: 'created_last_30_days',
    },
    {
      title: 'Sent Last 90 Days',
      value: 'created_last_90_days',
    },
  ],
};
