import React from 'react';
import { useIntl } from 'react-intl';

import { buildEventCollectionUrl } from 'app/shared/utils/eventCollections';
import { capitalize } from 'app/shared/utils/string';
import ListingCard from 'app/admin/components/organisms/ListingCard';
import { ReactComponent as View1 } from 'icons/streamline-regular/interface-essential/view/view-1.svg';

interface EventCollectionCardProps {
  id: number;
  index: number;
  title: string;
  cachedSlug: string;
  citiesCount: number;
  status: string;
  eventsCount: number;
  collectionType: string;
  onShowDetails: Function;
  upcomingEventsCount: number;
}

const EventCollectionCard: React.FC<EventCollectionCardProps> = ({
  id,
  index,
  title,
  cachedSlug,
  citiesCount,
  eventsCount,
  status,
  onShowDetails,
  upcomingEventsCount,
}) => {
  const intl = useIntl();

  const keysAndValuesList = [
    {
      key: intl.formatMessage({
        id: 'admin.users.listingCard.totalCities',
      }),
      value: citiesCount.toString(),
    },
    {
      key: intl.formatMessage({
        id: 'admin.users.listingCard.totalConcerts',
      }),
      value: eventsCount.toString(),
    },
    {
      key: intl.formatMessage({
        id: 'admin.users.listingCard.totalUpcomingConcerts',
      }),
      value: upcomingEventsCount.toString(),
    },
    {
      key: intl.formatMessage({
        id: 'admin.users.listingCard.status',
      }),
      value: capitalize(status || ''),
    },
  ];

  const onShowDetailsParams = {
    id,
    title,
  };

  const controlsInfoList = [
    {
      href: buildEventCollectionUrl({ collectionSlug: cachedSlug }),
      icon: View1,
      tipText: intl.formatMessage({
        id: 'admin.users.listingCard.seeGlobalCollection',
      }),
    },
  ];

  return (
    <>
      <ListingCard
        title={title}
        titleTruncateCharLength={32}
        keysAndValuesList={keysAndValuesList}
        controlsInfoList={controlsInfoList}
        onShowDetails={onShowDetails}
        onShowDetailsParams={onShowDetailsParams}
        showDetailsTipText={intl.formatMessage({
          id: 'admin.eventCollections.listingCard.collectionDetails',
        })}
        dataQaidPrefix="collections"
        index={index}
        height="210px"
      />
    </>
  );
};

export default EventCollectionCard;
