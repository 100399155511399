import React from 'react';
import { useIntl } from 'react-intl';

import { errorMsgForField } from 'app/shared/utils/form';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import FormGroup from 'app/shared/components/molecules/FormGroup';
import { BigNumberTextfield } from 'app/admin/components/molecules/Textfield';

interface Prop {
  fieldName: string;
  currencySymbol: string;
  price: number;
  priceHelperText?: JSX.Element;
  // The following 3 properties have type of any since they are from formikProps
  touched: any;
  errors: any;
  setFieldValue: any;
}

const TicketPriceFormField: React.FC<Prop> = ({
  fieldName,
  currencySymbol,
  price,
  touched,
  errors,
  setFieldValue,
  priceHelperText,
}) => {
  const intl = useIntl();

  return (
    <FormGroup
      data-qaid="ticket-price-formgroup"
      label={intl.formatMessage(
        {
          id: 'admin.shared.ticketPriceWithCurrencySymbol',
        },
        {
          currencySymbol,
        }
      )}
      errorMsg={errorMsgForField(fieldName, touched, errors)}
    >
      <Grid>
        <Col lg={1} md={2} sm={3} xs={3}>
          <BigNumberTextfield
            data-qaid="ticket-price-field"
            id={fieldName}
            name={fieldName}
            value={price}
            onChange={(e: any) => {
              setFieldValue(fieldName, e.target.value);
            }}
            maxLength={8}
          />
        </Col>
        {priceHelperText && (
          <Col lg={11} md={10} sm={9} xs={9}>
            {priceHelperText}
          </Col>
        )}
      </Grid>
    </FormGroup>
  );
};

export default TicketPriceFormField;
