import React, { useContext, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { Comment } from 'app/typings/comments';
import { dateFormatter } from 'app/shared/utils/datetime';
import { get } from 'app/shared/utils/get';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import { AuthContext } from 'app/shared/context/Auth';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import {
  ContentWrapper,
  HeaderWrapper,
} from 'app/shared/components/atoms/FlyoverContent';
import { ArrowLeftIcon } from 'app/shared/components/atoms/IconLibrary';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Spinner } from 'app/shared/components/atoms/SpinnerManualCSS';
import { Overline } from 'app/shared/components/atoms/TypographyManualCSS';
import { userFullNameOrEmail } from 'app/admin/utils/users';
import { CreateComment } from 'app/admin/graphql/comments/mutationHooks';
import { GetComments } from 'app/admin/graphql/comments/queryHooks';
import AddIconAndText from 'app/admin/components/atoms/AddIconAndText';
import FlyoverFooter from 'app/admin/components/molecules/FlyoverFooter';
import RichTextEditor from 'app/admin/components/molecules/RichTextEditor';

interface Props {
  eventId: number;
  onUpdateComments: Function;
}

const BackLinkContainer = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.green600};
    font-size: 12px;
    display: flex;
    flex-direction: row;
    cursor: pointer;
  `}
`;

const BackLink = styled.div`
  margin-right: 12px;
`;

const BackText = styled.div`
  ${({ theme }) => css`
    font-size: 10px;
    color: ${theme.colors.green600};
    line-height: 150%;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    margin-top: 2px;
    margin-right: 5px;
  `}
`;

const CenteredSpinner = styled(Spinner)`
  margin-left: 45%;
`;

const CommentWrapper = styled.div`
  ${({ theme }) => css`
    font-size: 13px;
    a {
      color: ${theme.colors.green600};
    }
  `}
`;

interface CommentsHeaderProps {
  intl: IntlShape;
  actionState: string;
}

const CommentsHeader = (props: CommentsHeaderProps) => {
  const { intl, actionState } = props;

  return (
    <HeaderWrapper>
      <Overline>
        {intl.formatMessage({
          id: `admin.eventPlanner.comments.${actionState}`,
        })}
      </Overline>
    </HeaderWrapper>
  );
};

interface CommentsCardProps {
  intl: IntlShape;
  eventId: number;
  commentsData: Comment[];
  actionState: string;
  setActionState: Function;
  onUpdateComments: Function;
  refetchComments: Function;
}

const CommentsCard = (props: CommentsCardProps) => {
  const {
    intl,
    actionState,
    commentsData,
    setActionState,
    eventId,
    onUpdateComments,
    refetchComments,
  } = props;

  switch (actionState) {
    case 'comments':
      return (
        <Comments
          intl={intl}
          setActionState={setActionState}
          commentsData={commentsData}
        />
      );
    case 'addComment':
      return (
        <AddComment
          intl={intl}
          eventId={eventId}
          setActionState={setActionState}
          onUpdateComments={onUpdateComments}
          refetchComments={refetchComments}
        />
      );
    default:
      return (
        <Comments
          intl={intl}
          setActionState={setActionState}
          commentsData={commentsData}
        />
      );
  }
};

const renderComments = (comments: Comment[]) =>
  comments.map(comment => (
    <React.Fragment key={comment.id}>
      <DottedLine />
      <Overline>{userFullNameOrEmail(comment.user)}</Overline>
      <br />
      <Overline>
        {dateFormatter(
          comment.updatedAt,
          'longWeekdayDateWithOrdinalFullMonthHoursMinutesWithAMPM'
        )}
      </Overline>
      <CommentWrapper dangerouslySetInnerHTML={{ __html: comment.body }} />
    </React.Fragment>
  ));

interface CommentsProps {
  intl: IntlShape;
  setActionState: Function;
  commentsData: Comment[];
}

const Comments = (commentsProps: CommentsProps) => {
  const { intl, setActionState, commentsData } = commentsProps;
  return (
    <ContentWrapper>
      <AddIconAndText
        text={intl.formatMessage({
          id: 'admin.eventPlanner.comments.addComment',
        })}
        textSizeType="small"
        onClick={() => setActionState('addComment')}
        dataQaid="add-comment-link"
      />
      {renderComments(commentsData)}
    </ContentWrapper>
  );
};

interface AddCommentProps {
  intl: IntlShape;
  eventId: number;
  setActionState: Function;
  onUpdateComments: Function;
  refetchComments: Function;
}

const AddComment = (props: AddCommentProps) => {
  const {
    intl,
    eventId,
    setActionState,
    onUpdateComments,
    refetchComments,
  } = props;
  const { user } = useContext(AuthContext);
  const [commentBody, setCommentBody] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const createCommentAction = CreateComment();

  const handleAddComment = useSubmitAction({
    submitAction: createCommentAction,
    submitVariables: () => ({
      body: commentBody,
      commentableId: eventId,
      commentableType: 'Event',
    }),
    successMsg: intl.formatMessage({
      id: 'admin.eventPlanner.comments.addCommentSuccess',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.eventPlanner.comments.addCommentFailure',
    }),
    onSuccess: (response: any) => {
      setIsSubmitting(false);
      setCommentBody('');
      refetchComments();
      setActionState('comments');
      onUpdateComments &&
        onUpdateComments(response?.data?.createComment?.commentsCount || 0);
    },
  });

  if (isSubmitting) {
    <CommentsSpinner />;
  }

  return (
    <>
      <ContentWrapper>
        <BackLinkContainer
          data-qaid="add-comment-back-link"
          onClick={() => setActionState('comments')}
        >
          <BackLink>
            <ArrowLeftIcon />
          </BackLink>
          <BackText>
            {intl.formatMessage({
              id: 'form.back',
            })}
          </BackText>
        </BackLinkContainer>
        <DottedLine />
        <Spacer mt={2} />
        {user && (
          <>
            <Overline>
              {user.firstName} {user.lastName}
            </Overline>
            <br />
          </>
        )}
        <Overline>
          {dateFormatter(
            new Date(),
            'longWeekdayDateWithOrdinalFullMonthHoursMinutesWithAMPM'
          )}
        </Overline>
        <Spacer mt={2} />
        <RichTextEditor
          onChange={value => {
            setCommentBody(value);
          }}
          disabled={false}
          name="comment-editor"
          currentValue={commentBody}
          maxHeight={200}
        />
      </ContentWrapper>
      <FlyoverFooter
        buttonText={intl.formatMessage({
          id: 'admin.eventPlanner.artists.update',
        })}
        buttonDisabled={!commentBody}
        onClickButton={() => {
          setIsSubmitting(true);
          handleAddComment();
        }}
        dataQaid="add-comment-submit-button"
      />
    </>
  );
};

const CommentsSpinner = () => (
  <ContentWrapper>
    <CenteredSpinner size="30px" />
  </ContentWrapper>
);

const EventPlannerCommentsFlyover: React.FC<Props> = ({
  eventId,
  onUpdateComments,
}) => {
  const intl = useIntl();
  const [actionState, setActionState] = useState('comments');

  const {
    data: dataComments,
    loading: loadingComments,
    error: errorComments,
    refetch: refetchComments,
  } = GetComments({
    page: 1,
    perPage: 3,
    commentableId: eventId,
    commentableType: 'Event',
    orderDirection: 'desc',
    orderBy: 'updated_at',
  });
  const commentsData = get(dataComments, 'comments.comments', []);

  if (errorComments) {
    return (
      <>
        <Spacer mt={2} />
        <p>
          {intl.formatMessage({
            id: 'admin.eventPlanner.comments.getCommentsFailure',
          })}
        </p>
      </>
    );
  }

  return (
    <div data-qaid="comments-flyover">
      <CommentsHeader intl={intl} actionState={actionState} />
      <Spacer mt={1} />
      {loadingComments ? (
        <CommentsSpinner />
      ) : (
        <CommentsCard
          commentsData={commentsData}
          actionState={actionState}
          setActionState={setActionState}
          intl={intl}
          eventId={eventId}
          onUpdateComments={onUpdateComments}
          refetchComments={refetchComments}
        />
      )}
    </div>
  );
};

export default EventPlannerCommentsFlyover;
