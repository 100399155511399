import * as Yup from 'yup';

const broadcastEditFormSchema = (intl: any) =>
  Yup.object().shape({
    startDate: Yup.string()
      .required(
        intl.formatMessage({
          id: 'form.requiredField',
        })
      )
      .typeError(
        intl.formatMessage({
          id: 'form.requiredField',
        })
      ),
    startTime: Yup.string()
      .required(
        intl.formatMessage({
          id: 'form.requiredField',
        })
      )
      .typeError(
        intl.formatMessage({
          id: 'form.requiredField',
        })
      ),
    artistId: Yup.number()
      .required(
        intl.formatMessage({
          id: 'form.requiredField',
        })
      )
      .typeError(
        intl.formatMessage({
          id: 'form.requiredField',
        })
      ),
    location: Yup.string().required(
      intl.formatMessage({
        id: 'form.requiredField',
      })
    ),
    streamType: Yup.string().required(
      intl.formatMessage({
        id: 'form.requiredField',
      })
    ),
    embedUrl: Yup.string().required(
      intl.formatMessage({
        id: 'form.requiredField',
      })
    ),
    linkType: Yup.string().required(
      intl.formatMessage({
        id: 'form.requiredField',
      })
    ),
    donationText: Yup.string().required(
      intl.formatMessage({
        id: 'form.requiredField',
      })
    ),
  });

export default broadcastEditFormSchema;
