import * as Yup from 'yup';

import { validateUrlNoHttp } from 'app/shared/utils/urls';

const competitionContentEditFormSchema = (intl: any) =>
  Yup.object().shape({
    thirdPartyCtaLink: Yup.string()
      .nullable()
      .when('thirdPartyCtaText', {
        is: value => !!value,
        then: Yup.string()
          .nullable()
          .required(
            intl.formatMessage({
              id: 'form.requiredField',
            })
          )
          .test(
            'isUrl',
            intl.formatMessage({
              id: 'admin.competitionContent.form.linkValidationNoHttp',
            }),
            function(value) {
              return value ? validateUrlNoHttp(value) : true;
            }
          ),
      }),
    featuredContentCtaLink: Yup.string()
      .nullable()
      .when('featuredContentCtaText', {
        is: value => !!value,
        then: Yup.string()
          .nullable()
          .required(
            intl.formatMessage({
              id: 'form.requiredField',
            })
          )
          .test(
            'isUrl',
            intl.formatMessage({
              id: 'admin.competitionContent.form.linkValidationNoHttp',
            }),
            function(value) {
              return value ? validateUrlNoHttp(value) : true;
            }
          ),
        else: Yup.string().nullable(),
      }),
  });

export default competitionContentEditFormSchema;
