import React from 'react';
import styled, { css } from 'styled-components';

import { Feedback, FeedbackPiece, OverallFeeling } from 'app/typings/feedbacks';
import { dateFormatter } from 'app/shared/utils/datetime';
import { Icon } from 'app/shared/components/atoms/IconManualCSS';
import { BaseLink } from 'app/shared/components/atoms/LinkManualCSS';
import { TagElement } from 'app/admin/components/molecules/TagElementList';

interface Props {
  feedback: Feedback;
  feedbackDisplayType?: string;
  onClickDeleteFeedback: Function;
}

const FeedbackContainer = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
`;

const FeedbackDetails = styled.div`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.4px;
  margin-bottom: 2px;
`;

const FeedbackCityWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const FeedbackCityLinkWrapper = styled.div``;

const FeedbackCityLink = styled(BaseLink)`
  color: #10ad52;
`;

const TrashCanIconWrapper = styled.div`
  margin-right: 8px;
  cursor: pointer;
`;

const TrashCanIcon = styled(Icon)`
  ${({ theme }) => css`
    &::before {
      color: ${theme.colors.green600};
    }
  `}
`;

const TagElementsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 5px;
`;

const feedbackableRelatedData = (feedback: Feedback, key: string) =>
  // @ts-ignore
  feedback.feedbackableRelatedData.filter(obj => obj.key === key)[0].value;

const feedbackEventUrl = (feedback: Feedback) => {
  const eventId = feedbackableRelatedData(feedback, 'eventId');
  if (eventId) {
    return '/admin/concert-planner?event_id=' + eventId;
  }

  return null;
};

const renderTagElements = (tagElements: any[]) => {
  return tagElements.map((tagElement: any, i: number) => (
    <TagElement
      key={i}
      tagElementColor={tagElement.tagElementColor}
      textColor="#000000"
      data-qaid={`tag-element-${i}`}
    >
      {tagElement.name}
    </TagElement>
  ));
};

const tagElementsForFeedback = (feedback: Feedback) => {
  const tagElements = feedback.feedbackPieces.map(
    (feedbackPiece: FeedbackPiece) => ({
      name: feedbackPiece.feedbackPhrase.phrase,
      tagElementColor: feedbackPiece.feedbackPhrase.isPositive
        ? 'rgba(139, 195, 75, 0.7)'
        : '#FFD426',
    })
  );
  if (feedback.overallFeeling === OverallFeeling.NEGATIVE) {
    tagElements.push({
      name: 'Would Not Book Again',
      tagElementColor: 'rgba(255, 80, 0, 0.75)',
    });
  }
  return tagElements;
};

const ArtistFeedbackSummary: React.FC<Props> = ({
  feedback,
  feedbackDisplayType,
  onClickDeleteFeedback,
}) => {
  return (
    <FeedbackContainer>
      <FeedbackDetails>
        <FeedbackCityWrapper>
          {feedbackDisplayType === 'User' && (
            <TrashCanIconWrapper
              onClick={() =>
                onClickDeleteFeedback({
                  id: feedback.id,
                  feedbackableAt: feedback.feedbackableAt,
                })
              }
            >
              <TrashCanIcon name="trashCan" size="16px" />
            </TrashCanIconWrapper>
          )}
          <FeedbackCityLinkWrapper>
            <FeedbackCityLink
              href={feedbackEventUrl(feedback) || ''}
              openInNewTab={true}
            >
              {feedbackableRelatedData(feedback, 'cityTitle')}
            </FeedbackCityLink>
          </FeedbackCityLinkWrapper>
        </FeedbackCityWrapper>
      </FeedbackDetails>
      <FeedbackDetails>
        {dateFormatter(feedback.feedbackableAt, 'shortMonthDateAndYear')}
      </FeedbackDetails>
      <TagElementsContainer>
        {renderTagElements(tagElementsForFeedback(feedback))}
      </TagElementsContainer>
    </FeedbackContainer>
  );
};

export default ArtistFeedbackSummary;
