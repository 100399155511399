export const sortOptions = [
  {
    title: 'Creation Date (Newest to Oldest)',
    value: {
      by: 'created_at',
      direction: 'desc',
    },
  },
  {
    title: 'Creation Date (Oldest to Newest)',
    value: {
      by: 'created_at',
      direction: 'asc',
    },
  },
  {
    title: 'Concert Date (Newest to Oldest)',
    value: {
      by: 'earliest_event_date',
      direction: 'desc',
    },
  },
  {
    title: 'Concert Date (Oldest to Newest)',
    value: {
      by: 'earliest_event_date',
      direction: 'asc',
    },
  },
];
