import { useContext } from 'react';
import { useIntl } from 'react-intl';

import { AdminNavChildMenu, AdminNavParentMenu } from 'app/typings';
import { addAdminNavMenuItemPermissions } from 'app/shared/utils/navbar';
import { adminNavConfig } from 'app/shared/utils/navbar';
import { AuthContext } from 'app/shared/context/Auth';
import { PermissionsContext } from 'app/shared/context/Permissions';

export const useAdminNavConfig = () => {
  const intl = useIntl();
  const authContext = useContext(AuthContext);

  const afterSignOut = () => {
    window.location.reload();
  };

  const rawAdminNavConfig = adminNavConfig({
    intl,
    signOut: authContext.signOut,
    afterSignOut,
  });

  return addAdminNavMenuItemPermissions(rawAdminNavConfig);
};

export const isItemViewable = (
  itemConfig: AdminNavChildMenu,
  permissionsContext: any,
  featureFlags: object
) => {
  return (
    (!itemConfig.permission ||
      permissionsContext.hasPermission(itemConfig.permission)) &&
    (!itemConfig.featureFlag || featureFlags[itemConfig.featureFlag])
  );
};

const hasViewableMenuItems = (
  parentMenuItem: AdminNavParentMenu,
  permissionsContext: any,
  featureFlags: object
) =>
  !parentMenuItem.childMenu ||
  parentMenuItem.childMenu.some((childMenuItem: AdminNavChildMenu) =>
    isItemViewable(childMenuItem, permissionsContext, featureFlags)
  );

export const isLinkViewable = (
  parentMenuItem: AdminNavParentMenu,
  permissionsContext: any,
  featureFlags: object
) => hasViewableMenuItems(parentMenuItem, permissionsContext, featureFlags);

interface GetMobileMenuItemDataProps {
  parentMenuItem: AdminNavParentMenu;
  permissionsContext: any;
  featureFlags: object;
}

const getMobileMenuItemData = ({
  parentMenuItem,
  permissionsContext,
  featureFlags,
}: GetMobileMenuItemDataProps) => {
  if (isLinkViewable(parentMenuItem, permissionsContext, featureFlags)) {
    return {
      title: parentMenuItem.title,
      key: parentMenuItem.title,
      linkStyle: 'button',
      link: parentMenuItem.link,
      openInNewTab: parentMenuItem.openInNewTab,
      childMenu:
        (parentMenuItem.childMenu &&
          parentMenuItem.childMenu
            .map((childMmenuItem: AdminNavChildMenu) =>
              isItemViewable(childMmenuItem, permissionsContext, featureFlags)
                ? {
                    title: childMmenuItem.title,
                    key: childMmenuItem.title,
                    link: childMmenuItem.link,
                    onClick: childMmenuItem.onClick,
                    openInNewTab: childMmenuItem.openInNewTab,
                  }
                : null
            )
            .filter((item: any) => !!item)) ||
        [],
    };
  } else {
    return null;
  }
};

export const useMobileAdminNavMenuData = () => {
  const adminNavConfig = useAdminNavConfig();
  const permissionsContext = useContext(PermissionsContext);
  const featureFlagsParam = {};

  const menuData = adminNavConfig
    .map((parentMenuItem: AdminNavParentMenu) =>
      getMobileMenuItemData({
        parentMenuItem,
        permissionsContext,
        featureFlags: featureFlagsParam,
      })
    )
    .filter((item: any) => !!item);

  return menuData;
};
