import React, { useState } from 'react';
import { zonedTimeToUtc } from 'date-fns-tz';
import { useIntl } from 'react-intl';

import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { CreateCompetition } from 'app/admin/graphql/competitions/mutationHooks';
import { DetailsFormMainContainer } from 'app/admin/components/atoms/DetailContent';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

import CompetitionBasicInfoForm from './CompetitionBasicInfoForm';
import CompetitionBasicInfoFormSchema from './CompetitionBasicInfoFormSchema';
import { getClosesAt } from './Utils';

interface Props {
  contentProps?: any;
  setFormSubmitAction?: (func: Function) => void;
  setDisplayConfirmation?: (set: boolean) => void;
  setIsSubmitting?: (set: boolean) => void;
  hide: VoidFunction;
}

const CompetitionCreate: React.FC<Props> = ({
  contentProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  hide,
}) => {
  const intl = useIntl();

  const [validationErrors, setValidationErrors] = useState<object>({});

  const formInitialValues = {
    isNewCompetition: true,
    title: '',
    closesAtDate: null,
    closesAtTime: null,
    closesAt: '',
    uid: '',
    city: null,
    event: undefined,
    maxWinners: '',
    competitionTemplateId: undefined,
  };

  const createCompetitionAction = CreateCompetition();

  const handleCreateCompetition = useSubmitAction({
    submitAction: createCompetitionAction,
    submitVariables: (values: any) => ({
      title: values.title,
      closesAt: zonedTimeToUtc(
        getClosesAt(values.closesAtDate, values.closesAtTime),
        values.city.timezone
      ),
      uid: values.uid,
      cityId: values.city ? values.city.id : undefined,
      eventId: values.event ? values.event.id : undefined,
      maxWinners: Number(values.maxWinners),
      competitionTemplateId: values.competitionTemplateId,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.competitionCreate.successMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.competitionCreate.failureMessage',
    }),
    onSuccess: () => {
      contentProps.refetchCompetitions();
      hide();
    },
    onValidationError: validationErrors =>
      setValidationErrors(validationErrors),
    validationErrorKeysForDisplay: { uid: 'slug' },
  });

  return (
    <ModalContentContainer>
      <DetailsFormMainContainer>
        <DetailsHeader
          title={intl.formatMessage({
            id: 'admin.competitionCreate.title',
          })}
          isNewForm={true}
        />
        <GenericForm
          formInitialValues={formInitialValues}
          renderFormComponent={(renderProps: any) => (
            <CompetitionBasicInfoForm
              formikProps={renderProps.formikProps}
              validationErrors={validationErrors}
              isNewCompetition={true}
            />
          )}
          onSubmit={handleCreateCompetition}
          formSchema={CompetitionBasicInfoFormSchema(intl)}
          setFormSubmitAction={setFormSubmitAction}
          setDisplayConfirmation={setDisplayConfirmation}
          setIsSubmitting={setIsSubmitting}
          dataQaId="competition-create-form"
        />
      </DetailsFormMainContainer>
    </ModalContentContainer>
  );
};

export default CompetitionCreate;
