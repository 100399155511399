import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Venue } from 'app/typings/venues';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import {
  DetailHeading as Heading,
  DetailSubheading as Subheading,
  DetailValueContainer as ValueContainer,
} from 'app/admin/components/atoms/DetailContent';
import ExpandableText from 'app/admin/components/atoms/ExpandableText';
import { None, NoneWrapper } from 'app/admin/components/atoms/None';

interface Props {
  sectionData: Venue;
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const SeatingContainer = styled.ul`
  margin-top: 0px;
  margin-bottom: 0px;
  padding-left: 20px;
`;

const SeatingBulletPoint = styled.li`
  font-size: 10px;
  color: #10ad52;
  padding-left: 6px;
`;

const SeatingText = styled.span`
  font-size: 14px;
  color: #000000;
`;

const SectionFanExperience: React.FC<Props> = ({ sectionData }) => {
  const intl = useIntl();

  return (
    <MainContainer data-qaid="section-fan-experience">
      <Grid cols={4}>
        <Col
          xs={12}
          sm={12}
          md={1}
          lg={1}
          xl={1}
          key={1}
          data-qaid="col-external-venue-notes"
        >
          <Heading>
            {intl.formatMessage({
              id: 'admin.shared.fanFacingDescription',
            })}
          </Heading>
          <Subheading>
            {intl.formatMessage({
              id: 'admin.venueFanExperience.fanFacingDescriptionSubheader',
            })}
          </Subheading>
          <ValueContainer>
            {sectionData.externalVenueNotes ? (
              <ExpandableText
                allowHTML
                text={sectionData.externalVenueNotes}
                truncateLength={50}
              />
            ) : (
              <None />
            )}
          </ValueContainer>
        </Col>
        <Col
          xs={12}
          sm={12}
          md={1}
          lg={1}
          xl={1}
          key={2}
          data-qaid="col-invite-email-message"
        >
          <Heading>
            {intl.formatMessage({
              id: 'admin.shared.inviteEmail',
            })}
          </Heading>
          <Subheading>
            {intl.formatMessage({
              id: 'admin.venueFanExperience.inviteEmailSubheader',
            })}
          </Subheading>
          <ValueContainer>
            {sectionData.inviteEmailMessage ? (
              <ExpandableText
                allowHTML
                text={sectionData.inviteEmailMessage}
                truncateLength={50}
              />
            ) : (
              <None />
            )}
          </ValueContainer>
        </Col>
        <Col
          xs={12}
          sm={12}
          md={1}
          lg={1}
          xl={1}
          key={3}
          data-qaid="col-reveal-address-message"
        >
          <Heading>
            {intl.formatMessage({
              id: 'admin.shared.addressRevealEmail',
            })}
          </Heading>
          <Subheading>
            {intl.formatMessage({
              id: 'admin.venueFanExperience.addressRevealEmailSubheader',
            })}
          </Subheading>
          <ValueContainer>
            {sectionData.revealAddressEmailMessage ? (
              <ExpandableText
                allowHTML
                text={sectionData.revealAddressEmailMessage}
                truncateLength={50}
              />
            ) : (
              <None />
            )}
          </ValueContainer>
        </Col>
        <Col
          xs={12}
          sm={12}
          md={1}
          lg={1}
          xl={1}
          key={4}
          data-qaid="col-seating"
        >
          <Heading>
            {intl.formatMessage({
              id: 'admin.shared.seating',
            })}
          </Heading>
          <ValueContainer>
            <SeatingContainer>
              <SeatingBulletPoint>
                <SeatingText>
                  {sectionData.seating && sectionData.seating.key ? (
                    sectionData.seating.name
                  ) : (
                    <NoneWrapper>Unknown</NoneWrapper>
                  )}
                </SeatingText>
              </SeatingBulletPoint>
            </SeatingContainer>
          </ValueContainer>
        </Col>
      </Grid>
    </MainContainer>
  );
};

export default SectionFanExperience;
