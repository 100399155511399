import React, { CSSProperties, useRef } from 'react';
import styled, { css } from 'styled-components';

interface BackgroundProps {
  left?: CSSProperties['left'];
  top?: CSSProperties['top'];
  position?: CSSProperties['position'];
}

interface ContentBackgroundProps {
  width?: CSSProperties['width'];
  height?: CSSProperties['height'];
}

interface Props extends BackgroundProps, ContentBackgroundProps {
  displayConfirmation: boolean;
  setFireCloseEvent?: (fireCloseEvent: boolean) => void;
  hide: Function;
  renderContent: any;
}

const Background = styled.div<BackgroundProps>`
  left: ${props => props.left};
  top: ${props => props.top};
  position: ${props => props.position};
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ContentBackground = styled.div<ContentBackgroundProps>`
  ${({ theme, width, height }) => css`
    background-color: ${theme.colors.whiteDenim};
    width: ${width};
    height: ${height};
    overflow: auto;
    position: relative;
    pointer-events: all;

    ${theme.media.xs`
      height: 100%;
      width: 100%;
    `}

    ${theme.media.sm`
      height: 81%;
      width: 87%;
    `}

    ${theme.media.xl`
      width: 1200px;
    `}
  `}
`;

const OuterModalContainer: React.FC<Props> = ({
  left = '0',
  top = '0',
  position = 'fixed',
  width = '100%',
  height = '80%',
  displayConfirmation,
  setFireCloseEvent,
  hide,
  renderContent,
}) => {
  const mainSectionBackgroundRef = useRef<HTMLDivElement>(null);

  const handleModalClick = (clickEventObject: any) => {
    const classList = (clickEventObject.target as HTMLElement).classList;
    const isOutsideModalClick = classList.contains(
      'outer-modal-container-background'
    );

    if (isOutsideModalClick) {
      if (displayConfirmation) {
        setFireCloseEvent && setFireCloseEvent(true);
      } else {
        // Hides modal on outside click
        hide();
      }
    }
  };

  return (
    <Background
      position={position}
      top={top}
      left={left}
      onClick={handleModalClick}
      className="outer-modal-container-background"
    >
      <ContentBackground
        width={width}
        height={height}
        ref={mainSectionBackgroundRef}
      >
        {renderContent(mainSectionBackgroundRef)}
      </ContentBackground>
    </Background>
  );
};

export default OuterModalContainer;
export { BackgroundProps, ContentBackgroundProps };
