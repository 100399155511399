import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { User } from 'app/typings/users';
import { capitalize } from 'app/shared/utils/string';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import { BaseLink } from 'app/shared/components/atoms/LinkManualCSS';
import TruncatedByCharText from 'app/shared/components/atoms/TruncatedByCharText';
import { mobileNumberFormatter } from 'app/admin/utils/mobile';
import {
  DetailHeading as Heading,
  DetailValueContainer as ValueContainer,
} from 'app/admin/components/atoms/DetailContent';
import { None } from 'app/admin/components/atoms/None';
import { CityLink } from 'app/admin/components/molecules/EntityLink';

interface Props {
  sectionData: User;
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const Link = styled(BaseLink)`
  ${({ theme }) => css`
    cursor: pointer;
    color: ${theme.colors.green600};
  `}
`;

const CityName = styled.div`
  margin-bottom: 3px;
`;

const renderTruncatedByCharTextValue = (value: string | undefined) => {
  return value ? (
    <TruncatedByCharText text={value} truncateLength={40} />
  ) : (
    <None />
  );
};

const SectionBasicInfo: React.FC<Props> = ({ sectionData }) => {
  const intl = useIntl();

  const citiesAndEventCounts = sectionData?.cityStaffedEventCounts?.sort(
    (a: any, b: any) => (a.city.title > b.city.title ? 1 : -1)
  );

  return (
    <MainContainer data-qaid="section-about">
      <Grid cols={4}>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={1}>
          <Heading>
            {intl.formatMessage({
              id: 'admin.userBasicInfo.heading.cities',
            })}
          </Heading>
          <ValueContainer>
            {citiesAndEventCounts && citiesAndEventCounts.length > 0 ? (
              citiesAndEventCounts?.map((cityAndEventCount: any) => (
                <CityName key={cityAndEventCount.city.id}>
                  <CityLink
                    city={cityAndEventCount.city}
                    textAfter={cityAndEventCount.staffedEventsCount}
                  />
                </CityName>
              ))
            ) : (
              <None />
            )}
          </ValueContainer>
        </Col>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={2}>
          <Heading>
            {intl.formatMessage({
              id: 'admin.userBasicInfo.heading.email',
            })}
          </Heading>
          <ValueContainer>
            <Link href={`mailto:${sectionData.email}`} openInNewTab={true}>
              {renderTruncatedByCharTextValue(sectionData.email)}
            </Link>
          </ValueContainer>
        </Col>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={3}>
          <Heading>
            {intl.formatMessage({
              id: 'admin.userBasicInfo.heading.mobile',
            })}
          </Heading>
          <ValueContainer>
            {sectionData.mobile && sectionData.mobile.numberBody ? (
              <Link
                href={`tel:${mobileNumberFormatter(sectionData.mobile)}`}
                openInNewTab={true}
              >
                {mobileNumberFormatter(sectionData.mobile, true)}
              </Link>
            ) : (
              <None />
            )}
          </ValueContainer>
        </Col>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={4}>
          <Heading>
            {intl.formatMessage({
              id: 'admin.userBasicInfo.heading.slackUserId',
            })}
          </Heading>
          <ValueContainer>{sectionData.slackUserId || <None />}</ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.userBasicInfo.heading.status',
            })}
          </Heading>
          <ValueContainer>
            {capitalize(sectionData.crewStatus || '')}
          </ValueContainer>
        </Col>
      </Grid>
    </MainContainer>
  );
};

export default SectionBasicInfo;
