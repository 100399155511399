import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { useCurrentTheme } from 'app/shared/theme';
import { FEATURED_SET_EVENT } from 'app/shared/utils/events';
import { get } from 'app/shared/utils/get';
import useModal from 'app/shared/utils/useModal';
import usePermission from 'app/shared/utils/usePermission';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import { Checkbox } from 'app/shared/components/atoms/CheckboxManualCSS';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import { Radio } from 'app/shared/components/atoms/Radio';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { StreamlineIcon } from 'app/shared/components/atoms/StreamlineIcon';
import { Overline } from 'app/shared/components/atoms/TypographyManualCSS';
import ConfirmationModal from 'app/shared/components/molecules/ConfirmationModal';
import FormGroup from 'app/shared/components/molecules/FormGroup';
import IconAndTextLink from 'app/shared/components/molecules/IconAndTextLink';
import { eventTypeLabels } from 'app/admin/utils/events';
import { eventTypeDisplayConfig } from 'app/admin/utils/eventTypeDisplayConfig';
import { UpdateEvent } from 'app/admin/graphql/events/mutationHooks';
import { GetEventInfo } from 'app/admin/graphql/events/queryHooks';
import { DisableVisaPresale } from 'app/admin/graphql/visa/mutationHooks';
import { TodaysEventsPDFLink } from 'app/admin/components/molecules/TodaysEventsPDFLink';
import CancellationReasonForm from 'app/admin/components/organisms/CancellationReasonForm';
import { ReactComponent as Camera1 } from 'icons/streamline-regular/images-photography/cameras/camera-1.svg';
import { ReactComponent as BrowserPageAccount } from 'icons/streamline-regular/programing-apps-websites/website-development/browser-page-account.svg';
import { ReactComponent as TicketGroup } from 'icons/streamline-regular/wayfinding/tickets/ticket-group.svg';
import { ReactComponent as TeamMeeting } from 'icons/streamline-regular/work-office-companies/meetings-collaboration/team-meeting.svg';

interface Props {
  sectionData: any;
  callbacks: { [name: string]: VoidFunction };
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const StyledIcon = styled.span`
  margin-right: 12px;
`;

const NotIsGloballyPromotedText = styled.div`
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const PDFDownloadLinkWrapper = styled.div`
  display: flex;
  margin: 5px 0 7px;
`;

const TodaysEventsPDFWrapper = styled.div`
  margin-left: 12px;
`;

const SectionSettings: React.FC<Props> = ({ sectionData, callbacks }) => {
  const intl = useIntl();
  const theme = useCurrentTheme();
  const updateEventAction = UpdateEvent();
  const disableVisaPresaleAction = DisableVisaPresale();

  const hasViewCitiesPermission = usePermission('city.list.view');
  const hasGuestlistAccessPermission = usePermission('event.guestlist.access');
  const hasViewInternalUsersPermission = usePermission(
    'user.internalList.view'
  );
  const hasDisableVisaPresalePermission = usePermission(
    'visaPresale.disablePresale'
  );
  const hasEditEventArtistSelfBookingPermission = usePermission(
    'event.artistSelfBooking.edit'
  );

  const { event } = sectionData;
  const {
    status: eventStatus,
    isAutoInviteEnabled,
    isGloballyPromoted,
  } = event;
  const displayConfig = eventTypeDisplayConfig[event.type];

  const [
    eventUpdateConfirmationModal,
    toggleEventUpdateConfirmationModal,
  ] = useModal();
  const [
    disableVisaPresaleConfirmationModal,
    toggleDisableVisaPresaleConfirmationModal,
  ] = useModal();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [submitSuccessMessage, setSubmitSuccessMessage] = useState<string>('');
  const [
    confirmationModalDescription,
    setConfirmationModalDescription,
  ] = useState<string>('');

  const [
    cancellationReasonValidationError,
    setCancellationReasonValidationError,
  ] = useState<string | undefined>(undefined);
  const [
    cancellationReasonDescription,
    setCancellationReasonDescription,
  ] = useState<string | undefined>(undefined);

  const [
    displayCancellationReasonForm,
    setDisplayCancellationReasonForm,
  ] = useState<boolean>(false);

  const [eventUpdateParams, setEventUpdateParams] = useState<any>({});

  const handleEventUpdate = useSubmitAction({
    submitAction: updateEventAction,
    submitVariables: () => ({
      ...eventUpdateParams,
      eventId: event.id,
    }),
    successMsg: submitSuccessMessage,
    failureMsg: intl.formatMessage({
      id: 'admin.eventPlanner.settings.eventStatusUpdateFailure',
    }),
    onSuccess: () => {
      setIsSubmitting(false);
      setEventUpdateParams({});
      if (eventUpdateConfirmationModal.isShowing) {
        toggleEventUpdateConfirmationModal();
      }
    },
  });

  const handleDisableVisaPresale = useSubmitAction({
    submitAction: disableVisaPresaleAction,
    submitVariables: () => ({
      eventId: event.id,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.eventPlanner.settings.disableVisaPresaleSuccess',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.eventPlanner.settings.eventStatusUpdateFailure',
    }),
    onSuccess: () => {
      setIsSubmitting(false);
      if (disableVisaPresaleConfirmationModal.isShowing) {
        toggleDisableVisaPresaleConfirmationModal();
      }
    },
  });

  const renderDraftPublishedRadioGroup = () => (
    <FormGroup data-qaid="event-settings-draft-published-formgroup" label="">
      <>
        <Radio
          id="eventStatusDraft"
          data-qaid="event-status-draft-radio-button"
          value="draft"
          name="eventStatusDraft"
          checked={eventStatus === 'draft'}
          onChange={() => {
            setConfirmationModalDescription(
              intl.formatMessage({
                id: 'admin.eventPlanner.settings.updateEventToDraft',
              })
            );
            setSubmitSuccessMessage(
              intl.formatMessage(
                {
                  id: 'admin.eventPlanner.settings.eventStatusUpdateSuccess',
                },
                {
                  newStatus: 'draft',
                }
              )
            );
            setEventUpdateParams({
              isPublished: false,
              cancelled: false,
            });
            toggleEventUpdateConfirmationModal();
          }}
        >
          {intl.formatMessage({
            id: 'shared.draft',
          })}
        </Radio>
        <Spacer mt={4} />
        <Radio
          id="eventStatusPublished"
          data-qaid="event-status-published-radio-button"
          value="published"
          name="eventStatusPublished"
          checked={eventStatus === 'published'}
          onChange={() => {
            setConfirmationModalDescription(
              intl.formatMessage({
                id: 'admin.eventPlanner.settings.updateEventToPublished',
              })
            );
            setSubmitSuccessMessage(
              intl.formatMessage(
                {
                  id: 'admin.eventPlanner.settings.eventStatusUpdateSuccess',
                },
                {
                  newStatus: 'published',
                }
              )
            );
            setEventUpdateParams({
              isPublished: true,
              cancelled: false,
            });
            toggleEventUpdateConfirmationModal();
          }}
        >
          {intl.formatMessage({
            id: 'admin.eventPlanner.settings.published',
          })}
        </Radio>
      </>
    </FormGroup>
  );

  const renderAutoInviteRadioGroup = () => (
    <FormGroup data-qaid="event-settings-auto-invite-formgroup" label="">
      <>
        <Radio
          id="autoInviteOn"
          data-qaid="auto-invite-on-radio-button"
          value="on"
          name="autoInviteOn"
          checked={isAutoInviteEnabled}
          onChange={() => {
            setConfirmationModalDescription(
              intl.formatMessage({
                id: 'admin.eventPlanner.settings.updateAutoInviteOn',
              })
            );
            setSubmitSuccessMessage(
              intl.formatMessage({
                id: 'admin.eventPlanner.settings.autoInviteUpdateOnSuccess',
              })
            );
            setEventUpdateParams({
              isAutoInviteEnabled: true,
            });
            toggleEventUpdateConfirmationModal();
          }}
        >
          {intl.formatMessage({
            id: 'admin.eventPlanner.settings.autoInviteOn',
          })}
        </Radio>
        <Spacer mt={4} />
        <Radio
          id="autoInviteOff"
          data-qaid="auto-invite-off-radio-button"
          value="off"
          name="autoInviteOff"
          checked={!isAutoInviteEnabled}
          onChange={() => {
            setConfirmationModalDescription(
              intl.formatMessage({
                id: 'admin.eventPlanner.settings.updateAutoInviteOff',
              })
            );
            setSubmitSuccessMessage(
              intl.formatMessage({
                id: 'admin.eventPlanner.settings.autoInviteUpdateOffSuccess',
              })
            );
            setEventUpdateParams({
              isAutoInviteEnabled: false,
            });
            toggleEventUpdateConfirmationModal();
          }}
        >
          {intl.formatMessage({
            id: 'admin.eventPlanner.settings.autoInviteOff',
          })}
        </Radio>
      </>
    </FormGroup>
  );

  const renderCancelArchivedRadioGroup = () => (
    <FormGroup data-qaid="event-settings-cancel-archived-formgroup" label="">
      <>
        <Radio
          id="eventStatusCancelled"
          data-qaid="event-status-cancelled-radio-button"
          value="cancelled"
          name="eventStatusCancelled"
          checked={eventStatus === 'cancelled'}
          onChange={() => {
            setConfirmationModalDescription(
              intl.formatMessage({
                id: 'admin.eventPlanner.settings.updateEventToCancelled',
              })
            );
            setSubmitSuccessMessage(
              intl.formatMessage(
                {
                  id: 'admin.eventPlanner.settings.eventStatusUpdateSuccess',
                },
                {
                  newStatus: 'cancelled',
                }
              )
            );
            setDisplayCancellationReasonForm(true);
            toggleEventUpdateConfirmationModal();
          }}
        >
          {intl.formatMessage({
            id: 'admin.eventPlanner.settings.cancel',
          })}
        </Radio>
        <Spacer mt={4} />
        <Radio
          disabled
          data-qaid="event-status-archive-radio-button"
          id="eventStatusArchived"
          value="archived"
          name="eventStatusArchived"
          checked={eventStatus === 'archived'}
          onChange={() => {
            setConfirmationModalDescription(
              intl.formatMessage({
                id: 'admin.eventPlanner.settings.updateEventToArchived',
              })
            );
            setSubmitSuccessMessage(
              intl.formatMessage(
                {
                  id: 'admin.eventPlanner.settings.eventStatusUpdateSuccess',
                },
                {
                  newStatus: 'archived',
                }
              )
            );
            toggleEventUpdateConfirmationModal();
          }}
        >
          {intl.formatMessage({
            id: 'admin.eventPlanner.settings.archive',
          })}
        </Radio>
      </>
    </FormGroup>
  );

  const { data: dataBasicInfo } = GetEventInfo({
    id: sectionData.event.id,
    includeDraftEvents: true,
  });

  const eventId = get(dataBasicInfo, 'event.id', undefined);
  const eventSlug = get(dataBasicInfo, 'event.cachedSlug', undefined);
  const eventType = get(dataBasicInfo, 'event.type', undefined);
  const isPublished = get(dataBasicInfo, 'event.isPublished', undefined);
  const isSpecialEvent = get(dataBasicInfo, 'event.isSpecialEvent', undefined);
  const specialEventUrl = get(
    dataBasicInfo,
    'event.specialEventUrl',
    undefined
  );
  const cityId = get(dataBasicInfo, 'event.city.id', undefined);
  const eventCitySlug = get(dataBasicInfo, 'event.city.cachedSlug', undefined);
  const attendeeFlowType = get(dataBasicInfo, 'event.attendeeFlow', undefined);
  const cityTitle = get(sectionData, 'event.city.title', undefined);

  const getEventPageUrl = () => {
    if (!isPublished) {
      return;
    }

    if (isSpecialEvent) {
      return specialEventUrl;
    }

    switch (eventType) {
      case FEATURED_SET_EVENT:
        return `/${displayConfig.cachedSlugPublicPageUrl}/${eventSlug}`;
      default:
        return `/events/${eventId}`;
    }
  };

  const renderAppliableRadioGroup = () => {
    const { isAppliable } = event;

    return (
      <>
        <Overline spaceAfter={3}>
          {intl.formatMessage({
            id: 'admin.eventPlanner.settings.canApply',
          })}
        </Overline>
        <FormGroup data-qaid="event-settings-appliable-formgroup" label="">
          <>
            <Radio
              id="eventAppliableActive"
              data-qaid="event-appliable-active-radio-button"
              value="active"
              disabled={eventStatus === 'cancelled'}
              name="eventAppliableActive"
              checked={isAppliable}
              onChange={() => {
                setConfirmationModalDescription(
                  intl.formatMessage({
                    id: 'admin.eventPlanner.settings.updateAppliableYes',
                  })
                );
                setSubmitSuccessMessage(
                  intl.formatMessage({
                    id: 'admin.eventPlanner.settings.updateAppliableYesSuccess',
                  })
                );
                setEventUpdateParams({
                  isAppliable: true,
                });
                toggleEventUpdateConfirmationModal();
              }}
            >
              {intl.formatMessage({
                id: 'yes',
              })}
            </Radio>
            <Spacer mt={4} />
            <Radio
              id="eventAppliableNo"
              data-qaid="event-appliable-paused-radio-button"
              value="paused"
              disabled={eventStatus === 'cancelled'}
              name="eventAppliableNo"
              checked={!isAppliable}
              onChange={() => {
                setConfirmationModalDescription(
                  intl.formatMessage({
                    id: 'admin.eventPlanner.settings.updateAppliableNo',
                  })
                );
                setSubmitSuccessMessage(
                  intl.formatMessage({
                    id: 'admin.eventPlanner.settings.updateAppliableNoSuccess',
                  })
                );
                setEventUpdateParams({
                  isAppliable: false,
                });
                toggleEventUpdateConfirmationModal();
              }}
            >
              {intl.formatMessage({
                id: 'no',
              })}
            </Radio>
          </>
        </FormGroup>
        <Spacer mt={8} />
      </>
    );
  };

  const renderTicketingRadioGroups = () => {
    const { isPurchasable, attendeeFlow } = event;
    const isBuyFlow = attendeeFlow === 'buy';

    const radioGroupTextKeys = {
      buy: {
        titleKey: 'admin.eventPlanner.settings.tickets',
        activeRadioButtonLabelKey: 'admin.eventPlanner.settings.active',
        pausedRadioButtonLabelKey: 'admin.eventPlanner.settings.paused',
        ticketingActiveSuccessMessageLabelKey:
          'admin.eventPlanner.settings.eventTicketingActiveSuccess',
        ticketingPausedSuccessMessageLabelKey:
          'admin.eventPlanner.settings.eventTicketingPausedSuccess',
        activeConfirmationModalDescriptionKey:
          'admin.eventPlanner.settings.updateTicketSalesToActive',
        pausedConfirmationModalDescriptionKey:
          'admin.eventPlanner.settings.updateTicketSalesToPaused',
      },
      apply: {
        titleKey: 'admin.eventPlanner.settings.canConfirm',
        activeRadioButtonLabelKey: 'yes',
        pausedRadioButtonLabelKey: 'no',
        ticketingActiveSuccessMessageLabelKey:
          'admin.eventPlanner.settings.eventCanConfirmYesSuccess',
        ticketingPausedSuccessMessageLabelKey:
          'admin.eventPlanner.settings.eventCanConfirmNoSuccess',
        activeConfirmationModalDescriptionKey:
          'admin.eventPlanner.settings.updateCanConfirmYes',
        pausedConfirmationModalDescriptionKey:
          'admin.eventPlanner.settings.updateCanConfirmNo',
      },
    };

    const {
      titleKey,
      activeRadioButtonLabelKey,
      pausedRadioButtonLabelKey,
      ticketingActiveSuccessMessageLabelKey,
      ticketingPausedSuccessMessageLabelKey,
      activeConfirmationModalDescriptionKey,
      pausedConfirmationModalDescriptionKey,
    } = radioGroupTextKeys[attendeeFlow];

    return (
      <>
        {!isBuyFlow && renderAppliableRadioGroup()}
        <Overline spaceAfter={3}>
          {intl.formatMessage({
            id: titleKey,
          })}
        </Overline>
        <FormGroup data-qaid="event-settings-purchasable-formgroup" label="">
          <>
            <Radio
              id="eventPurchasableActive"
              data-qaid="event-purchasable-active-radio-button"
              value="active"
              disabled={eventStatus === 'cancelled'}
              name="eventPurchasableActive"
              checked={isPurchasable}
              onChange={() => {
                setConfirmationModalDescription(
                  intl.formatMessage({
                    id: activeConfirmationModalDescriptionKey,
                  })
                );
                setSubmitSuccessMessage(
                  intl.formatMessage({
                    id: ticketingActiveSuccessMessageLabelKey,
                  })
                );
                setEventUpdateParams({
                  isPurchasable: true,
                });
                toggleEventUpdateConfirmationModal();
              }}
            >
              {intl.formatMessage({
                id: activeRadioButtonLabelKey,
              })}
            </Radio>
            <Spacer mt={4} />
            <Radio
              id="eventPurchasablePaused"
              data-qaid="event-purchasable-paused-radio-button"
              value="paused"
              disabled={eventStatus === 'cancelled'}
              name="eventPurchasablePaused"
              checked={!isPurchasable}
              onChange={() => {
                setConfirmationModalDescription(
                  intl.formatMessage({
                    id: pausedConfirmationModalDescriptionKey,
                  })
                );
                setSubmitSuccessMessage(
                  intl.formatMessage({
                    id: ticketingPausedSuccessMessageLabelKey,
                  })
                );
                setEventUpdateParams({
                  isPurchasable: false,
                });
                toggleEventUpdateConfirmationModal();
              }}
            >
              {intl.formatMessage({
                id: pausedRadioButtonLabelKey,
              })}
            </Radio>
          </>
        </FormGroup>
      </>
    );
  };

  const renderCancellationReasonForm = () => {
    return (
      <CancellationReasonForm
        cancellableType="Event"
        otherReasonDescriptionPlaceholderText={intl.formatMessage({
          id: 'admin.eventPlanner.settings.whyWasTheShowCancelled',
        })}
        validationError={cancellationReasonValidationError}
        onChange={({
          cancellationReasonId,
          cancellationReasonDescription,
          otherReasonDescription,
        }) => {
          setCancellationReasonValidationError(undefined);
          setCancellationReasonDescription(cancellationReasonDescription);
          setEventUpdateParams({
            cancelled: true,
            isPublished: false,
            cancellationReasonId,
            cancellationReasonDescription: otherReasonDescription,
          });
        }}
      />
    );
  };

  const renderEventStatusUpdateConfirmationModal = () => (
    <ConfirmationModal
      onCancel={() => {
        setDisplayCancellationReasonForm(false);
        eventUpdateConfirmationModal.hide();
        setSubmitSuccessMessage('');
      }}
      description={confirmationModalDescription}
      onConfirm={() => {
        if (
          displayCancellationReasonForm &&
          !eventUpdateParams.cancellationReasonId
        ) {
          setCancellationReasonValidationError('missing_cancellation_reason');
          return;
        } else if (
          displayCancellationReasonForm &&
          cancellationReasonDescription === 'Other' &&
          !eventUpdateParams?.cancellationReasonDescription
        ) {
          setCancellationReasonValidationError(
            'missing_other_reason_description'
          );
          return;
        }
        setDisplayCancellationReasonForm(false);
        setIsSubmitting(true);
        handleEventUpdate();
      }}
      isLoading={isSubmitting}
      customContent={
        displayCancellationReasonForm ? renderCancellationReasonForm() : null
      }
    />
  );

  // Start View on Event Grid
  const renderViewOnEventGridRadioGroup = () => (
    <FormGroup data-qaid="event-settings-view-on-event-grid-formgroup" label="">
      <>
        <Radio
          id="eventGridShow"
          data-qaid="event-show-on-grid-radio-button"
          value="show"
          name="eventGridShow"
          checked={isGloballyPromoted === true}
          onChange={() => {
            setConfirmationModalDescription(
              intl.formatMessage({
                id: 'admin.eventPlanner.settings.updateShowOnEventGridYes',
              })
            );
            setSubmitSuccessMessage(
              intl.formatMessage({
                id: 'admin.eventPlanner.settings.updateShowOnEventGridSuccess',
              })
            );
            setEventUpdateParams({
              isGloballyPromoted: true,
            });
            toggleEventUpdateConfirmationModal();
          }}
        >
          {intl.formatMessage({
            id: 'admin.eventPlanner.settings.show',
          })}
        </Radio>
        <Spacer mt={4} />
        <Radio
          id="eventGridHide"
          data-qaid="event-hide-on-grid-radio-button"
          value="hide"
          name="eventGridHide"
          checked={isGloballyPromoted === false}
          onChange={() => {
            setConfirmationModalDescription(
              intl.formatMessage({
                id: 'admin.eventPlanner.settings.updateShowOnEventGridNo',
              })
            );
            setSubmitSuccessMessage(
              intl.formatMessage({
                id: 'admin.eventPlanner.settings.updateHideOnEventGridSuccess',
              })
            );
            setEventUpdateParams({
              isGloballyPromoted: false,
            });
            toggleEventUpdateConfirmationModal();
          }}
        >
          {intl.formatMessage({
            id: 'admin.eventPlanner.settings.hide',
          })}
        </Radio>
      </>
    </FormGroup>
  );
  // End View on Event Grid

  const renderVisaPresaleCheckbox = () => (
    <FormGroup data-qaid="event-settings-visa-presale-formgroup" label="">
      <Checkbox
        id="visaPresaleEnable"
        data-qaid="event-enable-visa-presale-checkbox"
        checked={event.onPresale}
        name="visaPresaleEnable"
        disabled={!event.onPresale}
        onChange={() => {
          event.onPresale && toggleDisableVisaPresaleConfirmationModal();
        }}
      >
        {intl.formatMessage({
          id: event.onPresale ? 'shared.active' : 'shared.inactive',
        })}
      </Checkbox>
    </FormGroup>
  );

  const renderArtistSelfBookingCheckbox = () => (
    <FormGroup
      data-qaid="event-settings-artist-self-booking-formgroup"
      label=""
    >
      <Checkbox
        id="isArtistSelfBookingEnabled"
        data-qaid="event-enable-artist-self-booking-checkbox"
        checked={event.isArtistSelfBookingEnabled}
        name="isArtistSelfBookingEnabled"
        onChange={e => {
          setConfirmationModalDescription(
            intl.formatMessage(
              {
                id: 'admin.eventPlanner.settings.editArtistSelfBooking',
              },
              {
                action: e.target.checked ? 'enable' : 'disable',
              }
            )
          );
          setSubmitSuccessMessage(
            intl.formatMessage(
              {
                id: 'admin.eventPlanner.settings.editArtistSelfBookingSuccess',
              },
              {
                action: e.target.checked ? 'enabled' : 'disabled',
              }
            )
          );
          setEventUpdateParams({
            isArtistSelfBookingEnabled: e.target.checked,
          });
          toggleEventUpdateConfirmationModal();
        }}
      >
        {intl.formatMessage({
          id: 'include',
        })}
      </Checkbox>
    </FormGroup>
  );

  const renderDisableVisaPresaleConfirmationModal = () => (
    <ConfirmationModal
      onCancel={() => {
        disableVisaPresaleConfirmationModal.hide();
      }}
      description={intl.formatMessage({
        id: 'admin.eventPlanner.settings.disableVisaPresaleConfirmation',
      })}
      onConfirm={() => {
        setIsSubmitting(true);
        handleDisableVisaPresale();
      }}
      isLoading={isSubmitting}
    />
  );

  return (
    <>
      <MainContainer data-qaid="section-event-settings">
        <Grid cols={4}>
          <Col xs={12} sm={12} md={1} lg={1} xl={1} key={1}>
            <Overline spaceAfter={3}>
              {intl.formatMessage({
                id: 'admin.eventPlanner.settings.draftPublished',
              })}
            </Overline>
            {renderDraftPublishedRadioGroup()}
            <Spacer mt={8} />
            <Overline spaceAfter={3}>
              {intl.formatMessage({
                id: 'admin.eventPlanner.settings.viewOnEventGrid',
              })}
            </Overline>
            {displayConfig.displayIsGloballyPromoted ? (
              renderViewOnEventGridRadioGroup()
            ) : (
              <NotIsGloballyPromotedText>
                {intl.formatMessage(
                  {
                    id: 'admin.eventPlanner.settings.noViewOnEventGrid',
                  },
                  {
                    eventType: eventTypeLabels[eventType],
                  }
                )}
              </NotIsGloballyPromotedText>
            )}
            {displayConfig.displayOnPresale &&
              hasDisableVisaPresalePermission &&
              event.eventOrganizedAs === 'o_and_o' && (
                <>
                  <Spacer mt={8} />
                  <Overline spaceAfter={3}>
                    {intl.formatMessage({
                      id: 'shared.visaPresale',
                    })}
                  </Overline>
                  {renderVisaPresaleCheckbox()}
                </>
              )}
          </Col>
          <Col xs={12} sm={12} md={1} lg={1} xl={1} key={2}>
            {renderTicketingRadioGroups()}
          </Col>
          <Col xs={12} sm={12} md={1} lg={1} xl={1} key={3}>
            {attendeeFlowType === 'apply' &&
              displayConfig.displayIsAutoInviteEnabled && (
                <>
                  <Overline spaceAfter={3}>
                    {intl.formatMessage({
                      id: 'admin.eventPlanner.settings.autoInvite',
                    })}
                  </Overline>
                  {renderAutoInviteRadioGroup()}
                  <Spacer mt={8} />
                </>
              )}
            <Overline spaceAfter={3}>
              {intl.formatMessage({
                id: 'admin.eventPlanner.settings.cancelArchive',
              })}
            </Overline>
            {renderCancelArchivedRadioGroup()}
            {displayConfig.displayIsArtistSelfBookingEnabled &&
              hasEditEventArtistSelfBookingPermission &&
              event.eventOrganizedAs === 'o_and_o' && (
                <>
                  <Spacer mt={8} />
                  <Overline spaceAfter={3}>
                    {intl.formatMessage({
                      id: 'admin.eventPlanner.settings.artistSelfBooking',
                    })}
                  </Overline>
                  {renderArtistSelfBookingCheckbox()}
                </>
              )}
          </Col>
          <Col xs={12} sm={12} md={1} lg={1} xl={1} key={4}>
            <Overline>
              {intl.formatMessage({
                id: 'admin.eventPlanner.settings.shortcuts',
              })}
            </Overline>
            {hasGuestlistAccessPermission && (
              <IconAndTextLink
                icon={TicketGroup}
                text={intl.formatMessage({
                  id: 'admin.eventPlanner.settings.manageGuestlist',
                })}
                onClick={() => callbacks.toggleGuestlistModal()}
              />
            )}
            {hasViewInternalUsersPermission && (
              <IconAndTextLink
                icon={TeamMeeting}
                text={intl.formatMessage({
                  id: 'admin.eventPlanner.settings.manageTeam',
                })}
                url="/admin/internal-users"
              />
            )}
            <IconAndTextLink
              icon={Camera1}
              text={intl.formatMessage({
                id: 'admin.eventPlanner.settings.manageHeaderImage',
              })}
              onClick={callbacks.toggleEventHeaderImageModal}
            />
            <Spacer mb={6} />
            <Overline>
              {intl.formatMessage({
                id: 'admin.eventPlanner.settings.links',
              })}
            </Overline>
            <Spacer mr={10} />
            <IconAndTextLink
              icon={BrowserPageAccount}
              text={intl.formatMessage({
                id: 'admin.eventPlanner.settings.publicConcertPage',
              })}
              url={getEventPageUrl()}
            />
            <IconAndTextLink
              icon={BrowserPageAccount}
              text={intl.formatMessage({
                id: 'admin.eventPlanner.settings.publicCityPage',
              })}
              url={`/cities/${eventCitySlug}`}
            />
            {hasViewCitiesPermission && (
              <IconAndTextLink
                icon={BrowserPageAccount}
                text={intl.formatMessage({
                  id: 'admin.eventPlanner.settings.cityDirectoryPage',
                })}
                url={`/admin/city-directory?city_id=${cityId}`}
              />
            )}
            <PDFDownloadLinkWrapper>
              <TodaysEventsPDFLink
                cityTitle={cityTitle}
                citySlug={eventCitySlug}
              >
                <StreamlineIcon
                  icon={BrowserPageAccount}
                  size={17}
                  stroke={theme.colors.green600}
                />
              </TodaysEventsPDFLink>
              <TodaysEventsPDFWrapper>
                <TodaysEventsPDFLink
                  cityTitle={cityTitle}
                  citySlug={eventCitySlug}
                >
                  {intl.formatMessage({
                    id: 'admin.eventPlanner.settings.todaysEventsDownload',
                  })}
                </TodaysEventsPDFLink>
              </TodaysEventsPDFWrapper>
            </PDFDownloadLinkWrapper>
          </Col>
        </Grid>
      </MainContainer>
      {eventUpdateConfirmationModal.isShowing &&
        renderEventStatusUpdateConfirmationModal()}
      {disableVisaPresaleConfirmationModal.isShowing &&
        renderDisableVisaPresaleConfirmationModal()}
    </>
  );
};

export default SectionSettings;
