import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { PrimaryRole } from 'app/typings/roles';
import { get } from 'app/shared/utils/get';
import useModal from 'app/shared/utils/useModal';
import usePermission from 'app/shared/utils/usePermission';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import { AddIcon } from 'app/shared/components/atoms/IconLibrary';
import { BaseLink } from 'app/shared/components/atoms/LinkManualCSS';
import { LoadingBlocks } from 'app/shared/components/atoms/LoadingBlocks';
import { H2 } from 'app/shared/components/atoms/TypographyManualCSS';
import ConfirmationModal from 'app/shared/components/molecules/ConfirmationModal';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import {
  roleResourceTypeLabels,
  roleResourceTypes,
} from 'app/admin/utils/users';
import { DeletePrimaryRole } from 'app/admin/graphql/roles/mutationHooks';
import { GetPrimaryRolesFullInfo } from 'app/admin/graphql/roles/queryHooks';
import { DetailHeading as Heading } from 'app/admin/components/atoms/DetailContent';
import ListTable from 'app/admin/components/organisms/ListTable';

interface Props {
  navigateTo: (routeData: any) => void;
  hide: VoidFunction;
}

const MainContainer = styled.div`
  ${({ theme }) => css`
    padding: 10px 0px 0px 0px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: left;

    ${theme.media.md`
      padding: 20px 0px 0px 20px;
    `}
  `}
`;

const Title = styled(H2)`
  ${({ theme }) => css`
    font-size: 16px;
    font-weight: normal;
    line-height: 1.2em;
    margin-bottom: 25px;

    ${theme.media.xl`
      font-size: 34px;
      margin-bottom: 40px;
      margin-left: 0px;
    `};
  `}
`;

const PrimaryRoleHeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 20px;
  white-space: nowrap;
`;

const PrimaryRoleHeading = styled(Heading)`
  margin-bottom: 0;
`;

const PlusIconWrapper = styled.div`
  margin-left: 10px;
  margin-top: -2px;
`;

const Link = styled(BaseLink)`
  ${({ theme }) => css`
    color: ${theme.colors.green600};
  `}
`;

const NoPrimaryRoles = styled.div`
  white-space: nowrap;
  width: 100%;
`;

const getUsersCount = (roleKey: string, primaryRolesFullInfo: any[]) => {
  const primaryRole =
    primaryRolesFullInfo &&
    primaryRolesFullInfo.find((pr: PrimaryRole) => pr.key == roleKey);
  if (primaryRole) {
    return primaryRole.usersCount;
  }
  return 0;
};

const PrimaryRoles: React.FC<Props> = ({ navigateTo, hide }) => {
  const intl = useIntl();

  const hasViewInternalUsersPermission = usePermission(
    'user.internalList.view'
  );

  const [primaryRoleDeleteModal, togglePrimaryRoleDeleteModal] = useModal();
  const [primaryRoleData, setPrimaryRoleData] = useState<any>(undefined);

  const {
    loading: loadingPrimaryRoles,
    error: errorPrimaryRoles,
    data: dataPrimaryRoles,
    refetch: refetchPrimaryRolesFullInfo,
  } = GetPrimaryRolesFullInfo({
    resourceTypes: roleResourceTypes,
    fetchPolicy: 'no-cache',
  });

  const deletePrimaryRoleAction = DeletePrimaryRole();

  const handleDeletePrimaryRole = useSubmitAction({
    submitAction: deletePrimaryRoleAction,
    submitVariables: () => ({
      primaryRoleId: primaryRoleData && primaryRoleData.id,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.primaryRoleBasicInfo.deleteSuccess',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.primaryRoleBasicInfo.deleteFailure',
    }),
    onSuccess: () => {
      primaryRoleDeleteModal.hide();
      refetchPrimaryRolesFullInfo();
    },
  });

  if ((!loadingPrimaryRoles && !dataPrimaryRoles) || errorPrimaryRoles) {
    hide();
    return null;
  }

  const columnsConfig = [
    {
      headerText: intl.formatMessage({
        id: 'admin.rolesAndPermissions.PrimaryRoles.role',
      }),
      columnWidth: 240,
    },
    {
      headerText: intl.formatMessage({
        id: 'admin.shared.type',
      }),
      columnWidth: 120,
    },
    {
      headerText: intl.formatMessage({
        id: 'admin.rolesAndPermissions.PrimaryRoles.usersWithRole',
      }),
      columnWidth: 130,
    },
  ];

  const primaryRolesData = get(
    dataPrimaryRoles,
    'primaryRoles.primaryRoles',
    []
  );

  const rowValues = (primaryRole: any) => {
    const usersCount = getUsersCount(
      primaryRole.key,
      get(dataPrimaryRoles, 'primaryRoles.primaryRolesFullInfo', [])
    );
    return [
      primaryRole.name,
      roleResourceTypeLabels[primaryRole.resourceType] ||
        primaryRole.resourceType ||
        intl.formatMessage({
          id: 'admin.rolesAndPermissions.PrimaryRoles.global',
        }),
      hasViewInternalUsersPermission ? (
        <Link
          href={`/admin/internal-users?role=${primaryRole.key}`}
          openInNewTab={true}
        >
          {usersCount}
        </Link>
      ) : (
        <>{usersCount}</>
      ),
    ];
  };

  const togglePrimaryRoleCreateModal = () =>
    navigateTo({
      routeName: 'primary-role-create',
      routeProps: {},
    });

  const togglePrimaryRoleEditModal = (primaryRoleData: any) =>
    navigateTo({
      routeName: 'primary-role-basic-info-edit',
      routeProps: {
        id: primaryRoleData.id,
        displayName: primaryRoleData.displayName,
        shortDisplayName: primaryRoleData.shortDisplayName,
      },
    });

  const togglePrimaryRoleDeleteAndSetPrimaryRoleData = (
    primaryRoleData: any
  ) => {
    setPrimaryRoleData(primaryRoleData);
    togglePrimaryRoleDeleteModal();
  };

  return (
    <ModalContentContainer data-qaid="primary-roles-modal">
      <MainContainer>
        <Title>
          {intl.formatMessage({
            id: 'admin.rolesAndPermissions.PrimaryRoles.editRoles',
          })}
        </Title>
        <PrimaryRoleHeadingContainer>
          <PrimaryRoleHeading>
            {intl.formatMessage(
              {
                id: 'admin.rolesAndPermissions.PrimaryRoles.title',
              },
              {
                count: get(dataPrimaryRoles, 'primaryRoles.primaryRoles', [])
                  .length,
              }
            )}
          </PrimaryRoleHeading>
          <PlusIconWrapper>
            <AddIcon iconSize={12} onClick={togglePrimaryRoleCreateModal} />
          </PlusIconWrapper>
        </PrimaryRoleHeadingContainer>
        <ListTable
          columnsConfig={columnsConfig}
          rowValues={rowValues}
          onEdit={(obj: any) =>
            togglePrimaryRoleEditModal({
              id: obj.id,
              displayName: obj.name,
              shortDisplayName: obj.shortName,
            })
          }
          onDelete={(obj: any) =>
            togglePrimaryRoleDeleteAndSetPrimaryRoleData({
              id: obj.id,
              name: obj.name,
            })
          }
          showDelete={(obj: any) =>
            getUsersCount(
              obj.key,
              get(dataPrimaryRoles, 'primaryRoles.primaryRolesFullInfo', [])
            ) == 0 && obj.key != 'admin'
          }
          data={primaryRolesData}
          loadingContent={
            loadingPrimaryRoles ? (
              <LoadingBlocks.Rectangle width="100%" height="300px" />
            ) : null
          }
          noResultsContent={
            !loadingPrimaryRoles &&
            primaryRolesData.length == 0 && (
              <NoPrimaryRoles>
                {intl.formatMessage({
                  id: 'admin.rolesAndPermissions.PrimaryRoles.noPrimaryRoles',
                })}
              </NoPrimaryRoles>
            )
          }
        />
      </MainContainer>
      {primaryRoleDeleteModal.isShowing && (
        <ConfirmationModal
          onCancel={() => primaryRoleDeleteModal.hide()}
          description={intl.formatMessage(
            {
              id: 'admin.primaryRoleBasicInfo.deleteConfirmation',
            },
            {
              displayName: primaryRoleData && primaryRoleData.name,
            }
          )}
          onConfirm={handleDeletePrimaryRole}
        />
      )}
    </ModalContentContainer>
  );
};

export default PrimaryRoles;
