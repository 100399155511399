import React, { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { Feedback } from 'app/typings/feedbacks';
import { dateFormatter } from 'app/shared/utils/datetime';
import { get } from 'app/shared/utils/get';
import useModal from 'app/shared/utils/useModal';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import { AuthContext } from 'app/shared/context/Auth';
import { Container } from 'app/shared/components/atoms/Container';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { H3 } from 'app/shared/components/atoms/TypographyManualCSS';
import ConfirmationModal from 'app/shared/components/molecules/ConfirmationModal';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { DeleteFeedback } from 'app/admin/graphql/feedbacks/mutationHooks';
import { DetailsInfoMainContainer } from 'app/admin/components/atoms/DetailContent';
import LoadMoreButton from 'app/admin/components/atoms/LoadMoreButton';
import ArtistFeedbackSummary from 'app/admin/components/organisms/ArtistFeedbackSummary';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

interface Props {
  contentProps?: any;
  navigateTo: (routeData: object) => void;
}

const GridContainer = styled.div`
  padding: 0;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  border-radius: 3px;
`;

const BodyContainer = styled(Container)`
  padding: 40px 0px 0px 0px !important;
`;

const Title = styled(H3)`
  font-weight: bold;
`;

const LinkContainer = styled.div`
  text-align: center;
`;

const DottedLineCol = styled(Col)`
  ${({ theme }) => css`
    display: none;

    ${theme.media.md`
    display: block;
  `}
  `}
`;

const MobileSpacer = styled(Spacer)`
  ${({ theme }) => css`
    display: block;

    ${theme.media.md`
      display: none;
    `}
  `}
`;

const headers = {
  Crew: 'Recent Crew Feedback',
  Ops: 'Recent Ops Feedback',
  User: 'My Feedback',
};

const header = (type: string) => {
  return headers[type];
};

const getTotalFeedbacksToDisplay = (
  feedbacks: Feedback[],
  userId: number | undefined,
  type: string
) => {
  const totalFeedbacksToDisplay = [];
  for (const feedback of feedbacks) {
    if (type !== 'User' && feedback.feedbackContext === type) {
      totalFeedbacksToDisplay.push(feedback);
    } else if (
      type === 'User' &&
      feedback.createdBy &&
      feedback.createdBy.id === userId
    ) {
      totalFeedbacksToDisplay.push(feedback);
    }
  }
  return totalFeedbacksToDisplay;
};

const feedbackList = (
  feedbacks: Feedback[],
  type: string,
  onDeleteFeedback: Function
) =>
  feedbacks.map((feedback: Feedback, index: number, feedbacks: any) => {
    const lastIndex = feedbacks.length - 1;
    const i = index + 1;
    const showDottedLine = i % 4 === 0 || index == lastIndex;

    return (
      <React.Fragment key={feedback.id}>
        <Col data-qaid="listing-column" sm={6} md={3}>
          <ArtistFeedbackSummary
            feedback={feedback}
            feedbackDisplayType={type}
            onClickDeleteFeedback={onDeleteFeedback}
          />
        </Col>
        {showDottedLine && (
          <DottedLineCol>
            <DottedLine />
          </DottedLineCol>
        )}
      </React.Fragment>
    );
  });

const ArtistAllFeedbacks: React.FC<Props> = ({ contentProps, navigateTo }) => {
  const intl = useIntl();
  const { user } = useContext(AuthContext);
  const userId = user?.id;

  const [feedbackDeleteModal, toggleFeedbackDeleteModal] = useModal();
  const [feedbackData, setFeedbackData] = useState<any>(undefined);

  const PAGE_SIZE = 12;
  const [maxNumFeedbacksToDisplay, setMaxNumFeedbacksToDisplay] = useState(
    PAGE_SIZE
  );

  const totalFeedbacksToDisplay = getTotalFeedbacksToDisplay(
    get(contentProps, 'artistData.feedbacks', []),
    userId,
    contentProps.type
  );
  const feedbacksToDisplay = totalFeedbacksToDisplay.slice(
    0,
    maxNumFeedbacksToDisplay
  );

  const title = header(contentProps.type);

  const onDeleteFeedback = (feedbackData: any) => {
    setFeedbackData(feedbackData);
    toggleFeedbackDeleteModal();
  };

  const deleteFeedbackAction = DeleteFeedback();

  const handleDeleteFeedback = useSubmitAction({
    submitAction: deleteFeedbackAction,
    submitVariables: () => ({
      feedbackId: feedbackData && feedbackData.id,
    }),
    successMsg: intl.formatMessage(
      {
        id: 'admin.artistDirectory.sectionFeedback.deleteSuccess',
      },
      {
        date:
          feedbackData && dateFormatter(feedbackData, 'shortMonthDateAndYear'),
      }
    ),
    failureMsg: intl.formatMessage({
      id: 'admin.artistDirectory.sectionFeedback.deleteFailure',
    }),
    onSuccess: () => {
      navigateTo({
        routeName: 'artist-details',
        routeProps: {
          defaultOpenSection: 'feedback',
          ...contentProps.artistData,
        },
      });
    },
  });

  return (
    <ModalContentContainer>
      <DetailsInfoMainContainer>
        <DetailsHeader
          title={get(contentProps, 'artistData.title', '')}
          imageUrl={get(contentProps, 'artistData.imageUrl', '')}
          largeImageUrl={get(contentProps, 'artistData.largeImageUrl', '')}
          imageStyle="circle"
        />
        <GridContainer>
          <BodyContainer data-qaid={`${contentProps.type}-feedbacks`}>
            <Title data-qaid="title">{title}</Title>
            <Spacer mt={4} />
            <Grid
              data-qaid={`${contentProps.type}-feedbacks-container`}
              gap="0px"
            >
              {feedbackList(
                feedbacksToDisplay,
                contentProps.type,
                onDeleteFeedback
              )}
            </Grid>
            {totalFeedbacksToDisplay.length > feedbacksToDisplay.length && (
              <LinkContainer>
                <MobileSpacer mt={4} />
                <LoadMoreButton
                  data-qaid="load-more-button"
                  onClick={() => {
                    setMaxNumFeedbacksToDisplay(
                      maxNumFeedbacksToDisplay + PAGE_SIZE
                    );
                  }}
                  loading={false}
                >
                  {intl.formatMessage({
                    id: 'admin.artistDirectory.sectionFeedback.more',
                  })}
                </LoadMoreButton>
              </LinkContainer>
            )}
          </BodyContainer>
        </GridContainer>
      </DetailsInfoMainContainer>
      {feedbackDeleteModal.isShowing && (
        <ConfirmationModal
          onCancel={() => feedbackDeleteModal.hide()}
          description={intl.formatMessage({
            id: 'admin.artistDirectory.sectionFeedback.deleteConfirmation',
          })}
          onConfirm={handleDeleteFeedback}
        />
      )}
    </ModalContentContainer>
  );
};

export default ArtistAllFeedbacks;
