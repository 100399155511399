import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { useCurrentTheme } from 'app/shared/theme';
import {
  Caption,
  FormGroupLabel,
} from 'app/shared/components/atoms/TypographyManualCSS';

interface Props {
  label: string;
  errorMsg?: string;
  onClickEmailClientEmulatorLink?: () => void;
  children: any;
}

const Labels = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
`;

const FieldLabel = styled(FormGroupLabel)`
  display: block;
  margin-right: 10px;
`;

const EmailClientEmulatorLink = styled.div`
  ${({ theme }) => css`
    font-size: 10px;
    font-weight: 500;
    letter-spacing: ${theme.fontStyles.formGroupLabel.letterSpacing};
    margin-bottom: ${theme.ruler[2]}px;
    color: ${theme.colors.green600};
    cursor: pointer;
  `}
`;

const EmailTestCaption = styled(Caption)`
  display: block;
  margin-top: 5px;
`;

const EmailTestFormGroup: React.FC<Props> = ({
  label,
  errorMsg,
  onClickEmailClientEmulatorLink,
  children,
}) => {
  const intl = useIntl();
  const theme = useCurrentTheme();

  return (
    <>
      <Labels>
        <FieldLabel color={errorMsg ? theme.colors.redRedWine : undefined}>
          {label}:
        </FieldLabel>
        {onClickEmailClientEmulatorLink && (
          <EmailClientEmulatorLink onClick={onClickEmailClientEmulatorLink}>
            {intl.formatMessage({
              id: 'admin.emailTest.useEmailClientEmulatorAddress',
            })}
          </EmailClientEmulatorLink>
        )}
      </Labels>
      {children}
      {errorMsg && (
        <EmailTestCaption color={theme.colors.redRedWine}>
          {errorMsg}
        </EmailTestCaption>
      )}
    </>
  );
};

export default EmailTestFormGroup;
