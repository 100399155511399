import { PromoCode, PromoCodeCustomPrice } from 'app/typings/promoCodes';
import { currencyFloatToInt } from 'app/shared/utils/currencyFormatter';
import { get } from 'app/shared/utils/get';

export const getCustomPricingEntries = (promoCode: PromoCode) =>
  get(promoCode, 'customPrices', []).reduce(
    (obj: any, customPrice: PromoCodeCustomPrice) => ({
      ...obj,
      [customPrice.numTickets]: customPrice.price / 100,
    }),
    {}
  );

export const getCustomPricesSubmitVariable = (customPricingEntries: any[]) =>
  [...Array(10).keys()]
    .map((index: number) =>
      customPricingEntries[index + 1] || customPricingEntries[index + 1] == 0
        ? {
            numTickets: index + 1,
            price: currencyFloatToInt(customPricingEntries[index + 1]),
          }
        : null
    )
    .filter(n => n);
