import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { Artist } from 'app/typings/artists';
import { User } from 'app/typings/users';
import { dateFormatter } from 'app/shared/utils/datetime';
import { get } from 'app/shared/utils/get';
import { buildFullName } from 'app/shared/utils/string';
import usePermission from 'app/shared/utils/usePermission';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import { GenericLink } from 'app/shared/components/atoms/LinkManualCSS';
import { BaseLink } from 'app/shared/components/atoms/LinkManualCSS';
import TruncatedByCharText from 'app/shared/components/atoms/TruncatedByCharText';
import { mobileNumberFormatter } from 'app/admin/utils/mobile';
import {
  DetailHeading as Heading,
  DetailValueContainer as ValueContainer,
} from 'app/admin/components/atoms/DetailContent';
import { None, NoneWrapper } from 'app/admin/components/atoms/None';
import { ArtistLink } from 'app/admin/components/molecules/EntityLink';

interface Props {
  sectionData: User;
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const Link = styled(BaseLink)`
  ${({ theme }) => css`
    cursor: pointer;
    color: ${theme.colors.green600};
  `}
`;

const renderAssociatedArtists = (sectionData: User) => {
  if (
    sectionData.associatedArtists &&
    sectionData.associatedArtists.length > 0
  ) {
    return sectionData.associatedArtists.map((artist: Artist, i: number) => (
      <ArtistLink artist={artist} truncateLength={35} key={i} />
    ));
  } else {
    return <None />;
  }
};

const renderTruncatedByCharTextValue = (value: string | undefined) => {
  return value ? (
    <TruncatedByCharText text={value} truncateLength={40} />
  ) : (
    <None />
  );
};

const SectionBasicInfo: React.FC<Props> = ({ sectionData }) => {
  const intl = useIntl();
  const hasViewUsersPermission = usePermission('user.list.access');

  const userName = buildFullName(sectionData.firstName, sectionData.lastName);

  const acknowledgedGdprAgreementAt = sectionData.acknowledgedGdprAgreementAt
    ? `Signed ${dateFormatter(
        sectionData.acknowledgedGdprAgreementAt,
        'shortMonthDateAndYear'
      )}`
    : intl.formatMessage({
        id: 'admin.userBasicInfo.heading.notSigned',
      });

  const acknowledgedCuratorAgreementAt = sectionData.acknowledgedCuratorAgreementAt
    ? `Signed ${dateFormatter(
        sectionData.acknowledgedCuratorAgreementAt,
        'shortMonthDateAndYear'
      )}`
    : intl.formatMessage({
        id: 'admin.userBasicInfo.heading.notSigned',
      });

  const acknowledgedOrDeclinedCuratorAgreement2At = sectionData.acknowledgedCuratorAgreement2At
    ? `Signed ${dateFormatter(
        sectionData.acknowledgedCuratorAgreement2At,
        'shortMonthDateAndYear'
      )}`
    : sectionData.declinedCuratorAgreement2At
    ? `Declined ${dateFormatter(
        sectionData.declinedCuratorAgreement2At,
        'shortMonthDateAndYear'
      )}`
    : intl.formatMessage({
        id: 'admin.userBasicInfo.heading.notSigned',
      });

  const hrisId = sectionData.hrisId;
  const hrisSystem = get(sectionData, 'hrisSystem.name', null);

  return (
    <MainContainer data-qaid="section-about">
      <Grid cols={3}>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={1}>
          <Heading>
            {intl.formatMessage({
              id: 'shared.name',
            })}
          </Heading>
          <ValueContainer>
            {renderTruncatedByCharTextValue(userName)}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'shared.homeCity',
            })}
          </Heading>
          <ValueContainer>
            {sectionData.homeCity ? (
              <>
                {sectionData.homeCity.title},{' '}
                {sectionData.homeCity.country.title}
                {sectionData.cityOther && <div>({sectionData.cityOther})</div>}
              </>
            ) : (
              <>
                {sectionData.cityOther ? (
                  <>{sectionData.cityOther}</>
                ) : (
                  <None />
                )}
              </>
            )}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'shared.email',
            })}
          </Heading>
          <ValueContainer>
            <Link href={`mailto:${sectionData.email}`} openInNewTab={true}>
              {renderTruncatedByCharTextValue(sectionData.email)}
            </Link>
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.userBasicInfo.heading.additionalEmail',
            })}
          </Heading>
          <ValueContainer>
            <Link
              href={`mailto:${sectionData.additionalEmail}`}
              openInNewTab={true}
            >
              {renderTruncatedByCharTextValue(sectionData.additionalEmail)}
            </Link>
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.userBasicInfo.heading.mobile',
            })}
          </Heading>
          <ValueContainer>
            {sectionData.mobile && sectionData.mobile.numberBody ? (
              <Link
                href={`tel:${mobileNumberFormatter(sectionData.mobile)}`}
                openInNewTab={true}
              >
                {mobileNumberFormatter(sectionData.mobile, true)}
              </Link>
            ) : (
              <None />
            )}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.userBasicInfo.heading.slackUserId',
            })}
          </Heading>
          <ValueContainer>{sectionData.slackUserId || <None />}</ValueContainer>
        </Col>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={2}>
          {hasViewUsersPermission && (
            <>
              <Heading>
                {intl.formatMessage({
                  id: 'admin.userBasicInfo.heading.associatedArtists',
                })}
              </Heading>
              <ValueContainer>
                {renderAssociatedArtists(sectionData)}
              </ValueContainer>
              <Heading>
                {intl.formatMessage({
                  id: 'admin.userBasicInfo.heading.hrisSystem',
                })}
              </Heading>
              <ValueContainer>
                {hrisSystem ? (
                  hrisSystem
                ) : (
                  <NoneWrapper>
                    {intl.formatMessage({
                      id: 'admin.notApplicable',
                    })}
                  </NoneWrapper>
                )}
              </ValueContainer>
              <Heading>
                {intl.formatMessage({
                  id: 'admin.userBasicInfo.heading.hrisId',
                })}
              </Heading>
              <ValueContainer>
                {hrisId ? (
                  hrisId
                ) : (
                  <NoneWrapper>
                    {intl.formatMessage({
                      id: 'admin.notApplicable',
                    })}
                  </NoneWrapper>
                )}
              </ValueContainer>
              <Heading>
                {intl.formatMessage({
                  id: 'admin.userBasicInfo.heading.acknowledgedGdprAgreementAt',
                })}
              </Heading>
              <ValueContainer>{acknowledgedGdprAgreementAt}</ValueContainer>
              <Heading>
                {intl.formatMessage({
                  id:
                    'admin.userBasicInfo.heading.acknowledgedCuratorAgreementAt',
                })}
              </Heading>
              <ValueContainer>{acknowledgedCuratorAgreementAt}</ValueContainer>
              <Heading>
                {intl.formatMessage({
                  id:
                    'admin.userBasicInfo.heading.acknowledgedCuratorAgreement2At',
                })}
              </Heading>
              <ValueContainer>
                {acknowledgedOrDeclinedCuratorAgreement2At}
              </ValueContainer>
            </>
          )}
        </Col>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={3}>
          {hasViewUsersPermission && (
            <GenericLink url={`/admin/users?user_id=${sectionData.id}`}>
              {intl.formatMessage({
                id: 'admin.userBasicInfo.viewInUserDirectory',
              })}
            </GenericLink>
          )}
        </Col>
      </Grid>
    </MainContainer>
  );
};

export default SectionBasicInfo;
