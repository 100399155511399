import gql from 'graphql-tag';

export const GET_COUNTRIES = gql`
  query GetCountries($countrySearch: String, $skipPagination: Boolean) {
    countries(countrySearch: $countrySearch, skipPagination: $skipPagination) {
      countries {
        id
        title
        countryCode
        cachedSlug
      }
    }
  }
`;
