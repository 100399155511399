import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { TopFeedbackPhrase } from 'app/typings/feedbacks';
import { dateFormatter } from 'app/shared/utils/datetime';
import { errorMsgForField } from 'app/shared/utils/form';
import { get } from 'app/shared/utils/get';
import { useTShirtSizeBreakpoint as useBreakpoint } from 'app/shared/utils/useBreakpoint';
import { Checkbox } from 'app/shared/components/atoms/CheckboxManualCSS';
import { FormSectionTitle } from 'app/shared/components/atoms/FormContent';
import GenericFormContainer from 'app/shared/components/atoms/GenericFormContainer';
import { LoadingError } from 'app/shared/components/atoms/LoadingError';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import FormGroup from 'app/shared/components/molecules/FormGroup';
import Select from 'app/shared/components/molecules/SelectManualCSS';
import { GetFeedbackPhrases } from 'app/admin/graphql/feedbacks/queryHooks';
import EditFormLoadingBlocks from 'app/admin/components/atoms/EditFormLoadingBlocks';

interface FormProps {
  formikProps: any;
  numContactInfos?: number;
}

interface FormGroupContainerProps {
  isMobile?: boolean;
}
const FormGroupContainer = styled.div<FormGroupContainerProps>`
  ${({ theme, isMobile }) => css`
    max-width: 700px;
    padding: 30px;
    background-color: ${theme.colors.silverSprings};
    width: ${isMobile ? '105%' : 'auto'};
  `}
`;

// Can't use H6 from atoms b/c get React error "Warning: validateDOMNesting(...): <h6> cannot appear as a descendant of <p>"
// so instead use a span tag with the same css
const NotBookLabel = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.h6.xs};
    font-weight: 600;
    letter-spacing: 0.2px;
    margin: 0;
    padding: 0;
    margin-bottom: 0px;
    ${theme.media.md`
      font-size: ${theme.fontSizes.h6.md};
    `};
    ${theme.media.lg`
      font-size: ${theme.fontSizes.h6.lg};
    `};
  `}
`;

const sortByPositiveAndPhrase = (
  fb1: TopFeedbackPhrase,
  fb2: TopFeedbackPhrase
) => {
  if (fb1.isPositive === fb2.isPositive) {
    return fb1.phrase > fb2.phrase ? 1 : -1;
  }

  return fb1.isPositive ? -1 : 1;
};

const ArtistFeedbackCreateForm: React.FC<FormProps> = ({ formikProps }) => {
  const intl = useIntl();

  const feedbackDropdownInitialState = Array(3).fill(null);

  const currentBreakpoint = useBreakpoint();
  const isMobile = currentBreakpoint === 'xs' || currentBreakpoint === 'sm';

  const { loading, error, data } = GetFeedbackPhrases();

  if (error) {
    return <LoadingError whatsBeingLoaded="this form" />;
  }

  if (loading || !data) {
    return <EditFormLoadingBlocks />;
  }

  const { setFieldValue, touched, errors, values } = formikProps;

  const feedbackPhrases = get(data, 'feedbackPhrases.feedbackPhrases', []);
  const feedbackPhraseOptions = feedbackPhrases.sort(sortByPositiveAndPhrase);

  return (
    <GenericFormContainer dataQaId="artist-add-feedback-form">
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.artistCreateFeedback.form.addFeedback',
        })}
      </FormSectionTitle>
      <FormGroupContainer
        data-qaid="artist-feedback-select-event-formgroup-container"
        isMobile={isMobile}
      >
        <FormGroup
          data-qaid="artist-feedback-select-event-formgroup"
          label={intl.formatMessage({
            id: 'admin.artistCreateFeedback.form.selectShow',
          })}
          errorMsg={errorMsgForField('event', touched, errors)}
        >
          <Select
            data-qaid="artist-feedback-form-event-field"
            id="event"
            name="event"
            getOptionLabel={(option: any) =>
              `${dateFormatter(option.localStartsAt, 'MMM d, yyyy h:mma')} - ${
                option.city.title
              }
                  `
            }
            options={values.eventOptions}
            placeholder={intl.formatMessage({
              id: 'admin.artistCreateFeedback.form.chooseAShow',
            })}
            onChange={(e: any) => {
              setFieldValue('event', {
                id: e.id,
                eventType: e.eventType,
              });
            }}
          />
        </FormGroup>
        <Spacer mb={8} />
        {feedbackDropdownInitialState.map((_, idx) => (
          <FormGroup
            data-qaid={`artist-feedback-${idx}-formgroup`}
            label=""
            errorMsg={
              idx === 0
                ? errorMsgForField('feedbackPhrasesIds', touched, errors)
                : null
            }
            key={`feedback-phrase-${idx}`}
          >
            <Select
              data-qaid={`artist-feedback-phrase-${idx}-field`}
              id={`feedbackPhrase${idx}`}
              name={`feedbackPhrase${idx}`}
              getOptionLabel={(option: any) => option.phrase}
              options={feedbackPhraseOptions}
              placeholder={intl.formatMessage({
                id: 'admin.artistCreateFeedback.form.selectFeedback',
              })}
              onChange={(opt: any) => {
                if (opt) {
                  setFieldValue(
                    'feedbackPhrasesIds',
                    Object.assign(values.feedbackPhrasesIds, {
                      [`phrase${idx + 1}`]: opt.id,
                    })
                  );
                }
              }}
            />
          </FormGroup>
        ))}
        <Spacer mb={8} />
        <FormGroup data-qaid="artist-feedback-form-booking-formgroup" label="">
          <Checkbox
            data-qaid="artist-feedback-form-booking-field"
            id="wouldNotBookAgain"
            name="wouldNotBookAgain"
            checked={values.wouldNotBookAgain}
            onChange={(e: any) => {
              setFieldValue('wouldNotBookAgain', e.target.checked);
            }}
          >
            <NotBookLabel>
              {intl.formatMessage({
                id: 'admin.artistCreateFeedback.form.wouldNotBookAgain',
              })}
            </NotBookLabel>
          </Checkbox>
        </FormGroup>
      </FormGroupContainer>
    </GenericFormContainer>
  );
};

export default ArtistFeedbackCreateForm;
