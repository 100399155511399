import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { City } from 'app/typings/cities';
import { Country } from 'app/typings/countries';
import { CuratorGroup } from 'app/typings/curatorGroups';
import { Event } from 'app/typings/events';
import { PromoCodeType, PurchaserType } from 'app/typings/promoCodes';
import { currencyLiteralUnitsFormatter } from 'app/shared/utils/currencyFormatter';
import { errorMsgForField } from 'app/shared/utils/form';
import { get } from 'app/shared/utils/get';
import { objectForObjectId, objectForObjectKey } from 'app/shared/utils/object';
import { Checkbox } from 'app/shared/components/atoms/CheckboxManualCSS';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import {
  FormGroupContainer,
  FormSectionTitle,
} from 'app/shared/components/atoms/FormContent';
import GenericFormContainer from 'app/shared/components/atoms/GenericFormContainer';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import { AddIcon, DeleteIcon } from 'app/shared/components/atoms/IconLibrary';
import { LoadingError } from 'app/shared/components/atoms/LoadingError';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Textfield } from 'app/shared/components/atoms/Textfield';
import FormGroup from 'app/shared/components/molecules/FormGroup';
import RadioGroup from 'app/shared/components/molecules/RadioGroup';
import Select from 'app/shared/components/molecules/SelectManualCSS';
import { optionsWithNoneOption } from 'app/admin/utils/optionLists';
import { GetCampaigns } from 'app/admin/graphql/campaigns/queryHooks';
import { GetCuratorGroupsLite } from 'app/admin/graphql/curatorGroups/queryHooks';
import {
  GetGenerationReasons,
  GetPromoCodeTypes,
} from 'app/admin/graphql/promoCodes/queryHooks';
import EditFormLoadingBlocks from 'app/admin/components/atoms/EditFormLoadingBlocks';
import SimpleDatePicker from 'app/admin/components/molecules/SimpleDatePicker';
import {
  BigNumberTextfield,
  NumberTextfield,
} from 'app/admin/components/molecules/Textfield';

import RulesForm from './RulesForm';

interface FormProps {
  formikProps: any;
  setFormSubmitAction?: (func: Function) => void;
  setDisplayConfirmation?: (set: boolean) => void;
  setIsSubmitting?: (set: boolean) => void;
  validationErrors?: object;
  curatorGroupIdsToLimitBy?: number[];
}

const IncludePostfixContainer = styled.div`
  padding-top: 25px;
`;

const CodePrefixTextfield = styled(Textfield)`
  width: 230px;
`;

const DatePickerWrapper = styled.div`
  margin-bottom: 3px;
`;

const CustomPricingFieldsContainer = styled.div`
  max-width: 180px !important;
  margin-bottom: 25px;
`;

const CustomPricingFieldText = styled.div`
  white-space: nowrap;
  padding-top: 13px;
`;

const AddIconContainer = styled.div`
  display: flex;
  margin-bottom: 30px;
  cursor: pointer;
  white-space: nowrap;
`;

const ActiveIconText = styled.div`
  ${({ theme }) => css`
    font-size: 10px;
    line-height: 150%;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    margin-left: 5px;
    margin-top: 2px;
    color: ${theme.colors.green600};
  `}
`;

const CustomPricingValidationError = styled.div`
  ${({ theme }) => css`
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.3px;
    font-weight: 400;
    color: ${theme.colors.redRedWine};
    margin-bottom: 20px;
  `}
`;

const CustomPricingEntries = styled.div`
  margin-bottom: 10px;
`;

const CustomPricingEntry = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 15px;
`;

const CustomPricingEntryText = styled.div`
  width: 200px;
  font-weight: 600;
`;

const CustomPricingEntryDelete = styled.div`
  width: 40px;
  padding-top: 2px;
  padding-left: 40px;
`;

const promoCodeTypeGroupDescriptions = {
  Marketing: 'Marketing - Each code can be used by multiple users',
  User: 'User - Each code can be used by one user',
  Partner: "Partner - Each code's pricing can be customized",
};

const getCuratorGroupData = (
  dataCuratorGroups: any,
  curatorGroupIds?: number[]
) => {
  const curatorGroups = get(
    dataCuratorGroups,
    'curatorGroups.curatorGroups',
    []
  );
  if (curatorGroupIds && curatorGroupIds.length > 0) {
    return curatorGroups.filter((curatorGroup: any) =>
      curatorGroupIds.includes(curatorGroup.id)
    );
  }
  return curatorGroups;
};

const PromoCodeBasicInfoForm: React.FC<FormProps> = ({
  formikProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  validationErrors = {},
  curatorGroupIdsToLimitBy,
}) => {
  const intl = useIntl();

  const [
    customPricingNumTicketsError,
    setCustomPricingNumTicketsError,
  ] = useState<string>('');
  const [
    customPricingPriceForTicketsError,
    setCustomPricingPriceForTicketsError,
  ] = useState<string>('');

  useEffect(() => {
    setFormSubmitAction && setFormSubmitAction(() => formikProps.submitForm);
    setDisplayConfirmation && setDisplayConfirmation(formikProps.dirty);
    setIsSubmitting && setIsSubmitting(formikProps.isSubmitting);
  }, [
    formikProps.isSubmitting,
    formikProps.submitForm,
    formikProps.dirty,
    setFormSubmitAction,
    setDisplayConfirmation,
    setIsSubmitting,
  ]);

  const {
    loading: loadingCuratorGroups,
    error: errorCuratorGroups,
    data: dataCuratorGroups,
  } = GetCuratorGroupsLite({});

  const {
    loading: loadingCampaigns,
    error: errorCampaigns,
    data: dataCampaigns,
  } = GetCampaigns({
    skipPagination: true,
  });

  const {
    loading: loadingPromoCodeTypes,
    error: errorPromoCodeTypes,
    data: dataPromoCodeTypes,
  } = GetPromoCodeTypes();

  const {
    loading: loadingGenerationReasons,
    error: errorGenerationReasons,
    data: dataGenerationReasons,
  } = GetGenerationReasons();

  if (
    errorCuratorGroups ||
    errorCampaigns ||
    errorPromoCodeTypes ||
    errorGenerationReasons
  ) {
    return <LoadingError whatsBeingLoaded="this form" />;
  }

  if (
    loadingCuratorGroups ||
    !dataCuratorGroups ||
    loadingCampaigns ||
    !dataCampaigns ||
    loadingPromoCodeTypes ||
    !dataPromoCodeTypes ||
    loadingGenerationReasons ||
    !dataGenerationReasons
  ) {
    return <EditFormLoadingBlocks />;
  }

  const curatorGroupOptions = getCuratorGroupData(
    dataCuratorGroups,
    curatorGroupIdsToLimitBy
  );

  const campaignOptions = optionsWithNoneOption(
    get(dataCampaigns, 'campaigns.campaigns', [])
  );

  const generationReasonOptions = optionsWithNoneOption(
    get(dataGenerationReasons, 'generationReasons.generationReasons', []).map(
      (generationReason: any) => ({
        id: generationReason.id,
        title: generationReason.description,
      })
    )
  );

  const { setFieldValue, touched, errors, values } = formikProps;

  const campaignForCampaignId = (campaignId: number | null) =>
    objectForObjectId(
      get(dataCampaigns, 'campaigns.campaigns', []),
      campaignId
    );

  const promoCodeTypeForType = (typeKey: string | null) =>
    objectForObjectKey(
      get(dataPromoCodeTypes, 'promoCodeTypes.promoCodeTypes', []),
      typeKey
    );

  const generationReasonForGenerationReasonId = (
    generationReasonId: number | null
  ) => {
    const generationReason = objectForObjectId(
      get(dataGenerationReasons, 'generationReasons.generationReasons', []),
      generationReasonId
    );
    if (generationReason) {
      return {
        id: generationReason.id,
        title: generationReason.description,
      };
    }
    return undefined;
  };

  const hasMultiplePromoCodes = () =>
    values.numPromoCodes && Number(values.numPromoCodes) > 1;

  const isPercentageDiscount = () =>
    values.type == 'PercentageDiscount' ||
    values.type == 'PercentageDiscountSingleUse';

  const isCustomPricing = () =>
    values.type == 'CustomPricing' || values.type == 'CustomPricingSingleUse';

  const isValidCustomPricingNumTickets = () => {
    if (
      values.customPricingNumTickets &&
      /^\d+$/.test(values.customPricingNumTickets.trim()) &&
      Number(values.customPricingNumTickets) >= 1 &&
      Number(values.customPricingNumTickets) <= 10
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isValidCustomPricingPriceForTickets = () => {
    if (
      values.customPricingPriceForTickets &&
      /^\d+$/.test(values.customPricingPriceForTickets.trim()) &&
      Number(values.customPricingNumTickets) >= 1
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleAddCustomPricingEntry = () => {
    if (!isValidCustomPricingNumTickets()) {
      setCustomPricingNumTicketsError(
        intl.formatMessage({
          id: 'admin.promoCodeBasicInfo.form.customPricingNumTicketsError',
        })
      );
      return;
    }
    if (!isValidCustomPricingPriceForTickets()) {
      setCustomPricingPriceForTicketsError(
        intl.formatMessage({
          id: 'admin.promoCodeBasicInfo.form.customPricingPriceForTicketsError',
        })
      );
      return;
    }
    setFieldValue(
      'customPricingEntries',
      Object.assign(values.customPricingEntries, {
        [Number(values.customPricingNumTickets)]: Number(
          values.customPricingPriceForTickets
        ),
      })
    );
  };

  const handleDeleteCustomPricingEntry = (entryNum: number) => {
    const entriesWithoutDeletedValue = values.customPricingEntries;
    delete entriesWithoutDeletedValue[entryNum];
    setFieldValue(
      'customPricingEntries',
      Object.assign(values.customPricingEntries, entriesWithoutDeletedValue)
    );
  };

  const handleAddRuleType = (ruleType: string) => {
    setFieldValue('ruleTypes', values.ruleTypes.concat([ruleType]));
  };

  const handleDeleteRuleType = (ruleType: string) => {
    setFieldValue(
      'ruleTypes',
      values.ruleTypes.filter((r: string) => r != ruleType)
    );

    switch (ruleType) {
      case 'for_day_of_week':
        setFieldValue('ruleDayOfWeek', '');
        break;
      case 'for_days_of_week':
        setFieldValue('ruleDaysOfWeek', []);
        break;
      case 'for_city':
        setFieldValue('ruleCity', null);
        break;
      case 'for_multi_city':
        setFieldValue('ruleCities', []);
        break;
      case 'for_country':
        setFieldValue('ruleCountry', null);
        break;
      case 'for_event':
        setFieldValue('ruleEvent', null);
        break;
      case 'for_event_type':
        setFieldValue('ruleEventType', '');
        break;
      case 'for_event_owner_types':
        setFieldValue('ruleEventOwnerTypes', []);
        break;
      case 'for_purchaser_type':
        setFieldValue('rulePurchaserType', null);
        break;
    }
  };

  const handleChangeRuleDayOfWeek = (dayOfWeek: string) => {
    setFieldValue('ruleDayOfWeek', dayOfWeek);
  };

  const handleChangeRuleDaysOfWeek = (daysOfWeek: string[]) => {
    setFieldValue('ruleDaysOfWeek', daysOfWeek);
  };

  const handleChangeRuleCity = (city: City) => {
    setFieldValue('ruleCity', city);
  };

  const handleChangeRuleCities = (cities: City[]) => {
    setFieldValue('ruleCities', cities);
  };

  const handleChangeRuleCountry = (country: Country) => {
    setFieldValue('ruleCountry', country);
  };

  const handleChangeRuleEvent = (event: Event | null) => {
    setFieldValue('ruleEvent', event);
  };

  const handleChangeRuleEventType = (eventType: string) => {
    setFieldValue('ruleEventType', eventType);
  };

  const handleChangeRuleEventOwnerTypes = (eventOwnerTypes: string[]) => {
    setFieldValue('ruleEventOwnerTypes', eventOwnerTypes);
  };

  const handleChangeRulePurchaserType = (purchaserType: PurchaserType) => {
    setFieldValue('rulePurchaserType', purchaserType);
  };

  const rulesFormValidationErrors = [
    errorMsgForField('ruleDayOfWeek', touched, errors),
    errorMsgForField('ruleDaysOfWeek', touched, errors),
    errorMsgForField('ruleCity', touched, errors),
    errorMsgForField('ruleCities', touched, errors),
    errorMsgForField('ruleCountry', touched, errors),
    errorMsgForField('ruleEvent', touched, errors),
    errorMsgForField('ruleEventType', touched, errors),
    errorMsgForField('ruleEventOwnerTypes', touched, errors),
    errorMsgForField('rulePurchaserType', touched, errors),
  ].filter(n => n);

  return (
    <GenericFormContainer dataQaId="promo-code-edit-form">
      {values.hasCuratorGroupField && (
        <>
          <FormSectionTitle>
            {intl.formatMessage({
              id: 'admin.promoCodeBasicInfo.form.curatorGroupHeader',
            })}
          </FormSectionTitle>
          <FormGroupContainer>
            <FormGroup
              data-qaid="promo-code-edit-form-curator-group-formgroup"
              label={intl.formatMessage({
                id: 'admin.promoCodeBasicInfo.form.curatorGroup',
              })}
              errorMsg={errorMsgForField('curatorGroup', touched, errors)}
              required
            >
              <Select
                data-qaid="promo-code-edit-form-curator-group-selector"
                searchable
                getOptionLabel={(opt: any) => opt.name}
                defaultValue={undefined}
                options={curatorGroupOptions}
                placeholder={intl.formatMessage({
                  id: 'admin.promoCodeBasicInfo.form.curatorGroupPlaceholder',
                })}
                onChange={(curatorGroup: CuratorGroup) => {
                  setFieldValue('curatorGroup', curatorGroup);
                }}
              />
            </FormGroup>
          </FormGroupContainer>
        </>
      )}
      {values.isNewPromoCode && (
        <>
          <FormSectionTitle>
            {intl.formatMessage({
              id: 'admin.promoCodeBasicInfo.form.numPromoCodesHeader',
            })}
          </FormSectionTitle>
          <FormGroupContainer>
            <FormGroup
              data-qaid="promo-code-edit-form-num-promo-codes-formgroup"
              label={intl.formatMessage({
                id: 'admin.promoCodeBasicInfo.form.numPromoCodes',
              })}
              errorMsg={errorMsgForField('numPromoCodes', touched, errors)}
              required
            >
              <BigNumberTextfield
                data-qaid="promo-code-edit-form-num-promo-codes-field"
                id="numPromoCodes"
                name="numPromoCodes"
                value={values.numPromoCodes}
                onChange={(e: any) => {
                  setFieldValue('numPromoCodes', e.target.value);
                }}
                maxLength={6}
              />
            </FormGroup>
          </FormGroupContainer>
        </>
      )}
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.promoCodeBasicInfo.form.campaignHeader',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          label={intl.formatMessage({
            id: 'admin.promoCodeBasicInfo.form.campaign',
          })}
          data-qaid="promo-code-edit-form-campaign-formgroup"
          errorMsg={errorMsgForField('campaignId', touched, errors)}
        >
          <Select
            data-qaid="promo-code-edit-form-campaign-field"
            searchable
            getOptionLabel={(opt: any) => opt.title}
            defaultValue={campaignForCampaignId(values.campaignId)}
            options={campaignOptions}
            placeholder={intl.formatMessage({
              id: 'admin.promoCodeBasicInfo.form.campaignPlaceholder',
            })}
            onChange={campaign =>
              setFieldValue(
                'campaignId',
                campaign && campaign.id ? campaign.id : null
              )
            }
          />
        </FormGroup>
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.promoCodeBasicInfo.form.typeHeader',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          label={intl.formatMessage({
            id: 'admin.promoCodeBasicInfo.form.type',
          })}
          data-qaid="promo-code-edit-form-type-formgroup"
          errorMsg={errorMsgForField('type', touched, errors)}
          required
        >
          <Select
            data-qaid="promo-code-edit-form-type-field"
            getOptionLabel={(opt: any) => opt.name}
            defaultValue={promoCodeTypeForType(values.type)}
            options={get(
              dataPromoCodeTypes,
              'promoCodeTypes.promoCodeTypes',
              []
            )}
            placeholder={intl.formatMessage({
              id: 'admin.promoCodeBasicInfo.form.typePlaceholder',
            })}
            onChange={type => {
              setFieldValue('type', type.key);
              setFieldValue('percentageDiscount', '');
              setFieldValue('customPricingNumTickets', '');
              setFieldValue('customPricingPriceForTickets', '');
              setFieldValue('customPricingEntries', {});
              setCustomPricingNumTicketsError('');
              setCustomPricingPriceForTicketsError('');
            }}
            groupBy={(promoCodeType: PromoCodeType) =>
              promoCodeTypeGroupDescriptions[promoCodeType.typeGroupName]
            }
          />
        </FormGroup>
      </FormGroupContainer>
      {values.isNewPromoCode && (
        <>
          <FormSectionTitle>
            {intl.formatMessage({
              id: 'admin.promoCodeBasicInfo.form.codeHeader',
            })}
          </FormSectionTitle>
          <FormGroupContainer>
            <Grid cols={2}>
              <Col xs={12} sm={12} md={1} lg={1} xl={1} key={1}>
                <FormGroup
                  data-qaid="promo-code-edit-form-code-formgroup"
                  label={intl.formatMessage({
                    id: `${
                      hasMultiplePromoCodes()
                        ? 'admin.promoCodeBasicInfo.form.codePrefix'
                        : 'admin.promoCodeBasicInfo.form.code'
                    }`,
                  })}
                  errorMsg={
                    errorMsgForField('codePrefix', touched, errors) ||
                    // @ts-ignore
                    validationErrors.general
                  }
                  required
                >
                  <CodePrefixTextfield
                    data-qaid="promo-code-edit-form-code-field"
                    id="codePrefix"
                    name="codePrefix"
                    value={values.codePrefix}
                    placeholder={intl.formatMessage({
                      id: 'admin.promoCodeBasicInfo.form.codePlaceholder',
                    })}
                    onChange={(e: any) => {
                      setFieldValue('codePrefix', e.target.value);
                    }}
                    maxLength={30}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} sm={12} md={1} lg={1} xl={1} key={2}>
                <IncludePostfixContainer>
                  {hasMultiplePromoCodes() ? (
                    intl.formatMessage({
                      id:
                        'admin.promoCodeBasicInfo.form.codeRandomCharactersWillBeAdded',
                    })
                  ) : (
                    <Checkbox
                      data-qaid="promo-code-edit-form-include-postfix-field"
                      id="promo-code-edit-form-include-postfix-field"
                      name="includePostfix"
                      checked={values.includePostfix}
                      onChange={e =>
                        setFieldValue('includePostfix', e.target.checked)
                      }
                    >
                      {intl.formatMessage({
                        id:
                          'admin.promoCodeBasicInfo.form.codeAddRandomCharacters',
                      })}
                    </Checkbox>
                  )}
                </IncludePostfixContainer>
              </Col>
            </Grid>
          </FormGroupContainer>
        </>
      )}
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.promoCodeBasicInfo.form.reasonHeader',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          label={intl.formatMessage({
            id: 'admin.promoCodeBasicInfo.form.reason',
          })}
          data-qaid="promo-code-edit-form-reason-formgroup"
          errorMsg={errorMsgForField('generationReasonId', touched, errors)}
        >
          <Select
            data-qaid="promo-code-edit-form-reason-field"
            searchable
            getOptionLabel={(opt: any) => opt.title}
            defaultValue={generationReasonForGenerationReasonId(
              values.generationReasonId
            )}
            options={generationReasonOptions}
            placeholder={intl.formatMessage({
              id: 'admin.promoCodeBasicInfo.form.reasonPlaceholder',
            })}
            onChange={generationReason =>
              setFieldValue(
                'generationReasonId',
                generationReason && generationReason.id
                  ? generationReason.id
                  : null
              )
            }
          />
        </FormGroup>
      </FormGroupContainer>
      {isPercentageDiscount() && (
        <>
          <FormSectionTitle>
            {intl.formatMessage({
              id: 'admin.promoCodeBasicInfo.form.percentageDiscountHeader',
            })}
          </FormSectionTitle>
          <FormGroupContainer>
            <FormGroup
              data-qaid="promo-code-edit-form-percentage-discount-formgroup"
              label={intl.formatMessage({
                id: 'admin.promoCodeBasicInfo.form.percentageDiscount',
              })}
              errorMsg={errorMsgForField('percentageDiscount', touched, errors)}
              required
            >
              <NumberTextfield
                data-qaid="promo-code-edit-form-percentage-discount-field"
                id="percentageDiscount"
                name="percentageDiscount"
                value={values.percentageDiscount}
                onChange={(e: any) => {
                  setFieldValue('percentageDiscount', e.target.value);
                }}
                maxLength={3}
              />
            </FormGroup>
          </FormGroupContainer>
        </>
      )}
      {isCustomPricing() && (
        <>
          <FormSectionTitle>
            {intl.formatMessage({
              id: 'admin.promoCodeBasicInfo.form.customPricingHeader',
            })}
          </FormSectionTitle>
          <FormGroupContainer>
            <CustomPricingFieldsContainer>
              <Grid cols={4}>
                <Col xs={12} sm={12} md={1} lg={1} xl={1} key={1}>
                  <CustomPricingFieldText>
                    {intl.formatMessage({
                      id: 'admin.promoCodeBasicInfo.form.customPricingPriceFor',
                    })}
                  </CustomPricingFieldText>
                </Col>
                <Col xs={12} sm={12} md={1} lg={1} xl={1} key={2}>
                  <NumberTextfield
                    data-qaid="promo-code-edit-form-custom-pricing-num-tickets-field"
                    id="customPricingNumTickets"
                    name="customPricingNumTickets"
                    value={values.customPricingNumTickets}
                    placeholder="e.g. 2"
                    onChange={(e: any) => {
                      setFieldValue('customPricingNumTickets', e.target.value);
                      setCustomPricingNumTicketsError('');
                    }}
                    maxLength={2}
                  />
                </Col>
                <Col xs={12} sm={12} md={1} lg={1} xl={1} key={3}>
                  <CustomPricingFieldText>
                    {intl.formatMessage({
                      id: 'admin.promoCodeBasicInfo.form.customPricingTickets',
                    })}
                  </CustomPricingFieldText>
                </Col>
                <Col xs={12} sm={12} md={1} lg={1} xl={1} key={4}>
                  <NumberTextfield
                    data-qaid="promo-code-edit-form-custom-pricing-price-for-tickets-field"
                    id="customPricingPriceForTickets"
                    name="customPricingPriceForTickets"
                    value={values.customPricingPriceForTickets}
                    placeholder="e.g. 35"
                    onChange={(e: any) => {
                      setFieldValue(
                        'customPricingPriceForTickets',
                        e.target.value
                      );
                      setCustomPricingPriceForTicketsError('');
                    }}
                    maxLength={3}
                  />
                </Col>
              </Grid>
            </CustomPricingFieldsContainer>
            {customPricingNumTicketsError && (
              <CustomPricingValidationError>
                {customPricingNumTicketsError}
              </CustomPricingValidationError>
            )}
            {customPricingPriceForTicketsError && (
              <CustomPricingValidationError>
                {customPricingPriceForTicketsError}
              </CustomPricingValidationError>
            )}
            {errorMsgForField('customPricingEntries', touched, errors) && (
              <CustomPricingValidationError>
                {errorMsgForField('customPricingEntries', touched, errors)}
              </CustomPricingValidationError>
            )}
            <AddIconContainer onClick={() => handleAddCustomPricingEntry()}>
              <AddIcon />
              <ActiveIconText>
                {intl.formatMessage({
                  id:
                    'admin.promoCodeBasicInfo.form.customPricingAddTicketAmount',
                })}
              </ActiveIconText>
            </AddIconContainer>
            <DottedLine />
            <Spacer mb={8} />
            <CustomPricingEntries>
              {Object.entries(values.customPricingEntries).map(
                ([numTickets, priceForTickets], index) => (
                  <CustomPricingEntry key={index}>
                    <CustomPricingEntryText>
                      {Number(numTickets) == 1
                        ? intl.formatMessage(
                            {
                              id:
                                'admin.promoCodeBasicInfo.form.customPricingPriceForTicket',
                            },
                            {
                              price: currencyLiteralUnitsFormatter(
                                Number(priceForTickets),
                                '$'
                              ),
                            }
                          )
                        : intl.formatMessage(
                            {
                              id:
                                'admin.promoCodeBasicInfo.form.customPricingPriceForTickets',
                            },
                            {
                              numTickets: numTickets.toString(),
                              price: currencyLiteralUnitsFormatter(
                                Number(priceForTickets),
                                '$'
                              ),
                            }
                          )}
                    </CustomPricingEntryText>
                    <CustomPricingEntryDelete>
                      <DeleteIcon
                        onClick={() =>
                          handleDeleteCustomPricingEntry(Number(numTickets))
                        }
                      />
                    </CustomPricingEntryDelete>
                  </CustomPricingEntry>
                )
              )}
            </CustomPricingEntries>
          </FormGroupContainer>
        </>
      )}
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.promoCodeBasicInfo.form.validDatesHeader',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          label={intl.formatMessage({
            id: 'admin.promoCodeBasicInfo.form.validFrom',
          })}
          data-qaid="promo-code-edit-form-valid-from-formgroup"
          errorMsg={errorMsgForField('validFrom', touched, errors)}
          required
        >
          <DatePickerWrapper>
            <SimpleDatePicker
              data-qaid="promo-code-edit-form-valid-from-datepicker"
              name="validFrom"
              value={values.validFrom}
              placeholder="Select date"
              onChange={(e: any) => {
                const date = e.startOf('day');
                setFieldValue('validFrom', date);
              }}
            />
          </DatePickerWrapper>
        </FormGroup>
        <Spacer mb={6} />
        <FormGroup
          label={intl.formatMessage({
            id: 'admin.promoCodeBasicInfo.form.expiresAt',
          })}
          data-qaid="promo-code-edit-form-expires-at-formgroup"
          errorMsg={errorMsgForField('expiresAt', touched, errors)}
          required
        >
          <DatePickerWrapper>
            <SimpleDatePicker
              data-qaid="promo-code-edit-form-expires-at-datepicker"
              name="expiresAt"
              value={values.expiresAt}
              placeholder="Select date"
              onChange={(e: any) => {
                const date = e.startOf('day');
                setFieldValue('expiresAt', date);
              }}
              numDaysIntoFutureToDisable={0}
            />
          </DatePickerWrapper>
        </FormGroup>
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.promoCodeBasicInfo.form.maxUsesHeader',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <Grid>
          <Col sm={12}>
            <RadioGroup
              name="hasUnlimitedUses"
              options={[
                {
                  title: intl.formatMessage({
                    id: 'admin.promoCodeBasicInfo.form.maxUsesUnlimited',
                  }),
                  value: true,
                },
                {
                  title: intl.formatMessage({
                    id: 'admin.promoCodeBasicInfo.form.maxUsesLimited',
                  }),
                  value: false,
                },
              ]}
              selectedValue={values.hasUnlimitedUses}
              onChange={value => {
                setFieldValue('hasUnlimitedUses', value);
                if (value === true) {
                  setFieldValue('maxUses', '');
                }
              }}
            />
          </Col>
          <Col sm={8}>
            <FormGroup
              data-qaid="promo-code-edit-form-max-uses-formgroup"
              label={intl.formatMessage({
                id: 'admin.promoCodeBasicInfo.form.maxUsesEnterNum',
              })}
              errorMsg={errorMsgForField('maxUses', touched, errors)}
              required={!values.hasUnlimitedUses}
            >
              <NumberTextfield
                data-qaid="promo-code-edit-form-max-uses-field"
                id="maxUses"
                name="maxUses"
                value={values.maxUses || ''}
                onChange={(e: any) => {
                  setFieldValue('maxUses', e.target.value);
                }}
                maxLength={3}
                disabled={values.hasUnlimitedUses}
              />
            </FormGroup>
          </Col>
        </Grid>
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.promoCodeBasicInfo.form.numUsesPerUserHeader',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <Grid>
          <Col sm={12}>
            <RadioGroup
              name="hasUnlimitedUsesPerUser"
              options={[
                {
                  title: intl.formatMessage({
                    id: 'admin.promoCodeBasicInfo.form.numUsesPerUserUnlimited',
                  }),
                  value: true,
                },
                {
                  title: intl.formatMessage({
                    id: 'admin.promoCodeBasicInfo.form.numUsesPerUserLimited',
                  }),
                  value: false,
                },
              ]}
              selectedValue={values.hasUnlimitedUsesPerUser}
              onChange={value => {
                setFieldValue('hasUnlimitedUsesPerUser', value);
                if (value === true) {
                  setFieldValue('maxUsesPerUser', '');
                }
              }}
            />
          </Col>
          <Col sm={8}>
            <FormGroup
              data-qaid="promo-code-edit-form-num-uses-per-user-formgroup"
              label={intl.formatMessage({
                id: 'admin.promoCodeBasicInfo.form.numUsesPerUserEnterNum',
              })}
              errorMsg={errorMsgForField('maxUsesPerUser', touched, errors)}
              required={!values.hasUnlimitedUsesPerUser}
            >
              <NumberTextfield
                data-qaid="promo-code-edit-form-num-uses-per-user-field"
                id="maxUsesPerUser"
                name="maxUsesPerUser"
                value={values.maxUsesPerUser}
                onChange={(e: any) => {
                  setFieldValue('maxUsesPerUser', e.target.value);
                }}
                maxLength={3}
                disabled={values.hasUnlimitedUsesPerUser}
              />
            </FormGroup>
          </Col>
        </Grid>
      </FormGroupContainer>
      {(!curatorGroupIdsToLimitBy || curatorGroupIdsToLimitBy.length == 0) && (
        <RulesForm
          ruleTypes={values.ruleTypes}
          ruleDayOfWeek={values.ruleDayOfWeek}
          ruleDaysOfWeek={values.ruleDaysOfWeek}
          ruleCity={values.ruleCity}
          ruleCities={values.ruleCities}
          ruleCountry={values.ruleCountry}
          ruleEvent={values.ruleEvent}
          ruleEventType={values.ruleEventType}
          ruleEventOwnerTypes={values.ruleEventOwnerTypes}
          rulePurchaserType={values.rulePurchaserType}
          handleAddRuleType={handleAddRuleType}
          handleDeleteRuleType={handleDeleteRuleType}
          handleChangeRuleDayOfWeek={handleChangeRuleDayOfWeek}
          handleChangeRuleDaysOfWeek={handleChangeRuleDaysOfWeek}
          handleChangeRuleCity={handleChangeRuleCity}
          handleChangeRuleCities={handleChangeRuleCities}
          handleChangeRuleCountry={handleChangeRuleCountry}
          handleChangeRuleEvent={handleChangeRuleEvent}
          handleChangeRuleEventType={handleChangeRuleEventType}
          handleChangeRuleEventOwnerTypes={handleChangeRuleEventOwnerTypes}
          handleChangeRulePurchaserType={handleChangeRulePurchaserType}
          formValidationErrors={rulesFormValidationErrors}
        />
      )}
    </GenericFormContainer>
  );
};

export default PromoCodeBasicInfoForm;
