import { useQuery } from 'react-apollo';

import {
  GetArtistSetupsData,
  GetArtistSetupsVariables,
} from 'app/typings/artistSetups';
import { queryParams } from 'app/shared/utils/queries';
import { GET_ARTIST_SETUPS } from 'app/shared/graphql/artistSetups/queries';

export const GetArtistSetups = (params: GetArtistSetupsVariables = {}) => {
  return useQuery<GetArtistSetupsData>(GET_ARTIST_SETUPS, queryParams(params));
};
