import React from 'react';
import { useIntl } from 'react-intl';

import { get } from 'app/shared/utils/get';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { UpdateCity } from 'app/admin/graphql/cities/mutationHooks';
import { DetailsFormMainContainer } from 'app/admin/components/atoms/DetailContent';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

import CityEmailsEditForm from './CityEmailsEditForm';
import CityEmailsEditFormSchema from './CityEmailsEditFormSchema';

interface Props {
  contentProps?: any;
  setFormSubmitAction: (func: Function) => void;
  setDisplayConfirmation: (set: boolean) => void;
  setIsSubmitting: (set: boolean) => void;
  navigateTo: (routeData: object) => void;
}

export const ARTIST_APPLICATION_EMAIL_FIELDS = [
  'artistApplicationNotificationEmail1',
  'artistApplicationNotificationEmail2',
  'artistApplicationNotificationEmail3',
  'artistApplicationNotificationEmail4',
  'artistApplicationNotificationEmail5',
  'artistApplicationNotificationEmail6',
];

const artistApplicationNotificationEmailsForSubmit = (values: any) => {
  const emails = ARTIST_APPLICATION_EMAIL_FIELDS.map((emailField: string) => {
    return values.artistApplicationNotificationEmails[`${emailField}`];
  });

  return emails.filter(Boolean);
};

const CityEmailsEdit: React.FC<Props> = ({
  contentProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  navigateTo,
}) => {
  const intl = useIntl();

  const artistApplicationNotificationEmails = get(
    contentProps,
    'artistApplicationNotificationEmails',
    ''
  );

  const formInitialValues = {
    fromEmail: get(contentProps, 'fromEmail', '') || '',
    inviteEmailMessage: get(contentProps, 'inviteEmailMessage', '') || '',
    revealAddressEmailMessage:
      get(contentProps, 'revealAddressEmailMessage', '') || '',
    crewOpsId: get(contentProps, 'mainCrewOps.id', '') || null,
    venueOpsId: get(contentProps, 'mainVenueOps.id', '') || null,
    artistOpsId: get(contentProps, 'mainArtistOps.id', '') || null,
    useFromEmailForAllEmails: get(
      contentProps,
      'useFromEmailForAllEmails',
      false
    ),
    isAutomatedEmailEnabled: get(
      contentProps,
      'isAutomatedEmailEnabled',
      false
    ),
    isArtistApplicationNotificationEnabled: get(
      contentProps,
      'isArtistApplicationNotificationEnabled',
      false
    ),
    artistApplicationNotificationEmails: {},
  };

  ARTIST_APPLICATION_EMAIL_FIELDS.forEach(
    (emailField: string, index: number) => {
      formInitialValues.artistApplicationNotificationEmails[emailField] =
        artistApplicationNotificationEmails[index];
    }
  );

  const updateCityAction = UpdateCity();

  const handleSubmit = useSubmitAction({
    submitAction: updateCityAction,
    submitVariables: (values: any) => ({
      cityId: get(contentProps, 'id', undefined),
      fromEmail: values.fromEmail,
      inviteEmailMessage: values.inviteEmailMessage,
      revealAddressEmailMessage: values.revealAddressEmailMessage,
      crewOpsId: values.crewOpsId,
      venueOpsId: values.venueOpsId,
      artistOpsId: values.artistOpsId,
      useFromEmailForAllEmails: values.useFromEmailForAllEmails,
      isAutomatedEmailEnabled: values.isAutomatedEmailEnabled,
      isArtistApplicationNotificationEnabled:
        values.isArtistApplicationNotificationEnabled,
      artistApplicationNotificationEmails: artistApplicationNotificationEmailsForSubmit(
        values
      ),
    }),
    successMsg: intl.formatMessage({
      id: 'admin.cityBasicInfo.form.successMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.cityBasicInfo.form.failureMessage',
    }),
    onSuccess: response => {
      navigateTo({
        routeName: 'city-details',
        routeProps: {
          defaultOpenSection: 'emails',
          ...response.data.updateCity.city,
        },
      });
    },
  });

  return (
    <ModalContentContainer>
      <DetailsFormMainContainer>
        <DetailsHeader title={get(contentProps, 'title', '')} />
        <GenericForm
          formInitialValues={formInitialValues}
          renderFormComponent={(renderProps: any) => (
            <CityEmailsEditForm
              formikProps={renderProps.formikProps}
              cityCachedSlug={get(contentProps, 'cachedSlug', '')}
              cityOperatingModel={get(contentProps, 'cityOperatingModel', '')}
            />
          )}
          onSubmit={handleSubmit}
          formSchema={CityEmailsEditFormSchema(intl)}
          setFormSubmitAction={setFormSubmitAction}
          setDisplayConfirmation={setDisplayConfirmation}
          setIsSubmitting={setIsSubmitting}
          dataQaId="city-emails-section-edit-form"
        />
      </DetailsFormMainContainer>
    </ModalContentContainer>
  );
};

export default CityEmailsEdit;
