import React, { useState } from 'react';
import styled from 'styled-components';

import { Property } from 'app/typings/properties';
import { Icon } from 'app/shared/components/atoms/IconManualCSS';
import NoneTag from 'app/admin/components/atoms/NoneTag';
import ToggleText from 'app/admin/components/atoms/ToggleText';

interface PropertyListProps {
  properties?: Property[];
}

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  width: 75%;
`;

const PropertyContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 10px;
`;

const PropertyIcon = styled.div`
  margin-top: 1px;
`;

const ColoredIcon = styled(Icon)`
  &::before {
    color: #98035a;
  }
`;

const PropertyText = styled.div`
  margin-left: 17px;
`;

const ToggleContainer = styled.div`
  margin-top: 0px;
`;

export const renderProperties = (properties: Property[]) => {
  return properties.map((property: Property, i: number) => (
    <PropertyContainer key={i} data-qaid={`property-${i}`}>
      <PropertyIcon>
        <ColoredIcon name={property.iconName} size="16px" />
      </PropertyIcon>
      <PropertyText>{property.name}</PropertyText>
    </PropertyContainer>
  ));
};

const maxNumPropertiesToDisplayBeforeOverflow = 6;

export const PropertyList: React.FC<PropertyListProps> = ({
  properties = [],
}) => {
  const [showFullList, setShowFullList] = useState(false);

  return (
    <div>
      <ListContainer>
        {!properties || properties.length == 0 ? (
          <NoneTag>NONE</NoneTag>
        ) : (
          renderProperties(
            showFullList
              ? properties
              : properties.slice(0, maxNumPropertiesToDisplayBeforeOverflow)
          )
        )}
      </ListContainer>
      {properties &&
        properties.length > maxNumPropertiesToDisplayBeforeOverflow && (
          <ToggleContainer data-qaid="property-toggle">
            <ToggleText onClick={() => setShowFullList(!showFullList)}>
              {showFullList ? 'LESS' : 'MORE'}
            </ToggleText>
          </ToggleContainer>
        )}
    </div>
  );
};
