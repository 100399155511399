import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { get } from 'app/shared/utils/get';
import useModal from 'app/shared/utils/useModal';
import { GetBroadcasts } from 'app/shared/graphql/broadcasts/queryHooks';
import { ListPage, ListPageContext } from 'app/shared/context/ListPage';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import { LoadingBlocks } from 'app/shared/components/atoms/LoadingBlocks';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import RoutableModal from 'app/shared/components/molecules/RoutableModal';
import { GlobalError } from 'app/shared/components/pages/Status';
import { getFilterNamesAndOptionsForTitleMapping } from 'app/admin/utils/optionLists';
import ListingFooter from 'app/admin/components/molecules/ListingFooter';
import ListingNoResults from 'app/admin/components/molecules/ListingNoResults';
import BroadcastCard from 'app/admin/components/organisms/BroadcastCard';
import CardGrid, { CardType } from 'app/admin/components/organisms/CardGrid';
import ListingControls from 'app/admin/components/organisms/ListingControls';
import ListingFilter from 'app/admin/components/organisms/ListingFilter';
import ListingHeader from 'app/admin/components/organisms/ListingHeader';
import ListPageTemplate from 'app/admin/components/templates/ListPage';
import Layout from 'app/admin/layouts/ListPage';

import { scopeOptions, sortOptions, streamTypeOptions } from './options';
import Analytics from './tracking';

const pageStateConfig = {
  filterNames: ['scope', 'stream_type'],
  defaultSort: { by: 'start_time', direction: 'asc' },
  textSearchParamName: 'broadcast_search',
};

const AdminBroadcasts: React.FC = () => {
  const intl = useIntl();
  const pageState = useContext(ListPageContext);
  const PER_PAGE = 18;

  const filterDropdownOptionsInfoList = [
    {
      filterName: 'scope',
      dropdownParams: {
        title: intl.formatMessage({
          id: 'admin.broadcasts.filter.scope',
        }),
        options: scopeOptions,
      },
    },
    {
      filterName: 'stream_type',
      dropdownParams: {
        title: intl.formatMessage({
          id: 'admin.broadcasts.filter.streamType',
        }),
        options: streamTypeOptions,
      },
    },
  ];

  const [addBroadcastModal, toggleAddBroadcastModal] = useModal();
  const [editBroadcastModal, toggleEditBroadcastModal] = useModal();
  const [broadcastData, setBroadcastData] = useState<any>({});

  const {
    loading: loadingBroadcasts,
    error: errorBroadcasts,
    data: dataBroadcasts,
    refetch: refetchBroadcasts,
  } = GetBroadcasts({
    broadcastSearch: pageState.textSearchState,
    scope: pageState.filterListVariable('scope'),
    streamType: pageState.filterListVariable('stream_type'),
    orderBy: pageState.sortState.by,
    orderDirection: pageState.sortState.direction,
    page: pageState.page,
    perPage: PER_PAGE,
  });

  useEffect(() => {
    pageState.setupFilterLabelTitleMapping(
      getFilterNamesAndOptionsForTitleMapping(filterDropdownOptionsInfoList)
    );
  }, []);

  useEffect(() => {
    pageState.updatePageUrl();
  }, [
    pageState.page,
    pageState.sortState,
    pageState.filterState,
    pageState.textSearchState,
    pageState.detailData,
    pageState.detailsModal.isShowing,
  ]);

  if ((!loadingBroadcasts && !dataBroadcasts) || errorBroadcasts) {
    return <GlobalError />;
  }

  const modalsContent = () => (
    <>
      {addBroadcastModal.isShowing && (
        <RoutableModal
          hide={addBroadcastModal.hide}
          initialRouteProps={{ refetchBroadcasts }}
          initialRouteName="broadcast-create"
          dataQaidSuffix="admin-create-broadcast"
        />
      )}
      {editBroadcastModal.isShowing && (
        <RoutableModal
          hide={editBroadcastModal.hide}
          initialRouteProps={{
            ...broadcastData,
            refetchBroadcasts,
          }}
          initialRouteName="broadcast-edit"
          dataQaidSuffix="admin-edit-broadcast"
        />
      )}
    </>
  );

  return (
    <Layout scrollDisabled={pageState.detailsModal.isShowing}>
      <ListPageTemplate
        pageTitle={intl.formatMessage({
          id: 'admin.broadcasts.pageTitle',
        })}
        modalsContent={modalsContent}
      >
        <ListingHeader
          pageTitle={intl.formatMessage({
            id: 'admin.broadcasts.pageTitle',
          })}
          addEntityText={intl.formatMessage({
            id: 'admin.broadcasts.addBroadcast',
          })}
          onClickAddEntity={toggleAddBroadcastModal}
          searchPlaceholder={intl.formatMessage({
            id: 'admin.broadcasts.textSearch.placeholder',
          })}
          searchValue={pageState.textSearchState}
          onSearch={pageState.handleTextSearch}
          dataQaidPrefix="broadcast"
        />
        <div>
          <ListingControls
            sortOptions={sortOptions}
            orderBy={pageState.sortState.by}
            orderDirection={pageState.sortState.direction}
            totalRecords={
              dataBroadcasts && dataBroadcasts.broadcasts.metadata.totalRecords
            }
            loading={loadingBroadcasts}
            onSort={pageState.handleSortChange}
            onReset={pageState.handleResetFilters}
            dataQaidPrefix="broadcast"
          />
          <ListingFilter
            filterTitle={intl.formatMessage({
              id: 'admin.broadcasts.filterTitle',
            })}
            textSearchString={pageState.textSearchState}
            handleTextSearchLabelClose={pageState.handleTextSearchLabelClose}
            labelTitleMapping={pageState.filterLabelTitleMapping}
            dropdownOptionsInfoList={filterDropdownOptionsInfoList}
            filterState={pageState.filterState}
            handleRemoveFilter={pageState.handleRemoveFilter}
            handleFilterChange={pageState.handleFilterChange}
          />
        </div>

        <div>
          <CardGrid
            objectData={get(dataBroadcasts, 'broadcasts.broadcasts', [])}
            renderCardComponent={(broadcast: CardType, i: number) => (
              // @ts-ignore
              <BroadcastCard
                index={i}
                {...broadcast}
                onEditBroadcast={(broadcastData: any) => {
                  setBroadcastData(broadcastData);
                  toggleEditBroadcastModal();
                }}
                refetchBroadcasts={refetchBroadcasts}
              />
            )}
            dataQaid="admin-broadcasts-list"
            loading={loadingBroadcasts}
            loadingComponent={
              <LoadingBlocks.Rectangle width="100%" height="420px" />
            }
            hideDividerOnSize="xs"
          />
          <DottedLine />
          <Spacer mb={2} />
        </div>

        <ListingNoResults
          entityName={intl.formatMessage({
            id: 'admin.broadcasts.noResultsEntityName',
          })}
          numResults={get(
            dataBroadcasts,
            'broadcasts.broadcasts.length',
            undefined
          )}
          loading={loadingBroadcasts}
        />

        <ListingFooter
          numTotalRecords={get(
            dataBroadcasts,
            'broadcasts.metadata.totalRecords',
            undefined
          )}
          perPage={PER_PAGE}
          currentPage={pageState.page}
          onPageChange={pageState.handlePageChange}
          loading={loadingBroadcasts}
          dataQaidPrefix="broadcasts"
        />
      </ListPageTemplate>
    </Layout>
  );
};

const AdminBroadcastsWrapper: React.FC = () => (
  <ListPage config={pageStateConfig} analytics={Analytics}>
    <AdminBroadcasts />
  </ListPage>
);

export default AdminBroadcastsWrapper;
