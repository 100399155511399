import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { useAnalyticsContext } from 'app/shared/utils';
import { upperFirstLeaveRestUnchanged } from 'app/shared/utils/string';
import { UpdateImages } from 'app/shared/graphql/images/mutationHooks';
import { NotifyContext } from 'app/shared/context/Notify';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import ImageMultiUploaderForm from 'app/shared/components/organisms/ImageMultiUploaderForm';
import {
  maxFileSizeForDisplayMegabytes,
  mimeTypesForDisplay,
} from 'app/shared/components/organisms/ImageMultiUploaderWithCropper';
import { ImageInfo } from 'app/shared/components/organisms/ImageMultiUploaderWithCropper';

interface Props {
  objectType: string;
  objectId: number;
  // e.g. 'promo_header' - in snake_case
  imagePurpose: string;
  // e.g. ['1:1, '4:3']
  ratios: string[];
  ratioDescriptions?: { [ratio: string]: string };
  imageFieldDescription: string;
  renderHeaderContent?: () => JSX.Element;
  whatImagesAreForDescription: string;
  onUploadSuccess: (response?: any) => void;
  setFormSubmitAction: (func: Function) => void;
  setDisplayConfirmation: (set: boolean) => void;
  setIsSubmitting: (set: boolean) => void;
  dataQaidPrefix: string;
}

interface OriginalFileInfo {
  width: number;
  height: number;
  url: string;
}

const getCropJSONForOriginalFile = (originalFileInfo: OriginalFileInfo) => {
  return {
    inputPath: originalFileInfo.url,
    pipeline: {
      steps: [
        {
          geometry: {
            offset: { x: 0, y: 0 },
            size: {
              width: originalFileInfo.width,
              height: originalFileInfo.height,
              type: 'widthxheight!',
            },
          },
          type: 'crop',
        },
      ],
    },
  };
};

async function fetchUploadIOCropJSON(
  originalFileInfo: OriginalFileInfo,
  cropFileLocation?: string
) {
  if (!cropFileLocation) {
    return getCropJSONForOriginalFile(originalFileInfo);
  }

  try {
    const response = await fetch(cropFileLocation);
    return await response.json();
  } catch (error) {
    console.error(error);
  }
}

async function fetchImageDimensions(fileLocation: string) {
  const img = new Image();
  img.src = fileLocation;
  await img.decode();
  return [img.naturalWidth, img.naturalHeight];
}

function translateUploadIOToImgIXParams({
  crop_horizontal_offset,
  crop_vertical_offset,
  crop_width,
  crop_height,
  originalFileWidth,
  originalFileHeight,
  ratio,
}: {
  crop_horizontal_offset: number;
  crop_vertical_offset: number;
  crop_width: number;
  crop_height: number;
  originalFileWidth: number;
  originalFileHeight: number;
  ratio: string;
}) {
  const originalImageRatio = originalFileWidth / originalFileHeight;
  const cropImageRatio = crop_width / crop_height;
  const fp_x = (
    (crop_horizontal_offset + crop_width / 2) /
    originalFileWidth
  ).toPrecision(4);
  const fp_y = (
    (crop_vertical_offset + crop_height / 2) /
    originalFileHeight
  ).toPrecision(4);
  const fp_z = (cropImageRatio < originalImageRatio
    ? originalFileHeight / crop_height
    : originalFileWidth / crop_width
  ).toPrecision(4);

  return `?fit=crop&crop=focalpoint&w=${crop_width}&h=${crop_height}&fp-x=${fp_x}&fp-y=${fp_y}&fp-z=${fp_z}&ar=${ratio}`;
}

const ImageMultiEdit: React.FC<Props> = ({
  objectType,
  objectId,
  imagePurpose,
  ratios,
  ratioDescriptions,
  imageFieldDescription,
  renderHeaderContent,
  whatImagesAreForDescription,
  onUploadSuccess,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  dataQaidPrefix,
}) => {
  const intl = useIntl();
  const notifyContext = useContext(NotifyContext);
  const [imagesForAllRatios, setImagesForAllRatios] = useState<ImageInfo[]>([]);
  const { trackAnalyticsEvent } = useAnalyticsContext();
  const [imgIXParamsMap, setImgIXParamsMap] = useState<{
    [fileURL: string]: string;
  }>({});

  const formInitialValues = {
    anyChangesMade: false,
  };

  useEffect(() => {
    async function calculateImgIXParams() {
      imagesForAllRatios.map(async image => {
        const [
          originalFileWidth,
          originalFileHeight,
        ] = await fetchImageDimensions(image.originalFileUrl || '');
        const originalFileInfo = {
          width: originalFileWidth,
          height: originalFileHeight,
          url: image.originalFileUrl,
        };

        const uploadIOCropJSON = await fetchUploadIOCropJSON(
          originalFileInfo,
          image.editedFileUrl
        );

        if (uploadIOCropJSON && originalFileWidth && originalFileHeight) {
          const cropInstructions = uploadIOCropJSON.pipeline.steps;
          const {
            offset: { x: crop_horizontal_offset, y: crop_vertical_offset },
            size: { width: crop_width, height: crop_height },
          } = cropInstructions[0].geometry;

          const imgIXParams = translateUploadIOToImgIXParams({
            crop_horizontal_offset,
            crop_vertical_offset,
            crop_width,
            crop_height,
            originalFileWidth,
            originalFileHeight,
            ratio: image.ratio,
          });

          setImgIXParamsMap(currentImgIXParamsMap => {
            return {
              ...currentImgIXParamsMap,
              [image.fileUrl]: imgIXParams,
            };
          });
        }
      });
    }

    if (imagesForAllRatios) {
      calculateImgIXParams();
    }
  }, [imagesForAllRatios]);

  function getImagesToSave() {
    return imagesForAllRatios.map(image => {
      // originalFileName = e.g. City-29-1.3333333333333333-1700176971453-image.jpg
      const originalFileName = image.originalFileUrl.split('/').pop();
      const filePlatform = 'imgix';
      const imgIXParams = imgIXParamsMap[image.fileUrl] || '';
      const filename = originalFileName + imgIXParams;

      return {
        contentType: image.mime,
        filePlatform,
        filename,
        ratio: image.ratio,
        size: image.size,
      };
    });
  }

  const canSubmitImages = () =>
    imagesForAllRatios.length > 0 &&
    Object.keys(imgIXParamsMap).length == imagesForAllRatios.length;

  async function handleSubmit() {
    try {
      if (canSubmitImages()) {
        const response = await UpdateImages({
          imageableObjectType: objectType,
          imageableObjectId: objectId,
          images: [
            {
              imageableObjectType: objectType,
              imageableObjectId: objectId,
              imagePurpose,
              images: getImagesToSave(),
            },
          ],
        });
        if (response.data) {
          notifyContext.addMessage(
            intl.formatMessage(
              {
                id: 'admin.imageEdit.successMessage',
              },
              {
                imageFieldDescription: upperFirstLeaveRestUnchanged(
                  imageFieldDescription
                ),
              }
            )
          );
          onUploadSuccess && onUploadSuccess(response);
        } else {
          notifyContext.addMessage(
            intl.formatMessage(
              {
                id: 'admin.imageEdit.errorMessage',
              },
              {
                imageFieldDescription,
              }
            )
          );
        }
      }
      /* eslint-disable-next-line prettier/prettier */
    } catch (e) {
      if (e instanceof Error) {
        trackAnalyticsEvent('Save Multi Image Failure', {
          imageableObjectType: objectType,
          imageableObjectId: objectId,
          imageFieldDescription,
          images: [
            {
              imageableObjectType: objectType,
              imageableObjectId: objectId,
              imagePurpose,
              images: getImagesToSave(),
            },
          ],
          error: e,
        });
        notifyContext.addMessage(
          intl.formatMessage(
            {
              id: 'admin.imageEdit.errorMessageWithDescription',
            },
            {
              imageFieldDescription,
            }
          )
        );
      }
    }
  }

  return (
    <>
      <ModalContentContainer>
        {renderHeaderContent && renderHeaderContent()}
        <GenericForm
          formInitialValues={formInitialValues}
          renderFormComponent={(renderProps: any) => {
            return (
              <>
                <h1>
                  {intl.formatMessage(
                    {
                      id: 'admin.multiImageEdit.headerText',
                    },
                    {
                      description: whatImagesAreForDescription,
                      maxFileSizeMegabytes: maxFileSizeForDisplayMegabytes,
                      mimeTypes: mimeTypesForDisplay,
                    }
                  )}
                </h1>
                <ImageMultiUploaderForm
                  formikProps={renderProps.formikProps}
                  objectType={objectType}
                  objectId={objectId}
                  imagePurpose={imagePurpose}
                  ratios={ratios}
                  ratioDescriptions={ratioDescriptions}
                  imagesForAllRatios={imagesForAllRatios}
                  setImagesForAllRatios={setImagesForAllRatios}
                  dataQaidPrefix={dataQaidPrefix}
                />
              </>
            );
          }}
          onSubmit={handleSubmit}
          setFormSubmitAction={setFormSubmitAction}
          setDisplayConfirmation={setDisplayConfirmation}
          setIsSubmitting={setIsSubmitting}
          validateOnChange={false}
          dataQaId="image-multi-edit"
        />
      </ModalContentContainer>
    </>
  );
};

export default ImageMultiEdit;
