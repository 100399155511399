import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { Artist } from 'app/typings/artists';
import { Event } from 'app/typings/events';
import { DISCOVERY_EVENT } from 'app/shared/utils/events';
import { get } from 'app/shared/utils/get';
import { GetCity } from 'app/shared/graphql/cities/queryHooks';
import { Checkbox } from 'app/shared/components/atoms/CheckboxManualCSS';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import {
  FormGroupContainer,
  FormSectionTitle,
} from 'app/shared/components/atoms/FormContent';
import { LoadingBlocks } from 'app/shared/components/atoms/LoadingBlocks';
import { LoadingError } from 'app/shared/components/atoms/LoadingError';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { H5 } from 'app/shared/components/atoms/TypographyManualCSS';
import {
  getFilterNamesAndOptionsForTitleMapping,
  getMaxPaInputsOptions,
  getNeighborhoodOptionsSimple,
  getThemeOptions,
} from 'app/admin/utils/optionLists';
import { GetEventsForOfferCreate } from 'app/admin/graphql/events/queryHooks';
import { GetThemesLite } from 'app/admin/graphql/themes/queryHooks';
import ActionIcon from 'app/admin/components/atoms/ActionIcon';
import EventCalendarSelector from 'app/admin/components/organisms/EventCalendarSelector';
import ListingFilter from 'app/admin/components/organisms/ListingFilter';
import { ReactComponent as SynchronizeArrow } from 'icons/streamline-regular/interface-essential/synchronize/synchronize-arrow.svg';

import { openPositionOptions, ticketOptions } from './filterOptions';

interface Props {
  setFieldValue: any;
  touched: any;
  errors: any;
  values: any;
  pageState: any;
}

const MainContainer = styled.div`
  padding: 0px 0px 10px 0px;
`;

const ListingControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
`;

const ListCount = styled.div`
  display: flex;
  flex-direction: column-reverse;
  flex-grow: 1;
  p {
    height: fit-content;
  }
`;

const StyledH5 = styled(H5)`
  margin-bottom: 5px;
`;

const SmallMarginDottedLine = styled(DottedLine)`
  margin: 2px 0 2px 0;
`;

const EventCalendarSelectorContainer = styled.div`
  margin-top: 15px;
`;

const TopContentContainer = styled.div`
  padding: 5px 5px 25px 5px;
`;

const TopContentNote = styled.div`
  padding-bottom: 25px;
`;

const TopContentSelect = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const SelectAll = styled.div``;

const SelectAllText = styled.span`
  ${({ theme }) => css`
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 1.5px;
    font-weight: 700;
    text-transform: uppercase;
    color: ${theme.colors.green600};
    cursor: pointer;
  `}
`;

const NumEventsSelected = styled.div`
  font-weight: 600;
`;

const PositionContainer = styled.div`
  padding-bottom: 20px;
`;

const PositionNote = styled.h6`
  font-size: 12px;
  font-weight: 500;
  margin: -3px 0 25px 0;
`;

const Position = styled.span`
  padding-left: 8px;
`;

const GreenText = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.green600};
  `}
`;

interface ListingControlsProps {
  totalRecords?: number;
  loading: boolean;
  onReset: VoidFunction;
}

const ListingControls: React.FC<ListingControlsProps> = ({
  totalRecords,
  loading,
  onReset,
}) => {
  const intl = useIntl();

  return (
    <>
      <ListingControlsContainer>
        <ListCount>
          <StyledH5>
            {loading || (!totalRecords && totalRecords != 0)
              ? null
              : intl.formatMessage(
                  {
                    id: 'admin.offerCreate.showingXEvents',
                  },
                  {
                    numEvents: totalRecords.toLocaleString(),
                  }
                )}
          </StyledH5>
        </ListCount>
        <ActionIcon
          icon={SynchronizeArrow}
          label={intl.formatMessage({
            id: 'list.reset',
          })}
          onClick={onReset}
          dataQaid="select-dates-reset-filter"
        />
      </ListingControlsContainer>
      <SmallMarginDottedLine />
      <Spacer mb={4} />
    </>
  );
};

interface EventCalendarSelectorTopContentProps {
  values: any;
  selectAll: boolean;
  onChangeSelectAll: (value: boolean) => void;
}

const EventCalendarSelectorTopContent: React.FC<EventCalendarSelectorTopContentProps> = ({
  values,
  selectAll,
  onChangeSelectAll,
}) => {
  const intl = useIntl();

  return (
    <TopContentContainer>
      <TopContentNote>
        {intl.formatMessage({
          id: 'admin.offerCreate.theConcertsBelow',
        })}
      </TopContentNote>
      <TopContentSelect>
        <SelectAll>
          <Checkbox
            id="select-all"
            data-qaid="select-all"
            checked={selectAll}
            name="selectAll"
            onChange={() => onChangeSelectAll(!selectAll)}
          >
            <SelectAllText>
              {intl.formatMessage({
                id: 'admin.offerCreate.selectAll',
              })}
            </SelectAllText>
          </Checkbox>
        </SelectAll>
        <NumEventsSelected>
          {intl.formatMessage(
            {
              id: 'admin.offerCreate.numEventsSelected',
            },
            {
              numEvents: values.events.length,
            }
          )}
        </NumEventsSelected>
      </TopContentSelect>
    </TopContentContainer>
  );
};

const getDateKey = (dateStr: string) => dateStr.replace(/T.+/, '');

const getEventIdsForCurrentMonthPeriod = (
  eventsInfo: any,
  firstLastDates: string | undefined,
  eventIdsDisplayable: number[]
) => {
  if (firstLastDates) {
    const [firstDate, lastDate] = firstLastDates.split(',');
    return eventsInfo
      .filter(
        (eventInfo: any) =>
          eventIdsDisplayable.includes(eventInfo.eventId) &&
          getDateKey(eventInfo.eventDate) >= firstDate &&
          getDateKey(eventInfo.eventDate) <= lastDate
      )
      .map((eventInfo: any) => eventInfo.eventId);
  } else {
    return [];
  }
};

const OfferCreateFormStep2: React.FC<Props> = ({
  setFieldValue,
  values,
  pageState,
}) => {
  const intl = useIntl();
  const [eventIdsDisplayable, setEventIdsDisplayable] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [
    firstLastDatesOfMonthPeriod,
    setFirstLastDatesOfMonthPeriod,
  ] = useState<string | undefined>(undefined);

  const cityResponse = GetCity({
    id: values.city && values.city.id,
  });

  const neighborhoods = get(cityResponse, 'data.city.neighborhoods', []);

  const {
    loading: loadingThemes,
    error: errorThemes,
    data: dataThemes,
  } = GetThemesLite({});

  const neighborhoodOptions = getNeighborhoodOptionsSimple(neighborhoods);

  const themeOptions = [
    {
      title: intl.formatMessage({
        id: 'admin.offerDirectory.filter.option.noTheme',
      }),
      value: null,
    },
  ].concat(getThemeOptions(dataThemes));

  const maxPaInputsOptions = getMaxPaInputsOptions(intl);

  const filterDropdownOptionsInfoList = [
    {
      filterName: 'neighborhood',
      dropdownParams: {
        searchBar: true,
        title: intl.formatMessage({
          id: 'admin.offerCreate.filter.neighborhood',
        }),
        options: neighborhoodOptions,
      },
    },
    {
      filterName: 'open_positions',
      dropdownParams: {
        title: intl.formatMessage({
          id: 'admin.offerCreate.filter.openSlots',
        }),
        options: openPositionOptions,
      },
    },
    {
      filterName: 'theme',
      dropdownParams: {
        searchBar: true,
        title: intl.formatMessage({
          id: 'admin.offerCreate.filter.theme',
        }),
        options: themeOptions,
      },
    },
    {
      filterName: 'num_tickets_available_for_sale',
      dropdownParams: {
        title: intl.formatMessage({
          id: 'admin.offerCreate.filter.ticketsAvailable',
        }),
        options: ticketOptions,
      },
    },
    {
      filterName: 'max_pa_inputs',
      dropdownParams: {
        title: intl.formatMessage({
          id: 'admin.offerCreate.filter.production',
        }),
        options: maxPaInputsOptions,
      },
    },
  ];

  const {
    loading: loadingEvents,
    error: errorEvents,
    data: dataEvents,
  } = GetEventsForOfferCreate({
    city: values.city && values.city.cachedSlug,
    upcoming: true,
    type: DISCOVERY_EVENT,
    neighborhood: pageState.filterListVariable('neighborhood'),
    openPositions: pageState.filterListVariable('open_positions'),
    theme: pageState.filterListVariable('theme'),
    numTicketsAvailableForSale: pageState.filterListVariable(
      'num_tickets_available_for_sale'
    ),
    status: 'published',
    maxPaInputs: pageState.filterListVariable('max_pa_inputs'),
    maxSlotsTaken: 2,
    excludeArtistIds: values.artists.map((a: Artist) => a.id).join(),
    orderBy: pageState.sortState.by,
    orderDirection: pageState.sortState.direction,
    skipPagination: true,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  const events = get(dataEvents, 'events.events', []);

  const getNoNeighborhoodText = (intl: any) =>
    intl.formatMessage({
      id: 'admin.offerCreate.noNeighborhood',
    });

  const getNeighborhoodText = (event: Event, intl: any) =>
    event.venue?.neighborhood?.title ||
    (!event.neighborhood?.title && getNoNeighborhoodText(intl)) ||
    event.neighborhood?.title;

  const eventsInfo = events.map((event: Event) => ({
    eventId: event.id,
    eventDate: event.localStartsAt,
    toolTipText: getNeighborhoodText(event, intl),
  }));

  const eventForEventId = (eventId: number) =>
    events.find((event: Event) => Number(event.id) == eventId);

  const selectedEventIds = values.events.map((event: Event) =>
    Number(event.id)
  );

  const addToSelectedEvents = (eventIds: number[]) => {
    const eventsToAdd = [];
    for (const eventId of eventIds) {
      if (
        !values.events.map((event: Event) => Number(event.id)).includes(eventId)
      ) {
        eventsToAdd.push(eventForEventId(eventId));
      }
    }
    setFieldValue('events', values.events.concat(eventsToAdd));
  };

  const removeFromSelectedEvents = (eventIds: number[]) => {
    setFieldValue(
      'events',
      values.events.filter(
        (event: Event) => !eventIds.includes(Number(event.id))
      )
    );
  };

  const onSelectEvent = (eventId: number) => {
    addToSelectedEvents([eventId]);
  };

  const onUnselectEvent = (eventId: number) => {
    removeFromSelectedEvents([eventId]);
  };

  const onMonthChange = (
    firstDateOfMonthPeriod: string,
    lastDateOfMonthPeriod: string
  ) => {
    const newValue = `${firstDateOfMonthPeriod},${lastDateOfMonthPeriod}`;
    if (newValue != firstLastDatesOfMonthPeriod) {
      setSelectAll(false);
    }
    setFirstLastDatesOfMonthPeriod(newValue);
  };

  const onChangeSelectAll = (newSelectAll: boolean) => {
    const eventIds = getEventIdsForCurrentMonthPeriod(
      eventsInfo,
      firstLastDatesOfMonthPeriod,
      eventIdsDisplayable
    );
    if (newSelectAll) {
      addToSelectedEvents(eventIds);
    } else {
      removeFromSelectedEvents(eventIds);
    }
    setSelectAll(newSelectAll);
  };

  const addToSelectedPositions = (position: number) => {
    if (!values.positions.includes(position)) {
      setFieldValue(
        'positions',
        values.positions
          .concat([position])
          .sort((a: number, b: number) => a - b)
      );
    }
  };

  const removeFromSelectedPositions = (position: number) => {
    setFieldValue(
      'positions',
      values.positions.filter((p: number) => p != position)
    );
  };

  useEffect(() => {
    pageState.setupFilterLabelTitleMapping(
      getFilterNamesAndOptionsForTitleMapping(filterDropdownOptionsInfoList)
    );
  }, [dataEvents]);

  useEffect(() => {
    pageState.setOnAnyFilterChange(() => () =>
      removeFromSelectedEvents(selectedEventIds)
    );
  }, [values.events]);

  if (
    (!loadingEvents && !dataEvents) ||
    errorEvents ||
    (!loadingThemes && !dataThemes) ||
    errorThemes
  ) {
    return (
      <LoadingError
        whatsBeingLoaded={intl.formatMessage({
          id: 'admin.offerCreate.theCalendarOfDatesAndEvents',
        })}
      />
    );
  }

  const allPositions = [1, 2, 3];

  return (
    <MainContainer>
      <ListingControls
        totalRecords={eventIdsDisplayable.length}
        loading={loadingEvents}
        onReset={pageState.handleResetFilters}
      />
      <ListingFilter
        filterTitle={intl.formatMessage({
          id: 'admin.offerCreate.filterTitle',
        })}
        textSearchString={undefined}
        handleTextSearchLabelClose={undefined}
        labelTitleMapping={pageState.filterLabelTitleMapping}
        dropdownOptionsInfoList={filterDropdownOptionsInfoList}
        filterState={pageState.filterState}
        handleRemoveFilter={pageState.handleRemoveFilter}
        handleFilterChange={pageState.handleFilterChange}
      />
      <EventCalendarSelectorContainer>
        {loadingEvents ? (
          <LoadingBlocks.Rectangle width="761px" height="400px" />
        ) : (
          <EventCalendarSelector
            eventsInfo={eventsInfo}
            selectedEventIds={selectedEventIds}
            onSelect={onSelectEvent}
            onUnselect={onUnselectEvent}
            setEventIdsDisplayable={setEventIdsDisplayable}
            onMonthChange={onMonthChange}
            disableSelectPastDates={true}
            topContent={
              <EventCalendarSelectorTopContent
                values={values}
                selectAll={selectAll}
                onChangeSelectAll={onChangeSelectAll}
              />
            }
          />
        )}
      </EventCalendarSelectorContainer>
      <Spacer mb={10} />
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.offerCreate.whichSlot',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <PositionNote>
          {intl.formatMessage({
            id: 'admin.offerCreate.chooseWhichSlot',
          })}
          <GreenText>*</GreenText>
        </PositionNote>
        {allPositions.map((position: number) => (
          <PositionContainer key={position}>
            <Checkbox
              id={`offer-create-form-position-${position}-field`}
              data-qaid={`offer-create-form-position-${position}-field`}
              checked={!!values.positions.includes(position)}
              name={`position${position}`}
              onChange={e => {
                if (e.target.checked) {
                  addToSelectedPositions(position);
                } else {
                  removeFromSelectedPositions(position);
                }
              }}
            >
              <Position>{position}</Position>
            </Checkbox>
          </PositionContainer>
        ))}
      </FormGroupContainer>
    </MainContainer>
  );
};

export default OfferCreateFormStep2;
