import React, { CSSProperties } from 'react';

import { City } from 'app/typings';
import { NavbarContextProvider } from 'app/shared/context';
import { FlexDivWithSpacing } from 'app/shared/components/atoms/FlexDivWithSpacing';

import { useMobileAdminNavMenuData } from './adminNavUtils';
import { BeamerIcon } from './BeamerIcon';
import { MenuButton } from './MenuButton';
import { NavbarMenuButtons } from './NavbarMenuButtons';
import { NavbarWrapper } from './NavbarWrapper';
import { SofarLogo } from './SofarLogo';

export interface NavbarProps {
  position?: CSSProperties['position'];
  backgroundColor?: string;
  containerMaxWidth?: number;
  useResponsiveHeight?: boolean;
  hideCitySelector?: boolean;
  eventId?: string;
  city?: City;
  suppressAllLinks?: boolean;
  fanThemeOverride?: boolean;
  themeName?: 'adminManualCSS' | 'manualCSS';
  fixedContentHeight?: number;
  invertColors?: boolean;
  transparent?: boolean;
}

const Navbar: React.FC<NavbarProps> = ({
  position,
  backgroundColor,
  containerMaxWidth,
  useResponsiveHeight = true,
  hideCitySelector = false,
  eventId,
  city,
  suppressAllLinks = false,
}) => {
  const mobileNavMenuData = useMobileAdminNavMenuData();

  return (
    <NavbarWrapper
      position={position}
      backgroundColor={backgroundColor}
      containerMaxWidth={containerMaxWidth}
      useResponsiveHeight={useResponsiveHeight}
      hideCitySelector={hideCitySelector}
      eventId={eventId}
      city={city}
      suppressAllLinks={suppressAllLinks}
      mobileNavMenuData={mobileNavMenuData}
      isAdmin={true}
      disableContentWrapperBorder={true}
    >
      <FlexDivWithSpacing flex={2} alignItems="center">
        <SofarLogo
          url="/admin/concert-planner"
          invertLogoByEnvironment={true}
        />
        {!suppressAllLinks && <NavbarMenuButtons alignRight={false} />}
      </FlexDivWithSpacing>
      <FlexDivWithSpacing flex={3} alignItems="center">
        {!suppressAllLinks && <NavbarMenuButtons alignRight={true} />}
      </FlexDivWithSpacing>
      <BeamerIcon />
      <MenuButton displayOnTablet={true} />
    </NavbarWrapper>
  );
};

const NavbarWithContext = ({ ...props }: NavbarProps) => {
  return (
    <NavbarContextProvider isLandingPage={false}>
      <Navbar {...props} />
    </NavbarContextProvider>
  );
};

export default NavbarWithContext;
