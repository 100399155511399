import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { CuratorGroup } from 'app/typings';
import { KeyValue } from 'app/typings/generics';
import usePermission from 'app/shared/utils/usePermission';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import { Overline } from 'app/shared/components/atoms/TypographyManualCSS';
import { eventOrganizedAsLabelsShort } from 'app/admin/utils/events';
import { KeyValueInfoList } from 'app/admin/components/molecules/KeyValueInfoList';

interface DetailsProps {
  show?: boolean;
  guestsArriveAt: string;
  eventOrganizedAs?: string;
  curatorGroup?: CuratorGroup;
  partnerTitle?: string;
}

const TitleOverline = styled(Overline)`
  margin-bottom: 5px;
`;

const EventDetails: React.FC<DetailsProps> = ({
  show = true,
  guestsArriveAt,
  eventOrganizedAs,
  curatorGroup,
  partnerTitle,
}) => {
  const intl = useIntl();
  const hasAccessEventsPermission = usePermission('event.list.access');

  if (!show) {
    return null;
  }

  const keysAndValuesList: KeyValue[] = [
    {
      key: intl.formatMessage({
        id: 'admin.shared.eventTimes.guestsArriveTime',
      }),
      value:
        guestsArriveAt ||
        intl.formatMessage({
          id: 'none',
        }),
    },
    {
      key: intl.formatMessage({
        id: 'admin.shared.organizedAs',
      }),
      value:
        (eventOrganizedAs && eventOrganizedAsLabelsShort[eventOrganizedAs]) ||
        intl.formatMessage({
          id: 'none',
        }),
    },
    {
      key: intl.formatMessage({
        id: 'admin.eventPlanner.details.group',
      }),
      value: curatorGroup
        ? curatorGroup.name
        : intl.formatMessage({
            id: 'admin.notApplicable',
          }),
      valueTruncateCharLength: 14,
    },
  ];

  if (hasAccessEventsPermission) {
    keysAndValuesList.push({
      key: intl.formatMessage({
        id: 'admin.eventPlanner.details.sponsor',
      }),
      value:
        partnerTitle ||
        intl.formatMessage({
          id: 'none',
        }),
    });
  }

  return (
    <div data-qaid="event-planner-card-details">
      <TitleOverline>
        {intl.formatMessage({
          id: 'admin.eventPlanner.details.label',
        })}
      </TitleOverline>
      <KeyValueInfoList
        dataQaidPrefix="event-details"
        keysAndValues={keysAndValuesList}
      />
      <DottedLine />
    </div>
  );
};

export default EventDetails;
