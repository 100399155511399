import React, { useEffect, useState } from 'react';

import { googleMapsLoaded } from 'app/shared/utils/googleMaps';
// @ts-ignore
import { Textfield } from 'app/shared/components/atoms/Textfield';

interface Props {
  onChange: (e: any) => void;
  name: string;
  initialValue: string;
  placeholder: string;
  shouldAddListener?: boolean;
  placeType?: string | string[];
  countryCodes?: string[];
  'data-qaid'?: string;
}

interface GoogleAddressData {
  cityDescription: string | any;
  city: string;
  state: string;
  countryCode: string;
  latitude: number | null;
  longitude: number | null;
}

const valueSetter = (locality: string | null, defaultLocality: string) =>
  locality === null && defaultLocality ? defaultLocality : locality;

const parseGoogleAddressResult = (addressObject: any): GoogleAddressData => {
  const address = addressObject.address_components;

  const data = {
    cityDescription: '',
    city: '',
    state: '',
    countryCode: '',
    latitude: null,
    longitude: null,
    url: '',
  };

  if (address) {
    data.cityDescription = addressObject.formatted_address;
    data.latitude = addressObject.geometry.location.lat();
    data.longitude = addressObject.geometry.location.lng();
    data.url = addressObject.url;
    address.forEach((addr: any) => {
      if (addr.types.includes('locality')) {
        data.city = addr.long_name;
      } else if (addr.types.includes('administrative_area_level_1')) {
        data.state = addr.short_name;
      } else if (addr.types.includes('country')) {
        data.countryCode = addr.short_name;
      }
    });
  }

  return data;
};

const GooglePlaceSelect: React.FC<Props> = ({
  onChange,
  name,
  initialValue,
  placeholder,
  shouldAddListener,
  placeType,
  countryCodes,
  'data-qaid': qaId,
}) => {
  let autocomplete: any;
  const [listenerAdded, setListenerAdded] = useState(false);
  const [locality, setLocality] = useState(null);

  const handlePlaceSelect = () => {
    const addressObject = autocomplete.getPlace();
    const address = addressObject.address_components;

    if (address) {
      const selectedCity = parseGoogleAddressResult(addressObject);
      setLocality(selectedCity.cityDescription);
      onChange(selectedCity);
    }
  };

  const setupAutocomplete = () => {
    // There are different types of places that can be returned.
    // By default we return cities.
    // More info: https://developers.google.com/maps/documentation/javascript/places-autocomplete#add-autocomplete
    const type = placeType || '(cities)';
    const options = {
      types: [type],
      componentRestrictions: countryCodes
        ? { country: countryCodes }
        : undefined,
    };

    // @ts-ignore
    // eslint-disable-next-line
    autocomplete = new google.maps.places.Autocomplete(
      document.getElementById('google-city-select'),
      options
    );

    autocomplete.setFields([
      'address_components',
      'formatted_address',
      'geometry',
      'url',
    ]);
    autocomplete.addListener('place_changed', handlePlaceSelect);
    setListenerAdded(true);
  };

  useEffect(() => {
    if (googleMapsLoaded() && !listenerAdded && shouldAddListener) {
      setupAutocomplete();
    }
  });

  const handleChange = (e: any) => {
    setLocality(e.target.value);
    if (e.target.value == '') {
      onChange({
        cityDescription: '',
        city: '',
        state: '',
        countryCode: '',
        latitude: null,
        longitude: null,
      });
    }
  };

  return (
    <Textfield
      data-qaid={qaId || 'google-city-select'}
      name={name}
      id="google-city-select"
      onChange={handleChange}
      placeholder={placeholder}
      defaultValue={valueSetter(locality, initialValue) || undefined}
    />
  );
};

export default GooglePlaceSelect;
