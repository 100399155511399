import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { dataGetter } from 'app/shared/utils/queries';
import useModal from 'app/shared/utils/useModal';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import ConfirmationModal from 'app/shared/components/molecules/ConfirmationModal';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { CancelCompetition } from 'app/admin/graphql/competitions/mutationHooks';
import { GetCompetition } from 'app/admin/graphql/competitions/queryHooks';
import { DetailsMainContainer } from 'app/admin/components/atoms/DetailContent';
import AccordionSections from 'app/admin/components/organisms/AccordionSections';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';
import { ReactComponent as PeopleMan8 } from 'icons/streamline-regular/avatars/geometric-men-people/people-man-8.svg';
import { ReactComponent as PartyMusicDanceWoman } from 'icons/streamline-regular/entertainment-events-hobbies/party/party-music-dance-woman.svg';
import { ReactComponent as InformationCircle } from 'icons/streamline-regular/interface-essential/alerts/information-circle.svg';
import { ReactComponent as MessagesBubbleSquareSmile } from 'icons/streamline-regular/messages-chat-smileys/messages-speech-bubbles/messages-bubble-square-smile.svg';

import SectionBasicInfo from './SectionBasicInfo';
import SectionContent from './SectionContent';
import SectionEntries from './SectionEntries';
import SectionSharing from './SectionSharing';

interface Props {
  contentProps: any;
  navigateTo: (routeData: any) => void;
  hide: (hideProps?: any) => VoidFunction;
}

const detailsHeaderActionLinksInfo = (
  toggleCancelCompetitionConfirmationModal: VoidFunction,
  canCancel: boolean,
  intl: any
) => {
  return {
    link1: {
      text: intl.formatMessage({
        id: 'admin.competitions.details.cancelCompetition',
      }),
      active: canCancel,
      onClickAction: toggleCancelCompetitionConfirmationModal,
    },
  };
};

const CompetitionDetails: React.FC<Props> = ({
  contentProps,
  navigateTo,
  hide,
}) => {
  const { id, title, status } = contentProps;

  const intl = useIntl();
  const [
    cancelCompetitionConfirmationModal,
    toggleCancelCompetitionConfirmationModal,
  ] = useModal();

  const [headerData, setHeaderData] = useState({
    title,
    status,
    isLoaded: !!title,
  });

  const [isCancellingCompetition, setIsCancellingCompetition] = useState<
    boolean
  >(false);

  const {
    loading: loadingBasicInfo,
    error: errorBasicInfo,
    data: dataBasicInfo,
  } = GetCompetition({
    id,
    fetchPolicy: 'cache-and-network',
  });

  const sectionsConfig = {
    basicInfo: {
      title: intl.formatMessage({
        id: 'admin.competitions.details.sectionTitle.basicInfo',
      }),
      icon: InformationCircle,
      iconColor: 'blueChristmas',
      sectionComponent: SectionBasicInfo,
      dataKey: 'competition',
      displayEditIcon: true,
      loading: loadingBasicInfo,
      data: dataBasicInfo ? dataBasicInfo.competition : undefined,
      callbacks: {
        toggleSectionEditModal: () =>
          navigateTo({
            routeName: 'competition-basic-info-edit',
            routeProps: dataBasicInfo ? dataBasicInfo.competition : undefined,
          }),
      },
    },
    entries: {
      title: intl.formatMessage({
        id: 'admin.competitions.details.sectionTitle.entries',
      }),
      icon: PartyMusicDanceWoman,
      iconColor: 'greenOnions',
      iconCircle: true,
      sectionComponent: SectionEntries,
      dataKey: 'competition',
      displayEditIcon: false,
      loading: loadingBasicInfo,
      data: dataBasicInfo ? dataBasicInfo.competition : undefined,
      callbacks: {},
    },
    content: {
      title: intl.formatMessage({
        id: 'admin.competitions.details.sectionTitle.content',
      }),
      icon: MessagesBubbleSquareSmile,
      iconColor: 'purplePills',
      iconCircle: true,
      sectionComponent: SectionContent,
      dataKey: 'competition',
      displayEditIcon: true,
      loading: loadingBasicInfo,
      data: dataBasicInfo ? dataBasicInfo.competition : undefined,
      callbacks: {
        toggleSectionEditModal: () =>
          navigateTo({
            routeName: 'competition-content-edit',
            routeProps: dataBasicInfo ? dataBasicInfo.competition : undefined,
          }),
        toggleEditBackgroundImageModal: () => {
          navigateTo({
            routeName: 'competition-background-image-uploader',
            routeProps: dataBasicInfo ? dataBasicInfo.competition : undefined,
          });
        },
        toggleEditLogoImageModal: () => {
          navigateTo({
            routeName: 'competition-logo-image-uploader',
            routeProps: dataBasicInfo ? dataBasicInfo.competition : undefined,
          });
        },
        toggleEditMoreInfoImageModal: () => {
          navigateTo({
            routeName: 'competition-more-info-image-uploader',
            routeProps: dataBasicInfo ? dataBasicInfo.competition : undefined,
          });
        },
      },
    },
    sharing: {
      title: intl.formatMessage({
        id: 'admin.competitions.details.sectionTitle.sharing',
      }),
      icon: PeopleMan8,
      iconColor: 'royalBlood',
      iconCircle: true,
      sectionComponent: SectionSharing,
      dataKey: 'competition',
      displayEditIcon: true,
      loading: loadingBasicInfo,
      data: dataBasicInfo ? dataBasicInfo.competition : undefined,
      callbacks: {
        toggleSectionEditModal: () =>
          navigateTo({
            routeName: 'competition-sharing-edit',
            routeProps: dataBasicInfo ? dataBasicInfo.competition : undefined,
          }),
        toggleEditShareImageModal: () => {
          navigateTo({
            routeName: 'competition-share-image-uploader',
            routeProps: dataBasicInfo ? dataBasicInfo.competition : undefined,
          });
        },
      },
    },
  };

  useEffect(() => {
    const getDataBasicInfo = dataGetter(dataBasicInfo, 'competition');
    if (dataBasicInfo) {
      setHeaderData({
        title: getDataBasicInfo('title'),
        status: getDataBasicInfo('status'),
        isLoaded: true,
      });
    }
  }, [dataBasicInfo, loadingBasicInfo]);

  const cancelCompetitionAction = CancelCompetition();

  const handleCancelCompetition = useSubmitAction({
    beforeSubmit: () => setIsCancellingCompetition(true),
    submitAction: cancelCompetitionAction,
    submitVariables: () => ({
      id: contentProps.id,
    }),
    successMsg: intl.formatMessage(
      {
        id: 'admin.competitions.details.cancelSuccessMessage',
      },
      {
        title,
      }
    ),
    failureMsg: intl.formatMessage({
      id: 'admin.competitions.details.cancelFailureMessage',
    }),
    onSuccess: () => {
      setIsCancellingCompetition(false);
      cancelCompetitionConfirmationModal.hide();
    },
  });

  if ((!loadingBasicInfo && !dataBasicInfo) || errorBasicInfo) {
    hide();
    return null;
  }

  return (
    <>
      <ModalContentContainer data-qaid="competition-details-main-modal">
        <DetailsMainContainer>
          <DetailsHeader
            title={headerData.title}
            actionLinksInfo={detailsHeaderActionLinksInfo(
              toggleCancelCompetitionConfirmationModal,
              headerData.status == 'open',
              intl
            )}
            loading={!headerData.isLoaded}
          />
          <AccordionSections
            sectionsConfig={sectionsConfig}
            defaultOpenSection={contentProps.defaultOpenSection}
          />
        </DetailsMainContainer>
      </ModalContentContainer>
      {cancelCompetitionConfirmationModal.isShowing && (
        <ConfirmationModal
          onCancel={() => cancelCompetitionConfirmationModal.hide()}
          description={intl.formatMessage(
            {
              id: 'admin.competitions.details.cancelConfirm',
            },
            {
              title: contentProps.title,
            }
          )}
          onConfirm={handleCancelCompetition}
          isLoading={isCancellingCompetition}
          confirmationButtonText={intl.formatMessage({
            id: 'admin.competitions.details.cancelConfirmButtonText',
          })}
        />
      )}
    </>
  );
};

export default CompetitionDetails;
