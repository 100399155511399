import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { dateFormatter } from 'app/shared/utils/datetime';
import { get } from 'app/shared/utils/get';
import useModal from 'app/shared/utils/useModal';
import usePermission from 'app/shared/utils/usePermission';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import { ListPage, ListPageContext } from 'app/shared/context/ListPage';
import { BaseLink } from 'app/shared/components/atoms/LinkManualCSS';
import { LoadingBlocks } from 'app/shared/components/atoms/LoadingBlocks';
import TruncatedByCharText from 'app/shared/components/atoms/TruncatedByCharText';
import ConfirmationModal from 'app/shared/components/molecules/ConfirmationModal';
import RoutableModal from 'app/shared/components/molecules/RoutableModal';
import { GlobalError } from 'app/shared/components/pages/Status';
import { getFilterNamesAndOptionsForTitleMapping } from 'app/admin/utils/optionLists';
import { ArchivePartner } from 'app/admin/graphql/partners/mutationHooks';
import { GetPartnersFullInfo } from 'app/admin/graphql/partners/queryHooks';
import ListingFooter from 'app/admin/components/molecules/ListingFooter';
import ListingNoResults from 'app/admin/components/molecules/ListingNoResults';
import ListingControls from 'app/admin/components/organisms/ListingControls';
import ListingFilter from 'app/admin/components/organisms/ListingFilter';
import ListingHeader from 'app/admin/components/organisms/ListingHeader';
import ListTable from 'app/admin/components/organisms/ListTable';
import ListPageTemplate from 'app/admin/components/templates/ListPage';
import Layout from 'app/admin/layouts/ListPage';

import { sortOptions, staticOptions } from './options';

const pageStateConfig = {
  filterNames: ['archived_status'],
  defaultSort: { by: 'title', direction: 'asc' },
  textSearchParamName: 'partner_search',
};

interface AddressContainerProps {
  address: string;
  address2: string;
}

interface CampaignLinkProps {
  partnerId: number;
  campaignCount?: number;
}

const CampaignLinked = styled(BaseLink)`
  ${({ theme }) => css`
    color: ${theme.colors.green600};
  `}
`;

const AddressContainer: React.FC<AddressContainerProps> = ({
  address,
  address2,
}) => {
  return (
    <>
      {address ? (
        <TruncatedByCharText text={address} truncateLength={15} />
      ) : (
        ''
      )}
      <p> </p>
      {address2 ? (
        <TruncatedByCharText text={address2} truncateLength={15} />
      ) : (
        ''
      )}
    </>
  );
};

const CampaignLink: React.FC<CampaignLinkProps> = ({
  partnerId,
  campaignCount,
}) => {
  const hasAccessCampaignPermission = usePermission('campaign.access');

  return (
    <>
      {hasAccessCampaignPermission ? (
        <CampaignLinked
          href={`/admin/campaigns?partner=${partnerId}`}
          openInNewTab={true}
        >
          {campaignCount}
        </CampaignLinked>
      ) : (
        <div>{campaignCount}</div>
      )}
    </>
  );
};

const AdminPartners: React.FC = () => {
  const intl = useIntl();
  const pageState = useContext(ListPageContext);
  const PER_PAGE = 25;

  const [addPartnerModal, toggleAddPartnerModal] = useModal();
  const [editPartnerModal, toggleEditPartnerModal] = useModal();
  const [archivePartnerModal, toggleArchivePartnerModal] = useModal();
  const [partnerData, setPartnerData] = useState<any>(undefined);

  const filterDropdownOptionsInfoList = [
    {
      filterName: 'archived_status',
      dropdownParams: {
        title: intl.formatMessage({
          id: 'admin.partners.filter.archived',
        }),
        options: staticOptions.archivedStatus,
      },
    },
  ];

  const {
    loading,
    error,
    data,
    refetch: refetchPartnersFullInfo,
  } = GetPartnersFullInfo({
    partnerSearch: pageState.textSearchState,
    archivedStatus:
      pageState.filterListVariable('archived_status') || 'not_archived',
    orderBy: pageState.sortState.by,
    orderDirection: pageState.sortState.direction,
    page: pageState.page,
    perPage: PER_PAGE,
  });

  const archivePartnerAction = ArchivePartner();

  const handleArchivePartner = useSubmitAction({
    submitAction: archivePartnerAction,
    submitVariables: () => ({
      id: partnerData && partnerData.id,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.partners.archiveSuccess',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.partners.archiveFailure',
    }),
    onSuccess: () => {
      refetchPartnersFullInfo();
      archivePartnerModal.hide();
    },
  });

  useEffect(() => {
    pageState.setupFilterLabelTitleMapping(
      getFilterNamesAndOptionsForTitleMapping(filterDropdownOptionsInfoList)
    );
  }, [data]);

  useEffect(() => {
    pageState.updatePageUrl();
  }, [
    pageState.page,
    pageState.sortState,
    pageState.filterState,
    pageState.textSearchState,
    pageState.detailData,
    pageState.detailsModal.isShowing,
  ]);

  if ((!loading && !data) || error) {
    return <GlobalError />;
  }

  const columnsConfig = [
    {
      headerText: intl.formatMessage({
        id: 'shared.title',
      }),
      columnWidth: 220,
    },
    {
      headerText: intl.formatMessage({
        id: 'admin.partners.description',
      }),
      columnWidth: 350,
    },
    {
      headerText: intl.formatMessage({
        id: 'shared.address',
      }),
      columnWidth: 260,
    },
    {
      headerText: intl.formatMessage({
        id: 'admin.partners.campaigns',
      }),
      columnWidth: 150,
    },
    {
      headerText: intl.formatMessage({
        id: 'admin.shared.added',
      }),
      columnWidth: 150,
    },
  ];

  const partnersData = get(data, 'partners.partners', []);

  const rowValues = (partner: any) => [
    <TruncatedByCharText text={partner.title} truncateLength={17} key={1} /> ||
      '',
    partner.description ? (
      <TruncatedByCharText text={partner.description} truncateLength={30} />
    ) : (
      ''
    ),
    partner.address || partner.address2 ? (
      <AddressContainer address={partner.address} address2={partner.address2} />
    ) : (
      ''
    ),
    partner.partnersCampaignsCount ? (
      <CampaignLink
        partnerId={partner.id}
        campaignCount={partner.partnersCampaignsCount}
      />
    ) : (
      '0'
    ),
    partner.createdAt &&
      dateFormatter(partner.createdAt, 'shortMonthDateAndYear'),
  ];

  const toggleEditPartnerAndSetPartnerData = (partnerData: any) => {
    setPartnerData(partnerData);
    toggleEditPartnerModal();
  };

  const toggleArchivePartnerAndSetPartnerData = (partnerData: any) => {
    setPartnerData(partnerData);
    toggleArchivePartnerModal();
  };

  const modalsContent = () => (
    <>
      {addPartnerModal.isShowing && (
        <RoutableModal
          hide={addPartnerModal.hide}
          initialRouteProps={{ refetchPartners: refetchPartnersFullInfo }}
          initialRouteName="partner-create"
          dataQaidSuffix="admin-create-partner"
        />
      )}
      {editPartnerModal.isShowing && (
        <RoutableModal
          hide={editPartnerModal.hide}
          initialRouteProps={{
            ...partnerData,
            refetchPartners: refetchPartnersFullInfo,
          }}
          initialRouteName="partner-edit"
          dataQaidSuffix="admin-edit-partner"
        />
      )}
    </>
  );

  return (
    <Layout scrollDisabled={pageState.detailsModal.isShowing}>
      <ListPageTemplate
        pageTitle={intl.formatMessage({
          id: 'shared.partners',
        })}
        modalsContent={modalsContent}
      >
        <ListingHeader
          pageTitle={intl.formatMessage({
            id: 'shared.partners',
          })}
          addEntityText={intl.formatMessage({
            id: 'admin.partners.addPartner',
          })}
          onClickAddEntity={toggleAddPartnerModal}
          searchPlaceholder={intl.formatMessage({
            id: 'admin.partners.textSearch.placeholder',
          })}
          searchValue={pageState.textSearchState}
          onSearch={pageState.handleTextSearch}
          dataQaidPrefix="partner"
        />
        <div>
          <ListingControls
            sortOptions={sortOptions}
            orderBy={pageState.sortState.by}
            orderDirection={pageState.sortState.direction}
            totalRecords={data && data.partners.metadata.totalRecords}
            loading={loading}
            onSort={pageState.handleSortChange}
            onReset={pageState.handleResetFilters}
            dataQaidPrefix="partner"
          />
          <ListingFilter
            filterTitle={intl.formatMessage({
              id: 'admin.partners.filterTitle',
            })}
            textSearchString={pageState.textSearchState}
            handleTextSearchLabelClose={pageState.handleTextSearchLabelClose}
            labelTitleMapping={pageState.filterLabelTitleMapping}
            dropdownOptionsInfoList={filterDropdownOptionsInfoList}
            filterState={pageState.filterState}
            handleRemoveFilter={pageState.handleRemoveFilter}
            handleFilterChange={pageState.handleFilterChange}
          />
        </div>

        <ListTable
          columnsConfig={columnsConfig}
          rowValues={rowValues}
          dataQaidPrefix="partner"
          onEdit={
            pageState.filterListVariable('archived_status') != 'archived'
              ? (obj: any) => toggleEditPartnerAndSetPartnerData({ ...obj })
              : undefined
          }
          onArchive={
            pageState.filterListVariable('archived_status') != 'archived'
              ? (obj: any) =>
                  toggleArchivePartnerAndSetPartnerData({
                    id: obj.id,
                    title: obj.title,
                    upcomingEventsWithCampaignCount:
                      obj.upcomingEventsWithCampaignCount,
                  })
              : undefined
          }
          data={partnersData}
          loadingContent={
            loading ? (
              <LoadingBlocks.Rectangle width="100%" height="300px" />
            ) : null
          }
          noResultsContent={
            <ListingNoResults
              entityName={intl.formatMessage({
                id: 'shared.partner',
              })}
              numResults={get(data, 'partners.partners.length', undefined)}
              loading={loading}
            />
          }
          footerContent={
            <ListingFooter
              numTotalRecords={get(
                data,
                'partners.metadata.totalRecords',
                undefined
              )}
              perPage={PER_PAGE}
              currentPage={pageState.page}
              onPageChange={pageState.handlePageChange}
              loading={loading}
              dataQaidPrefix="partner"
            />
          }
        />
        {archivePartnerModal.isShowing && (
          <>
            {partnerData && partnerData.partnersCampaignCount > 0 ? (
              <ConfirmationModal
                confirmationButtonText={intl.formatMessage({
                  id: 'okay',
                })}
                description={intl.formatMessage(
                  {
                    id: 'admin.partners.cannotArchiveNote',
                  },
                  {
                    title: partnerData && partnerData.title,
                    upcomingEventsWithCampaignCount:
                      partnerData &&
                      partnerData.partnersCampaignCount.toString(),
                  }
                )}
                onConfirm={() => archivePartnerModal.hide()}
                onClose={() => archivePartnerModal.hide()}
              />
            ) : (
              <ConfirmationModal
                onCancel={() => archivePartnerModal.hide()}
                description={intl.formatMessage(
                  {
                    id: 'admin.partners.archiveConfirmation',
                  },
                  {
                    title: partnerData && partnerData.title,
                  }
                )}
                onConfirm={handleArchivePartner}
              />
            )}
          </>
        )}
      </ListPageTemplate>
    </Layout>
  );
};

const AdminPartnersWrapper: React.FC = () => (
  <ListPage config={pageStateConfig}>
    <AdminPartners />
  </ListPage>
);

export default AdminPartnersWrapper;
