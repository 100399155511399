import React from 'react';
import { useIntl } from 'react-intl';

import { merchandisingPropertyGroupNames } from 'app/shared/utils/events';
import { errorMsgForField } from 'app/shared/utils/form';
import { get } from 'app/shared/utils/get';
import { objectForObjectId } from 'app/shared/utils/object';
import { GetProperties } from 'app/shared/graphql/properties/queryHooks';
import { LoadingError } from 'app/shared/components/atoms/LoadingError';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import FormGroup from 'app/shared/components/molecules/FormGroup';
import Select from 'app/shared/components/molecules/SelectManualCSS';
import { getPropertyOptions } from 'app/admin/utils/optionLists';
import EditFormLoadingBlocks from 'app/admin/components/atoms/EditFormLoadingBlocks';

interface Props {
  setFieldValue: any;
  touched: any;
  errors: any;
  values: any;
  propertyContext: string;
  dataQaId?: string;
}

const getPropertyOptionsByPropertyGroupName = (propertyOptions: any) => {
  const options = propertyOptions.reduce(
    (obj: any, opt: any) => ({
      ...obj,
      [opt.fields.propertyGroupName]: obj[opt.fields.propertyGroupName]
        ? obj[opt.fields.propertyGroupName].concat(opt)
        : [opt],
    }),
    {}
  );
  const sortedOptionsWithNone = Object.keys(options).reduce(
    (obj: any, key: string) => ({
      ...obj,
      [key]: [{ title: 'None', value: null }].concat(
        options[key].sort((optionA: any, optionB: any) => {
          return optionA.title < optionB.title ? -1 : 1;
        })
      ),
    }),
    {}
  );
  return sortedOptionsWithNone;
};

const MerchandisingPropertyGroupsEditFormFields: React.FC<Props> = ({
  setFieldValue,
  touched,
  errors,
  values,
  propertyContext,
  dataQaId,
}) => {
  const intl = useIntl();

  const {
    loading: loadingProperties,
    error: errorProperties,
    data: dataProperties,
  } = GetProperties({
    propertyContext,
  });

  const propertyOptionForPropertyId = (propertyId: number | null) => {
    const property = objectForObjectId(
      get(dataProperties, 'properties.properties', []),
      propertyId
    );

    if (property) {
      return {
        title: property.name,
        value: property.id,
        key: property.key,
        fields: {
          propertyGroupName: property.propertyGroupName,
        },
        iconName: property.iconName,
      };
    } else {
      return null;
    }
  };

  const propertyOptions = getPropertyOptions(dataProperties, true);

  const propertyOptionsByPropertyGroupName = getPropertyOptionsByPropertyGroupName(
    propertyOptions
  );

  if (errorProperties) {
    return <LoadingError whatsBeingLoaded="this form" />;
  }

  if (loadingProperties || !dataProperties) {
    return <EditFormLoadingBlocks />;
  }

  const merchandisingPropertyGroupNamesToDisplay = merchandisingPropertyGroupNames.filter(
    (propertyGroupName: string) =>
      !!propertyOptionsByPropertyGroupName[propertyGroupName]
  );

  return (
    <>
      {merchandisingPropertyGroupNamesToDisplay.map(
        (propertyGroupName: string, index: number) => (
          <React.Fragment key={index}>
            <FormGroup
              data-qaid={`${dataQaId}-formgroup`}
              label={propertyGroupName}
              errorMsg={errorMsgForField(
                'merchandisingProperties',
                touched,
                errors
              )}
            >
              <Select
                data-qaid={`${dataQaId}-field`}
                searchable
                getOptionLabel={(opt: any) => opt.title}
                defaultValue={propertyOptionForPropertyId(
                  values.merchandisingProperties[propertyGroupName]
                )}
                options={propertyOptionsByPropertyGroupName[propertyGroupName]}
                placeholder={intl.formatMessage({
                  id: 'admin.shared.selectGenericPlaceholder',
                })}
                onChange={opt => {
                  setFieldValue('merchandisingProperties', {
                    ...values.merchandisingProperties,
                    [propertyGroupName]: opt && opt.value ? opt.value : null,
                  });
                }}
                initialWidth="350px"
              />
            </FormGroup>
            <Spacer mb={8} />
          </React.Fragment>
        )
      )}
    </>
  );
};

export default MerchandisingPropertyGroupsEditFormFields;
