import React from 'react';
import styled, { css } from 'styled-components';

import { ManualCSSColors } from 'app/shared/theme';
import { useCurrentTheme } from 'app/shared/theme';
import { Icon } from 'app/shared/components/atoms/IconManualCSS';
import { Image } from 'app/shared/components/atoms/ImageManualCSS';
import { StreamlineIcon } from 'app/shared/components/atoms/StreamlineIcon';
import { TagElement } from 'app/admin/components/molecules/TagElementList';

interface LabelWrapperProps {
  height?: number;
}

const LabelWrapper = styled.div<LabelWrapperProps>`
  ${({ theme, height }) => css`
    cursor: pointer;
    background-color: ${theme.colors.whiteDenim};
    padding: ${theme.ruler[1]}px ${theme.ruler[2]}px;
    height: ${theme.ruler[height || 12]}px;
    border-radius: ${theme.ruler[6]}px;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  `}
`;

const IconAndTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

interface IconLabelTextProps {
  labelTextColor?: ManualCSSColors;
  hasSmallLabelText?: boolean;
}

const IconLabelText = styled.div<IconLabelTextProps>`
  ${({ theme, labelTextColor, hasSmallLabelText }) => css`
    ${hasSmallLabelText &&
      css`
        font-size: 10px;
        letter-spacing: 1.5px;
      `}
    padding: 0px ${theme.ruler[2]}px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    ${labelTextColor &&
      css`
        color: ${theme.colors[labelTextColor]};
      `}
  `}
`;

const StyledLabelText = styled.div`
  font-size: 12px;
  letter-spacing: 0.4px;
`;

const LabelsWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  justify-content: center;
`;

const ImageWrapper = styled.div`
  ${({ theme }) => css`
    width: inherit;
    max-width: ${theme.ruler[8]}px;
    height: ${theme.ruler[8]}px;
    border-radius: ${theme.ruler[4]}px;
    overflow: hidden;
  `}
`;

const TagElementWrapper = styled.div`
  padding: 10px 10px 10px 13px;
`;

interface IconWrapperProps {
  iconColor: ManualCSSColors;
  backgroundColor: ManualCSSColors;
  inverted: boolean;
}

const IconWrapper = styled.div<IconWrapperProps>`
  ${({ theme, iconColor, backgroundColor, inverted }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: ${theme.ruler[8]}px;
    min-height: ${theme.ruler[8]}px;
    width: ${theme.ruler[8]}px;
    height: ${theme.ruler[8]}px;
    border-radius: ${theme.ruler[4]}px;
    padding: ${theme.ruler[2]}px;
    background-color: ${theme.colors[
      inverted ? backgroundColor : 'whiteDenim'
    ]};
    color: ${theme.colors[inverted ? 'whiteDenim' : iconColor]};

    i::before {
      color: ${theme.colors[inverted ? 'whiteDenim' : iconColor]};
    }

    ${!inverted &&
      css`
        border: 1px solid ${theme.colors[backgroundColor]};
      `}
  `}
`;

const NotificationDot = styled.div`
  ${({ theme }) => css`
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: ${theme.colors.redRedWine};
    margin-right: 4px;
    display: flex;
    flex-shrink: 0;
  `}
`;

interface Props {
  icon?: string | React.FC;
  iconSize?: string;
  imageUrl?: string;
  labelText?: string;
  upperLabelText?: string;
  tagElement?: TagElementProps;
  onClick?: any;
  draft?: boolean;
  color?: ManualCSSColors;
  labelTextColor?: ManualCSSColors;
  iconColor?: ManualCSSColors;
  hasSmallLabelText?: boolean;
  invertIcon?: boolean;
  className?: string;
  emptyIcon?: boolean;
  showNotification?: boolean;
}

interface TagElementProps {
  tagElementColor: string;
  name: string;
}

const draftColor = 'blueSmoke';

const IconLabel: React.FC<Props> = ({
  icon,
  iconSize = '14px',
  color,
  labelTextColor,
  iconColor,
  hasSmallLabelText = false,
  imageUrl,
  labelText,
  upperLabelText,
  tagElement,
  onClick,
  draft = false,
  invertIcon = false,
  className = '',
  emptyIcon = false,
  showNotification = false,
}) => {
  const theme = useCurrentTheme();

  const getLabelTextColor = () => {
    if (labelTextColor) {
      return labelTextColor;
    } else if (draft) {
      return draftColor;
    } else {
      return undefined;
    }
  };

  return (
    <LabelWrapper
      data-qaid="label"
      onClick={onClick}
      className={className}
      height={tagElement ? 24 : undefined}
    >
      <IconAndTextWrapper>
        {draft && (
          <IconWrapper
            color="macyGrey"
            backgroundColor="macyGrey"
            iconColor="blueSmoke"
            inverted={false}
            data-qaid="iconlabel-icon-wrapper"
          >
            <Icon name="loading" size={iconSize} data-qaid="iconlabel-icon" />
          </IconWrapper>
        )}
        {imageUrl && (
          <ImageWrapper data-qaid="iconlabel-image-wrapper">
            <Image
              src={imageUrl}
              style={{
                minWidth: '100%',
                minHeight: '100%',
              }}
              data-qaid="iconlabel-image"
            />
          </ImageWrapper>
        )}
        {!imageUrl && icon && (
          <IconWrapper
            backgroundColor={color || 'macyGrey'}
            iconColor="blueSmoke"
            inverted={invertIcon}
            data-qaid="iconlabel-icon-wrapper"
          >
            {typeof icon === 'string' ? (
              <Icon name={icon} size={iconSize} data-qaid="iconlabel-icon" />
            ) : (
              <StreamlineIcon
                icon={icon}
                stroke={
                  (iconColor && theme.colors[iconColor]) ||
                  theme.colors.macyGrey
                }
                data-qaid="iconlabel-streamline-icon"
                size={14}
              />
            )}
          </IconWrapper>
        )}
        {!imageUrl && !icon && emptyIcon && (
          <IconWrapper
            backgroundColor={color || 'macyGrey'}
            iconColor="blueSmoke"
            inverted={invertIcon}
            data-qaid="iconlabel-icon-wrapper"
          />
        )}
        {!icon && !imageUrl && !draft && !labelText && (
          <IconWrapper
            backgroundColor="macyGrey"
            iconColor="blueSmoke"
            inverted={true}
            data-qaid="iconlabel-icon-wrapper"
          >
            <Icon name="plus" size={iconSize} data-qaid="iconlabel-icon" />
          </IconWrapper>
        )}
        <LabelsWrapper>
          {upperLabelText && (
            <IconLabelText
              labelTextColor={getLabelTextColor()}
              hasSmallLabelText={true}
              data-qaid="iconlabel-upper-label-text"
            >
              {upperLabelText}
            </IconLabelText>
          )}
          <IconLabelText
            labelTextColor={getLabelTextColor()}
            hasSmallLabelText={hasSmallLabelText}
            data-qaid="iconlabel-text"
          >
            {hasSmallLabelText && upperLabelText ? (
              <StyledLabelText>{labelText}</StyledLabelText>
            ) : (
              labelText
            )}
          </IconLabelText>
        </LabelsWrapper>
        {showNotification && <NotificationDot data-qaid="offer-notification" />}
      </IconAndTextWrapper>
      {tagElement && (
        <TagElementWrapper>
          <TagElement
            tagElementColor={tagElement.tagElementColor}
            textColor="#ffffff"
          >
            {tagElement.name}
          </TagElement>
        </TagElementWrapper>
      )}
    </LabelWrapper>
  );
};

export default IconLabel;
