import React from 'react';
import { useIntl } from 'react-intl';

import { get } from 'app/shared/utils/get';
import {
  citySocialUrls,
  getSocialUrlsFormInitialValues,
  getSocialUrlsSubmitVariable,
} from 'app/shared/utils/socialLinks';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { getTitlesSubmitVariable } from 'app/admin/utils/titleByLocale';
import { CreateCity } from 'app/admin/graphql/cities/mutationHooks';
import { DetailsFormMainContainer } from 'app/admin/components/atoms/DetailContent';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

import CityBasicInfoForm from './CityBasicInfoForm';
import CityBasicInfoFormSchema from './CityBasicInfoFormSchema';

interface Props {
  contentProps?: any;
  setFormSubmitAction?: (func: Function) => void;
  setDisplayConfirmation?: (set: boolean) => void;
  setIsSubmitting?: (set: boolean) => void;
  navigateTo: (routeData: object) => void;
}

const CityCreate: React.FC<Props> = ({
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  navigateTo,
}) => {
  const intl = useIntl();

  const formInitialValues = {
    isNewCity: true,
    titleEn: '',
    titleEs: '',
    titleIt: '',
    countryId: null,
    businessOwnerId: null,
    latitude: '',
    longitude: '',
    cityOperatingModel: null,
    sofarOperatedMarketType: null,
    cityStatus: 'active',
    aliases: '',
    socialUrls: getSocialUrlsFormInitialValues(citySocialUrls),
  };

  const createCityAction = CreateCity();

  const handleCreateCity = useSubmitAction({
    submitAction: createCityAction,
    submitVariables: (values: any) => ({
      titles: getTitlesSubmitVariable(values),
      countryId: values.countryId,
      businessOwnerId: [
        'sofar_operated',
        'sofar_operated_and_curator',
      ].includes(values.cityOperatingModel)
        ? values.businessOwnerId
        : null,
      latitude: values.latitude ? parseFloat(values.latitude) : null,
      longitude: values.longitude ? parseFloat(values.longitude) : null,
      cityOperatingModel: values.cityOperatingModel,
      sofarOperatedMarketType: [
        'sofar_operated',
        'sofar_operated_and_curator',
      ].includes(values.cityOperatingModel)
        ? values.sofarOperatedMarketType
        : null,
      aliases: values.aliases,
      socialUrls: getSocialUrlsSubmitVariable(values),
    }),
    successMsg: intl.formatMessage({
      id: 'admin.cityCreate.successMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.cityCreate.failureMessage',
    }),
    onSuccess: (response: any) => {
      navigateTo({
        routeName: 'city-details',
        routeProps: {
          id: get(response.data, 'createCity.city.id', undefined),
        },
      });
    },
  });

  return (
    <ModalContentContainer>
      <DetailsFormMainContainer>
        <DetailsHeader
          title={intl.formatMessage({
            id: 'admin.cityCreate.title',
          })}
          isNewForm={true}
        />
        <GenericForm
          formInitialValues={formInitialValues}
          renderFormComponent={(renderProps: any) => (
            <CityBasicInfoForm
              formikProps={renderProps.formikProps}
              isNewCity={true}
            />
          )}
          onSubmit={handleCreateCity}
          formSchema={CityBasicInfoFormSchema(intl)}
          setFormSubmitAction={setFormSubmitAction}
          setDisplayConfirmation={setDisplayConfirmation}
          setIsSubmitting={setIsSubmitting}
          dataQaId="city-create-form"
        />
      </DetailsFormMainContainer>
    </ModalContentContainer>
  );
};

export default CityCreate;
