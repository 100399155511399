import styled, { css } from 'styled-components';

import {
  withTypography,
  WithTypographyProps,
} from 'app/shared/components/styleUtils/manualCSS/withTypography';
import {
  getSpaceAfter,
  withH1Style,
  withH2Style,
  withH3Style,
  withH4Style,
  withH5Style,
  withH6Style,
} from 'app/shared/components/styleUtils/NativeStyling';

const withTitleStyle = ({ spaceAfter }: any) => css`
  ${({ theme }) => css`
    ${theme.fontStyles.title.fontFamily &&
      css`
        font-family: ${theme.fontStyles.title.fontFamily};
      `};
    font-size: ${theme.fontSizes.title.xs};
    font-weight: 400;
    letter-spacing: ${theme.fontStyles.title.letterSpacing};
    line-height: normal;
    margin: 0;
    padding: 0;
    margin-bottom: ${theme.ruler[getSpaceAfter(10, spaceAfter)]}px;
    color: ${theme.fontColors.secondary};

    ${theme.media.md`
        font-size: ${theme.fontSizes.title.md};
      `};

    ${theme.media.lg`
        font-size: ${theme.fontSizes.title.lg};
      `};
  `}
`;

interface TypographyProps extends WithTypographyProps {
  children?: any;
  spaceAfter?: number;
}

export const Title = styled.h2<TypographyProps>`
  ${withTitleStyle}
  ${withTypography}
`;

export const H1 = styled.h1<TypographyProps>`
  ${withH1Style}
  ${withTypography}
`;

export const H2 = styled.h2<TypographyProps>`
  ${withH2Style}
  ${withTypography}
`;

export const H3 = styled.h3<TypographyProps>`
  ${withH3Style}
  ${withTypography}
`;

export const H4 = styled.h4<TypographyProps>`
  ${withH4Style}
  ${withTypography}
`;

export const H5 = styled.h5<TypographyProps>`
  ${withH5Style}
  ${withTypography}
`;

export const H6 = styled.h6<TypographyProps>`
  ${withH6Style}
  ${withTypography}
`;

export const Body = styled.p<TypographyProps>`
  ${({ theme, spaceAfter }) => css`
    font-weight: 400;
    letter-spacing: ${theme.fontStyles.body.letterSpacing};
    line-height: ${theme.fontStyles.body.lineHeight};
    margin: 0;
    padding: 0;
    margin-bottom: ${spaceAfter ? theme.ruler[spaceAfter] : 0}px;
    color: ${theme.fontColors.secondary};
    font-size: ${theme.fontSizes.body1.xs};

    ${theme.media.xs`
      font-size: ${theme.fontSizes.body1.xs};
    `};

    ${theme.media.lg`
      font-size: ${theme.fontSizes.body1.lg};
    `};
  `};

  ${withTypography}
`;

export const Body2 = styled.p<TypographyProps>`
  ${({ theme, spaceAfter }) => css`
    font-weight: normal;
    letter-spacing: ${theme.fontStyles.body2.letterSpacing};
    line-height: ${theme.fontStyles.body2.lineHeight};
    margin: 0;
    padding: 0;
    margin-bottom: ${spaceAfter ? theme.ruler[spaceAfter] : 0}px;
    color: ${theme.fontColors.secondary};
    font-size: ${theme.fontSizes.body2.xs};

    ${theme.media.xs`
      font-size: ${theme.fontSizes.body2.xs};
    `};

    ${theme.media.lg`
      font-size: ${theme.fontSizes.body2.lg};
    `};
  `};

  ${withTypography}
`;

export const Overline = styled.span<TypographyProps>`
  ${({ theme, spaceAfter, inverted }) => css`
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    line-height: 1.6;
    margin: 0;
    padding: 0;
    margin-bottom: ${spaceAfter ? theme.ruler[spaceAfter] : 0}px;
    display: inline-block;
    font-size: ${theme.fontSizes.overline.xs};
    color: ${inverted ? theme.colors.whiteDenim : theme.fontColors.secondary};

    ${theme.media.xs`
      font-size: ${theme.fontSizes.overline.xs};
    `};

    ${theme.media.lg`
      font-size: ${theme.fontSizes.overline.lg};
    `};
  `};

  ${withTypography}
`;

export const Anchor = styled.span<TypographyProps>`
  ${({ theme, spaceAfter }) => css`
    font-weight: bold;
    color: ${theme.colors.primary};
    margin-bottom: ${spaceAfter ? theme.ruler[spaceAfter] : 0}px;
    display: inline-block;
    font-size: ${theme.fontSizes.body2.xs};

    ${theme.media.xs`
      font-size: ${theme.fontSizes.body2.xs};
    `};

    ${theme.media.lg`
      font-size: ${theme.fontSizes.body2.lg};
    `};
  `};

  ${withTypography}

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const Caption = styled.span<TypographyProps>`
  ${({ theme, spaceAfter }) => css`
    font-size: ${theme.fontSizes.caption.xs};
    font-weight: normal;
    letter-spacing: ${theme.fontStyles.caption.letterSpacing};
    line-height: ${theme.fontStyles.caption.lineHeight};
    margin: 0;
    padding: 0;
    margin-bottom: ${spaceAfter ? theme.ruler[spaceAfter] : 0}px;
    display: inline-block;
    color: ${theme.fontColors.secondary};

    ${theme.media.xs`
      font-size: ${theme.fontSizes.caption.xs};
    `};

    ${theme.media.lg`
      font-size: ${theme.fontSizes.caption.lg};
    `};
  `};

  ${withTypography}
`;

export const FormGroupLabel = styled.span<TypographyProps>`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.formGroupLabel};
    font-weight: 400;
    letter-spacing: ${theme.fontStyles.formGroupLabel.letterSpacing};
    margin-bottom: ${theme.ruler[2]}px;
    display: flex;
    color: ${theme.fontColors.secondary};
  `}

  ${withTypography}
`;
