import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Artist } from 'app/typings/artists';
import { City } from 'app/typings/cities';
import { KeyValue } from 'app/typings/generics';
import { dateFormatter } from 'app/shared/utils/datetime';
import { objectListFilteredByBooleanConditionOnKeys } from 'app/shared/utils/object';
import useModal from 'app/shared/utils/useModal';
import usePermission from 'app/shared/utils/usePermission';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import ConfirmationModal from 'app/shared/components/molecules/ConfirmationModal';
import { DeclineBookingRequest } from 'app/admin/graphql/bookingRequests/mutationHooks';
import { KeyAboveValueInfoList } from 'app/admin/components/molecules/KeyAboveValueInfoList';
import ListingCard from 'app/admin/components/organisms/ListingCard';
import { ReactComponent as EmailActionUnread } from 'icons/streamline-regular/emails/email-actions/email-action-unread.svg';
import { ReactComponent as Check2 } from 'icons/streamline-regular/interface-essential/form-validation/check-2.svg';
import { ReactComponent as RemoveSquare } from 'icons/streamline-regular/interface-essential/form-validation/remove-square.svg';

interface BookingRequestCardProps {
  id: number;
  index: number;
  city: City;
  artist: Artist;
  availabilityStart: string;
  availabilityEnd: string;
  bandSetup: string;
  notes: string;
  status: string;
  createdAt: string;
  onViewArtistTourRequest: (bookingRequestData: any) => void;
  onAcceptBookingRequest: (bookingRequestData: any) => void;
  refetchBookingRequests: VoidFunction;
}

interface TopContentContainerProps {
  height: string;
}

const TopContentContainer = styled.div<TopContentContainerProps>`
  height: ${props => props.height};
`;

const BookingRequestCard: React.FC<BookingRequestCardProps> = ({
  id,
  index,
  city,
  artist,
  availabilityStart,
  availabilityEnd,
  bandSetup,
  notes,
  status,
  createdAt,
  onViewArtistTourRequest,
  onAcceptBookingRequest,
  refetchBookingRequests,
}) => {
  const intl = useIntl();

  const hasEditBookingRequestPermission = usePermission(
    'artist.bookingRequest.edit'
  );

  const [
    declineBookingRequestModal,
    toggleDeclineBookingRequestModal,
  ] = useModal();

  const keysAboveValuesList = objectListFilteredByBooleanConditionOnKeys(
    [
      {
        key: intl.formatMessage({
          id: 'admin.bookingRequests.listingCard.sofarCity',
        }),
        value: city.title,
      },
      {
        key: intl.formatMessage({
          id: 'shared.email',
        }),
        value: artist.email || 'None',
      },
      {
        key: intl.formatMessage({
          id: 'admin.bookingRequests.listingCard.availability',
        }),
        value:
          availabilityStart && availabilityEnd
            ? dateFormatter(availabilityStart, 'shortMonthDateAndYear') +
              ' - ' +
              dateFormatter(availabilityEnd, 'shortMonthDateAndYear')
            : intl.formatMessage({
                id: 'admin.bookingRequests.listingCard.notEntered',
              }),
      },
      {
        key: intl.formatMessage({
          id: 'admin.bookingRequests.listingCard.travel',
        }),
        value: intl.formatMessage({
          id: 'admin.bookingRequests.listingCard.tourRequest',
        }),
        action: () => {
          onViewArtistTourRequest({
            artist,
            bandSetup,
            notes,
            createdAt,
          });
        },
      },
      {
        key: intl.formatMessage({
          id: 'admin.bookingRequests.listingCard.bandSetup',
        }),
        value: bandSetup || 'None',
      },
      {
        key: intl.formatMessage({
          id: 'admin.bookingRequests.listingCard.notes',
        }),
        value: notes || 'None',
      },
    ],
    {
      [intl.formatMessage({
        id: 'shared.email',
      })]: hasEditBookingRequestPermission,
    }
  );

  const statusDisplayTexts = {
    received: intl.formatMessage({
      id: 'admin.shared.review',
    }),
    accepted: intl.formatMessage({
      id: 'admin.bookingRequests.listingCard.accepted',
    }),
    declined: intl.formatMessage({
      id: 'admin.bookingRequests.listingCard.declined',
    }),
  };

  const keysAndValuesList = [
    {
      key: intl.formatMessage({
        id: 'shared.status',
      }),
      value: statusDisplayTexts[status],
    },
    {
      key: intl.formatMessage({
        id: 'admin.shared.submitted',
      }),
      value: createdAt
        ? dateFormatter(createdAt, 'shortMonthDateAndYear')
        : 'None',
    },
  ];

  const controlsInfoList = [
    {
      href:
        !artist.email || !hasEditBookingRequestPermission
          ? ''
          : `mailto: ${artist.email || ''}`,
      icon: EmailActionUnread,
      tipText: intl.formatMessage({
        id: 'admin.bookingRequests.listingCard.emailArtist',
      }),
    },
    {
      action:
        status != 'received' || !hasEditBookingRequestPermission
          ? undefined
          : toggleDeclineBookingRequestModal,
      icon: RemoveSquare,
      tipText: intl.formatMessage({
        id: 'admin.bookingRequests.listingCard.decline',
      }),
    },
    {
      action:
        status != 'received' || !hasEditBookingRequestPermission
          ? undefined
          : () => {
              onAcceptBookingRequest({
                id,
                city,
                artist,
                availabilityStart,
                availabilityEnd,
              });
            },
      icon: Check2,
      tipText: intl.formatMessage({
        id: 'admin.bookingRequests.listingCard.accept',
      }),
    },
  ];

  const declineBookingRequestAction = DeclineBookingRequest();

  const handleDeclineBookingRequest = useSubmitAction({
    submitAction: declineBookingRequestAction,
    submitVariables: () => ({ id }),
    successMsg: intl.formatMessage(
      {
        id: 'admin.bookingRequests.listingCard.archiveSuccessMessage',
      },
      {
        artistName: artist.title,
      }
    ),
    failureMsg: intl.formatMessage({
      id: 'admin.bookingRequests.listingCard.archiveFailureMessage',
    }),
    onSuccess: () => {
      declineBookingRequestModal.hide();
      refetchBookingRequests();
    },
  });

  const renderTopContent = (keysAboveValuesList: KeyValue[]) => {
    return (
      <TopContentContainer height="300px">
        <KeyAboveValueInfoList keysAndValues={keysAboveValuesList} />
      </TopContentContainer>
    );
  };

  return (
    <>
      <ListingCard
        title={artist.title}
        topContent={() => renderTopContent(keysAboveValuesList)}
        keysAndValuesList={keysAndValuesList}
        controlsInfoList={controlsInfoList}
        dataQaidPrefix="booking-requests"
        index={index}
        height="470px"
      />
      {declineBookingRequestModal.isShowing && (
        <ConfirmationModal
          onCancel={() => declineBookingRequestModal.hide()}
          description={intl.formatMessage(
            {
              id: 'admin.bookingRequests.listingCard.declineConfirmation',
            },
            {
              artistName: artist.title,
            }
          )}
          onConfirm={handleDeclineBookingRequest}
        />
      )}
    </>
  );
};

export default BookingRequestCard;
