import React from 'react';
import styled from 'styled-components';

import { Container } from 'app/shared/components/atoms/Container';
import { Caption } from 'app/shared/components/atoms/TypographyManualCSS';

interface Props {
  maxCharacters?: number;
  charactersLength: number;
}

const CounterContainer = styled(Container)`
  margin: 4px 4px 6px 4px;
`;

export const RemainingCharactersCounter: React.FC<Props> = ({
  maxCharacters,
  charactersLength,
}) => {
  if (!maxCharacters) {
    return null;
  }

  return (
    <CounterContainer>
      <Caption>
        Remaining characters: {maxCharacters - charactersLength}
      </Caption>
    </CounterContainer>
  );
};
