import React from 'react';

import GenericFormContainer from 'app/shared/components/atoms/GenericFormContainer';
import FromEmailSettingsEditForm from 'app/admin/components/organisms/FromEmailSettingsEditForm';

interface FormProps {
  formikProps: any;
  curatorGroupId?: number;
}

const CuratorGroupEmailsEditForm: React.FC<FormProps> = ({
  formikProps,
  curatorGroupId,
}) => {
  const { setFieldValue, touched, errors, values } = formikProps;

  return (
    <GenericFormContainer dataQaId="curator-group-emails-section-edit-form">
      <FromEmailSettingsEditForm
        setFieldValue={setFieldValue}
        touched={touched}
        errors={errors}
        values={values}
        curatorGroupId={curatorGroupId}
        overrideHelpTextType="curator_group"
      />
    </GenericFormContainer>
  );
};

export default CuratorGroupEmailsEditForm;
