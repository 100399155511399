import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { CompetitionTemplate } from 'app/typings/competitionTemplates';
import { dateFormatter } from 'app/shared/utils/datetime';
import { get } from 'app/shared/utils/get';
import useModal from 'app/shared/utils/useModal';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import { AddIcon } from 'app/shared/components/atoms/IconLibrary';
import { LoadingBlocks } from 'app/shared/components/atoms/LoadingBlocks';
import TruncatedByCharText from 'app/shared/components/atoms/TruncatedByCharText';
import { H2 } from 'app/shared/components/atoms/TypographyManualCSS';
import ConfirmationModal from 'app/shared/components/molecules/ConfirmationModal';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { DeleteCompetitionTemplate } from 'app/admin/graphql/competitionTemplates/mutationHooks';
import { GetCompetitionTemplates } from 'app/admin/graphql/competitionTemplates/queryHooks';
import { DetailHeading as Heading } from 'app/admin/components/atoms/DetailContent';
import ListTable from 'app/admin/components/organisms/ListTable';

interface Props {
  navigateTo: (routeData: any) => void;
  hide: VoidFunction;
}

const MainContainer = styled.div`
  ${({ theme }) => css`
    padding: 10px 0px 0px 0px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: left;

    ${theme.media.md`
    padding: 20px 0px 0px 20px;
  `}
  `}
`;

const Title = styled(H2)`
  ${({ theme }) => css`
    font-size: 16px;
    font-weight: normal;
    line-height: 1.2em;
    margin-bottom: 25px;

    ${theme.media.xl`
    font-size: 34px;
    margin-bottom: 40px;
    margin-left: 0px;
  `};
  `}
`;

const CompetitionTemplateHeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 20px;
  white-space: nowrap;
`;

const CompetitionTemplateHeading = styled(Heading)`
  margin-bottom: 0;
`;

const AddIconWrapper = styled.div`
  margin-left: 10px;
  margin-top: -2px;
`;

const NoCompetitionTemplates = styled.div`
  white-space: nowrap;
  width: 100%;
`;

const CompetitionTemplates: React.FC<Props> = ({ navigateTo, hide }) => {
  const intl = useIntl();

  const [
    competitionTemplateDeleteModal,
    toggleCompetitionTemplateDeleteModal,
  ] = useModal();
  const [competitionTemplateData, setCompetitionTemplateData] = useState<any>(
    undefined
  );

  const {
    loading: loadingCompetitionTemplates,
    error: errorCompetitionTemplates,
    data: dataCompetitionTemplates,
    refetch: refetchCompetitionTemplates,
  } = GetCompetitionTemplates({
    skipPagination: true,
    fetchPolicy: 'cache-and-network',
  });

  const deleteCompetitionTemplateAction = DeleteCompetitionTemplate();

  const handleDeleteCompetitionTemplate = useSubmitAction({
    submitAction: deleteCompetitionTemplateAction,
    submitVariables: () => ({
      competitionTemplateId:
        competitionTemplateData && competitionTemplateData.id,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.competitionTemplates.deleteSuccess',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.competitionTemplates.deleteFailure',
    }),
    onSuccess: () => {
      competitionTemplateDeleteModal.hide();
      refetchCompetitionTemplates();
    },
  });

  if (
    (!loadingCompetitionTemplates && !dataCompetitionTemplates) ||
    errorCompetitionTemplates
  ) {
    hide();
    return null;
  }

  const columnsConfig = [
    {
      headerText: intl.formatMessage({
        id: 'admin.competitionTemplates.templateName',
      }),
      columnWidth: 350,
    },
    {
      headerText: intl.formatMessage({
        id: 'admin.shared.added',
      }),
      columnWidth: 150,
    },
  ];

  const competitionTemplatesData = get(
    dataCompetitionTemplates,
    'competitionTemplates.competitionTemplates',
    []
  );

  const rowValues = (competitionTemplate: CompetitionTemplate) => {
    return [
      <TruncatedByCharText
        text={competitionTemplate.templateName || null}
        truncateLength={40}
        key={competitionTemplate.id}
      />,
      dateFormatter(
        competitionTemplate.createdAt || '',
        'shortMonthDateAndYear'
      ),
    ];
  };

  const toggleCompetitionTemplateCreateModal = () =>
    navigateTo({
      routeName: 'competition-template-create',
      routeProps: {},
    });

  const toggleCompetitionTemplateEditModal = (competitionTemplateData: any) => {
    const competitionTemplate = competitionTemplatesData.find(
      (competitionTemplate: CompetitionTemplate) =>
        competitionTemplate.id == competitionTemplateData.id
    );
    navigateTo({
      routeName: 'competition-template-edit',
      routeProps: {
        ...competitionTemplate,
      },
    });
  };

  const toggleCompetitionTemplateDeleteAndSetCompetitionTemplateData = (
    competitionTemplateData: any
  ) => {
    setCompetitionTemplateData(competitionTemplateData);
    toggleCompetitionTemplateDeleteModal();
  };

  return (
    <ModalContentContainer data-qaid="competition-templates-modal">
      <MainContainer>
        <Title>
          {intl.formatMessage({
            id: 'admin.competitionTemplates.editCompetitionTemplates',
          })}
        </Title>
        <CompetitionTemplateHeadingContainer>
          <CompetitionTemplateHeading>
            {intl.formatMessage(
              {
                id: 'admin.competitionTemplates.competitionTemplatesCount',
              },
              {
                count: competitionTemplatesData.length,
              }
            )}
          </CompetitionTemplateHeading>
          <AddIconWrapper>
            <AddIcon
              iconSize={12}
              onClick={toggleCompetitionTemplateCreateModal}
            />
          </AddIconWrapper>
        </CompetitionTemplateHeadingContainer>
        <ListTable
          columnsConfig={columnsConfig}
          rowValues={rowValues}
          onEdit={(obj: any) =>
            toggleCompetitionTemplateEditModal({
              id: obj.id,
            })
          }
          onDelete={(obj: any) =>
            toggleCompetitionTemplateDeleteAndSetCompetitionTemplateData({
              id: obj.id,
              templateName: obj.templateName,
            })
          }
          data={competitionTemplatesData}
          loadingContent={
            loadingCompetitionTemplates ? (
              <LoadingBlocks.Rectangle width="100%" height="300px" />
            ) : null
          }
          noResultsContent={
            !loadingCompetitionTemplates &&
            competitionTemplatesData.length == 0 && (
              <NoCompetitionTemplates>
                {intl.formatMessage({
                  id: 'admin.competitionTemplates.noCompetitionTemplates',
                })}
              </NoCompetitionTemplates>
            )
          }
        />
      </MainContainer>
      {competitionTemplateDeleteModal.isShowing && (
        <ConfirmationModal
          onCancel={() => competitionTemplateDeleteModal.hide()}
          description={intl.formatMessage(
            {
              id: 'admin.competitionTemplates.deleteConfirmation',
            },
            {
              templateName:
                competitionTemplateData && competitionTemplateData.templateName,
            }
          )}
          onConfirm={handleDeleteCompetitionTemplate}
        />
      )}
    </ModalContentContainer>
  );
};

export default CompetitionTemplates;
