import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { Role } from 'app/typings/roles';
import { EventsStaffedSummary, User } from 'app/typings/users';
import usePermission from 'app/shared/utils/usePermission';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import { Icon } from 'app/shared/components/atoms/IconManualCSS';
import TruncatedByCharText from 'app/shared/components/atoms/TruncatedByCharText';
import {
  curatorEditablePrimaryRoleKeys,
  roleResourceTypeLabels,
} from 'app/admin/utils/users';
import {
  DetailHeading as Heading,
  DetailValueContainer as ValueContainer,
} from 'app/admin/components/atoms/DetailContent';
import { None } from 'app/admin/components/atoms/None';

interface Props {
  sectionData: User;
  callbacks: { [name: string]: Function };
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const RolesHeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 20px;
`;

const RolesHeading = styled(Heading)`
  margin-bottom: 0;
`;

const PlusIconWrapper = styled.div`
  margin-left: 7px;
  margin-top: -2px;
  cursor: pointer;
`;

const PlusIcon = styled(Icon)`
  ${({ theme }) => css`
    &::before {
      color: ${theme.colors.green800};
    }
  `}
`;

const RoleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding-bottom: 12px;
`;

const RoleLabel = styled.div`
  max-width: 500px;
  white-space: nowrap;
`;

const RoleAction = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
`;

const TrashCanIcon = styled(Icon)`
  ${({ theme }) => css`
    &::before {
      color: ${theme.colors.green600};
    }
  `}
`;

const EventsStaffedHeadingContainer = styled(Heading)`
  margin-bottom: 20px;
`;

const EventsStaffedContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding-bottom: 12px;
`;

const EventsStaffedLabel = styled.div`
  width: 170px;
  white-space: nowrap;
`;

const EventsStaffedValue = styled.div`
  padding-left: 5px;
`;

const resourceTypeDisplay = (resourceType: string) =>
  resourceType == 'CuratorGroup'
    ? 'Group'
    : roleResourceTypeLabels[resourceType] || resourceType;

const getFullRoleName = (role: Role) => {
  if (role.resourceType && role.resourceName && role.resourceName !== 'null') {
    return `${role.name} (${resourceTypeDisplay(role.resourceType)}: ${
      role.resourceName
    })`;
  }
  return role.name;
};

const renderRoles = (
  sectionData: User,
  callbacks: { [name: string]: Function },
  hasEditUserRolesPermission: boolean,
  hasEditUserRolesByCuratorGroupPermission: boolean
) => {
  if (sectionData.roles && sectionData.roles.length > 0) {
    return sectionData.roles.map((role: Role, i: number) => {
      const fullRoleName = getFullRoleName(role);
      return (
        <RoleContainer key={i}>
          <RoleLabel>
            <TruncatedByCharText text={fullRoleName} truncateLength={60} />
          </RoleLabel>
          {(hasEditUserRolesPermission ||
            (hasEditUserRolesByCuratorGroupPermission &&
              curatorEditablePrimaryRoleKeys.includes(role.key))) && (
            <RoleAction
              onClick={() =>
                callbacks.toggleRemoveRoleAndSetRoleData({
                  roleToRemove: role.key,
                  resourceId: role.resourceId,
                  resourceType: role.resourceType,
                  fullRoleName,
                })
              }
            >
              <TrashCanIcon name="trashCan" size="16px" />
            </RoleAction>
          )}
        </RoleContainer>
      );
    });
  } else {
    return <None />;
  }
};

const renderEventsStaffedSummaries = (sectionData: User) => {
  if (
    sectionData.eventsStaffedSummaries &&
    sectionData.eventsStaffedSummaries.length > 0
  ) {
    return sectionData.eventsStaffedSummaries.map(
      (eventsStaffedSummary: EventsStaffedSummary, i: number) => {
        return (
          <EventsStaffedContainer key={i}>
            <EventsStaffedLabel>
              {eventsStaffedSummary.primaryRole.name}
            </EventsStaffedLabel>
            <EventsStaffedValue>
              {eventsStaffedSummary.numEventsStaffed}
            </EventsStaffedValue>
          </EventsStaffedContainer>
        );
      }
    );
  } else {
    return <None />;
  }
};

const SectionRoles: React.FC<Props> = ({ sectionData, callbacks }) => {
  const intl = useIntl();

  const hasEditUserRolesPermission = usePermission('user.roles.edit');
  const hasEditUserRolesByCuratorGroupPermission = usePermission(
    'user.roles.editByCuratorGroup'
  );

  return (
    <MainContainer data-qaid="section-permissions">
      <Grid cols={2}>
        <Col xs={2} sm={2} md={1} lg={1} xl={1} key={1}>
          <RolesHeadingContainer>
            <RolesHeading>
              {intl.formatMessage({
                id: 'admin.shared.roles',
              })}
            </RolesHeading>
            {(hasEditUserRolesPermission ||
              hasEditUserRolesByCuratorGroupPermission) && (
              <PlusIconWrapper onClick={() => callbacks.toggleAddRoleModal()}>
                <PlusIcon name="plus" size="12px" />
              </PlusIconWrapper>
            )}
          </RolesHeadingContainer>
          <ValueContainer>
            {renderRoles(
              sectionData,
              callbacks,
              hasEditUserRolesPermission,
              hasEditUserRolesByCuratorGroupPermission
            )}
          </ValueContainer>
        </Col>
        <Col xs={2} sm={2} md={1} lg={1} xl={1} key={2}>
          <EventsStaffedHeadingContainer>
            {intl.formatMessage({
              id: 'admin.userRoles.heading.showsWorkedByRole',
            })}
          </EventsStaffedHeadingContainer>
          <ValueContainer>
            {renderEventsStaffedSummaries(sectionData)}
          </ValueContainer>
        </Col>
      </Grid>
    </MainContainer>
  );
};

export default SectionRoles;
