import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Competition } from 'app/typings/competitions';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import { GenericLink } from 'app/shared/components/atoms/LinkManualCSS';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import TruncatedByCharText from 'app/shared/components/atoms/TruncatedByCharText';
import IconAndTextLink from 'app/shared/components/molecules/IconAndTextLink';
import {
  DetailHeading as Heading,
  DetailValueContainer as ValueContainer,
} from 'app/admin/components/atoms/DetailContent';
import { None } from 'app/admin/components/atoms/None';
import { ReactComponent as Camera1 } from 'icons/streamline-regular/images-photography/cameras/camera-1.svg';

interface Props {
  sectionData: Competition;
  callbacks: { [name: string]: Function };
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const SectionContent: React.FC<Props> = ({ sectionData, callbacks }) => {
  const intl = useIntl();

  return (
    <>
      <MainContainer data-qaid="section-content">
        <Grid cols={4}>
          <Col xs={12} sm={12} md={1} lg={1} xl={1} key={1}>
            <Heading>
              {intl.formatMessage({
                id: 'admin.competitionContent.heading.headerTitle',
              })}
            </Heading>
            <ValueContainer>{sectionData.title}</ValueContainer>
            <Heading>
              {intl.formatMessage({
                id: 'admin.competitionContent.heading.introduction',
              })}
            </Heading>
            <ValueContainer>{sectionData.intro || <None />}</ValueContainer>
            <Heading>
              {intl.formatMessage({
                id: 'admin.competitionContent.heading.thirdPartyCtaText',
              })}
            </Heading>
            <ValueContainer>
              {sectionData.thirdPartyCtaText || <None />}
            </ValueContainer>
            <Heading>
              {intl.formatMessage({
                id: 'admin.competitionContent.heading.thirdPartyCtaLink',
              })}
            </Heading>
            <ValueContainer>
              {sectionData.thirdPartyCtaLink ? (
                <GenericLink url={sectionData.thirdPartyCtaLink}>
                  <TruncatedByCharText
                    text={sectionData.thirdPartyCtaLink}
                    truncateLength={30}
                  />
                </GenericLink>
              ) : (
                <None />
              )}
            </ValueContainer>
            <Spacer mb={4} />
            <IconAndTextLink
              icon={Camera1}
              text={intl.formatMessage({
                id: 'admin.competitionContent.heading.manageBackgroundImage',
              })}
              onClick={() => callbacks.toggleEditBackgroundImageModal()}
            />
            <Spacer mb={4} />
            <IconAndTextLink
              icon={Camera1}
              text={intl.formatMessage({
                id: 'admin.competitionContent.heading.manageLogo',
              })}
              onClick={() => callbacks.toggleEditLogoImageModal()}
            />
          </Col>
          <Col xs={12} sm={12} md={1} lg={1} xl={1} key={2}>
            <Heading>
              {intl.formatMessage({
                id: 'admin.competitionContent.heading.bodyTitle',
              })}
            </Heading>
            <ValueContainer>{sectionData.subtitle || <None />}</ValueContainer>
            <Heading>
              {intl.formatMessage({
                id: 'admin.competitionContent.heading.bodySubheading',
              })}
            </Heading>
            <ValueContainer>
              {sectionData.subtitleTwo || <None />}
            </ValueContainer>
            <Heading>
              {intl.formatMessage({
                id: 'admin.competitionContent.heading.descriptionNotEntered',
              })}
            </Heading>
            <ValueContainer>
              {sectionData.description || <None />}
            </ValueContainer>
            <Heading>
              {intl.formatMessage({
                id: 'admin.competitionContent.heading.descriptionEntered',
              })}
            </Heading>
            <ValueContainer>
              {sectionData.postDescription || <None />}
            </ValueContainer>
            <Heading>
              {intl.formatMessage({
                id: 'admin.competitionContent.heading.descriptionWinner',
              })}
            </Heading>
            <ValueContainer>
              {sectionData.winnerCopy || <None />}
            </ValueContainer>
            <Heading>
              {intl.formatMessage({
                id: 'admin.competitionContent.heading.descriptionExpired',
              })}
            </Heading>
            <ValueContainer>
              {sectionData.expiredCopy || <None />}
            </ValueContainer>
          </Col>
          <Col xs={12} sm={12} md={1} lg={1} xl={1} key={3}>
            <Heading>
              {intl.formatMessage({
                id: 'admin.competitionContent.heading.featuredTitle',
              })}
            </Heading>
            <ValueContainer>
              {sectionData.moreInfoTitle || <None />}
            </ValueContainer>
            <Heading>
              {intl.formatMessage({
                id: 'admin.competitionContent.heading.featuredSubheading',
              })}
            </Heading>
            <ValueContainer>
              {sectionData.moreInfoSubheading || <None />}
            </ValueContainer>
            <Heading>
              {intl.formatMessage({
                id: 'admin.competitionContent.heading.preview',
              })}
            </Heading>
            <ValueContainer>
              {sectionData.moreInfoPreview || <None />}
            </ValueContainer>
            <Heading>
              {intl.formatMessage({
                id: 'admin.competitionContent.heading.featuredContentCtaText',
              })}
            </Heading>
            <ValueContainer>
              {sectionData.featuredContentCtaText || <None />}
            </ValueContainer>
            <Heading>
              {intl.formatMessage({
                id: 'admin.competitionContent.heading.featuredContentCtaLink',
              })}
            </Heading>
            <ValueContainer>
              {sectionData.featuredContentCtaLink ? (
                <GenericLink url={sectionData.featuredContentCtaLink}>
                  <TruncatedByCharText
                    text={sectionData.featuredContentCtaLink}
                    truncateLength={30}
                  />
                </GenericLink>
              ) : (
                <None />
              )}
            </ValueContainer>
            <IconAndTextLink
              icon={Camera1}
              text={intl.formatMessage({
                id: 'admin.competitionContent.heading.manageMoreInfoImage',
              })}
              onClick={() => callbacks.toggleEditMoreInfoImageModal()}
            />
          </Col>
          <Col xs={12} sm={12} md={1} lg={1} xl={1} key={4}></Col>
        </Grid>
      </MainContainer>
    </>
  );
};

export default SectionContent;
