export const columnsConfig = [
  {
    headerText: 'Model',
    columnWidth: 150,
  },
  {
    headerText: 'ID',
    columnWidth: 100,
  },
  {
    headerText: 'Change Type',
    columnWidth: 100,
  },
  {
    headerText: 'Change Summary',
    columnWidth: 450,
    whiteSpace: 'unset',
  },
  {
    headerText: 'Whodunnit',
    columnWidth: 150,
  },
  {
    headerText: 'Date',
    columnWidth: 225,
  },
];

export const pageStateConfig = {
  filterNames: ['item_type', 'event', 'date'],
  defaultSort: { by: 'date', direction: 'desc' },
  textSearchParamName: 'version_search',
};
