import React from 'react';
import styled from 'styled-components';

import { GeneralEvent } from 'app/typings/index';
import { isEmpty } from 'app/shared/utils/object';
import {
  DetailHeading as Heading,
  DetailValueContainer as ValueContainer,
} from 'app/admin/components/atoms/DetailContent';
import LoadMoreButton from 'app/admin/components/atoms/LoadMoreButton';
import { None } from 'app/admin/components/atoms/None';
import EventSummary from 'app/admin/components/organisms/EventSummary';

interface DetailsEventListProps {
  title: string;
  events: GeneralEvent[];
  totalNumEvents: number;
  onClickSeeAll: VoidFunction;
  dataQaidPrefix: string;
  artistName?: string;
  artistId?: number;
}

const ListContainer = styled(ValueContainer)`
  width: 75%;
`;

const EventContainer = styled.div`
  margin-bottom: 10px;
`;

const renderEvents = (
  events: GeneralEvent[],
  artistName?: string,
  artistId?: number
) => {
  if (events && !isEmpty(events)) {
    return events.map((event: GeneralEvent) => (
      <EventContainer key={`${event.id}-${event.city.title || ''}`}>
        <EventSummary
          event={event}
          artistName={artistName}
          artistId={artistId}
        />
      </EventContainer>
    ));
  } else {
    return <None />;
  }
};

const maxNumEventsToDisplay = 3;

const DetailsEventList: React.FC<DetailsEventListProps> = ({
  title,
  events,
  totalNumEvents,
  onClickSeeAll,
  dataQaidPrefix,
  artistName,
  artistId,
}) => {
  return (
    <>
      <Heading>{title}</Heading>
      <ListContainer data-qaid={`${dataQaidPrefix}-container`}>
        {renderEvents(events, artistName, artistId)}
        {totalNumEvents > maxNumEventsToDisplay && (
          <LoadMoreButton
            data-qaid={`all-${dataQaidPrefix}-button`}
            onClick={() => onClickSeeAll()}
          >
            ALL
          </LoadMoreButton>
        )}
      </ListContainer>
    </>
  );
};

export default DetailsEventList;
