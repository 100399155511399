import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { ContentWrapper } from 'app/shared/components/atoms/FlyoverContent';
import FlyoverFooter from 'app/admin/components/molecules/FlyoverFooter';

interface SwapShiftWithAvailableCrewConfirmationProps {
  setIsSubmitting: Function;
  handleUpdateEventStaffMember: Function;
}

const ConfirmationMessage = styled.div`
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin: 25px 0px 12px 0px;
`;

const SwapShiftWithAvailableCrewConfirmation: React.FC<SwapShiftWithAvailableCrewConfirmationProps> = ({
  setIsSubmitting,
  handleUpdateEventStaffMember,
}) => {
  const intl = useIntl();

  return (
    <div data-qaid="swap-role-confirmation-flyover">
      <ContentWrapper>
        <ConfirmationMessage>
          {intl.formatMessage({
            id: 'admin.crewPortal.crewRole.swapRoleConfirmationMsg',
          })}
        </ConfirmationMessage>
      </ContentWrapper>
      <FlyoverFooter
        buttonText={intl.formatMessage({
          id: 'shared.confirm',
        })}
        onClickButton={() => {
          setIsSubmitting(true);
          handleUpdateEventStaffMember();
        }}
        dataQaid="swap-role-submit-button"
      />
    </div>
  );
};

export default SwapShiftWithAvailableCrewConfirmation;
