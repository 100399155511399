import React from 'react';
import styled, { css } from 'styled-components';

import { WithFlexProps } from 'app/shared/components/styleUtils/manualCSS';
import { Container } from 'app/shared/components/atoms/Container';
import ThemeWrapper from 'app/shared/components/atoms/ThemeWrapper';

const MainContainer = styled(Container)<{
  containerMaxWidth?: number;
  useResponsiveHeight?: boolean;
}>`
  ${({ theme, containerMaxWidth = 1260, useResponsiveHeight }) => css`
    width: 100%;

    ${!useResponsiveHeight &&
      css`
        height: ${theme.ruler[21]}px;
      `}

    ${useResponsiveHeight &&
      css`
        ${theme.media.xs`
          height: ${theme.ruler[14]}px;
        `}
        ${theme.media.md`
          height: ${theme.ruler[16]};
        `}
        ${theme.media.lg`
          height: ${theme.ruler[21]}px;
        `}
      `}

    ${containerMaxWidth &&
      css`
        ${theme.media.lg`
          max-width: ${containerMaxWidth}px;
        `}
      `}
  `}
`;

export const NavbarDefaultMainContainer: React.FC<WithFlexProps & {
  containerMaxWidth?: number;
  useResponsiveHeight?: boolean;
}> = ({ children, ...props }) => {
  return (
    <ThemeWrapper themeName="manualCSS">
      <MainContainer {...props}>{children}</MainContainer>
    </ThemeWrapper>
  );
};
