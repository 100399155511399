import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { Artist } from 'app/typings/artists';
import { City } from 'app/typings/cities';
import { Event } from 'app/typings/events';
import { Performance } from 'app/typings/performances';
import { dateFormatter } from 'app/shared/utils/datetime';
import { errorMsgForField } from 'app/shared/utils/form';
import { get } from 'app/shared/utils/get';
import GenericFormContainer from 'app/shared/components/atoms/GenericFormContainer';
import { LoadingError } from 'app/shared/components/atoms/LoadingError';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { StreamlineIcon } from 'app/shared/components/atoms/StreamlineIcon';
import TruncatedByCharText from 'app/shared/components/atoms/TruncatedByCharText';
import { H2 } from 'app/shared/components/atoms/TypographyManualCSS';
import FormGroup from 'app/shared/components/molecules/FormGroup';
import { GetEventsForBookingRequestAccept } from 'app/admin/graphql/events/queryHooks';
import EditFormLoadingBlocks from 'app/admin/components/atoms/EditFormLoadingBlocks';
import ListingFooter from 'app/admin/components/molecules/ListingFooter';
import { ReactComponent as AddSquare } from 'icons/streamline-regular/interface-essential/remove-add/add-square.svg';
import { ReactComponent as SubtractSquare } from 'icons/streamline-regular/interface-essential/remove-add/subtract-square.svg';

import {
  HeaderRowCell,
  HeaderRowContainer,
  ListContainer,
  RowCell,
  RowContainer,
} from './SimpleListingTable';

interface Props {
  formikProps: any;
  setFormSubmitAction?: (func: Function) => void;
  setDisplayConfirmation?: (set: boolean) => void;
  setIsSubmitting?: (set: boolean) => void;
  city?: City;
  artist?: Artist;
  availabilityStart?: string;
  availabilityEnd?: string;
}

const MainContainer = styled.div`
  padding: 0px 0px 0px 0px;
  margin-top: -40px;
  display: flex;
  flex-direction: column;
  align-items: left;
`;

const Title = styled(H2)`
  ${({ theme }) => css`
    font-size: 16px;
    font-weight: normal;
    line-height: 1.2em;
    margin-bottom: 3px;

    ${theme.media.xl`
    font-size: 34px;
    margin-bottom: 15px;
    margin-left: 0px;
  `};
  `}
`;

const ArtistName = styled.div`
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.4px;
  margin-bottom: 15px;
`;

const EventList = styled.div`
  margin-bottom: 30px;
`;

const NoEvents = styled.div`
  margin-top: 35px;
`;

const ArtistOnEvent = styled.div`
  display: flex;
  flex-direction: row;
`;

const MainArtistName = styled.div`
  ${({ theme }) => css`
    font-weight: 600;
    color: ${theme.colors.blueChristmas};
    padding-right: 5px;
  `}
`;

const IconWrapper = styled.div`
  cursor: pointer;
`;

const AddIcon: React.FC = () => {
  return <StreamlineIcon icon={AddSquare} size={16} />;
};

const RemoveIcon: React.FC = () => {
  return <StreamlineIcon icon={SubtractSquare} size={12} />;
};

const ListingFooterContainer = styled.div`
  width: 940px;
  margin-top: 25px;
`;

const renderHeaders = (intl: any) => {
  return (
    <HeaderRowContainer width="960px">
      <HeaderRowCell width="150px">
        {intl.formatMessage({
          id: 'shared.date',
        })}
      </HeaderRowCell>
      <HeaderRowCell width="300px">
        {intl.formatMessage({
          id: 'shared.venue',
        })}
      </HeaderRowCell>
      <HeaderRowCell width="170px">
        {intl.formatMessage({
          id: 'shared.artists',
        })}
      </HeaderRowCell>
      <HeaderRowCell width="170px"></HeaderRowCell>
      <HeaderRowCell width="170px"></HeaderRowCell>
    </HeaderRowContainer>
  );
};

const dateFormatterWithWeekday = (date: Date | string) =>
  dateFormatter(date, 'shortWeekdayShortMonthDateAndYear');

const buildFullVenueName = (event: Event) =>
  event.venue
    ? ((event.venue.venueName || '') + ' ' + (event.venue.address || '')).trim()
    : '';

const getArtistForPosition = (position: number, event: Event) => {
  const performance =
    event &&
    event.performances &&
    event.performances.find((p: Performance) => p.position == position);
  if (performance) {
    return performance.artist;
  }
  return null;
};

const renderEvents = (
  events: Event[],
  performanceRequests: any[],
  onAddToEvent: Function,
  onRemoveFromEvent: Function,
  mainArtist: Artist | undefined,
  intl: any
) => {
  return events.map((event: Event, i: number) => {
    const venueName = buildFullVenueName(event);
    return (
      <RowContainer width="960px" shaded={i % 2 == 0} key={i}>
        <RowCell width="150px">
          {event.localStartsAt
            ? dateFormatterWithWeekday(event.localStartsAt)
            : intl.formatMessage({
                id: 'admin.bookingRequests.bookingRequestAccept.noDate',
              })}
        </RowCell>
        <RowCell width="300px">
          <TruncatedByCharText text={venueName} truncateLength={35} />
        </RowCell>
        {[1, 2, 3].map((position: number) => {
          const artist = getArtistForPosition(position, event);
          return (
            <RowCell width="170px" key={position}>
              {artist ? (
                <TruncatedByCharText text={artist.title} truncateLength={20} />
              ) : performanceRequests.some(
                  (pr: any) => pr.eventId == event.id && pr.position == position
                ) ? (
                <ArtistOnEvent>
                  <MainArtistName>
                    {mainArtist && (
                      <TruncatedByCharText
                        text={mainArtist.title}
                        truncateLength={16}
                      />
                    )}
                  </MainArtistName>
                  <IconWrapper onClick={() => onRemoveFromEvent(event.id)}>
                    <RemoveIcon />
                  </IconWrapper>
                </ArtistOnEvent>
              ) : (
                <IconWrapper onClick={() => onAddToEvent(event.id, position)}>
                  <AddIcon />
                </IconWrapper>
              )}
            </RowCell>
          );
        })}
      </RowContainer>
    );
  });
};

const BookingRequestAcceptForm: React.FC<Props> = ({
  formikProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  city,
  artist,
  availabilityStart,
  availabilityEnd,
}) => {
  const intl = useIntl();

  useEffect(() => {
    setFormSubmitAction && setFormSubmitAction(() => formikProps.submitForm);
    setDisplayConfirmation && setDisplayConfirmation(formikProps.dirty);
    setIsSubmitting && setIsSubmitting(formikProps.isSubmitting);
  }, [
    formikProps.isSubmitting,
    formikProps.submitForm,
    formikProps.dirty,
    setFormSubmitAction,
    setDisplayConfirmation,
    setIsSubmitting,
  ]);

  const PER_PAGE = 10;
  const [page, setPage] = useState<number>(1);

  const {
    loading: loadingEvents,
    error: errorEvents,
    data: dataEvents,
  } = GetEventsForBookingRequestAccept({
    upcoming: true,
    city: city && city.cachedSlug,
    page,
    perPage: PER_PAGE,
  });

  const { setFieldValue, touched, errors, values } = formikProps;

  const onAddToEvent = (eventId: number, position: number) => {
    const performanceRequests = values.performanceRequests.filter(
      (pr: any) => pr.eventId != eventId
    );
    performanceRequests.push({
      eventId,
      position,
    });
    setFieldValue('performanceRequests', performanceRequests);
  };

  const onRemoveFromEvent = (eventId: number) => {
    setFieldValue(
      'performanceRequests',
      values.performanceRequests.filter((pr: any) => pr.eventId != eventId)
    );
  };

  if (errorEvents) {
    return <LoadingError whatsBeingLoaded="this form" />;
  }

  if (loadingEvents || !dataEvents) {
    return <EditFormLoadingBlocks />;
  }

  const datesDescription =
    availabilityStart && availabilityEnd
      ? dateFormatterWithWeekday(availabilityStart) +
        ' - ' +
        dateFormatterWithWeekday(availabilityEnd)
      : intl.formatMessage({
          id: 'admin.bookingRequests.bookingRequestAccept.unknownDates',
        });

  return (
    <GenericFormContainer dataQaId="booking-request-accept-form">
      <MainContainer>
        <Title>
          {intl.formatMessage({
            id: 'admin.bookingRequests.bookingRequestAccept.addArtistToEvent',
          })}
        </Title>
        <ArtistName>
          {intl.formatMessage(
            {
              id:
                'admin.bookingRequests.bookingRequestAccept.artistWantsToPlay',
            },
            {
              artistName: artist && artist.title,
              dates: datesDescription,
              cityTitle: city && city.title,
            }
          )}
        </ArtistName>
        <FormGroup
          data-qaid="booking-request-accept-form-errors-formgroup"
          label=""
          errorMsg={errorMsgForField('performanceRequests', touched, errors)}
        >
          <></>
        </FormGroup>
        <Spacer mb={3} />
        <EventList>
          <ListContainer>
            {get(dataEvents, 'events.events', []) &&
            get(dataEvents, 'events.events', []).length > 0 ? (
              <>
                {renderHeaders(intl)}
                {renderEvents(
                  get(dataEvents, 'events.events', []),
                  values.performanceRequests,
                  onAddToEvent,
                  onRemoveFromEvent,
                  artist,
                  intl
                )}
                <ListingFooterContainer>
                  <ListingFooter
                    numTotalRecords={get(
                      dataEvents,
                      'events.metadata.totalRecords',
                      undefined
                    )}
                    perPage={PER_PAGE}
                    currentPage={page}
                    onPageChange={setPage}
                    loading={loadingEvents}
                    dataQaidPrefix="booking-request-accept"
                  />
                </ListingFooterContainer>
              </>
            ) : (
              <NoEvents>
                {intl.formatMessage(
                  {
                    id: 'admin.bookingRequests.bookingRequestAccept.noEvents',
                  },
                  {
                    cityTitle: city && city.title,
                  }
                )}
              </NoEvents>
            )}
          </ListContainer>
        </EventList>
      </MainContainer>
    </GenericFormContainer>
  );
};

export default BookingRequestAcceptForm;
