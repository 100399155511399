import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { ArtistEligibility } from 'app/typings/artists';
import { BookingPreference } from 'app/typings/bookingPreferences';
import { Tag } from 'app/typings/tags';
import { dateFormatter } from 'app/shared/utils/datetime';
import usePermission from 'app/shared/utils/usePermission';
import { getBookingPreferenceCities } from 'app/admin/utils/artists';
import { InfoText } from 'app/admin/components/molecules/KeyValueInfoList';
import { TagElement } from 'app/admin/components/molecules/TagElementList';
import { SIGNED_STATUSES } from 'app/admin/components/organisms/ArtistDetails/SectionBasicInfo';
import ArtistHistoryFlyover from 'app/admin/components/organisms/ArtistHistoryFlyover';
import ListingCard from 'app/admin/components/organisms/ListingCard';
import { ReactComponent as EmailActionUnread } from 'icons/streamline-regular/emails/email-actions/email-action-unread.svg';
import { ReactComponent as Calendar3 } from 'icons/streamline-regular/interface-essential/date-calendar/calendar-3.svg';
import { ReactComponent as MusicNote2 } from 'icons/streamline-regular/music-audio/music/music-note-2.svg';

interface ArtistCardProps {
  id: number;
  title: string;
  imageUrl: string;
  largeImageUrl?: string;
  homeCityDescription?: string;
  mainArtistLink?: string;
  email?: string;
  index: number;
  pastEventsCount?: number;
  upcomingEventsCount?: number;
  lastEventAt?: string;
  citiesPlayedCount?: number;
  tags?: Tag[];
  artistEligibilities?: ArtistEligibility[];
  bookingPreferences?: BookingPreference[];
  genres?: string[];
  onShowDetails: Function;
  signedStatus?: string;
  needsReview?: boolean;
}

const StyledTagElement = styled(TagElement)`
  letter-spacing: 0.5px;
  font-size: 12px;
  text-transform: none;
  padding: 0 2px 1px 2px;
  margin: 0;
`;

const ArtistCard: React.FC<ArtistCardProps> = ({
  id,
  title,
  imageUrl,
  largeImageUrl,
  homeCityDescription,
  mainArtistLink,
  email,
  index,
  pastEventsCount = 0,
  upcomingEventsCount = 0,
  lastEventAt,
  citiesPlayedCount = 0,
  tags,
  bookingPreferences,
  genres,
  onShowDetails,
  signedStatus = 'unknown',
  needsReview = false,
}) => {
  const intl = useIntl();

  const hasAccessArtistsPermission = usePermission('artist.list.access');
  const hasAccessEventsPermission = usePermission('event.list.access');

  const {
    availableLastMinuteCities,
    generalAvailabilityCities,
    specificAvailabilityCities,
  } = getBookingPreferenceCities(bookingPreferences);

  const listingCardHeight = () => {
    return '900px';
  };

  const tagElementsInfoList = [
    {
      tagElements: tags,
      listTitle: intl.formatMessage({
        id: 'shared.tags',
      }),
      tagElementColor: '#34495E',
      hasPermission: hasAccessArtistsPermission,
    },
    {
      tagElements: genres,
      listTitle: intl.formatMessage({
        id: 'admin.artistDirectory.listingCard.genres',
      }),
      tagElementColor: '#9B525F',
      hasPermission: hasAccessArtistsPermission,
    },
  ];

  tagElementsInfoList.push(
    {
      //@ts-ignore
      tagElements: generalAvailabilityCities,
      listTitle: intl.formatMessage({
        id: 'admin.shared.generalAvailability',
      }),
      tagElementColor: '#3F51B5',
      hasPermission: hasAccessArtistsPermission,
      containerHeight: '130px',
    },
    {
      tagElements: specificAvailabilityCities,
      listTitle: intl.formatMessage({
        id: 'admin.shared.specificAvailability',
      }),
      tagElementColor: '#039842',
      hasPermission: hasAccessArtistsPermission,
      containerHeight: '130px',
    },
    {
      tagElements: availableLastMinuteCities,
      listTitle: intl.formatMessage({
        id: 'admin.shared.lastMinute',
      }),
      tagElementColor: '#9B59B6',
      hasPermission: hasAccessArtistsPermission,
      containerHeight: '130px',
    }
  );

  const keysAndValuesList = [
    {
      key: intl.formatMessage({
        id: 'admin.artistDirectory.listingCard.showsPlayed',
      }),
      value: pastEventsCount.toString(),
    },
    {
      key: intl.formatMessage({
        id: 'admin.artistDirectory.listingCard.upcomingShows',
      }),
      value: upcomingEventsCount.toString(),
    },
    {
      key: intl.formatMessage({
        id: 'admin.artistDirectory.listingCard.lastShow',
      }),
      value: lastEventAt
        ? dateFormatter(lastEventAt, 'shortMonthAndLongYear')
        : intl.formatMessage({
            id: 'none',
          }),
    },
    {
      key: intl.formatMessage({
        id: 'admin.artistDirectory.listingCard.mgmt',
      }),
      value: SIGNED_STATUSES[signedStatus],
    },
    ...(hasAccessEventsPermission
      ? [
          {
            key: intl.formatMessage({
              id: 'shared.feedback',
            }),
            value: needsReview.toString(),
            renderValue: (value: string) => {
              return value === 'true' ? (
                <StyledTagElement tagElementColor="#ec5d2a" textColor="#ffffff">
                  {intl.formatMessage({
                    id: 'admin.shared.review',
                  })}
                </StyledTagElement>
              ) : (
                <InfoText>{intl.formatMessage({ id: 'none' })}</InfoText>
              );
            },
          },
        ]
      : []),
  ];

  const controlsInfoList = [
    {
      href: email ? `mailto: ${email || ''}` : '',
      icon: EmailActionUnread,
      tipText: intl.formatMessage({
        id: 'admin.artistDirectory.listingCard.emailTheArtist',
      }),
    },
    {
      href: mainArtistLink || '',
      icon: MusicNote2,
      tipText: intl.formatMessage({
        id: 'admin.artistDirectory.listingCard.listenToArtist',
      }),
    },
  ];

  const ArtistHistoryFlyoverContent = () => (
    <ArtistHistoryFlyover id={id} title={title} />
  );

  const topFlyoverInfo = {
    icon: Calendar3,
    iconEnabled: true,
    tipText: intl.formatMessage({
      id: 'admin.artistDirectory.artistHistory.artistHistoryToolTip',
    }),
    infoBoxContent: ArtistHistoryFlyoverContent,
  };

  const onShowDetailsParams = {
    id,
    title,
    imageUrl,
    largeImageUrl,
    upcomingEventsCount,
    pastEventsCount,
    citiesPlayedCount,
    lastEventAt,
    index,
  };

  return (
    <ListingCard
      topFlyoverInfo={topFlyoverInfo}
      imageStyle="circle"
      imageUrl={imageUrl}
      largeImageUrl={largeImageUrl}
      title={title}
      subtitle={homeCityDescription}
      tagElementsInfoList={tagElementsInfoList}
      keysAndValuesList={keysAndValuesList}
      controlsInfoList={controlsInfoList}
      onShowDetails={onShowDetails}
      onShowDetailsParams={onShowDetailsParams}
      showDetailsTipText={intl.formatMessage({
        id: 'admin.artistDirectory.listingCard.artistDetails',
      })}
      dataQaidPrefix="artist"
      index={index}
      height={listingCardHeight()}
    />
  );
};

export default ArtistCard;
