import React from 'react';
import styled from 'styled-components';

import { get } from 'app/shared/utils/get';
import { Checkbox } from 'app/shared/components/atoms/CheckboxManualCSS';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import { FormHelpText } from 'app/shared/components/atoms/FormContent';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Textfield } from 'app/shared/components/atoms/Textfield';
import { H5 } from 'app/shared/components/atoms/TypographyManualCSS';
import FormGroup from 'app/shared/components/molecules/FormGroup';
import Select from 'app/shared/components/molecules/SelectManualCSS';

interface Props {
  name: string;
  title: string;
  helpText?: string;
  values: any;
  setFieldValue: any;
  errorMsgForField: any;
  touched: any;
  errors: any;
  contactRelationships: any;
  last?: boolean;
  dataQaidPrefix: string;
  formId?: string;
}

const FormSectionSubTitle = styled(H5)`
  text-align: left;
  font-weight: 600;
  margin-bottom: 15px;
`;

const ContactInfoFieldGroup: React.FC<Props> = ({
  name,
  title,
  helpText,
  values,
  setFieldValue,
  errorMsgForField,
  touched,
  errors,
  contactRelationships,
  last,
  dataQaidPrefix,
  formId,
}) => {
  const formIdentifier = formId || 'about-section-edit';

  return (
    <>
      <FormSectionSubTitle>{title}</FormSectionSubTitle>
      {helpText ? <FormHelpText>{helpText}</FormHelpText> : <Spacer mb={8} />}
      <Grid gap="12px">
        <Col xs={12} sm={4}>
          <FormGroup
            data-qaid={`${dataQaidPrefix}-${formIdentifier}-${name}-contact-first-name-formgroup`}
            label="First Name"
          >
            <Textfield
              data-qaid={`${dataQaidPrefix}-${formIdentifier}-${name}-contact-first-name-field`}
              id={`${name}-contact-first-name`}
              name={`${name}-contact-first-name`}
              placeholder="First Name"
              value={get(values, 'firstName', '')}
              onChange={(e: any) => {
                setFieldValue(
                  `contactInfos.${name}ContactInfo.firstName`,
                  e.target.value
                );
              }}
              maxLength={30}
            />
          </FormGroup>
        </Col>
        <Col xs={12} sm={4}>
          <FormGroup
            data-qaid={`${dataQaidPrefix}-${formIdentifier}-${name}-contact-last-name-formgroup`}
            label="Last Name"
          >
            <Textfield
              data-qaid={`${dataQaidPrefix}-${formIdentifier}-${name}-contact-last-name-field`}
              id={`${name}-contact-last-name`}
              name={`${name}-contact-last-name`}
              placeholder="Last Name"
              value={get(values, 'lastName', '')}
              onChange={(e: any) => {
                setFieldValue(
                  `contactInfos.${name}ContactInfo.lastName`,
                  e.target.value
                );
              }}
              maxLength={35}
            />
          </FormGroup>
        </Col>
        <Col xs={12} sm={4}>
          <FormGroup
            data-qaid={`${dataQaidPrefix}-${formIdentifier}-${name}-contact-relationship-formgroup`}
            label="Relationship / Role"
          >
            <Select
              data-qaid={`${dataQaidPrefix}-${formIdentifier}-${name}-contact-relationship-field`}
              name={`${name}-contact-relationship`}
              id={`${name}-contact-relationship`}
              onChange={option => {
                setFieldValue(
                  `contactInfos.${name}ContactInfo.contactRelationship`,
                  option
                );
              }}
              options={contactRelationships.map((e: any) => ({
                id: e.id,
                name: e.name,
              }))}
              getOptionLabel={(opt: any) => {
                return opt.name;
              }}
              placeholder="Relationship / Role"
              defaultValue={get(values, 'contactRelationship', '')}
            />
          </FormGroup>
        </Col>
        <Col xs={12} sm={4}>
          <FormGroup
            data-qaid={`${dataQaidPrefix}-${formIdentifier}-${name}-contact-email-formgroup`}
            label="Email"
            required={
              name == 'primary' || !!values.firstName || !!values.lastName
            }
            errorMsg={errorMsgForField(
              `contactInfos.${name}ContactInfo.email`,
              touched,
              errors
            )}
          >
            <Textfield
              data-qaid={`${dataQaidPrefix}-${formIdentifier}-${name}-contact-email-field`}
              id={`${name}-contact-email`}
              name="email"
              placeholder="Email"
              value={get(values, 'email', '')}
              onChange={(e: any) => {
                setFieldValue(
                  `contactInfos.${name}ContactInfo.email`,
                  e.target.value
                );
              }}
            />
          </FormGroup>
        </Col>
        <Col xs={12} sm={4}>
          <FormGroup
            data-qaid={`${dataQaidPrefix}-${formIdentifier}-${name}-contact-phone-number-formgroup`}
            label="Phone Number"
            errorMsg={errorMsgForField(
              `contactInfos.${name}ContactInfo.phoneNumber`,
              touched,
              errors
            )}
            required={get(values, 'isDayOfShowContact', '')}
          >
            <Textfield
              data-qaid={`${dataQaidPrefix}-${formIdentifier}-${name}-contact-phone-number-field`}
              id={`${name}-contact-phone-number`}
              name={`${name}-contact-phone-number`}
              placeholder="Phone Number"
              value={get(values, 'phoneNumber', '')}
              onChange={(e: any) => {
                setFieldValue(
                  `contactInfos.${name}ContactInfo.phoneNumber`,
                  e.target.value
                );
              }}
              maxLength={20}
            />
          </FormGroup>
        </Col>
      </Grid>
      <Spacer mb={6} />
      <Checkbox
        data-qaid={`${dataQaidPrefix}-${formIdentifier}-${name}-contact-dos-field`}
        id={`${name}-contact-dos`}
        name={`${name}-contact-dos`}
        checked={get(values, 'isDayOfShowContact', false)}
        onChange={e =>
          setFieldValue(
            `contactInfos.${name}ContactInfo.isDayOfShowContact`,
            e.target.checked
          )
        }
      >
        <b>Day of Show Contact</b>
      </Checkbox>
      {!last && (
        <>
          <Spacer mb={8} />
          <DottedLine />
          <Spacer mb={8} />
        </>
      )}
    </>
  );
};

export default ContactInfoFieldGroup;
