import React from 'react';
import styled from 'styled-components';

const CuratorGroupTitlesContainer = styled.div`
  margin-top: 0x;
  margin-bottom: 10x;
  font-size: 24px;
  font-weight: 600;
`;

interface CuratorGroupNamesProps {
  curatorGroupNames: string[] | undefined;
}

export const CuratorGroupNames: React.FC<CuratorGroupNamesProps> = ({
  curatorGroupNames,
}) => {
  return curatorGroupNames && curatorGroupNames.length > 0 ? (
    <CuratorGroupTitlesContainer>
      {curatorGroupNames.sort().join(' and ')}
    </CuratorGroupTitlesContainer>
  ) : null;
};
