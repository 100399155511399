import * as Yup from 'yup';

import { UrgentEmailKeys } from './options';

// Not just html but ANY open/closed brackets or quotes, since they break the email parser in the backend
const containsHtml = (value: string) =>
  value.includes('<') ||
  value.includes('>') ||
  value.includes('"') ||
  value.includes("'");

const EventSendUrgentEmailFormSchema = (intl: any) =>
  Yup.object().shape({
    urgentEmailKey: Yup.string().required(
      intl.formatMessage({
        id: 'form.requiredField',
      })
    ),
    customEmailSubject: Yup.string()
      .nullable()
      .when('urgentEmailKey', {
        is: UrgentEmailKeys.GUEST_CUSTOM,
        then: Yup.string()
          .required(
            intl.formatMessage({
              id: 'form.requiredField',
            })
          )
          .test(
            'defined',
            intl.formatMessage(
              {
                id: 'form.fieldCanNotBeEmpty',
              },
              {
                field: intl.formatMessage({
                  id: 'admin.shared.emails.subject',
                }),
              }
            ),
            value => value && !!value.trim()
          )
          .test(
            'containsNoHtml',
            intl.formatMessage({
              id: 'admin.eventPlanner.emails.errorHtmlInSubject',
            }),
            (value: any) => value && !containsHtml(value)
          ),
      }),
    recipientType: Yup.string()
      .nullable()
      .when('urgentEmailKey', {
        is: UrgentEmailKeys.GUEST_EVENT_CANCELLED,
        then: Yup.string().required(
          intl.formatMessage({
            id: 'form.requiredField',
          })
        ),
      })
      .when('urgentEmailKey', {
        is: UrgentEmailKeys.GUEST_CUSTOM,
        then: Yup.string().required(
          intl.formatMessage({
            id: 'form.requiredField',
          })
        ),
      }),
    customEmailMessage: Yup.string()
      .nullable()
      .when('urgentEmailKey', {
        is: UrgentEmailKeys.GUEST_CUSTOM,
        then: Yup.string().required(
          intl.formatMessage({
            id: 'form.requiredField',
          })
        ),
      }),
    newVenueAddress: Yup.string()
      .nullable()
      .when('urgentEmailKey', {
        is: UrgentEmailKeys.GUEST_VENUE_CHANGED,
        then: Yup.string()
          .required(
            intl.formatMessage({
              id: 'form.requiredField',
            })
          )
          .test(
            'defined',
            intl.formatMessage(
              {
                id: 'form.fieldCanNotBeEmpty',
              },
              {
                field: intl.formatMessage({
                  id: 'admin.eventPlanner.emails.newAddress',
                }),
              }
            ),
            value => value && !!value.trim()
          )
          .test(
            'containsNoHtml',
            intl.formatMessage({
              id: 'admin.eventPlanner.emails.errorHtmlInNewAddress',
            }),
            (value: any) => value && !containsHtml(value)
          ),
      }),
  });

export default EventSendUrgentEmailFormSchema;
