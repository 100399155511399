import React from 'react';
import { useIntl } from 'react-intl';

import { ContactInfo } from 'app/typings/contactInfos';
import { Event } from 'app/typings/events';
import { Property } from 'app/typings/properties';
import { Tag } from 'app/typings/tags';
import { useCurrentTheme } from 'app/shared/theme';
import usePermission from 'app/shared/utils/usePermission';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { StreamlineIcon } from 'app/shared/components/atoms/StreamlineIcon';
import IconAndTextLink from 'app/shared/components/molecules/IconAndTextLink';
import {
  CircledOverline,
  InlineHtml,
  SectionText,
  SectionTitle,
  SectionWithIcon,
  Subheading,
} from 'app/admin/components/atoms/CrewEventContent';
import { renderProperties } from 'app/admin/components/molecules/PropertyList';
import SocialIconList from 'app/admin/components/molecules/SocialIconList';
import CrewEventContactInfo from 'app/admin/components/organisms/CrewEventContactInfo';
import { ReactComponent as InfoCircle } from 'icons/streamline-regular/interface-essential/alerts/information-circle.svg';
import { ReactComponent as PinLocation1 } from 'icons/streamline-regular/maps-navigation/pins/pin-location-1.svg';

interface EventInfoProps {
  event: Event;
}

const filterParkingCharacteristics = (event: Event) => {
  const PARKING_CHARACTERISTICS = [
    'free_public_parking_nearby',
    'paid_parking',
    'no_parking',
  ];
  return event.properties?.filter((property: Property) =>
    PARKING_CHARACTERISTICS.includes(property.key)
  );
};

const CrewEventVenueInfo: React.FC<EventInfoProps> = ({ event }) => {
  const intl = useIntl();
  const theme = useCurrentTheme();
  const parkingCharacteristics = filterParkingCharacteristics(event);
  const hasVenueViewPermission = usePermission('venue.list.view');
  const dayOfShowOrPrimaryContactInfo =
    event.venue?.contactInfos?.find(
      (contactInfo: ContactInfo) => contactInfo.isDayOfShowContact
    ) ||
    event.venue?.contactInfos?.find(
      (contactInfo: ContactInfo) => contactInfo.position === 1
    );

  const alternativeContactInfos = (event.venue?.contactInfos || []).filter(
    (contactInfo: ContactInfo) =>
      contactInfo.id !== dayOfShowOrPrimaryContactInfo?.id
  );
  alternativeContactInfos.sort((a: ContactInfo) =>
    a.isDayOfShowContact ? 1 : -1
  );

  return (
    <SectionWithIcon>
      <SectionTitle>
        <StreamlineIcon
          icon={InfoCircle}
          size={40}
          stroke={theme.colors.purpleHaze}
          background={theme.colors.whiteDenim}
          borderRadius={20}
        />
        {intl.formatMessage({ id: 'admin.crewEvent.section.venueInfo' })}
      </SectionTitle>
      {event.venue?.pastEventsCount === 0 && (
        <>
          <Spacer mt={4} />
          <SectionText>
            <CircledOverline inverted={true}>
              {intl.formatMessage({
                id: 'admin.crewEvent.section.venueInfo.firstConcertForVenue',
              })}
            </CircledOverline>
          </SectionText>
        </>
      )}
      <SectionText>
        <Spacer mt={4} />
        <>
          <Subheading>{intl.formatMessage({ id: 'shared.venue' })}:</Subheading>
          &nbsp;
          {event.venue?.venueName || intl.formatMessage({ id: 'none' })}
        </>
        <Spacer mt={2} />
        <>
          {event.venue?.address && (
            <IconAndTextLink
              icon={PinLocation1}
              text={event.venue?.address}
              url={`https://www.google.com/maps/place/${event?.venue?.address}`}
              dataQaid="crew-event-view-venue-address"
              buttonStyle="unfilled"
            />
          )}
        </>
        {hasVenueViewPermission && dayOfShowOrPrimaryContactInfo && (
          <>
            <Spacer mt={4} />
            <CrewEventContactInfo
              label={intl.formatMessage({
                id: 'admin.shared.dayOfShowContact',
              })}
              contactInfo={dayOfShowOrPrimaryContactInfo}
            />
          </>
        )}
        {hasVenueViewPermission && !!alternativeContactInfos && (
          <>
            <Spacer mt={4} />
            {alternativeContactInfos.map(
              (contactInfo: ContactInfo, index: number) => (
                <CrewEventContactInfo
                  label={intl.formatMessage({
                    id: contactInfo.isDayOfShowContact
                      ? 'admin.shared.altDayOfShowContact'
                      : 'admin.shared.altContact',
                  })}
                  contactInfo={contactInfo}
                  key={index}
                />
              )
            )}
          </>
        )}
        <>
          <Subheading>
            {intl.formatMessage({
              id: 'admin.shared.characteristics',
            })}
            :
          </Subheading>
          &nbsp;
          {event.properties && event.properties?.length > 0 ? (
            <>
              <Spacer mt={2} />
              {renderProperties(event.properties)}
            </>
          ) : (
            intl.formatMessage({ id: 'none' })
          )}
        </>
        <Spacer mt={4} />
        <>
          <Subheading>
            {intl.formatMessage({
              id: 'admin.crewEvent.section.venueInfo.sofarConcerts',
            })}
            :
          </Subheading>
          &nbsp;
          {event.venue?.pastEventsCount || 0}
        </>
        <Spacer mt={4} />
        <>
          <Subheading>
            {intl.formatMessage({
              id: 'admin.shared.venueType',
            })}
            :
          </Subheading>
          &nbsp;
          {event.venue?.venueCategories &&
          event.venue?.venueCategories.length > 0
            ? event.venue?.venueCategories
                .map((venueCategory: Tag) => venueCategory.name)
                .join(', ')
            : intl.formatMessage({ id: 'none' })}
        </>
        <Spacer mt={4} />
        <>
          <Subheading>
            {intl.formatMessage({
              id: 'admin.crewEvent.section.venueInfo.guestParking',
            })}
            :
          </Subheading>
          &nbsp;
          {parkingCharacteristics && parkingCharacteristics?.length > 0
            ? parkingCharacteristics
                .map((property: Property) => property.name)
                .join(', ')
            : intl.formatMessage({ id: 'none' })}
        </>
        <Spacer mt={4} />
        <>
          <Subheading>
            {intl.formatMessage({
              id: 'admin.crewEvent.section.venueInfo.parkingNotes',
            })}
            :
          </Subheading>
          &nbsp;
          <InlineHtml
            dangerouslySetInnerHTML={{
              __html:
                event.venue?.parkingNotes || intl.formatMessage({ id: 'none' }),
            }}
          />
        </>
        <Spacer mt={4} />
        <>
          <Subheading>
            {intl.formatMessage({
              id: 'admin.crewEvent.section.venueInfo.loadInInstructions',
            })}
            :
          </Subheading>
          &nbsp;
          <InlineHtml
            dangerouslySetInnerHTML={{
              __html:
                event.venue?.loadInNotes || intl.formatMessage({ id: 'none' }),
            }}
          />
        </>
        <Spacer mt={4} />
        <>
          <Subheading>
            {intl.formatMessage({ id: 'admin.shared.gearStorage' })}:
          </Subheading>
          &nbsp;
          <InlineHtml
            dangerouslySetInnerHTML={{
              __html:
                event.venue?.gearStorage || intl.formatMessage({ id: 'none' }),
            }}
          />
        </>
        <Spacer mt={4} />
        <>
          <Subheading>
            {intl.formatMessage({ id: 'admin.shared.noiseRestrictions' })}:
          </Subheading>
          &nbsp;
          <InlineHtml
            dangerouslySetInnerHTML={{
              __html:
                event.venue?.noiseRestrictions ||
                intl.formatMessage({ id: 'none' }),
            }}
          />
        </>
        <Spacer mt={4} />
        <>
          <Subheading>
            {intl.formatMessage({
              id: 'admin.crewEvent.section.venueInfo.fanFacingVenuInfo',
            })}
            :
          </Subheading>
          &nbsp;
          <InlineHtml
            dangerouslySetInnerHTML={{
              __html:
                event.venue?.externalVenueNotes ||
                intl.formatMessage({ id: 'none' }),
            }}
          />
        </>
        <Spacer mt={4} />
        {event.venue?.socialUrls && (
          <SocialIconList
            socialUrls={event.venue.socialUrls}
            dataQaid="crew-event-venue"
          />
        )}
      </SectionText>
    </SectionWithIcon>
  );
};

export default CrewEventVenueInfo;
