import React from 'react';
import { FormikErrors } from 'formik';
import styled, { css } from 'styled-components';

import { ManualCSSColors } from 'app/shared/theme';
import { useCurrentTheme } from 'app/shared/theme';
import { FlexDivWithSpacing } from 'app/shared/components/atoms/FlexDivWithSpacing';
import { StreamlineIcon } from 'app/shared/components/atoms/StreamlineIcon';
import {
  Caption,
  FormGroupLabel,
} from 'app/shared/components/atoms/TypographyManualCSS';
import { ReactComponent as AlertCircle } from 'icons/streamline-regular/interface-essential/alerts/alert-circle.svg';

interface Props {
  label?: string;
  uppercaseLabel?: boolean;
  required?: boolean;
  errorMsg?: string | string[] | FormikErrors<any> | FormikErrors<any>[];
  children: any;
  renderIcon?: Function;
  'data-qaid'?: string;
  id?: string;
  className?: string;
  displayIconWithError?: boolean;
  errorColor?: ManualCSSColors;
  labelColor?: string;
}

const FormGroupWrapper = styled.div``;

const StyledSpan = styled.span<{
  hasError: boolean;
  errorColor: ManualCSSColors;
}>`
  ${({ theme, hasError, errorColor }) => css`
    color: ${hasError ? theme.colors[errorColor] : theme.colors.secondaryText};
  `}
`;

const FormGroup: React.FC<Props> = ({
  label,
  uppercaseLabel = false,
  required,
  children,
  errorMsg,
  renderIcon,
  'data-qaid': qaId,
  id,
  className,
  displayIconWithError,
  errorColor = 'redRedWine',
  labelColor,
}) => {
  const themeContext = useCurrentTheme();

  const renderLabel = () => {
    if (!label) {
      return null;
    }

    return (
      <FormGroupLabel
        uppercase={uppercaseLabel}
        color={
          errorMsg
            ? themeContext.colors[errorColor]
            : labelColor
            ? labelColor
            : undefined
        }
        data-qaid={`${qaId}-label`}
      >
        {label}
        {required ? (
          <StyledSpan hasError={!!errorMsg} errorColor={errorColor}>
            *
          </StyledSpan>
        ) : null}{' '}
        {renderIcon ? renderIcon() : null}
      </FormGroupLabel>
    );
  };

  return (
    <FormGroupWrapper className={className} id={id} data-qaid={qaId}>
      {renderLabel()}
      {React.cloneElement(children, { hasError: !!errorMsg })}
      {errorMsg && (
        <FlexDivWithSpacing gap={1} pt={2} alignItems="center">
          {displayIconWithError && (
            <StreamlineIcon
              size={14}
              display="inline-flex"
              icon={AlertCircle}
              stroke={themeContext.colors[errorColor]}
            />
          )}
          <Caption
            color={themeContext.colors[errorColor]}
            data-qaid={`${qaId}-error`}
          >
            {errorMsg}
          </Caption>
        </FlexDivWithSpacing>
      )}
    </FormGroupWrapper>
  );
};

export default FormGroup;
