import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import {
  ArtistApplication,
  ArtistApplicationMainDecision,
  ArtistApplicationOfferDecision,
  ArtistApplicationRejectDecision,
  ArtistApplicationStatus,
} from 'app/typings/artistApplications';
import { Artist } from 'app/typings/artists';
import { ArtistSetup } from 'app/typings/artistSetups';
import { dateFormatter } from 'app/shared/utils/datetime';
import { get } from 'app/shared/utils/get';
import usePermission from 'app/shared/utils/usePermission';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import { GenericLinkWrapper } from 'app/shared/components/atoms/LinkManualCSS';
import { LoadingError } from 'app/shared/components/atoms/LoadingError';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import InfoBox from 'app/shared/components/molecules/Flyover';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import {
  AcceptArtistApplication,
  RejectArtistApplication,
} from 'app/admin/graphql/artistApplications/mutationHooks';
import { GetArtistApplication } from 'app/admin/graphql/artistApplications/queryHooks';
import { AddArtistToOffer } from 'app/admin/graphql/offers/mutationHooks';
import { DetailsInfoMainContainer } from 'app/admin/components/atoms/DetailContent';
import EditFormLoadingBlocks from 'app/admin/components/atoms/EditFormLoadingBlocks';
import ExpandableText from 'app/admin/components/atoms/ExpandableText';
import ArtistSetupDetails from 'app/admin/components/organisms/ArtistSetupDetails';
import Carousel from 'app/admin/components/organisms/Carousel';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

import ArtistApplicationReviewForm from './ArtistApplicationReviewForm';
import ArtistApplicationReviewFormSchema from './ArtistApplicationReviewFormSchema';

interface Props {
  contentProps: any;
  setFormSubmitAction: (func: Function) => void;
  setDisplayConfirmation: (set: boolean) => void;
  setConfirmationDescription: (set: string) => void;
  setIsSubmitting: (set: boolean) => void;
  setButtonConfigs: (set: object) => void;
  navigateTo: (routeData: object) => void;
  hide: VoidFunction;
}

const VideoContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;

    ${theme.media.xs`
      height: 250px;
    `}

    ${theme.media.sm`
      height: 250px;
    `}

    ${theme.media.md`
      margin-top: 40px;
      max-height: 400px;
    `};

    ${theme.media.lg`
      margin-top: 50px;
      max-height: 400px;
    `};

    ${theme.media.xl`
      margin-top: 50px;
      max-height: 400px;
    `};
  `}
`;

const StyledSpacer = styled(Spacer)`
  ${({ theme }) => css`
    ${theme.media.lg`
      margin-top: 46px;
    `};
  `}
`;

const RowContainer = styled.div`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  margin-bottom: 10px;
`;

const RowHeading = styled.span`
  font-weight: 600;
`;

const RowValue = styled.span``;

const GreyRowValue = styled(RowValue)`
  ${({ theme }) => css`
    color: ${theme.colors.warmGrey};
  `};
`;

const SetupInfoWrapper = styled.div`
  display: inline-block;
`;

const ArtistApplicationReview: React.FC<Props> = ({
  contentProps,
  setButtonConfigs,
  setFormSubmitAction,
  setDisplayConfirmation,
  setConfirmationDescription,
  setIsSubmitting,
  navigateTo,
  hide,
}) => {
  const intl = useIntl();
  const { id, artistName, refetchArtistApplications } = contentProps;

  const hasSoftRejectApplicationPermission = usePermission(
    'artistApplication.softReject'
  );

  const [headerData, setHeaderData] = useState({
    artistName,
  });
  const [
    artistApplicationMainDecision,
    setArtistApplicationMainDecision,
  ] = useState<ArtistApplicationMainDecision | undefined>(undefined);
  const [
    artistApplicationRejectDecision,
    setArtistApplicationRejectDecision,
  ] = useState<ArtistApplicationRejectDecision | undefined>(undefined);
  const [
    artistApplicationOfferDecision,
    setArtistApplicationOfferDecision,
  ] = useState<ArtistApplicationOfferDecision>(
    ArtistApplicationOfferDecision.CREATE_NEW_OFFER
  );
  const [
    artistApplicationSelectedOffer,
    setArtistApplicationSelectedOffer,
  ] = useState<any>(undefined);
  const [artist, setArtist] = useState<Artist | undefined>(undefined);
  const [submitAddArtistToOffer, setSubmitAddArtistToOffer] = useState(false);

  const {
    loading: loadingArtistApplicationInfo,
    error: errorArtistApplicationInfo,
    data: dataArtistApplicationInfo,
  } = GetArtistApplication({
    id,
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    const artistApplicationInfo = get(
      dataArtistApplicationInfo,
      'artistApplication',
      undefined
    );

    if (artistApplicationInfo) {
      setHeaderData({
        artistName: artistApplicationInfo.artistName,
      });
    }
  }, [dataArtistApplicationInfo]);

  const formInitialValues = {
    artistApplicationMainDecision,
    artistApplicationRejectDecision,
    artistApplicationOfferDecision:
      ArtistApplicationOfferDecision.CREATE_NEW_OFFER,
    customEmailMessage: '',
  };

  const artistApp: ArtistApplication = get(
    dataArtistApplicationInfo,
    'artistApplication',
    {}
  );
  const artistSetup: ArtistSetup = get(
    dataArtistApplicationInfo,
    'artistApplication.artistSetups',
    {}
  )[0];

  const socialUrls = get(
    dataArtistApplicationInfo,
    'artistApplication.socialUrls',
    []
  );

  const ArtistSetupLink = () => (
    <GenericLinkWrapper>
      {artistSetup && artistSetup.setupName}
    </GenericLinkWrapper>
  );

  const artistSetupInfo = () => (
    <SetupInfoWrapper>
      <InfoBox
        showCaret={false}
        width="300px"
        triggerElement={<ArtistSetupLink />}
        innerContent={<ArtistSetupDetails artistSetup={artistSetup} />}
      />
    </SetupInfoWrapper>
  );

  const availableLastMinuteToPlayInCity = () => {
    return dataArtistApplicationInfo?.artistApplication?.isAvailableLastMinute
      ? intl.formatMessage({ id: 'yes' })
      : intl.formatMessage({ id: 'no' });
  };

  const getPortfolioUrls = (): string[] => {
    if (
      artistApp.portfolioUrl1 ||
      artistApp.portfolioUrl2 ||
      artistApp.portfolioUrl3
    ) {
      return [
        artistApp.portfolioUrl1,
        artistApp.portfolioUrl2,
        artistApp.portfolioUrl3,
      ].filter(portfolioUrl => !!portfolioUrl) as string[];
    }

    return [];
  };

  const portfolioUrls = getPortfolioUrls();

  const mappingStatusToDisplayLabels = {
    rejected: intl.formatMessage({
      id: 'admin.artistApplication.details.archivedAndNotified',
    }),
    soft_rejected: intl.formatMessage({
      id: 'admin.artistApplication.details.archivedNotNotified',
    }),
    timed_out: intl.formatMessage({
      id: 'admin.artistApplication.details.expired',
    }),
    open: intl.formatMessage({ id: 'admin.artistApplication.details.open' }),
    accepted: intl.formatMessage({
      id: 'admin.artistApplication.details.acceptedWithBooking',
    }),
    accepted_without_booking: intl.formatMessage({
      id: 'admin.artistApplication.details.acceptedWithoutBooking',
    }),
  };

  const isRejectOrSoftReject = (
    decision: ArtistApplicationMainDecision | undefined
  ) => decision === ArtistApplicationMainDecision.REJECT_OR_SOFT_REJECT;

  const handleUpdateArtistApplication = () => {
    if (isRejectOrSoftReject(artistApplicationMainDecision)) {
      return rejectArtistApplication;
    } else {
      return acceptArtistApplication;
    }
  };

  const acceptArtistApplicationAction = AcceptArtistApplication();

  const isAcceptWithBooking = (
    decision: ArtistApplicationMainDecision | undefined
  ) => decision === ArtistApplicationMainDecision.ACCEPT_WITH_BOOKING;

  const acceptArtistApplication = useSubmitAction({
    submitAction: acceptArtistApplicationAction,
    submitVariables: (values: any) => ({
      id,
      status: isAcceptWithBooking(values.artistApplicationMainDecision)
        ? 'accepted'
        : 'accepted_without_booking',
      customEmailMessage: values.customEmailMessage,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.artistApplication.accept.successMsg',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.artistApplication.accept.failureMsg',
    }),
    onSuccess: (data: any) => {
      const createdArtist = get(
        data,
        'data.acceptArtistApplication.artist',
        undefined
      );
      setArtist(createdArtist);
      refetchArtistApplications();
      if (
        isAcceptWithBooking(
          artistApplicationMainDecision as ArtistApplicationMainDecision
        )
      ) {
        if (
          artistApplicationOfferDecision ===
          ArtistApplicationOfferDecision.CREATE_NEW_OFFER
        ) {
          navigateTo({
            routeName: 'offer-create',
            routeProps: {
              artists: [createdArtist],
              city: artistApp?.city,
              offerName: `${createdArtist.title} Invitation`,
              initialFormStep: 'step2',
              attribution: 'artist_application',
            },
          });
        } else {
          setSubmitAddArtistToOffer(true);
          hide();
        }
      } else {
        hide();
      }
    },
  });

  const rejectArtistApplicationAction = RejectArtistApplication();

  const isSoftReject = (
    decision: ArtistApplicationRejectDecision | undefined
  ) => decision === ArtistApplicationRejectDecision.SOFT_REJECT;

  const rejectionStatus = (
    artistApplicationRejectDecision: ArtistApplicationRejectDecision
  ) => {
    if (!hasSoftRejectApplicationPermission) {
      return 'rejected';
    }

    switch (artistApplicationRejectDecision) {
      case ArtistApplicationRejectDecision.REJECT:
        return 'rejected';
      case ArtistApplicationRejectDecision.SOFT_REJECT:
        return 'soft_rejected';
      case ArtistApplicationRejectDecision.SOFT_REJECT_REAPPLY:
        return 'soft_rejected_reapply';
    }
  };

  const rejectArtistApplication = useSubmitAction({
    submitAction: rejectArtistApplicationAction,
    submitVariables: (values: any) => ({
      id,
      status: rejectionStatus(values.artistApplicationRejectDecision),
      customEmailMessage: isSoftReject(values.artistApplicationRejectDecision)
        ? null
        : values.customEmailMessage,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.artistApplication.reject.successMsg',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.artistApplication.reject.failureMsg',
    }),
    onSuccess: () => {
      hide();
      refetchArtistApplications();
    },
  });

  const addArtistToOfferAction = AddArtistToOffer();

  const handleAddArtistToOffer = useSubmitAction({
    submitAction: addArtistToOfferAction,
    submitVariables: () => {
      return {
        offerId: artistApplicationSelectedOffer?.value,
        artistId: artist?.id,
        attribution: 'artist_application',
      };
    },
    successMsg: intl.formatMessage(
      {
        id: 'admin.artistApplications.addArtistToExistingInvitation.success',
      },
      {
        artistName: artist?.title,
        offerName: artistApplicationSelectedOffer?.title,
      }
    ),
    onSuccess: () => {},
    failureMsg: intl.formatMessage(
      {
        id: 'admin.artistApplications.addArtistToExistingInvitation.failure',
      },
      {
        artistName: artist?.title,
        offerName: artistApplicationSelectedOffer?.title,
      }
    ),
  });

  const artistAppStatus = artistApp && artistApp.status;
  const createdAt =
    artistApp?.createdAt &&
    dateFormatter(artistApp.createdAt, 'longMonthDateAndYear');
  const decisionMadeAt =
    artistApp?.decisionMadeAt &&
    dateFormatter(artistApp.decisionMadeAt, 'longMonthDateAndYear');
  const setup = artistSetup && artistSetupInfo();
  const referrer = artistApp && artistApp.referrer;
  const decisionMadeByUser = artistApp && artistApp.decisionMadeByUser;

  useEffect(() => {
    if (artist && submitAddArtistToOffer) {
      setSubmitAddArtistToOffer(false);
      handleAddArtistToOffer();
    }
  }, [submitAddArtistToOffer, artist, handleAddArtistToOffer]);

  if (errorArtistApplicationInfo) {
    return <LoadingError whatsBeingLoaded="this form" />;
  }

  if (loadingArtistApplicationInfo || !dataArtistApplicationInfo) {
    return <EditFormLoadingBlocks />;
  }

  return (
    <>
      <ModalContentContainer>
        <DetailsInfoMainContainer>
          <DetailsHeader
            title={intl.formatMessage(
              {
                id: 'admin.artistApplication.details.title',
              },
              {
                artistName: headerData.artistName,
              }
            )}
          />
          <Spacer mb={5} />
          <DottedLine />
          <Spacer mb={5} />
          <Grid cols={3}>
            <Col xs={3} sm={3} md={3} lg={2} xl={2} key={1}>
              <VideoContainer>
                {portfolioUrls.length > 0 && (
                  <Carousel
                    sources={getPortfolioUrls().map((url: string) => ({ url }))}
                    type="video"
                  />
                )}
              </VideoContainer>
            </Col>
            <Col xs={3} sm={3} md={3} lg={1} xl={1} key={2}>
              {artistAppStatus !== ArtistApplicationStatus.OPEN && (
                <StyledSpacer />
              )}
              <RowContainer>
                <RowHeading>
                  {intl.formatMessage({
                    id: 'admin.artistApplication.details.dateOfApplication',
                  })}
                  :{' '}
                </RowHeading>
                <RowValue> {createdAt} </RowValue>
              </RowContainer>
              <RowContainer>
                <RowHeading>
                  {intl.formatMessage({
                    id: 'admin.artistApplication.details.cityOfApplication',
                  })}
                  :{' '}
                </RowHeading>
                <RowValue> {artistApp.city && artistApp.city.title} </RowValue>
              </RowContainer>
              <RowContainer>
                <RowHeading>
                  {intl.formatMessage({
                    id: 'admin.artistApplication.details.homeCity',
                  })}
                  :{' '}
                </RowHeading>
                <RowValue> {artistApp.homeCityDescription} </RowValue>
              </RowContainer>
              <RowContainer>
                <RowHeading>
                  {intl.formatMessage({
                    id: 'admin.artistApplication.details.setup',
                  })}
                  :{' '}
                </RowHeading>
                <RowValue>
                  {setup
                    ? setup
                    : intl.formatMessage({ id: 'admin.notApplicable' })}
                </RowValue>
              </RowContainer>
              <RowContainer>
                <RowHeading>
                  {intl.formatMessage({
                    id: 'admin.artistApplication.details.artistEmail',
                  })}
                  :{' '}
                </RowHeading>
                <RowValue> {artistApp.emailAddress} </RowValue>
              </RowContainer>
              <RowContainer>
                <RowHeading>
                  {intl.formatMessage({
                    id:
                      'admin.artistApplication.details.availableToPlayLastMinute',
                  })}
                  :{' '}
                </RowHeading>
                <RowValue> {availableLastMinuteToPlayInCity()} </RowValue>
              </RowContainer>
              <RowContainer>
                <RowHeading>
                  {intl.formatMessage({
                    id: 'admin.artistApplication.details.referredBy',
                  })}
                  :{' '}
                </RowHeading>
                <RowValue>
                  {referrer
                    ? `${referrer.firstName} ${referrer.lastName}`
                    : intl.formatMessage({ id: 'admin.notApplicable' })}
                </RowValue>
              </RowContainer>
              {artistAppStatus &&
                artistAppStatus !== ArtistApplicationStatus.OPEN && (
                  <>
                    <RowContainer>
                      <RowHeading>
                        {intl.formatMessage({
                          id: 'shared.status',
                        })}
                        :{' '}
                      </RowHeading>
                      <RowValue>
                        {mappingStatusToDisplayLabels[artistAppStatus]}
                      </RowValue>
                    </RowContainer>

                    {decisionMadeByUser && decisionMadeAt && (
                      <>
                        <RowContainer>
                          <RowHeading>
                            {intl.formatMessage({
                              id: 'admin.artistApplication.details.archivedBy',
                            })}
                            :{' '}
                          </RowHeading>
                          <RowValue>
                            {decisionMadeByUser
                              ? `${decisionMadeByUser.firstName} ${decisionMadeByUser.lastName}`
                              : intl.formatMessage({
                                  id: 'admin.notApplicable',
                                })}
                          </RowValue>
                        </RowContainer>
                        <RowContainer>
                          <RowHeading>
                            {intl.formatMessage({
                              id: 'admin.artistApplication.details.archivedOn',
                            })}
                            :{' '}
                          </RowHeading>
                          <RowValue> {decisionMadeAt} </RowValue>
                        </RowContainer>
                      </>
                    )}
                    <RowContainer>
                      <RowHeading>
                        {intl.formatMessage({
                          id:
                            'admin.artistApplication.details.customNoteToEmail',
                        })}
                        :{' '}
                      </RowHeading>
                      {artistApp.customEmailMessage ? (
                        <RowValue>
                          <Spacer mb={2} />
                          <ExpandableText
                            allowHTML
                            text={artistApp.customEmailMessage}
                            truncateLength={150}
                          />
                        </RowValue>
                      ) : (
                        <GreyRowValue>
                          {intl.formatMessage({ id: 'none' })}
                        </GreyRowValue>
                      )}
                    </RowContainer>
                  </>
                )}
              {artistAppStatus &&
                artistAppStatus === ArtistApplicationStatus.OPEN && (
                  <>
                    <Spacer mb={5} />
                    <GenericForm
                      formInitialValues={formInitialValues}
                      renderFormComponent={(renderProps: any) => (
                        <ArtistApplicationReviewForm
                          city={artistApp?.city}
                          formikProps={renderProps.formikProps}
                          setButtonConfigs={setButtonConfigs}
                          setFormSubmitAction={setFormSubmitAction}
                          setDisplayConfirmation={setDisplayConfirmation}
                          setConfirmationDescription={
                            setConfirmationDescription
                          }
                          setIsSubmitting={setIsSubmitting}
                          setArtistApplicationMainDecision={
                            setArtistApplicationMainDecision
                          }
                          setArtistApplicationRejectDecision={
                            setArtistApplicationRejectDecision
                          }
                          setArtistApplicationOfferDecision={
                            setArtistApplicationOfferDecision
                          }
                          setArtistApplicationSelectedOffer={
                            setArtistApplicationSelectedOffer
                          }
                          artistApplicationSelectedOffer={
                            artistApplicationSelectedOffer
                          }
                          artistApplicationSocialUrls={socialUrls}
                        />
                      )}
                      onSubmit={handleUpdateArtistApplication()}
                      formSchema={ArtistApplicationReviewFormSchema(intl)}
                      dataQaId="review-artist-application-form"
                      paddingTop="0"
                    />
                  </>
                )}
            </Col>
          </Grid>
        </DetailsInfoMainContainer>
      </ModalContentContainer>
    </>
  );
};

export default ArtistApplicationReview;
