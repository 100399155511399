import * as Yup from 'yup';

const crewUserBasicInfoFormSchema = (intl: any) =>
  Yup.object().shape({
    email: Yup.string()
      .email(
        intl.formatMessage(
          {
            id: 'form.fieldMustBeValidEmail',
          },
          {
            field: 'Email',
          }
        )
      )
      .required(
        intl.formatMessage({
          id: 'form.requiredField',
        })
      ),
    slackUserId: Yup.string()
      .nullable()
      .matches(/^[A-Za-z0-9]+$/, {
        message: intl.formatMessage({
          id: 'admin.userBasicInfo.form.slackUserIdError',
        }),
      }),
  });

export default crewUserBasicInfoFormSchema;
