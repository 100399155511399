export const sortOptions = [
  {
    title: 'Artist Name (A - Z)',
    value: {
      by: 'title',
      direction: 'asc',
    },
  },
  {
    title: 'Artist Name (Z - A)',
    value: {
      by: 'title',
      direction: 'desc',
    },
  },
  {
    title: 'Number of Duplicates (Most First)',
    value: {
      by: 'num_artists',
      direction: 'desc',
    },
  },
  {
    title: 'Number of Duplicates (Fewest First)',
    value: {
      by: 'num_artists',
      direction: 'asc',
    },
  },
];
