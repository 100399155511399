import apolloClient from 'src/apollo/sofarApolloClient';

import { Artist, City, Competition, Event, Venue } from 'app/typings';
import {
  UPDATE_IMAGES,
  UPDATE_IMAGES_FOR_REFILE,
} from 'app/shared/graphql/images/mutations';

interface RefileImage {
  contentType?: string;
  filename?: string;
  id?: string;
  size?: number;
  url?: string;
}

interface ImageInput {
  contentType?: string;
  fileLocation?: string;
  filename?: string;
  ratio?: string;
  size?: number;
}

export interface ObjectImageInput {
  imageableObjectId: number;
  imageableObjectType: string;
  imagePurpose: string;
  images: ImageInput[];
}

export interface UpdateImagesVariables {
  imageableObjectId: number;
  imageableObjectType: string;
  refileImage?: RefileImage;
  refileKey?: string;
  images?: ObjectImageInput[];
}

export interface UpdateImagesData {
  updateImages: {
    success: boolean;
    artist?: Artist;
    city?: City;
    competition?: Competition;
    event?: Event;
    venue?: Venue;
  };
}

export const UpdateImages = async (data: UpdateImagesVariables) => {
  return apolloClient.mutate<UpdateImagesData, UpdateImagesVariables>({
    mutation: UPDATE_IMAGES,
    variables: data,
  });
};

export const UpdateImagesForRefile = async (data: UpdateImagesVariables) => {
  return apolloClient.mutate<UpdateImagesData, UpdateImagesVariables>({
    mutation: UPDATE_IMAGES_FOR_REFILE,
    variables: data,
  });
};
