import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { errorMsgForField } from 'app/shared/utils/form';
import {
  FormGroupContainer,
  FormSectionTitle,
} from 'app/shared/components/atoms/FormContent';
import GenericFormContainer from 'app/shared/components/atoms/GenericFormContainer';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Textfield } from 'app/shared/components/atoms/Textfield';
import FormGroup from 'app/shared/components/molecules/FormGroup';
import RichTextEditor from 'app/admin/components/molecules/RichTextEditor';

interface FormProps {
  formikProps: any;
  validationErrors?: object;
}

const TitleTextfield = styled(Textfield)`
  ${({ theme }) => css`
    display: block;
    width: 100%;
    ${theme.media.lg`
        width: 48%;
      `};
  `}
`;

const NotesNote = styled.div`
  font-size: 14px;
  font-style: italic;
  margin-bottom: 10px;
`;

const ThemeEditForm: React.FC<FormProps> = ({
  formikProps,
  validationErrors = {},
}) => {
  const intl = useIntl();

  const { setFieldValue, touched, errors, values } = formikProps;

  return (
    <GenericFormContainer dataQaId="theme-edit-form">
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.themeEdit.form.title',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="theme-edit-form-title-formgroup"
          label={intl.formatMessage({
            id: 'admin.themeEdit.form.title',
          })}
          errorMsg={
            errorMsgForField('title', touched, errors) ||
            // @ts-ignore
            validationErrors.title
          }
          required
        >
          <TitleTextfield
            data-qaid="theme-edit-form-title-field"
            id="title"
            name="title"
            value={values.title}
            onChange={(e: any) => {
              setFieldValue('title', e.target.value);
            }}
            maxLength={25}
          />
        </FormGroup>
        <Spacer mb={8} />
        <FormGroup
          data-qaid="theme-edit-form--notes-formgroup"
          label={intl.formatMessage({
            id: 'admin.themeEdit.form.notes',
          })}
          errorMsg={errorMsgForField('notes', touched, errors)}
        >
          <>
            <NotesNote>
              {intl.formatMessage({
                id: 'admin.themeEdit.form.notesNote',
              })}
            </NotesNote>
            <RichTextEditor
              name="notes"
              currentValue={values.notes}
              onChange={value => {
                setFieldValue('notes', value);
              }}
            />
          </>
        </FormGroup>
      </FormGroupContainer>
    </GenericFormContainer>
  );
};

export default ThemeEditForm;
