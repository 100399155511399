import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { scrollToTop } from 'app/shared/utils/modal';
import CloseButton from 'app/shared/components/atoms/CloseButton';
import { CloseIcon } from 'app/shared/components/atoms/IconLibrary';

import { ButtonConfig, ModalFooter } from './ModalFooter';
import ModalRoutes from './routes';

// @ts-ignore
const AnchoredCloseButton = styled(CloseButton)`
  position: sticky;
  top: 0;
  padding: 15px;
  margin: 0px;
`;

interface ModalNavigationProps {
  initialRouteName: string;
  initialRouteProps: object;
  hide: VoidFunction;
  modalRef: React.RefObject<HTMLDivElement>;
  fireCloseEvent?: boolean;
  setFireCloseEvent?: (fireEvent: boolean) => void;
  displayConfirmation: boolean;
  setDisplayConfirmation: (displayConfirmation: boolean) => void;
  dataQaidSuffix: string;
}

export interface RouteData {
  routeName: string;
  routeProps: any;
}

const atSecondLevelRouteOrDeeper = (routesData: RouteData[]) => {
  if (routesData.length > 1) {
    return true;
  } else {
    return false;
  }
};

const previousRouteWasAForm = (routesData: RouteData[]) => {
  if (routesData.length <= 1) {
    return false;
  }

  const previousRouteName = routesData[routesData.length - 2].routeName;
  const previousRouteComponentBundle = ModalRoutes[previousRouteName];
  return (previousRouteComponentBundle.metadata || {}).isForm;
};

const getNavigateRoutesData = (
  routesData: RouteData[],
  newRoute: RouteData
) => {
  // e.g. if routesData currently contains:
  // [
  //   {routeName: 'guestlist', routeProps: { blah blah }},
  //   {routeName: 'guestlist-day-of-show', routeProps: { blah blah }},
  //   {routeName: 'guestlist-attendance-edit', routeProps: { blah blah }},
  // ]
  // and we're navigating to guestlist-day-of-show, then instead of adding
  // guestlist-day-of-show to end of the list, remove the previous
  // guestlist-day-of-show entry and everything after it, then add
  // guestlist-day-of-show to end of list (using latest props), resulting in:
  // [
  //   {routeName: 'guestlist', routeProps: { blah blah }},
  //   {routeName: 'guestlist-day-of-show', routeProps: { blah blah }},
  // ]
  // This makes it so the Close/Back button correctly takes user back to the next-outer modal
  const indexOfRouteWithSameName = routesData
    .map((route: any) => route.routeName)
    .indexOf(newRoute.routeName);
  let newRoutesData = routesData;
  if (indexOfRouteWithSameName >= 0) {
    newRoutesData = newRoutesData.slice(0, indexOfRouteWithSameName);
  }
  return newRoutesData.concat([newRoute]);
};

const getGoBackRoutesData = (
  routesData: RouteData[],
  onGoBackExtraRouteProps?: object,
  fromConfirmationModal = false
) => {
  const newRoutesData = fromConfirmationModal
    ? routesData
    : routesData.slice(0, -1);
  if (onGoBackExtraRouteProps) {
    let lastRouteData = newRoutesData[newRoutesData.length - 1];
    let lastRouteProps = lastRouteData.routeProps;
    lastRouteProps = Object.assign(lastRouteProps, onGoBackExtraRouteProps);
    lastRouteData = Object.assign(lastRouteData, {
      routeProps: lastRouteProps,
    });
    newRoutesData[newRoutesData.length - 1] = lastRouteData;
  }
  return newRoutesData;
};

interface DefaultButtonConfig {
  defaultTextKey?: string;
  defaultOnClick?: VoidFunction;
  defaultDisplayed?: boolean;
  defaultDisabled?: boolean;
  defaultLoading?: boolean;
}

const getButtonConfig = (
  values: ButtonConfig,
  defaultValues: DefaultButtonConfig
) => {
  return {
    textKey:
      values.hasOwnProperty('textKey') && values.textKey !== undefined
        ? values.textKey
        : defaultValues.defaultTextKey,
    onClick:
      values.hasOwnProperty('onClick') && values.onClick !== undefined
        ? values.onClick
        : defaultValues.defaultOnClick,
    displayed:
      values.hasOwnProperty('displayed') && values.displayed !== undefined
        ? values.displayed
        : defaultValues.defaultDisplayed,
    disabled:
      values.hasOwnProperty('disabled') && values.disabled !== undefined
        ? values.disabled
        : defaultValues.defaultDisabled,
    loading:
      values.hasOwnProperty('loading') && values.loading !== undefined
        ? values.loading
        : defaultValues.defaultLoading,
  };
};

interface ButtonState {
  textKey?: string;
  displayed?: boolean;
  disabled?: boolean;
  loading?: boolean;
}

interface ButtonsState {
  back?: ButtonState;
  forward?: ButtonState;
  secondarySubmit?: ButtonState;
  submit?: ButtonState;
}

const ModalNavigation: React.FC<ModalNavigationProps> = ({
  initialRouteName,
  initialRouteProps,
  hide,
  modalRef,
  fireCloseEvent = false,
  setFireCloseEvent,
  displayConfirmation,
  setDisplayConfirmation,
  dataQaidSuffix,
}) => {
  const intl = useIntl();

  const [routesData, setRoutesData] = useState<RouteData[]>([
    {
      routeName: initialRouteName,
      routeProps: initialRouteProps,
    },
  ]);
  const [buttonsState, setButtonsState] = useState<ButtonsState>({});
  const [goBackAction, setGoBackAction] = useState(undefined);
  const [closeAction, setCloseAction] = useState(undefined);
  const [goForwardAction, setGoForwardAction] = useState(undefined);
  const [
    secondaryFormSubmitAction,
    setFormSecondarySubmitAction,
  ] = useState(() => () => {});
  const [formSubmitAction, setFormSubmitAction] = useState(() => () => {});
  const [confirmationDescription, setConfirmationDescription] = useState(
    undefined
  );
  const [confirmationButtonText, setConfirmationButtonText] = useState(
    'Continue'
  );
  const [cancellationButtonText, setCancellationButtonText] = useState(
    'Cancel'
  );
  const [customContent, setCustomContent] = useState();
  const [checkConfirmation, setCheckConfirmation] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingSecondary, setIsSubmittingSecondary] = useState(false);

  const currentRouteName = routesData[routesData.length - 1].routeName;
  const currentRouteProps = routesData[routesData.length - 1].routeProps;
  const componentBundle = ModalRoutes[currentRouteName];
  const Content = componentBundle.content;
  const metadata = componentBundle.metadata || {};
  const ConfirmationComponent = metadata.isForm
    ? componentBundle.confirmationComponent
    : null;

  useEffect(() => {
    if (fireCloseEvent) {
      handleCloseButtonClick();
    }
  });

  metadata.displayModalFooter = metadata.hasOwnProperty('displayModalFooter')
    ? metadata.displayModalFooter
    : true;

  const updateModal = (newRoutesData: RouteData[]) => {
    setButtonsState({});
    setGoBackAction(undefined);
    setCloseAction(undefined);
    setGoForwardAction(undefined);
    setFormSecondarySubmitAction(() => () => {});
    setFormSubmitAction(() => () => {});
    setRoutesData(newRoutesData);
    // Must call setDisplayConfirmation AFTER calling setRoutesData, since setRoutesData causes the NEW
    // component containing fresh formikProps (and hence a fresh value of formikProps.dirty) to get mounted,
    // but until we do that, any re-render will re-call setDisplayConfirmation(formikProps.dirty) via the
    // useEffect in the OLD component, whose formikProps.dirty=true
    setDisplayConfirmation(false);
    // This is needed in order to scroll content back to top when navigating to a new route
    scrollToTop(modalRef);
  };

  const onNavigate = ({ routeName, routeProps }: RouteData) => {
    if (displayConfirmation) {
      updateModal(getNavigateRoutesData([], { routeName, routeProps }));
    } else {
      updateModal(getNavigateRoutesData(routesData, { routeName, routeProps }));
    }
  };

  const onGoBack = () => {
    if (displayConfirmation) {
      setCheckConfirmation(true);
      return;
    }
    if (routesData.length <= 1) {
      hide();
    } else {
      updateModal(
        getGoBackRoutesData(routesData, metadata.onGoBackExtraRouteProps)
      );
    }
  };

  const onConfirmationModalGoBack = () => {
    setFireCloseEvent && setFireCloseEvent(false);
    if (routesData.length <= 1) {
      hide();
    } else {
      updateModal(
        getGoBackRoutesData(routesData, metadata.onGoBackExtraRouteProps, false)
      );
      setDisplayConfirmation(true);
      if (checkConfirmation) {
        setCheckConfirmation(false);
        return;
      }
    }
  };

  const confirmModalAction = metadata.isForm
    ? onConfirmationModalGoBack
    : () => setFireCloseEvent && setFireCloseEvent(false);

  const cancelModal = () => {
    setCheckConfirmation && setCheckConfirmation(false);
    setFireCloseEvent && setFireCloseEvent(false);
  };

  const backButtonConfig = getButtonConfig(
    {
      textKey: buttonsState?.back?.textKey,
      onClick: goBackAction,
      displayed: buttonsState?.back?.displayed,
      disabled: buttonsState?.back?.disabled,
      loading: buttonsState?.back?.loading,
    },
    {
      defaultTextKey:
        !metadata.isForm &&
        !previousRouteWasAForm(routesData) &&
        atSecondLevelRouteOrDeeper(routesData)
          ? 'form.back'
          : metadata.backButtonTextKey || 'form.cancel',
      defaultOnClick: onGoBack,
      defaultDisplayed:
        metadata.displayBackButton !== undefined
          ? metadata.displayBackButton
          : true,
      defaultDisabled: false,
      defaultLoading: false,
    }
  );

  const forwardButtonConfig = getButtonConfig(
    {
      textKey: buttonsState?.forward?.textKey,
      onClick: goForwardAction,
      displayed: buttonsState?.forward?.displayed,
      disabled: buttonsState?.forward?.disabled,
      loading: buttonsState?.forward?.loading,
    },
    {
      defaultTextKey: metadata.forwardButtonTextKey || 'form.next',
      defaultOnClick: () => {},
      defaultDisplayed:
        metadata.displayForwardButton !== undefined
          ? metadata.displayForwardButton
          : false,
      defaultDisabled: false,
      defaultLoading: false,
    }
  );

  const secondarySubmitButtonConfig = getButtonConfig(
    {
      textKey: buttonsState?.secondarySubmit?.textKey,
      onClick: secondaryFormSubmitAction,
      displayed: buttonsState?.secondarySubmit?.displayed,
      disabled: buttonsState?.secondarySubmit?.disabled,
      loading: buttonsState?.secondarySubmit?.loading,
    },
    {
      defaultTextKey:
        metadata.secondarySubmitButtonTextKey || 'form.saveAndAddMore',
      defaultOnClick: () => {},
      defaultDisplayed:
        metadata.displaySecondarySubmitButton !== undefined
          ? metadata.displaySecondarySubmitButton
          : false,
      defaultDisabled: isSubmitting,
      defaultLoading: isSubmittingSecondary,
    }
  );

  const submitButtonConfig = getButtonConfig(
    {
      textKey: buttonsState?.submit?.textKey,
      onClick: formSubmitAction,
      displayed: buttonsState?.submit?.displayed,
      disabled: buttonsState?.submit?.disabled,
      loading: buttonsState?.submit?.loading,
    },
    {
      defaultTextKey: metadata.submitButtonTextKey || 'form.save',
      defaultOnClick: () => {},
      defaultDisplayed:
        metadata.displaySubmitButton !== undefined
          ? metadata.displaySubmitButton
          : metadata.isForm,
      defaultDisabled: isSubmittingSecondary || !displayConfirmation,
      defaultLoading: isSubmitting,
    }
  );

  const handleCloseButtonClick = () => {
    if (closeAction) {
      (closeAction as Function)();
    } else {
      if (metadata.isForm && displayConfirmation && setCheckConfirmation) {
        setCheckConfirmation(true);
      } else {
        hide();
      }
    }
  };

  return (
    <>
      <AnchoredCloseButton onClick={handleCloseButtonClick}>
        <CloseIcon />
      </AnchoredCloseButton>
      {componentBundle.content && (
        <Content
          contentProps={{ ...currentRouteProps, modalRef }}
          navigateTo={onNavigate}
          hide={hide}
          setButtonConfigs={setButtonsState}
          setGoBackAction={setGoBackAction}
          setCloseAction={setCloseAction}
          setGoForwardAction={setGoForwardAction}
          setFormSecondarySubmitAction={setFormSecondarySubmitAction}
          setFormSubmitAction={setFormSubmitAction}
          setDisplayConfirmation={setDisplayConfirmation}
          setConfirmationDescription={setConfirmationDescription}
          setConfirmationButtonText={setConfirmationButtonText}
          setCancellationButtonText={setCancellationButtonText}
          setConfirmationCustomContent={setCustomContent}
          setIsSubmitting={setIsSubmitting}
          setIsSubmittingSecondary={setIsSubmittingSecondary}
          isSubmitting={isSubmitting}
          isSubmittingSecondary={isSubmittingSecondary}
        />
      )}
      {metadata.displayModalFooter && (
        <ModalFooter
          backButtonConfig={backButtonConfig}
          forwardButtonConfig={forwardButtonConfig}
          secondarySubmitButtonConfig={secondarySubmitButtonConfig}
          submitButtonConfig={submitButtonConfig}
          dataQaidSuffix={dataQaidSuffix}
        />
      )}
      {componentBundle.confirmationComponent &&
        displayConfirmation &&
        checkConfirmation && (
          <ConfirmationComponent
            onCancel={() => cancelModal()}
            onConfirm={confirmModalAction}
            confirmationButtonText={confirmationButtonText}
            cancellationButtonText={cancellationButtonText}
            customContent={customContent}
            description={
              confirmationDescription ||
              intl.formatMessage({
                id: 'form.closeModalWithoutSaving',
              })
            }
          />
        )}
    </>
  );
};

export default ModalNavigation;
