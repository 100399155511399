import React from 'react';
import { useIntl } from 'react-intl';

import { get } from 'app/shared/utils/get';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { UpdateCompetition } from 'app/admin/graphql/competitions/mutationHooks';
import { DetailsFormMainContainer } from 'app/admin/components/atoms/DetailContent';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

import CompetitionSharingEditForm from './CompetitionSharingEditForm';
import CompetitionSharingEditFormSchema from './CompetitionSharingEditFormSchema';

interface Props {
  contentProps?: any;
  setFormSubmitAction: (func: Function) => void;
  setDisplayConfirmation: (set: boolean) => void;
  setIsSubmitting: (set: boolean) => void;
  navigateTo: (routeData: object) => void;
}

const CompetitionSharingEdit: React.FC<Props> = ({
  contentProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  navigateTo,
}) => {
  const intl = useIntl();

  const formInitialValues = {
    turnOnOptIn: get(contentProps, 'turnOnOptIn', false),
    optInCopy: get(contentProps, 'optInCopy', '') || '',
    shareTitle: get(contentProps, 'shareTitle', '') || '',
    shareCopy: get(contentProps, 'shareCopy', '') || '',
  };

  const updateCompetitionAction = UpdateCompetition();

  const handleUpdateCompetition = useSubmitAction({
    submitAction: updateCompetitionAction,
    submitVariables: (values: any) => ({
      competitionId: get(contentProps, 'id', undefined),
      turnOnOptIn: values.turnOnOptIn,
      optInCopy: values.optInCopy,
      shareTitle: values.shareTitle,
      shareCopy: values.shareCopy,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.competitionSharing.form.successMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.competitionSharing.form.failureMessage',
    }),
    onSuccess: response => {
      navigateTo({
        routeName: 'competition-details',
        routeProps: {
          defaultOpenSection: 'sharing',
          ...response.data.updateCompetition.competition,
        },
      });
    },
  });

  return (
    <ModalContentContainer>
      <DetailsFormMainContainer>
        <DetailsHeader title={get(contentProps, 'title', '')} />
        <GenericForm
          formInitialValues={formInitialValues}
          renderFormComponent={(renderProps: any) => (
            <CompetitionSharingEditForm formikProps={renderProps.formikProps} />
          )}
          onSubmit={handleUpdateCompetition}
          formSchema={CompetitionSharingEditFormSchema(intl)}
          setFormSubmitAction={setFormSubmitAction}
          setDisplayConfirmation={setDisplayConfirmation}
          setIsSubmitting={setIsSubmitting}
          dataQaId="competition-sharing-section-edit-form"
        />
      </DetailsFormMainContainer>
    </ModalContentContainer>
  );
};

export default CompetitionSharingEdit;
