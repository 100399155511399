import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { get } from 'app/shared/utils/get';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import { Spinner } from 'app/shared/components/atoms/SpinnerManualCSS';
import { H3 } from 'app/shared/components/atoms/TypographyManualCSS';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { GetEventEmailPreviewContent } from 'app/admin/graphql/emails/queryHooks';
import { DetailsInfoMainContainer } from 'app/admin/components/atoms/DetailContent';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

interface Props {
  contentProps?: any;
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const EmailNameContainer = styled.div`
  padding: 25px 20px 15px 0px;
`;

const EmailName = styled(H3)`
  margin-bottom: 0px;
`;

const SubjectContainer = styled.div`
  font-size: 14px;
  padding: 15px 20px 25px 0px;
`;

const SubjectLabel = styled.span`
  font-weight: bold;
`;

const ContentContainer = styled.div`
  ${({ theme }) => css`
    padding: 0px 40px 20px 40px;
    background-color: ${theme.colors.silverSprings};
  `}
`;

const LoadingIndicatorContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 300px 30px 300px 30px;
  width: 100%;
`;

const LoadingIndicator: React.FC = () => {
  return (
    <LoadingIndicatorContainer>
      <Spinner />
    </LoadingIndicatorContainer>
  );
};

const LoadingErrorContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  white-space: normal;
  padding: 300px 30px 300px 30px;
  width: 100%;
`;

const LoadingError: React.FC = () => {
  const intl = useIntl();

  return (
    <LoadingErrorContainer>
      {intl.formatMessage(
        {
          id: 'admin.shared.modalLoadingError',
        },
        {
          whatsBeingLoaded: intl.formatMessage({
            id: 'admin.eventPlanner.emails.errorWhatsBeingLoaded',
          }),
        }
      )}
    </LoadingErrorContainer>
  );
};

const EventEmailPreviewContent: React.FC<Props> = ({ contentProps }) => {
  const intl = useIntl();

  const {
    eventId,
    cityTitle,
    neighborhoodTitle,
    localStartsAt,
    emailKey,
    emailName,
  } = contentProps;

  const { loading, error, data } = GetEventEmailPreviewContent({
    emailKey,
    eventId,
  });

  if (loading) {
    return <LoadingIndicator />;
  }

  if ((!loading && !data) || error) {
    return <LoadingError />;
  }

  const subject = get(data, 'eventEmailPreviewContent.subject', '');
  const content = get(data, 'eventEmailPreviewContent.content', '');

  return (
    <ModalContentContainer data-qaid="event-email-preview-content-modal">
      <DetailsInfoMainContainer>
        <DetailsHeader
          title={cityTitle}
          subTitle={neighborhoodTitle}
          loading={loading}
          imageStyle="calendar"
          detailsDate={localStartsAt}
        />
        <MainContainer>
          <EmailNameContainer>
            <EmailName>
              {intl.formatMessage(
                {
                  id: 'admin.eventPlanner.emails.emailName',
                },
                {
                  emailName,
                }
              )}
            </EmailName>
          </EmailNameContainer>
          <DottedLine />
          <SubjectContainer>
            <SubjectLabel>
              {intl.formatMessage({
                id: 'admin.shared.emails.subject',
              })}
              :
            </SubjectLabel>{' '}
            {subject}
          </SubjectContainer>
          <ContentContainer
            dangerouslySetInnerHTML={{ __html: content }}
          ></ContentContainer>
        </MainContainer>
      </DetailsInfoMainContainer>
    </ModalContentContainer>
  );
};

export default EventEmailPreviewContent;
