import React from 'react';

import { PromoCode } from 'app/typings';
import { Artist } from 'app/typings/artists';
import { Campaign } from 'app/typings/campaigns';
import { City } from 'app/typings/cities';
import { Competition } from 'app/typings/competitions';
import { Country } from 'app/typings/countries';
import { EventCollection } from 'app/typings/eventCollections';
import { Event, UserStaffedEvent } from 'app/typings/events';
import { User } from 'app/typings/users';
import { Venue } from 'app/typings/venues';
import { dateFormatter } from 'app/shared/utils/datetime';
import usePermission from 'app/shared/utils/usePermission';
import { GenericLink } from 'app/shared/components/atoms/LinkManualCSS';
import TruncatedByCharText from 'app/shared/components/atoms/TruncatedByCharText';
import { userFullNameOrEmail } from 'app/admin/utils/users';

type LinkableObjectType =
  | Artist
  | Campaign
  | Competition
  | Country
  | Event
  | EventCollection
  | PromoCode
  | User
  | Venue;

const renderTruncatedObjectText = (
  linkableObject: LinkableObjectType,
  renderObjectText: Function,
  truncateLength?: number
) => {
  return (
    <>
      {truncateLength ? (
        <TruncatedByCharText
          text={renderObjectText(linkableObject) || null}
          truncateLength={truncateLength}
        />
      ) : (
        renderObjectText(linkableObject)
      )}
    </>
  );
};

interface ObjectLinkProps {
  linkableObject: LinkableObjectType;
  url: string;
  permissionName: string;
  renderObjectText: Function;
  truncateLength?: number;
  textAfter?: string;
}

const ObjectLink: React.FC<ObjectLinkProps> = ({
  linkableObject,
  url,
  permissionName,
  renderObjectText,
  truncateLength,
  textAfter,
}) => {
  const hasPermissionToFollowLink = usePermission(permissionName);

  return (
    <>
      {hasPermissionToFollowLink ? (
        <div>
          <GenericLink url={`${url}=${linkableObject.id}`}>
            {renderTruncatedObjectText(
              linkableObject,
              renderObjectText,
              truncateLength
            )}
          </GenericLink>
          {textAfter && ` (${textAfter})`}
        </div>
      ) : (
        <div>
          {renderTruncatedObjectText(
            linkableObject,
            renderObjectText,
            truncateLength
          )}
        </div>
      )}
    </>
  );
};

const renderArtist = (artist: Artist) => artist.title;

interface ArtistLinkProps {
  artist: Artist;
  truncateLength?: number;
}

export const ArtistLink: React.FC<ArtistLinkProps> = ({
  artist,
  truncateLength,
}) => {
  return (
    <ObjectLink
      linkableObject={artist}
      url="/admin/artists?artist_id"
      permissionName="artist.list.view"
      renderObjectText={renderArtist}
      truncateLength={truncateLength}
    />
  );
};

const renderCampaign = (campaign: Campaign) => campaign.title;

interface CampaignLinkProps {
  campaign: Campaign;
  truncateLength?: number;
}

export const CampaignLink: React.FC<CampaignLinkProps> = ({
  campaign,
  truncateLength,
}) => {
  return (
    <ObjectLink
      linkableObject={campaign}
      url="/admin/campaigns?campaign_search"
      permissionName="campaign.access"
      renderObjectText={renderCampaign}
      truncateLength={truncateLength}
    />
  );
};

interface CityLinkProps {
  city: City;
  truncateLength?: number;
  renderCityName?: (city: City) => {};
  textAfter?: string;
}

const renderCity = (city: City) => city.title;

export const CityLink: React.FC<CityLinkProps> = ({
  city,
  truncateLength,
  renderCityName,
  textAfter,
}) => {
  const renderCityNameFunc = renderCityName || renderCity;

  return (
    <ObjectLink
      linkableObject={city}
      url="/admin/city-directory?city_id"
      permissionName="city.list.access"
      renderObjectText={renderCityNameFunc}
      truncateLength={truncateLength}
      textAfter={textAfter}
    />
  );
};

const renderCompetition = (competition: Competition) => competition.title;

interface CompetitionLinkProps {
  competition: Competition;
  truncateLength?: number;
}

export const CompetitionLink: React.FC<CompetitionLinkProps> = ({
  competition,
  truncateLength,
}) => {
  return (
    <ObjectLink
      linkableObject={competition}
      url="/admin/competitions?competition_id"
      permissionName="competition.access"
      renderObjectText={renderCompetition}
      truncateLength={truncateLength}
    />
  );
};

const renderCountry = (country: Country) => country.title;

interface CountryLinkProps {
  country: Country;
  truncateLength?: number;
}

export const CountryLink: React.FC<CountryLinkProps> = ({
  country,
  truncateLength,
}) => {
  return (
    <ObjectLink
      linkableObject={country}
      url="/admin/countries?country_id"
      permissionName="country.access"
      renderObjectText={renderCountry}
      truncateLength={truncateLength}
    />
  );
};

interface EventLinkProps {
  event: Event | UserStaffedEvent;
  truncateLength?: number;
  renderEventName?: (event: Event) => {};
  includeSearchQueryParam?: boolean;
}

const renderEvent = (event: Event) =>
  dateFormatter(event.localStartsAt, 'shortMonthDateAndYear');

export const EventLink: React.FC<EventLinkProps> = ({
  event,
  truncateLength,
  renderEventName,
  includeSearchQueryParam,
}) => {
  const url = includeSearchQueryParam
    ? `/admin/concert-planner?event_search=${event.id}&event_id`
    : '/admin/concert-planner?event_id';
  const renderEventNameFunc = renderEventName || renderEvent;

  return (
    <ObjectLink
      linkableObject={event}
      url={url}
      permissionName="event.list.view"
      renderObjectText={renderEventNameFunc}
      truncateLength={truncateLength}
    />
  );
};

const renderEventCollection = (eventCollection: EventCollection) =>
  eventCollection.title;

interface EventCollectionLinkProps {
  eventCollection: EventCollection;
  truncateLength?: number;
  textAfter?: string;
}

export const EventCollectionLink: React.FC<EventCollectionLinkProps> = ({
  eventCollection,
  truncateLength,
  textAfter,
}) => {
  return (
    <ObjectLink
      linkableObject={eventCollection}
      url="/admin/collections?event_collection_id"
      permissionName="eventCollection.access"
      renderObjectText={renderEventCollection}
      truncateLength={truncateLength}
      textAfter={textAfter}
    />
  );
};

const renderPromoCode = (promoCode: PromoCode) => promoCode.code;

interface PromoCodeLinkProps {
  promoCode: PromoCode;
  truncateLength?: number;
}

export const PromoCodeLink: React.FC<PromoCodeLinkProps> = ({
  promoCode,
  truncateLength,
}) => {
  return (
    <ObjectLink
      linkableObject={promoCode}
      url="/admin/promo-codes?promo_code_id"
      permissionName="promoCode.access"
      renderObjectText={renderPromoCode}
      truncateLength={truncateLength}
    />
  );
};

const renderUser = (user: User) => userFullNameOrEmail(user);

interface UserLinkProps {
  user: User;
  truncateLength?: number;
}

export const UserLink: React.FC<UserLinkProps> = ({ user, truncateLength }) => {
  return (
    <ObjectLink
      linkableObject={user}
      url="/admin/users?user_id"
      permissionName="user.list.access"
      renderObjectText={renderUser}
      truncateLength={truncateLength}
    />
  );
};

export const InternalUserLink: React.FC<UserLinkProps> = ({
  user,
  truncateLength,
}) => {
  return (
    <ObjectLink
      linkableObject={user}
      url="/admin/internal-users?user_id"
      permissionName="user.internalList.view"
      renderObjectText={renderUser}
      truncateLength={truncateLength}
    />
  );
};

const renderVenue = (venue: Venue) =>
  venue.venueName || venue.address || 'Venue';

interface VenueLinkProps {
  venue: Venue;
  truncateLength?: number;
}

export const VenueLink: React.FC<VenueLinkProps> = ({
  venue,
  truncateLength,
}) => {
  return (
    <ObjectLink
      linkableObject={venue}
      url="/admin/venues?venue_id"
      permissionName="venue.list.view"
      renderObjectText={renderVenue}
      truncateLength={truncateLength}
    />
  );
};
