import { get, getInteger } from 'app/shared/utils/get';

export const errorMsgForField = (field: string, touched: any, errors: any) =>
  get(touched, field, undefined) && get(errors, field, undefined)
    ? get(errors, field, undefined)
    : null;

export const refocusToFirstError = (errors: any, isSubmitting: boolean) => {
  const keys = Object.keys(errors);
  if (keys.length > 0 && isSubmitting) {
    const findFirstErrorElementName = (scopedErrors: any): any => {
      const errorKeys = Object.keys(scopedErrors);

      if (typeof scopedErrors[errorKeys[0]] === 'string') {
        return errorKeys[0];
      }
      return findFirstErrorElementName(scopedErrors[errorKeys[0]]);
    };

    const firstErrorElementName = findFirstErrorElementName(errors);
    const errorElement: HTMLElement | null = document.querySelector(
      `[name="${firstErrorElementName}"]`
    );
    if (errorElement) {
      errorElement.scrollIntoView && errorElement.scrollIntoView();
      errorElement.focus();
    }
  }
};

export const getCurrencyFormInitialValue = (data: any, path: string) => {
  const valueFromProps = getInteger(data, path, undefined);
  return valueFromProps || valueFromProps == 0
    ? (valueFromProps / 100).toFixed(2)
    : '';
};

export const getCurrencyRoundedFormInitialValue = (data: any, path: string) => {
  const valueFromProps = getInteger(data, path, undefined);
  return valueFromProps || valueFromProps == 0
    ? (valueFromProps / 100).toFixed(0)
    : '';
};

export const getCurrencySubmitVariable = (
  value: string,
  defaultValue?: number | null
) =>
  value
    ? Number(value) * 100
    : defaultValue || defaultValue == 0
    ? defaultValue
    : null;

export const getIdsSubmitVariable = (ids: number[] | undefined) =>
  ids && ids.length > 0
    ? ids.map((id: number) => String(id)).join(',')
    : undefined;
