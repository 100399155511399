import React, { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { get } from 'app/shared/utils/get';
import { ListPage, ListPageContext } from 'app/shared/context/ListPage';
import { LoadingBlocks } from 'app/shared/components/atoms/LoadingBlocks';
import { GlobalError } from 'app/shared/components/pages/Status';
import {
  getFilterNamesAndOptionsForTitleMapping,
  getVersionItemTypeOptions,
} from 'app/admin/utils/optionLists';
import {
  GetVersionItemTypes,
  GetVersionsLazy,
} from 'app/admin/graphql/versions/queryHooks';
import ListingFooter from 'app/admin/components/molecules/ListingFooter';
import ListingControls from 'app/admin/components/organisms/ListingControls';
import ListingFilter from 'app/admin/components/organisms/ListingFilter';
import ListingHeader from 'app/admin/components/organisms/ListingHeader';
import ListTable from 'app/admin/components/organisms/ListTable';
import ListPageTemplate from 'app/admin/components/templates/ListPage';
import Layout from 'app/admin/layouts/ListPage';

import { columnsConfig, pageStateConfig } from './config';
import { sortOptions } from './options';
import { getDropdownOptionsInfoList, rowValues } from './utils';

const NoVersions = styled.div`
  width: 100%;
`;

const HelpText = styled.div`
  width: 100%;
  margin-top: 10px;
`;

export const AdminVersions: React.FC = () => {
  const intl = useIntl();
  const pageState = useContext(ListPageContext);
  const PER_PAGE = 20;

  const {
    loading: loadingVersionItemTypes,
    error: errorVersionItemTypes,
    data: dataVersionItemTypes,
  } = GetVersionItemTypes();

  const [
    fetchVersions,
    {
      loading: loadingVersions,
      error: errorVersions,
      data: dataVersions,
      updateQuery,
    },
  ] = GetVersionsLazy();

  const versionItemTypeOptions = getVersionItemTypeOptions(
    dataVersionItemTypes
  );
  const dropdownOptionsInfoList = getDropdownOptionsInfoList(
    intl,
    versionItemTypeOptions
  );
  let versions = get(dataVersions, 'versions.versions', []);
  let totalRecords = get(
    dataVersions,
    'versions.metadata.totalRecords',
    undefined
  );

  useEffect(() => {
    pageState.setupFilterLabelTitleMapping(
      getFilterNamesAndOptionsForTitleMapping(dropdownOptionsInfoList)
    );
  }, [dataVersions]);

  useEffect(() => {
    pageState.updatePageUrl();
  }, [
    pageState.page,
    pageState.sortState,
    pageState.filterState,
    pageState.textSearchState,
  ]);

  const emptyDataVersions = () => ({
    versions: {
      versions: [],
      metadata: {
        totalRecords: 0,
        __typename: 'Metadata',
      },
      __typename: 'GetVersions',
    },
  });

  useEffect(() => {
    if (
      pageState.filterState.item_type.length > 0 ||
      pageState.textSearchState
    ) {
      // Must pass variables in here instead of above in getVersions() call, otherwise
      // Apollo internally re-calls fetchVersions() every time any state changes
      fetchVersions({
        variables: {
          versionSearch: pageState.textSearchState,
          itemType: pageState.filterListVariable('item_type'),
          event: pageState.filterListVariable('event'),
          date: pageState.filterListVariable('date'),
          orderBy: pageState.sortState.by,
          orderDirection: pageState.sortState.direction,
          page: pageState.page,
          perPage: PER_PAGE,
        },
      });
    } else if (dataVersions) {
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      updateQuery(prevDataVersions => emptyDataVersions());
    }
  }, [dataVersions, fetchVersions, pageState, updateQuery]);

  if (
    (!loadingVersionItemTypes && !dataVersionItemTypes) ||
    errorVersionItemTypes ||
    errorVersions
  ) {
    return <GlobalError />;
  }

  const noResultsContent = () => {
    if (
      pageState.filterState.item_type.length == 0 &&
      !pageState.textSearchState
    ) {
      return (
        <NoVersions>
          {intl.formatMessage({ id: 'admin.versions.pleaseFilter' })}
        </NoVersions>
      );
    } else if (!loadingVersions && versions.length === 0) {
      return (
        <NoVersions>
          {intl.formatMessage({ id: 'admin.versions.noVersions' })}
        </NoVersions>
      );
    }

    return null;
  };

  return (
    <Layout scrollDisabled={false}>
      <ListPageTemplate
        pageTitle={intl.formatMessage({
          id: 'admin.versions.pageTitle',
        })}
      >
        <ListingHeader
          pageTitle={intl.formatMessage({
            id: 'admin.versions.pageTitle',
          })}
          searchPlaceholder={intl.formatMessage({
            id: 'admin.versions.textSearch.placeholder',
          })}
          searchValue={pageState.textSearchState}
          onSearch={pageState.handleTextSearch}
          dataQaidPrefix="version"
        />
        <HelpText>
          Note: Event and EventStaffMember &lsquo;create&rsquo; events are
          currently <b>not</b> being recorded (due to a known issue with event
          creation).
        </HelpText>
        <ListingControls
          sortOptions={sortOptions}
          orderBy={pageState.sortState.by}
          orderDirection={pageState.sortState.direction}
          totalRecords={totalRecords}
          loading={loadingVersions}
          onSort={pageState.handleSortChange}
          onReset={pageState.handleResetFilters}
          dataQaidPrefix="version"
        />
        <ListingFilter
          filterTitle={intl.formatMessage({
            id: 'admin.versions.filterTitle',
          })}
          textSearchString={pageState.textSearchState}
          handleTextSearchLabelClose={pageState.handleTextSearchLabelClose}
          labelTitleMapping={pageState.filterLabelTitleMapping}
          dropdownOptionsInfoList={dropdownOptionsInfoList}
          filterState={pageState.filterState}
          handleRemoveFilter={pageState.handleRemoveFilter}
          handleFilterChange={pageState.handleFilterChange}
        />
        <ListTable
          columnsConfig={columnsConfig}
          data={versions}
          rowValues={rowValues}
          dataQaidPrefix="versions"
          loadingContent={
            loadingVersions ? (
              <LoadingBlocks.Rectangle width="100%" height="300px" />
            ) : null
          }
          noResultsContent={noResultsContent()}
          footerContent={
            <ListingFooter
              numTotalRecords={totalRecords}
              perPage={PER_PAGE}
              currentPage={pageState.page}
              onPageChange={pageState.handlePageChange}
              loading={loadingVersions}
              dataQaidPrefix="versions"
            />
          }
        />
      </ListPageTemplate>
    </Layout>
  );
};

const AdminVersionsWrapper: React.FC = () => (
  <ListPage config={pageStateConfig}>
    <AdminVersions />
  </ListPage>
);

export default AdminVersionsWrapper;
