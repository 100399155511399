import React, { useState } from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { Event } from 'app/typings/events';
import { get } from 'app/shared/utils/get';
import useModal from 'app/shared/utils/useModal';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import UpdateOptionsModal from 'app/shared/components/organisms/UpdateOptionsModal';
import { GetEventsForVenueEdit } from 'app/admin/graphql/events/queryHooks';
import { UpdateVenue } from 'app/admin/graphql/venues/mutationHooks';
import { DetailsFormMainContainer } from 'app/admin/components/atoms/DetailContent';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

import VenueFanExperienceEditForm from './VenueFanExperienceEditForm';
import VenueFanExperienceEditFormSchema from './VenueFanExperienceEditFormSchema';

const ErrorMessage = styled.div<any>`
  ${({ theme }) => css`
    width: 100%;
    color: ${theme.colors.redRedWine};
    font-size: ${theme.fontSizes.caption};
  `}
`;

interface Props {
  contentProps?: any;
  setFormSubmitAction: (func: Function) => void;
  setDisplayConfirmation: (set: boolean) => void;
  setIsSubmitting: (set: boolean) => void;
  navigateTo: (routeData: object) => void;
}

const fieldsRequiringUpdateOptions = [
  'externalVenueNotes',
  'inviteEmailMessage',
  'revealAddressEmailMessage',
  'seating',
];

const VenueFanExperienceEdit: React.FC<Props> = ({
  contentProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  navigateTo,
}) => {
  const intl = useIntl();
  const [submitData, setSubmitData] = useState({});
  const [changedFields, setChangedFields] = useState<string[]>([]);
  const [totalChangedFields, setTotalChangedFields] = useState<number>(0);
  const [updateOptionsModal, toggleUpdateOptionsModal] = useModal();
  const {
    error: errorEvents,
    loading: loadingEvents,
    data: dataEvents,
  } = GetEventsForVenueEdit({
    venueIds: contentProps.id.toString(),
    upcoming: true,
    page: 1,
    perPage: 1000,
  });

  const eventOptions = dataEvents?.events?.events || [];

  const formInitialValues = {
    externalVenueNotes: get(contentProps, 'externalVenueNotes', ''),
    inviteEmailMessage: get(contentProps, 'inviteEmailMessage', ''),
    revealAddressEmailMessage: get(
      contentProps,
      'revealAddressEmailMessage',
      ''
    ),
    seating: get(contentProps, 'seating.key', 'unknown'),
  };

  const updateVenueAction = UpdateVenue();

  const handleUpdateVenue = useSubmitAction({
    submitAction: updateVenueAction,
    submitVariables: (values: any) => ({
      venueId: contentProps.id,
      externalVenueNotes: values.externalVenueNotes,
      inviteEmailMessage: values.inviteEmailMessage,
      revealAddressEmailMessage: values.revealAddressEmailMessage,
      seating: values.seating,
      applyMethod: values.applyMethod,
      selectedValues: values.selectedValues,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.venueFanExperience.successMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.venueFanExperience.failureMessage',
    }),
    onSuccess: (response: any) => {
      navigateTo({
        routeName: 'venue-details',
        routeProps: {
          defaultOpenSection: 'fanExperience',
          ...response.data.updateVenue.venue,
        },
      });
    },
  });

  return (
    <ModalContentContainer>
      <DetailsFormMainContainer>
        <DetailsHeader
          title={get(contentProps, 'venueName', '')}
          imageUrl={get(contentProps, 'imageUrl', '')}
          largeImageUrl={get(contentProps, 'largeImageUrl', '')}
          imageStyle="landscape"
        />
        <GenericForm
          formInitialValues={formInitialValues}
          renderFormComponent={(renderProps: any) => (
            <VenueFanExperienceEditForm
              formikProps={renderProps.formikProps}
              setFormSubmitAction={setFormSubmitAction}
              setDisplayConfirmation={setDisplayConfirmation}
              setIsSubmitting={setIsSubmitting}
            />
          )}
          onSubmit={data => {
            const changedFieldsForUpdateOptions = fieldsRequiringUpdateOptions.filter(
              (field: string) => formInitialValues[field] !== data[field]
            );
            const numChangedFields = Object.keys(data).filter(
              field => formInitialValues[field] !== data[field]
            ).length;
            if (
              changedFieldsForUpdateOptions.length === 0 ||
              eventOptions.length === 0
            ) {
              handleUpdateVenue(data);
            } else {
              setIsSubmitting(false);
              setSubmitData(data);
              setChangedFields(changedFieldsForUpdateOptions);
              setTotalChangedFields(numChangedFields);
              return toggleUpdateOptionsModal();
            }
          }}
          formSchema={VenueFanExperienceEditFormSchema(intl)}
          dataQaId="venue-fan-experience-section-edit-form"
        />
        {updateOptionsModal.isShowing &&
          (errorEvents ? (
            <ErrorMessage data-qaid="venue-event-fan-experience-events-error">
              {intl.formatMessage({
                id: 'admin.citySettings.form.getEventsError',
              })}
            </ErrorMessage>
          ) : (
            <UpdateOptionsModal
              onCancel={() => updateOptionsModal.hide()}
              onConfirm={(data: any) => {
                setIsSubmitting(true);
                updateOptionsModal.hide();
                handleUpdateVenue({
                  ...submitData,
                  ...data,
                });
              }}
              changedFields={changedFields}
              modalType="venue"
              isLoading={loadingEvents}
              dataQaidPrefix="venue-event-fan-experience-options-modal"
              title={intl.formatMessage(
                {
                  id: 'admin.updateOptionsModal.applyChangesWarning',
                },
                {
                  updatedFields: changedFields.length,
                  totalFields: totalChangedFields,
                }
              )}
              options={contentProps.upcomingEvents.map((ev: Event) => ({
                title:
                  ev.localStartsAt &&
                  moment.utc(ev.localStartsAt).format('MMM DD YYYY hh:mmA'),
                value: ev.id,
              }))}
            />
          ))}
      </DetailsFormMainContainer>
    </ModalContentContainer>
  );
};

export default VenueFanExperienceEdit;
