import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { errorMsgForField } from 'app/shared/utils/form';
import { get } from 'app/shared/utils/get';
import { objectForObjectId } from 'app/shared/utils/object';
import {
  FormGroupContainer,
  FormSectionTitle,
} from 'app/shared/components/atoms/FormContent';
import GenericFormContainer from 'app/shared/components/atoms/GenericFormContainer';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import { GenericLink } from 'app/shared/components/atoms/LinkManualCSS';
import { LoadingError } from 'app/shared/components/atoms/LoadingError';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Textfield } from 'app/shared/components/atoms/Textfield';
import { Caption } from 'app/shared/components/atoms/TypographyManualCSS';
import FormGroup from 'app/shared/components/molecules/FormGroup';
import Select from 'app/shared/components/molecules/SelectManualCSS';
import { GetContinents } from 'app/admin/graphql/continents/queryHooks';
import EditFormLoadingBlocks from 'app/admin/components/atoms/EditFormLoadingBlocks';
import TitleByLocaleFields from 'app/admin/components/molecules/TitleByLocaleFields';

interface FormProps {
  formikProps: any;
  validationErrors?: object;
  isNewCountry?: boolean;
}

const AliasesTextfield = styled(Textfield)`
  display: block;
  width: 100%;
`;

const CoumtryCodeTextfield = styled(Textfield)`
  display: block;
  width: 70px;
`;

const CurrencyCodeTextfield = styled(Textfield)`
  display: block;
  width: 70px;
`;

const CurrencySymbolTextfield = styled(Textfield)`
  display: block;
  width: 80px;
`;

const ExchangeRateWithUsdTextfield = styled(Textfield)`
  display: block;
  width: 90px;
`;

const StyledCaption = styled(Caption)`
  ${({ theme }) => css`
    color: ${theme.colors.darkSmoke};
  `}
`;

const CountryCodeLookupNote = styled.div``;

const VerticalCenteredText = styled.div`
  font-size: 14px;
  font-style: italic;
`;

const CountryBasicInfoForm: React.FC<FormProps> = ({
  formikProps,
  validationErrors = {},
  isNewCountry = false,
}) => {
  const intl = useIntl();

  const {
    loading: loadingContinents,
    error: errorContinents,
    data: dataContinents,
  } = GetContinents();

  if (errorContinents) {
    return <LoadingError whatsBeingLoaded="this form" />;
  }

  if (loadingContinents || !dataContinents) {
    return <EditFormLoadingBlocks />;
  }

  const { setFieldValue, touched, errors, values } = formikProps;

  const continentForContinentId = (continentId: number | null) =>
    objectForObjectId(
      get(dataContinents, 'continents.continents', []),
      continentId
    );

  return (
    <GenericFormContainer dataQaId="country-about-section-edit-form">
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'shared.title',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <TitleByLocaleFields
          setFieldValue={setFieldValue}
          touched={touched}
          errors={errors}
          values={values}
          validationErrors={validationErrors}
          dataQaidPrefix="country-basic-info-form-title"
        />
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.countryBasicInfo.form.continent',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          label={intl.formatMessage({
            id: 'admin.countryBasicInfo.form.continent',
          })}
          data-qaid="country-basic-info-form-continent-formgroup"
          errorMsg={errorMsgForField('continentId', touched, errors)}
          required
        >
          <Select
            data-qaid="country-basic-info-form-continent-field"
            searchable
            getOptionLabel={(opt: any) => opt.title}
            defaultValue={continentForContinentId(values.continentId)}
            options={get(dataContinents, 'continents.continents', [])}
            placeholder={intl.formatMessage({
              id: 'admin.countryBasicInfo.form.continentPlaceholder',
            })}
            onChange={continent =>
              setFieldValue(
                'continentId',
                continent && continent.id ? continent.id : null
              )
            }
          />
        </FormGroup>
      </FormGroupContainer>
      {isNewCountry && (
        <>
          <FormSectionTitle>
            {intl.formatMessage({
              id: 'admin.countryBasicInfo.form.countryCode',
            })}
          </FormSectionTitle>
          <FormGroupContainer>
            <FormGroup
              data-qaid="country-basic-info-form-country-code-formgroup"
              label={intl.formatMessage({
                id: 'admin.countryBasicInfo.form.countryCode',
              })}
              errorMsg={
                errorMsgForField('countryCode', touched, errors) ||
                // @ts-ignore
                validationErrors.general_country_code
              }
              required
            >
              <CoumtryCodeTextfield
                data-qaid="country-basic-info-form-country-code-field"
                id="countryCode"
                name="countryCode"
                value={values.countryCode}
                onChange={(e: any) => {
                  setFieldValue('countryCode', e.target.value);
                }}
                maxLength={2}
              />
            </FormGroup>
            <StyledCaption>
              {intl.formatMessage({
                id: 'admin.countryBasicInfo.form.countryCodeNote',
              })}
              <CountryCodeLookupNote>
                (
                <GenericLink url="https://www.iso.org/obp/ui/#search/code/">
                  {intl.formatMessage({
                    id: 'admin.countryBasicInfo.form.lookUpCountryCode',
                  })}
                </GenericLink>
                )
              </CountryCodeLookupNote>
            </StyledCaption>
          </FormGroupContainer>
        </>
      )}
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.countryBasicInfo.form.aliases',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="country-basic-info-form-aliases-formgroup"
          label={intl.formatMessage({
            id: 'admin.countryBasicInfo.form.aliases',
          })}
          errorMsg={errorMsgForField('cachedSlug', touched, errors)}
        >
          <Grid>
            <Col lg={6} md={6} sm={6} xs={6} key={1}>
              <AliasesTextfield
                data-qaid="country-basic-info-form-aliases-field"
                id="aliases"
                name="aliases"
                value={values.aliases}
                onChange={(e: any) => {
                  setFieldValue('aliases', e.target.value);
                }}
                maxLength={100}
              />
            </Col>
            <Col lg={6} md={6} sm={6} xs={6} key={2}>
              <VerticalCenteredText>
                {intl.formatMessage({
                  id: 'admin.countryBasicInfo.form.aliasesNote',
                })}
              </VerticalCenteredText>
            </Col>
          </Grid>
        </FormGroup>
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.countryBasicInfo.form.currency',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="country-basic-info-form-currency-code-formgroup"
          label={intl.formatMessage({
            id: 'admin.countryBasicInfo.form.currencyCode',
          })}
          errorMsg={errorMsgForField('currency', touched, errors)}
        >
          <CurrencyCodeTextfield
            data-qaid="country-basic-info-form-currency-code-field"
            id="currency"
            name="currency"
            value={values.currency}
            onChange={(e: any) => {
              setFieldValue('currency', e.target.value);
            }}
            maxLength={3}
          />
        </FormGroup>
        <StyledCaption>
          {intl.formatMessage({
            id: 'admin.countryBasicInfo.form.currencyCodeNote',
          })}
        </StyledCaption>
        <Spacer mb={6} />
        <FormGroup
          data-qaid="country-basic-info-form-currency-symbol-formgroup"
          label={intl.formatMessage({
            id: 'admin.countryBasicInfo.form.currencySymbol',
          })}
          errorMsg={errorMsgForField('currencySymbol', touched, errors)}
        >
          <CurrencySymbolTextfield
            data-qaid="country-basic-info-form-currency-symbol-field"
            id="currencySymbol"
            name="currencySymbol"
            value={values.currencySymbol}
            onChange={(e: any) => {
              setFieldValue('currencySymbol', e.target.value);
            }}
            maxLength={4}
          />
        </FormGroup>
        <Spacer mb={6} />
        <FormGroup
          data-qaid="country-basic-info-form-exchange-rate-with-usd-formgroup"
          label={intl.formatMessage({
            id: 'admin.countryBasicInfo.form.exchangeRateWithUsd',
          })}
          errorMsg={errorMsgForField('exchangeRateWithUsd', touched, errors)}
        >
          <ExchangeRateWithUsdTextfield
            data-qaid="country-basic-info-form-exchange-rate-with-usd-field"
            id="exchangeRateWithUsd"
            name="exchangeRateWithUsd"
            value={values.exchangeRateWithUsd}
            onChange={(e: any) => {
              setFieldValue('exchangeRateWithUsd', e.target.value);
            }}
            maxLength={7}
          />
        </FormGroup>
      </FormGroupContainer>
    </GenericFormContainer>
  );
};

export default CountryBasicInfoForm;
