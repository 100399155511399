import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { Tag } from 'app/typings/tags';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import { BaseLink } from 'app/shared/components/atoms/LinkManualCSS';
import {
  DetailHeading as Heading,
  DetailSubheading as Subheading,
  DetailValueContainer as ValueContainer,
} from 'app/admin/components/atoms/DetailContent';
import ExpandableText from 'app/admin/components/atoms/ExpandableText';
import { None, NoneWrapper } from 'app/admin/components/atoms/None';
import {
  PromoCodeLink,
  VenueLink,
} from 'app/admin/components/molecules/EntityLink';
import { OtherContactInfos } from 'app/admin/components/molecules/OtherContactInfos';
import { PrimaryContactInfo } from 'app/admin/components/molecules/PrimaryContactInfo';
import { PropertyList } from 'app/admin/components/molecules/PropertyList';

interface Props {
  sectionData: any;
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const ValueLink = styled(BaseLink)`
  ${({ theme }) => css`
    color: ${theme.colors.primary};
  `}
`;

const VenueCategoryContainer = styled.div`
  display: block;
`;

const SeatingContainer = styled.ul`
  margin-top: 0px;
  margin-bottom: 0px;
  padding-left: 20px;
`;

const SeatingBulletPoint = styled.li`
  ${({ theme }) => css`
    font-size: 10px;
    color: ${theme.colors.green600};
    padding-left: 6px;
  `}
`;

const SeatingText = styled.span`
  ${({ theme }) => css`
    font-size: 14px;
    color: ${theme.colors.backToBlack};
  `}
`;

const VenueInfoNone = () => {
  const intl = useIntl();
  return (
    <NoneWrapper>
      {intl.formatMessage({
        id: 'admin.eventPlanner.venueInfo.notApplicable',
      })}
    </NoneWrapper>
  );
};

const SectionVenueInfo: React.FC<Props> = ({ sectionData }) => {
  const intl = useIntl();

  const { dataBasicInfo } = sectionData;
  const { event } = dataBasicInfo;

  return (
    <>
      <MainContainer data-qaid="section-event-venue">
        <Grid cols={4}>
          <Col xs={12} sm={12} md={1} lg={1} xl={1} key={1}>
            <Heading>
              {intl.formatMessage({
                id: 'admin.eventPlanner.venueInfo.name',
              })}
            </Heading>
            <ValueContainer data-qaid="event-venue-name">
              {event.venue?.venueName ? (
                <VenueLink venue={event.venue} truncateLength={30} />
              ) : (
                <NoneWrapper>
                  {intl.formatMessage({
                    id: 'admin.eventPlanner.venueInfo.notYetBooked',
                  })}
                </NoneWrapper>
              )}
            </ValueContainer>
            <Heading>
              {intl.formatMessage({
                id: 'shared.address',
              })}
            </Heading>
            <ValueContainer data-qaid="event-venue-address">
              {event.venue ? (
                <ValueLink
                  href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                    event.venue.address
                  )}`}
                  openInNewTab={true}
                >
                  {event.venue.address}
                </ValueLink>
              ) : (
                <VenueInfoNone />
              )}
            </ValueContainer>
            <Heading>
              {intl.formatMessage({
                id: 'admin.eventPlanner.venueInfo.primaryContact',
              })}
            </Heading>
            <ValueContainer data-qaid="event-venue-contactInfo">
              {event.venue ? (
                <PrimaryContactInfo contactInfos={event.venue?.contactInfos} />
              ) : (
                <VenueInfoNone />
              )}
            </ValueContainer>
            <Heading>
              {intl.formatMessage({
                id: 'admin.venueBasicInfo.otherContacts',
              })}
            </Heading>
            <ValueContainer>
              <OtherContactInfos contactInfos={event.venue?.contactInfos} />
            </ValueContainer>
            <Heading>
              {intl.formatMessage({
                id: 'admin.shared.venueType',
              })}
            </Heading>
            <ValueContainer data-qaid="event-venue-type">
              {event.venue?.venueCategories?.length ? (
                event.venue.venueCategories.map(
                  (venueCategory: Tag, key: any) => (
                    <VenueCategoryContainer key={key}>
                      {venueCategory.name}
                    </VenueCategoryContainer>
                  )
                )
              ) : (
                <VenueInfoNone />
              )}
            </ValueContainer>
            <Heading>
              {intl.formatMessage({
                id: 'shared.capacity',
              })}
            </Heading>
            <ValueContainer>
              {event.venue?.capacity ? (
                event.venue?.capacity
              ) : (
                <VenueInfoNone />
              )}
            </ValueContainer>
            <Heading
              title={intl.formatMessage({
                id: 'admin.shared.promoCode',
              })}
            >
              {intl.formatMessage({
                id: 'admin.shared.promoCode',
              })}
            </Heading>
            <ValueContainer data-qaid="promo-code">
              {event.venue?.promoCodes?.length > 0 &&
              event.venue?.promoCodes[0] ? (
                <PromoCodeLink
                  promoCode={event.venue?.promoCodes[0]}
                  truncateLength={40}
                />
              ) : (
                <None />
              )}
            </ValueContainer>
          </Col>
          <Col xs={12} sm={12} md={1} lg={1} xl={1} key={2}>
            <Heading>
              {intl.formatMessage({
                id: 'admin.shared.characteristics',
              })}
            </Heading>
            <ValueContainer>
              <PropertyList properties={event.properties} />
            </ValueContainer>
            <>
              <Heading>
                {intl.formatMessage({
                  id: 'admin.shared.checkInProcess',
                })}
              </Heading>
              <ValueContainer>
                {event.venue?.checkInProcessNotes ? (
                  <ExpandableText
                    allowHTML
                    text={event.venue?.checkInProcessNotes}
                    truncateLength={120}
                  />
                ) : (
                  <VenueInfoNone />
                )}
              </ValueContainer>
              <Heading>
                {intl.formatMessage({
                  id: 'admin.venueEventSetup.loadInNotes',
                })}
              </Heading>
              <ValueContainer>
                {event.venue?.loadInNotes ? (
                  <ExpandableText
                    allowHTML
                    text={event.venue?.loadInNotes}
                    truncateLength={120}
                  />
                ) : (
                  <VenueInfoNone />
                )}
              </ValueContainer>
              <Heading>
                {intl.formatMessage({
                  id: 'admin.venueEventSetup.stageSetup',
                })}
              </Heading>
              <ValueContainer>
                {event.venue?.stageSetupNotes ? (
                  <ExpandableText
                    allowHTML
                    text={event.venue?.stageSetupNotes}
                    truncateLength={120}
                  />
                ) : (
                  <VenueInfoNone />
                )}
              </ValueContainer>
              <Heading>
                {intl.formatMessage({
                  id: 'admin.shared.suppliesNeeded',
                })}
              </Heading>
              <ValueContainer>
                {event.suppliesNeededNotes ? (
                  <ExpandableText
                    allowHTML
                    text={event.suppliesNeededNotes}
                    truncateLength={120}
                  />
                ) : (
                  <VenueInfoNone />
                )}
              </ValueContainer>
            </>
          </Col>
          <Col xs={12} sm={12} md={1} lg={1} xl={1} key={3}>
            <Heading>
              {intl.formatMessage({
                id: 'admin.eventPlanner.venueInfo.fanFacingDescription',
              })}
            </Heading>
            <Subheading>
              {intl.formatMessage({
                id: 'admin.venueFanExperience.fanFacingDescriptionSubheader',
              })}
            </Subheading>
            <ValueContainer>
              {event.externalVenueNotes ? (
                <ExpandableText
                  allowHTML
                  text={event.externalVenueNotes}
                  truncateLength={120}
                />
              ) : (
                <VenueInfoNone />
              )}
            </ValueContainer>
            <>
              <Heading>
                {intl.formatMessage({
                  id: 'admin.shared.addressRevealEmail',
                })}
              </Heading>
              <ValueContainer>
                {event.revealAddressEmailMessage ? (
                  <ExpandableText
                    allowHTML
                    text={event.revealAddressEmailMessage}
                    truncateLength={120}
                  />
                ) : (
                  <VenueInfoNone />
                )}
              </ValueContainer>
              <Heading>
                {intl.formatMessage({
                  id: 'admin.shared.inviteEmail',
                })}
              </Heading>
              <ValueContainer>
                {event.inviteEmailMessage ? (
                  <ExpandableText
                    allowHTML
                    text={event.inviteEmailMessage}
                    truncateLength={120}
                  />
                ) : (
                  <VenueInfoNone />
                )}
              </ValueContainer>
            </>
            <Heading>
              {intl.formatMessage({
                id: 'admin.shared.seating',
              })}
            </Heading>
            <ValueContainer>
              <SeatingContainer>
                <SeatingBulletPoint>
                  <SeatingText>
                    {event.seating && event.seating.key ? (
                      event.seating.name
                    ) : (
                      <NoneWrapper>
                        {intl.formatMessage({
                          id: 'unknown',
                        })}
                      </NoneWrapper>
                    )}
                  </SeatingText>
                </SeatingBulletPoint>
              </SeatingContainer>
            </ValueContainer>
            <>
              <Heading>
                {intl.formatMessage({
                  id: 'admin.shared.internalSeatingNotes',
                })}
              </Heading>
              <ValueContainer>
                {event.seatingNotes ? (
                  <ExpandableText
                    allowHTML
                    text={event.seatingNotes}
                    truncateLength={120}
                  />
                ) : (
                  <VenueInfoNone />
                )}
              </ValueContainer>
            </>
          </Col>
          <Col xs={12} sm={12} md={1} lg={1} xl={1} key={4}>
            <>
              <Heading>
                {intl.formatMessage({
                  id: 'admin.shared.notesForMc',
                })}
              </Heading>
              <ValueContainer>
                {event.mcNotes ? (
                  <ExpandableText
                    allowHTML
                    text={event.mcNotes}
                    truncateLength={120}
                  />
                ) : (
                  <VenueInfoNone />
                )}
              </ValueContainer>
              <Heading>
                {intl.formatMessage({
                  id: 'admin.shared.paInfo',
                })}
              </Heading>
              <ValueContainer>
                {event.venue && event.maxPaInputs.name ? (
                  event.maxPaInputs.name
                ) : (
                  <VenueInfoNone />
                )}
              </ValueContainer>
              <Heading>
                {intl.formatMessage({
                  id: 'admin.shared.audioNotes',
                })}
              </Heading>
              <ValueContainer>
                {event.audioNotes ? (
                  <ExpandableText
                    allowHTML
                    text={event.audioNotes}
                    truncateLength={120}
                  />
                ) : (
                  <VenueInfoNone />
                )}
              </ValueContainer>
              <Heading>
                {intl.formatMessage({
                  id: 'admin.eventPlanner.venueInfo.otherInternalNotes',
                })}
              </Heading>
              <ValueContainer data-qaid="event-internal-venue-notes">
                {event.internalVenueNotes ? (
                  <ExpandableText
                    allowHTML
                    text={event.internalVenueNotes}
                    truncateLength={120}
                  />
                ) : (
                  <VenueInfoNone />
                )}
              </ValueContainer>
            </>
          </Col>
        </Grid>
      </MainContainer>
    </>
  );
};

export default SectionVenueInfo;
