import styled from 'styled-components';

interface HeaderRowContainerProps {
  width: string;
}

interface HeaderRowCellProps {
  width: string;
  centered?: boolean;
}

interface RowContainerProps {
  width: string;
  shaded: boolean;
}

interface RowCellProps {
  width: string;
  centered?: boolean;
}

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const HeaderRowContainer = styled.div<HeaderRowContainerProps>`
  display: flex;
  flex-direction: row;
  width: ${props => props.width};
  padding: 8px 10px 8px 12px;
  margin-bottom: 12px;
`;

export const HeaderRowCell = styled.div<HeaderRowCellProps>`
  min-width: ${props => props.width};
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  ${props => props.centered && 'text-align: center;'};
`;

export const RowContainer = styled.div<RowContainerProps>`
  display: flex;
  flex-direction: row;
  width: ${props => props.width};
  padding: 8px 10px 8px 12px;
  margin-bottom: 7px;
  background-color: ${props => (props.shaded ? '#f6f6f6' : '#ffffff')};
`;

export const RowCell = styled.div<RowCellProps>`
  min-width: ${props => props.width};
  font-size: 14px;
  white-space: nowrap;
  ${props => props.centered && 'text-align: center;'};
`;
