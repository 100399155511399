export const sortOptions = [
  {
    title: 'Most Recently Submitted',
    value: {
      by: 'created_at',
      direction: 'desc',
    },
  },
  {
    title: 'Least Recently Submitted',
    value: {
      by: 'created_at',
      direction: 'asc',
    },
  },
  {
    title: 'Artist Name (A - Z)',
    value: {
      by: 'name',
      direction: 'asc',
    },
  },
  {
    title: 'Artist Name (Z - A)',
    value: {
      by: 'name',
      direction: 'desc',
    },
  },
];

export const dateOptions = () => {
  const opts = [
    {
      title: 'Today',
      value: 'today',
    },
    {
      title: 'Past 7 Days',
      value: 'past_7_days',
    },
    {
      title: 'Past 30 Days',
      value: 'past_30_days',
    },
  ];
  return opts;
};
