import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { City, Neighborhood } from 'app/typings';
import { errorMsgForField } from 'app/shared/utils/form';
import { get } from 'app/shared/utils/get';
import { venueSocialUrls } from 'app/shared/utils/socialLinks';
import { useGoogleMapsApi } from 'app/shared/utils/useGoogleMapsApi';
import { GetCitiesLite } from 'app/shared/graphql/cities/queryHooks';
import { GetContactRelationships } from 'app/shared/graphql/contactRelationships/queryHooks';
import { GetProperties } from 'app/shared/graphql/properties/queryHooks';
import { Checkbox } from 'app/shared/components/atoms/CheckboxManualCSS';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import { FormHelpText } from 'app/shared/components/atoms/FormContent';
import {
  FormGroupContainer,
  FormSectionTitle,
} from 'app/shared/components/atoms/FormContent';
import GenericFormContainer from 'app/shared/components/atoms/GenericFormContainer';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import { LoadingError } from 'app/shared/components/atoms/LoadingError';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Textfield } from 'app/shared/components/atoms/Textfield';
import {
  Body2,
  Caption,
} from 'app/shared/components/atoms/TypographyManualCSS';
import CheckboxGroup from 'app/shared/components/molecules/CheckboxGroup';
import ContactInfoFieldGroup from 'app/shared/components/molecules/ContactInfoFieldGroup';
import FormGroup from 'app/shared/components/molecules/FormGroup';
import PlaceSelect from 'app/shared/components/molecules/PlaceSelect';
import Select from 'app/shared/components/molecules/SelectManualCSS';
import CitySelectorManualCSS from 'app/shared/components/organisms/CitySelectorManualCSS';
import { CityInfoForLimitingByCity } from 'app/admin/utils/cityPermissions';
import {
  getMaxPaInputsOptions,
  getPropertyOptions,
} from 'app/admin/utils/optionLists';
import { getTagOptions } from 'app/admin/utils/optionLists';
import { GetTags } from 'app/admin/graphql/tags/queryHooks';
import EditFormLoadingBlocks from 'app/admin/components/atoms/EditFormLoadingBlocks';
import CheckboxGroupGrouped from 'app/admin/components/molecules/CheckboxGroupGrouped';
import NeighborhoodSelector from 'app/admin/components/molecules/NeighborhoodSelector';
import RichTextEditor from 'app/admin/components/molecules/RichTextEditor';
import SocialLinkFormFields from 'app/admin/components/molecules/SocialLinkFormFields';
import { NumberTextfield } from 'app/admin/components/molecules/Textfield';

const MAX_DISTANCE_TO_SOFAR_CITY = 50;

interface FormProps {
  formikProps: any;
  setFormSubmitAction?: (func: Function) => void;
  setDisplayConfirmation?: (set: boolean) => void;
  setIsSubmitting?: (set: boolean) => void;
  numContactInfos?: number;
  isNewVenue?: boolean;
}

const StyledTextfield = styled(Textfield)`
  ${({ theme }) => css`
    display: block;
    width: 100%;
    ${theme.media.lg`
        width: 48%;
      `};
  `}
`;

const StyledCaption = styled(Caption)`
  ${({ theme }) => css`
    color: ${theme.colors.darkSmoke};
  `}
`;

const VenueBasicInfoForm: React.FC<FormProps> = ({
  formikProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  numContactInfos,
  isNewVenue,
}) => {
  const intl = useIntl();

  useEffect(() => {
    setFormSubmitAction && setFormSubmitAction(() => formikProps.submitForm);
    setDisplayConfirmation && setDisplayConfirmation(formikProps.dirty);
    setIsSubmitting && setIsSubmitting(formikProps.isSubmitting);
  }, [
    formikProps.isSubmitting,
    formikProps.submitForm,
    formikProps.dirty,
    setFormSubmitAction,
    setDisplayConfirmation,
    setIsSubmitting,
  ]);

  useGoogleMapsApi({ libraries: ['places'] });

  const getCitiesLiteResponse = GetCitiesLite();

  const {
    loading: loadingProperties,
    error: errorProperties,
    data: dataProperties,
  } = GetProperties({
    propertyContext: 'Venue',
  });

  const { loading: loadingTags, error: errorTags, data: dataTags } = GetTags({
    tagContext: 'Venue',
  });

  const {
    loading: loadingCategories,
    error: errorCategories,
    data: dataCategories,
  } = GetTags({
    tagContext: 'VenueCategory',
  });

  const {
    loading: loadingContactRelationships,
    error: errorContactRelationships,
    data: dataContactRelationships,
  } = GetContactRelationships({ contactableType: 'Venue' });

  const { setFieldValue, touched, errors, values } = formikProps;

  const { cityIdsToLimitBy, countryCodesToLimitBy } = CityInfoForLimitingByCity(
    'venue.list.viewByCity',
    getCitiesLiteResponse.data
  );

  if (
    getCitiesLiteResponse.error ||
    errorTags ||
    errorContactRelationships ||
    errorProperties ||
    errorCategories
  ) {
    return <LoadingError whatsBeingLoaded="this form" />;
  }

  if (
    getCitiesLiteResponse.loading ||
    !getCitiesLiteResponse.data ||
    loadingProperties ||
    !dataProperties ||
    loadingTags ||
    !dataTags ||
    loadingContactRelationships ||
    !dataContactRelationships ||
    loadingCategories ||
    !dataCategories
  ) {
    return <EditFormLoadingBlocks />;
  }

  const handleAddressSelect = (place: any) => {
    if (
      place.closestSofarCityId &&
      place.distanceFromSofarCity <= MAX_DISTANCE_TO_SOFAR_CITY
    ) {
      const sofarCities = get(getCitiesLiteResponse, 'data.cities.cities', []);
      setFieldValue(
        'city',
        sofarCities.find((city: City) => city.id === place.closestSofarCityId)
      );
      setFieldValue('neighborhood', null);
    }
    setFieldValue('address', place.homeCityDescription);
    setFieldValue('latitude', place.homeCityLatitude);
    setFieldValue('longitude', place.homeCityLongitude);
    setFieldValue('mapUrl', place.url);
  };

  const propertyOptionDependencies = {
    // This option -> is depended on by this list of options
    commercial: ['residential'],
    residential: ['commercial'],
    accessible: ['not_accessible'],
    not_accessible: ['accessible'],
    alcohol_not_permitted_or_sold: ['byob', 'alcohol_for_sale', 'no_red_wine'],
    smoking_permitted: ['smoking_not_permitted'],
    smoking_not_permitted: ['smoking_permitted'],
    free_public_parking_nearby: ['no_parking'],
    paid_parking: ['no_parking'],
    no_parking: ['free_public_parking_nearby', 'paid_parking'],
    over_25_only: ['over_18_only', 'over_21_only', 'over_23_only'],
    over_23_only: ['over_18_only', 'over_21_only', 'over_25_only'],
    over_21_only: ['over_18_only', 'over_23_only', 'over_25_only'],
    over_18_only: ['over_21_only', 'over_23_only', 'over_25_only'],
    face_mask_required: ['face_mask_not_required'],
    face_mask_not_required: ['face_mask_required'],
  };

  const maxPaInputsOptions = getMaxPaInputsOptions(intl);

  const maxPaInputsOptionsMap = Object.fromEntries(
    maxPaInputsOptions.map(opt => [opt.value, opt])
  );

  return (
    <GenericFormContainer dataQaId="venue-about-section-edit-form">
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.venueBasicInfo.venueName',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="venue-basic-info-form-name-formgroup"
          label={intl.formatMessage({
            id: 'admin.venueBasicInfo.venueName',
          })}
          errorMsg={errorMsgForField('venueName', touched, errors)}
        >
          <StyledTextfield
            data-qaid="venue-basic-info-form-name-field"
            id="venueName"
            name="venueName"
            value={values.venueName}
            onChange={(e: any) => {
              setFieldValue('venueName', e.target.value);
            }}
            maxLength={200}
          />
        </FormGroup>
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.venueBasicInfo.venueLocation',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <Grid>
          <Col xs={12} lg={6}>
            <PlaceSelect
              errorMsg={errorMsgForField('address', touched, errors)}
              placeType={['establishment', 'point_of_interest']}
              label={intl.formatMessage({
                id: 'shared.address',
              })}
              name="address"
              initialValue={values.address}
              cities={getCitiesLiteResponse}
              countryCodes={countryCodesToLimitBy || []}
              onChange={handleAddressSelect}
              placeholder="Venue Address"
              noSofarCityNearPlaceMessage={`There is no Sofar city within ${MAX_DISTANCE_TO_SOFAR_CITY}km of this address`}
              formGroupDataQaid="venue-about-section-edit-form-address"
              inputDataQaid="venue-about-section-edit-form-address-field"
              required
            />
            {values.address && (
              <StyledCaption>
                {intl.formatMessage(
                  {
                    id: 'admin.venueBasicInfo.selectedAddress',
                  },
                  {
                    address: values.address,
                  }
                )}
              </StyledCaption>
            )}
            <Spacer mb={6} />
            <FormGroup
              label={intl.formatMessage({
                id: 'admin.venueBasicInfo.address2',
              })}
              data-qaid="venue-basic-info-form-address-2-formgroup"
              errorMsg={errorMsgForField('address2', touched, errors)}
            >
              <Textfield
                data-qaid="venue-basic-info-form-venue-address-2-form-field"
                id="address2"
                name="address2"
                value={values.address2}
                placeholder={intl.formatMessage({
                  id: 'admin.venueBasicInfo.address2Placeholder',
                })}
                maxLength={80}
                onChange={(e: any) => setFieldValue('address2', e.target.value)}
              />
            </FormGroup>
            <Spacer mb={6} />
            <FormGroup
              data-qaid="venue-basic-info-form-city-formgroup"
              label={intl.formatMessage({
                id: 'admin.venueBasicInfo.city',
              })}
              errorMsg={errorMsgForField('city', touched, errors)}
              required
            >
              <CitySelectorManualCSS
                parentContainer="venue-basic-info-form"
                disableTopCities
                disableClosestCities
                initialValue={values.city}
                value={values.city}
                data-qaid="venue-basic-info-form-city-selector"
                onChange={(city: City) => {
                  setFieldValue('city', city);
                  if (values.neighborhood) {
                    setFieldValue('neighborhood', null);
                  }
                }}
                cityIds={cityIdsToLimitBy}
              />
            </FormGroup>
            <Spacer mb={6} />
            <FormGroup
              data-qaid="venue-basic-info-form-neighborhood-formgroup"
              label={intl.formatMessage({
                id: 'shared.neighborhood',
              })}
              errorMsg={errorMsgForField('neighborhood', touched, errors)}
            >
              <NeighborhoodSelector
                city={values.city}
                initialValue={values.neighborhood}
                value={values.neighborhood}
                data-qaid="venue-basic-info-form-neighborhood-selector"
                onChange={(neighborhood: Neighborhood) =>
                  setFieldValue('neighborhood', neighborhood)
                }
              />
            </FormGroup>
            <Spacer mb={6} />
            <FormGroup
              data-qaid="venue-basic-info-form-closest-station-formgroup"
              label={intl.formatMessage({
                id: 'admin.venueBasicInfo.closestStation',
              })}
            >
              <Textfield
                data-qaid="venue-basic-info-form-closet-station-field"
                id="closestStation"
                name="closestStation"
                value={values.closestStation}
                onChange={(e: any) => {
                  setFieldValue('closestStation', e.target.value);
                }}
                maxLength={200}
              />
            </FormGroup>
          </Col>
        </Grid>
        <Spacer mb={8} />
        <DottedLine />
        <Spacer mb={6} />
        <FormGroup
          data-qaid="venue-basic-info-form-street-view-url-formgroup"
          label={intl.formatMessage({
            id: 'admin.venueBasicInfo.streetViewUrl',
          })}
          errorMsg={errorMsgForField('streetViewUrl', touched, errors)}
        >
          <StyledTextfield
            data-qaid="venue-basic-info-form-street-view-url-field"
            id="streetViewUrl"
            name="streetViewUrl"
            value={values.streetViewUrl}
            onChange={(e: any) => {
              setFieldValue('streetViewUrl', e.target.value);
            }}
            maxLength={200}
          />
        </FormGroup>
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'shared.capacity',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <Grid>
          <Col xs={12} lg={6}>
            <FormGroup
              label={intl.formatMessage({
                id: 'admin.shared.ticketsAvailable',
              })}
              data-qaid="venue-basic-info-form-tickets-available-for-sale-formgroup"
              errorMsg={errorMsgForField(
                'numTicketsAvailableForSale',
                touched,
                errors
              )}
              required
            >
              <NumberTextfield
                data-qaid="venue-basic-info-form-tickets-available-for-sale-form-field"
                id="tickets-available-for-sale"
                name="numTicketsAvailableForSale"
                value={values.numTicketsAvailableForSale}
                maxLength={4}
                onChange={(e: any) =>
                  setFieldValue('numTicketsAvailableForSale', e.target.value)
                }
              />
            </FormGroup>
            <StyledCaption>
              {intl.formatMessage({
                id: 'admin.venueBasicInfo.maxNumberOfGuests',
              })}
            </StyledCaption>
            <Spacer mb={6} />
            <FormGroup
              label={intl.formatMessage({
                id: 'admin.venueBasicInfo.capacityOfVenue',
              })}
              data-qaid="venue-basic-info-form-capacity-formgroup"
              errorMsg={errorMsgForField('capacity', touched, errors)}
              required
            >
              <NumberTextfield
                data-qaid="venue-basic-info-form-capacity-form-field"
                id="capacity"
                name="capacity"
                value={values.capacity}
                maxLength={4}
                onChange={(e: any) => setFieldValue('capacity', e.target.value)}
              />
            </FormGroup>
            <Spacer mb={6} />
            <FormGroup
              label={intl.formatMessage({
                id: 'admin.venueBasicInfo.numGuestTickets',
              })}
              data-qaid="venue-basic-info-form-num-guest-tickets-formgroup"
              errorMsg={errorMsgForField('numGuestTickets', touched, errors)}
              required
            >
              <NumberTextfield
                data-qaid="venue-basic-info-form-num-guest-tickets-form-field"
                id="numGuestTickets"
                name="numGuestTickets"
                value={values.numGuestTickets}
                maxLength={4}
                onChange={(e: any) =>
                  setFieldValue('numGuestTickets', e.target.value)
                }
              />
            </FormGroup>
          </Col>
        </Grid>
      </FormGroupContainer>
      {isNewVenue && (
        <>
          <FormSectionTitle>
            {intl.formatMessage({
              id: 'admin.shared.paRequired',
            })}
          </FormSectionTitle>
          <FormGroupContainer>
            <FormGroup
              data-qaid="max-pa-inputs-formgroup"
              label={intl.formatMessage({
                id: 'admin.shared.typeOfPa',
              })}
              errorMsg={errorMsgForField('maxPaInputs', touched, errors)}
              required
            >
              <Select
                onChange={(opt: any) => setFieldValue('maxPaInputs', opt.value)}
                placeholder={intl.formatMessage({
                  id: 'admin.shared.selectPa',
                })}
                options={maxPaInputsOptions}
                getOptionLabel={(opt: any) => opt.title}
                value={maxPaInputsOptionsMap[values.maxPaInputs]}
                initialWidth="150px"
              />
            </FormGroup>
          </FormGroupContainer>
        </>
      )}
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.venueBasicInfo.venueFee',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <Grid>
          <Col xs={12} lg={8}>
            <FormGroup
              label={intl.formatMessage({
                id: 'admin.venueBasicInfo.doesTheVenueHaveAFee',
              })}
              data-qaid="venue-basic-info-form-venue-fee-present-formgroup"
              errorMsg={errorMsgForField('venueFeePresent', touched, errors)}
            >
              <Checkbox
                id="venue-basic-info-form-venue-fee-present-form-field"
                data-qaid="venue-basic-info-form-venue-fee-present-form-field"
                checked={values.venueFeePresent}
                name="venueFeePresent"
                onChange={e =>
                  setFieldValue('venueFeePresent', e.target.checked)
                }
              >
                {intl.formatMessage({
                  id: 'yes',
                })}
              </Checkbox>
            </FormGroup>
            <Spacer mb={6} />
            <FormGroup
              label={intl.formatMessage({
                id: 'admin.venueBasicInfo.fee',
              })}
              data-qaid="venue-basic-info-form-fee-description-formgroup"
              errorMsg={errorMsgForField(
                'venueFeeDescription',
                touched,
                errors
              )}
              required={!!values.venueFeePresent}
            >
              <StyledTextfield
                data-qaid="venue-basic-info-form-venue-fee-description-form-field"
                id="venueFeeDescription"
                name="venueFeeDescription"
                value={values.venueFeeDescription}
                placeholder={intl.formatMessage({
                  id: 'admin.venueBasicInfo.feePlaceholder',
                })}
                maxLength={200}
                onChange={(e: any) =>
                  setFieldValue('venueFeeDescription', e.target.value)
                }
                disabled={!values.venueFeePresent}
              />
            </FormGroup>
          </Col>
        </Grid>
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.shared.characteristics',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <CheckboxGroupGrouped
          name="properties"
          options={getPropertyOptions(dataProperties, true)}
          optionDependencies={propertyOptionDependencies}
          selectedValues={values.properties}
          onChange={(values: any) => setFieldValue('properties', values)}
          groupBy="propertyGroupName"
          groupNames={get(dataProperties, 'properties.propertyGroupNames', [])}
        />
        {!isNewVenue && (
          <>
            <Spacer mb={6} />
            <FormGroup
              data-qaid="venue-basic-info-form-parking-notes-formgroup"
              label={intl.formatMessage({
                id: 'admin.venueBasicInfo.internalParkingNotes',
              })}
              errorMsg={errorMsgForField('parkingNotes', touched, errors)}
            >
              <RichTextEditor
                name="parkingNotes"
                currentValue={values.parkingNotes}
                onChange={(value: any) => setFieldValue('parkingNotes', value)}
              />
            </FormGroup>
          </>
        )}
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.shared.venueType',
        })}
      </FormSectionTitle>
      <FormHelpText>
        {intl.formatMessage({
          id: 'admin.venueBasicInfo.visibleToFansNote',
        })}
      </FormHelpText>
      <FormGroupContainer>
        <CheckboxGroup
          name="venue-categories"
          options={getTagOptions(dataCategories, true, true)}
          selectedValues={values.venueCategories}
          onChange={values => setFieldValue('venueCategories', values)}
        />
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.venueBasicInfo.venueTags',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <CheckboxGroupGrouped
          name="tags"
          options={getTagOptions(dataTags, true)}
          groupBy="tagGroupName"
          groupNames={get(dataTags, 'tags.tagGroupNames', [])}
          selectedValues={values.tags}
          onChange={values => setFieldValue('tags', values)}
        />
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.venueBasicInfo.contactInfo',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <ContactInfoFieldGroup
          name="primary"
          title={intl.formatMessage({
            id: 'shared.primaryContact',
          })}
          values={values.contactInfos.primaryContactInfo}
          setFieldValue={setFieldValue}
          errorMsgForField={errorMsgForField}
          touched={touched}
          errors={errors}
          contactRelationships={
            // @ts-ignore
            // eslint-disable-next-line prettier/prettier
                dataContactRelationships.contactRelationships.contactRelationships
          }
          dataQaidPrefix="venue"
        />
        <ContactInfoFieldGroup
          name="secondary"
          title={
            intl.formatMessage({
              id: 'admin.venueBasicInfo.contact',
            }) + ' 2'
          }
          values={values.contactInfos.secondaryContactInfo}
          setFieldValue={setFieldValue}
          errorMsgForField={errorMsgForField}
          touched={touched}
          errors={errors}
          contactRelationships={
            // @ts-ignore
            // eslint-disable-next-line prettier/prettier
                dataContactRelationships.contactRelationships.contactRelationships
          }
          dataQaidPrefix="venue"
        />
        <ContactInfoFieldGroup
          name="tertiary"
          title={
            intl.formatMessage({
              id: 'admin.venueBasicInfo.contact',
            }) + ' 3'
          }
          values={values.contactInfos.tertiaryContactInfo}
          setFieldValue={setFieldValue}
          errorMsgForField={errorMsgForField}
          touched={touched}
          errors={errors}
          contactRelationships={
            // @ts-ignore
            // eslint-disable-next-line prettier/prettier
                dataContactRelationships.contactRelationships.contactRelationships
          }
          last={true}
          dataQaidPrefix="venue"
        />
        {numContactInfos && numContactInfos > 3 && (
          <>
            <Spacer mb={6} />
            <Body2>
              {intl.formatMessage({
                id: 'admin.venueBasicInfo.moreContactsExistForThisVenue',
              })}
            </Body2>
          </>
        )}
      </FormGroupContainer>
      <SocialLinkFormFields
        setFieldValue={setFieldValue}
        touched={touched}
        errors={errors}
        values={values}
        socialLinkKeys={venueSocialUrls}
        dataQaidPrefix="venue-basic-info-form"
      />
    </GenericFormContainer>
  );
};

export default VenueBasicInfoForm;
