import React from 'react';

import { Artist } from 'app/typings/artists';
import usePermission from 'app/shared/utils/usePermission';
import CircleThumbnailAndText from 'app/admin/components/molecules/CircleThumbnailAndText';

interface ArtistCircleThumbnailAndTextProps {
  artist: Artist;
  description?: string;
  onRemove?: (artist: Artist) => void;
}

const ArtistCircleThumbnailAndText: React.FC<ArtistCircleThumbnailAndTextProps> = ({
  artist,
  description,
  onRemove,
}) => {
  const hasViewArtistsPermission = usePermission('artist.list.view');

  return (
    <CircleThumbnailAndText
      data-qaid="selected-artist"
      imageUrl={artist.imageUrl}
      text={artist.title}
      subtext={artist.homeCityDescription}
      description={description}
      textTruncateLength={28}
      subtextTruncateLength={28}
      textSize="14px"
      linkUrl={
        hasViewArtistsPermission
          ? `/admin/artists?artist_id=${artist.id}`
          : undefined
      }
      titleText=""
      onRemove={
        onRemove
          ? () => {
              onRemove(artist);
            }
          : undefined
      }
    />
  );
};

export default ArtistCircleThumbnailAndText;
