import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { City, EventCollection } from 'app/typings';
import { buildEventCollectionUrl } from 'app/shared/utils/eventCollections';
import { GenericLink } from 'app/shared/components/atoms/LinkManualCSS';
import { None } from 'app/admin/components/atoms/None';

interface Props {
  sectionData: EventCollection;
}

const MainContainer = styled.div`
  width: 100%;
`;

const UrlTitle = styled.p`
  margin-top: 20px;
  font-size: 14px;
  font-weight: 600;
`;

const LinkContainer = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
`;

const renderCityUrls = (collectionSlug: string, cities?: City[]) => {
  if (cities && cities.length > 0 && collectionSlug) {
    return cities
      .sort((cityA, cityB) => {
        return cityA.cachedSlug < cityB.cachedSlug ? -1 : 1;
      })
      .map((city: City) => {
        const url = buildEventCollectionUrl({
          citySlug: city.cachedSlug,
          collectionSlug,
        });
        return (
          <div key={city.cachedSlug}>
            <LinkContainer>
              <GenericLink url={url}>{url}</GenericLink>
            </LinkContainer>
          </div>
        );
      });
  } else {
    return <None />;
  }
};

const renderPastSlugs = (collectionSlugs: string[]) => {
  return (collectionSlugs || []).map((collectionSlug: string) => (
    <LinkContainer key={collectionSlug}>{`/${collectionSlug}`}</LinkContainer>
  ));
};

const SectionUrls: React.FC<Props> = ({ sectionData }) => {
  const intl = useIntl();

  if (!sectionData.cachedSlug) {
    return <None />;
  }

  const globalUrl = buildEventCollectionUrl({
    collectionSlug: sectionData.cachedSlug,
  });
  return (
    <MainContainer data-qaid="section-urls">
      <UrlTitle data-qaid="global">
        {intl.formatMessage({
          id: 'admin.collections.section.urls.global',
        })}
      </UrlTitle>
      <LinkContainer>
        <GenericLink url={globalUrl}>{globalUrl}</GenericLink>
      </LinkContainer>
      <UrlTitle data-qaid="cities">
        {intl.formatMessage({
          id: 'admin.collections.section.urls.cities',
        })}
      </UrlTitle>
      {renderCityUrls(sectionData.cachedSlug, sectionData.cities)}
      {sectionData.pastCachedSlugs && sectionData.pastCachedSlugs.length > 0 && (
        <>
          <UrlTitle data-qaid="cities">
            {intl.formatMessage({
              id: 'admin.collections.section.urls.oldUrls',
            })}
          </UrlTitle>
          {renderPastSlugs(sectionData.pastCachedSlugs)}
        </>
      )}
    </MainContainer>
  );
};

export default SectionUrls;
