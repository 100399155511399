import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { EventAttendee } from 'app/typings/eventAttendees';
import { Event } from 'app/typings/events';
import { KeyValue } from 'app/typings/generics';
import { VipAttendee } from 'app/typings/vipAttendees';
import { useCurrentTheme } from 'app/shared/theme';
import { currencyFormatter } from 'app/shared/utils/currencyFormatter';
import useModal from 'app/shared/utils/useModal';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import { ToolTip, ToolTipContainer } from 'app/shared/components/atoms/ToolTip';
import {
  Body2,
  Overline,
} from 'app/shared/components/atoms/TypographyManualCSS';
import ConfirmationModal from 'app/shared/components/molecules/ConfirmationModal';
import InfoBox from 'app/shared/components/molecules/Flyover';
import {
  eventAttendeeStatuses,
  getStatus,
  vipAttendeeFullName,
} from 'app/admin/utils/guestlist';
import { DeleteVipAttendee } from 'app/admin/graphql/vipAttendees/mutationHooks';
import IconLabel from 'app/admin/components/molecules/IconLabel';
import { KeyAboveValueInfoList } from 'app/admin/components/molecules/KeyAboveValueInfoList';
import { KeyValueInfoList } from 'app/admin/components/molecules/KeyValueInfoList';
import GuestlistAttendeeActions from 'app/admin/components/organisms/GuestlistAttendeeActions';
import GuestlistAttendeeDetails from 'app/admin/components/organisms/GuestlistAttendeeDetails';
import GuestlistAttendeeStatus from 'app/admin/components/organisms/GuestlistAttendeeStatus';
import GuestlistVipEdit from 'app/admin/components/organisms/GuestlistVipEdit/GuestlistVipEdit';
import ListingCard from 'app/admin/components/organisms/ListingCard';
import { ReactComponent as Bin1 } from 'icons/streamline-regular/interface-essential/delete/bin-1.svg';
import { ReactComponent as Pencil } from 'icons/streamline-regular/interface-essential/edit/pencil.svg';
import { ReactComponent as SynchronizeArrowsSquare2 } from 'icons/streamline-regular/interface-essential/synchronize/synchronize-arrows-square-2.svg';
import { ReactComponent as SingleManActionsInformation } from 'icons/streamline-regular/users/geometric-full-body-single-user-actions-man/single-man-actions-information.svg';

interface GuestlistCardProps {
  id: number;
  index: number;
  name: string;
  email: string;
  eventAttendee?: EventAttendee;
  vipAttendee?: VipAttendee;
  event?: Event;
  refetchEvent?: Function;
  refetchAttendees?: Function;
  modalRef?: any;
}

interface StatusProps {
  eventAttendee?: EventAttendee;
  infoBoxContent?: any;
  intl?: any;
}

const TopContentContainer = styled.div`
  height: 350px;
`;

const KeyValueInfoListContainer = styled.div`
  height: auto;
`;

const StatusContainer = styled.div`
  margin-bottom: 13px;
`;

const StatusPlainValue = styled(Body2)`
  font-size: 12px;
  color: #111111;
  background: #f5f5f5;
  white-space: normal;
  height: 49px;
`;

const renderKeyValueInfo = (keysAndValuesList: KeyValue[] | undefined) => {
  if (keysAndValuesList && keysAndValuesList.length > 0) {
    return (
      <KeyValueInfoListContainer data-qaid="guestlist-card-key-value-info">
        <KeyValueInfoList keysAndValues={keysAndValuesList} />
      </KeyValueInfoListContainer>
    );
  }
  return null;
};

const statusColors = {
  applied: 'blueChristmas',
  guestlisted: 'mellowYellow',
  confirmed: 'purplePills',
  cant_go: 'redRedWine',
  expired: 'darkSmoke',
};

const statusIcons = {
  applied: 'cog', // TODO: Change to real icon, cogQuestionMark, and fix it not displaying
  guestlisted: 'timeHand', // TODO: Change to real icon, timeClockCircle, and fix it not displaying
  confirmed: 'check',
  cant_go: 'close',
  expired: 'calendar',
};

const Status: React.FC<StatusProps> = ({
  eventAttendee,
  infoBoxContent,
  intl,
}) => {
  const [flyoverClosed, setFlyoverClosed] = useState(false);

  const status = eventAttendee ? getStatus(eventAttendee.currentState) : '';
  const statusText = eventAttendeeStatuses[status];
  const statusColor = statusColors[status];
  const statusIcon = statusIcons[status];

  return status ? (
    <>
      <ToolTipContainer>
        <InfoBox
          zIndex={102}
          showCaret={false}
          width="300px"
          triggerElement={
            <div data-qaid="status-icon-label">
              <IconLabel
                color={statusColor}
                icon={statusIcon}
                draft={undefined}
                invertIcon
                labelText={statusText}
              />
            </div>
          }
          keepInViewPort={{
            vertical: true,
            horizontal: true,
          }}
          onStateChange={isOpen => setFlyoverClosed(!isOpen)}
          shouldFlyoverClose={flyoverClosed}
          innerContent={infoBoxContent(setFlyoverClosed)}
        />
        <ToolTip>{statusText}</ToolTip>
      </ToolTipContainer>
    </>
  ) : (
    <StatusPlainValue>
      {intl.formatMessage({ id: 'admin.notApplicable' })}
    </StatusPlainValue>
  );
};

const renderTopContent = (
  keysAboveValuesList1: KeyValue[],
  keysAboveValuesList2: KeyValue[],
  keysAndValuesList: KeyValue[],
  eventAttendee: EventAttendee | undefined,
  GuestStatusContent: any,
  intl: any
) => {
  return (
    <TopContentContainer>
      <KeyAboveValueInfoList
        keysAndValues={keysAboveValuesList1}
        valueTruncateCharLength={20}
      />
      <StatusContainer>
        <Overline>
          {intl.formatMessage({
            id: 'shared.status',
          })}
        </Overline>
        <Status
          eventAttendee={eventAttendee}
          infoBoxContent={GuestStatusContent}
          intl={intl}
        />
      </StatusContainer>
      {renderKeyValueInfo(keysAndValuesList)}
      <KeyAboveValueInfoList
        keysAndValues={keysAboveValuesList2}
        valueTruncateCharLength={55}
      />
    </TopContentContainer>
  );
};

const GuestlistCard: React.FC<GuestlistCardProps> = ({
  index,
  name,
  email,
  eventAttendee,
  vipAttendee,
  event,
  refetchEvent,
  refetchAttendees,
  modalRef,
}) => {
  const intl = useIntl();
  const theme = useCurrentTheme();
  const [vipDeleteModal, toggleVipDeleteModal] = useModal();

  const GuestStatusContent = (setFlyoverClosed: Function) => (
    <GuestlistAttendeeStatus
      eventAttendeeId={eventAttendee && Number(eventAttendee.id)}
      event={event}
      onSuccess={() => {
        setFlyoverClosed(true);
        refetchEvent && refetchEvent();
        refetchAttendees && refetchAttendees();
      }}
    />
  );

  const GuestEditVipContent = (setFlyoverClosed: Function) => (
    <GuestlistVipEdit
      vipAttendeeId={vipAttendee && Number(vipAttendee.id)}
      onSuccess={() => {
        setFlyoverClosed(true);
        refetchEvent && refetchEvent();
        refetchAttendees && refetchAttendees();
      }}
    />
  );

  const GuestDetailsContent = () => (
    <GuestlistAttendeeDetails
      eventAttendeeId={eventAttendee && Number(eventAttendee.id)}
      event={event}
    />
  );

  const GuestActionsContent = (setFlyoverClosed: Function) => (
    <GuestlistAttendeeActions
      eventAttendeeId={eventAttendee && Number(eventAttendee.id)}
      event={event}
      onSuccess={() => {
        setFlyoverClosed(true);
        refetchEvent && refetchEvent();
        refetchAttendees && refetchAttendees();
      }}
    />
  );

  const keysAboveValuesList1 = [
    {
      key: intl.formatMessage({
        id: 'shared.email',
      }),
      value:
        email ||
        intl.formatMessage({
          id: 'unknown',
        }),
    },
  ];

  const eventAttendeeTicketsCount =
    eventAttendee && eventAttendee.ticketsCount
      ? eventAttendee.ticketsCount.toString()
      : '';

  const vipAttendeeTicketsCount =
    vipAttendee && vipAttendee.ticketsCount
      ? vipAttendee.ticketsCount.toString()
      : '';

  const keysAndValuesList = [
    {
      key: intl.formatMessage({
        id: 'admin.guestlist.listingCard.tickets',
      }),
      value: eventAttendeeTicketsCount || vipAttendeeTicketsCount,
    },
    {
      key: intl.formatMessage({
        id: 'admin.guestlist.listingCard.paid',
      }),
      value:
        eventAttendee && event && event.attendeeFlow == 'buy'
          ? currencyFormatter(
              eventAttendee.totalTicketCost || 0,
              (event && event.currencySymbol) || ''
            )
          : intl.formatMessage({ id: 'admin.notApplicable' }),
    },
    {
      key: intl.formatMessage({
        id: 'admin.guestlist.listingCard.transferred',
      }),
      value: eventAttendee
        ? eventAttendee.isTransferred
          ? intl.formatMessage({ id: 'yes' })
          : intl.formatMessage({ id: 'no' })
        : intl.formatMessage({ id: 'admin.notApplicable' }),
    },
    {
      key: intl.formatMessage({
        id: 'admin.guestlist.listingCard.code',
      }),
      value: eventAttendee
        ? eventAttendee.appliedPromoCodeCode
          ? eventAttendee.appliedPromoCodeCode
          : intl.formatMessage({ id: 'none' })
        : intl.formatMessage({ id: 'admin.notApplicable' }),
      valueTruncateCharLength: 11,
    },
    {
      key: intl.formatMessage({
        id: 'shared.city',
      }),
      value: eventAttendee
        ? eventAttendee.homeCityTitle
          ? eventAttendee.homeCityTitle
          : intl.formatMessage({ id: 'none' })
        : intl.formatMessage({ id: 'admin.notApplicable' }),
    },
    {
      key: intl.formatMessage({
        id: 'admin.guestlist.listingCard.eventAttendeeId',
      }),
      value:
        (eventAttendee && eventAttendee.id) ||
        intl.formatMessage({ id: 'admin.notApplicable' }),
    },
    {
      key: intl.formatMessage({
        id: 'admin.guestlist.listingCard.confirmationNumber',
      }),
      value:
        (eventAttendee && eventAttendee.externalConfirmationId) ||
        intl.formatMessage({ id: 'admin.notApplicable' }),
    },
  ];

  const eventAttendeeNote = eventAttendee?.generalNotes;
  const vipAttendeeNote = vipAttendee?.reference;

  const keysAboveValuesList2 = [
    {
      key: intl.formatMessage({
        id: 'admin.guestlist.listingCard.note',
      }),
      value:
        eventAttendeeNote ||
        vipAttendeeNote ||
        intl.formatMessage({ id: 'none' }),
      wrapValue: true,
    },
  ];

  const controlsInfoList = vipAttendee
    ? [
        {
          infoBoxContent: GuestEditVipContent,
          icon: Pencil,
          tipText: intl.formatMessage({
            id: 'admin.guestlist.listingCard.vipEdit',
          }),
        },
        {
          action: toggleVipDeleteModal,
          icon: Bin1,
          tipText: intl.formatMessage({
            id: 'admin.guestlist.listingCard.vipDelete',
          }),
        },
      ]
    : [
        {
          infoBoxContent: GuestDetailsContent,
          icon: SingleManActionsInformation,
          tipText: intl.formatMessage({
            id: 'admin.guestlist.listingCard.guestDetails',
          }),
        },
        {
          infoBoxContent: GuestActionsContent,
          icon: SynchronizeArrowsSquare2,
          tipText: intl.formatMessage({
            id: 'admin.guestlist.listingCard.guestActions',
          }),
        },
      ];

  const listingCardTopTag = vipAttendee
    ? {
        tagElementColor: theme.colors.paintItBlack,
        name: 'VIP',
      }
    : undefined;

  const vipAttendeeName = vipAttendeeFullName(vipAttendee, intl);

  const deleteVipAttendeeAction = DeleteVipAttendee();

  const handleDeleteVipAttendee = useSubmitAction({
    submitAction: deleteVipAttendeeAction,
    submitVariables: () => ({ vipAttendeeId: vipAttendee && vipAttendee.id }),
    successMsg: intl.formatMessage(
      {
        id: 'admin.guestlist.listingCard.deleteVipSuccessMessage',
      },
      {
        name:
          name ||
          intl.formatMessage({
            id: 'unknown',
          }),
      }
    ),
    failureMsg: intl.formatMessage({
      id: 'admin.guestlist.listingCard.deleteVipFailureMessage',
    }),
    onSuccess: () => {
      vipDeleteModal.hide();
      refetchEvent && refetchEvent();
      refetchAttendees && refetchAttendees();
    },
  });

  return (
    <>
      <ListingCard
        listingCardTopTag={listingCardTopTag}
        title={
          name ||
          intl.formatMessage({
            id: 'unknown',
          })
        }
        titleTruncateCharLength={14}
        topContent={() =>
          renderTopContent(
            keysAboveValuesList1,
            keysAboveValuesList2,
            keysAndValuesList,
            eventAttendee,
            GuestStatusContent,
            intl
          )
        }
        dataQaidPrefix="guestlist"
        index={index}
        height="450px"
        modalRef={modalRef}
        controlsInfoList={controlsInfoList}
      />
      {vipDeleteModal.isShowing && (
        <ConfirmationModal
          onCancel={() => vipDeleteModal.hide()}
          description={intl.formatMessage(
            {
              id: 'admin.guestlist.listingCard.deleteVipConfirmation',
            },
            {
              name: vipAttendeeName,
            }
          )}
          onConfirm={handleDeleteVipAttendee}
        />
      )}
    </>
  );
};

export default GuestlistCard;
