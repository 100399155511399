import React from 'react';
import { useIntl } from 'react-intl';

import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { CreateNeighborhood } from 'app/admin/graphql/neighborhoods/mutationHooks';
import { DetailsFormMainContainer } from 'app/admin/components/atoms/DetailContent';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

import NeighborhoodBasicInfoForm from './NeighborhoodBasicInfoForm';
import NeighborhoodBasicInfoFormSchema from './NeighborhoodBasicInfoFormSchema';

interface Props {
  contentProps?: any;
  setFormSubmitAction?: (func: Function) => void;
  setDisplayConfirmation?: (set: boolean) => void;
  setIsSubmitting?: (set: boolean) => void;
  navigateTo: (routeData: object) => void;
}

const NeighborhoodCreate: React.FC<Props> = ({
  contentProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  navigateTo,
}) => {
  const intl = useIntl();

  const formInitialValues = {
    title: '',
  };

  const createNeighborhoodAction = CreateNeighborhood();

  const handleCreateNeighborhood = useSubmitAction({
    submitAction: createNeighborhoodAction,
    submitVariables: (values: any) => ({
      cityId: contentProps.city.id,
      title: values.title,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.neighborhoodCreate.successMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.neighborhoodCreate.failureMessage',
    }),
    onSuccess: () => {
      navigateTo({
        routeName: 'city-details',
        routeProps: {
          defaultOpenSection: 'neighborhoods',
          id: contentProps.city.id,
        },
      });
    },
  });

  return (
    <ModalContentContainer>
      <DetailsFormMainContainer>
        <DetailsHeader
          title={intl.formatMessage({
            id: 'admin.neighborhoodCreate.title',
          })}
          isNewForm={true}
        />
        <GenericForm
          formInitialValues={formInitialValues}
          renderFormComponent={(renderProps: any) => (
            <NeighborhoodBasicInfoForm formikProps={renderProps.formikProps} />
          )}
          onSubmit={handleCreateNeighborhood}
          formSchema={NeighborhoodBasicInfoFormSchema(intl)}
          setFormSubmitAction={setFormSubmitAction}
          setDisplayConfirmation={setDisplayConfirmation}
          setIsSubmitting={setIsSubmitting}
          dataQaId="neighborhood-create-form"
        />
      </DetailsFormMainContainer>
    </ModalContentContainer>
  );
};

export default NeighborhoodCreate;
