import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { EventCollection } from 'app/typings';
import { dateFormatter } from 'app/shared/utils/datetime';
import { capitalize } from 'app/shared/utils/string';
import usePermission from 'app/shared/utils/usePermission';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import { GenericLink } from 'app/shared/components/atoms/LinkManualCSS';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import ImageList from 'app/shared/components/molecules/ImageList';
import { getCurrentImageForEachRatio } from 'app/admin/utils/images';
import AddIconAndText from 'app/admin/components/atoms/AddIconAndText';
import {
  DetailHeading as Heading,
  DetailSmallHeading as SmallHeading,
  DetailSmallValue as SmallValue,
  DetailSubheading as Subheading,
  DetailValueContainer as ValueContainer,
} from 'app/admin/components/atoms/DetailContent';
import ExpandableText from 'app/admin/components/atoms/ExpandableText';
import { None } from 'app/admin/components/atoms/None';
import { CityLink, UserLink } from 'app/admin/components/molecules/EntityLink';
import MerchandisingPropertyGroups from 'app/admin/components/molecules/MerchandisingPropertyGroups';

interface Props {
  sectionData: EventCollection;
  callbacks: { [name: string]: VoidFunction };
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const ImageListContainer = styled.div`
  margin-bottom: 10px;
`;

const UpcomingEventsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const UpcomingEventsCount = styled.div``;

const UpcomingEventsLink = styled.div`
  margin-left: 10px;
`;

const SectionBasicInfo: React.FC<Props> = ({ sectionData, callbacks }) => {
  const intl = useIntl();

  const hasViewEventsPermission = usePermission('event.list.view');

  const imagesForDisplay = getCurrentImageForEachRatio(
    sectionData.images?.header
  );

  const renderCollectionCities = () => {
    if (sectionData.cities && sectionData.cities.length > 0) {
      return sectionData.cities
        .sort((cityA, cityB) => {
          return cityA.cachedSlug < cityB.cachedSlug ? -1 : 1;
        })
        .map(city => {
          return <CityLink city={city} key={city.cachedSlug} />;
        });
    } else {
      return <None />;
    }
  };

  return (
    <>
      <MainContainer data-qaid="section-basic-info">
        <Grid cols={4}>
          <Col xs={12} sm={12} md={1} lg={1} xl={1} key={1}>
            <Heading>
              {intl.formatMessage({
                id: 'admin.collection.details.section.images',
              })}
            </Heading>
            <ValueContainer>
              <ImageListContainer>
                {imagesForDisplay.length > 0 ? (
                  <ImageList images={imagesForDisplay} />
                ) : (
                  <None />
                )}
              </ImageListContainer>
              <AddIconAndText
                text={intl.formatMessage({
                  id: 'admin.collection.details.section.images.photo',
                })}
                textSizeType="small"
                onClick={callbacks.toggleImageEditModal}
                dataQaid="add-collection-image-button"
              />
            </ValueContainer>
          </Col>
          <Col xs={12} sm={12} md={1} lg={1} xl={1} key={2}>
            <Heading>
              {intl.formatMessage({
                id: 'admin.collection.details.section.cities.concerts',
              })}
            </Heading>
            <ValueContainer>{renderCollectionCities()}</ValueContainer>
            <Heading>
              {intl.formatMessage({
                id: 'admin.collection.details.section.concerts',
              })}
            </Heading>
            <ValueContainer>{sectionData.eventsCount}</ValueContainer>
            <Heading>
              {intl.formatMessage({
                id: 'admin.collection.details.section.upcomingConcerts',
              })}
            </Heading>
            <ValueContainer>
              <UpcomingEventsContainer>
                <UpcomingEventsCount>
                  {sectionData.upcomingEventsCount}
                </UpcomingEventsCount>
                <UpcomingEventsLink>
                  {hasViewEventsPermission && (
                    <GenericLink
                      url={`/admin/concert-planner?event_collection=${sectionData.cachedSlug}`}
                    >
                      {intl.formatMessage({
                        id:
                          'admin.collection.details.section.viewInConcertPlanner',
                      })}
                    </GenericLink>
                  )}
                </UpcomingEventsLink>
              </UpcomingEventsContainer>
            </ValueContainer>
            <Heading>
              {intl.formatMessage({
                id: 'admin.collection.details.section.collectionStatus',
              })}
            </Heading>
            <ValueContainer>
              {capitalize(sectionData.status || '')}
            </ValueContainer>
            <Heading>
              {intl.formatMessage({
                id: 'admin.collection.details.section.collectionUrls',
              })}
            </Heading>
            <ValueContainer>/{sectionData.cachedSlug}</ValueContainer>
            <Heading>
              {intl.formatMessage({
                id: 'admin.collection.details.section.createdBy',
              })}
            </Heading>
            <ValueContainer>
              {sectionData.createdBy && (
                <UserLink user={sectionData.createdBy} truncateLength={30} />
              )}
              <ValueContainer>
                {sectionData.createdAt &&
                  dateFormatter(sectionData.createdAt, 'longMonthDateAndYear')}
              </ValueContainer>
            </ValueContainer>
          </Col>
          <Col xs={12} sm={12} md={1} lg={1} xl={1} key={3}>
            <Heading>
              {intl.formatMessage({
                id: 'admin.collection.details.section.descriptiveText',
              })}
            </Heading>
            <Subheading>
              {intl.formatMessage({
                id:
                  'admin.collection.details.section.descriptiveTextSubheading',
              })}
            </Subheading>
            <ExpandableText
              allowHTML
              text={sectionData.description}
              truncateLength={120}
            />
            <Heading>
              {intl.formatMessage({
                id: 'admin.collection.details.section.whatToExpect',
              })}
            </Heading>
            <Subheading>
              {intl.formatMessage({
                id: 'admin.collection.details.section.whatToExpectSubheading',
              })}
            </Subheading>
            <ExpandableText
              allowHTML
              text={sectionData.whatToExpect}
              truncateLength={120}
            />
          </Col>
          <Col xs={12} sm={12} md={1} lg={1} xl={1} key={4}>
            <Heading>
              {intl.formatMessage({
                id: 'admin.collection.details.section.concertMerchandising',
              })}
            </Heading>
            <Subheading>
              {intl.formatMessage({
                id:
                  'admin.collection.details.section.concertMerchandisingSubheading',
              })}
            </Subheading>
            <Spacer mb={6} />
            <ValueContainer>
              <SmallHeading>
                {intl.formatMessage({
                  id: 'admin.collection.details.section.concertHeadline',
                })}
              </SmallHeading>
              <SmallValue>{sectionData.eventHeadline || <None />}</SmallValue>
              <SmallHeading>
                {intl.formatMessage({
                  id: 'admin.collection.details.section.concertDescription',
                })}
              </SmallHeading>
              <SmallValue>
                {sectionData.eventDescription ? (
                  <ExpandableText
                    allowHTML
                    text={sectionData.eventDescription}
                    truncateLength={120}
                  />
                ) : (
                  <None />
                )}
              </SmallValue>
              <MerchandisingPropertyGroups
                merchandisingProperties={sectionData.merchandisingProperties}
              />
            </ValueContainer>
          </Col>
        </Grid>
      </MainContainer>
    </>
  );
};

export default SectionBasicInfo;
