import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { ParentMenu } from 'app/typings/navbar';
import { useIsTShirtSizeBreakpointSize as useIsBreakpointSize } from 'app/shared/utils/useBreakpoint';
import { useNavbarContext } from 'app/shared/context';
import { FlexDivWithSpacing } from 'app/shared/components/atoms/FlexDivWithSpacing';
import { Logo } from 'app/shared/components/atoms/LogoManualCSS';
import {
  IconButton,
  StyledStreamlineIcon,
} from 'app/shared/components/molecules/IconButtonManualCSS';
import { LinkMenuMobile } from 'app/admin/components/molecules/LinkMenuManualCSS/LinkMenuMobile';
import { ReactComponent as DeleteIcon } from 'icons/streamline-regular/interface-essential/delete/delete-1.svg';

const MobileContainer = styled(FlexDivWithSpacing)<{
  isMobileMenuOpen: boolean;
}>`
  ${({ theme, isMobileMenuOpen }) => css`
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100%;
    overflow: hidden;
    z-index: ${theme.zIndex.navbar - 1};
    overflow-y: scroll;
    transition: transform 0.5s ease;
    transform: translateX(100%);
    animation-duration: 0.3s;
    background-color: ${theme.colors.backToBlack};

    ${isMobileMenuOpen &&
      css`
        transform: translateX(0%);
      `}
  `}
`;

const LogoContainer = styled(FlexDivWithSpacing)`
  ${({ theme }) => css`
    width: ${theme.ruler[22]}px;
  `}
`;

interface Props {
  mobileNavMenuData: any;
  isAdmin?: boolean;
}

export const MobileNav: React.FC<Props> = ({
  mobileNavMenuData,
  isAdmin = false,
}) => {
  const { isMobileMenuOpen, setIsMobileMenuOpen } = useNavbarContext();
  const [activeParentIndex, setActiveParentIndex] = useState<number>();
  const [activeChildIndex, setActiveChildIndex] = useState<number>();
  const [activeIndicesInitialized, setActiveIndicesInitialized] = useState(
    false
  );
  const { isMobile, isTablet } = useIsBreakpointSize();

  useEffect(() => {
    if (!activeIndicesInitialized && typeof window !== 'undefined') {
      mobileNavMenuData.forEach((parentMenuItem: any, parentIndex: any) => {
        if (
          parentMenuItem &&
          parentMenuItem.childMenu &&
          parentMenuItem.childMenu.length > 0 &&
          !(parentMenuItem.mobileOnly && !isMobile)
        ) {
          parentMenuItem.childMenu.forEach(
            (childMenuItem: any, childIndex: number) => {
              if (childMenuItem.link === window.location.pathname) {
                setActiveChildIndex(childIndex);
                setActiveParentIndex(parentIndex);
                setActiveIndicesInitialized(true);
              }
            }
          );
        }
      });
    }
  }, [mobileNavMenuData, activeIndicesInitialized, isMobile]);

  useEffect(() => {
    if (isMobileMenuOpen) {
      // disable body scroll
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isMobileMenuOpen]);

  if (!isMobile && !isTablet) {
    return null;
  }

  return (
    mobileNavMenuData && (
      <>
        <MobileContainer mt={0} isMobileMenuOpen={isMobileMenuOpen}>
          <FlexDivWithSpacing
            flexDirection="row"
            justifyContent="space-between"
            alignItems="flex-end"
            pr={5}
          >
            <LogoContainer
              flexDirection="row"
              justifyContent="space-between"
              pl={5}
              pt={6}
            >
              <Logo alt="Sofar Sounds logo" invert />
            </LogoContainer>
            <IconButton
              onClick={() => setIsMobileMenuOpen(false)}
              data-qaid="close-button"
            >
              <StyledStreamlineIcon
                icon={DeleteIcon}
                size={30}
                fill="white"
                hoverStroke="white"
              />
            </IconButton>
          </FlexDivWithSpacing>
          <LinkMenuMobile
            data={mobileNavMenuData as ParentMenu[]}
            activeParentIndex={activeParentIndex}
            activeChildIndex={activeChildIndex}
            isAdmin={isAdmin}
          />
        </MobileContainer>
      </>
    )
  );
};
