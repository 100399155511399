import { useQuery } from 'react-apollo';

import {
  GetContactRelationshipsData,
  GetContactRelationshipsVariables,
} from 'app/typings/contactRelationships';
import { queryParams } from 'app/shared/utils/queries';
import { GET_CONTACT_RELATIONSHIPS } from 'app/shared/graphql/contactRelationships/queries';

export const GetContactRelationships = (
  params: GetContactRelationshipsVariables
) => {
  return useQuery<GetContactRelationshipsData>(
    GET_CONTACT_RELATIONSHIPS,
    queryParams(params)
  );
};
