import React, { useContext, useRef } from 'react';
import styled, { css } from 'styled-components';

import { ManualCSSColors } from 'app/shared/theme';
import { useAnalyticsContext } from 'app/shared/utils';
import { makeOpaque } from 'app/shared/utils/colors';
import { AuthContext } from 'app/shared/context/Auth';
import { Body2 } from 'app/shared/components/atoms/TypographyManualCSS';

const Container = styled.label`
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  margin: -10px;
  padding: 10px;
  width: fit-content;
`;

export const Tick = styled.div`
  position: absolute;
  display: none;
  top: -1px;
  left: -1px;
  width: 21px;
  height: 21px;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
  transform: rotate(45deg);
`;

export const TickStem = styled.div`
  ${({ theme }) => css`
    position: absolute;
    width: 2px;
    height: 13px;
    background-color: ${theme.colors.whiteDenim};
    left: 12px;
    top: 3px;
  `}
`;

export const TickKick = styled.div`
  ${({ theme }) => css`
    position: absolute;
    width: 6px;
    height: 2px;
    background-color: ${theme.colors.whiteDenim};
    left: 7px;
    top: 14px;
  `}
`;

export const Square = styled.label<any>`
  ${({ theme, color }) => css`
    border-radius: 50%;
    position: relative;
    display: block;
    width: ${theme.dimensions.radioBox};
    height: ${theme.dimensions.radioBox};
    transition: all 0.1s ease-out;

    &:hover {
      cursor: pointer;
      box-shadow: 0px 0px 0px 10px ${makeOpaque(theme.colors.blackBetty, 0.1)},
        inset 0 0 0 10px ${makeOpaque(theme.colors.blackBetty, 0.1)};
    }

    &:focus {
      outline: none;
      box-shadow: 0px 0px 0px 10px ${makeOpaque(theme.colors.blackBetty, 0.1)},
        inset 0 0 0 20px ${makeOpaque(theme.colors.blackBetty, 0.1)};
    }

    &::before {
      content: '';
      border: 2px solid ${color ? theme.colors[color] : 'rgba(0, 0, 0, 0.75)'};
      border-radius: 10%;
      background-color: transparent;
      display: block;
      box-sizing: border-box;
      width: ${theme.dimensions.radioBox};
      height: ${theme.dimensions.radioBox};
      vertical-align: top;
      cursor: pointer;
      transition: all 0.1s ease-out;
    }
  `}
`;

export const Input = styled.input.attrs({ type: 'checkbox' })<{
  color?: ManualCSSColors;
  hideOnDisable?: boolean;
  disabled?: boolean;
  dark?: boolean;
}>`
  ${({
    theme,
    color,
    disabled = false,
    hideOnDisable = true,
    dark = false,
  }) => css`
    position: absolute;
    top: auto;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    width: 1px;
    height: 1px;
    user-select: none;
    white-space: nowrap;

    &:checked + ${Square}:hover {
      box-shadow: 0px 0px 0px 10px ${makeOpaque(theme.colors.primary, 0.1)};
    }

    &:checked + ${Square} ${Tick} {
      display: block;
    }

    &:checked + ${Square} ${Tick} ${TickStem} {
      background-color: ${dark
        ? theme.colors.primary
        : theme.isFanFacing
        ? theme.colors.backToBlack
        : !hideOnDisable && disabled
        ? (color && theme.colors[color]) || theme.colors.backToBlack
        : theme.colors.whiteDenim};
    }

    &:checked + ${Square} ${Tick} ${TickKick} {
      background-color: ${dark
        ? theme.colors.primary
        : theme.isFanFacing
        ? theme.colors.backToBlack
        : !hideOnDisable && disabled
        ? (color && theme.colors[color]) || theme.colors.backToBlack
        : theme.colors.whiteDenim};
    }

    &:checked + ${Square}::before {
      background-color: ${dark
        ? theme.colors.backToBlack
        : theme.colors.primary};
      border-color: ${theme.colors.primary};
    }

    &:disabled + ${Square}:hover {
      box-shadow: none;
      background-color: transparent;
      cursor: not-allowed;
    }

    &:disabled + ${Square} ${Tick} {
      display: ${hideOnDisable ? 'none' : 'block'};
    }

    &:disabled + ${Square}::before {
      border-color: ${(color && theme.colors[color]) || theme.colors.macyGrey};
      background-color: transparent;
      cursor: not-allowed;
    }
  `}
`;

export const Label = styled(Body2)<any>`
  ${({ theme, disabled, color }) => css`
    margin-left: 10px;
    user-select: none;
    color: ${color ? theme.colors[color] : '#000000'};

    ${disabled &&
      css`
        color: ${theme.colors.macyGrey};
      `}
  `}
`;

interface Props {
  id: string;
  name: string;
  value?: string;
  disabled?: boolean;
  onChange?: (e: any) => void;
  'data-qaid'?: string;
  checked?: boolean;
  color?: ManualCSSColors;
  hideOnDisable?: boolean;
  dark?: boolean;
}

export const Checkbox: React.FC<Props> = ({
  children,
  'data-qaid': qaId = '',
  hideOnDisable = true,
  onChange = () => {},
  ...props
}) => {
  const { trackAnalyticsEvent } = useAnalyticsContext();
  const { visitorType } = useContext(AuthContext);
  const checkboxRef = useRef<HTMLInputElement>(null);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const checkboxNode = checkboxRef.current;
    const analyticsEventName = checkboxNode?.checked
      ? 'Checkbox Checked'
      : 'Checkbox Unchecked';

    if (checkboxNode) {
      trackAnalyticsEvent(analyticsEventName, {
        location: checkboxNode.baseURI,
        dataQaid: checkboxNode.dataset.qaid,
        visitor_type: visitorType,
      });
    }

    return onChange(event);
  }

  return (
    <>
      <Container>
        <Input
          type="checkbox"
          {...props}
          onChange={handleChange}
          ref={checkboxRef}
          hideOnDisable={hideOnDisable}
          data-qaid={qaId}
        />
        <Square
          htmlFor={props.id}
          data-qaid={`${qaId}-square`}
          color={props.color}
          dark={props.dark}
        >
          {props.checked && (
            <Tick data-qaid={`${qaId}-tick`}>
              <TickKick />
              <TickStem />
            </Tick>
          )}
        </Square>
        {children && (
          <Label data-qaid={`${qaId}-label`} color={props.color}>
            {children}
          </Label>
        )}
      </Container>
    </>
  );
};
