import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { City } from 'app/typings/cities';
import { KeyValue } from 'app/typings/generics';
import { dateFormatter } from 'app/shared/utils/datetime';
import { buildFullName } from 'app/shared/utils/string';
import { capitalize } from 'app/shared/utils/string';
import { KeyAboveValueInfoList } from 'app/admin/components/molecules/KeyAboveValueInfoList';
import ListingCard from 'app/admin/components/organisms/ListingCard';

interface CrewUserCardProps {
  id: number;
  index: number;
  firstName: string;
  lastName: string;
  email: string;
  upcomingStaffedEventsCount: number;
  pastStaffedEventsCount: number;
  lastStaffedEventAt: string;
  crewRoleCities: City[];
  homeCity: City;
  crewStatus: string;
  onShowDetails: Function;
}

const TopContentContainer = styled.div`
  height: 100px;
`;

const CrewUserCard: React.FC<CrewUserCardProps> = ({
  id,
  index,
  firstName,
  lastName,
  email,
  upcomingStaffedEventsCount,
  pastStaffedEventsCount,
  lastStaffedEventAt,
  crewRoleCities,
  homeCity,
  crewStatus,
  onShowDetails,
}) => {
  const intl = useIntl();
  const citiesList =
    crewRoleCities.length > 0 ? crewRoleCities : homeCity ? [homeCity] : [];
  const citiesStr =
    citiesList.length > 0
      ? citiesList
          .map((city: City) => city.title)
          .sort()
          .join(', ')
      : 'None';

  const keysAboveValuesList = [
    {
      key: intl.formatMessage({
        id: 'admin.users.listingCard.city',
      }),
      value: citiesStr,
    },
    {
      key: intl.formatMessage({
        id: 'shared.email',
      }),
      value: email || 'None',
    },
  ];

  const keysAndValuesList = [
    {
      key: intl.formatMessage({
        id: 'admin.users.listingCard.upcomingConcerts',
      }),
      value: upcomingStaffedEventsCount.toString(),
    },
    {
      key: intl.formatMessage({
        id: 'admin.users.listingCard.lastConcert',
      }),
      value: lastStaffedEventAt
        ? dateFormatter(lastStaffedEventAt, 'shortMonthAndLongYear')
        : intl.formatMessage({
            id: 'none',
          }),
    },
    {
      key: intl.formatMessage({
        id: 'admin.users.listingCard.totalConcerts',
      }),
      value: pastStaffedEventsCount.toString(),
    },
    {
      key: intl.formatMessage({
        id: 'admin.users.listingCard.status',
      }),
      value: capitalize(crewStatus || ''),
    },
  ];

  const userName = buildFullName(firstName, lastName);

  const renderTopContent = (keysAboveValuesList: KeyValue[]) => {
    return (
      <TopContentContainer>
        <KeyAboveValueInfoList keysAndValues={keysAboveValuesList} />
      </TopContentContainer>
    );
  };

  const onShowDetailsParams = {
    id,
    firstName,
    lastName,
    email,
    index,
  };

  return (
    <>
      <ListingCard
        title={
          userName ||
          intl.formatMessage({
            id: 'admin.users.details.noName',
          })
        }
        topContent={() => renderTopContent(keysAboveValuesList)}
        keysAndValuesList={keysAndValuesList}
        controlsInfoList={[]}
        onShowDetails={onShowDetails}
        onShowDetailsParams={onShowDetailsParams}
        showDetailsTipText={intl.formatMessage({
          id: 'admin.users.listingCard.userDetails',
        })}
        dataQaidPrefix="users"
        index={index}
        height="280px"
      />
    </>
  );
};

export default CrewUserCard;
