import React, { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { City } from 'app/typings/cities';
import { Event } from 'app/typings/events';
import { useCurrentTheme } from 'app/shared/theme';
import { dateFormatter } from 'app/shared/utils/datetime';
import { get } from 'app/shared/utils/get';
import {
  FlyoverLoadingError,
  FlyoverLoadingIndicator,
} from 'app/shared/components/atoms/FlyoverContent';
import { BaseLink } from 'app/shared/components/atoms/LinkManualCSS';
import { Radio } from 'app/shared/components/atoms/Radio';
import { StreamlineIcon } from 'app/shared/components/atoms/StreamlineIcon';
import TruncatedByCharText from 'app/shared/components/atoms/TruncatedByCharText';
import { eventTypeLabelsShort } from 'app/admin/utils/events';
import { GetEventsForGuestlistTransferAttendee } from 'app/admin/graphql/events/queryHooks';
import { ReactComponent as AppWindowNext } from 'icons/streamline-regular/programing-apps-websites/apps-window/app-window-next.svg';

interface Props {
  currentEventId?: string;
  city?: City;
  eventType?: string;
  attendeeFlow?: string;
  minRemainingSpaces: number;
  selectedEvent?: Event;
  setEvent: (event: Event) => void;
  containerRef?: React.RefObject<HTMLDivElement>;
}

interface EventResultProps {
  event: Event;
  selectedEvent?: Event;
  setEvent: (event: Event) => void;
  scrollToResult: (scrollTop: number) => void;
}

const MainContainer = styled.div`
  padding-top: 10px;
  margin-bottom: 0px;
`;

const EventResults = styled.div`
  width: 100%;
  margin-top: 20px;
`;

const EventNoResults = styled.div`
  width: 100%;
  margin-top: 30px;
  margin-bottom: 20px;
`;

const EventResultContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 15px;
`;

const RadioButton = styled.div`
  padding-left: 6px;
  padding-right: 12px;
`;

const EventDescription = styled.div`
  font-size: 14px;
  white-space: nowrap;
  padding-right: 20px;
  width: 190px;
`;

const EventSubDescription = styled.div`
  font-size: 14px;
  font-style: italic;
  white-space: nowrap;
  padding-top: 5px;
`;

const EventLink = styled.div`
  padding-top: 3px;
  cursor: pointer;
`;

const getDescription = (event: Event) =>
  dateFormatter(event.localStartsAt, 'shortMonthDateAndYear') +
  (event.venue?.neighborhood?.title
    ? ` - ${event.venue.neighborhood.title}`
    : '');

const EventResult: React.FC<EventResultProps> = ({
  event,
  selectedEvent,
  setEvent,
  scrollToResult,
}) => {
  const theme = useCurrentTheme();

  const description = getDescription(event);
  const radioButtonRef = useRef<any>();

  useEffect(() => {
    if (
      selectedEvent &&
      Number(event.id) === Number(selectedEvent.id) &&
      radioButtonRef &&
      radioButtonRef.current
    ) {
      // Subtract 190 pixels so that we scroll to top of div where events are displayed
      // instead of top of entire flyover
      scrollToResult(radioButtonRef.current.offsetTop - 190);
    }
  }, [event.id, selectedEvent, scrollToResult]);

  return (
    <EventResultContainer>
      <RadioButton ref={radioButtonRef}>
        <Radio
          id={`event-${event.id}`}
          data-qaid={`event-for-transfer-${event.id}`}
          name={`event-${event.id}`}
          value={event.id}
          checked={
            selectedEvent && Number(event.id) === Number(selectedEvent.id)
          }
          onChange={() => {
            setEvent(event);
          }}
        ></Radio>
      </RadioButton>
      <EventDescription>
        <TruncatedByCharText text={description} truncateLength={23} />
        <EventSubDescription>
          {event.type && eventTypeLabelsShort[event.type]} - ID {event.id}
        </EventSubDescription>
      </EventDescription>
      <EventLink>
        <BaseLink
          href={`/admin/concert-planner?event_id=${event.id}`}
          openInNewTab={true}
        >
          <StreamlineIcon
            icon={AppWindowNext}
            size={16}
            stroke={theme.colors.green600}
          />
        </BaseLink>
      </EventLink>
    </EventResultContainer>
  );
};

export const EventsForTransfer: React.FC<Props> = ({
  currentEventId,
  city,
  eventType,
  attendeeFlow,
  minRemainingSpaces,
  selectedEvent,
  setEvent,
  containerRef,
}) => {
  const intl = useIntl();

  const {
    loading: loadingEvents,
    error: errorEvents,
    data: dataEvents,
  } = GetEventsForGuestlistTransferAttendee({
    city: city && city.cachedSlug,
    type: eventType,
    published: true,
    upcoming: true,
    attendeeTicketingFlow: attendeeFlow,
    minRemainingSpaces,
    orderBy: 'start',
    orderDirection: 'asc',
    skipPagination: true,
  });

  if (loadingEvents) {
    return <FlyoverLoadingIndicator />;
  }

  if ((!loadingEvents && !dataEvents) || errorEvents) {
    return <FlyoverLoadingError whatsBeingLoaded="upcoming events" />;
  }

  const events = get(dataEvents, 'events.events', []).filter(
    (e: Event) => e.id.toString() != currentEventId
  );

  return (
    <MainContainer>
      {events.length > 0 ? (
        <EventResults>
          {events.map((event: Event, index: number) => (
            <EventResult
              event={event}
              selectedEvent={selectedEvent}
              setEvent={setEvent}
              scrollToResult={(scrollTop: number) => {
                if (containerRef && containerRef.current) {
                  containerRef.current.scrollTop = scrollTop;
                }
              }}
              key={index}
            />
          ))}
        </EventResults>
      ) : (
        <EventNoResults>
          {intl.formatMessage(
            {
              id: 'admin.guestlist.attendeeActions.noEvents',
            },
            {
              city: city && city.title,
              eventType: eventType && eventTypeLabelsShort[eventType],
              attendeeFlow,
            }
          )}
        </EventNoResults>
      )}
    </MainContainer>
  );
};
