import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Artist } from 'app/typings/artists';
import { get } from 'app/shared/utils/get';
import { Checkbox } from 'app/shared/components/atoms/CheckboxManualCSS';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import { LoadingBlocks } from 'app/shared/components/atoms/LoadingBlocks';
import { LoadingError } from 'app/shared/components/atoms/LoadingError';
import TruncatedByCharText from 'app/shared/components/atoms/TruncatedByCharText';
import { H3, H5 } from 'app/shared/components/atoms/TypographyManualCSS';
import { GetDuplicatedArtistsForTitle } from 'app/admin/graphql/artistMerges/queryHooks';
import { ArtistLink } from 'app/admin/components/molecules/EntityLink';
import ListTable from 'app/admin/components/organisms/ListTable';

interface Props {
  setFieldValue: any;
  touched: any;
  errors: any;
  values: any;
  duplicatedArtistTitle?: string;
}

const MainContainer = styled.div`
  padding: 20px 0px 10px 0px;
`;

const TopNote = styled.div`
  padding-bottom: 20px;
`;

const TopNoteTextContainer = styled.div`
  margin-bottom: -15px;
`;

const TopNoteText = styled(H3)``;

const TopNoteSubText = styled(H5)``;

const ArtistMergeSelectArtistsFromSet: React.FC<Props> = ({
  setFieldValue,
  values,
  duplicatedArtistTitle,
}) => {
  const intl = useIntl();

  const {
    loading: loadingArtists,
    error: errorArtists,
    data: dataArtists,
  } = GetDuplicatedArtistsForTitle({
    title: duplicatedArtistTitle,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  if ((!loadingArtists && !dataArtists) || errorArtists) {
    return (
      <LoadingError
        whatsBeingLoaded={intl.formatMessage({
          id: 'admin.artistMerge.modal.theDuplicatedArtists',
        })}
      />
    );
  }

  const artists = get(dataArtists, 'duplicatedArtistsForTitle.artists', []);

  const changeSelectedArtists = (artist: Artist, isSelected: boolean) => {
    if (isSelected) {
      if (!values.artist1 && !values.artist2) {
        setFieldValue('artist1', artist);
      } else if (values.artist1 && !values.artist2) {
        setFieldValue('artist2', artist);
      } else if (values.artist1 && values.artist2) {
        setFieldValue('artist2', artist);
      }
    } else {
      if (values.artist1 && values.artist1.id == artist.id) {
        setFieldValue('artist1', values.artist2);
        setFieldValue('artist2', undefined);
      } else if (values.artist2 && values.artist2.id == artist.id) {
        setFieldValue('artist2', undefined);
      }
    }
  };

  const columnsConfig = [
    {
      headerText: intl.formatMessage({
        id: 'admin.artistMerge.modal.merge',
      }),
      columnWidth: 65,
    },
    {
      headerText: intl.formatMessage({
        id: 'admin.artistMerge.modal.id',
      }),
      columnWidth: 55,
    },
    {
      headerText: intl.formatMessage({
        id: 'admin.artistMerge.modal.name',
      }),
      columnWidth: 210,
    },
    {
      headerText: intl.formatMessage({
        id: 'admin.artistMerge.modal.email',
      }),
      columnWidth: 130,
    },
    {
      headerText: intl.formatMessage({
        id: 'admin.artistMerge.modal.homeCity',
      }),
      columnWidth: 130,
    },
    {
      headerText: intl.formatMessage({
        id: 'admin.artistMerge.modal.biography',
      }),
      columnWidth: 200,
    },
    {
      headerText: intl.formatMessage({
        id: 'admin.artistMerge.modal.website',
      }),
      columnWidth: 130,
    },
    {
      headerText: intl.formatMessage({
        id: 'admin.artistMerge.modal.facebook',
      }),
      columnWidth: 130,
    },
  ];

  const rowValues = (artist: Artist) => [
    <Checkbox
      id={`select-artist-${artist.id}`}
      data-qaid={`select-artist-${artist.id}`}
      checked={
        (values.artist1 && values.artist1.id == artist.id) ||
        (values.artist2 && values.artist2.id == artist.id)
      }
      name="selectArtists"
      onChange={e => changeSelectedArtists(artist, e.target.checked)}
      key={1}
    ></Checkbox>,
    artist.id,
    <ArtistLink artist={artist} truncateLength={27} key={1} />,
    <TruncatedByCharText
      text={artist.email || null}
      truncateLength={12}
      key={1}
    />,
    <TruncatedByCharText
      text={artist.homeCityDescription || null}
      truncateLength={12}
      key={1}
    />,
    <TruncatedByCharText
      text={artist.description || null}
      truncateLength={22}
      key={1}
    />,
    <TruncatedByCharText
      text={
        artist.socialUrls && artist.socialUrls.urlWebsite
          ? artist.socialUrls.urlWebsite
          : null
      }
      truncateLength={13}
      key={1}
    />,
    <TruncatedByCharText
      text={
        artist.socialUrls && artist.socialUrls.urlFacebook
          ? artist.socialUrls.urlFacebook
          : null
      }
      truncateLength={13}
      key={1}
    />,
  ];

  return (
    <>
      <DottedLine />
      <MainContainer>
        <TopNote>
          <TopNoteTextContainer>
            <TopNoteText>
              {intl.formatMessage(
                {
                  id: 'admin.artistMerge.modal.thereAreXArtists',
                },
                {
                  numArtists: artists.length,
                  title: duplicatedArtistTitle,
                }
              )}
            </TopNoteText>
          </TopNoteTextContainer>
          <TopNoteSubText>
            {intl.formatMessage({
              id: 'admin.artistMerge.modal.selectTwoArtists',
            })}
          </TopNoteSubText>
        </TopNote>
        <ListTable
          columnsConfig={columnsConfig}
          rowValues={rowValues}
          data={artists}
          loadingContent={
            loadingArtists ? (
              <LoadingBlocks.Rectangle width="100%" height="200px" />
            ) : null
          }
        />
      </MainContainer>
    </>
  );
};

export default ArtistMergeSelectArtistsFromSet;
