import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import {
  Feedback,
  FeedbacksInfo,
  TopFeedbackPhrase,
} from 'app/typings/feedbacks';
import { get } from 'app/shared/utils/get';
import usePermission from 'app/shared/utils/usePermission';
import { AuthContext } from 'app/shared/context/Auth';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import { AddIcon, AlertIcon } from 'app/shared/components/atoms/IconLibrary';
import { H6 } from 'app/shared/components/atoms/TypographyManualCSS';
import { DetailHeading as Heading } from 'app/admin/components/atoms/DetailContent';
import DetailText from 'app/admin/components/atoms/DetailText';
import LoadMoreButton from 'app/admin/components/atoms/LoadMoreButton';
import { TagElement } from 'app/admin/components/molecules/TagElementList';
import ArtistFeedbackSummary from 'app/admin/components/organisms/ArtistFeedbackSummary';

interface Props {
  sectionData: FeedbacksInfo;
  callbacks: { [name: string]: Function };
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const FeedbackSummary = styled.div`
  ${({ theme }) => css`
    border-radius: ${theme.ruler[3]}px;
    background-color: ${theme.colors.silverSprings};
    padding: 15px;
    width: 207px;
  `}
`;

const FeedbackList = styled.div`
  padding-top: 15px;
`;

const FirstFeedbackList = styled.div`
  padding: 0;
  margin-top: 15px;
`;

const FeedbackContainer = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
`;

const FeedbackDetails = styled.div`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.4px;
  margin-bottom: 2px;
`;

const TagElementsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 5px;
`;

const UserFeedbackHeading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const MyFeedbackHeading = styled(Heading)`
  margin-bottom: 0;
`;

const AddIconWrapper = styled.div`
  margin-left: 7px;
`;

const None = styled(DetailText)`
  ${({ theme }) => css`
    color: ${theme.colors.blueSmoke};
    margin: 0 !important;
  `}
`;

const SlimH6 = styled(H6)`
  font-weight: 400;
  margin-bottom: 8px;
`;

interface GetFeedbackListsValues {
  feedbacksCrew: Feedback[];
  feedbacksOps: Feedback[];
  feedbacksCurrentUser: Feedback[];
}

const getFeedbackLists = (
  feedbacks: Feedback[],
  userId: number | undefined
): GetFeedbackListsValues => {
  const feedbacksCrew = [];
  const feedbacksOps = [];
  const feedbacksCurrentUser = [];
  for (const feedback of feedbacks) {
    if (feedback.feedbackContext === 'Crew') {
      feedbacksCrew.push(feedback);
    } else if (feedback.feedbackContext === 'Ops') {
      feedbacksOps.push(feedback);
    }
    if (feedback.createdBy && feedback.createdBy.id === userId) {
      feedbacksCurrentUser.push(feedback);
    }
  }
  return { feedbacksCrew, feedbacksOps, feedbacksCurrentUser };
};

const renderTagElements = (tagElements: any[]) => {
  return tagElements.map((tagElement: any, i: number) => (
    <TagElement
      key={i}
      tagElementColor={tagElement.tagElementColor}
      textColor="#000000"
      data-qaid={`tag-element-${i}`}
    >
      {tagElement.name}
    </TagElement>
  ));
};

const tagElementsForTopFeedback = (feedbackPhrases: TopFeedbackPhrase[]) =>
  feedbackPhrases.map((feedbackPhrase: TopFeedbackPhrase) => ({
    name: feedbackPhrase.phrase,
    tagElementColor: feedbackPhrase.isPositive
      ? 'rgba(139, 195, 75, 0.7)'
      : '#FFD426',
  }));

const feedbackSum = (feedbackPhrases: TopFeedbackPhrase[]) =>
  feedbackPhrases
    .map((fbPh: TopFeedbackPhrase) => (fbPh.isPositive ? 1 : -1))
    .reduce((a: number, b: number) => a + b, 0);

const renderTopFeedbackIcon = (feedbackList: any) => {
  const evaluation = feedbackSum(feedbackList);
  let component = <div />;

  if (evaluation <= -1) {
    component = <AlertIcon iconSize={20} />;
  } else {
    component = <div />;
  }

  return component;
};

const maxNumFeedbacksToDisplay = 3;

const renderTopFeedbacks = (feedbackList: any, label: string) => {
  return (
    <>
      <SlimH6>
        {feedbackList && feedbackList.length > 0 && (
          <span>
            {renderTopFeedbackIcon(feedbackList)}
            {'  '}
          </span>
        )}
        {label}
      </SlimH6>
      {feedbackList && feedbackList.length > 0 ? (
        <TagElementsContainer>
          {renderTagElements(tagElementsForTopFeedback(feedbackList))}
        </TagElementsContainer>
      ) : (
        <None>None</None>
      )}
    </>
  );
};

const renderFeedbacks = (
  feedbacks: Feedback[],
  feedbackDisplayType: string,
  callbacks: { [name: string]: Function }
) => {
  if (feedbacks && feedbacks.length > 0) {
    return (
      <>
        {feedbacks
          .slice(0, maxNumFeedbacksToDisplay)
          .map((feedback: Feedback) => {
            return (
              <ArtistFeedbackSummary
                feedback={feedback}
                feedbackDisplayType={feedbackDisplayType}
                onClickDeleteFeedback={
                  callbacks.toggleDeleteFeedbackandSetFeedbackData
                }
                key={feedback.id}
              />
            );
          })}
        {feedbacks.length > maxNumFeedbacksToDisplay && (
          <LoadMoreButton
            data-qaid={`all-feedbacks-button-${feedbackDisplayType.toLowerCase()}`}
            onClick={() =>
              callbacks.toggleAllFeedbacksAndSetType(feedbackDisplayType)
            }
          >
            ALL
          </LoadMoreButton>
        )}
      </>
    );
  } else {
    return (
      <FeedbackContainer>
        <FeedbackDetails>
          <None>None</None>
        </FeedbackDetails>
      </FeedbackContainer>
    );
  }
};

const SectionFeedback: React.FC<Props> = ({ sectionData, callbacks }) => {
  const { user } = useContext(AuthContext);
  const userId = user?.id;
  const hasAccessArtistsPermission = usePermission('artist.list.accessBasic');

  const {
    feedbacksCrew,
    feedbacksOps,
    feedbacksCurrentUser,
    // @ts-ignore
  } = getFeedbackLists(sectionData.feedbacks, userId);

  const topFeedbacksCrew = get(sectionData, 'topFeedbacks.crew', []);
  const topFeedbacksOps = get(sectionData, 'topFeedbacks.ops', []);
  const intl = useIntl();

  return (
    <MainContainer data-qaid="section-feedback-container">
      <Grid cols={4} gap="30">
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={1}>
          <FeedbackSummary data-qaid="feedback-summary-container">
            <Heading>
              {intl.formatMessage({
                id: 'admin.artistDirectory.sectionFeedback.topFeedback',
              })}
            </Heading>
            <FirstFeedbackList>
              {renderTopFeedbacks(topFeedbacksCrew, 'Crew')}
            </FirstFeedbackList>
            <FeedbackList>
              {renderTopFeedbacks(topFeedbacksOps, 'Ops')}
            </FeedbackList>
          </FeedbackSummary>
        </Col>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={2}>
          <FeedbackList data-qaid="crew-feedback-container">
            <Heading>
              {intl.formatMessage({
                id: 'admin.artistDirectory.sectionFeedback.recentCrewFeedback',
              })}
            </Heading>
            {renderFeedbacks(feedbacksCrew, 'Crew', callbacks)}
          </FeedbackList>
        </Col>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={3}>
          <FeedbackList data-qaid="ops-feedback-container">
            <Heading>
              {intl.formatMessage({
                id: 'admin.artistDirectory.sectionFeedback.recentOpsFeedback',
              })}
            </Heading>
            {renderFeedbacks(feedbacksOps, 'Ops', callbacks)}
          </FeedbackList>
        </Col>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={4}>
          <FeedbackList data-qaid="user-feedback-container">
            <UserFeedbackHeading>
              <MyFeedbackHeading>
                {intl.formatMessage({
                  id: 'admin.artistDirectory.sectionFeedback.myFeedback',
                })}
              </MyFeedbackHeading>
              {hasAccessArtistsPermission && (
                <AddIconWrapper>
                  <AddIcon
                    iconSize={12}
                    onClick={() => callbacks.toggleFeedbackCreateModal()}
                    dataQaid="add-feedback-button"
                  />
                </AddIconWrapper>
              )}
            </UserFeedbackHeading>
            {renderFeedbacks(feedbacksCurrentUser, 'User', callbacks)}
          </FeedbackList>
        </Col>
      </Grid>
    </MainContainer>
  );
};

export default SectionFeedback;
