import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { City, Event } from 'app/typings';
import { errorMsgForField } from 'app/shared/utils/form';
import { FlexDivWithSpacing } from 'app/shared/components/atoms/FlexDivWithSpacing';
import { FormGroupContainer } from 'app/shared/components/atoms/FormContent';
import FormGroup from 'app/shared/components/molecules/FormGroup';
import { MultipleEventsSelector } from 'app/admin/components/organisms/EventSelector';

import { CollectionBasicInfoFormSectionHeader } from './components';
import { CollectionBasicInfoFormikProps } from './typings';

const EventSelectorContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  max-width: 350px;
`;

export const EventsSection: React.FC<CollectionBasicInfoFormikProps> = ({
  values,
  setFieldValue,
  touched,
  setTouched,
  errors,
}) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <CollectionBasicInfoFormSectionHeader
        titleIntlId="admin.collectionBasicInfo.form.selectConcerts"
        descriptionIntlId="admin.collectionBasicInfo.form.concertsDescription"
      />
      <FormGroupContainer>
        <FormGroup
          data-qaid="collection-about-section-edit-form-concerts-formgroup"
          label={formatMessage({
            id: 'admin.collectionBasicInfo.form.concertsLabel',
          })}
          errorMsg={errorMsgForField('events', touched, errors)}
        >
          <EventSelectorContainer>
            <FlexDivWithSpacing widthProp="100%">
              <MultipleEventsSelector
                selectedEvents={values.events || []}
                setEvents={(events: Event[]) => {
                  setTouched({ ...touched, title: true }); // Set any non-array field to touched - it doesn't like 'events'
                  setFieldValue('events', events);
                  setFieldValue(
                    'selectedCities',
                    (values.selectedCities || []).filter(
                      (city: City) =>
                        (events || []).findIndex(
                          (event: Event) => event?.city.id === city.id
                        ) !== -1
                    )
                  );
                }}
              />
            </FlexDivWithSpacing>
          </EventSelectorContainer>
        </FormGroup>
      </FormGroupContainer>
    </>
  );
};
