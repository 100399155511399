import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Container } from 'app/shared/components/atoms/Container';
import { H1 } from 'app/shared/components/atoms/TypographyManualCSS';
import SEO from 'app/shared/components/molecules/SEO';
import EmailTestingForm from 'app/admin/components/organisms/EmailTestingForm';
import Layout from 'app/admin/layouts/Admin';

// @ts-ignore
const MainContainer = styled(Container)`
  @media screen and (min-width: 1300px) {
    max-width: 1260px;
  }
`;

const SubheaderContainer = styled.div`
  text-align: left;
  margin: 30px 0 10px 0;
`;

const FormContainer = styled.div`
  margin-top: 0;
`;

const MainTitle = styled(H1)`
  margin-bottom: 0px;
`;

const SubtitleBullet = styled.li`
  margin-bottom: 10px;
`;

const AdminEmailTest: React.FC = () => {
  const intl = useIntl();

  return (
    <Layout centerContent={true} data-qaid="email-test">
      <SEO title="Admin Email Test" />
      <MainContainer>
        <MainTitle data-qaid="title">
          {intl.formatMessage({ id: 'admin.emailTest.title' })}
        </MainTitle>
        <SubheaderContainer data-qaid="subheader-container">
          <p data-qaid="subtitle">
            {intl.formatMessage({ id: 'admin.emailTest.subtitle' })}
          </p>
          <ul data-qaid="subtitle-bullets">
            <SubtitleBullet>
              {intl.formatMessage({ id: 'admin.emailTest.subtitleBullet1' })}
            </SubtitleBullet>
            <SubtitleBullet>
              {intl.formatMessage({ id: 'admin.emailTest.subtitleBullet2' })}
            </SubtitleBullet>
          </ul>
        </SubheaderContainer>
        <FormContainer data-qaid="cta-container">
          <EmailTestingForm />
        </FormContainer>
      </MainContainer>
    </Layout>
  );
};

export default AdminEmailTest;
