import * as Yup from 'yup';

const artistMergeFormSchema = (intl: any) =>
  Yup.object().shape({
    title: Yup.string().required(
      intl.formatMessage({
        id: 'form.requiredField',
      })
    ),
    email: Yup.string()
      .required(
        intl.formatMessage({
          id: 'form.requiredField',
        })
      )
      .email(
        intl.formatMessage(
          {
            id: 'form.fieldMustBeValidEmail',
          },
          {
            field: 'Email',
          }
        )
      ),
    cachedSlug: Yup.string().required(
      intl.formatMessage({
        id: 'form.requiredField',
      })
    ),
  });

export default artistMergeFormSchema;
