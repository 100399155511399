import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { CreateContinent } from 'app/admin/graphql/continents/mutationHooks';
import { DetailsFormMainContainer } from 'app/admin/components/atoms/DetailContent';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

import ContinentEditForm from './ContinentEditForm';
import ContinentEditFormSchema from './ContinentEditFormSchema';

interface Props {
  setFormSubmitAction?: (func: Function) => void;
  setDisplayConfirmation?: (set: boolean) => void;
  setIsSubmitting?: (set: boolean) => void;
  navigateTo: (routeData: object) => void;
}

const ContinentCreate: React.FC<Props> = ({
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  navigateTo,
}) => {
  const intl = useIntl();

  const [validationErrors, setValidationErrors] = useState<object>({});

  const formInitialValues = {
    title: '',
    continentCode: '',
  };

  const createContinentAction = CreateContinent();

  const handleCreateContinent = useSubmitAction({
    submitAction: createContinentAction,
    submitVariables: (values: any) => ({
      title: values.title,
      continentCode: values.continentCode,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.continentCreate.successMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.continentCreate.failureMessage',
    }),
    onSuccess: () => {
      navigateTo({
        routeName: 'continents',
        routeProps: {},
      });
    },
    onValidationError: validationErrors =>
      setValidationErrors(validationErrors),
    validationErrorKeysForDisplay: { continent_code: 'continent code' },
  });

  return (
    <ModalContentContainer>
      <DetailsFormMainContainer>
        <DetailsHeader
          title={intl.formatMessage({
            id: 'admin.continentCreate.title',
          })}
          isNewForm={true}
        />
        <GenericForm
          formInitialValues={formInitialValues}
          renderFormComponent={(renderProps: any) => (
            <ContinentEditForm
              formikProps={renderProps.formikProps}
              validationErrors={validationErrors}
              isNewContinent={true}
            />
          )}
          onSubmit={handleCreateContinent}
          formSchema={ContinentEditFormSchema(intl)}
          setFormSubmitAction={setFormSubmitAction}
          setDisplayConfirmation={setDisplayConfirmation}
          setIsSubmitting={setIsSubmitting}
          dataQaId="continent-create-form"
        />
      </DetailsFormMainContainer>
    </ModalContentContainer>
  );
};

export default ContinentCreate;
