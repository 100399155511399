import React from 'react';
import { useIntl } from 'react-intl';

import ImageEdit from 'app/shared/components/organisms/ImageEdit';
import { getRenderHeaderContent } from 'app/admin/components/organisms/CompetitionImageUploaderContent';

interface Props {
  contentProps?: any;
  setFormSubmitAction: (func: Function) => void;
  setDisplayConfirmation: (set: boolean) => void;
  setIsSubmitting: (set: boolean) => void;
  setDisplaySubmitButton: (set: boolean) => void;
  navigateTo: (routeData: object) => void;
}

const CompetitionMoreInfoImageUploader: React.FC<Props> = ({
  contentProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  setDisplaySubmitButton,
  navigateTo,
}) => {
  const intl = useIntl();

  const onUploadSuccess = (response: any) => {
    navigateTo({
      routeName: 'competition-details',
      routeProps: {
        defaultOpenSection: 'content',
        ...response.data.updateImages.competition,
      },
    });
  };

  const onDeleteSuccess = (response: any) => {
    navigateTo({
      routeName: 'competition-details',
      routeProps: {
        defaultOpenSection: 'content',
        ...response.data.updateImages.competition,
      },
    });
  };

  return (
    <ImageEdit
      objectType="Competition"
      objectId={contentProps.id}
      imageFieldName="moreInfoImage"
      imageFieldDescription={intl.formatMessage({
        id: 'admin.competitionMoreInfoImageEdit.imageDescription',
      })}
      imageUrl={contentProps.moreInfoImageUrl}
      cropperRatio={8 / 5}
      onUploadSuccess={onUploadSuccess}
      onDeleteSuccess={onDeleteSuccess}
      renderHeaderContent={getRenderHeaderContent(
        'landscape',
        contentProps.title,
        contentProps.moreInfoImageUrl
      )}
      headerTitle={intl.formatMessage({
        id: 'admin.competitionMoreInfoImageEdit.editImage',
      })}
      setFormSubmitAction={setFormSubmitAction}
      setDisplayConfirmation={setDisplayConfirmation}
      setIsSubmitting={setIsSubmitting}
      setDisplaySubmitButton={setDisplaySubmitButton}
      dataQaidPrefix="competition-more-info-image"
    />
  );
};

export default CompetitionMoreInfoImageUploader;
