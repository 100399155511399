import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { City } from 'app/typings/cities';
import { dateFormatter } from 'app/shared/utils/datetime';
import { snakecaseToTitleCase } from 'app/shared/utils/string';
import usePermission from 'app/shared/utils/usePermission';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import { Body2 } from 'app/shared/components/atoms/TypographyManualCSS';
import IconAndTextLink from 'app/shared/components/molecules/IconAndTextLink';
import {
  cityOperatingModelLabels,
  sofarOperatedMarketTypeLabels,
} from 'app/admin/utils/cities';
import {
  DetailHeading as Heading,
  DetailSubheading as Subheading,
  DetailValueContainer as ValueContainer,
} from 'app/admin/components/atoms/DetailContent';
import ExpandableText from 'app/admin/components/atoms/ExpandableText';
import { None } from 'app/admin/components/atoms/None';
import { CountryLink } from 'app/admin/components/molecules/EntityLink';
import SocialLinkList from 'app/admin/components/molecules/SocialLinkList';
import { ReactComponent as Camera1 } from 'icons/streamline-regular/images-photography/cameras/camera-1.svg';

interface Props {
  sectionData: City;
  callbacks: { [name: string]: VoidFunction };
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const SimpleKeyValue = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding-bottom: 5px;
`;

const SimpleKey = styled.div`
  width: 80px;
  white-space: nowrap;
`;

const SimpleValue = styled.div`
  padding-left: 5px;
`;

const renderKeyValue = (key: string, value: string | number | undefined) => {
  return (
    <SimpleKeyValue>
      <SimpleKey>{key}</SimpleKey>
      <SimpleValue>{value || <None />}</SimpleValue>
    </SimpleKeyValue>
  );
};

const socialLinksToDisplay = [
  'urlFacebook',
  'urlTwitter',
  'urlYoutube',
  'urlInstagram',
  'urlVKontacte',
  'urlSpotify',
];

const SectionBasicInfo: React.FC<Props> = ({ sectionData, callbacks }) => {
  const intl = useIntl();

  const hasAccessBasicByCityPermission = usePermission(
    'city.list.accessBasicByCity'
  );

  const nearbySecondaryCities = sectionData.nearbySecondaryCities
    ? sectionData.nearbySecondaryCities
    : [];

  return (
    <MainContainer data-qaid="section-about">
      <Grid cols={4}>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={1}>
          <Heading>
            {intl.formatMessage({
              id: 'shared.title',
            })}
          </Heading>
          <ValueContainer>{sectionData.title}</ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.cityBasicInfo.heading.country',
            })}
          </Heading>
          <ValueContainer>
            <CountryLink country={sectionData.country} />
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.cityBasicInfo.heading.latitudeLongitude',
            })}
          </Heading>
          <ValueContainer>
            {renderKeyValue('Latitude', sectionData.latitude)}
            {renderKeyValue('Longitude', sectionData.longitude)}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.cityBasicInfo.heading.timeZone',
            })}
          </Heading>
          <ValueContainer>{sectionData.timezone}</ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.cityBasicInfo.heading.citySince',
            })}
          </Heading>
          <ValueContainer>
            {sectionData.createdAt &&
              dateFormatter(sectionData.createdAt, 'shortMonthDateAndYear')}
          </ValueContainer>
          {!hasAccessBasicByCityPermission && (
            <>
              <Heading>
                {intl.formatMessage({
                  id: 'admin.cityBasicInfo.heading.images',
                })}
              </Heading>
              <IconAndTextLink
                icon={Camera1}
                text={intl.formatMessage({
                  id: 'admin.cityBasicInfo.manageCityPageImage',
                })}
                onClick={callbacks.toggleCityHeaderImageModal}
              />
              <IconAndTextLink
                icon={Camera1}
                text={intl.formatMessage({
                  id: 'admin.cityBasicInfo.manageHomePageImage',
                })}
                onClick={callbacks.toggleCityPromoHeaderImageModal}
              />
            </>
          )}
        </Col>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={2}>
          <Heading>
            {intl.formatMessage({
              id: 'admin.cityBasicInfo.heading.cityStatus',
            })}
          </Heading>
          <ValueContainer>
            {snakecaseToTitleCase(sectionData.cityStatus)}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.cities.details.cityOperatingModel',
            })}
          </Heading>
          <ValueContainer>
            {cityOperatingModelLabels[sectionData.cityOperatingModel || '']}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.cities.listingCard.marketType',
            })}
          </Heading>
          <ValueContainer>
            {sectionData.cityOperatingModel == 'curator' ? (
              intl.formatMessage({ id: 'admin.notApplicable' })
            ) : sectionData.sofarOperatedMarketType ? (
              sofarOperatedMarketTypeLabels[sectionData.sofarOperatedMarketType]
            ) : (
              <None />
            )}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'shared.businessOwner',
            })}
          </Heading>
          <ValueContainer>
            {sectionData.cityOperatingModel == 'curator' ? (
              intl.formatMessage({ id: 'admin.notApplicable' })
            ) : sectionData.businessOwner ? (
              sectionData.businessOwner.title
            ) : (
              <None />
            )}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.cityBasicInfo.heading.availableForArtistBooking',
            })}
          </Heading>
          <Subheading>
            {intl.formatMessage({
              id: 'admin.cityBasicInfo.availableForArtistBookingSubheader',
            })}
          </Subheading>
          <ValueContainer>
            {sectionData.isAcceptingArtistRequests
              ? intl.formatMessage({
                  id: 'yes',
                })
              : intl.formatMessage({
                  id: 'no',
                })}
          </ValueContainer>
        </Col>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={3}>
          <Heading>
            {intl.formatMessage({
              id: 'admin.cityBasicInfo.heading.urlSlug',
            })}
          </Heading>
          <ValueContainer>{sectionData.cachedSlug}</ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.cityBasicInfo.heading.aliases',
            })}
          </Heading>
          <ValueContainer>{sectionData.aliases || <None />}</ValueContainer>

          <Heading>
            {intl.formatMessage({
              id: 'admin.cityBasicInfo.heading.descriptiveText',
            })}
          </Heading>
          <ExpandableText
            allowHTML
            text={sectionData.description}
            truncateLength={120}
          />
          <Heading>
            {intl.formatMessage({
              id: 'admin.cityBasicInfo.heading.whatToExpect',
            })}
          </Heading>
          <ExpandableText
            allowHTML
            text={sectionData.whatToExpect}
            truncateLength={120}
          />

          <Heading>
            {intl.formatMessage({
              id: 'admin.cityBasicInfo.heading.nearbySecondaryCities',
            })}
          </Heading>
          <ValueContainer>
            {nearbySecondaryCities.length > 0 ? (
              nearbySecondaryCities.map((city: City, i: number) => (
                <Body2 key={`nearby-secondary-city-${i}`}>{city.title}</Body2>
              ))
            ) : (
              <None />
            )}
          </ValueContainer>
        </Col>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={4}>
          <SocialLinkList
            socialLinks={sectionData.socialUrls}
            socialLinksToDisplay={socialLinksToDisplay}
          />
        </Col>
      </Grid>
    </MainContainer>
  );
};

export default SectionBasicInfo;
