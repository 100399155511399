import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Venue } from 'app/typings/venues';
import { useCurrentTheme } from 'app/shared/theme';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Body2 } from 'app/shared/components/atoms/TypographyManualCSS';
import { availableDayOptions } from 'app/admin/utils/venues';
import {
  DetailHeading as Heading,
  DetailValueContainer as ValueContainer,
} from 'app/admin/components/atoms/DetailContent';
import ExpandableText from 'app/admin/components/atoms/ExpandableText';
import { None } from 'app/admin/components/atoms/None';
import { TagElement } from 'app/admin/components/molecules/TagElementList';

interface Props {
  sectionData: Venue;
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const DayAvailabilityWrapper = styled.div`
  margin-top: 4px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column
  align-items: flex-start;
  align-content: flex-start;
  line-height: 18px;
`;

const StyledTagElement = styled(TagElement)`
  width: fit-content;
  display: inline-flex;
`;

const SectionBookingInformation: React.FC<Props> = ({ sectionData }) => {
  const intl = useIntl();
  const theme = useCurrentTheme();

  const isDayAvailable = (dayKey: string) => {
    return sectionData.availableDays?.some(day => day.key === dayKey);
  };

  return (
    <MainContainer data-qaid="section-booking-information">
      <Grid>
        <Col xs={12} md={3} key={1}>
          <>
            <Heading>
              {intl.formatMessage({
                id: 'admin.venueBookingInformation.dayOfWeekAvailability',
              })}
            </Heading>
            {sectionData?.availableDays ? (
              <DayAvailabilityWrapper>
                {availableDayOptions.map((day, index) => (
                  <div key={index}>
                    <StyledTagElement
                      tagElementColor={
                        isDayAvailable(day.value)
                          ? theme.colors.purpleHaze
                          : theme.colors.macyGrey
                      }
                      textColor={theme.colors.whiteDenim}
                      marginBottom={0}
                    >
                      {day.title}
                    </StyledTagElement>
                    <Spacer mt={1} />
                  </div>
                ))}
              </DayAvailabilityWrapper>
            ) : (
              <None />
            )}
          </>
        </Col>

        <Col xs={12} md={3} key={2} data-qaid="col-day-of-week-availability">
          <Heading>
            {intl.formatMessage({
              id: 'admin.venueBookingInformation.bookingFrequency',
            })}
          </Heading>

          <ValueContainer data-qaid="hosting-frequency">
            {sectionData.hostingFrequency &&
            sectionData.hostingFrequency.key ? (
              <>{sectionData.hostingFrequency.name}</>
            ) : (
              <Body2> Unknown </Body2>
            )}
          </ValueContainer>

          <Heading>
            {intl.formatMessage({
              id: 'admin.venueBookingInformation.availabilityNotes',
            })}
          </Heading>
          <ExpandableText
            allowHTML
            text={sectionData.availabilityNotes}
            truncateLength={120}
          />
        </Col>
      </Grid>
    </MainContainer>
  );
};

export default SectionBookingInformation;
