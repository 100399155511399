import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { Artist } from 'app/typings/artists';
import { ArtistSetup } from 'app/typings/artistSetups';
import { EventPlannerEvent } from 'app/typings/events';
import {
  CloseOfferData,
  CreateOfferForArtistsData,
  Offer,
  PerformanceOffer,
} from 'app/typings/offers';
import { CreatePerformanceData, Performance } from 'app/typings/performances';
import { useCurrentTheme } from 'app/shared/theme';
import { useAnalyticsContext } from 'app/shared/utils';
import { DISCOVERY_EVENT, FEATURED_SET_EVENT } from 'app/shared/utils/events';
import { get } from 'app/shared/utils/get';
import usePermission from 'app/shared/utils/usePermission';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import { GetArtistSetups } from 'app/shared/graphql/artistSetups/queryHooks';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import {
  ContentWrapper,
  FlyoverNote,
  HeaderWrapper,
} from 'app/shared/components/atoms/FlyoverContent';
import { BaseLink } from 'app/shared/components/atoms/LinkManualCSS';
import { Radio } from 'app/shared/components/atoms/Radio';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Spinner } from 'app/shared/components/atoms/SpinnerManualCSS';
import { StreamlineIcon } from 'app/shared/components/atoms/StreamlineIcon';
import { Overline } from 'app/shared/components/atoms/TypographyManualCSS';
import Typeahead from 'app/shared/components/molecules/Typeahead';
import { filterActivePerformanceOffers } from 'app/admin/utils/offers';
import { GetArtists } from 'app/admin/graphql/artists/queryHooks';
import {
  CloseOffer as CloseOfferHook,
  CreateOfferForArtists as CreateOfferForArtistsHook,
} from 'app/admin/graphql/offers/mutationHooks';
import { CreatePerformance } from 'app/admin/graphql/performances/mutationHooks';
import {
  DeletePerformance,
  UpdatePerformanceArtistConfirmed,
} from 'app/admin/graphql/performances/mutationHooks';
import { None } from 'app/admin/components/atoms/None';
import CircleThumbnailAndText from 'app/admin/components/molecules/CircleThumbnailAndText';
import FlyoverFooter from 'app/admin/components/molecules/FlyoverFooter';
import ArtistSetupSelect from 'app/admin/components/organisms/ArtistSetupSelect';
import { ReactComponent as QuestionCircle } from 'icons/streamline-regular/interface-essential/alerts/question-circle.svg';
import { ReactComponent as Check1 } from 'icons/streamline-regular/interface-essential/form-validation/check-1.svg';
import { ReactComponent as Close } from 'icons/streamline-regular/interface-essential/form-validation/close.svg';
import { ReactComponent as Add } from 'icons/streamline-regular/interface-essential/remove-add/add.svg';
import { ReactComponent as ModernMusicDj } from 'icons/streamline-regular/music-audio/modern-music/modern-music-dj.svg';
import { ReactComponent as MoodQuestion } from 'icons/streamline-regular/social-medias-rewards-rating/mood/mood-question.svg';
import { ReactComponent as TaskListPin } from 'icons/streamline-regular/work-office-companies/tasks/task-list-pin.svg';

import AddArtist from './AddArtist';

interface Props {
  intl?: any;
  show?: boolean;
  performance: any;
  performances?: any;
  performanceOffers?: PerformanceOffer[];
  performanceOffersForEvent?: PerformanceOffer[];
  hasArtist?: boolean;
  isConfirmed?: boolean;
  handleFlyoverClose?: any;
  event: EventPlannerEvent;
  position?: number;
  initialState?: string;
  onSuccess: Function;
  onCreateOffer?: Function;
  offer?: Offer;
}

const InputWrapper = styled.div`
  display: flex;
`;

const IconWrapper = styled.div`
  display: flex;
  cursor: pointer;
  white-space: nowrap;
`;

const StyledIcon = styled.span`
  margin-right: 12px;
`;

const RadioLabel = styled.span`
  font-size: 12px;
`;

const Link = styled(BaseLink)`
  ${({ theme }) => css`
    color: ${theme.colors.green600};
    font-size: 12px;
  `}
`;

const Link2 = styled.a`
  ${({ theme }) => css`
    color: ${theme.colors.green600};
    font-size: 14px;
  `}
`;

const LinkContainer = styled.div`
  width: auto;
  display: inline-block;
`;

const CenteredSpinner = styled(Spinner)`
  margin-left: 45%;
`;

const RemoveMessage = styled.div`
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin: 25px 0px 12px 0px;
`;

const DisabledLink2 = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.macyGrey};
    font-size: 14px;
  `}
`;

const FlyoverParagraph = styled.p`
  text-align: center;
  font-size: 14px;
`;

const ArtistPerformanceOffer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`;

const ArtistPerformanceOfferRadio = styled.div``;

const ArtistPerformanceOfferArtistInfo = styled.div`
  margin-left: 15px;
`;

const EventPlannerArtistFlyover: React.FC<Props> = ({
  event,
  handleFlyoverClose,
  hasArtist,
  initialState,
  isConfirmed,
  onSuccess,
  onCreateOffer,
  performance,
  performances,
  performanceOffers,
  performanceOffersForEvent,
  position,
  offer,
}) => {
  const intl = useIntl();
  const theme = useCurrentTheme();
  const { startsAt, isPublished } = event;
  const { id: cityId } = event.city;
  const { trackAnalyticsEvent } = useAnalyticsContext();
  const hasAccessBasicEventsPermission = usePermission(
    'event.list.accessBasic'
  );
  const hasAccessEventsPermission = usePermission('event.list.access');
  const hasViewArtistsPermission = usePermission('artist.list.view');
  const hasViewOffersPermission = usePermission('artistOffer.list.view');
  const offerDirectoryUrl = '/admin/artist-booking-invitations?offer_id=';

  const [artistState, setArtistState] = useState<string | undefined>(
    initialState || 'artistChoice'
  );
  const [artistSetups, setArtistSetups] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(isConfirmed);
  const [searchMethod, setSearchMethod] = useState<string | null>(null);

  const updateArtistState = (artistState?: string) => {
    setArtistState(artistState);
  };

  useEffect(() => {
    if (initialState) {
      updateArtistState(initialState);
    }
  }, [initialState]);

  const {
    loading: loadingArtistSetups,
    data: dataArtistSetups,
    error: errorArtistSetups,
    refetch: refetchArtistSetups,
  } = GetArtistSetups({
    setuppableId: performance && performance.id,
    setuppableType: 'Performance',
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    setArtistSetups(get(dataArtistSetups, 'artistSetups.artistSetups', []));
  }, [dataArtistSetups]);

  const isFourthPosition = position === 4;

  const ArtistHeader = () => {
    const artistText =
      isFourthPosition && !performance?.artistConfirmed
        ? `${performance?.artist?.title} (Draft)`
        : `${performance?.artist?.title}`;

    return (
      <HeaderWrapper>
        {hasArtist ? (
          <CircleThumbnailAndText
            data-qaid="artist-header-thumbnail"
            imageUrl={
              performance && performance.artist && performance.artist.imageUrl
            }
            text={artistText}
            linkUrl={
              performance.artist && hasViewArtistsPermission
                ? `/admin/artists?artist_id=${performance.artist.id}`
                : undefined
            }
            titleText={
              isFourthPosition
                ? intl.formatMessage({
                    id: 'admin.eventPlanner.artists.additionalArtistInLineup',
                  })
                : intl.formatMessage({
                    id: 'admin.eventPlanner.artists.currentArtist',
                  })
            }
          />
        ) : (
          <CircleThumbnailAndText
            data-qaid="artist-header-thumbnail-null"
            displayAsEmpty={true}
            text={intl.formatMessage({
              id: 'none',
            })}
            titleText={
              isFourthPosition
                ? intl.formatMessage({
                    id: 'admin.eventPlanner.artists.additionalArtistInLineup',
                  })
                : intl.formatMessage({
                    id: 'admin.eventPlanner.artists.currentArtist',
                  })
            }
          />
        )}
        <Spacer mt={2} />
        <DottedLine />
      </HeaderWrapper>
    );
  };

  const ArtistCard = () => {
    if (isSubmitting) {
      return (
        <ContentWrapper>
          <CenteredSpinner size="30px" />
        </ContentWrapper>
      );
    } else {
      switch (artistState) {
        case 'artistChoice':
          return <ArtistChoice />;
        case 'addArtist':
          return (
            <AddArtist
              event={event}
              hasArtist={hasArtist}
              handleFlyoverClose={handleFlyoverClose}
              onSuccess={onSuccess}
              performance={performance}
              performances={performances}
              performanceOffersForEvent={performanceOffersForEvent}
              position={position}
              searchMethod={searchMethod || undefined}
              setIsSubmitting={setIsSubmitting}
              setSearchMethod={setSearchMethod}
              updateArtistState={updateArtistState}
            />
          );
        case 'updateArtistStatus':
          return <UpdateArtistStatus />;
        case 'deleteArtist':
          return <DeleteArtist />;
        case 'updateArtistSetup':
          return <UpdateArtistSetup />;
        case 'createOffer':
          return (
            <CreateOffer
              setIsSubmitting={setIsSubmitting}
              onSuccess={onSuccess}
            />
          );
        case 'viewOffer':
          return <ViewOffer />;
        case 'closeOffer':
          return <CloseOffer />;
        default:
          return <ArtistChoice />;
      }
    }
  };

  const onClickViewCompleteInvitation = () => {
    window.open(`${offerDirectoryUrl}${offer && offer.id}`, '_blank');
  };

  const ArtistChoice = () => {
    const activeOffers =
      performanceOffers && filterActivePerformanceOffers(performanceOffers);

    if (!hasArtist) {
      return (
        <ContentWrapper>
          <IconWrapper>
            <Link
              data-qaid="add-artist-link"
              onClick={() => {
                updateArtistState('addArtist');
              }}
            >
              <StyledIcon>
                <StreamlineIcon
                  icon={Add}
                  size={14}
                  stroke={theme.colors.green600}
                />
              </StyledIcon>
              {isFourthPosition ? (
                <FormattedMessage id="admin.eventPlanner.artists.addFourthArtistToLineup" />
              ) : (
                <FormattedMessage id="admin.eventPlanner.artists.selectArtistInThisPosition" />
              )}
            </Link>
          </IconWrapper>
          {isPublished &&
            onCreateOffer &&
            event.type === DISCOVERY_EVENT &&
            startsAt &&
            new Date().getTime() < Date.parse(startsAt) &&
            (!activeOffers || activeOffers.length === 0) && (
              <>
                <Spacer mt={4} />
                <IconWrapper>
                  <Link
                    data-qaid="create-offer-link"
                    onClick={() => {
                      onCreateOffer && onCreateOffer();
                    }}
                  >
                    <StyledIcon>
                      <StreamlineIcon
                        icon={QuestionCircle}
                        size={14}
                        stroke={theme.colors.green600}
                      />
                    </StyledIcon>
                    <FormattedMessage id="admin.eventPlanner.artists.createInvitationForThisPosition" />
                  </Link>
                </IconWrapper>
              </>
            )}
          {activeOffers && activeOffers.length > 0 && (
            <>
              <Spacer mt={4} />
              <IconWrapper>
                <Link
                  data-qaid="view-offer-link"
                  onClick={() => {
                    updateArtistState('viewOffer');
                  }}
                >
                  <StyledIcon>
                    <StreamlineIcon
                      icon={MoodQuestion}
                      size={14}
                      stroke={theme.colors.green600}
                    />
                  </StyledIcon>
                  <FormattedMessage id="admin.eventPlanner.artists.viewInvitationForThisPosition" />
                </Link>
              </IconWrapper>
            </>
          )}
          {hasViewOffersPermission && offer && offer.isMultiSlot && (
            <>
              <Spacer mt={4} />
              <IconWrapper>
                <Link
                  data-qaid="view-complete-invitation-link"
                  onClick={onClickViewCompleteInvitation}
                >
                  <StyledIcon>
                    <StreamlineIcon
                      icon={TaskListPin}
                      size={14}
                      stroke={theme.colors.green600}
                    />
                  </StyledIcon>
                  <FormattedMessage id="admin.eventPlanner.artists.viewCompleteInvitation" />
                </Link>
              </IconWrapper>
            </>
          )}
        </ContentWrapper>
      );
    }
    return (
      <ContentWrapper>
        {!isConfirmed && (
          <>
            <IconWrapper>
              <Link onClick={() => updateArtistState('addArtist')}>
                <StyledIcon>
                  <StreamlineIcon
                    icon={Add}
                    size={14}
                    stroke={theme.colors.green600}
                  />
                </StyledIcon>
                <FormattedMessage id="admin.eventPlanner.artists.changeArtistInThisPosition" />
              </Link>
            </IconWrapper>
            <Spacer mt={4} />
          </>
        )}
        <IconWrapper>
          <Link
            data-qaid="artist-update-status-link"
            onClick={() => updateArtistState('updateArtistStatus')}
          >
            <StyledIcon>
              <StreamlineIcon
                icon={Check1}
                size={14}
                stroke={theme.colors.green600}
              />
            </StyledIcon>
            <FormattedMessage id="admin.eventPlanner.artists.checkOrChangeArtistStatus" />
          </Link>
        </IconWrapper>
        <Spacer mt={4} />
        <IconWrapper>
          <Link
            data-qaid="update-artist-setup-link"
            onClick={() => {
              updateArtistState('updateArtistSetup');
            }}
          >
            <StyledIcon>
              <StreamlineIcon
                icon={ModernMusicDj}
                size={14}
                stroke={theme.colors.green600}
              />
            </StyledIcon>
            <FormattedMessage id="admin.eventPlanner.artists.checkOrChangeArtistSetups" />
          </Link>
        </IconWrapper>
        <Spacer mt={4} />
        <IconWrapper>
          <Link
            data-qaid="remove-artist-link"
            onClick={() => updateArtistState('deleteArtist')}
          >
            <StyledIcon>
              <StreamlineIcon
                icon={Close}
                size={14}
                stroke={theme.colors.green600}
              />
            </StyledIcon>
            <FormattedMessage id="admin.eventPlanner.artists.removeArtistFromThisPosition" />
          </Link>
        </IconWrapper>
      </ContentWrapper>
    );
  };

  interface CreateOfferProps {
    setIsSubmitting: (arg: boolean) => void;
    onSuccess: Function;
  }

  const CreateOffer: React.FC<CreateOfferProps> = ({
    setIsSubmitting,
    onSuccess,
  }) => {
    const [
      selectedArtistForOffer,
      setSelectedArtistForOffer,
    ] = useState<Artist | null>(null);
    const [selectedArtistsForOffer, setSelectedArtistsForOffer] = useState<
      Artist[]
    >([]);
    const [typeaheadSearchString, setTypeaheadSearchString] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    const tagLimitingArtistsForCuratorEvents = 'Booking Paused';

    const {
      data: searchArtistsData,
      loading: searchArtistsLoading,
      refetch: searchArtistsRefetch,
    } = GetArtists({
      artistSearch: typeaheadSearchString,
      eligibility: event.type === 'DiscoveryEvent' ? 'discovery' : undefined,
      notTag:
        !hasAccessEventsPermission &&
        (event.eventOrganizedAs === 'curator_owner_commercial_event' ||
          event.eventOrganizedAs === 'curator_owner_noncommercial_event')
          ? tagLimitingArtistsForCuratorEvents
          : undefined,
      orderBy: 'similarity',
      orderDirection: 'desc',
      page: 1,
      perPage: 30,
    });
    const artistData = searchArtistsData?.artists?.artists || [];
    const artistOptions = artistData.map((artist: any) => ({
      id: artist.id,
      title: artist.title,
      homeCityDescription: artist.homeCityDescription,
      needsReview: artist.needsReview,
    }));

    const onGetArtists = (searchString: string) => {
      if (!isOpen) {
        setIsOpen(true);
      }
      if (searchString !== typeaheadSearchString) {
        setTypeaheadSearchString(searchString);
        searchArtistsRefetch({ artistSearch: searchString });
      }
    };

    const createOfferForArtistsAction = CreateOfferForArtistsHook();

    const handleCreateOffer = useSubmitAction({
      submitAction: createOfferForArtistsAction,
      submitVariables: () => ({
        eventPositions: [
          {
            eventId: event.id,
            position,
          },
        ],
        artistIds: selectedArtistsForOffer.map((artist: any) => artist.id),
        name: `Invitation for Concert #${event.id}`,
        cityId,
      }),
      successMsg: intl.formatMessage(
        {
          id: 'admin.eventPlanner.artists.createInvitationSuccess',
        },
        {
          name: `Invitation for Concert #${event.id}`,
        }
      ),
      failureMsg: intl.formatMessage(
        {
          id: 'admin.eventPlanner.artists.createInvitationFailure',
        },
        {
          name: `Invitation for Concert #${event.id}`,
        }
      ),
      onSuccess: (response: CreateOfferForArtistsData) => {
        const createdOffer = get(response, 'data.createOffer.offer', {});
        setIsSubmitting(false);
        onSuccess &&
          onSuccess({ offer: createdOffer }, false, 'artistChoice', false);
      },
    });

    return (
      <div data-qaid="create-offer-flyover">
        <ContentWrapper>
          <Overline>
            <FormattedMessage id="admin.eventPlanner.artists.selectArtists" />
          </Overline>
          <Spacer mt={2} />
          <>
            <InputWrapper>
              <Typeahead
                getOptionLabel={(option: any) => {
                  return option.title;
                }}
                realTimeSearch={true}
                options={artistOptions}
                value={typeaheadSearchString}
                placeholder={intl.formatMessage({
                  id: 'admin.eventPlanner.artists.typeArtistName',
                })}
                isLoading={searchArtistsLoading}
                // @ts-ignore
                handleOptionClick={(value: string | number, option: Artist) => {
                  setSelectedArtistForOffer(option);
                  setTypeaheadSearchString(option.title);
                  setIsOpen(false);
                }}
                onSearch={onGetArtists}
                autoFocus={true}
                isTypeaheadOpen={isOpen}
                data-qaid="add-artist-typeahead"
              />
            </InputWrapper>
          </>
          <Spacer mt={3} />
          <IconWrapper>
            {selectedArtistForOffer &&
            !selectedArtistsForOffer
              .map((a: Artist) => a.id)
              .includes((selectedArtistForOffer as Artist).id) ? (
              <LinkContainer>
                <Link2
                  data-qaid="add-artist-to-offer-link"
                  onClick={() => {
                    const artistToAdd = selectedArtistForOffer as Artist;
                    setSelectedArtistsForOffer([
                      ...selectedArtistsForOffer,
                      artistToAdd,
                    ]);
                    setSelectedArtistForOffer(null);
                    setTypeaheadSearchString('');
                  }}
                >
                  <StyledIcon>
                    <StreamlineIcon
                      icon={Add}
                      size={14}
                      stroke={theme.colors.green600}
                    />
                  </StyledIcon>
                  <FormattedMessage id="admin.eventPlanner.artists.addArtist" />
                </Link2>
              </LinkContainer>
            ) : (
              <DisabledLink2>
                <StyledIcon>
                  <StreamlineIcon
                    icon={Add}
                    size={14}
                    stroke={theme.colors.macyGrey}
                  />
                </StyledIcon>
                <FormattedMessage id="admin.eventPlanner.artists.addArtist" />
              </DisabledLink2>
            )}
          </IconWrapper>
          <Spacer mt={3} />
          {selectedArtistsForOffer.length > 0 && (
            <>
              {selectedArtistsForOffer.map((artist: Artist, i: number) => (
                <React.Fragment key={i}>
                  <CircleThumbnailAndText
                    data-qaid="artist-offer-thumbnail"
                    imageUrl={artist.imageUrl}
                    text={artist.title}
                    subtext={artist.homeCityDescription}
                    textSize="14px"
                    linkUrl={
                      hasViewArtistsPermission
                        ? `/admin/artists?artist_id=${artist.id}`
                        : undefined
                    }
                    titleText=""
                    onRemove={() => {
                      setSelectedArtistsForOffer(
                        selectedArtistsForOffer.filter(
                          (a: Artist) => a.id !== artist.id
                        )
                      );
                    }}
                  />
                  <Spacer mt={2} />
                </React.Fragment>
              ))}
              <Spacer mt={4} />
              <FlyoverNote>
                <FormattedMessage id="admin.eventPlanner.artists.artistsWillSeeBookingInvitations" />
              </FlyoverNote>
            </>
          )}
        </ContentWrapper>
        <FlyoverFooter
          buttonText={intl.formatMessage({
            id: 'admin.eventPlanner.artists.update',
          })}
          buttonDisabled={selectedArtistsForOffer.length === 0}
          onClickButton={() => {
            setIsSubmitting(true);
            handleCreateOffer();
          }}
          dataQaid="create-offer-for-artist-submit-button"
        />
      </div>
    );
  };

  const ViewOffer = () => {
    const [selectedArtist, setSelectedArtist] = useState(
      performance && performance.artist
    );
    const [
      selectedPerformanceOffer,
      setSelectedPerformanceOffer,
    ] = useState<PerformanceOffer | null>(null);

    const createPerformanceAction = CreatePerformance();
    const handleAddArtist = useSubmitAction({
      submitAction: createPerformanceAction,
      submitVariables: () => ({
        eventId: event.id,
        artistId: selectedArtist && selectedArtist.id,
        position,
        performanceOfferId:
          selectedPerformanceOffer && selectedPerformanceOffer.id,
      }),
      successMsg: intl.formatMessage(
        {
          id: 'admin.eventPlanner.artists.addArtistSuccess',
        },
        {
          artist: selectedArtist && selectedArtist.title,
        }
      ),
      failureMsg: intl.formatMessage(
        {
          id: 'admin.eventPlanner.artists.addArtistFailure',
        },
        {
          artist: selectedArtist && selectedArtist.title,
        }
      ),
      onSuccess: (response: CreatePerformanceData) => {
        if (performance && performance.artistConfirmed) {
          trackAnalyticsEvent('Artist Performance Cancelled - Artist Changed', {
            eventId: event.id,
            performanceId: performance.id,
            artistId: performance.artist.id,
            isSelfBooked: performance.isArtistSelfBooked,
          });
        }
        const createdPerformance = get(
          response,
          'data.createPerformance.performance',
          {}
        );
        const updatedPerformanceOffers = get(
          response,
          'data.createPerformance.performanceOffers',
          {}
        );
        setIsSubmitting(false);
        updateArtistState('updateArtistSetup');
        onSuccess &&
          onSuccess(
            {
              performance: createdPerformance,
              offer: {
                performanceOffers: updatedPerformanceOffers,
              },
            },
            false,
            'updateArtistSetup',
            true
          );
      },
    });

    const performanceOffersByStatus: any =
      (performanceOffers &&
        performanceOffers.reduce(
          (retVal: object, performanceOffer: PerformanceOffer) => {
            if (performanceOffer && performanceOffer.status) {
              (retVal[performanceOffer.status] =
                retVal[performanceOffer.status] || []).push(performanceOffer);
            }
            return retVal;
          },
          {}
        )) ||
      {};

    const getPerformanceOfferSubtext = ({
      performanceOffer,
      displayArtistSetup,
    }: {
      performanceOffer: PerformanceOffer;
      displayArtistSetup?: boolean;
    }) => {
      if (!displayArtistSetup) {
        return null;
      }

      return performanceOffer.artistSetup?.audioSupport
        ? intl.formatMessage({
            id: `admin.eventPlanner.artists.audioSupport.${performanceOffer.artistSetup.audioSupport}`,
          })
        : intl.formatMessage({
            id: 'admin.eventPlanner.artists.audioSupport.noSetupGiven',
          });
    };

    const renderPerformanceOffer = ({
      performanceOffer,
      displayArtistSetup,
    }: {
      performanceOffer: PerformanceOffer;
      displayArtistSetup?: boolean;
    }) => (
      <CircleThumbnailAndText
        data-qaid="artist-offer-thumbnail"
        imageUrl={performanceOffer.artist && performanceOffer.artist.imageUrl}
        text={performanceOffer.artist && performanceOffer.artist.title}
        subtext={getPerformanceOfferSubtext({
          performanceOffer,
          displayArtistSetup,
        })}
        textSize="14px"
        linkUrl={
          hasViewArtistsPermission
            ? `/admin/artists?artist_id=${performanceOffer.artist &&
                performanceOffer.artist.id}`
            : undefined
        }
        titleText=""
      />
    );

    return (
      <div data-qaid="view-offer-flyover">
        <ContentWrapper>
          <Overline>
            <FormattedMessage id="admin.eventPlanner.shared.accepted" />
          </Overline>
          {!!performanceOffersByStatus.accepted || <None />}
          <Spacer mt={4} />
          {performanceOffersByStatus.accepted &&
            performanceOffersByStatus.accepted.map(
              (performanceOffer: PerformanceOffer, index: number) => (
                <ArtistPerformanceOffer key={index}>
                  <ArtistPerformanceOfferRadio>
                    <Radio
                      id={`acceptedOffer-${performanceOffer.id}`}
                      name={`acceptedOffer-${performanceOffer.id}`}
                      value={
                        performanceOffer.artist &&
                        performanceOffer.artist.id.toString()
                      }
                      checked={
                        (selectedArtist && selectedArtist.id) ===
                        (performanceOffer.artist && performanceOffer.artist.id)
                      }
                      alignItems="center"
                      onChange={() => {
                        setSelectedArtist(performanceOffer.artist);
                        setSelectedPerformanceOffer(performanceOffer);
                      }}
                      data-qaid="accepted-offer-radio-button"
                    />
                  </ArtistPerformanceOfferRadio>
                  <ArtistPerformanceOfferArtistInfo>
                    {renderPerformanceOffer({
                      performanceOffer,
                      displayArtistSetup: true,
                    })}
                  </ArtistPerformanceOfferArtistInfo>
                </ArtistPerformanceOffer>
              )
            )}
          <Overline>
            <FormattedMessage id="admin.eventPlanner.shared.noResponse" />
          </Overline>
          {!!performanceOffersByStatus.open || <None />}
          <Spacer mt={4} />
          {performanceOffersByStatus.open &&
            performanceOffersByStatus.open.map(
              (performanceOffer: PerformanceOffer, index: number) => (
                <React.Fragment key={index}>
                  {renderPerformanceOffer({ performanceOffer })}
                  <Spacer mt={4} />
                </React.Fragment>
              )
            )}
          <Overline>
            <FormattedMessage id="admin.eventPlanner.shared.declined" />
          </Overline>
          {!!performanceOffersByStatus.declined || <None />}
          <Spacer mt={4} />
          {performanceOffersByStatus.declined &&
            performanceOffersByStatus.declined.map(
              (performanceOffer: PerformanceOffer, index: number) => (
                <React.Fragment key={index}>
                  {renderPerformanceOffer({ performanceOffer })}
                  <Spacer mt={4} />
                </React.Fragment>
              )
            )}
        </ContentWrapper>
        {!performanceOffersByStatus.accepted && offer && !offer.isMultiSlot ? (
          hasAccessBasicEventsPermission && (
            <FlyoverFooter
              buttonText={intl.formatMessage({
                id: 'admin.offerDetails.closeBookingInvitation',
              })}
              onClickButton={() => {
                updateArtistState('closeOffer');
              }}
              dataQaid="close-booking-invitation-button"
            />
          )
        ) : (
          <FlyoverFooter
            buttonText={intl.formatMessage({
              id: 'admin.eventPlanner.artists.confirmArtist',
            })}
            buttonDisabled={!selectedArtist}
            onClickButton={() => {
              setIsSubmitting(true);
              handleAddArtist();
            }}
            dataQaid="confirm-artist-button"
          />
        )}
      </div>
    );
  };

  const UpdateArtistStatus = () => {
    const params = {
      performanceId: performance.id,
      isConfirmed: selectedStatus,
    };

    const handleUpdatePerformanceArtistConfirmed = useSubmitAction({
      submitAction: UpdatePerformanceArtistConfirmed(),
      submitVariables: () => params,
      successMsg: intl.formatMessage(
        {
          id: 'admin.eventPlanner.artists.updateStatus.success',
        },
        {
          status: selectedStatus
            ? intl.formatMessage({
                id: 'admin.eventPlanner.artists.statusConfirmed',
              })
            : intl.formatMessage({
                id: 'shared.draft',
              }),
        }
      ),
      failureMsg: intl.formatMessage({
        id: 'admin.eventPlanner.artists.updateStatus.failure',
      }),
      onSuccess: () => {
        setIsSubmitting(false);
        performance.artistConfirmed = selectedStatus;
        onSuccess && onSuccess({ performance }, selectedStatus);
      },
      onValidationError: () => {
        setIsSubmitting(false);
      },
    });

    const updateArtistStatus = () => {
      setIsSubmitting(true);
      handleUpdatePerformanceArtistConfirmed();
    };

    return (
      <div data-qaid="artist-update-status-confirmation-flyover">
        <ContentWrapper>
          <Overline spaceAfter={4}>
            <FormattedMessage id="admin.eventPlanner.artists.artistStatus" />
          </Overline>
          <Radio
            id="artistStatusDraft"
            checked={!selectedStatus}
            name="artistStatusDraft"
            onChange={() => setSelectedStatus(false)}
          >
            <RadioLabel>
              <FormattedMessage id="shared.draft" />
            </RadioLabel>
          </Radio>
          <Spacer mt={4} />
          <Radio
            id="artistStatusConfirmed"
            checked={selectedStatus}
            name="artistStatusConfirmed"
            onChange={() => setSelectedStatus(true)}
            data-qaid="artist-update-status-confirm-radio-button"
          >
            <RadioLabel>
              <FormattedMessage id="admin.eventPlanner.artists.statusConfirmed" />
            </RadioLabel>
          </Radio>
          {!isConfirmed && selectedStatus && (
            <>
              <Spacer mt={3} />
              <FlyoverNote>
                {intl.formatMessage({
                  id: 'admin.eventPlanner.artists.confirmingWillSendTheArtist',
                })}
              </FlyoverNote>
              <FlyoverNote>
                {intl.formatMessage({
                  id: 'admin.eventPlanner.artists.ifTheConcertIsLessThan',
                })}
              </FlyoverNote>
            </>
          )}
          {isConfirmed && !selectedStatus && (
            <>
              <Spacer mt={3} />
              <FlyoverNote>
                {intl.formatMessage({
                  id: 'admin.eventPlanner.artists.delete.messageHelpText',
                })}
              </FlyoverNote>
            </>
          )}
        </ContentWrapper>
        <FlyoverFooter
          buttonText={intl.formatMessage({
            id: 'admin.eventPlanner.artists.update',
          })}
          onClickButton={() => {
            updateArtistStatus();
          }}
          dataQaid="update-artist-status-submit-button"
        />
      </div>
    );
  };

  const DeleteArtist = () => {
    const params = {
      performanceId: performance.id,
    };

    const handleDeletePerformance = useSubmitAction({
      submitAction: DeletePerformance(),
      submitVariables: () => params,
      successMsg: intl.formatMessage(
        {
          id: 'admin.eventPlanner.artists.delete.success',
        },
        {
          artist: performance.artist.title,
        }
      ),
      failureMsg: intl.formatMessage(
        {
          id: 'admin.eventPlanner.artists.delete.failure',
        },
        {
          artist: performance.artist.title,
        }
      ),
      onSuccess: () => {
        if (performance && performance.artistConfirmed) {
          trackAnalyticsEvent('Artist Performance Cancelled - Artist Removed', {
            eventId: event.id,
            performanceId: performance.id,
            artistId: performance.artist.id,
            isSelfBooked: performance.isArtistSelfBooked,
          });
        }
        setIsSubmitting(false);
        onSuccess && onSuccess({ performance: null }, null);
      },
      onValidationError: () => {
        setIsSubmitting(false);
      },
    });

    const deleteArtist = () => {
      setIsSubmitting(true);
      handleDeletePerformance();
    };

    return (
      <div data-qaid="delete-artist-confirmation-flyover">
        <ContentWrapper>
          <Overline>
            <FormattedMessage id="admin.eventPlanner.artists.removeArtist" />
          </Overline>
          <Spacer mt={4} />
          <RemoveMessage>
            <FormattedMessage
              id="admin.eventPlanner.artists.delete.message"
              values={{ artist: performance.artist.title }}
            />
          </RemoveMessage>
          {performance.artistConfirmed && (
            <FlyoverNote>
              {intl.formatMessage({
                id: 'admin.eventPlanner.artists.delete.messageHelpText',
              })}
            </FlyoverNote>
          )}
        </ContentWrapper>
        <FlyoverFooter
          buttonText={intl.formatMessage({
            id: 'admin.shared.remove',
          })}
          onClickButton={deleteArtist}
          dataQaid="delete-artist-submit-button"
        />
      </div>
    );
  };

  const CloseOffer = () => {
    const params = {
      id: offer && offer.id,
    };

    const closeOfferAction = CloseOfferHook();
    const handleCloseOffer = useSubmitAction({
      submitAction: closeOfferAction,
      submitVariables: () => params,
      successMsg: intl.formatMessage(
        {
          id: 'admin.offerDetails.closeOffer.successMessage',
        },
        {
          name: offer && offer.name,
        }
      ),
      failureMsg: intl.formatMessage({
        id: 'admin.offerDetails.closeOffer.failureMessage',
      }),
      onSuccess: (response: CloseOfferData) => {
        const closedOffer = get(response, 'data.closeOffer.offer', {});

        setIsSubmitting(false);
        onSuccess &&
          onSuccess({ offer: closedOffer }, false, 'artistChoice', false);
      },
      onValidationError: () => {
        setIsSubmitting(false);
      },
    });

    const closeOffer = () => {
      setIsSubmitting(true);
      handleCloseOffer();
    };

    return (
      <div data-qaid="close-offer-confirmation-flyover">
        <ContentWrapper>
          <Overline>
            <FormattedMessage id="admin.offerDetails.closeBookingInvitation" />
          </Overline>
          <Spacer mt={4} />
          <RemoveMessage>
            <FormattedMessage
              id="admin.offerDetails.cancelConfirm"
              values={{ id: params.id }}
            />
          </RemoveMessage>
          <FlyoverNote>
            {intl.formatMessage({
              id: 'admin.offerDetails.cancelConfirmSubtitleAbbreviated',
            })}
          </FlyoverNote>
        </ContentWrapper>
        <FlyoverFooter
          buttonText={intl.formatMessage({
            id: 'admin.offerDetails.closeBookingInvitation',
          })}
          onClickButton={closeOffer}
          dataQaid="close-offer-submit-button"
        />
      </div>
    );
  };

  const UpdateArtistSetup = () => {
    const performanceOfferForArtist = performanceOffersForEvent?.find(
      (performanceOffer: PerformanceOffer) =>
        performanceOffer.artist?.id === performance?.artist.id
    );

    const [
      selectedArtistSetup,
      setSelectedArtistSetup,
    ] = useState<ArtistSetup | null>(null);

    return (
      <ArtistSetupSelect
        performance={performance}
        artistSetups={artistSetups}
        loadingArtistSetups={loadingArtistSetups}
        errorArtistSetups={errorArtistSetups}
        setIsSubmitting={setIsSubmitting}
        setSelectedArtistSetup={setSelectedArtistSetup}
        selectedArtistSetup={selectedArtistSetup}
        handleFlyoverClose={handleFlyoverClose}
        performanceOfferForArtist={performanceOfferForArtist}
        onSuccess={async (performance: Performance) => {
          if (performance) {
            const updatedArtistSetups = await refetchArtistSetups();
            setArtistSetups(
              get(updatedArtistSetups, 'artistSetups.artistSetups', [])
            );
            setIsSubmitting(false);
            return onSuccess(
              { performance },
              performance.artistConfirmed,
              undefined,
              false
            );
          }
        }}
      />
    );
  };

  return (
    <div data-qaid="artist-flyover">
      <ArtistHeader />
      <Spacer mt={1} />
      {event.type === FEATURED_SET_EVENT &&
      performance &&
      performance.artistConfirmed === true ? (
        <ContentWrapper>
          <FlyoverParagraph data-qaid="artist-fs-message">
            {intl.formatMessage({
              id: 'admin.eventPlanner.artists.errorCannotEditFeaturedSet',
            })}
          </FlyoverParagraph>
        </ContentWrapper>
      ) : (
        <ArtistCard />
      )}
    </div>
  );
};

export default EventPlannerArtistFlyover;
