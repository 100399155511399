import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { Event, UserStaffedEvent } from 'app/typings/events';
import { get } from 'app/shared/utils/get';
import { isEmpty } from 'app/shared/utils/object';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Spinner } from 'app/shared/components/atoms/SpinnerManualCSS';
import LoadMoreButton from 'app/admin/components/atoms/LoadMoreButton';

interface Props {
  queryDataHook: any; // e.g. GetVenueEvents
  queryExtraVariables?: object; // e.g. { scope: 'past' }
  endpointKey: string; // e.g. 'venueBasicInfo'
  eventFieldKey: string; // e.g. 'pastEvents'
  perPage?: number; // e.g. 12
  eventComponent: React.ComponentType<any>;
  eventComponentExtraProps?: object;
  noEventsMessage: string;
}

const LinkContainer = styled.div`
  text-align: center;
`;

const MobileSpacer = styled(Spacer)`
  ${({ theme }) => css`
    display: block;

    ${theme.media.md`
      display: none;
    `}
  `}
`;

const DottedLineCol = styled(Col)`
  ${({ theme }) => css`
    display: none;

    ${theme.media.md`
      display: block;
    `}
  `}
`;

const CenteredSpinner = styled(Spinner)`
  width: 50px;
  height: 50px;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
`;

const NoEvents = styled.div`
  white-space: nowrap;
  width: 100%;
`;

interface EventBlockProps {
  event: Event | UserStaffedEvent;
  eventComponent: React.ComponentType<any>;
  eventComponentExtraProps?: object;
  showDottedLine: boolean;
}

const EventBlock: React.FC<EventBlockProps> = ({
  event,
  eventComponent,
  eventComponentExtraProps,
  showDottedLine,
}) => {
  const EventComponent = eventComponent;

  return (
    <React.Fragment>
      <Col data-qaid="event-block-column" sm={6} md={3}>
        <EventComponent event={event} {...eventComponentExtraProps} />
      </Col>
      {showDottedLine && (
        <DottedLineCol>
          <DottedLine />
        </DottedLineCol>
      )}
    </React.Fragment>
  );
};

interface EventListProps {
  events: (Event | UserStaffedEvent)[];
  eventComponent: React.ComponentType<any>;
  eventComponentExtraProps?: object;
}

const EventList: React.FC<EventListProps> = ({
  events,
  eventComponent,
  eventComponentExtraProps,
}) => {
  const numEvents = events.length;

  return (
    <React.Fragment>
      {events.map((event: Event | UserStaffedEvent, index: number) => {
        const i = index + 1;
        const showDottedLine = i % 4 === 0 && numEvents > i;

        return (
          <EventBlock
            event={event}
            eventComponent={eventComponent}
            eventComponentExtraProps={eventComponentExtraProps}
            showDottedLine={showDottedLine}
            key={event.id}
          />
        );
      })}
    </React.Fragment>
  );
};

const EventListWithLoadMore: React.FC<Props> = ({
  queryDataHook,
  queryExtraVariables = {},
  endpointKey,
  eventFieldKey,
  perPage = 12,
  eventComponent,
  eventComponentExtraProps,
  noEventsMessage,
}) => {
  const intl = useIntl();

  const queryVariables = Object.assign(queryExtraVariables, {
    page: 1,
    perPage,
  });

  const { loading, error, data, fetchMore } = queryDataHook(queryVariables);

  const eventsKey = `${endpointKey}.${eventFieldKey}.events`;
  const metadataKey = `${endpointKey}.${eventFieldKey}.metadata`;

  const events = get(data, eventsKey, undefined);
  const totalRecords = get(data, `${metadataKey}.totalRecords`, undefined);
  const currentPage = get(data, `${metadataKey}.currentPage`, undefined);

  const [loadingMore, setLoadingMore] = useState(false);

  if (!events || !isEmpty(error) || (loading && !loadingMore)) {
    return <CenteredSpinner data-qaid="loading-events" />;
  }

  function fetchMoreEvents(setLoadingMore: any) {
    let updateQuery = (prev: any, { fetchMoreResult }: any) => {
      if (!fetchMoreResult) {
        return prev;
      }

      setLoadingMore(false);

      return {
        ...prev,
        [endpointKey]: {
          ...prev[endpointKey],
          [eventFieldKey]: {
            ...get(prev, `${endpointKey}.${eventFieldKey}`, {}),
            events: [
              ...get(prev, eventsKey, {}),
              ...get(fetchMoreResult, eventsKey, {}),
            ],
            metadata: get(fetchMoreResult, metadataKey, {}),
          },
        },
      };
    };

    const fetchMoreQueryVariables = Object.assign(queryExtraVariables, {
      page: currentPage + 1,
      perPage,
    });

    return fetchMore({
      variables: fetchMoreQueryVariables,
      updateQuery,
    });
  }

  return (
    <>
      <Grid gap="30px">
        {events && events.length > 0 && (
          <EventList
            events={events}
            eventComponent={eventComponent}
            eventComponentExtraProps={eventComponentExtraProps}
          />
        )}
      </Grid>
      {totalRecords > events.length && (
        <LinkContainer>
          <DottedLine />
          <MobileSpacer mt={4} />
          <LoadMoreButton
            data-qaid="load-more-button"
            onClick={() => {
              setLoadingMore(true);
              fetchMoreEvents(setLoadingMore);
            }}
            loading={loadingMore}
          >
            {intl.formatMessage({
              id: 'more',
            })}
          </LoadMoreButton>
        </LinkContainer>
      )}
      {!loading && !loadingMore && (!events || events.length == 0) && (
        <NoEvents>{noEventsMessage}</NoEvents>
      )}
    </>
  );
};

export default EventListWithLoadMore;
