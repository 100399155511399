import React from 'react';
import { useIntl } from 'react-intl';

import { Event } from 'app/typings/events';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import {
  InlineHtml,
  SectionText,
  SectionTitle,
  SectionWithIcon,
} from 'app/admin/components/atoms/CrewEventContent';
import IconInCircle from 'app/admin/components/atoms/IconInCircle';
import { ReactComponent as MicrophoneKaraoke } from 'icons/streamline-regular/music-audio/microphone/microphone-karaoke.svg';

interface EventInfoProps {
  event: Event;
}

const CrewEventSuppliesInfo: React.FC<EventInfoProps> = ({ event }) => {
  const intl = useIntl();

  return (
    <SectionWithIcon>
      <SectionTitle>
        <IconInCircle
          borderCircle={true}
          circleBackgroundColor="whiteDenim"
          dataQaIdSuffix="microphone"
          iconColor="mellowYellow"
          iconName={MicrophoneKaraoke}
        />
        {intl.formatMessage({ id: 'admin.crewEvent.section.suppliesInfo' })}
      </SectionTitle>

      <SectionText>
        <Spacer mt={4} />
        <InlineHtml
          dangerouslySetInnerHTML={{
            __html:
              event.suppliesNeededNotes ||
              intl.formatMessage({
                id: 'admin.crewEvent.section.noSuppliesNeeded',
              }),
          }}
        />
      </SectionText>
    </SectionWithIcon>
  );
};

export default CrewEventSuppliesInfo;
