import React from 'react';
import { useIntl } from 'react-intl';

import { CrewMessage } from 'app/typings/staffMembers';
import useModal from 'app/shared/utils/useModal';
import RoutableModal from 'app/shared/components/molecules/RoutableModal';
import {
  SectionText,
  SectionTitle,
  SectionWithIcon,
} from 'app/admin/components/atoms/CrewEventContent';
import IconInCircle from 'app/admin/components/atoms/IconInCircle';
import { ReactComponent as Megaphone } from 'icons/streamline-regular/interface-essential/share/megaphone.svg';

import {
  CrewMessageContainer,
  renderCrewMessage,
  ToggleContainer,
  ToggleText,
} from './shared';

interface CrewEventBreakingNewsProps {
  eventId: number;
  messages: CrewMessage[];
}

const CrewEventBreakingNews: React.FC<CrewEventBreakingNewsProps> = ({
  eventId,
  messages = [],
}) => {
  const intl = useIntl();
  const [viewAllCrewMessagesModal, toggleViewAllCrewMessagesModal] = useModal();
  const numMessagesShownInitially = 3;
  const initialMessages = messages.slice(0, numMessagesShownInitially);
  const lastMessageIndex =
    messages.length < numMessagesShownInitially
      ? messages.length - 1
      : numMessagesShownInitially - 1;

  return (
    <SectionWithIcon inverted>
      <SectionTitle inverted>
        <IconInCircle
          borderCircle={true}
          circleBackgroundColor="whiteDenim"
          dataQaIdSuffix="megaphone"
          iconColor="blueChristmas"
          iconName={Megaphone}
        />
        {intl.formatMessage({ id: 'admin.crewEvent.section.breakingNews' })}
      </SectionTitle>
      {!messages ||
        (messages.length === 0 && (
          <SectionText>
            <CrewMessageContainer>
              {intl.formatMessage({
                id: 'admin.crewEvent.section.breakingNews.noNews',
              })}
            </CrewMessageContainer>
          </SectionText>
        ))}
      {messages.length > numMessagesShownInitially ? (
        <>
          {initialMessages.map((message, index) =>
            renderCrewMessage(message, intl, lastMessageIndex, index)
          )}
          <ToggleContainer data-qaid="crew-messages-more-toggle-container">
            <ToggleText
              data-qaid="crew-messages-more-toggle-btn"
              onClick={toggleViewAllCrewMessagesModal}
            >
              {intl.formatMessage({
                id: 'more',
              })}
            </ToggleText>
          </ToggleContainer>
        </>
      ) : (
        messages.map((message, index) =>
          renderCrewMessage(message, intl, lastMessageIndex, index)
        )
      )}
      {viewAllCrewMessagesModal.isShowing && (
        <RoutableModal
          hide={viewAllCrewMessagesModal.hide}
          initialRouteName="crew-all-messages"
          initialRouteProps={{
            eventId,
            messages,
          }}
          dataQaidSuffix="crew-all-messages"
        />
      )}
    </SectionWithIcon>
  );
};

export default CrewEventBreakingNews;
