import * as Yup from 'yup';

const PrimaryRoleBasicInfoEditFormSchema = (intl: any) =>
  Yup.object().shape({
    displayName: Yup.string()
      .required(
        intl.formatMessage({
          id: 'form.requiredField',
        })
      )
      .trim()
      .matches(/^[A-Za-z0-9- ]+$/, {
        message: intl.formatMessage({
          id: 'admin.primaryRoleCreate.mustBeValidDisplayName',
        }),
      }),
    shortDisplayName: Yup.string()
      .trim()
      .matches(/^[A-Za-z0-9- ]+$/, {
        message: intl.formatMessage({
          id: 'admin.primaryRoleCreate.mustBeValidDisplayName',
        }),
      }),
  });

export default PrimaryRoleBasicInfoEditFormSchema;
