import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { dataGetter } from 'app/shared/utils/queries';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { userFullNameOrEmail } from 'app/admin/utils/users';
import { GetUserForCrewDirectory } from 'app/admin/graphql/users/queryHooks';
import { DetailsMainContainer } from 'app/admin/components/atoms/DetailContent';
import AccordionSections from 'app/admin/components/organisms/AccordionSections';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';
import { ReactComponent as InformationCircle } from 'icons/streamline-regular/interface-essential/alerts/information-circle.svg';
import { ReactComponent as MusicGenreTeam } from 'icons/streamline-regular/music-audio/music-genres/music-genre-team.svg';

import SectionBasicInfo from './SectionBasicInfo';
import SectionEventsPast from './SectionEventsPast';
import SectionEventsUpcoming from './SectionEventsUpcoming';

interface Props {
  navigateTo: (routeData: any) => void;
  contentProps: any;
  hide: VoidFunction;
}

const CrewUserDetails: React.FC<Props> = ({
  navigateTo,
  contentProps,
  hide,
}) => {
  const { id, firstName, lastName, email } = contentProps;

  const intl = useIntl();

  const [headerData, setHeaderData] = useState({
    firstName,
    lastName,
    email,
    isLoaded: !!email,
  });

  const {
    loading: loadingBasicInfo,
    error: errorBasicInfo,
    data: dataBasicInfo,
  } = GetUserForCrewDirectory({
    id,
    fetchPolicy: 'cache-and-network',
  });

  const sectionsConfig = {
    basicInfo: {
      title: intl.formatMessage({
        id: 'admin.users.details.sectionTitle.basicInfo',
      }),
      icon: InformationCircle,
      iconColor: 'blueChristmas',
      sectionComponent: SectionBasicInfo,
      dataKey: 'user',
      displayEditIcon: true,
      loading: loadingBasicInfo,
      data: dataBasicInfo ? dataBasicInfo.user : undefined,
      callbacks: {
        toggleSectionEditModal: () =>
          navigateTo({
            routeName: 'crew-user-basic-info-edit',
            routeProps: dataBasicInfo ? dataBasicInfo.user : undefined,
          }),
      },
    },
    eventsUpcoming: {
      title: intl.formatMessage({
        id: 'admin.userBasicInfo.heading.upcomingConcerts',
      }),
      icon: MusicGenreTeam,
      iconColor: 'orangeCrush',
      iconCircle: true,
      sectionComponent: SectionEventsUpcoming,
      dataKey: 'user',
      displayEditIcon: false,
      loading: loadingBasicInfo,
      data: dataBasicInfo ? dataBasicInfo.user : undefined,
      callbacks: {},
    },
    eventsPast: {
      title: intl.formatMessage({
        id: 'admin.userBasicInfo.heading.pastConcerts',
      }),
      icon: MusicGenreTeam,
      iconColor: 'checkBerry',
      iconCircle: true,
      sectionComponent: SectionEventsPast,
      dataKey: 'user',
      displayEditIcon: false,
      loading: loadingBasicInfo,
      data: dataBasicInfo ? dataBasicInfo.user : undefined,
      callbacks: {},
    },
  };

  useEffect(() => {
    const getDataBasicInfo = dataGetter(dataBasicInfo, 'user');
    if (dataBasicInfo) {
      setHeaderData({
        firstName: getDataBasicInfo('firstName'),
        lastName: getDataBasicInfo('lastName'),
        email: getDataBasicInfo('email'),
        isLoaded: true,
      });
    }
  }, [dataBasicInfo, loadingBasicInfo]);

  if ((!loadingBasicInfo && !dataBasicInfo) || errorBasicInfo) {
    hide();
    return null;
  }

  const user = {
    id,
    firstName: headerData.firstName,
    lastName: headerData.lastName,
    email: headerData.email,
  };

  return (
    <ModalContentContainer data-qaid="crew-user-details-main-modal">
      <DetailsMainContainer>
        <DetailsHeader
          title={
            userFullNameOrEmail(user) ||
            intl.formatMessage({
              id: 'admin.users.details.noName',
            })
          }
          loading={!headerData.isLoaded}
        />
        <AccordionSections
          sectionsConfig={sectionsConfig}
          defaultOpenSection={contentProps.defaultOpenSection}
        />
      </DetailsMainContainer>
    </ModalContentContainer>
  );
};

export default CrewUserDetails;
