import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { useCurrentTheme } from 'app/shared/theme';
import { BaseLink } from 'app/shared/components/atoms/LinkManualCSS';
import { StreamlineIcon } from 'app/shared/components/atoms/StreamlineIcon';
import { ToolTip, ToolTipContainer } from 'app/shared/components/atoms/ToolTip';
import InfoBox from 'app/shared/components/molecules/Flyover';
import { ReactComponent as ExpandHorizontal3 } from 'icons/streamline-regular/interface-essential/resize/expand-horizontal-3.svg';

interface CardControlsProps {
  controlsInfoList: ControlsInfo[];
  onClickShowDetails?: VoidFunction;
  showDetailsTipText?: string;
  dataQaidPrefix: string;
  cardIndex?: number;
  extraHeight?: boolean;
  listingCardRef?: React.RefObject<HTMLDivElement>;
  modalRef?: React.RefObject<HTMLDivElement>;
}

export interface ControlsInfo {
  action?: VoidFunction;
  href?: string;
  infoBoxContent?: any;
  icon: any;
  iconSize?: number;
  tipText: string;
}

const CardControlsContainer = styled.div<any>`
  ${({ extraHeight }) => css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-end;
    width: 100%;
    height: ${extraHeight ? '25px' : 'none'};
  `}
`;

const CardControlsWrapper = styled.div<any>`
  ${({ justifyRight }) => css`
    display: flex;
    flex-direction: row;
    justify-content: ${justifyRight ? 'flex-end' : 'space-between'};
  `}
`;

const BoxedTooltipContainer = styled(ToolTipContainer)`
  display: flex;
  align-items: center;
  justify-content: center;
  height 25px;
  width: 25px;
`;

const PointerA = styled(BaseLink)`
  cursor: pointer;
`;

interface DisplayIconProps {
  theme: any;
  enabled: boolean;
  icon: any;
  iconSize: number | undefined;
}

const displayIcon = ({
  theme,
  enabled,
  icon,
  iconSize = 16,
}: DisplayIconProps) => {
  const color = enabled ? theme.colors.green600 : theme.colors.blueSmoke;
  return <StreamlineIcon icon={icon} size={iconSize} stroke={color} />;
};

interface DisplayIconWithActionProps {
  theme: any;
  enabled: boolean;
  icon: any;
  iconSize: number | undefined;
  tipText: string | undefined;
  dataQaidPrefix: string;
  index?: number;
  action?: VoidFunction;
}

const displayIconWithAction = ({
  theme,
  enabled,
  icon,
  iconSize,
  tipText,
  dataQaidPrefix,
  index,
  action,
}: DisplayIconWithActionProps) => {
  return (
    <PointerA
      onClick={action}
      data-qaid={`${dataQaidPrefix}-${index || 0}`}
      key={index || 0}
    >
      {tipText ? (
        <BoxedTooltipContainer>
          {displayIcon({ theme, enabled, icon, iconSize })}
          <ToolTip>{tipText}</ToolTip>
        </BoxedTooltipContainer>
      ) : (
        displayIcon({ theme, enabled, icon, iconSize })
      )}
    </PointerA>
  );
};

interface DisplayIconWithLinkProps {
  theme: any;
  enabled: boolean;
  icon: any;
  iconSize: number | undefined;
  tipText: string | undefined;
  dataQaidPrefix: string;
  index?: number;
  href?: string;
}

const displayIconWithLink = ({
  theme,
  enabled,
  icon,
  iconSize,
  tipText,
  dataQaidPrefix,
  index,
  href,
}: DisplayIconWithLinkProps) => {
  return (
    <PointerA
      href={href}
      openInNewTab={true}
      data-qaid={`${dataQaidPrefix}-${index || 0}`}
      key={index || 0}
    >
      {tipText ? (
        <BoxedTooltipContainer>
          {displayIcon({ theme, enabled, icon, iconSize })}
          <ToolTip>{tipText}</ToolTip>
        </BoxedTooltipContainer>
      ) : (
        displayIcon({ theme, enabled, icon, iconSize })
      )}
    </PointerA>
  );
};

interface DisplayIconWithInfoBoxProps {
  theme: any;
  enabled: boolean;
  icon: any;
  iconSize: number | undefined;
  tipText: string | undefined;
  dataQaidPrefix: string;
  index?: number;
  flyoverClosed?: any;
  setFlyoverClosed?: Function;
  infoBoxContent?: any;
  listingCardRef?: React.RefObject<HTMLDivElement>;
  modalRef?: React.RefObject<HTMLDivElement>;
}

const displayIconWithInfoBox = ({
  theme,
  enabled,
  icon,
  iconSize,
  tipText,
  dataQaidPrefix,
  index,
  flyoverClosed,
  setFlyoverClosed,
  infoBoxContent,
  listingCardRef,
  modalRef,
}: DisplayIconWithInfoBoxProps) => {
  return (
    <PointerA data-qaid={`${dataQaidPrefix}-${index || 0}`} key={index || 0}>
      {tipText ? (
        <BoxedTooltipContainer>
          <InfoBox
            zIndex={102}
            anchorRef={listingCardRef}
            containerRef={modalRef}
            showCaret={false}
            width="300px"
            triggerElement={displayIcon({ theme, enabled, icon, iconSize })}
            keepInViewPort={{
              vertical: true,
              horizontal: true,
            }}
            onStateChange={isOpen =>
              setFlyoverClosed && setFlyoverClosed(!isOpen)
            }
            shouldFlyoverClose={flyoverClosed}
            innerContent={infoBoxContent(setFlyoverClosed)}
          />
          <ToolTip>{tipText}</ToolTip>
        </BoxedTooltipContainer>
      ) : (
        <InfoBox
          zIndex={102}
          anchorRef={listingCardRef}
          containerRef={modalRef}
          showCaret={false}
          width="300px"
          triggerElement={displayIcon({ theme, enabled, icon, iconSize })}
          keepInViewPort={{
            vertical: true,
            horizontal: true,
          }}
          onStateChange={isOpen =>
            setFlyoverClosed && setFlyoverClosed(!isOpen)
          }
          shouldFlyoverClose={flyoverClosed}
          innerContent={infoBoxContent(setFlyoverClosed)}
        />
      )}
    </PointerA>
  );
};

interface DisplayIconPlainProps {
  theme: any;
  enabled: boolean;
  icon: any;
  iconSize: number | undefined;
  tipText: string | undefined;
  index?: number;
}

const displayIconPlain = ({
  theme,
  enabled,
  icon,
  iconSize,
  tipText,
  index,
}: DisplayIconPlainProps) => {
  return (
    <React.Fragment key={index || 0}>
      {tipText ? (
        <BoxedTooltipContainer>
          {displayIcon({ theme, enabled, icon, iconSize })}
          <ToolTip>{tipText}</ToolTip>
        </BoxedTooltipContainer>
      ) : (
        displayIcon({ theme, enabled, icon, iconSize })
      )}
    </React.Fragment>
  );
};

interface RenderControlProps {
  theme: any;
  icon: any;
  iconSize?: number;
  tipText: string | undefined;
  dataQaidPrefix: string;
  index?: number;
  action?: VoidFunction;
  href?: string;
  flyoverClosed?: any;
  setFlyoverClosed?: Function;
  infoBoxContent?: any;
  listingCardRef?: React.RefObject<HTMLDivElement>;
  modalRef?: React.RefObject<HTMLDivElement>;
}

const renderControl = ({
  theme,
  icon,
  iconSize,
  tipText,
  dataQaidPrefix,
  index,
  action,
  href,
  flyoverClosed,
  setFlyoverClosed,
  infoBoxContent,
  listingCardRef,
  modalRef,
}: RenderControlProps) => {
  if (action) {
    return displayIconWithAction({
      theme,
      enabled: true,
      icon,
      iconSize,
      tipText,
      dataQaidPrefix,
      index,
      action,
    });
  }
  if (href) {
    return displayIconWithLink({
      theme,
      enabled: true,
      icon,
      iconSize,
      tipText,
      dataQaidPrefix,
      index,
      href,
    });
  }
  if (infoBoxContent) {
    return displayIconWithInfoBox({
      theme,
      enabled: true,
      icon,
      iconSize,
      tipText,
      dataQaidPrefix,
      index,
      flyoverClosed,
      setFlyoverClosed,
      infoBoxContent,
      listingCardRef,
      modalRef,
    });
  }
  if (!action && !href && !infoBoxContent) {
    return displayIconPlain({
      theme,
      enabled: false,
      icon,
      iconSize,
      tipText,
      index,
    });
  }
  return null;
};

export const CardControls: React.FC<CardControlsProps> = ({
  controlsInfoList,
  onClickShowDetails,
  showDetailsTipText,
  dataQaidPrefix,
  cardIndex,
  extraHeight = true,
  listingCardRef,
  modalRef,
}) => {
  const theme = useCurrentTheme();

  const [flyoverClosed, setFlyoverClosed] = useState(false);

  return (
    <CardControlsContainer
      extraHeight={extraHeight}
      data-qaid={`${dataQaidPrefix}-card-controls`}
    >
      <CardControlsWrapper justifyRight={controlsInfoList.length == 0}>
        {controlsInfoList.map((controlsInfo: ControlsInfo, index: number) =>
          renderControl({
            theme,
            icon: controlsInfo.icon,
            iconSize: controlsInfo.iconSize,
            tipText: controlsInfo.tipText,
            dataQaidPrefix: 'card-controls-icon',
            index,
            action: controlsInfo.action,
            href: controlsInfo.href,
            flyoverClosed,
            setFlyoverClosed,
            infoBoxContent: controlsInfo.infoBoxContent,
            listingCardRef,
            modalRef,
          })
        )}
        {onClickShowDetails &&
          renderControl({
            theme,
            icon: ExpandHorizontal3,
            iconSize: 18,
            tipText: showDetailsTipText,
            dataQaidPrefix: 'expand-horizontal',
            index: cardIndex,
            action: onClickShowDetails,
          })}
      </CardControlsWrapper>
    </CardControlsContainer>
  );
};
