import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import { PrimaryButton } from 'app/shared/components/atoms/Button';
import ExternalRedirect from 'app/shared/components/atoms/ExternalRedirect';
import { H1 } from 'app/shared/components/atoms/TypographyManualCSS';
import { AcknowledgeUserAgreement as AcknowledgeUserAgreementHook } from 'app/admin/graphql/users/mutationHooks';

interface Props {
  location: any;
  agreementName: string;
  actions: string[];
  agreementTitle: string;
  agreementIntroContent: any;
  children: any;
}

const IntroText = styled.p`
  text-align: left;
  font-weight: 600;
  margin-bottom: 30px;
`;

const HeaderContainer = styled.div`
  margin: 0px 100px 30px 100px;
`;

const GreetingContainer = styled.div`
  text-align: left;
  max-width: 800px;
  padding: 40px 30px 30px 30px;
`;

const GreetingText = styled.p`
  text-align: left;
`;

const MainTitle = styled(H1)`
  text-align: center;
  margin-bottom: 0px;
`;

const BodyContainer = styled.div`
  text-align: left;
  max-width: 800px;
  padding: 0 30px 20px 30px;
`;

const SubmitButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0px 0 30px 0;
`;

const SubmitButton = styled.div`
  padding: 5px 30px 5px 30px;
`;

const AcknowledgeUserAgreement: React.FC<Props> = ({
  location,
  agreementName,
  actions,
  agreementTitle,
  agreementIntroContent,
  children,
}) => {
  const intl = useIntl();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const params = new URLSearchParams(location.search);
  const redirectPath = params.get('referrer_path') || '/dashboard';

  const acknowledgeUserAgreementAction = AcknowledgeUserAgreementHook();

  const handleAcknowledgeUserAgreement = useSubmitAction({
    submitAction: acknowledgeUserAgreementAction,
    submitVariables: (action: string) => ({
      agreementName,
      action,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.acknowledgeUserAgreement.successMessage',
    }),
    successMsgValuesForDisplay: {
      fullMessage: (action: string) =>
        action == 'acknowledge'
          ? intl.formatMessage({
              id: 'admin.acknowledgeUserAgreement.acknowledgeSuccessMessage',
            })
          : intl.formatMessage({
              id: 'admin.acknowledgeUserAgreement.declineSuccessMessage',
            }),
    },
    failureMsg: intl.formatMessage({
      id: 'admin.acknowledgeUserAgreement.failureMessage',
    }),
    onSuccess: () => {
      setIsSuccess(true);
    },
  });

  const handleSubmit = (action: string) => {
    setIsSubmitting(true);
    handleAcknowledgeUserAgreement(action);
  };

  return (
    <>
      {isSuccess && <ExternalRedirect to={redirectPath} />}
      <GreetingContainer>
        <GreetingText>
          {intl.formatMessage({
            id: 'admin.acknowledgeUserAgreement.greeting',
          })}
        </GreetingText>
      </GreetingContainer>
      <HeaderContainer>
        <MainTitle>{agreementTitle}</MainTitle>
      </HeaderContainer>
      <BodyContainer>
        <IntroText
          dangerouslySetInnerHTML={{ __html: agreementIntroContent }}
        />
        {children}
      </BodyContainer>
      <SubmitButtonContainer>
        {actions.includes('acknowledge') && (
          <SubmitButton>
            <PrimaryButton
              data-qaid="submit-button"
              onClick={() => handleSubmit('acknowledge')}
              type="submit"
              loading={isSubmitting}
            >
              {intl.formatMessage({
                id:
                  'admin.acknowledgeUserAgreement.acknowledgeSubmitButtonText',
              })}
            </PrimaryButton>
          </SubmitButton>
        )}
        {actions.includes('decline') && (
          <SubmitButton>
            <PrimaryButton
              data-qaid="submit-button"
              onClick={() => handleSubmit('decline')}
              type="submit"
              loading={isSubmitting}
            >
              {intl.formatMessage({
                id: 'admin.acknowledgeUserAgreement.declineSubmitButtonText',
              })}
            </PrimaryButton>
          </SubmitButton>
        )}
      </SubmitButtonContainer>
    </>
  );
};

export default AcknowledgeUserAgreement;
