import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { PromoCode } from 'app/typings/promoCodes';
import { dateFormatter } from 'app/shared/utils/datetime';
import usePermission from 'app/shared/utils/usePermission';
import TruncatedByCharText from 'app/shared/components/atoms/TruncatedByCharText';
import DownloadLink from 'app/admin/components/atoms/DownloadLink';
import { EventLink } from 'app/admin/components/molecules/EntityLink';
import { UserLink } from 'app/admin/components/molecules/EntityLink';
import ListTable from 'app/admin/components/organisms/ListTable';

interface Props {
  sectionData: PromoCode;
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const NoOrders = styled.div`
  width: 100%;
`;

const SectionOrders: React.FC<Props> = ({ sectionData }) => {
  const intl = useIntl();

  const hasDownloadPromoCodeOrdersPermission = usePermission(
    'promoCode.downloadInfo'
  );

  const columnsConfig = [
    {
      headerText: intl.formatMessage({
        id: 'shared.name',
      }),
      columnWidth: 190,
    },
    {
      headerText: intl.formatMessage({
        id: 'shared.email',
      }),
      columnWidth: 220,
    },
    {
      headerText: intl.formatMessage({
        id: 'shared.event',
      }),
      columnWidth: 110,
    },
    {
      headerText: intl.formatMessage({
        id: 'shared.city',
      }),
      columnWidth: 170,
    },
    {
      headerText: intl.formatMessage({
        id: 'admin.promoCodeBasicInfo.orders.usedAt',
      }),
      columnWidth: 220,
    },
    {
      headerText: intl.formatMessage({
        id: 'admin.promoCodeBasicInfo.orders.firstOrder',
      }),
      columnWidth: 100,
    },
  ];

  const rowValues = (order: any) => [
    <UserLink user={order.eventAttendee.user} truncateLength={22} key={1} />,
    <TruncatedByCharText
      text={order.eventAttendee.user.email}
      truncateLength={24}
      key={1}
    />,
    <EventLink event={order.eventAttendee.event} truncateLength={20} key={1} />,
    <TruncatedByCharText
      text={order.eventAttendee.event.city.title}
      truncateLength={20}
      key={1}
    />,
    dateFormatter(
      order.createdAt,
      'shortMonthDateYearHoursAndMinutesWithSpacedAMPMWithTimezone',
      order.eventAttendee.event.city.timezone
    ),
    order.hasPreviousOrders ? 'No' : 'Yes',
  ];

  const downloadUrl = `/admin/download-promo-code-redemptions.csv?id=${sectionData.id}`;

  return (
    <MainContainer data-qaid="section-orders">
      {hasDownloadPromoCodeOrdersPermission &&
        sectionData.orders &&
        sectionData.orders.length > 0 && (
          <DownloadLink
            text={intl.formatMessage({
              id: 'admin.promoCodes.downloadOrdersCsv',
            })}
            url={downloadUrl}
          />
        )}
      <ListTable
        columnsConfig={columnsConfig}
        rowValues={rowValues}
        data={sectionData.orders}
        noResultsContent={
          sectionData.orders &&
          sectionData.orders.length == 0 && (
            <NoOrders>
              {intl.formatMessage({
                id: 'admin.promoCodeBasicInfo.orders.noOrders',
              })}
            </NoOrders>
          )
        }
      />
    </MainContainer>
  );
};

export default SectionOrders;
