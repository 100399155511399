import React, { useEffect } from 'react';

import { scrollToTop } from 'app/shared/utils/modal';
import GenericFormContainer from 'app/shared/components/atoms/GenericFormContainer';

import ArtistMergeMergeArtists from './ArtistMergeMergeArtists';
import ArtistMergeSelectArtists from './ArtistMergeSelectArtists';
import ArtistMergeSelectArtistsFromSet from './ArtistMergeSelectArtistsFromSet';

interface FormProps {
  formikProps: any;
  modalRef: React.RefObject<HTMLDivElement>;
  setButtonConfigs?: (buttonConfigs: any) => void;
  setGoBackAction?: (func: Function) => void;
  setGoForwardAction?: (func: Function) => void;
  setFormSubmitAction?: (func: Function) => void;
  setDisplayConfirmation?: (set: boolean) => void;
  setIsSubmitting?: (set: boolean) => void;
  validationErrors?: object;
  formView: string;
  setFormView?: (view: string) => void;
  duplicatedArtistTitle?: string;
}

const ArtistMergeForm: React.FC<FormProps> = ({
  formikProps,
  modalRef,
  setButtonConfigs,
  setGoBackAction,
  setGoForwardAction,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  validationErrors,
  formView,
  setFormView,
  duplicatedArtistTitle,
}) => {
  const { setFieldValue, touched, errors, values } = formikProps;

  useEffect(() => {
    const goToView = (formView: string) => {
      setFormView && setFormView(formView);
      // This is needed in order to scroll content back to top when navigating back or forward
      scrollToTop(modalRef);
    };
    const getBackButtonTextKey = () => {
      switch (formView) {
        case 'selectArtists':
          return 'form.cancel';
        case 'selectArtistsFromSet':
          return 'form.cancel';
        case 'mergeArtists':
          return 'form.back';
        default:
          return 'form.back';
      }
    };
    const getForwardButtonDisplayed = () => {
      switch (formView) {
        case 'selectArtists':
          return true;
        case 'selectArtistsFromSet':
          return true;
        case 'mergeArtists':
          return false;
        default:
          return true;
      }
    };
    const getForwardButtonDisabled = () => {
      switch (formView) {
        case 'selectArtists':
          return !values.artist1 || !values.artist2;
        case 'selectArtistsFromSet':
          return !values.artist1 || !values.artist2;
        case 'mergeArtists':
          return false;
        default:
          return false;
      }
    };
    const getSubmitButtonTextKey = () =>
      'admin.artistMerge.modal.submitMergeArtists';
    const getSubmitButtonDisplayed = () => {
      switch (formView) {
        case 'selectArtists':
          return false;
        case 'selectArtistsFromSet':
          return false;
        case 'mergeArtists':
          return true;
        default:
          return false;
      }
    };
    const getSubmitButtonDisabled = () => {
      switch (formView) {
        case 'selectArtists':
          return false;
        case 'selectArtistsFromSet':
          return false;
        case 'mergeArtists':
          return (
            !values.artist1 ||
            !values.artist2 ||
            !values.title ||
            !values.email ||
            !values.cachedSlug
          );
        default:
          return false;
      }
    };
    const getButtonConfigs = () => ({
      back: {
        textKey: getBackButtonTextKey(),
      },
      forward: {
        displayed: getForwardButtonDisplayed(),
        disabled: getForwardButtonDisabled(),
      },
      submit: {
        textKey: getSubmitButtonTextKey(),
        displayed: getSubmitButtonDisplayed(),
        disabled: getSubmitButtonDisabled(),
      },
    });
    const getGoBackAction: Function = () => () => {
      if (formView == 'selectArtists' || formView == 'selectArtistsFromSet') {
        return undefined;
      } else {
        return () => {
          switch (formView) {
            case 'mergeArtists':
              if (values.artistsSelectedFromSet) {
                return goToView('selectArtistsFromSet');
              } else {
                return goToView('selectArtists');
              }
            default:
              return goToView('selectArtists');
          }
        };
      }
    };
    const getGoForwardAction: Function = () => () => () => {
      switch (formView) {
        case 'selectArtists':
          return goToView('mergeArtists');
        case 'selectArtistsFromSet':
          return goToView('mergeArtists');
        default:
          return goToView('mergeArtists');
      }
    };
    setButtonConfigs && setButtonConfigs(getButtonConfigs());
    setGoBackAction && setGoBackAction(getGoBackAction());
    setGoForwardAction && setGoForwardAction(getGoForwardAction());
    setFormSubmitAction && setFormSubmitAction(() => formikProps.submitForm);
    setDisplayConfirmation && setDisplayConfirmation(formikProps.dirty);
    setIsSubmitting && setIsSubmitting(formikProps.isSubmitting);
  }, [
    formikProps.isSubmitting,
    formikProps.submitForm,
    formikProps.dirty,
    modalRef,
    setButtonConfigs,
    setGoBackAction,
    setGoForwardAction,
    setFormSubmitAction,
    setDisplayConfirmation,
    setIsSubmitting,
    formView,
    setFormView,
    values.artistsSelectedFromSet,
    values.artist1,
    values.artist2,
    values.title,
    values.email,
    values.cachedSlug,
  ]);

  return (
    <GenericFormContainer dataQaId="artist-merge-form">
      {formView == 'selectArtists' && (
        <ArtistMergeSelectArtists
          setFieldValue={setFieldValue}
          touched={touched}
          errors={errors}
          values={values}
        />
      )}
      {formView == 'selectArtistsFromSet' && (
        <ArtistMergeSelectArtistsFromSet
          setFieldValue={setFieldValue}
          touched={touched}
          errors={errors}
          values={values}
          duplicatedArtistTitle={duplicatedArtistTitle}
        />
      )}
      {formView == 'mergeArtists' && (
        <ArtistMergeMergeArtists
          setFieldValue={setFieldValue}
          touched={touched}
          errors={errors}
          values={values}
          validationErrors={validationErrors}
        />
      )}
    </GenericFormContainer>
  );
};

export default ArtistMergeForm;
