import gql from 'graphql-tag';

export const GET_GLOBAL_SETTINGS = gql`
  query GetGlobalSettings($keys: String) {
    globalSettings(keys: $keys) {
      globalSettings {
        id
        key
        title
        value
      }
    }
  }
`;
