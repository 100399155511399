import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { City } from 'app/typings/cities';
import {
  currencyFormatter,
  currencyLiteralUnitsFormatter,
  currencyRoundedFormatter,
} from 'app/shared/utils/currencyFormatter';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import BooleanSetting from 'app/admin/components/atoms/BooleanSetting';
import {
  DetailHeading as Heading,
  DetailValueContainer as ValueContainer,
} from 'app/admin/components/atoms/DetailContent';
import { None } from 'app/admin/components/atoms/None';
import { SimpleKeyValueInfoList } from 'app/admin/components/molecules/SimpleKeyValueInfoList';

interface Props {
  sectionData: City;
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const renderIntegerSetting = (value: number | undefined) =>
  value || value == 0 ? value : <None />;

const HelperText = styled.div`
  font-size: 10px;
  font-weight: 500;
  margin-top: 2px;
`;

const pricingTypeIsTiered = (sectionData: City) =>
  sectionData &&
  sectionData.tieredPrice &&
  (sectionData.tieredPrice.priceSun || sectionData.tieredPrice.priceSun == 0);

const SectionSettings: React.FC<Props> = ({ sectionData }) => {
  const intl = useIntl();

  const shortDaysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const tieredPricesKeysAndValues = shortDaysOfWeek.map(
    (day: string, index: number) => {
      const rawValue = sectionData.tieredPrice
        ? sectionData.tieredPrice['price' + shortDaysOfWeek[index]]
        : undefined;
      return {
        key: day,
        value:
          rawValue || rawValue == 0
            ? currencyLiteralUnitsFormatter(
                rawValue,
                sectionData.currencySymbol || '$'
              )
            : undefined,
      };
    }
  );

  return (
    <MainContainer data-qaid="section-settings">
      <Grid cols={4}>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={1}>
          <Heading>
            {intl.formatMessage({
              id: 'admin.citySettings.heading.defaultEventPrice',
            })}
          </Heading>
          <ValueContainer>
            {pricingTypeIsTiered(sectionData) ? (
              <None />
            ) : (
              currencyRoundedFormatter(
                sectionData.defaultEventPrice || 0,
                sectionData.currencySymbol || '$'
              )
            )}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.citySettings.heading.tieredPrice',
            })}
          </Heading>
          <SimpleKeyValueInfoList keysAndValues={tieredPricesKeysAndValues} />
          <Heading>
            {intl.formatMessage({
              id: 'admin.citySettings.heading.bookingFee',
            })}
          </Heading>
          <ValueContainer data-qaid="settings.bookingFee">
            {sectionData.bookingFee || sectionData.bookingFee == 0 ? (
              currencyFormatter(
                sectionData.bookingFee,
                sectionData.currencySymbol || '$'
              )
            ) : (
              <>
                <None />
                {(sectionData.country.bookingFee ||
                  sectionData.country.bookingFee == 0) && (
                  <HelperText>
                    {intl.formatMessage(
                      {
                        id: 'admin.citySettings.bookingFeeOverrideHelperText',
                      },
                      {
                        countryValue: currencyFormatter(
                          sectionData.country.bookingFee,
                          sectionData.currencySymbol || '$'
                        ),
                      }
                    )}
                  </HelperText>
                )}
              </>
            )}
          </ValueContainer>
          <BooleanSetting
            value={
              sectionData.displayBookingFee ||
              ((sectionData.displayBookingFee === undefined ||
                sectionData.displayBookingFee === null) &&
                sectionData.country.displayBookingFee)
            }
            headingText={intl.formatMessage({
              id: 'admin.citySettings.heading.displayBookingFee',
            })}
            helperText={
              sectionData.displayBookingFee === undefined ||
              sectionData.displayBookingFee === null
                ? intl.formatMessage({
                    id:
                      'admin.citySettings.displayBookingFeeOverrideHelperText',
                  })
                : undefined
            }
          />
          <BooleanSetting
            value={sectionData.isTieredArtistCompensationEnabled}
            headingText={intl.formatMessage({
              id: 'admin.citySettings.heading.userTieredArtistCompensation',
            })}
          />
        </Col>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={2}>
          <Heading>
            {intl.formatMessage({
              id: 'admin.citySettings.heading.maxTicketsPerAttendee',
            })}
          </Heading>
          <ValueContainer>
            {sectionData.maxTicketsPerAttendee || <None />}
          </ValueContainer>
          <BooleanSetting
            value={sectionData.isAutoInviteEnabled}
            headingText={intl.formatMessage({
              id: 'admin.citySettings.heading.isAutoInviteEnabled',
            })}
          />
          <Heading>
            {intl.formatMessage({
              id: 'admin.citySettings.heading.inviteFirstDayPercentage',
            })}
          </Heading>
          <ValueContainer>
            {renderIntegerSetting(sectionData.inviteFirstDayPercentage)}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.citySettings.heading.inviteSubsequentDaysPercentage',
            })}
          </Heading>
          <ValueContainer>
            {renderIntegerSetting(sectionData.inviteSubsequentDaysPercentage)}
          </ValueContainer>
          <BooleanSetting
            value={sectionData.isAutoStopApplyEnabled}
            headingText={intl.formatMessage({
              id: 'admin.citySettings.heading.isAutoStopApplyEnabled',
            })}
          />
        </Col>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={3}>
          <Heading>
            {intl.formatMessage({
              id: 'admin.citySettings.heading.maximumPromoCodeUses',
            })}
          </Heading>
          <ValueContainer>
            {renderIntegerSetting(sectionData.maximumPromoCodeUses)}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.citySettings.heading.maximumPercentagePromoCodeUses',
            })}
          </Heading>
          <ValueContainer>
            {renderIntegerSetting(sectionData.maximumPercentagePromoCodeUses)}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.citySettings.heading.maximumGuaranteedPromoCodeUses',
            })}
          </Heading>
          <ValueContainer>
            {renderIntegerSetting(sectionData.maximumGuaranteedPromoCodeUses)}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.citySettings.heading.maximumCustomPromoCodeUses',
            })}
          </Heading>
          <ValueContainer>
            {renderIntegerSetting(sectionData.maximumCustomPromoCodeUses)}
          </ValueContainer>
          <BooleanSetting
            value={sectionData.sendPromoCodesToFirstTimers}
            headingText={intl.formatMessage({
              id: 'admin.citySettings.heading.sendPromoCodesToFirstTimers',
            })}
          />
          <BooleanSetting
            value={sectionData.isUnluckyEmailPromoCodeEnabled}
            headingText={intl.formatMessage({
              id: 'admin.citySettings.heading.isUnluckyEmailPromoCodeEnabled',
            })}
          />
        </Col>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={4}>
          <BooleanSetting
            value={sectionData.topGlobalCity}
            headingText={intl.formatMessage({
              id: 'admin.citySettings.heading.topGlobalCity',
            })}
          />
          <BooleanSetting
            value={sectionData.topCity}
            headingText={intl.formatMessage({
              id: 'admin.citySettings.heading.topCity',
            })}
          />
          <BooleanSetting
            value={sectionData.noindex}
            headingText={intl.formatMessage({
              id: 'admin.citySettings.heading.noindex',
            })}
          />
        </Col>
      </Grid>
    </MainContainer>
  );
};

export default SectionSettings;
