import * as Yup from 'yup';

const internalUserBasicInfoFormSchema = (intl: any) =>
  Yup.object().shape({
    additionalEmail: Yup.string()
      .nullable()
      .email(
        intl.formatMessage(
          {
            id: 'form.fieldMustBeValidEmail',
          },
          {
            field: 'Additional email',
          }
        )
      ),
    slackUserId: Yup.string()
      .nullable()
      .matches(/^[A-Za-z0-9]+$/, {
        message: intl.formatMessage({
          id: 'admin.userBasicInfo.form.slackUserIdError',
        }),
      }),
    hrisId: Yup.string()
      .nullable()
      .when('hrisSystem', {
        is: hrisSystem => !!hrisSystem.value,
        then: Yup.string().required(
          intl.formatMessage({
            id: 'form.requiredField',
          })
        ),
      }),
    hrisSystemKey: Yup.string()
      .nullable()
      .when('hrisId', {
        is: hrisId => !!hrisId,
        then: Yup.string().required(
          intl.formatMessage({
            id: 'form.requiredField',
          })
        ),
      }),
  });

export default internalUserBasicInfoFormSchema;
