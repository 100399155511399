import React from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { get } from 'app/shared/utils/get';
import { withFlex } from 'app/shared/components/styleUtils/manualCSS/withFlex';
import { Link } from 'app/shared/components/atoms/LinkManualCSS';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { H2 } from 'app/shared/components/atoms/TypographyManualCSS';
import LoadingBlocks from 'app/shared/components/pages/Loading';
import { GlobalError } from 'app/shared/components/pages/Status';
import { GetVersion } from 'app/admin/graphql/versions/queryHooks';
import { UserLink } from 'app/admin/components/molecules/EntityLink';
import ListTable from 'app/admin/components/organisms/ListTable';
import ListPageTemplate from 'app/admin/components/templates/ListPage';
import Layout from 'app/admin/layouts/ListPage';

import { columnsConfig } from './config';
import { rowValue } from './utils';

const HeaderText = styled(H2)`
  ${({ theme }) => css`
    ${withFlex}
    column-gap: ${theme.ruler[2]}px;
  `}
`;

export const AdminVersion: React.FC = () => {
  const intl = useIntl();
  const params = useParams();
  const { loading, data, error } = GetVersion({
    id: parseInt(params.id || ''),
  });

  if (loading) {
    return <LoadingBlocks />;
  }

  if (error || !data) {
    return <GlobalError />;
  }

  const version = get(data, 'version.version', {});
  const {
    itemType,
    itemId,
    event,
    whodunnit,
    createdAt,
    versionTo,
    versionFrom,
  } = version;

  const orderedKeys = () => {
    const alphabetizedKeys = Object.keys(versionTo || versionFrom).sort();
    const alphabetizedKeysWithoutId = alphabetizedKeys.filter(
      key => key !== 'id'
    );
    return ['id', ...alphabetizedKeysWithoutId];
  };

  const changedBy = () => {
    return whodunnit ? <UserLink user={whodunnit} /> : 'Platform';
  };
  const changedAt = () => {
    if (createdAt) {
      return ` at ${new Date(createdAt).toUTCString()}`;
    } else if ((versionTo || versionFrom).updated_at) {
      return ` at ${new Date(
        (versionTo || versionFrom).updated_at
      ).toUTCString()}`;
    } else {
      return '';
    }
  };

  const changeTypes = {
    create: 'created',
    update: 'updated',
    destroy: 'destroyed',
    touch: 'touched',
  };

  const eventText = changeTypes[event] || 'changed';
  const headerText = `${itemType} ${itemId} ${eventText} by `;

  return (
    <Layout scrollDisabled={false}>
      <ListPageTemplate
        pageTitle={intl.formatMessage({
          id: 'admin.version.pageTitle',
        })}
      >
        <Link href="/admin/versions" onClick={history.back}>
          {intl.formatMessage({
            id: 'admin.version.goBack',
          })}
        </Link>
        <Spacer mb={4} />
        <HeaderText>
          {headerText}
          {changedBy()}
          {changedAt()}
        </HeaderText>
        <ListTable
          columnsConfig={columnsConfig}
          data={orderedKeys()}
          rowValues={(key: string) => rowValue({ version, key })}
          dataQaidPrefix="version"
        />
      </ListPageTemplate>
    </Layout>
  );
};

export default AdminVersion;
