import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { useCurrentTheme } from 'app/shared/theme';
import { dataGetter } from 'app/shared/utils/queries';
import useModal from 'app/shared/utils/useModal';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import ConfirmationModal from 'app/shared/components/molecules/ConfirmationModal';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { promoCodeStatusLabels } from 'app/admin/utils/promoCodes';
import { CancelPromoCode } from 'app/admin/graphql/promoCodes/mutationHooks';
import { GetPromoCode } from 'app/admin/graphql/promoCodes/queryHooks';
import { DetailsMainContainer } from 'app/admin/components/atoms/DetailContent';
import AccordionSections from 'app/admin/components/organisms/AccordionSections';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';
import { ReactComponent as PartyMusicDanceWoman } from 'icons/streamline-regular/entertainment-events-hobbies/party/party-music-dance-woman.svg';
import { ReactComponent as InformationCircle } from 'icons/streamline-regular/interface-essential/alerts/information-circle.svg';

import SectionBasicInfo from './SectionBasicInfo';
import SectionOrders from './SectionOrders';

interface Props {
  contentProps: any;
  navigateTo: (routeData: any) => void;
  hide: (hideProps?: any) => VoidFunction;
}

const detailsHeaderActionLinksInfo = (
  toggleCancelPromoCodeConfirmationModal: VoidFunction,
  canCancel: boolean,
  intl: any
) => {
  return {
    link1: {
      text: intl.formatMessage({
        id: 'admin.promoCodes.details.cancelPromoCode',
      }),
      active: canCancel,
      onClickAction: toggleCancelPromoCodeConfirmationModal,
    },
  };
};

const PromoCodeDetails: React.FC<Props> = ({
  contentProps,
  navigateTo,
  hide,
}) => {
  const { id, code, status } = contentProps;

  const intl = useIntl();
  const theme = useCurrentTheme();
  const [
    cancelPromoCodeConfirmationModal,
    toggleCancelPromoCodeConfirmationModal,
  ] = useModal();

  const [headerData, setHeaderData] = useState({
    code,
    status,
    isLoaded: !!code,
  });

  const [isCancellingPromoCode, setIsCancellingPromoCode] = useState<boolean>(
    false
  );

  const {
    loading: loadingBasicInfo,
    error: errorBasicInfo,
    data: dataBasicInfo,
  } = GetPromoCode({
    id,
    fetchPolicy: 'cache-and-network',
  });

  const getStatusTag = (status: string, intl: any, theme: any) => {
    const statusLabels = promoCodeStatusLabels(intl);
    const tagsConfig = {
      pending_start: {
        tagElementColor: theme.colors.mellowYellow,
        name: statusLabels.pending_start,
      },
      cancelled: {
        tagElementColor: theme.colors.redRedWine,
        name: statusLabels.cancelled,
      },
      expired: {
        tagElementColor: theme.colors.blueSmoke,
        name: statusLabels.expired,
      },
    };
    return tagsConfig[status];
  };

  const statusTag = getStatusTag(contentProps.status, intl, theme);

  const sectionsConfig = {
    basicInfo: {
      title: intl.formatMessage({
        id: 'admin.promoCodes.details.sectionTitle.basicInfo',
      }),
      icon: InformationCircle,
      iconColor: 'blueChristmas',
      sectionComponent: SectionBasicInfo,
      dataKey: 'promoCode',
      displayEditIcon: true,
      loading: loadingBasicInfo,
      data: dataBasicInfo ? dataBasicInfo.promoCode : undefined,
      callbacks: {
        toggleSectionEditModal: () =>
          navigateTo({
            routeName: 'promo-code-basic-info-edit',
            routeProps: dataBasicInfo ? dataBasicInfo.promoCode : undefined,
          }),
      },
      titleTags: statusTag ? [statusTag] : [],
    },
    orders: {
      title: intl.formatMessage({
        id: 'admin.promoCodes.details.sectionTitle.orders',
      }),
      icon: PartyMusicDanceWoman,
      iconColor: 'greenOnions',
      iconCircle: true,
      sectionComponent: SectionOrders,
      dataKey: 'promoCode',
      displayEditIcon: false,
      loading: loadingBasicInfo,
      data: dataBasicInfo ? dataBasicInfo.promoCode : undefined,
      callbacks: {},
    },
  };

  useEffect(() => {
    const getDataBasicInfo = dataGetter(dataBasicInfo, 'promoCode');
    if (dataBasicInfo) {
      setHeaderData({
        code: getDataBasicInfo('code'),
        status: getDataBasicInfo('status'),
        isLoaded: true,
      });
    }
  }, [dataBasicInfo, loadingBasicInfo]);

  const cancelPromoCodeAction = CancelPromoCode();

  const handleCancelPromoCode = useSubmitAction({
    beforeSubmit: () => setIsCancellingPromoCode(true),
    submitAction: cancelPromoCodeAction,
    submitVariables: () => ({
      id: contentProps.id,
    }),
    successMsg: intl.formatMessage(
      {
        id: 'admin.promoCodes.details.cancelSuccessMessage',
      },
      {
        code: contentProps.code,
      }
    ),
    failureMsg: intl.formatMessage({
      id: 'admin.promoCodes.details.cancelFailureMessage',
    }),
    onSuccess: () => {
      setIsCancellingPromoCode(false);
      hide({ shouldRefetchPromoCodes: true });
    },
  });

  if ((!loadingBasicInfo && !dataBasicInfo) || errorBasicInfo) {
    hide();
    return null;
  }

  return (
    <>
      <ModalContentContainer data-qaid="promo-code-details-main-modal">
        <DetailsMainContainer>
          <DetailsHeader
            title={headerData.code}
            actionLinksInfo={detailsHeaderActionLinksInfo(
              toggleCancelPromoCodeConfirmationModal,
              headerData.status == 'active' ||
                headerData.status == 'pending_start',
              intl
            )}
            loading={!headerData.isLoaded}
            nowrap={true}
          />
          <AccordionSections
            sectionsConfig={sectionsConfig}
            defaultOpenSection={contentProps.defaultOpenSection}
          />
        </DetailsMainContainer>
      </ModalContentContainer>
      {cancelPromoCodeConfirmationModal.isShowing && (
        <ConfirmationModal
          onCancel={() => cancelPromoCodeConfirmationModal.hide()}
          description={intl.formatMessage(
            {
              id: 'admin.promoCodes.details.cancelConfirm',
            },
            {
              code: contentProps.code,
            }
          )}
          onConfirm={handleCancelPromoCode}
          isLoading={isCancellingPromoCode}
          confirmationButtonText={intl.formatMessage({
            id: 'admin.promoCodes.details.cancelConfirmButtonText',
          })}
        />
      )}
    </>
  );
};

export default PromoCodeDetails;
