import React from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import SEO from 'app/shared/components/molecules/SEO';
import AcknowledgeUserAgreement from 'app/admin/components/templates/AcknowledgeUserAgreement';
import Layout from 'app/admin/layouts/AcknowledgeUserAgreement';

const BodyText = styled.p`
  text-align: left;
  margin-bottom: 30px;
`;

const AdminConfidentialityAgreement: React.FC = () => {
  const intl = useIntl();
  const location = useLocation();

  return (
    <Layout>
      <SEO title="Admin Confidentiality Agreement" />

      <AcknowledgeUserAgreement
        location={location}
        agreementName="confidentiality"
        actions={['acknowledge']}
        agreementTitle={intl.formatMessage({
          id: 'admin.confidentialityAgreement.title',
        })}
        agreementIntroContent="Out of respect for the privacy of the Sofar community
      as well as to remain in compliance with various privacy regulations worldwide,
      we need all team members accessing our administration platform to agree to
      the following confidentiality agreement. Please let your manager know
      if you have questions."
      >
        <>
          <BodyText>
            I understand that, in accessing this platform and in my other
            activities representing Sofar Sounds, I will be provided with
            information by Sofar in confidence (“Confidential Information”) and
            will not utilize or disclose Confidential Information verbally or in
            writing for any reason, other than in connection with the
            performance of services, on behalf of and as authorized by Sofar.
          </BodyText>
          <BodyText>
            &quot;Confidential Information&quot; means any Sofar proprietary
            information including all data concerning customers, artists, and
            hosts as well as finances or other business information.
          </BodyText>
        </>
      </AcknowledgeUserAgreement>
    </Layout>
  );
};

export default AdminConfidentialityAgreement;
