import React from 'react';
import { useIntl } from 'react-intl';

import { errorMsgForField } from 'app/shared/utils/form';
import {
  FormGroupContainer,
  FormSectionTitle,
} from 'app/shared/components/atoms/FormContent';
import GenericFormContainer from 'app/shared/components/atoms/GenericFormContainer';
import CheckboxGroup from 'app/shared/components/molecules/CheckboxGroup';
import FormGroup from 'app/shared/components/molecules/FormGroup';
import RadioGroup from 'app/shared/components/molecules/RadioGroup';
import { availableDayOptions } from 'app/admin/utils/venues';
import RichTextEditor from 'app/admin/components/molecules/RichTextEditor';

interface FormProps {
  formikProps: any;
}

const hostingFrequencyOptions = [
  {
    title: 'Once',
    value: 'once',
  },
  {
    title: 'Weekly',
    value: 'weekly',
  },
  {
    title: 'Monthly',
    value: 'monthly',
  },
  {
    title: 'Every 2 months',
    value: 'every_2_months',
  },
  {
    title: 'Every 3 months',
    value: 'every_3_months',
  },
  {
    title: 'Unknown',
    value: undefined,
  },
];

const VenueBookingInformationEditForm: React.FC<FormProps> = ({
  formikProps,
}) => {
  const intl = useIntl();

  const { setFieldValue, touched, errors, values } = formikProps;

  return (
    <GenericFormContainer dataQaId="venue-booking-information-section-edit-form">
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.venueBookingInformation.dayOfWeekAvailability',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <CheckboxGroup
          name="availableDays"
          options={availableDayOptions}
          selectedValues={values.availableDays}
          onChange={values => setFieldValue('availableDays', values)}
          colProps={{ xs: 12 }}
          gridProps={{ gap: '16px' }}
        />
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.venueBookingInformation.bookingFrequency',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <RadioGroup
          name="hostingFrequencyOptions"
          data-qaid="booking-frequency-options"
          options={hostingFrequencyOptions}
          selectedValue={values.hostingFrequency}
          spaceAboveGroup={false}
          onChange={value => {
            setFieldValue('hostingFrequency', value);
          }}
        />
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.venueBookingInformation.availabilityNotes',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="availability-notes-formgroup"
          label={intl.formatMessage({
            id: 'admin.venueBookingInformation.availabilityNotes',
          })}
          errorMsg={errorMsgForField('availabilityNotes', touched, errors)}
        >
          <RichTextEditor
            name="availability-notes-editor"
            currentValue={values.availabilityNotes}
            onChange={(value: any) => setFieldValue('availabilityNotes', value)}
          />
        </FormGroup>
      </FormGroupContainer>
    </GenericFormContainer>
  );
};

export default VenueBookingInformationEditForm;
