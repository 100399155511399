import React, { useContext } from 'react';
import { useIntl } from 'react-intl';

import { Event } from 'app/typings';
import { SlackMessage } from 'app/typings/slacks';
import { CrewMessage } from 'app/typings/staffMembers';
import { AuthContext } from 'app/shared/context/Auth';
import { SectionText } from 'app/admin/components/atoms/CrewEventContent';

import FailedCancellationSlackMessages from './FailedCancellationSlackMessages';
import { renderCrewMessage } from './shared';
import { CrewMessageContainer, ToggleContainer, ToggleText } from './shared';

interface AdminEventBreakingNewsProps {
  messages: CrewMessage[];
  failedCancellationMessages: SlackMessage[];
  event: Event;
  eventId: number;
  isAdmin: boolean;
  onRemove?: (id: number) => void;
  truncateMessage?: boolean;
  navigateTo: any;
}

const AdminEventBreakingNews: React.FC<AdminEventBreakingNewsProps> = ({
  messages = [],
  failedCancellationMessages = [],
  event,
  eventId,
  onRemove,
  isAdmin,
  truncateMessage,
  navigateTo,
}) => {
  const intl = useIntl();
  const { user } = useContext(AuthContext);
  const numMessagesShownInitially = 3;
  const initialMessages = messages.slice(0, numMessagesShownInitially);
  const lastMessageIndex =
    messages.length < numMessagesShownInitially
      ? messages.length - 1
      : numMessagesShownInitially - 1;

  return (
    <div>
      {messages.length === 0 && (
        <SectionText>
          <CrewMessageContainer>
            {intl.formatMessage({
              id: 'admin.crewEvent.section.breakingNews.noNews',
            })}
          </CrewMessageContainer>
        </SectionText>
      )}
      {messages.length > numMessagesShownInitially ? (
        <>
          {initialMessages.map((message, index) =>
            renderCrewMessage(
              message,
              intl,
              lastMessageIndex,
              index,
              isAdmin,
              user,
              onRemove,
              truncateMessage
            )
          )}
          <ToggleContainer data-qaid="crew-messages-more-toggle-container">
            <ToggleText
              data-qaid="crew-messages-more-toggle-btn"
              onClick={() =>
                navigateTo({
                  routeName: 'crew-all-messages',
                  routeProps: {
                    eventId,
                    messages,
                    isAdmin,
                    user,
                    onRemove,
                  },
                  dataQaidSuffix: 'crew-all-messages',
                })
              }
              isAdmin
            >
              {intl.formatMessage({
                id: 'admin.crewEvent.section.breakingNews.viewMoreBreakingNews',
              })}
            </ToggleText>
          </ToggleContainer>
        </>
      ) : (
        messages.map((message, index) =>
          renderCrewMessage(
            message,
            intl,
            lastMessageIndex,
            index,
            isAdmin,
            user,
            onRemove,
            truncateMessage
          )
        )
      )}
      <FailedCancellationSlackMessages
        event={event}
        failedMessages={failedCancellationMessages}
      />
    </div>
  );
};

export default AdminEventBreakingNews;
