import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { PrimaryRole } from 'app/typings/roles';
import { Ability, AbilityContext } from 'app/typings/userAbilities';
import { get } from 'app/shared/utils/get';
import useModal from 'app/shared/utils/useModal';
import usePermission from 'app/shared/utils/usePermission';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import { PrimaryButton } from 'app/shared/components/atoms/Button';
import { Checkbox } from 'app/shared/components/atoms/CheckboxManualCSS';
import { Container } from 'app/shared/components/atoms/Container';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import { BaseLink } from 'app/shared/components/atoms/LinkManualCSS';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { StreamlineIcon } from 'app/shared/components/atoms/StreamlineIcon';
import { H1, Overline } from 'app/shared/components/atoms/TypographyManualCSS';
import RoutableModal from 'app/shared/components/molecules/RoutableModal';
import Select from 'app/shared/components/molecules/SelectManualCSS';
import SEO from 'app/shared/components/molecules/SEO';
import { GlobalError } from 'app/shared/components/pages/Status';
import { getSlice } from 'app/admin/utils/array';
import {
  getPrimaryRoleOptions,
  getUserAbilityOptions,
} from 'app/admin/utils/optionLists';
import {
  roleResourceTypeLabels,
  roleResourceTypes,
} from 'app/admin/utils/users';
import { UpdatePermissions } from 'app/admin/graphql/permissions/mutationHooks';
import { RawPermission } from 'app/admin/graphql/permissions/queryHooks';
import { GetRawPermissions } from 'app/admin/graphql/permissions/queryHooks';
import { GetPrimaryRoles } from 'app/admin/graphql/roles/queryHooks';
import { GetUserAbilities } from 'app/admin/graphql/userAbilities/queryHooks';
import EditIconAndTextLink from 'app/admin/components/atoms/EditIconAndTextLink';
import Layout from 'app/admin/layouts/Admin';
import { ReactComponent as CheckCircle1 } from 'icons/streamline-regular/interface-essential/form-validation/check-circle-1.svg';

// @ts-ignore
const MainContainer = styled(Container)`
  @media screen and (min-width: 1300px) {
  }
  margin-bottom: 40px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: auto;
  margin-bottom: 50px;
`;

const Title = styled.div`
  margin-bottom: -50px;
`;

const TopHelpText = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 35px;
`;

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 80px;
`;

const SelectLabel = styled.div`
  font-weight: 600;
  margin-bottom: 7px;
`;

const SelectRole = styled.div`
  width: 400px;
`;

const SelectAbility = styled.div`
  width: 400px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  height: 80px;
`;

const UpdateButton = styled.div`
  width: 120px;
`;

const ListHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 25px;
`;

const ListTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 7px;
`;

const ListRoleLink = styled.div`
  ${({ theme }) => css`
    font-size: 14px;
    margin-bottom: 15px;
    a {
      color: ${theme.colors.green600};
    }
  `}
`;

const ListAbilityDescription = styled.div`
  font-size: 14px;
  margin-bottom: 15px;
`;

const EmptySpace = styled.div`
  height: 200px;
`;

const ListRoles = styled.div`
  margin-bottom: 25px;
`;

const ListAbilities = styled.div`
  margin-bottom: 20px;
`;

const StyledCheckbox = styled(Checkbox)`
  vertical-align: top;
  white-space: nowrap;
`;

const CheckboxWrapper = styled.div`
  margin-bottom: 20px;
`;

const AbilityGroup = styled.div`
  margin-bottom: 45px;
`;

const AbilityGroupTitle = styled.div`
  margin-bottom: 22px;
`;

const AbilityGroupList = styled.div`
  margin-bottom: 20px;
`;

const AbilityDescription = styled.div`
  ${({ theme }) => css`
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.1px;
    font-style: normal;
    font-weight: normal;
    color: ${theme.colors.darkSmoke};
    margin-top: 3px;
    margin-left: 30px;
  `}
`;

const ValidationError = styled.div`
  ${({ theme }) => css`
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.3px;
    font-weight: normal;
    margin-top: 20px;
    margin-bottom: 35px;
    color: ${theme.colors.redRedWine};
  `}
`;

const ValueDisplayWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

const ValueDisplayIcon = styled.div`
  width: 23px;
  margin-right: 7px;
`;

const ValueDisplayText = styled.div`
  padding-left: 5px;
`;

const ValueDisplayAbilityDescription = styled(AbilityDescription)`
  margin-left: 35px;
`;

const SelectedIconWrapper = styled.div`
  margin-bottom: -8px;
`;

const SelectedIcon: React.FC = () => (
  <SelectedIconWrapper>
    <StreamlineIcon icon={CheckCircle1} size={24} stroke="#039842" />
  </SelectedIconWrapper>
);

const AdminRolesAndPermissions: React.FC = () => {
  const intl = useIntl();

  const hasEditPermissionsPermission = usePermission('permission.edit');
  const hasEditPrimaryRolesPermission = usePermission(
    'permission.primaryRole.edit'
  );
  const hasViewInternalUsersPermission = usePermission(
    'user.internalList.view'
  );

  const [filterInfo, setFilterInfo] = useState<any>({
    roleKey: null,
    abilityKey: null,
    unsetRoleKey: false,
    unsetAbilityKey: false,
  });
  const [selectedRoleKeys, setSelectedRoleKeys] = useState([]);
  const [selectedAbilityKeys, setSelectedAbilityKeys] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [primaryRolesEditModal, togglePrimaryRolesEditModal] = useModal();
  const [validationError, setValidationError] = useState('');

  const {
    loading: loadingPrimaryRoles,
    error: errorPrimaryRoles,
    data: dataPrimaryRoles,
  } = GetPrimaryRoles({
    resourceTypes: roleResourceTypes,
  });

  const {
    loading: loadingUserAbilities,
    error: errorUserAbilities,
    data: dataUserAbilities,
  } = GetUserAbilities();

  const {
    loading: loadingRawPermissions,
    error: errorRawPermissions,
    data: dataRawPermissions,
    refetch: refetchRawPermissions,
  } = GetRawPermissions({
    roleKey: filterInfo.roleKey,
    abilityKey: filterInfo.abilityKey,
  });

  const primaryRoleOptions = getPrimaryRoleOptions(dataPrimaryRoles);

  const userAbilityOptions = getUserAbilityOptions(dataUserAbilities);

  const roleIsSelected = (roleKey: string) => {
    if (selectedRoleKeys && selectedRoleKeys.length > 0) {
      // @ts-ignore
      return selectedRoleKeys.includes(roleKey);
    }
    return false;
  };

  const abilityIsSelected = (abilityKey: string) => {
    if (selectedAbilityKeys && selectedAbilityKeys.length > 0) {
      // @ts-ignore
      return selectedAbilityKeys.includes(abilityKey);
    }
    return false;
  };

  const selectRole = (roleKey: string) => {
    // @ts-ignore
    if (!selectedRoleKeys.includes(roleKey)) {
      // @ts-ignore
      setSelectedRoleKeys(selectedRoleKeys.concat([roleKey]));
    }
  };

  const unselectRole = (roleKey: string) => {
    setSelectedRoleKeys(selectedRoleKeys.filter((k: string) => k != roleKey));
  };

  const selectAbility = (abilityKey: string) => {
    // @ts-ignore
    if (!selectedAbilityKeys.includes(abilityKey)) {
      // @ts-ignore
      setSelectedAbilityKeys(selectedAbilityKeys.concat([abilityKey]));
    }
  };

  const unselectAbility = (abilityKey: string) => {
    setSelectedAbilityKeys(
      selectedAbilityKeys.filter((k: string) => k != abilityKey)
    );
  };

  useEffect(() => {
    if (dataRawPermissions && dataRawPermissions.rawPermissions) {
      if (filterInfo.roleKey) {
        setSelectedRoleKeys([]);
        setSelectedAbilityKeys(
          // @ts-ignore
          dataRawPermissions.rawPermissions.rawPermissions.map(
            (rp: RawPermission) => rp.abilityKey
          )
        );
        setValidationError('');
      } else if (filterInfo.abilityKey) {
        setSelectedRoleKeys(
          // @ts-ignore
          dataRawPermissions.rawPermissions.rawPermissions.map(
            (rp: RawPermission) => rp.roleKey
          )
        );
        setSelectedAbilityKeys([]);
        setValidationError('');
      }
    }
  }, [dataRawPermissions, filterInfo.abilityKey, filterInfo.roleKey]);

  const updatePermissionsAction = UpdatePermissions();

  const handleUpdatePermissions = useSubmitAction({
    beforeSubmit: () => setIsSubmitting(true),
    submitAction: updatePermissionsAction,
    submitVariables: () => ({
      roleKey: filterInfo.roleKey,
      abilityKey: filterInfo.abilityKey,
      roleKeysForAbilityKey: selectedRoleKeys,
      abilityKeysForRoleKey: selectedAbilityKeys,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.rolesAndPermissions.form.successMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.rolesAndPermissions.form.failureMessage',
    }),
    onSuccess: () => {
      setIsSubmitting(false);
      refetchRawPermissions();
    },
  });

  const permissionsAccessRemovedFromAdmin = () => {
    if (
      filterInfo.roleKey == 'admin' &&
      (!abilityIsSelected('permission.list.view') ||
        !abilityIsSelected('permission.edit'))
    ) {
      return true;
    } else if (
      (filterInfo.abilityKey == 'permission.list.view' ||
        filterInfo.abilityKey == 'permission.edit') &&
      !roleIsSelected('admin')
    ) {
      return true;
    }
    return false;
  };

  const VALIDATION_ERROR_REMOVED_PERMISSIONS_ACCESS_FROM_ADMIN = intl.formatMessage(
    {
      id: 'admin.rolesAndPermissions.validationErrorRemovedPermissionsAccess',
    }
  );

  const handleUpdatePermissionsClick = () => {
    if (permissionsAccessRemovedFromAdmin()) {
      setValidationError(
        VALIDATION_ERROR_REMOVED_PERMISSIONS_ACCESS_FROM_ADMIN
      );
    } else {
      setValidationError('');
      handleUpdatePermissions();
    }
  };

  if (
    (!loadingPrimaryRoles && !dataPrimaryRoles) ||
    errorPrimaryRoles ||
    (!loadingUserAbilities && !dataUserAbilities) ||
    errorUserAbilities ||
    (!loadingRawPermissions && !dataRawPermissions) ||
    errorRawPermissions
  ) {
    return <GlobalError />;
  }

  const getRoleField = (roleKey: string, fieldName: string) => {
    const primaryRole =
      dataPrimaryRoles &&
      dataPrimaryRoles.primaryRoles &&
      dataPrimaryRoles.primaryRoles.primaryRoles.find(
        (pr: PrimaryRole) => pr.key == roleKey
      );
    if (primaryRole) {
      return primaryRole[fieldName];
    }
    return null;
  };

  const getAbilityField = (abilityKey: string, fieldName: string) => {
    const userAbility =
      dataUserAbilities &&
      dataUserAbilities.userAbilities &&
      dataUserAbilities.userAbilities.abilities.find(
        (ua: Ability) => ua.key == abilityKey
      );
    if (userAbility) {
      return userAbility[fieldName];
    }
    return null;
  };

  const getRoleFullName = (roleKey: string) => {
    const name = getRoleField(roleKey, 'name');
    const resourceType = getRoleField(roleKey, 'resourceType');
    return resourceType
      ? `${name} (By ${roleResourceTypeLabels[resourceType] || resourceType})`
      : name;
  };

  const getGroupedUserAbilities = () => {
    const abilities = {};
    for (const ability of get(
      dataUserAbilities,
      'userAbilities.abilities',
      []
    )) {
      abilities[ability.contextName] = abilities[ability.contextName] || [];
      abilities[ability.contextName].push(ability);
    }
    return abilities;
  };

  const renderHeader = () => (
    <Header>
      <Title>
        <H1>
          {intl.formatMessage({
            id: 'admin.rolesAndPermissions.pageTitle',
          })}
        </H1>
      </Title>
      {hasEditPrimaryRolesPermission && (
        <EditIconAndTextLink
          text={intl.formatMessage({
            id: 'admin.rolesAndPermissions.editRoles',
          })}
          onClick={togglePrimaryRolesEditModal}
        />
      )}
    </Header>
  );

  const renderSelectAbility = () => (
    <SelectWrapper>
      <SelectLabel>
        {intl.formatMessage({
          id: 'admin.rolesAndPermissions.selectPermission',
        })}
      </SelectLabel>
      <SelectAbility>
        <Select
          data-qaid="roles-and-permissions-ability-select"
          name="abilityKey"
          id="abilityKey"
          value={filterInfo.unsetAbilityKey ? null : filterInfo.abilityKey}
          onChange={option => {
            setFilterInfo({
              abilityKey: filterInfo.unsetAbilityKey
                ? null
                : option
                ? option.value
                : null,
              roleKey: filterInfo.unsetAbilityKey ? filterInfo.roleKey : null,
              unsetAbilityKey: false,
              unsetRoleKey:
                filterInfo.unsetAbilityKey || !filterInfo.roleKey
                  ? false
                  : true,
            });
          }}
          options={userAbilityOptions}
          groupBy={(opt: any) => opt.contextName}
          getOptionLabel={(opt: any) => opt.title}
          placeholder={intl.formatMessage({
            id: 'admin.rolesAndPermissions.genericPlaceholder',
          })}
          searchable
        />
      </SelectAbility>
    </SelectWrapper>
  );

  const renderSelectRole = () => (
    <SelectWrapper>
      <SelectLabel>
        {intl.formatMessage({
          id: 'admin.rolesAndPermissions.selectRole',
        })}
      </SelectLabel>
      <SelectRole>
        <Select
          data-qaid="roles-and-permissions-role-select"
          name="roleKey"
          id="roleKey"
          value={filterInfo.unsetRoleKey ? null : filterInfo.roleKey}
          onChange={option => {
            setFilterInfo({
              roleKey: filterInfo.unsetRoleKey
                ? null
                : option
                ? option.value
                : null,
              abilityKey: filterInfo.unsetRoleKey
                ? filterInfo.abilityKey
                : null,
              unsetRoleKey: false,
              unsetAbilityKey:
                filterInfo.unsetRoleKey || !filterInfo.abilityKey
                  ? false
                  : true,
            });
          }}
          options={primaryRoleOptions}
          getOptionLabel={(opt: any) => opt.title}
          placeholder={intl.formatMessage({
            id: 'admin.rolesAndPermissions.genericPlaceholder',
          })}
          searchable
        />
      </SelectRole>
    </SelectWrapper>
  );

  const renderListHeader = () => (
    <ListHeader>
      <ListTitle>
        {filterInfo.roleKey
          ? intl.formatMessage(
              {
                id: 'admin.rolesAndPermissions.listTitleRole',
              },
              {
                roleName: getRoleFullName(filterInfo.roleKey),
              }
            )
          : intl.formatMessage(
              {
                id: 'admin.rolesAndPermissions.listTitleAbility',
              },
              {
                abilityName: getAbilityField(filterInfo.abilityKey, 'name'),
              }
            )}
      </ListTitle>
      {filterInfo.roleKey && hasViewInternalUsersPermission && (
        <ListRoleLink>
          <BaseLink
            href={`/admin/internal-users?role=${filterInfo.roleKey}`}
            openInNewTab={true}
          >
            {intl.formatMessage({
              id: 'admin.rolesAndPermissions.viewInternalUsers',
            })}
          </BaseLink>
        </ListRoleLink>
      )}
      {filterInfo.abilityKey && (
        <ListAbilityDescription>
          {getAbilityField(filterInfo.abilityKey, 'description')}
        </ListAbilityDescription>
      )}
    </ListHeader>
  );

  const renderUserAbilities = (
    contextNames: string[],
    groupedUserAbilities: any
  ) =>
    contextNames.map((contextName: string, indexGroup: number) => {
      const userAbilities = groupedUserAbilities[contextName];
      if (!userAbilities) {
        return null;
      }
      return (
        <AbilityGroup key={indexGroup}>
          <AbilityGroupTitle>
            <Overline>{contextName}</Overline>
          </AbilityGroupTitle>
          <AbilityGroupList>
            {userAbilities.map((userAbility: Ability, index: number) => (
              <CheckboxWrapper key={index}>
                {hasEditPermissionsPermission ? (
                  <>
                    <StyledCheckbox
                      id={`role-${userAbility.key}`}
                      name={`role-${userAbility.key}`}
                      onChange={(event: any) => {
                        if (event.target.checked) {
                          selectAbility(userAbility.key);
                        } else {
                          unselectAbility(userAbility.key);
                        }
                      }}
                      checked={abilityIsSelected(userAbility.key)}
                    >
                      {userAbility.name}
                    </StyledCheckbox>
                    <AbilityDescription>
                      {userAbility.description}
                    </AbilityDescription>
                  </>
                ) : (
                  <>
                    <ValueDisplayWrapper>
                      <ValueDisplayIcon>
                        {abilityIsSelected(userAbility.key) && <SelectedIcon />}
                      </ValueDisplayIcon>
                      <ValueDisplayText>{userAbility.name}</ValueDisplayText>
                    </ValueDisplayWrapper>
                    <ValueDisplayAbilityDescription>
                      {userAbility.description}
                    </ValueDisplayAbilityDescription>
                  </>
                )}
              </CheckboxWrapper>
            ))}
          </AbilityGroupList>
        </AbilityGroup>
      );
    });

  const renderPrimaryRoles = (primaryRoles: PrimaryRole[]) =>
    primaryRoles.map((primaryRole: PrimaryRole, index: number) => (
      <CheckboxWrapper key={index}>
        {hasEditPermissionsPermission ? (
          <StyledCheckbox
            id={`role-${primaryRole.key}`}
            name={`role-${primaryRole.key}`}
            onChange={(event: any) => {
              if (event.target.checked) {
                selectRole(primaryRole.key);
              } else {
                unselectRole(primaryRole.key);
              }
            }}
            checked={roleIsSelected(primaryRole.key)}
          >
            {getRoleFullName(primaryRole.key)}
          </StyledCheckbox>
        ) : (
          <ValueDisplayWrapper>
            <ValueDisplayIcon>
              {roleIsSelected(primaryRole.key) && <SelectedIcon />}
            </ValueDisplayIcon>
            <ValueDisplayText>
              {getRoleFullName(primaryRole.key)}
            </ValueDisplayText>
          </ValueDisplayWrapper>
        )}
      </CheckboxWrapper>
    ));

  const renderActionButtons = () => (
    <UpdateButton>
      <PrimaryButton
        data-qaid="roles-and-permissions-update-btn"
        onClick={handleUpdatePermissionsClick}
        loading={isSubmitting}
        type="button"
        block
      >
        {intl.formatMessage({
          id: 'admin.rolesAndPermissions.update',
        })}
      </PrimaryButton>
    </UpdateButton>
  );

  const groupedUserAbilities = getGroupedUserAbilities();

  const contextNames = get(
    dataUserAbilities,
    'userAbilities.abilityContexts',
    []
  ).map((abilityContext: AbilityContext) => abilityContext.name);

  // For user abilities, initially we automatically divided up the contexts evenly across the columns, but over time, as
  // permissions were added and removed, some contexts became disproportionately large, hence the columns varied hugely in
  // length - we prefer to minimize scrolling to view this page - so instead now we manually assign the contexts to the 3
  // columns - yes, it means that as permissions are added and removed or new contexts are added, we'll have to adjust these
  // column assingments - but it's easy to do and worth the UI improvement
  //
  // IMPORTANT - For last column we don't specify an end index, so that if a new context gets added, it will at least
  // appear, not just be omitted - we can then recalibrate the column assignments as needed
  const contextNamesCol1 = contextNames.slice(0, 16);
  const contextNamesCol2 = contextNames.slice(16, 18);
  const contextNamesCol3 = contextNames.slice(18);

  // For primary roles, we automatically divide up the contexts evenly across the columns, since, unlike user abilities,
  // the length of each item in the list is the same (just the name of the primary role), so we don't have to worry about
  // the columns varying in length
  const primaryRolesCol1 = getSlice(
    get(dataPrimaryRoles, 'primaryRoles.primaryRoles', []),
    1,
    3
  );
  const primaryRolesCol2 = getSlice(
    get(dataPrimaryRoles, 'primaryRoles.primaryRoles', []),
    2,
    3
  );
  const primaryRolesCol3 = getSlice(
    get(dataPrimaryRoles, 'primaryRoles.primaryRoles', []),
    3,
    3
  );

  return (
    <Layout data-qaid="roles-and-permissions">
      <SEO title="Admin Roles and Permissions" />
      <Spacer mb={8} />
      <MainContainer>
        {renderHeader()}
        <TopHelpText>
          {intl.formatMessage({
            id: 'admin.rolesAndPermissions.selectHelpText',
          })}
        </TopHelpText>
        <Grid cols={3}>
          <Col xs={12} sm={12} md={1} lg={1} xl={1} key={1}>
            {renderSelectRole()}
          </Col>
          <Col xs={12} sm={12} md={1} lg={1} xl={1} key={2}>
            {renderSelectAbility()}
          </Col>
          <Col xs={12} sm={12} md={1} lg={1} xl={1} key={3}>
            {hasEditPermissionsPermission &&
              (filterInfo.roleKey || filterInfo.abilityKey) && (
                <ButtonWrapper>{renderActionButtons()}</ButtonWrapper>
              )}
          </Col>
        </Grid>
        <Spacer mb={10} />
        {filterInfo.roleKey || filterInfo.abilityKey ? (
          renderListHeader()
        ) : (
          <EmptySpace />
        )}
        {validationError && (
          <ValidationError>{validationError}</ValidationError>
        )}
        {filterInfo.roleKey && (
          <ListAbilities data-qaid="roles-and-permissions-list-abilities">
            <Grid cols={3}>
              <Col xs={12} sm={12} md={1} lg={1} xl={1} key={1}>
                {renderUserAbilities(contextNamesCol1, groupedUserAbilities)}
              </Col>
              <Col xs={12} sm={12} md={1} lg={1} xl={1} key={2}>
                {renderUserAbilities(contextNamesCol2, groupedUserAbilities)}
              </Col>
              <Col xs={12} sm={12} md={1} lg={1} xl={1} key={3}>
                {renderUserAbilities(contextNamesCol3, groupedUserAbilities)}
              </Col>
            </Grid>
          </ListAbilities>
        )}
        {filterInfo.abilityKey && (
          <ListRoles data-qaid="roles-and-permissions-list-roles">
            <Grid cols={3}>
              <Col xs={12} sm={12} md={1} lg={1} xl={1} key={1}>
                {renderPrimaryRoles(primaryRolesCol1)}
              </Col>
              <Col xs={12} sm={12} md={1} lg={1} xl={1} key={2}>
                {renderPrimaryRoles(primaryRolesCol2)}
              </Col>
              <Col xs={12} sm={12} md={1} lg={1} xl={1} key={3}>
                {renderPrimaryRoles(primaryRolesCol3)}
              </Col>
            </Grid>
          </ListRoles>
        )}
        {validationError && (
          <ValidationError>{validationError}</ValidationError>
        )}
        {hasEditPermissionsPermission &&
          (filterInfo.roleKey || filterInfo.abilityKey) &&
          renderActionButtons()}
      </MainContainer>
      {primaryRolesEditModal.isShowing && (
        <RoutableModal
          hide={primaryRolesEditModal.hide}
          initialRouteProps={{}}
          initialRouteName="primary-roles"
          dataQaidSuffix="admin-edit-primary-roles"
        />
      )}
    </Layout>
  );
};

export default AdminRolesAndPermissions;
