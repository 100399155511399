import React from 'react';
import styled from 'styled-components';

import DottedLine from 'app/shared/components/atoms/DottedLine';
import {
  ArchiveIcon,
  DeleteIcon,
  EditIcon,
  ViewIcon,
} from 'app/shared/components/atoms/IconLibrary';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Overline } from 'app/shared/components/atoms/TypographyManualCSS';
import Flyover from 'app/shared/components/molecules/SimpleFlyover';

interface ColumnConfig {
  headerText?: string;
  columnWidth: number;
  isCentered?: boolean;
}

interface DetailsFlyoverInfo {
  overline: string;
  title: (obj: any) => string;
  body: (obj: any) => string;
  footer?: (obj: any) => string;
}

interface Props {
  columnsConfig: ColumnConfig[];
  rowValues: (obj: any) => any[];
  detailsFlyoverInfo?: DetailsFlyoverInfo;
  onEdit?: (obj: any) => void;
  onDelete?: (obj: any) => void;
  onArchive?: (obj: any) => void;
  showEdit?: (obj: any) => boolean;
  showDelete?: (obj: any) => boolean;
  showArchive?: (obj: any) => boolean;
  data?: any[];
  loadingContent?: any;
  noResultsContent?: any;
  footerContent?: any;
  dataQaidPrefix?: string;
}

interface ActionsConfig {
  detailsFlyoverInfo: DetailsFlyoverInfo | undefined;
  onEdit: ((obj: any) => void) | undefined;
  onDelete: ((obj: any) => void) | undefined;
  onArchive: ((obj: any) => void) | undefined;
  showEdit: (obj: any) => boolean;
  showDelete: (obj: any) => boolean;
  showArchive: (obj: any) => boolean;
}

interface ListWrapperProps {
  width: string;
}

interface HeaderRowContainerProps {
  width: string;
}

interface HeaderRowCellProps {
  width: string;
  centered?: boolean;
}

interface RowContainerProps {
  width: string;
  shaded: boolean;
}

interface RowCellProps {
  width: string;
  centered?: boolean;
}

const ListWrapper = styled.div<ListWrapperProps>`
  max-width: ${props => props.width};
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const HeaderRowContainer = styled.div<HeaderRowContainerProps>`
  display: flex;
  flex-direction: row;
  width: ${props => props.width};
  padding: 8px 10px 8px 12px;
  margin-bottom: 12px;
`;

const HeaderRowCell = styled.div<HeaderRowCellProps>`
  min-width: ${props => props.width};
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  ${props => props.centered && 'text-align: center;'};
`;

const RowContainer = styled.div<RowContainerProps>`
  display: flex;
  flex-direction: row;
  width: ${props => props.width};
  padding: 8px 10px 8px 12px;
  margin-bottom: 7px;
  background-color: ${props => (props.shaded ? '#f6f6f6' : '#ffffff')};
`;

const RowCell = styled.div<RowCellProps>`
  min-width: ${props => props.width};
  font-size: 14px;
  white-space: nowrap;
  ${props => props.centered && 'text-align: center;'};
`;

const DetailsFlyoverTitleContainer = styled.div`
  padding-bottom: 4px;
`;

const DetailsFlyoverBodyContainer = styled.div`
  font-size: 14px;
  line-height: 22px;
  white-space: normal;
  padding-top: 10px;
`;

const DetailsFlyoverFooterContainer = styled.div`
  font-size: 14px;
  line-height: 22px;
  font-style: italic;
  white-space: normal;
  padding-top: 25px;
  paddint-bottom: 15px;
`;

const Title = styled.div`
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  letter-spacing: 0.5px;
  white-space: normal;
`;

interface DetailsFlyoverProps {
  overline: string;
  title: string;
  body: string;
  footer?: string;
}

const DetailsFlyover: React.FC<DetailsFlyoverProps> = ({
  overline,
  title,
  body,
  footer,
}) => {
  return (
    <div>
      <Overline spaceAfter={2}>{overline}</Overline>
      <DetailsFlyoverTitleContainer>
        <Title>{title}</Title>
      </DetailsFlyoverTitleContainer>
      <DottedLine />
      <DetailsFlyoverBodyContainer>
        <div dangerouslySetInnerHTML={{ __html: body }}></div>
      </DetailsFlyoverBodyContainer>
      {footer && (
        <DetailsFlyoverFooterContainer>{footer}</DetailsFlyoverFooterContainer>
      )}
    </div>
  );
};

interface ViewIconAndDetailsFlyoverProps {
  overline: string;
  title: string;
  body: string;
  footer?: string;
}

const ViewIconAndDetailsFlyover: React.FC<ViewIconAndDetailsFlyoverProps> = ({
  overline,
  title,
  body,
  footer,
}) => {
  return (
    <Flyover
      triggerElement={<ViewIcon />}
      innerElement={
        <DetailsFlyover
          overline={overline}
          title={title}
          body={body}
          footer={footer}
        />
      }
      zIndex={102}
    />
  );
};

const detailsColWidth = 50;
const editColWidth = 50;
const deleteColWidth = 50;
const archiveColWidth = 50;

const getTotalWidth = (
  columnsConfig: ColumnConfig[],
  actionsConfig: ActionsConfig
) => {
  let totalWidth = columnsConfig.reduce(
    (sum: number, columnConfig: ColumnConfig) => sum + columnConfig.columnWidth,
    0
  );
  if (actionsConfig.detailsFlyoverInfo) {
    totalWidth += detailsColWidth;
  }
  if (actionsConfig.onEdit) {
    totalWidth += editColWidth;
  }
  if (actionsConfig.onDelete) {
    totalWidth += deleteColWidth;
  }
  if (actionsConfig.onArchive) {
    totalWidth += archiveColWidth;
  }
  return totalWidth;
};

const renderHeader = (
  columnsConfig: ColumnConfig[],
  actionsConfig: ActionsConfig,
  totalWidth: number,
  dataQaidPrefix: string | undefined
) => {
  return (
    <HeaderRowContainer
      data-qaid={`${dataQaidPrefix}-list-header`}
      width={`${totalWidth}px`}
    >
      {columnsConfig.map((columnConfig: ColumnConfig, index: number) => (
        <HeaderRowCell
          width={`${columnConfig.columnWidth}px`}
          centered={columnConfig.isCentered || false}
          key={index}
        >
          {columnConfig.headerText}
        </HeaderRowCell>
      ))}
      {actionsConfig.detailsFlyoverInfo && (
        <HeaderRowCell width={`${detailsColWidth}px`}></HeaderRowCell>
      )}
      {actionsConfig.onEdit && (
        <HeaderRowCell width={`${editColWidth}px`}></HeaderRowCell>
      )}
      {actionsConfig.onDelete && (
        <HeaderRowCell width={`${deleteColWidth}px`}></HeaderRowCell>
      )}
      {actionsConfig.onArchive && (
        <HeaderRowCell width={`${archiveColWidth}px`}></HeaderRowCell>
      )}
    </HeaderRowContainer>
  );
};

const renderBody = (
  columnsConfig: ColumnConfig[],
  rowValues: (obj: any) => any[],
  actionsConfig: ActionsConfig,
  totalWidth: number,
  dataQaidPrefix: string | undefined,
  data: any[]
) => {
  return data.map((obj: any, rowNum: number) => {
    const valuesList = rowValues(obj);

    return (
      <RowContainer
        width={`${totalWidth}px`}
        shaded={rowNum % 2 == 0}
        key={rowNum}
        data-qaid={`${dataQaidPrefix}-list-row-${rowNum}`}
      >
        {valuesList.map((value: any, colNum: number) => (
          <RowCell
            width={`${columnsConfig[colNum].columnWidth}px`}
            centered={columnsConfig[colNum].isCentered || false}
            key={colNum}
          >
            {value}
          </RowCell>
        ))}
        {actionsConfig.detailsFlyoverInfo && (
          <RowCell width={`${detailsColWidth}px`}>
            {actionsConfig.detailsFlyoverInfo.body(obj) && (
              <ViewIconAndDetailsFlyover
                overline={actionsConfig.detailsFlyoverInfo.overline}
                title={actionsConfig.detailsFlyoverInfo.title(obj)}
                body={actionsConfig.detailsFlyoverInfo.body(obj)}
                footer={
                  actionsConfig.detailsFlyoverInfo.footer &&
                  actionsConfig.detailsFlyoverInfo.footer(obj)
                }
              />
            )}
          </RowCell>
        )}
        {actionsConfig.onEdit && actionsConfig.showEdit(obj) && (
          <RowCell width={`${editColWidth}px`}>
            <EditIcon
              onClick={() => actionsConfig.onEdit && actionsConfig.onEdit(obj)}
            />
          </RowCell>
        )}
        {actionsConfig.onDelete && actionsConfig.showDelete(obj) && (
          <RowCell width={`${deleteColWidth}px`}>
            <DeleteIcon
              onClick={() =>
                actionsConfig.onDelete && actionsConfig.onDelete(obj)
              }
            />
          </RowCell>
        )}
        {actionsConfig.onArchive && actionsConfig.showArchive(obj) && (
          <RowCell width={`${archiveColWidth}px`}>
            <ArchiveIcon
              onClick={() =>
                actionsConfig.onArchive && actionsConfig.onArchive(obj)
              }
            />
          </RowCell>
        )}
      </RowContainer>
    );
  });
};

const ListTable: React.FC<Props> = ({
  columnsConfig,
  rowValues,
  detailsFlyoverInfo,
  onEdit,
  onDelete,
  onArchive,
  showEdit,
  showDelete,
  showArchive,
  data,
  loadingContent,
  noResultsContent,
  footerContent,
  dataQaidPrefix,
}) => {
  const actionsConfig = {
    detailsFlyoverInfo,
    onEdit,
    onDelete,
    onArchive,
    showEdit: showEdit || (() => true),
    showDelete: showDelete || (() => true),
    showArchive: showArchive || (() => true),
  };
  const totalWidth = getTotalWidth(columnsConfig, actionsConfig);

  return (
    <ListWrapper width={`${totalWidth}px`}>
      {data && data.length > 0 && !loadingContent && (
        <ListContainer>
          {renderHeader(
            columnsConfig,
            actionsConfig,
            totalWidth,
            dataQaidPrefix
          )}
          {renderBody(
            columnsConfig,
            rowValues,
            actionsConfig,
            totalWidth,
            dataQaidPrefix,
            data
          )}
        </ListContainer>
      )}
      {loadingContent || null}
      {noResultsContent || null}
      {footerContent && <Spacer mb={4} />}
      {footerContent || null}
    </ListWrapper>
  );
};

export default ListTable;
