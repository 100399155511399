import { ContactInfo } from 'app/typings';
import { get } from 'app/shared/utils/get';

export const extractContactInfoInitialValuesByPosition = (
  contactInfos: ContactInfo[] | [],
  position: number
) => {
  const contactInfoInPosition = contactInfos.filter(
    (item: any) => item.position == position
  )[0];

  return {
    id: get(contactInfoInPosition, 'id', undefined),
    firstName: get(contactInfoInPosition, 'firstName', undefined),
    lastName: get(contactInfoInPosition, 'lastName', undefined),
    email: get(contactInfoInPosition, 'email', undefined),
    contactRelationship: {
      name: get(contactInfoInPosition, 'contactRelationship.name', undefined),
      id: get(contactInfoInPosition, 'contactRelationship.id', undefined),
    },
    phoneNumber: get(contactInfoInPosition, 'phoneNumber', undefined),
    position: get(contactInfoInPosition, 'position', position),
    isDayOfShowContact: get(contactInfoInPosition, 'isDayOfShowContact', false),
  };
};
