import React from 'react';
import { useIntl } from 'react-intl';

import {
  getCurrencyFormInitialValue,
  getCurrencyRoundedFormInitialValue,
  getCurrencySubmitVariable,
} from 'app/shared/utils/form';
import { get, getInteger } from 'app/shared/utils/get';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { UpdateEvent } from 'app/admin/graphql/events/mutationHooks';
import { DetailsFormMainContainer } from 'app/admin/components/atoms/DetailContent';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

import EventDetailsTicketingEditForm from './EventDetailsTicketingEditForm';
import EventDetailsTicketingEditFormSchema from './EventDetailsTicketingEditFormSchema';

interface Props {
  contentProps?: any;
  setFormSubmitAction: (func: Function) => void;
  setDisplayConfirmation: (set: boolean) => void;
  setIsSubmitting: (set: boolean) => void;
  navigateTo: (routeData: object) => void;
}

const EventDetailsTicketingEdit: React.FC<Props> = ({
  contentProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  navigateTo,
}) => {
  const intl = useIntl();

  const localStartsAt = get(contentProps, 'localStartsAt', '');
  const cityTitle = get(contentProps, 'city.title', '');
  const neighborhoodTitle = get(contentProps, 'neighborhood.title', '');
  const venueId = get(contentProps, 'venue.id', '');
  const eventId = contentProps.id;
  const cityHasTieredPrice = get(contentProps, 'city.hasTieredPrice', '');
  const cityTicketPrice = get(contentProps, 'cityTicketPrice', '');
  const dealAmount = get(contentProps, 'dealAmount', '');
  const eventType = get(contentProps, 'type', 'DiscoveryEvent');
  const city = get(contentProps, 'city', undefined);

  const getIntegerValue = (field: string) =>
    getInteger(contentProps, field, '') ||
    getInteger(contentProps, field, '') == 0
      ? getInteger(contentProps, field, '')
      : '';

  const formInitialValues = {
    capacity: get(contentProps, 'venue.capacity', undefined),
    numTicketsAvailableForSale: get(
      contentProps,
      'numTicketsAvailableForSale',
      undefined
    ),
    maxTicketsPerAttendee: get(contentProps, 'maxTicketsPerAttendee', ''),
    maximumPromoCodeUses: getIntegerValue('maximumPromoCodeUses'),
    maximumGuaranteedPromoCodeUses: getIntegerValue(
      'maximumGuaranteedPromoCodeUses'
    ),
    maximumCustomPromoCodeUses: getIntegerValue('maximumCustomPromoCodeUses'),
    maximumPercentagePromoCodeUses: getIntegerValue(
      'maximumPercentagePromoCodeUses'
    ),
    ticketPrice: getCurrencyRoundedFormInitialValue(contentProps, 'eventPrice'),
    eventBookingFee: getCurrencyFormInitialValue(
      contentProps,
      'eventBookingFee'
    ),
    cityBookingFee: get(contentProps, 'city.bookingFee', ''),
    countryBookingFee: get(contentProps, 'city.country.bookingFee', ''),
    cityTitle,
    attendeeFlow: get(contentProps, 'attendeeFlow', undefined),
    currencySymbol: get(contentProps, 'currencySymbol', '$'),
    dayOfWeek: new Date(localStartsAt),
    cityHasTieredPrice,
    cityTicketPrice,
    dealAmount: dealAmount && dealAmount / 100.0,
  };

  const integerOrNull = (value: string | undefined) =>
    value || value === '0' ? Number(value) : null;

  const updateEventAction = UpdateEvent();

  const handleUpdateEvent = useSubmitAction({
    submitAction: updateEventAction,
    submitVariables: (values: any) => ({
      eventId,
      numTicketsAvailableForSale: values.numTicketsAvailableForSale.toString(),
      ticketPrice:
        values.attendeeFlow == 'buy'
          ? getCurrencySubmitVariable(values.ticketPrice, null)
          : undefined,
      eventBookingFee:
        values.attendeeFlow == 'buy'
          ? getCurrencySubmitVariable(values.eventBookingFee, null)
          : undefined,
      maxTicketsPerAttendee:
        values.attendeeFlow == 'buy'
          ? values.maxTicketsPerAttendee && Number(values.maxTicketsPerAttendee)
          : undefined,
      maximumPromoCodeUses: integerOrNull(values.maximumPromoCodeUses),
      maximumGuaranteedPromoCodeUses: integerOrNull(
        values.maximumGuaranteedPromoCodeUses
      ),
      maximumCustomPromoCodeUses: integerOrNull(
        values.maximumCustomPromoCodeUses
      ),
      maximumPercentagePromoCodeUses: integerOrNull(
        values.maximumPercentagePromoCodeUses
      ),
      dealAmount:
        eventType == 'CommercialPartnershipEvent'
          ? values.dealAmount
            ? Number(values.dealAmount) * 100
            : null
          : undefined,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.eventBasicInfo.successMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.eventBasicInfo.failureMessage',
    }),
    onSuccess: (response: any) => {
      navigateTo({
        routeName: 'event-details',
        routeProps: {
          defaultOpenSection: 'ticketingInfo',
          ...response.data.updateEvent.event,
        },
      });
    },
  });

  return (
    <ModalContentContainer>
      <DetailsFormMainContainer>
        <DetailsHeader
          title={cityTitle}
          subTitle={neighborhoodTitle}
          loading={!(!!cityTitle || !!localStartsAt)}
          imageStyle="calendar"
          detailsDate={localStartsAt}
        />
        <GenericForm
          formInitialValues={formInitialValues}
          renderFormComponent={(renderProps: any) => (
            <EventDetailsTicketingEditForm
              formikProps={renderProps.formikProps}
              venueId={venueId}
              eventType={eventType}
              city={city}
            />
          )}
          onSubmit={handleUpdateEvent}
          formSchema={EventDetailsTicketingEditFormSchema(intl, venueId)}
          setFormSubmitAction={setFormSubmitAction}
          setDisplayConfirmation={setDisplayConfirmation}
          setIsSubmitting={setIsSubmitting}
          dataQaId="event-details-ticketing-edit-form"
        />
      </DetailsFormMainContainer>
    </ModalContentContainer>
  );
};

export default EventDetailsTicketingEdit;
