import React from 'react';
import styled, { css } from 'styled-components';

import { ManualCSSColors } from 'app/shared/theme';
import { OutlineButton } from 'app/shared/components/atoms/Button';
import { PrimaryButton } from 'app/shared/components/atoms/Button';
import CloseButton from 'app/shared/components/atoms/CloseButton';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { StreamlineIcon } from 'app/shared/components/atoms/StreamlineIcon';
import {
  Body,
  Caption,
  H3,
} from 'app/shared/components/atoms/TypographyManualCSS';
import { ReactComponent as RemoveCircle } from 'icons/streamline-regular/interface-essential/remove-add/remove-circle.svg';

interface Props {
  onCancel?: () => void;
  onConfirm: () => void;
  onClose?: () => void;
  description: string;
  title?: string;
  footerText?: string;
  confirmationButtonText?: string;
  cancellationButtonText?: string;
  confirmationButtonDisabled?: boolean;
  isLoading?: boolean;
  customContent?: any;
  backgroundColor?: ManualCSSColors;
}

// @ts-ignore
const AnchoredCloseButton = styled(CloseButton)`
  ${({ theme }) => css`
    position: absolute;
    top: 0;
    right: 0;
    padding: 24px;
    margin: 0px;

    ${theme.media.xs`
      padding: 15px 17px;
    `}

    ${theme.media.sm`
      padding: 16px;
    `}
  `}
`;

const StyledOutlineButton = styled(OutlineButton)`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.backToBlack};
  `}
`;

const ConfirmationModalUnderlay = styled.div`
  left: 0;
  top: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 100;
  overflow-y: auto;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ConfirmationModalContainer = styled.div`
  ${({ theme }) => css`
    width: 90%;
    max-width: 320px;

    ${theme.media.md`
      width: 60%;
      max-width: 555px;
    `}

    ${theme.media.lg`
      width: 50%;
      max-width: 595px;
    `}

    ${theme.media.xl`
      width: 45%;
      max-width: 595px;
    `}
  `}
`;

const ModalMainSection = styled.div<any>`
  ${({ theme, backgroundColor }) => css`
    background-color: ${theme.colors[backgroundColor]};
    padding: 50px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: ${theme.borderRadius.default};
    position: relative;

    ${theme.media.md`
      padding: 60px 50px;
    `}
  `}
`;

const ModalTitle = styled(H3)`
  margin-bottom: 25px;
  font-weight: 800;
`;

const ModalMessage = styled(Body)`
  margin-bottom: 30px;
  line-height: 24px;
  font-weight: 400;
`;

const BreakLineSpan = styled.span`
  display: block;
  text-align: center;
`;

const ModalButtonContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    flex-direction: column-reverse;

    button {
      margin: 8px;
    }

    ${theme.media.md`
     flex-flow: unset;
    `}
  `}
`;

const MarginPrimaryButton = styled(PrimaryButton)`
  margin-right: 10px;
  min-width: 110px;
`;

const FooterText = styled(Caption)`
  text-align: center;
`;

export const ConfirmationModalContent: React.FC<Props> = ({
  onCancel,
  onConfirm,
  onClose,
  description,
  title,
  footerText,
  confirmationButtonText = 'Continue',
  cancellationButtonText = 'Cancel',
  confirmationButtonDisabled = false,
  isLoading = false,
  customContent,
  backgroundColor = 'silverSprings',
}) => {
  return (
    <>
      <ModalMainSection
        data-qaid="confirm-modal"
        backgroundColor={backgroundColor}
      >
        <AnchoredCloseButton
          onClick={onClose ? onClose : onCancel}
          data-qaid="confirmation-modal-close-btn"
        >
          <StreamlineIcon size={24} display="inline-flex" icon={RemoveCircle} />
        </AnchoredCloseButton>
        {title && (
          <ModalTitle>
            <BreakLineSpan>
              <div
                data-qaid="confirmation-modal-title"
                dangerouslySetInnerHTML={{ __html: title }}
              ></div>
            </BreakLineSpan>
          </ModalTitle>
        )}
        <ModalMessage as="div">
          <BreakLineSpan>
            <div
              data-qaid="confirmation-modal-description"
              dangerouslySetInnerHTML={{ __html: description }}
            ></div>
          </BreakLineSpan>
          {customContent && (
            <>
              <Spacer mb={8} />
              <BreakLineSpan>{customContent}</BreakLineSpan>
            </>
          )}
        </ModalMessage>
        <ModalButtonContainer>
          {onCancel ? (
            <>
              <StyledOutlineButton onClick={onCancel} data-qaid="cancel-btn">
                {cancellationButtonText}
              </StyledOutlineButton>
              <MarginPrimaryButton
                loading={isLoading}
                onClick={onConfirm}
                data-qaid="confirm-btn"
                disabled={confirmationButtonDisabled}
              >
                {confirmationButtonText}
              </MarginPrimaryButton>
            </>
          ) : (
            <PrimaryButton
              loading={isLoading}
              onClick={onConfirm}
              data-qaid="confirm-btn"
            >
              {confirmationButtonText}
            </PrimaryButton>
          )}
        </ModalButtonContainer>
        {footerText && (
          <>
            <Spacer mt={8} />
            <FooterText>{footerText}</FooterText>
          </>
        )}
      </ModalMainSection>
    </>
  );
};

const ConfirmationModal: React.FC<Props> = props => {
  return (
    <ConfirmationModalUnderlay>
      <ConfirmationModalContainer>
        <ConfirmationModalContent {...props} />
      </ConfirmationModalContainer>
    </ConfirmationModalUnderlay>
  );
};

export default ConfirmationModal;
