import * as Yup from 'yup';

const userRoleAddFormSchema = (intl: any) =>
  Yup.object().shape({
    userId: Yup.number()
      .when('action', {
        is: 'addRoleToAnyUser',
        then: Yup.number()
          .nullable()
          .required(
            intl.formatMessage({
              id: 'form.requiredField',
            })
          ),
      })
      .when('action', {
        is: 'addCuratorRoleToAnyUser',
        then: Yup.number()
          .nullable()
          .required(
            intl.formatMessage({
              id: 'form.requiredField',
            })
          ),
      }),
    role: Yup.string()
      .nullable()
      .required(
        intl.formatMessage({
          id: 'form.requiredField',
        })
      )
      .test(
        'curator-group-required',
        intl.formatMessage({
          id: 'admin.users.userRoleAdd.form.curatorGroupRequired',
        }),
        function() {
          const roleResourceType = this.parent.roleResourceType;
          const curatorGroup = this.parent.curatorGroup;
          return !(roleResourceType == 'CuratorGroup' && !curatorGroup);
        }
      )
      .test(
        'city-required',
        intl.formatMessage({
          id: 'admin.users.userRoleAdd.form.cityRequired',
        }),
        function() {
          const roleResourceType = this.parent.roleResourceType;
          const city = this.parent.city;
          return !(roleResourceType == 'City' && !city);
        }
      )
      .test(
        'business-owner-required',
        intl.formatMessage({
          id: 'admin.users.userRoleAdd.form.businessOwnerRequired',
        }),
        function() {
          const roleResourceType = this.parent.roleResourceType;
          const businessOwner = this.parent.businessOwner;
          return !(roleResourceType == 'BusinessOwner' && !businessOwner);
        }
      )
      .test(
        'curator-group-must-be-empty',
        intl.formatMessage({
          id: 'admin.users.userRoleAdd.form.curatorGroupMustBeEmpty',
        }),
        function() {
          const roleResourceType = this.parent.roleResourceType;
          const curatorGroup = this.parent.curatorGroup;
          return !(
            (roleResourceType == 'City' ||
              roleResourceType == 'BusinessOwner') &&
            curatorGroup
          );
        }
      )
      .test(
        'city-must-be-empty',
        intl.formatMessage({
          id: 'admin.users.userRoleAdd.form.cityMustBeEmpty',
        }),
        function() {
          const roleResourceType: any = this.parent.roleResourceType;
          const city = this.parent.city;
          return !(
            (roleResourceType == 'CuratorGroup' ||
              roleResourceType == 'BusinessOwner') &&
            city
          );
        }
      )
      .test(
        'business-owner-must-be-empty',
        intl.formatMessage({
          id: 'admin.users.userRoleAdd.form.businessOwnerMustBeEmpty',
        }),
        function() {
          const roleResourceType: any = this.parent.roleResourceType;
          const businessOwner = this.parent.businessOwner;
          return !(
            (roleResourceType == 'CuratorGroup' ||
              roleResourceType == 'City') &&
            businessOwner
          );
        }
      )
      .test(
        'city-and-business-owner-must-be-empty',
        intl.formatMessage({
          id: 'admin.users.userRoleAdd.form.allResourceSelectionsMustBeEmpty',
        }),
        function() {
          const roleResourceType: any = this.parent.roleResourceType;
          const curatorGroup = this.parent.curatorGroup;
          const city = this.parent.city;
          const businessOwner = this.parent.businessOwner;
          return !(
            !roleResourceType &&
            (curatorGroup || city || businessOwner)
          );
        }
      ),
  });

export default userRoleAddFormSchema;
