import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Attendee } from 'app/typings/attendees';
import { Email } from 'app/typings/emails';
import { Event } from 'app/typings/events';
import { get } from 'app/shared/utils/get';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import {
  FlyoverLoadingError,
  FlyoverLoadingIndicator,
} from 'app/shared/components/atoms/FlyoverContent';
import { MenuItem } from 'app/shared/components/atoms/Menu';
import TruncatedByCharText from 'app/shared/components/atoms/TruncatedByCharText';
import {
  Body2,
  Overline,
} from 'app/shared/components/atoms/TypographyManualCSS';
import RadioGroup from 'app/shared/components/molecules/RadioGroup';
import Select from 'app/shared/components/molecules/SelectManualCSS';
import { userFullNameWithEmail } from 'app/admin/utils/users';
import { GetAttendeesLazy } from 'app/admin/graphql/attendees/queryHooks';
import { SendEventEmail } from 'app/admin/graphql/emails/mutationHooks';
import FlyoverFooter from 'app/admin/components/molecules/FlyoverFooter';

interface Props {
  email: Email;
  event: Event;
  onSuccess: Function;
}

const ContentWrapper = styled.div`
  padding: 3px 16px 24px 16px;
  margin-bottom: 10px;
  max-height: 300px;
  overflow-y: scroll;
`;

const SelectEntityWrapper = styled.div`
  margin-top: 15px;
`;

const HeaderWrapper = styled.div`
  padding: 16px 16px 0px 16px;
  margin-bottom: 2px;
`;

const EmailName = styled.div`
  font-size: 14px;
  line-height: 21px;
  font-weight: 700;
  letter-spacing: 0.1px;
  white-space: normal;
  margin-top: 5px;
  margin-bottom: 12px;
`;

const EntityLabel = styled(Body2)<{ greyedOut: boolean }>`
  ${props => `
    ${props.greyedOut && `color: ${props.theme.colors.lightBlueSmoke};`}
  `}
`;

const entityNames = {
  Artist: 'admin.eventPlanner.emails.entityNameArtist',
  Crew: 'admin.eventPlanner.emails.entityNameCrew',
  Guest: 'admin.eventPlanner.emails.entityNameGuest',
  Venue: 'admin.eventPlanner.emails.entityNameVenue',
};

const entityNamesPlural = {
  Artist: 'admin.eventPlanner.emails.entityNamePluralArtist',
  Crew: 'admin.eventPlanner.emails.entityNamePluralCrew',
  Guest: 'admin.eventPlanner.emails.entityNamePluralGuest',
  Venue: 'admin.eventPlanner.emails.entityNamePluralVenue',
};

const entityCountTextsSingular = {
  Artist: 'admin.eventPlanner.emails.entityCountTextSingularArtist',
  Crew: 'admin.eventPlanner.emails.entityCountTextSingularCrew',
  Guest: 'admin.eventPlanner.emails.entityCountTextSingularGuest',
  GuestConfirmed:
    'admin.eventPlanner.emails.entityCountTextSingularGuestConfirmed',
  Venue: 'admin.eventPlanner.emails.entityCountTextSingularVenue',
};

const entityCountTextsPlural = {
  Artist: 'admin.eventPlanner.emails.entityCountTextPluralArtist',
  Crew: 'admin.eventPlanner.emails.entityCountTextPluralCrew',
  Guest: 'admin.eventPlanner.emails.entityCountTextPluralGuest',
  GuestConfirmed:
    'admin.eventPlanner.emails.entityCountTextPluralGuestConfirmed',
  Venue: 'admin.eventPlanner.emails.entityCountTextPluralVenue',
};

const sendTypes = {
  all: 'admin.eventPlanner.emails.sendEmailAll',
  new: 'admin.eventPlanner.emails.sendEmailNew',
  one: 'admin.eventPlanner.emails.sendEmailOne',
};

const capitalizeFirstLetter = (str: string) =>
  str[0].toUpperCase() + str.slice(1);

const isSendTypeOptionDisabled = (key: string, email: Email) => {
  if (key == 'all' && email.numAllPotentialRecipients == 0) {
    return true;
  } else if (key == 'new' && email.numNewPotentialRecipients == 0) {
    return true;
  } else {
    return false;
  }
};

const isEmailForOnlyConfirmedGuests = (email: Email) =>
  email.emailGroup == 'Guest' &&
  email.isEmailCountsEnabled &&
  email.id != 'GuestVipConfirmation';

const getSendTypeOptionTitle = (email: Email, sendType: string, intl: any) => {
  const entityName = intl.formatMessage({
    id: entityNames[email.emailGroup || ''],
  });
  const entityNamePlural = intl.formatMessage({
    id: entityNamesPlural[email.emailGroup || ''],
  });
  const entityCountTextSingular =
    entityCountTextsSingular[
      isEmailForOnlyConfirmedGuests(email)
        ? 'GuestConfirmed'
        : email.emailGroup || ''
    ];
  const entityCountTextPlural =
    entityCountTextsPlural[
      isEmailForOnlyConfirmedGuests(email)
        ? 'GuestConfirmed'
        : email.emailGroup || ''
    ];
  let title = intl.formatMessage(
    {
      id: sendTypes[sendType],
    },
    {
      entityName,
      entityNamePlural:
        sendType == 'new'
          ? capitalizeFirstLetter(entityNamePlural)
          : entityNamePlural,
    }
  );
  let countText = '';

  switch (sendType) {
    case 'all':
      if (email.isEmailCountsEnabled) {
        if (email.numAllPotentialRecipients == 1) {
          countText = intl.formatMessage({
            id: entityCountTextSingular,
          });
        } else {
          countText = intl.formatMessage(
            {
              id: entityCountTextPlural,
            },
            {
              num: email.numAllPotentialRecipients,
            }
          );
        }
      }
      break;
    case 'new':
      if (email.isEmailCountsEnabled) {
        if (email.numNewPotentialRecipients == 1) {
          countText = intl.formatMessage({
            id: entityCountTextSingular,
          });
        } else {
          countText = intl.formatMessage(
            {
              id: entityCountTextPlural,
            },
            {
              num: email.numNewPotentialRecipients,
            }
          );
        }
      }
      break;
    case 'one':
      break;
  }
  if (countText) {
    title = `${title} (${countText})`;
  }
  return title;
};

const getSendTypeOptions = (email: Email, intl: any) => {
  return Object.keys(sendTypes).map((key: string) => ({
    title: getSendTypeOptionTitle(email, key, intl),
    value: key,
    disabled: isSendTypeOptionDisabled(key, email),
  }));
};

const eventArtists = (event: Event) =>
  event.performances &&
  event.performances.filter((performance: any) => !!performance.artist);

const eventStaffUsers = (event: Event) =>
  event.staff &&
  event.staff
    .map((eventStaffMember: any) => eventStaffMember.user)
    .filter((user: any) => !!user);

const eventEventAttendees = (attendees?: Attendee[]) =>
  attendees && attendees.filter((attendee: any) => !!attendee.eventAttendee);

const eventConfirmedEventAttendees = (attendees?: Attendee[]) =>
  attendees &&
  attendees.filter(
    (attendee: any) =>
      !!(
        attendee.eventAttendee &&
        (attendee.eventAttendee.currentState == 'confirmed' ||
          attendee.eventAttendee.currentState == 'reserved')
      )
  );

const eventConfirmedEventAttendeesAndVipAttendees = (attendees?: Attendee[]) =>
  attendees &&
  attendees.filter(
    (attendee: any) =>
      !!(
        attendee.eventAttendee &&
        (attendee.eventAttendee.currentState == 'confirmed' ||
          attendee.eventAttendee.currentState == 'reserved')
      ) || !!attendee.vipAttendee
  );

const eventVipAttendees = (attendees?: Attendee[]) =>
  attendees && attendees.filter((attendee: any) => !!attendee.vipAttendee);

const getEntityObjs = (email: Email, event: Event, attendees?: Attendee[]) => {
  switch (email.emailGroup) {
    case 'Artist':
      return eventArtists(event);
    case 'Crew':
      return eventStaffUsers(event);
    case 'Guest':
      if (
        email.id == 'GuestPostEvent' ||
        email.id == 'GuestRevealAddress' ||
        email.id == 'GuestDoorsOpen'
      ) {
        return eventConfirmedEventAttendeesAndVipAttendees(attendees);
      } else if (isEmailForOnlyConfirmedGuests(email)) {
        return eventConfirmedEventAttendees(attendees);
      } else if (email.id == 'GuestVipConfirmation') {
        return eventVipAttendees(attendees);
      } else {
        return eventEventAttendees(attendees);
      }
    case 'Venue':
      return [event.venue];
  }
  return [];
};

const getEntityTitle = (emailGroup: string | undefined, entityObj: any) => {
  switch (emailGroup) {
    case 'Artist':
      return entityObj && entityObj.artist.title;
    case 'Crew':
      return entityObj && userFullNameWithEmail(entityObj);
    case 'Guest':
      return entityObj && `${entityObj.name} (${entityObj.email})`;
    case 'Venue':
      return entityObj && (entityObj.venueName || entityObj.address);
  }
  return '';
};

const getEntityId = (emailGroup: string | undefined, entityObj: any) => {
  switch (emailGroup) {
    case 'Artist':
      return entityObj && entityObj.artist.id;
    case 'Crew':
      return entityObj && entityObj.id;
    case 'Guest':
      if (entityObj.eventAttendee) {
        return entityObj.eventAttendee.id;
      } else if (entityObj.vipAttendee) {
        return entityObj.vipAttendee.id;
      }
      return '';
    case 'Venue':
      return entityObj && entityObj.id;
  }
  return '';
};

const getEntityDisabled = (emailGroup: string | undefined, entityObj: any) => {
  switch (emailGroup) {
    case 'Artist':
      if (entityObj.artistConfirmed) {
        return false;
      } else {
        return true;
      }
    case 'Crew':
      return false;
    case 'Guest':
      return false;
    case 'Venue':
      return false;
  }
  return false;
};

const getEntityType = (emailGroup: string | undefined, entityObj: any) => {
  switch (emailGroup) {
    case 'Artist':
      return '';
    case 'Crew':
      return '';
    case 'Guest':
      if (entityObj.eventAttendee) {
        return 'EventAttendee';
      } else if (entityObj.vipAttendee) {
        return 'VipAttendee';
      }
      return '';
    case 'Venue':
      return '';
  }
  return '';
};

const getEntityOptions = (
  email: Email,
  event: Event,
  attendees?: Attendee[]
) => {
  const entityObjs = getEntityObjs(email, event, attendees) || [];
  return entityObjs.map((entityObj: any) => ({
    title: getEntityTitle(email.emailGroup, entityObj),
    value: getEntityId(email.emailGroup, entityObj),
    disabled: getEntityDisabled(email.emailGroup, entityObj),
    type: getEntityType(email.emailGroup, entityObj),
  }));
};

interface HeaderProps {
  emailName: string;
}

const Header: React.FC<HeaderProps> = ({ emailName }) => {
  const intl = useIntl();

  return (
    <HeaderWrapper>
      <Overline>
        {intl.formatMessage({
          id: 'admin.eventPlanner.emails.sendEmail',
        })}
      </Overline>
      <EmailName>
        <TruncatedByCharText text={emailName} truncateLength={30} />
      </EmailName>
      <DottedLine />
    </HeaderWrapper>
  );
};

interface SendEmailContentsProps {
  email: Email;
  event: Event;
  attendees?: Attendee[];
  sendType: string;
  entity: any;
  onChangeSendType: Function;
  onChangeEntity: Function;
}

const SendEmailContents: React.FC<SendEmailContentsProps> = ({
  email,
  event,
  attendees,
  sendType,
  entity,
  onChangeSendType,
  onChangeEntity,
}) => {
  const intl = useIntl();

  const sendTypeOptions = getSendTypeOptions(email, intl);
  const entityOptions = getEntityOptions(email, event, attendees);
  const entityName = intl.formatMessage({
    id: entityNames[email.emailGroup || ''],
  });

  return (
    <ContentWrapper>
      <Overline>
        {intl.formatMessage({
          id: 'admin.eventPlanner.emails.sendEmailTo',
        })}
      </Overline>
      <RadioGroup
        name="sendType"
        options={sendTypeOptions}
        selectedValue={sendType}
        onChange={value => onChangeSendType(value)}
      />
      {sendType == 'one' && (
        <SelectEntityWrapper>
          <Select
            placeholder={intl.formatMessage(
              {
                id: 'admin.eventPlanner.emails.selectEntity',
              },
              {
                entityName,
              }
            )}
            searchable
            data-qaid="select-entity"
            value={entity}
            options={entityOptions}
            onChange={opt => {
              if (opt.disabled) {
                onChangeEntity(entity);
              } else {
                onChangeEntity(opt);
              }
            }}
            getOptionLabel={(opt: any) => opt.title}
            renderOption={(opt, props) => {
              const propsToUse = opt.disabled
                ? Object.assign(props, { onClick: () => {} })
                : props;
              return (
                <MenuItem
                  key={opt.value}
                  disabled={opt.disabled}
                  {...propsToUse}
                  className="suppress-outside-click"
                >
                  <EntityLabel
                    greyedOut={opt.disabled}
                    as="span"
                    className="suppress-outside-click"
                  >
                    {opt.title}
                  </EntityLabel>
                </MenuItem>
              );
            }}
            initialWidth="265px"
          />
        </SelectEntityWrapper>
      )}
    </ContentWrapper>
  );
};

const EventSendEmail: React.FC<Props> = ({ email, event, onSuccess }) => {
  const intl = useIntl();
  const [sendType, setSendType] = useState('all');
  const [entity, setEntity] = useState<any>(undefined);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [
    fetchAttendees,
    { loading: loadingAttendees, error: errorAttendees, data: dataAttendees },
  ] = GetAttendeesLazy({
    eventId: Number(event.id),
    orderBy: 'name',
    orderDirection: 'asc',
    fetchPolicy: 'cache-and-network',
  });

  const attendees = get(dataAttendees, 'attendees.attendees', []);

  useEffect(() => {
    if (email.emailGroup == 'Guest') {
      fetchAttendees();
    }
  }, [email, fetchAttendees]);

  const onChangeSendType = (newSendType: string) => {
    setSendType(newSendType);
    if (newSendType == 'all' || newSendType == 'new') {
      setEntity(undefined);
    }
  };

  const onChangeEntity = (newEntity: any) => {
    setEntity(newEntity);
  };

  const artistId =
    entity && email.emailGroup == 'Artist' ? entity.value : undefined;
  const eventAttendeeId =
    entity && email.emailGroup == 'Guest' && entity.type == 'EventAttendee'
      ? entity.value
      : undefined;
  const vipAttendeeId =
    entity && email.emailGroup == 'Guest' && entity.type == 'VipAttendee'
      ? entity.value
      : undefined;
  const staffUserId =
    entity && email.emailGroup == 'Crew' ? entity.value : undefined;

  const sendEventEmailAction = SendEventEmail();

  const handleSendEventEmail = useSubmitAction({
    submitAction: sendEventEmailAction,
    submitVariables: () => ({
      emailKey: email.id,
      sendType,
      eventId: event.id,
      artistId,
      eventAttendeeId,
      vipAttendeeId,
      staffUserId,
    }),
    successMsg: intl.formatMessage(
      {
        id: 'admin.eventPlanner.emails.sendEmailSuccessMessage',
      },
      {
        emailName: email.emailName,
      }
    ),
    failureMsg: intl.formatMessage({
      id: 'admin.eventPlanner.emails.sendEmailFailureMessage',
    }),
    onSuccess: () => {
      setIsSubmitting(false);
      onSuccess();
    },
  });

  if (
    email.emailGroup == 'Guest' &&
    ((!loadingAttendees && !dataAttendees) || errorAttendees)
  ) {
    return <FlyoverLoadingError whatsBeingLoaded="guests" />;
  }

  return (
    <div data-qaid="event-send-email">
      <Header emailName={email.emailName} />
      {isSubmitting || loadingAttendees ? (
        <FlyoverLoadingIndicator />
      ) : (
        <>
          <SendEmailContents
            email={email}
            event={event}
            attendees={attendees}
            sendType={sendType}
            entity={entity}
            onChangeSendType={onChangeSendType}
            onChangeEntity={onChangeEntity}
          />
          <FlyoverFooter
            buttonText={intl.formatMessage({
              id: 'admin.eventPlanner.emails.send',
            })}
            buttonDisabled={sendType === 'one' && !entity}
            onClickButton={() => {
              setIsSubmitting(true);
              handleSendEventEmail();
            }}
          />
        </>
      )}
    </div>
  );
};

export default EventSendEmail;
