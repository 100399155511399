import React, { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { ArtistSetup } from 'app/typings/artistSetups';
import { Event } from 'app/typings/events';
import { Performance } from 'app/typings/performances';
import { Document, DocumentTypes, SignedForm } from 'app/typings/signedForms';
import { dateFormatter } from 'app/shared/utils/datetime';
import useModal from 'app/shared/utils/useModal';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import { NotifyContext } from 'app/shared/context/Notify';
import Divider from 'app/shared/components/atoms/DividerManualCSS';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import {
  DeleteIcon,
  EditIcon,
  ListIcon,
} from 'app/shared/components/atoms/IconLibrary';
import { Image } from 'app/shared/components/atoms/ImageManualCSS';
import { BaseLink } from 'app/shared/components/atoms/LinkManualCSS';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Spinner } from 'app/shared/components/atoms/SpinnerManualCSS';
import TruncatedByCharText from 'app/shared/components/atoms/TruncatedByCharText';
import ConfirmationModal from 'app/shared/components/molecules/ConfirmationModal';
import InfoBox from 'app/shared/components/molecules/Flyover';
import HelpIconAndFlyover from 'app/shared/components/molecules/HelpIconAndFlyover';
import { getArtistSetupName } from 'app/admin/utils/artists';
import {
  getArtistPronouns,
  performancesForPositions,
} from 'app/admin/utils/artists';
import {
  CreateSignedForm,
  DeleteSignedForm,
} from 'app/admin/graphql/signedForms/mutationHooks';
import AddIconAndText from 'app/admin/components/atoms/AddIconAndText';
import {
  DetailHeading as Heading,
  DetailValueContainer as ValueContainer,
} from 'app/admin/components/atoms/DetailContent';
import ExpandableText from 'app/admin/components/atoms/ExpandableText';
import { None, NoneWrapper } from 'app/admin/components/atoms/None';
import ThumbnailCircle from 'app/admin/components/atoms/ThumbnailCircle';
import HeadingWithHelpIcon from 'app/admin/components/molecules/DetailHeadingWithHelpIcon';
import {
  ArtistLink,
  PromoCodeLink,
} from 'app/admin/components/molecules/EntityLink';
import { PrimaryContactInfo } from 'app/admin/components/molecules/PrimaryContactInfo';
import EventPlannerArtistSetupSelectFlyover from 'app/admin/components/organisms/EventPlannerArtistSetupSelectFlyover';
import FileUploader from 'app/admin/components/organisms/FileUploader';

interface Props {
  sectionData: any;
  callbacks: { [name: string]: Function };
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const GreyDivider = styled(Divider)`
  ${({ theme }) => css`
    background-color: ${theme.colors.macyGrey};
    margin-bottom: 20px;
  `}
`;

const HelperText = styled.div`
  font-size: 14px;
  font-style: italic;
  margin-top: 10px;
  margin-bottom: 15px;
`;

const ValueContainerWithoutMargin = styled(ValueContainer)`
  margin-bottom: 0px;
`;

const StyledHeading = styled(Heading)`
  max-width: 100px;
  margin-right: 0px;
`;

const StyledLink = styled(BaseLink)`
  ${({ theme }) => css`
    color: ${theme.colors.green600};
  `}
`;

const IconLink = styled.a`
  cursor: pointer;
`;

export const Subheader = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.blueSmoke};
    font-size: 12px;
    margin-bottom: 10px;
    font-style: italic;
  `}
`;

export const Subheading = styled(Heading)`
  font-size: 14px;
`;

export const SetupValueContainer = styled(ValueContainer)`
  font-size: 14px;
`;

const AudioSupportLabel = styled.span`
  text-transform: capitalize;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const InfoIconContainer = styled.div`
  padding-left: 10px;
  padding-bottom: 2px;
`;

const UploadFileFlexContainer = styled.span`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 2px;
`;

const AddIconAndTextContainer = styled.div`
  margin-top: -5px;
`;

const DeleteIconWrapper = styled.div``;

const CenteredSpinner = styled(Spinner)`
  width: 200px;
  height: 200px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
`;

const ArtistPerformance: React.FC<{
  event?: Event;
  performance?: Performance;
  artistSetup?: ArtistSetup;
  artistConfirmed?: boolean;
  dataQaidPrefix: string;
  callbacks: { [name: string]: Function };
  refetchEvent?: any;
  navigateTo?: any;
}> = ({
  event,
  performance,
  artistSetup,
  artistConfirmed,
  dataQaidPrefix,
  callbacks,
  refetchEvent,
  navigateTo,
}) => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const [flyoverClosed, setFlyoverClosed] = useState(false);
  const [formIdToDelete, setFormIdToDelete] = useState(0);
  const notifyContext = useContext(NotifyContext);
  const [deleteFormModal, toggleDeleteFormModal] = useModal();

  const deleteFormAction = DeleteSignedForm();

  const handleFormDeletion = useSubmitAction({
    submitAction: deleteFormAction,
    submitVariables: () => ({
      signedFormId: formIdToDelete,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.eventPlanner.artists.deleteArtistForm.deleteSuccess',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.eventPlanner.artists.deleteArtistForm.deleteFailure',
    }),
    onSuccess: () => {
      deleteFormModal.hide();
      refetchEvent();
      navigateTo({
        routeName: 'event-details',
        routeProps: {
          ...event,
          defaultOpenSection: 'artistsInfo',
        },
      });
    },
  });

  if (!performance) {
    return (
      <>
        <ThumbnailCircle />
        <Spacer mb={3} />
        <Heading data-qaid={`artist-${dataQaidPrefix}-title`}>
          <NoneWrapper>
            {intl.formatHTMLMessage({
              id: 'admin.eventDetails.artists.notBooked',
            })}
          </NoneWrapper>
        </Heading>
      </>
    );
  }

  const {
    id,
    title,
    imageUrl,
    homeCityDescription,
    contactInfos,
    description,
    socialUrls,
    isPolicyCompliant,
    preferredPronouns,
  } = performance.artist;

  const supportLink = socialUrls && socialUrls.urlSupport;
  const artistPreferredPronouns =
    preferredPronouns &&
    preferredPronouns.length &&
    getArtistPronouns(intl, preferredPronouns);

  const handleFileUpload = async (data: Document, documentType: string) => {
    try {
      if (performance.id) {
        setIsLoading(true);
        const response = await CreateSignedForm({
          document: {
            id: data.id,
            filename: data.filename,
            contentType: data.contentType,
            size: data.size,
          },
          uploadableType: 'Performance',
          uploadableId: performance.id,
          documentType,
        });

        if (response.data) {
          setIsLoading(false);
          refetchEvent();
          notifyContext.addMessage(
            intl.formatMessage({
              id: 'admin.eventPlanner.artists.uploadArtistForms.successMsg',
            })
          );
          navigateTo({
            routeName: 'event-details',
            routeProps: {
              ...event,
              defaultOpenSection: 'artistsInfo',
            },
          });
        } else {
          setIsLoading(false);
          return notifyContext.addMessage(
            intl.formatMessage({
              id: 'admin.eventPlanner.artists.uploadArtistForms.failureMsg',
            })
          );
        }
      }
      /* eslint-disable-next-line prettier/prettier */
    } catch (error) {
      setIsLoading(false);
      if (error instanceof Error) {
        notifyContext.addMessage(
          intl.formatMessage(
            {
              id:
                'admin.eventPlanner.artists.uploadArtistForms.errorMessageWithDescription',
            },
            {
              description: error.message,
            }
          )
        );
      }
    }
  };

  const renderFormDetails = (form: SignedForm) => (
    <>
      <UploadFileFlexContainer>
        <StyledLink href={form.url} openInNewTab={true}>
          <TruncatedByCharText text={form.filename} truncateLength={26} />
        </StyledLink>
        <Spacer mr={2} />
        <DeleteIconWrapper data-qaid={`${form.documentType}-delete-button`}>
          <DeleteIcon
            iconSize={14}
            onClick={() => {
              if (form && form.id) {
                setFormIdToDelete(form.id);
              }
              toggleDeleteFormModal();
            }}
          />
        </DeleteIconWrapper>
      </UploadFileFlexContainer>
      {intl.formatMessage(
        {
          id: 'admin.eventPlanner.artists.uploadArtistForms.uploadedInfo',
        },
        {
          createdAt: dateFormatter(form.createdAt, 'longMonthDateAndYear'),
        }
      )}
    </>
  );

  const renderFileUploader = (documentType: string) => (
    <FileUploader
      styledFileUploaderComponent={
        <AddIconAndTextContainer>
          <AddIconAndText
            text={intl.formatMessage({
              id: 'admin.eventPlanner.artists.uploadArtistForms.addAfile',
            })}
            textSizeType="small"
            dataQaid={`add-${documentType.toLowerCase()}-container`}
          />
        </AddIconAndTextContainer>
      }
      onChange={(data: Document) => handleFileUpload(data, documentType)}
    />
  );

  if (isLoading) {
    return <CenteredSpinner size="30px" />;
  }

  return (
    <Grid>
      <Col xs={12} sm={12} md={3}>
        <ThumbnailCircle>
          {imageUrl && (
            <Image
              src={imageUrl}
              width="100%"
              data-qaid={`artist-${dataQaidPrefix}-details`}
            />
          )}
        </ThumbnailCircle>
        <Spacer mb={3} />
        <ValueContainerWithoutMargin
          data-qaid={`artist-${dataQaidPrefix}-name`}
        >
          <ArtistLink artist={{ id, title }} truncateLength={28} />
        </ValueContainerWithoutMargin>
        <ValueContainerWithoutMargin
          data-qaid={`artist-${dataQaidPrefix}-home-city`}
        >
          {homeCityDescription ? homeCityDescription : <None />}
        </ValueContainerWithoutMargin>
        <Spacer mb={4} />
        <ValueContainer data-qaid={`artist-${dataQaidPrefix}-status`}>
          {artistConfirmed
            ? intl.formatHTMLMessage({
                id: 'admin.eventDetails.artists.confirmedStatus',
              })
            : intl.formatHTMLMessage({
                id: 'shared.draft',
              })}
        </ValueContainer>
      </Col>
      <Col xs={12} sm={12} md={3}>
        <HeadingWithHelpIcon
          title={intl.formatMessage({
            id: 'admin.shared.artists.artistPronouns',
          })}
          bodyId="admin.shared.artists.artistPronounsHelpText"
        >
          {intl.formatMessage({
            id: 'admin.shared.artists.artistPronouns',
          })}
        </HeadingWithHelpIcon>
        <ValueContainer>
          {artistPreferredPronouns ? (
            artistPreferredPronouns.map(
              (pronoun: { title: string }, i: number) => {
                return <div key={i + 1}>{pronoun.title} </div>;
              }
            )
          ) : (
            <NoneWrapper>
              {intl.formatMessage({
                id: 'admin.shared.artists.artistHasNotSelectedPronouns',
              })}
            </NoneWrapper>
          )}
        </ValueContainer>
        <HeadingWithHelpIcon
          title={intl.formatMessage({
            id: 'admin.artistDetails.artistBiography',
          })}
          bodyId="admin.artistDetails.artistBiographyHelpText"
        >
          {intl.formatMessage({
            id: 'admin.artistDetails.artistBiography',
          })}
        </HeadingWithHelpIcon>
        <ValueContainer data-qaid="artist-biography">
          {description ? (
            <ExpandableText allowHTML text={description} truncateLength={50} />
          ) : (
            <None />
          )}
        </ValueContainer>
        <Heading
          title={intl.formatMessage({
            id: 'admin.shared.promoCode',
          })}
        >
          {intl.formatMessage({
            id: 'admin.shared.promoCode',
          })}
        </Heading>
        <ValueContainer data-qaid="promo-code">
          {performance.promoCodes!.length > 0 && performance?.promoCodes![0] ? (
            <PromoCodeLink
              promoCode={performance?.promoCodes![0]}
              truncateLength={40}
            />
          ) : (
            <None />
          )}
        </ValueContainer>
        {isPolicyCompliant && (
          <Heading data-qaid="artist-policy-compliant">
            {intl.formatMessage({
              id: 'admin.artistDetails.isPolicyCompliant',
            })}
          </Heading>
        )}
      </Col>
      <Col xs={12} sm={12} md={3}>
        <HeadingWithHelpIcon
          title={intl.formatMessage({
            id: 'admin.eventDetails.artists.primaryContact',
          })}
          bodyId="admin.eventPlanner.artists.primaryContactHelpText"
          data-qaid={`artist-${dataQaidPrefix}-primary-contact`}
        >
          {intl.formatMessage({
            id: 'admin.eventDetails.artists.primaryContact',
          })}
        </HeadingWithHelpIcon>
        <ValueContainer>
          <PrimaryContactInfo contactInfos={contactInfos} />
        </ValueContainer>
        <HeadingWithHelpIcon
          title={intl.formatMessage({
            id: 'admin.artistDetails.artistSupportLink',
          })}
          bodyId="admin.artistDetails.artistSupportLinkHelpText"
        >
          {intl.formatMessage({
            id: 'admin.artistDetails.artistSupportLink',
          })}
        </HeadingWithHelpIcon>
        <ValueContainer data-qaid="artist-support-link">
          {supportLink ? (
            <StyledLink href={supportLink} openInNewTab={true}>
              <TruncatedByCharText text={supportLink} truncateLength={30} />
            </StyledLink>
          ) : (
            <None />
          )}
        </ValueContainer>
        <Spacer mb={3} />
        <Heading>
          <HeaderContainer>
            {intl.formatMessage({
              id: 'admin.shared.forms',
            })}
            <InfoIconContainer>
              <HelpIconAndFlyover
                title={intl.formatMessage({
                  id: 'admin.shared.forms',
                })}
                bodyId="admin.eventPlanner.artists.uploadArtistForms.infoHelp"
              />
            </InfoIconContainer>
          </HeaderContainer>
        </Heading>
        <ValueContainer data-qaid="artist-forms">
          <HelperText>
            {intl.formatMessage({
              id:
                'admin.eventPlanner.artists.uploadArtistForms.uploadFileFormatAndSize',
            })}
          </HelperText>
          <Spacer mb={5} />
          <Heading>
            {intl.formatMessage({
              id: 'admin.eventPlanner.artists.uploadArtistForms.releaseForm',
            })}
          </Heading>
          <Spacer mb={2} />
          {performance.releaseForm
            ? renderFormDetails(performance.releaseForm)
            : renderFileUploader(DocumentTypes.RELEASE_FORM)}
          <Spacer mb={5} />
          <Heading>
            {intl.formatMessage({
              id:
                'admin.eventPlanner.artists.uploadArtistForms.participationAgreement',
            })}
          </Heading>
          <Spacer mb={2} />
          {performance.termsAndConditionsForm
            ? renderFormDetails(performance.termsAndConditionsForm)
            : renderFileUploader(DocumentTypes.TERMS_AND_CONDITIONS_FORM)}
        </ValueContainer>
      </Col>
      {deleteFormModal.isShowing && (
        <ConfirmationModal
          onCancel={() => deleteFormModal.hide()}
          description={intl.formatMessage({
            id:
              'admin.eventPlanner.artists.deleteArtistForm.deleteConfirmation',
          })}
          onConfirm={handleFormDeletion}
        />
      )}
      <Col xs={12} sm={12} md={3}>
        <Grid>
          <Col xs={6} sm={6} md={5}>
            <StyledHeading
              mb="2px"
              data-qaid={`artist-${dataQaidPrefix}-setup-notes`}
            >
              {intl.formatMessage({
                id: 'admin.artistDetails.artistSetup',
              })}
            </StyledHeading>
          </Col>
          <Col xs={1}>
            <InfoBox
              showCaret={false}
              width="300px"
              triggerElement={<ListIcon />}
              shouldFlyoverClose={flyoverClosed}
              innerContent={
                <EventPlannerArtistSetupSelectFlyover
                  performance={performance}
                  artist={performance && performance.artist}
                  onSuccess={() => {
                    setFlyoverClosed(true);
                    callbacks.navigateAfterArtistSetupSelect(event);
                  }}
                />
              }
            />
          </Col>
          <Col xs={1}>
            {artistSetup && artistSetup.id && (
              <IconLink
                data-qaid="update-performance-setup-link"
                onClick={() => {
                  callbacks.toggleArtistSetupEditModal(id);
                }}
              >
                <EditIcon />
              </IconLink>
            )}
          </Col>
        </Grid>
        {artistSetup && artistSetup.lastUpdatedAt && (
          <Subheader>
            {intl.formatMessage({
              id: 'admin.artistDetails.updated',
            })}{' '}
            {dateFormatter(artistSetup.lastUpdatedAt, 'shortMonthDateAndYear')}
          </Subheader>
        )}
        {artistSetup && !artistSetup.lastUpdatedAt && artistSetup.createdAt && (
          <Subheader>
            {intl.formatMessage({
              id: 'admin.artistDetails.created',
            })}{' '}
            {dateFormatter(artistSetup.createdAt, 'shortMonthDateAndYear')}
          </Subheader>
        )}
        <Spacer mb={3} />
        <Subheading data-qaid={`artist-${dataQaidPrefix}-setup-name`}>
          {intl.formatMessage({
            id: 'shared.setupName',
          })}
          :
        </Subheading>
        <SetupValueContainer>
          {artistSetup && artistSetup.setupName ? (
            getArtistSetupName(artistSetup)
          ) : (
            <None />
          )}
        </SetupValueContainer>
        <Subheading data-qaid={`artist-${dataQaidPrefix}-setup-performers`}>
          {intl.formatMessage({
            id: 'shared.numberOfPerformers',
          })}
          :
        </Subheading>
        <SetupValueContainer>
          {artistSetup && artistSetup.numPerformers ? (
            artistSetup.numPerformers
          ) : (
            <None />
          )}
        </SetupValueContainer>
        <Subheading data-qaid={`artist-${dataQaidPrefix}-setup-support`}>
          {intl.formatMessage({
            id: 'shared.productionSupport',
          })}
          :
        </Subheading>
        <SetupValueContainer>
          {artistSetup && artistSetup.audioSupport ? (
            <AudioSupportLabel>
              {artistSetup.audioSupport.replace('_', ' ')}
            </AudioSupportLabel>
          ) : (
            <None />
          )}
        </SetupValueContainer>
        <Subheading
          data-qaid={`artist-${dataQaidPrefix}-setup-additional-support`}
        >
          {intl.formatMessage({
            id: 'shared.additionalSupport',
          })}
          :
        </Subheading>
        <SetupValueContainer>
          {artistSetup && artistSetup.additionalSupportNotes ? (
            artistSetup.additionalSupportNotes
          ) : (
            <None />
          )}
        </SetupValueContainer>
        <Subheading data-qaid={`artist-${dataQaidPrefix}-setup-instruments`}>
          {intl.formatMessage({
            id: 'shared.instruments',
          })}
          :
        </Subheading>
        <SetupValueContainer>
          {artistSetup && artistSetup.instrumentNotes ? (
            artistSetup.instrumentNotes
          ) : (
            <None />
          )}
        </SetupValueContainer>
        <Spacer mb={3} />
      </Col>
    </Grid>
  );
};

const getPositions = (length: number) => {
  const numPositions = Math.max(3, length);
  return Array.from({ length: numPositions }, (_, i) => i + 1);
};

const SectionArtistsInfo: React.FC<Props> = ({ sectionData, callbacks }) => {
  const { dataBasicInfo, refetchEvent, navigateTo } = sectionData;
  const { event } = dataBasicInfo;
  const NUM_OF_ARTISTS = Math.max(3, event.performances.length);

  return (
    <MainContainer data-qaid="section-artists">
      {!event.performances.length
        ? [...Array(NUM_OF_ARTISTS).keys()].map(
            (val: number, index: number) => {
              return (
                <div key={index}>
                  <ArtistPerformance
                    dataQaidPrefix={val.toString()}
                    callbacks={{}}
                  />
                  {index < NUM_OF_ARTISTS - 1 && <GreyDivider />}
                </div>
              );
            }
          )
        : performancesForPositions(
            event.performances,
            getPositions(event.performances.length)
          ).map((performance: any, index: number) => (
            <div key={index}>
              {performance ? (
                <>
                  <ArtistPerformance
                    event={event}
                    performance={performance}
                    artistSetup={performance.artistSetup}
                    artistConfirmed={performance.artistConfirmed}
                    dataQaidPrefix={(index + 1).toString()}
                    callbacks={callbacks}
                    refetchEvent={refetchEvent}
                    navigateTo={navigateTo}
                  />
                  {index < NUM_OF_ARTISTS - 1 && <GreyDivider />}
                </>
              ) : (
                <>
                  <ArtistPerformance
                    dataQaidPrefix={(index + 1).toString()}
                    callbacks={{}}
                  />
                  {index < NUM_OF_ARTISTS - 1 && (
                    <>
                      <GreyDivider />
                      <Spacer mb={3} />
                    </>
                  )}
                </>
              )}
            </div>
          ))}
    </MainContainer>
  );
};

export default SectionArtistsInfo;
