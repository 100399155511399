import * as Yup from 'yup';

import { getNotesFormSchema } from 'app/admin/utils/string';

const venueFanExperienceEditFormSchema = (intl: any) =>
  Yup.object().shape({
    seating: Yup.string().required(
      intl.formatMessage({
        id: 'form.requiredField',
      })
    ),
    seatingNotes: getNotesFormSchema(intl),
  });

export default venueFanExperienceEditFormSchema;
