import React from 'react';
import { useIntl } from 'react-intl';

import { GlobalSetting } from 'app/typings/globalSettings';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { UpdateGlobalSettings } from 'app/admin/graphql/globalSettings/mutationHooks';
import { DetailsFormMainContainer } from 'app/admin/components/atoms/DetailContent';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

import SiteBannerEditForm from './SiteBannerEditForm';
import SiteBannerEditFormSchema from './SiteBannerEditFormSchema';

interface Props {
  contentProps?: any;
  setFormSubmitAction: (func: Function) => void;
  setDisplayConfirmation: (set: boolean) => void;
  setIsSubmitting: (set: boolean) => void;
  hide: VoidFunction;
}

const globalSettingForKey = (
  key: string,
  globalSettingsData: GlobalSetting[]
) => {
  const globalSetting = globalSettingsData.find(
    (globalSetting: GlobalSetting) => globalSetting.key == key
  );
  return globalSetting && globalSetting.value;
};

const getGlobalSettingInputValues = (values: any) => [
  {
    key: 'banner_link',
    value: values.bannerLink,
  },
  {
    key: 'banner_link_text',
    value: values.bannerLinkText,
  },
  {
    key: 'banner_message',
    value: values.bannerMessage,
  },
];

const SiteBannerEdit: React.FC<Props> = ({
  contentProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  hide,
}) => {
  const intl = useIntl();

  const globalSettingsData = contentProps.globalSettingsData;

  const formInitialValues = {
    bannerLink: globalSettingForKey('banner_link', globalSettingsData),
    bannerLinkText: globalSettingForKey('banner_link_text', globalSettingsData),
    bannerMessage: globalSettingForKey('banner_message', globalSettingsData),
  };

  const updateGlobalSettingsAction = UpdateGlobalSettings();

  const handleSubmit = useSubmitAction({
    submitAction: updateGlobalSettingsAction,
    submitVariables: (values: any) => ({
      globalSettingInputValues: getGlobalSettingInputValues(values),
    }),
    successMsg: intl.formatMessage({
      id: 'admin.siteBanner.form.successMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.siteBanner.form.failureMessage',
    }),
    onSuccess: () => {
      hide();
    },
  });

  return (
    <ModalContentContainer>
      <DetailsFormMainContainer>
        <DetailsHeader
          title={intl.formatMessage({
            id: 'admin.siteBanner.pageTitle',
          })}
        />
        <GenericForm
          formInitialValues={formInitialValues}
          renderFormComponent={(renderProps: any) => (
            <SiteBannerEditForm formikProps={renderProps.formikProps} />
          )}
          onSubmit={handleSubmit}
          formSchema={SiteBannerEditFormSchema(intl)}
          setFormSubmitAction={setFormSubmitAction}
          setDisplayConfirmation={setDisplayConfirmation}
          setIsSubmitting={setIsSubmitting}
          dataQaId="site-banner--edit-form"
        />
      </DetailsFormMainContainer>
    </ModalContentContainer>
  );
};

export default SiteBannerEdit;
