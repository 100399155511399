import React from 'react';
import { useIntl } from 'react-intl';

import { FlexDivWithSpacing } from 'app/shared/components/atoms/FlexDivWithSpacing';
import { FormSectionTitle } from 'app/shared/components/atoms/FormContent';
import { Body } from 'app/shared/components/atoms/TypographyManualCSS';

export interface SectionHeaderProps {
  titleIntlId: string;
  descriptionIntlId: string;
}

export const CollectionBasicInfoFormSectionHeader: React.FC<SectionHeaderProps> = ({
  titleIntlId,
  descriptionIntlId,
}) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <FormSectionTitle mb={1}>
        {formatMessage({ id: titleIntlId })}
      </FormSectionTitle>
      <FlexDivWithSpacing mb={3}>
        <Body>{formatMessage({ id: descriptionIntlId })}</Body>
      </FlexDivWithSpacing>
    </>
  );
};
