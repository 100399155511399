import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { GlobalSetting } from 'app/typings/globalSettings';
import { get } from 'app/shared/utils/get';
import useModal from 'app/shared/utils/useModal';
import { GetGlobalSettings } from 'app/shared/graphql/globalSettings/queryHooks';
import { Container } from 'app/shared/components/atoms/Container';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import { EditIcon } from 'app/shared/components/atoms/IconLibrary';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { H1, H3 } from 'app/shared/components/atoms/TypographyManualCSS';
import RoutableModal from 'app/shared/components/molecules/RoutableModal';
import SEO from 'app/shared/components/molecules/SEO';
import { GlobalError } from 'app/shared/components/pages/Status';
import Layout from 'app/admin/layouts/Admin';

// @ts-ignore
const MainContainer = styled(Container)`
  @media screen and (min-width: 1300px) {
    max-width: 1260px;
  }
  margin-bottom: 80px;
`;

const HeaderContainer = styled.div`
  margin-bottom: 30px;
`;

const Title = styled(H1)`
  ${({ theme }) => css`
    ${theme.media.xs`
      margin-bottom: 20px;
    `}
  `}
`;

const ContentHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
`;

const ContentTitleContainer = styled.div``;

const ContentTitle = styled(H3)`
  margin-bottom: 15px;
`;

const EditIconContainer = styled.div`
  padding-left: 25px;
  padding-bottom: 10px;
`;

const ContentNote = styled.div`
  font-size: 14px;
  margin-bottom: 30px;
`;

const ContentBox = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.silverSprings};
    border-radius: 10px;
    padding: 20px 30px 10px 30px;
    margin-bottom: 30px;
    width: 60%;
  `}
`;

const ContentLabel = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
`;

const ContentItemBox = styled.div`
  background-color: #fff;
  border-radius: 20px;
  padding: 9px 10px 9px 15px;
  margin-bottom: 22px;
  width: 100%;
  font-size: 12px;
`;

const globalSettingsKeys = [
  'banner_link',
  'banner_link_text',
  'banner_message',
];

const globalSettingForKey = (
  key: string,
  globalSettingsData: GlobalSetting[]
) => {
  const globalSetting = globalSettingsData.find(
    (globalSetting: GlobalSetting) => globalSetting.key == key
  );
  return globalSetting && globalSetting.value;
};

const AdminSiteBanner: React.FC = () => {
  const intl = useIntl();
  const [siteBannerEditModal, toggleSiteBannerEditModal] = useModal();

  const {
    loading: loadingGlobalSettings,
    error: errorGlobalSettings,
    data: dataGlobalSettings,
  } = GetGlobalSettings({
    keys: globalSettingsKeys.join(','),
  });

  if ((!loadingGlobalSettings && !dataGlobalSettings) || errorGlobalSettings) {
    return <GlobalError />;
  }

  let globalSettingsData = get(
    dataGlobalSettings,
    'globalSettings.globalSettings',
    []
  );

  const bannerLink = globalSettingForKey('banner_link', globalSettingsData);
  const bannerLinkText = globalSettingForKey(
    'banner_link_text',
    globalSettingsData
  );
  const bannerMessage = globalSettingForKey(
    'banner_message',
    globalSettingsData
  );

  return (
    <>
      <SEO
        title={`Admin ${intl.formatMessage({
          id: 'admin.siteBanner.pageTitle',
        })}`}
      />
      <Layout data-qaid="admin-site-banner">
        <MainContainer>
          <HeaderContainer>
            <Title>
              {intl.formatMessage({
                id: 'admin.siteBanner.pageTitle',
              })}
            </Title>
          </HeaderContainer>
          <DottedLine />
          <ContentHeaderContainer>
            <ContentTitleContainer>
              <ContentTitle>
                {intl.formatMessage({
                  id: 'admin.siteBanner.contentTitle',
                })}
              </ContentTitle>
            </ContentTitleContainer>
            <EditIconContainer>
              <EditIcon onClick={() => toggleSiteBannerEditModal()} />
            </EditIconContainer>
          </ContentHeaderContainer>
          <ContentNote>
            {intl.formatMessage({
              id: 'admin.siteBanner.contentNote',
            })}
          </ContentNote>
          <ContentBox>
            <ContentLabel>
              {intl.formatMessage({
                id: 'admin.siteBanner.message',
              })}
            </ContentLabel>
            <ContentItemBox>{bannerMessage}</ContentItemBox>
            <DottedLine />
            <Spacer mb={5} />
            <ContentLabel>
              {intl.formatMessage({
                id: 'admin.siteBanner.linkText',
              })}
            </ContentLabel>
            <ContentItemBox>{bannerLinkText}</ContentItemBox>
            <DottedLine />
            <Spacer mb={5} />
            <ContentLabel data-qaid="banner-link-label">
              {intl.formatMessage({
                id: 'admin.siteBanner.linkUrl',
              })}
            </ContentLabel>
            <ContentItemBox>{bannerLink}</ContentItemBox>
          </ContentBox>
        </MainContainer>
        {siteBannerEditModal.isShowing && (
          <RoutableModal
            hide={siteBannerEditModal.hide}
            initialRouteProps={{ globalSettingsData }}
            initialRouteName="site-banner-edit"
            dataQaidSuffix="admin-edit-site-banner"
          />
        )}
      </Layout>
    </>
  );
};

export default AdminSiteBanner;
