export const sortOptions = [
  {
    title: 'Title (A - Z)',
    value: {
      by: 'title',
      direction: 'asc',
    },
  },
  {
    title: 'Title (Z - A)',
    value: {
      by: 'title',
      direction: 'desc',
    },
  },
  {
    title: 'Earliest Closing Date First',
    value: {
      by: 'closes_at',
      direction: 'asc',
    },
  },
  {
    title: 'Latest Closing Date First',
    value: {
      by: 'closes_at',
      direction: 'desc',
    },
  },
  {
    title: 'UID (A - Z)',
    value: {
      by: 'uid',
      direction: 'asc',
    },
  },
  {
    title: 'UID (Z - A)',
    value: {
      by: 'uid',
      direction: 'desc',
    },
  },
  {
    title: 'Most Entries',
    value: {
      by: 'entries_count',
      direction: 'desc',
    },
  },
  {
    title: 'Fewest Entries',
    value: {
      by: 'entries_count',
      direction: 'asc',
    },
  },
];

export const staticOptions = {
  status: [
    {
      title: 'Cancelled',
      value: 'cancelled',
    },
  ],
  closesAt: [
    {
      title: 'Today',
      value: 'today',
    },
    {
      title: 'Tomorrow',
      value: 'tomorrow',
    },
    {
      title: 'Next 7 Days',
      value: 'next_7_days',
    },
    {
      title: 'Next 30 Days',
      value: 'next_30_days',
    },
    {
      title: 'Next 90 Days',
      value: 'next_90_days',
    },
    {
      title: 'All Future',
      value: 'next_all',
    },
    {
      title: 'Past 7 Days',
      value: 'past_7_days',
    },
    {
      title: 'Past 30 Days',
      value: 'past_30_days',
    },
    {
      title: 'Past 90 Days',
      value: 'past_90_days',
    },
    {
      title: 'All Past',
      value: 'past_all',
    },
  ],
};
