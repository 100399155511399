import React, { useContext, useEffect } from 'react';
import loadable from '@loadable/component';
import { useIntl } from 'react-intl';

import { getIdsSubmitVariable } from 'app/shared/utils/form';
import { get } from 'app/shared/utils/get';
import usePermission from 'app/shared/utils/usePermission';
import { GetCitiesLite } from 'app/shared/graphql/cities/queryHooks';
import { ListPage, ListPageContext } from 'app/shared/context/ListPage';
import { LoadingBlocks } from 'app/shared/components/atoms/LoadingBlocks';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { CityInfoForLimitingByCity } from 'app/admin/utils/cityPermissions';
import {
  filterInfoListFilteredByCheckOnFilterName,
  getBusinessOwnerOptions,
  getCityOptions,
  getFilterNamesAndOptionsForTitleMapping,
} from 'app/admin/utils/optionLists';
import { GetArtistApplications } from 'app/admin/graphql/artistApplications/queryHooks';
import { GetBusinessOwners } from 'app/admin/graphql/businessOwners/queryHooks';
import { CityTitles as CityTitlesLimitedBy } from 'app/admin/components/atoms/CityTitles';
import ListingHeader from 'app/admin/components/organisms/ListingHeader';
import Layout from 'app/admin/layouts/ListPage';

import { dateOptions, sortOptions } from './options';
import Analytics from './tracking';

const GlobalError = loadable(
  () => import('app/shared/components/pages/Status'),
  {
    resolveComponent: components => components.GlobalError,
  }
);
const ListPageTemplate = loadable(() =>
  import('app/admin/components/templates/ListPage')
);
const ListingControls = loadable(() =>
  import('app/admin/components/organisms/ListingControls')
);
const ListingFilter = loadable(() =>
  import('app/admin/components/organisms/ListingFilter')
);
const ListingFooter = loadable(() =>
  import('app/admin/components/molecules/ListingFooter')
);
const RoutableModal = loadable(() =>
  import('app/shared/components/molecules/RoutableModal')
);
const ListingNoResults = loadable(() =>
  import('app/admin/components/molecules/ListingNoResults')
);
const CardGrid = loadable(() =>
  import('app/admin/components/organisms/CardGrid')
);
const ArtistApplicationCard = loadable(() =>
  import('app/admin/components/organisms/ArtistApplicationCard')
);
const DottedLine = loadable(() =>
  import('app/shared/components/atoms/DottedLine')
);

const pageStateConfig = {
  filterNames: [
    'closest_sofar_city',
    'city_of_application',
    'created_date',
    'business_owner',
    'status',
  ],
  defaultSort: { by: 'created_at', direction: 'asc' },
  textSearchParamName: 'artist_application_search',
  genericModalIdParamName: 'artist_application_id',
};

const AdminArtistApplications: React.FC = () => {
  const intl = useIntl();
  const pageState = useContext(ListPageContext);
  const viewArtistApplicationsByCity = usePermission(
    'artistApplication.viewByCity'
  );
  const PER_PAGE = 18;

  const {
    loading: loadingCities,
    error: errorCities,
    data: dataCities,
  } = GetCitiesLite();

  const { cityIdsToLimitBy, cityTitlesToLimitBy } = CityInfoForLimitingByCity(
    'artistApplication.viewByCity',
    dataCities
  );

  const {
    data: dataBusinessOwners,
    loading: loadingBusinessOwners,
    error: errorBusinessOwners,
  } = GetBusinessOwners({
    archivedStatus: 'not_archived',
    skipPagination: true,
  });
  const cityOptions = getCityOptions(dataCities);

  const businessOwnerOptions = getBusinessOwnerOptions(dataBusinessOwners);
  const statusOptions = [
    { title: 'Expired', value: 'expired' },
    { title: 'Archived', value: 'archived' },
    { title: 'Referred', value: 'referred' },
    { title: 'Not Referred', value: 'not_referred' },
  ];

  const filterDropdownOptionsInfoList = filterInfoListFilteredByCheckOnFilterName(
    [
      {
        filterName: 'status',
        dropdownParams: {
          searchBar: false,
          title: intl.formatMessage({
            id: 'shared.status',
          }),
          options: statusOptions,
        },
      },
      {
        filterName: 'business_owner',
        dropdownParams: {
          searchBar: false,
          title: intl.formatMessage({
            id: 'shared.businessOwners',
          }),
          options: businessOwnerOptions,
        },
      },
      {
        filterName: 'city_of_application',
        dropdownParams: {
          searchBar: true,
          title: intl.formatMessage({
            id: 'admin.artistApplications.filter.cityOfApplication',
          }),
          groupBy: 'country',
          groupById: 'country',
          options: cityOptions,
        },
      },
      {
        filterName: 'closest_sofar_city',
        dropdownParams: {
          searchBar: true,
          title: intl.formatMessage({
            id: 'admin.artistApplications.filter.closestSofarCity',
          }),
          groupBy: 'country',
          groupById: 'country',
          options: cityOptions,
        },
      },
      {
        filterName: 'created_date',
        dropdownParams: {
          searchBar: false,
          title: intl.formatMessage({
            id: 'shared.date',
          }),
          hasDateInput: true,
          options: dateOptions(),
        },
      },
    ],
    {
      business_owner: !viewArtistApplicationsByCity,
      city_of_application: !viewArtistApplicationsByCity,
    }
  );

  const {
    loading,
    error,
    data,
    refetch: refetchArtistApplications,
  } = GetArtistApplications({
    cityOfApplication: !cityIdsToLimitBy
      ? pageState.filterListVariable('city_of_application')
      : undefined,
    cityIdsOfApplication: getIdsSubmitVariable(cityIdsToLimitBy),
    closestSofarCity: pageState.filterListVariable('closest_sofar_city'),
    createdDate: pageState.filterListVariable('created_date'),
    businessOwner: pageState.filterListVariable('business_owner'),
    status: pageState.filterListVariable('status') || 'open',
    artistApplicationSearch: pageState.textSearchState,
    orderBy: pageState.sortState.by,
    orderDirection: pageState.sortState.direction,
    page: pageState.page,
    perPage: PER_PAGE,
  });

  useEffect(() => {
    const preloadedComponents = [
      ArtistApplicationCard,
      DottedLine,
      CardGrid,
      ListPageTemplate,
      ListingControls,
      ListingFilter,
    ];
    preloadedComponents.map(c => c.preload());

    pageState.updateDetailsModal();
  }, []);

  useEffect(() => {
    pageState.updateGenericModal();
  }, []);

  useEffect(() => {
    pageState.updateScrollPositionOnPage();
  }, [pageState.genericModal.isShowing]);

  useEffect(() => {
    pageState.setupFilterLabelTitleMapping(
      getFilterNamesAndOptionsForTitleMapping(filterDropdownOptionsInfoList)
    );
  }, [dataCities]);

  useEffect(() => {
    pageState.updatePageUrl();
  }, [
    pageState.page,
    pageState.sortState,
    pageState.filterState,
    pageState.textSearchState,
    pageState.genericData,
    pageState.genericModal.isShowing,
  ]);

  useEffect(() => {
    Analytics.pushDataLayer();
  }, [pageState.filterState, pageState.textSearchState]);

  if (
    (!loading && !data) ||
    error ||
    (!loadingCities && !dataCities) ||
    errorCities ||
    (!loadingBusinessOwners && !dataBusinessOwners) ||
    errorBusinessOwners
  ) {
    return <GlobalError />;
  }

  const modalsContent = () => {
    return (
      <>
        {pageState.genericModal.isShowing && (
          <RoutableModal
            hide={pageState.genericModal.hide}
            initialRouteName="artist-application-review"
            initialRouteProps={{
              ...pageState.genericData,
              refetchArtistApplications,
            }}
            dataQaidSuffix="admin-review-artist-application"
          />
        )}
      </>
    );
  };

  return (
    <Layout scrollDisabled={pageState.genericModal.isShowing}>
      <ListPageTemplate
        pageTitle={intl.formatMessage({
          id: 'admin.artistApplications.pageTitle',
        })}
        modalsContent={modalsContent}
      >
        <ListingHeader
          pageTitle={intl.formatMessage({
            id: 'admin.artistApplications.pageTitle',
          })}
          searchPlaceholder={intl.formatMessage({
            id: 'admin.artistApplications.textSearch.placeholder',
          })}
          searchValue={pageState.textSearchState}
          onSearch={pageState.handleTextSearch}
          dataQaidPrefix="artist-application"
        />
        <CityTitlesLimitedBy cityTitles={cityTitlesToLimitBy} />
        <div>
          <ListingControls
            sortOptions={sortOptions}
            orderBy={pageState.sortState.by}
            orderDirection={pageState.sortState.direction}
            totalRecords={data && data.artistApplications.metadata.totalRecords}
            loading={loading}
            onSort={pageState.handleSortChange}
            onReset={pageState.handleResetFilters}
            dataQaidPrefix="artist-application"
          />
          <ListingFilter
            filterTitle={intl.formatMessage({
              id: 'admin.artistApplications.filterTitle',
            })}
            textSearchString={pageState.textSearchState}
            handleTextSearchLabelClose={pageState.handleTextSearchLabelClose}
            labelTitleMapping={pageState.filterLabelTitleMapping}
            dropdownOptionsInfoList={filterDropdownOptionsInfoList}
            filterState={pageState.filterState}
            handleRemoveFilter={pageState.handleRemoveFilter}
            handleFilterChange={pageState.handleFilterChange}
          />
        </div>

        <div>
          <CardGrid
            objectData={get(data, 'artistApplications.artistApplications', [])}
            renderCardComponent={(artistApplication: any, i: number) => (
              <ArtistApplicationCard
                index={i}
                key={artistApplication.id}
                dataQaidPrefix="artist-application"
                onShowArtistApplicationReview={
                  pageState.toggleGenericModalAndSetGenericData
                }
                id={artistApplication.id}
                artistName={artistApplication.artistName}
                cityTitle={artistApplication.city?.title}
                createdAt={artistApplication.createdAt}
                homeCityDescription={artistApplication.homeCityDescription}
                referrer={artistApplication.referrer}
                status={artistApplication.status}
              />
            )}
            dataQaid="admin-artist-applications-list"
            loading={loading}
            loadingComponent={
              <LoadingBlocks.Rectangle width="100%" height="420px" />
            }
            hideDividerOnSize="xs"
          />
          <DottedLine />
          <Spacer mb={2} />
        </div>

        <ListingNoResults
          entityName={intl.formatMessage({
            id: 'admin.artistApplications.noResultsEntityName',
          })}
          numResults={get(
            data,
            'artistApplications.artistApplications.length',
            undefined
          )}
          loading={loading}
        />

        <ListingFooter
          numTotalRecords={get(
            data,
            'artistApplications.metadata.totalRecords',
            undefined
          )}
          perPage={PER_PAGE}
          currentPage={pageState.page}
          onPageChange={pageState.handlePageChange}
          loading={loading}
          dataQaidPrefix="artist-applications"
        />
      </ListPageTemplate>
    </Layout>
  );
};

const AdminArtistApplicationsWrapper: React.FC = () => (
  <ListPage config={pageStateConfig} analytics={Analytics}>
    <AdminArtistApplications />
  </ListPage>
);

export default AdminArtistApplicationsWrapper;
