import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { ManualCSSColors } from 'app/shared/theme';
import { useCurrentTheme } from 'app/shared/theme';
import FindNearestSofarCities from 'app/shared/utils/findNearestSofarCities';
import { get } from 'app/shared/utils/get';
import { isEmpty } from 'app/shared/utils/object';
import { Icon } from 'app/shared/components/atoms/IconManualCSS';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Body2 } from 'app/shared/components/atoms/TypographyManualCSS';
import FormGroup from 'app/shared/components/molecules/FormGroup';
import GooglePlaceSelect from 'app/shared/components/molecules/GooglePlaceSelect';

// @ts-ignore
const SelectedPlaceMessage = styled(Body2)`
  margin-left: 5px;
`;

interface Props {
  label: string;
  placeholder: string;
  name: string;
  initialValue: string;
  initialPlaceLatitude?: number | null;
  initialPlaceLongitude?: number | null;
  onChange: (e: any) => void;
  cities: any;
  countryCodes?: string[];
  errorMsg?: string;
  required?: boolean;
  placeType?: string | string[];
  noSofarCityNearPlaceMessage?: string;
  inputDataQaid?: string;
  formGroupDataQaid?: string;
}

const PlaceSelect: React.FC<Props> = ({
  label,
  placeholder,
  name,
  initialValue,
  initialPlaceLatitude,
  initialPlaceLongitude,
  onChange,
  cities,
  countryCodes,
  errorMsg,
  required,
  placeType,
  noSofarCityNearPlaceMessage,
  inputDataQaid,
  formGroupDataQaid,
}) => {
  const intl = useIntl();
  const theme = useCurrentTheme();

  const sofarCities =
    cities.loading || cities.error ? [] : get(cities, 'data.cities.cities', []);

  let initialTitle = undefined;
  let initialUserSelected = false;

  if (initialPlaceLatitude && initialPlaceLongitude) {
    const nearestCities = FindNearestSofarCities(
      {
        latitude: initialPlaceLatitude,
        longitude: initialPlaceLongitude,
      },
      sofarCities
    );

    if (!isEmpty(nearestCities) && nearestCities[0].distanceBetween <= 50) {
      initialTitle = nearestCities[0].title;
    }
    initialUserSelected = true;
  }

  const [selectedPlace, setSelectedPlace] = useState({
    title: initialTitle,
    userSelected: initialUserSelected,
  });

  const handlePlaceSelect = (place: any) => {
    if (isEmpty(place) || isEmpty(sofarCities)) {
      return;
    }

    if (place.cityDescription == '') {
      setSelectedPlace({ title: undefined, userSelected: false });
      onChange({
        cityId: undefined,
        homeCityDescription: '',
        homeCity: '',
        homeState: '',
        homeCountryCode: '',
        homeCityLatitude: null,
        homeCityLongitude: null,
        closestSofarCityId: null,
        url: '',
      });
    } else {
      const nearestCities = FindNearestSofarCities(place, sofarCities);

      if (isEmpty(nearestCities)) {
        return;
      }

      const nearestCity = nearestCities[0];
      const distance = Math.round(nearestCity.distanceBetween);

      if (distance <= 50) {
        setSelectedPlace({ title: nearestCity.title, userSelected: true });
        onChange({
          cityId: nearestCity.id,
          homeCityDescription: place.cityDescription,
          homeCity: place.city,
          homeState: place.state,
          homeCountryCode: place.countryCode,
          homeCityLatitude: place.latitude,
          homeCityLongitude: place.longitude,
          closestSofarCityId: nearestCity.id,
          url: place.url,
        });
      } else {
        setSelectedPlace({ title: undefined, userSelected: true });
        onChange({
          cityId: undefined,
          homeCityDescription: place.cityDescription,
          homeCity: place.city,
          homeState: place.state,
          homeCountryCode: place.countryCode,
          homeCityLatitude: place.latitude,
          homeCityLongitude: place.longitude,
          closestSofarCityId: nearestCity.id,
          url: place.url,
        });
      }
    }
  };

  const renderSofarCityMessage = () => {
    const { title, userSelected } = selectedPlace;

    if (!userSelected) {
      return;
    }

    let iconName = 'checkCircle';
    let iconColor: ManualCSSColors = 'green900';
    let content = `${intl.formatMessage({
      id: 'placeSelect.nearestSofarCity',
    })} ${title}.`;

    if (!title && userSelected) {
      iconName = 'alertCircle';
      iconColor = 'paintItBlack';
      content =
        noSofarCityNearPlaceMessage ||
        intl.formatMessage({
          id: 'placeSelect.sofarNotActive',
        });
    }

    return (
      <SelectedPlaceMessage color={theme.colors.blueSmoke}>
        <Icon name={iconName} color={iconColor} />{' ' // eslint-disable-line
        }
        {content}
      </SelectedPlaceMessage>
    );
  };

  return (
    <>
      <FormGroup
        data-qaid={formGroupDataQaid}
        label={label}
        errorMsg={errorMsg}
        required={required}
      >
        <GooglePlaceSelect
          data-qaid={inputDataQaid}
          placeholder={placeholder}
          onChange={handlePlaceSelect}
          name={name}
          initialValue={initialValue}
          shouldAddListener={!isEmpty(sofarCities)}
          placeType={placeType}
          countryCodes={countryCodes}
        />
      </FormGroup>
      <Spacer mb={2} />
      {renderSofarCityMessage()}
    </>
  );
};

export default PlaceSelect;
