import React, { useEffect } from 'react';
import loadable from '@loadable/component';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Performance } from 'app/typings/performances';
import { dateFormatter } from 'app/shared/utils/datetime';
import useModal from 'app/shared/utils/useModal';
import usePermission from 'app/shared/utils/usePermission';
import { PrimaryButton } from 'app/shared/components/atoms/Button';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import { ChecklistIcon } from 'app/shared/components/atoms/IconLibrary';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Spinner } from 'app/shared/components/atoms/SpinnerManualCSS';
import RoutableModal from 'app/shared/components/molecules/RoutableModal';
import { performancesForPositions } from 'app/admin/utils/artists';
import { GetEventForCrewPortal } from 'app/admin/graphql/events/queryHooks';
import { GetCrewMessages } from 'app/admin/graphql/staffMembers/queryHooks';
import { Link, SectionText } from 'app/admin/components/atoms/CrewEventContent';
import CrewEventArtistInfo from 'app/admin/components/organisms/CrewEventArtistInfo';
import CrewEventAudioInfo from 'app/admin/components/organisms/CrewEventAudioInfo';
import { CancelledTag } from 'app/admin/components/organisms/CrewEventBasicInfo';
import CrewEventBasicInfo from 'app/admin/components/organisms/CrewEventBasicInfo';
import CrewEventBreakingNews from 'app/admin/components/organisms/CrewEventBreakingNews';
import CrewEventGuestInfo from 'app/admin/components/organisms/CrewEventGuestInfo';
import CrewEventStaffInfo from 'app/admin/components/organisms/CrewEventStaffInfo';
import CrewEventSuppliesInfo from 'app/admin/components/organisms/CrewEventSuppliesInfo';
import CrewEventVenueInfo from 'app/admin/components/organisms/CrewEventVenueInfo';
import CrewEventVenueSetupInfo from 'app/admin/components/organisms/CrewEventVenueSetupInfo';
import ListingHeader from 'app/admin/components/organisms/ListingHeader';
import ListPageTemplate from 'app/admin/components/templates/ListPage';
import Layout from 'app/admin/layouts/ListPage';

const GlobalError = loadable(
  () => import('app/shared/components/pages/Status'),
  {
    resolveComponent: components => components.GlobalError,
  }
);

const StyledContainer = styled.div`
  ${({ theme }) => css`
    ${theme.media.md`
      max-width: 800px;
    `}
  `}
`;

const GuestlistIconAndText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const GuestlistText = styled.div`
  margin-left: 15px;
`;

interface AdminCrewEventProps {
  eventId: number;
}

const AdminCrewEvent: React.FC<AdminCrewEventProps> = ({ eventId }) => {
  const intl = useIntl();
  const hasGuestlistAccessPermission = usePermission('event.guestlist.access');

  const pollInterval = process.env.CREW_ONE_PAGER_POLL_INTERVAL || '30000';

  const [guestlistModal, toggleGuestlistModal] = useModal();
  const artistPositionsToShow = { min: 3, max: 5 };

  const {
    loading: loadingEvent,
    error: errorEvent,
    data: dataEvent,
    refetch: refetchEvent,
  } = GetEventForCrewPortal({
    id: eventId,
    fetchPolicy: 'no-cache',
  });

  const {
    loading: loadingCrewMessages,
    error: errorCrewMessages,
    data: dataCrewMessages,
    refetch: refetchCrewMessages,
  } = GetCrewMessages({
    eventId,
    isArchived: false,
    fetchPolicy: 'no-cache',
  });

  const event = dataEvent?.event;
  const performancesToDisplay = event?.performances?.filter(
    (perf: Performance) => perf.artistConfirmed
  );
  const performances = performancesForPositions(
    performancesToDisplay || [],
    [...Array(artistPositionsToShow.max).keys()].map(
      (index: number) => index + 1
    )
  );
  const maxPerformancePosition =
    Math.max(
      ...(performancesToDisplay?.map(
        (perf: Performance) => perf?.position || 0
      ) || [])
    ) || artistPositionsToShow.min;

  useEffect(() => {
    const pollIntervalMs = parseInt(pollInterval);
    if (pollIntervalMs && pollIntervalMs > 0) {
      const refetchInterval = setInterval(() => {
        refetchEvent();
        refetchCrewMessages();
      }, pollIntervalMs);
      return () => {
        clearInterval(refetchInterval);
      };
    }
    return () => {};
  }, []);

  if (
    (!loadingEvent && !dataEvent) ||
    errorEvent ||
    (!loadingCrewMessages && !dataCrewMessages) ||
    errorCrewMessages
  ) {
    return <GlobalError />;
  }

  return (
    <Layout scrollDisabled={false} noNavbarMargin={true}>
      <ListPageTemplate
        pageTitle={intl.formatMessage({
          id: 'admin.crewEvent.title',
        })}
      >
        <ListingHeader
          pageTitle={intl.formatMessage({
            id: 'admin.crewEvent.title',
          })}
          dataQaidPrefix="crew-portal"
          mobileTitleSpacing="10px 0px 5px 0px"
        />
        <StyledContainer>
          <Spacer mb={2} />
          {loadingEvent && <Spinner />}
          {event && (
            <>
              <div>
                <SectionText>
                  <Link
                    href={`https://www.google.com/maps/place/${event?.venue?.address}`}
                    target="_blank"
                    data-qaid="crew-event-venue-location"
                  >
                    {event?.venue?.venueName && (
                      <>
                        <strong>{event?.venue?.venueName} —</strong>{' '}
                      </>
                    )}
                    {event?.venue?.address}
                  </Link>
                </SectionText>
                <Spacer mt={2} />
                {event.cancelled && <CancelledTag />}
                <SectionText>
                  {dateFormatter(
                    event?.startsAt || '',
                    'longWeekdayLongMonthDateAndYear',
                    event?.city?.timezone
                  )}
                  <Spacer mt={1} />
                  {[event?.city?.title, event?.neighborhood?.title]
                    .filter((part: any) => !!part)
                    .join(', ')}
                  <Spacer mt={1} />
                  {event?.crewLoadInAt &&
                    `Crew load in ${dateFormatter(
                      event?.crewLoadInAt,
                      'hoursAndMinutesWithSpacedAMPM',
                      event?.city?.timezone
                    )}`}
                </SectionText>
                <Spacer mt={1} />
                <SectionText>
                  {intl.formatMessage({
                    id: 'shared.capacity',
                  })}
                  : {event.venue?.capacity || 'TBD'}
                </SectionText>
                <Spacer mt={1} />
                <SectionText>
                  {intl.formatMessage({
                    id: 'admin.shared.ticketsAvailable',
                  })}
                  : {event.numTicketsAvailableForSale}
                </SectionText>
                {hasGuestlistAccessPermission && (
                  <>
                    <Spacer mt={4} />
                    <PrimaryButton
                      onClick={
                        !event.cancelled ? toggleGuestlistModal : undefined
                      }
                      disabled={event.cancelled}
                      small={true}
                    >
                      <GuestlistIconAndText>
                        <ChecklistIcon
                          iconColor="white"
                          disabled={event.cancelled}
                        />
                        <GuestlistText>
                          {intl.formatMessage({
                            id: 'admin.crewEvent.viewGuestlist',
                          })}
                        </GuestlistText>
                      </GuestlistIconAndText>
                    </PrimaryButton>
                  </>
                )}
                <Spacer mt={2} />
              </div>
              <Spacer mt={6} />
              <CrewEventBreakingNews
                eventId={eventId}
                messages={dataCrewMessages?.crewMessages.crewMessages || []}
              />
              <Spacer mt={4} mb={4}>
                <DottedLine />
              </Spacer>
              <CrewEventBasicInfo event={event} intl={intl} />
              <Spacer mt={4} mb={4}>
                <DottedLine />
              </Spacer>
              <CrewEventStaffInfo event={event} />
              <Spacer mt={4} mb={4}>
                <DottedLine />
              </Spacer>
              <CrewEventVenueInfo event={event} />
              <Spacer mt={4} mb={4}>
                <DottedLine />
              </Spacer>
              <CrewEventSuppliesInfo event={event} />
              <Spacer mt={4} mb={4}>
                <DottedLine />
              </Spacer>
              <CrewEventVenueSetupInfo event={event} />
              <Spacer mt={4} mb={4}>
                <DottedLine />
              </Spacer>
              <CrewEventAudioInfo event={event} />
              <Spacer mt={4} mb={4}>
                <DottedLine />
              </Spacer>
              <CrewEventGuestInfo
                event={event}
                toggleGuestlistModal={toggleGuestlistModal}
              />
              <Spacer mt={4} mb={4}>
                <DottedLine />
              </Spacer>
              {performances.map((performance: Performance, index: number) => (
                <React.Fragment key={index}>
                  {index <= maxPerformancePosition - 1 && (
                    <CrewEventArtistInfo
                      event={event}
                      performance={performance}
                      position={index + 1}
                    />
                  )}
                  {index < maxPerformancePosition - 1 && (
                    <Spacer mt={4} mb={4}>
                      <DottedLine />
                    </Spacer>
                  )}
                </React.Fragment>
              ))}
              <Spacer mb={4} />
            </>
          )}
        </StyledContainer>
        {guestlistModal.isShowing && (
          <RoutableModal
            hide={guestlistModal.hide}
            initialRouteProps={{
              id: eventId,
            }}
            initialRouteName="guestlist-day-of-show"
            dataQaidSuffix="guestlist-modal"
          />
        )}
      </ListPageTemplate>
    </Layout>
  );
};

const AdminCrewEventWrapper: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { eventId } = params;
  const parsedEventId = eventId && parseInt(eventId);
  if (!parsedEventId) {
    navigate('/admin/crew-portal');
    return null;
  }
  return <AdminCrewEvent eventId={parsedEventId} />;
};

export default AdminCrewEventWrapper;
