import styled, { css } from 'styled-components';

import { ManualCSSColors } from 'app/shared/theme';
import {
  withSpacing,
  WithSpacingProps,
} from 'app/shared/components/styleUtils/manualCSS/withSpacing';

interface KeylineProps extends WithSpacingProps {
  desktopOnly?: boolean;
  mobileOnly?: boolean;
  lineColor?: ManualCSSColors;
  width?: string;
}

export const Keyline = styled.div<KeylineProps>`
  ${withSpacing}

  ${({
    theme,
    desktopOnly = false,
    mobileOnly = false,
    lineColor = 'backToBlack',
    width = 'inherit',
  }) => css`
    width: ${width};
    border-bottom: 1px solid ${theme.colors[lineColor]};

    ${mobileOnly &&
      css`
        ${theme.media.xs`
          display: block;
        `}
        ${theme.media.md`
          display: none;
        `}
      `}
    }
    ${desktopOnly &&
      css`
        ${theme.media.xs`
          display: none;
        `}
        ${theme.media.md`
          display: block;
        `}
      `}
  `}
`;
