import React from 'react';
import { useIntl } from 'react-intl';

import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { AcceptBookingRequest } from 'app/admin/graphql/bookingRequests/mutationHooks';
import { DetailsFormMainContainer } from 'app/admin/components/atoms/DetailContent';

import BookingRequestAcceptForm from './BookingRequestAcceptForm';
import BookingRequestAcceptFormSchema from './BookingRequestAcceptFormSchema';

interface Props {
  contentProps?: any;
  setFormSubmitAction: (func: Function) => void;
  setDisplayConfirmation: (set: boolean) => void;
  setIsSubmitting: (set: boolean) => void;
  navigateTo: (routeData: object) => void;
  hide: VoidFunction;
}

const BookingRequestAccept: React.FC<Props> = ({
  contentProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  hide,
}) => {
  const { id, city, artist, availabilityStart, availabilityEnd } = contentProps;

  const intl = useIntl();

  const formInitialValues = {
    performanceRequests: [],
  };

  const acceptBookingRequestAction = AcceptBookingRequest();

  const handleAcceptBookingRequest = useSubmitAction({
    submitAction: acceptBookingRequestAction,
    submitVariables: (values: any) => ({
      id,
      performanceRequests: values.performanceRequests,
    }),
    successMsg: intl.formatMessage(
      {
        id: 'admin.bookingRequests.bookingRequestAccept.acceptSuccessMessage',
      },
      {
        artistName: artist.title,
      }
    ),
    successMsgValuesForDisplay: {
      numEventsText: (values: any) =>
        values.performanceRequests.length == 1
          ? '1 event'
          : `${values.performanceRequests.length} events`,
    },
    failureMsg: intl.formatMessage({
      id: 'admin.bookingRequests.bookingRequestAccept.acceptFailureMessage',
    }),
    onSuccess: () => {
      hide();
    },
  });

  return (
    <ModalContentContainer data-qaid="booking-request-accept-modal">
      <DetailsFormMainContainer>
        <GenericForm
          formInitialValues={formInitialValues}
          renderFormComponent={(renderProps: any) => (
            <BookingRequestAcceptForm
              formikProps={renderProps.formikProps}
              city={city}
              artist={artist}
              availabilityStart={availabilityStart}
              availabilityEnd={availabilityEnd}
            />
          )}
          onSubmit={handleAcceptBookingRequest}
          formSchema={BookingRequestAcceptFormSchema(intl)}
          setFormSubmitAction={setFormSubmitAction}
          setDisplayConfirmation={setDisplayConfirmation}
          setIsSubmitting={setIsSubmitting}
          dataQaId="booking-request-accept-form"
        />
      </DetailsFormMainContainer>
    </ModalContentContainer>
  );
};

export default BookingRequestAccept;
