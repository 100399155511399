import React from 'react';
import { useIntl } from 'react-intl';

import { get } from 'app/shared/utils/get';
import ImageMultiEdit from 'app/shared/components/organisms/ImageMultiEdit';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

interface Props {
  contentProps?: any;
  setFormSubmitAction: (func: Function) => void;
  setDisplayConfirmation: (set: boolean) => void;
  setIsSubmitting: (set: boolean) => void;
  navigateTo: (routeData: object) => void;
}

const getRenderHeaderContent = (
  cityTitle?: string,
  neighborhoodTitle?: string,
  localStartsAt?: any,
  summaryInfoList?: any
) => {
  return () => (
    <DetailsHeader
      title={cityTitle}
      subTitle={neighborhoodTitle}
      loading={!cityTitle || !localStartsAt}
      imageStyle="calendar"
      summaryInfoList={summaryInfoList}
      detailsDate={localStartsAt}
    />
  );
};

const EventHeaderImageUploader: React.FC<Props> = ({
  contentProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  navigateTo,
}) => {
  const intl = useIntl();

  const cityTitle = get(contentProps, 'cityTitle', undefined);
  const neighborhoodTitle = get(contentProps, 'neighborhoodTitle', undefined);
  const summaryInfoList = get(contentProps, 'summaryInfoList', undefined);
  const localStartsAt = get(contentProps, 'localStartsAt', undefined);
  const status = get(contentProps, 'status', undefined);
  const type = get(contentProps, 'type', undefined);
  const eventAttendeeGuestsConfirmedCount = get(
    contentProps,
    'eventAttendeeGuestsConfirmedCount',
    undefined
  );
  const numTicketsAvailableForSale = get(
    contentProps,
    'numTicketsAvailableForSale',
    undefined
  );
  const remainingSpaces = get(contentProps, 'remainingSpaces', undefined);

  const onUploadSuccess = () => {
    navigateTo({
      routeName: 'event-details',
      routeProps: {
        defaultOpenSection: 'settings',
        id: contentProps.id,
        cityTitle,
        neighborhoodTitle,
        localStartsAt,
        status,
        type,
        eventAttendeeGuestsConfirmedCount,
        numTicketsAvailableForSale,
        remainingSpaces,
      },
    });
  };

  const ratioDescriptions = {
    '1:1': intl.formatMessage({
      id: 'admin.multiImageEdit.ratioDescriptionEvent1x1',
    }),
    '4:1': intl.formatMessage({
      id: 'admin.multiImageEdit.ratioDescriptionEvent4x1',
    }),
    '4:3': intl.formatMessage({
      id: 'admin.multiImageEdit.ratioDescriptionEvent4x3',
    }),
    '16:9': intl.formatMessage({
      id: 'admin.multiImageEdit.ratioDescriptionEvent16x9',
    }),
  };

  return (
    <>
      <ImageMultiEdit
        objectType="Event"
        objectId={contentProps.id}
        imagePurpose="header"
        ratios={['1:1', '4:1', '4:3', '16:9']}
        ratioDescriptions={ratioDescriptions}
        imageFieldDescription={intl.formatMessage({
          id: 'admin.eventHeaderImageEdit.imageFieldDescription',
        })}
        renderHeaderContent={getRenderHeaderContent(
          cityTitle,
          neighborhoodTitle,
          localStartsAt,
          summaryInfoList
        )}
        whatImagesAreForDescription="this event"
        onUploadSuccess={onUploadSuccess}
        setFormSubmitAction={setFormSubmitAction}
        setDisplayConfirmation={setDisplayConfirmation}
        setIsSubmitting={setIsSubmitting}
        dataQaidPrefix="event-image-upload-form"
      />
    </>
  );
};

export default EventHeaderImageUploader;
