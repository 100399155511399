import React from 'react';

import { Badge } from 'app/shared/components/atoms/Badge';
import { Icon } from 'app/shared/components/atoms/IconManualCSS';

interface ArrowProps {
  onClick: () => void;
  disabled?: boolean;
  'data-qaid'?: string;
}

const renderArrow = (
  disabled = false,
  onClick: any,
  icon: string,
  qaId = ''
) => (
  <Badge
    as="button"
    disabled={disabled}
    clickable
    onClick={onClick}
    data-qaid={qaId}
  >
    <Icon color={disabled ? 'blueSmoke' : undefined} name={icon} />
  </Badge>
);

export const ArrowLeft: React.FC<ArrowProps> = ({
  disabled,
  onClick,
  'data-qaid': qaId,
}) => renderArrow(disabled, onClick, 'chevronLeft', qaId);

export const ArrowRight: React.FC<ArrowProps> = ({
  disabled,
  onClick,
  'data-qaid': qaId,
}) => renderArrow(disabled, onClick, 'chevronRight', qaId);
