import React from 'react';
import { useIntl } from 'react-intl';

import { get } from 'app/shared/utils/get';
import useModal from 'app/shared/utils/useModal';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import ConfirmationModal from 'app/shared/components/molecules/ConfirmationModal';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import {
  DeleteVideo,
  UpdateVideo,
} from 'app/admin/graphql/videos/mutationHooks';
import { DetailsFormMainContainer } from 'app/admin/components/atoms/DetailContent';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

import ArtistVideoEditForm from './ArtistVideoEditForm';
import ArtistVideoEditFormSchema from './ArtistVideoEditFormSchema';

interface Props {
  contentProps?: any;
  setFormSubmitAction: (func: Function) => void;
  setDisplayConfirmation: (set: boolean) => void;
  setIsSubmitting: (set: boolean) => void;
  navigateTo: (routeData: object) => void;
}

const ArtistVideoEdit: React.FC<Props> = ({
  contentProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  navigateTo,
}) => {
  const intl = useIntl();

  const [videoDeleteModal, toggleVideoDeleteModal] = useModal();
  const formInitialValues = {
    showOnFeaturedVideos: get(
      contentProps,
      'videoData.showOnFeaturedVideos',
      undefined
    ),
    description: get(contentProps, 'videoData.description', undefined),
    performanceId: get(contentProps, 'videoData.performanceId', undefined),
    title: get(contentProps, 'videoData.title', undefined),
    link: get(contentProps, 'videoData.link', undefined),
    videoId: get(contentProps, 'videoData.id', undefined),
    performances: get(contentProps, 'performances', undefined),
  };

  const updateVideoAction = UpdateVideo();

  const handleUpdateVideo = useSubmitAction({
    submitAction: updateVideoAction,
    submitVariables: (values: any) => ({
      videoId: values.videoId,
      title: values.title,
      description: values.description,
      performanceId: values.performanceId,
      link: values.link,
      showOnFeaturedVideos: values.showOnFeaturedVideos,
    }),
    successMsg: intl.formatMessage({
      id: get(contentProps, 'videoData.id', undefined)
        ? 'admin.artistDirectory.video.form.updateSuccess'
        : 'admin.artistDirectory.video.form.addSuccess',
    }),
    failureMsg: intl.formatMessage({
      id: get(contentProps, 'videoData.id', undefined)
        ? 'admin.artistDirectory.video.form.updateFailure'
        : 'admin.artistDirectory.video.form.addFailure',
    }),
    onSuccess: () => {
      navigateTo({
        routeName: 'artist-details',
        routeProps: {
          defaultOpenSection: 'media',
          ...contentProps.artistData,
        },
      });
    },
  });

  const deleteVideoAction = DeleteVideo();

  const handleDeleteVideo = useSubmitAction({
    submitAction: deleteVideoAction,
    submitVariables: () => ({
      videoId: get(contentProps, 'videoData.id', undefined),
    }),
    successMsg: intl.formatMessage({
      id: 'admin.artistDirectory.video.form.deleteSuccess',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.artistDirectory.video.form.deleteFailure',
    }),
    onSuccess: () => {
      navigateTo({
        routeName: 'artist-details',
        routeProps: {
          defaultOpenSection: 'media',
          ...contentProps.artistData,
        },
      });
    },
  });

  return (
    <ModalContentContainer>
      <DetailsFormMainContainer>
        <DetailsHeader
          title={get(contentProps, 'artistData.title', '')}
          imageUrl={get(contentProps, 'artistData.imageUrl', '')}
          largeImageUrl={get(contentProps, 'artistData.largeImageUrl', '')}
          imageStyle="circle"
        />
        <GenericForm
          formInitialValues={formInitialValues}
          renderFormComponent={(renderProps: any) => (
            <ArtistVideoEditForm
              formikProps={renderProps.formikProps}
              toggleVideoDeleteModal={toggleVideoDeleteModal}
            />
          )}
          onSubmit={handleUpdateVideo}
          formSchema={ArtistVideoEditFormSchema(intl)}
          setFormSubmitAction={setFormSubmitAction}
          setDisplayConfirmation={setDisplayConfirmation}
          setIsSubmitting={setIsSubmitting}
          dataQaId="artist-video-edit-form"
        />
        {videoDeleteModal.isShowing && (
          <ConfirmationModal
            onCancel={() => videoDeleteModal.hide()}
            description={intl.formatMessage({
              id: 'admin.artistDirectory.video.deleteConfirmation',
            })}
            onConfirm={handleDeleteVideo}
          />
        )}
      </DetailsFormMainContainer>
    </ModalContentContainer>
  );
};

export default ArtistVideoEdit;
