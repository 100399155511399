import {
  cityOperatingModelLabels,
  sofarOperatedMarketTypeLabels,
} from 'app/admin/utils/cities';

export const cityOperatingModelOptions = {
  options: Object.keys(cityOperatingModelLabels).map((key: string) => ({
    label: cityOperatingModelLabels[key],
    value: key,
  })),
};

export const sofarOperatedMarketTypeOptions = Object.keys(
  sofarOperatedMarketTypeLabels
).map((key: string) => ({
  label: sofarOperatedMarketTypeLabels[key],
  value: key,
}));
