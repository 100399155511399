import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { useCurrentTheme } from 'app/shared/theme';
import useModal from 'app/shared/utils/useModal';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import { Spacer16 } from 'app/shared/components/atoms/Spacer';
import { H6, Overline } from 'app/shared/components/atoms/TypographyManualCSS';
import ConfirmationModal from 'app/shared/components/molecules/ConfirmationModal';
import RoutableModal from 'app/shared/components/molecules/RoutableModal';
import { ColoredCircleInfo } from 'app/admin/components/atoms/ColoredCircleInfo';
import { DetailsImage } from 'app/admin/components/molecules/DetailsImage';
import { DetailsTitle } from 'app/admin/components/molecules/DetailsTitle';

interface Props {
  title?: string;
  subTitle?: string;
  subHeader?: SubHeaderInfo[];
  imageUrl?: string;
  largeImageUrl?: string;
  imageStyle?: string;
  loading?: boolean;
  actionLinksInfo?: ActionLinksInfo;
  isNewForm?: boolean;
  summaryInfoList?: SummaryInfo[];
  displayEditIcon?: boolean;
  onEditIconClick?: VoidFunction;
  displayDeleteIcon?: boolean;
  setDisplayDeleteIcon?: (set: boolean) => void;
  onDeleteIconClick?: VoidFunction;
  largeImageBackButtonText?: string;
  detailsDate?: string;
  nowrap?: boolean;
}

export interface ActionLinksInfo {
  link1?: ActionLinkProps;
  link2?: ActionLinkProps;
}

interface ActionLinkProps {
  text: string | JSX.Element;
  active: boolean;
  onClickAction: VoidFunction;
}

export interface SubHeaderInfo {
  info?: string;
}

export interface SummaryInfo {
  backgroundColor: string;
  mainInfo: string | 0 | undefined;
  description: string;
}

const UpperContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    ${theme.media.sm`
      margin-left: 45px;
    `};
    ${theme.media.md`
      margin-left: 25px;
    `};
    ${theme.media.lg`
      margin-left: 15px;
    `};
    ${theme.media.xl`
      margin: 0;
      flex-direction: row;
    `};
  `}
`;

const LeftBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    width: 100%;
    ${theme.media.xl`
        width: 58%;
      `};
  `}
`;

const RightBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 40px;
    ${theme.media.xl`
      margin-top: 0;
      width: 42%;
    `};
  `}
`;

const SubHeaderContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    ${theme.media.xs`
      flex-direction: column;
    `};
    ${theme.media.sm`
      margin-left: 45px;
      flex-direction: column;
    `};
    ${theme.media.md`
      margin-left: 25px;
      flex-direction: row;
    `};
    ${theme.media.lg`
      margin-left: 15px;
      flex-direction: row;
    `};
    ${theme.media.xl`
      margin: 0;
      flex-direction: row;
    `};
  `}
`;

const ColorLegendContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const SubHeader = styled(H6)`
  ${({ theme }) => css`
    font-weight: normal;
    margin-bottom: 10px;
    color: ${theme.colors.darkSmoke};
    margin-right: 30px;
  `}
`;

const SubHeaderHelperText = styled(Overline)`
  padding-right: 5px;
`;

interface BackgroundColorProps {
  color: string;
}

const SubHeaderCircle = styled.div<BackgroundColorProps>`
  ${props => `
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: ${props.color || props.theme.colors.darkSmoke};
    margin-right: 5px;
  `}
`;

const renderSubHeaderInfo = (info: string) => <SubHeader>{info}</SubHeader>;

const renderSubHeaderWithCircle = (
  info1: string,
  info2: string,
  theme: any
) => (
  <ColorLegendContainer>
    <SubHeaderCircle color={theme.colors.orangeCrush} />
    <SubHeaderHelperText>{info1}</SubHeaderHelperText>
    <Spacer16 />
    <SubHeaderCircle color={theme.colors.blueMagic} />
    <SubHeaderHelperText>{info2}</SubHeaderHelperText>
  </ColorLegendContainer>
);

const StackContainer = styled.div`
  width: 100%;
`;

export const DetailsHeader: React.FC<Props> = ({
  title,
  subTitle,
  subHeader,
  imageUrl,
  largeImageUrl,
  actionLinksInfo,
  isNewForm,
  summaryInfoList,
  imageStyle,
  loading = false,
  displayEditIcon = false,
  onEditIconClick,
  displayDeleteIcon = false,
  setDisplayDeleteIcon,
  onDeleteIconClick,
  largeImageBackButtonText,
  detailsDate,
  nowrap,
}) => {
  const intl = useIntl();
  const theme = useCurrentTheme();

  const [viewLargeImageModal, toggleViewLargeImageModal] = useModal();
  const [
    deleteImageConfirmationModal,
    toggleDeleteImageConfirmationModal,
  ] = useModal();

  return (
    <>
      <UpperContainer>
        <LeftBox>
          <Grid>
            <DetailsImage
              imageUrl={imageUrl}
              imageStyle={imageStyle}
              loading={loading}
              displayEditIcon={displayEditIcon}
              onEditIconClick={onEditIconClick}
              displayDeleteIcon={displayDeleteIcon}
              toggleDeleteImageConfirmationModal={
                toggleDeleteImageConfirmationModal
              }
              toggleViewLargeImageModal={
                largeImageUrl ? toggleViewLargeImageModal : undefined
              }
              detailsDate={detailsDate}
            />
            <Col xs={5} sm={7}>
              <DetailsTitle
                nowrap={nowrap}
                title={title}
                subTitle={subTitle}
                actionLinksInfo={actionLinksInfo}
                isNewForm={isNewForm}
                loading={loading}
                detailsDate={detailsDate}
              />
            </Col>
          </Grid>
        </LeftBox>
        {summaryInfoList && !loading && (
          <RightBox>
            <Grid cols={3} gap="0px">
              {summaryInfoList.map((circle, idx) => (
                <Col xs={1} lg={1} key={idx}>
                  <ColoredCircleInfo
                    backgroundColor={circle.backgroundColor}
                    mainInfo={circle.mainInfo}
                    description={circle.description}
                  />
                </Col>
              ))}
            </Grid>
          </RightBox>
        )}
        {deleteImageConfirmationModal.isShowing && onDeleteIconClick && (
          <ConfirmationModal
            onCancel={() => deleteImageConfirmationModal.hide()}
            description={intl.formatMessage({
              id: 'imageHeader.currentImage.deletionMessage',
            })}
            onConfirm={() => {
              onDeleteIconClick();
              deleteImageConfirmationModal.hide();
              if (setDisplayDeleteIcon) {
                setDisplayDeleteIcon(false);
              }
            }}
            confirmationButtonText={intl.formatMessage({
              id: 'imageHeader.currentImage.delete',
            })}
            cancellationButtonText={intl.formatMessage({
              id: 'imageHeader.currentImage.cancel',
            })}
          />
        )}
        {viewLargeImageModal.isShowing && (
          <RoutableModal
            hide={viewLargeImageModal.hide}
            initialRouteProps={{
              largeImageUrl,
              backButtonText: largeImageBackButtonText,
            }}
            initialRouteName="large-image"
            dataQaidSuffix="edit-large-header-image"
          />
        )}
      </UpperContainer>
      {subHeader && (
        <StackContainer>
          <SubHeaderContainer>
            {subHeader &&
              subHeader[0] &&
              subHeader[0].info &&
              renderSubHeaderInfo(subHeader[0].info)}
            {subHeader &&
              subHeader[1] &&
              subHeader[1].info &&
              subHeader[2] &&
              subHeader[2].info &&
              renderSubHeaderWithCircle(
                subHeader[1].info,
                subHeader[2].info,
                theme
              )}
          </SubHeaderContainer>
        </StackContainer>
      )}
    </>
  );
};
