import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { Event } from 'app/typings/events';
import { get } from 'app/shared/utils/get';
import { objectFilteredByBooleanConditionOnKeys } from 'app/shared/utils/object';
import { dataGetter } from 'app/shared/utils/queries';
import { Spacer40 } from 'app/shared/components/atoms/Spacer';
import { Spinner } from 'app/shared/components/atoms/SpinnerManualCSS';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import {
  HasEditPermissionForEvent as hasEditPermissionForEvent,
  HasPermissionForEvent as hasPermissionForEvent,
} from 'app/admin/utils/events';
import { GetEventInfo } from 'app/admin/graphql/events/queryHooks';
import { DetailsMainContainer } from 'app/admin/components/atoms/DetailContent';
import { detailsHeaderSummaryInfoList } from 'app/admin/components/molecules/EventPlannerUtils';
import AccordionSections from 'app/admin/components/organisms/AccordionSections';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';
import { ReactComponent as PeopleMan8 } from 'icons/streamline-regular/avatars/geometric-men-people/people-man-8.svg';
import { ReactComponent as EmailActionUnread } from 'icons/streamline-regular/emails/email-actions/email-action-unread.svg';
import { ReactComponent as PartyMusicDanceWoman } from 'icons/streamline-regular/entertainment-events-hobbies/party/party-music-dance-woman.svg';
import { ReactComponent as InformationCircle } from 'icons/streamline-regular/interface-essential/alerts/information-circle.svg';
import { ReactComponent as PinLocation1 } from 'icons/streamline-regular/maps-navigation/pins/pin-location-1.svg';
import { ReactComponent as MusicGenreSettings } from 'icons/streamline-regular/music-audio/music-genres/music-genre-settings.svg';
import { ReactComponent as MultipleChat } from 'icons/streamline-regular/users/geomertic-close-up-multiple-users/multiple-chat.svg';
import { ReactComponent as MultipleUsers1 } from 'icons/streamline-regular/users/geometric-full-body-multiple-users/multiple-users-1.svg';

import SectionArtistsInfo from './SectionArtistsInfo';
import SectionBasicInfo from './SectionBasicInfo';
import SectionEmailsInfo from './SectionEmailsInfo';
import SectionPartnerInfo from './SectionPartnerInfo';
import SectionSettings from './SectionSettings';
import SectionTeam from './SectionTeam';
import SectionTicketingInfo from './SectionTicketingInfo';
import SectionVenueInfo from './SectionVenueInfo';

interface Props {
  navigateTo: (routeData: any) => void;
  contentProps: any;
  hide: VoidFunction;
  type?: string;
}

const EventDetails: React.FC<Props> = ({ contentProps, hide, navigateTo }) => {
  const {
    urgentEmailKeySent,
    cityTitle,
    neighborhoodTitle,
    localStartsAt,
    status,
    type,
    eventAttendeeGuestsConfirmedCount,
    numTicketsAvailableForSale,
    remainingSpaces,
    modalRef,
  } = contentProps;

  const intl = useIntl();

  const {
    loading: loadingBasicInfo,
    error: errorBasicInfo,
    data: dataBasicInfo,
    refetch: refetchEvent,
  } = GetEventInfo({
    id: contentProps.id,
    includeDraftEvents: true,
    fetchPolicy: 'cache-and-network',
  });

  const [headerData, setHeaderData] = useState({
    cityTitle,
    neighborhoodTitle,
    localStartsAt,
    type,
    status,
    eventAttendeeGuestsConfirmedCount,
    numTicketsAvailableForSale,
    remainingSpaces,
    isLoaded: !!cityTitle || !!localStartsAt,
  });

  const canViewEvent = hasPermissionForEvent(
    get(dataBasicInfo, 'event.eventOrganizedAs', undefined),
    get(dataBasicInfo, 'event.curatorGroup', undefined)
  );
  const canEditEvent = hasEditPermissionForEvent(
    get(dataBasicInfo, 'event.eventOrganizedAs', undefined),
    get(dataBasicInfo, 'event.curatorGroup', undefined)
  );

  useEffect(() => {
    const getDataBasicInfo = dataGetter(dataBasicInfo, 'event');
    const getNeighborhoodTitle = () => {
      if (
        getDataBasicInfo('venue.id') &&
        getDataBasicInfo('venue.neighborhood.id')
      ) {
        return getDataBasicInfo('venue.neighborhood.title');
      } else if (getDataBasicInfo('venue.id')) {
        return '';
      } else if (getDataBasicInfo('neighborhood.id')) {
        return getDataBasicInfo('neighborhood.title');
      } else {
        return intl.formatMessage({
          id: 'none',
        });
      }
    };

    if (dataBasicInfo) {
      setHeaderData({
        cityTitle: getDataBasicInfo('city.title'),
        neighborhoodTitle: getNeighborhoodTitle(),
        localStartsAt: getDataBasicInfo('localStartsAt'),
        type: getDataBasicInfo('type'),
        status: getDataBasicInfo('status'),
        eventAttendeeGuestsConfirmedCount: getDataBasicInfo(
          'eventAttendeeGuestsConfirmedCount'
        ),
        numTicketsAvailableForSale: getDataBasicInfo(
          'numTicketsAvailableForSale'
        ),
        remainingSpaces: getDataBasicInfo('remainingSpaces'),
        isLoaded: true,
      });
    }
  }, [dataBasicInfo, intl]);

  const eventTitleTags = () => {
    let tags: any = [];
    const getDataBasicInfo = dataGetter(dataBasicInfo, 'event');
    const eventStatus = getDataBasicInfo('status');
    const isBuyFlow = getDataBasicInfo('attendeeFlow') === 'buy';
    const isPurchasable = getDataBasicInfo('isPurchasable');
    const isAppliable = getDataBasicInfo('isAppliable');

    const tagsConfig = {
      applyOff: {
        tagElementColor: '#9B525F',
        name: intl.formatMessage({
          id: 'admin.eventPlanner.applyOff',
        }),
      },
      cancelled: {
        tagElementColor: '#EF4423',
        name: intl.formatMessage({
          id: 'admin.eventPlanner.cancelledStatus',
        }),
      },
      confirmOff: {
        tagElementColor: '#9B59B6',
        name: intl.formatMessage({
          id: 'admin.eventPlanner.confirmOff',
        }),
      },
      draft: {
        tagElementColor: '#3498DB',
        name: intl.formatMessage({
          id: 'shared.draft',
        }),
      },
      paused: {
        tagElementColor: '#F39C12',
        name: intl.formatMessage({
          id: 'admin.eventPlanner.ticketsPaused',
        }),
      },
    };

    if (eventStatus !== 'published') {
      tags.push(tagsConfig[eventStatus]);
    }

    if (!isPurchasable) {
      tags.push(tagsConfig[isBuyFlow ? 'paused' : 'confirmOff']);
    }

    if (!isBuyFlow && !isAppliable) {
      tags.push(tagsConfig.applyOff);
    }

    return tags;
  };

  const getPerformanceForArtistId = (artistId: number) =>
    dataBasicInfo && dataBasicInfo.event
      ? dataBasicInfo.event.performances.find(
          performance => performance.artist.id == artistId
        )
      : undefined;

  const sectionsConfig = objectFilteredByBooleanConditionOnKeys(
    {
      settings: {
        title: intl.formatMessage({
          id: 'admin.eventPlanner.settings.label',
        }),
        icon: MusicGenreSettings,
        iconColor: 'green900',
        iconCircle: true,
        sectionComponent: SectionSettings,
        dataKey: 'eventBasicInfo',
        displayEditIcon: false,
        data: dataBasicInfo,
        loading: loadingBasicInfo,
        titleTags: eventTitleTags(),
        callbacks: {
          toggleGuestlistModal: () =>
            navigateTo({
              routeName: 'guestlist',
              routeProps:
                dataBasicInfo && dataBasicInfo.event
                  ? dataBasicInfo.event
                  : undefined,
            }),
          toggleEventHeaderImageModal: () =>
            navigateTo({
              routeName: 'event-header-image-uploader',
              routeProps: {
                id: dataBasicInfo?.event?.id,
                imageUrl: dataBasicInfo?.event?.imageUrl,
                cityTitle: headerData.cityTitle,
                neighborhoodTitle: dataBasicInfo?.event?.neighborhood?.title,
                localStartsAt: dataBasicInfo?.event?.localStartsAt,
                status: dataBasicInfo?.event.status,
                type: dataBasicInfo?.event.type,
                eventAttendeeGuestsConfirmedCount:
                  dataBasicInfo?.event?.eventAttendeeGuestsConfirmedCount,
                numTicketsAvailableForSale:
                  dataBasicInfo?.event?.numTicketsAvailableForSale,
                remainingSpaces: dataBasicInfo?.event.remainingSpaces,
                summaryInfoList,
              },
            }),
        },
      },
      basicInfo: {
        title: intl.formatMessage({
          id: 'admin.eventPlanner.overview.label',
        }),
        icon: InformationCircle,
        iconColor: 'blueChristmas',
        sectionComponent: SectionBasicInfo,
        dataKey: 'eventBasicInfo',
        displayEditIcon: canEditEvent,
        data: {
          dataBasicInfo,
        },
        loading: loadingBasicInfo,
        callbacks: {
          toggleSectionEditModal: () => {
            navigateTo({
              routeName: 'event-basic-info-edit',
              routeProps:
                dataBasicInfo && dataBasicInfo.event
                  ? dataBasicInfo.event
                  : undefined,
            });
          },
        },
      },
      team: {
        title: intl.formatMessage({
          id: 'admin.eventPlanner.team.label',
        }),
        icon: MultipleUsers1,
        iconColor: 'blueChristmas',
        iconCircle: true,
        sectionComponent: SectionTeam,
        dataKey: 'eventTeam',
        displayEditIcon: false,
        data: {
          ...dataBasicInfo,
          modalRef,
          navigateTo,
        },
        loading: loadingBasicInfo,
        callbacks: {
          refetchEvent: () => {
            refetchEvent();
          },
        },
      },
      artistsInfo: {
        title: intl.formatMessage({
          id: 'shared.artists',
        }),
        icon: PartyMusicDanceWoman,
        iconColor: 'greenOnions',
        iconCircle: true,
        sectionComponent: SectionArtistsInfo,
        dataKey: 'eventArtistInfo',
        displayEditIcon: false,
        data: {
          dataBasicInfo,
          refetchEvent,
          navigateTo,
        },
        callbacks: {
          toggleArtistSetupEditModal: (artistId: number) => {
            const performance = getPerformanceForArtistId(artistId);
            navigateTo({
              routeName: 'artist-setup-edit',
              routeProps: {
                userContext: 'admin',
                action: 'updatePerformanceSetup',
                performanceId: performance && performance.id,
                artistSetup: performance && performance.artistSetup,
                city:
                  dataBasicInfo &&
                  dataBasicInfo.event.city &&
                  dataBasicInfo.event.city.title,
                localStartsAt:
                  dataBasicInfo && dataBasicInfo.event.localStartsAt,
                event: dataBasicInfo && dataBasicInfo.event,
              },
            });
          },
          navigateAfterArtistSetupSelect: (event: Event) => {
            refetchEvent();
            navigateTo({
              routeName: 'event-details',
              routeProps: {
                ...event,
                defaultOpenSection: 'artistsInfo',
              },
            });
          },
        },
      },
      ticketingInfo: {
        title: intl.formatMessage({
          id: 'admin.eventPlanner.ticketing.label',
        }),
        icon: MultipleChat,
        iconColor: 'blueChristmas',
        iconCircle: true,
        sectionComponent: SectionTicketingInfo,
        dataKey: 'eventTicketingInfo',
        displayEditIcon: canEditEvent,
        data: dataBasicInfo,
        loading: loadingBasicInfo,
        callbacks: {
          toggleSectionEditModal: () =>
            navigateTo({
              routeName: 'event-details-ticketing-edit',
              routeProps:
                dataBasicInfo && dataBasicInfo.event
                  ? dataBasicInfo.event
                  : undefined,
            }),
          toggleGuestlistModal: () =>
            navigateTo({
              routeName: 'guestlist',
              routeProps:
                dataBasicInfo && dataBasicInfo.event
                  ? dataBasicInfo.event
                  : undefined,
            }),
        },
      },
      emailsInfo: {
        title: intl.formatMessage({
          id: 'admin.shared.emails',
        }),
        icon: EmailActionUnread,
        iconColor: 'mellowYellow',
        iconCircle: true,
        sectionComponent: SectionEmailsInfo,
        dataKey: 'emailsInfo',
        displayEditIcon: false,
        data: dataBasicInfo,
        extraData: { urgentEmailKeySent },
        loading: loadingBasicInfo,
        callbacks: {
          toggleEmailSettingsModal: (emailGroupsAndEmails: any) => {
            navigateTo({
              routeName: 'event-email-settings-edit',
              routeProps: {
                event:
                  dataBasicInfo && dataBasicInfo.event
                    ? dataBasicInfo.event
                    : undefined,
                emailGroupsAndEmails,
              },
            });
          },
          toggleSendUrgentEmailModal: () => {
            navigateTo({
              routeName: 'event-send-urgent-email',
              routeProps: {
                event:
                  dataBasicInfo && dataBasicInfo.event
                    ? dataBasicInfo.event
                    : undefined,
              },
            });
          },
          togglePreviewEventEmailModal: (emailData: any) =>
            navigateTo({
              routeName: 'event-email-preview-content',
              routeProps: {
                eventId: contentProps.id,
                cityTitle: headerData.cityTitle,
                neighborhoodTitle: headerData.neighborhoodTitle,
                localStartsAt: headerData.localStartsAt,
                emailKey: emailData.emailKey,
                emailName: emailData.emailName,
              },
            }),
        },
      },
      venueInfo: {
        title: intl.formatMessage({
          id: 'shared.venue',
        }),
        icon: PinLocation1,
        iconColor: 'orangeCrush',
        iconCircle: true,
        sectionComponent: SectionVenueInfo,
        dataKey: 'eventVenue',
        displayEditIcon:
          canEditEvent && !!get(dataBasicInfo, 'event.venue.id', undefined),
        data: {
          dataBasicInfo,
        },
        loading: loadingBasicInfo,
        callbacks: {
          toggleSectionEditModal: () =>
            navigateTo({
              routeName: 'event-details-venue-edit',
              routeProps:
                dataBasicInfo && dataBasicInfo.event
                  ? dataBasicInfo.event
                  : undefined,
            }),
        },
      },
      partnerInfo: {
        title: intl.formatMessage({
          id: 'admin.eventPlanner.partnerInfo.label',
        }),
        icon: PeopleMan8,
        iconColor: 'royalBlood',
        iconCircle: true,
        sectionComponent: SectionPartnerInfo,
        dataKey: 'eventPartner',
        displayEditIcon: canEditEvent,
        data: dataBasicInfo,
        loading: loadingBasicInfo,
        callbacks: {
          toggleSectionEditModal: () =>
            navigateTo({
              routeName: 'event-details-partner-edit',
              routeProps:
                dataBasicInfo && dataBasicInfo.event
                  ? dataBasicInfo.event
                  : undefined,
            }),
          togglePartnerPromotionImageModal: () =>
            navigateTo({
              routeName: 'partner-promotion-image-uploader',
              routeProps: {
                id: dataBasicInfo?.event?.id,
                imageUrl: dataBasicInfo?.event?.imageUrl,
                cityTitle: headerData.cityTitle,
                neighborhoodTitle: dataBasicInfo?.event?.neighborhood?.title,
                localStartsAt: dataBasicInfo?.event?.localStartsAt,
                status: dataBasicInfo?.event.status,
                type: dataBasicInfo?.event.type,
                eventAttendeeGuestsConfirmedCount:
                  dataBasicInfo?.event?.eventAttendeeGuestsConfirmedCount,
                numTicketsAvailableForSale:
                  dataBasicInfo?.event?.numTicketsAvailableForSale,
                remainingSpaces: dataBasicInfo?.event.remainingSpaces,
                summaryInfoList,
              },
            }),
        },
      },
    },
    {
      ['settings']: canEditEvent,
      ['emailsInfo']: canEditEvent,
    }
  );

  if (!canViewEvent) {
    hide();
    return null;
  }

  if ((!loadingBasicInfo && !dataBasicInfo) || errorBasicInfo) {
    hide();
    return null;
  }

  const summaryInfoList = detailsHeaderSummaryInfoList(
    headerData.type,
    headerData.eventAttendeeGuestsConfirmedCount,
    headerData.remainingSpaces,
    intl
  );
  const detailsDate = headerData.localStartsAt;

  return (
    <ModalContentContainer data-qaid="event-details-main-modal">
      <DetailsMainContainer>
        <DetailsHeader
          title={headerData.cityTitle}
          subTitle={headerData.neighborhoodTitle}
          loading={!headerData.isLoaded}
          imageStyle="calendar"
          summaryInfoList={summaryInfoList}
          detailsDate={detailsDate}
        />

        {loadingBasicInfo ? (
          <>
            <Spacer40 />
            <Spinner />
          </>
        ) : (
          <AccordionSections
            sectionsConfig={sectionsConfig}
            defaultOpenSection={contentProps.defaultOpenSection}
          />
        )}
      </DetailsMainContainer>
    </ModalContentContainer>
  );
};

export default EventDetails;
