import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { User } from 'app/typings';
import { useCurrentTheme } from 'app/shared/theme';
import { dateFormatter } from 'app/shared/utils/datetime';
import { EditIcon } from 'app/shared/components/atoms/IconLibrary';
import LazyImgixImage from 'app/shared/components/atoms/LazyImgixLoad';
import { BaseLink } from 'app/shared/components/atoms/LinkManualCSS';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { StreamlineIcon } from 'app/shared/components/atoms/StreamlineIcon';
import YouTubePlayer from 'app/shared/components/organisms/Prismic/YouTubePlayer';
import { ReactComponent as ArrowLeft1 } from 'icons/streamline-regular/arrows-diagrams/arrows/arrow-left-1.svg';
import { ReactComponent as ArrowRight1 } from 'icons/streamline-regular/arrows-diagrams/arrows/arrow-right-1.svg';
import { ReactComponent as AlertCircle } from 'icons/streamline-regular/interface-essential/alerts/alert-circle.svg';

interface CarouselSource {
  url: string;
  title?: string;
  description?: string;
  createdBy?: User;
  updatedAt?: string;
  timezone?: string;
}

const SourceTitle = styled.div`
  flex-grow: 1;
`;

const InlineHtml = styled.div`
  display: inline;
  p {
    display: inline;
  }
`;

const CarouselContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;

    ${theme.media.xs`
      max-width: 450px;
    `};

    ${theme.media.sm`
      max-width: 500px;
    `};

    ${theme.media.md`
      max-width: 450px;
    `};

    ${theme.media.lg`
      max-width: 750px;
    `};
  `}
`;

const MainSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const CarouselContent = styled.div`
  max-width: 750px;
  width: 100%;
`;

const VideoLinkContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    border-radius: 8px;
    margin-bottom: 16px;
    background-color: ${theme.colors.paintItBlack};
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;

    ${theme.media.xs`
      min-height: 200px;
    `};

    ${theme.media.md`
      min-height: 200px;
    `};

    ${theme.media.lg`
      min-height: 400px;
    `};

    ${theme.media.xl`
      min-height: 400px;
    `};
  `}
`;

const LeftControl = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  padding-right: 8px;
`;

const RightControl = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  padding-left: 8px;
`;

const DotControls = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  margin: 20px 0px;
`;

const Dot = styled.div<{ active?: boolean }>`
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: ${props =>
    props.active ? props.theme.colors.darkSmoke : props.theme.colors.macyGrey};
  margin: 0px 6px;
  cursor: pointer;
`;

const StyledLink = styled(BaseLink)`
  ${({ theme }) => css`
    color: ${theme.colors.green600};
    font-size: 14px;
    font-weight: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 320px;
    display: block;

    ${theme.media.xs`
      max-width: 120px;
    `};

    ${theme.media.lg`
      max-width: 320px;
    `};

    &:hover {
      cursor: pointer;
      text-decoration: none;
    }
  `}
`;

const IconWrapper = styled.div<{ active?: boolean }>`
  cursor: ${props => (props.active ? 'pointer' : 'default')};
`;

const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;
  font-size: 14px;
  padding: 20px;
`;

const LinkWrapper = styled.div`
  text-align: left;
`;

const TextContainer = styled.div`
  margin: 0px 36px;
  padding-bottom: 10px;
  font-size: 14px;
`;

const TitleContainer = styled.div`
  display: flex;
`;

const GrayItalic = styled.div`
  ${({ theme }) => css`
    font-style: italic;
    color: ${theme.colors.blueSmoke};
  `}
`;

interface CarouselProps {
  type: string;
  sources: CarouselSource[];
  startIndex?: number;
  onEditClick?: Function;
}

interface VideoPlayerLinkProps {
  url: string;
  intl: any;
}

const validVideoUrl = (url: string) => {
  const urlRegex = /https?:\/\/(?:(?:www\.|(?!www))youtube\.com|youtu\.be)\/((.)*)?(\\?(.)*)?/gi;
  return url.match(urlRegex);
};

const fixMinifiedUrl = (url: string) => {
  return url.replace('youtu.be', 'youtube.com/embed');
};

const VideoPlayerLink: React.FC<VideoPlayerLinkProps> = ({ url, intl }) => {
  return (
    <VideoLinkContainer>
      <InnerContainer>
        <StreamlineIcon icon={AlertCircle} stroke="#fff" size={28} />
        <LinkWrapper>
          {intl.formatMessage({ id: 'carousel.clickLinkToOpen' })}:<br />
          <StyledLink href={url} openInNewTab={true}>
            {url}
          </StyledLink>
        </LinkWrapper>
      </InnerContainer>
    </VideoLinkContainer>
  );
};

const Carousel: React.FC<CarouselProps> = ({
  type,
  sources,
  startIndex,
  onEditClick,
}) => {
  const intl = useIntl();
  const theme = useCurrentTheme();
  const [index, setIndex] = useState(startIndex || 0);
  const validIndex = (newIndex: number) => {
    return newIndex >= 0 && newIndex < sources.length;
  };
  return (
    <CarouselContainer>
      <MainSection>
        <LeftControl>
          <IconWrapper
            active={validIndex(index - 1)}
            onClick={() => {
              if (validIndex(index - 1)) {
                setIndex(index - 1);
              }
            }}
          >
            <StreamlineIcon
              stroke={
                validIndex(index - 1)
                  ? theme.colors.darkSmoke
                  : theme.colors.macyGrey
              }
              icon={ArrowLeft1}
              size={28}
            />
          </IconWrapper>
        </LeftControl>
        <CarouselContent>
          {type === 'video' &&
            (validVideoUrl(sources[index]?.url) ? (
              <YouTubePlayer
                url={fixMinifiedUrl(sources[index]?.url)}
                minHeight="400"
              />
            ) : (
              <VideoPlayerLink url={sources[index]?.url} intl={intl} />
            ))}
          {type === 'image' && (
            <LazyImgixImage
              src={sources[index]?.url}
              sizes="750px"
              aspectRatio="1.5:1"
              dataQaid="venue-image"
            />
          )}
        </CarouselContent>
        <RightControl>
          <IconWrapper
            active={validIndex(index + 1)}
            onClick={() => {
              if (validIndex(index + 1)) {
                setIndex(index + 1);
              }
            }}
          >
            <StreamlineIcon
              stroke={
                validIndex(index + 1)
                  ? theme.colors.darkSmoke
                  : theme.colors.macyGrey
              }
              icon={ArrowRight1}
              size={28}
            />
          </IconWrapper>
        </RightControl>
      </MainSection>
      <DotControls>
        {[...Array(sources.length).keys()].map((key: number) => (
          <Dot key={key} onClick={() => setIndex(key)} active={key === index} />
        ))}
      </DotControls>
      <TextContainer data-qaid="text-container">
        <TitleContainer>
          {sources[index]?.title && (
            <SourceTitle>
              <strong>{sources[index]?.title}</strong>
            </SourceTitle>
          )}
          {onEditClick && (
            <EditIcon
              onClick={() => onEditClick(sources[index])}
              iconSize={12}
            />
          )}
        </TitleContainer>

        {sources[index]?.createdBy && sources[index]?.updatedAt && (
          <>
            <Spacer mt={2} />
            <GrayItalic>
              {`${sources[index]?.createdBy?.firstName} ${sources[index]?.createdBy?.lastName}`}
            </GrayItalic>
            <Spacer mt={1} />
            <GrayItalic>
              {intl.formatMessage({
                id: 'admin.crewEvent.section.venueSetupInfo.updated',
              })}
              :{' '}
              {dateFormatter(
                // @ts-ignore
                sources[index].updatedAt,
                'longMonthDateHoursAndMinutes',
                sources[index].timezone
              )}
            </GrayItalic>
          </>
        )}

        {sources[index]?.description && (
          <>
            <Spacer mt={2} />
            <InlineHtml
              dangerouslySetInnerHTML={{
                __html: sources[index].description || '',
              }}
            ></InlineHtml>
            <Spacer mt={4} />
          </>
        )}
      </TextContainer>
    </CarouselContainer>
  );
};

export default Carousel;
