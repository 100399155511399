import React, { useEffect } from 'react';
import { Formik } from 'formik';
import styled, { css } from 'styled-components';

import { refocusToFirstError } from 'app/shared/utils/form';

interface Props {
  formInitialValues: any;
  renderFormComponent: any;
  onSubmit: (values: any) => Promise<any> | void;
  formSchema?: any;
  setFormSubmitAction?: (func: Function) => void;
  setDisplayConfirmation?: (set: boolean) => void;
  setIsSubmitting?: (set: boolean) => void;
  validateOnChange?: boolean;
  dataQaId?: string;
  paddingTop?: string;
}

const MainContainer = styled.div<{ paddingTop: string | undefined }>`
  ${({ paddingTop, theme }) => css`
    ${theme.media.xs`
      padding-top: ${paddingTop || theme.ruler[8]}px;
    `};

    ${theme.media.md`
      padding-top: ${paddingTop || theme.ruler[12]}px;
    `};
  `}
`;

interface FormComponentContainerProps {
  renderFormComponent: any;
  formikProps: any;
  setFormSubmitAction?: (func: Function) => void;
  setDisplayConfirmation?: (set: boolean) => void;
  setIsSubmitting?: (set: boolean) => void;
}

const FormComponentContainer: React.FC<FormComponentContainerProps> = ({
  renderFormComponent,
  formikProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
}) => {
  useEffect(() => {
    refocusToFirstError(formikProps.errors, formikProps.isSubmitting);
  });

  // This useEffect and the functions called within it are only for forms embedded in a RoutableModal-style modal
  // (used all over admin) - but GenericForm can be used anywhere, including outside of modals of any kind
  useEffect(() => {
    setFormSubmitAction && setFormSubmitAction(() => formikProps.submitForm);
    setDisplayConfirmation && setDisplayConfirmation(formikProps.dirty);
    setIsSubmitting && setIsSubmitting(formikProps.isSubmitting);
  }, [
    formikProps.isSubmitting,
    formikProps.submitForm,
    formikProps.dirty,
    setFormSubmitAction,
    setDisplayConfirmation,
    setIsSubmitting,
  ]);

  return renderFormComponent({
    formikProps,
  });
};

const GenericForm: React.FC<Props> = ({
  formInitialValues,
  renderFormComponent,
  onSubmit,
  formSchema,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  validateOnChange = true,
  dataQaId,
  paddingTop,
}) => {
  return (
    <>
      <MainContainer data-qaid={dataQaId} paddingTop={paddingTop}>
        <Formik
          data-qaid="modal-form-formik"
          initialValues={{
            ...formInitialValues,
          }}
          validationSchema={formSchema}
          onSubmit={onSubmit}
          validateOnBlur={false}
          validateOnChange={validateOnChange}
        >
          {formikProps => {
            return (
              <FormComponentContainer
                renderFormComponent={renderFormComponent}
                formikProps={formikProps}
                setFormSubmitAction={setFormSubmitAction}
                setDisplayConfirmation={setDisplayConfirmation}
                setIsSubmitting={setIsSubmitting}
              />
            );
          }}
        </Formik>
      </MainContainer>
    </>
  );
};

export default GenericForm;
