import React, { useContext, useEffect } from 'react';
import loadable from '@loadable/component';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { getIdsSubmitVariable } from 'app/shared/utils/form';
import { get } from 'app/shared/utils/get';
import { removeEntriesByKey } from 'app/shared/utils/object';
import useModal from 'app/shared/utils/useModal';
import usePermission from 'app/shared/utils/usePermission';
import { GetCitiesLite } from 'app/shared/graphql/cities/queryHooks';
import { ListPage, ListPageContext } from 'app/shared/context/ListPage';
import { LoadingBlocks } from 'app/shared/components/atoms/LoadingBlocks';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { bookingStatusFilters } from 'app/admin/utils/artists';
import { CityInfoForLimitingByCity } from 'app/admin/utils/cityPermissions';
import {
  filterInfoListFilteredByCheckOnFilterName,
  getArtistEligibilityOptions,
  getBookingSettingsOptions,
  getCityOptions,
  getFilterNamesAndOptionsForTitleMapping,
  getTagOptions,
} from 'app/admin/utils/optionLists';
import { GetArtists } from 'app/admin/graphql/artists/queryHooks';
import { GetArtistEligibilities } from 'app/admin/graphql/artists/queryHooks';
import { GetTags } from 'app/admin/graphql/tags/queryHooks';
import { CityTitles as CityTitlesLimitedBy } from 'app/admin/components/atoms/CityTitles';
import { NoResults } from 'app/admin/components/molecules/ListingNoResults';
import { CardType } from 'app/admin/components/organisms/CardGrid';
import ListingHeader from 'app/admin/components/organisms/ListingHeader';
import Layout from 'app/admin/layouts/ListPage';

import ArtistsActions from './ArtistsActions';
import { sortOptions, staticOptions } from './options';
import Analytics from './tracking';

const GlobalError = loadable(
  () => import('app/shared/components/pages/Status'),
  {
    resolveComponent: components => components.GlobalError,
  }
);
const ListPageTemplate = loadable(() =>
  import('app/admin/components/templates/ListPage')
);
const ListingControls = loadable(() =>
  import('app/admin/components/organisms/ListingControls')
);
const ListingFilter = loadable(() =>
  import('app/admin/components/organisms/ListingFilter')
);
const ListingFooter = loadable(() =>
  import('app/admin/components/molecules/ListingFooter')
);
const RoutableModal = loadable(() =>
  import('app/shared/components/molecules/RoutableModal')
);
const ListingNoResults = loadable(() =>
  import('app/admin/components/molecules/ListingNoResults')
);
const CardGrid = loadable(() =>
  import('app/admin/components/organisms/CardGrid')
);
const ArtistCard = loadable(() =>
  import('app/admin/components/organisms/ArtistCard')
);
const DottedLine = loadable(() =>
  import('app/shared/components/atoms/DottedLine')
);

const pageStateConfig = {
  filterNames: [
    'city',
    'closest_sofar_city',
    'eligibility',
    'event_scope',
    'tag',
    'booking_settings',
  ],
  defaultSort: { by: 'past_events_count', direction: 'desc' },
  textSearchParamName: 'artist_search',
  idParamName: 'artist_id',
};

const ListingFilterRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;

const ListingFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
`;

const ArtistsActionsContainer = styled.div`
  padding-top: 0px;
  margin-top: -9px;

  @media (max-width: 767px) {
    position: absolute;
    right: 25px;
    top: 375px;
  }
`;

const AdminArtists: React.FC = () => {
  const intl = useIntl();
  const pageState = useContext(ListPageContext);

  const hasCreateAllEventTypesPermission = usePermission(
    'event.eventAllTypes.create'
  );
  const hasAccessArtistsPermission = usePermission('artist.list.access');
  const hasBasicAccessArtistsPermission = usePermission(
    'artist.list.accessBasic'
  );
  const hasViewOffersPermission = usePermission('artistOffer.list.view');
  const hasViewArtistByCityPermission = usePermission('artist.list.viewByCity');

  const PER_PAGE = 18;

  const {
    loading: loadingCities,
    error: errorCities,
    data: dataCities,
  } = GetCitiesLite();

  const { loading: loadingTags, error: errorTags, data: dataTags } = GetTags({
    tagContext: 'Artist',
  });

  const {
    loading: loadingArtistEligibilities,
    error: errorArtistEligibilities,
    data: dataArtistEligibilities,
  } = GetArtistEligibilities();

  const { cityIdsToLimitBy, cityTitlesToLimitBy } = CityInfoForLimitingByCity(
    'artist.list.viewByCity',
    dataCities
  );

  const cityOptions = getCityOptions(dataCities);
  const tagOptions = getTagOptions(dataTags);
  const artistEligibilityOptions = getArtistEligibilityOptions(
    dataArtistEligibilities
  );
  const bookingSettingsGroupBookingStatus = intl.formatMessage({
    id: 'admin.artistDirectory.filter.title.bookingStatus',
  });
  const bookingSettingsGroupArtistsBookingPreferences = intl.formatMessage({
    id: 'admin.artistDirectory.filter.title.artistsBookingPreferences',
  });
  const bookingSettingsGroupBookingInvitations = intl.formatMessage({
    id: 'admin.artistDirectory.filter.title.bookingInvitations',
  });
  let bookingSettingsOptionsData = {
    [bookingSettingsGroupBookingStatus]: bookingStatusFilters,
    [bookingSettingsGroupArtistsBookingPreferences]: {
      available_last_minute_without_legacy: intl.formatMessage({
        id: 'admin.artistDirectory.filter.option.availableLastMinuteNoLegacy',
      }),
      general_availability_without_legacy: intl.formatMessage({
        id: 'admin.artistDirectory.filter.option.generalAvailabilityNoLegacy',
      }),
      specific_availability: intl.formatMessage({
        id: 'admin.artistDirectory.filter.option.specificAvailability',
      }),
      updated_in_last_3_months: intl.formatMessage({
        id: 'admin.artistDirectory.filter.option.updatedInLast3Months',
      }),
      updated_in_last_6_months: intl.formatMessage({
        id: 'admin.artistDirectory.filter.option.updatedInLast6Months',
      }),
      updated_in_last_year: intl.formatMessage({
        id: 'admin.artistDirectory.filter.option.updatedInLastYear',
      }),
    },
    [bookingSettingsGroupBookingInvitations]: {
      has_performance_offers: intl.formatMessage({
        id: 'admin.artistDirectory.filter.option.hasBookingInvitations',
      }),
      no_performance_offers: intl.formatMessage({
        id: 'admin.artistDirectory.filter.option.noBookingInvitations',
      }),
    },
  };
  if (hasViewArtistByCityPermission) {
    bookingSettingsOptionsData = removeEntriesByKey(
      bookingSettingsOptionsData,
      [bookingSettingsGroupBookingStatus]
    );
  }
  const bookingSettingsOptions = getBookingSettingsOptions(
    bookingSettingsOptionsData
  );

  const filterDropdownOptionsInfoList = filterInfoListFilteredByCheckOnFilterName(
    [
      {
        filterName: 'eligibility',
        dropdownParams: {
          title: intl.formatMessage({
            id: 'admin.artistDirectory.filter.title.eligibility',
          }),
          options: artistEligibilityOptions,
        },
      },
      {
        filterName: 'city',
        dropdownParams: {
          searchBar: true,
          title: intl.formatMessage({
            id: 'admin.artistDirectory.filter.title.city',
          }),
          groupBy: 'country',
          options: cityOptions,
        },
      },
      {
        filterName: 'closest_sofar_city',
        dropdownParams: {
          searchBar: true,
          title: intl.formatMessage({
            id: 'admin.artistDirectory.filter.title.closestSofarCity',
          }),
          groupBy: 'country',
          options: cityOptions,
        },
      },
      {
        filterName: 'event_scope',
        dropdownParams: {
          title: intl.formatMessage({
            id: 'shared.concerts',
          }),
          options: staticOptions.eventScope,
        },
      },
      {
        filterName: 'tag',
        dropdownParams: {
          searchBar: true,
          title: intl.formatMessage({
            id: 'shared.tags',
          }),
          groupBy: 'tagGroupName',
          groupNames: get(dataTags, 'tags.tagGroupNames', []),
          options: tagOptions,
        },
      },
      {
        filterName: 'booking_settings',
        dropdownParams: {
          title: intl.formatMessage({
            id: 'admin.artistDirectory.filter.title.bookingSettings',
          }),
          groupBy: 'bookingSettingsGroup',
          groupNames: Object.keys(bookingSettingsOptionsData),
          options: bookingSettingsOptions,
        },
      },
    ],
    {
      eligibility:
        hasAccessArtistsPermission && hasCreateAllEventTypesPermission,
      city: hasAccessArtistsPermission,
      closestSofarCity: hasAccessArtistsPermission,
      event_scope: hasAccessArtistsPermission || hasViewArtistByCityPermission,
      tag: hasAccessArtistsPermission || hasViewArtistByCityPermission,
      booking_settings:
        hasAccessArtistsPermission || hasViewArtistByCityPermission,
    }
  );

  const [addArtistModal, toggleAddArtistModal] = useModal();
  const [createOfferModal, toggleCreateOfferModal] = useModal();

  const isCurator = !!cityIdsToLimitBy;

  const { loading, error, data } = GetArtists({
    city: isCurator ? undefined : pageState.filterListVariable('city'),
    cityIds:
      isCurator && pageState.textSearchState
        ? undefined
        : getIdsSubmitVariable(cityIdsToLimitBy),
    closestSofarCity: pageState.filterListVariable('closest_sofar_city'),
    eligibility: !hasCreateAllEventTypesPermission
      ? 'discovery'
      : pageState.filterListVariable('eligibility'),
    eventScope: pageState.filterListVariable('event_scope'),
    tag: pageState.filterListVariable('tag'),
    bookingSettings: pageState.filterListVariable('booking_settings'),
    // If user doesn't have hasAccessArtistsPermission AND isn't doing a text search
    // default artistSearch to arbitrarily random long string which guarantees no
    // artist exists for that search, which ensures no artists are returned from backend,
    // which ensures page displays no artists - long-term, get useLazyQuery working
    // correctly here instead
    artistSearch: pageState.textSearchState || undefined,
    orderBy: pageState.sortState.by,
    orderDirection: pageState.sortState.direction,
    page: pageState.page,
    perPage: PER_PAGE,
  });

  useEffect(() => {
    const preloadedComponents = [
      ArtistCard,
      DottedLine,
      CardGrid,
      ListPageTemplate,
      ListingControls,
      ListingFilter,
    ];
    preloadedComponents.map(c => c.preload());

    pageState.updateDetailsModal();
  }, []);

  useEffect(() => {
    pageState.updateScrollPositionOnPage();
  }, [pageState.detailsModal.isShowing]);

  useEffect(() => {
    pageState.setupFilterLabelTitleMapping(
      getFilterNamesAndOptionsForTitleMapping(filterDropdownOptionsInfoList)
    );
  }, [dataCities, dataTags, dataArtistEligibilities]);

  useEffect(() => {
    pageState.updatePageUrl();
  }, [
    pageState.page,
    pageState.sortState,
    pageState.filterState,
    pageState.textSearchState,
    pageState.detailData,
    pageState.detailsModal.isShowing,
  ]);

  useEffect(() => {
    Analytics.pushDataLayer();
  }, [pageState.filterState, pageState.textSearchState]);

  if (
    (!loading && !data) ||
    error ||
    (!loadingCities && !dataCities) ||
    errorCities ||
    (!loadingTags && !dataTags) ||
    errorTags ||
    (!loadingArtistEligibilities && !dataArtistEligibilities) ||
    errorArtistEligibilities
  ) {
    return <GlobalError />;
  }

  const modalsContent = () => (
    <>
      {pageState.detailsModal.isShowing && (
        <RoutableModal
          hide={pageState.detailsModal.hide}
          initialRouteProps={pageState.detailData}
          initialRouteName="artist-details"
          dataQaidSuffix="admin-edit-artist-details"
        />
      )}
      {addArtistModal.isShowing && (
        <RoutableModal
          hide={addArtistModal.hide}
          initialRouteProps={{}}
          initialRouteName="artist-create"
          dataQaidSuffix="admin-create-artist"
        />
      )}
      {createOfferModal.isShowing && (
        <RoutableModal
          hide={createOfferModal.hide}
          initialRouteProps={{}}
          initialRouteName="offer-create"
          dataQaidSuffix="admin-create-offer"
        />
      )}
    </>
  );

  return (
    <Layout scrollDisabled={pageState.detailsModal.isShowing}>
      <ListPageTemplate
        pageTitle={intl.formatMessage({
          id: 'admin.artistDirectory.pageTitle',
        })}
        modalsContent={modalsContent}
      >
        <ListingHeader
          pageTitle={intl.formatMessage({
            id: 'admin.artistDirectory.pageTitle',
          })}
          addEntityText={intl.formatMessage({
            id: 'admin.artistDirectory.addArtist',
          })}
          onClickAddEntity={toggleAddArtistModal}
          isAddEntityActive={hasBasicAccessArtistsPermission}
          searchPlaceholder={intl.formatMessage({
            id: 'admin.artistDirectory.textSearch.placeholder',
          })}
          searchValue={pageState.textSearchState}
          onSearch={pageState.handleTextSearch}
          dataQaidPrefix="artist"
        />
        <CityTitlesLimitedBy cityTitles={cityTitlesToLimitBy} />
        <div>
          <ListingControls
            sortOptions={sortOptions}
            orderBy={pageState.sortState.by}
            orderDirection={pageState.sortState.direction}
            totalRecords={data && data.artists.metadata.totalRecords}
            loading={loading}
            onSort={pageState.handleSortChange}
            onReset={pageState.handleResetFilters}
            dataQaidPrefix="artist"
          />
          {filterDropdownOptionsInfoList.length > 0 && (
            <ListingFilterRowContainer>
              <ListingFilterContainer>
                <ListingFilter
                  filterTitle={intl.formatMessage({
                    id: 'admin.artistDirectory.filterTitle',
                  })}
                  textSearchString={pageState.textSearchState}
                  handleTextSearchLabelClose={
                    pageState.handleTextSearchLabelClose
                  }
                  labelTitleMapping={pageState.filterLabelTitleMapping}
                  dropdownOptionsInfoList={filterDropdownOptionsInfoList}
                  filterState={pageState.filterState}
                  handleRemoveFilter={pageState.handleRemoveFilter}
                  handleFilterChange={pageState.handleFilterChange}
                />
              </ListingFilterContainer>
              {hasViewOffersPermission && (
                <ArtistsActionsContainer>
                  <ArtistsActions
                    toggleCreateOfferModal={toggleCreateOfferModal}
                  />
                </ArtistsActionsContainer>
              )}
            </ListingFilterRowContainer>
          )}
        </div>
        <div>
          <CardGrid
            objectData={get(data, 'artists.artists', [])}
            renderCardComponent={(artist: CardType, i: number) => (
              // @ts-ignore
              <ArtistCard
                index={i}
                onShowDetails={pageState.toggleDetailsModalAndSetDetailData}
                {...artist}
              />
            )}
            dataQaid="admin-artists-list"
            loading={loading}
            loadingComponent={
              <LoadingBlocks.Rectangle width="100%" height="420px" />
            }
            hideDividerOnSize="xs"
          />
          {hasAccessArtistsPermission && <DottedLine />}
          <Spacer mb={2} />
        </div>

        {hasAccessArtistsPermission || pageState.textSearchState ? (
          <ListingNoResults
            entityName="Artist"
            numResults={get(data, 'artists.artists.length', undefined)}
            loading={loading}
          />
        ) : (
          <>
            {!loading && get(data, 'artists.artists.length', undefined) == 0 && (
              <Spacer mt={12} mb={12}>
                <NoResults>
                  {intl.formatMessage({
                    id: 'admin.artistDirectory.textSearchNoResultsMessage',
                  })}
                </NoResults>
              </Spacer>
            )}
          </>
        )}

        <ListingFooter
          numTotalRecords={get(
            data,
            'artists.metadata.totalRecords',
            undefined
          )}
          perPage={PER_PAGE}
          currentPage={pageState.page}
          onPageChange={pageState.handlePageChange}
          loading={loading}
          dataQaidPrefix="artists"
        />
      </ListPageTemplate>
    </Layout>
  );
};

const AdminArtistsWrapper: React.FC = () => (
  <ListPage config={pageStateConfig} analytics={Analytics}>
    <AdminArtists />
  </ListPage>
);

export default AdminArtistsWrapper;
