import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { fromEntries } from 'app/shared/utils/object';
import { LinkButton } from 'app/shared/components/atoms/Button';
import { Checkbox } from 'app/shared/components/atoms/CheckboxManualCSS';
import Input from 'app/shared/components/molecules/Typeahead/Input';

interface Option {
  title: string;
  value: string;
}

interface Props {
  options: Option[];
  selectedValues: string[];
  onApply(values: string[]): void;
  placeholder?: string;
  groupBy?: string;
  groupById?: string;
  groupNames?: string[];
  disabled?: boolean;
  submitLabel?: string;
  isActive?: boolean;
}

const StyledLinkButton = styled(LinkButton)`
  padding: 0;
  font-weight: 400;
  &:focus {
    background: none;
  }
`;

const DropdownWrapper = styled.div`
  width: 100%;
  display: inline-block;
  z-index: 10;
`;

const Content = styled.div`
  width: 402px;
  padding: 0px;
  background-color: white;
  box-shadow: 0 14px 28px 0px rgba(0, 0, 0, 0.25);
  z-index: 10;
  overflow: hidden;
  position: absolute;
  left: 112px;
`;

const OptionsContainer = styled.div`
  ${({ theme }) => css`
    max-height: 150px;
    overflow: scroll;
    position: relative;
    padding: ${theme.ruler[1]}px ${theme.ruler[4]}px;
  `}
`;

const OptionContainer = styled.div`
  ${({ theme }) => css`
    padding: ${theme.ruler[2]}px 0px;
  `}
`;

const OptionsFooter = styled.div`
  width: 100%;
  border-top: 1px solid #f5f5f5;
  text-align: center;
`;

const InputWrapper = styled.div`
  margin: 0 30px;
`;

const MultiSelectInput: React.FC<Props> = ({
  placeholder,
  options,
  selectedValues,
  onApply,
  submitLabel,
  isActive = false,
  disabled = false,
}) => {
  const intl = useIntl();
  const ref = React.useRef<any>();
  const [active, setActive] = useState(isActive);
  const [selectAll, setSelectAll] = useState(false);
  const [optionState, setOptionState] = useState(
    fromEntries(selectedValues.map((val: string) => [val, true]))
  );
  const optionsMap = fromEntries(
    options.map((opt: Option) => [opt.value, opt.title])
  );

  const resetOptionState = useCallback(() => {
    setOptionState(
      fromEntries(selectedValues.map((val: string) => [val, true]))
    );
  }, [selectedValues]);

  const renderOptions = (options: Option[]) => {
    return [
      ...[
        {
          value: 'select-all',
          title: intl.formatMessage({
            id: 'admin.updateOptionsModal.selectAll',
          }),
        },
      ],
      ...options,
    ].map((opt: Option, index: number) => (
      <OptionContainer key={index} data-qaid="option-container">
        <Checkbox
          id={`option-${index}`}
          name={`option-${index}`}
          data-qaid={`option-${index}`}
          onChange={(event: any) => {
            if (opt.value === 'select-all') {
              setSelectAll(event.target.checked);
              setOptionState(
                fromEntries(
                  options.map((opt: Option) => [
                    opt.value,
                    event.target.checked,
                  ])
                )
              );
            } else {
              if (!event.target.checked) {
                setSelectAll(false);
              }
              setOptionState({
                ...optionState,
                [opt.value]: event.target.checked,
              });
            }
          }}
          checked={
            selectAll ||
            (optionState[opt.value] !== undefined
              ? optionState[opt.value]
              : selectedValues.includes(opt.value))
          }
        >
          {opt.title}
        </Checkbox>
      </OptionContainer>
    ));
  };

  const getSubmitLabel = () => {
    if (submitLabel) {
      return submitLabel;
    }
    return intl.formatMessage({
      id: 'admin.dropdownOption.submit.applyFilter',
    });
  };

  useEffect(() => {
    const handleDocumentClick = (event: Event) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        resetOptionState();
        setActive(false);
      }
    };
    document.addEventListener('mousedown', handleDocumentClick);
  }, [resetOptionState]);

  useEffect(resetOptionState, [selectedValues]);

  useEffect(() => {
    if (!isActive) {
      setSelectAll(false);
      resetOptionState();
    }
  }, [isActive, resetOptionState]);

  if (options.length === 0) {
    return null;
  }

  return (
    <DropdownWrapper data-qaid="dropdown-wrapper">
      <InputWrapper
        onClick={() => {
          setActive(!active);
        }}
      >
        <Input
          onSearchChange={() => {}}
          placeholder={placeholder}
          value={
            !disabled && selectedValues && selectedValues.length
              ? selectedValues.map((val: string) => optionsMap[val]).join(', ')
              : undefined
          }
          readonly={true}
        />
      </InputWrapper>
      {active && (
        <Content data-qaid="dropdown-container">
          <OptionsContainer data-qaid="options-container">
            {renderOptions(options)}
          </OptionsContainer>
          <OptionsFooter>
            <StyledLinkButton
              onClick={() => {
                resetOptionState();
                setActive(false);
                onApply(
                  Object.entries(optionState)
                    .filter((entry: any) => entry[1])
                    .map((entry: any) => entry[0])
                );
              }}
              data-qaid="options-submit-button"
            >
              {getSubmitLabel()}
            </StyledLinkButton>
          </OptionsFooter>
        </Content>
      )}
    </DropdownWrapper>
  );
};

export default MultiSelectInput;
