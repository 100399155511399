import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { City } from 'app/typings/cities';
import { Tag } from 'app/typings/tags';
import { Venue } from 'app/typings/venues';
import { dateFormatter } from 'app/shared/utils/datetime';
import { get } from 'app/shared/utils/get';
import { venueSocialUrls } from 'app/shared/utils/socialLinks';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import { BaseLink } from 'app/shared/components/atoms/LinkManualCSS';
import TruncatedByCharText from 'app/shared/components/atoms/TruncatedByCharText';
import { venueStatusLabels } from 'app/admin/utils/venues';
import {
  DetailHeading as Heading,
  DetailSmallHeading as SmallHeading,
  DetailSmallValue as SmallValue,
  DetailValueContainer as ValueContainer,
} from 'app/admin/components/atoms/DetailContent';
import ExpandableText from 'app/admin/components/atoms/ExpandableText';
import { None } from 'app/admin/components/atoms/None';
import HeadingWithHelpIcon from 'app/admin/components/molecules/DetailHeadingWithHelpIcon';
import { UserLink } from 'app/admin/components/molecules/EntityLink';
import { CityLink } from 'app/admin/components/molecules/EntityLink';
import { OtherContactInfos } from 'app/admin/components/molecules/OtherContactInfos';
import { PrimaryContactInfo } from 'app/admin/components/molecules/PrimaryContactInfo';
import { PropertyList } from 'app/admin/components/molecules/PropertyList';
import SocialLinkList from 'app/admin/components/molecules/SocialLinkList';
import { TagElementList } from 'app/admin/components/molecules/TagElementList';

interface Props {
  sectionData: Venue;
  callbacks: { [name: string]: Function };
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const TagElementListsContainer = styled.div`
  width: 170px;
`;

const Link = styled(BaseLink)`
  ${({ theme }) => css`
    color: ${theme.colors.green600};
  `}
`;

const VenueCategoryContainer = styled.div`
  display: block;
`;

const ActionLink = styled.div`
  ${({ theme }) => css`
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 15px;
    color: ${theme.colors.green600};
    cursor: pointer;
  `}
`;

const SectionBasicInfo: React.FC<Props> = ({ sectionData, callbacks }) => {
  const intl = useIntl();

  return (
    <MainContainer data-qaid="section-about">
      <Grid cols={4}>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={1}>
          <Heading>
            {intl.formatMessage({
              id: 'admin.venueBasicInfo.venueName',
            })}
          </Heading>
          <ValueContainer>
            {sectionData.venueName ? sectionData.venueName : <None />}
          </ValueContainer>
          <HeadingWithHelpIcon
            title={intl.formatMessage({
              id: 'admin.shared.venueStatus',
            })}
            bodyId="admin.venueBasicInfo.hostingStatusActive"
            bodyId2="admin.venueBasicInfo.hostingStatusHiatus"
            bodyId3="admin.venueBasicInfo.hostingStatusInactive"
          >
            {intl.formatMessage({
              id: 'shared.status',
            })}
          </HeadingWithHelpIcon>
          <ValueContainer>
            {sectionData.hostingStatus ? (
              sectionData.hostingStatus.name
            ) : (
              <None />
            )}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.venueBasicInfo.venueCity',
            })}
          </Heading>
          <ValueContainer>
            {sectionData.city ? (
              <CityLink
                city={sectionData.city}
                renderCityName={(city: City) =>
                  `${city.title}, ${city.country.title}`
                }
              />
            ) : (
              <None />
            )}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'shared.neighborhood',
            })}
          </Heading>
          <ValueContainer>
            {get(sectionData, 'neighborhood.title', undefined) ? (
              get(sectionData, 'neighborhood.title', '')
            ) : (
              <None />
            )}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'shared.address',
            })}
          </Heading>
          <ValueContainer>
            {sectionData.address ? sectionData.address : <None />}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.venueBasicInfo.address2',
            })}
          </Heading>
          <ValueContainer>
            {sectionData.address2 ? sectionData.address2 : <None />}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.venueBasicInfo.closestStation',
            })}
          </Heading>
          <ValueContainer>
            {sectionData.closestStation ? sectionData.closestStation : <None />}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.venueBasicInfo.streetViewUrl',
            })}
          </Heading>
          <ValueContainer>
            {sectionData.streetViewUrl ? (
              <Link href={sectionData.streetViewUrl} openInNewTab={true}>
                <TruncatedByCharText
                  text={sectionData.streetViewUrl}
                  truncateLength={35}
                />
              </Link>
            ) : (
              <None />
            )}
          </ValueContainer>
        </Col>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={2}>
          <Heading>
            {intl.formatMessage({
              id: 'shared.capacity',
            })}
          </Heading>
          <ValueContainer>
            {sectionData.capacity ? sectionData.capacity : <None />}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.shared.ticketsAvailable',
            })}
          </Heading>
          <ValueContainer>
            {sectionData.numTicketsAvailableForSale ? (
              sectionData.numTicketsAvailableForSale
            ) : (
              <None />
            )}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.venueBasicInfo.guestTickets',
            })}
          </Heading>
          <ValueContainer>{sectionData.numGuestTickets}</ValueContainer>
          <HeadingWithHelpIcon
            title={intl.formatMessage({
              id: 'admin.venueBasicInfo.venueFee',
            })}
            bodyId="admin.venueBasicInfo.venueFeeHelpText"
          >
            {intl.formatMessage({
              id: 'admin.venueBasicInfo.venueFee',
            })}
          </HeadingWithHelpIcon>
          <ValueContainer>
            {sectionData.venueFeePresent ? (
              sectionData.venueFeeDescription
            ) : (
              <None />
            )}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.shared.characteristics',
            })}
          </Heading>
          <ValueContainer>
            <PropertyList properties={sectionData.properties} />
          </ValueContainer>
          <>
            <Heading>
              {intl.formatMessage({
                id: 'admin.venueBasicInfo.internalParkingNotes',
              })}
            </Heading>
            <ValueContainer>
              {sectionData.parkingNotes ? (
                <ExpandableText
                  allowHTML
                  text={sectionData.parkingNotes}
                  truncateLength={50}
                />
              ) : (
                <None />
              )}
            </ValueContainer>
          </>
          <Heading>
            {intl.formatMessage({
              id: 'admin.shared.venueType',
            })}
          </Heading>
          <ValueContainer>
            {sectionData.venueCategories &&
            sectionData.venueCategories.length ? (
              sectionData.venueCategories.map((venueCategory: Tag, key) => (
                <VenueCategoryContainer key={key}>
                  {venueCategory.name}
                </VenueCategoryContainer>
              ))
            ) : (
              <None />
            )}
          </ValueContainer>
        </Col>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={3}>
          <TagElementListsContainer>
            <Heading>
              {sectionData.tags && sectionData.tags.length > 0
                ? `Tags: ${sectionData.tags.length}`
                : 'Tags'}
            </Heading>
            <ValueContainer>
              <TagElementList
                tagElements={
                  // @ts-ignore
                  sectionData.tags
                }
                tagElementColor="#34495E"
                displayShowAllLink={true}
              />
            </ValueContainer>
          </TagElementListsContainer>
          <Heading>
            {intl.formatMessage({
              id: 'shared.primaryContact',
            })}
          </Heading>
          <ValueContainer>
            <PrimaryContactInfo contactInfos={sectionData.contactInfos} />
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.venueBasicInfo.otherContacts',
            })}
          </Heading>
          <ValueContainer>
            <OtherContactInfos contactInfos={sectionData.contactInfos} />
          </ValueContainer>
        </Col>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={4}>
          <Heading>
            {intl.formatMessage({
              id: 'admin.venueBasicInfo.termsAndConditions',
            })}
          </Heading>
          <ValueContainer>
            {sectionData.city &&
              !sectionData.city.isAutomatedEmailEnabled &&
              !sectionData.termsAndConditionsAcceptedBy && (
                <ActionLink
                  onClick={() =>
                    callbacks.toggleResendConfirmEmailConfirmationModal()
                  }
                  data-qaid="resend-confirm-email-link"
                >
                  {intl.formatMessage({
                    id: 'admin.venueBasicInfo.resendConfirmDetailsEmail',
                  })}
                </ActionLink>
              )}
            <SmallHeading>
              {intl.formatMessage({
                id: 'admin.venueBasicInfo.tcStatus',
              })}
            </SmallHeading>
            <SmallValue>
              {
                // @ts-ignore
                venueStatusLabels[sectionData.status]
              }
            </SmallValue>
            <SmallHeading>
              {intl.formatMessage({
                id: 'admin.venueBasicInfo.dateAccepted',
              })}
            </SmallHeading>
            <SmallValue>
              {sectionData.termsAndConditionsAcceptedAt
                ? dateFormatter(
                    sectionData.termsAndConditionsAcceptedAt.replace(/-/g, '/'),
                    'longMonthDateAndYear'
                  )
                : intl.formatMessage({
                    id: 'admin.notApplicable',
                  })}
            </SmallValue>
            <SmallHeading>
              {intl.formatMessage({
                id: 'admin.venueBasicInfo.acceptedBy',
              })}
            </SmallHeading>
            <SmallValue>
              {sectionData.termsAndConditionsAcceptedBy ? (
                <UserLink
                  user={sectionData.termsAndConditionsAcceptedBy}
                  truncateLength={30}
                />
              ) : (
                intl.formatMessage({
                  id: 'admin.notApplicable',
                })
              )}
            </SmallValue>
            {sectionData.termsAndConditionsAcceptedBy && (
              <ActionLink
                onClick={() => callbacks.toggleUnlinkUserConfirmationModal()}
                data-qaid="unlink-user-link"
              >
                {intl.formatMessage({
                  id: 'admin.venueBasicInfo.unlinkUser',
                })}
              </ActionLink>
            )}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.shared.forms',
            })}
          </Heading>
          <ValueContainer>
            <None />
          </ValueContainer>
          <SocialLinkList
            socialLinks={sectionData.socialUrls}
            socialLinksToDisplay={venueSocialUrls}
          />
        </Col>
      </Grid>
    </MainContainer>
  );
};

export default SectionBasicInfo;
