import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { DeleteIcon } from 'app/shared/components/atoms/IconLibrary';
import { Image } from 'app/shared/components/atoms/ImageManualCSS';
import { BaseLink } from 'app/shared/components/atoms/LinkManualCSS';
import { Spacer4 } from 'app/shared/components/atoms/Spacer';
import { ToolTip, ToolTipContainer } from 'app/shared/components/atoms/ToolTip';
import TruncatedByCharText from 'app/shared/components/atoms/TruncatedByCharText';
import { Overline } from 'app/shared/components/atoms/TypographyManualCSS';

interface Props {
  imageUrl?: string;
  text?: string;
  description?: string;
  subtext?: string | React.ReactNode;
  textTruncateLength?: number;
  subtextTruncateLength?: number;
  displayAsEmpty?: boolean;
  titleText?: string;
  textSize?: string;
  linkUrl?: string;
  onRemove?: () => void;
  alignItems?: string;
  'data-qaid'?: string;
  hasCheckbox?: boolean;
}

const MainContainer = styled.div<{ alignItems: string; hasCheckbox: boolean }>`
  ${({ alignItems, hasCheckbox }) => css`
    display: flex;
    flex-direction: row;
    align-items: ${alignItems};
    height: auto;
    min-height: 40px;

    ${hasCheckbox &&
      css`
        align-items: start;
      `}
  `}
`;

const ThumbnailWrapper = styled.div`
  width: 40px;
  height: 40px;
  margin-right: 12px;
  display: flex;
  flex: 0 1 40px;
`;

const Thumbnail = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background: rgba(219, 219, 219, 0.5);
  overflow: hidden;
`;

const TextWrapper = styled.div<{ textSize: string }>`
  ${({ textSize }) => css`
    font-size: ${textSize};
    font-weight: 400;
    white-space: nowrap;
    height: auto;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    width: 180px;
    justify-content: center;
  `}
`;

const MainText = styled.div`
  color: #949494;
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 180px;
`;

const Link = styled(BaseLink)`
  ${({ theme }) => css`
    color: ${theme.colors.green600};
    white-space: nowrap;
    max-width: 180px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

const NoneText = styled.div`
  color: #949494;
`;

const Subtext = styled.div`
  font-weight: 400;
`;

const IconWrapper = styled.div`
  width: fit-content;
  display: inline-block;
  margin-left: 7px;
`;

const LinkWrapper = styled.div`
  flex-direction: row;
`;

const NonLinkWrapper = styled.div`
  flex-direction: row;
`;

const CircleImage = styled(Image)`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
`;

const StyledToolTipContainer = styled(ToolTipContainer)`
  max-width: 100px;
  position: relative;
  align-items: center;
  justify-content: flex-start;
`;

const CircleThumbnailAndText: React.FC<Props> = ({
  imageUrl,
  text,
  description,
  subtext,
  textTruncateLength,
  subtextTruncateLength,
  displayAsEmpty,
  titleText,
  textSize = '12px',
  linkUrl,
  onRemove,
  alignItems = 'center',
  'data-qaid': dataQaid,
  hasCheckbox = false,
}) => {
  const intl = useIntl();
  return (
    <MainContainer
      data-qaid={dataQaid}
      alignItems={alignItems}
      hasCheckbox={hasCheckbox}
    >
      {!displayAsEmpty && (
        <ThumbnailWrapper data-qaid="thumbnail-wrapper">
          <Thumbnail>
            <CircleImage src={imageUrl} data-qaid="thumbnail-image" />
          </Thumbnail>
        </ThumbnailWrapper>
      )}
      <>
        <TextWrapper textSize={textSize}>
          <StyledToolTipContainer>
            {titleText && <Overline>{titleText}</Overline>}
            {displayAsEmpty ? (
              <NoneText data-qaid="empty-text-label">None</NoneText>
            ) : linkUrl && text ? (
              <>
                <LinkWrapper>
                  <ToolTip>{text && text}</ToolTip>
                  <Link href={linkUrl} openInNewTab={true} data-qaid="text">
                    {textTruncateLength ? (
                      <TruncatedByCharText
                        text={text}
                        truncateLength={textTruncateLength}
                        isLink={true}
                      />
                    ) : (
                      text
                    )}
                  </Link>

                  {onRemove && (
                    <IconWrapper>
                      <DeleteIcon onClick={onRemove} />
                    </IconWrapper>
                  )}
                </LinkWrapper>

                {subtext && (
                  <Subtext>
                    {subtextTruncateLength && typeof subtext === 'string' ? (
                      <TruncatedByCharText
                        text={subtext as string}
                        truncateLength={subtextTruncateLength}
                      />
                    ) : (
                      subtext
                    )}
                  </Subtext>
                )}
                <Spacer4 />
                {description && (
                  <Subtext>
                    <i>
                      {intl.formatMessage(
                        {
                          id: 'admin.offerCreate.form.specificAvailabilityIn',
                        },
                        {
                          city: description,
                        }
                      )}
                    </i>
                  </Subtext>
                )}
              </>
            ) : (
              <>
                <NonLinkWrapper>
                  <ToolTip>{text && text}</ToolTip>
                  {text && (
                    <MainText data-qaid="text">
                      {textTruncateLength ? (
                        <TruncatedByCharText
                          text={text}
                          truncateLength={textTruncateLength}
                        />
                      ) : (
                        text
                      )}
                    </MainText>
                  )}
                  {onRemove && (
                    <IconWrapper>
                      <DeleteIcon onClick={onRemove} />
                    </IconWrapper>
                  )}
                </NonLinkWrapper>
                {subtext && (
                  <div>
                    {subtextTruncateLength && typeof subtext === 'string' ? (
                      <TruncatedByCharText
                        text={subtext as string}
                        truncateLength={subtextTruncateLength}
                      />
                    ) : (
                      subtext
                    )}
                  </div>
                )}
              </>
            )}
          </StyledToolTipContainer>
        </TextWrapper>
      </>
    </MainContainer>
  );
};

export default CircleThumbnailAndText;
