import styled from 'styled-components';

import { Textfield } from 'app/shared/components/atoms/Textfield';

export const SmallNumberTextfield = styled(Textfield)`
  display: block;
  width: 60px;
`;

export const NumberTextfield = styled(Textfield)`
  display: block;
  width: 80px;
`;

export const BigNumberTextfield = styled(Textfield)`
  display: block;
  width: 120px;
`;
