import React, { useEffect } from 'react';

import GenericFormContainer from 'app/shared/components/atoms/GenericFormContainer';

import CompetitionSharingEditFormFields from './CompetitionSharingEditFormFields';

interface FormProps {
  formikProps: any;
  setFormSubmitAction?: (func: Function) => void;
  setDisplayConfirmation?: (set: boolean) => void;
  setIsSubmitting?: (set: boolean) => void;
}

const CompetitionSharingEditForm: React.FC<FormProps> = ({
  formikProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
}) => {
  useEffect(() => {
    setFormSubmitAction && setFormSubmitAction(() => formikProps.submitForm);
    setDisplayConfirmation && setDisplayConfirmation(formikProps.dirty);
    setIsSubmitting && setIsSubmitting(formikProps.isSubmitting);
  }, [
    formikProps.isSubmitting,
    formikProps.submitForm,
    formikProps.dirty,
    setFormSubmitAction,
    setDisplayConfirmation,
    setIsSubmitting,
  ]);

  const { setFieldValue, setTouched, touched, errors, values } = formikProps;

  return (
    <GenericFormContainer dataQaId="competition-sharing-edit-form">
      <CompetitionSharingEditFormFields
        setFieldValue={setFieldValue}
        touched={touched}
        setTouched={setTouched}
        errors={errors}
        values={values}
      />
    </GenericFormContainer>
  );
};

export default CompetitionSharingEditForm;
