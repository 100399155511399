import React from 'react';
import { PDFDownloadLink, StyleSheet } from '@react-pdf/renderer';

import { useCurrentTheme } from 'app/shared/theme';

import { TodaysEventsPDF } from './TodaysEventsPDF';

interface TodaysEventPDFLinkProps {
  cityTitle: string;
  citySlug: string;
}

export const TodaysEventsPDFLink: React.FC<TodaysEventPDFLinkProps> = ({
  cityTitle,
  citySlug,
  children,
}) => {
  const theme = useCurrentTheme();

  // We disable this component in tests because @react-pdf/renderer requires
  // methods on the DOM that do not exist on the Testing Library JSDOM
  if (process.env.DISABLE_CITY_QR_CODE) {
    return null;
  }

  const styles = StyleSheet.create({
    qrCodeLink: {
      color: theme.colors.green600,
      cursor: 'pointer',
      fontSize: '14px',
      fontWeight: 'bold',
    },
  });

  return (
    <PDFDownloadLink
      document={<TodaysEventsPDF cityTitle={cityTitle} citySlug={citySlug} />}
      fileName={`todays-events-${citySlug}-qr-code.pdf`}
      style={styles.qrCodeLink}
    >
      {({ loading, error }) => !error && !loading && children}
    </PDFDownloadLink>
  );
};
