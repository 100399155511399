import React from 'react';
import styled from 'styled-components';

import { ChildMenu, ParentMenu } from 'app/typings/navbar';
import { Keyline } from 'app/shared/components/atoms/Keyline';

import { ChildMenuItemMobile } from './ChildMenuItemMobile';
import { ParentMenuItemMobile } from './ParentMenuItemMobile';

export interface LinkMenuMobileProps {
  data?: ParentMenu[];
  'data-qaid'?: string;
  activeParentIndex: number | undefined;
  activeChildIndex: number | undefined;
  isAdmin?: boolean;
}

const MobileContainer = styled.div`
  display: block;
  overflow: hidden;
  padding-bottom: 250px;
`;

export const LinkMenuMobile: React.FC<LinkMenuMobileProps> = ({
  'data-qaid': qaId = 'link-menu',
  data,
  activeParentIndex,
  activeChildIndex,
  isAdmin = false,
}) => {
  return (
    <MobileContainer data-qaid={`${qaId}`}>
      {data &&
        data.map((parentMenuItem: ParentMenu, parentIndex) => {
          if (
            parentMenuItem &&
            parentMenuItem.childMenu.length === 0 &&
            !parentMenuItem.link
          ) {
            return null;
          }

          return (
            <React.Fragment key={parentIndex}>
              {parentIndex !== 0 && (
                <Keyline lineColor="whiteDenim" mt={3} mr={6} ml={6} />
              )}
              <ParentMenuItemMobile
                parentMenuItem={parentMenuItem}
                isAdmin={isAdmin}
                qaId={qaId}
                index={parentIndex}
                key={parentMenuItem.link}
              />
              {parentMenuItem.childMenu.map(
                (childMenuItem: ChildMenu, childIndex: number) => {
                  return (
                    <ChildMenuItemMobile
                      childData={childMenuItem}
                      qaId={qaId}
                      isSelected={
                        activeParentIndex === parentIndex &&
                        activeChildIndex === childIndex
                      }
                      isAdmin={isAdmin}
                      parentIndex={activeParentIndex}
                      index={childIndex}
                      key={
                        childMenuItem.link
                          ? childMenuItem.link
                          : `${parentIndex}-${childIndex}`
                      }
                      openInNewTab={childMenuItem.openInNewTab}
                    />
                  );
                }
              )}
            </React.Fragment>
          );
        })}
    </MobileContainer>
  );
};
