import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { Artist } from 'app/typings/artists';
import { ArtistSetup } from 'app/typings/artistSetups';
import { Performance } from 'app/typings/performances';
import { get } from 'app/shared/utils/get';
import { GetArtistSetups } from 'app/shared/graphql/artistSetups/queryHooks';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import { HeaderWrapper } from 'app/shared/components/atoms/FlyoverContent';
import TruncatedByCharText from 'app/shared/components/atoms/TruncatedByCharText';
import { Overline } from 'app/shared/components/atoms/TypographyManualCSS';
import ArtistSetupSelect from 'app/admin/components/organisms/ArtistSetupSelect';

interface Props {
  performance?: Performance;
  artist?: Artist;
  onSuccess: Function;
}

const ArtistTitle = styled.div`
  ${({ theme }) => css`
    font-size: 14px;
    line-height: 21px;
    font-weight: 700;
    letter-spacing: 0.1px;
    color: ${theme.colors.blueSmoke};
    padding: 6px 0px 6px 0px;
  `}
`;

const EventPlannerArtistSetupSelectFlyover: React.FC<Props> = ({
  performance,
  artist,
  onSuccess,
}) => {
  const intl = useIntl();
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [
    selectedArtistSetup,
    setSelectedArtistSetup,
  ] = useState<ArtistSetup | null>(null);

  const {
    loading: loadingArtistSetups,
    data: dataArtistSetups,
    error: errorArtistSetups,
  } = GetArtistSetups({
    setuppableId: performance && performance.id,
    setuppableType: 'Performance',
    fetchPolicy: 'network-only',
  });

  return (
    <div data-qaid="event-planner-artist-setup-select">
      <HeaderWrapper>
        <Overline>
          {intl.formatMessage({
            id: 'shared.artist',
          })}
        </Overline>
        <ArtistTitle>
          <TruncatedByCharText
            text={artist ? artist.title : null}
            truncateLength={25}
          />
        </ArtistTitle>
        <DottedLine />
      </HeaderWrapper>
      <ArtistSetupSelect
        performance={performance}
        artistSetups={get(dataArtistSetups, 'artistSetups.artistSetups', [])}
        loadingArtistSetups={loadingArtistSetups}
        errorArtistSetups={errorArtistSetups}
        selectedArtistSetup={selectedArtistSetup}
        setIsSubmitting={setIsSubmitting}
        setSelectedArtistSetup={setSelectedArtistSetup}
        onSuccess={() => {
          onSuccess();
        }}
      />
    </div>
  );
};

export default EventPlannerArtistSetupSelectFlyover;
