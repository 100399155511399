import * as Yup from 'yup';

const countrySettingsEditFormSchema = (intl: any) =>
  Yup.object().shape({
    bookingFee: Yup.string()
      .nullable()
      .trim()
      .matches(/^\d+\.\d\d$/, {
        message: intl.formatMessage({
          id: 'admin.countryBasicInfo.form.bookingFeeError',
        }),
      }),
    artistCompensation: Yup.string()
      .trim()
      .required(
        intl.formatMessage({
          id: 'form.requiredField',
        })
      )
      .matches(/^\d+\.\d\d$/, {
        message: intl.formatMessage({
          id: 'admin.countryBasicInfo.form.artistCompensationError',
        }),
      }),
    artistCompensationTier1: Yup.string()
      .nullable()
      .trim()
      .matches(/^\d+\.\d\d$/, {
        message: intl.formatMessage({
          id: 'admin.countryBasicInfo.form.artistCompensationError',
        }),
      }),
    artistCompensationTier2: Yup.string()
      .nullable()
      .trim()
      .matches(/^\d+\.\d\d$/, {
        message: intl.formatMessage({
          id: 'admin.countryBasicInfo.form.artistCompensationError',
        }),
      }),
    artistCompensationTier3: Yup.string()
      .nullable()
      .trim()
      .matches(/^\d+\.\d\d$/, {
        message: intl.formatMessage({
          id: 'admin.countryBasicInfo.form.artistCompensationError',
        }),
      }),
    artistCompensationTier4: Yup.string()
      .nullable()
      .trim()
      .matches(/^\d+\.\d\d$/, {
        message: intl.formatMessage({
          id: 'admin.countryBasicInfo.form.artistCompensationError',
        }),
      }),
    artistCompensationTier5: Yup.string()
      .nullable()
      .trim()
      .matches(/^\d+\.\d\d$/, {
        message: intl.formatMessage({
          id: 'admin.countryBasicInfo.form.artistCompensationError',
        }),
      }),
  });

export default countrySettingsEditFormSchema;
