export const sortOptions = [
  {
    title: 'Most Recently Submitted',
    value: {
      by: 'created_at',
      direction: 'desc',
    },
  },
  {
    title: 'Least Recently Submitted',
    value: {
      by: 'created_at',
      direction: 'asc',
    },
  },
  {
    title: 'Artist Name (A - Z)',
    value: {
      by: 'name',
      direction: 'asc',
    },
  },
  {
    title: 'Artist Name (Z - A)',
    value: {
      by: 'name',
      direction: 'desc',
    },
  },
  {
    title: 'City (A - Z)',
    value: {
      by: 'city_title',
      direction: 'asc',
    },
  },
  {
    title: 'City (Z - A)',
    value: {
      by: 'city_title',
      direction: 'desc',
    },
  },
  {
    title: 'Soonest Availability',
    value: {
      by: 'availability_dates',
      direction: 'asc',
    },
  },
  {
    title: 'Latest Availability',
    value: {
      by: 'availability_dates',
      direction: 'desc',
    },
  },
];

export const staticOptions = {
  status: [
    {
      title: 'Accepted',
      value: 'accepted',
    },
    {
      title: 'Declined',
      value: 'declined',
    },
  ],
  availabilityDates: [
    {
      title: 'Next 7 Days',
      value: 'start_next_7_days',
    },
    {
      title: 'Next 30 Days',
      value: 'start_next_30_days',
    },
    {
      title: 'Next 90 Days',
      value: 'start_next_90_days',
    },
  ],
};
