import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { MaxPaInputs } from 'app/typings';
import { EventFormatExperiment } from 'app/typings/eventFormatExperiments';
import { Theme } from 'app/typings/themes';
import { ToolTip, ToolTipContainer } from 'app/shared/components/atoms/ToolTip';
import TruncatedByCharText from 'app/shared/components/atoms/TruncatedByCharText';
import InfoBox from 'app/shared/components/molecules/Flyover';
import IconInCircle from 'app/admin/components/atoms/IconInCircle';
import EventPlannerCommentsFlyover from 'app/admin/components/organisms/EventPlannerCommentsFlyover';
import { ReactComponent as NotesBookText } from 'icons/streamline-regular/content/notes/notes-book-text.svg';
import { ReactComponent as MusicNote2 } from 'icons/streamline-regular/music-audio/music/music-note-2.svg';
import { ReactComponent as LabFlaskExperiment } from 'icons/streamline-regular/science/labs-experiments/lab-flask-experiment.svg';
import { ReactComponent as CameraStudio } from 'icons/streamline-regular/video-movies-tv/cameras/camera-studio.svg';

interface FeaturesProps {
  id: number;
  eventTheme?: Theme;
  commentsCount?: number;
  maxPaInputs?: MaxPaInputs;
  isFilmed?: boolean;
  eventFormatExperiment?: EventFormatExperiment;
}

const ThemeTag = styled.div`
  ${({ theme }) => css`
    height: 16px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    text-align: center;
    flex-shrink: 0;
    padding: 1px 10px;
    margin-top: -21px;
    margin-right: -2px;
    height: 18px;
    border-radius: 8px 8px 8px 8px;
    color: ${theme.colors.whiteDenim};
    background: rgba(52, 73, 94, 0.4);

    @media screen and (min-width: 1200px) and (max-width: 1300px) {
      font-size: 9px;
    }
  `}
`;

const EventTheme = styled.div`
  height: 18px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 30px;
`;

const EventFeatureList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px;
`;

const EventFeature = styled.div`
  margin-right: 7px;
`;

const EventFeatures: React.FC<FeaturesProps> = ({
  id,
  eventTheme,
  commentsCount,
  maxPaInputs,
  isFilmed,
  eventFormatExperiment,
}) => {
  const intl = useIntl();

  const [commentsExist, setCommentsExist] = useState(!!commentsCount);

  return (
    <div data-qaid="event-planner-card-features">
      <EventTheme>
        {eventTheme && (
          <ThemeTag>
            <TruncatedByCharText text={eventTheme.title} truncateLength={15} />
          </ThemeTag>
        )}
      </EventTheme>
      <EventFeatureList>
        <EventFeature>
          <InfoBox
            showCaret={false}
            width="300px"
            triggerElement={
              <IconInCircle
                circleBackgroundColor={
                  commentsExist ? 'orangeCrush' : 'whiteDenim'
                }
                iconSize={18}
                iconName={NotesBookText}
                iconColor={commentsExist ? 'whiteDenim' : 'green600'}
                borderCircle={false}
                circleSize={32}
                dataQaIdSuffix="feature-icon"
              />
            }
            innerContent={
              <EventPlannerCommentsFlyover
                eventId={id}
                onUpdateComments={(commentsCount: number) => {
                  setCommentsExist(commentsCount > 0);
                }}
              />
            }
            data-qaid="notes-info-box"
          />
        </EventFeature>
        {maxPaInputs?.key === 'acoustic' && (
          <EventFeature>
            <ToolTipContainer>
              <IconInCircle
                circleBackgroundColor="blueChristmas"
                iconSize={18}
                iconName={MusicNote2}
                iconColor="whiteDenim"
                borderCircle={false}
                circleSize={32}
                dataQaIdSuffix="feature-acoustic-icon"
              />
              <ToolTip>
                {intl.formatMessage({
                  id: 'admin.shared.acoustic',
                })}
              </ToolTip>
            </ToolTipContainer>
          </EventFeature>
        )}
        {isFilmed && (
          <EventFeature>
            <ToolTipContainer>
              <IconInCircle
                circleBackgroundColor="purpleHaze"
                iconSize={18}
                iconName={CameraStudio}
                iconColor="whiteDenim"
                borderCircle={false}
                circleSize={32}
                dataQaIdSuffix="feature-filmed-icon"
              />
              <ToolTip>
                {intl.formatMessage({
                  id: 'admin.shared.filmed',
                })}
              </ToolTip>
            </ToolTipContainer>
          </EventFeature>
        )}
        {eventFormatExperiment && (
          <EventFeature>
            <ToolTipContainer>
              <IconInCircle
                circleBackgroundColor="mellowYellow"
                iconSize={18}
                iconName={LabFlaskExperiment}
                iconColor="whiteDenim"
                borderCircle={false}
                circleSize={32}
                dataQaIdSuffix="feature-experiment-icon"
              />
              <ToolTip>{eventFormatExperiment.title}</ToolTip>
            </ToolTipContainer>
          </EventFeature>
        )}
      </EventFeatureList>
    </div>
  );
};

export default EventFeatures;
