import * as Yup from 'yup';

import { getNotesFormSchema } from 'app/admin/utils/string';

const EventDetailsVenueEditFormSchema = (intl: any) =>
  Yup.object().shape({
    venueName: Yup.string()
      .trim()
      .nullable(),
    audioNotes: getNotesFormSchema(intl, 1000),
    mcNotes: getNotesFormSchema(intl, 1000),
    seatingNotes: getNotesFormSchema(intl),
    suppliesNeededNotes: getNotesFormSchema(intl),
  });

export default EventDetailsVenueEditFormSchema;
