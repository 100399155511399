import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { ContactInfo } from 'app/typings/contactInfos';
import { User } from 'app/typings/users';
import { currencyFormatter } from 'app/shared/utils/currencyFormatter';
import { get } from 'app/shared/utils/get';
import usePermission from 'app/shared/utils/usePermission';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import {
  BaseLink,
  GenericLink,
} from 'app/shared/components/atoms/LinkManualCSS';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Overline } from 'app/shared/components/atoms/TypographyManualCSS';
import IconAndTextLink from 'app/shared/components/molecules/IconAndTextLink';
import {
  DetailHeading as Heading,
  DetailValueContainer as ValueContainer,
} from 'app/admin/components/atoms/DetailContent';
import ExpandableText from 'app/admin/components/atoms/ExpandableText';
import { None, NoneWrapper } from 'app/admin/components/atoms/None';
import { UserLink } from 'app/admin/components/molecules/EntityLink';
import PartnerPromotionDisplayValue from 'app/admin/components/organisms/EventDetails/PartnerPromotionDisplayValue';
import { ReactComponent as Camera1 } from 'icons/streamline-regular/images-photography/cameras/camera-1.svg';

interface Props {
  sectionData: any;
  callbacks: { [name: string]: VoidFunction };
}

interface ProtectedLinkProps {
  permission: string;
  href: string;
  value: string;
}

interface RoleContactProps {
  user?: User;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
}

interface PartnerContactProps {
  title?: string;
  contactRelationship?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  address?: string;
}

const UserContainer = styled.div`
  margin-bottom: 7px;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const StyledOverline = styled(Overline)`
  margin-bottom: 24px;
`;

const ValueLink = styled(BaseLink)`
  ${({ theme }) => css`
    color: ${theme.colors.green600};
  `}
`;

const renderAssociatedUser = (
  user: User | undefined,
  firstName: string | undefined,
  lastName: string | undefined
) => {
  if (user) {
    return (
      <UserContainer>
        <UserLink user={user} truncateLength={30} />
      </UserContainer>
    );
  } else {
    return (
      <>
        {!!firstName && `${firstName}`} {!!lastName && `${lastName}`}
      </>
    );
  }
};

const RoleContactInfoCard: React.FC<RoleContactProps> = ({
  user,
  firstName,
  lastName,
  email,
  phoneNumber,
}) => {
  return (
    <>
      {renderAssociatedUser(user, firstName, lastName)}
      <br />
      {!!email && <ValueLink href={`mailto:${email}`}>{email}</ValueLink>}
      <br />
      {!!phoneNumber && (
        <ValueLink href={`tel:${phoneNumber}`}> {phoneNumber} </ValueLink>
      )}
      <Spacer mb={4} />
    </>
  );
};

const PartnerInfoCard: React.FC<PartnerContactProps> = ({
  title,
  contactRelationship,
  firstName,
  lastName,
  email,
  phoneNumber,
  address,
}) => {
  return (
    <>
      {!!title && `${title}`}
      {(!!firstName || !!lastName) && <br />}
      {!!firstName && `${firstName}`} {!!lastName && `${lastName}`}
      <br />
      {!!contactRelationship && `${contactRelationship}`}
      <br />
      {!!email && <ValueLink href={`mailto:${email}`}>{email}</ValueLink>}
      <br />
      {!!phoneNumber && (
        <ValueLink href={`tel:${phoneNumber}`}> {phoneNumber} </ValueLink>
      )}
      <br />
      {!!address && `${address}`}
      <Spacer mb={4} />
    </>
  );
};

const ProtectedLink: React.FC<ProtectedLinkProps> = ({
  permission,
  href,
  value,
}) => {
  return (
    <>
      {usePermission(permission) ? (
        <ValueLink href={href} openInNewTab={true}>
          {value}
        </ValueLink>
      ) : (
        <div>{value}</div>
      )}
    </>
  );
};

const PartnerInfoNone = () => {
  const intl = useIntl();
  return (
    <NoneWrapper>
      {intl.formatMessage({
        id: 'admin.eventPlanner.venueInfo.notApplicable',
      })}
    </NoneWrapper>
  );
};

const getPhoneNumber = (countryCode?: string, numberBody?: string) => {
  if (!numberBody) {
    return '';
  } else if (!countryCode) {
    return numberBody;
  } else {
    return countryCode + '-' + numberBody;
  }
};

const renderPartnerContactInfo = (
  contactInfos: ContactInfo[] | undefined,
  title?: string,
  address?: string
) => {
  const none = <None />;

  if (!contactInfos) {
    return none;
  }

  const primaryContactInfo = contactInfos.filter(item => item.position == 1);

  if (primaryContactInfo.length > 0) {
    const firstName = get(primaryContactInfo[0], 'firstName', undefined);
    const lastName = get(primaryContactInfo[0], 'lastName', undefined);
    const email = get(primaryContactInfo[0], 'email', undefined);
    const phoneNumber = get(primaryContactInfo[0], 'phoneNumber', undefined);
    const contactRelationship = get(
      primaryContactInfo[0],
      'contactRelationship.name',
      undefined
    );

    return (
      <PartnerInfoCard
        title={title}
        contactRelationship={contactRelationship}
        firstName={firstName}
        lastName={lastName}
        email={email}
        phoneNumber={phoneNumber}
        address={address}
      />
    );
  } else {
    return none;
  }
};

const SectionPartnerInfo: React.FC<Props> = ({ sectionData, callbacks }) => {
  const intl = useIntl();

  const { event } = sectionData;

  return (
    <>
      <MainContainer data-qaid="section-partner-info">
        <Grid cols={4}>
          <Col xs={12} sm={12} md={1} lg={1} xl={1} key={1}>
            <StyledOverline>
              {intl.formatMessage({
                id: 'admin.eventPlanner.partnerInfo.partnerInfo',
              })}
            </StyledOverline>
            <Heading>
              {intl.formatMessage({
                id: 'admin.eventPlanner.partnerInfo.partnerContact',
              })}
            </Heading>
            <ValueContainer data-qaid="section-partner-info-contact-info">
              {get(event, 'campaign.partner.id', undefined) ? (
                renderPartnerContactInfo(
                  get(event, 'campaign.partner.contactInfos', undefined),
                  get(event, 'campaign.partner.title', undefined),
                  get(event, 'campaign.partner.address', undefined) ||
                    get(event, 'campaign.partner.address2', undefined)
                )
              ) : (
                <PartnerInfoNone />
              )}
            </ValueContainer>
            <Heading>
              {intl.formatMessage({
                id: 'admin.eventPlanner.partnerInfo.partnerDescription',
              })}
            </Heading>
            <ValueContainer data-qaid="section-partner-info-description">
              {get(event, 'campaign.partner.description', undefined) ? (
                <ExpandableText
                  allowHTML
                  text={get(event, 'campaign.partner.description', undefined)}
                  truncateLength={50}
                />
              ) : (
                <PartnerInfoNone />
              )}
            </ValueContainer>
            <Heading>
              {intl.formatMessage({
                id: 'admin.eventPlanner.partnerInfo.campaigns',
              })}
            </Heading>
            <ValueContainer data-qaid="section-partner-info-campaign-count">
              {get(
                event,
                'campaign.partner.partnersCampaignsCount',
                undefined
              ) ? (
                <ProtectedLink
                  permission="campaign.access"
                  href={`/admin/campaigns?partner=${event.campaign.partner.id}`}
                  value={get(
                    event,
                    'campaign.partner.partnersCampaignsCount',
                    undefined
                  )}
                />
              ) : (
                <PartnerInfoNone />
              )}
            </ValueContainer>
          </Col>
          <Col xs={12} sm={12} md={1} lg={1} xl={1} key={2}>
            <StyledOverline>
              {intl.formatMessage({
                id: 'admin.eventPlanner.partnerInfo.campaignInfo',
              })}
            </StyledOverline>
            <Heading>
              {intl.formatMessage({
                id: 'admin.eventPlanner.partnerInfo.campaignName',
              })}
            </Heading>
            <ValueContainer data-qaid="section-partner-info-campaign-progress">
              {get(event, 'campaign.partner.id', undefined) ? (
                <>
                  <ProtectedLink
                    permission="campaign.access"
                    href={`/admin/promo-codes?campaign_id=${event.campaign.id}`}
                    value={get(event, 'campaign.title', undefined)}
                  />
                  <div> </div>
                  {intl.formatMessage(
                    {
                      id: 'admin.eventPlanner.partnerInfo.campaignProgress',
                    },
                    {
                      pastCampaigns: get(
                        event,
                        'campaign.pastEventsWithCampaignCount',
                        0
                      ),
                      totalCampaigns:
                        get(event, 'campaign.pastEventsWithCampaignCount', 0) +
                        get(
                          event,
                          'campaign.upcomingEventsWithCampaignCount',
                          0
                        ),
                    }
                  )}
                </>
              ) : (
                <PartnerInfoNone />
              )}
            </ValueContainer>
            <Heading>
              {intl.formatMessage({
                id: 'admin.eventPlanner.partnerInfo.agency',
              })}
            </Heading>
            <ValueContainer data-qaid="section-partner-info-agency">
              {get(event, 'campaign.agency.id', undefined) ? (
                get(event, 'campaign.agency.title', undefined)
              ) : (
                <PartnerInfoNone />
              )}
            </ValueContainer>
            <Heading>
              {intl.formatMessage({
                id: 'shared.competition',
              })}
            </Heading>
            <ValueContainer data-qaid="section-partner-info-competition">
              {get(event, 'competition.uid', undefined) ? (
                <ProtectedLink
                  permission="competition.access"
                  href={`/comp/${event.competition.uid}`}
                  value={get(event, 'competition.title', undefined)}
                />
              ) : (
                <PartnerInfoNone />
              )}
            </ValueContainer>
            <Heading>
              {intl.formatMessage({
                id: 'admin.eventPlanner.partnerInfo.promoCodes',
              })}
            </Heading>
            <ValueContainer data-qaid="section-partner-info-promocodes">
              {get(event, 'campaign.partner.id', undefined) &&
              get(event, 'campaign.promoCodesWithCampaignCount', undefined) ? (
                <ProtectedLink
                  permission="promoCode.access"
                  href={`/admin/promo-codes?campaign_id=${event.campaign.id}`}
                  value={get(
                    event,
                    'campaign.promoCodesWithCampaignCount',
                    undefined
                  )}
                />
              ) : (
                <PartnerInfoNone />
              )}
            </ValueContainer>
          </Col>
          <Col xs={12} sm={12} md={1} lg={1} xl={1} key={3}>
            <StyledOverline>
              {intl.formatMessage({
                id: 'admin.eventPlanner.partnerInfo.otherInfo',
              })}
            </StyledOverline>
            <Heading>
              {intl.formatMessage({
                id: 'admin.eventPlanner.partnerInfo.accountManager',
              })}
            </Heading>
            <ValueContainer data-qaid="section-partner-info-account-manager-user">
              {get(event, 'campaign.accountManagerUser.id', undefined) ? (
                <RoleContactInfoCard
                  firstName={get(
                    event,
                    'campaign.accountManagerUser.firstName',
                    undefined
                  )}
                  lastName={get(
                    event,
                    'campaign.accountManagerUser.lastName',
                    undefined
                  )}
                  email={get(
                    event,
                    'campaign.accountManagerUser.email',
                    undefined
                  )}
                  phoneNumber={getPhoneNumber(
                    get(
                      event,
                      'campaign.accountManagerUser.mobile.countryCode',
                      undefined
                    ),
                    get(
                      event,
                      'campaign.accountManagerUser.mobile.numberBody',
                      undefined
                    )
                  )}
                />
              ) : (
                <PartnerInfoNone />
              )}
            </ValueContainer>
            <Heading>
              {intl.formatMessage({
                id: 'admin.eventPlanner.partnerInfo.salesRep',
              })}
            </Heading>
            <ValueContainer data-qaid="section-partner-info-sales-rep-user">
              {get(event, 'campaign.salesRepUser.id', undefined) ? (
                <RoleContactInfoCard
                  firstName={get(
                    event,
                    'campaign.salesRepUser.firstName',
                    undefined
                  )}
                  lastName={get(
                    event,
                    'campaign.salesRepUser.lastName',
                    undefined
                  )}
                  email={get(event, 'campaign.salesRepUser.email', undefined)}
                  phoneNumber={getPhoneNumber(
                    get(
                      event,
                      'campaign.accountManagerUser.mobile.countryCode',
                      undefined
                    ),
                    get(
                      event,
                      'campaign.accountManagerUser.mobile.numberBody',
                      undefined
                    )
                  )}
                />
              ) : (
                <PartnerInfoNone />
              )}
            </ValueContainer>
            <Heading>
              {intl.formatMessage({
                id: 'admin.eventPlanner.partnerInfo.venueBudget',
              })}
            </Heading>
            <ValueContainer data-qaid="section-partner-info-venue-budget">
              {get(event, 'venueBudget', undefined) ? (
                currencyFormatter(event.venueBudget || 0, event.currencySymbol)
              ) : (
                <PartnerInfoNone />
              )}
            </ValueContainer>
            <ValueContainer data-qaid="section-partner-info-venue-budget-description">
              {get(event, 'venueBudgetDescription', undefined) ? (
                <ExpandableText
                  allowHTML
                  text={get(event, 'venueBudgetDescription', undefined)}
                  truncateLength={50}
                />
              ) : (
                intl.formatMessage({
                  id: 'admin.eventPlanner.partnerInfo.defaultBudgetDescription',
                })
              )}
            </ValueContainer>
          </Col>
          <Col xs={12} sm={12} md={1} lg={1} xl={1} key={4}>
            <StyledOverline>
              {intl.formatMessage({
                id: 'admin.eventPlanner.partnerInfo.notes',
              })}
            </StyledOverline>
            <Heading>
              {intl.formatMessage({
                id: 'admin.eventPlanner.partnerInfo.partnerNotes',
              })}
            </Heading>
            <ValueContainer data-qaid="section-partner-info-partner-notes">
              {get(event, 'campaign.partner', undefined) ? (
                <ExpandableText
                  allowHTML
                  text={get(event, 'sponsorNotes', undefined)}
                  truncateLength={50}
                />
              ) : (
                <PartnerInfoNone />
              )}
            </ValueContainer>
            <Heading>
              {intl.formatMessage({
                id: 'admin.eventPlanner.partnerInfo.postShowSummary',
              })}
            </Heading>
            <ValueContainer data-qaid="section-partner-info-post-show-summary">
              {get(event, 'campaign.partner', undefined) ? (
                <ExpandableText
                  allowHTML
                  text={get(event, 'sponsorPostShowSummary', undefined)}
                  truncateLength={50}
                />
              ) : (
                <PartnerInfoNone />
              )}
            </ValueContainer>
            <StyledOverline>
              {intl.formatMessage({
                id: 'admin.eventPlanner.partnerPromotion',
              })}
            </StyledOverline>
            <Heading>
              {intl.formatMessage({
                id: 'admin.eventPlanner.partnerPromotion.title',
              })}
            </Heading>
            <ValueContainer data-qaid="section-partner-info-partner-promotion-title">
              {event.partnerPromotionTitle ? (
                <ExpandableText
                  allowHTML
                  text={event.partnerPromotionTitle}
                  truncateLength={50}
                />
              ) : (
                <PartnerInfoNone />
              )}
            </ValueContainer>
            <Heading>
              {intl.formatMessage({
                id: 'admin.eventPlanner.partnerPromotion.description',
              })}
            </Heading>
            <ValueContainer data-qaid="section-partner-info-partner-promotion-description">
              {event.partnerPromotionDescription ? (
                <ExpandableText
                  allowHTML
                  text={event.partnerPromotionDescription}
                  truncateLength={50}
                />
              ) : (
                <PartnerInfoNone />
              )}
            </ValueContainer>
            <Heading>
              {intl.formatMessage({
                id: 'admin.eventPlanner.partnerPromotion.buttonText',
              })}
            </Heading>
            <ValueContainer data-qaid="section-partner-info-partner-promotion-button-text">
              {event.partnerPromotionButtonText ? (
                <ExpandableText
                  allowHTML
                  text={event.partnerPromotionButtonText}
                  truncateLength={50}
                />
              ) : (
                <PartnerInfoNone />
              )}
            </ValueContainer>
            <Heading>
              {intl.formatMessage({
                id: 'admin.eventPlanner.partnerPromotion.buttonUrl',
              })}
            </Heading>
            <ValueContainer data-qaid="section-partner-info-partner-promotion-button-url">
              {event.partnerPromotionButtonUrl ? (
                <GenericLink url={event.partnerPromotionButtonUrl}>
                  {event.partnerPromotionButtonUrl}
                </GenericLink>
              ) : (
                <PartnerInfoNone />
              )}
            </ValueContainer>
            <Heading>
              {intl.formatMessage({
                id: 'admin.eventPlanner.partnerPromotion.shouldDisplay',
              })}
            </Heading>
            <ValueContainer data-qaid="section-partner-info-partner-promotion-should-display">
              <PartnerPromotionDisplayValue event={event} />
            </ValueContainer>
            <IconAndTextLink
              icon={Camera1}
              text={intl.formatMessage({
                id: 'admin.eventPlanner.partnerPromotion.manageImage',
              })}
              onClick={callbacks.togglePartnerPromotionImageModal}
            />
          </Col>
        </Grid>
      </MainContainer>
    </>
  );
};

export default SectionPartnerInfo;
