import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { GeneralEvent } from 'app/typings/events';
import { Venue } from 'app/typings/venues';
import { useCurrentTheme } from 'app/shared/theme';
import { dateFormatter } from 'app/shared/utils/datetime';
import usePermission from 'app/shared/utils/usePermission';
import { BaseLink } from 'app/shared/components/atoms/LinkManualCSS';
import { Spacer, Spacer8 } from 'app/shared/components/atoms/Spacer';
import { StreamlineIcon } from 'app/shared/components/atoms/StreamlineIcon';
import { ToolTip, ToolTipContainer } from 'app/shared/components/atoms/ToolTip';
import { eventTypeLabels } from 'app/admin/utils/events';
import DetailText from 'app/admin/components/atoms/DetailText';
import { TagElement } from 'app/admin/components/molecules/TagElementList';
import { ReactComponent as Star } from 'icons/streamline-regular/social-medias-rewards-rating/rating/rating-star.svg';

interface Props {
  event: GeneralEvent;
  artistName?: string;
  artistId?: number;
}

const CityNameDetailText = styled(DetailText)`
  ${({ theme }) => css`
    color: ${theme.colors.green600};
    margin-bottom: 5px;
  `}
`;

const CityNameContainer = styled.div`
  margin-bottom: 5px;
`;

const IconWrapper = styled.span<any>`
  ${({ iconSize }) => css`
    height: ${iconSize}px;
    display: flex;
  `}
`;

const StyledStreamlineIcon = styled(StreamlineIcon)`
  margin-top: 2px;
`;

const StyledToolTip = styled(ToolTip)`
  left: 0;
`;

const CancelledTagElementWrapper = styled.div`
  height: 24px;
`;

const StyledTag = styled(TagElement)`
  text-align: center;
  padding: 1px 5px;
  height: 18px;
  width: fit-content;
`;

const venueBuilder = (venue: Venue) => {
  if (venue.address == 'Secret Location') {
    return;
  }

  return venue.venueName || venue.address;
};

const eventTypeLabelsWithBroadcast = Object.assign(eventTypeLabels, {
  Broadcast: 'Broadcast',
});

const EventSummary: React.FC<Props> = ({ event, artistName, artistId }) => {
  const intl = useIntl();
  const theme = useCurrentTheme();

  const hasViewEventsPermission = usePermission('event.list.view');
  const cancellations = event?.cancellations;

  const mostRecentCancellation =
    cancellations?.length > 0 ? cancellations[cancellations.length - 1] : null;

  const cancellationReason = mostRecentCancellation?.cancellationReason;

  const cancellableType =
    mostRecentCancellation?.cancellationReason?.cancellableType;

  // had_other_opportunity, had_staffing_challenges, host_cancellation, and unexpectedly_closed will display the host cancellation tag
  const cancellationReasons = [
    'had_other_opportunity',
    'had_staffing_challenges',
    'unexpectedly_closed',
    'host_cancellation',
  ];

  const isHostRelatedCancellation = cancellationReasons.includes(
    cancellationReason?.key
  );

  const eventType = event.eventType || '';
  const cancelled = !!event.cancelled;
  const eventId = event.id;

  const artistIsSelfBookedOnEvent = event.performances?.find(
    performance =>
      performance.artist.id === artistId && performance.isArtistSelfBooked
  );

  const getCityTitle = (city: any) => {
    if (!city.title) {
      return '';
    }
    if (city.title === 'NYC') {
      return 'New York City';
    }
    return city.title;
  };

  const cityTitle = getCityTitle(event.city);

  const getArtistsList = () => {
    if (event.artists) {
      if (artistName && event.artists.length > 1) {
        const otherArtists = event.artists
          .filter((artist: any) => artist.title !== artistName)
          .map((artist: any) => artist.title)
          .join(', ');
        return `with ${otherArtists}`;
      }
      return event.artists.map((artist: any) => artist.title).join(', ');
    }
    return '';
  };

  return (
    <>
      {hasViewEventsPermission ? (
        <BaseLink
          href={
            event.eventType === 'Broadcast'
              ? '/admin/broadcasts'
              : `/admin/concert-planner?event_id=${eventId}`
          }
          openInNewTab={true}
          data-qaid="link"
        >
          <CityNameDetailText data-qaid="city-title">
            {artistIsSelfBookedOnEvent ? (
              <ToolTipContainer>
                <IconWrapper>
                  <StyledStreamlineIcon
                    size={16}
                    icon={Star}
                    stroke={theme.colors.green600}
                  />
                  <Spacer8 />
                  {cityTitle}
                </IconWrapper>
                <StyledToolTip>
                  {intl.formatMessage({
                    id: 'admin.detailsAllEvents.title.selfBooked',
                  })}
                </StyledToolTip>
              </ToolTipContainer>
            ) : (
              cityTitle
            )}
          </CityNameDetailText>
        </BaseLink>
      ) : (
        <CityNameContainer>
          <DetailText data-qaid="city-title">{cityTitle}</DetailText>
        </CityNameContainer>
      )}
      {event.localStartsAt && (
        <DetailText data-qaid="date">
          {dateFormatter(event.localStartsAt, 'shortMonthDateAndYear')}
        </DetailText>
      )}
      {event.venue && (
        <DetailText data-qaid="venue">
          {cancelled || venueBuilder(event.venue)}
        </DetailText>
      )}
      {event.artists && eventType !== 'Broadcast' && (
        <DetailText data-qaid="other-artists">{getArtistsList()}</DetailText>
      )}
      {eventType && eventTypeLabelsWithBroadcast[eventType] && (
        <DetailText data-qaid="event-type">{`Type: ${eventTypeLabelsWithBroadcast[eventType]}`}</DetailText>
      )}
      <Spacer mt={2} />
      {cancellationReason && (event?.cancelled || cancellableType === 'Venue') && (
        <React.Fragment>
          {isHostRelatedCancellation && (
            <CancelledTagElementWrapper data-qaid="cancelled-tag">
              <StyledTag
                tagElementColor={theme.colors.redRedWine}
                textColor={theme.colors.whiteDenim}
              >
                {intl.formatMessage({
                  id: 'admin.detailsAllEvents.title.hostCancelled',
                })}
              </StyledTag>
            </CancelledTagElementWrapper>
          )}
          <CancelledTagElementWrapper data-qaid="cancelled-tag">
            <StyledTag
              tagElementColor={theme.colors.blueSmoke}
              textColor={theme.colors.whiteDenim}
            >
              {cancellableType === 'Event'
                ? intl.formatMessage({
                    id: 'admin.detailsAllEvents.title.concertCancelled',
                  })
                : intl.formatMessage({
                    id: 'admin.detailsAllEvents.title.sofarRemovedHost',
                  })}
            </StyledTag>
          </CancelledTagElementWrapper>
          {cancellableType && (
            <DetailText
              data-qaid={`${cancellableType.toLowerCase()}-${
                cancellableType === 'Event' ? 'cancel' : 'removal'
              }-date`}
            >{`${
              cancellableType === 'Event' ? 'Cancel' : 'Removal'
            } date: ${dateFormatter(
              mostRecentCancellation?.updatedAt,
              'shortMonthDateAndYear'
            )}`}</DetailText>
          )}
          <DetailText data-qaid="cancellation-reason-description">
            {cancellationReason?.key === 'other'
              ? mostRecentCancellation?.reasonDescription
              : cancellationReason?.description}
          </DetailText>
        </React.Fragment>
      )}
      {event.status && <DetailText> Status: {event.status}</DetailText>}
      {event.position && <DetailText> Position: {event.position}</DetailText>}
    </>
  );
};

export default EventSummary;
