import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { CreateBroadcast } from 'app/admin/graphql/broadcasts/mutationHooks';
import { DetailsFormMainContainer } from 'app/admin/components/atoms/DetailContent';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

import BroadcastEditForm from './BroadcastEditForm';
import BroadcastEditFormSchema from './BroadcastEditFormSchema';

interface Props {
  contentProps?: any;
  setFormSubmitAction?: (func: Function) => void;
  setDisplayConfirmation?: (set: boolean) => void;
  setIsSubmitting?: (set: boolean) => void;
  hide: VoidFunction;
}

const BroadcastCreate: React.FC<Props> = ({
  contentProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  hide,
}) => {
  const intl = useIntl();

  const [validationErrors, setValidationErrors] = useState<object>({});

  const formInitialValues = {
    scheduledStartTimeUtc: '',
    startDate: '',
    startTime: '',
    artistId: null,
    artistTitle: '',
    location: '',
    streamType: 'live_stream',
    embedUrl: '',
    extraInfoUrl: '',
    linkType: 'Web',
    partnershipTag: '',
    hideDonation: false,
    donationText: 'Donate',
  };

  const createBroadcastAction = CreateBroadcast();

  const handleCreateBroadcast = useSubmitAction({
    submitAction: createBroadcastAction,
    submitVariables: (values: any) => ({
      scheduledStartTimeUtc: values.scheduledStartTimeUtc,
      artistId: values.artistId,
      location: values.location,
      streamType: values.streamType,
      embedUrl: values.embedUrl,
      extraInfoUrl: values.extraInfoUrl,
      linkType: values.linkType,
      partnershipTag: values.partnershipTag,
      hideDonation: values.hideDonation,
      donationText: values.donationText,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.broadcastCreate.successMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.broadcastCreate.failureMessage',
    }),
    onSuccess: () => {
      contentProps.refetchBroadcasts();
      hide();
    },
    onValidationError: validationErrors =>
      setValidationErrors(validationErrors),
  });

  return (
    <ModalContentContainer>
      <DetailsFormMainContainer>
        <DetailsHeader
          title={intl.formatMessage({
            id: 'admin.broadcastCreate.title',
          })}
          isNewForm={true}
        />
        <GenericForm
          formInitialValues={formInitialValues}
          renderFormComponent={(renderProps: any) => (
            <BroadcastEditForm
              formikProps={renderProps.formikProps}
              validationErrors={validationErrors}
            />
          )}
          onSubmit={handleCreateBroadcast}
          formSchema={BroadcastEditFormSchema(intl)}
          setFormSubmitAction={setFormSubmitAction}
          setDisplayConfirmation={setDisplayConfirmation}
          setIsSubmitting={setIsSubmitting}
          dataQaId="broadcast-create-form"
        />
      </DetailsFormMainContainer>
    </ModalContentContainer>
  );
};

export default BroadcastCreate;
