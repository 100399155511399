import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import styled, { css } from 'styled-components';

import { useCurrentTheme } from 'app/shared/theme';
import { StreamlineIcon } from 'app/shared/components/atoms/StreamlineIcon';
import { EventCollectionLink } from 'app/admin/components/molecules/EntityLink';
import { ReactComponent as LayoutCornersDashboard1 } from 'icons/streamline-regular/interface-essential/dashboard/layout-corners-dashboard-1.svg';

interface RowProps {
  collection: any;
  isDragDisabled?: boolean;
}

const IconContainer = styled.div`
  width: 30px;
  padding-left: 8px;
`;

const UpcomingEventsCountContainer = styled.div`
  width: 80px;
  text-align: left;
`;

const TitleContainer = styled.div`
  width: 250px;
`;

const SlugContainer = styled.div`
  ${({ theme }) => css`
    width: auto;
    flex: 1;

    ${theme.media.xs`
      display: none;
    `}

    ${theme.media.lg`
      display: block;
    `}
  `}
`;

const RowContainer = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.silverSprings};
    border-radius: 12px;
    margin-bottom: 8px;
    height: 36px;
    padding: 4px;
    font-size: 14px;
    width: 100%;
    user-select: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
  `}
`;

const CollectionRow: React.FC<RowProps> = ({ collection, isDragDisabled }) => {
  const theme = useCurrentTheme();

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: collection.position.toString() });

  const style = {
    cursor: isDragging ? 'grabbing' : 'pointer',
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      key={collection.position}
      style={isDragDisabled ? {} : style}
      ref={isDragDisabled ? null : setNodeRef}
      {...(isDragDisabled ? {} : attributes)}
      {...(isDragDisabled ? {} : listeners)}
    >
      <RowContainer>
        <IconContainer>
          <StreamlineIcon
            icon={LayoutCornersDashboard1}
            size={11}
            stroke={theme.colors.blueSmoke}
          />
        </IconContainer>
        <TitleContainer>
          <EventCollectionLink
            eventCollection={collection}
            truncateLength={40}
          />
        </TitleContainer>
        <SlugContainer>{collection.cachedSlug}</SlugContainer>
        <UpcomingEventsCountContainer>
          {collection.upcomingEventsInCityCount}
        </UpcomingEventsCountContainer>
      </RowContainer>
    </div>
  );
};

export default CollectionRow;
