import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { Venue } from 'app/typings/venues';
import { get } from 'app/shared/utils/get';
import Typeahead from 'app/shared/components/molecules/Typeahead';
import {
  venueNameAndAddress,
  venueNameOrAddress,
} from 'app/admin/utils/venues';
import { GetVenuesForVenueTypeahead } from 'app/admin/graphql/venues/queryHooks';

interface Props {
  citySlugs?: string[];
  setSelectedVenue: (venue: Venue | undefined) => void;
  prepopulatedTypeaheadSearchString?: string;
  showVenueAddressAndCity?: boolean;
}

const VenueTypeahead: React.FC<Props> = ({
  citySlugs,
  setSelectedVenue,
  prepopulatedTypeaheadSearchString,
  showVenueAddressAndCity = true,
}) => {
  const intl = useIntl();

  const [typeaheadSearchString, setTypeaheadSearchString] = useState(
    prepopulatedTypeaheadSearchString || ''
  );
  const [isOpen, setIsOpen] = useState(false);

  const {
    loading: loadingVenues,
    error: errorVenues,
    data: dataVenues,
  } = GetVenuesForVenueTypeahead({
    venueSearch: typeaheadSearchString,
    city: citySlugs && citySlugs.join(','),
    page: 1,
    perPage: 50,
  });

  const venues = get(dataVenues, 'venues.venues', []);
  const options = venues.map((venue: Venue) => {
    return {
      id: venue.id,
      title: showVenueAddressAndCity
        ? venueNameAndAddress(venue)
        : venueNameOrAddress(venue),
    };
  });

  const onSearch = (searchString: string) => {
    if (searchString.trim() == '') {
      setSelectedVenue(undefined);
      setTypeaheadSearchString('');
      setIsOpen(false);
    } else if (searchString !== typeaheadSearchString) {
      setTypeaheadSearchString(searchString);
      setIsOpen(true);
    }
  };

  if (errorVenues) {
    return null;
  }

  const getSelectedVenue = (option: any) =>
    venues.find((v: Venue) => v.id == option.id);

  return (
    <>
      <Typeahead
        name="venueTypeahead"
        getOptionLabel={(option: any) => {
          return option.title;
        }}
        realTimeSearch={true}
        options={options}
        value={typeaheadSearchString}
        placeholder={intl.formatMessage({
          id: 'form.fields.placeholder.pleaseTypeVenueNameOrAddress',
        })}
        isLoading={loadingVenues}
        // @ts-ignore
        handleOptionClick={(value: string | number, option: any) => {
          setSelectedVenue(getSelectedVenue(option));
          setTypeaheadSearchString(option.title);
          setIsOpen(false);
        }}
        onSearch={onSearch}
        isTypeaheadOpen={isOpen}
      />
    </>
  );
};

export default VenueTypeahead;
