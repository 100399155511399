import React from 'react';

import { ContactInfo } from 'app/typings/contactInfos';
import { get } from 'app/shared/utils/get';
import { None } from 'app/admin/components/atoms/None';
import ContactInfoCard from 'app/admin/components/molecules/ContactInfoCard';

interface Props {
  contactInfos?: ContactInfo[];
}

export const OtherContactInfos: React.FC<Props> = ({ contactInfos }) => {
  const none = <None />;

  if (!contactInfos) {
    return none;
  }

  const otherContactInfos = contactInfos.filter(
    item => item.position == 2 || item.position == 3
  );

  if (otherContactInfos.length == 0) {
    return none;
  }

  return (
    <>
      {otherContactInfos.map((contactInfo: any, index: number) => {
        const contactRelationship = get(
          contactInfo,
          'contactRelationship.name',
          undefined
        );
        const firstName = get(contactInfo, 'firstName', undefined);
        const lastName = get(contactInfo, 'lastName', undefined);
        const email = get(contactInfo, 'email', undefined);
        const phoneNumber = get(contactInfo, 'phoneNumber', undefined);
        const isDayOfShowContact = get(
          contactInfo,
          'isDayOfShowContact',
          undefined
        );

        return (
          <React.Fragment key={index}>
            <ContactInfoCard
              firstName={firstName}
              lastName={lastName}
              email={email}
              phoneNumber={phoneNumber}
              contactRelationship={contactRelationship}
              isDayOfShowContact={isDayOfShowContact}
            />
          </React.Fragment>
        );
      })}
    </>
  );
};
