import React from 'react';

import { isEnvironmentProdOrLocal } from 'app/lib/environmentChecker';
import { FlexDivWithSpacing } from 'app/shared/components/atoms/FlexDivWithSpacing';
import { Logo } from 'app/shared/components/atoms/LogoManualCSS';

export interface Props {
  url: string;
  invertLogoByEnvironment?: boolean;
}

export const SofarLogo: React.FC<Props> = ({
  url,
  invertLogoByEnvironment = false,
}) => {
  return (
    <a href={url}>
      <FlexDivWithSpacing
        widthProp="68px"
        justifyContent="center"
        alignItems="flex-start"
        alignContent="center"
        mr={[0, 0, 0, 5, 5]}
      >
        <Logo
          alt="Sofar Sounds home"
          invert={invertLogoByEnvironment && isEnvironmentProdOrLocal()}
        />
      </FlexDivWithSpacing>
    </a>
  );
};
