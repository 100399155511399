import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { errorMsgForField } from 'app/shared/utils/form';
import {
  FormGroupContainer,
  FormSectionTitle,
} from 'app/shared/components/atoms/FormContent';
import GenericFormContainer from 'app/shared/components/atoms/GenericFormContainer';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Textarea } from 'app/shared/components/atoms/Textarea';
import { Textfield } from 'app/shared/components/atoms/Textfield';
import FormGroup from 'app/shared/components/molecules/FormGroup';

interface FormProps {
  formikProps: any;
  validationErrors?: object;
}

const StyledTextfield = styled(Textfield)`
  ${({ theme }) => css`
    display: block;
    width: 100%;
    ${theme.media.lg`
        width: 48%;
      `};
  `}
`;

const StyledTextarea = styled(Textarea)`
  display: block;
  width: 100%;
  height: 200px;
`;

const PartnerEditForm: React.FC<FormProps> = ({
  formikProps,
  validationErrors = {},
}) => {
  const intl = useIntl();

  const { setFieldValue, touched, errors, values } = formikProps;

  return (
    <GenericFormContainer dataQaId="partner-edit-form">
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'shared.title',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="partner-edit-form-title-formgroup"
          label={intl.formatMessage({
            id: 'shared.title',
          })}
          errorMsg={
            errorMsgForField('title', touched, errors) ||
            // @ts-ignore
            validationErrors.title
          }
          required
        >
          <StyledTextfield
            data-qaid="partner-edit-form-title-field"
            id="title"
            name="title"
            value={values.title}
            onChange={(e: any) => {
              setFieldValue('title', e.target.value);
            }}
            maxLength={80}
          />
        </FormGroup>
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.partnerEdit.form.description',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          label={intl.formatMessage({
            id: 'admin.partnerEdit.form.description',
          })}
          data-qaid="partner-edit-form-description-formgroup"
          errorMsg={errorMsgForField('partnerId', touched, errors)}
        >
          <StyledTextarea
            data-qaid="partner-edit-form-description-field"
            id="description"
            name="description"
            value={values.description}
            onChange={(e: any) => {
              setFieldValue('description', e.target.value);
            }}
            maxLength={2000}
          />
        </FormGroup>
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'shared.address',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="partner-edit-form-address-formgroup"
          label={intl.formatMessage({
            id: 'shared.address',
          })}
          errorMsg={
            errorMsgForField('address', touched, errors) ||
            // @ts-ignore
            validationErrors.address
          }
        >
          <StyledTextfield
            data-qaid="partner-edit-form-address-field"
            id="address"
            name="address"
            value={values.address}
            onChange={(e: any) => {
              setFieldValue('address', e.target.value);
            }}
            maxLength={80}
          />
        </FormGroup>
        <Spacer mb={4} />
        <FormGroup
          data-qaid="partner-edit-form-address2-formgroup"
          label={intl.formatMessage({
            id: 'admin.partnerEdit.form.address2',
          })}
          errorMsg={
            errorMsgForField('address2', touched, errors) ||
            // @ts-ignore
            validationErrors.address
          }
        >
          <StyledTextfield
            data-qaid="partner-edit-form-address2-field"
            id="address2"
            name="address2"
            value={values.address2}
            onChange={(e: any) => {
              setFieldValue('address2', e.target.value);
            }}
            maxLength={80}
          />
        </FormGroup>
      </FormGroupContainer>
    </GenericFormContainer>
  );
};

export default PartnerEditForm;
