import gql from 'graphql-tag';

export const GET_CONTACT_RELATIONSHIPS = gql`
  query GetContactRelationships($contactableType: String!) {
    contactRelationships(contactableType: $contactableType) {
      contactRelationships {
        id
        name
        contextModel
      }
    }
  }
`;
