import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { City } from 'app/typings/cities';
import { currencyFloatToInt } from 'app/shared/utils/currencyFormatter';
import { get } from 'app/shared/utils/get';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { CuratorGroupInfoForLimitingByCuratorGroup } from 'app/admin/utils/curatorGroupPermissions';
import { CreatePromoCodes } from 'app/admin/graphql/promoCodes/mutationHooks';
import { DetailsFormMainContainer } from 'app/admin/components/atoms/DetailContent';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

import PromoCodeBasicInfoForm from './PromoCodeBasicInfoForm';
import PromoCodeBasicInfoFormSchema from './PromoCodeBasicInfoFormSchema';
import { getCustomPricesSubmitVariable } from './Utils';

interface Props {
  contentProps?: any;
  setFormSubmitAction?: (func: Function) => void;
  setDisplayConfirmation?: (set: boolean) => void;
  setIsSubmitting?: (set: boolean) => void;
  navigateTo: (routeData: object) => void;
  hide: VoidFunction;
}

const formatDateForSubmit = (date: any) => date.format('YYYY-MM-DD');

const PromoCodeCreate: React.FC<Props> = ({
  contentProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  navigateTo,
  hide,
}) => {
  const intl = useIntl();

  const {
    curatorGroupIdsToLimitBy,
  } = CuratorGroupInfoForLimitingByCuratorGroup(
    'promoCode.accessByCuratorGroup'
  );

  const [validationErrors, setValidationErrors] = useState<object>({});

  const formInitialValues = {
    isNewPromoCode: true,
    numPromoCodes: '1',
    curatorGroup: undefined,
    hasCuratorGroupField: !!(
      curatorGroupIdsToLimitBy && curatorGroupIdsToLimitBy.length > 0
    ),
    campaignId: null,
    type: null,
    codePrefix: '',
    includePostfix: true,
    generationReasonId: null,
    percentageDiscount: '',
    customPricingNumTickets: '',
    customPricingPriceForTickets: '',
    customPricingEntries: {},
    validFrom: null,
    expiresAt: null,
    hasUnlimitedUses: true,
    hasUnlimitedUsesPerUser: false,
    maxUses: '',
    maxUsesPerUser: '1',
    ruleTypes: [],
    ruleDayOfWeek: '',
    ruleDaysOfWeek: [],
    ruleCity: null,
    ruleCities: [],
    ruleCountry: null,
    ruleEvent: null,
    ruleEventType: '',
    ruleEventOwnerTypes: [],
    rulePurchaserType: '',
  };

  const createPromoCodesAction = CreatePromoCodes();

  const handleCreatePromoCode = useSubmitAction({
    submitAction: createPromoCodesAction,
    submitVariables: (values: any) => ({
      numPromoCodes: Number(values.numPromoCodes),
      curatorGroupId: values.curatorGroup ? values.curatorGroup.id : undefined,
      campaignId: values.campaignId,
      type: values.type,
      codePrefix: values.codePrefix,
      includePostfix: values.includePostfix,
      generationReasonId: values.generationReasonId,
      percentageDiscount: currencyFloatToInt(values.percentageDiscount),
      customPrices: getCustomPricesSubmitVariable(values.customPricingEntries),
      validFrom: formatDateForSubmit(values.validFrom),
      expiresAt: formatDateForSubmit(values.expiresAt),
      maxUses: values.maxUses ? Number(values.maxUses) : null,
      maxUsesPerUser: values.maxUsesPerUser
        ? Number(values.maxUsesPerUser)
        : null,
      ruleTypes: values.ruleTypes,
      ruleDayOfWeek: values.ruleDayOfWeek,
      ruleDaysOfWeek: values.ruleDaysOfWeek,
      ruleCityId: values.ruleCity ? values.ruleCity.id : null,
      ruleCityIds: values.ruleCities.map((city: City) => city.id),
      ruleCountryId: values.ruleCountry ? values.ruleCountry.id : null,
      ruleEventId: values.ruleEvent ? values.ruleEvent.id : null,
      ruleEventType: values.ruleEventType,
      ruleEventOwnerTypes: values.ruleEventOwnerTypes,
      rulePurchaserType: values.rulePurchaserType,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.promoCodeCreate.successMessage',
    }),
    successMsgValuesForDisplay: {
      fullMessage: (values: any) =>
        Number(values.numPromoCodes) == 1
          ? intl.formatMessage({
              id: 'admin.promoCodeCreate.successMessageSingle',
            })
          : intl.formatMessage(
              {
                id: 'admin.promoCodeCreate.successMessageMultiple',
              },
              {
                numPromoCodes: values.numPromoCodes,
              }
            ),
    },
    failureMsg: intl.formatMessage({
      id: 'admin.promoCodeCreate.failureMessage',
    }),
    onSuccess: (response: any) => {
      const promoCodeId = get(
        response.data,
        'createPromoCodes.promoCode.id',
        undefined
      );
      if (promoCodeId) {
        navigateTo({
          routeName: 'promo-code-details',
          routeProps: {
            id: promoCodeId,
          },
        });
      } else {
        contentProps.refetchPromoCodes();
        hide();
      }
    },
    onValidationError: validationErrors =>
      setValidationErrors(validationErrors),
  });

  return (
    <ModalContentContainer>
      <DetailsFormMainContainer>
        <DetailsHeader
          title={intl.formatMessage({
            id: 'admin.promoCodeCreate.title',
          })}
          isNewForm={true}
        />
        <GenericForm
          formInitialValues={formInitialValues}
          renderFormComponent={(renderProps: any) => (
            <PromoCodeBasicInfoForm
              formikProps={renderProps.formikProps}
              validationErrors={validationErrors}
              curatorGroupIdsToLimitBy={curatorGroupIdsToLimitBy}
            />
          )}
          onSubmit={handleCreatePromoCode}
          formSchema={PromoCodeBasicInfoFormSchema(intl)}
          setFormSubmitAction={setFormSubmitAction}
          setDisplayConfirmation={setDisplayConfirmation}
          setIsSubmitting={setIsSubmitting}
          dataQaId="promo-code-create-form"
        />
      </DetailsFormMainContainer>
    </ModalContentContainer>
  );
};

export default PromoCodeCreate;
