import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { CreateCampaign } from 'app/admin/graphql/campaigns/mutationHooks';
import { DetailsFormMainContainer } from 'app/admin/components/atoms/DetailContent';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

import CampaignEditForm from './CampaignEditForm';
import CampaignEditFormSchema from './CampaignEditFormSchema';

interface Props {
  contentProps?: any;
  setFormSubmitAction?: (func: Function) => void;
  setDisplayConfirmation?: (set: boolean) => void;
  setIsSubmitting?: (set: boolean) => void;
  hide: VoidFunction;
}

const CampaignCreate: React.FC<Props> = ({
  contentProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  hide,
}) => {
  const intl = useIntl();

  const [validationErrors, setValidationErrors] = useState<object>({});

  const formInitialValues = {
    title: '',
    partnerId: null,
    agencyId: null,
    accountManagerUser: undefined,
    accountManagerUserIdToAssociate: undefined,
    unassociateAccountManagerUserId: false,
    salesRepUser: undefined,
    salesRepUserIdToAssociate: undefined,
    unassociateSalesRepUserId: false,
  };

  const createCampaignAction = CreateCampaign();

  const handleCreateCampaign = useSubmitAction({
    submitAction: createCampaignAction,
    submitVariables: (values: any) => ({
      title: values.title,
      partnerId: values.partnerId,
      agencyId: values.agencyId,
      accountManagerUserId: values.accountManagerUserIdToAssociate,
      salesRepUserId: values.salesRepUserIdToAssociate,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.campaignCreate.successMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.campaignCreate.failureMessage',
    }),
    onSuccess: () => {
      contentProps.refetchCampaigns();
      hide();
    },
    onValidationError: validationErrors =>
      setValidationErrors(validationErrors),
  });

  return (
    <ModalContentContainer>
      <DetailsFormMainContainer>
        <DetailsHeader
          title={intl.formatMessage({
            id: 'admin.campaignCreate.title',
          })}
          isNewForm={true}
        />
        <GenericForm
          formInitialValues={formInitialValues}
          renderFormComponent={(renderProps: any) => (
            <CampaignEditForm
              formikProps={renderProps.formikProps}
              validationErrors={validationErrors}
              isNewCampaign={true}
            />
          )}
          onSubmit={handleCreateCampaign}
          formSchema={CampaignEditFormSchema(intl)}
          setFormSubmitAction={setFormSubmitAction}
          setDisplayConfirmation={setDisplayConfirmation}
          setIsSubmitting={setIsSubmitting}
          dataQaId="campaign-create-form"
        />
      </DetailsFormMainContainer>
    </ModalContentContainer>
  );
};

export default CampaignCreate;
