import React, { useState } from 'react';

import { City } from 'app/typings/cities';
import { get } from 'app/shared/utils/get';
import { GetCitiesLite } from 'app/shared/graphql/cities/queryHooks';
import Typeahead from 'app/shared/components/molecules/Typeahead';

interface Props {
  setSelectedCity: (city: City | undefined) => void;
  clearFieldOnSelect?: boolean;
  placeholder: string;
}

export const CityTypeahead: React.FC<Props> = ({
  setSelectedCity,
  clearFieldOnSelect = false,
  placeholder,
}) => {
  const [typeaheadSearchString, setTypeaheadSearchString] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const {
    loading: loadingCities,
    error: errorCities,
    data: dataCities,
  } = GetCitiesLite({
    citySearch: typeaheadSearchString,
  });

  const cities = get(dataCities, 'cities.cities', []);
  const options = cities.map((city: City) => {
    return { id: city.id, title: city.title };
  });

  const onSearch = (searchString: string) => {
    if (searchString.trim() == '') {
      setSelectedCity(undefined);
      setTypeaheadSearchString('');
      setIsOpen(false);
    } else if (searchString !== typeaheadSearchString) {
      setTypeaheadSearchString(searchString);
      setIsOpen(true);
    }
  };

  if (errorCities) {
    return null;
  }

  const getSelectedCity = (option: any) =>
    cities.find((c: City) => c.id == option.id);

  return (
    <>
      <Typeahead
        name="cityTypeahead"
        getOptionLabel={(option: any) => {
          return option.title;
        }}
        realTimeSearch={true}
        options={options}
        value={typeaheadSearchString}
        placeholder={placeholder}
        isLoading={loadingCities}
        // @ts-ignore
        handleOptionClick={(value: string | number, option: any) => {
          setSelectedCity(getSelectedCity(option));
          setTypeaheadSearchString(clearFieldOnSelect ? '' : option.title);
          setIsOpen(false);
        }}
        onSearch={onSearch}
        isTypeaheadOpen={isOpen}
        data-qaid="city-typeahead"
      />
    </>
  );
};

export default CityTypeahead;
