export const sortOptions = [
  {
    title: 'Most Recently Submitted',
    value: {
      by: 'created_at',
      direction: 'desc',
    },
  },
  {
    title: 'Least Recently Submitted',
    value: {
      by: 'created_at',
      direction: 'asc',
    },
  },
  {
    title: 'Applicant Name (A - Z)',
    value: {
      by: 'name',
      direction: 'asc',
    },
  },
  {
    title: 'Applicant Name (Z - A)',
    value: {
      by: 'name',
      direction: 'desc',
    },
  },
];

export const staticOptions = {
  archivedStatus: [
    {
      title: 'Archived',
      value: 'archived_and_not_approved',
    },
    {
      title: 'Approved',
      value: 'archived_and_approved',
    },
  ],
  capacityRanges: [
    {
      title: '20-49',
      value: '20-49',
    },
    {
      title: '50-99',
      value: '50-99',
    },
    {
      title: '100+',
      value: '100+',
    },
  ],
};
