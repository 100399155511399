import React, { useState } from 'react';
import loadable from '@loadable/component';
import styled, { css } from 'styled-components';

import { Spacer } from 'app/shared/components/atoms/Spacer';
import { H5 } from 'app/shared/components/atoms/TypographyManualCSS';
import RotateToggle from 'app/admin/components/atoms/RotateToggle';

const LabelList = loadable(() =>
  import('app/admin/components/molecules/LabelList')
);
const Label = loadable(() => import('app/admin/components/atoms/Label'));
const DropdownOptions = loadable(() =>
  import('app/admin/components/molecules/DropdownOptions')
);

interface Props {
  filterTitle: string;
  textSearchString: string | undefined | null;
  handleTextSearchLabelClose: Function | undefined;
  labelTitleMapping: any;
  dropdownOptionsInfoList: DropdownOptionsInfo[];
  filterState: any;
  handleRemoveFilter: (labelsToRemove: Map<string, string[]>) => void;
  handleFilterChange: Function;
  rewriteValue?: Function;
}

export interface DropdownOptionsInfo {
  filterName: string;
  dropdownParams: DropdownParams;
  filterDependentOn?: string;
}

interface DropdownParams {
  title: string;
  searchBar?: boolean;
  options: any;
  typeaheadFilterComponent?: React.ComponentType<any>;
  groupBy?: string;
  groupById?: string;
  groupNames?: any;
}

const FilterHeader = styled.div`
  ${({ theme }) => css`
    ${theme.media.xs`
      flex-direction: column;
      align-items: flex-start;
    `};
    ${theme.media.md`
      flex-direction: row;
      align-items: center;
    `};
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    min-height: ${theme.ruler[10]}px;
  `}
`;

const ShowFilters = styled.div`
  ${({ theme }) => css`
    margin: 0px;
    margin-right: -${theme.ruler[7]}px;
    cursor: pointer;
    display: flex;
    align-items: center;
  `}
`;

const InlineH5 = styled(H5)`
  margin-bottom: 0px;
  display: inline-block;
`;

const FilterLabels = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    margin-left: ${theme.ruler[9]}px;
  `}
`;

const DropdownOptionsContainer = styled.div`
  margin-left: 42px;
  margin-top: 12px;
  margin-bottom: 24px;
`;

const DropdownOptionsWrapper = styled.div`
  ${({ theme }) => css`
    margin-right: 8px;
    ${theme.media.xs`
      display: block;
    `}
    ${theme.media.md`
      display: inline-flex;
    `}
  `}
`;

const preloadComponents = () => {
  [LabelList, Label, DropdownOptions].map((component: any) =>
    component.preload()
  );
};

const renderDropdowns = (
  dropdownOptionsInfoList: DropdownOptionsInfo[],
  filterState: any,
  handleFilterChange: Function
) => {
  return dropdownOptionsInfoList.map(
    (dropdownOptionsInfo: DropdownOptionsInfo, i: number) => (
      <DropdownOptionsWrapper key={i}>
        <DropdownOptions
          selectedValues={filterState[dropdownOptionsInfo.filterName] || []}
          onApply={handleFilterChange(dropdownOptionsInfo.filterName)}
          {...dropdownOptionsInfo.dropdownParams}
          filterDependentOn={dropdownOptionsInfo.filterDependentOn || ''}
          dependentFilterSelectedValues={
            dropdownOptionsInfo.filterDependentOn &&
            filterState[dropdownOptionsInfo.filterDependentOn]
          }
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        />
      </DropdownOptionsWrapper>
    )
  );
};

const ListingFilter: React.FC<Props> = ({
  filterTitle,
  textSearchString,
  handleTextSearchLabelClose,
  labelTitleMapping,
  dropdownOptionsInfoList,
  filterState,
  handleRemoveFilter,
  handleFilterChange,
  rewriteValue,
}) => {
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [preloaded, setPreloaded] = useState(false);

  const onRemoveLabel = (key: string, label: string) => {
    const labelsToRemove = new Map<string, string[]>();
    labelsToRemove.set(key, [label]);

    dropdownOptionsInfoList.forEach(optionInfo => {
      if (
        optionInfo.filterDependentOn &&
        optionInfo.filterDependentOn === key
      ) {
        const groupById = optionInfo.dropdownParams.groupById;
        if (groupById) {
          const valuesToRemove: string[] = [];
          for (let option of optionInfo.dropdownParams.options) {
            if (option.fields[groupById] === label) {
              valuesToRemove.push(option.value);
            }
          }
          valuesToRemove.push(`${label}-select-all`);
          labelsToRemove.set(optionInfo.filterName, valuesToRemove);
        }
      } else if (optionInfo.filterDependentOn) {
        // To make sure select all is removed when any purple filter grouped option is removed
        const groupById = optionInfo.dropdownParams.groupById;
        if (groupById) {
          const valuesToRemove: string[] = labelsToRemove.get(key) || [];
          for (let option of optionInfo.dropdownParams.options) {
            if (option.value === label && valuesToRemove) {
              valuesToRemove.push(`${option.fields[groupById]}-select-all`);
            }
          }

          labelsToRemove.set(optionInfo.filterName, valuesToRemove);
        }
      }
    });
    handleRemoveFilter(labelsToRemove);
  };

  return (
    <>
      <FilterHeader>
        <ShowFilters
          data-qaid="listing-filter-display-button"
          onClick={() => {
            setShowFilterOptions(!showFilterOptions);
            if (!preloaded) {
              preloadComponents();
              setPreloaded(true);
            }
          }}
          id="show-filters-toggle"
        >
          <RotateToggle rotate={!showFilterOptions} />
          <InlineH5>{filterTitle}</InlineH5>
        </ShowFilters>
        <FilterLabels>
          {textSearchString && (
            <Label
              key="text-search"
              name="text-search"
              title={`"${textSearchString}"`}
              onClose={handleTextSearchLabelClose}
              closeButton
            />
          )}
          {labelTitleMapping && (
            <LabelList
              labelMapping={filterState}
              titleMapping={labelTitleMapping}
              closeButton
              removeLabel={onRemoveLabel}
              rewriteValue={rewriteValue}
            />
          )}
        </FilterLabels>
      </FilterHeader>

      {showFilterOptions && (
        <DropdownOptionsContainer>
          {renderDropdowns(
            dropdownOptionsInfoList,
            filterState,
            handleFilterChange
          )}
        </DropdownOptionsContainer>
      )}
      <Spacer mb={4} />
    </>
  );
};

export default ListingFilter;
