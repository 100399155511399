import React from 'react';

import useSelect from 'app/shared/utils/useSelect';

import Input, { ActionButtonColors } from './Input';
import Options, { NoOptionsOption, OptionsListProps } from './Options';

export enum SelectState {
  ready = 'ready',
  loading = 'loading',
  error = 'error',
}

export interface SelectProps<T> extends OptionsListProps<T> {
  // select props
  options: T[];
  onChange: (option: T) => void;

  // input props
  placeholder: string;
  defaultValue?: T | null;
  value?: T | null;
  id?: string;
  name?: string;
  hasError?: boolean;
  invertColor?: boolean;
  actionButtonColors?: ActionButtonColors;
  renderLeftIcon?: React.ReactNode;
  inputValue?: string;
  setInputValue?: React.Dispatch<React.SetStateAction<string>>;
  onOpen?: () => void;
  className?: string;
  noOptionsOption?: NoOptionsOption;

  // typeahead props
  searchable?: boolean;
  filterBy?: (option: T, query: string) => boolean;

  // recommended options
  recommendedOptions?: T[];
  getRecommendedOptionsTitle?: (options: T[]) => string;

  // most visited options
  mostVisitedOptions?: T[];
  getMostVisitedOptionsTitle?: (options: T[]) => string;

  // misc props
  initialWidth?: string;
  groupBy?: (option: T) => string;
  disableScrollWhenOpen?: boolean;
  keepInViewPort?: boolean;
  state?: SelectState;
  'data-qaid'?: string;
}

const Select = <T extends {}>({
  options: defaultOptions,
  recommendedOptions,
  getRecommendedOptionsTitle,
  mostVisitedOptions,
  getMostVisitedOptionsTitle,
  onChange,
  renderOption,
  getOptionLabel,
  placeholder,
  defaultValue,
  value,
  id,
  name,
  hasError,
  invertColor,
  actionButtonColors,
  renderLeftIcon,
  searchable = false,
  filterBy,
  groupBy,
  initialWidth,
  state = SelectState.ready,
  disableScrollWhenOpen = false,
  inputValue,
  setInputValue,
  onOpen,
  className,
  noOptionsOption,
  keepInViewPort = true,
  'data-qaid': qaId,
}: SelectProps<T>) => {
  const {
    selectRef,
    isOpen,
    onToggle,
    onOptionClick,
    inputProps,
    options,
  } = useSelect<T>({
    disableScrollWhenOpen,
    getOptionLabel,
    defaultValue,
    value,
    searchable,
    filterBy,
    defaultOptions,
    // @ts-ignore
    onChange,
    controlledInputValue: inputValue,
    setControlledInputValue: setInputValue,
  });

  function handleToggle() {
    if (!isOpen && onOpen) {
      onOpen();
    }
    onToggle(!isOpen);
  }

  return (
    <>
      <Input
        innerRef={selectRef}
        isOpen={isOpen}
        invertColor={invertColor}
        actionButtonColors={actionButtonColors}
        inputProps={{
          id,
          readOnly: !searchable,
          placeholder,
          name: `srch${(name || '') + Math.floor(Math.random() * 1000)}`, // String with the term "srch" and random number is necessary to prevent wonky Chrome autocomplete.
          ...inputProps,
        }}
        onToggle={handleToggle}
        renderLeftIcon={renderLeftIcon}
        hasError={hasError}
        state={state}
        initialWidth={initialWidth}
        data-qaid={qaId}
        className={className}
        name={name}
      />
      {isOpen && (
        <Options
          qaId={qaId}
          innerRef={selectRef}
          isOpen={isOpen}
          options={options}
          groupBy={groupBy}
          onOptionClick={onOptionClick}
          noOptionsOption={noOptionsOption}
          getOptionLabel={getOptionLabel}
          renderOption={renderOption}
          recommendedOptions={recommendedOptions}
          getRecommendedOptionsTitle={getRecommendedOptionsTitle}
          mostVisitedOptions={mostVisitedOptions}
          getMostVisitedOptionsTitle={getMostVisitedOptionsTitle}
          state={state}
          userIsSearching={!!inputProps.value}
          searchable={searchable}
          keepInViewPort={keepInViewPort}
        />
      )}
    </>
  );
};

export default Select;
