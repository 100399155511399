import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { BookingPreference } from 'app/typings';
import { Artist } from 'app/typings/artists';
import { City } from 'app/typings/cities';
import { errorMsgForField } from 'app/shared/utils/form';
import usePermission from 'app/shared/utils/usePermission';
import { Checkbox } from 'app/shared/components/atoms/CheckboxManualCSS';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import {
  FormGroupContainer,
  FormSectionTitle,
} from 'app/shared/components/atoms/FormContent';
import { BaseLink } from 'app/shared/components/atoms/LinkManualCSS';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Spinner } from 'app/shared/components/atoms/SpinnerManualCSS';
import { Textfield } from 'app/shared/components/atoms/Textfield';
import { Body2 } from 'app/shared/components/atoms/TypographyManualCSS';
import FormGroup from 'app/shared/components/molecules/FormGroup';
import CitySelectorManualCSS from 'app/shared/components/organisms/CitySelectorManualCSS';
import { GetArtistsForOfferCreate } from 'app/admin/graphql/artists/queryHooks';
import ArtistCircleThumbnailAndText from 'app/admin/components/molecules/ArtistCircleThumbnailAndText';
import ArtistTypeahead from 'app/admin/components/molecules/ArtistTypeahead';
import CircleThumbnailAndText from 'app/admin/components/molecules/CircleThumbnailAndText';

interface Props {
  setFieldValue: any;
  touched: any;
  errors: any;
  values: any;
  creatingOfferForSingleEvent: boolean;
}

const MainContainer = styled.div`
  padding: 30px 0px 10px 0px;
`;

const NoResultsMessage = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.darkSmoke};
  `}
`;

const PreselectedCity = styled.div`
  font-size: 14px;
  letter-spacing: 0.1px;
  padding: 0px;
`;

const WarningMessage = styled(Body2)`
  ${({ theme }) => css`
    color: ${theme.colors.redRedWine};
    font-style: italic;
  `}
`;

const StyledText = styled.div`
  font-size: 14px;
`;

const Link = styled(BaseLink)<{ disabled?: boolean }>`
  ${({ theme, disabled }) => css`
    cursor: ${disabled ? 'default' : 'pointer'};
    color: ${disabled ? theme.colors.macyGrey : theme.colors.green600};
    white-space: nowrap;
    max-width: 180px;
    display: inline-block;

    ${disabled &&
      css`
        pointer-events: none;
      `}
  `}
`;

const StyledCircleThumbnailAndText = styled(CircleThumbnailAndText)`
  display: flex;
  align-items: center;
`;

const OfferCreateFormStep1: React.FC<Props> = ({
  setFieldValue,
  touched,
  errors,
  values,
  creatingOfferForSingleEvent,
}) => {
  const intl = useIntl();
  const hasAccessEventsPermission = usePermission('event.list.access');
  const hasViewArtistsPermission = usePermission('artist.list.view');

  const MAX_NUM_RECOMMENDED_ARTISTS = 35;
  const RECOMMENDED_ARTISTS_PER_PAGE = 7;

  const [
    numDisplayedRecommendedArtists,
    setNumDisplayedRecommendedArtists,
  ] = useState(RECOMMENDED_ARTISTS_PER_PAGE);

  const isArtistAlreadySelected = (artist: Artist) =>
    values.artists.map((artist: Artist) => artist.id).includes(artist.id);

  const isArtistSpecificallyAvailableInCity = (artist: Artist) =>
    artist.bookingPreferences?.some(
      (bookingPreference: BookingPreference) =>
        bookingPreference.isAvailableOnCertainDates &&
        bookingPreference.city.title === values.city?.title
    );

  const {
    data: dataRecommendedArtists,
    loading: loadingRecommendedArtists,
    error: errorRecommendedArtists,
  } = GetArtistsForOfferCreate({
    availabilityType: 'generally_available',
    city: values.city?.cachedSlug || undefined,
    eligibility: 'discovery',
    eventScope: 'no_upcoming_events',
    excludeShouldNotBook: true,
    excludeNeedsReview: true,
    lastEventDateInCity: 'more_than_1_month_ago',
    lastOfferDate: 'more_than_3_months_ago',
    numPastEvents: '3_plus',
    bookingSettings: 'general_availability_without_legacy',
    page: 1,
    perPage: MAX_NUM_RECOMMENDED_ARTISTS,
    orderBy: 'random',
    skip: !values.city,
  });

  const addArtist = (artist: Artist | undefined) => {
    if (artist && !isArtistAlreadySelected(artist)) {
      setFieldValue('artists', [artist].concat(values.artists));
    }
  };

  const removeArtist = (artist: Artist) => {
    setFieldValue(
      'artists',
      values.artists.filter((a: Artist) => a.id !== artist.id)
    );
  };

  const removeRecommendedArtist = (artist: Artist) => {
    setFieldValue(
      'recommendedArtists',
      values.recommendedArtists.filter((a: Artist) => a.id !== artist.id)
    );
  };

  const displayMoreRecommendedArtists = () => {
    const remainingArtists =
      dataRecommendedArtists?.artists?.artists.length || 0;
    const artistsToDisplay = Math.min(
      remainingArtists,
      RECOMMENDED_ARTISTS_PER_PAGE
    );
    if (artistsToDisplay > 0) {
      setNumDisplayedRecommendedArtists(
        numDisplayedRecommendedArtists + artistsToDisplay
      );
    }
  };

  const renderRecommendedArtist = (artist: Artist) => {
    const availableLastMinute = (artist?.bookingPreferences || []).find(
      (pref: any) => pref.city.id === values.city?.id
    )?.isAvailableLastMinute;

    return (
      <StyledCircleThumbnailAndText
        data-qaid="recommended-artist-thumbnail"
        imageUrl={artist?.imageUrl}
        hasCheckbox={true}
        text={artist?.title}
        textSize="14px"
        subtext={
          <>
            <StyledText>{artist?.homeCity}</StyledText>
            {availableLastMinute && (
              <i>
                {intl.formatMessage({
                  id: 'artistProfile.bookingPreferences.availableLastMinute',
                })}
              </i>
            )}
            <StyledText>
              <i>
                {artist?.pastEventsCount !== undefined &&
                  artist?.citiesPlayedCount !== undefined &&
                  `${artist?.pastEventsCount} ${
                    artist?.pastEventsCount === 1
                      ? intl.formatMessage({
                          id: 'admin.offerCreate.form.concert',
                        })
                      : intl.formatMessage({
                          id: 'admin.offerCreate.form.concerts',
                        })
                  } / ${artist?.citiesPlayedCount} ${
                    artist?.citiesPlayedCount === 1
                      ? intl
                          .formatMessage({
                            id: 'admin.offerCreate.form.city',
                          })
                          .toLowerCase()
                      : intl.formatMessage({
                          id: 'admin.offerCreate.form.cities',
                        })
                  }`}
              </i>
            </StyledText>
          </>
        }
        linkUrl={
          artist && hasViewArtistsPermission
            ? `/admin/artists?artist_id=${artist?.id}`
            : undefined
        }
      />
    );
  };

  return (
    <>
      <DottedLine />
      <MainContainer>
        <FormSectionTitle>
          {intl.formatMessage({
            id: 'admin.offerCreate.form.name',
          })}
        </FormSectionTitle>
        <FormGroupContainer>
          <FormGroup
            data-qaid="offer-create-form-name-formgroup"
            label=""
            errorMsg={errorMsgForField('name', touched, errors)}
          >
            <Textfield
              data-qaid="offer-create-form-name-field"
              id="name"
              name="name"
              value={values.name}
              onChange={(e: any) => {
                setFieldValue('name', e.target.value);
              }}
              maxLength={100}
            />
          </FormGroup>
        </FormGroupContainer>
        <FormSectionTitle>
          {intl.formatMessage({
            id: 'admin.offerCreate.form.city',
          })}
        </FormSectionTitle>
        <FormGroupContainer>
          {creatingOfferForSingleEvent ? (
            <PreselectedCity>{values.city.title}</PreselectedCity>
          ) : (
            <FormGroup
              data-qaid="offer-create-form-select-city-formgroup"
              label={intl.formatMessage({
                id: 'admin.offerCreate.form.selectCity',
              })}
              errorMsg={errorMsgForField('city', touched, errors)}
            >
              <CitySelectorManualCSS
                parentContainer="offer-create-form-select-city-formgroup"
                data-qaid="offer-create-form-select-city"
                disableTopCities
                disableClosestCities
                initialValue={undefined}
                value={values.city}
                onChange={(city: City) => {
                  setFieldValue('city', city);
                  setFieldValue('events', []);
                }}
              />
            </FormGroup>
          )}
        </FormGroupContainer>
        <FormSectionTitle>
          {intl.formatMessage({
            id: 'admin.offerCreate.form.addedArtists',
          })}
        </FormSectionTitle>
        <FormGroupContainer>
          <FormGroup
            data-qaid="offer-create-form-select-artists-formgroup"
            label={intl.formatMessage({
              id: 'admin.offerCreate.form.addArtist',
            })}
            errorMsg={errorMsgForField('artists', touched, errors)}
          >
            <ArtistTypeahead
              eligibilities={['discovery']}
              setSelectedArtist={(artist: Artist | undefined) => {
                addArtist(artist);
              }}
              clearFieldOnSelect={true}
              showArtistEmails={true}
            />
          </FormGroup>
          {values.artists.length > 0 && (
            <>
              <Spacer mt={6} />
              {values.artists.map((artist: Artist, i: number) => (
                <React.Fragment key={i}>
                  {hasAccessEventsPermission && artist.needsReview && (
                    <>
                      <WarningMessage>
                        {intl.formatMessage({
                          id: 'admin.eventPlanner.artists.reviewArtistFeedback',
                        })}
                      </WarningMessage>
                      <Spacer mt={2} />
                    </>
                  )}
                  <ArtistCircleThumbnailAndText
                    artist={artist}
                    description={
                      isArtistSpecificallyAvailableInCity(artist) &&
                      values.city?.title
                    }
                    onRemove={removeArtist}
                  />
                  <Spacer mt={4} />
                </React.Fragment>
              ))}
            </>
          )}
        </FormGroupContainer>
        <FormSectionTitle>
          {intl.formatMessage({
            id: 'admin.offerCreate.form.recommendedArtists',
          })}
        </FormSectionTitle>
        <FormGroupContainer>
          <Spacer mt={2} />
          <div>
            {loadingRecommendedArtists && <Spinner />}
            {!loadingRecommendedArtists &&
            !errorRecommendedArtists &&
            //@ts-ignore
            dataRecommendedArtists?.artists?.artists?.length > 0 ? (
              <>
                {intl.formatMessage({
                  id: 'admin.offerCreate.form.recommendationsTitle',
                })}
                <Spacer mt={10} />
                {dataRecommendedArtists?.artists?.artists
                  .slice(0, numDisplayedRecommendedArtists)
                  .map((artist: Artist, i: number) => {
                    return (
                      <React.Fragment key={i}>
                        <Checkbox
                          id={`recommended-artist-${i}`}
                          name={`recommended-artist-${i}`}
                          onChange={(event: any) => {
                            if (event.target.checked) {
                              addArtist(artist);
                            } else {
                              removeArtist(artist);
                              removeRecommendedArtist(artist);
                            }
                          }}
                          checked={isArtistAlreadySelected(artist)}
                          data-qaid="recommended-artist-option"
                        >
                          {renderRecommendedArtist(artist)}
                        </Checkbox>
                        <Spacer mt={4} />
                      </React.Fragment>
                    );
                  })}
                <Spacer mt={10} />
                <Link
                  disabled={
                    //@ts-ignore
                    dataRecommendedArtists?.artists?.artists?.length -
                      numDisplayedRecommendedArtists <=
                    0
                  }
                  data-qaid="show-more-artist-offer-recommendations-button"
                  onClick={displayMoreRecommendedArtists}
                >
                  {intl.formatMessage({
                    id: 'admin.offerCreate.form.showMoreRecommendations',
                  })}
                </Link>
              </>
            ) : values.city && !loadingRecommendedArtists ? (
              <NoResultsMessage>
                {intl.formatMessage(
                  {
                    id: 'admin.offerCreate.form.noArtistResults',
                  },
                  {
                    city: values.city.title,
                  }
                )}
              </NoResultsMessage>
            ) : !loadingRecommendedArtists ? (
              <NoResultsMessage>
                {intl.formatMessage({
                  id: 'admin.offerCreate.form.selectACity',
                })}
              </NoResultsMessage>
            ) : (
              <></>
            )}
          </div>
        </FormGroupContainer>
      </MainContainer>
    </>
  );
};

export default OfferCreateFormStep1;
