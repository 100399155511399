import React, { MouseEvent, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { useAnalyticsContext } from 'app/shared/utils';
import { matchExternalUrl } from 'app/shared/utils/string';
import { AuthContext } from 'app/shared/context/Auth';
import { StreamlineIcon } from 'app/shared/components/atoms/StreamlineIcon';
import { ReactComponent as BackArrow } from 'icons/streamline-regular/interface-essential/navigate/navigation-left-circle.svg';
import { ReactComponent as Arrow } from 'icons/streamline-regular/interface-essential/navigate/navigation-right-circle-1.svg';

interface LinkWithArrowProps {
  children: any;
  href?: string;
  target?: string;
  inverted?: boolean;
  invertArrow?: boolean;
  iconSize?: number;
  openInNewTab?: boolean;
}

interface BaseLinkProps extends React.HTMLProps<HTMLAnchorElement> {
  iconSize?: number;
  isOpen?: boolean;
  isActive?: boolean;
  lastChild?: boolean;
  inverted?: boolean;
  invertArrow?: boolean;
  openInNewTab?: boolean;
}

function getLinkRel(
  openInNewTab: boolean,
  isExternalUrl: boolean,
  rel?: string
) {
  if (openInNewTab) {
    if (isExternalUrl) {
      // This prevents Reverse Tabnabbing
      // https://hackernoon.com/prevent-reverse-tabnabbing-attacks-with-proper-noopener-noreferrer-and-nofollow-attribution-z14d3zbh
      return 'noopener noreferrer';
    }
    return 'noopener';
  }
  return rel;
}

export const BaseLink: React.FC<BaseLinkProps> = ({
  iconSize,
  isOpen,
  isActive,
  lastChild,
  inverted,
  invertArrow,
  openInNewTab = false,
  children,
  href,
  onClick,
  target,
  rel,
  ...props
}) => {
  const { trackAnalyticsEvent } = useAnalyticsContext();
  const { visitorType } = useContext(AuthContext);
  const navigate = useNavigate();
  const anchorRef = useRef<HTMLAnchorElement>(null);

  const isExternalUrl = href ? matchExternalUrl(href) : false;
  const newRel = getLinkRel(openInNewTab, isExternalUrl, rel);
  const newTarget = openInNewTab ? '_blank' : target;

  function handleClick(event: MouseEvent<HTMLAnchorElement>) {
    const anchorNode = anchorRef.current;

    if (anchorNode) {
      trackAnalyticsEvent('Link Clicked', {
        location: anchorNode.baseURI,
        text: anchorNode.textContent,
        dataQaid: anchorNode.dataset.qaid,
        href,
        visitor_type: visitorType,
      });
    }

    if (onClick) {
      onClick(event);
    }

    // When navigating using the anchor tag's href, the user
    // goes to the next page before we can run the onClick callback and
    // track events. Preventing default behavior and navigating using react-router-dom
    // fixes this
    if (href && !isExternalUrl && newTarget !== '_blank') {
      event.preventDefault ? event.preventDefault() : null;
      // Need some time to track events before redirecting user.
      // Otherwise event is not tracked or pathname is incorrect
      setTimeout(() => {
        navigate(href || '');
      }, 500);
      return false;
    }

    return;
  }

  return (
    <a
      {...props}
      href={href}
      ref={anchorRef}
      onClick={handleClick}
      rel={newRel}
      target={newTarget}
      data-iconsize={iconSize}
      data-isopen={isOpen}
      data-isactive={isActive}
      data-lastchild={lastChild}
      data-inverted={inverted}
      data-invertarrow={invertArrow}
    >
      {children}
    </a>
  );
};

const withLinkStyle = () => css`
  ${({ theme }) => css`
    color: ${theme.colors.secondaryText};
    font-weight: ${theme.fontStyles.links.fontWeight};
    text-decoration: 'none';

    &:hover {
      cursor: pointer;
      text-decoration: underline;
      color: ${theme.colors.primaryHover};
    }
  `}
`;

const IconWrapper = styled.span<any>`
  ${({ theme, iconSize }) => css`
    margin-left: ${theme.ruler[2]}px;
    height: ${iconSize}px;
    display: flex;
  `}
`;

export const Link = styled(BaseLink)<{ inverted?: boolean }>`
  ${({ theme, inverted }) => css`
    ${withLinkStyle};

    ${inverted &&
      css`
        color: ${theme.colors.invertedSecondaryText};
      `}
  `}
`;

const sharedLinkStyles = css<{
  inverted?: boolean;
  invertArrow?: boolean;
  iconSize: number;
}>`
  ${({ theme, inverted, invertArrow, iconSize }) => css`
    display: flex;
    align-items: center;

    ${inverted &&
      css`
        color: ${theme.colors.invertedSecondaryText};
      `}

    &:hover {
      text-decoration: none;
      cursor: pointer;
    }

    &:hover > span > span > svg {
      background-color: ${theme.colors.primaryHover};
    }

    > span {
      height: ${iconSize}px;
    }

    > span > span {
      height: ${iconSize}px;
      display: flex;
    }

    > span > span > svg {
      border-radius: 50px;
      background-color: ${invertArrow ? 'transparent' : theme.colors.primary};
    }

    > span > span > svg > path {
      stroke: ${invertArrow ? theme.colors.primary : 'none'};
    }

    &:hover > span > span > svg > path {
      stroke: none;
    }

    > span > span > svg > path:first-of-type {
      stroke: ${theme.colors[invertArrow ? 'primary' : 'backToBlack']};
    }

    &:hover > span > span > svg > path:first-of-type {
      stroke: ${theme.colors.backToBlack};
    }
  `}
`;

const StyledLink = styled(Link)`
  ${sharedLinkStyles}
`;

const StyledBackArrowLink = styled(Link)`
  ${sharedLinkStyles}
  ${({ theme, invertArrow }) => css`
    &:hover > span > span > svg > path {
      stroke: ${theme.colors[invertArrow ? 'primary' : 'backToBlack']};
    }
    &:hover > span > span > svg > path:first-of-type {
      stroke: ${theme.colors.primaryHover};
    }
  `}
`;

export const LinkWithArrow: React.FC<LinkWithArrowProps> = ({
  children,
  iconSize = 20,
  ...props
}) => {
  return (
    <StyledLink iconSize={iconSize} {...props}>
      {children}
      <IconWrapper iconSize={iconSize}>
        <StreamlineIcon size={iconSize} icon={Arrow} />
      </IconWrapper>
    </StyledLink>
  );
};

export const BackArrowLink: React.FC<LinkWithArrowProps> = ({
  children,
  iconSize = 20,
  ...props
}) => {
  return (
    <StyledBackArrowLink iconSize={iconSize} {...props}>
      <IconWrapper iconSize={iconSize}>
        <StreamlineIcon size={iconSize} icon={BackArrow} />
      </IconWrapper>
      {children}
    </StyledBackArrowLink>
  );
};

export const AdminLink = styled(Link)`
  font-size: 14px;
  font-weight: normal;

  &:hover {
    text-decoration: none;
  }
`;

export const GenericLinkWrapper = styled(BaseLink)`
  ${({ theme }) => css`
    color: ${theme.colors.green600};
  `}
`;

interface GenericLinkProps {
  url: string;
  children: any;
}

export const GenericLink: React.FC<GenericLinkProps> = ({ url, children }) => {
  return (
    <GenericLinkWrapper href={url} openInNewTab={true}>
      {children}
    </GenericLinkWrapper>
  );
};
