import React from 'react';
import { useIntl } from 'react-intl';

import CrewEventSurveyCard, {
  CrewEventSurveyCardTitle,
} from './CrewEventSurveyCard';
import Rating from './Rating';

interface Props {
  val: number;
  onChange: (value: any) => void;
}

const CrewEventSurveyConcertRating: React.FC<Props> = ({ val, onChange }) => {
  const intl = useIntl();
  return (
    <CrewEventSurveyCard>
      <CrewEventSurveyCardTitle>
        {intl.formatMessage({
          id: 'admin.crewPortal.crewFeedback.overallFeeling',
        })}
      </CrewEventSurveyCardTitle>
      <Rating val={val} start={0} end={10} onChange={onChange} />
    </CrewEventSurveyCard>
  );
};

export default CrewEventSurveyConcertRating;
