import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { User } from 'app/typings/users';
import TruncatedByCharText from 'app/shared/components/atoms/TruncatedByCharText';
import {
  DetailHeading as Heading,
  DetailValueContainer as ValueContainer,
} from 'app/admin/components/atoms/DetailContent';
import { None, NoneWrapper } from 'app/admin/components/atoms/None';
import { UserLink } from 'app/admin/components/molecules/EntityLink';

interface FromEmailSettingsProps {
  useFromEmailForAllEmails?: boolean;
  fromEmail?: string;
  mainArtistOps?: User;
  mainCrewOps?: User;
  mainVenueOps?: User;
}

const UserContainer = styled.div`
  margin-bottom: 7px;
`;

const renderAssociatedUser = (user: User | undefined) => {
  if (user) {
    return (
      <UserContainer>
        <UserLink user={user} truncateLength={30} />
      </UserContainer>
    );
  } else {
    return <None />;
  }
};

// We could move out this  in the future. So it can be reused by other components -- Aug 23, 2022.
const NotApplicableNoneWrapper: React.FC = () => {
  const intl = useIntl();

  return (
    <NoneWrapper>
      {intl.formatMessage({
        id: 'admin.notApplicable',
      })}
    </NoneWrapper>
  );
};

const FromEmailSettings: React.FC<FromEmailSettingsProps> = ({
  useFromEmailForAllEmails,
  fromEmail,
  mainArtistOps,
  mainCrewOps,
  mainVenueOps,
}) => {
  const intl = useIntl();
  return (
    <>
      <Heading>
        {intl.formatMessage({
          id: useFromEmailForAllEmails
            ? 'admin.cityEmails.heading.fromEmailAll'
            : 'admin.cityEmails.heading.fromEmail',
        })}
      </Heading>
      <ValueContainer>
        {fromEmail ? (
          <TruncatedByCharText text={fromEmail} truncateLength={30} />
        ) : (
          <None />
        )}
      </ValueContainer>
      <Heading>
        {intl.formatMessage({
          id: 'admin.emailSection.heading.mainArtistOps',
        })}
      </Heading>
      <ValueContainer>
        {useFromEmailForAllEmails ? (
          <NotApplicableNoneWrapper />
        ) : (
          renderAssociatedUser(mainArtistOps)
        )}
      </ValueContainer>
      <Heading>
        {intl.formatMessage({
          id: 'admin.emailSection.heading.mainVenueOps',
        })}
      </Heading>
      <ValueContainer>
        {useFromEmailForAllEmails ? (
          <NotApplicableNoneWrapper />
        ) : (
          renderAssociatedUser(mainVenueOps)
        )}
      </ValueContainer>
      <Heading>
        {intl.formatMessage({
          id: 'admin.emailSection.heading.mainCrewOps',
        })}
      </Heading>
      <ValueContainer>
        {useFromEmailForAllEmails ? (
          <NotApplicableNoneWrapper />
        ) : (
          renderAssociatedUser(mainCrewOps)
        )}
      </ValueContainer>
    </>
  );
};

export default FromEmailSettings;
