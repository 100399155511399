import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { useTShirtSizeBreakpoint as useBreakpoint } from 'app/shared/utils/useBreakpoint';
import { CloseIcon } from 'app/shared/components/atoms/IconLibrary';
import { Popper } from 'app/shared/components/atoms/PopperManualCSS';
import { Portal } from 'app/shared/components/atoms/Portal';

export interface VerticalHorizontalProps {
  vertical?: string;
  horizontal?: string;
}

interface VerticalHorizontalBooleanProps {
  vertical?: boolean;
  horizontal?: boolean;
}

export interface Offset {
  vertical?: number;
  horizontal?: number;
}

const Trigger = styled.div`
  border: none;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  background-color: transparent;
`;

interface FlyoverProps {
  offset?: Offset;
  zIndex?: number;
  anchorRef?: React.RefObject<HTMLDivElement>;
  anchorOrigin?: VerticalHorizontalProps;
  containerRef?: React.RefObject<HTMLDivElement>;
  transformOrigin?: VerticalHorizontalProps;
  keepInViewPort?: VerticalHorizontalBooleanProps;
  width?: string;
  height?: string;
  triggerElement?: JSX.Element;
  renderLabel?: (arg?: any) => any;
  'data-qaid'?: string;
  id?: string;
  innerContent: JSX.Element;
  showCaret?: boolean;
  shouldFlyoverClose?: boolean;
  shouldFlyoverOpen?: boolean;
  onStateChange?: (isOpen: boolean) => void;
}

interface FlyoverContentProps {
  width?: string;
  height?: string;
  showCaret?: boolean;
}

const FlyoverContent = styled.div<FlyoverContentProps>`
  ${({ theme, width, showCaret }) => css`
    display: flex;
    justify-content: space-between;
    margin: 0;

    background-color: ${theme.colors.whiteDenim};
    
    ${theme.media.xs`
      position: fixed;
      margin: 1.75rem 1rem;
      left: 0;
      top: 0;
      margin: 0;
      width: 100vw;
      height: 100vh;
    `};

    ${theme.media.sm`
      position: static;
      border-radius: ${theme.borderRadius.large};
      box-shadow: 5px 5px 28px 0 rgba(0, 0, 0, 0.25);
      width: ${width || 'auto'};
      overflow: hidden;
      height: auto;
    `};

    ${theme.media.md`
      overflow: auto;
    `};
    
    &:after {
      content: ' ';
      position: absolute;
      bottom: 90%;
      left: 50%;
      margin-left: -25px
      border-style: solid;
      visibility: ${showCaret ? 'visible' : 'hidden'};
      border-left: 25px solid transparent;
      border-right: 25px solid transparent;
      border-bottom: 25px solid ${theme.colors.whiteDenim};
      border-top: 25px solid transparent;
    }
  `}
`;

const CloseButton = styled.div`
  position: absolute;
  cursor: pointer;
  width: 25px;
  right: 12px;
  top: 12px;
`;

const FullWidth = styled.div`
  width: 100%;
`;

const Flyover: React.FC<FlyoverProps> = ({
  triggerElement,
  renderLabel,
  anchorOrigin = {
    vertical: 'top',
    horizontal: 'right',
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'left',
  },
  keepInViewPort = {
    vertical: false,
    horizontal: true,
  },
  width,
  height,
  'data-qaid': qaId,
  id,
  innerContent,
  offset,
  zIndex,
  anchorRef,
  containerRef,
  showCaret,
  onStateChange,
  shouldFlyoverClose,
  shouldFlyoverOpen,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<any>();
  const boxRef = useRef<any>();
  const currentBreakpoint = useBreakpoint();
  const isMobile = currentBreakpoint === 'xs' || currentBreakpoint === 'sm';
  const zIndexPopper = isMobile ? 110 : zIndex;

  useEffect(() => {
    if (isMobile && isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isMobile, isOpen]);

  useEffect(() => {
    if (shouldFlyoverClose === true) {
      setIsOpen(false);
      onStateChange && onStateChange(false);
    }
    if (shouldFlyoverOpen === true) {
      setIsOpen(true);
      onStateChange && onStateChange(true);
    } else if (shouldFlyoverOpen === false) {
      setIsOpen(false);
      onStateChange && onStateChange(false);
    }
  }, [onStateChange, shouldFlyoverClose, shouldFlyoverOpen]);

  useEffect(() => {
    const handleDocumentClick = (event: Event) => {
      if (
        boxRef.current &&
        !boxRef.current.contains(event.target as Node) &&
        !(event.target as HTMLElement).classList.contains(
          'suppress-outside-click'
        ) &&
        // the below is needed for tinymce rte hyperlinks creation popups to work correctly within flyovers
        !(event.target as HTMLElement).classList.value
          .split('-')
          .join(' ')
          .startsWith('tox')
      ) {
        setIsOpen(false);
        onStateChange && onStateChange(false);
      }
    };
    document.addEventListener('mousedown', handleDocumentClick);
  }, [onStateChange]);

  return (
    <>
      <Trigger
        id={id}
        ref={ref}
        onClick={() => {
          const toggledIsOpen = !isOpen;
          setIsOpen(toggledIsOpen);
          onStateChange && onStateChange(toggledIsOpen);
        }}
        data-qaid={qaId}
      >
        {renderLabel ? renderLabel(isOpen) : triggerElement}
      </Trigger>

      {isOpen && (
        <Portal dom={document.body}>
          <Popper
            offset={offset}
            anchorEl={anchorRef || ref}
            containerEl={containerRef}
            anchorOrigin={anchorOrigin || {}}
            transformOrigin={transformOrigin}
            keepInViewPort={keepInViewPort}
            flip={false}
            zIndex={zIndexPopper || 90}
          >
            <FlyoverContent
              ref={boxRef}
              width={width || '340px'}
              height={height || 'auto'}
              data-qaid={`${qaId}-flyout`}
              showCaret={showCaret}
              className="suppress-modal-navigation-outside-click"
            >
              <FullWidth className="suppress-modal-navigation-outside-click">
                {innerContent}
              </FullWidth>
              <CloseButton
                data-qaid="auth-modal-close-button"
                onClick={() => {
                  setIsOpen(false);
                  onStateChange && onStateChange(false);
                }}
              >
                <CloseIcon />
              </CloseButton>
            </FlyoverContent>
          </Popper>
        </Portal>
      )}
    </>
  );
};

export default Flyover;
