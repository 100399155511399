import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { Checkbox } from 'app/shared/components/atoms/CheckboxManualCSS';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import { Icon } from 'app/shared/components/atoms/IconManualCSS';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import {
  defaultIsDisabledState,
  handleOptionChange,
  isOptionSelected,
} from 'app/admin/utils/optionDependencies';

interface CheckboxGroupOption {
  title: string;
  value: any;
  key?: string;
  selected?: boolean;
  fields?: object;
  disabled?: boolean;
  iconName?: string;
}

interface Props {
  name: string;
  options: CheckboxGroupOption[];
  optionDependencies?: { [key: string]: string[] };
  selectedValues: any[];
  onChange(values: any[]): void;
  groupBy: string;
  groupNames: string[];
}

const CheckboxGroupContainer = styled.div`
  width: 100%;
`;

const CheckboxGroupHeader = styled.div`
  ${({ theme }) => css`
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    margin-bottom: ${theme.ruler[1]}px;
  `}
`;

const CheckboxContainer = styled.div<{ isLastCheckboxOfLastGroup: boolean }>`
  ${({ theme, isLastCheckboxOfLastGroup }) => css`
    padding: ${isLastCheckboxOfLastGroup
      ? `${theme.ruler[3]}px 0px 0px 0px`
      : `${theme.ruler[3]}px 0px`};
  `}
`;

const StyledCheckbox = styled(Checkbox)`
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  align-items: flex-start;
  white-space: nowrap;
`;

const CheckboxIcon = styled.span`
  margin-top: -2px;
`;

const ColoredIcon = styled(Icon)<{ greyedOut: boolean }>`
  ${({ theme, greyedOut }) => css`
    &::before {
      color: ${greyedOut ? theme.colors.blueSmoke : '#98035a'};
    }
  `}
`;

const CheckboxText = styled.span<{ greyedOut: boolean }>`
  ${({ theme, greyedOut }) => css`
    ${greyedOut && `color: ${theme.colors.blueSmoke};`}
    margin-top: -21px;
    margin-left: 8px;
  `}
`;

const groupArray = function(arr: CheckboxGroupOption[], key: string) {
  return arr.reduce(function(vals: any, obj: CheckboxGroupOption) {
    if (obj.fields && obj.fields[key]) {
      (vals[obj.fields[key]] = vals[obj.fields[key]] || []).push(obj);
    }
    return vals;
  }, {});
};

const CheckboxGroupGrouped: React.FC<Props> = ({
  name,
  options,
  optionDependencies,
  selectedValues,
  onChange,
  groupBy,
  groupNames,
}) => {
  const [isDisabled, setIsDisabled] = useState<object>(
    defaultIsDisabledState(options, optionDependencies, selectedValues)
  );

  if (options.length === 0) {
    return null;
  }

  const handleChange = (opt: CheckboxGroupOption) => {
    return (event: any) => {
      handleOptionChange(
        options,
        optionDependencies,
        selectedValues,
        isDisabled,
        setIsDisabled,
        onChange,
        event.target.checked,
        opt
      );
    };
  };

  const isChecked = (opt: CheckboxGroupOption) =>
    isOptionSelected(selectedValues, opt);

  const renderOptions = (
    options: CheckboxGroupOption[],
    name: string,
    isLastGroup: boolean
  ) => {
    return options.map((opt: CheckboxGroupOption, index: number) => (
      <CheckboxContainer
        key={index}
        isLastCheckboxOfLastGroup={isLastGroup && index + 1 === options.length}
      >
        <StyledCheckbox
          id={`${name}-option-${index}`}
          name={`${name}-option-${index}`}
          onChange={handleChange(opt)}
          checked={isChecked(opt)}
          disabled={isDisabled[opt.value]}
        >
          {opt.iconName ? (
            <>
              <CheckboxIcon>
                <ColoredIcon
                  name={opt.iconName}
                  size="16px"
                  greyedOut={isDisabled[opt.value]}
                />
              </CheckboxIcon>
              <CheckboxText greyedOut={isDisabled[opt.value]}>
                {opt.title}
              </CheckboxText>
            </>
          ) : (
            opt.title
          )}
        </StyledCheckbox>
      </CheckboxContainer>
    ));
  };

  const renderGroupedOptions = (
    options: CheckboxGroupOption[],
    groupBy: string,
    groupNames?: string[]
  ) => {
    const groupedOptions = groupArray(options, groupBy);
    const sortedGroups = Object.entries(groupedOptions).sort((a: any, b: any) =>
      groupNames
        ? groupNames.indexOf(a[0]) - groupNames.indexOf(b[0])
        : a[0].localeCompare(b[0])
    );

    return sortedGroups.map((group: any, index: number) => (
      <React.Fragment key={index}>
        <CheckboxGroupHeader data-qaid="group-header">
          {group[0]}
        </CheckboxGroupHeader>
        {renderOptions(
          group[1],
          `group-${group[0]}`,
          index + 1 === sortedGroups.length
        )}
        {index + 1 < sortedGroups.length && (
          <>
            <Spacer mb={3} />
            <DottedLine />
            <Spacer mb={4} />
          </>
        )}
      </React.Fragment>
    ));
  };

  return (
    <CheckboxGroupContainer data-qaid={`${name}-checkbox-group-container`}>
      {renderGroupedOptions(options, groupBy, groupNames)}
    </CheckboxGroupContainer>
  );
};

export default CheckboxGroupGrouped;
