import React, { useContext, useEffect, useState } from 'react';
import { FormattedHTMLMessage, useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { Artist } from 'app/typings/artists';
import { EventPlannerEvent } from 'app/typings/events';
import { PerformanceOffer, PerformanceOfferStatus } from 'app/typings/offers';
import { CreatePerformanceData, Performance } from 'app/typings/performances';
import { useCurrentTheme } from 'app/shared/theme';
import { useAnalyticsContext } from 'app/shared/utils';
import { DISCOVERY_EVENT } from 'app/shared/utils/events';
import { get } from 'app/shared/utils/get';
import useModal from 'app/shared/utils/useModal';
import usePermission from 'app/shared/utils/usePermission';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import { ListPageContext } from 'app/shared/context/ListPage';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import { ContentWrapper } from 'app/shared/components/atoms/FlyoverContent';
import { FlyoverNote } from 'app/shared/components/atoms/FlyoverContent';
import { BaseLink } from 'app/shared/components/atoms/LinkManualCSS';
import { Radio } from 'app/shared/components/atoms/Radio';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Spinner } from 'app/shared/components/atoms/SpinnerManualCSS';
import { StreamlineIcon } from 'app/shared/components/atoms/StreamlineIcon';
import {
  Body2,
  Overline,
} from 'app/shared/components/atoms/TypographyManualCSS';
import RoutableModal from 'app/shared/components/molecules/RoutableModal';
import { GetArtistsForAddArtistToEvent } from 'app/admin/graphql/artists/queryHooks';
import { CreatePerformance } from 'app/admin/graphql/performances/mutationHooks';
import { None } from 'app/admin/components/atoms/None';
import ArtistTypeahead from 'app/admin/components/molecules/ArtistTypeahead';
import CircleThumbnailAndText from 'app/admin/components/molecules/CircleThumbnailAndText';
import FlyoverFooter from 'app/admin/components/molecules/FlyoverFooter';
import { ReactComponent as Envelope } from 'icons/streamline-regular/emails/envelopes/envelope.svg';
import { ReactComponent as SocialProfileClick } from 'icons/streamline-regular/social-medias-rewards-rating/social-profile/social-profile-click.svg';

interface Props {
  displayArtistBookingAvailability?: boolean;
  event: EventPlannerEvent;
  hasArtist?: boolean;
  handleFlyoverClose?: VoidFunction;
  onSuccess: any;
  performance?: Performance;
  performances?: Performance[];
  performanceOffersForEvent?: PerformanceOffer[];
  position?: number;
  searchMethod?: string;
  setIsSubmitting: (arg: boolean) => void;
  setSearchMethod: (arg: string) => void;
  updateArtistState: (arg: string) => void;
}

const StyledText = styled.div`
  font-size: 12px;
`;

const RadioLabel = styled.span`
  font-size: 12px;
`;

const Link = styled(BaseLink)`
  ${({ theme }) => css`
    color: ${theme.colors.green600};
    font-size: 12px;
  `}
`;

const DisabledLink = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.macyGrey};
    font-size: 12px;
  `}
`;

const WarningMessage = styled(Body2)`
  ${({ theme }) => css`
    color: ${theme.colors.redRedWine};
    font-size: 12px !important;
    font-style: italic;
  `}
`;

const StyledIcon = styled.span`
  margin-right: 12px;
`;

const LinkWrapper = styled(BaseLink)<{ disabled: boolean }>`
  ${({ disabled }) => css`
    cursor: ${disabled ? undefined : 'pointer'};
  `}

  display: block;
  padding-left: 1em;
  text-indent: -1.6em;
  margin-left: 15px;
`;

const AddArtist: React.FC<Props> = ({
  event,
  handleFlyoverClose,
  hasArtist,
  onSuccess,
  performance,
  performances,
  performanceOffersForEvent,
  position,
  searchMethod,
  setIsSubmitting,
  setSearchMethod,
  updateArtistState,
}) => {
  const intl = useIntl();
  const theme = useCurrentTheme();
  const pageState = useContext(ListPageContext);
  const { trackAnalyticsEvent } = useAnalyticsContext();
  const hasViewArtistsPermission = usePermission('artist.list.view');
  const hasAccessEventsPermission = usePermission('event.list.access');
  const [createOfferModal, toggleCreateOfferModal] = useModal();
  const [selectedArtist, setSelectedArtist] = useState(
    performance && performance.artist
  );
  const { id: cityId, cachedSlug: citySlug, title: cityName } = event.city;
  const { localStartsAt } = event;

  const [availabilityType, setAvailabilityType] = useState<string | undefined>(
    undefined
  );

  const hasViewOffersPermission = usePermission('artistOffer.list.view');

  const tagLimitingArtistsForCuratorEvents = 'Booking Paused';
  const disableCreateInviteForAllArtists =
    performance?.artist ||
    performanceOffersForEvent?.some(
      (perfOffer: PerformanceOffer) =>
        perfOffer.status === PerformanceOfferStatus.OPEN &&
        perfOffer.position === position
    );

  const filterAvailabilityArtists = (artists: Artist[] | undefined) => {
    return artists?.filter(
      (artist: any) =>
        !performances ||
        (!performances.some(
          (performance: any) => performance?.artist?.id === artist?.id
        ) &&
          !performanceOffersForEvent?.some(
            (performanceOffer: any) =>
              (performanceOffer?.status !== PerformanceOfferStatus.CLOSED ||
                performanceOffer?.status !== PerformanceOfferStatus.BOOKED) &&
              performanceOffer?.artist?.id === artist?.id
          ))
    );
  };

  const {
    loading: loadingGeneralArtists,
    data: dataGeneralArtists,
    error: errorGeneralArtists,
  } = GetArtistsForAddArtistToEvent({
    availabilityType: 'generally_available',
    availableForDate: localStartsAt,
    city: citySlug,
    eligibility: event.type,
    eventScope: 'no_upcoming_events',
    excludeShouldNotBook: true,
    excludeNeedsReview: true,
    excludeDeclinedPerformanceOffersForEventId: event.id,
    lastEventDateInCity: 'more_than_1_month_ago',
    lastOfferDate: 'more_than_3_months_ago',
    numPastEvents: '3_plus',
    page: 1,
    perPage: 5,
    orderBy: 'random',
  });

  const availableGeneralArtists =
    (!errorGeneralArtists &&
      filterAvailabilityArtists(dataGeneralArtists?.artists?.artists)) ||
    [];

  const {
    loading: loadingSpecificDateArtists,
    data: dataSpecificDateArtists,
    error: errorSpecificDateArtists,
  } = GetArtistsForAddArtistToEvent({
    availabilityType: 'available_for_certain_dates',
    availableForDate: localStartsAt,
    city: citySlug,
    eligibility: event.type,
    excludeNeedsReview: true,
    page: 1,
    perPage: 20,
  });

  const availableSpecificDateArtists =
    (!errorSpecificDateArtists &&
      filterAvailabilityArtists(dataSpecificDateArtists?.artists?.artists)) ||
    [];

  const searchArtistsEligibilityParam =
    event.type === 'DiscoveryEvent' ? ['discovery'] : undefined;

  const searchArtistsNotTagParam =
    !hasAccessEventsPermission &&
    (event.eventOrganizedAs === 'curator_owner_commercial_event' ||
      event.eventOrganizedAs === 'curator_owner_noncommercial_event')
      ? tagLimitingArtistsForCuratorEvents
      : undefined;

  const renderAvailableArtist = (artist: Artist) => {
    const availableLastMinute = (artist?.bookingPreferences || []).find(
      (pref: any) => pref.city.id === cityId
    )?.isAvailableLastMinute;
    return (
      <CircleThumbnailAndText
        data-qaid="available-artist-thumbnail"
        imageUrl={artist?.imageUrl}
        text={artist?.title}
        subtext={
          <>
            <StyledText>{artist?.homeCity}</StyledText>
            {availableLastMinute && (
              <StyledText>Available last minute</StyledText>
            )}
            <StyledText>
              <i>
                {artist?.pastEventsCount !== undefined &&
                  artist?.citiesPlayedCount !== undefined &&
                  `${artist?.pastEventsCount} ${
                    artist?.pastEventsCount === 1 ? 'concert' : 'concerts'
                  } / ${artist?.citiesPlayedCount} ${
                    artist?.citiesPlayedCount === 1 ? 'city' : 'cities'
                  }`}
              </i>
            </StyledText>
          </>
        }
        linkUrl={
          artist && hasViewArtistsPermission
            ? `/admin/artists?artist_id=${artist?.id}`
            : undefined
        }
        textSize="12px"
        titleText=""
      />
    );
  };

  const createPerformanceAction = CreatePerformance();
  const handleAddArtist = useSubmitAction({
    submitAction: createPerformanceAction,
    submitVariables: () => ({
      eventId: event.id,
      artistId: selectedArtist && selectedArtist.id,
      position,
      isArtistBookedAsGenerallyAvailable:
        availabilityType === 'generally_available',
      isArtistBookedAsAvailableOnCertainDates:
        availabilityType === 'available_on_certain_dates',
    }),
    successMsg: intl.formatMessage(
      {
        id: 'admin.eventPlanner.artists.addArtistSuccess',
      },
      {
        artist: selectedArtist && selectedArtist.title,
      }
    ),
    failureMsg: intl.formatMessage(
      {
        id: 'admin.eventPlanner.artists.addArtistFailure',
      },
      {
        artist: selectedArtist && selectedArtist.title,
      }
    ),
    onSuccess: (response: CreatePerformanceData) => {
      if (performance && performance.artistConfirmed) {
        trackAnalyticsEvent('Artist Performance Cancelled - Artist Changed', {
          eventId: event.id,
          performanceId: performance.id,
          artistId: performance.artist.id,
          isSelfBooked: performance.isArtistSelfBooked,
        });
      }

      const createdPerformance = get(
        response,
        'data.createPerformance.performance',
        {}
      );
      const updatedPerformanceOffers = get(
        response,
        'data.createPerformance.performanceOffers',
        {}
      );
      setIsSubmitting(false);
      updateArtistState('updateArtistSetup');
      onSuccess &&
        onSuccess(
          {
            performance: createdPerformance,
            offer: {
              performanceOffers: updatedPerformanceOffers,
            },
          },
          false,
          'updateArtistSetup',
          true
        );
    },
  });

  useEffect(() => {
    if (
      (searchMethod === 'availability' && availableGeneralArtists.length > 0) ||
      availableSpecificDateArtists.length > 0
    ) {
      trackAnalyticsEvent(
        'Artist Availability: Artist Availability Recommender',
        {
          eventId: event.id,
          generallyAvailableArtistIds: availableGeneralArtists.map(
            (artist: Artist) => artist.id
          ),
          specificDateAvailableArtistIds: availableSpecificDateArtists.map(
            (artist: Artist) => artist.id
          ),
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div data-qaid="add-artist-confirmation-flyover">
      <ContentWrapper>
        <Overline>
          <FormattedHTMLMessage
            id={
              !hasArtist
                ? 'admin.eventPlanner.artists.selectArtist'
                : 'admin.eventPlanner.artists.changeArtist'
            }
          />
        </Overline>
        <Spacer mt={2} />

        <Radio
          id="searchByName"
          value="active"
          name="searchByName"
          checked={searchMethod === 'name'}
          onChange={() => setSearchMethod('name')}
          data-qaid="add-artist-search-by-name-radio-button"
        >
          <RadioLabel>
            <FormattedHTMLMessage id="admin.eventPlanner.artists.searchByName" />
          </RadioLabel>
        </Radio>
        <Spacer mt={4} />

        {searchMethod === 'name' && (
          <>
            <Spacer mt={3} />
            <ArtistTypeahead
              eligibilities={searchArtistsEligibilityParam}
              notTag={searchArtistsNotTagParam}
              orderBy="similarity"
              orderDirection="desc"
              setSelectedArtist={(artist: Artist | undefined) => {
                setSelectedArtist(artist);
              }}
              showArtistEmails={false}
            />
          </>
        )}

        {searchMethod === 'name' &&
          selectedArtist?.needsReview &&
          hasAccessEventsPermission && (
            <>
              <Spacer mt={2} />
              <WarningMessage>
                {intl.formatMessage({
                  id: 'admin.eventPlanner.artists.reviewArtistFeedback',
                })}
              </WarningMessage>
            </>
          )}

        <Spacer mt={3} />
        {searchMethod === 'name' &&
          (selectedArtist && hasViewArtistsPermission ? (
            <Link
              href={`/admin/artists?artist_id=${selectedArtist.id}`}
              openInNewTab={true}
            >
              <StreamlineIcon
                icon={SocialProfileClick}
                stroke={theme.colors.green600}
                size={16}
              />
              &nbsp;
              <FormattedHTMLMessage id="admin.eventPlanner.artists.viewArtistProfile" />
            </Link>
          ) : (
            <DisabledLink>
              <StreamlineIcon
                icon={SocialProfileClick}
                stroke={theme.colors.macyGrey}
                size={16}
              />
              &nbsp;
              <FormattedHTMLMessage id="admin.eventPlanner.artists.viewArtistProfile" />
            </DisabledLink>
          ))}
        <Spacer mt={4} />
        <Radio
          id="selectFromAvailable"
          name="selectFromAvailable"
          disabled={event.type !== DISCOVERY_EVENT}
          checked={searchMethod === 'availability'}
          onChange={() => setSearchMethod('availability')}
          data-qaid="add-artist-search-by-availability-radio-button"
        >
          <RadioLabel>
            <FormattedHTMLMessage id="admin.eventPlanner.artists.selectFromAvailable" />
          </RadioLabel>
        </Radio>
        {searchMethod === 'availability' &&
          event.type === DISCOVERY_EVENT &&
          (loadingSpecificDateArtists || loadingGeneralArtists ? (
            <>
              <Spacer mt={4} />
              <Spinner />
            </>
          ) : !(availableGeneralArtists || availableSpecificDateArtists) ||
            !(
              availableGeneralArtists.length > 0 ||
              availableSpecificDateArtists.length > 0
            ) ? (
            <>
              <Spacer mt={4} />
              <None />
            </>
          ) : (
            <>
              {availableSpecificDateArtists?.length > 0 && (
                <>
                  <Spacer mt={4} />
                  <DottedLine />
                  <Spacer mt={4} />
                  <StyledText>
                    <i>
                      {intl.formatMessage({
                        id: 'admin.eventPlanner.artists.specificAvailability',
                      })}
                      :
                    </i>
                  </StyledText>
                  <Spacer mt={6} />
                  {availableSpecificDateArtists.map(
                    (artist: any, index: number) => (
                      <React.Fragment key={index}>
                        <Radio
                          id={`artist-${artist.id}`}
                          name={`artist-${artist.id}`}
                          checked={selectedArtist?.id === artist.id}
                          onChange={() => {
                            setSelectedArtist(artist);
                            setAvailabilityType('available_on_certain_dates');
                          }}
                          alignItems="center"
                          data-qaid={`add-artist-${artist.id}-radio-button`}
                        >
                          {renderAvailableArtist(artist)}
                        </Radio>
                        <Spacer mt={12} />
                      </React.Fragment>
                    )
                  )}
                </>
              )}
              {availableGeneralArtists?.length > 0 && (
                <>
                  <Spacer mt={4} />
                  <DottedLine />
                  <Spacer mt={4} />
                  <StyledText>
                    <i>
                      {intl.formatMessage(
                        {
                          id: 'admin.eventPlanner.artists.generalAvailability',
                        },
                        {
                          cityName,
                        }
                      )}
                      :
                    </i>
                  </StyledText>
                  <Spacer mt={6} />
                  {availableGeneralArtists.map((artist: any) => (
                    <>
                      <Radio
                        id={`artist-${artist.id}`}
                        name={`artist-${artist.id}`}
                        checked={selectedArtist?.id === artist.id}
                        onChange={() => {
                          setSelectedArtist(artist);
                          setAvailabilityType('generally_available');
                        }}
                        alignItems="center"
                        data-qaid={`add-artist-${artist.id}-radio-button`}
                      >
                        {renderAvailableArtist(artist)}
                      </Radio>
                      <Spacer mt={12} />
                    </>
                  ))}
                </>
              )}
              <>
                <DottedLine />
                <Spacer mt={4} />
                <LinkWrapper disabled={!!disableCreateInviteForAllArtists}>
                  {disableCreateInviteForAllArtists ? (
                    <DisabledLink>
                      <StyledIcon>
                        <StreamlineIcon
                          icon={Envelope}
                          size={14}
                          stroke={theme.colors.macyGrey}
                        />
                      </StyledIcon>
                      {intl.formatMessage({
                        id:
                          'admin.eventPlanner.artists.createBookingInvitationWithAllArtists',
                      })}
                    </DisabledLink>
                  ) : (
                    <Link
                      onClick={() => {
                        handleFlyoverClose && handleFlyoverClose();
                        toggleCreateOfferModal();
                      }}
                    >
                      <StyledIcon>
                        <StreamlineIcon
                          icon={Envelope}
                          size={14}
                          stroke={theme.colors.green600}
                        />
                      </StyledIcon>
                      {intl.formatMessage({
                        id:
                          'admin.eventPlanner.artists.createBookingInvitationWithAllArtists',
                      })}
                    </Link>
                  )}
                </LinkWrapper>
                <Spacer mt={4} />
                <DottedLine />
                <Spacer mt={4} />
              </>
            </>
          ))}
        {selectedArtist && (
          <>
            <Spacer mt={4} />
            <FlyoverNote>
              <FormattedHTMLMessage id="admin.eventPlanner.artists.artistsWillBeAddedWithDraftStatus" />
            </FlyoverNote>
          </>
        )}
      </ContentWrapper>
      <FlyoverFooter
        buttonText={intl.formatMessage({
          id: 'admin.eventPlanner.artists.update',
        })}
        buttonDisabled={
          !selectedArtist ||
          (performance &&
            performance.artist &&
            performance.artist.id === selectedArtist.id)
        }
        onClickButton={() => {
          setIsSubmitting(true);
          handleAddArtist();
        }}
        dataQaid="add-artist-submit-button"
      />
      {createOfferModal.isShowing && (
        <RoutableModal
          hide={createOfferModal.hide}
          initialRouteProps={{
            event,
            artists: [
              ...availableSpecificDateArtists,
              ...availableGeneralArtists,
            ],
            position,
            creatingOfferForSingleEvent: hasViewOffersPermission ? false : true,
            onSuccessCreateAvailabilityOffer: pageState.refetchListPage,
            availableSpecificDateArtistIds: availableSpecificDateArtists.map(
              (a: Artist) => a.id
            ),
            availableGeneralArtistIds: availableGeneralArtists.map(
              (a: Artist) => a.id
            ),
          }}
          initialRouteName="offer-create"
          dataQaidSuffix="admin-create-offer"
        />
      )}
    </div>
  );
};

export default AddArtist;
