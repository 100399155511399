import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { User } from 'app/typings/users';
import { dateFormatter } from 'app/shared/utils/datetime';
import { NoIcon, YesIcon } from 'app/shared/components/atoms/IconLibrary';
import { getDisplayStatus } from 'app/admin/utils/guestlist';
import { CompetitionLink } from 'app/admin/components/molecules/EntityLink';
import ListTable from 'app/admin/components/organisms/ListTable';

interface Props {
  sectionData: User;
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const NoCompetitions = styled.div`
  white-space: nowrap;
  width: 100%;
`;

const renderBooleanValue = (isTrue: boolean) =>
  isTrue ? <YesIcon /> : <NoIcon />;

const SectionCompetitions: React.FC<Props> = ({ sectionData }) => {
  const intl = useIntl();

  const columnsConfig = [
    {
      headerText: intl.formatMessage({
        id: 'shared.title',
      }),
      columnWidth: 300,
    },
    {
      headerText: intl.formatMessage({
        id: 'admin.userCompetitions.header.closingDate',
      }),
      columnWidth: 130,
    },
    {
      headerText: intl.formatMessage({
        id: 'shared.status',
      }),
      columnWidth: 110,
    },
    {
      headerText: intl.formatMessage({
        id: 'admin.userCompetitions.header.winner',
      }),
      columnWidth: 90,
    },
  ];

  const rowValues = (competitionEntered: any) => {
    return [
      <CompetitionLink competition={competitionEntered} key={1} />,
      dateFormatter(competitionEntered.closesAt, 'shortMonthDateAndYear'),
      getDisplayStatus(competitionEntered.currentState),
      renderBooleanValue(competitionEntered.isWinner),
    ];
  };

  return (
    <MainContainer data-qaid="section-competitions">
      <ListTable
        columnsConfig={columnsConfig}
        rowValues={rowValues}
        data={sectionData.competitionsEntered}
        noResultsContent={
          sectionData.competitionsEntered &&
          sectionData.competitionsEntered.length == 0 && (
            <NoCompetitions>
              {intl.formatMessage({
                id: 'admin.userCompetitions.hasNotEnteredCompetitions',
              })}
            </NoCompetitions>
          )
        }
      />
    </MainContainer>
  );
};

export default SectionCompetitions;
