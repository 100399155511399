import React from 'react';
import styled, { css } from 'styled-components';

import { ManualCSSColors } from 'app/shared/theme';

interface Props {
  percentage?: number;
  width?: string;
  height?: string;
  borderRadius?: string;
  color?: ManualCSSColors;
  unfilledColor?: ManualCSSColors;
  borderWidth?: string;
}

interface InnerBarProps {
  percentage?: number;
  color?: ManualCSSColors;
}

interface ContainerProps {
  width?: string;
  color?: ManualCSSColors;
  height?: string;
  borderRadius?: string;
  borderWidth?: string;
  borderColor?: ManualCSSColors;
}

const InnerBar = styled.div<InnerBarProps>`
  ${({ theme, percentage = 0, color = 'blueSmoke' }) => css`
    background-color: ${theme.colors[color]};
    height: 100%;
    width: ${percentage}%;
    transition: width 0.2s;
  `};
`;

const Container = styled.div<ContainerProps>`
  ${({
    theme,
    width = '120px',
    color = 'macyGrey',
    height = '16px',
    borderRadius = '8px',
    borderWidth = '0px',
    borderColor = 'macyGrey',
  }) => css`
    background-color: ${theme.colors[color]};
    width: ${width};
    height: ${height};
    border-radius: ${borderRadius};
    border: ${borderWidth} solid ${theme.colors[borderColor]};
    overflow: hidden;
    display: flex;
    flex-direction: row;
  `};
`;

const ProgressBar = (props: Props) => {
  const {
    percentage,
    width,
    height,
    borderRadius,
    color,
    unfilledColor,
    borderWidth,
  } = props;
  return (
    <Container
      width={width}
      color={unfilledColor}
      borderColor={color}
      height={height}
      borderRadius={borderRadius}
      borderWidth={borderWidth}
    >
      <InnerBar color={color} percentage={percentage} data-qaid="inner-bar" />
    </Container>
  );
};

export default ProgressBar;
