import React from 'react';
import styled, { css } from 'styled-components';

import { ParentMenu } from 'app/typings/navbar';
import { adminManualCSSTheme, commonTheme } from 'app/shared/theme';
import { useTShirtSizeBreakpoint as useBreakpoint } from 'app/shared/utils/useBreakpoint';
import { FlexDivWithSpacing } from 'app/shared/components/atoms/FlexDivWithSpacing';
import { Body } from 'app/shared/components/atoms/TypographyManualCSS';

const StyledDiv = styled(FlexDivWithSpacing)`
  display: inline-block;
`;

const ParentMenuItemText = styled(Body)<{ isAdmin: boolean }>`
  ${({ isAdmin }) => css`
    display: inline-block;
    text-transform: uppercase;
    color: ${isAdmin
      ? adminManualCSSTheme.colors.green800
      : commonTheme.colors.accent.primaryAccent.base};
  `}
`;

interface ParentMenuItemMobileProps {
  parentMenuItem: ParentMenu;
  isAdmin: boolean;
  qaId: string;
  index: number;
}

export const ParentMenuItemMobile: React.FC<ParentMenuItemMobileProps> = ({
  parentMenuItem,
  isAdmin,
  qaId,
  index,
}) => {
  const currentBreakpoint = useBreakpoint();
  const isMobile = currentBreakpoint === 'xs' || currentBreakpoint === 'sm';

  if (
    (parentMenuItem.mobileOnly && !isMobile) ||
    parentMenuItem.childMenu.length === 0
  ) {
    return null;
  }

  return (
    <StyledDiv
      data-qaid={`${qaId}-parent-menu-item`}
      key={`${qaId}-parent-menu-item-${index}`}
      mt={6}
      pb={3}
      pl={6}
    >
      <ParentMenuItemText
        as="h2"
        isAdmin={isAdmin}
        data-qaid={`${qaId}-parent-menu-item-text-${index}`}
      >
        {parentMenuItem.title}
      </ParentMenuItemText>
    </StyledDiv>
  );
};
