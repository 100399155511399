import React from 'react';
import styled, { css } from 'styled-components';

import { useCurrentTheme } from 'app/shared/theme';
import { GenericLink } from 'app/shared/components/atoms/LinkManualCSS';
import { StreamlineIcon } from 'app/shared/components/atoms/StreamlineIcon';

interface Props {
  icon: any;
  text: string;
  url?: string;
  onClick?: VoidFunction;
  dataQaid?: string;
  buttonStyle?: string;
  disabled?: boolean;
}

const MainContainer = styled.div<{ buttonStyle?: string; disabled?: boolean }>`
  ${({ buttonStyle, disabled, theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: fit-content;
    margin: 5px 0 7px;
    cursor: ${disabled ? 'default' : 'pointer'};
    background-color: ${buttonStyle === 'filled'
      ? theme.colors.green600
      : buttonStyle === 'unfilled'
      ? theme.colors.whiteDenim
      : undefined};
    border-radius: ${buttonStyle === 'filled' || buttonStyle === 'unfilled'
      ? '8px'
      : undefined};
    padding: ${buttonStyle === 'filled' || buttonStyle === 'unfilled'
      ? '4px 12px'
      : undefined};
    ${theme.media.xs`
      width: fit-content;
      min-width: 135px;
      max-width: 300px;
    `}
    ${theme.media.sm`
      width: fit-content;
      min-width: 135px;
      max-width: 450px;
    `}
  `}
`;

const IconContainer = styled.div`
  margin-top: 4px;
  margin-right: 12px;
`;

const TextContainer = styled.div<{ buttonStyle?: string; disabled?: boolean }>`
  ${({ buttonStyle, disabled, theme }) => css`
    font-size: 14px;
    font-weight: ${buttonStyle === 'filled' || buttonStyle === 'unfilled'
      ? 'normal'
      : 'bold'};
    line-height: 18px;
    color: ${disabled
      ? theme.colors.macyGrey
      : buttonStyle === 'filled'
      ? theme.colors.whiteDenim
      : theme.colors.green600};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `}
`;

interface IconProps {
  icon: any;
  buttonStyle?: string;
  disabled?: boolean;
}

const Icon: React.FC<IconProps> = ({ icon, buttonStyle, disabled }) => {
  const theme = useCurrentTheme();

  return (
    <StreamlineIcon
      icon={icon}
      size={17}
      stroke={
        disabled
          ? theme.colors.macyGrey
          : buttonStyle === 'filled'
          ? theme.colors.whiteDenim
          : theme.colors.green600
      }
    />
  );
};

const IconAndTextLink: React.FC<Props> = ({
  icon,
  text,
  url,
  onClick,
  dataQaid,
  buttonStyle,
  disabled,
}) => {
  return (
    <>
      {url ? (
        <MainContainer
          data-qaid={dataQaid}
          buttonStyle={buttonStyle}
          disabled={disabled}
        >
          <IconContainer>
            <GenericLink url={url}>
              <Icon icon={icon} buttonStyle={buttonStyle} />
            </GenericLink>
          </IconContainer>
          <TextContainer buttonStyle={buttonStyle} disabled={disabled}>
            <GenericLink url={url}>{text}</GenericLink>
          </TextContainer>
        </MainContainer>
      ) : onClick ? (
        <MainContainer
          data-qaid={dataQaid}
          onClick={onClick}
          buttonStyle={buttonStyle}
          disabled={disabled}
        >
          <IconContainer>
            <Icon icon={icon} buttonStyle={buttonStyle} />
          </IconContainer>
          <TextContainer buttonStyle={buttonStyle} disabled={disabled}>
            {text}
          </TextContainer>
        </MainContainer>
      ) : (
        <MainContainer
          data-qaid={dataQaid}
          buttonStyle={buttonStyle}
          disabled={disabled}
        >
          <IconContainer>
            <Icon icon={icon} buttonStyle={buttonStyle} disabled={disabled} />
          </IconContainer>
          <TextContainer buttonStyle={buttonStyle} disabled={disabled}>
            {text}
          </TextContainer>
        </MainContainer>
      )}
    </>
  );
};

export default IconAndTextLink;
