import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { errorMsgForField } from 'app/shared/utils/form';
import usePermission from 'app/shared/utils/usePermission';
import { Checkbox } from 'app/shared/components/atoms/CheckboxManualCSS';
import {
  FormGroupContainer,
  FormSectionTitle,
} from 'app/shared/components/atoms/FormContent';
import GenericFormContainer from 'app/shared/components/atoms/GenericFormContainer';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Textarea } from 'app/shared/components/atoms/Textarea';
import { Textfield } from 'app/shared/components/atoms/Textfield';
import {
  Caption,
  FormGroupLabel,
} from 'app/shared/components/atoms/TypographyManualCSS';
import FormGroup from 'app/shared/components/molecules/FormGroup';
import CampaignFormGroup from 'app/admin/components/organisms/CampaignFormGroup';

export const NumberTextfield = styled(Textfield)`
  display: block;
  width: 160px;
`;

interface FormProps {
  formikProps: any;
}

const EventDetailsPartnerEditForm: React.FC<FormProps> = ({ formikProps }) => {
  const intl = useIntl();
  const { setFieldValue, values, errors, touched } = formikProps;

  const hasPartnerAccessPermission = usePermission('partner.access');
  const hasCampaignAccessPermission = usePermission('campaign.access');

  return (
    <GenericFormContainer dataQaId="event-partner-info-edit-form">
      {hasPartnerAccessPermission && (
        <>
          <FormSectionTitle>
            {intl.formatMessage({
              id: 'admin.eventPartnerInfo.form.partnerAndCampaign',
            })}
          </FormSectionTitle>
          <CampaignFormGroup
            formikProps={formikProps}
            hasPartnerAccessPermission={hasPartnerAccessPermission}
            hasCampaignAccessPermission={hasCampaignAccessPermission}
            dataQaidPrefix="event-partner-info-edit-form"
          />
        </>
      )}
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.eventPartnerInfo.form.venueBudgetAvailable',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="event-partner-form-venue-budget-available-formgroup"
          label={intl.formatMessage(
            {
              id: 'admin.eventPartnerInfo.form.venueBudgetWithCurrencySymbol',
            },
            {
              currencySymbol: values.currencySymbol,
            }
          )}
          errorMsg={errorMsgForField('venueBudget', touched, errors)}
        >
          <NumberTextfield
            data-qaid="event-partner-form-venue-budget-field"
            id="venueBudget"
            name="venueBudget"
            value={values.venueBudget}
            onChange={(e: any) => {
              setFieldValue('venueBudget', e.target.value);
            }}
            maxLength={8}
          />
        </FormGroup>
        <Spacer mt={4} />

        <FormGroup
          data-qaid="event-partner-form-venue-budget-description-formgroup"
          label={intl.formatMessage({
            id: 'admin.eventPartnerInfo.form.venueBudgetDescription',
          })}
          errorMsg={errorMsgForField('venueBudgetDescription', touched, errors)}
        >
          <Textarea
            data-qaid="event-partner-form-venue-budget-description-field"
            id="venueBudgetDescription"
            name="venueBudgetDescription"
            value={values.venueBudgetDescription}
            maxLength={1000}
            onChange={(e: any) => {
              setFieldValue('venueBudgetDescription', e.target.value);
            }}
          />
        </FormGroup>
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.eventPlanner.partnerInfo.notes',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="event-partner-form-partner-notes-formgroup"
          label={intl.formatMessage({
            id: 'admin.eventPlanner.partnerInfo.partnerNotes',
          })}
          errorMsg={errorMsgForField('sponsorNotes', touched, errors)}
        >
          <Textarea
            data-qaid="event-partner-form-partner-notes-field"
            id="sponsorNotes"
            name="sponsorNotes"
            value={values.sponsorNotes}
            maxLength={1000}
            onChange={(e: any) => {
              setFieldValue('sponsorNotes', e.target.value);
            }}
          />
        </FormGroup>
        <Spacer mt={4} />

        <FormGroup
          data-qaid="event-partner-form-post-show-summary-formgroup"
          label={intl.formatMessage({
            id: 'admin.eventPlanner.partnerInfo.postShowSummary',
          })}
          errorMsg={errorMsgForField('sponsorPostShowSummary', touched, errors)}
        >
          <Textarea
            data-qaid="event-partner-form-post-show-summary-field"
            id="postShowSummary"
            name="postShowSummary"
            value={values.sponsorPostShowSummary}
            maxLength={1000}
            onChange={(e: any) => {
              setFieldValue('sponsorPostShowSummary', e.target.value);
            }}
          />
        </FormGroup>
      </FormGroupContainer>
      <Spacer mt={4} />
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.eventPlanner.partnerPromotion',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroupLabel fontWeight="bold">
          {intl.formatMessage({
            id: 'admin.eventPlanner.partnerPromotion.displaySectionTitle',
          })}
        </FormGroupLabel>
        <Spacer mb={4} />
        <FormGroup
          data-qaid="event-partner-form-partner-promotion-display-formgroup"
          errorMsg={errorMsgForField(
            'partnerPromotionSectionEnabled',
            touched,
            errors
          )}
        >
          <Checkbox
            id="partnerPromotionSectionEnabled"
            data-qaid="event-partner-form-partner-promotion-display-field"
            checked={values.partnerPromotionSectionEnabled}
            name="partnerPromotionSectionEnabled"
            onChange={(e: any) => {
              setFieldValue('partnerPromotionSectionEnabled', e.target.checked);
            }}
          >
            {intl.formatMessage({
              id: 'admin.eventPlanner.partnerPromotion.displayCheckboxText',
            })}
          </Checkbox>
        </FormGroup>
        <Spacer mt={8} />
        <FormGroup
          data-qaid="event-partner-form-partner-promotion-title-formgroup"
          label={intl.formatMessage({
            id: 'admin.eventPlanner.partnerPromotion.title',
          })}
          errorMsg={errorMsgForField('partnerPromotionTitle', touched, errors)}
        >
          <>
            <Caption>
              {intl.formatMessage({
                id:
                  'admin.eventPlanner.partnerPromotion.titleMaxCharactersDescription',
              })}
            </Caption>
            <Textfield
              data-qaid="event-partner-form-partner-promotion-title-field"
              id="partnerPromotionTitle"
              name="partnerPromotionTitle"
              value={values.partnerPromotionTitle}
              maxLength={40}
              onChange={(e: any) => {
                setFieldValue('partnerPromotionTitle', e.target.value);
              }}
            />
          </>
        </FormGroup>
        <Spacer mt={4} />
        <FormGroup
          data-qaid="event-partner-form-partner-promotion-description-formgroup"
          label={intl.formatMessage({
            id: 'admin.eventPlanner.partnerPromotion.description',
          })}
          errorMsg={errorMsgForField(
            'partnerPromotionDescription',
            touched,
            errors
          )}
        >
          <>
            <Caption>
              {intl.formatMessage({
                id:
                  'admin.eventPlanner.partnerPromotion.descriptionMaxCharactersDescription',
              })}
            </Caption>
            <Textarea
              data-qaid="event-partner-form-partner-promotion-description-field"
              id="partnerPromotionDescription"
              name="partnerPromotionDescription"
              value={values.partnerPromotionDescription}
              maxLength={250}
              onChange={(e: any) => {
                setFieldValue('partnerPromotionDescription', e.target.value);
              }}
            />
          </>
        </FormGroup>
        <Spacer mt={4} />

        <FormGroup
          data-qaid="event-partner-form-partner-promotion-button-text-formgroup"
          label={intl.formatMessage({
            id: 'admin.eventPlanner.partnerPromotion.buttonText',
          })}
          errorMsg={errorMsgForField(
            'partnerPromotionButtonText',
            touched,
            errors
          )}
        >
          <>
            <Caption>
              {intl.formatMessage({
                id:
                  'admin.eventPlanner.partnerPromotion.buttonTextMaxCharactersDescription',
              })}
            </Caption>
            <Textfield
              data-qaid="event-partner-form-partner-promotion-button-text-field"
              id="partnerPromotionButtonText"
              name="partnerPromotionButtonText"
              value={values.partnerPromotionButtonText}
              maxLength={30}
              onChange={(e: any) => {
                setFieldValue('partnerPromotionButtonText', e.target.value);
              }}
            />
          </>
        </FormGroup>
        <Spacer mt={4} />

        <FormGroup
          data-qaid="event-partner-form-partner-promotion-button-url-formgroup"
          label={intl.formatMessage({
            id: 'admin.eventPlanner.partnerPromotion.buttonUrl',
          })}
          errorMsg={errorMsgForField(
            'partnerPromotionButtonUrl',
            touched,
            errors
          )}
        >
          <Textfield
            data-qaid="event-partner-form-partner-promotion-button-url-field"
            id="partnerPromotionButtonUrl"
            name="partnerPromotionButtonUrl"
            value={values.partnerPromotionButtonUrl}
            maxLength={40}
            onChange={(e: any) => {
              setFieldValue('partnerPromotionButtonUrl', e.target.value);
            }}
          />
        </FormGroup>
      </FormGroupContainer>
    </GenericFormContainer>
  );
};

export default EventDetailsPartnerEditForm;
