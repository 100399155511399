import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Artist } from 'app/typings';
import LazyImgixImage from 'app/shared/components/atoms/LazyImgixLoad';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import Select from 'app/shared/components/molecules/SelectManualCSS';
import ThumbnailCircle from 'app/admin/components/atoms/ThumbnailCircle';

import AudienceReaction from './AudienceReaction';

interface Props {
  artist: Artist;
  onChangeOverallFeeling: Function;
  onChangeFeedbackString: Function;
  selectedValueOverallFeeling?: string;
  selectedValueFeedbackString?: string;
  feedbackPhrases: any;
}

const ReactionArtistContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ReactionArtistImageTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const AudienceReactionArtist: React.FC<Props> = ({
  artist,
  onChangeOverallFeeling,
  onChangeFeedbackString,
  selectedValueOverallFeeling,
  selectedValueFeedbackString,
  feedbackPhrases,
}) => {
  const intl = useIntl();
  const selectOptions = feedbackPhrases.map((phrase: any) => {
    return {
      label: phrase.phrase,
      key: phrase.id,
      value: phrase.id,
    };
  });

  return (
    <>
      <ReactionArtistContainer>
        <ReactionArtistImageTitleContainer>
          <ThumbnailCircle>
            {artist.imageUrl && (
              <LazyImgixImage src={artist.imageUrl} sizes="44px" />
            )}
          </ThumbnailCircle>
          {artist.title}
        </ReactionArtistImageTitleContainer>

        <Spacer mt={4} />

        <AudienceReaction
          idNamePrefix={`artist-${artist.id}`}
          selectedValue={selectedValueOverallFeeling}
          onChange={(option: string) => onChangeOverallFeeling(artist, option)}
        />

        <Spacer mt={4} />

        <Select
          id="select-{artist.id}"
          name="select-{artist.id}"
          getOptionLabel={(option: any) => option.label}
          options={selectOptions}
          value={selectedValueFeedbackString}
          placeholder={intl.formatMessage({
            id: 'admin.crewPortal.crewFeedback.form.selectFeedback',
          })}
          onChange={(option: any) => {
            onChangeFeedbackString(artist, parseInt(option.value));
          }}
        />
      </ReactionArtistContainer>
    </>
  );
};

export default AudienceReactionArtist;
