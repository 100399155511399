import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { City } from 'app/typings/cities';
import { PromoCode, PromoCodeRule } from 'app/typings/promoCodes';
import { PromoCodeCustomPrice } from 'app/typings/promoCodes';
import { currencyRoundedFormatter } from 'app/shared/utils/currencyFormatter';
import { dateFormatter } from 'app/shared/utils/datetime';
import { commaSeparatedList } from 'app/shared/utils/string';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import { eventOwnerTypeLabels, eventTypeLabels } from 'app/admin/utils/events';
import {
  DetailHeading as Heading,
  DetailValueContainer as ValueContainer,
} from 'app/admin/components/atoms/DetailContent';
import { None } from 'app/admin/components/atoms/None';
import { CampaignLink } from 'app/admin/components/molecules/EntityLink';
import { UserLink } from 'app/admin/components/molecules/EntityLink';
import { SimpleKeyValueInfoList } from 'app/admin/components/molecules/SimpleKeyValueInfoList';

interface Props {
  sectionData: PromoCode;
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const Rule = styled.div`
  margin-bottom: 7px;
`;

const getCustomRuleTexts = (intl: any, rules?: PromoCodeRule[]) => {
  let ruleTexts: string[] = [];
  if (rules && rules.length > 0) {
    ruleTexts = rules.map((rule: PromoCodeRule) => {
      switch (rule.ruleType) {
        case 'for_day_of_week':
          return intl.formatMessage(
            {
              id: 'admin.promoCodeBasicInfo.ruleDayOfWeek',
            },
            {
              dayOfWeek: `${rule.ruleDayOfWeek}s`,
            }
          );
        case 'for_days_of_week':
          return intl.formatMessage(
            {
              id: 'admin.promoCodeBasicInfo.ruleDaysOfWeek',
            },
            {
              daysOfWeek:
                rule.ruleDaysOfWeek && rule.ruleDaysOfWeek.length > 0
                  ? commaSeparatedList(
                      rule.ruleDaysOfWeek.map((d: string) => `${d}s`)
                    )
                  : '',
            }
          );
        case 'for_city':
          return intl.formatMessage(
            {
              id: 'admin.promoCodeBasicInfo.ruleCity',
            },
            {
              cityTitle: rule.ruleCity ? rule.ruleCity.title : '',
            }
          );
        case 'for_multi_city':
          return intl.formatMessage(
            {
              id: 'admin.promoCodeBasicInfo.ruleMultiCity',
            },
            {
              cityTitles:
                rule.ruleCities && rule.ruleCities.length > 0
                  ? commaSeparatedList(
                      rule.ruleCities.map((city: City) => city.title)
                    )
                  : '',
            }
          );
        case 'for_country':
          return intl.formatMessage(
            {
              id: 'admin.promoCodeBasicInfo.ruleCountry',
            },
            {
              countryTitle: rule.ruleCountry ? rule.ruleCountry.title : '',
            }
          );
        case 'for_event':
          return intl.formatMessage(
            {
              id: 'admin.promoCodeBasicInfo.ruleEvent',
            },
            {
              eventDisplayName: rule.ruleEvent
                ? `${dateFormatter(
                    rule.ruleEvent.localStartsAt,
                    'shortMonthDateAndYear'
                  )} ${rule.ruleEvent.city.title}`
                : '',
            }
          );
        case 'for_event_type':
          return intl.formatMessage(
            {
              id: 'admin.promoCodeBasicInfo.ruleEventType',
            },
            {
              eventType: rule.ruleEventType
                ? eventTypeLabels[rule.ruleEventType]
                : '',
            }
          );
        case 'for_event_owner_types':
          return intl.formatMessage(
            {
              id: 'admin.promoCodeBasicInfo.ruleEventOwnerTypes',
            },
            {
              eventOwnerTypes:
                rule.ruleEventOwnerTypes && rule.ruleEventOwnerTypes.length > 0
                  ? commaSeparatedList(
                      rule.ruleEventOwnerTypes.map(
                        (eot: string) => eventOwnerTypeLabels[eot]
                      )
                    )
                  : '',
            }
          );
        case 'for_purchaser_type':
          return intl.formatMessage(
            {
              id: 'admin.promoCodeBasicInfo.rulePurchaserType',
            },
            {
              purchaserType: rule.rulePurchaserType
                ? rule.rulePurchaserType
                : '',
            }
          );
        default:
          return '';
      }
    });
  }
  return ruleTexts;
};

const SectionBasicInfo: React.FC<Props> = ({ sectionData }) => {
  const intl = useIntl();

  const ruleTexts = getCustomRuleTexts(intl, sectionData.rules);

  const customPricesKeysAndValues = sectionData.customPrices
    ? sectionData.customPrices.map((customPrice: PromoCodeCustomPrice) => {
        const rawValue = customPrice.price;
        return {
          key:
            customPrice.numTickets == 1
              ? intl.formatMessage({
                  id: 'admin.promoCodeBasicInfo.singleTicket',
                })
              : intl.formatMessage(
                  {
                    id: 'admin.promoCodeBasicInfo.multipleTickets',
                  },
                  {
                    numTickets: customPrice.numTickets,
                  }
                ),
          value:
            rawValue || rawValue == 0
              ? currencyRoundedFormatter(rawValue, '$')
              : undefined,
        };
      })
    : undefined;

  return (
    <MainContainer data-qaid="section-overview">
      <Grid cols={4}>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={1}>
          <Heading>
            {intl.formatMessage({
              id: 'admin.shared.type',
            })}
          </Heading>
          <ValueContainer>{sectionData.typeName}</ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.promoCodeBasicInfo.heading.reason',
            })}
          </Heading>
          <ValueContainer>
            {sectionData.generationReason ? (
              <>{sectionData.generationReason.description}</>
            ) : (
              <None />
            )}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.promoCodeBasicInfo.heading.campaign',
            })}
          </Heading>
          <ValueContainer>
            {sectionData.campaign ? (
              <CampaignLink
                campaign={sectionData.campaign}
                truncateLength={30}
              />
            ) : (
              <None />
            )}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.promoCodeBasicInfo.heading.creator',
            })}
          </Heading>
          <ValueContainer>
            {sectionData.createdBy ? (
              <UserLink user={sectionData.createdBy} truncateLength={30} />
            ) : (
              <None />
            )}
          </ValueContainer>
          {sectionData.curatorGroup && (
            <>
              <Heading>
                {intl.formatMessage({
                  id: 'admin.promoCodeBasicInfo.heading.curatorGroup',
                })}
              </Heading>
              <ValueContainer>{sectionData.curatorGroup.name}</ValueContainer>
            </>
          )}
        </Col>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={2}>
          <Heading>
            {intl.formatMessage({
              id: 'admin.shared.dateCreated',
            })}
          </Heading>
          <ValueContainer>
            {sectionData.createdAt ? (
              dateFormatter(sectionData.createdAt, 'shortMonthDateAndYear')
            ) : (
              <None />
            )}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.promoCodeBasicInfo.heading.dateValid',
            })}
          </Heading>
          <ValueContainer>
            {sectionData.validFrom ? (
              dateFormatter(sectionData.validFrom, 'shortMonthDateAndYear')
            ) : (
              <None />
            )}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.promoCodeBasicInfo.heading.expirationDate',
            })}
          </Heading>
          <ValueContainer>
            {sectionData.expiresAt ? (
              dateFormatter(sectionData.expiresAt, 'shortMonthDateAndYear')
            ) : (
              <None />
            )}
          </ValueContainer>
        </Col>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={3}>
          <Heading>
            {intl.formatMessage({
              id: 'admin.promoCodeBasicInfo.heading.timesUsed',
            })}
          </Heading>
          <ValueContainer>{sectionData.numTimesUsed}</ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.promoCodeBasicInfo.heading.ticketsPurchased',
            })}
          </Heading>
          <ValueContainer>{sectionData.numTicketsPurchased}</ValueContainer>
          {(sectionData.type == 'PromoCode::PercentageDiscount' ||
            sectionData.type == 'PromoCode::PercentageDiscountSingleUse') && (
            <>
              <Heading>
                {intl.formatMessage({
                  id: 'admin.promoCodeBasicInfo.heading.percentageDiscount',
                })}
              </Heading>
              <ValueContainer>
                {sectionData.percentageDiscount ? (
                  `${sectionData.percentageDiscount / 100}%`
                ) : (
                  <None />
                )}
              </ValueContainer>
            </>
          )}
          {(sectionData.type == 'PromoCode::CustomPricing' ||
            (sectionData.type == 'PromoCode::CustomPricingSingleUse' &&
              customPricesKeysAndValues)) && (
            <>
              <Heading>
                {intl.formatMessage({
                  id: 'admin.promoCodeBasicInfo.heading.customPricing',
                })}
              </Heading>
              <SimpleKeyValueInfoList
                keysAndValues={customPricesKeysAndValues || []}
                keyColWidth="60px"
              />
            </>
          )}
        </Col>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={4}>
          <Heading>
            {intl.formatMessage({
              id: 'admin.promoCodeBasicInfo.heading.rules',
            })}
          </Heading>
          <ValueContainer>
            <Rule>
              {intl.formatMessage(
                {
                  id: 'admin.promoCodeBasicInfo.validBetweenDates',
                },
                {
                  validFrom: dateFormatter(
                    sectionData.validFrom,
                    'shortMonthDateAndYear'
                  ),
                  expiresAt: dateFormatter(
                    sectionData.expiresAt,
                    'shortMonthDateAndYear'
                  ),
                }
              )}
            </Rule>
            <Rule>
              {sectionData.maxUses === null
                ? intl.formatMessage({
                    id: 'admin.promoCodeBasicInfo.maxUsesUnlimited',
                  })
                : sectionData.maxUses == 1
                ? intl.formatMessage({
                    id: 'admin.promoCodeBasicInfo.maxUsesSpecificOne',
                  })
                : intl.formatMessage(
                    {
                      id: 'admin.promoCodeBasicInfo.maxUsesSpecific',
                    },
                    {
                      maxUses: sectionData.maxUses,
                    }
                  )}
            </Rule>
            <Rule>
              {sectionData.maxUsesPerUser === null
                ? intl.formatMessage({
                    id: 'admin.promoCodeBasicInfo.numUsesPerUserUnlimited',
                  })
                : sectionData.maxUsesPerUser == 1
                ? intl.formatMessage({
                    id: 'admin.promoCodeBasicInfo.numUsesPerUserSpecificOne',
                  })
                : intl.formatMessage(
                    {
                      id: 'admin.promoCodeBasicInfo.numUsesPerUserSpecific',
                    },
                    {
                      maxUsesPerUser: sectionData.maxUsesPerUser,
                    }
                  )}
            </Rule>
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.promoCodeBasicInfo.heading.customRules',
            })}
          </Heading>
          <ValueContainer>
            {ruleTexts.length > 0 ? (
              ruleTexts.map((ruleText: string, index: number) => (
                <Rule key={index}>{ruleText}</Rule>
              ))
            ) : (
              <None />
            )}
          </ValueContainer>
        </Col>
      </Grid>
    </MainContainer>
  );
};

export default SectionBasicInfo;
