import React, { useState } from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';

import { dateFormatter } from 'app/shared/utils/datetime';
import { get } from 'app/shared/utils/get';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { UpdateBroadcast } from 'app/admin/graphql/broadcasts/mutationHooks';
import { DetailsFormMainContainer } from 'app/admin/components/atoms/DetailContent';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

import BroadcastEditForm from './BroadcastEditForm';
import BroadcastEditFormSchema from './BroadcastEditFormSchema';

interface Props {
  contentProps?: any;
  setFormSubmitAction: (func: Function) => void;
  setDisplayConfirmation: (set: boolean) => void;
  setIsSubmitting: (set: boolean) => void;
  hide: VoidFunction;
}

const datetimeFormat =
  'shortMonthDateYearHoursAndMinutesWithSpacedAMPMWithTimezone';

const streamTypes = {
  live_stream: 'Live Stream',
  rerun: 'Rerun',
};

const getStreamType = (streamTypeRaw: string) =>
  streamTypeRaw &&
  Object.keys(streamTypes).find(
    (st: string) => streamTypes[st] === streamTypeRaw
  );

const BroadcastEdit: React.FC<Props> = ({
  contentProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  hide,
}) => {
  const intl = useIntl();

  const [validationErrors, setValidationErrors] = useState<object>({});

  const formInitialValues = {
    scheduledStartTimeUtc: get(contentProps, 'scheduledStartTimeUtc', ''),
    startDate: get(contentProps, 'scheduledStartTimeUtc', '')
      ? moment(get(contentProps, 'scheduledStartTimeUtc', ''))
      : '',
    startTime: get(contentProps, 'scheduledStartTimeUtc', ''),
    artistId: get(contentProps, 'artistId', null),
    artistTitle: get(contentProps, 'artistTitle', ''),
    location: get(contentProps, 'location', ''),
    streamType: getStreamType(get(contentProps, 'streamType', '')),
    embedUrl: get(contentProps, 'embedUrl', ''),
    extraInfoUrl: get(contentProps, 'extraInfoUrl', ''),
    linkType: get(contentProps, 'linkType', ''),
    partnershipTag: get(contentProps, 'partnershipTag', ''),
    hideDonation: get(contentProps, 'hideDonation', false),
    donationText: get(contentProps, 'donationText', ''),
  };

  const updateBroadcastAction = UpdateBroadcast();

  const handleSubmit = useSubmitAction({
    submitAction: updateBroadcastAction,
    submitVariables: (values: any) => ({
      broadcastId: get(contentProps, 'id', undefined),
      scheduledStartTimeUtc: values.scheduledStartTimeUtc,
      artistId: values.artistId,
      location: values.location,
      streamType: values.streamType,
      embedUrl: values.embedUrl,
      extraInfoUrl: values.extraInfoUrl,
      linkType: values.linkType,
      partnershipTag: values.partnershipTag,
      hideDonation: values.hideDonation,
      donationText: values.donationText,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.broadcastEdit.successMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.broadcastEdit.failureMessage',
    }),
    onSuccess: () => {
      contentProps.refetchBroadcasts();
      hide();
    },
    onValidationError: validationErrors =>
      setValidationErrors(validationErrors),
  });

  const startDatetime = dateFormatter(
    get(contentProps, 'scheduledStartTimeUtc', ''),
    datetimeFormat
  );

  return (
    <ModalContentContainer>
      <DetailsFormMainContainer>
        <DetailsHeader
          title={`${intl.formatMessage({
            id: 'admin.broadcastEdit.title',
          })} ${startDatetime}`}
        />
        <GenericForm
          formInitialValues={formInitialValues}
          renderFormComponent={(renderProps: any) => (
            <BroadcastEditForm
              formikProps={renderProps.formikProps}
              validationErrors={validationErrors}
            />
          )}
          onSubmit={handleSubmit}
          formSchema={BroadcastEditFormSchema(intl)}
          setFormSubmitAction={setFormSubmitAction}
          setDisplayConfirmation={setDisplayConfirmation}
          setIsSubmitting={setIsSubmitting}
          dataQaId="broadcast-edit-form"
        />
      </DetailsFormMainContainer>
    </ModalContentContainer>
  );
};

export default BroadcastEdit;
