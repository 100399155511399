import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { Campaign, GenerationReason, User } from 'app/typings';
import { useCurrentTheme } from 'app/shared/theme';
import { dateFormatter } from 'app/shared/utils/datetime';
import usePermission from 'app/shared/utils/usePermission';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import TruncatedByCharText from 'app/shared/components/atoms/TruncatedByCharText';
import { Overline } from 'app/shared/components/atoms/TypographyManualCSS';
import { promoCodeStatusLabels } from 'app/admin/utils/promoCodes';
import { userFullNameOrEmail } from 'app/admin/utils/users';
import { KeyAboveValueInfoList } from 'app/admin/components/molecules/KeyAboveValueInfoList';
import { KeyValueInfoList } from 'app/admin/components/molecules/KeyValueInfoList';
import { ListingCardTopTags } from 'app/admin/components/molecules/ListingCardTopTag';
import { CardControls } from 'app/admin/components/organisms/CardControls';

interface Props {
  index: number;
  id: number;
  code: string;
  type: string;
  typeName: string;
  validFrom: string;
  expiresAt: string;
  campaign?: Campaign;
  generationReason?: GenerationReason;
  numTimesUsed: number;
  numTicketsPurchased: number;
  status: string;
  createdBy?: User;
  createdAt: string;
  onShowDetails: Function;
  refetchPromoCodes: VoidFunction;
}

const CardWrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 240px;
    margin: 0;
    position: relative;
    background-color: ${theme.colors.silverSprings};
    border-radius: 10px;
    padding: ${theme.ruler[4]}px;
    display: inline-block;
  `}
`;

const StatusWrapper = styled.div`
  margin-bottom: 15px;
  padding-top: 5px;
`;

const Code = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 10px;
`;

const PromoCodeDetailsLabelWrapper = styled.div`
  margin-bottom: 11px;
`;

const KeyAboveValueInfoListContainer = styled.div`
  height: 200px;
`;

const KeyValueInfoListContainer = styled.div`
  height: auto;
`;

const getStatusTag = (status: string, intl: any, theme: any) => {
  const statusLabels = promoCodeStatusLabels(intl);
  const tagsConfig = {
    pending_start: {
      tagElementColor: theme.colors.mellowYellow,
      name: statusLabels.pending_start,
    },
    cancelled: {
      tagElementColor: theme.colors.redRedWine,
      name: statusLabels.cancelled,
    },
    expired: {
      tagElementColor: theme.colors.blueSmoke,
      name: statusLabels.expired,
    },
  };
  return tagsConfig[status];
};

const PromoCodeCard: React.FC<Props> = ({
  index,
  id,
  code,
  typeName,
  validFrom,
  expiresAt,
  campaign,
  generationReason,
  numTimesUsed,
  numTicketsPurchased,
  status,
  createdBy,
  createdAt,
  onShowDetails,
}) => {
  const intl = useIntl();
  const theme = useCurrentTheme();
  const hasAccessCampaignsPermission = usePermission('campaign.access');
  const hasAccessUsersPermission = usePermission('user.list.access');

  const keysAboveValuesList = [
    {
      key: intl.formatMessage({
        id: 'admin.shared.type',
      }),
      value: typeName,
    },
    {
      key: intl.formatMessage({
        id: 'admin.promoCodes.listingCard.reason',
      }),
      value: generationReason ? generationReason.description : 'None',
    },
    {
      key: intl.formatMessage({
        id: 'admin.promoCodes.listingCard.campaign',
      }),
      value: campaign && campaign.title ? campaign.title : 'None',
      url:
        campaign && hasAccessCampaignsPermission
          ? `/admin/campaigns?campaign_search=${campaign.id}`
          : undefined,
    },
    {
      key: intl.formatMessage({
        id: 'admin.promoCodes.listingCard.creator',
      }),
      value: createdBy ? userFullNameOrEmail(createdBy) || '' : 'None',
      url:
        createdBy && hasAccessUsersPermission
          ? `/admin/users?user_id=${createdBy.id}`
          : undefined,
    },
  ];

  const keysAndValuesList = [
    {
      key: intl.formatMessage({
        id: 'admin.promoCodes.listingCard.createdOn',
      }),
      value: dateFormatter(createdAt, 'shortMonthDateAndYear'),
    },
    {
      key: intl.formatMessage({
        id: 'admin.promoCodes.listingCard.validOn',
      }),
      value: dateFormatter(validFrom, 'shortMonthDateAndYear'),
    },
    {
      key: intl.formatMessage({
        id: 'admin.promoCodes.listingCard.expiresOn',
      }),
      value: dateFormatter(expiresAt, 'shortMonthDateAndYear'),
    },
    {
      key: intl.formatMessage({
        id: 'admin.promoCodes.listingCard.timesUsed',
      }),
      value: numTimesUsed.toString(),
    },
    {
      key: intl.formatMessage({
        id: 'admin.promoCodes.listingCard.ticketsPurchased',
      }),
      value: numTicketsPurchased.toString(),
    },
  ];

  const onShowDetailsParams = {
    id,
    code,
    status,
  };

  const statusTag = getStatusTag(status, intl, theme);

  return (
    <>
      <CardWrapper key={id} data-qaid="admin-promo-code-card">
        <StatusWrapper>
          {statusTag && (
            <ListingCardTopTags tags={[statusTag]} extraPaddingRight="25px" />
          )}
        </StatusWrapper>
        <Overline>
          {intl.formatMessage({
            id: 'admin.promoCodes.listingCard.promoCode',
          })}
        </Overline>
        <Code>
          <TruncatedByCharText text={code} truncateLength={17} />
        </Code>
        <DottedLine />
        <PromoCodeDetailsLabelWrapper>
          <Overline>
            {intl.formatMessage({
              id: 'admin.promoCodes.listingCard.promoCodeDetails',
            })}
          </Overline>
        </PromoCodeDetailsLabelWrapper>
        <KeyAboveValueInfoListContainer>
          <KeyAboveValueInfoList keysAndValues={keysAboveValuesList} />
        </KeyAboveValueInfoListContainer>
        <KeyValueInfoListContainer>
          <KeyValueInfoList keysAndValues={keysAndValuesList} />
        </KeyValueInfoListContainer>
        <DottedLine />
        <CardControls
          controlsInfoList={[]}
          onClickShowDetails={
            onShowDetails &&
            (() => {
              onShowDetails(onShowDetailsParams);
            })
          }
          showDetailsTipText={intl.formatMessage({
            id: 'admin.promoCodes.cardControls.expandDetails',
          })}
          dataQaidPrefix="promo-codes"
          cardIndex={index}
          extraHeight={false}
        />
      </CardWrapper>
    </>
  );
};

export default PromoCodeCard;
