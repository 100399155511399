import React from 'react';
import styled, { css } from 'styled-components';

import { Icon } from 'app/shared/components/atoms/IconManualCSS';

const LabelWrapper = styled.div`
  ${({ theme }) => css`
    background-color: #9b59b6;
    padding: ${theme.ruler[1]}px ${theme.ruler[2]}px;
    margin: ${theme.ruler[1]}px;
    color: ${theme.colors.whiteDenim};
    height: fit-content;
    border-radius: 3px;
    font-size: 12px;
    display: flex;

    i::before {
      color: ${theme.colors.whiteDenim};
      padding-left: ${theme.ruler[2]}px;
      font-size: 8px;
    }
  `}
`;

const StyledIcon = styled(Icon)`
  display: flex;
  align-items: center;
`;

const CloseButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

interface Props {
  title: string;
  name: string;
  closeButton?: boolean;
  onClose?: any;
}

const Label: React.FC<Props> = ({
  title,
  name,
  closeButton = false,
  onClose = () => {},
}) => {
  return (
    <LabelWrapper key={name} data-qaid="label">
      {title}
      {closeButton && (
        <CloseButton data-qaid="label-close" onClick={onClose}>
          <StyledIcon name="close" />
        </CloseButton>
      )}
    </LabelWrapper>
  );
};

export default Label;
