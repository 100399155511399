import React from 'react';
import { IntlShape } from 'react-intl';

import { Version } from 'app/typings/versions';
import { Link } from 'app/shared/components/atoms/LinkManualCSS';
import TruncatedByCharText from 'app/shared/components/atoms/TruncatedByCharText';
import { UserLink } from 'app/admin/components/molecules/EntityLink';

import { dateOptions, eventOptions } from './options';

const formatVersionChange = (value: any) => {
  if (typeof value === 'string') {
    const date = new Date(value).toUTCString();
    if (date && date !== 'Invalid Date') {
      return date;
    }
  }

  return value;
};

const ChangeSummary: React.FC<{ changeset?: object }> = ({ changeset }) => {
  if (!changeset) {
    return <>N/A</>;
  }

  const textContent = Object.keys(changeset).map((key: string) => {
    const change = changeset[key];

    if (!change[0]) {
      return `${key}: ${formatVersionChange(change[1])}`;
    }
    return `${key}: ${formatVersionChange(change[0])} => ${formatVersionChange(
      change[1]
    )}`;
  });

  return (
    <TruncatedByCharText text={textContent.join(', ')} truncateLength={55} />
  );
};

const eventDisplay = (event: string) => {
  return event.charAt(0).toUpperCase() + event.slice(1);
};

export const rowValues = (version: Version) => {
  return [
    version.itemType,
    version.itemId,
    <Link href={`/admin/versions/${version.id}`} key={`link-to-${version.id}`}>
      {eventDisplay(version.event)}
    </Link>,
    <ChangeSummary
      key={`${version.id}-change-summary`}
      changeset={version.changeset}
    />,
    version.whodunnit ? (
      <UserLink user={version.whodunnit} truncateLength={16} />
    ) : (
      'Platform'
    ),
    version.createdAt ? new Date(version.createdAt).toUTCString() : null,
  ];
};

export const getDropdownOptionsInfoList = (
  intl: IntlShape,
  versionItemTypeOptions: string
) => [
  {
    filterName: 'item_type',
    dropdownParams: {
      searchBar: true,
      title: intl.formatMessage({
        id: 'admin.versions.filter.itemType',
      }),
      options: versionItemTypeOptions,
    },
  },
  {
    filterName: 'event',
    filterDependentOn: 'item_type',
    dropdownParams: {
      searchBar: true,
      dependentFilterErrorIntlId: 'admin.versions.filter.needsItemTypeFilter',
      title: intl.formatMessage({
        id: 'admin.versions.filter.event',
      }),
      options: eventOptions,
    },
  },
  {
    filterName: 'date',
    filterDependentOn: 'item_type',
    dropdownParams: {
      searchBar: true,
      dependentFilterErrorIntlId: 'admin.versions.filter.needsItemTypeFilter',
      title: intl.formatMessage({
        id: 'shared.date',
      }),
      options: dateOptions,
    },
  },
];
