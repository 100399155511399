import React from 'react';
import styled, { css } from 'styled-components';

import { Checkbox } from 'app/shared/components/atoms/CheckboxManualCSS';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';

interface CheckboxGroupOption {
  title: string;
  value: any;
  selected?: boolean;
  fields?: object;
  disabled?: boolean;
  isEmptySpace?: boolean;
}

interface Props {
  name: string;
  options: CheckboxGroupOption[];
  selectedValues: any[];
  onChange(values: any[]): void;
  colProps?: any;
  gridProps?: any;
  className?: string;
}

const CheckboxGroupContainer = styled.div`
  width: 100%;
`;

const StyledCheckbox = styled(Checkbox)`
  white-space: nowrap;
  position: relative;
  display: none;
`;

const CheckboxText = styled.span<{ greyedOut: boolean }>`
  ${({ theme, greyedOut }) => css`
    ${greyedOut && `color: ${theme.colors.blueSmoke};`}
  `}
`;

const CheckboxGroup: React.FC<Props> = ({
  name,
  options,
  selectedValues,
  onChange,
  className,
  colProps = { xs: 12, sm: 4 },
  gridProps = {},
}) => {
  if (options.length === 0) {
    return null;
  }

  return (
    <CheckboxGroupContainer
      className={className}
      data-qaid={`${name}-checkbox-group-container`}
    >
      <Grid {...gridProps}>
        {options.map((opt: CheckboxGroupOption, index: number) => (
          <Col {...colProps} key={index}>
            {opt.isEmptySpace ? (
              <></>
            ) : (
              <StyledCheckbox
                id={`${name}-option-${index}`}
                name={`${name}-option-${index}`}
                data-qaid={`${name}-option-${index}`}
                onChange={(event: any) => {
                  if (event.target.checked) {
                    onChange([...selectedValues, opt.value]);
                  } else {
                    onChange(selectedValues.filter(val => val !== opt.value));
                  }
                }}
                checked={selectedValues.indexOf(opt.value) !== -1}
                disabled={!!opt.disabled}
              >
                <CheckboxText greyedOut={!!opt.disabled}>
                  {opt.title}
                </CheckboxText>
              </StyledCheckbox>
            )}
          </Col>
        ))}
      </Grid>
    </CheckboxGroupContainer>
  );
};

export default CheckboxGroup;
