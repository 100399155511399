import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { Artist } from 'app/typings/artists';
import { errorMsgForField } from 'app/shared/utils/form';
import { Checkbox } from 'app/shared/components/atoms/CheckboxManualCSS';
import {
  FormGroupContainer,
  FormSectionTitle,
} from 'app/shared/components/atoms/FormContent';
import GenericFormContainer from 'app/shared/components/atoms/GenericFormContainer';
import { Radio } from 'app/shared/components/atoms/Radio';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Textfield } from 'app/shared/components/atoms/Textfield';
import FormGroup from 'app/shared/components/molecules/FormGroup';
import ArtistTypeahead from 'app/admin/components/molecules/ArtistTypeahead';
import { ArtistLink } from 'app/admin/components/molecules/EntityLink';
import SimpleDatePicker from 'app/admin/components/molecules/SimpleDatePicker';
import Timepicker from 'app/admin/components/molecules/TimePicker';

interface FormProps {
  formikProps: any;
  validationErrors?: object;
}

const TimezoneNote = styled.h6`
  font-size: 12px;
  font-weight: 500;
  margin: -3px 0 25px 0;
`;

const LocationTextfield = styled(Textfield)`
  ${({ theme }) => css`
    width: 100%;
    ${theme.media.lg`
    width: 65%;
    `};
  `}
`;

const PartnershipTagTextfield = styled(Textfield)`
  width: 220px;
`;

const DonationTextTextfield = styled(Textfield)`
  width: 160px;
`;

const ArtistContainer = styled.div`
  font-size: 14px;
  letter-spacing: 0.4px;
  padding-top: 4px;
  margin-bottom: 12px;
`;

const SelectArtistContainer = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  max-width: 500px;
`;

const SelectArtist = styled.div`
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.1px;
  margin-bottom: 10px;
`;

const getStartDateTime = (startDate?: any, startTime?: string) => {
  // Returns e.g. 2021-12-04T21:15:53Z
  if (startDate && startTime) {
    let year = startDate.year();
    let month = startDate.month() + 1;
    if (month < 10) {
      month = `0${month}`;
    }
    let day = startDate.date();
    if (day < 10) {
      day = `0${day}`;
    }
    let [hours, minutes, seconds] = startTime.split(':');
    hours = hours.replace(/.+T/, '');
    seconds = '00';
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
  }
  return '';
};

const BroadcastEditForm: React.FC<FormProps> = ({
  formikProps,
  validationErrors = {},
}) => {
  const intl = useIntl();

  const { setFieldValue, touched, errors, values } = formikProps;

  return (
    <GenericFormContainer dataQaId="broadcast-edit-form">
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.broadcastEdit.form.scheduledStartTimeUtc',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <TimezoneNote>
          {intl.formatMessage({
            id: 'admin.broadcastEdit.form.utcNote',
          })}
        </TimezoneNote>
        <FormGroup
          label={intl.formatMessage({
            id: 'admin.broadcastEdit.form.startDate',
          })}
          data-qaid="broadcast-edit-form-start-date-formgroup"
          errorMsg={errorMsgForField('startDate', touched, errors)}
          required
        >
          <SimpleDatePicker
            data-qaid="broadcast-edit-form-start-date-datepicker"
            name="startDate"
            value={values.startDate}
            placeholder="Select date"
            onChange={(e: any) => {
              const date = e.startOf('day');
              setFieldValue('startDate', date);
              setFieldValue(
                'scheduledStartTimeUtc',
                getStartDateTime(date, values.startTime)
              );
            }}
          />
        </FormGroup>
        <Spacer mt={4} />
        <FormGroup
          data-qaid="broadcast-edit-form-start-time-formgroup"
          label={intl.formatMessage({
            id: 'admin.broadcastEdit.form.startTime',
          })}
          errorMsg={errorMsgForField('startTime', touched, errors)}
          required
        >
          <Timepicker
            data-qaid="broadcast-edit-form-start-date-timepicker"
            name="startTime"
            value={values.startTime}
            onChange={(value: any) => {
              setFieldValue('startTime', value);
              setFieldValue(
                'scheduledStartTimeUtc',
                getStartDateTime(values.startDate, value)
              );
            }}
          />
        </FormGroup>
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'shared.artist',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        {values.artistId && (
          <ArtistContainer>
            <ArtistLink
              artist={{ id: values.artistId, title: values.artistTitle }}
              truncateLength={30}
            />
          </ArtistContainer>
        )}
        <FormGroup
          data-qaid="broadcast-edit-form-artist-id-formgroup"
          label=""
          errorMsg={
            errorMsgForField('artistId', touched, errors) ||
            // @ts-ignore
            validationErrors.artist
          }
        >
          <SelectArtistContainer>
            <SelectArtist>
              {intl.formatMessage({
                id: 'admin.broadcastEdit.form.selectArtist',
              })}
            </SelectArtist>
            <ArtistTypeahead
              setSelectedArtist={(artist: Artist | undefined) => {
                setFieldValue('artistId', artist && artist.id);
                setFieldValue('artistTitle', artist && artist.title);
              }}
              showArtistEmails={false}
            />
          </SelectArtistContainer>
        </FormGroup>
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.broadcastEdit.form.location',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="broadcast-edit-form-location-formgroup"
          label={intl.formatMessage({
            id: 'admin.broadcastEdit.form.location',
          })}
          errorMsg={errorMsgForField('location', touched, errors)}
          required
        >
          <LocationTextfield
            data-qaid="broadcast-edit-form-location-field"
            id="location"
            name="location"
            value={values.location}
            onChange={(e: any) => {
              setFieldValue('location', e.target.value);
            }}
            maxLength={60}
          />
        </FormGroup>
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.broadcastEdit.form.streamType',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="broadcast-edit-form-stream-type-formgroup"
          label=""
        >
          <>
            <Radio
              id="streamTypeLiveStream"
              value="live_stream"
              name="streamTypeLiveStream"
              checked={values.streamType == 'live_stream'}
              onChange={() => {
                setFieldValue('streamType', 'live_stream');
              }}
            >
              {intl.formatMessage({
                id: 'admin.broadcastEdit.form.streamTypeLiveStream',
              })}
            </Radio>
            <Spacer mt={4} />
            <Radio
              id="streamTypeRerun"
              value="rerun"
              name="streamTypeRerun"
              checked={values.streamType == 'rerun'}
              onChange={() => {
                setFieldValue('streamType', 'rerun');
              }}
            >
              {intl.formatMessage({
                id: 'admin.broadcastEdit.form.streamTypeRerun',
              })}
            </Radio>
          </>
        </FormGroup>
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.broadcastEdit.form.urls',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="broadcast-edit-form-embed-url-formgroup"
          label={intl.formatMessage({
            id: 'admin.broadcastEdit.form.embedUrl',
          })}
          errorMsg={errorMsgForField('embedUrl', touched, errors)}
          required
        >
          <Textfield
            data-qaid="broadcast-edit-form-embed-url-field"
            id="embedUrl"
            name="embedUrl"
            value={values.embedUrl}
            onChange={(e: any) => {
              setFieldValue('embedUrl', e.target.value);
            }}
            maxLength={100}
          />
        </FormGroup>
        <Spacer mt={4} />
        <FormGroup
          data-qaid="broadcast-edit-form-extra-info-url-formgroup"
          label={intl.formatMessage({
            id: 'admin.broadcastEdit.form.extraInfoUrl',
          })}
          errorMsg={errorMsgForField('extraInfoUrl', touched, errors)}
        >
          <Textfield
            data-qaid="broadcast-edit-form-extra-info-url-field"
            id="extraInfoUrl"
            name="extraInfoUrl"
            value={values.extraInfoUrl}
            onChange={(e: any) => {
              setFieldValue('extraInfoUrl', e.target.value);
            }}
            maxLength={100}
          />
        </FormGroup>
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.broadcastEdit.form.additionalInfo',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="broadcast-edit-form-link-type-formgroup"
          label={intl.formatMessage({
            id: 'admin.broadcastEdit.form.linkType',
          })}
        >
          <>
            <Spacer mb={4} />
            <Radio
              id="linkTypeWeb"
              value="Web"
              name="linkTypeWeb"
              checked={values.linkType == 'Web'}
              onChange={() => {
                setFieldValue('linkType', 'Web');
              }}
            >
              Web
            </Radio>
            <Spacer mt={4} />
            <Radio
              id="linkTypeStripe"
              value="Stripe"
              name="linkTypeStripe"
              checked={values.linkType == 'Stripe'}
              onChange={() => {
                setFieldValue('linkType', 'Stripe');
              }}
            >
              Stripe
            </Radio>
          </>
        </FormGroup>
        <Spacer mb={9} />
        <FormGroup
          data-qaid="broadcast-edit-form-partnership-tag-formgroup"
          label={intl.formatMessage({
            id: 'admin.broadcastEdit.form.partnershipTag',
          })}
          errorMsg={errorMsgForField('partnershipTag', touched, errors)}
        >
          <PartnershipTagTextfield
            data-qaid="broadcast-edit-form-partnership-tag-field"
            id="partnershipTag"
            name="partnershipTag"
            value={values.partnershipTag}
            onChange={(e: any) => {
              setFieldValue('partnershipTag', e.target.value);
            }}
            maxLength={30}
          />
        </FormGroup>
        <Spacer mb={9} />
        <Checkbox
          id="broadcast-edit-form-hide-donation-field"
          data-qaid="broadcast-edit-form-hide-donation-field"
          checked={values.hideDonation}
          name="hideDonation"
          onChange={e => setFieldValue('hideDonation', e.target.checked)}
        >
          {intl.formatMessage({
            id: 'admin.broadcastEdit.form.hideDonation',
          })}
        </Checkbox>
        <Spacer mb={9} />
        <FormGroup
          data-qaid="broadcast-edit-form-donation-text-formgroup"
          label={intl.formatMessage({
            id: 'admin.broadcastEdit.form.donationText',
          })}
          errorMsg={errorMsgForField('donationText', touched, errors)}
          required
        >
          <DonationTextTextfield
            data-qaid="broadcast-edit-form-donation-text-field"
            id="donationText"
            name="donationText"
            value={values.donationText}
            onChange={(e: any) => {
              setFieldValue('donationText', e.target.value);
            }}
            maxLength={20}
          />
        </FormGroup>
      </FormGroupContainer>
    </GenericFormContainer>
  );
};

export default BroadcastEditForm;
