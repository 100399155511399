import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { errorMsgForField } from 'app/shared/utils/form';
import {
  FormGroupContainer,
  FormSectionTitle,
} from 'app/shared/components/atoms/FormContent';
import GenericFormContainer from 'app/shared/components/atoms/GenericFormContainer';
import { Textfield } from 'app/shared/components/atoms/Textfield';
import FormGroup from 'app/shared/components/molecules/FormGroup';

interface FormProps {
  formikProps: any;
  validationErrors?: object;
  isNewContinent?: boolean;
}

const StyledTextfield = styled(Textfield)`
  ${({ theme }) => css`
    display: block;
    width: 100%;
    ${theme.media.lg`
      width: 48%;
    `};
  `}
`;

const ContinentCodeTextfield = styled(Textfield)`
  display: block;
  width: 70px;
`;

const ContinentEditForm: React.FC<FormProps> = ({
  formikProps,
  validationErrors = {},
  isNewContinent = false,
}) => {
  const intl = useIntl();

  const { setFieldValue, touched, errors, values } = formikProps;

  return (
    <GenericFormContainer dataQaId="continent-edit-form">
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'shared.title',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="continent-edit-form-title-formgroup"
          label={intl.formatMessage({
            id: 'shared.title',
          })}
          errorMsg={
            errorMsgForField('title', touched, errors) ||
            // @ts-ignore
            validationErrors.title
          }
          required
        >
          <StyledTextfield
            data-qaid="continent-edit-form-title-field"
            id="title"
            name="title"
            value={values.title}
            onChange={(e: any) => {
              setFieldValue('title', e.target.value);
            }}
            maxLength={60}
          />
        </FormGroup>
      </FormGroupContainer>
      {isNewContinent && (
        <>
          <FormSectionTitle>
            {intl.formatMessage({
              id: 'admin.continentBasicInfo.continentCode',
            })}
          </FormSectionTitle>
          <FormGroupContainer>
            <FormGroup
              data-qaid="continent-edit-form-continent-code-formgroup"
              label={intl.formatMessage({
                id: 'admin.continentBasicInfo.continentCode',
              })}
              errorMsg={
                errorMsgForField('continentCode', touched, errors) ||
                // @ts-ignore
                validationErrors.continent_code
              }
              required
            >
              <ContinentCodeTextfield
                data-qaid="continent-edit-form-continent-code-field"
                id="continentCode"
                name="continentCode"
                value={values.continentCode}
                onChange={(e: any) => {
                  setFieldValue('continentCode', e.target.value);
                }}
                maxLength={2}
              />
            </FormGroup>
          </FormGroupContainer>
        </>
      )}
    </GenericFormContainer>
  );
};

export default ContinentEditForm;
