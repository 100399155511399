import React, { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { get } from 'app/shared/utils/get';
import useModal from 'app/shared/utils/useModal';
import { GetCitiesLite } from 'app/shared/graphql/cities/queryHooks';
import { ListPage, ListPageContext } from 'app/shared/context/ListPage';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import { LoadingBlocks } from 'app/shared/components/atoms/LoadingBlocks';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import RoutableModal from 'app/shared/components/molecules/RoutableModal';
import { GlobalError } from 'app/shared/components/pages/Status';
import {
  getCityOptions,
  getFilterNamesAndOptionsForTitleMapping,
} from 'app/admin/utils/optionLists';
import { GetCompetitions } from 'app/admin/graphql/competitions/queryHooks';
import EditIconAndTextLink from 'app/admin/components/atoms/EditIconAndTextLink';
import ListingFooter from 'app/admin/components/molecules/ListingFooter';
import ListingNoResults from 'app/admin/components/molecules/ListingNoResults';
import CardGrid, { CardType } from 'app/admin/components/organisms/CardGrid';
import CompetitionCard from 'app/admin/components/organisms/CompetitionCard';
import ListingControls from 'app/admin/components/organisms/ListingControls';
import ListingFilter from 'app/admin/components/organisms/ListingFilter';
import ListingHeader from 'app/admin/components/organisms/ListingHeader';
import ListPageTemplate from 'app/admin/components/templates/ListPage';
import Layout from 'app/admin/layouts/ListPage';

import { sortOptions, staticOptions } from './options';
import Analytics from './tracking';

const pageStateConfig = {
  filterNames: ['city', 'status', 'closes_at'],
  defaultSort: { by: 'closes_at', direction: 'asc' },
  textSearchParamName: 'competition_search',
  idParamName: 'competition_id',
};

const EditCompetitionTemplatesLinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding-right: 10px;
  margin-bottom: 15px;
`;

const AdminCompetitions: React.FC = () => {
  const intl = useIntl();
  const pageState = useContext(ListPageContext);
  const PER_PAGE = 18;

  const {
    loading: loadingCities,
    error: errorCities,
    data: dataCities,
  } = GetCitiesLite();

  const cityOptions = getCityOptions(dataCities);

  const filterDropdownOptionsInfoList = [
    {
      filterName: 'city',
      dropdownParams: {
        // @ts-ignore
        searchBar: true,
        title: intl.formatMessage({
          id: 'admin.competitions.filter.city',
        }),
        groupBy: 'country',
        options: cityOptions,
      },
    },
    {
      filterName: 'status',
      dropdownParams: {
        title: intl.formatMessage({
          id: 'admin.competitions.filter.status',
        }),
        options: staticOptions.status,
      },
    },
    {
      filterName: 'closes_at',
      dropdownParams: {
        title: intl.formatMessage({
          id: 'admin.competitions.filter.closingDate',
        }),
        options: staticOptions.closesAt,
      },
    },
  ];

  const [addCompetitionModal, toggleAddCompetitionModal] = useModal();
  const [
    competitionTemplatesEditModal,
    toggleCompetitionTemplatesEditModal,
  ] = useModal();

  const isSearchingForCompetitionId = () =>
    pageState.textSearchState && /^\d+$/.test(pageState.textSearchState.trim());

  const shouldFilterByOpen = () =>
    !pageState.filterListVariable('city') &&
    !pageState.filterListVariable('status') &&
    !pageState.filterListVariable('closes_at') &&
    !isSearchingForCompetitionId();

  const {
    loading,
    error,
    data,
    refetch: refetchCompetitions,
  } = GetCompetitions({
    competitionSearch: pageState.textSearchState,
    city: pageState.filterListVariable('city'),
    status: shouldFilterByOpen()
      ? 'open'
      : pageState.filterListVariable('status'),
    closesAt: pageState.filterListVariable('closes_at'),
    orderBy: pageState.sortState.by,
    orderDirection: pageState.sortState.direction,
    page: pageState.page,
    perPage: PER_PAGE,
  });

  useEffect(() => {
    pageState.updateDetailsModal();
  }, []);

  useEffect(() => {
    pageState.updateScrollPositionOnPage();
  }, [pageState.detailsModal.isShowing]);

  useEffect(() => {
    pageState.setupFilterLabelTitleMapping(
      getFilterNamesAndOptionsForTitleMapping(filterDropdownOptionsInfoList)
    );
  }, [dataCities]);

  useEffect(() => {
    pageState.updatePageUrl();
  }, [
    pageState.page,
    pageState.sortState,
    pageState.filterState,
    pageState.textSearchState,
    pageState.detailData,
    pageState.detailsModal.isShowing,
  ]);

  useEffect(() => {
    Analytics.pushDataLayer();
  }, [pageState.filterState, pageState.textSearchState]);

  if (
    (!loading && !data) ||
    error ||
    (!loadingCities && !dataCities) ||
    errorCities
  ) {
    return <GlobalError />;
  }

  const modalsContent = () => (
    <>
      {pageState.detailsModal.isShowing && (
        <RoutableModal
          hide={pageState.detailsModal.hide}
          initialRouteProps={pageState.detailData}
          initialRouteName="competition-details"
          dataQaidSuffix="admin-edit-competition-details"
        />
      )}
      {addCompetitionModal.isShowing && (
        <RoutableModal
          hide={addCompetitionModal.hide}
          initialRouteProps={{ refetchCompetitions }}
          initialRouteName="competition-create"
          dataQaidSuffix="admin-create-competition"
        />
      )}
      {competitionTemplatesEditModal.isShowing && (
        <RoutableModal
          hide={competitionTemplatesEditModal.hide}
          initialRouteProps={{}}
          initialRouteName="competition-templates"
          dataQaidSuffix="admin-edit-competition-templates"
        />
      )}
    </>
  );

  return (
    <Layout scrollDisabled={pageState.detailsModal.isShowing}>
      <ListPageTemplate
        pageTitle={intl.formatMessage({
          id: 'admin.competitions.pageTitle',
        })}
        modalsContent={modalsContent}
      >
        <ListingHeader
          pageTitle={intl.formatMessage({
            id: 'admin.competitions.pageTitle',
          })}
          addEntityText={intl.formatMessage({
            id: 'admin.competitions.addCompetition',
          })}
          onClickAddEntity={toggleAddCompetitionModal}
          searchPlaceholder={intl.formatMessage({
            id: 'admin.competitions.textSearch.placeholder',
          })}
          searchValue={pageState.textSearchState}
          onSearch={pageState.handleTextSearch}
          dataQaidPrefix="competition"
        />
        <EditCompetitionTemplatesLinkContainer>
          <EditIconAndTextLink
            text={intl.formatMessage({
              id: 'admin.competitions.editCompetitionTemplates',
            })}
            onClick={toggleCompetitionTemplatesEditModal}
          />
        </EditCompetitionTemplatesLinkContainer>
        <div>
          <ListingControls
            sortOptions={sortOptions}
            orderBy={pageState.sortState.by}
            orderDirection={pageState.sortState.direction}
            totalRecords={data && data.competitions.metadata.totalRecords}
            loading={loading}
            onSort={pageState.handleSortChange}
            onReset={pageState.handleResetFilters}
            dataQaidPrefix="competition"
          />
          <ListingFilter
            filterTitle={intl.formatMessage({
              id: 'admin.competitions.filterTitle',
            })}
            textSearchString={pageState.textSearchState}
            handleTextSearchLabelClose={pageState.handleTextSearchLabelClose}
            labelTitleMapping={pageState.filterLabelTitleMapping}
            dropdownOptionsInfoList={filterDropdownOptionsInfoList}
            filterState={pageState.filterState}
            handleRemoveFilter={pageState.handleRemoveFilter}
            handleFilterChange={pageState.handleFilterChange}
          />
        </div>

        <div>
          <CardGrid
            objectData={get(data, 'competitions.competitions', [])}
            renderCardComponent={(competition: CardType, i: number) => (
              // @ts-ignore
              <CompetitionCard
                index={i}
                onShowDetails={pageState.toggleDetailsModalAndSetDetailData}
                {...competition}
              />
            )}
            dataQaid="admin-competitions-list"
            loading={loading}
            loadingComponent={
              <LoadingBlocks.Rectangle width="100%" height="420px" />
            }
            hideDividerOnSize="xs"
          />
          <DottedLine />
          <Spacer mb={2} />
        </div>

        <ListingNoResults
          entityName={intl.formatMessage({
            id: 'admin.competitions.noResultsEntityName',
          })}
          numResults={get(data, 'competitions.competitions.length', undefined)}
          loading={loading}
        />

        <ListingFooter
          numTotalRecords={get(
            data,
            'competitions.metadata.totalRecords',
            undefined
          )}
          perPage={PER_PAGE}
          currentPage={pageState.page}
          onPageChange={pageState.handlePageChange}
          loading={loading}
          dataQaidPrefix="competitions"
        />
      </ListPageTemplate>
    </Layout>
  );
};

const AdminCompetitionsWrapper: React.FC = () => (
  <ListPage config={pageStateConfig} analytics={Analytics}>
    <AdminCompetitions />
  </ListPage>
);

export default AdminCompetitionsWrapper;
