import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { get } from 'app/shared/utils/get';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import {
  ContentWrapper,
  FlyoverBackLink,
} from 'app/shared/components/atoms/FlyoverContent';
import { Overline } from 'app/shared/components/atoms/TypographyManualCSS';
import { UpdateVipAttendee } from 'app/admin/graphql/vipAttendees/mutationHooks';
import { GetVipAttendee } from 'app/admin/graphql/vipAttendees/queryHooks';
import {
  LoadingError,
  LoadingIndicator,
} from 'app/admin/components/atoms/GuestlistLoading';
import FlyoverFooter from 'app/admin/components/molecules/FlyoverFooter';
import { FlyoverFormContainer } from 'app/admin/components/molecules/FlyoverFormContent';

import GuestlistVipEditForm from './GuestlistVipEditForm';
import GuestlistVipEditFormSchema from './GuestlistVipEditFormSchema';

interface Props {
  vipAttendeeId?: number;
  onSuccess: Function;
}

const Title = styled.div`
  padding: 5px 0px 10px 0px;
`;

const GuestlistVipEdit: React.FC<Props> = ({ vipAttendeeId, onSuccess }) => {
  const intl = useIntl();
  const [formSubmitAction, setFormSubmitAction] = useState(() => () => {});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [validationErrors, setValidationErrors] = useState<object>({});

  const {
    data: dataVipAttendee,
    loading: loadingVipAttendee,
    error: errorVipAttendee,
  } = GetVipAttendee({
    id: vipAttendeeId,
  });

  const formInitialValues = {
    fullName: get(dataVipAttendee, 'vipAttendee.fullName', ''),
    email: get(dataVipAttendee, 'vipAttendee.email', ''),
    reference: get(dataVipAttendee, 'vipAttendee.reference', ''),
    ticketsCount: get(
      dataVipAttendee,
      'vipAttendee.ticketsCount',
      ''
    ).toString(),
  };

  const updateVipAttendeeAction = UpdateVipAttendee();

  const handleSubmit = useSubmitAction({
    beforeSubmit: () => setIsSubmitting(true),
    submitAction: updateVipAttendeeAction,
    submitVariables: (values: any) => ({
      vipAttendeeId,
      fullName: values.fullName,
      email: values.email,
      reference: values.reference,
      ticketsCount: Number(values.ticketsCount),
    }),
    successMsg: intl.formatMessage({
      id: 'admin.guestlist.vipEdit.editSuccessMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.guestlist.vipEdit.editFailureMessage',
    }),
    onSuccess: () => {
      setIsSubmitting(false);
      onSuccess();
    },
    onValidationError: validationErrors => {
      setIsSubmitting(false);
      setValidationErrors(validationErrors);
    },
  });

  if (loadingVipAttendee) {
    return <LoadingIndicator />;
  }

  if ((!loadingVipAttendee && !dataVipAttendee) || errorVipAttendee) {
    return <LoadingError />;
  }

  return (
    <div data-qaid="guestlist-vip-edit">
      <FlyoverBackLink
        onClickButton={() => {
          onSuccess();
        }}
      />
      <ContentWrapper>
        <Title>
          <Overline>
            {intl.formatMessage({
              id: 'admin.guestlist.vipEdit.editVip',
            })}
          </Overline>
        </Title>
        <DottedLine />
        <FlyoverFormContainer
          validateOnChange
          formInitialValues={formInitialValues}
          renderFormComponent={(renderProps: any) => (
            <GuestlistVipEditForm
              formikProps={renderProps.formikProps}
              // @ts-ignore
              setFormSubmitAction={setFormSubmitAction}
              validationErrors={validationErrors}
            />
          )}
          formSchema={GuestlistVipEditFormSchema(intl)}
          dataQaId="guestlist-vip-edit-form"
          onSubmit={handleSubmit}
        />
      </ContentWrapper>
      <FlyoverFooter
        buttonText={intl.formatMessage({
          id: 'admin.guestlist.vipEdit.updateVip',
        })}
        onClickButton={() => formSubmitAction && formSubmitAction()}
        isSubmitting={isSubmitting}
      />
    </div>
  );
};

export default GuestlistVipEdit;
