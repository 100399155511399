import { useQuery } from 'react-apollo';

import { ObjectImageMap } from 'app/typings';
import { SharedGetDataParameters } from 'app/typings/generics';
import { queryParams } from 'app/shared/utils/queries';
import { GET_IMAGES } from 'app/shared/graphql/images/queries';

interface GetImagesImages {
  images: ObjectImageMap;
}

export interface GetImagesData {
  images: GetImagesImages;
}

export interface GetImagesVariables extends SharedGetDataParameters {
  imageableObjectId: number;
  imageableObjectType: string;
}

export const GetImages = (params: GetImagesVariables) => {
  return useQuery<GetImagesData>(GET_IMAGES, queryParams(params));
};
