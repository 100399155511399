export const sortOptions = [
  {
    title: 'Soonest Date of Concert',
    value: {
      by: 'start',
      direction: 'asc',
    },
  },
  {
    title: 'Latest Date of Concert',
    value: {
      by: 'start',
      direction: 'desc',
    },
  },
];

export const dateOptions = [
  {
    title: 'Today',
    value: 'today',
  },
  {
    title: 'Next 7 Days',
    value: 'next_7_days',
  },
  {
    title: 'Next 30 Days',
    value: 'next_30_days',
  },
  {
    title: 'Past concerts',
    value: 'past_all',
  },
];

export const roleOptions = (intl: any) => {
  const options = [
    {
      title: intl.formatMessage({
        id: 'admin.crewPortal.crewRole.dropdownOption.all',
      }),
      value: 'all_available_roles',
    },
    {
      title: intl.formatMessage({
        id: 'admin.crewPortal.crewRole.dropdownOption.lead',
      }),
      value: 'crew_lead',
    },
    {
      title: intl.formatMessage({
        id: 'admin.crewPortal.crewRole.dropdownOption.emcee',
      }),
      value: 'crew_emcee',
    },
    {
      title: intl.formatMessage({
        id: 'admin.crewPortal.crewRole.dropdownOption.support',
      }),
      value: 'support',
    },
    {
      title: intl.formatMessage({
        id: 'admin.crewPortal.crewRole.dropdownOption.audio',
      }),
      value: 'audio',
    },
    {
      title: intl.formatMessage({
        id: 'admin.crewPortal.crewRole.dropdownOption.photo',
      }),
      value: 'photo',
    },
    {
      title: intl.formatMessage({
        id: 'admin.crewPortal.crewRole.dropdownOption.gearRunner',
      }),
      value: 'gear_runner',
    },
    {
      title: intl.formatMessage({
        id: 'admin.crewPortal.crewRole.dropdownOption.video',
      }),
      value: 'video',
    },
  ];

  return options;
};

export const statusOptions = [
  {
    title: 'Hide Cancelled',
    value: 'exclude_cancelled',
  },
];
