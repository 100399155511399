import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { errorMsgForField } from 'app/shared/utils/form';
import {
  FormGroupContainer,
  FormSectionTitle,
} from 'app/shared/components/atoms/FormContent';
import StyledTextarea from 'app/shared/components/atoms/SmallTextarea';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Textfield } from 'app/shared/components/atoms/Textfield';
import FormGroup from 'app/shared/components/molecules/FormGroup';

interface Props {
  setFieldValue: any;
  touched: any;
  errors: any;
  values: any;
}

const ThirdPartyCtaTextTextfield = styled(Textfield)`
  display: block;
  width: 60%;
`;

const MoreInfoSubheadingTextfield = styled(Textfield)`
  display: block;
  width: 60%;
`;

const FeaturedContentCtaTextTextfield = styled(Textfield)`
  display: block;
  width: 60%;
`;

const CompetitionContentEditFormFields: React.FC<Props> = ({
  setFieldValue,
  touched,
  errors,
  values,
}) => {
  const intl = useIntl();

  return (
    <>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.competitionContent.form.header',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="competition-content-edit-form-intro-formgroup"
          label={intl.formatMessage({
            id: 'admin.competitionContent.form.introduction',
          })}
          errorMsg={errorMsgForField('intro', touched, errors)}
        >
          <Textfield
            data-qaid="competition-content-edit-form-intro-field"
            id="intro"
            name="intro"
            value={values.intro}
            onChange={(e: any) => {
              setFieldValue('intro', e.target.value);
            }}
            maxLength={100}
          />
        </FormGroup>
        <Spacer mb={8} />
        <FormGroup
          data-qaid="competition-content-edit-form-third-party-cta-text-formgroup"
          label={intl.formatMessage({
            id: 'admin.competitionContent.form.thirdPartyCtaText',
          })}
          errorMsg={errorMsgForField('thirdPartyCtaText', touched, errors)}
        >
          <ThirdPartyCtaTextTextfield
            data-qaid="competition-content-edit-form-third-party-cta-text-field"
            id="thirdPartyCtaText"
            name="thirdPartyCtaText"
            value={values.thirdPartyCtaText}
            onChange={(e: any) => {
              setFieldValue('thirdPartyCtaText', e.target.value);
            }}
            maxLength={50}
          />
        </FormGroup>
        <Spacer mb={8} />
        <FormGroup
          data-qaid="competition-content-edit-form-third-party-cta-link-formgroup"
          label={intl.formatMessage({
            id: 'admin.competitionContent.form.thirdPartyCtaLink',
          })}
          errorMsg={errorMsgForField('thirdPartyCtaLink', touched, errors)}
        >
          <Textfield
            data-qaid="competition-content-edit-form-third-party-cta-link-field"
            id="thirdPartyCtaLink"
            name="thirdPartyCtaLink"
            value={values.thirdPartyCtaLink}
            onChange={(e: any) => {
              setFieldValue('thirdPartyCtaLink', e.target.value);
            }}
            maxLength={250}
          />
        </FormGroup>
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.competitionContent.form.body',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="competition-content-edit-form-subtitle-formgroup"
          label={intl.formatMessage({
            id: 'admin.competitionContent.form.bodyTitle',
          })}
          errorMsg={errorMsgForField('subtitle', touched, errors)}
        >
          <Textfield
            data-qaid="competition-content-edit-form-subtitle-field"
            id="subtitle"
            name="subtitle"
            value={values.subtitle}
            onChange={(e: any) => {
              setFieldValue('subtitle', e.target.value);
            }}
            maxLength={200}
          />
        </FormGroup>
        <Spacer mb={5} />
        <FormGroup
          data-qaid="competition-content-edit-form-subtitle-two-formgroup"
          label={intl.formatMessage({
            id: 'admin.competitionContent.form.bodySubheading',
          })}
          errorMsg={errorMsgForField('subtitleTwo', touched, errors)}
        >
          <Textfield
            data-qaid="competition-content-edit-form-subtitle-two-field"
            id="subtitleTwo"
            name="subtitleTwo"
            value={values.subtitleTwo}
            onChange={(e: any) => {
              setFieldValue('subtitleTwo', e.target.value);
            }}
            maxLength={200}
          />
        </FormGroup>
        <Spacer mb={5} />
        <FormGroup
          data-qaid="competition-content-edit-form-description-formgroup"
          label={intl.formatMessage({
            id: 'admin.competitionContent.form.descriptionNotEntered',
          })}
          errorMsg={errorMsgForField('description', touched, errors)}
        >
          <StyledTextarea
            data-qaid="competition-content-edit-form-description-field"
            id="description"
            name="description"
            value={values.description}
            onChange={(e: any) => {
              setFieldValue('description', e.target.value);
            }}
            maxLength={1200}
          />
        </FormGroup>
        <Spacer mb={5} />
        <FormGroup
          data-qaid="competition-content-edit-form-post-description-formgroup"
          label={intl.formatMessage({
            id: 'admin.competitionContent.form.descriptionEntered',
          })}
          errorMsg={errorMsgForField('postDescription', touched, errors)}
        >
          <StyledTextarea
            data-qaid="competition-content-edit-form-post-description-field"
            id="postDescription"
            name="postDescription"
            value={values.postDescription}
            onChange={(e: any) => {
              setFieldValue('postDescription', e.target.value);
            }}
            maxLength={1200}
          />
        </FormGroup>
        <Spacer mb={5} />
        <FormGroup
          data-qaid="competition-content-edit-form-winner-copy-formgroup"
          label={intl.formatMessage({
            id: 'admin.competitionContent.form.descriptionWinner',
          })}
          errorMsg={errorMsgForField('winnerCopy', touched, errors)}
        >
          <StyledTextarea
            data-qaid="competition-content-edit-form-winner-copy-field"
            id="winnerCopy"
            name="winnerCopy"
            value={values.winnerCopy}
            onChange={(e: any) => {
              setFieldValue('winnerCopy', e.target.value);
            }}
            maxLength={1200}
          />
        </FormGroup>
        <Spacer mb={5} />
        <FormGroup
          data-qaid="competition-content-edit-form-expired-copy-formgroup"
          label={intl.formatMessage({
            id: 'admin.competitionContent.form.descriptionExpired',
          })}
          errorMsg={errorMsgForField('expiredCopy', touched, errors)}
        >
          <StyledTextarea
            data-qaid="competition-content-edit-form-expired-copy-field"
            id="expiredCopy"
            name="expiredCopy"
            value={values.expiredCopy}
            onChange={(e: any) => {
              setFieldValue('expiredCopy', e.target.value);
            }}
            maxLength={1200}
          />
        </FormGroup>
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.competitionContent.form.featured',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="competition-content-edit-form-more-info-title-formgroup"
          label={intl.formatMessage({
            id: 'admin.competitionContent.form.featuredTitle',
          })}
          errorMsg={errorMsgForField('moreInfoTitle', touched, errors)}
        >
          <Textfield
            data-qaid="competition-content-edit-form-more-info-title-field"
            id="moreInfoTitle"
            name="moreInfoTitle"
            value={values.moreInfoTitle}
            onChange={(e: any) => {
              setFieldValue('moreInfoTitle', e.target.value);
            }}
            maxLength={100}
          />
        </FormGroup>
        <Spacer mb={8} />
        <FormGroup
          data-qaid="competition-content-edit-form-more-info-subheading-formgroup"
          label={intl.formatMessage({
            id: 'admin.competitionContent.form.featuredSubheading',
          })}
          errorMsg={errorMsgForField('moreInfoSubheading', touched, errors)}
        >
          <MoreInfoSubheadingTextfield
            data-qaid="competition-content-edit-form-more-info-subheading-field"
            id="moreInfoSubheading"
            name="moreInfoSubheading"
            value={values.moreInfoSubheading}
            onChange={(e: any) => {
              setFieldValue('moreInfoSubheading', e.target.value);
            }}
            maxLength={50}
          />
        </FormGroup>
        <Spacer mb={8} />
        <FormGroup
          data-qaid="competition-content-edit-form-more-info-preview-formgroup"
          label={intl.formatMessage({
            id: 'admin.competitionContent.form.preview',
          })}
          errorMsg={errorMsgForField('moreInfoPreview', touched, errors)}
        >
          <StyledTextarea
            data-qaid="competition-content-edit-form-more-info-preview-field"
            id="moreInfoPreview"
            name="moreInfoPreview"
            value={values.moreInfoPreview}
            onChange={(e: any) => {
              setFieldValue('moreInfoPreview', e.target.value);
            }}
            maxLength={1000}
          />
        </FormGroup>
        <Spacer mb={8} />
        <FormGroup
          data-qaid="competition-content-edit-form-featured-content-cta-text-formgroup"
          label={intl.formatMessage({
            id: 'admin.competitionContent.form.featuredContentCtaText',
          })}
          errorMsg={errorMsgForField('featuredContentCtaText', touched, errors)}
        >
          <FeaturedContentCtaTextTextfield
            data-qaid="competition-content-edit-form-featured-content-cta-text-field"
            id="featuredContentCtaText"
            name="featuredContentCtaText"
            value={values.featuredContentCtaText}
            onChange={(e: any) => {
              setFieldValue('featuredContentCtaText', e.target.value);
            }}
            maxLength={50}
          />
        </FormGroup>
        <Spacer mb={8} />
        <FormGroup
          data-qaid="competition-content-edit-form-featured-content-cta-link-formgroup"
          label={intl.formatMessage({
            id: 'admin.competitionContent.form.featuredContentCtaLink',
          })}
          errorMsg={errorMsgForField('featuredContentCtaLink', touched, errors)}
        >
          <Textfield
            data-qaid="competition-content-edit-form-featured-content-cta-link-field"
            id="featuredContentCtaLink"
            name="featuredContentCtaLink"
            value={values.featuredContentCtaLink}
            onChange={(e: any) => {
              setFieldValue('featuredContentCtaLink', e.target.value);
            }}
            maxLength={250}
          />
        </FormGroup>
      </FormGroupContainer>
    </>
  );
};

export default CompetitionContentEditFormFields;
