import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { Partner } from 'app/typings/partners';
import { errorMsgForField } from 'app/shared/utils/form';
import { get } from 'app/shared/utils/get';
import { FormGroupContainer } from 'app/shared/components/atoms/FormContent';
import { BaseLink } from 'app/shared/components/atoms/LinkManualCSS';
import { LoadingError } from 'app/shared/components/atoms/LoadingError';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Spinner } from 'app/shared/components/atoms/SpinnerManualCSS';
import FormGroup from 'app/shared/components/molecules/FormGroup';
import Select from 'app/shared/components/molecules/SelectManualCSS';
import { GetPartners } from 'app/admin/graphql/partners/queryHooks';
import CampaignSelector from 'app/admin/components/molecules/CampaignSelector';

const LinkContainer = styled.div`
  font-size: 14px;
  font-weight: bold;
  line-height: 150%;
  letter-spacing: 1.1px;
  text-transform: uppercase;
  margin-top: 9px;
`;

const Link = styled(BaseLink)`
  ${({ theme }) => css`
    color: ${theme.colors.green600};
  `}
`;

interface CampaignFormGroupProps {
  formikProps: any;
  hasPartnerAccessPermission?: boolean;
  hasCampaignAccessPermission?: boolean;
  dataQaidPrefix?: string;
  required?: boolean;
}

const CampaignFormGroup: React.FC<CampaignFormGroupProps> = ({
  formikProps,
  hasPartnerAccessPermission = false,
  hasCampaignAccessPermission = false,
  dataQaidPrefix = '',
  required = false,
}) => {
  const intl = useIntl();
  const { setFieldValue, setValues, values, errors, touched } = formikProps;

  const {
    loading: loadingPartners,
    error: errorPartners,
    data: dataPartners,
  } = GetPartners({
    archivedStatus: 'not_archived',
    skipPagination: true,
  });

  if (errorPartners) {
    return <LoadingError whatsBeingLoaded="this form" containerSize="small" />;
  }

  if (loadingPartners || !dataPartners) {
    return <Spinner />;
  }

  const partnerOptions = get(dataPartners, 'partners.partners', []);

  return (
    <FormGroupContainer>
      <FormGroup
        data-qaid={`${dataQaidPrefix}-partner-formgroup`}
        label={intl.formatMessage({
          id: 'admin.eventPartnerInfo.form.selectPartner',
        })}
        errorMsg={errorMsgForField('partner', touched, errors)}
        required={required}
      >
        <Select
          data-qaid={`${dataQaidPrefix}-partner-field`}
          searchable
          getOptionLabel={(opt: any) => opt.title}
          defaultValue={values.partner}
          options={partnerOptions}
          placeholder={intl.formatMessage({
            id: 'admin.eventCreate.partnerPlaceholder',
          })}
          onChange={(partner: Partner) => {
            setValues({ ...values, partner, campaign: null });
          }}
        />
      </FormGroup>
      <Spacer mt={4} />

      <FormGroup
        data-qaid={`${dataQaidPrefix}-campaign-formgroup`}
        label={intl.formatMessage({
          id: 'admin.eventPartnerInfo.form.selectCampaign',
        })}
        errorMsg={errorMsgForField('campaign', touched, errors)}
        required={required}
      >
        <CampaignSelector
          partner={values.partner}
          initialValue={values.campaign}
          value={values.campaign}
          onChange={campaign => setFieldValue('campaign', campaign)}
          dataQaidPrefix={dataQaidPrefix}
        />
      </FormGroup>
      {hasPartnerAccessPermission && (
        <>
          <Spacer mb={7} />
          <LinkContainer>
            <Link href="/admin/partners" openInNewTab={true}>
              {intl.formatMessage({
                id: 'admin.eventCreate.partnerDirectoryLink',
              })}
            </Link>
          </LinkContainer>
        </>
      )}
      {hasCampaignAccessPermission && (
        <LinkContainer>
          <Link href="/admin/campaigns" openInNewTab={true}>
            {intl.formatMessage({
              id: 'admin.eventCreate.campaignDirectoryLink',
            })}
          </Link>
        </LinkContainer>
      )}
    </FormGroupContainer>
  );
};

export default CampaignFormGroup;
