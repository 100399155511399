import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { errorMsgForField } from 'app/shared/utils/form';
import {
  FormGroupContainer,
  FormSectionTitle,
} from 'app/shared/components/atoms/FormContent';
import GenericFormContainer from 'app/shared/components/atoms/GenericFormContainer';
import { Radio } from 'app/shared/components/atoms/Radio';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Textfield } from 'app/shared/components/atoms/Textfield';
import FormGroup from 'app/shared/components/molecules/FormGroup';

interface FormProps {
  formikProps: any;
  validationErrors?: object;
  isNewPrimaryRole?: boolean;
}

const StyledTextfield = styled(Textfield)`
  ${({ theme }) => css`
    display: block;
    width: 100%;
    ${theme.media.lg`
        width: 48%;
      `};
  `}
`;

const StyledTextfieldShort = styled(Textfield)`
  display: block;
  width: 200px;
`;

const ResourceTypeGroup = styled.div`
  margin-top: 25px;
`;

const PrimaryRoleBasicInfoEditForm: React.FC<FormProps> = ({
  formikProps,
  validationErrors = {},
  isNewPrimaryRole = false,
}) => {
  const intl = useIntl();

  const { setFieldValue, touched, errors, values } = formikProps;

  return (
    <GenericFormContainer dataQaId="primary-role-edit-form">
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'shared.name',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="primary-role-edit-form-display-name-formgroup"
          label={intl.formatMessage({
            id: 'admin.primaryRoleBasicInfo.roleName',
          })}
          errorMsg={
            errorMsgForField('displayName', touched, errors) ||
            // @ts-ignore
            validationErrors.name ||
            // @ts-ignore
            validationErrors.display_name
          }
          required
        >
          <StyledTextfield
            data-qaid="primary-role-edit-form-display-name-field"
            id="displayName"
            name="displayName"
            value={values.displayName}
            onChange={(e: any) => {
              setFieldValue('displayName', e.target.value);
            }}
            maxLength={30}
          />
        </FormGroup>
        <Spacer mt={6} />
        <FormGroup
          data-qaid="primary-role-edit-form-short-display-name-formgroup"
          label={intl.formatMessage({
            id: 'admin.primaryRoleBasicInfo.shortRoleName',
          })}
          errorMsg={errorMsgForField('shortDisplayName', touched, errors)}
        >
          <StyledTextfieldShort
            data-qaid="primary-role-edit-form-short-display-name-field"
            id="shortDisplayName"
            name="shortDisplayName"
            value={values.shortDisplayName}
            onChange={(e: any) => {
              setFieldValue('shortDisplayName', e.target.value);
            }}
            maxLength={18}
          />
        </FormGroup>
      </FormGroupContainer>
      {isNewPrimaryRole && (
        <>
          <FormSectionTitle>
            {intl.formatMessage({
              id: 'admin.shared.type',
            })}
          </FormSectionTitle>
          <FormGroupContainer>
            <FormGroup
              data-qaid="primary-role-edit-form-resource-type-formgroup"
              label={intl.formatMessage({
                id: 'admin.primaryRoleBasicInfo.resourceTypeNotes',
              })}
            >
              <ResourceTypeGroup>
                <Radio
                  id="resourceTypeNone"
                  value="None"
                  name="resourceTypeNone"
                  checked={values.resourceType == 'None'}
                  onChange={() => {
                    setFieldValue('resourceType', 'None');
                  }}
                >
                  {intl.formatMessage({
                    id: 'admin.primaryRoleBasicInfo.resourceTypeNone',
                  })}
                </Radio>
                <Spacer mt={4} />
                <Radio
                  id="resourceTypeCuratorGroup"
                  value="CuratorGroup"
                  name="resourceTypeCuratorGroup"
                  checked={values.resourceType == 'CuratorGroup'}
                  onChange={() => {
                    setFieldValue('resourceType', 'CuratorGroup');
                  }}
                >
                  {intl.formatMessage({
                    id: 'admin.shared.curatorGroup',
                  })}
                </Radio>
                <Spacer mt={4} />
                <Radio
                  id="resourceTypeCity"
                  value="City"
                  name="resourceTypeCity"
                  checked={values.resourceType == 'City'}
                  onChange={() => {
                    setFieldValue('resourceType', 'City');
                  }}
                >
                  {intl.formatMessage({
                    id: 'shared.city',
                  })}
                </Radio>
                <Spacer mt={4} />
                <Radio
                  id="resourceTypeBusinessOwner"
                  value="BusinessOwner"
                  name="resourceTypeBusinessOwner"
                  checked={values.resourceType == 'BusinessOwner'}
                  onChange={() => {
                    setFieldValue('resourceType', 'BusinessOwner');
                  }}
                >
                  {intl.formatMessage({
                    id: 'shared.businessOwner',
                  })}
                </Radio>
              </ResourceTypeGroup>
            </FormGroup>
          </FormGroupContainer>
        </>
      )}
    </GenericFormContainer>
  );
};

export default PrimaryRoleBasicInfoEditForm;
