export const sortOptions = [
  {
    title: 'Artist Name (A - Z)',
    value: {
      by: 'artist_name',
      direction: 'asc',
    },
  },
  {
    title: 'Artist Name (Z - A)',
    value: {
      by: 'artist_name',
      direction: 'desc',
    },
  },
  {
    title: 'Shortest Time Since Last Concert',
    value: {
      by: 'last_event_at',
      direction: 'desc',
    },
  },
  {
    title: 'Longest Time Since Last Concert',
    value: {
      by: 'last_event_at',
      direction: 'asc',
    },
  },
  {
    title: 'Most Concerts Played',
    value: {
      by: 'past_events_count',
      direction: 'desc',
    },
  },
  {
    title: 'Least Concerts Played',
    value: {
      by: 'past_events_count',
      direction: 'asc',
    },
  },
  {
    title: 'Most Recently Added',
    value: {
      by: 'created_at',
      direction: 'desc',
    },
  },
];

export const staticOptions = {
  eventScope: [
    {
      title: 'Upcoming Concerts',
      value: 'upcoming_events',
    },
    {
      title: 'Played At Least 1 Concert',
      value: 'played_at_least_1_show',
    },
    {
      title: 'Played 0 Concerts',
      value: 'played_0_shows',
    },
  ],
};
