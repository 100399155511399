import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Event } from 'app/typings/events';
import { dateFormatter } from 'app/shared/utils/datetime';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import {
  InlineHtml,
  SectionText,
  SectionTitle,
  SectionWithIcon,
  Subheading,
} from 'app/admin/components/atoms/CrewEventContent';
import IconInCircle from 'app/admin/components/atoms/IconInCircle';
import EventTimes from 'app/admin/components/molecules/EventTimes';
import { TagElement } from 'app/admin/components/molecules/TagElementList';
import { ReactComponent as ConcertRock1 } from 'icons/streamline-regular/entertainment-events-hobbies/concerts-night-clubs/concert-rock-1.svg';

interface EventInfoProps {
  event: Event;
  intl: any;
}

const CancelledTagWrapper = styled.div`
  width: 80px;
  padding: 0px;
  margin-top: 40px;
  margin-bottom: 20px;
`;

const CancelledTagElement = styled(TagElement)`
  text-align: center;
  padding: 1px 5px;
  margin-top: -21px;
  margin-right: -2px;
  height: 18px;
  border-radius: 0px 0px 2px 2px;

  @media screen and (min-width: 1200px) and (max-width: 1300px) {
    font-size: 9px;
  }
`;

export const CancelledTag: React.FC = () => {
  const intl = useIntl();

  return (
    <CancelledTagWrapper>
      <CancelledTagElement
        tagElementColor="#EF4423"
        textColor="#fff"
        data-qaid="tag-element-cancel"
      >
        {intl.formatMessage({
          id: 'admin.eventPlanner.cancelledStatus',
        })}
      </CancelledTagElement>
      <Spacer mr={1} />
    </CancelledTagWrapper>
  );
};

const EventTimesContainer = styled.div`
  max-width: 250px;
`;

const CrewEventBasicInfo: React.FC<EventInfoProps> = ({ event, intl }) => {
  return (
    <SectionWithIcon>
      <SectionTitle>
        <IconInCircle
          borderCircle={true}
          circleBackgroundColor="whiteDenim"
          dataQaIdSuffix="concert-rock"
          iconColor="checkBerry"
          iconName={ConcertRock1}
          iconSize={18}
        />
        {intl.formatMessage({ id: 'admin.crewEvent.section.timingInfo' })}
      </SectionTitle>
      {event.cancelled && <CancelledTag />}
      <SectionText>
        <Spacer mt={4} />
        <>
          <Subheading>{intl.formatMessage({ id: 'shared.date' })}:</Subheading>
          &nbsp;
          {dateFormatter(
            event?.startsAt || '',
            'longWeekdayLongMonthDateAndYear',
            event?.city?.timezone
          )}
        </>
        <Spacer mt={4} />
        <>
          <Subheading>{intl.formatMessage({ id: 'shared.city' })}:</Subheading>
          &nbsp;
          {[event?.city?.title, event?.neighborhood?.title]
            .filter((part: any) => !!part)
            .join(', ')}
        </>
        <Spacer mt={4} />
        <>
          <Subheading>
            {intl.formatMessage({ id: 'admin.shared.sponsor' })}:
          </Subheading>
          &nbsp;
          {event.campaign?.partner?.title || intl.formatMessage({ id: 'none' })}
        </>
        <Spacer mt={4} />
        <>
          <Subheading>
            {intl.formatMessage({
              id: 'admin.shared.eventTimes.loadInLoadOutTimes',
            })}
            :
          </Subheading>
          &nbsp;
        </>
        <EventTimesContainer>
          <EventTimes
            crewLoadInAt={event.crewLoadInAt}
            artistLoadInAt={event.artistLoadInAt}
            guestsArriveAt={event.guestsArriveAt}
            startsAt={event.startsAt}
            endsAt={event.endsAt}
            timezone={event.city && event.city.timezone}
            displayHeading={false}
          />
        </EventTimesContainer>
        <Spacer mt={4} />
        <>
          <Subheading>
            {intl.formatMessage({ id: 'admin.shared.timeNotes' })}:
          </Subheading>
          &nbsp;
          <InlineHtml
            dangerouslySetInnerHTML={{
              __html: event.timingNotes || intl.formatMessage({ id: 'none' }),
            }}
          />
        </>
        <Spacer mt={4} />
        <>
          <Subheading>
            {intl.formatMessage({ id: 'admin.crewPortal.setup.theme' })}:
          </Subheading>
          &nbsp;
          {event.theme?.title || intl.formatMessage({ id: 'none' })}
        </>
        <Spacer mt={4} />
        <>
          <Subheading>
            {intl.formatMessage({ id: 'admin.crewEvent.field.themeNotes' })}:
          </Subheading>
          &nbsp;
          <InlineHtml
            dangerouslySetInnerHTML={{
              __html: event.theme?.notes || intl.formatMessage({ id: 'none' }),
            }}
          />
        </>
      </SectionText>
    </SectionWithIcon>
  );
};

export default CrewEventBasicInfo;
