import React, { useState } from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { City } from 'app/typings/cities';
import { Event } from 'app/typings/events';
import {
  COMMERCIAL_PARTNERSHIP_EVENT,
  DISCOVERY_EVENT,
  FEATURED_SET_EVENT,
  PRIVATE_DISCOVERY_EVENT,
} from 'app/shared/utils/events';
import { get } from 'app/shared/utils/get';
import {
  citySocialUrls,
  getSocialUrlsFormInitialValues,
  getSocialUrlsSubmitVariable,
} from 'app/shared/utils/socialLinks';
import useModal from 'app/shared/utils/useModal';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import UpdateOptionsModal from 'app/shared/components/organisms/UpdateOptionsModal';
import {
  getTitleFormInitialValue,
  getTitlesSubmitVariable,
} from 'app/admin/utils/titleByLocale';
import { UpdateCity } from 'app/admin/graphql/cities/mutationHooks';
import { GetEventsForCityEdit } from 'app/admin/graphql/events/queryHooks';
import { DetailsFormMainContainer } from 'app/admin/components/atoms/DetailContent';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

import CityBasicInfoForm from './CityBasicInfoForm';
import CityBasicInfoFormSchema from './CityBasicInfoFormSchema';

interface Props {
  contentProps?: any;
  setFormSubmitAction: (func: Function) => void;
  setDisplayConfirmation: (set: boolean) => void;
  setIsSubmitting: (set: boolean) => void;
  navigateTo: (routeData: object) => void;
}

const ErrorMessage = styled.div<any>`
  ${({ theme }) => css`
    width: 100%;
    color: ${theme.colors.redRedWine};
    font-size: ${theme.fontSizes.caption};
  `}
`;

const CityBasicInfoEdit: React.FC<Props> = ({
  contentProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  navigateTo,
}) => {
  const intl = useIntl();

  const [validationErrors, setValidationErrors] = useState<object>({});
  const [submitData, setSubmitData] = useState({});
  const [updateOptionsModal, toggleUpdateOptionsModal] = useModal();

  const {
    error: errorEvents,
    loading: loadingEvents,
    data: dataEvents,
  } = GetEventsForCityEdit({
    city: contentProps.cachedSlug,
    upcoming: true,
    eventOrganizedAs: 'o_and_o',
    type: [
      DISCOVERY_EVENT,
      FEATURED_SET_EVENT,
      COMMERCIAL_PARTNERSHIP_EVENT,
      PRIVATE_DISCOVERY_EVENT,
    ].toString(),
    page: 1,
    perPage: 1000,
  });

  const formInitialValues = {
    isNewCity: false,
    titleEn: getTitleFormInitialValue(contentProps, 'en'),
    titleEs: getTitleFormInitialValue(contentProps, 'es'),
    titleIt: getTitleFormInitialValue(contentProps, 'it'),
    countryId: get(contentProps, 'country.id', null),
    businessOwnerId: get(contentProps, 'businessOwner.id', null),
    latitude: get(contentProps, 'latitude', ''),
    longitude: get(contentProps, 'longitude', ''),
    cityStatus: get(contentProps, 'cityStatus', ''),
    cityOperatingModel: get(contentProps, 'cityOperatingModel', ''),
    sofarOperatedMarketType: get(contentProps, 'sofarOperatedMarketType', ''),
    isAcceptingArtistRequests: get(
      contentProps,
      'isAcceptingArtistRequests',
      false
    ),
    cachedSlug: get(contentProps, 'cachedSlug', ''),
    aliases: get(contentProps, 'aliases', ''),
    description: get(contentProps, 'description', ''),
    whatToExpect: get(contentProps, 'whatToExpect', ''),
    nearbySecondaryCities: get(contentProps, 'nearbySecondaryCities', []),
    socialUrls: getSocialUrlsFormInitialValues(
      citySocialUrls,
      contentProps,
      'socialUrls'
    ),
  };

  const updateCityAction = UpdateCity();

  const handleSubmit = useSubmitAction({
    submitAction: updateCityAction,
    submitVariables: (values: any) => ({
      cityId: get(contentProps, 'id', undefined),
      titles: getTitlesSubmitVariable(values),
      countryId: values.countryId,
      businessOwnerId: [
        'sofar_operated',
        'sofar_operated_and_curator',
      ].includes(values.cityOperatingModel)
        ? values.businessOwnerId
        : null,
      latitude: values.latitude ? parseFloat(values.latitude) : null,
      longitude: values.longitude ? parseFloat(values.longitude) : null,
      cityStatus: values.cityStatus,
      cityOperatingModel: values.cityOperatingModel,
      sofarOperatedMarketType: [
        'sofar_operated',
        'sofar_operated_and_curator',
      ].includes(values.cityOperatingModel)
        ? values.sofarOperatedMarketType
        : null,
      isAcceptingArtistRequests: values.isAcceptingArtistRequests,
      cachedSlug: values.cachedSlug,
      aliases: values.aliases,
      description: values.description,
      whatToExpect: values.whatToExpect,
      nearbySecondaryCitiesIds: values.nearbySecondaryCities.map(
        (city: City) => city.id
      ),
      socialUrls: getSocialUrlsSubmitVariable(values),
      applyMethod: values.applyMethod,
      selectedValues: values.selectedValues,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.cityBasicInfo.form.successMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.cityBasicInfo.form.failureMessage',
    }),
    onSuccess: response => {
      navigateTo({
        routeName: 'city-details',
        routeProps: {
          defaultOpenSection: 'basicInfo',
          ...response.data.updateCity.city,
        },
      });
    },
    onValidationError: validationErrors => {
      setValidationErrors({ cached_slug: validationErrors.general });
    },
  });

  return (
    <ModalContentContainer>
      <DetailsFormMainContainer>
        <DetailsHeader title={get(contentProps, 'title', '')} />
        <GenericForm
          formInitialValues={formInitialValues}
          renderFormComponent={(renderProps: any) => (
            <CityBasicInfoForm
              formikProps={renderProps.formikProps}
              validationErrors={validationErrors}
            />
          )}
          onSubmit={data => {
            if (
              formInitialValues.sofarOperatedMarketType ==
                data.sofarOperatedMarketType &&
              formInitialValues.businessOwnerId == data.businessOwnerId
            ) {
              handleSubmit(data);
            } else {
              setIsSubmitting(false);
              setSubmitData(data);
              return toggleUpdateOptionsModal();
            }
          }}
          formSchema={CityBasicInfoFormSchema(intl)}
          setFormSubmitAction={setFormSubmitAction}
          setDisplayConfirmation={setDisplayConfirmation}
          setIsSubmitting={setIsSubmitting}
          dataQaId="city-about-section-edit-form"
        />
        {updateOptionsModal.isShowing &&
          (errorEvents ? (
            <ErrorMessage data-qaid="city-basic-info-events-error">
              {intl.formatMessage({
                id: 'admin.citySettings.form.getEventsError',
              })}
            </ErrorMessage>
          ) : (
            <UpdateOptionsModal
              onCancel={() => updateOptionsModal.hide()}
              onConfirm={(data: any) => {
                setIsSubmitting(true);
                updateOptionsModal.hide();
                handleSubmit({
                  ...submitData,
                  ...data,
                });
              }}
              dataQaidPrefix="city-basic-info-options-modal"
              modalType="city"
              isLoading={loadingEvents}
              title={intl.formatMessage({
                id: 'admin.updateOptionsModal.title',
              })}
              options={get(dataEvents, 'events.events', []).map(
                (ev: Event) => ({
                  title:
                    ev.localStartsAt &&
                    moment.utc(ev.localStartsAt).format('MMM DD YYYY hh:mm'),
                  value: ev.id,
                })
              )}
            />
          ))}
      </DetailsFormMainContainer>
    </ModalContentContainer>
  );
};

export default CityBasicInfoEdit;
