import React from 'react';
import { useIntl } from 'react-intl';

import ImageEdit from 'app/shared/components/organisms/ImageEdit';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

interface Props {
  contentProps?: any;
  setFormSubmitAction: (func: Function) => void;
  setDisplayConfirmation: (set: boolean) => void;
  setIsSubmitting: (set: boolean) => void;
  setDisplaySubmitButton: (set: boolean) => void;
  navigateTo: (routeData: object) => void;
}

const getRenderHeaderContent = (title?: string, imageUrl?: string) => {
  return (headerActionLinksInfo?: any) => (
    <DetailsHeader
      title={title}
      imageUrl={imageUrl}
      imageStyle="landscape"
      actionLinksInfo={headerActionLinksInfo}
    />
  );
};

const VenueProfileImageUploader: React.FC<Props> = ({
  contentProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  setDisplaySubmitButton,
  navigateTo,
}) => {
  const intl = useIntl();

  const onUploadSuccess = (response: any) => {
    navigateTo({
      routeName: 'venue-details',
      routeProps: response.data.updateImages.venue,
    });
  };

  const onDeleteSuccess = (response: any) => {
    navigateTo({
      routeName: 'venue-details',
      routeProps: response.data.updateImages.venue,
    });
  };

  return (
    <ImageEdit
      objectType="Venue"
      objectId={contentProps.id}
      imageFieldName="mainImage"
      imageFieldDescription={intl.formatMessage({
        id: 'admin.venueProfileImageEdit.imageDescription',
      })}
      imageUrl={contentProps.imageUrl}
      cropperRatio={8 / 5}
      onUploadSuccess={onUploadSuccess}
      onDeleteSuccess={onDeleteSuccess}
      defaultImageTextRegex=".*venue-image-placeholder.*"
      renderHeaderContent={getRenderHeaderContent(
        contentProps.title,
        contentProps.imageUrl
      )}
      headerTitle={intl.formatMessage({
        id: 'admin.venueProfileImageEdit.editProfileImage',
      })}
      setFormSubmitAction={setFormSubmitAction}
      setDisplayConfirmation={setDisplayConfirmation}
      setIsSubmitting={setIsSubmitting}
      setDisplaySubmitButton={setDisplaySubmitButton}
      dataQaidPrefix="venue-image"
    />
  );
};

export default VenueProfileImageUploader;
