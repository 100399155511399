import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import useModal from 'app/shared/utils/useModal';
import GenericFormContainer from 'app/shared/components/atoms/GenericFormContainer';
import { DeleteIcon } from 'app/shared/components/atoms/IconLibrary';
import LazyImgixImage from 'app/shared/components/atoms/LazyImgixLoad';
import { Spacer, Spacer24 } from 'app/shared/components/atoms/Spacer';
import { Textfield } from 'app/shared/components/atoms/Textfield';
import { H6 } from 'app/shared/components/atoms/TypographyManualCSS';
import ConfirmationModal from 'app/shared/components/molecules/ConfirmationModal';
import FormGroup from 'app/shared/components/molecules/FormGroup';
import ImageUploaderWithCropper from 'app/shared/components/organisms/ImageUploaderWithCropper';
import RichTextEditor from 'app/admin/components/molecules/RichTextEditor';

interface FormProps {
  formikProps: any;
  modalRef: React.RefObject<HTMLDivElement>;
  setButtonConfigs?: (buttonConfigs: any) => void;
  setGoBackAction?: (func: Function) => void;
  setGoForwardAction?: (func: Function) => void;
  setFormSubmitAction?: (func: Function) => void;
  setDisplayConfirmation?: (set: boolean) => void;
  setIsSubmitting?: (set: boolean) => void;
  setCropAction?: (func: Function) => Promise<Record<string, any>>;
  venuePhotoToEdit?: any;
}

const StyledTextfield = styled(Textfield)`
  ${({ theme }) => css`
    display: block;
    width: 100%;
    ${theme.media.lg`
        width: 50%;
      `};
  `}
`;

const StyledFormGroup = styled(FormGroup)`
  margin-left: 30px;
`;

const PreviewImage = styled.div`
  ${({ theme }) => css`
    background-position: center;
    background-size: cover;
    border: 1px solid ${theme.colors.green600};
    border-radius: 10px;
    height: 140px;
    width: 224px;
    overflow: hidden;
  `}
`;

const IconWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    color: ${theme.colors.green500};
    cursor: pointer;
    &:hover {
      color: ${theme.colors.primary};
    }
  `}
`;

const DeleteText = styled.div`
  font-size: 14px;
  margin-top: 1px;
  margin-left: 8px;
`;

const ImageSection = styled.div`
  margin-left: 35px;
  margin-bottom: -25px;
  margin-top: -30px;
`;

const VenuePhotoUploadForm: React.FC<FormProps> = ({
  formikProps,
  modalRef,
  setButtonConfigs,
  setGoBackAction,
  setGoForwardAction,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  setCropAction,
  venuePhotoToEdit,
}) => {
  const { setFieldValue, touched, errors, values } = formikProps;

  const [previewConfirmationModal, togglePreviewConfirmationModal] = useModal();
  const [isEditing, setIsEditing] = useState(false);
  const [replacingImage, setReplacingImage] = useState(false);

  useEffect(() => {
    const getButtonConfigs = () => ({
      submit: {
        textKey: 'form.save',
        displayed: true,
        disabled: !isEditing && !(values.image && values.title),
      },
    });

    if (venuePhotoToEdit) {
      setIsEditing(true);
    }

    setButtonConfigs && setButtonConfigs(getButtonConfigs());
    setFormSubmitAction && setFormSubmitAction(() => formikProps.submitForm);
    setDisplayConfirmation && setDisplayConfirmation(formikProps.dirty);
    setIsSubmitting && setIsSubmitting(formikProps.isSubmitting);
  }, [
    formikProps.isSubmitting,
    formikProps.submitForm,
    formikProps.dirty,
    modalRef,
    setButtonConfigs,
    setGoBackAction,
    setGoForwardAction,
    setFormSubmitAction,
    setDisplayConfirmation,
    setIsSubmitting,
    values.image,
    values.description,
    values.title,
  ]);

  const intl = useIntl();

  const getImageErrorMessage = (error: any) => {
    // Given that image is an object, error can sometimes be an object and not a string
    if (error && typeof error === 'object') {
      return error[Object.keys(error)[0]];
    }
    return error;
  };

  const errorMsgForField = (field: string, touched: any, errors: any) =>
    touched[field] && errors[field] ? errors[field] : null;

  return (
    <GenericFormContainer dataQaId="venue-photo-upload-form">
      <>
        {!isEditing || replacingImage ? (
          <ImageUploaderWithCropper
            largeCropper={true}
            imagePreviewShape="square"
            previewWithUploader={false}
            formatAndUploadSizeInfo="imageUploader.defaultFormatAndUploadSizeInfo"
            numberOfImages={1}
            cropperRatio={8 / 5}
            maxFileSizeInKB={25000}
            hideCropButton={true}
            onChange={(value: any) => {
              setFieldValue('image', value);

              if (Object.keys(value).length === 0 && !replacingImage) {
                setFieldValue('title', '');
                setFieldValue('description', '');
              }
            }}
            images={[values.image]}
            //@ts-ignore
            setCropAction={setCropAction}
            errorMsg={getImageErrorMessage(
              errorMsgForField('image', touched, errors)
            )}
          />
        ) : (
          <ImageSection>
            <H6 spaceAfter={3}>
              {intl.formatMessage({
                id: 'imageCropper.previewImage',
              })}
            </H6>
            <PreviewImage>
              <LazyImgixImage
                src={values.venuePhoto?.imageUrl}
                sizes="44px"
                aspectRatio="1.5:1"
                dataQaid="venue-image"
              />
            </PreviewImage>
            <>
              <Spacer24 />
              <IconWrapper
                onClick={togglePreviewConfirmationModal}
                data-qaid="image-cropper-remove"
              >
                <DeleteIcon iconSize={14} />
                <DeleteText>
                  {intl.formatMessage({
                    id: 'shared.replaceThisImage',
                  })}
                </DeleteText>
              </IconWrapper>
            </>
          </ImageSection>
        )}
        <Spacer mt={18} />
        {(values.image?.name || values.venuePhoto?.id || isEditing) && (
          <>
            <StyledFormGroup
              label={intl.formatMessage({
                id: 'shared.title',
              })}
              data-qaid="venue-photo-title-formgroup"
              required={true}
            >
              <StyledTextfield
                data-qaid="venue-photo-title-form-field"
                id="venuePhotoTitle"
                name="venuePhotoTitle"
                value={values.title}
                maxLength={200}
                onChange={(e: any) => setFieldValue('title', e.target.value)}
                disabled={false}
              />
            </StyledFormGroup>
            <Spacer mt={7} />
            <StyledFormGroup
              data-qaid="venue-photo-description-formgroup"
              label={intl.formatMessage({
                id: 'form.fields.notes',
              })}
            >
              <RichTextEditor
                name="description"
                currentValue={values.description}
                onChange={value => {
                  setFieldValue('description', value);
                }}
              />
            </StyledFormGroup>
          </>
        )}
      </>
      {previewConfirmationModal.isShowing && (
        <ConfirmationModal
          onCancel={() => previewConfirmationModal.hide()}
          description={intl.formatMessage({
            id: 'shared.replaceConfirmation',
          })}
          onConfirm={() => {
            previewConfirmationModal.hide();
            setFieldValue('image', undefined);
            setIsEditing(false);
            setReplacingImage(true);
          }}
          confirmationButtonText={intl.formatMessage({
            id: 'shared.delete',
          })}
          cancellationButtonText={intl.formatMessage({
            id: 'shared.cancel',
          })}
        />
      )}
    </GenericFormContainer>
  );
};

export default VenuePhotoUploadForm;
