import * as Yup from 'yup';

import { maxEventPriceMainUnits } from 'app/admin/utils/events';

const curatorGroupBasicInfoFormSchema = (intl: any) =>
  Yup.object().shape({
    name: Yup.string().required(
      intl.formatMessage({
        id: 'form.requiredField',
      })
    ),
    city: Yup.string().when('isNewCuratorGroup', {
      is: true,
      then: Yup.string()
        .nullable()
        .required(
          intl.formatMessage({
            id: 'form.requiredField',
          })
        ),
    }),
    curatorGroupType: Yup.string().required(
      intl.formatMessage({
        id: 'form.requiredField',
      })
    ),
    status: Yup.string().when('isNewCuratorGroup', {
      is: false,
      then: Yup.string().required(
        intl.formatMessage({
          id: 'form.requiredField',
        })
      ),
    }),
    eventPrice: Yup.string()
      .nullable()
      .matches(/^\d+$/, {
        message: intl.formatMessage({
          id: 'admin.curatorGroupBasicInfo.form.numberPriceError',
        }),
      })
      .test(
        'event-price-too-large',
        intl.formatMessage(
          {
            id: 'admin.eventBasicInfo.form.numberPriceTooLargeError',
          },
          {
            maxPrice: String(maxEventPriceMainUnits),
          }
        ),
        function(value: string) {
          return (
            (!value && Number(value) != 0) ||
            Number(value) <= maxEventPriceMainUnits
          );
        }
      ),
  });

export default curatorGroupBasicInfoFormSchema;
