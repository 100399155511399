import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { LinkButton } from 'app/shared/components/atoms/Button';
import InfoBox from 'app/shared/components/molecules/Flyover';

import ArtistsActionsFlyover from './ArtistsActionsFlyover';

interface Props {
  toggleCreateOfferModal: Function;
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  padding-right: 8px;
`;

const StyledLinkButton = styled(LinkButton)`
  padding: 0;
  font-weight: 700;
  &:focus {
    background: none;
    border: none;
  }

  &:hover {
    border: none;
  }
`;

const ArtistsActions: React.FC<Props> = ({ toggleCreateOfferModal }) => {
  const intl = useIntl();

  const [flyoverClosed, setFlyoverClosed] = useState(false);

  return (
    <MainContainer>
      <InfoBox
        zIndex={102}
        showCaret={false}
        width="300px"
        triggerElement={
          <StyledLinkButton>
            {intl.formatMessage({ id: 'admin.artistDirectory.actions' })}
          </StyledLinkButton>
        }
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepInViewPort={{
          vertical: true,
          horizontal: true,
        }}
        offset={{
          vertical: 40,
        }}
        onStateChange={isOpen => setFlyoverClosed(!isOpen)}
        shouldFlyoverClose={flyoverClosed}
        innerContent={
          <ArtistsActionsFlyover
            onSuccess={() => {
              setFlyoverClosed(true);
              toggleCreateOfferModal();
            }}
          />
        }
      />
    </MainContainer>
  );
};

export default ArtistsActions;
