import React from 'react';
import styled, { css } from 'styled-components';

import { CrewMessage } from 'app/typings/staffMembers';
import { User } from 'app/typings/users';
import { dateFormatter } from 'app/shared/utils/datetime';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import { Overline } from 'app/shared/components/atoms/TypographyManualCSS';
import { userFullNameOrEmail } from 'app/admin/utils/users';
import { SectionText } from 'app/admin/components/atoms/CrewEventContent';
import ExpandableText from 'app/admin/components/atoms/ExpandableText';
import IconButton from 'app/admin/components/molecules/IconButton';
import { ReactComponent as Bin1 } from 'icons/streamline-regular/interface-essential/delete/bin-1.svg';

export const CrewMessageContainer = styled.div`
  margin-top: 15px;
  margin-bottom: 10px;
`;

export const ToggleContainer = styled.div`
  margin-top: 8px;
  text-align: center;
`;

export const ToggleText = styled.span<{ isAdmin?: boolean }>`
  ${({ theme, isAdmin }) => css`
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 1.5px;
    color: ${isAdmin ? theme.colors.green600 : theme.colors.yellowSubmarine};
    cursor: pointer;
    text-transform: uppercase;
  `}
`;

export const CrewMessageInfo = styled.div`
  line-height: 1px;
`;

export const CrewMessageText = styled.div<{ isAdmin?: boolean }>`
  margin: 4px 0px 14px 0px;

  ${({ theme, isAdmin }) => css`
    a {
      color: ${isAdmin
        ? theme.colors.green600
        : theme.colors.yellowSubmarine} !important;
      text-decoration: underline;
    }
  `}
`;

export const IconButtonContainer = styled.div`
  display: inline-flex;
  cursor: pointer;
`;

const ErrorMessage = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.redRedWine};
    font-size: 12px;

    a {
      color: ${theme.colors.redRedWine};
      text-decoration: underline;
    }
  `}
`;

const stripParagraphTagsFromBeginningAndEndOfString = (message: string) => {
  const regex = /(<p>)(.*?)(<\/p>)/g;
  return message.replace(regex, '$2');
};

export const renderCrewMessage = (
  message: CrewMessage,
  intl: any,
  lastMessageIndex: number,
  index: number,
  isAdmin?: boolean,
  user?: User | null,
  onRemove?: Function,
  truncateMessage?: boolean
) => {
  const { createdAt, createdBy, body, event } = message;

  const createdByUser = userFullNameOrEmail(createdBy);
  const createdAtDate = dateFormatter(
    createdAt,
    'shortWeekdayShortMonthDateAndYear',
    event.city.timezone
  );
  const createdAtTime = dateFormatter(
    createdAt,
    'hoursAndMinutesWithSpacedAMPM',
    event.city.timezone
  );

  const slackMessagesByUserId = message.slackMessages.reduce((acc, val) => {
    if (val?.messagable?.id) {
      acc[val?.messagable?.id] = [...(acc[val?.messagable?.id] || []), val];
    }
    return acc;
  }, {});

  const usersWithSlackFailures = Object.keys(slackMessagesByUserId)
    .filter((userId: string) =>
      slackMessagesByUserId[userId].every(
        (slackMessage: any) => !slackMessage.isSuccess
      )
    )
    .map((userId: string) => slackMessagesByUserId[userId][0].messagable);

  return (
    <SectionText key={index}>
      <CrewMessageContainer>
        <CrewMessageInfo>
          <Overline inverted={!isAdmin}>{createdByUser}</Overline>
        </CrewMessageInfo>
        <CrewMessageInfo>
          <Overline inverted={!isAdmin}>
            {createdAtDate} - {createdAtTime}
            {user?.id && user?.id === createdBy?.id && (
              <IconButtonContainer
                onClick={() => {
                  onRemove && onRemove(message?.id);
                }}
              >
                <IconButton icon={Bin1} size={14} />
              </IconButtonContainer>
            )}
          </Overline>
        </CrewMessageInfo>
        {isAdmin && truncateMessage ? (
          <CrewMessageText>
            <ExpandableText
              allowHTML
              text={stripParagraphTagsFromBeginningAndEndOfString(body)}
              truncateLength={70}
            />
          </CrewMessageText>
        ) : (
          <CrewMessageText
            dangerouslySetInnerHTML={{
              __html: stripParagraphTagsFromBeginningAndEndOfString(body),
            }}
          />
        )}

        {isAdmin &&
          usersWithSlackFailures &&
          usersWithSlackFailures.map((user: any) => (
            <ErrorMessage
              key={user.id}
              dangerouslySetInnerHTML={{
                __html: intl.formatHTMLMessage(
                  {
                    id: 'admin.eventPlanner.team.slackMessageFailed',
                  },
                  {
                    userId: user.id,
                    userFullName: user.name,
                  }
                ),
              }}
            />
          ))}

        {index !== lastMessageIndex && <DottedLine inverted={!isAdmin} />}
      </CrewMessageContainer>
    </SectionText>
  );
};
