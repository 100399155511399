import {
  cityOperatingModelLabels,
  sofarOperatedMarketTypeLabels,
} from 'app/admin/utils/cities';

export const sortOptions = [
  {
    title: 'Title (A - Z)',
    value: {
      by: 'title',
      direction: 'asc',
    },
  },
  {
    title: 'Title (Z - A)',
    value: {
      by: 'title',
      direction: 'desc',
    },
  },
];

export const cityOperatingModelOptions = {
  options: Object.keys(cityOperatingModelLabels).map((key: string) => ({
    title: cityOperatingModelLabels[key],
    value: key,
  })),
};

export const sofarOperatedMarketTypeOptions = {
  options: Object.keys(sofarOperatedMarketTypeLabels).map((key: string) => ({
    title: sofarOperatedMarketTypeLabels[key],
    value: key,
  })),
};

export const activeOptions = {
  options: [
    {
      title: 'Active',
      value: 'active',
    },
    {
      title: 'Inactive',
      value: 'inactive',
    },
  ],
};
