import React from 'react';
import styled, { css } from 'styled-components';

import { SocialUrls } from 'app/typings/socialUrls';
import {
  artistSocialUrls,
  citySocialUrls,
  venueSocialUrls,
} from 'app/shared/utils/socialLinks';
import { socialIcons } from 'app/shared/components/atoms/IconLibrary';
import { BaseLink } from 'app/shared/components/atoms/LinkManualCSS';

interface SocialIconListProps {
  socialUrls: SocialUrls;
  dataQaid?: string;
}

const SocialLinks = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 10px;
`;

const SocialLink = styled.div`
  margin-right: 20px;
  cursor: pointer;
  ${({ theme }) => css`
    color: ${theme.colors.green400};
  `};
`;

const socialUrlsToDisplay = Array.from(
  new Set(artistSocialUrls.concat(citySocialUrls).concat(venueSocialUrls))
);

const SocialIconList: React.FC<SocialIconListProps> = ({
  socialUrls,
  dataQaid,
}) => {
  const socialUrlsToUse = socialUrlsToDisplay.filter(
    (socialUrlKey: string) => !!socialUrls[socialUrlKey]
  );

  if (socialUrlsToUse.length <= 0) {
    return <></>;
  }

  return (
    <SocialLinks>
      {socialUrlsToUse.map((socialUrlKey: string) => {
        let SocialIcon = socialIcons[socialUrlKey];
        if (!SocialIcon) {
          return <React.Fragment key={socialUrlKey}></React.Fragment>;
        }
        return (
          <SocialLink key={socialUrlKey}>
            <BaseLink
              href={socialUrls[socialUrlKey]}
              openInNewTab={true}
              data-qaid={`${dataQaid}-social-link-${socialUrlKey}`}
            >
              <SocialIcon />
            </BaseLink>
          </SocialLink>
        );
      })}
    </SocialLinks>
  );
};

export default SocialIconList;
