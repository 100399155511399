export const sortOptions = [
  {
    title: 'Venue Name (A - Z)',
    value: {
      by: 'venue_name',
      direction: 'asc',
    },
  },
  {
    title: 'Venue Name (Z - A)',
    value: {
      by: 'venue_name',
      direction: 'desc',
    },
  },
  {
    title: 'Shortest Time Since Last Concert',
    value: {
      by: 'last_event_at',
      direction: 'desc',
    },
  },
  {
    title: 'Longest Time Since Last Concert',
    value: {
      by: 'last_event_at',
      direction: 'asc',
    },
  },
  {
    title: 'Most Concerts Hosted',
    value: {
      by: 'past_events_count',
      direction: 'desc',
    },
  },
  {
    title: 'Least Concerts Hosted',
    value: {
      by: 'past_events_count',
      direction: 'asc',
    },
  },
  {
    title: 'Highest Capacity',
    value: {
      by: 'capacity',
      direction: 'desc',
    },
  },
  {
    title: 'Lowest Capacity',
    value: {
      by: 'capacity',
      direction: 'asc',
    },
  },
  {
    title: 'Most Recently Added',
    value: {
      by: 'created_at',
      direction: 'desc',
    },
  },
];

export const characteristicsOptions = {
  characteristics: [
    {
      title: 'Residential',
      value: 'residential',
    },
    {
      title: 'Commercial',
      value: 'commercial',
    },
    {
      title: 'Is Accessible',
      value: 'is_accessible',
    },
    {
      title: 'Has Pets',
      value: 'has_pets',
    },
    {
      title: 'BYOB',
      value: 'byob',
    },
    {
      title: 'Alcohol For Sale',
      value: 'alcohol_for_sale',
    },
    {
      title: 'Alcohol Free',
      value: 'alcohol_free',
    },
    {
      title: 'Indoor',
      value: 'indoor',
    },
    {
      title: 'Outdoor',
      value: 'outdoor',
    },
    {
      title: 'Artist Parking Available',
      value: 'artist_parking_available',
    },
    {
      title: 'Guest Parking Available',
      value: 'guest_parking_available',
    },
  ],
};

export const eventScopeOptions = {
  eventScope: [
    {
      title: 'Past 7 Days',
      value: 'past_7_days',
    },
    {
      title: 'Past 30 Days',
      value: 'past_30_days',
    },
    {
      title: 'Hosted At Least 1 Show',
      value: 'hosted_at_least_1_show',
    },
    {
      title: 'No Shows In Past 6 Months',
      value: 'no_shows_in_past_6_months',
    },
    {
      title: 'Next 7 Days',
      value: 'next_7_days',
    },
    {
      title: 'Next 30 Days',
      value: 'next_30_days',
    },
    {
      title: 'Booked For Upcoming Show',
      value: 'booked_for_upcoming_show',
    },
    {
      title: 'Not Booked For Upcoming Show',
      value: 'no_upcoming_show',
    },
    {
      title: 'Hosted 0 Shows',
      value: 'hosted_0_shows',
    },
  ],
};

export const ticketOptions = {
  ticketRanges: [
    {
      title: '0-25',
      value: '0-25',
    },
    {
      title: '26-50',
      value: '26-50',
    },
    {
      title: '51-75',
      value: '51-75',
    },
    {
      title: '76-100',
      value: '76-100',
    },
    {
      title: '101-125',
      value: '101-125',
    },
    {
      title: '126-150',
      value: '126-150',
    },
    {
      title: '151-175',
      value: '151-175',
    },
    {
      title: '176-200',
      value: '176-200',
    },
    {
      title: '200+',
      value: '200+',
    },
  ],
};

export const capacityOptions = {
  capacityRanges: [
    {
      title: '0-25',
      value: '0-25',
    },
    {
      title: '26-50',
      value: '26-50',
    },
    {
      title: '51-75',
      value: '51-75',
    },
    {
      title: '76-100',
      value: '76-100',
    },
    {
      title: '101-125',
      value: '101-125',
    },
    {
      title: '126-150',
      value: '126-150',
    },
    {
      title: '151-175',
      value: '151-175',
    },
    {
      title: '176-200',
      value: '176-200',
    },
    {
      title: '200+',
      value: '200+',
    },
  ],
};

export const tagOptions = {
  tagNames: [
    {
      title: 'Available Last Minute',
      value: 'available_last_minute',
    },
    {
      title: 'Advocate',
      value: 'advocate',
    },
    {
      title: 'In-House Amp',
      value: 'in-house_amp',
    },
    {
      title: 'Audio Engineer Required',
      value: 'audio_engineer_required',
    },
    {
      title: 'Great Accoustic',
      value: 'great_accoustic',
    },
    {
      title: 'Do Not Book',
      value: 'do_not_book',
    },
    {
      title: 'Has Free',
      value: 'has_free',
    },
  ],
};

export const hostingStatusOptions = {
  hostingStatuses: [
    {
      title: 'Active',
      value: 'active',
    },
    {
      title: 'On Hiatus',
      value: 'on_hiatus',
    },
    {
      title: 'Inactive',
      value: 'inactive',
    },
  ],
};
