import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { ContentWrapper } from 'app/shared/components/atoms/FlyoverContent';
import FlyoverFooter from 'app/admin/components/molecules/FlyoverFooter';
import { getLocaleDateTime } from 'app/admin/components/organisms/CrewPortalCard';

interface ActivateRoleFormProps {
  roleTitle: string;
  cityTitle: string;
  localStartsAt: string;
  crewLoadInAt?: string;
  setIsSubmitting: Function;
  handleUpdateEventStaffMember: Function;
}

const ValueContainer = styled.div`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.4px;
  margin-bottom: 5px;
`;

const ActivateRoleForm: React.FC<ActivateRoleFormProps> = ({
  roleTitle,
  cityTitle,
  localStartsAt,
  crewLoadInAt,
  setIsSubmitting,
  handleUpdateEventStaffMember,
}) => {
  const intl = useIntl();
  const { weekday, month, day, time, meridian } = getLocaleDateTime(
    localStartsAt
  );

  return (
    <div data-qaid="activate-role-confirmation-flyover">
      <ContentWrapper>
        <ValueContainer>
          {intl.formatMessage(
            {
              id: 'admin.crewPortal.crewRole.signupMessage',
            },
            {
              role: roleTitle,
              time: time + ' ' + meridian,
              weekday,
              month,
              day,
              city: cityTitle,
              crewLoadInTime: crewLoadInAt,
            }
          )}
        </ValueContainer>
      </ContentWrapper>
      <FlyoverFooter
        buttonText={intl.formatMessage({
          id: 'yes',
        })}
        onClickButton={() => {
          setIsSubmitting(true);
          handleUpdateEventStaffMember();
        }}
        dataQaid="activate-role-submit-button"
      />
    </div>
  );
};

export default ActivateRoleForm;
