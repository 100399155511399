import React, { useState } from 'react';
import moment from 'moment';
import Calendar from 'react-calendar';
import styled, { css } from 'styled-components';

import { getDateParts } from 'app/shared/utils/datetime';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
} from 'app/shared/components/atoms/IconLibrary';
import { Overline } from 'app/shared/components/atoms/TypographyManualCSS';

interface Props {
  // dateKey = YYYY-MM-DD e.g. '2022-04-26'
  content: { [dateKey: string]: React.ComponentType<any> };
  onMonthChange?: (
    firstDateOfMonthPeriod: string,
    lastDateOfMonthPeriod: string
  ) => void;
  disablePastDates?: boolean;
  topContent?: any;
}

const MainContainer = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.silverSprings};
    /* 761px = ( (95px tile width + (4px margin x 2)) x 7 days in week ) + (20px padding x 2) */
    width: 761px;
    margin-top: 20px;
    padding: 20px;
  `}
`;

const StyledCalendar = styled(Calendar)`
  ${({ theme }) => css`
    .react-calendar__navigation {
      margin-bottom: 10px;
      text-align: center;
      width: 100%;
    }
    .react-calendar__navigation button {
      padding-left: 15px;
      padding-right: 15px;
      border: none;
    }
    .react-calendar__tile {
      border: 1px solid ${theme.colors.silverSprings};
      border-radius: 10px;
      background-color: #fff;
      max-width: 95px !important;
      min-width: 95px !important;
      height: 200px;
      margin: 4px;
    }
  `}
`;

const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;

const DateLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 12px 10px 0px 10px;
  width: 100%;
`;

const DateContent = styled.div`
  padding: 5px 7px 7px 7px;
`;

const DatePart = styled(Overline)`
  ${({ theme }) => css`
    padding-top: 0px;
    color: ${theme.colors.backToBlack};
  `}
`;

const Day = styled.div`
  ${({ theme }) => css`
    font-size: 28px;
    letter-spacing: -0.5px;
    line-height: normal;
    font-weight: 400;
    color: ${theme.colors.backToBlack};
    margin-top: -7px;
    margin-bottom: -5px;
  `}
`;

const Month = styled.div`
  margin-left: -4px;
`;

const Year = styled.div`
  margin-top: -2px;
`;

const getFirstDateOfMonthPeriod = (activeStartDate: Date) => {
  return moment(activeStartDate)
    .startOf('month')
    .startOf('week')
    .format('YYYY-MM-DD');
};

const getLastDateOfMonthPeriod = (activeStartDate: Date) => {
  return moment(activeStartDate)
    .endOf('month')
    .endOf('week')
    .format('YYYY-MM-DD');
};

interface DateLabelProps {
  date: Date;
}

const DateLabel: React.FC<DateLabelProps> = ({ date }) => {
  const { weekday, month, day, year } = getDateParts(date, false);

  return (
    <DateLabelContainer>
      <DatePart>{weekday}</DatePart>
      <Day>{day}</Day>
      <DatePart>
        <Month>{month}</Month>
        <Year>{year}</Year>
      </DatePart>
    </DateLabelContainer>
  );
};

interface PreviousIconProps {
  disabled: boolean;
}

const PreviousIcon: React.FC<PreviousIconProps> = ({ disabled = false }) => (
  <ArrowLeftIcon iconSize={14} onClick={() => {}} disabled={disabled} />
);

const NextIcon: React.FC = () => (
  <ArrowRightIcon iconSize={14} onClick={() => {}} disabled={false} />
);

const navigationLabel = (date: Date) =>
  `${moment(date)
    .format('MMM')
    .toUpperCase()} ${moment(date).format('YYYY')}`;

const getDateKey = (date: Date) => moment(date).format('YYYY-MM-DD');

interface TileContentParams {
  date: Date;
}

const CalendarWithContent: React.FC<Props> = ({
  content,
  onMonthChange,
  disablePastDates = false,
  topContent,
}) => {
  const today = new Date();

  const [isCurrentMonth, setIsCurrentMonth] = useState(true);
  const [realActiveStartDate, setRealActiveStartDate] = useState(today);

  if (onMonthChange && isCurrentMonth) {
    onMonthChange(
      getFirstDateOfMonthPeriod(today),
      getLastDateOfMonthPeriod(today)
    );
  }

  const tileContent = ({ date }: TileContentParams) => {
    const dateKey = getDateKey(date);
    return (
      <DateContainer>
        <DateLabel date={date} />
        <DateContent>{content[dateKey] || null}</DateContent>
      </DateContainer>
    );
  };

  return (
    <MainContainer>
      {topContent || null}
      <StyledCalendar
        calendarType="US"
        prevLabel={
          <PreviousIcon disabled={!!(disablePastDates && isCurrentMonth)} />
        }
        nextLabel={<NextIcon />}
        prev2Label={null}
        next2Label={null}
        navigationLabel={({ date }) => navigationLabel(date)}
        minDate={disablePastDates ? today : undefined}
        onActiveStartDateChange={({ action, activeStartDate }) => {
          if (disablePastDates && (action == 'prev' || action == 'next')) {
            if (activeStartDate <= today) {
              setIsCurrentMonth(true);
            } else {
              setIsCurrentMonth(false);
            }
          }
          // realActiveStartDate and this logic exists to handle issue where clicking on a day tile
          // from the previous or next month (e.g. clicking on Mar 31 when looking at April) switches
          // the view to that month (which we don't want b/c the code using this calendar puts content
          // inside each day tile which has its own onClick behaviors)
          //
          // Inside the onActiveStartDateChange callback, if action=onChange, that means react-calendar
          // is trying to switch months due to a click on a day tile and NOT due to a click on prev/next
          // navigation buttons, so instead of allowing that switch, manually set the activeStartDate prop
          // (via the realActiveStartDate state below) to the OLD/EXISTING value - if action!=onChange,
          // that means user clicked on a prev/next navigation button and so user WANTS to switch months,
          // so go ahead with the switch and manually set the activeStartDate prop (via the realActiveStartDate
          // state below) to the NEW value
          //
          // See also https://github.com/wojtekmaj/react-calendar/issues/378
          if (action == 'onChange') {
            setRealActiveStartDate(realActiveStartDate);
          } else {
            setRealActiveStartDate(activeStartDate);
          }
          if (onMonthChange && (action == 'prev' || action == 'next')) {
            onMonthChange(
              getFirstDateOfMonthPeriod(activeStartDate),
              getLastDateOfMonthPeriod(activeStartDate)
            );
          }
        }}
        activeStartDate={realActiveStartDate}
        formatDay={() => ''}
        formatShortWeekday={() => ''}
        tileContent={tileContent}
      />
    </MainContainer>
  );
};

export default CalendarWithContent;
