import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { truncateByWord } from 'app/shared/utils/string';
import { LoadingBlocks } from 'app/shared/components/atoms/LoadingBlocks';
import { SubTitle, Title } from 'app/shared/components/atoms/ModalTitle';
import { Spacer16 } from 'app/shared/components/atoms/Spacer';

interface Props {
  title?: string;
  subTitle?: string;
  aboveTitleComponent?: React.ComponentType<any>;
  actionLinksInfo?: ActionLinksInfo;
  isNewForm?: boolean;
  loading?: boolean;
  detailsDate?: string;
  nowrap?: boolean;
}

interface ActionLinksInfo {
  link1?: ActionLinkProps;
  link2?: ActionLinkProps;
}

interface ActionLinkProps {
  text: string | JSX.Element;
  active: boolean;
  onClickAction: VoidFunction;
}

const TitleSection = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Span = styled.span`
  ${({ theme }) => css`
    font-size: 12px;
    ${theme.media.xl`
      font-size: 14px;
    `};
  `}
`;

interface ColorProps {
  active: boolean;
}

const ColorSpan = styled(Span)<ColorProps>`
  ${props => `
    color: ${
      props.active ? props.theme.colors.green600 : props.theme.colors.blueSmoke
    };
    cursor: ${props.active ? 'pointer' : 'default'};
    font-weight: bold;
  `}
`;

const renderActionLink = (actionLink: ActionLinkProps, id: number) =>
  actionLink.active ? (
    <ColorSpan
      onClick={actionLink.onClickAction}
      active={actionLink.active}
      data-qaid={`action-link-${id}`}
    >
      {actionLink.text}
    </ColorSpan>
  ) : (
    <ColorSpan active={actionLink.active}>{actionLink.text}</ColorSpan>
  );

const StackContainer = styled.div`
  width: 100%;
`;

const GreenText = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.green600};
  `}
`;

export const DetailsTitle: React.FC<Props> = ({
  title,
  subTitle,
  aboveTitleComponent,
  actionLinksInfo,
  isNewForm,
  loading = false,
  detailsDate,
  nowrap,
}) => {
  const intl = useIntl();

  const shortTitle = truncateByWord(title || null, 37, '…');

  const AboveTitleComponent = aboveTitleComponent;

  return (
    <TitleSection>
      <StackContainer>
        {!loading ? (
          <>
            {AboveTitleComponent && <AboveTitleComponent />}
            <Title
              data-qaid="details-header-title"
              displayCalendar={!!detailsDate}
              nowrap={!actionLinksInfo || nowrap}
            >
              {shortTitle || 'None'}
            </Title>
            <SubTitle
              data-qaid="details-header-sub-title"
              displayCalendar={!!detailsDate}
            >
              {subTitle ? subTitle : ''}
            </SubTitle>
          </>
        ) : (
          <>
            <LoadingBlocks.Rectangle height="40px" width="240px" />
            <Spacer16 />
          </>
        )}
      </StackContainer>
      {actionLinksInfo && (
        <StackContainer>
          {actionLinksInfo.link1 && renderActionLink(actionLinksInfo.link1, 1)}
          {actionLinksInfo.link2 && (
            <>
              <Span> | </Span>
              {renderActionLink(actionLinksInfo.link2, 2)}
            </>
          )}
        </StackContainer>
      )}
      {isNewForm && (
        <Span>
          {intl.formatMessage({
            id: 'admin.detailsTitle.tip',
          })}{' '}
          <GreenText>
            {intl.formatMessage({
              id: 'admin.detailsTitle.requiredFieldColor',
            })}
          </GreenText>{' '}
          {intl.formatMessage({
            id: 'admin.detailsTitle.indicatesRequiredFields',
          })}
        </Span>
      )}
    </TitleSection>
  );
};
