import React, { useEffect } from 'react';

import { snakeCaseToCamel } from 'app/shared/utils/string';
import { GetImages } from 'app/shared/graphql/images/queryHooks';
import { FlexDivWithSpacing } from 'app/shared/components/atoms/FlexDivWithSpacing';
import GenericFormContainer from 'app/shared/components/atoms/GenericFormContainer';
import ImageMultiUploaderWithCropper, {
  ImageInfo,
} from 'app/shared/components/organisms/ImageMultiUploaderWithCropper';

interface FormProps {
  formikProps: any;
  setCropAction?: (func: Function) => Promise<Record<string, any>>;
  objectType: string;
  objectId: number;
  // e.g. 'header'
  imagePurpose: string;
  // e.g. ['1:1, '4:3']
  ratios: string[];
  ratioDescriptions?: { [ratio: string]: string };
  imagesForAllRatios: ImageInfo[];
  setImagesForAllRatios: (func: any) => void;
  dataQaidPrefix: string;
}

// Returns a decimal number e.g. 4 / 3
const getCropperRatio = (ratio: string) => {
  const cropperRatioParts = ratio
    .split(':')
    .map((ratioPart: string) => Number(ratioPart));
  return cropperRatioParts[0] / cropperRatioParts[1];
};

const ImageMultiUploaderForm: React.FC<FormProps> = ({
  formikProps,
  objectType,
  objectId,
  imagePurpose,
  ratios,
  ratioDescriptions = {},
  imagesForAllRatios,
  setImagesForAllRatios,
  dataQaidPrefix,
}) => {
  const { setFieldValue } = formikProps;

  useEffect(() => {
    if (imagesForAllRatios.length > 0) {
      setFieldValue('anyChangesMade', true);
    } else {
      setFieldValue('anyChangesMade', false);
    }
  }, [imagesForAllRatios]);

  const { loading: imagesDataLoading, data: imagesData } = GetImages({
    imageableObjectId: objectId,
    imageableObjectType: objectType,
    fetchPolicy: 'no-cache',
  });

  const existingImagesForRatio = (ratio: string) =>
    imagesData?.images?.images?.[snakeCaseToCamel(imagePurpose)]?.[
      `ratio${ratio.replace(':', 'x')}`
    ] || [];

  return (
    <GenericFormContainer dataQaId={`${dataQaidPrefix}-image-edit-form`}>
      {ratios.map((ratio: string, index: number) => {
        const cropperRatio = getCropperRatio(ratio);
        return (
          <FlexDivWithSpacing alignItems="center" key={index}>
            <ImageMultiUploaderWithCropper
              objectType={objectType}
              objectId={objectId}
              ratio={ratio}
              cropperRatio={cropperRatio}
              ratioDescription={ratioDescriptions[ratio]}
              imagesForAllRatios={imagesForAllRatios}
              setImagesForAllRatios={setImagesForAllRatios}
              existingImages={existingImagesForRatio(ratio)}
              loadingExistingImages={imagesDataLoading}
              dataQaidPrefix={`${objectType.toLowerCase()}-${imagePurpose}-image-${ratio}`}
            />
          </FlexDivWithSpacing>
        );
      })}
    </GenericFormContainer>
  );
};

export default ImageMultiUploaderForm;
