import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { Event, EventStaffMember, UserStaffedEvent } from 'app/typings/events';
import { useCurrentTheme } from 'app/shared/theme';
import { dateFormatter } from 'app/shared/utils/datetime';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import TruncatedByCharText from 'app/shared/components/atoms/TruncatedByCharText';
import {
  Body2,
  Overline,
} from 'app/shared/components/atoms/TypographyManualCSS';
import { eventTypeLabels } from 'app/admin/utils/events';
import DetailText from 'app/admin/components/atoms/DetailText';
import { EventLink } from 'app/admin/components/molecules/EntityLink';
import { TagElement } from 'app/admin/components/molecules/TagElementList';

interface Props {
  event: UserStaffedEvent;
  scope: string;
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
`;

const CityNameContainer = styled.div`
  margin-bottom: 5px;
`;

const CancelledTagWrapper = styled.div`
  width: 80px;
  padding: 0px;
  margin-top: 28px;
  margin-bottom: 5px;
`;

const CancelledTagElement = styled(TagElement)`
  text-align: center;
  padding: 1px 5px;
  margin-top: -21px;
  margin-right: -2px;
  height: 18px;
  border-radius: 0px 0px 2px 2px;

  @media screen and (min-width: 1200px) and (max-width: 1300px) {
    font-size: 9px;
  }
`;

interface KeyValueContainerProps {
  height?: string;
}

const KeyValueContainer = styled.div<KeyValueContainerProps>`
  ${({ height }) => css`
    height: ${height || '50px'};
  `}
`;

const ValueContainer = styled(Body2)`
  ${({ theme }) => css`
    color: ${theme.colors.backToBlack};
    background-color: ${theme.colors.whiteDenim};
    white-space: nowrap;

    ${theme.media.xs`
      font-size: 12px;
    `}
  `}
`;

const ProvidedFeedbackTagTagElementWrapper = styled.div`
  height: 32px;
  width: 40px;
  margin-top: 5px;
`;

const CancelledTag: React.FC = () => {
  const intl = useIntl();

  return (
    <CancelledTagWrapper>
      <CancelledTagElement
        tagElementColor="#EF4423"
        textColor="#fff"
        data-qaid="tag-element-cancel"
      >
        {intl.formatMessage({
          id: 'admin.userStaffedEvent.cancelled',
        })}
      </CancelledTagElement>
      <Spacer mr={1} />
    </CancelledTagWrapper>
  );
};

interface KeyAboveValueProps {
  keyText: string;
  valueText: string;
  valueText2?: string;
}

const KeyAboveValue: React.FC<KeyAboveValueProps> = ({
  keyText,
  valueText,
  valueText2,
}) => {
  return (
    <KeyValueContainer key={keyText} height={valueText2 && '70px'}>
      <Overline>{keyText}</Overline>
      <ValueContainer>
        <TruncatedByCharText text={valueText} truncateLength={23} />
      </ValueContainer>
      {valueText2 && (
        <ValueContainer>
          <TruncatedByCharText text={valueText2} truncateLength={23} />
        </ValueContainer>
      )}
    </KeyValueContainer>
  );
};

interface KeyAboveValueProps {
  keyText: string;
  valueText: string;
}

interface ProvidedFeedbackProps {
  providedFeedback: boolean;
}

const ProvidedFeedbackTag: React.FC<ProvidedFeedbackProps> = ({
  providedFeedback,
}) => {
  const intl = useIntl();
  const theme = useCurrentTheme();

  const tagElementColor = providedFeedback
    ? theme.colors.green600
    : theme.colors.redRedWine;
  const tagElementText = intl.formatMessage({
    id: providedFeedback ? 'yes' : 'no',
  });
  return (
    <ProvidedFeedbackTagTagElementWrapper>
      <TagElement
        tagElementColor={tagElementColor}
        textColor={theme.colors.whiteDenim}
      >
        {tagElementText}
      </TagElement>
    </ProvidedFeedbackTagTagElementWrapper>
  );
};

const ProvidedFeedbackKeyAboveValue: React.FC<ProvidedFeedbackProps> = ({
  providedFeedback,
}) => {
  const intl = useIntl();

  return (
    <KeyValueContainer key="gave_feedback">
      <Overline>
        {intl.formatMessage({
          id: 'admin.userStaffedEvent.gaveFeedback',
        })}
      </Overline>
      <ProvidedFeedbackTag providedFeedback={providedFeedback} />
    </KeyValueContainer>
  );
};

interface RoleInfo {
  roleName: string;
  roleSignedUpDate: string;
}

const StaffedEvent: React.FC<Props> = ({ event, scope }) => {
  const intl = useIntl();

  const roleNamesAndSignedUpDates = event?.staff?.map(
    (eventStaffMember: EventStaffMember) => ({
      roleName: eventStaffMember.role.name,
      roleSignedUpDate: eventStaffMember.userAddedAt
        ? dateFormatter(eventStaffMember.userAddedAt, 'shortMonthDateAndYear')
        : intl.formatMessage({
            id: 'unknown',
          }),
    })
  );

  const sortedRoleNamesAndSignedUpDates = roleNamesAndSignedUpDates.sort(
    (a: RoleInfo, b: RoleInfo) => (a.roleName > b.roleName ? 1 : -1)
  );

  return (
    <MainContainer>
      <CityNameContainer>
        <DetailText data-qaid="city-title">
          <EventLink
            event={event}
            truncateLength={23}
            renderEventName={(event: Event) => event.city.title}
          />
        </DetailText>
      </CityNameContainer>
      {event.cancelled && <CancelledTag />}
      <KeyAboveValue
        keyText={intl.formatMessage({
          id: 'admin.userStaffedEvent.concertDate',
        })}
        valueText={dateFormatter(event.localStartsAt, 'shortMonthDateAndYear')}
      />
      <KeyAboveValue
        keyText={intl.formatMessage({
          id: 'admin.userStaffedEvent.concertType',
        })}
        valueText={eventTypeLabels[event.type] || 'None'}
      />
      <KeyAboveValue
        keyText={intl.formatMessage({
          id: 'admin.userStaffedEvent.theme',
        })}
        valueText={event?.theme?.title || 'None'}
      />
      {sortedRoleNamesAndSignedUpDates.map((roleInfo: RoleInfo) => (
        <>
          <KeyAboveValue
            keyText={intl.formatMessage({
              id: 'admin.userStaffedEvent.roleSignedUp',
            })}
            valueText={roleInfo.roleName}
            valueText2={roleInfo.roleSignedUpDate}
            key={roleInfo.roleName}
          />
        </>
      ))}
      {scope === 'past' && (
        <ProvidedFeedbackKeyAboveValue
          providedFeedback={event.providedFeedback || false}
        />
      )}
    </MainContainer>
  );
};

export default StaffedEvent;
