import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { UploadDropzone } from 'react-uploader';
import styled from 'styled-components';
import { Uploader, UploadWidgetResult } from 'uploader';

import { Image as ImageType } from 'app/typings';
import { isDefaultImage } from 'app/shared/utils/images';
import Divider from 'app/shared/components/atoms/DividerManualCSS';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import { LoadingBlocks } from 'app/shared/components/atoms/LoadingBlocks';
import ImageList from 'app/shared/components/molecules/ImageList';

const maxFileSizeBytes = 2097152;
export const maxFileSizeForDisplayMegabytes = '2';

const mimeTypes = ['image/jpeg'];
export const mimeTypesForDisplay = 'JPG';

const uploader = Uploader({ apiKey: process.env.UPLOAD_IO_KEY || '' });

export interface ImageInfo {
  // e.g. '1:1'
  ratio: string;
  filePath: string;
  fileUrl: string;
  editedFileUrl?: string;
  originalFileUrl: string;
  mime: string;
  size: number;
}

interface ImageMultiUploaderWithCropperProps {
  objectType: string;
  objectId: number;
  // e.g. '1:1'
  ratio: string;
  cropperRatio: number;
  ratioDescription?: string;
  multipleImagesPerRatio?: boolean;
  imagesForAllRatios: ImageInfo[];
  setImagesForAllRatios: (values: any) => void;
  existingImages: ImageType[];
  loadingExistingImages: boolean;
  dataQaidPrefix: string;
}

interface DropZoneProps {
  objectType: string;
  objectId: number;
  ratio: string;
  cropperRatio: number;
  multipleImagesPerRatio?: boolean;
  imagesForRatio: ImageInfo[];
  imagesForAllRatios: ImageInfo[];
  setImagesForRatio: (values: ImageInfo[]) => void;
  setImagesForAllRatios: (values: ImageInfo[]) => void;
  existingImages: ImageType[];
  loadingExistingImages: boolean;
}

const MainContainer = styled.div`
  width: 100%;
  margin-bottom: 30px;
`;

const DividerWithSpace = styled(Divider)`
  margin-bottom: 35px;
`;

const UploadedImagesHeader = styled.div`
  margin-bottom: 10px;
`;

const ExistingImagesHeader = styled.div`
  margin-bottom: 10px;
`;

const ExistingImagesContainer = styled.div`
  margin-left: 9px;
`;

const DropzoneContainer = styled.div`
  margin-left: -13px;
`;

const NoImages = styled.div`
  margin-top: -8px;
  margin-bottom: 15px;
  margin-left: 14px;
`;

const RatioDescription = styled.div`
  font-size: 16px;
  font-style: italic;
  line-height: 24px;
  padding-bottom: 20px;
`;

const getImagesForDisplay = (
  images: any[],
  multipleImagesPerRatio?: boolean
) => {
  // For uploading images for a carousel (multipleImagesPerRatio=true), display all images,
  // otherwise, display only last-uploaded image, which is the only image used
  if (multipleImagesPerRatio) {
    return images;
  } else {
    return images.slice(-1);
  }
};

const UploadedImage: React.FC<{ image: ImageInfo }> = ({ image }) => {
  const filePath = image.filePath;
  const fileUrl = uploader.url(filePath, 'thumbnail');
  return (
    <div key={fileUrl}>
      <img src={fileUrl} width="300" height="auto" />
    </div>
  );
};

const UploadedImages: React.FC<{
  images: ImageInfo[];
  multipleImagesPerRatio?: boolean;
}> = ({ images, multipleImagesPerRatio }) => {
  const intl = useIntl();
  const imagesForDisplay = getImagesForDisplay(images, multipleImagesPerRatio);

  return (
    <>
      {imagesForDisplay.length > 0 && (
        <UploadedImagesHeader>
          {intl.formatMessage({
            id: 'admin.multiImageEdit.imageToBeUploaded',
          })}
        </UploadedImagesHeader>
      )}
      {imagesForDisplay.map((image: ImageInfo, index: number) => {
        return <UploadedImage image={image} key={index} />;
      })}
    </>
  );
};

const ExistingImages: React.FC<{
  images: ImageType[];
  multipleImagesPerRatio?: boolean;
}> = ({ images, multipleImagesPerRatio }) => {
  const intl = useIntl();
  const imagesForDisplay = getImagesForDisplay(images, multipleImagesPerRatio);

  return (
    <ExistingImagesContainer>
      {imagesForDisplay.length > 0 && (
        <ExistingImagesHeader>
          {intl.formatMessage(
            {
              id: multipleImagesPerRatio
                ? 'admin.multiImageEdit.existingImages'
                : 'admin.multiImageEdit.existingImage',
            },
            {
              extraText: ` (${intl.formatMessage({
                id: isDefaultImage(imagesForDisplay[0].fileLocation)
                  ? 'admin.multiImageEdit.defaultImage'
                  : 'admin.multiImageEdit.customImage',
              })})`,
            }
          )}
        </ExistingImagesHeader>
      )}
      <ImageList images={imagesForDisplay} />
    </ExistingImagesContainer>
  );
};

// Type definition: https://github.com/upload-io/uploader#get-the-result
const getImageInfoFromRawImage = (
  rawImage: UploadWidgetResult,
  ratio: string
) => ({
  ratio,
  filePath: rawImage.filePath,
  fileUrl: rawImage.fileUrl,
  editedFileUrl: rawImage.editedFile?.fileUrl,
  originalFileUrl: rawImage.originalFile.fileUrl,
  mime: rawImage.originalFile.mime,
  size: rawImage.originalFile.size,
});

const getImagesWithAddedImages = (
  currentImages: ImageInfo[],
  rawImagesForRatio: UploadWidgetResult[],
  ratio: string
) => {
  const updatedImages = currentImages.concat(
    rawImagesForRatio.map((rawImage: UploadWidgetResult) =>
      getImageInfoFromRawImage(rawImage, ratio)
    )
  );
  return updatedImages;
};

const getImagesWithRemovedImages = (
  currentImages: ImageInfo[],
  rawImagesForRatio: UploadWidgetResult[],
  ratio: string
) => {
  const updatedFilePathsForRatio = rawImagesForRatio.map(
    (rawImage: UploadWidgetResult) => rawImage.filePath
  );
  // Keep only images where EITHER:
  // - ratio is different from the ratio we're curently working with (which means image is a saved image from another ratio)
  // - ratio is the same and file path is one of the updated set of file paths for this ratio
  const updatedImages = currentImages.filter(
    (currentImage: ImageInfo) =>
      currentImage.ratio != ratio ||
      updatedFilePathsForRatio.includes(currentImage.filePath)
  );
  return updatedImages;
};

const Dropzone: React.FC<DropZoneProps> = ({
  objectType,
  objectId,
  ratio,
  cropperRatio,
  multipleImagesPerRatio,
  imagesForRatio,
  imagesForAllRatios,
  setImagesForRatio,
  setImagesForAllRatios,
  existingImages,
  loadingExistingImages,
}) => {
  const intl = useIntl();
  const ratioForFileName = String(cropperRatio).substring(0, 7);
  const timestampForFileName = Date.now();

  return (
    <DropzoneContainer>
      <Grid>
        <Col xs={12} sm={6}>
          <UploadDropzone
            uploader={uploader}
            options={{
              editor: {
                images: {
                  crop: true,
                  cropRatio: cropperRatio,
                  preview: true,
                },
              },
              layout: 'modal',
              maxFileCount: 6,
              maxFileSizeBytes,
              metadata: {
                objectType,
                objectId,
              },
              mimeTypes,
              multi: multipleImagesPerRatio,
              path: {
                fileName: `${objectType}-${objectId}-${ratioForFileName}-${timestampForFileName}-image{ORIGINAL_FILE_EXT}`,
                fileNameFallback: `${objectType}-${objectId}-${ratioForFileName}-${timestampForFileName}-image{ORIGINAL_FILE_EXT}`,
                fileNameVariablesEnabled: true,
                folderPath: process.env.UPLOAD_IO_FOLDER,
              },
              showFinishButton: false,
              showRemoveButton: true,
              styles: {
                colors: {
                  primary: '#039842',
                },
                fontFamilies: {
                  base: 'Open Sans, sans-serif',
                },
                fontSizes: {
                  base: 16,
                },
              },
            }}
            onUpdate={rawImages => {
              if (rawImages) {
                if (rawImages.length > imagesForRatio.length) {
                  // User ADDED an image to images for this ratio
                  setImagesForRatio(
                    getImagesWithAddedImages([], rawImages, ratio)
                  );
                  setImagesForAllRatios(
                    getImagesWithAddedImages(
                      imagesForAllRatios,
                      rawImages,
                      ratio
                    )
                  );
                } else if (rawImages.length < imagesForRatio.length) {
                  // User REMOVED an image from images for this ratio
                  setImagesForRatio(
                    getImagesWithRemovedImages(imagesForRatio, rawImages, ratio)
                  );
                  setImagesForAllRatios(
                    getImagesWithRemovedImages(
                      imagesForAllRatios,
                      rawImages,
                      ratio
                    )
                  );
                }
              }
            }}
            onComplete={() => {}}
            width="400px"
            height="275px"
          />
        </Col>
        {imagesForRatio && (
          <Col xs={12} sm={6}>
            <UploadedImages images={imagesForRatio} />
          </Col>
        )}
        {existingImages && (
          <Col xs={12} sm={6}>
            {loadingExistingImages ? (
              <LoadingBlocks.Box width="100px" />
            ) : existingImages.length > 0 ? (
              <ExistingImages images={existingImages} />
            ) : (
              <NoImages>
                {multipleImagesPerRatio
                  ? intl.formatMessage(
                      {
                        id: 'admin.multiImageEdit.noImages',
                      },
                      {
                        ratio,
                      }
                    )
                  : intl.formatMessage(
                      {
                        id: 'admin.multiImageEdit.noImage',
                      },
                      {
                        ratio,
                      }
                    )}
              </NoImages>
            )}
          </Col>
        )}
      </Grid>
    </DropzoneContainer>
  );
};

const ImageMultiUploaderWithCropper: React.FC<ImageMultiUploaderWithCropperProps> = ({
  objectType,
  objectId,
  ratio,
  cropperRatio,
  ratioDescription,
  multipleImagesPerRatio = false,
  imagesForAllRatios,
  setImagesForAllRatios,
  existingImages,
  loadingExistingImages,
  dataQaidPrefix,
}) => {
  const intl = useIntl();

  const [imagesForRatio, setImagesForRatio] = useState<ImageInfo[]>([]);

  return (
    <MainContainer data-qaid={dataQaidPrefix}>
      <DividerWithSpace space={1} color="macyGrey" />
      <h2>
        {intl.formatMessage(
          {
            id: 'admin.multiImageEdit.aspectRatio',
          },
          {
            ratio,
          }
        )}
      </h2>
      {ratioDescription && (
        <RatioDescription>{ratioDescription}</RatioDescription>
      )}
      <Dropzone
        objectType={objectType}
        objectId={objectId}
        ratio={ratio}
        cropperRatio={cropperRatio}
        multipleImagesPerRatio={multipleImagesPerRatio}
        imagesForRatio={imagesForRatio}
        imagesForAllRatios={imagesForAllRatios}
        setImagesForRatio={setImagesForRatio}
        setImagesForAllRatios={setImagesForAllRatios}
        existingImages={existingImages}
        loadingExistingImages={loadingExistingImages}
      />
    </MainContainer>
  );
};

export default ImageMultiUploaderWithCropper;
