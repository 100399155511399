import React from 'react';
import { IntlShape, useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { EventCollection } from 'app/typings';
import { Event } from 'app/typings/index';
import { useCurrentTheme } from 'app/shared/theme';
import { dateFormatter } from 'app/shared/utils/datetime';
import { isEmpty } from 'app/shared/utils/object';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import DetailText from 'app/admin/components/atoms/DetailText';
import { None } from 'app/admin/components/atoms/None';
import { EventLink } from 'app/admin/components/molecules/EntityLink';
import { TagElement } from 'app/admin/components/molecules/TagElementList';

interface Props {
  sectionData: EventCollection;
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const StyledTag = styled(TagElement)`
  text-align: center;
  padding: 1px 5px;
  height: 18px;
  width: fit-content;
`;

const DottedLineCol = styled(Col)`
  ${({ theme }) => css`
    display: none;

    ${theme.media.md`
      display: block;
    `}
  `}
`;

const StyledCol = styled(Col)`
  margin-top: 20px;
  margin-bottom: 20px;
`;

const isEventVisibleToFans = (event: Event) =>
  !event.cancelled &&
  event.isPublished &&
  new Date(event.localStartsAt) >= new Date();

const renderEvents = (events: Event[], intl: IntlShape, theme: any) => {
  if (events && !isEmpty(events)) {
    return events
      .sort((eventA, eventB) => {
        return new Date(eventB.localStartsAt) < new Date(eventA.localStartsAt)
          ? -1
          : 1;
      })
      .map((event: Event, index: number) => {
        const i = index + 1;
        const showDottedLine = i % 4 === 0 && events.length > i;
        const visibleToFans = isEventVisibleToFans(event);

        return (
          <React.Fragment key={`${event.id}-${event.city.title || ''}`}>
            <StyledCol xs={12} sm={1} md={1} lg={1} xl={1}>
              {visibleToFans && (
                <>
                  <StyledTag
                    tagElementColor={theme.colors.greenOnions}
                    textColor={theme.colors.whiteDenim}
                  >
                    {intl.formatMessage({
                      id: 'admin.collections.section.concerts.visibleToFans',
                    })}
                  </StyledTag>
                </>
              )}
              {event.city && (
                <>
                  <DetailText data-qaid="city">{event.city.title}</DetailText>
                </>
              )}
              {event.neighborhood && (
                <DetailText data-qaid="neighborhood">
                  {event.neighborhood?.title}
                </DetailText>
              )}
              {event.localStartsAt && (
                <DetailText data-qaid="date">
                  {dateFormatter(
                    event.localStartsAt,
                    'longWeekdayShortMonthDateAndYear'
                  )}
                </DetailText>
              )}
              <EventLink
                event={event}
                truncateLength={30}
                renderEventName={() => {
                  return `Concert ID: ${event.id}`;
                }}
                includeSearchQueryParam
              />
            </StyledCol>
            {showDottedLine && (
              <DottedLineCol>
                <DottedLine />
              </DottedLineCol>
            )}
          </React.Fragment>
        );
      });
  } else {
    return <None />;
  }
};

const SectionConcerts: React.FC<Props> = ({ sectionData }) => {
  const intl = useIntl();
  const theme = useCurrentTheme();

  if (!sectionData.events) {
    return null;
  }
  return (
    <MainContainer data-qaid="section-concerts">
      <Grid cols={4} gap="20">
        {renderEvents(sectionData.events as Event[], intl, theme)}
      </Grid>
    </MainContainer>
  );
};

export default SectionConcerts;
