import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { CreatePartner } from 'app/admin/graphql/partners/mutationHooks';
import { DetailsFormMainContainer } from 'app/admin/components/atoms/DetailContent';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

import PartnerEditForm from './PartnerEditForm';
import PartnerEditFormSchema from './PartnerEditFormSchema';

interface Props {
  contentProps?: any;
  setFormSubmitAction?: (func: Function) => void;
  setDisplayConfirmation?: (set: boolean) => void;
  setIsSubmitting?: (set: boolean) => void;
  hide: VoidFunction;
}

const PartnerCreate: React.FC<Props> = ({
  contentProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  hide,
}) => {
  const intl = useIntl();

  const [validationErrors, setValidationErrors] = useState<object>({});

  const formInitialValues = {
    title: '',
    description: '',
    address: '',
    address2: '',
  };

  const createPartnerAction = CreatePartner();

  const handleCreatePartner = useSubmitAction({
    submitAction: createPartnerAction,
    submitVariables: (values: any) => ({
      title: values.title,
      description: values.description,
      address: values.address,
      address2: values.address2,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.partnerCreate.successMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.partnerCreate.failureMessage',
    }),
    onSuccess: () => {
      contentProps.refetchPartners();
      hide();
    },
    onValidationError: validationErrors =>
      setValidationErrors(validationErrors),
  });

  return (
    <ModalContentContainer>
      <DetailsFormMainContainer>
        <DetailsHeader
          title={intl.formatMessage({
            id: 'admin.partnerCreate.title',
          })}
          isNewForm={true}
        />
        <GenericForm
          formInitialValues={formInitialValues}
          renderFormComponent={(renderProps: any) => (
            <PartnerEditForm
              formikProps={renderProps.formikProps}
              validationErrors={validationErrors}
            />
          )}
          onSubmit={handleCreatePartner}
          formSchema={PartnerEditFormSchema(intl)}
          setFormSubmitAction={setFormSubmitAction}
          setDisplayConfirmation={setDisplayConfirmation}
          setIsSubmitting={setIsSubmitting}
          dataQaId="partner-create-form"
        />
      </DetailsFormMainContainer>
    </ModalContentContainer>
  );
};

export default PartnerCreate;
