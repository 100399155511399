import styled from 'styled-components';

import { LinkButton } from 'app/shared/components/atoms/Button';

const LoadMoreButton = styled(LinkButton)`
  background-color: transparent;
  padding: 0 0;
  text-align: left;
  font-size: 10px;
  font-weight: normal;
  line-height: 12px;
  letter-spacing: 1.5px;

  &:focus {
    background-color: transparent;
  }
`;

export default LoadMoreButton;
