import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { GeneralEvent } from 'app/typings/events';
import { get } from 'app/shared/utils/get';
import { isEmpty } from 'app/shared/utils/object';
import { Container } from 'app/shared/components/atoms/Container';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Spinner } from 'app/shared/components/atoms/SpinnerManualCSS';
import { H3 } from 'app/shared/components/atoms/TypographyManualCSS';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { GetArtistEventsInfo } from 'app/admin/graphql/events/queryHooks';
import { GetVenueEvents } from 'app/admin/graphql/venues/queryHooks';
import { DetailsInfoMainContainer } from 'app/admin/components/atoms/DetailContent';
import LoadMoreButton from 'app/admin/components/atoms/LoadMoreButton';
import BookingRequestSummary from 'app/admin/components/organisms/BookingRequestSummary';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';
import EventSummary from 'app/admin/components/organisms/EventSummary';

interface Props {
  contentProps: any;
}

const Title = styled(H3)`
  font-weight: bold;
  margin-bottom: 10px;
`;

const GridContainer = styled.div`
  padding: 0;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  border-radius: 3px;
`;

const BodyContainer = styled(Container)`
  padding: 40px 0px 0px 0px !important;
`;

const LinkContainer = styled.div`
  text-align: center;
`;

const MobileSpacer = styled(Spacer)`
  ${({ theme }) => css`
    display: block;

    ${theme.media.md`
      display: none;
    `}
  `}
`;

const DottedLineCol = styled(Col)`
  ${({ theme }) => css`
    display: none;

    ${theme.media.md`
      display: block;
    `}
  `}
`;

const CenteredSpinner = styled(Spinner)`
  width: 200px;
  height: 200px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
`;

const renderLoadingSpinner = (title: string, dataQaidPrefix: string) => {
  return (
    <BodyContainer>
      <Title data-qaid="title">{title}</Title>
      <CenteredSpinner data-qaid={`loading-${dataQaidPrefix}`} size="100px" />
    </BodyContainer>
  );
};

const renderEvents = (
  events: GeneralEvent[],
  eventSummaryComponent: React.ComponentType<any> | undefined,
  artistId?: number
) => {
  const EventSummaryComponent = eventSummaryComponent || EventSummary;
  return events.map((event: GeneralEvent, index: number, events: any) => {
    const lastIndex = events.length - 1;
    const i = index + 1;
    const showDottedLine = i % 4 === 0 || index == lastIndex;

    return (
      <React.Fragment key={`${event.id}-${event.city.title || ''}`}>
        <Col data-qaid="listing-column" sm={6} md={3}>
          <EventSummaryComponent event={event} artistId={artistId} />
        </Col>
        {showDottedLine && (
          <DottedLineCol>
            <DottedLine />
          </DottedLineCol>
        )}
      </React.Fragment>
    );
  });
};

const eventConfig = {
  artistEventsUpcoming: {
    query: GetArtistEventsInfo,
    queryType: 'upcoming',
    eventKey: 'upcomingEvents',
    eventOuterKey: 'artistEventsInfo',
    eventMiddleKey: 'artistEventsInfo.upcomingEvents',
    eventInnerKey: 'events',
    eventListKey: 'artistEventsInfo.upcomingEvents.events',
    metadataKey: 'artistEventsInfo.upcomingEvents.metadata',
    eventSummaryComponent: EventSummary,
  },
  artistEventsPast: {
    query: GetArtistEventsInfo,
    queryType: 'past',
    eventKey: 'pastEvents',
    eventOuterKey: 'artistEventsInfo',
    eventMiddleKey: 'artistEventsInfo.pastEvents',
    eventInnerKey: 'events',
    eventListKey: 'artistEventsInfo.pastEvents.events',
    metadataKey: 'artistEventsInfo.pastEvents.metadata',
    eventSummaryComponent: EventSummary,
  },
  artistBookingRequests: {
    query: GetArtistEventsInfo,
    queryType: 'bookingRequests',
    eventKey: 'bookingRequests',
    eventOuterKey: 'artistEventsInfo',
    eventMiddleKey: 'artistEventsInfo.bookingRequests',
    eventInnerKey: 'bookingRequests',
    eventListKey: 'artistEventsInfo.bookingRequests.bookingRequests',
    metadataKey: 'artistEventsInfo.bookingRequests.metadata',
    eventSummaryComponent: BookingRequestSummary,
  },
  artistPerformanceOffers: {
    query: GetArtistEventsInfo,
    queryType: 'upcoming',
    eventKey: 'performanceOffers',
    eventOuterKey: 'artistEventsInfo',
    eventMiddleKey: 'artistEventsInfo.performanceOffers',
    eventInnerKey: 'events',
    eventListKey: 'artistEventsInfo.performanceOffers.events',
    metadataKey: 'artistEventsInfo.performanceOffers.metadata',
    eventSummaryComponent: EventSummary,
  },
  venueEventsUpcoming: {
    query: GetVenueEvents,
    queryType: 'upcoming',
    eventKey: 'upcomingEvents',
    eventOuterKey: 'venue',
    eventMiddleKey: 'venue.upcomingEvents',
    eventInnerKey: 'events',
    eventListKey: 'venue.upcomingEvents',
    metadataKey: 'venue.upcomingEventsMetadata',
    eventSummaryComponent: EventSummary,
  },
  venueEventsPast: {
    query: GetVenueEvents,
    queryType: 'past',
    eventKey: 'pastEvents',
    eventOuterKey: 'venue',
    eventMiddleKey: 'venue.pastEvents',
    eventInnerKey: 'events',
    eventListKey: 'venue.pastEvents',
    metadataKey: 'venue.pastEventsMetadata',
    eventSummaryComponent: EventSummary,
  },
};

const DetailsAllEvents: React.FC<Props> = ({ contentProps }) => {
  const intl = useIntl();
  const PAGE_SIZE = 12;

  const {
    eventType,
    id,
    title,
    imageUrl,
    largeImageUrl,
    imageStyle,
    dataQaidPrefix,
  } = contentProps;

  const query = eventConfig[eventType].query;
  const queryType = eventConfig[eventType].queryType;
  const eventKey = eventConfig[eventType].eventKey;
  const eventOuterKey = eventConfig[eventType].eventOuterKey;
  const eventMiddleKey = eventConfig[eventType].eventMiddleKey;
  const eventInnerKey = eventConfig[eventType].eventInnerKey;
  const eventListKey = eventConfig[eventType].eventListKey;
  const metadataKey = eventConfig[eventType].metadataKey;
  const eventSummaryComponent = eventConfig[eventType].eventSummaryComponent;

  const { loading, error, data, fetchMore } = query({
    id,
    scope: queryType,
    perPage: PAGE_SIZE,
  });

  const events = get(data, eventListKey, undefined);
  const totalNumEvents = get(data, `${metadataKey}.totalRecords`, undefined);
  const currentPage = get(data, `${metadataKey}.currentPage`, undefined);

  const [loadingMore, setLoadingMore] = useState(false);

  if (!events || !isEmpty(error) || (loading && !loadingMore)) {
    return renderLoadingSpinner(title, dataQaidPrefix);
  }

  function fetchMoreEvents(setLoadingMore: any) {
    let updateQuery = (prev: any, { fetchMoreResult }: any) => {
      if (!fetchMoreResult) {
        return prev;
      }
      setLoadingMore(false);

      return {
        ...prev,
        [eventOuterKey]: {
          ...prev[eventOuterKey],
          [eventKey]: {
            ...get(prev, eventMiddleKey, {}),
            [eventInnerKey]: [
              ...get(prev, eventListKey, {}),
              ...get(fetchMoreResult, eventListKey, {}),
            ],
            metadata: get(fetchMoreResult, metadataKey, {}),
          },
        },
      };
    };

    if (
      eventType === 'venueEventsPast' ||
      eventType === 'venueEventsUpcoming'
    ) {
      updateQuery = (prev: any, { fetchMoreResult }: any) => {
        if (!fetchMoreResult) {
          return prev;
        }
        setLoadingMore(false);
        return {
          ...prev,
          [eventOuterKey]: {
            ...prev[eventOuterKey],
            [eventKey]: [
              ...get(prev, eventListKey, {}),
              ...get(fetchMoreResult, eventListKey, {}),
            ],
            [`${eventKey}Metadata`]: get(fetchMoreResult, metadataKey, {}),
          },
        };
      };
    }

    return fetchMore({
      variables: {
        id,
        scope: queryType,
        page: currentPage + 1,
        perPage: PAGE_SIZE,
      },
      updateQuery,
    });
  }

  return (
    <ModalContentContainer>
      <DetailsInfoMainContainer>
        <DetailsHeader
          title={title}
          imageUrl={imageUrl}
          largeImageUrl={largeImageUrl}
          imageStyle={imageStyle}
        />
        <GridContainer>
          <BodyContainer>
            <Title data-qaid="title">
              {intl.formatMessage({
                id: `admin.detailsAllEvents.title.${eventKey}`,
              })}
            </Title>
            <Spacer mt={8} />
            <Grid data-qaid={`all-${dataQaidPrefix}-container`} gap="30px">
              {events && renderEvents(events, eventSummaryComponent, id)}
            </Grid>
            {totalNumEvents > events.length && (
              <LinkContainer>
                <MobileSpacer mt={4} />
                <LoadMoreButton
                  data-qaid="load-more-button"
                  onClick={() => {
                    setLoadingMore(true);
                    fetchMoreEvents(setLoadingMore);
                  }}
                  loading={loadingMore}
                >
                  {intl.formatMessage({
                    id: 'eventSection.More',
                  })}
                </LoadMoreButton>
              </LinkContainer>
            )}
          </BodyContainer>
        </GridContainer>
      </DetailsInfoMainContainer>
    </ModalContentContainer>
  );
};

export default DetailsAllEvents;
