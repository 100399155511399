import React from 'react';
import { Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontStyle: 'normal',
    height: '100%',
    width: '100%',
  },
  content: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  h1: {
    fontWeight: 400,
    fontSize: '32px',
    lineHeight: '150%',
    letterSpacing: '0.1px',
    textTransform: 'uppercase',
    margin: '54px auto 0 auto',
  },
  h2: {
    fontWeight: 400,
    fontSize: '44px',
    lineHeight: '150%',
    letterSpacing: '0.1px',
    textTransform: 'uppercase',
    marginBottom: '50px',
  },

  p: {
    fontSize: '24px',
    lineHeight: '120%',
    letterSpacing: '0.5px',
  },
  qrCodeView: {
    width: '204px',
    height: '204px',
    margin: '24px 0',
  },
  qrCode: {
    height: '100%',
    width: '100%',
  },
  urlText: {
    fontSize: '18px',
    lineHeight: '150%',
    letterSpacing: '0.5px',
  },
  logoView: {
    position: 'absolute',
    bottom: '0',
    right: '0',
    width: '93px',
    height: '45px',
    marginRight: '48px',
    marginBottom: '48px',
  },
  sofarLogo: {
    width: '93px',
    height: '45px',
  },
  versionView: {
    position: 'absolute',
    bottom: '0',
    left: '0',
    marginLeft: '48px',
  },
  versionText: {
    fontFamily: 'Courier',
    fontSize: '7px',
    lineHeight: '8px',
  },
});

interface LandscapePDFProps {
  cityTitle: string;
  citySlug: string;
  version: string;
  imgixDomain?: string;
}

// We can't internationalize strings here b/c when we use useIntl with withLocaleMessages, the
// react-pdf/renderer package internally triggers React errors:
//   TypeError: Cannot read properties of null (reading 'props')
// Googling doesn't surface any fixes to this, and we double-checked we're using the package correctly -
// for now we're more interested in keeping the dev console clear of meaningless React warnings so that
// we don't make mistakes in concert planner code, than we are in ensuring that the two strings below
// are internationalized
export const LandscapePDF: React.FC<LandscapePDFProps> = ({
  cityTitle,
  citySlug,
  version,
  imgixDomain,
}) => {
  return (
    <Page size="LETTER" style={styles.page} orientation="landscape">
      <View style={styles.content}>
        <Text style={styles.h1}>Welcome to Sofar</Text>
        <Text style={styles.h2}>{cityTitle}</Text>
        <Text style={styles.p}>Connect with tonight’s performers</Text>
        <View style={styles.qrCodeView}>
          <Image
            style={styles.qrCode}
            src={`${imgixDomain}/day_of_show_qr_codes/${citySlug}.png`}
          />
        </View>
        <Text style={styles.urlText}>
          {`sofarsounds.com/${citySlug}/today`}
        </Text>
      </View>
      <View fixed style={styles.logoView}>
        <Image
          style={styles.sofarLogo}
          src={`${imgixDomain}/day_of_show_qr_codes/sofar_logo_new.png`}
        />
      </View>
      <View fixed style={styles.versionView}>
        <Text style={styles.versionText}>{version}</Text>
      </View>
    </Page>
  );
};
