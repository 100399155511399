import config from 'app/lib/config';
import { useAddScript } from 'app/shared/utils/useAddScript';

interface Props {
  libraries: string[];
}

const API_URL_BASE = 'https://maps.googleapis.com/maps/api/js';

const apiUrl = (libraries: string[]) =>
  `${API_URL_BASE}?key=${config.googleMaps.key}&libraries=${libraries.join(
    ','
  )}`;

export const useGoogleMapsApi = ({ libraries }: Props) => {
  const scriptInitialized = useAddScript({
    scriptSrc: apiUrl(libraries),
    scriptId: `google_maps_${libraries.join('_')}`,
  });

  return scriptInitialized;
};
