import { useContext } from 'react';
import { useIntl } from 'react-intl';

import { AlertContext } from 'app/shared/context/Alert';
import { NotifyContext } from 'app/shared/context/Notify';

interface UseSubmitActionParameters {
  beforeSubmit?: (values?: any) => void;
  submitAction: Function;
  submitVariables: (values?: any) => {};
  successMsg?: string;
  successMsgValuesForDisplay?: object;
  failureMsg: string;
  onSuccess: (response?: any, resetForm?: any) => void;
  onError?: any;
  onValidationError?: (validationErrors?: any) => void;
  validationErrorKeysForDisplay?: object;
  messageContext?: 'notify' | 'alert';
}

const formatValidationErrorsForDisplay = (
  validationErrors: any,
  keysForDisplay: object = {}
) =>
  Object.keys(validationErrors).reduce(
    (obj, key) => ({
      ...obj,
      [key]: /^general.*/.test(key)
        ? validationErrors[key]
        : `This ${keysForDisplay[key] || key} ${validationErrors[key]}`,
    }),
    {}
  );

const setCustomGenericValidationError = (validationErrors?: any) => {
  if (validationErrors && Object.keys(validationErrors)[0] === 'msg') {
    validationErrors.general = Object.values(validationErrors)[0];
    delete validationErrors.msg;
  }
  return validationErrors;
};

const setCustomNamedValidationError = (validationErrors?: any) => {
  if (validationErrors && /^msg_.+$/.test(Object.keys(validationErrors)[0])) {
    const errKey = Object.keys(validationErrors)[0].replace(
      /^msg_/,
      'general_'
    );
    validationErrors[errKey] = Object.values(validationErrors)[0];
    delete validationErrors[Object.keys(validationErrors)[0]];
  }
  return validationErrors;
};

export const getValidationErrors = (error: any, keysForDisplay?: object) => {
  const validationErrorIndicatorTextActiveRecordError = 'Record invalid';
  const validationErrorIndicatorTextCustomError = 'Validation Error';

  if (error && error.graphQLErrors) {
    // There are two kinds of validation errors returned by backend:
    // 1. ActiveRecord: Errors raised automatically by Rails ActiveRecord when a 'save' action fails
    //    a model validation, e.g. field uniqueness validations
    // 2. Custom: Errors raised manually by raising GraphQL::UserValidationError in a GraphQL endpoint,
    //    e.g. custom validations requiring more complex logic than an ActiveRecord model validation

    const validationError = error.graphQLErrors.find(
      (o: any) =>
        o.message === validationErrorIndicatorTextActiveRecordError ||
        o.message === validationErrorIndicatorTextCustomError
    );

    let validationErrors =
      validationError &&
      validationError.extensions &&
      validationError.extensions.validation_errors;

    // If we have a GENERIC custom validation error ('msg'), store it in a pseudo-key named 'general'
    validationErrors = setCustomGenericValidationError(validationErrors);

    // If we have a NAMED custom validation error ('msg_blah_blah'), store it in a pseudo-key whose
    // name starts with 'general_' e.g. general_blah_blah
    validationErrors = setCustomNamedValidationError(validationErrors);

    if (validationErrors && Object.keys(validationErrors).length > 0) {
      return formatValidationErrorsForDisplay(validationErrors, keysForDisplay);
    } else {
      return null;
    }
  }

  return null;
};

const getValidationErrorsStr = (validationErrors: object) =>
  Object.keys(validationErrors)
    .map((key: string) => `${key} ${validationErrors[key][0]}`)
    .join(', ');

export const UseSubmitAction = ({
  beforeSubmit,
  submitAction,
  submitVariables,
  successMsg,
  successMsgValuesForDisplay,
  failureMsg,
  onSuccess,
  onError,
  onValidationError,
  validationErrorKeysForDisplay,
  messageContext = 'notify',
}: UseSubmitActionParameters) => {
  const intl = useIntl();
  const notifyContext = useContext(NotifyContext);
  const alertContext = useContext(AlertContext);
  const failureMsgFull = intl.formatMessage(
    {
      id: 'submitAction.failureMsgFull',
    },
    {
      failureMsg,
    }
  );

  const getSuccessMsg = (values: any) => {
    if (successMsgValuesForDisplay) {
      for (const valueName of Object.keys(successMsgValuesForDisplay)) {
        const regex = new RegExp(`\\[${valueName}\\]`);
        successMsg = successMsg?.replace(
          regex,
          successMsgValuesForDisplay[valueName](values)
        );
      }
    }
    return successMsg;
  };

  const showTechnicalError = () =>
    process.env.NODE_ENV !== 'production' ||
    process.env.SHOW_MUTATION_TECH_ERRORS === 'true';

  const failureMsgFullTechnical = (error: string) => {
    return `${failureMsgFull} (${intl.formatMessage({
      id: 'submitAction.technicalError',
    })}: ${error})`;
  };

  const getFailureMsg = (error: string) =>
    showTechnicalError() ? failureMsgFullTechnical(error) : failureMsgFull;

  return async (values?: any, { resetForm }: { resetForm?: any } = {}) => {
    try {
      if (beforeSubmit) {
        beforeSubmit(values);
      }
      const response = await submitAction({
        variables: submitVariables(values),
      });
      if (response.data) {
        if (successMsg) {
          if (messageContext === 'alert') {
            alertContext.addAlert({
              type: 'success',
              message: getSuccessMsg(values) || '',
            });
          } else {
            notifyContext.addMessage(getSuccessMsg(values) || '');
          }
        }
        onSuccess(response, resetForm);
      }
      /* eslint-disable-next-line prettier/prettier */
    } catch (e: any) {
      const validationErrors = getValidationErrors(
        e,
        validationErrorKeysForDisplay
      );
      if (validationErrors) {
        if (onValidationError) {
          onValidationError(validationErrors);
        } else {
          if (messageContext === 'alert') {
            alertContext.addAlert({
              type: 'error',
              message: getValidationErrorsStr(validationErrors),
            });
          } else {
            notifyContext.addMessage(
              getFailureMsg(getValidationErrorsStr(validationErrors))
            );
          }
        }
      } else {
        onError && onError(e);
        if (messageContext === 'alert') {
          alertContext.addAlert({
            type: 'error',
            message: getFailureMsg(e.message),
          });
        } else {
          notifyContext.addMessage(getFailureMsg(e.message));
        }
      }
    }
  };
};
