import React from 'react';
import styled from 'styled-components';

import { errorMsgForField } from 'app/shared/utils/form';
import { FormSectionTitle } from 'app/shared/components/atoms/FormContent';
import GenericFormContainer from 'app/shared/components/atoms/GenericFormContainer';
import ImageUploaderWithCropper from 'app/shared/components/organisms/ImageUploaderWithCropper';

interface FormProps {
  formikProps: any;
  setCropAction?: (func: Function) => Promise<Record<string, any>>;
  setDisplaySubmitButton?: (set: boolean) => void;
  title: string;
  subtitle?: string;
  cropperRatio: number;
  dataQaidPrefix: string;
}

const FormGroupContainerMessage = styled.div`
  font-size: 16px;
  padding-bottom: 20px;
`;

const ImageUploaderForm: React.FC<FormProps> = ({
  formikProps,
  setCropAction,
  setDisplaySubmitButton,
  title,
  subtitle,
  cropperRatio,
  dataQaidPrefix,
}) => {
  const { setFieldValue, touched, errors } = formikProps;

  return (
    <GenericFormContainer dataQaId={`${dataQaidPrefix}-image-edit-form`}>
      <FormSectionTitle>{title}</FormSectionTitle>
      {subtitle && (
        <FormGroupContainerMessage>{subtitle}</FormGroupContainerMessage>
      )}
      <ImageUploaderWithCropper
        imagePreviewShape="square"
        numberOfImages={1}
        cropperRatio={cropperRatio}
        formatAndUploadSizeInfo="admin.imageUploaderForm.formatAndUploadSizeInfo"
        onChange={(value: any) => {
          setFieldValue('mainImage', value);
        }}
        images={[formikProps.imageUrl]}
        errorMsg={errorMsgForField('mainImage', touched, errors)}
        hideCropButton={true}
        // @ts-ignore
        setCropAction={setCropAction}
        setDisplaySubmitButton={setDisplaySubmitButton}
      />
    </GenericFormContainer>
  );
};

export default ImageUploaderForm;
