import React from 'react';
import loadable from '@loadable/component';

import { ModalLoading } from './ModalLoading';

const ConfirmationModal = loadable(() =>
  import('app/shared/components/molecules/ConfirmationModal')
);

const ModalRoutes = {
  'artist-details': {
    content: loadable(() =>
      import('app/admin/components/organisms/ArtistDetails')
    ),
    metadata: {
      displayModalFooter: false,
    },
  },
  'artist-create': {
    content: loadable(() =>
      import('app/admin/components/organisms/ArtistBasicInfo/ArtistCreate')
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
    },
  },
  'artist-basic-info-edit': {
    content: loadable(() =>
      import(
        'app/admin/components/organisms/ArtistBasicInfo/ArtistBasicInfoEdit'
      )
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
      onGoBackExtraRouteProps: { defaultOpenSection: 'basicInfo' },
    },
  },
  'artist-availability-preferences': {
    content: loadable(() =>
      import(
        'app/artist/components/organisms/ArtistProfileEditForm/ArtistAvailabilityPreferences'
      )
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
    },
  },
  'artist-all-events': {
    content: loadable(() =>
      import('app/admin/components/organisms/DetailsAllEvents')
    ),
  },
  'artist-application-review': {
    content: loadable(() =>
      import('app/admin/components/organisms/ArtistApplicationReview')
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
      submitButtonTextKey: 'form.submit',
      backButtonTextKey: 'form.close',
    },
  },
  'artist-merge': {
    content: loadable(() =>
      import('app/admin/components/organisms/ArtistMerge')
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
    },
  },
  'artist-profile-image-uploader': {
    content: loadable(() =>
      import('app/admin/components/organisms/ArtistProfileImageUploader')
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
    },
  },
  'artist-all-feedbacks': {
    content: loadable(() =>
      import('app/admin/components/organisms/ArtistAllFeedbacks')
    ),
    metadata: {
      onGoBackExtraRouteProps: { defaultOpenSection: 'feedback' },
    },
  },
  'artist-feedback-create': {
    content: loadable(() =>
      import('app/admin/components/organisms/ArtistFeedbackCreate')
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
      onGoBackExtraRouteProps: { defaultOpenSection: 'feedback' },
    },
  },
  'artist-all-videos': {
    content: loadable(() =>
      import('app/admin/components/organisms/ArtistAllVideos')
    ),
  },
  'artist-video-edit': {
    content: loadable(() =>
      import('app/admin/components/organisms/ArtistVideoEdit')
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
      onGoBackExtraRouteProps: { defaultOpenSection: 'media' },
    },
  },
  'artist-socials-edit': {
    content: loadable(() =>
      import('app/admin/components/organisms/ArtistSocialsEdit')
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
      onGoBackExtraRouteProps: { defaultOpenSection: 'media' },
    },
  },
  'artist-self-booking-create': {
    content: loadable(() =>
      import('app/artist/components/organisms/ArtistSelfBooking')
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
    },
  },
  'artist-setup-edit': {
    content: loadable(() =>
      import('app/shared/components/organisms/ArtistSetupEdit')
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
    },
  },
  'artist-setup-selection': {
    content: loadable(() =>
      import(
        'app/artist/components/organisms/ArtistOfferCard/SetupSelectionModalContent'
      )
    ),
    metadata: {
      isForm: true,
    },
  },
  'booking-request-tour-request': {
    content: loadable(() =>
      import('app/admin/components/organisms/BookingRequestTourRequest')
    ),
    metadata: {
      displayModalFooter: false,
    },
  },
  'booking-request-accept': {
    content: loadable(() =>
      import('app/admin/components/organisms/BookingRequestAccept')
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
    },
  },
  'broadcast-create': {
    content: loadable(() =>
      import('app/admin/components/organisms/BroadcastEdit/BroadcastCreate')
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
    },
  },
  'broadcast-edit': {
    content: loadable(() =>
      import('app/admin/components/organisms/BroadcastEdit')
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
    },
  },
  'business-owner-create': {
    content: loadable(() =>
      import(
        'app/admin/components/organisms/BusinessOwnerEdit/BusinessOwnerCreate'
      )
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
    },
  },
  'business-owner-edit': {
    content: loadable(() =>
      import(
        'app/admin/components/organisms/BusinessOwnerEdit/BusinessOwnerEdit'
      )
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
    },
  },
  'campaign-create': {
    content: loadable(() =>
      import('app/admin/components/organisms/CampaignEdit/CampaignCreate')
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
    },
  },
  'campaign-edit': {
    content: loadable(() =>
      import('app/admin/components/organisms/CampaignEdit')
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
    },
  },
  'city-details': {
    content: loadable(() =>
      import('app/admin/components/organisms/CityDetails')
    ),
    metadata: {
      displayModalFooter: false,
    },
  },
  'city-header-image-uploader': {
    content: loadable(() =>
      import(
        'app/admin/components/organisms/CityDetails/CityHeaderImageUploader'
      )
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
    },
  },
  'city-promo-header-image-uploader': {
    content: loadable(() =>
      import(
        'app/admin/components/organisms/CityDetails/CityPromoHeaderImageUploader'
      )
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
    },
  },
  'city-create': {
    content: loadable(() =>
      import('app/admin/components/organisms/CityBasicInfo/CityCreate')
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
    },
  },
  'city-basic-info-edit': {
    content: loadable(() =>
      import('app/admin/components/organisms/CityBasicInfo/CityBasicInfoEdit')
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
      onGoBackExtraRouteProps: { defaultOpenSection: 'basicInfo' },
    },
  },
  'city-emails-edit': {
    content: loadable(() =>
      import('app/admin/components/organisms/CityEmailsEdit')
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
      onGoBackExtraRouteProps: { defaultOpenSection: 'emails' },
    },
  },
  'city-settings-edit': {
    content: loadable(() =>
      import('app/admin/components/organisms/CitySettingsEdit')
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
      onGoBackExtraRouteProps: { defaultOpenSection: 'settings' },
    },
  },
  'competition-background-image-uploader': {
    content: loadable(() =>
      import(
        'app/admin/components/organisms/CompetitionBackgroundImageUploader'
      )
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
      onGoBackExtraRouteProps: { defaultOpenSection: 'content' },
    },
  },
  'competition-basic-info-edit': {
    content: loadable(() =>
      import(
        'app/admin/components/organisms/CompetitionBasicInfo/CompetitionBasicInfoEdit'
      )
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
      onGoBackExtraRouteProps: { defaultOpenSection: 'basicInfo' },
    },
  },
  'competition-content-edit': {
    content: loadable(() =>
      import('app/admin/components/organisms/CompetitionContentEdit')
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
      onGoBackExtraRouteProps: { defaultOpenSection: 'content' },
    },
  },
  'competition-create': {
    content: loadable(() =>
      import(
        'app/admin/components/organisms/CompetitionBasicInfo/CompetitionCreate'
      )
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
    },
  },
  'competition-details': {
    content: loadable(() =>
      import('app/admin/components/organisms/CompetitionDetails')
    ),
    metadata: {
      displayModalFooter: false,
    },
  },
  'competition-logo-image-uploader': {
    content: loadable(() =>
      import('app/admin/components/organisms/CompetitionLogoImageUploader')
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
      onGoBackExtraRouteProps: { defaultOpenSection: 'content' },
    },
  },
  'competition-more-info-image-uploader': {
    content: loadable(() =>
      import('app/admin/components/organisms/CompetitionMoreInfoImageUploader')
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
      onGoBackExtraRouteProps: { defaultOpenSection: 'content' },
    },
  },
  'competition-share-image-uploader': {
    content: loadable(() =>
      import('app/admin/components/organisms/CompetitionShareImageUploader')
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
      onGoBackExtraRouteProps: { defaultOpenSection: 'sharing' },
    },
  },
  'competition-sharing-edit': {
    content: loadable(() =>
      import('app/admin/components/organisms/CompetitionSharingEdit')
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
      onGoBackExtraRouteProps: { defaultOpenSection: 'sharing' },
    },
  },
  'competition-templates': {
    content: loadable(() =>
      import('app/admin/components/organisms/CompetitionTemplates')
    ),
    metadata: {
      displayModalFooter: false,
    },
  },
  'competition-template-create': {
    content: loadable(() =>
      import('app/admin/components/organisms/CompetitionTemplateCreate')
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
    },
  },
  'competition-template-edit': {
    content: loadable(() =>
      import('app/admin/components/organisms/CompetitionTemplateEdit')
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
    },
  },
  // eslint-disable-next-line prettier/prettier
  continents: {
    content: loadable(() =>
      import('app/admin/components/organisms/Continents')
    ),
    metadata: {
      displayModalFooter: false,
    },
  },
  'continent-create': {
    content: loadable(() =>
      import('app/admin/components/organisms/ContinentEdit/ContinentCreate')
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
    },
  },
  'continent-edit': {
    content: loadable(() =>
      import('app/admin/components/organisms/ContinentEdit')
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
    },
  },
  'country-details': {
    content: loadable(() =>
      import('app/admin/components/organisms/CountryDetails')
    ),
    metadata: {
      displayModalFooter: false,
    },
  },
  'country-create': {
    content: loadable(() =>
      import('app/admin/components/organisms/CountryBasicInfo/CountryCreate')
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
    },
  },
  'country-basic-info-edit': {
    content: loadable(() =>
      import(
        'app/admin/components/organisms/CountryBasicInfo/CountryBasicInfoEdit'
      )
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
      onGoBackExtraRouteProps: { defaultOpenSection: 'basicInfo' },
    },
  },
  'country-settings-edit': {
    content: loadable(() =>
      import('app/admin/components/organisms/CountrySettingsEdit')
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
      onGoBackExtraRouteProps: { defaultOpenSection: 'settings' },
    },
  },
  'crew-all-messages': {
    content: loadable(() =>
      import(
        'app/admin/components/organisms/CrewEventBreakingNews/ViewAllBreakingNews'
      )
    ),
    metadata: {
      onGoBackExtraRouteProps: { defaultOpenSection: 'team' },
    },
  },
  'crew-unavailability-dates': {
    content: loadable(() =>
      import('app/admin/components/organisms/CrewUnavailabilityDates')
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
    },
  },
  'crew-feedback-events': {
    content: loadable(() =>
      import('app/admin/components/organisms/CrewFeedbackableEvents')
    ),
    metadata: {
      displayModalFooter: false,
    },
  },
  'crew-feedback-event': {
    content: loadable(() =>
      import(
        'app/admin/components/organisms/CrewFeedbackableEvents/CrewEventSurvey'
      )
    ),
    metadata: {
      displayModalFooter: false,
    },
  },
  'crew-user-details': {
    content: loadable(() =>
      import('app/admin/components/organisms/CrewUserDetails')
    ),
    metadata: {
      displayModalFooter: false,
    },
  },
  'crew-user-basic-info-edit': {
    content: loadable(() =>
      import('app/admin/components/organisms/CrewUserBasicInfoEdit')
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
      onGoBackExtraRouteProps: { defaultOpenSection: 'basicInfo' },
    },
  },
  'curator-group-details': {
    content: loadable(() =>
      import('app/admin/components/organisms/CuratorGroupDetails')
    ),
    metadata: {
      displayModalFooter: false,
    },
  },
  'curator-group-create': {
    content: loadable(() =>
      import(
        'app/admin/components/organisms/CuratorGroupBasicInfo/CuratorGroupCreate'
      )
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
    },
  },
  'curator-group-basic-info-edit': {
    content: loadable(() =>
      import(
        'app/admin/components/organisms/CuratorGroupBasicInfo/CuratorGroupBasicInfoEdit'
      )
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
      onGoBackExtraRouteProps: { defaultOpenSection: 'basicInfo' },
    },
  },
  'curator-group-emails-edit': {
    content: loadable(() =>
      import('app/admin/components/organisms/CuratorGroupEmails')
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
      onGoBackExtraRouteProps: { defaultOpenSection: 'email' },
    },
  },
  'email-message-content': {
    content: loadable(() =>
      import('app/admin/components/organisms/EmailMessageContent')
    ),
    metadata: {
      backButtonTextKey: 'form.close',
    },
  },
  'event-create': {
    content: loadable(() =>
      import('app/admin/components/organisms/EventCreate')
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
      displaySecondarySubmitButton: true,
    },
  },
  'event-details': {
    content: loadable(() =>
      import('app/admin/components/organisms/EventDetails')
    ),
    metadata: {
      displayModalFooter: false,
    },
  },
  'event-basic-info-edit': {
    content: loadable(() =>
      import('app/admin/components/organisms/EventBasicInfoEdit')
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
      onGoBackExtraRouteProps: { defaultOpenSection: 'basicInfo' },
    },
  },
  'event-details-venue-edit': {
    content: loadable(() =>
      import(
        'app/admin/components/organisms/EventDetailsVenueEdit/EventDetailsVenueEdit'
      )
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
      onGoBackExtraRouteProps: { defaultOpenSection: 'venueInfo' },
    },
  },
  'event-details-ticketing-edit': {
    content: loadable(() =>
      import('app/admin/components/organisms/EventDetailsTicketingEdit')
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
      onGoBackExtraRouteProps: { defaultOpenSection: 'ticketingInfo' },
    },
  },
  'event-email-preview-content': {
    content: loadable(() =>
      import('app/admin/components/organisms/EventEmailPreviewContent')
    ),
    metadata: {
      backButtonTextKey: 'form.back',
      onGoBackExtraRouteProps: { defaultOpenSection: 'emailsInfo' },
    },
  },
  guestlist: {
    content: loadable(
      () => import('app/admin/components/organisms/Guestlist'),
      {
        fallback: <ModalLoading />,
      }
    ),
    metadata: {
      backButtonTextKey: 'form.close',
      onGoBackExtraRouteProps: { defaultOpenSection: 'ticketingInfo' },
    },
  },
  'guestlist-day-of-show': {
    content: loadable(
      () => import('app/admin/components/organisms/GuestlistDayOfShow'),
      {
        fallback: <ModalLoading />,
      }
    ),
    metadata: {
      backButtonTextKey: 'form.close',
    },
  },
  'event-header-image-uploader': {
    content: loadable(() =>
      import(
        'app/admin/components/organisms/EventDetails/EventHeaderImageUploader'
      )
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
    },
  },
  'event-details-partner-edit': {
    content: loadable(() =>
      import('app/admin/components/organisms/EventDetailsPartnerEdit')
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
      onGoBackExtraRouteProps: { defaultOpenSection: 'partnerInfo' },
    },
  },
  'event-email-settings-edit': {
    content: loadable(() =>
      import('app/admin/components/organisms/EventEmailSettingsEdit')
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
      backButtonTextKey: 'form.back',
      onGoBackExtraRouteProps: { defaultOpenSection: 'emailsInfo' },
    },
  },
  'event-send-urgent-email': {
    content: loadable(() =>
      import('app/admin/components/organisms/EventSendUrgentEmail')
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
      backButtonTextKey: 'form.back',
      submitButtonTextKey: 'form.send',
      onGoBackExtraRouteProps: { defaultOpenSection: 'emailsInfo' },
    },
  },
  'event-collection-details': {
    content: loadable(() =>
      import('app/admin/components/organisms/EventCollectionDetails')
    ),
    metadata: {
      displayModalFooter: false,
    },
  },
  'event-collection-header-image-uploader': {
    content: loadable(() =>
      import(
        'app/admin/components/organisms/EventCollectionDetails/EventCollectionHeaderImageUploader'
      )
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
    },
  },
  'event-collection-create': {
    content: loadable(() =>
      import(
        'app/admin/components/organisms/EventCollectionBasicInfo/CollectionCreate'
      )
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
    },
  },
  'event-collection-basic-info-edit': {
    content: loadable(() =>
      import(
        'app/admin/components/organisms/EventCollectionBasicInfo/CollectionBasicInfoEdit'
      )
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
    },
  },
  'guestlist-attendance-edit': {
    content: loadable(() =>
      import('app/admin/components/organisms/GuestlistAttendanceEdit')
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
    },
  },
  'internal-user-details': {
    content: loadable(() =>
      import('app/admin/components/organisms/InternalUserDetails')
    ),
    metadata: {
      displayModalFooter: false,
    },
  },
  'internal-user-basic-info-edit': {
    content: loadable(() =>
      import('app/admin/components/organisms/InternalUserBasicInfoEdit')
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
      onGoBackExtraRouteProps: { defaultOpenSection: 'basicInfo' },
    },
  },
  'neighborhood-create': {
    content: loadable(() =>
      import(
        'app/admin/components/organisms/NeighborhoodBasicInfo/NeighborhoodCreate'
      )
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
    },
  },
  'neighborhood-basic-info-edit': {
    content: loadable(() =>
      import(
        'app/admin/components/organisms/NeighborhoodBasicInfo/NeighborhoodBasicInfoEdit'
      )
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
    },
  },
  'offer-create': {
    content: loadable(() =>
      import('app/admin/components/organisms/OfferCreate')
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
    },
  },
  'offer-details': {
    content: loadable(() =>
      import('app/admin/components/organisms/OfferDetails')
    ),
    metadata: {
      displayModalFooter: false,
    },
  },
  'partner-create': {
    content: loadable(() =>
      import('app/admin/components/organisms/PartnerEdit/PartnerCreate')
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
    },
  },
  'partner-edit': {
    content: loadable(() =>
      import('app/admin/components/organisms/PartnerEdit')
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
    },
  },
  'partner-promotion-image-uploader': {
    content: loadable(() =>
      import('app/admin/components/organisms/PartnerPromotionImageUploader')
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
    },
  },
  'primary-roles': {
    content: loadable(() =>
      import('app/admin/components/organisms/PrimaryRoles')
    ),
    metadata: {
      displayModalFooter: false,
    },
  },
  'primary-role-create': {
    content: loadable(() =>
      import(
        'app/admin/components/organisms/PrimaryRoleBasicInfoEdit/PrimaryRoleCreate'
      )
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
    },
  },
  'primary-role-basic-info-edit': {
    content: loadable(() =>
      import('app/admin/components/organisms/PrimaryRoleBasicInfoEdit')
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
    },
  },
  'promo-code-details': {
    content: loadable(() =>
      import('app/admin/components/organisms/PromoCodeDetails')
    ),
    metadata: {
      displayModalFooter: false,
    },
  },
  'promo-code-create': {
    content: loadable(() =>
      import(
        'app/admin/components/organisms/PromoCodeBasicInfo/PromoCodeCreate'
      )
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
    },
  },
  'promo-code-basic-info-edit': {
    content: loadable(() =>
      import(
        'app/admin/components/organisms/PromoCodeBasicInfo/PromoCodeBasicInfoEdit'
      )
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
    },
  },
  'site-banner-edit': {
    content: loadable(() =>
      import('app/admin/components/organisms/SiteBannerEdit')
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
    },
  },
  'theme-create': {
    content: loadable(() =>
      import('app/admin/components/organisms/ThemeEdit/ThemeCreate')
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
    },
  },
  'theme-edit': {
    content: loadable(() =>
      import('app/admin/components/organisms/ThemeEdit/ThemeEdit')
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
    },
  },
  'user-details': {
    content: loadable(() =>
      import('app/admin/components/organisms/UserDetails')
    ),
    metadata: {
      displayModalFooter: false,
    },
  },
  'user-basic-info-edit': {
    content: loadable(() =>
      import('app/admin/components/organisms/UserBasicInfoEdit')
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
      onGoBackExtraRouteProps: { defaultOpenSection: 'basicInfo' },
    },
  },
  'user-role-add': {
    content: loadable(() =>
      import('app/admin/components/organisms/UserRoleAdd')
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
    },
  },
  'venue-details': {
    content: loadable(() =>
      import('app/admin/components/organisms/VenueDetails')
    ),
    metadata: {
      displayModalFooter: false,
    },
  },
  'venue-create': {
    content: loadable(() =>
      import('app/admin/components/organisms/VenueBasicInfo/VenueCreate')
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
    },
  },
  'venue-setup-photo-uploader': {
    content: loadable(() =>
      import('app/admin/components/organisms/VenueSetupPhotoUploader')
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
      displayModalFooter: true,
    },
  },
  'venue-photos': {
    content: loadable(() =>
      import(
        'app/admin/components/organisms/CrewEventVenueSetupInfo/ViewVenuePhotos'
      )
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      displayModalFooter: false,
    },
  },
  'venue-basic-info-edit': {
    content: loadable(() =>
      import('app/admin/components/organisms/VenueBasicInfo/VenueBasicInfoEdit')
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
      onGoBackExtraRouteProps: { defaultOpenSection: 'basicInfo' },
    },
  },
  'venue-fan-experience-edit': {
    content: loadable(() =>
      import('app/admin/components/organisms/VenueFanExperienceEdit')
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
      onGoBackExtraRouteProps: { defaultOpenSection: 'fanExperience' },
    },
  },
  'venue-all-events': {
    content: loadable(() =>
      import('app/admin/components/organisms/DetailsAllEvents')
    ),
  },
  'venue-booking-information-edit': {
    content: loadable(() =>
      import('app/admin/components/organisms/VenueBookingInformationEdit')
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
      onGoBackExtraRouteProps: { defaultOpenSection: 'bookingInformation' },
    },
  },
  'venue-event-setup-edit': {
    content: loadable(() =>
      import('app/admin/components/organisms/VenueEventSetupEdit')
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
      onGoBackExtraRouteProps: { defaultOpenSection: 'eventSetup' },
    },
  },
  'venue-photo-carousel': {
    content: loadable(() =>
      import('app/admin/components/organisms/VenuePhotoCarousel')
    ),
  },
  'venue-profile-image-uploader': {
    content: loadable(() =>
      import('app/admin/components/organisms/VenueProfileImageUploader')
    ),
    confirmationComponent: ConfirmationModal,
    metadata: {
      isForm: true,
    },
  },
  'large-image': {
    content: loadable(() =>
      import('app/admin/components/molecules/LargeImage')
    ),
    metadata: {
      backButtonTextKey: 'form.back',
    },
  },
};

export default ModalRoutes;
