import React from 'react';
import moment from 'moment';
import Calendar from 'rc-calendar';
import DatePicker from 'rc-calendar/lib/Picker';
import styled from 'styled-components';

import 'rc-calendar/assets/index.css';

const StyledInput = styled.input`
  border: 1px solid #dbdbdb;
  padding: 10px 20px;
  width: 50%;
  max-width: 253px;
`;

interface Props {
  name: string;
  value: any;
  placeholder: any;
  onChange: (e: any) => void;
  numDaysIntoFutureDefaultValue?: number;
  numDaysIntoFutureToDisable?: number;
}

const SimpleDatePicker: React.FC<Props> = ({
  name,
  value,
  placeholder,
  onChange,
  numDaysIntoFutureDefaultValue,
  numDaysIntoFutureToDisable,
}) => {
  const handleChange = (e: any) => {
    const fullDate = e || value;
    onChange(fullDate);
  };

  const defaultValue = () => {
    const now = moment();
    // Set default value to either a date a given number of days into future, or today (default) -
    // examples for numDaysIntoFutureDefaultValue:
    //  yesterday = -1
    //  today = 0
    //  tomorrow = 1
    //  day after tomorrow = 2
    //  etc...
    const defaultValueDay = now.add(
      numDaysIntoFutureDefaultValue || numDaysIntoFutureDefaultValue == 0
        ? numDaysIntoFutureDefaultValue
        : 0,
      'days'
    );
    return defaultValueDay;
  };

  const disabledDate = (value: any) => {
    const now = moment();
    // Disable dates either up through the given number of days into future, or up through yesterday (default) -
    // examples for numDaysIntoFutureToDisable:
    //  yesterday = -1
    //  today = 0
    //  tomorrow = 1
    //  day after tomorrow = 2
    //  etc...
    const lastDayToDisable = now.add(
      numDaysIntoFutureToDisable || numDaysIntoFutureToDisable == 0
        ? numDaysIntoFutureToDisable
        : -1,
      'days'
    );
    return value < lastDayToDisable.endOf('day');
  };

  const calendar = (
    <Calendar
      showToday={false}
      showDateInput={false}
      defaultValue={defaultValue()}
      disabledDate={disabledDate}
    />
  );

  return (
    <DatePicker
      data-qaid="start-date"
      calendar={calendar}
      id={name}
      value={value}
      onChange={handleChange}
    >
      {(props: any) => {
        return (
          <StyledInput
            data-qaid="date-input"
            readOnly
            name={name}
            value={props.value ? props.value.format('D MMMM YYYY') : ''}
            placeholder={placeholder}
          />
        );
      }}
    </DatePicker>
  );
};

export default SimpleDatePicker;
