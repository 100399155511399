import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { dateFormatter } from 'app/shared/utils/datetime';
import { OutlineButton } from 'app/shared/components/atoms/Button';
import { Checkbox } from 'app/shared/components/atoms/CheckboxManualCSS';
import {
  FormHelpText,
  FormSectionTitle,
} from 'app/shared/components/atoms/FormContent';
import GenericFormContainer from 'app/shared/components/atoms/GenericFormContainer';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Textarea } from 'app/shared/components/atoms/Textarea';
import { Textfield } from 'app/shared/components/atoms/Textfield';
import FormGroup from 'app/shared/components/molecules/FormGroup';
import Select from 'app/shared/components/molecules/SelectManualCSS';
import YouTubePlayer from 'app/shared/components/organisms/Prismic/YouTubePlayer';

interface FormProps {
  formikProps: any;
  toggleVideoDeleteModal: VoidFunction;
}

const StyledFormContainer = styled(GenericFormContainer)`
  max-width: 432px;
`;

const StyledTextfield = styled(Textfield)`
  display: block;
  width: 100%;
`;

const StyledTextarea = styled(Textarea)`
  display: block;
  width: 100%;
`;

// Container to prevent Input to grow to full width
const FormGroupContainer = styled.div`
  ${({ theme }) => css`
    max-width: 400px;
    padding: 16px 32px;
    background-color: ${theme.colors.silverSprings};
  `}
`;

const VideoContainer = styled.div`
  width: 100%;
  height: fit-content;
`;

const DeleteButtonWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 16px;
`;

const errorMsgForField = (field: string, touched: any, errors: any) =>
  touched[field] && errors[field] ? errors[field] : null;

const ArtistVideoEditForm: React.FC<FormProps> = ({
  formikProps,
  toggleVideoDeleteModal,
}) => {
  const intl = useIntl();

  return (
    <StyledFormContainer dataQaId="artist-video-edit-form">
      <FormSectionTitle>
        {formikProps.values.videoId
          ? intl.formatMessage({
              id: 'admin.artistVideoEdit.form.editVideo',
            })
          : intl.formatMessage({
              id: 'admin.artistVideoEdit.form.addVideo',
            })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormHelpText>
          {intl.formatMessage({
            id: 'admin.artistVideoEdit.form.videosHelpText',
          })}
        </FormHelpText>
        <Spacer mb={4} />
        {formikProps.values.link && (
          <>
            <VideoContainer>
              <YouTubePlayer url={formikProps.values.link} />
            </VideoContainer>
            <Spacer mb={8} />
          </>
        )}
        <FormGroup
          data-qaid="artist-video-edit-form-title-formgroup"
          label={intl.formatMessage({
            id: 'shared.title',
          })}
          errorMsg={errorMsgForField(
            'title',
            formikProps.touched,
            formikProps.errors
          )}
          required
        >
          <StyledTextfield
            data-qaid="artist-video-edit-form-title-field"
            id="title"
            name="title"
            value={formikProps.values.title}
            onChange={(e: any) => {
              formikProps.setFieldValue('title', e.target.value);
            }}
            maxLength={200}
          />
        </FormGroup>
      </FormGroupContainer>
      <FormGroupContainer>
        <FormGroup
          data-qaid="artist-video-edit-form-performance-formgroup"
          label={intl.formatMessage({
            id: 'admin.artistVideoEdit.form.event',
          })}
          errorMsg={errorMsgForField(
            'performanceId',
            formikProps.touched,
            formikProps.errors
          )}
          required
        >
          <Select
            data-qaid="artist-video-edit-performance-field"
            searchable={true}
            getOptionLabel={(option: any) =>
              `${dateFormatter(
                option.event.localStartsAt,
                'shortMonthDateYearHoursAndMinutesWithSpacedAMPMWithTimezone'
              )} \u2014 ${option.event.city.title}`
            }
            defaultValue={formikProps.values.performances.find(
              (p: { id: string }) => p.id === formikProps.values.performanceId
            )}
            options={formikProps.values.performances}
            placeholder={intl.formatMessage({
              id: 'admin.artistVideoEdit.form.placeholderPerformance',
            })}
            onChange={(opt: any) => {
              if (opt) {
                formikProps.setFieldValue('performanceId', opt.id);
              }
            }}
          />
        </FormGroup>
      </FormGroupContainer>
      <FormGroupContainer>
        <FormGroup
          data-qaid="artist-video-edit-form-link-formgroup"
          label={intl.formatMessage({
            id: 'admin.artistVideoEdit.form.link',
          })}
          errorMsg={errorMsgForField(
            'link',
            formikProps.touched,
            formikProps.errors
          )}
          required
        >
          <StyledTextfield
            data-qaid="artist-video-edit-form-link-field"
            id="link"
            name="link"
            value={formikProps.values.link}
            onChange={(e: any) => {
              formikProps.setFieldValue('link', e.target.value);
            }}
            maxLength={200}
          />
        </FormGroup>
      </FormGroupContainer>
      <FormGroupContainer>
        <FormGroup
          data-qaid="artist-video-edit-form-description-formgroup"
          label={intl.formatMessage({
            id: 'admin.artistVideoEdit.form.description',
          })}
          errorMsg={errorMsgForField(
            'description',
            formikProps.touched,
            formikProps.errors
          )}
        >
          <StyledTextarea
            data-qaid="artist-video-edit-form-description-field"
            id="description"
            name="description"
            value={formikProps.values.description}
            onChange={(e: any) => {
              formikProps.setFieldValue('description', e.target.value);
            }}
          />
        </FormGroup>
      </FormGroupContainer>

      <FormGroupContainer>
        <Checkbox
          data-qaid="artist-video-edit-form-show-on-featured-videos-field"
          id="showOnFeaturedVideos"
          name="showOnFeaturedVideos"
          checked={formikProps.values.showOnFeaturedVideos}
          onChange={(e: any) => {
            formikProps.setFieldValue('showOnFeaturedVideos', e.target.checked);
          }}
        >
          {intl.formatMessage({
            id: 'admin.artistVideoEdit.form.displayInFeatured',
          })}
        </Checkbox>
        <Spacer mb={8} />

        {formikProps.values.videoId && (
          <DeleteButtonWrapper>
            <OutlineButton onClick={toggleVideoDeleteModal}>
              {intl.formatMessage({
                id: 'delete',
              })}
            </OutlineButton>
          </DeleteButtonWrapper>
        )}
      </FormGroupContainer>
    </StyledFormContainer>
  );
};

export default ArtistVideoEditForm;
