import * as Yup from 'yup';

import { getNotesFormSchema } from 'app/admin/utils/string';

const competitionSharingEditFormSchema = (intl: any) =>
  Yup.object().shape({
    optInCopy: Yup.string()
      .nullable()
      .when('turnOnOptIn', {
        is: true,
        then: getNotesFormSchema(intl, 600).required(
          intl.formatMessage({
            id: 'form.requiredField',
          })
        ),
      }),
  });

export default competitionSharingEditFormSchema;
