import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Event } from 'app/typings/events';
import usePermission from 'app/shared/utils/usePermission';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import IconAndTextLink from 'app/shared/components/molecules/IconAndTextLink';
import {
  SectionText,
  SectionTitle,
  SectionWithIcon,
} from 'app/admin/components/atoms/CrewEventContent';
import IconInCircle from 'app/admin/components/atoms/IconInCircle';
import { ReactComponent as Checklist } from 'icons/streamline-regular/interface-essential/form-edition/checklist.svg';
import { ReactComponent as TicketGroup } from 'icons/streamline-regular/wayfinding/tickets/ticket-group.svg';

interface EventInfoProps {
  event: Event;
  toggleGuestlistModal: Function;
}

const Subheading = styled.span`
  font-weight: 600;
`;

const CrewEventGuestInfo: React.FC<EventInfoProps> = ({
  event,
  toggleGuestlistModal,
}) => {
  const intl = useIntl();
  const hasGuestlistAccessPermission = usePermission('event.guestlist.access');

  return (
    <SectionWithIcon>
      <SectionTitle>
        <IconInCircle
          borderCircle={true}
          circleBackgroundColor="whiteDenim"
          dataQaIdSuffix="ticket-group"
          iconColor="royalBlood"
          iconName={TicketGroup}
        />
        {intl.formatMessage({ id: 'admin.crewEvent.section.guestInfo' })}
      </SectionTitle>
      <SectionText>
        <Spacer mt={4} />
        <>
          <Subheading>
            {intl.formatMessage({
              id: 'admin.crewEvent.section.guestInfo.expectedHeadcount',
            })}
            :{' '}
          </Subheading>
          {event.totalHeadcount}
        </>
        <Spacer mt={2} />
        <>
          {event.eventAttendeeGuestsConfirmedCount}{' '}
          {intl
            .formatMessage({ id: 'admin.crewEvent.section.guestInfo.guests' })
            .toLowerCase()}
        </>
        <Spacer mt={2} />
        <>
          {event.vipAttendeeGuestsCount}{' '}
          {intl.formatMessage({ id: 'admin.crewEvent.section.guestInfo.vips' })}
        </>
        <Spacer mt={2} />
        <>
          {event.staffDayOfShowCount}{' '}
          {intl
            .formatMessage({ id: 'admin.crewEvent.section.guestInfo.dosTeam' })
            .toLowerCase()}
        </>
      </SectionText>
      {hasGuestlistAccessPermission && (
        <>
          <Spacer mt={4} />
          <IconAndTextLink
            icon={Checklist}
            text={intl.formatMessage({
              id: 'admin.shared.viewGuestlist',
            })}
            // @ts-ignore
            onClick={!event.cancelled ? toggleGuestlistModal : undefined}
            dataQaid="crew-event-view-guestlist-guest-info"
            buttonStyle="unfilled"
            disabled={event.cancelled}
          />
        </>
      )}
    </SectionWithIcon>
  );
};

export default CrewEventGuestInfo;
