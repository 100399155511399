import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { Artist } from 'app/typings/artists';
import { get } from 'app/shared/utils/get';
import Typeahead from 'app/shared/components/molecules/Typeahead';
import { artistTitleWithEmail } from 'app/admin/utils/artists';
import { GetArtistsForArtistTypeahead } from 'app/admin/graphql/artists/queryHooks';

interface Props {
  eligibilities?: string[];
  notTag?: string;
  orderBy?: string;
  orderDirection?: string;
  setSelectedArtist: (artist: Artist | undefined) => void;
  clearFieldOnSelect?: boolean;
  showArtistEmails?: boolean;
  'data-qaid'?: string;
}

const ArtistTypeahead: React.FC<Props> = ({
  eligibilities,
  notTag,
  orderBy = 'artist_name',
  orderDirection = 'asc',
  setSelectedArtist,
  clearFieldOnSelect = false,
  showArtistEmails = true,
  'data-qaid': qaId,
}) => {
  const intl = useIntl();

  const [typeaheadSearchString, setTypeaheadSearchString] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const {
    loading: loadingArtists,
    error: errorArtists,
    data: dataArtists,
  } = GetArtistsForArtistTypeahead({
    artistSearch: typeaheadSearchString,
    eligibility: eligibilities && eligibilities.join(','),
    notTag,
    orderBy,
    orderDirection,
    page: 1,
    perPage: 50,
  });

  const artists = get(dataArtists, 'artists.artists', []);
  const options = artists.map((artist: Artist) => {
    return {
      id: artist.id,
      title: showArtistEmails ? artistTitleWithEmail(artist) : artist.title,
    };
  });

  const onSearch = (searchString: string) => {
    if (searchString.trim() == '') {
      setSelectedArtist(undefined);
      setTypeaheadSearchString('');
      setIsOpen(false);
    } else if (searchString !== typeaheadSearchString) {
      setTypeaheadSearchString(searchString);
      setIsOpen(true);
    }
  };

  if (errorArtists) {
    return null;
  }

  const getSelectedArtist = (option: any) =>
    artists.find((a: Artist) => a.id == option.id);

  return (
    <>
      <Typeahead
        name="artistTypeahead"
        getOptionLabel={(option: any) => {
          return option.title;
        }}
        realTimeSearch={true}
        options={options}
        value={typeaheadSearchString}
        placeholder={intl.formatMessage({
          id: 'form.fields.placeholder.pleaseTypeArtistNameOrEmail',
        })}
        isLoading={loadingArtists}
        // @ts-ignore
        handleOptionClick={(value: string | number, option: any) => {
          setSelectedArtist(getSelectedArtist(option));
          setTypeaheadSearchString(clearFieldOnSelect ? ' ' : option.title);
          setIsOpen(false);
        }}
        onSearch={onSearch}
        isTypeaheadOpen={isOpen}
        data-qaid={qaId}
      />
    </>
  );
};

export default ArtistTypeahead;
