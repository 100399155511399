import React from 'react';
import { useIntl } from 'react-intl';

import { get } from 'app/shared/utils/get';
import usePermission from 'app/shared/utils/usePermission';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { CreateArtist } from 'app/admin/graphql/artists/mutationHooks';
import { DetailsFormMainContainer } from 'app/admin/components/atoms/DetailContent';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

import ArtistBasicInfoForm from './ArtistBasicInfoForm';
import ArtistBasicInfoFormSchema from './ArtistBasicInfoFormSchema';

interface Props {
  contentProps?: any;
  setFormSubmitAction?: (func: Function) => void;
  setDisplayConfirmation?: (set: boolean) => void;
  setIsSubmitting?: (set: boolean) => void;
  navigateTo: (routeData: object) => void;
}

const contactInfoInitialValues = (position: number) => ({
  id: undefined,
  firstName: undefined,
  lastName: undefined,
  email: undefined,
  contactRelationship: {
    name: undefined,
    id: undefined,
  },
  phoneNumber: undefined,
  position,
  isDayOfShowContact: false,
});

const ArtistCreate: React.FC<Props> = ({
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  navigateTo,
}) => {
  const intl = useIntl();

  const hasCreateAllEventTypesPermission = usePermission(
    'event.eventAllTypes.create'
  );

  const formInitialValues = {
    title: '',
    homeCityDescription: '',
    homeCity: '',
    homeState: '',
    homeCountryCode: '',
    homeCityLatitude: undefined,
    homeCityLongitude: undefined,
    closestSofarCityId: undefined,
    description: '',
    tags: [],
    initialTags: [],
    eligibilities: hasCreateAllEventTypesPermission ? ['discovery'] : [],
    performingRightsOrganisation: {},
    contactInfos: {
      primaryContactInfo: contactInfoInitialValues(1),
      secondaryContactInfo: contactInfoInitialValues(2),
      tertiaryContactInfo: contactInfoInitialValues(3),
    },
    userIdToAssociate: undefined,
  };

  const getContactInfos = (values: any) =>
    Object.values(get(values, 'contactInfos', {}));

  const getContactInfosEmail = (values: any) => {
    const contactInfos = getContactInfos(values);
    if (contactInfos.length > 0) {
      // @ts-ignore
      return contactInfos[0].email;
    } else {
      return '';
    }
  };

  const createArtistAction = CreateArtist();

  const handleCreateArtist = useSubmitAction({
    submitAction: createArtistAction,
    submitVariables: (values: any) => ({
      title: values.title,
      email: getContactInfosEmail(values),
      homeCityDescription: values.homeCityDescription,
      homeCity: values.homeCity,
      homeState: values.homeState,
      homeCountryCode: values.homeCountryCode,
      homeCityLatitude: values.homeCityLatitude,
      homeCityLongitude: values.homeCityLongitude,
      closestSofarCityId: values.closestSofarCityId,
      description: values.description,
      processTags: true,
      tags: values.tags,
      processEligibilities: true,
      eligibilities: values.eligibilities,
      performingRightsOrganisationId: get(
        values,
        'performingRightsOrganisation.value',
        undefined
      ),
      contactInfos: getContactInfos(values),
      signedStatus: get(values, 'signedStatus.value', undefined),
      userIdToAssociate: values.userIdToAssociate,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.artistCreate.successMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.artistCreate.failureMessage',
    }),
    onSuccess: (response: any) => {
      navigateTo({
        routeName: 'artist-details',
        routeProps: {
          id: get(response.data, 'createArtist.artist.id', undefined),
        },
      });
    },
  });

  return (
    <ModalContentContainer>
      <DetailsFormMainContainer>
        <DetailsHeader
          title={intl.formatMessage({
            id: 'admin.artistCreate.title',
          })}
          imageUrl=""
          imageStyle="circle"
          isNewForm={true}
        />
        <GenericForm
          formInitialValues={formInitialValues}
          renderFormComponent={(renderProps: any) => (
            <ArtistBasicInfoForm
              formikProps={renderProps.formikProps}
              isNewArtist={true}
            />
          )}
          onSubmit={handleCreateArtist}
          formSchema={ArtistBasicInfoFormSchema(intl)}
          setFormSubmitAction={setFormSubmitAction}
          setDisplayConfirmation={setDisplayConfirmation}
          setIsSubmitting={setIsSubmitting}
          dataQaId="artist-create-form"
        />
      </DetailsFormMainContainer>
    </ModalContentContainer>
  );
};

export default ArtistCreate;
