import React from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';

import { City } from 'app/typings/cities';
import { PromoCode, PromoCodeRule } from 'app/typings/promoCodes';
import { currencyFloatToInt } from 'app/shared/utils/currencyFormatter';
import { get } from 'app/shared/utils/get';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { CuratorGroupInfoForLimitingByCuratorGroup } from 'app/admin/utils/curatorGroupPermissions';
import { UpdatePromoCode } from 'app/admin/graphql/promoCodes/mutationHooks';
import { DetailsFormMainContainer } from 'app/admin/components/atoms/DetailContent';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

import PromoCodeBasicInfoForm from './PromoCodeBasicInfoForm';
import PromoCodeBasicInfoFormSchema from './PromoCodeBasicInfoFormSchema';
import {
  getCustomPricesSubmitVariable,
  getCustomPricingEntries,
} from './Utils';

interface Props {
  contentProps?: any;
  setFormSubmitAction: (func: Function) => void;
  setDisplayConfirmation: (set: boolean) => void;
  setIsSubmitting: (set: boolean) => void;
  navigateTo: (routeData: object) => void;
}

const getRuleProperty = (
  promoCode: PromoCode,
  ruleType: string,
  propertyName: string
) => {
  const rule = get(promoCode, 'rules', []).find(
    (rule: PromoCodeRule) => rule.ruleType == ruleType
  );
  return rule ? rule[propertyName] : null;
};

const PromoCodeBasicInfoEdit: React.FC<Props> = ({
  contentProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  navigateTo,
}) => {
  const intl = useIntl();

  const {
    curatorGroupIdsToLimitBy,
  } = CuratorGroupInfoForLimitingByCuratorGroup(
    'promoCode.accessByCuratorGroup'
  );

  const formInitialValues = {
    isNewPromoCode: false,
    campaignId: get(contentProps, 'campaign.id', null),
    type: get(contentProps, 'type', null)
      ? get(contentProps, 'type', null).replace(/PromoCode::/, '')
      : null,
    generationReasonId: get(contentProps, 'generationReason.id', null),
    percentageDiscount: get(contentProps, 'percentageDiscount', '')
      ? (get(contentProps, 'percentageDiscount', '') / 100).toString()
      : '',
    customPricingNumTickets: '',
    customPricingPriceForTickets: '',
    customPricingEntries: getCustomPricingEntries(contentProps),
    validFrom: get(contentProps, 'validFrom', null)
      ? moment(get(contentProps, 'validFrom', null))
      : '',
    expiresAt: get(contentProps, 'expiresAt', null)
      ? moment(get(contentProps, 'expiresAt', null))
      : '',
    hasUnlimitedUses: get(contentProps, 'maxUses', '') ? false : true,
    hasUnlimitedUsesPerUser: get(contentProps, 'maxUsesPerUser', '')
      ? false
      : true,
    maxUses: get(contentProps, 'maxUses', ''),
    maxUsesPerUser: get(contentProps, 'maxUsesPerUser', ''),
    ruleTypes: get(contentProps, 'rules', [])
      ? get(contentProps, 'rules', []).map(
          (rule: PromoCodeRule) => rule.ruleType
        )
      : [],
    ruleDayOfWeek:
      getRuleProperty(contentProps, 'for_day_of_week', 'ruleDayOfWeek') || '',
    ruleDaysOfWeek:
      getRuleProperty(contentProps, 'for_days_of_week', 'ruleDaysOfWeek') || [],
    ruleCity: getRuleProperty(contentProps, 'for_city', 'ruleCity') || null,
    ruleCities:
      getRuleProperty(contentProps, 'for_multi_city', 'ruleCities') || [],
    ruleCountry:
      getRuleProperty(contentProps, 'for_country', 'ruleCountry') || null,
    ruleEvent: getRuleProperty(contentProps, 'for_event', 'ruleEvent') || null,
    ruleEventType:
      getRuleProperty(contentProps, 'for_event_type', 'ruleEventType') || '',
    ruleEventOwnerTypes:
      getRuleProperty(
        contentProps,
        'for_event_owner_types',
        'ruleEventOwnerTypes'
      ) || [],
    rulePurchaserType:
      getRuleProperty(
        contentProps,
        'for_purchaser_type',
        'rulePurchaserType'
      ) || null,
  };

  const updatePromoCodeAction = UpdatePromoCode();

  const handleSubmit = useSubmitAction({
    submitAction: updatePromoCodeAction,
    submitVariables: (values: any) => ({
      promoCodeId: get(contentProps, 'id', undefined),
      campaignId: values.campaignId,
      type: values.type,
      generationReasonId: values.generationReasonId,
      percentageDiscount: currencyFloatToInt(values.percentageDiscount),
      customPrices: getCustomPricesSubmitVariable(values.customPricingEntries),
      validFrom: values.validFrom,
      expiresAt: values.expiresAt,
      maxUses: values.maxUses ? Number(values.maxUses) : null,
      maxUsesPerUser: values.maxUsesPerUser
        ? Number(values.maxUsesPerUser)
        : null,
      ruleTypes: values.ruleTypes,
      ruleDayOfWeek: values.ruleDayOfWeek,
      ruleDaysOfWeek: values.ruleDaysOfWeek,
      ruleCityId: values.ruleCity ? values.ruleCity.id : null,
      ruleCityIds: values.ruleCities.map((city: City) => city.id),
      ruleCountryId: values.ruleCountry ? values.ruleCountry.id : null,
      ruleEventId: values.ruleEvent ? values.ruleEvent.id : null,
      ruleEventType: values.ruleEventType,
      ruleEventOwnerTypes: values.ruleEventOwnerTypes,
      rulePurchaserType: values.rulePurchaserType,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.promoCodeBasicInfo.successMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.promoCodeBasicInfo.failureMessage',
    }),
    onSuccess: (response: any) => {
      navigateTo({
        routeName: 'promo-code-details',
        routeProps: {
          defaultOpenSection: 'basicInfo',
          ...response.data.updatePromoCode.promoCode,
        },
      });
    },
  });

  return (
    <ModalContentContainer>
      <DetailsFormMainContainer>
        <DetailsHeader title={get(contentProps, 'code', '')} />
        <GenericForm
          formInitialValues={formInitialValues}
          renderFormComponent={(renderProps: any) => (
            <PromoCodeBasicInfoForm
              formikProps={renderProps.formikProps}
              curatorGroupIdsToLimitBy={curatorGroupIdsToLimitBy}
            />
          )}
          onSubmit={handleSubmit}
          formSchema={PromoCodeBasicInfoFormSchema(intl)}
          setFormSubmitAction={setFormSubmitAction}
          setDisplayConfirmation={setDisplayConfirmation}
          setIsSubmitting={setIsSubmitting}
          dataQaId="promo-code-edit-form"
        />
      </DetailsFormMainContainer>
    </ModalContentContainer>
  );
};

export default PromoCodeBasicInfoEdit;
