import * as Yup from 'yup';

const neighborhoodBasicInfoFormSchema = (intl: any) =>
  Yup.object().shape({
    title: Yup.string().required(
      intl.formatMessage({
        id: 'form.requiredField',
      })
    ),
  });

export default neighborhoodBasicInfoFormSchema;
