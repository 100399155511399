import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { EventStaffMember, User } from 'app/typings';
import { get } from 'app/shared/utils/get';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import {
  ContentWrapper,
  FlyoverBackLink,
  HeaderWrapper,
} from 'app/shared/components/atoms/FlyoverContent';
import { MenuItem } from 'app/shared/components/atoms/Menu';
import { Radio } from 'app/shared/components/atoms/Radio';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Spinner } from 'app/shared/components/atoms/SpinnerManualCSS';
import { Body2 } from 'app/shared/components/atoms/TypographyManualCSS';
import Select from 'app/shared/components/molecules/SelectManualCSS';
import {
  getEventStaffAddByOtherPermission,
  getEventStaffAvailablePermission,
  getEventStaffCuratorPermission,
} from 'app/admin/utils/crew';
import { userFullNameOrEmail } from 'app/admin/utils/users';
import {
  CreateEventStaffMember,
  DeleteEventStaffMember,
  UpdateEventStaffMember,
} from 'app/admin/graphql/events/mutationHooks';
import { GetUsersForAddStaffToEvent } from 'app/admin/graphql/users/queryHooks';
import FlyoverFooter from 'app/admin/components/molecules/FlyoverFooter';

interface Props {
  eventId: number;
  localStartsAt?: string;
  citySlug: string;
  eventOrganizedAs?: string;
  eventStaffMember?: EventStaffMember;
  roleKey: string;
  roleLabel: string;
  callbacks: { [name: string]: Function };
  onSuccess?: Function;
}

const IconWrapper = styled.div`
  display: flex;
  cursor: pointer;
  white-space: nowrap;
`;

const Link = styled.a`
  ${({ theme }) => css`
    color: ${theme.colors.green600};
    font-size: 12px;
    cursor: pointer;
    display: flex;
  `}
`;

const ConfirmMessage = styled.span`
  font-size: 12px;
  padding: 16px;
  text-align: center;
  display: block;
  font-weight: 600;
`;

const RadioLabel = styled.span`
  font-size: 12px;
`;

const NoResultsMessage = styled.span`
  font-size: 12px;
  display: block;
`;

const WarningMessage = styled.span`
  font-style: italic;
  font-size: 12px;
  display: block;
`;

const HeaderText = styled.span`
  font-size: 10px;
  font-weight: normal;
  text-transform: uppercase;
  -webkit-letter-spacing: 1.5px;
  -moz-letter-spacing: 1.5px;
  -ms-letter-spacing: 1.5px;
  letter-spacing: 1.5px;
  line-height: 1.6;
  margin: 0;
  padding: 0;
  margin-bottom: 0px;
  display: inline-block;
`;

const SpinnerWrapper = styled.div`
  padding: 24px;
  display: flex;
  justify-content: center;
`;

const UsersToSelect = styled.div`
  margin-left: 36px;
  max-height: 155px;
  overflow: scroll;
`;

const getPermissionNameForUsersQuery = (
  eventOrganizedAs: string | undefined,
  roleKey: string
) =>
  eventOrganizedAs === 'curator_owner_commercial_event' ||
  eventOrganizedAs === 'curator_owner_noncommercial_event'
    ? getEventStaffCuratorPermission(roleKey)
    : getEventStaffAddByOtherPermission(roleKey);

const displaySelectFromAvailable = (
  roleKey: string,
  eventOrganizedAs: string | undefined
) => {
  if (
    eventOrganizedAs === 'o_and_o' &&
    !!getEventStaffAvailablePermission(roleKey)
  ) {
    return true;
  } else {
    return false;
  }
};

//////////////////////////////////////////////////////////////////////
// HEADER
//////////////////////////////////////////////////////////////////////

interface FlyoverHeaderProps {
  headerTextKey: string;
  eventStaffStateOnCancel?: string;
  setEventStaffState?: Function;
}

const FlyoverHeader: React.FC<FlyoverHeaderProps> = ({
  headerTextKey,
  eventStaffStateOnCancel,
  setEventStaffState,
}) => {
  const intl = useIntl();

  return (
    <HeaderWrapper>
      <HeaderText>
        {intl.formatMessage({
          id: headerTextKey,
        })}
      </HeaderText>
      {eventStaffStateOnCancel && setEventStaffState && (
        <>
          <Spacer mt={2} />
          <FlyoverBackLink
            linkTextType="cancel"
            noPadding={true}
            onClickButton={() => setEventStaffState(eventStaffStateOnCancel)}
          />
          <DottedLine />
        </>
      )}
    </HeaderWrapper>
  );
};

//////////////////////////////////////////////////////////////////////
// SECTIONS
//////////////////////////////////////////////////////////////////////

interface ActivateRoleProps {
  setEventStaffState: Function;
}

const ActivateRole: React.FC<ActivateRoleProps> = ({ setEventStaffState }) => {
  const intl = useIntl();

  return (
    <div data-qaid="activate-role-flyover">
      <FlyoverHeader headerTextKey="admin.eventPlanner.team.activateRole" />
      <Spacer mt={4} />
      <ContentWrapper>
        <IconWrapper>
          <Link
            data-qaid="activate-role-link"
            onClick={() => setEventStaffState('activateRoleConfirmation')}
          >
            <span>
              {intl.formatMessage({
                id: 'admin.eventPlanner.team.activateRoleForThisConcert',
              })}
            </span>
          </Link>
        </IconWrapper>
      </ContentWrapper>
    </div>
  );
};

interface ActivateRoleConfirmationProps {
  roleLabel: string;
  setSubmitting: Function;
  handleActivateRole: Function;
  setEventStaffState: Function;
}

const ActivateRoleConfirmation: React.FC<ActivateRoleConfirmationProps> = ({
  roleLabel,
  setSubmitting,
  handleActivateRole,
  setEventStaffState,
}) => {
  const intl = useIntl();

  return (
    <div data-qaid="activate-role-confirmation-flyover">
      <FlyoverHeader
        headerTextKey="admin.eventPlanner.team.activateRole"
        eventStaffStateOnCancel="activateRole"
        setEventStaffState={setEventStaffState}
      />
      <ContentWrapper>
        <ConfirmMessage>
          {intl.formatMessage(
            {
              id: 'admin.eventPlanner.team.confirmActivateRole',
            },
            {
              roleName: roleLabel,
            }
          )}
        </ConfirmMessage>
      </ContentWrapper>
      <FlyoverFooter
        buttonText={intl.formatMessage({
          id: 'admin.eventPlanner.team.activate',
        })}
        onClickButton={() => {
          setSubmitting(true);
          handleActivateRole();
        }}
        dataQaid="activate-role-confirmation-flyover-submit-button"
      />
    </div>
  );
};

interface DeactivateRoleConfirmationProps {
  setSubmitting: Function;
  handleDeactivateRole: Function;
  setEventStaffState: Function;
}

const DeactivateRoleConfirmation: React.FC<DeactivateRoleConfirmationProps> = ({
  setSubmitting,
  handleDeactivateRole,
  setEventStaffState,
}) => {
  const intl = useIntl();

  return (
    <div data-qaid="deactivate-role-confirmation-flyover">
      <FlyoverHeader
        headerTextKey="admin.eventPlanner.team.deactivateRole"
        eventStaffStateOnCancel="deactivateRole"
        setEventStaffState={setEventStaffState}
      />
      <Spacer mt={4} />
      <ContentWrapper>
        <ConfirmMessage>
          {intl.formatMessage(
            {
              id: 'admin.eventPlanner.team.confirmRemoveTeamMember',
            },
            {
              roleName: '',
            }
          )}
        </ConfirmMessage>
      </ContentWrapper>
      <FlyoverFooter
        buttonText={intl.formatMessage({
          id: 'admin.eventPlanner.team.deactivate',
        })}
        onClickButton={() => {
          setSubmitting(true);
          handleDeactivateRole();
        }}
        dataQaid="deactivate-role-confirmation-flyover-submit-button"
      />
    </div>
  );
};

interface UnfilledRoleSelectActionProps {
  setEventStaffState: Function;
}

const UnfilledRoleSelectAction: React.FC<UnfilledRoleSelectActionProps> = ({
  setEventStaffState,
}) => {
  const intl = useIntl();

  return (
    <div data-qaid="add-or-deactivate-team-member-flyover">
      <FlyoverHeader headerTextKey="admin.eventPlanner.team.addTeamMemberOrDeactivateRole" />
      <Spacer mt={4} />
      <ContentWrapper>
        <Link
          data-qaid="add-staff-member-link"
          onClick={() => setEventStaffState('addUserToRole')}
        >
          <span>
            {intl.formatMessage({
              id: 'admin.eventPlanner.team.addStaffMember',
            })}
          </span>
        </Link>
        <Spacer mt={4} />
        <Link
          data-qaid="deactivate-staff-member-link"
          onClick={() => setEventStaffState('deactivateRoleConfirmation')}
        >
          <span>
            {intl.formatMessage({
              id: 'admin.eventPlanner.team.deactivateRoleForThisConcert',
            })}
          </span>
        </Link>
      </ContentWrapper>
    </div>
  );
};

interface FilledRoleSelectActionProps {
  setEventStaffState: Function;
}

const FilledRoleSelectAction: React.FC<FilledRoleSelectActionProps> = ({
  setEventStaffState,
}) => {
  const intl = useIntl();

  return (
    <div data-qaid="modify-role-flyover">
      <FlyoverHeader headerTextKey="admin.eventPlanner.team.modifyRole" />
      <Spacer mt={4} />
      <ContentWrapper>
        <Link
          data-qaid="remove-user-from-role-option-link"
          onClick={() => setEventStaffState('removeUserFromRoleConfirmation')}
        >
          <span>
            {intl.formatMessage({
              id: 'admin.eventPlanner.team.removeTeamMemberFromRole',
            })}
          </span>
        </Link>
      </ContentWrapper>
    </div>
  );
};

interface AddUserToRoleProps {
  roleKey: string;
  eventOrganizedAs: string | undefined;
  citySlug: string;
  localStartsAt: string | undefined;
  searchMethod: string | null;
  selectedUser: User | undefined;
  setSearchMethod: Function;
  setSelectedUser: Function;
  setSubmitting: Function;
  handleAddUserToRole: Function;
}

const AddUserToRole: React.FC<AddUserToRoleProps> = ({
  roleKey,
  eventOrganizedAs,
  citySlug,
  localStartsAt,
  searchMethod,
  selectedUser,
  setSearchMethod,
  setSelectedUser,
  setSubmitting,
  handleAddUserToRole,
}) => {
  const intl = useIntl();

  return (
    <div data-qaid="add-staff-member-flyover">
      <FlyoverHeader headerTextKey="admin.eventPlanner.team.addTeamMember" />
      <Spacer mt={4} />
      <ContentWrapper className="suppress-modal-navigation-outside-click">
        <SelectUserFromNameOption
          roleKey={roleKey}
          eventOrganizedAs={eventOrganizedAs}
          citySlug={citySlug}
          autoSelect={!displaySelectFromAvailable(roleKey, eventOrganizedAs)}
          searchMethod={searchMethod}
          selectedUser={selectedUser}
          setSearchMethod={setSearchMethod}
          setSelectedUser={setSelectedUser}
        />
        {displaySelectFromAvailable(roleKey, eventOrganizedAs) && (
          <SelectUserFromAvailableOption
            roleKey={roleKey}
            citySlug={citySlug}
            localStartsAt={localStartsAt}
            searchMethod={searchMethod}
            selectedUser={selectedUser}
            setSearchMethod={setSearchMethod}
            setSelectedUser={setSelectedUser}
          />
        )}
      </ContentWrapper>
      <FlyoverFooter
        buttonText={intl.formatMessage({
          id: 'admin.eventPlanner.team.update',
        })}
        buttonDisabled={!selectedUser}
        onClickButton={() => {
          setSubmitting(true);
          handleAddUserToRole();
        }}
        dataQaid="add-staff-member-submit-button"
      />
    </div>
  );
};

interface SelectUserFromNameOptionProps {
  roleKey: string;
  eventOrganizedAs: string | undefined;
  citySlug: string;
  autoSelect?: boolean;
  searchMethod: string | null;
  selectedUser: User | undefined;
  setSearchMethod: Function;
  setSelectedUser: Function;
}

const SelectUserFromNameOption: React.FC<SelectUserFromNameOptionProps> = ({
  roleKey,
  eventOrganizedAs,
  citySlug,
  autoSelect = false,
  searchMethod,
  selectedUser,
  setSearchMethod,
  setSelectedUser,
}) => {
  const intl = useIntl();

  if (autoSelect) {
    setSearchMethod('name');
  }

  return (
    <>
      {autoSelect ? (
        <RadioLabel>
          {intl.formatMessage({
            id: 'admin.eventPlanner.team.searchByName',
          })}
        </RadioLabel>
      ) : (
        <Radio
          id="searchByName"
          name="searchByName"
          data-qaid="add-staff-member-search-by-name-radio-button"
          checked={searchMethod === 'name'}
          onChange={() => setSearchMethod('name')}
        >
          <RadioLabel>
            {intl.formatMessage({
              id: 'admin.eventPlanner.team.searchByName',
            })}
          </RadioLabel>
        </Radio>
      )}
      <Spacer mt={4} />
      {searchMethod === 'name' && (
        <SelectUserFromName
          roleKey={roleKey}
          eventOrganizedAs={eventOrganizedAs}
          citySlug={citySlug}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
        />
      )}
    </>
  );
};

interface SelectUserFromAvailableOptionProps {
  roleKey: string;
  citySlug: string;
  localStartsAt: string | undefined;
  searchMethod: string | null;
  selectedUser: User | undefined;
  setSearchMethod: Function;
  setSelectedUser: Function;
}

const SelectUserFromAvailableOption: React.FC<SelectUserFromAvailableOptionProps> = ({
  roleKey,
  citySlug,
  localStartsAt,
  searchMethod,
  selectedUser,
  setSearchMethod,
  setSelectedUser,
}) => {
  const intl = useIntl();

  return (
    <>
      <Radio
        id="selectFromAvailable"
        name="selectFromAvailable"
        data-qaid="add-staff-member-search-by-available-radio-button"
        checked={searchMethod === 'available'}
        onChange={() => setSearchMethod('available')}
      >
        <RadioLabel>
          {intl.formatMessage({
            id: 'admin.eventPlanner.team.selectFromAvailable',
          })}
        </RadioLabel>
      </Radio>
      <Spacer mt={4} />
      {searchMethod === 'available' && (
        <SelectUserFromAvailable
          roleKey={roleKey}
          citySlug={citySlug}
          localStartsAt={localStartsAt}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
        />
      )}
    </>
  );
};

interface SelectUserFromNameProps {
  roleKey: string;
  eventOrganizedAs: string | undefined;
  citySlug: string;
  selectedUser: User | undefined;
  setSelectedUser: Function;
}

const SelectUserFromName: React.FC<SelectUserFromNameProps> = ({
  roleKey,
  eventOrganizedAs,
  citySlug,
  selectedUser,
  setSelectedUser,
}) => {
  const intl = useIntl();

  const permissionNameForUsersQuery = getPermissionNameForUsersQuery(
    eventOrganizedAs,
    roleKey
  );

  const {
    loading: usersLoading,
    error: usersError,
    data: usersData,
  } = GetUsersForAddStaffToEvent({
    permission: permissionNameForUsersQuery,
    permissionCity: citySlug,
    skipPagination: true,
  });

  if (usersError) {
    return (
      <>
        <WarningMessage>
          {intl.formatMessage({
            id: 'admin.eventPlanner.team.usersOptionsError',
          })}
        </WarningMessage>
        <Spacer mt={4} />
      </>
    );
  }

  if (usersLoading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  return (
    <>
      <Select<User>
        searchable
        placeholder={intl.formatMessage({
          id: 'admin.eventPlanner.team.searchUsers',
        })}
        data-qaid="add-staff-member-typeahead"
        value={selectedUser}
        options={get(usersData, 'users.users', [])}
        onChange={(user: User) => {
          setSelectedUser(user);
        }}
        getOptionLabel={user => userFullNameOrEmail(user) || ''}
        renderOption={(user, props) => (
          <MenuItem
            key={user.id}
            {...props}
            className="suppress-outside-click"
            data-qaid="add-staff-member-typeahead-option"
          >
            <Body2 as="span" className="suppress-outside-click">
              {userFullNameOrEmail(user)}
            </Body2>
          </MenuItem>
        )}
      />
      <Spacer mt={4} />
    </>
  );
};

const dateStrFromDateTimeStr = (dateTimeStr?: string) =>
  // Given e.g. '2022-11-04T20:00:00Z'
  // Return e.g. '2022-11-04'
  dateTimeStr?.split('T')[0];

interface SelectUserFromAvailableProps {
  roleKey: string;
  citySlug: string;
  localStartsAt: string | undefined;
  selectedUser: User | undefined;
  setSelectedUser: Function;
}

const SelectUserFromAvailable: React.FC<SelectUserFromAvailableProps> = ({
  roleKey,
  citySlug,
  localStartsAt,
  selectedUser,
  setSelectedUser,
}) => {
  const intl = useIntl();

  const permissionNameForAvailableUsersQuery = getEventStaffAvailablePermission(
    roleKey
  );

  const {
    loading: availableUsersLoading,
    error: availableUsersError,
    data: availableUsersData,
  } = GetUsersForAddStaffToEvent({
    permission: permissionNameForAvailableUsersQuery,
    permissionCity: citySlug,
    availableOnDate: dateStrFromDateTimeStr(localStartsAt),
    skipPagination: true,
  });

  if (availableUsersError) {
    return (
      <div data-qaid="available-staff-members">
        <WarningMessage>
          {intl.formatMessage({
            id: 'admin.eventPlanner.team.usersOptionsError',
          })}
        </WarningMessage>
        <Spacer mt={4} />
      </div>
    );
  }

  if (availableUsersLoading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  const updateUser = (userId: number) => {
    const foundUser = get(availableUsersData, 'users.users', []).find(
      (user: User) => {
        return user.id == userId;
      }
    );
    setSelectedUser(foundUser);
  };

  if (get(availableUsersData, 'users.users', []).length == 0) {
    return (
      <NoResultsMessage>
        {intl.formatMessage({
          id: 'admin.eventPlanner.team.noUsersAvailable',
        })}
      </NoResultsMessage>
    );
  }

  const users = get(availableUsersData, 'users.users', []).map((user: any) => {
    return (
      <div key={user.id}>
        <Radio
          data-qaid={`available-member-${user.id}`}
          id={user.id}
          checked={(selectedUser && selectedUser.id) === user.id}
          onChange={(event: any) => {
            updateUser(event.target.id);
          }}
          name={user.id}
        >
          <RadioLabel>{userFullNameOrEmail(user)}</RadioLabel>
        </Radio>
        <Spacer mt={4} />
      </div>
    );
  });

  return (
    <UsersToSelect>
      <div data-qaid="available-staff-members">{users}</div>
    </UsersToSelect>
  );
};

interface RemoveUserFromRoleConfirmationProps {
  roleLabel: string;
  selectedUser: User | undefined;
  setSubmitting: Function;
  handleRemoveUserFromRole: Function;
  setEventStaffState: Function;
}

const RemoveUserFromRoleConfirmation: React.FC<RemoveUserFromRoleConfirmationProps> = ({
  roleLabel,
  selectedUser,
  setSubmitting,
  handleRemoveUserFromRole,
  setEventStaffState,
}) => {
  const intl = useIntl();

  return (
    <div data-qaid="remove-user-from-role-confirmation-flyover">
      <FlyoverHeader
        headerTextKey="admin.eventPlanner.team.removeTeamMemberFromRole"
        eventStaffStateOnCancel="filledRoleSelectAction"
        setEventStaffState={setEventStaffState}
      />
      <ContentWrapper>
        <ConfirmMessage>
          {intl.formatMessage(
            {
              id: 'admin.eventPlanner.team.confirmRemoveUserFromRole',
            },
            {
              user: userFullNameOrEmail(selectedUser),
              roleName: roleLabel,
            }
          )}
        </ConfirmMessage>
      </ContentWrapper>
      <FlyoverFooter
        buttonText={intl.formatMessage({
          id: 'admin.eventPlanner.team.update',
        })}
        buttonDisabled={!selectedUser}
        onClickButton={() => {
          setSubmitting(true);
          handleRemoveUserFromRole();
        }}
        dataQaid="remove-user-from-role-confirmation-flyover-submit-button"
      />
    </div>
  );
};

//////////////////////////////////////////////////////////////////////
// MAIN COMPONENT
//////////////////////////////////////////////////////////////////////

const EventPlannerTeamMemberFlyover: React.FC<Props> = ({
  eventId,
  localStartsAt,
  citySlug,
  eventOrganizedAs,
  eventStaffMember,
  roleKey,
  roleLabel,
  callbacks,
  onSuccess = () => {},
}) => {
  const intl = useIntl();

  const isRoleActive = !!eventStaffMember;
  const isRoleFilled =
    eventStaffMember && eventStaffMember.user && eventStaffMember.user.id;

  const defaultEventStaffState = () => {
    if (isRoleActive && !isRoleFilled) {
      return 'unfilledRoleSelectAction';
    } else if (isRoleActive && isRoleFilled) {
      return 'filledRoleSelectAction';
    } else {
      return 'activateRole';
    }
  };

  const [eventStaffState, setEventStaffState] = useState(
    defaultEventStaffState()
  );
  const [searchMethod, setSearchMethod] = useState<string | null>(null);
  const [selectedUser, setSelectedUser] = useState(
    eventStaffMember && eventStaffMember.user
  );

  const [submitting, setSubmitting] = useState(false);

  const createEventStaffMemberAction = CreateEventStaffMember();
  const updateEventStaffMemberAction = UpdateEventStaffMember();
  const deleteEventStaffMemberAction = DeleteEventStaffMember();

  const handleActivateRole = useSubmitAction({
    submitAction: createEventStaffMemberAction,
    submitVariables: () => ({
      eventId,
      roleKey,
    }),
    successMsg: intl.formatMessage(
      {
        id: 'admin.eventPlanner.team.activateRoleSuccess',
      },
      {
        roleName: roleLabel,
      }
    ),
    failureMsg: intl.formatMessage(
      {
        id: 'admin.eventPlanner.team.activateRoleFailure',
      },
      {
        user: userFullNameOrEmail(selectedUser),
      }
    ),
    onSuccess: (response: any) => {
      const updatedStaff = get(
        response,
        'data.createEventStaffMember.event.staff',
        []
      );
      setSubmitting(false);
      onSuccess && onSuccess(updatedStaff);
      callbacks.refetchEvent();
    },
  });

  const handleDeactivateRole = useSubmitAction({
    submitAction: deleteEventStaffMemberAction,
    submitVariables: () => ({
      eventStaffMemberId: eventStaffMember && eventStaffMember.id,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.eventPlanner.team.removeEventStaffMemberSuccess',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.eventPlanner.team.removeEventStaffMemberFailure',
    }),
    onSuccess: (response: any) => {
      const updatedStaff = get(
        response,
        'data.deleteEventStaffMember.event.staff',
        []
      );
      setSubmitting(false);
      onSuccess && onSuccess(updatedStaff);
      callbacks.refetchEvent();
    },
  });

  const handleAddUserToRole = useSubmitAction({
    submitAction: updateEventStaffMemberAction,
    submitVariables: () => ({
      eventId,
      roleKey,
      userId: selectedUser && selectedUser.id,
      skipMaxSignUpsRestriction: true,
      skipAuthorizationRestriction: true,
    }),
    successMsg: intl.formatMessage(
      {
        id: 'admin.eventPlanner.team.addStaffMemberSuccess',
      },
      {
        user: userFullNameOrEmail(selectedUser),
      }
    ),
    failureMsg: intl.formatMessage(
      {
        id: 'admin.eventPlanner.team.addStaffMemberFailure',
      },
      {
        user: userFullNameOrEmail(selectedUser),
      }
    ),
    onSuccess: (response: any) => {
      const updatedStaff = get(
        response,
        'data.updateEventStaffMember.event.staff',
        []
      );
      setSubmitting(false);
      onSuccess && onSuccess(updatedStaff);
    },
  });

  const handleRemoveUserFromRole = useSubmitAction({
    submitAction: updateEventStaffMemberAction,
    submitVariables: () => ({
      eventId,
      roleKey,
      userId: null,
      skipAuthorizationRestriction: true,
    }),
    successMsg: intl.formatMessage(
      {
        id: 'admin.eventPlanner.team.removeStaffMemberSuccess',
      },
      {
        user: userFullNameOrEmail(selectedUser),
      }
    ),
    failureMsg: intl.formatMessage(
      {
        id: 'admin.eventPlanner.team.removeStaffMemberFailure',
      },
      {
        user: userFullNameOrEmail(selectedUser),
      }
    ),
    onSuccess: (response: any) => {
      const updatedStaff = get(
        response,
        'data.updateEventStaffMember.event.staff',
        []
      );
      setSubmitting(false);
      onSuccess && onSuccess(updatedStaff);
    },
  });

  if (submitting) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }
  switch (eventStaffState) {
    case 'activateRole':
      return <ActivateRole setEventStaffState={setEventStaffState} />;
    case 'activateRoleConfirmation':
      return (
        <ActivateRoleConfirmation
          roleLabel={roleLabel}
          setSubmitting={setSubmitting}
          handleActivateRole={handleActivateRole}
          setEventStaffState={setEventStaffState}
        />
      );
    case 'deactivateRoleConfirmation':
      return (
        <DeactivateRoleConfirmation
          setSubmitting={setSubmitting}
          handleDeactivateRole={handleDeactivateRole}
          setEventStaffState={setEventStaffState}
        />
      );
    case 'unfilledRoleSelectAction':
      return (
        <UnfilledRoleSelectAction setEventStaffState={setEventStaffState} />
      );
    case 'filledRoleSelectAction':
      return <FilledRoleSelectAction setEventStaffState={setEventStaffState} />;
    case 'addUserToRole':
      return (
        <AddUserToRole
          roleKey={roleKey}
          eventOrganizedAs={eventOrganizedAs}
          citySlug={citySlug}
          localStartsAt={localStartsAt}
          searchMethod={searchMethod}
          selectedUser={selectedUser}
          setSearchMethod={setSearchMethod}
          setSelectedUser={setSelectedUser}
          setSubmitting={setSubmitting}
          handleAddUserToRole={handleAddUserToRole}
        />
      );
    case 'removeUserFromRoleConfirmation':
      return (
        <RemoveUserFromRoleConfirmation
          roleLabel={roleLabel}
          selectedUser={selectedUser}
          setSubmitting={setSubmitting}
          handleRemoveUserFromRole={handleRemoveUserFromRole}
          setEventStaffState={setEventStaffState}
        />
      );
    default:
      return null;
  }
};

export default EventPlannerTeamMemberFlyover;
