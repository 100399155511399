import React from 'react';
import { useIntl } from 'react-intl';

import { Continent } from 'app/typings/continents';
import ListingCard from 'app/admin/components/organisms/ListingCard';
import { ReactComponent as MapsPin2 } from 'icons/streamline-regular/maps-navigation/maps/maps-pin-2.svg';

interface CountryCardProps {
  id: number;
  index: number;
  title: string;
  continent: Continent;
  countryCode: string;
  citiesCount: number;
  cachedSlug: string;
  onShowDetails: Function;
}

const CountryCard: React.FC<CountryCardProps> = ({
  id,
  index,
  title,
  continent,
  countryCode,
  citiesCount,
  cachedSlug,
  onShowDetails,
}) => {
  const intl = useIntl();

  const keysAndValuesList = [
    {
      key: intl.formatMessage({
        id: 'admin.countries.listingCard.countryCode',
      }),
      value: countryCode,
    },
    {
      key: intl.formatMessage({
        id: 'shared.cities',
      }),
      value: citiesCount.toString(),
    },
  ];

  const controlsInfoList = [
    {
      href: `https://maps.google.com/maps?q=${cachedSlug}`,
      icon: MapsPin2,
      tipText: intl.formatMessage({
        id: 'admin.shared.linkToMap',
      }),
    },
  ];

  const onShowDetailsParams = {
    id,
    title,
  };

  return (
    <>
      <ListingCard
        title={title}
        subtitle={continent.title}
        keysAndValuesList={keysAndValuesList}
        dataQaidPrefix="country"
        index={index}
        height="180px"
        controlsInfoList={controlsInfoList}
        onShowDetails={onShowDetails}
        onShowDetailsParams={onShowDetailsParams}
        showDetailsTipText={intl.formatMessage({
          id: 'admin.countries.countryDetails',
        })}
      />
    </>
  );
};

export default CountryCard;
