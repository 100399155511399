import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { dataGetter } from 'app/shared/utils/queries';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { GetEventCollectionForAdmin } from 'app/admin/graphql/eventCollections/queryHooks';
import { DetailsMainContainer } from 'app/admin/components/atoms/DetailContent';
import AccordionSections from 'app/admin/components/organisms/AccordionSections';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';
import SectionBasicInfo from 'app/admin/components/organisms/EventCollectionDetails/SectionBasicInfo';
import SectionConcerts from 'app/admin/components/organisms/EventCollectionDetails/SectionConcerts';
import SectionUrls from 'app/admin/components/organisms/EventCollectionDetails/SectionUrls';
import { ReactComponent as Link } from 'icons/streamline-bold/hyperlink-3.svg';
import { ReactComponent as InformationCircle } from 'icons/streamline-regular/interface-essential/alerts/information-circle.svg';
import { ReactComponent as MusicGenreTeam } from 'icons/streamline-regular/music-audio/music-genres/music-genre-team.svg';

interface Props {
  navigateTo: (routeData: any) => void;
  contentProps: any;
  hide: (hideProps?: any) => VoidFunction;
}

const EventCollectionDetails: React.FC<Props> = ({
  navigateTo,
  contentProps,
  hide,
}) => {
  const { id, title } = contentProps;

  const intl = useIntl();

  const [headerData, setHeaderData] = useState({
    title,
    isLoaded: !!title,
  });

  const {
    loading: loadingBasicInfo,
    error: errorBasicInfo,
    data: dataBasicInfo,
  } = GetEventCollectionForAdmin({
    id,
    fetchPolicy: 'no-cache',
  });

  const sectionsConfig = {
    basicInfo: {
      title: intl.formatMessage({
        id: 'admin.collection.details.sectionTitle.overview',
      }),
      icon: InformationCircle,
      iconColor: 'blueChristmas',
      sectionComponent: SectionBasicInfo,
      dataKey: 'eventCollection',
      displayEditIcon: true,
      loading: loadingBasicInfo,
      data: dataBasicInfo ? dataBasicInfo.eventCollection : undefined,
      callbacks: {
        toggleSectionEditModal: () =>
          navigateTo({
            routeName: 'event-collection-basic-info-edit',
            routeProps: dataBasicInfo
              ? dataBasicInfo.eventCollection
              : undefined,
          }),
        toggleImageEditModal: () => {
          navigateTo({
            routeName: 'event-collection-header-image-uploader',
            routeProps: {
              id: dataBasicInfo?.eventCollection.id,
              eventCollectionTitle: dataBasicInfo?.eventCollection.title,
            },
          });
        },
      },
    },
    concerts: {
      title: intl.formatMessage({
        id: 'admin.collection.details.sectionTitle.concerts',
      }),
      icon: MusicGenreTeam,
      iconColor: 'orangeCrush',
      iconCircle: true,
      sectionComponent: SectionConcerts,
      dataKey: 'eventCollection',
      displayEditIcon: true,
      loading: loadingBasicInfo,
      data: dataBasicInfo?.eventCollection,
      callbacks: {
        toggleSectionEditModal: () =>
          navigateTo({
            routeName: 'event-collection-basic-info-edit',
            routeProps: dataBasicInfo
              ? dataBasicInfo.eventCollection
              : undefined,
          }),
      },
    },
    urls: {
      title: intl.formatMessage({
        id: 'admin.collection.details.sectionTitle.collectionUrls',
      }),
      icon: Link,
      iconColor: 'greenOnions',
      iconCircle: true,
      sectionComponent: SectionUrls,
      dataKey: 'eventCollection',
      displayEditIcon: false,
      loading: loadingBasicInfo,
      data: dataBasicInfo ? dataBasicInfo.eventCollection : undefined,
    },
  };

  useEffect(() => {
    const getDataBasicInfo = dataGetter(dataBasicInfo, 'eventCollection');
    if (dataBasicInfo) {
      setHeaderData({
        title: getDataBasicInfo('title'),
        isLoaded: true,
      });
    }
  }, [dataBasicInfo, loadingBasicInfo]);

  if ((!loadingBasicInfo && !dataBasicInfo) || errorBasicInfo) {
    hide();
    return null;
  }

  return (
    <ModalContentContainer data-qaid="collection-details-main-modal">
      <DetailsMainContainer>
        <DetailsHeader
          title={headerData.title}
          loading={!headerData.isLoaded}
        />
        <AccordionSections
          sectionsConfig={sectionsConfig}
          defaultOpenSection={contentProps.defaultOpenSection}
        />
      </DetailsMainContainer>
    </ModalContentContainer>
  );
};

export default EventCollectionDetails;
