import React from 'react';
import { IntlShape, useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { EventCollection, EventPreview } from 'app/typings';
import {
  currencyFormatter,
  currencyMainUnitsFormatter,
} from 'app/shared/utils/currencyFormatter';
import { dateFormatter } from 'app/shared/utils/datetime';
import { get } from 'app/shared/utils/get';
import { useEventHeaderImageSourceMap } from 'app/shared/utils/useEventHeaderImageSourceMap';
import { useFallbackCollection } from 'app/shared/utils/useFallbackCollection';
import { useMerchandisingHeadlineAndDescription } from 'app/shared/utils/useMerchandisingHeadlineAndDescription';
import { useMerchandisingProperties } from 'app/shared/utils/useMerchandisingProperties';
import usePermission from 'app/shared/utils/usePermission';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import { BaseLink } from 'app/shared/components/atoms/LinkManualCSS';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { sofarOperatedMarketTypeLabels } from 'app/admin/utils/cities';
import {
  eventTicketPriceForDisplay,
  eventTypeOptions,
  formatTypeLabels,
} from 'app/admin/utils/events';
import { eventTypeDisplayConfig } from 'app/admin/utils/eventTypeDisplayConfig';
import {
  DetailHeading as Heading,
  DetailSmallHeading as SmallHeading,
  DetailSmallValue as SmallValue,
  DetailSubheading as Subheading,
  DetailValueContainer as ValueContainer,
} from 'app/admin/components/atoms/DetailContent';
import ExpandableList from 'app/admin/components/atoms/ExpandableList';
import ExpandableText from 'app/admin/components/atoms/ExpandableText';
import { None } from 'app/admin/components/atoms/None';
import { EventCollectionLink } from 'app/admin/components/molecules/EntityLink';
import EventTimes from 'app/admin/components/molecules/EventTimes';
import MerchandisingPropertyGroups from 'app/admin/components/molecules/MerchandisingPropertyGroups';

interface Props {
  sectionData: {
    dataBasicInfo: {
      event: EventPreview;
    };
  };
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const ValueDetailContainer = styled.div`
  margin-bottom: 1px;
`;

const Link = styled(BaseLink)`
  ${({ theme }) => css`
    color: ${theme.colors.green600};
  `}
`;

const findEventType = (eventType: string | undefined, intl: IntlShape) => {
  const typeToDisplay = eventTypeOptions(intl).find(option => {
    return option.value === eventType;
  });

  return typeToDisplay ? typeToDisplay.title : eventType;
};

const formatTime = (timeValue: string, timezone: string | undefined) => {
  if (timezone) {
    return dateFormatter(
      timeValue,
      'hoursAndMinutesWithSpacedAMPM',
      timezone
    ).toLowerCase();
  }
  return null;
};

const neighborhood = (event: any) => {
  if (event.venue?.neighborhood) {
    return event.venue.neighborhood.title;
  } else if (event.venue) {
    return <None />;
  } else if (event.neighborhood) {
    return event.neighborhood.title;
  } else {
    return <None />;
  }
};

const HelperText = styled.div`
  font-size: 10px;
  font-weight: 500;
  margin-top: 2px;
`;

const SectionBasicInfo: React.FC<Props> = ({ sectionData }) => {
  const intl = useIntl();
  const hasAccessCampaignPermission = usePermission('campaign.access');

  const { dataBasicInfo } = sectionData;
  const { event } = dataBasicInfo;
  const displayConfig = eventTypeDisplayConfig[event.type];
  const eventTypeToDisplay = findEventType(event.type, intl);

  const eventHeaderImageInfo = useEventHeaderImageSourceMap({ event });
  const eventHeadlineAndDescriptionInfo = useMerchandisingHeadlineAndDescription(
    {
      event,
    }
  );
  const eventMerchandisingInfo = useMerchandisingProperties({ event });
  const fallbackEventCollection = useFallbackCollection({
    eventCollections: event.eventCollections,
  });

  const eventCollectionLinkComponents = event.eventCollections
    ?.sort((a: EventCollection, b: EventCollection) =>
      (a.title || '') > (b.title || '') ? 1 : -1
    )
    ?.map((eventCollection: EventCollection, index: number) => (
      <EventCollectionLink
        eventCollection={eventCollection}
        truncateLength={40}
        textAfter={eventCollection.status !== 'active' ? 'Inactive' : undefined}
        key={index}
      />
    ));

  const ticketPriceForDisplay = eventTicketPriceForDisplay(event);

  return (
    <MainContainer data-qaid="section-event-overview">
      <Grid cols={4}>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={1}>
          <Heading>
            {intl.formatMessage({
              id: 'shared.city',
            })}
          </Heading>
          <ValueContainer data-qaid="event-city">
            {event.city && event.city.id ? (
              <>
                {event.city.title}, {event.city.country.title}
              </>
            ) : (
              <None />
            )}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'shared.neighborhood',
            })}
          </Heading>
          <ValueContainer data-qaid="event-neighborhood">
            {neighborhood(event)}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'shared.date',
            })}
          </Heading>
          <ValueContainer data-qaid="event-date">
            {event.localStartsAt ? (
              <>
                <ValueDetailContainer>
                  {dateFormatter(event.localStartsAt, 'longWeekday')}
                </ValueDetailContainer>
                <ValueDetailContainer>
                  {dateFormatter(event.localStartsAt, 'longMonthDateAndYear')}
                </ValueDetailContainer>
              </>
            ) : (
              <None />
            )}
          </ValueContainer>
          {displayConfig.displayOnSaleAt && (
            <>
              <Heading>
                {intl.formatMessage({
                  id: 'admin.shared.eventPlanner.ticketsOnSale',
                })}
              </Heading>
              <ValueContainer data-qaid="event-onsale-date">
                {event.onSaleAt ? (
                  <>
                    <ValueDetailContainer>
                      {dateFormatter(
                        event.onSaleAt,
                        'longWeekday',
                        event.city.timezone
                      )}
                    </ValueDetailContainer>
                    <ValueDetailContainer>
                      {dateFormatter(
                        event.onSaleAt,
                        'longMonthDateAndYear',
                        event.city.timezone
                      )}
                    </ValueDetailContainer>
                    <ValueDetailContainer>
                      {formatTime(event.onSaleAt, event.city.timezone)}
                    </ValueDetailContainer>
                    {displayConfig.displayArtistFacingPageUrl && (
                      <ValueDetailContainer>
                        <Link
                          href={`/artist/featured-set/${event.id}`}
                          openInNewTab={true}
                        >
                          {intl.formatMessage({
                            id: 'admin.eventPlanner.overview.artistsInfoPage',
                          })}
                        </Link>
                      </ValueDetailContainer>
                    )}
                  </>
                ) : (
                  <None />
                )}
              </ValueContainer>
            </>
          )}
          {displayConfig.displayOnPresale &&
            event.eventOrganizedAs === 'o_and_o' && (
              <>
                <Heading>
                  {intl.formatMessage({
                    id: 'admin.shared.eventPlanner.ticketsOnPresale',
                  })}
                </Heading>
                <ValueContainer>
                  {event.onPresale ? (
                    <>
                      {intl.formatMessage({
                        id: 'yes',
                      })}
                    </>
                  ) : (
                    <>
                      {intl.formatMessage({
                        id: 'no',
                      })}
                    </>
                  )}
                </ValueContainer>
              </>
            )}
          <Heading>
            {intl.formatMessage({
              id: 'admin.eventPlanner.overview.eventId',
            })}
          </Heading>
          <ValueContainer>{event.id}</ValueContainer>
        </Col>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={2}>
          <Heading>
            {intl.formatMessage({
              id: 'shared.capacity',
            })}
          </Heading>
          <ValueContainer data-qaid="event-capacity">
            {event.venue
              ? event.venue.capacity
              : intl.formatMessage({
                  id: 'admin.notApplicable',
                })}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.shared.ticketsAvailable',
            })}
          </Heading>
          <ValueContainer data-qaid="event-tickets-available">
            {event.numTicketsAvailableForSale}
          </ValueContainer>
          {event.attendeeFlow === 'buy' && (
            <>
              <Heading>
                {intl.formatMessage({
                  id: 'admin.shared.ticketPrice',
                })}
              </Heading>
              <ValueContainer data-qaid="ticket-price-view">
                {ticketPriceForDisplay}
              </ValueContainer>
            </>
          )}
          <Heading>
            {intl.formatMessage({
              id: 'admin.shared.recommendedTeam',
            })}
          </Heading>
          <ValueContainer data-qaid="event-recommended-crew">
            {event.venue
              ? event.venue.recommendedCrewCount || '0'
              : intl.formatMessage({
                  id: 'admin.notApplicable',
                })}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.eventPlanner.overview.marketType',
            })}
          </Heading>
          <ValueContainer>
            {event.eventOrganizedAs === 'curator_owner_commercial_event' ||
            event.eventOrganizedAs === 'curator_owner_noncommercial_event' ? (
              intl.formatMessage({ id: 'admin.notApplicable' })
            ) : event.sofarOperatedMarketType ? (
              sofarOperatedMarketTypeLabels[event.sofarOperatedMarketType]
            ) : (
              <None />
            )}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'shared.businessOwner',
            })}
          </Heading>
          <ValueContainer>
            {event.eventOrganizedAs === 'curator_owner_commercial_event' ||
            event.eventOrganizedAs === 'curator_owner_noncommercial_event' ? (
              intl.formatMessage({ id: 'admin.notApplicable' })
            ) : event.businessOwner ? (
              event.businessOwner.title
            ) : (
              <None />
            )}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.eventPlanner.overview.concertType',
            })}
          </Heading>
          <ValueContainer data-qaid="event-type">
            {event.type && eventTypeToDisplay ? eventTypeToDisplay : <None />}
          </ValueContainer>
          {event.eventOrganizedAs === 'o_and_o' && (
            <>
              <Heading>
                {intl.formatMessage({
                  id: 'shared.experimentalConcert',
                })}
              </Heading>
              <ValueContainer>
                {event.eventFormatExperiment
                  ? event.eventFormatExperiment.title
                  : intl.formatMessage({
                      id: 'no',
                    })}
              </ValueContainer>
            </>
          )}
          <Heading>
            {intl.formatMessage({
              id: 'admin.eventPlanner.overview.formatType',
            })}
          </Heading>
          <ValueContainer>
            {event.formatType ? formatTypeLabels[event.formatType] : <None />}
          </ValueContainer>

          <Heading>
            {intl.formatMessage({
              id: 'admin.eventPlanner.overview.artistCompensation',
            })}
          </Heading>
          <ValueContainer>
            {event.eventArtistCompensation ||
            event.eventArtistCompensation == 0 ? (
              currencyFormatter(
                event.eventArtistCompensation,
                event.currencySymbol || '$'
              )
            ) : (
              <>
                <None />
                {(event.artistCompensation ||
                  event.artistCompensation == 0) && (
                  <HelperText>
                    {intl.formatMessage(
                      {
                        id:
                          'admin.eventPlanner.overview.artistCompensationOverrideCountryHelperText',
                      },
                      {
                        countryValue: currencyMainUnitsFormatter(
                          event.artistCompensation,
                          event.currencySymbol || '$'
                        ),
                      }
                    )}
                  </HelperText>
                )}
              </>
            )}
          </ValueContainer>
        </Col>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={3}>
          <Heading>
            {intl.formatMessage({
              id: 'admin.eventPlanner.overview.concertMerchandising',
            })}
          </Heading>
          <Subheading>
            {fallbackEventCollection
              ? intl.formatMessage(
                  {
                    id:
                      'admin.eventPlanner.overview.concertMerchandisingSubheadingWithCollection',
                  },
                  {
                    collectionTitle: fallbackEventCollection.title,
                  }
                )
              : intl.formatMessage({
                  id:
                    'admin.eventPlanner.overview.concertMerchandisingSubheading',
                })}
          </Subheading>
          <Spacer mb={6} />
          <ValueContainer>
            <Heading>
              {intl.formatMessage({
                id: 'admin.eventPlanner.overview.collections',
              })}
            </Heading>
            <ValueContainer>
              {eventCollectionLinkComponents &&
              eventCollectionLinkComponents.length > 0 ? (
                <ExpandableList
                  itemComponents={eventCollectionLinkComponents}
                />
              ) : (
                <None />
              )}
            </ValueContainer>
            <SmallHeading>
              {intl.formatMessage({
                id: 'admin.eventPlanner.overview.concertPagePhotoTitle',
              })}
            </SmallHeading>
            <Subheading>
              {intl.formatMessage({
                id:
                  'admin.eventPlanner.overview.concertPagePhoto.' +
                  eventHeaderImageInfo?.imageSource,
              })}
            </Subheading>
            <img
              src={eventHeaderImageInfo?.imageSourceMap.srcSmallDesktop}
              width="175"
              height="auto"
            />
            <Spacer mb={6} />
            <SmallHeading>
              {intl.formatMessage({
                id: 'admin.eventPlanner.overview.concertHeadline',
              })}
            </SmallHeading>
            {eventHeadlineAndDescriptionInfo.headlineSource && (
              <Subheading>
                {intl.formatMessage({
                  id:
                    'admin.eventPlanner.overview.concertHeadline.' +
                    eventHeadlineAndDescriptionInfo.headlineSource,
                })}
              </Subheading>
            )}
            <SmallValue>
              {eventHeadlineAndDescriptionInfo.headline || <None />}
            </SmallValue>
            <SmallHeading>
              {intl.formatMessage({
                id: 'admin.eventPlanner.overview.concertDescription',
              })}
            </SmallHeading>
            {eventHeadlineAndDescriptionInfo.descriptionSource && (
              <Subheading>
                {intl.formatMessage({
                  id:
                    'admin.eventPlanner.overview.concertDescription.' +
                    eventHeadlineAndDescriptionInfo.descriptionSource,
                })}
              </Subheading>
            )}
            <SmallValue>
              {eventHeadlineAndDescriptionInfo.description ? (
                <ExpandableText
                  allowHTML
                  text={eventHeadlineAndDescriptionInfo.description}
                  truncateLength={120}
                />
              ) : (
                <None />
              )}
            </SmallValue>
            {eventMerchandisingInfo && (
              <MerchandisingPropertyGroups
                merchandisingProperties={eventMerchandisingInfo}
              />
            )}
          </ValueContainer>
        </Col>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={4}>
          <EventTimes
            crewLoadInAt={event.crewLoadInAt}
            artistLoadInAt={event.artistLoadInAt}
            guestsArriveAt={event.guestsArriveAt}
            startsAt={event.startsAt}
            endsAt={event.endsAt}
            timezone={event.city && event.city.timezone}
          />
          <Spacer mb={6} />
          <>
            <Heading>
              {intl.formatMessage({
                id: 'admin.shared.timeNotes',
              })}
            </Heading>
            <ValueContainer data-qaid="event-timing-notes">
              {event.timingNotes ? (
                <ExpandableText
                  allowHTML
                  text={event.timingNotes}
                  truncateLength={120}
                />
              ) : (
                <None />
              )}
            </ValueContainer>
          </>
          <Heading>
            {intl.formatMessage({
              id: 'admin.eventPlanner.overview.theme',
            })}
          </Heading>
          <ValueContainer data-qaid="event-theme">
            {event.theme?.title || <None />}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.eventPlanner.overview.themeNotes',
            })}
          </Heading>
          <ValueContainer data-qaid="event-theme-notes">
            {event.theme?.notes ? (
              <ExpandableText
                allowHTML
                text={event.theme.notes}
                truncateLength={120}
              />
            ) : (
              <None />
            )}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.eventPlanner.overview.specialEvent',
            })}
          </Heading>
          <ValueContainer data-qaid="event-special-event">
            {event.isSpecialEvent ? (
              <Link href={event.specialEventUrl} openInNewTab={true}>
                {intl.formatMessage({
                  id: 'yes',
                })}
              </Link>
            ) : (
              <>
                {intl.formatMessage({
                  id: 'no',
                })}
              </>
            )}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.shared.filmed',
            })}
          </Heading>
          <ValueContainer data-qaid="event-filmed">
            {event.isFilmed
              ? intl.formatMessage({
                  id: 'yes',
                })
              : intl.formatMessage({
                  id: 'no',
                })}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'shared.competition',
            })}
          </Heading>
          <ValueContainer data-qaid="event-competition">
            {event.competition && event.competition.id ? (
              event.competition.title
            ) : (
              <None />
            )}
          </ValueContainer>
          {displayConfig.displayPartnerInformation && (
            <>
              <Heading>
                {intl.formatMessage({
                  id: 'admin.eventPlanner.partnerInfo.label',
                })}
              </Heading>
              <ValueContainer data-qaid="event-sponsor">
                {get(event, 'campaign.partner.id', undefined) ? (
                  hasAccessCampaignPermission ? (
                    <Link
                      href={`/admin/partners?partner_search=${event.campaign?.partner?.id}`}
                      openInNewTab={true}
                      rel="noopener noreferrer"
                    >
                      {get(event, 'campaign.partner.title', undefined)}
                    </Link>
                  ) : (
                    get(event, 'campaign.partner.title', undefined)
                  )
                ) : (
                  <None />
                )}
              </ValueContainer>
            </>
          )}
        </Col>
      </Grid>
    </MainContainer>
  );
};

export default SectionBasicInfo;
