import React from 'react';
import styled, { css } from 'styled-components';

interface Props {
  val: number;
  start: number;
  end: number;
  onChange: (val: number) => void;
}

interface NumberRatingProps {
  val: number;
  isActive: boolean;
}

const CircleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Circle = styled.div<NumberRatingProps>`
  ${({ isActive }) => css`
    border-radius: 50%;
    width: 23px;
    height: 23px;
    font-weight: 400;
    font-size: 10px;
    padding: 4px;
    background: #d9d9d9;
    text-align: center;
    margin-right: 3px;
    cursor: pointer;

    ${isActive &&
      css`
        background: #039842;
        color: #ffffff;
      `}
  `}
`;

const Rating: React.FC<Props> = ({ val, start, end, onChange }) => {
  const numbers: number[] = [];
  for (let i = start; i <= end; i++) {
    numbers.push(i);
  }
  return (
    <CircleContainer>
      {numbers.map(n => (
        <Circle
          key={n}
          val={n}
          isActive={n === val}
          onClick={() => onChange(n)}
        >
          {n}
        </Circle>
      ))}
    </CircleContainer>
  );
};

export default Rating;
