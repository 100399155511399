export const sortOptions = [
  {
    title: 'Model Name (A - Z)',
    value: {
      by: 'item_type',
      direction: 'asc',
    },
  },
  {
    title: 'Model Name (Z - A)',
    value: {
      by: 'item_type',
      direction: 'desc',
    },
  },
  {
    title: 'Created (Oldest First)',
    value: {
      by: 'date',
      direction: 'asc',
    },
  },
  {
    title: 'Created (Most Recent First)',
    value: {
      by: 'date',
      direction: 'desc',
    },
  },
];

export const eventOptions = [
  { title: 'Create', value: 'create' },
  { title: 'Update', value: 'update' },
  { title: 'Destroy', value: 'destroy' },
  { title: 'Touch', value: 'touch' },
];

export const dateOptions = [
  { title: 'Today', value: 'today' },
  { title: 'Yesterday', value: 'yesterday' },
  { title: 'Past 7 Days', value: 'past_7_days' },
  { title: 'Past 30 Days', value: 'past_30_days' },
  { title: 'Past 90 Days', value: 'past_90_days' },
];
