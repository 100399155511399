import React from 'react';
import styled from 'styled-components';

import Carousel from 'app/admin/components/organisms/Carousel';

interface VenuePhotoCarouselProps {
  contentProps?: any;
}

const Container = styled.div`
  margin: auto;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  min-height: calc(100% - 126px);
  height: max-content;
  overflow: scroll;
`;

const VenuePhotoCarousel: React.FC<VenuePhotoCarouselProps> = ({
  contentProps,
}) => {
  const sources = contentProps.photos.map((photo: any) => ({
    url: photo.imageUrl,
    title: photo.title,
    description: photo.description,
    id: photo.id,
    updatedAt: photo.updatedAt,
    createdBy: photo.createdBy,
    timezone: contentProps.timezone,
  }));
  return (
    <Container>
      <Carousel
        startIndex={contentProps.index}
        sources={sources}
        type="image"
        onEditClick={(venuePhoto: any) => {
          const photo = { ...venuePhoto, imageUrl: venuePhoto.url };
          contentProps.navigateTo({
            routeName: 'venue-setup-photo-uploader',
            routeProps: {
              venueId: contentProps.venueId,
              venueName: contentProps.venueName,
              headerImageUrl: contentProps.headerImageUrl,
              venuePhoto: photo,
            },
          });
        }}
      />
    </Container>
  );
};

export default VenuePhotoCarousel;
