import React from 'react';
import styled, { css } from 'styled-components';

import { User } from 'app/typings/users';
import { DeleteIcon } from 'app/shared/components/atoms/IconLibrary';
import { userFullNameOrEmail } from 'app/admin/utils/users';
import { UserLink } from 'app/admin/components/molecules/EntityLink';

interface AssociatedUserProps {
  user: User;
  userWillBeUnassociatedOnSave?: boolean;
  removeAssociatedUser: Function;
  associatedUserRemoveMsg: string;
}

const AssociatedUserContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const AssociatedUserDisplayContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: flex-start;
`;

const AssociatedUserRemoveMsgContainer = styled.div`
  font-size: 14px;
  margin-top: -9px;
  margin-bottom: 12px;
`;

const UserContainer = styled.div`
  font-size: 14px;
  letter-spacing: 0.4px;
  padding-top: 4px;
  margin-bottom: 12px;
`;

const UserInactive = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.blueSmoke};
  `}
`;

const UserRemoveContainer = styled.div`
  padding-left: 9px;
`;

export const AssociatedUser: React.FC<AssociatedUserProps> = ({
  user,
  userWillBeUnassociatedOnSave,
  removeAssociatedUser,
  associatedUserRemoveMsg,
}) => {
  return (
    <AssociatedUserContainer>
      <AssociatedUserDisplayContainer>
        {userWillBeUnassociatedOnSave ? (
          <>
            <UserContainer>
              <UserInactive>{userFullNameOrEmail(user)}</UserInactive>
            </UserContainer>
            <UserRemoveContainer>
              <DeleteIcon iconSize={12} onClick={() => {}} disabled={true} />
            </UserRemoveContainer>
          </>
        ) : (
          <>
            <UserContainer>
              <UserLink user={user} truncateLength={30} />
            </UserContainer>
            <UserRemoveContainer>
              <DeleteIcon
                iconSize={12}
                onClick={() => removeAssociatedUser(user.id)}
              />
            </UserRemoveContainer>
          </>
        )}
      </AssociatedUserDisplayContainer>
      {userWillBeUnassociatedOnSave && (
        <AssociatedUserRemoveMsgContainer>
          {associatedUserRemoveMsg}
        </AssociatedUserRemoveMsgContainer>
      )}
    </AssociatedUserContainer>
  );
};
