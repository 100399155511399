import * as Yup from 'yup';

import { validateUrlNoHttp } from 'app/shared/utils/urls';

const siteBannerEditFormSchema = (intl: any) =>
  Yup.object().shape({
    bannerLink: Yup.string()
      .required(
        intl.formatMessage({
          id: 'form.requiredField',
        })
      )
      .test(
        'isUrl',
        intl.formatMessage(
          {
            id: 'form.fieldMustBeValidUrl',
          },
          {
            field: 'Link URL',
          }
        ),
        function(value) {
          return value ? validateUrlNoHttp(value) : true;
        }
      ),
    bannerLinkText: Yup.string().required(
      intl.formatMessage({
        id: 'form.requiredField',
      })
    ),
    bannerMessage: Yup.string().required(
      intl.formatMessage({
        id: 'form.requiredField',
      })
    ),
  });

export default siteBannerEditFormSchema;
