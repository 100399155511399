import React from 'react';

import Label from 'app/admin/components/atoms/Label';

interface Props {
  labelMapping: { [key: string]: string[] };
  titleMapping: { [key: string]: { [value: string]: string } };
  removeLabel?: (key: string, label: string) => void;
  closeButton?: boolean;
  rewriteValue?: Function;
}

const getLabelValues = (labelMapping: { [key: string]: string[] }) => {
  // Convert the label mapping to an array of key-values
  const labelValues: string[][] = [];

  Object.entries(labelMapping).forEach(label =>
    label[1].forEach(val => {
      if (!val.includes('-select-all')) {
        labelValues.push([label[0], val]);
      }
    })
  );

  return labelValues;
};

const LabelList: React.FC<Props> = ({
  labelMapping,
  titleMapping,
  removeLabel = () => {},
  closeButton = false,
  rewriteValue,
}) => {
  const labelValues = getLabelValues(labelMapping);

  return (
    <>
      {labelValues.map((label: string[]) => {
        const [key, value] = label;
        return titleMapping[key] && titleMapping[key][value] ? (
          <Label
            key={`${key}-${value}`}
            name={`${key}-${value}`}
            title={titleMapping[key][value]}
            onClose={() => removeLabel(key, value)}
            closeButton={closeButton}
          />
        ) : (
          <Label
            key={`${key}-${value}`}
            name={`${key}-${value}`}
            title={`${(rewriteValue && rewriteValue(key, value)) || value}`}
            onClose={() => removeLabel(key, value)}
            closeButton={closeButton}
          />
        );
      })}
    </>
  );
};

export default LabelList;
