import React from 'react';
import { useIntl } from 'react-intl';

import { errorMsgForField } from 'app/shared/utils/form';
import { Checkbox } from 'app/shared/components/atoms/CheckboxManualCSS';
import {
  FormGroupContainer,
  FormSectionTitle,
} from 'app/shared/components/atoms/FormContent';
import StyledTextarea from 'app/shared/components/atoms/SmallTextarea';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Textfield } from 'app/shared/components/atoms/Textfield';
import FormGroup from 'app/shared/components/molecules/FormGroup';
import RichTextEditorComponent from 'app/admin/components/molecules/RichTextEditor';

interface Props {
  setFieldValue: any;
  touched: any;
  setTouched: any;
  errors: any;
  values: any;
}

const CompetitionSharingEditFormFields: React.FC<Props> = ({
  setFieldValue,
  touched,
  setTouched,
  errors,
  values,
}) => {
  const intl = useIntl();

  return (
    <>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.competitionSharing.form.dataSharing',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <Checkbox
          id="competition-sharing-edit-form-turn-on-opt-in-field"
          data-qaid="competition-sharing-edit-form-turn-on-opt-in-field"
          checked={values.turnOnOptIn}
          name="turnOnOptIn"
          onChange={e => setFieldValue('turnOnOptIn', e.target.checked)}
        >
          {intl.formatMessage({
            id: 'admin.competitionSharing.form.turnOnOptIn',
          })}
        </Checkbox>
        <Spacer mb={8} />
        <FormGroup
          data-qaid="competition-sharing-edit-form-opt-in-copy-formgroup"
          label={intl.formatMessage({
            id: 'admin.competitionSharing.form.optInCopy',
          })}
          errorMsg={errorMsgForField('optInCopy', touched, errors)}
        >
          <RichTextEditorComponent
            data-qaid="competition-sharing-edit-form-opt-in-copy-field"
            name="optInCopy"
            currentValue={values.optInCopy}
            onChange={value => {
              setTouched({ ...touched, optInCopy: true });
              setFieldValue('optInCopy', value);
            }}
            maxCharacters={600}
          />
        </FormGroup>
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.competitionSharing.form.facebook',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="competition-sharing-edit-form-share-title-formgroup"
          label={intl.formatMessage({
            id: 'admin.competitionSharing.form.facebookShareTitle',
          })}
          errorMsg={errorMsgForField('shareTitle', touched, errors)}
        >
          <Textfield
            data-qaid="competition-sharing-edit-form-share-title-field"
            id="shareTitle"
            name="shareTitle"
            value={values.shareTitle}
            onChange={(e: any) => {
              setFieldValue('shareTitle', e.target.value);
            }}
            maxLength={200}
          />
        </FormGroup>
        <Spacer mb={5} />
        <FormGroup
          data-qaid="competition-sharing-edit-form-share-copy-formgroup"
          label={intl.formatMessage({
            id: 'admin.competitionSharing.form.facebookShareCopy',
          })}
          errorMsg={errorMsgForField('shareCopy', touched, errors)}
        >
          <StyledTextarea
            data-qaid="competition-sharing-edit-form-share-copy-field"
            id="shareCopy"
            name="shareCopy"
            value={values.shareCopy}
            onChange={(e: any) => {
              setFieldValue('shareCopy', e.target.value);
            }}
            maxLength={500}
          />
        </FormGroup>
      </FormGroupContainer>
    </>
  );
};

export default CompetitionSharingEditFormFields;
