import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { Campaign } from 'app/typings/campaigns';
import { Partner } from 'app/typings/partners';
import { get } from 'app/shared/utils/get';
import { Icon } from 'app/shared/components/atoms/IconManualCSS';
import { MenuItem } from 'app/shared/components/atoms/Menu';
import { Body2 } from 'app/shared/components/atoms/TypographyManualCSS';
import { SelectState } from 'app/shared/components/molecules/SelectManualCSS';
import Select from 'app/shared/components/molecules/SelectManualCSS';
import { GetCampaignsLazy } from 'app/admin/graphql/campaigns/queryHooks';

interface Props {
  partner?: Partner;
  initialValue?: Campaign | null;
  value?: Campaign | null;
  onChange: (option: Campaign) => void;
  dataQaidPrefix: string;
}

const getState = (loading: any, error: any) => {
  if (loading) {
    return SelectState.loading;
  } else if (error) {
    return SelectState.error;
  }

  return SelectState.ready;
};

const CampaignSelector: React.FC<Props> = ({
  partner,
  initialValue,
  value,
  onChange,
  dataQaidPrefix,
}) => {
  const intl = useIntl();

  const [
    fetchCampaigns,
    { loading: loadingCampaigns, error: errorCampaigns, data: dataCampaigns },
  ] = GetCampaignsLazy({
    archivedStatus: 'not_archived',
    // Default partnerId to arbitrarily high number which guarantees no partner exists for
    // that id, which ensures by default (if user unselects partner and hence we need to
    // empty the campaign select), no campaigns for that partner id are returned from backend -
    // the campaign select being empty on initial component load is handled by only running
    // query if we have a partnerId, in useEffect
    partner: partner
      ? partner && partner.id
        ? partner.id.toString()
        : '999999'
      : '999999',
    skipPagination: true,
  });

  useEffect(() => {
    if (partner) {
      fetchCampaigns();
    }
  }, [partner, fetchCampaigns]);

  const campaignOptions = get(dataCampaigns, 'campaigns.campaigns', []);

  return (
    <Select
      data-qaid={`${dataQaidPrefix}-campaign-selector`}
      state={getState(loadingCampaigns, errorCampaigns)}
      searchable
      onChange={onChange}
      placeholder={intl.formatMessage({
        id: 'admin.campaignSelector.campaignPlaceholder',
      })}
      options={campaignOptions}
      getOptionLabel={o => (o && o.title ? o.title : '')}
      renderOption={(o, props) => (
        <MenuItem
          key={o.title}
          {...props}
          data-qaid={`${dataQaidPrefix}-option`}
        >
          <Body2 as="span">{o.title}</Body2>
        </MenuItem>
      )}
      renderLeftIcon={<Icon name="search" />}
      defaultValue={initialValue}
      value={value}
    />
  );
};

export default CampaignSelector;
