import React from 'react';
import styled from 'styled-components';

import { Image as ImageType } from 'app/typings';
import { getImageUrlForImageObject } from 'app/shared/utils/images';
import { Image } from 'app/shared/components/atoms/ImageManualCSS';

interface Props {
  images: ImageType[];
}

const StyledImage = styled(Image)`
  max-height:150px;
  max-width:150px
  object-fit: cover;
  margin: 5px;
`;

const ImageList: React.FC<Props> = ({ images }) => {
  return (
    <>
      {images.map((image: ImageType, index: number) => {
        return (
          <StyledImage
            src={getImageUrlForImageObject({ image })}
            alt={image.ratio + '-' + index}
            key={image.id}
          />
        );
      })}
    </>
  );
};

export default ImageList;
