import { Artist } from 'app/typings/artists';
import { Event } from 'app/typings/events';

import { getArtistIdsSubmitVariable, InvalidEventInfo } from './index';

export const maxNumEventsByNumPositions = {
  1: 70,
  2: 45,
  3: 35,
};

export const maxNumArtistsByNumPositions = {
  1: 80,
  2: 60,
  3: 50,
};

const getNumSubmissableEventIds = (events: any[]) => {
  let numEventIds = 0;
  const eventIds = {};
  for (const event of events) {
    if (!eventIds[event.id]) {
      numEventIds += 1;
    }
    eventIds[event.eventId] = true;
  }
  return numEventIds;
};

export const getMaxNumValues = (positions: number[]) => {
  const numPositions = positions.length;

  return {
    maxNumEvents: maxNumEventsByNumPositions[numPositions],
    maxNumArtists: maxNumArtistsByNumPositions[numPositions],
  };
};

export const getHasValidationErrorOverMaxOfferSize = (
  artists: Artist[],
  events: Event[],
  positions: number[],
  invalidEventInfo: InvalidEventInfo
) => {
  const numSubmissableEventIds = getNumSubmissableEventIds(events);
  const numSubmissableArtistIds = getArtistIdsSubmitVariable(
    artists,
    events,
    invalidEventInfo
  ).length;
  const { maxNumEvents, maxNumArtists } = getMaxNumValues(positions);

  return (
    numSubmissableEventIds > maxNumEvents ||
    numSubmissableArtistIds > maxNumArtists
  );
};
