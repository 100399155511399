import React, { useState } from 'react';
import moment from 'moment';
import RCTimePicker from 'rc-time-picker';
import styled from 'styled-components';

import 'rc-time-picker/assets/index.css';

interface Props {
  'data-qaid'?: string;
  disabled?: boolean;
  name: string;
  onChange: (value: any) => void;
  placeholder?: string;
  readOnly?: boolean;
  value?: string;
}

// This is an ugly way to avoid typescript errors since getPopupContainer it is not a documented prop
const StyledTimepicker = styled(RCTimePicker)<{ getPopupContainer: any }>`
  & input {
    width: 100px;
    height: 44px;
    background-color: #ffffff;
    border-radius: 2px;
    border: 1px solid #dbdbdb;
    padding: 0 16px;
    outline: none;
    box-shadow: none;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    font-size: 14px;
    -webkit-letter-spacing: 0.1px;
    -moz-letter-spacing: 0.1px;
    -ms-letter-spacing: 0.1px;
    letter-spacing: 0.1px;
    color: #111111;
  }

  & a {
    top: -1px;
    right: 0px;

    & i {
      color: #979797;
      font-size: 15px;
    }
  }
`;

const Timepicker: React.FC<Props> = ({
  'data-qaid': dataQaId = 'timepicker',
  disabled = false,
  name,
  onChange,
  placeholder = '',
  readOnly = false,
  value = '',
}) => {
  const [selectedValue, setSelectedValue] = useState<any>(undefined);
  const [valueShouldBeNull, setValueShouldBeNull] = useState<boolean>(false);

  const parseTime = (time: any) => moment.utc(time);

  const handleChange = (date: any) => {
    let dateInUTC;

    if (date) {
      // date is a moment object in the context of the user's local time
      const selectedDate = date.get('date');
      const selectedMonth = date.get('month');
      const selectedYear = date.get('year');
      const selectedHour = date.get('hour');
      const selectedMinute = date.get('minute');

      dateInUTC = moment.utc().set({
        date: selectedDate,
        month: selectedMonth,
        year: selectedYear,
        hour: selectedHour,
        minute: selectedMinute,
      });

      const now = moment.utc();
      const parsedTime = parseTime(now);
      const timezoneOffset = parsedTime.utcOffset();

      dateInUTC = moment.utc(dateInUTC).subtract(timezoneOffset, 'm');
    }

    setSelectedValue(dateInUTC);
    setValueShouldBeNull(!date);

    // Handle clearing the timepicker field by clicking the X
    if (!date) {
      onChange(null);
    }
  };

  const handleClose = () => {
    const value = selectedValue ? selectedValue.toISOString() : null;

    if (value === null && !valueShouldBeNull) {
      return;
    }

    onChange(value);
  };

  const format = 'h:mm a';

  const currentMomentValue = () => {
    if (valueShouldBeNull) {
      return undefined;
    }

    if (selectedValue || valueShouldBeNull) {
      return parseTime(selectedValue);
    }

    if (value) {
      return parseTime(value);
    }

    return undefined;
  };

  return (
    <div data-qaid={dataQaId}>
      <StyledTimepicker
        showSecond={false}
        showHour
        showMinute
        placeholder={placeholder}
        name={name}
        id={name}
        minuteStep={5}
        format={format}
        inputReadOnly={readOnly}
        use12Hours
        disabled={disabled}
        onChange={handleChange}
        onClose={handleClose}
        value={currentMomentValue()}
        // This is an undocumented prop used to fix an issue when timepicker container is
        // not fixed on input trigger: https://github.com/react-component/time-picker/issues/89
        getPopupContainer={(triggerNode: any) => triggerNode.parentNode}
      />
    </div>
  );
};

export default Timepicker;
