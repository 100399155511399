export const sortOptions = [
  {
    title: 'Title (A - Z)',
    value: {
      by: 'title',
      direction: 'asc',
    },
  },
  {
    title: 'Title (Z - A)',
    value: {
      by: 'title',
      direction: 'desc',
    },
  },
  {
    title: 'Country Code (A - Z)',
    value: {
      by: 'country_code',
      direction: 'asc',
    },
  },
  {
    title: 'Country Code (Z - A)',
    value: {
      by: 'country_code',
      direction: 'desc',
    },
  },
  {
    title: 'Number of Cities Most First',
    value: {
      by: 'cities_count',
      direction: 'desc',
    },
  },
  {
    title: 'Number of Cities Least First',
    value: {
      by: 'cities_count',
      direction: 'asc',
    },
  },
];
