import React from 'react';
import { useIntl } from 'react-intl';

import { get } from 'app/shared/utils/get';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { UpdateVenue } from 'app/admin/graphql/venues/mutationHooks';
import { DetailsFormMainContainer } from 'app/admin/components/atoms/DetailContent';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

import VenueBookingInformationEditForm from './VenueBookingInformationEditForm';
import VenueBookingInformationEditFormSchema from './VenueBookingInformationEditFormSchema';

interface Props {
  contentProps?: any;
  setFormSubmitAction: (func: Function) => void;
  setDisplayConfirmation: (set: boolean) => void;
  setIsSubmitting: (set: boolean) => void;
  navigateTo: (routeData: object) => void;
}

const VenueBookingInformationEdit: React.FC<Props> = ({
  contentProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  navigateTo,
}) => {
  const intl = useIntl();
  const availableDays = (contentProps.availableDays || []).map(
    (availableDay: { name: string; key: string }) => availableDay.key
  );
  const hostingFrequency =
    get(contentProps, 'hostingFrequency', '').key || undefined;

  const formInitialValues = {
    availableDays,
    availabilityNotes: get(contentProps, 'availabilityNotes', '') || undefined,
    hostingFrequency,
  };

  const updateVenueAction = UpdateVenue();

  const handleUpdateVenue = useSubmitAction({
    submitAction: updateVenueAction,
    submitVariables: (values: any) => ({
      venueId: contentProps.id,
      availableDays: values.availableDays,
      availabilityNotes: values.availabilityNotes,
      hostingFrequency: values.hostingFrequency || null,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.venueBasicInfo.successMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.venueBasicInfo.failureMessage',
    }),
    onSuccess: (response: any) => {
      navigateTo({
        routeName: 'venue-details',
        routeProps: {
          defaultOpenSection: 'bookingInformation',
          ...response.data.updateVenue.venue,
        },
      });
    },
  });

  return (
    <ModalContentContainer>
      <DetailsFormMainContainer>
        <DetailsHeader
          title={get(contentProps, 'venueName', '')}
          imageUrl={get(contentProps, 'imageUrl', '')}
          largeImageUrl={get(contentProps, 'largeImageUrl', '')}
          imageStyle="landscape"
        />
        <GenericForm
          formInitialValues={formInitialValues}
          renderFormComponent={(renderProps: any) => (
            <VenueBookingInformationEditForm
              formikProps={renderProps.formikProps}
            />
          )}
          onSubmit={handleUpdateVenue}
          formSchema={VenueBookingInformationEditFormSchema(intl)}
          setFormSubmitAction={setFormSubmitAction}
          setDisplayConfirmation={setDisplayConfirmation}
          setIsSubmitting={setIsSubmitting}
          dataQaId="venue-booking-information-section-edit-form"
        />
      </DetailsFormMainContainer>
    </ModalContentContainer>
  );
};

export default VenueBookingInformationEdit;
