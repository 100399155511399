import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { errorMsgForField } from 'app/shared/utils/form';
import { socialUrls } from 'app/shared/utils/socialLinks';
import {
  FormGroupContainer,
  FormHelpText,
  FormSectionTitle,
} from 'app/shared/components/atoms/FormContent';
import { Textfield } from 'app/shared/components/atoms/Textfield';
import FormGroup from 'app/shared/components/molecules/FormGroup';

interface Props {
  setFieldValue: any;
  touched: any;
  errors: any;
  values: any;
  socialLinkKeys: string[];
  titleText?: string;
  topHelpText?: string;
  helpTextByLink?: object;
  dataQaidPrefix: string;
}

interface DynamicSpacerProps {
  isLast?: boolean;
}

const FormGroupDynamicSpacer = styled.div<DynamicSpacerProps>`
  margin-bottom: ${props => (props.isLast ? '0px' : '16px')};
`;

const StyledTextfield = styled(Textfield)`
  ${({ theme }) => css`
    display: block;
    width: 100%;
    ${theme.media.lg`
        width: 48%;
      `};
  `}
`;

const SocialLinkFormFields: React.FC<Props> = ({
  setFieldValue,
  touched,
  errors,
  values,
  socialLinkKeys,
  titleText,
  topHelpText,
  helpTextByLink,
  dataQaidPrefix,
}) => {
  const intl = useIntl();

  return (
    <>
      <FormSectionTitle>
        {titleText ||
          intl.formatMessage({
            id: 'admin.socialLinkList.links',
          })}
      </FormSectionTitle>
      <FormGroupContainer>
        {topHelpText && <FormHelpText>{topHelpText}</FormHelpText>}
        {socialLinkKeys.map((key: string, index: number) => {
          return (
            <FormGroupDynamicSpacer
              key={index}
              isLast={index === socialLinkKeys.length - 1}
            >
              <FormGroup
                data-qaid={`${dataQaidPrefix}-${key}-formgroup`}
                label={socialUrls[key].name}
                errorMsg={errorMsgForField(
                  `socialUrls.${key}`,
                  touched,
                  errors
                )}
              >
                <>
                  {helpTextByLink && helpTextByLink[key] && (
                    <FormHelpText>{helpTextByLink[key]}</FormHelpText>
                  )}
                  <StyledTextfield
                    data-qaid={`${dataQaidPrefix}-${key}-field`}
                    id={`socialUrls.${key}`}
                    name={`socialUrls.${key}`}
                    placeholder="Link"
                    value={values.socialUrls[key]}
                    onChange={(e: any) => {
                      setFieldValue(`socialUrls.${key}`, e.target.value);
                    }}
                    maxLength={200}
                  />
                </>
              </FormGroup>
            </FormGroupDynamicSpacer>
          );
        })}
      </FormGroupContainer>
    </>
  );
};

export default SocialLinkFormFields;
