import React from 'react';
import { useIntl } from 'react-intl';

import { ArtistApplicationOfferDecision } from 'app/typings/artistApplications';
import { City } from 'app/typings/cities';
import { get } from 'app/shared/utils/get';
import { AdminLink } from 'app/shared/components/atoms/LinkManualCSS';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import RadioGroup from 'app/shared/components/molecules/RadioGroup';
import Select from 'app/shared/components/molecules/SelectManualCSS';
import { validateOfferArtistLimit } from 'app/admin/utils/offers';
import { GetOffers } from 'app/admin/graphql/offers/queryHooks';
import { maxNumArtistsByNumPositions } from 'app/admin/components/organisms/OfferCreate/maxOfferSizeValidation';

interface Props {
  city?: City;
  values: any;
  setFieldValue: Function;
  setArtistApplicationOfferDecision: Function;
  setArtistApplicationSelectedOffer: Function;
}

const ArtistApplicationOfferDecisionField: React.FC<Props> = ({
  city,
  values,
  setFieldValue,
  setArtistApplicationOfferDecision,
  setArtistApplicationSelectedOffer,
}) => {
  const intl = useIntl();

  const {
    loading: loadingOffers,
    error: errorOffers,
    data: dataOffers,
  } = GetOffers({
    hasUpcomingEvents: true,
    offerType: 'artist',
    status: 'open',
    city: city?.cachedSlug,
    page: 1,
    perPage: 100,
  });

  const offerOptions = get(dataOffers, 'offers.offers', [])
    .filter((offer: any) =>
      validateOfferArtistLimit(offer, maxNumArtistsByNumPositions)
    )
    .map((offer: any) => ({ title: offer.name, value: offer.id }));

  return (
    <RadioGroup
      name="artistApplicationOfferDecision"
      data-qaid="artist-application-booking-options-field"
      options={[
        {
          title: intl.formatMessage({
            id: 'admin.artistApplication.details.createNewOffer',
          }),
          value: ArtistApplicationOfferDecision.CREATE_NEW_OFFER,
        },
        {
          title: intl.formatMessage({
            id: 'admin.artistApplication.details.addToExistingOffer',
          }),
          value: ArtistApplicationOfferDecision.ADD_TO_EXISTING_OFFER,
          disabled: loadingOffers || !!errorOffers || offerOptions.length === 0,
        },
      ]}
      subfields={{
        [ArtistApplicationOfferDecision.ADD_TO_EXISTING_OFFER]: {
          component: (
            <>
              <Spacer mt={2} />
              <Select
                options={offerOptions}
                searchable={
                  offerOptions.length > 0 && !loadingOffers && !errorOffers
                }
                getOptionLabel={(option: any) => option.title}
                placeholder={
                  offerOptions.length > 0 && !loadingOffers
                    ? intl.formatMessage({
                        id:
                          'admin.artistApplications.addArtistToExistingInvitation.placeholder',
                      })
                    : intl.formatMessage({
                        id: 'admin.artistApplications.noInvitationsAvailable',
                      })
                }
                onChange={(option: any) => {
                  setArtistApplicationOfferDecision(
                    ArtistApplicationOfferDecision.ADD_TO_EXISTING_OFFER
                  );
                  setArtistApplicationSelectedOffer(option);
                }}
              />
              <Spacer mt={2} />
              <AdminLink
                href={`/admin/artist-booking-invitations?city=${city?.cachedSlug}`}
                openInNewTab={true}
              >
                {intl.formatMessage(
                  {
                    id: 'admin.artistApplications.viewAllCityInvitations',
                  },
                  { city: city?.title }
                )}
              </AdminLink>
              <Spacer mt={2} />
            </>
          ),
          indented: false,
        },
      }}
      selectedValue={values.artistApplicationOfferDecision}
      onChange={value => {
        setFieldValue('artistApplicationOfferDecision', value);
        setArtistApplicationOfferDecision(value);
      }}
      spaceBetweenOptions={2}
    />
  );
};

export default ArtistApplicationOfferDecisionField;
