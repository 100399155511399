import React from 'react';
import { useIntl } from 'react-intl';

import {
  getCurrencyFormInitialValue,
  getCurrencySubmitVariable,
} from 'app/shared/utils/form';
import { get } from 'app/shared/utils/get';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { UpdateCountry } from 'app/admin/graphql/countries/mutationHooks';
import { DetailsFormMainContainer } from 'app/admin/components/atoms/DetailContent';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

import CountrySettingsEditForm from './CountrySettingsEditForm';
import CountrySettingsEditFormSchema from './CountrySettingsEditFormSchema';

interface Props {
  contentProps?: any;
  setFormSubmitAction: (func: Function) => void;
  setDisplayConfirmation: (set: boolean) => void;
  setIsSubmitting: (set: boolean) => void;
  navigateTo: (routeData: object) => void;
}

const CountrySettingsEdit: React.FC<Props> = ({
  contentProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  navigateTo,
}) => {
  const intl = useIntl();

  const formInitialValues = {
    bookingFee: getCurrencyFormInitialValue(contentProps, 'bookingFee'),
    displayBookingFee: contentProps.displayBookingFee,
    isTieredArtistCompensationEnabled: get(
      contentProps,
      'isTieredArtistCompensationEnabled',
      false
    ),
    artistCompensation: getCurrencyFormInitialValue(
      contentProps,
      'artistCompensation'
    ),
    artistCompensationTier1: getCurrencyFormInitialValue(
      contentProps,
      'artistCompensationTier1'
    ),
    artistCompensationTier2: getCurrencyFormInitialValue(
      contentProps,
      'artistCompensationTier2'
    ),
    artistCompensationTier3: getCurrencyFormInitialValue(
      contentProps,
      'artistCompensationTier3'
    ),
    artistCompensationTier4: getCurrencyFormInitialValue(
      contentProps,
      'artistCompensationTier4'
    ),
    artistCompensationTier5: getCurrencyFormInitialValue(
      contentProps,
      'artistCompensationTier5'
    ),
  };

  const updateCountryAction = UpdateCountry();

  const handleSubmit = useSubmitAction({
    submitAction: updateCountryAction,
    submitVariables: (values: any) => ({
      countryId: get(contentProps, 'id', undefined),
      bookingFee: getCurrencySubmitVariable(values.bookingFee),
      displayBookingFee: values.displayBookingFee,
      isTieredArtistCompensationEnabled:
        values.isTieredArtistCompensationEnabled,
      artistCompensation: getCurrencySubmitVariable(values.artistCompensation),
      artistCompensationTier1: getCurrencySubmitVariable(
        values.artistCompensationTier1
      ),
      artistCompensationTier2: getCurrencySubmitVariable(
        values.artistCompensationTier2
      ),
      artistCompensationTier3: getCurrencySubmitVariable(
        values.artistCompensationTier3
      ),
      artistCompensationTier4: getCurrencySubmitVariable(
        values.artistCompensationTier4
      ),
      artistCompensationTier5: getCurrencySubmitVariable(
        values.artistCompensationTier5
      ),
    }),
    successMsg: intl.formatMessage({
      id: 'admin.countryBasicInfo.form.successMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.countryBasicInfo.form.failureMessage',
    }),
    onSuccess: response => {
      navigateTo({
        routeName: 'country-details',
        routeProps: {
          defaultOpenSection: 'settings',
          ...response.data.updateCountry.country,
        },
      });
    },
  });

  return (
    <ModalContentContainer>
      <DetailsFormMainContainer>
        <DetailsHeader title={get(contentProps, 'title', '')} />
        <GenericForm
          formInitialValues={formInitialValues}
          renderFormComponent={(renderProps: any) => (
            <CountrySettingsEditForm formikProps={renderProps.formikProps} />
          )}
          onSubmit={handleSubmit}
          formSchema={CountrySettingsEditFormSchema(intl)}
          setFormSubmitAction={setFormSubmitAction}
          setDisplayConfirmation={setDisplayConfirmation}
          setIsSubmitting={setIsSubmitting}
          dataQaId="country-settings-section-edit-form"
        />
      </DetailsFormMainContainer>
    </ModalContentContainer>
  );
};

export default CountrySettingsEdit;
