import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { snakeCaseToDash } from 'app/shared/utils/string';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import { Menu, MenuItem } from 'app/shared/components/atoms/Menu';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Body2, H5 } from 'app/shared/components/atoms/TypographyManualCSS';
import Dropdown from 'app/shared/components/molecules/Dropdown';
import ActionIcon from 'app/admin/components/atoms/ActionIcon';
import DropdownOptions from 'app/admin/components/molecules/DropdownOptions';
import { ReactComponent as ArrowUp } from 'icons/streamline-regular/arrows-diagrams/arrows/arrow-up.svg';
import { ReactComponent as LayoutModule } from 'icons/streamline-regular/interface-essential/layouts/layout-module.svg';
import { ReactComponent as SynchronizeArrow } from 'icons/streamline-regular/interface-essential/synchronize/synchronize-arrow.svg';
import { ReactComponent as MapsPin2 } from 'icons/streamline-regular/maps-navigation/maps/maps-pin-2.svg';

interface Props {
  sortOptions: Sort[];
  orderBy: string | null;
  orderDirection: string | null;
  viewMode?: string | null;
  hideResultsText?: boolean;
  resultsTextStringId?: string;
  totalRecords?: number;
  loading: boolean;
  onViewModeChange?: VoidFunction;
  onSort: (sortValue: SortValue) => void;
  onReset: VoidFunction;
  selectedDisplayFields?: string[] | null;
  handleDisplayFieldsChange?: (values: any) => void;
  selectedFieldDisplayOptions?: { title: string; value: string }[];
  dataQaidPrefix?: string;
}

interface SortValue {
  by: string;
  direction: string;
}

interface Sort {
  title: string;
  value: SortValue;
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-right: 8px;
`;

const ListCount = styled.div`
  display: flex;
  flex-direction: column-reverse;
  flex-grow: 1;
  p {
    height: fit-content;
  }
`;

const StyledH5 = styled(H5)`
  margin-bottom: 5px;
`;

const StyledMenu = styled(Menu)`
  padding: 30px 10px;
  max-height: none;
  border-radius: 10px;
`;

interface SortOptionProps {
  isSelected?: boolean;
}

const SortOption = styled(Body2)<SortOptionProps>`
  ${props => `
    color: ${props.isSelected ? props.theme.colors.green600 : 'black'};
    font-weight: ${props.isSelected ? '600' : '400'};
  `}
`;

const SmallMarginDottedLine = styled(DottedLine)`
  margin: 2px 0 2px 0;
`;

const FieldDisplayOptionsWrapper = styled.div`
  margin-left: 7px;
`;

const ListingControls: React.FC<Props> = ({
  sortOptions,
  orderBy,
  orderDirection,
  viewMode,
  hideResultsText,
  resultsTextStringId,
  totalRecords,
  loading,
  onViewModeChange,
  onSort,
  onReset,
  dataQaidPrefix,
  handleDisplayFieldsChange,
  selectedFieldDisplayOptions,
  selectedDisplayFields,
}) => {
  const intl = useIntl();

  const renderFieldDisplayOptions = () => {
    if (
      !selectedFieldDisplayOptions ||
      !handleDisplayFieldsChange ||
      !selectedDisplayFields
    ) {
      return null;
    }
    return (
      <FieldDisplayOptionsWrapper>
        <DropdownOptions
          title={intl.formatMessage({
            id: 'list.displayFields',
          })}
          selectedValues={selectedDisplayFields}
          onApply={handleDisplayFieldsChange}
          options={selectedFieldDisplayOptions}
          submitLabel={intl.formatMessage({
            id: 'list.displayFields.submit.label',
          })}
          customDisplayButton={true}
        />
      </FieldDisplayOptionsWrapper>
    );
  };

  return (
    <>
      <MainContainer>
        <ListCount>
          <StyledH5>
            {loading || !totalRecords || hideResultsText
              ? null
              : intl.formatMessage(
                  {
                    id: resultsTextStringId || 'list.showingXResults',
                  },
                  {
                    numResults: totalRecords.toLocaleString(),
                  }
                )}
          </StyledH5>
        </ListCount>
        {viewMode && onViewModeChange && (
          <ActionIcon
            icon={viewMode == 'map' ? LayoutModule : MapsPin2}
            label={
              viewMode == 'map'
                ? intl.formatMessage({
                    id: 'list.list',
                  })
                : intl.formatMessage({
                    id: 'list.map',
                  })
            }
            onClick={onViewModeChange}
            dataQaid={`${dataQaidPrefix}-map-toggle`}
          />
        )}
        {viewMode !== 'map' && (
          <Dropdown
            flyoutContainer={false}
            offset={{ vertical: 15, horizontal: 36 }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            renderLabel={(isOpen: boolean) => (
              <ActionIcon
                icon={ArrowUp}
                label={intl.formatMessage({
                  id: 'list.sort',
                })}
                isOpen={isOpen}
                isContained={true}
                dataQaid={`${dataQaidPrefix}-sort-filter`}
              />
            )}
            disableScrollWhenOpen={false}
          >
            <StyledMenu data-qaid={`${dataQaidPrefix}-sort-menu`}>
              {sortOptions.map((opt: Sort, idx: number) => (
                <MenuItem
                  data-qaid={opt.value.by}
                  key={`${opt.value.by}-${idx}`}
                  value={opt.value.by}
                  onClick={() => {
                    onSort(opt.value);
                  }}
                >
                  <a
                    data-qaid={`${dataQaidPrefix}-sort-by-${snakeCaseToDash(
                      opt.value.by
                    )}-${opt.value.direction}`}
                  >
                    <SortOption
                      isSelected={
                        opt.value.by === orderBy &&
                        opt.value.direction === orderDirection
                      }
                    >
                      {opt.title}
                    </SortOption>
                  </a>
                </MenuItem>
              ))}
            </StyledMenu>
          </Dropdown>
        )}
        <ActionIcon
          icon={SynchronizeArrow}
          label={intl.formatMessage({
            id: 'list.reset',
          })}
          onClick={onReset}
          dataQaid={`${dataQaidPrefix}-reset-filter`}
        />
        {selectedFieldDisplayOptions && renderFieldDisplayOptions()}
      </MainContainer>
      <SmallMarginDottedLine />
      <Spacer mb={4} />
    </>
  );
};

export default ListingControls;
