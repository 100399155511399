import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Email } from 'app/typings/emails';
import { Event } from 'app/typings/events';
import { LoadingBlocks } from 'app/shared/components/atoms/LoadingBlocks';
import { LoadingError } from 'app/shared/components/atoms/LoadingError';
import {
  Body2,
  Overline,
} from 'app/shared/components/atoms/TypographyManualCSS';
import IconAndTextLink from 'app/shared/components/molecules/IconAndTextLink';
import {
  emailGroupLabels,
  emailGroupLabelsSingular,
} from 'app/admin/utils/events';
import { GetEventEmails } from 'app/admin/graphql/emails/queryHooks';
import { UserLink } from 'app/admin/components/molecules/EntityLink';
import EmailCard from 'app/admin/components/organisms/EventDetails/EmailCard';
import { ReactComponent as EmailActionWarning } from 'icons/streamline-regular/emails/email-actions/email-action-warning.svg';
import { ReactComponent as Cog } from 'icons/streamline-regular/interface-essential/settings/cog.svg';

interface Props {
  sectionData: any;
  extraData?: any;
  callbacks: { [name: string]: Function };
}

const MainContainer = styled.div`
  width: 100%;
`;

const EmailCardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`;

const EmailGroupsContainer = styled.div`
  padding-top: 20px;
  padding-bottom: 10px;
`;

const EmailGroupFromEntityMessage = styled(Body2)`
  font-weight: 700;
  letter-spacing: 0.5px;
  white-space: normal;
`;

const EmailGroupFromEmailAddress = styled.div`
  font-size: 14px;
  margin-top: 4px;
  margin-bottom: 25px;
`;

const EmailGroupFromUser = styled.div`
  font-size: 14px;
  margin-top: 4px;
  margin-bottom: 25px;
`;

const emailGroupsAndOpsUserKeys = {
  Artist: 'mainArtistOps',
  Venue: 'mainVenueOps',
  Crew: 'mainCrewOps',
};

const getFromEntityForGuestEmailGroup = (event: Event) => {
  let emailAddress = null;
  let user = null;

  if (event.fromEmail) {
    emailAddress = event.fromEmail;
  } else if (event.city && event.city.fromEmail) {
    emailAddress = event.city.fromEmail;
  } else {
    emailAddress = 'website@sofarsounds.com';
  }

  return { emailAddress, user };
};

const getFromEntityForArtistVenueCrewEmailGroup = (
  emailGroup: string,
  event: Event
) => {
  let emailAddress = null;
  let user = null;

  const opsUserKey = emailGroupsAndOpsUserKeys[emailGroup];

  if (opsUserKey) {
    const eventOpsUser = event[opsUserKey];
    const cityOpsUser = event.city && event.city[opsUserKey];
    if (event.useFromEmailForAllEmails && event.fromEmail) {
      emailAddress = event.fromEmail;
    } else if (eventOpsUser) {
      user = eventOpsUser;
    } else if (
      event.city &&
      event.city.useFromEmailForAllEmails &&
      event.city.fromEmail
    ) {
      emailAddress = event.city.fromEmail;
    } else if (cityOpsUser) {
      user = cityOpsUser;
    } else {
      emailAddress = 'info@sofarsounds.com';
    }
  }

  return { emailAddress, user };
};

const getFromEntityForEmailGroup = (emailGroup: string, event: Event) => {
  if (emailGroup == 'Guest') {
    return getFromEntityForGuestEmailGroup(event);
  } else {
    return getFromEntityForArtistVenueCrewEmailGroup(emailGroup, event);
  }
};

interface EmailGroupProps {
  emailGroup: string;
  emails: Email[];
  event: Event;
  callbacks: { [name: string]: Function };
}

const EmailGroup: React.FC<EmailGroupProps> = ({
  emailGroup,
  emails,
  event,
  callbacks,
}) => {
  const intl = useIntl();

  const emailGroupLabel = emailGroupLabels(intl)[emailGroup];
  const emailGroupLabelSingular = emailGroupLabelsSingular(intl)[emailGroup];

  const {
    emailAddress: fromEmailAddress,
    user: fromUser,
  } = getFromEntityForEmailGroup(emailGroup, event);

  return (
    <>
      <Overline spaceAfter={4}>{emailGroupLabel}</Overline>
      {fromEmailAddress && (
        <>
          <EmailGroupFromEntityMessage>
            {intl.formatMessage(
              {
                id:
                  'admin.eventPlanner.emails.emailsWillBeSentFromEmailAddress',
              },
              {
                emailGroup: emailGroupLabelSingular,
              }
            )}
          </EmailGroupFromEntityMessage>
          <EmailGroupFromEmailAddress>
            {fromEmailAddress}
          </EmailGroupFromEmailAddress>
        </>
      )}
      {fromUser && (
        <>
          <EmailGroupFromEntityMessage>
            {intl.formatMessage(
              {
                id: 'admin.eventPlanner.emails.emailsWillBeSentFromUser',
              },
              {
                emailGroup: emailGroupLabelSingular,
              }
            )}
          </EmailGroupFromEntityMessage>
          <EmailGroupFromUser>
            <UserLink user={fromUser} truncateLength={40} />
          </EmailGroupFromUser>
        </>
      )}
      <EmailCardsContainer>
        {emails.map((email: Email, index: number) => (
          <EmailCard
            email={email}
            event={event}
            index={index}
            onPreviewEventEmail={(emailData: any) => {
              callbacks.togglePreviewEventEmailModal(emailData);
            }}
            key={index}
          />
        ))}
      </EmailCardsContainer>
    </>
  );
};

const getEmailGroupsAndEmails = (dataEventEmails: any) => {
  const emailsToUse = dataEventEmails?.eventEmails?.emails;
  return emailsToUse
    ? emailsToUse.reduce((map: {}, email: Email) => {
        if (email.emailGroup) {
          map[email.emailGroup] = map[email.emailGroup] || [];
          map[email.emailGroup].push(email);
        }
        return map;
      }, {})
    : {};
};

const eventHasDisableableEmails = (emailGroupsAndEmails: any) => {
  const disableableEventEmails = Object.values(emailGroupsAndEmails)
    .flat()
    .filter((email: any) => email && !!email.isDisableable);

  return disableableEventEmails.length > 0;
};

const SectionEmailsInfo: React.FC<Props> = ({
  sectionData,
  extraData,
  callbacks,
}) => {
  const intl = useIntl();

  const { event } = sectionData;

  const {
    loading: loadingEventEmails,
    error: errorEventEmails,
    data: dataEventEmails,
  } = GetEventEmails({
    eventId: event.id,
    emailKey: extraData?.urgentEmailKeySent,
    fetchPolicy: 'no-cache',
  });

  if (loadingEventEmails) {
    return <LoadingBlocks.Rectangle width="100%" height="300px" />;
  }

  if ((!loadingEventEmails && !dataEventEmails) || errorEventEmails) {
    return (
      <LoadingError
        whatsBeingLoaded={intl.formatMessage({
          id: 'admin.eventPlanner.emails.theEmailInformation',
        })}
        containerSize="small"
      />
    );
  }

  const emailGroupsAndEmails = getEmailGroupsAndEmails(dataEventEmails);
  const displayEmailSettings = eventHasDisableableEmails(emailGroupsAndEmails);

  return (
    <MainContainer data-qaid="section-event-emails-info">
      <IconAndTextLink
        icon={EmailActionWarning}
        text={intl.formatMessage({
          id: 'admin.eventPlanner.emails.sendUrgentEmail',
        })}
        onClick={() => callbacks.toggleSendUrgentEmailModal()}
        dataQaid="urgent-email-link"
      />
      <EmailGroupsContainer>
        {Object.keys(emailGroupLabels(intl)).map(
          (emailGroup: string, index: number) =>
            emailGroupsAndEmails[emailGroup] && (
              <EmailGroup
                emailGroup={emailGroup}
                emails={emailGroupsAndEmails[emailGroup]}
                event={event}
                callbacks={callbacks}
                key={index}
              />
            )
        )}
      </EmailGroupsContainer>

      {displayEmailSettings && (
        <IconAndTextLink
          icon={Cog}
          text={intl.formatMessage({
            id: 'admin.eventPlanner.emails.manageEmailSettings',
          })}
          onClick={() =>
            callbacks.toggleEmailSettingsModal(emailGroupsAndEmails)
          }
          dataQaid="manage-email-settings-link"
        />
      )}
    </MainContainer>
  );
};

export default SectionEmailsInfo;
