import React from 'react';
import { useIntl } from 'react-intl';

import { Property, PropertyWithSource } from 'app/typings';
import { merchandisingPropertyGroupNames } from 'app/shared/utils/events';
import {
  DetailSmallHeading as SmallHeading,
  DetailSmallValue as SmallValue,
  DetailSubheading,
} from 'app/admin/components/atoms/DetailContent';
import { None } from 'app/admin/components/atoms/None';

interface Props {
  merchandisingProperties?: Property[] | PropertyWithSource[];
}

const propertyDetailsForPropertyGroupName = (
  propertyGroupName: string,
  merchandisingProperties?: Property[] | PropertyWithSource[]
) => {
  const property = merchandisingProperties?.find(
    (property: Property | PropertyWithSource) =>
      property.propertyGroupName == propertyGroupName
  );

  if (property) {
    return {
      name: property.name,
      source: property && 'source' in property ? property.source : undefined,
    };
  }

  return null;
};

const MerchandisingPropertyGroups: React.FC<Props> = ({
  merchandisingProperties,
}) => {
  const intl = useIntl();

  return (
    <>
      {merchandisingPropertyGroupNames.map(
        (propertyGroupName: string, index: number) => {
          const propertyDetails = propertyDetailsForPropertyGroupName(
            propertyGroupName,
            merchandisingProperties
          );

          return (
            <React.Fragment key={index}>
              <SmallHeading>{propertyGroupName}</SmallHeading>
              {propertyDetails && propertyDetails.source && (
                <DetailSubheading>
                  {intl.formatMessage({
                    id:
                      'admin.eventPlanner.overview.merchandising.' +
                      propertyDetails.source,
                  })}
                </DetailSubheading>
              )}
              <SmallValue>{propertyDetails?.name || <None />}</SmallValue>
            </React.Fragment>
          );
        }
      )}
    </>
  );
};

export default MerchandisingPropertyGroups;
