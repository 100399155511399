import React, { useState } from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';

import { Event } from 'app/typings/events';
import { extractContactInfoInitialValuesByPosition } from 'app/shared/utils/contactInfos';
import { get } from 'app/shared/utils/get';
import {
  getSocialUrlsFormInitialValues,
  getSocialUrlsSubmitVariable,
  venueSocialUrls,
} from 'app/shared/utils/socialLinks';
import useModal from 'app/shared/utils/useModal';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import UpdateOptionsModal from 'app/shared/components/organisms/UpdateOptionsModal';
import { UpdateVenue } from 'app/admin/graphql/venues/mutationHooks';
import { DetailsFormMainContainer } from 'app/admin/components/atoms/DetailContent';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

import VenueBasicInfoForm from './VenueBasicInfoForm';
import VenueBasicInfoFormSchema from './VenueBasicInfoFormSchema';

interface Props {
  contentProps?: any;
  setFormSubmitAction: (func: Function) => void;
  setDisplayConfirmation: (set: boolean) => void;
  setIsSubmitting: (set: boolean) => void;
  navigateTo: (routeData: object) => void;
}

const fieldsRequiringUpdateOptions = [
  'numTicketsAvailableForSale',
  'properties',
];

const VenueBasicInfoEdit: React.FC<Props> = ({
  contentProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  navigateTo,
}) => {
  const intl = useIntl();
  const [submitData, setSubmitData] = useState({});
  const [changedFields, setChangedFields] = useState<string[]>([]);
  const [totalChangedFields, setTotalChangedFields] = useState<number>(0);
  const [updateOptionsModal, toggleUpdateOptionsModal] = useModal();

  const venueName = get(contentProps, 'venueName', '');
  const contactInfos = get(contentProps, 'contactInfos', []);
  const venueFeeDescription = get(contentProps, 'venueFeeDescription', '');
  const neighborhood = get(contentProps, 'neighborhood', null);

  const formInitialValues = {
    isNewVenue: false,
    venueName,
    address: get(contentProps, 'address', ''),
    address2: get(contentProps, 'address2', ''),
    latitude: get(contentProps, 'latitude', null),
    longitude: get(contentProps, 'longitude', null),
    city: get(contentProps, 'city', null),
    neighborhood: neighborhood && neighborhood.id ? neighborhood : null,
    closestStation: get(contentProps, 'closestStation', ''),
    properties: get(contentProps, 'properties', []).map((e: any) => e.id),
    capacity: get(contentProps, 'capacity', ''),
    numTicketsAvailableForSale: get(
      contentProps,
      'numTicketsAvailableForSale',
      ''
    ),
    numGuestTickets: get(contentProps, 'numGuestTickets', ''),
    contactInfos: {
      primaryContactInfo: extractContactInfoInitialValuesByPosition(
        contactInfos,
        1
      ),
      secondaryContactInfo: extractContactInfoInitialValuesByPosition(
        contactInfos,
        2
      ),
      tertiaryContactInfo: extractContactInfoInitialValuesByPosition(
        contactInfos,
        3
      ),
    },
    tags: get(contentProps, 'tags', []).map((e: any) => e.id),
    venueCategories: get(contentProps, 'venueCategories', []).map(
      (e: any) => e.id
    ),
    socialUrls: getSocialUrlsFormInitialValues(
      venueSocialUrls,
      contentProps,
      'socialUrls'
    ),
    mapUrl: get(contentProps, 'mapUrl', ''),
    streetViewUrl: get(contentProps, 'streetViewUrl', ''),
    venueFeePresent: get(contentProps, 'venueFeePresent', false),
    venueFeeDescription: venueFeeDescription || '',
    parkingNotes: get(contentProps, 'parkingNotes', ''),
  };

  const updateVenueAction = UpdateVenue();

  const handleUpdateVenue = useSubmitAction({
    submitAction: updateVenueAction,
    submitVariables: (values: any) => ({
      venueId: contentProps.id,
      venueName: values.venueName,
      address: values.address,
      address2: values.address2,
      latitude: values.latitude,
      longitude: values.longitude,
      closestStation: values.closestStation,
      cityId: get(values, 'city.id', undefined),
      neighborhoodId: get(values, 'neighborhood.id', null),
      processProperties: true,
      properties: values.properties,
      capacity: values.capacity.toString(),
      numTicketsAvailableForSale: values.numTicketsAvailableForSale.toString(),
      numGuestTickets: values.numGuestTickets.toString(),
      contactInfos: Object.values(get(values, 'contactInfos', {})),
      tags: values.tags,
      processTags: true,
      venueCategories: values.venueCategories,
      socialUrls: getSocialUrlsSubmitVariable(values),
      mapUrl: values.mapUrl,
      streetViewUrl: values.streetViewUrl,
      venueFeePresent: values.venueFeePresent,
      venueFeeDescription: values.venueFeeDescription,
      parkingNotes: values.parkingNotes,
      applyMethod: values.applyMethod,
      selectedValues: values.selectedValues,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.venueBasicInfo.successMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.venueBasicInfo.failureMessage',
    }),
    onSuccess: (response: any) => {
      navigateTo({
        routeName: 'venue-details',
        routeProps: {
          defaultOpenSection: 'basicInfo',
          ...response.data.updateVenue.venue,
        },
      });
    },
  });

  return (
    <ModalContentContainer>
      <DetailsFormMainContainer>
        <DetailsHeader
          title={get(contentProps, 'venueName', '')}
          imageUrl={get(contentProps, 'imageUrl', '')}
          largeImageUrl={get(contentProps, 'largeImageUrl', '')}
          imageStyle="landscape"
        />
        <GenericForm
          formInitialValues={formInitialValues}
          renderFormComponent={(renderProps: any) => (
            <VenueBasicInfoForm
              formikProps={renderProps.formikProps}
              setFormSubmitAction={setFormSubmitAction}
              setDisplayConfirmation={setDisplayConfirmation}
              setIsSubmitting={setIsSubmitting}
              numContactInfos={get(contentProps, 'contactInfos', []).length}
            />
          )}
          onSubmit={data => {
            const changedFieldsForUpdateOptions = fieldsRequiringUpdateOptions.filter(
              (field: string) => {
                if (field === 'properties') {
                  return (
                    formInitialValues.properties.length !==
                    data.properties.length
                  );
                }

                return formInitialValues[field] !== data[field];
              }
            );

            const numChangedFields = Object.keys(data).filter(
              (field: string) =>
                JSON.stringify(formInitialValues[field]) !==
                JSON.stringify(data[field])
            ).length;

            if (changedFieldsForUpdateOptions.length === 0) {
              handleUpdateVenue(data);
            } else {
              setIsSubmitting(false);
              setSubmitData(data);
              setChangedFields(changedFieldsForUpdateOptions);
              setTotalChangedFields(numChangedFields);
              return toggleUpdateOptionsModal();
            }
          }}
          formSchema={VenueBasicInfoFormSchema(intl)}
          dataQaId="venue-about-section-edit-form"
        />
        {updateOptionsModal.isShowing && (
          <UpdateOptionsModal
            onCancel={() => updateOptionsModal.hide()}
            onConfirm={(data: any) => {
              setIsSubmitting(true);
              updateOptionsModal.hide();
              handleUpdateVenue({
                ...submitData,
                ...data,
              });
            }}
            dataQaidPrefix="venue-basic-info-options-modal"
            title={intl.formatMessage(
              {
                id: 'admin.updateOptionsModal.applyChangesWarning',
              },
              {
                updatedFields: changedFields.length,
                totalFields: totalChangedFields,
              }
            )}
            changedFields={changedFields}
            modalType="venue"
            options={contentProps.allUpcomingEvents.map((ev: Event) => ({
              title:
                ev.localStartsAt &&
                moment.utc(ev.localStartsAt).format('MMM DD YYYY hh:mmA'),
              value: ev.id,
            }))}
          />
        )}
      </DetailsFormMainContainer>
    </ModalContentContainer>
  );
};

export default VenueBasicInfoEdit;
