import React from 'react';
import styled, { css } from 'styled-components';

import { PrimaryButton } from 'app/shared/components/atoms/Button';
import { AddIcon, DownloadIcon } from 'app/shared/components/atoms/IconLibrary';
import { BaseLink } from 'app/shared/components/atoms/LinkManualCSS';
import { ToolTip } from 'app/shared/components/atoms/ToolTip';
import { H1 } from 'app/shared/components/atoms/TypographyManualCSS';
import SearchInput from 'app/shared/components/molecules/SearchInput';

interface Props {
  pageTitle: string;
  addEntityText?: string;
  onClickAddEntity?: Function;
  downloadText?: string;
  downloadUrl?: string;
  downloadDisabledText?: string;
  availabilityText?: string;
  onAvailabilityClick?: (available: any) => void;
  searchPlaceholder?: string;
  searchValue?: any;
  onSearch?: (search: string) => void;
  dataQaidPrefix?: string;
  isAddEntityActive?: boolean;
  mobileTitleSpacing?: string;
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;

const TitleContainer = styled.div`
  ${({ theme }) => css`
    display: flex;

    ${theme.media.xs`
      flex-grow: 2;
    `}

    ${theme.media.md`
      flex-grow: 4;
    `}
  `}
`;

const Title = styled(H1)<{ mobileTitleSpacing?: string }>`
  ${({ mobileTitleSpacing = '0px 0px 20px 0px', theme }) => css`
    ${theme.media.xs`
      margin: ${mobileTitleSpacing};
      letter-spacing: normal;
      font-weight: 600;
    `}

    ${theme.media.md`
      margin-top: 0px;
      margin-bottom: 20px;
      font-weight: normal;
    `}
  `}
`;

interface AddEntityControlsProps {
  otherControlsPresent?: boolean;
}

const AddEntityControls = styled.div<AddEntityControlsProps>`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-end;
    margin: 0 8px 20px 0;
    flex-grow: 1;

    ${theme.media.lg`
      margin: 0 ${(props: { otherControlsPresent?: boolean }) =>
        props.otherControlsPresent ? '10px' : '30px'} 20px 0;
    `}

    ${theme.media.xl`
      margin: 0 ${(props: { otherControlsPresent?: boolean }) =>
        props.otherControlsPresent ? '10px' : '40px'} 20px 0;
    `}
  `}
`;

const DownloadControls = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-end;
    margin: 0 8px 20px 0;
    flex-grow: 1;

    ${theme.media.lg`
      margin: 0 30px 20px 0;
    `}

    ${theme.media.xl`
      margin: 0 50px 20px 0;
    `}
  `}
`;

const ActionIconWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    cursor: pointer;
    white-space: nowrap;
    color: ${theme.colors.green600};
  `}
`;

const ActionIconDisabledWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    cursor: default;
    white-space: nowrap;
    color: ${theme.colors.blueSmoke};
  `}
`;

const IconWrapper = styled(BaseLink)`
  ${({ theme }) => css`
    display: flex;
    cursor: pointer;
    white-space: nowrap;
    color: ${theme.colors.green600};
  `}
`;

const IconDisabledWrapper = styled.a`
  ${({ theme }) => css`
    display: flex;
    cursor: default;
    white-space: nowrap;
    color: ${theme.colors.blueSmoke};
  `}
`;

const BaseIconText = styled.div`
  font-size: 10px;
  line-height: 150%;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  margin-top: 2px;
  margin-left: 6px;
`;

const ActiveIconText = styled(BaseIconText)`
  ${({ theme }) => css`
    color: ${theme.colors.green600};
  `}
`;

const DisabledIconText = styled(BaseIconText)`
  ${({ theme }) => css`
    color: ${theme.colors.blueSmoke};
  `}
`;

const LongTextTooltip = styled(ToolTip)`
  ${({ theme }) => css`
    left: -0%;
    width: 150px;
    max-width: 150px;
    margin-left: 0px;
    padding: 10px;

    ${theme.media.xl`
      left: -65%;
      width: 300px;
      max-width: 300px;
    `}
  `}
`;

const LongTextTooltipContainer = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 99;

  &:hover ${LongTextTooltip} {
    visibility: visible;
    opacity: 1;
  }
`;

const ButtonsContainer = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.ruler[6]}px;
    display: flex;
    justify-content: flex-end;
  `}
`;

const ButtonSpacer = styled.div`
  ${({ theme }) => css`
    display: inline;
    margin-left: ${theme.ruler[2]}px;
    margin-right: ${theme.ruler[2]}px;
  `}
`;

const SearchInputWrapper = styled.div`
  ${({ theme }) => css`
    ${theme.media.xs`
      flex-grow: 3;
    `};

    ${theme.media.md`
      flex-grow: 1;
    `};
  `}
`;

const ListingHeader: React.FC<Props> = ({
  pageTitle,
  addEntityText,
  onClickAddEntity,
  downloadText,
  downloadUrl,
  downloadDisabledText,
  searchPlaceholder,
  searchValue,
  onSearch,
  availabilityText,
  onAvailabilityClick,
  dataQaidPrefix,
  isAddEntityActive = true,
  mobileTitleSpacing,
}) => {
  return (
    <MainContainer>
      <TitleContainer data-qaid={`${dataQaidPrefix}-title-container`}>
        <Title
          data-qaid={`${dataQaidPrefix}-title`}
          mobileTitleSpacing={mobileTitleSpacing}
        >
          {pageTitle}
        </Title>
      </TitleContainer>
      {addEntityText && onClickAddEntity && isAddEntityActive && (
        <AddEntityControls
          otherControlsPresent={!!(downloadText && downloadUrl)}
        >
          <ActionIconWrapper
            data-qaid={`add-${dataQaidPrefix}-button`}
            onClick={() => onClickAddEntity()}
          >
            <AddIcon iconColor="green" />
            <ActiveIconText> {addEntityText}</ActiveIconText>
          </ActionIconWrapper>
        </AddEntityControls>
      )}
      {addEntityText && onClickAddEntity && !isAddEntityActive && (
        <AddEntityControls
          otherControlsPresent={!!(downloadText && downloadUrl)}
        >
          <ActionIconDisabledWrapper data-qaid={`add-${dataQaidPrefix}-button`}>
            <AddIcon iconColor="grey" />
            <DisabledIconText> {addEntityText}</DisabledIconText>
          </ActionIconDisabledWrapper>
        </AddEntityControls>
      )}
      {downloadText && downloadUrl && !downloadDisabledText && (
        <DownloadControls>
          <IconWrapper
            data-qaid={`download-${dataQaidPrefix}-button`}
            href={downloadUrl}
            download
            openInNewTab={true}
          >
            <DownloadIcon iconSize={20} iconColor="green" />
            <ActiveIconText> {downloadText}</ActiveIconText>
          </IconWrapper>
        </DownloadControls>
      )}
      {downloadText && downloadUrl && downloadDisabledText && (
        <DownloadControls>
          <LongTextTooltipContainer>
            <IconDisabledWrapper
              data-qaid={`download-${dataQaidPrefix}-button`}
            >
              <DownloadIcon iconSize={20} iconColor="grey" />
              <DisabledIconText> {downloadText}</DisabledIconText>
            </IconDisabledWrapper>
            <LongTextTooltip>{downloadDisabledText}</LongTextTooltip>
          </LongTextTooltipContainer>
        </DownloadControls>
      )}
      {searchPlaceholder && onSearch && (
        <SearchInputWrapper>
          <SearchInput
            placeholder={searchPlaceholder}
            value={searchValue}
            onSearch={onSearch}
            dataQaidPrefix={`${dataQaidPrefix}-search`}
          />
        </SearchInputWrapper>
      )}
      {availabilityText && onAvailabilityClick && (
        <ButtonsContainer>
          <ButtonSpacer>
            <PrimaryButton
              onClick={onAvailabilityClick}
              data-qaid={`availability-${dataQaidPrefix}-button`}
            >
              {availabilityText}
            </PrimaryButton>
          </ButtonSpacer>
        </ButtonsContainer>
      )}
    </MainContainer>
  );
};

export default ListingHeader;
