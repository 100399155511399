import React, { useContext, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { ArtistApplicationStatus } from 'app/typings/artistApplications';
import { KeyValue } from 'app/typings/generics';
import { User } from 'app/typings/users';
import { useCurrentTheme } from 'app/shared/theme';
import { dateFormatter } from 'app/shared/utils/datetime';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import { AuthContext } from 'app/shared/context/Auth';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import {
  AddIcon,
  DeleteIcon,
  MusicNoteIcon,
} from 'app/shared/components/atoms/IconLibrary';
import { Link } from 'app/shared/components/atoms/LinkManualCSS';
import {
  Spacer8,
  Spacer12,
  Spacer20,
} from 'app/shared/components/atoms/Spacer';
import { Spinner } from 'app/shared/components/atoms/SpinnerManualCSS';
import { StreamlineIcon } from 'app/shared/components/atoms/StreamlineIcon';
import TruncatedByCharText from 'app/shared/components/atoms/TruncatedByCharText';
import {
  Body2,
  Overline,
} from 'app/shared/components/atoms/TypographyManualCSS';
import { UpdateArtistApplicationReferrer } from 'app/admin/graphql/artistApplications/mutationHooks';
import { KeyValueInfoList } from 'app/admin/components/molecules/KeyValueInfoList';

interface ApplicationCardProps {
  id: number;
  index: number;
  cityTitle: string;
  artistName: string;
  createdAt: string;
  dataQaidPrefix: string;
  homeCityDescription?: string;
  onShowArtistApplicationReview: Function;
  referrer: User;
  status: string;
}
interface ReferralButtonProps {
  loading: boolean;
  referrer: User;
}

interface CardWrapperProps {
  height: string;
}

const CardWrapper = styled.div<CardWrapperProps>`
  ${props => `
    background-color: ${props.theme.colors.silverSprings};
    width: 100%;
    max-width: 240px;
    height: ${props.height || '600px'} !important;
    border-radius: ${props.theme.ruler[3]}px;
    padding: ${props.theme.ruler[4]}px;
    display: flex;
    flex-direction: column;
  `}
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`;

const IconWrapper = styled.span`
  margin-right: 10px;
  display: inline-flex;
`;

const StyledReferralLinkWrapper = styled.div`
  ${({ theme }) => css`
    ${theme.media.xs`
      margin-left: 14px;
    `}

    ${theme.media.md`
      margin-left: 0px;
    `}
    padding: 8px 0px;
  `}
`;

const LoadingWrapper = styled.div`
  padding: 7px 0px;
`;

const ReferredByWrapper = styled.div`
  padding: 10px 0px;
`;

const TopInfoContainer = styled.div`
  height: 165px;
`;

const KeyValueInfoListContainer = styled.div`
  height: auto;
`;

const StyledBody2 = styled(Body2)`
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const StyledReferralOverline = styled(Overline)`
  ${({ theme }) => css`
    color: ${theme.colors.green600};
    font-size: 14px !important;
    letter-spacing: 0.4px;
    text-transform: none;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    ${theme.media.xs`
      margin-left: -14px;
    `}

    ${theme.media.md`
      margin-left: -1px;
    `}

    &:hover {
      text-transform: none;
    }
  `}
`;

const IconButton = styled.a`
  ${({ theme }) => css`
    display: flex;
    cursor: pointer;
    margin-right: 8px;
    color: ${theme.colors.green600};
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.4px;
    line-height: 1.6;

    ${theme.media.xs`
      margin-left: 65px;
      margin-bottom: 10px;
      margin-top: 10px;
    `}

    ${theme.media.md`
      margin-left: 35px;
      margin-bottom: 10px;
      margin-top: 10px;
    `}
  `}
`;

const StyledStreamlineIcon = styled(StreamlineIcon)`
  ${({ theme }) => css`
    margin-top: 2px;
    margin-right: 5px;

    > span > span > svg {
      background-color: ${theme.colors.green600};
      border-radius: 50%;
      transform: scale(1.2);
    }

    > span > span > svg > path {
      stroke: ${theme.colors.whiteDenim};
      transform: scale(0.55) translate(8px, 10px);
    }
  `}
`;

const CardTitle = styled(Body2)`
  font-weight: 700;
  letter-spacing: 0.5px;
`;

const CardSubtitle = styled(Body2)`
  font-size: 12px !important;
`;

const ArtistApplicationCard: React.FC<ApplicationCardProps> = ({
  artistName,
  cityTitle,
  createdAt,
  dataQaidPrefix,
  homeCityDescription,
  id,
  index,
  onShowArtistApplicationReview,
  referrer,
  status,
}) => {
  const intl = useIntl();
  const listingCardRef = useRef<any>();
  const { user } = useContext(AuthContext);
  const theme = useCurrentTheme();
  const userId = user?.id;

  const [loading, setLoading] = useState(false);
  const [currentReferrer, setCurrentReferrer] = useState(referrer);

  const updateArtistApplicationReferrerAction = UpdateArtistApplicationReferrer();
  const handleUpdateArtistApplicationReferrer = useSubmitAction({
    submitAction: updateArtistApplicationReferrerAction,
    submitVariables: () => ({
      id,
      removeReferrer: !!currentReferrer,
    }),
    successMsg: intl.formatMessage(
      {
        id: currentReferrer
          ? 'admin.removeArtistApplicationReferrer.successMessage'
          : 'admin.addArtistApplicationReferrer.successMessage',
      },
      {
        artistName,
      }
    ),
    failureMsg: intl.formatMessage(
      {
        id: currentReferrer
          ? 'admin.removeArtistApplicationReferrer.failureMessage'
          : 'admin.addArtistApplicationReferrer.failureMessage',
      },
      {
        artistName,
      }
    ),
    onSuccess: (response: any) => {
      setLoading(false);
      setCurrentReferrer(
        response?.data?.updateArtistApplicationReferrer?.artistApplication
          ?.referrer
      );
    },
  });

  const keysAndValuesList = [
    {
      key: intl.formatMessage({
        id: 'admin.shared.submitted',
      }),
      value: dateFormatter(createdAt, 'shortMonthDateAndYear'),
    } || '',
    {
      key: intl.formatMessage({
        id: 'admin.artistApplication.card.applied',
      }),
      value: cityTitle || '',
    },
  ];

  const ReferralButton: React.FC<ReferralButtonProps> = ({
    referrer,
    loading,
  }) => {
    return (
      <>
        <Spacer12 />
        {loading ? (
          <LoadingWrapper>
            <Spinner />
          </LoadingWrapper>
        ) : (
          <>
            {referrer && referrer.id !== userId && (
              <ReferredByWrapper>
                <StyledBody2>
                  {intl.formatMessage({
                    id: 'admin.artistApplication.card.referredBy',
                  })}{' '}
                  {`${referrer.firstName} ${referrer.lastName &&
                    referrer.lastName.charAt(0).toUpperCase()}.`}
                </StyledBody2>
              </ReferredByWrapper>
            )}
            {referrer && referrer.id === userId && (
              <StyledReferralLinkWrapper>
                <StyledLink
                  data-qaid="remove-referral-link"
                  onClick={() => {
                    setLoading(true);
                    handleUpdateArtistApplicationReferrer();
                  }}
                >
                  <StyledReferralOverline>
                    <IconWrapper>
                      <DeleteIcon iconSize={14} />
                    </IconWrapper>
                    {intl.formatMessage({
                      id: 'admin.artistApplication.card.referredBy',
                    })}{' '}
                    {`${referrer.firstName} ${referrer.lastName &&
                      referrer.lastName.charAt(0).toUpperCase()}.`}
                  </StyledReferralOverline>
                </StyledLink>
              </StyledReferralLinkWrapper>
            )}
            {!referrer && (
              <StyledReferralLinkWrapper>
                <StyledLink
                  data-qaid="add-referral-link"
                  onClick={() => {
                    setLoading(true);
                    handleUpdateArtistApplicationReferrer();
                  }}
                >
                  <StyledReferralOverline>
                    <IconWrapper>
                      <AddIcon iconSize={14} />
                    </IconWrapper>
                    {intl.formatMessage({
                      id: 'admin.artistApplication.card.addReferral',
                    })}
                  </StyledReferralOverline>
                </StyledLink>
              </StyledReferralLinkWrapper>
            )}
          </>
        )}
        <Spacer12 />
      </>
    );
  };

  const onShowArtistApplicationReviewParams = {
    id,
    artistName,
  };

  const ReviewOrViewButton = () => {
    return (
      <IconButton
        onClick={() =>
          onShowArtistApplicationReview(onShowArtistApplicationReviewParams)
        }
        data-qaid="review-artist-application-button"
      >
        <StyledStreamlineIcon
          icon={MusicNoteIcon}
          size={16}
          stroke={theme.colors.whiteDenim}
        />
        <Spacer8 />
        {status === ArtistApplicationStatus.OPEN
          ? intl.formatMessage({
              id: 'admin.shared.review',
            })
          : intl.formatMessage({
              id: 'shared.view',
            })}
      </IconButton>
    );
  };

  const renderKeyValueInfo = (
    keysAndValuesList: KeyValue[] | undefined,
    dataQaidPrefix: string
  ) => {
    if (keysAndValuesList && keysAndValuesList.length > 0) {
      return (
        <KeyValueInfoListContainer
          data-qaid={`${dataQaidPrefix}-card-key-value-info`}
        >
          <KeyValueInfoList
            keysAndValues={keysAndValuesList}
            valueTruncateCharLength={12}
          />
        </KeyValueInfoListContainer>
      );
    }
    return null;
  };

  const title = artistName;
  const subtitle = homeCityDescription;

  return (
    <CardWrapper
      data-qaid={`admin-${dataQaidPrefix}-card-${index}`}
      height="250px"
      ref={listingCardRef}
    >
      <TopInfoContainer>
        <CardTitle data-qaid={`${dataQaidPrefix}-card-title`}>
          <TruncatedByCharText text={title} truncateLength={16} />
        </CardTitle>
        {subtitle && (
          <CardSubtitle data-qaid={`${dataQaidPrefix}-card-subtitle`}>
            <TruncatedByCharText text={subtitle} truncateLength={22} />
          </CardSubtitle>
        )}
      </TopInfoContainer>
      <Spacer20 />
      {renderKeyValueInfo(keysAndValuesList, dataQaidPrefix)}
      <ReferralButton referrer={currentReferrer} loading={loading} />
      <DottedLine />
      <ReviewOrViewButton />
    </CardWrapper>
  );
};

export default ArtistApplicationCard;
