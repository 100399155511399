import React, { useContext, useEffect } from 'react';
import loadable from '@loadable/component';
import { useIntl } from 'react-intl';

import { getIdsSubmitVariable } from 'app/shared/utils/form';
import { get } from 'app/shared/utils/get';
import useModal from 'app/shared/utils/useModal';
import usePermission from 'app/shared/utils/usePermission';
import { GetCitiesLite } from 'app/shared/graphql/cities/queryHooks';
import { ListPage, ListPageContext } from 'app/shared/context/ListPage';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import { LoadingBlocks } from 'app/shared/components/atoms/LoadingBlocks';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import RoutableModal from 'app/shared/components/molecules/RoutableModal';
import {
  CuratorGroupInfoForLimitingByCuratorGroup,
  fieldsForCuratorGroups,
} from 'app/admin/utils/curatorGroupPermissions';
import {
  filterInfoListFilteredByCheckOnFilterName,
  getCityOptions,
  getFilterNamesAndOptionsForTitleMapping,
} from 'app/admin/utils/optionLists';
import { GetCuratorGroups } from 'app/admin/graphql/curatorGroups/queryHooks';
import { CuratorGroupNames as CuratorGroupNamesLimitedBy } from 'app/admin/components/atoms/CuratorGroupNames';
import CardGrid, { CardType } from 'app/admin/components/organisms/CardGrid';
import CuratorGroupCard from 'app/admin/components/organisms/CuratorGroupCard';
import ListingControls from 'app/admin/components/organisms/ListingControls';
import ListingHeader from 'app/admin/components/organisms/ListingHeader';
import ListPageTemplate from 'app/admin/components/templates/ListPage';
import Layout from 'app/admin/layouts/ListPage';

import { curatorGroupTypeOptions, sortOptions, statusOptions } from './options';

const GlobalError = loadable(
  () => import('app/shared/components/pages/Status'),
  {
    resolveComponent: components => components.GlobalError,
  }
);
const ListingFilter = loadable(() =>
  import('app/admin/components/organisms/ListingFilter')
);
const ListingNoResults = loadable(() =>
  import('app/admin/components/molecules/ListingNoResults')
);
const ListingFooter = loadable(() =>
  import('app/admin/components/molecules/ListingFooter')
);

const pageStateConfig = {
  filterNames: ['city', 'curator_group', 'curator_group_type', 'status'],
  defaultSort: { by: 'name', direction: 'asc' },
  textSearchParamName: 'curator_group_search',
  idParamName: 'curator_group_id',
};

const AdminCuratorGroups: React.FC = () => {
  const intl = useIntl();
  const PER_PAGE = 30;
  const pageState = useContext(ListPageContext);

  const hasCreateCuratorGroupPermission = usePermission(
    'curatorGroup.list.accessBasic'
  );

  const {
    curatorGroupIdsToLimitBy,
  } = CuratorGroupInfoForLimitingByCuratorGroup(
    'curatorGroup.list.viewByCuratorGroup'
  );

  const {
    data: dataCities,
    loading: loadingCities,
    error: errorCities,
  } = GetCitiesLite();

  const {
    data: dataCuratorGroups,
    loading: loadingCuratorGroups,
    error: errorCuratorGroups,
  } = GetCuratorGroups({
    curatorGroupSearch: pageState.textSearchState,
    city: pageState.filterListVariable('city'),
    curatorGroup:
      getIdsSubmitVariable(curatorGroupIdsToLimitBy) ||
      pageState.filterListVariable('curator_group'),
    curatorGroupType: pageState.filterListVariable('curator_group_type'),
    status: pageState.filterListVariable('status'),
    orderBy: pageState.sortState.by,
    orderDirection: pageState.sortState.direction,
    page: pageState.page,
    perPage: PER_PAGE,
  });

  const curatorGroupNamesToLimitBy =
    curatorGroupIdsToLimitBy &&
    fieldsForCuratorGroups(dataCuratorGroups, curatorGroupIdsToLimitBy, 'name');

  const cityOptions = getCityOptions(dataCities);

  const filterDropdownOptionsInfoList = filterInfoListFilteredByCheckOnFilterName(
    [
      {
        filterName: 'city',
        dropdownParams: {
          searchBar: true,
          title: intl.formatMessage({
            id: 'shared.city',
          }),
          // @ts-ignore
          groupBy: 'country',
          groupById: 'country',
          options: cityOptions,
        },
      },
      {
        filterName: 'curator_group_type',
        dropdownParams: {
          searchBar: false,
          title: intl.formatMessage({
            id: 'admin.shared.type',
          }),
          options: curatorGroupTypeOptions,
        },
      },
      {
        filterName: 'status',
        dropdownParams: {
          searchBar: false,
          title: intl.formatMessage({
            id: 'shared.status',
          }),
          options: statusOptions,
        },
      },
    ],
    {
      city: !curatorGroupIdsToLimitBy || curatorGroupIdsToLimitBy.length == 0,
    }
  );

  const [addCuratorGroupModal, toggleAddCuratorGroupModal] = useModal();

  useEffect(() => {
    pageState.updateDetailsModal();
  }, []);

  useEffect(() => {
    pageState.updateScrollPositionOnPage();
  }, [pageState.detailsModal.isShowing]);

  useEffect(() => {
    pageState.setupFilterLabelTitleMapping(
      getFilterNamesAndOptionsForTitleMapping(
        filterDropdownOptionsInfoList,
        pageState.filterState
      )
    );
  }, [dataCities, pageState.filterState]);

  useEffect(() => {
    pageState.updatePageUrl();
  }, [
    pageState.page,
    pageState.sortState,
    pageState.filterState,
    pageState.textSearchState,
    pageState.detailData,
    pageState.detailsModal.isShowing,
    pageState.viewModeState,
    pageState.displayFields,
  ]);

  if (
    (!loadingCuratorGroups && !dataCuratorGroups) ||
    errorCuratorGroups ||
    (!loadingCities && !dataCities) ||
    errorCities
  ) {
    return <GlobalError />;
  }

  const modalsContent = () => (
    <>
      {pageState.detailsModal.isShowing && (
        <RoutableModal
          hide={pageState.detailsModal.hide}
          initialRouteProps={pageState.detailData}
          initialRouteName="curator-group-details"
          dataQaidSuffix="admin-curator-group-details"
        />
      )}
      {addCuratorGroupModal.isShowing && (
        <RoutableModal
          hide={addCuratorGroupModal.hide}
          initialRouteProps={{}}
          initialRouteName="curator-group-create"
          dataQaidSuffix="admin-create-curator-group"
        />
      )}
    </>
  );

  return (
    <Layout scrollDisabled={pageState.detailsModal.isShowing}>
      <ListPageTemplate
        pageTitle={intl.formatMessage({
          id: 'admin.curatorGroups.pageTitle',
        })}
        modalsContent={modalsContent}
      >
        <ListingHeader
          pageTitle={intl.formatMessage({
            id: 'admin.curatorGroups.pageTitle',
          })}
          addEntityText={
            hasCreateCuratorGroupPermission
              ? intl.formatMessage({
                  id: 'admin.curatorGroups.addGroup',
                })
              : undefined
          }
          onClickAddEntity={
            hasCreateCuratorGroupPermission
              ? toggleAddCuratorGroupModal
              : undefined
          }
          searchPlaceholder={intl.formatMessage({
            id: 'admin.curatorGroups.textSearch.placeholder',
          })}
          searchValue={pageState.textSearchState}
          onSearch={pageState.handleTextSearch}
          dataQaidPrefix="curator-groups"
        />
        <CuratorGroupNamesLimitedBy
          curatorGroupNames={curatorGroupNamesToLimitBy}
        />
        <ListingControls
          sortOptions={sortOptions}
          orderBy={pageState.sortState.by}
          orderDirection={pageState.sortState.direction}
          totalRecords={
            dataCuratorGroups &&
            dataCuratorGroups.curatorGroups.metadata.totalRecords
          }
          loading={false}
          onSort={pageState.handleSortChange}
          onReset={pageState.handleResetFilters}
          dataQaidPrefix="curator-groups"
        />
        <ListingFilter
          filterTitle={intl.formatMessage({
            id: 'admin.curatorGroups.filterTitle',
          })}
          textSearchString={pageState.textSearchState}
          handleTextSearchLabelClose={pageState.handleTextSearchLabelClose}
          labelTitleMapping={pageState.filterLabelTitleMapping}
          dropdownOptionsInfoList={filterDropdownOptionsInfoList}
          filterState={pageState.filterState}
          handleRemoveFilter={pageState.handleRemoveFilter}
          handleFilterChange={pageState.handleFilterChange}
        />
        <CardGrid
          objectData={get(dataCuratorGroups, 'curatorGroups.curatorGroups', [])}
          renderCardComponent={(curatorGroup: CardType, i: number) => (
            // @ts-ignore
            <CuratorGroupCard
              index={i}
              onShowDetails={pageState.toggleDetailsModalAndSetDetailData}
              {...curatorGroup}
            />
          )}
          dataQaid="admin-curator-groups-list"
          loading={loadingCuratorGroups}
          loadingComponent={
            <LoadingBlocks.Rectangle width="100%" height="420px" />
          }
          hideDividerOnSize="xs"
        />
        <DottedLine />
        <Spacer mb={2} />
        <ListingNoResults
          entityName={intl.formatMessage({ id: 'admin.shared.curatorGroups' })}
          numResults={get(
            dataCuratorGroups,
            'curatorGroups.curatorGroups.length',
            undefined
          )}
          loading={loadingCuratorGroups}
        />
        <Spacer mt={8} />
        <ListingFooter
          numTotalRecords={get(
            dataCuratorGroups,
            'curatorGroups.metadata.totalRecords',
            undefined
          )}
          perPage={PER_PAGE}
          currentPage={pageState.page}
          onPageChange={pageState.handlePageChange}
          loading={loadingCuratorGroups}
          dataQaidPrefix="curator-groups"
        />
      </ListPageTemplate>
    </Layout>
  );
};

const AdminCuratorGroupsWrapper: React.FC = () => (
  <ListPage config={pageStateConfig}>
    <AdminCuratorGroups />
  </ListPage>
);

export default AdminCuratorGroupsWrapper;
