import React from 'react';
import styled from 'styled-components';

import HelpIconAndFlyover from 'app/shared/components/molecules/HelpIconAndFlyover';
import { DetailHeading } from 'app/admin/components/atoms/DetailContent';

const DetailHeadingWithHelpIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const HelpIconAndFlyoverContainer = styled.div`
  padding-left: 10px;
  padding-bottom: 10px;
`;

interface DetailHeadingWithHelpIconProps {
  title?: string;
  body?: string;
  body2?: string;
  body3?: string;
  bodyId?: string;
  bodyId2?: string;
  bodyId3?: string;
  children: any;
}

const DetailHeadingWithHelpIcon: React.FC<DetailHeadingWithHelpIconProps> = ({
  title,
  body,
  body2,
  body3,
  bodyId,
  bodyId2,
  bodyId3,
  children,
}) => {
  return (
    <DetailHeadingWithHelpIconContainer>
      <DetailHeading>{children}</DetailHeading>
      <HelpIconAndFlyoverContainer>
        <HelpIconAndFlyover
          title={title}
          body={body}
          body2={body2}
          body3={body3}
          bodyId={bodyId}
          bodyId2={bodyId2}
          bodyId3={bodyId3}
        />
      </HelpIconAndFlyoverContainer>
    </DetailHeadingWithHelpIconContainer>
  );
};

export default DetailHeadingWithHelpIcon;
