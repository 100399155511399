import styled, { css } from 'styled-components';

import { LoadingBlocks } from 'app/shared/components/atoms/LoadingBlocks';

interface ThumbnailProps {
  suppressClickable?: boolean;
}

const CircleThumbnail = styled.div<ThumbnailProps>`
  ${props => `
    ${props.theme.media.xs`   
      width: 95px;
      height: 95px;
      border-radius: 50%;
    `};
    ${props.theme.media.sm`
      width: 130px;
      height: 130px;
      border-radius: 75px;
    `};
    ${props.theme.media.xl`
      padding: 0;
    `};
    background-color: ${props.theme.colors.blueSmoke};
    overflow: hidden;
    ${!props.suppressClickable && 'cursor: pointer;'}
  `}
`;

const SquareThumbnail = styled.div<ThumbnailProps>`
  ${props => `
    ${props.theme.media.xs`
      width: 76px;
      height: 76px;
      border-radius: 10px;
    `};
    ${props.theme.media.sm`
      width: 126px;
      height: 126px;
      border-radius: 10px;
      padding: 0;
    `};
    ${props.theme.media.md`
      width: 126px;
      height: 126px;
      border-radius: 10px;
      padding: 0;
    `};
    ${props.theme.media.xl`
      padding: 0;
    `};
    background-color: ${props.theme.colors.blueSmoke};
    overflow: hidden;
    ${!props.suppressClickable && 'cursor: pointer;'}
  `}
`;

const LandscapeThumbnail = styled.div<ThumbnailProps>`
  ${props => `
    ${props.theme.media.xs`
      width: 122px;
      height: 76px;
      border-radius: 10px;
    `};
    ${props.theme.media.sm`
      width: 200px;
      height: 126px;
      border-radius: 10px;
      padding: 0;
    `};
    ${props.theme.media.md`
      width: 200px;
      height: 126px;
      border-radius: 10px;
      padding: 0;
    `};
    ${props.theme.media.xl`
      padding: 0;
    `};
    background-color: ${props.theme.colors.blueSmoke};
    overflow: hidden;
    ${!props.suppressClickable && 'cursor: pointer;'}
  `}
`;

const WideLandscapeThumbnail = styled.div<ThumbnailProps>`
  ${props => `
    ${props.theme.media.xs`
      width: 122px;
      height: 41px;
      border-radius: 10px;
    `};
    ${props.theme.media.sm`
      width: 200px;
      height: 67px;
      border-radius: 10px;
      padding: 0;
    `};
    ${props.theme.media.md`
      width: 200px;
      height: 67px;
      border-radius: 10px;
      padding: 0;
    `};
    ${props.theme.media.xl`
      padding: 0;
    `};
    background-color: ${props.theme.colors.blueSmoke};
    overflow: hidden;
    ${!props.suppressClickable && 'cursor: pointer;'}
  `}
`;

const CircleThumbnailWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    ${theme.media.xs`
      width: 95px;
      height: 95px;
    `};
    ${theme.media.sm`
      width: 130px;
      height: 130px;
    `};
  `}
`;

const SquareThumbnailWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    ${theme.media.xs`
      width: 76px;
      height: 76px;
    `};
    ${theme.media.sm`
      width: 126px;
      height: 126px;
    `};
  `}
`;

const LandscapeThumbnailWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    ${theme.media.xs`
      width: 138px;
      height: 76px;
    `};
    ${theme.media.sm`
      width: 217px;
      height: 126px;
    `};
  `}
`;

const WideLandscapeThumbnailWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    ${theme.media.xs`
      width: 138px;
      height: 76px;
    `};
    ${theme.media.sm`
      width: 217px;
      height: 126px;
    `};
    padding-top: 15px;
  `}
`;

const CalendarThumbnailWrapper = styled.div`
  position: relative;
`;

export const imageThumbnailStyles = {
  circle: {
    component: CircleThumbnail,
    wrapper: CircleThumbnailWrapper,
    aspectRatio: '1:1',
    colSizes: { xs: 4, sm: 4, md: 2, lg: 2, xl: 3 },
    loadingComponent: LoadingBlocks.Circle,
  },
  square: {
    component: SquareThumbnail,
    wrapper: SquareThumbnailWrapper,
    aspectRatio: '1:1',
    colSizes: { xs: 4, sm: 4, md: 2, lg: 2, xl: 3 },
    loadingComponent: LoadingBlocks.Rectangle,
  },
  landscape: {
    component: LandscapeThumbnail,
    wrapper: LandscapeThumbnailWrapper,
    aspectRatio: '1.6:1',
    colSizes: { xs: 7, sm: 5, md: 4, lg: 3, xl: 4 },
    loadingComponent: LoadingBlocks.Rectangle,
  },
  wideLandscape: {
    component: WideLandscapeThumbnail,
    wrapper: WideLandscapeThumbnailWrapper,
    aspectRatio: '3:1',
    colSizes: { xs: 7, sm: 5, md: 4, lg: 3, xl: 4 },
    loadingComponent: LoadingBlocks.Rectangle,
  },
  calendar: {
    component: LandscapeThumbnail,
    wrapper: CalendarThumbnailWrapper,
    aspectRatio: '1.6:1',
    colSizes: { xs: 5, sm: 3, md: 3, lg: 4, xl: 3 },
    loadingComponent: LoadingBlocks.Rectangle,
  },
};
