import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Artist } from 'app/typings/artists';
import { Event } from 'app/typings/events';
import { ArtistOfferEvent, ArtistOfferEvents, Offer } from 'app/typings/offers';
import { useCurrentTheme } from 'app/shared/theme';
import { dateFormatter } from 'app/shared/utils/datetime';
import usePermission from 'app/shared/utils/usePermission';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import TruncatedByCharText from 'app/shared/components/atoms/TruncatedByCharText';
import { Overline } from 'app/shared/components/atoms/TypographyManualCSS';
import IconAndTextLink from 'app/shared/components/molecules/IconAndTextLink';
import { None } from 'app/admin/components/atoms/None';
import ArtistCircleThumbnailAndText from 'app/admin/components/molecules/ArtistCircleThumbnailAndText';
import { TagElement } from 'app/admin/components/molecules/TagElementList';
import { ReactComponent as ConcertDj } from 'icons/streamline-regular/entertainment-events-hobbies/concerts-night-clubs/concert-dj.svg';

interface Props {
  sectionData: any;
}

const MainContainer = styled.div`
  width: 100%;
`;

const EventPlannerLink = styled.div`
  padding-bottom: 20px;
`;

const SubSectionLabel = styled.div`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  font-weight: 600;
  padding-bottom: 20px;
`;

const ArtistEventContainer = styled.div`
  padding-left: 53px;
  padding-bottom: 20px;
`;

const ArtistEventContainerTop = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding-bottom: 2px;
`;

const TagElementWrapper = styled.div`
  padding: 6px 6px 0px 0px;
  width: 73px;
`;

const ArtistEventNeighborhood = styled.div``;

const ArtistEventDate = styled.div`
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.1px;
`;

const getNoNeighborhoodText = (intl: any) =>
  intl.formatMessage({
    id: 'admin.offerCreate.noNeighborhoodYet',
  });

const getNeighborhoodText = (event: Event, intl: any) =>
  event.venue?.neighborhood?.title ||
  event.neighborhood?.title ||
  getNoNeighborhoodText(intl);

const eventFromEventId = (eventId: number, events?: Event[]) =>
  events && events.find((event: Event) => Number(event.id) == eventId);

const artistOfferEventsBookedForArtistId = (artistId: number, offer: Offer) =>
  offer.artistsAndEventsBooked &&
  offer.artistsAndEventsBooked.find(
    (artistOfferEvents: ArtistOfferEvents) =>
      artistOfferEvents.artistId == artistId
  );

const artistOfferEventsNotBookedForArtistId = (
  artistId: number,
  offer: Offer
) =>
  offer.artistsAndEventsNotBooked &&
  offer.artistsAndEventsNotBooked.find(
    (artistOfferEvents: ArtistOfferEvents) =>
      artistOfferEvents.artistId == artistId
  );

const getArtistsAndEventsBooked = (offer: Offer) => {
  if (offer.artists) {
    const artistsAndEventsBooked = [];
    for (const artist of offer.artists) {
      const artistOfferEvents = artistOfferEventsBookedForArtistId(
        artist.id,
        offer
      );
      if (artistOfferEvents) {
        const artistEvents = artistOfferEvents.artistEvents.map(
          (artistOfferEvent: ArtistOfferEvent) => ({
            event: eventFromEventId(artistOfferEvent.eventId, offer.events),
            isBooked: artistOfferEvent.isBooked,
          })
        );
        artistsAndEventsBooked.push({
          artist,
          artistEvents,
        });
      }
    }
    return artistsAndEventsBooked;
  } else {
    return [];
  }
};

const getArtistsAndEventsNotBooked = (offer: Offer) => {
  if (offer.artists) {
    const artistsAndEventsNotBooked = [];
    for (const artist of offer.artists) {
      const artistOfferEvents = artistOfferEventsNotBookedForArtistId(
        artist.id,
        offer
      );
      if (artistOfferEvents) {
        const artistEvents = artistOfferEvents.artistEvents.map(
          (artistOfferEvent: ArtistOfferEvent) => ({
            event: eventFromEventId(artistOfferEvent.eventId, offer.events),
          })
        );
        artistsAndEventsNotBooked.push({
          artist,
          artistEvents,
        });
      }
    }
    return artistsAndEventsNotBooked;
  } else {
    return [];
  }
};

const getArtistsOpen = (offer: Offer) => {
  if (offer.artists) {
    return offer.artists.filter(
      (artist: Artist) =>
        offer.artistIdsOpen && offer.artistIdsOpen.includes(artist.id)
    );
  } else {
    return [];
  }
};

const getArtistsDeclined = (offer: Offer) => {
  if (offer.artists) {
    return offer.artists.filter(
      (artist: Artist) =>
        offer.artistIdsDeclined && offer.artistIdsDeclined.includes(artist.id)
    );
  } else {
    return [];
  }
};

// Incoming date is already in local time, so don't convert it to a different time zone -
// we need to trick dateFormatter by telling it timezone is UTC
const dateFormat = () => 'shortWeekdayShortMonthDateAndYear';

const BookedTag: React.FC = () => {
  const intl = useIntl();
  const theme = useCurrentTheme();

  return (
    <TagElementWrapper>
      <TagElement
        tagElementColor={theme.colors.blueChristmas}
        textColor="#ffffff"
      >
        {intl.formatMessage({
          id: 'admin.offerDetails.artistAvailability.bookedForTag',
        })}
      </TagElement>
    </TagElementWrapper>
  );
};

interface ArtistEventProps {
  artistEventInfo: any;
}

const ArtistEvent: React.FC<ArtistEventProps> = ({ artistEventInfo }) => {
  const intl = useIntl();

  return artistEventInfo.event ? (
    <ArtistEventContainer>
      <ArtistEventContainerTop>
        {artistEventInfo.isBooked && <BookedTag />}
        <ArtistEventNeighborhood>
          <Overline>
            <TruncatedByCharText
              text={getNeighborhoodText(artistEventInfo.event, intl)}
              truncateLength={20}
            />
          </Overline>
        </ArtistEventNeighborhood>
      </ArtistEventContainerTop>
      <ArtistEventDate>
        {dateFormatter(artistEventInfo.event.localStartsAt, dateFormat())}
      </ArtistEventDate>
    </ArtistEventContainer>
  ) : null;
};

const SectionArtistAvailability: React.FC<Props> = ({ sectionData }) => {
  const intl = useIntl();

  const hasViewEventsPermission = usePermission('event.list.view');

  const { offer } = sectionData;

  const artistsAndEventsBooked = getArtistsAndEventsBooked(offer);
  const artistsAndEventsNotBooked = getArtistsAndEventsNotBooked(offer);
  const artistsOpen = getArtistsOpen(offer);
  const artistsDeclined = getArtistsDeclined(offer);

  return (
    <MainContainer data-qaid="section-artist-availability">
      {hasViewEventsPermission && (
        <EventPlannerLink>
          <IconAndTextLink
            icon={ConcertDj}
            text={intl.formatMessage({
              id: 'admin.offerDetails.artistAvailability.viewThisOffer',
            })}
            url={`/admin/concert-planner?offer_id=${offer.id}`}
            dataQaid="concert-planner-link"
          />
        </EventPlannerLink>
      )}
      <Grid cols={4}>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={1}>
          <SubSectionLabel>
            {intl.formatMessage({
              id: 'admin.offerDetails.artistAvailability.booked',
            })}
          </SubSectionLabel>
          {artistsAndEventsBooked.length ? (
            artistsAndEventsBooked.map((artistEventsInfo: any, i: number) => (
              <React.Fragment key={i}>
                <ArtistCircleThumbnailAndText
                  artist={artistEventsInfo.artist}
                />
                <Spacer mt={2} />
                {artistEventsInfo.artistEvents.map(
                  (artistEventInfo: any, j: number) => (
                    <ArtistEvent artistEventInfo={artistEventInfo} key={j} />
                  )
                )}
                <Spacer mt={2} />
              </React.Fragment>
            ))
          ) : (
            <None />
          )}
        </Col>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={2}>
          <SubSectionLabel>
            {intl.formatMessage({
              id: 'admin.offerDetails.artistAvailability.notBooked',
            })}
          </SubSectionLabel>
          {artistsAndEventsNotBooked.length ? (
            artistsAndEventsNotBooked.map(
              (artistEventsInfo: any, i: number) => (
                <React.Fragment key={i}>
                  <ArtistCircleThumbnailAndText
                    artist={artistEventsInfo.artist}
                  />
                  <Spacer
                    mt={artistEventsInfo.artistEvents.length == 0 ? 4 : 2}
                  />
                  {artistEventsInfo.artistEvents.map(
                    (artistEventInfo: any, j: number) => (
                      <ArtistEvent artistEventInfo={artistEventInfo} key={j} />
                    )
                  )}
                  <Spacer mt={2} />
                </React.Fragment>
              )
            )
          ) : (
            <None />
          )}
        </Col>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={3}>
          <SubSectionLabel>
            {intl.formatMessage({
              id: 'admin.offerDetails.artistAvailability.noResponse',
            })}
          </SubSectionLabel>
          {artistsOpen.length ? (
            artistsOpen.map((artist: Artist, i: number) => (
              <React.Fragment key={i}>
                <ArtistCircleThumbnailAndText artist={artist} />
                <Spacer mt={4} />
              </React.Fragment>
            ))
          ) : (
            <None />
          )}
        </Col>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={4}>
          <SubSectionLabel>
            {intl.formatMessage({
              id: 'admin.offerDetails.artistAvailability.unavailable',
            })}
          </SubSectionLabel>
          {artistsDeclined.length ? (
            artistsDeclined.map((artist: Artist, i: number) => (
              <React.Fragment key={i}>
                <ArtistCircleThumbnailAndText artist={artist} />
                <Spacer mt={4} />
              </React.Fragment>
            ))
          ) : (
            <None />
          )}
        </Col>
      </Grid>
    </MainContainer>
  );
};

export default SectionArtistAvailability;
