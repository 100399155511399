import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { User } from 'app/typings/users';
import { currencyFormatter } from 'app/shared/utils/currencyFormatter';
import usePermission from 'app/shared/utils/usePermission';
import { NoIcon, YesIcon } from 'app/shared/components/atoms/IconLibrary';
import TruncatedByCharText from 'app/shared/components/atoms/TruncatedByCharText';
import { EventLink } from 'app/admin/components/molecules/EntityLink';
import ListTable from 'app/admin/components/organisms/ListTable';

interface Props {
  sectionData: User;
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const TotalsRowCell = styled.span`
  font-weight: 600;
`;

const NoEvents = styled.div`
  white-space: nowrap;
  width: 100%;
`;

const renderBooleanValue = (isTrue: boolean) =>
  isTrue ? <YesIcon /> : <NoIcon />;

const SectionEvents: React.FC<Props> = ({ sectionData }) => {
  const intl = useIntl();

  const hasViewEventRevenueRolePermission = usePermission('event.revenue.view');

  let eventsAttendedData = Array<any>();
  eventsAttendedData = eventsAttendedData.concat(
    sectionData &&
      sectionData.eventsAttendedInfo &&
      sectionData.eventsAttendedInfo.eventsAttended.length > 0
      ? sectionData.eventsAttendedInfo.eventsAttended
      : []
  );
  if (eventsAttendedData.length > 0) {
    eventsAttendedData.push(
      sectionData &&
        sectionData.eventsAttendedInfo &&
        sectionData.eventsAttendedInfo.eventsAttendedSummaryInfo
    );
  }

  const columnsConfig = [
    {
      headerText: intl.formatMessage({
        id: 'shared.date',
      }),
      columnWidth: 140,
    },
    {
      headerText: intl.formatMessage({
        id: 'shared.city',
      }),
      columnWidth: 100,
    },
    {
      headerText: intl.formatMessage({
        id: 'admin.userEvents.header.invited',
      }),
      columnWidth: 95,
      isCentered: true,
    },
    {
      headerText: intl.formatMessage({
        id: 'admin.userEvents.header.confirmed',
      }),
      columnWidth: 70,
      isCentered: true,
    },
    {
      headerText: intl.formatMessage({
        id: 'admin.userEvents.header.cantGo',
      }),
      columnWidth: 80,
      isCentered: true,
    },
    {
      headerText: intl.formatMessage({
        id: 'admin.userEvents.header.paid',
      }),
      columnWidth: 40,
      isCentered: true,
    },
    {
      headerText: intl.formatMessage({
        id: 'admin.userEvents.header.noShow',
      }),
      columnWidth: 90,
      isCentered: true,
    },
    {
      headerText: intl.formatMessage({
        id: 'admin.userEvents.header.externalConfirmationId',
      }),
      columnWidth: 130,
      isCentered: true,
    },
    {
      headerText: intl.formatMessage({
        id: 'admin.userEvents.header.ticketsPurchased',
      }),
      columnWidth: 140,
      isCentered: true,
    },
  ];

  if (hasViewEventRevenueRolePermission) {
    columnsConfig.push({
      headerText: intl.formatMessage({
        id: 'admin.userEvents.header.paymentAmount',
      }),
      columnWidth: 150,
      isCentered: true,
    });
  }

  const rowValues = (eventAttended: any) => {
    let values;
    if (eventAttended.numGuestlisted !== undefined) {
      values = [
        <TotalsRowCell key={1}>
          {intl.formatMessage({
            id: 'admin.userEvents.totals',
          })}
        </TotalsRowCell>,
        '',
        eventAttended.numGuestlisted,
        eventAttended.numCanGo,
        eventAttended.numCantGo,
        eventAttended.numPaid,
        eventAttended.numNoShow,
        eventAttended.externalConfirmationId,
        eventAttended.numTicketsPurchased,
      ];
      if (hasViewEventRevenueRolePermission) {
        values.push('');
      }
    } else {
      values = [
        <EventLink
          // @ts-ignore
          event={{
            id: eventAttended.eventId,
            localStartsAt: eventAttended.eventStartsAt,
          }}
          key={1}
        />,
        <TruncatedByCharText
          text={eventAttended.city.title}
          truncateLength={15}
          key={eventAttended.id}
        />,
        renderBooleanValue(eventAttended.isGuestlisted),
        renderBooleanValue(eventAttended.canGo),
        renderBooleanValue(eventAttended.cantGo),
        renderBooleanValue(eventAttended.isPaid),
        renderBooleanValue(eventAttended.noShow),
        eventAttended.externalConfirmationId,
        eventAttended.numTicketsPurchased,
      ];
      if (hasViewEventRevenueRolePermission) {
        values.push(
          currencyFormatter(
            eventAttended.paymentAmount || 0,
            eventAttended.paymentAmountCurrencySymbol
          )
        );
      }
    }
    return values;
  };

  return (
    <MainContainer data-qaid="section-events">
      <ListTable
        columnsConfig={columnsConfig}
        rowValues={rowValues}
        data={eventsAttendedData}
        noResultsContent={
          eventsAttendedData.length == 0 && (
            <NoEvents>
              {intl.formatMessage({
                id: 'admin.userEvents.hasNotAttendedShows',
              })}
            </NoEvents>
          )
        }
      />
    </MainContainer>
  );
};

export default SectionEvents;
