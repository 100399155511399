import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { User } from 'app/typings';
import { buildFullName } from 'app/shared/utils/string';
import { Spacer } from 'app/shared/components/atoms/Spacer';

import AudienceReaction from './AudienceReaction';
import CrewEventSurveyCard, {
  CrewEventSurveyCardTitle,
} from './CrewEventSurveyCard';

interface Props {
  emcee?: User;
  onChange: (value: string) => void;
  selectedValue: string;
}

const NameWrapper = styled.div`
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.4px;
`;

const AudienceReactionMC: React.FC<Props> = ({
  emcee,
  onChange,
  selectedValue,
}) => {
  const intl = useIntl();
  const emceeName =
    buildFullName(emcee?.firstName, emcee?.lastName) || emcee?.email;

  return (
    <CrewEventSurveyCard>
      <CrewEventSurveyCardTitle>
        {intl.formatMessage({ id: 'admin.crewPortal.crewFeedback.toMC' })}
      </CrewEventSurveyCardTitle>
      <NameWrapper>{emceeName}</NameWrapper>
      <Spacer mt={4} />
      <AudienceReaction
        idNamePrefix="mc"
        onChange={onChange}
        selectedValue={selectedValue}
      />
    </CrewEventSurveyCard>
  );
};

export default AudienceReactionMC;
