import React from 'react';
import { FormattedHTMLMessage, useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { User } from 'app/typings/users';
import { get } from 'app/shared/utils/get';
import { ContentWrapper } from 'app/shared/components/atoms/FlyoverContent';
import { MenuItem } from 'app/shared/components/atoms/Menu';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Body2 } from 'app/shared/components/atoms/TypographyManualCSS';
import { SelectState } from 'app/shared/components/molecules/SelectManualCSS';
import Select from 'app/shared/components/molecules/SelectManualCSS';
import { getEventStaffAvailablePermission } from 'app/admin/utils/crew';
import { GetUsersForAddStaffToEvent } from 'app/admin/graphql/users/queryHooks';
import FlyoverFooter from 'app/admin/components/molecules/FlyoverFooter';

interface SwapShiftWithAvailableCrewOptionProps {
  eventId: number;
  roleKey: string;
  citySlug: string;
  localStartsAt: string;
  updateFlyoverState: Function;
  setStaffMemberUser: Function;
  staffMemberUser?: User;
}

const InputWrapper = styled.div`
  display: flex;
`;

const InlineSpacer = styled(Spacer)`
  display: inline;
`;

const SwapRoleMessage = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  padding: 16px 0px;
`;

const StyledText = styled.div`
  ${({ theme }) => css`
    font-size: 14px;
    font-weight: normal;
    color: ${theme.colors.green600};
    padding: 16px 0px;
  `}
`;

const dateStrFromDateTimeStr = (dateTimeStr?: string) =>
  // Given e.g. '2022-11-04T20:00:00Z'
  // Return e.g. '2022-11-04'
  dateTimeStr?.split('T')[0];

const SwapShiftWithAvailableCrewOption: React.FC<SwapShiftWithAvailableCrewOptionProps> = ({
  eventId,
  roleKey,
  citySlug,
  localStartsAt,
  updateFlyoverState,
  staffMemberUser,
  setStaffMemberUser,
}) => {
  const intl = useIntl();
  const {
    data: usersData,
    loading: usersLoading,
    error: usersError,
  } = GetUsersForAddStaffToEvent({
    eventRole: roleKey,
    permission: getEventStaffAvailablePermission(roleKey) || '',
    permissionCity: citySlug,
    availableOnDate: dateStrFromDateTimeStr(localStartsAt),
    validEventStaffForEventId: Number(eventId),
    skipPagination: true,
  });

  return (
    <>
      <ContentWrapper>
        <StyledText>
          <FormattedHTMLMessage id="admin.crewPortal.crewRole.selectFromAvailable" />
        </StyledText>
        <InputWrapper>
          <Select<User>
            options={get(usersData, 'users.users', undefined) || []}
            placeholder={intl.formatMessage({
              id: 'admin.crewPortal.crewRole.selectStaffMember',
            })}
            value={staffMemberUser}
            onChange={opt => {
              setStaffMemberUser(opt);
            }}
            renderOption={(opt, props) => (
              <MenuItem
                key={opt.id}
                {...props}
                className="suppress-outside-click"
              >
                <Body2 as="span" className="suppress-outside-click">
                  {`${opt.firstName} ${opt.lastName}`}
                </Body2>
              </MenuItem>
            )}
            getOptionLabel={opt => `${opt.firstName} ${opt.lastName}`}
            state={
              usersLoading
                ? SelectState.loading
                : usersError
                ? SelectState.error
                : SelectState.ready
            }
          />
          <InlineSpacer mr={2} />
        </InputWrapper>
        <SwapRoleMessage>
          <FormattedHTMLMessage id="admin.crewPortal.crewRole.swapRoleMessage" />
        </SwapRoleMessage>
      </ContentWrapper>
      <FlyoverFooter
        buttonText={intl.formatMessage({
          id: 'shared.update',
        })}
        buttonDisabled={!staffMemberUser}
        onClickButton={() => {
          updateFlyoverState('swapShiftWithAvailableCrewConfirmation');
        }}
        dataQaid="swap-role-submit-button"
      />
    </>
  );
};

export default SwapShiftWithAvailableCrewOption;
