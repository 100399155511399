import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { truncateByWord } from 'app/shared/utils/string';
import { None } from 'app/admin/components/atoms/None';
import ToggleText from 'app/admin/components/atoms/ToggleText';

interface Props {
  allowHTML?: boolean;
  text?: string | null;
  truncateLength: number;
}

const MainContainer = styled.div`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.4px;
  margin-bottom: 25px;
`;

const ToggleContainer = styled.div`
  margin-top: 8px;
`;

const ExpandableText: React.FC<Props> = ({
  allowHTML,
  text,
  truncateLength,
}) => {
  const intl = useIntl();

  const [showFullText, setShowFullText] = useState(false);

  return (
    <MainContainer data-qaid="expandable-text-main-container">
      {text ? (
        <>
          {allowHTML && (
            <>
              {showFullText ? (
                <div dangerouslySetInnerHTML={{ __html: text }}></div>
              ) : (
                <div
                  dangerouslySetInnerHTML={{
                    __html: truncateByWord(text, truncateLength, '…'),
                  }}
                ></div>
              )}
            </>
          )}
          {!allowHTML && (
            <>
              {showFullText ? text : truncateByWord(text, truncateLength, '…')}
            </>
          )}
        </>
      ) : (
        <None />
      )}
      {text && text.length > truncateLength && (
        <ToggleContainer data-qaid="expandable-text-toggle-container">
          <ToggleText onClick={() => setShowFullText(!showFullText)}>
            {showFullText
              ? intl.formatMessage({
                  id: 'less',
                })
              : intl.formatMessage({
                  id: 'more',
                })}
          </ToggleText>
        </ToggleContainer>
      )}
    </MainContainer>
  );
};

export default ExpandableText;
