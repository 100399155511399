import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { EventAttendee } from 'app/typings/eventAttendees';
import { Event } from 'app/typings/events';
import { get } from 'app/shared/utils/get';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import {
  FlyoverLoadingIndicator,
  FlyoverNote,
} from 'app/shared/components/atoms/FlyoverContent';
import { Overline } from 'app/shared/components/atoms/TypographyManualCSS';
import RadioGroup from 'app/shared/components/molecules/RadioGroup';
import {
  eventAttendeeFullName,
  eventAttendeeStatuses,
  getStatus,
} from 'app/admin/utils/guestlist';
import { UpdateEventAttendeeState } from 'app/admin/graphql/eventAttendees/mutationHooks';
import { GetEventAttendeeForAdmin } from 'app/admin/graphql/eventAttendees/queryHooks';
import {
  LoadingError,
  LoadingIndicator,
} from 'app/admin/components/atoms/GuestlistLoading';
import FlyoverFooter from 'app/admin/components/molecules/FlyoverFooter';
import { GuestlistAttendeeDetailsHeader } from 'app/admin/components/molecules/GuestlistAttendeeDetailsHeader';

interface Props {
  eventAttendeeId?: number;
  event?: Event;
  onSuccess: Function;
}

const ContentWrapper = styled.div`
  padding: 8px 16px 24px 16px;
  margin-bottom: 10px;
`;

const isStatusOptionDisabled = (key: string, eventAttendee: EventAttendee) => {
  if (key == 'applied' && !['applied'].includes(eventAttendee.currentState)) {
    return true;
  }
  if (
    key == 'guestlisted' &&
    !['applied', 'guestlisted', 'expired'].includes(eventAttendee.currentState)
  ) {
    return true;
  }
  if (key == 'expired' && !['expired'].includes(eventAttendee.currentState)) {
    return true;
  }

  return false;
};

const statusOptions = (eventAttendee: EventAttendee, event?: Event) => {
  let statusKeys = Object.keys(eventAttendeeStatuses);
  if (event && event.attendeeFlow == 'buy' && !event.competition) {
    statusKeys = statusKeys.filter(
      (s: string) => !['applied', 'guestlisted'].includes(s)
    );
  }
  if (event && !event.competition) {
    statusKeys = statusKeys.filter((s: string) => !['expired'].includes(s));
  }
  return statusKeys.map((key: string) => ({
    title: eventAttendeeStatuses[key],
    value: key,
    disabled: isStatusOptionDisabled(key, eventAttendee),
  }));
};

const newStatusWillTriggerEmail = (newStatus: string) =>
  ['guestlisted', 'confirmed', 'reserved'].includes(newStatus);

const GuestlistAttendeeStatus: React.FC<Props> = ({
  eventAttendeeId,
  event,
  onSuccess,
}) => {
  const intl = useIntl();
  const [actionState, setActionState] = useState('selectStatus');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [statusValue, setStatusValue] = useState('');

  const { data, loading, error } = GetEventAttendeeForAdmin({
    id: eventAttendeeId,
  });

  const eventAttendee = get(data, 'eventAttendee', undefined);

  const currentState = eventAttendee
    ? getStatus(eventAttendee.currentState)
    : '';

  const fullName = eventAttendeeFullName(eventAttendee, intl);

  const updateEventAttendeeStateAction = UpdateEventAttendeeState();

  const handleUpdateEventAttendeeState = useSubmitAction({
    submitAction: updateEventAttendeeStateAction,
    submitVariables: () => ({
      eventAttendeeId,
      state: statusValue,
    }),
    successMsg: intl.formatMessage(
      {
        id: 'admin.guestlist.attendeeStatus.successMessage',
      },
      {
        name: fullName,
        status: eventAttendeeStatuses[statusValue],
      }
    ),
    failureMsg: intl.formatMessage(
      {
        id: 'admin.guestlist.attendeeStatus.failureMessage',
      },
      {
        name: fullName,
        status: eventAttendeeStatuses[statusValue],
      }
    ),
    onSuccess: () => {
      setIsSubmitting(false);
      onSuccess();
    },
  });

  useEffect(() => {
    if (eventAttendee) {
      setStatusValue(currentState);
    }
  }, [currentState, eventAttendee]);

  if (loading) {
    return <LoadingIndicator />;
  }

  if ((!loading && !data) || error) {
    return <LoadingError />;
  }

  const SelectStatus = () => {
    const options = statusOptions(eventAttendee, event);

    return (
      <>
        <ContentWrapper>
          <Overline>
            {intl.formatMessage({
              id: 'admin.guestlist.attendeeStatus.setGuestStatus',
            })}
          </Overline>
          <RadioGroup
            name="status"
            options={options}
            selectedValue={statusValue}
            onChange={value => setStatusValue(value)}
          />
        </ContentWrapper>
        <FlyoverFooter
          buttonText={intl.formatMessage({
            id: 'admin.guestlist.next',
          })}
          buttonDisabled={statusValue === currentState}
          onClickButton={() => {
            setActionState('confirmStatus');
          }}
        />
      </>
    );
  };

  const ConfirmStatus = () => {
    return (
      <>
        <ContentWrapper>
          <FlyoverNote>
            {newStatusWillTriggerEmail(statusValue)
              ? intl.formatMessage(
                  {
                    id: 'admin.guestlist.attendeeStatus.confirmationWithEmail',
                  },
                  {
                    status: eventAttendeeStatuses[statusValue],
                  }
                )
              : intl.formatMessage(
                  {
                    id: 'admin.guestlist.attendeeStatus.confirmation',
                  },
                  {
                    status: eventAttendeeStatuses[statusValue],
                  }
                )}
          </FlyoverNote>
          {event && event.isSoldOut && statusValue == 'confirmed' && (
            <FlyoverNote>
              {intl.formatMessage({
                id:
                  'admin.guestlist.attendeeStatus.confirmationOversoldWarning',
              })}
            </FlyoverNote>
          )}
        </ContentWrapper>
        <FlyoverFooter
          buttonText={intl.formatMessage({
            id: 'admin.guestlist.update',
          })}
          onClickButton={() => {
            setIsSubmitting(true);
            handleUpdateEventAttendeeState();
          }}
        />
      </>
    );
  };

  const CardContents = () => {
    if (isSubmitting) {
      return <FlyoverLoadingIndicator />;
    } else {
      switch (actionState) {
        case 'selectStatus':
          return <SelectStatus />;
        case 'confirmStatus':
          return <ConfirmStatus />;
        default:
          return <SelectStatus />;
      }
    }
  };

  return (
    <div data-qaid="guestlist-attendee-status">
      <GuestlistAttendeeDetailsHeader
        eventAttendee={eventAttendee}
        intl={intl}
      />
      <CardContents />
    </div>
  );
};

export default GuestlistAttendeeStatus;
