import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { get } from 'app/shared/utils/get';
import useModal from 'app/shared/utils/useModal';
import { ListPage, ListPageContext } from 'app/shared/context/ListPage';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import { LoadingBlocks } from 'app/shared/components/atoms/LoadingBlocks';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import RoutableModal from 'app/shared/components/molecules/RoutableModal';
import { GlobalError } from 'app/shared/components/pages/Status';
import { CuratorGroupInfoForLimitingByCuratorGroup } from 'app/admin/utils/curatorGroupPermissions';
import { emailMessageStatusLabels } from 'app/admin/utils/emails';
import {
  getEmailMessageStatusOptions,
  getEmailTypeOptions,
  getFilterNamesAndOptionsForTitleMapping,
} from 'app/admin/utils/optionLists';
import { GetEmailMessages } from 'app/admin/graphql/emailMessages/queryHooks';
import { GetEmailTypes } from 'app/admin/graphql/emailTypes/queryHooks';
import ListingFooter from 'app/admin/components/molecules/ListingFooter';
import ListingNoResults from 'app/admin/components/molecules/ListingNoResults';
import CardGrid, { CardType } from 'app/admin/components/organisms/CardGrid';
import EmailMessageCard from 'app/admin/components/organisms/EmailMessageCard';
import ListingControls from 'app/admin/components/organisms/ListingControls';
import ListingFilter from 'app/admin/components/organisms/ListingFilter';
import ListingHeader from 'app/admin/components/organisms/ListingHeader';
import ListPageTemplate from 'app/admin/components/templates/ListPage';
import Layout from 'app/admin/layouts/ListPage';

import { dateOptions, sortOptions } from './options';
import Analytics from './tracking';

const pageStateConfig = {
  filterNames: ['email_key', 'date', 'status', 'event_id'],
  defaultSort: { by: 'created_at', direction: 'desc' },
  textSearchParamName: 'email_message_search',
};

const AdminEmailMessages: React.FC = () => {
  const intl = useIntl();
  const pageState = useContext(ListPageContext);
  const PER_PAGE = 18;

  const {
    curatorGroupIdsToLimitBy,
  } = CuratorGroupInfoForLimitingByCuratorGroup(
    'emailMessage.accessByCuratorGroup'
  );

  const {
    loading: loadingEmailTypes,
    error: errorEmailTypes,
    data: dataEmailTypes,
  } = GetEmailTypes({
    includeOnlyEventEmails: !!(
      curatorGroupIdsToLimitBy && curatorGroupIdsToLimitBy.length > 0
    ),
  });

  const emailOptions = getEmailTypeOptions(dataEmailTypes);

  const statusOptions = getEmailMessageStatusOptions(emailMessageStatusLabels);

  const filterDropdownOptionsInfoList = [
    {
      filterName: 'email_key',
      dropdownParams: {
        searchBar: true,
        title: intl.formatMessage({
          id: 'admin.emailMessages.filter.emailKey',
        }),
        options: emailOptions,
      },
    },
    {
      filterName: 'date',
      dropdownParams: {
        title: intl.formatMessage({
          id: 'shared.date',
        }),
        options: dateOptions.date,
      },
    },
    {
      filterName: 'status',
      dropdownParams: {
        title: intl.formatMessage({
          id: 'shared.status',
        }),
        options: statusOptions,
      },
    },
  ];

  const [viewEmailModal, toggleViewEmailModal] = useModal();
  const [emailMessageData, setEmailMessageData] = useState<any>({});

  const currentQuery = new URLSearchParams(window.location.search);
  const eventId = currentQuery.get('event_id') || undefined;
  const emailKeys = emailOptions.map((o: any) => o.value);

  const {
    loading,
    error,
    data,
    refetch: refetchEmailMessages,
  } = GetEmailMessages({
    emailMessageSearch: pageState.textSearchState,
    emailKey:
      pageState.filterListVariable('email_key') ||
      (curatorGroupIdsToLimitBy && curatorGroupIdsToLimitBy.length > 0
        ? emailKeys.join(',')
        : undefined),
    status: pageState.filterListVariable('status'),
    date: pageState.filterListVariable('date'),
    eventId,
    curatorGroup:
      curatorGroupIdsToLimitBy && curatorGroupIdsToLimitBy.length > 0
        ? curatorGroupIdsToLimitBy.join(',')
        : undefined,
    orderBy: pageState.sortState.by,
    orderDirection: pageState.sortState.direction,
    page: pageState.page,
    perPage: PER_PAGE,
  });

  useEffect(() => {
    pageState.setupFilterLabelTitleMapping(
      getFilterNamesAndOptionsForTitleMapping(filterDropdownOptionsInfoList)
    );
  }, [dataEmailTypes]);

  useEffect(() => {
    pageState.updatePageUrl();
  }, [
    pageState.page,
    pageState.sortState,
    pageState.filterState,
    pageState.textSearchState,
    pageState.detailData,
    pageState.detailsModal.isShowing,
  ]);

  useEffect(() => {
    Analytics.pushDataLayer();
  }, [pageState.filterState, pageState.textSearchState]);

  if (
    (!loading && !data) ||
    error ||
    (!loadingEmailTypes && !dataEmailTypes) ||
    errorEmailTypes
  ) {
    return <GlobalError />;
  }

  const modalsContent = () => (
    <>
      {viewEmailModal.isShowing && (
        <RoutableModal
          hide={viewEmailModal.hide}
          initialRouteProps={emailMessageData}
          initialRouteName="email-message-content"
          dataQaidSuffix="admin-edit-email-message-content"
        />
      )}
    </>
  );

  return (
    <Layout scrollDisabled={pageState.detailsModal.isShowing}>
      <ListPageTemplate
        pageTitle={intl.formatMessage({
          id: 'admin.emailMessages.pageTitle',
        })}
        modalsContent={modalsContent}
      >
        <ListingHeader
          pageTitle={intl.formatMessage({
            id: 'admin.emailMessages.pageTitle',
          })}
          searchPlaceholder={intl.formatMessage({
            id: 'admin.emailMessages.textSearch.placeholder',
          })}
          searchValue={pageState.textSearchState}
          onSearch={pageState.handleTextSearch}
          dataQaidPrefix="email_message"
        />
        <div>
          <ListingControls
            sortOptions={sortOptions}
            orderBy={pageState.sortState.by}
            orderDirection={pageState.sortState.direction}
            totalRecords={data && data.emailMessages.metadata.totalRecords}
            loading={loading}
            onSort={pageState.handleSortChange}
            onReset={pageState.handleResetFilters}
            dataQaidPrefix="user"
          />
          <ListingFilter
            filterTitle={intl.formatMessage({
              id: 'admin.emailMessages.filterTitle',
            })}
            textSearchString={pageState.textSearchState}
            handleTextSearchLabelClose={pageState.handleTextSearchLabelClose}
            labelTitleMapping={pageState.filterLabelTitleMapping}
            dropdownOptionsInfoList={filterDropdownOptionsInfoList}
            filterState={pageState.filterState}
            handleRemoveFilter={pageState.handleRemoveFilter}
            handleFilterChange={pageState.handleFilterChange}
          />
        </div>

        <div>
          <CardGrid
            objectData={get(data, 'emailMessages.emailMessages', [])}
            renderCardComponent={(emailMessage: CardType, i: number) => (
              // @ts-ignore
              <EmailMessageCard
                index={i}
                onViewEmail={(emailMessageData: any) => {
                  setEmailMessageData(emailMessageData);
                  toggleViewEmailModal();
                }}
                refetchEmailMessages={refetchEmailMessages}
                {...emailMessage}
              />
            )}
            dataQaid="admin-email-messages-list"
            loading={loading}
            loadingComponent={
              <LoadingBlocks.Rectangle width="100%" height="420px" />
            }
            hideDividerOnSize="xs"
          />
          <DottedLine />
          <Spacer mb={2} />
        </div>

        <ListingNoResults
          entityName={intl.formatMessage({
            id: 'shared.email',
          })}
          numResults={get(
            data,
            'emailMessages.emailMessages.length',
            undefined
          )}
          loading={loading}
        />

        <ListingFooter
          numTotalRecords={get(
            data,
            'emailMessages.metadata.totalRecords',
            undefined
          )}
          perPage={PER_PAGE}
          currentPage={pageState.page}
          onPageChange={pageState.handlePageChange}
          loading={loading}
          dataQaidPrefix="email-messages"
        />
      </ListPageTemplate>
    </Layout>
  );
};

const AdminEmailMessagesWrapper: React.FC = () => (
  <ListPage config={pageStateConfig} analytics={Analytics}>
    <AdminEmailMessages />
  </ListPage>
);

export default AdminEmailMessagesWrapper;
