import React from 'react';
import styled, { css } from 'styled-components';

import { DownloadIcon } from 'app/shared/components/atoms/IconLibrary';
import { BaseLink } from 'app/shared/components/atoms/LinkManualCSS';

interface Props {
  text: string;
  url: string;
}

const MainContainer = styled.div`
  padding-left: 10px;
  padding-bottom: 25px;
`;

const IconWrapper = styled(BaseLink)`
  ${({ theme }) => css`
    cursor: pointer;
    white-space: nowrap;
    color: ${theme.colors.green600};
  `}
`;

const BaseIconText = styled.div`
  font-size: 10px;
  line-height: 150%;
  letter-spacing: 1.5px;
  text-transform: uppercase;
`;

const ActiveIconText = styled(BaseIconText)`
  ${({ theme }) => css`
    color: ${theme.colors.green600};
  `}
`;

const DownloadLink: React.FC<Props> = ({ text, url }) => {
  return (
    <MainContainer>
      <IconWrapper href={url} download openInNewTab={true}>
        <DownloadIcon iconSize={20} iconColor="green" />
        <ActiveIconText>{text}</ActiveIconText>
      </IconWrapper>
    </MainContainer>
  );
};

export default DownloadLink;
