import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { isEnvironmentProdOrLocal } from 'app/lib/environmentChecker';
import usePermission from 'app/shared/utils/usePermission';
import { FlexDivWithSpacing } from 'app/shared/components/atoms/FlexDivWithSpacing';

const BeamerText = styled.div`
  ${({ theme }) => css`
    font-size: 16px;
    color: inherit;
    padding-right: 12px;
    color: ${isEnvironmentProdOrLocal()
      ? theme.colors.whiteDenim
      : theme.colors.backToBlack};
  `}
`;

export const BeamerIcon: React.FC = () => {
  const intl = useIntl();
  const beamerScopes = [];
  const hasBeamerPermission = usePermission('tool.beamer.adminAccess');
  const hasBeamerCrewPermission = usePermission('tool.beamer.crewAccess');
  const hasBeamerCuratorPermission = usePermission('tool.beamer.curatorAccess');
  if (hasBeamerPermission) {
    beamerScopes.push('admin');
  }
  if (hasBeamerCrewPermission) {
    beamerScopes.push('crew');
  }
  if (hasBeamerCuratorPermission) {
    beamerScopes.push('curator');
  }
  if (
    beamerScopes.length > 0 &&
    process.env.BEAMER_ID &&
    !(process.env.DISABLE_BEAMER == 'true')
  ) {
    (window as any).Beamer.init();
    (window as any).Beamer.update({ filter: beamerScopes.join(';') });
  }
  const beamerNavDisplayed = beamerScopes.length > 0 && process.env.BEAMER_ID;
  return (
    (beamerNavDisplayed && (
      <FlexDivWithSpacing alignItems="right" widthProp="130px" pl={3} pr={1}>
        <BeamerText id="beamerButton" data-qaid="navbar-admin-beamer">
          {intl.formatMessage({ id: 'admin.navbar.whatsNew' })}
        </BeamerText>
      </FlexDivWithSpacing>
    )) ||
    null
  );
};
