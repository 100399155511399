import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import useModal from 'app/shared/utils/useModal';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import { LoadingBlocks } from 'app/shared/components/atoms/LoadingBlocks';
import ConfirmationModal from 'app/shared/components/molecules/ConfirmationModal';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { CloseOffer } from 'app/admin/graphql/offers/mutationHooks';
import { GetOfferForOfferDetail } from 'app/admin/graphql/offers/queryHooks';
import { DetailsMainContainer } from 'app/admin/components/atoms/DetailContent';
import AccordionSections from 'app/admin/components/organisms/AccordionSections';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';
import { ReactComponent as InformationCircle } from 'icons/streamline-regular/interface-essential/alerts/information-circle.svg';
import { ReactComponent as MusicGenreSettings } from 'icons/streamline-regular/music-audio/music-genres/music-genre-settings.svg';

import SectionArtistAvailability from './SectionArtistAvailability';
import SectionBookingInvitationDetails from './SectionBookingInvitationDetails';

interface Props {
  navigateTo: (routeData: any) => void;
  contentProps: any;
  hide: VoidFunction;
  type?: string;
}

const OfferDetails: React.FC<Props> = ({ contentProps, hide }) => {
  const { name, status, id } = contentProps;
  const [isClosingOffer, setIsClosingOffer] = useState(false);
  const [
    closeOfferConfirmationModal,
    toggleCloseOfferConfirmationModal,
  ] = useModal();

  const intl = useIntl();

  const closeOfferAction = CloseOffer();
  const handleCloseOffer = useSubmitAction({
    beforeSubmit: () => setIsClosingOffer(true),
    submitAction: closeOfferAction,
    submitVariables: () => ({
      id,
    }),
    successMsg: intl.formatMessage(
      {
        id: 'admin.offerDetails.closeOffer.successMessage',
      },
      {
        name,
      }
    ),
    failureMsg: intl.formatMessage({
      id: 'admin.offerDetails.closeOffer.failureMessage',
    }),
    onSuccess: (data: any) => {
      setIsClosingOffer(false);
      hide();
      contentProps &&
        contentProps.onCloseOffer &&
        contentProps.onCloseOffer(data);
    },
  });

  const {
    loading: loadingOfferInfo,
    error: errorOfferInfo,
    data: dataOfferInfo,
  } = GetOfferForOfferDetail({
    id: contentProps.id,
    fetchPolicy: 'cache-and-network',
  });

  const [headerData, setHeaderData] = useState({ name, status });
  const [offerInfo, setOfferInfo] = useState(dataOfferInfo);

  useEffect(() => {
    if (dataOfferInfo) {
      setOfferInfo(dataOfferInfo);
      setHeaderData({
        name: dataOfferInfo?.offer?.name,
        status: dataOfferInfo?.offer?.status,
      });
    }
  }, [dataOfferInfo]);

  const detailsHeaderActionLinksInfo = (intl: any) => {
    return {
      link1: {
        text: intl.formatMessage({
          id: 'admin.offerDetails.closeOffer',
        }),
        active:
          headerData &&
          headerData.status !== 'closed' &&
          headerData.status !== undefined,
        onClickAction: toggleCloseOfferConfirmationModal,
      },
    };
  };

  const sectionsConfig = {
    bookingInvitationDetails: {
      title: intl.formatMessage({
        id: 'admin.offerDetails.bookingInvitationDetails',
      }),
      icon: MusicGenreSettings,
      iconColor: 'primaryHover',
      sectionComponent: SectionBookingInvitationDetails,
      iconCircle: true,
      displayEditIcon: false,
      data: offerInfo
        ? {
            ...offerInfo,
            setOfferInfo,
            modalRef: contentProps.modalRef,
          }
        : undefined,
    },
    artistAvailability: {
      title: intl.formatMessage({
        id: 'admin.offerDetails.artistAvailability',
      }),
      icon: InformationCircle,
      iconColor: 'blueChristmas',
      sectionComponent: SectionArtistAvailability,
      displayEditIcon: false,
      data: offerInfo,
    },
  };

  if (loadingOfferInfo) {
    return (
      <DetailsMainContainer>
        <LoadingBlocks.Rectangle width="100%" height="300px" />
      </DetailsMainContainer>
    );
  }

  if ((!loadingOfferInfo && !dataOfferInfo) || errorOfferInfo) {
    hide();
    return null;
  }

  return (
    <>
      <ModalContentContainer data-qaid="offer-details-main-modal">
        <DetailsMainContainer>
          <DetailsHeader
            title={headerData && headerData.name}
            actionLinksInfo={detailsHeaderActionLinksInfo(intl)}
          />
        </DetailsMainContainer>
        <AccordionSections
          sectionsConfig={sectionsConfig}
          defaultOpenSection={contentProps.defaultOpenSection}
        />
      </ModalContentContainer>
      {closeOfferConfirmationModal.isShowing && (
        <ConfirmationModal
          onCancel={() => closeOfferConfirmationModal.hide()}
          title={intl.formatMessage({
            id: 'admin.offerDetails.cancelConfirm',
          })}
          description={intl.formatMessage({
            id: 'admin.offerDetails.cancelConfirmSubtitle',
          })}
          onConfirm={handleCloseOffer}
          isLoading={isClosingOffer}
          confirmationButtonText={intl.formatMessage({
            id: 'yes',
          })}
        />
      )}
    </>
  );
};

export default OfferDetails;
