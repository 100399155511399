import React from 'react';
import styled, { css } from 'styled-components';

import { errorMsgForField } from 'app/shared/utils/form';
import { upperFirst } from 'app/shared/utils/string';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Textfield } from 'app/shared/components/atoms/Textfield';
import FormGroup from 'app/shared/components/molecules/FormGroup';
import { titleLocales } from 'app/admin/utils/titleByLocale';

interface Props {
  setFieldValue: any;
  touched: any;
  errors: any;
  values: any;
  validationErrors?: any;
  maxLength?: number;
  dataQaidPrefix: string;
}

const StyledTextfield = styled(Textfield)`
  ${({ theme }) => css`
    display: block;
    width: 100%;
    ${theme.media.lg`
        width: 48%;
      `};
  `}
`;

const TitleByLocaleFields: React.FC<Props> = ({
  setFieldValue,
  touched,
  errors,
  values,
  validationErrors = {},
  maxLength,
  dataQaidPrefix,
}) => {
  const renderTitleFields = () =>
    Object.keys(titleLocales).map((locale: string, index: number) => {
      const fieldName = `title${upperFirst(locale)}`;
      return (
        <React.Fragment key={index}>
          <FormGroup
            data-qaid={`${dataQaidPrefix}-${locale}-formgroup`}
            label={`${locale.toUpperCase()} (${titleLocales[locale]})`}
            errorMsg={
              errorMsgForField(fieldName, touched, errors) ||
              // @ts-ignore
              (locale === 'en'
                ? validationErrors.title || validationErrors.general_title
                : null)
            }
            required={!!(locale === 'en')}
          >
            <StyledTextfield
              data-qaid={`${dataQaidPrefix}-${locale}`}
              id={fieldName}
              name={fieldName}
              value={values[fieldName]}
              onChange={(e: any) => {
                setFieldValue(fieldName, e.target.value);
              }}
              maxLength={maxLength || 100}
            />
          </FormGroup>
          <Spacer mb={6} />
        </React.Fragment>
      );
    });

  return <>{renderTitleFields()}</>;
};

export default TitleByLocaleFields;
