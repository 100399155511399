import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { City } from 'app/typings/cities';
import { CompetitionTemplate } from 'app/typings/competitionTemplates';
import { Event } from 'app/typings/events';
import { errorMsgForField } from 'app/shared/utils/form';
import { get } from 'app/shared/utils/get';
import {
  FormGroupContainer,
  FormHelpText,
  FormSectionTitle,
} from 'app/shared/components/atoms/FormContent';
import GenericFormContainer from 'app/shared/components/atoms/GenericFormContainer';
import { DeleteIcon } from 'app/shared/components/atoms/IconLibrary';
import { LoadingError } from 'app/shared/components/atoms/LoadingError';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Textfield } from 'app/shared/components/atoms/Textfield';
import FormGroup from 'app/shared/components/molecules/FormGroup';
import Select from 'app/shared/components/molecules/SelectManualCSS';
import CitySelectorManualCSS from 'app/shared/components/organisms/CitySelectorManualCSS';
import { getCompetitionTemplatesOptions } from 'app/admin/utils/optionLists';
import { GetCompetitionTemplates } from 'app/admin/graphql/competitionTemplates/queryHooks';
import EditFormLoadingBlocks from 'app/admin/components/atoms/EditFormLoadingBlocks';
import SimpleDatePicker from 'app/admin/components/molecules/SimpleDatePicker';
import { NumberTextfield } from 'app/admin/components/molecules/Textfield';
import Timepicker from 'app/admin/components/molecules/TimePicker';
import { EventSelector } from 'app/admin/components/organisms/EventSelector';

interface FormProps {
  formikProps: any;
  validationErrors?: object;
  isNewCompetition?: boolean;
}

const TitleTextfield = styled(Textfield)`
  display: block;
  width: 100%;
`;

const UidTextfield = styled(Textfield)`
  display: block;
  width: 60%;
`;

const EventSelectorContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  max-width: 350px;
`;

const EventAdd = styled.div`
  width: 100%;
`;

const EventRemove = styled.div`
  padding-left: 20px;
  padding-bottom: 10px;
`;

const CompetitionBasicInfoForm: React.FC<FormProps> = ({
  formikProps,
  validationErrors = {},
  isNewCompetition,
}) => {
  const intl = useIntl();

  const {
    loading: loadingCompetitionTemplates,
    error: errorCompetitionTemplates,
    data: dataCompetitionTemplates,
  } = GetCompetitionTemplates();

  if (errorCompetitionTemplates) {
    return <LoadingError whatsBeingLoaded="this form" />;
  }

  if (loadingCompetitionTemplates || !dataCompetitionTemplates) {
    return <EditFormLoadingBlocks />;
  }

  const competitionTemplatesOptions = getCompetitionTemplatesOptions(
    dataCompetitionTemplates
  );

  const { setFieldValue, touched, setTouched, errors, values } = formikProps;

  const getCompetitionTemplateForId = (competitionTemplateId: number) =>
    get(
      dataCompetitionTemplates,
      'competitionTemplates.competitionTemplates',
      []
    ).find(
      (competitionTemplate: CompetitionTemplate) =>
        competitionTemplate.id == competitionTemplateId
    );

  const updateValuesFromCompetitionTemplate = (
    competitionTemplateId: number
  ) => {
    const competitionTemplate = getCompetitionTemplateForId(
      competitionTemplateId
    );
    if (competitionTemplate) {
      setFieldValue('title', competitionTemplate.title || '');
      setFieldValue('city', competitionTemplate.city);
      setFieldValue('maxWinners', competitionTemplate.maxWinners || '');
    }
  };

  const onRemoveEvent = () => {
    setFieldValue('event', undefined);
    setTouched({ ...touched, event: true });
  };

  return (
    <GenericFormContainer dataQaId="competition-create-form">
      {isNewCompetition && (
        <>
          <FormSectionTitle>
            {intl.formatMessage({
              id: 'admin.competitionCreate.form.competitionTemplate',
            })}
          </FormSectionTitle>
          <FormGroupContainer>
            <FormGroup
              data-qaid="competition-create-form-select-competition-template-formgroup"
              label={intl.formatMessage({
                id: 'admin.competitionCreate.form.competitionTemplateNote',
              })}
            >
              <Select
                data-qaid="competition-create-form-select-competition-template"
                searchable
                getOptionLabel={(opt: any) => opt.title}
                defaultValue={undefined}
                options={competitionTemplatesOptions}
                placeholder={intl.formatMessage({
                  id:
                    'admin.competitionCreate.form.competitionTemplatePlaceholder',
                })}
                onChange={(opt: any) => {
                  setFieldValue(
                    'competitionTemplateId',
                    opt ? opt.value : undefined
                  );
                  if (opt) {
                    updateValuesFromCompetitionTemplate(opt.value);
                  }
                }}
              />
            </FormGroup>
          </FormGroupContainer>
        </>
      )}
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.competitionCreate.form.titleAndClosingDate',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="competition-create-form-title-formgroup"
          label={intl.formatMessage({
            id: 'admin.competitionCreate.form.title',
          })}
          errorMsg={errorMsgForField('title', touched, errors)}
          required
        >
          <TitleTextfield
            data-qaid="competition-create-form-title-field"
            id="title"
            name="title"
            value={values.title}
            onChange={(e: any) => {
              setFieldValue('title', e.target.value);
            }}
            maxLength={200}
          />
        </FormGroup>
        <Spacer mb={6} />
        <FormGroup
          label={intl.formatMessage({
            id: 'admin.competitionCreate.form.closingDate',
          })}
          data-qaid="competition-create-form-closing-date-date-formgroup"
          errorMsg={errorMsgForField('closesAtDat', touched, errors)}
          required
        >
          <div>
            <SimpleDatePicker
              data-qaid="competition-create-form-closing-date-date-datepicker"
              name="closesAtDate"
              value={values.closesAtDate}
              placeholder="Select date"
              onChange={(e: any) => {
                const date = e.startOf('day');
                setFieldValue('closesAtDate', date);
              }}
            />
          </div>
        </FormGroup>
        <Spacer mb={5} />
        <FormGroup
          label={intl.formatMessage({
            id: 'admin.competitionCreate.form.closingTime',
          })}
          data-qaid="competition-create-form-closing-date-date-formgroup"
          errorMsg={errorMsgForField('closesAtTime', touched, errors)}
          required
        >
          <Timepicker
            data-qaid="competition-create-form-closing-date-time-timepicker"
            name="closesAtTime"
            value={values.closesAtTime}
            onChange={(value: any) => {
              setFieldValue('closesAtTime', value);
            }}
          />
        </FormGroup>
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: isNewCompetition
            ? 'admin.competitionCreate.form.cityAndEvent'
            : 'admin.competitionCreate.form.city',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="competition-create-form-select-city-formgroup"
          label={intl.formatMessage({
            id: 'admin.competitionCreate.form.city',
          })}
          errorMsg={errorMsgForField('city', touched, errors)}
          required
        >
          <CitySelectorManualCSS
            parentContainer="competition-create-form-select-city-formgroup"
            data-qaid="competition-create-form-select-city"
            disableTopCities
            disableClosestCities
            initialValue={values.city}
            value={values.city}
            onChange={(city: City) => {
              setFieldValue('city', city);
            }}
          />
        </FormGroup>
        {isNewCompetition && (
          <>
            <Spacer mb={5} />
            <FormGroup
              data-qaid="competition-create-form-select-event-formgroup"
              label={intl.formatMessage({
                id: 'admin.competitionCreate.form.event',
              })}
              errorMsg={errorMsgForField('event', touched, errors)}
              required
            >
              <EventSelectorContainer>
                <EventAdd>
                  <EventSelector
                    selectedEvent={values.event}
                    setEvent={(event: Event) => {
                      setFieldValue('event', event);
                    }}
                  />
                </EventAdd>
                {values.event && (
                  <EventRemove>
                    <DeleteIcon onClick={() => onRemoveEvent()} />
                  </EventRemove>
                )}
              </EventSelectorContainer>
            </FormGroup>
          </>
        )}
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.competitionCreate.form.uid',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormHelpText>
          {intl.formatMessage({
            id: 'admin.competitionCreate.form.uidHelpText',
          })}
        </FormHelpText>
        <FormGroup
          data-qaid="competition-create-form-uid-formgroup"
          label={intl.formatMessage({
            id: 'admin.competitionCreate.form.uid',
          })}
          errorMsg={
            errorMsgForField('uid', touched, errors) ||
            // @ts-ignore
            validationErrors.uid
          }
          required
        >
          <UidTextfield
            data-qaid="competition-create-form-uid-field"
            id="uid"
            name="uid"
            value={values.uid}
            onChange={(e: any) => {
              setFieldValue('uid', e.target.value);
            }}
            placeholder={intl.formatMessage({
              id: 'admin.competitionCreate.form.uidPlaceholder',
            })}
            maxLength={100}
          />
        </FormGroup>
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.competitionCreate.form.maxNumberOfWinners',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="competition-create-form-max-winners-formgroup"
          label={intl.formatMessage({
            id: 'admin.competitionCreate.form.maxNumberOfWinnersNote',
          })}
          errorMsg={errorMsgForField('maxWinners', touched, errors)}
          required
        >
          <NumberTextfield
            data-qaid="competition-create-form-max-winners-field"
            id="maxWinners"
            name="maxWinners"
            value={values.maxWinners}
            onChange={(e: any) => {
              setFieldValue('maxWinners', e.target.value);
            }}
            maxLength={4}
          />
        </FormGroup>
      </FormGroupContainer>
    </GenericFormContainer>
  );
};

export default CompetitionBasicInfoForm;
