import styled from 'styled-components';

export const ToolTip = styled.span`
  letter-spacing: 1.2px;
  text-transform: uppercase;
  line-height: 1.6;
  font-weight: normal;
  font-size: 10px;

  display: inline;
  max-width: 160px;
  min-width: 80px;
  background-color: #000;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;

  position: absolute;
  z-index: 1;
  bottom: 130%;
  left: 50%;
  margin-left: -40px;

  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s;

  // Arrow below
  &:after {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
`;

export const ToolTipContainer = styled.div`
  position: relative;
  &:hover ${ToolTip} {
    visibility: visible;
    opacity: 1;
  }
`;
