import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { City } from 'app/typings/cities';
import { currencyRoundedFormatter } from 'app/shared/utils/currencyFormatter';
import { errorMsgForField } from 'app/shared/utils/form';
import {
  FormGroupContainer,
  FormSectionTitle,
} from 'app/shared/components/atoms/FormContent';
import GenericFormContainer from 'app/shared/components/atoms/GenericFormContainer';
import { Radio } from 'app/shared/components/atoms/Radio';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Textfield } from 'app/shared/components/atoms/Textfield';
import FormGroup from 'app/shared/components/molecules/FormGroup';
import CitySelectorManualCSS from 'app/shared/components/organisms/CitySelectorManualCSS';
import TicketPriceFormField from 'app/admin/components/molecules/TicketPriceFormField';

interface FormProps {
  formikProps: any;
  validationErrors?: object;
  isNewCuratorGroup?: boolean;
}

const NameTextfield = styled(Textfield)`
  ${({ theme }) => css`
    display: block;
    width: 100%;
    ${theme.media.lg`
      width: 48%;
    `};
  `}
`;

const VerticalCenteredText = styled.div`
  font-size: 14px;
`;

export const renderPriceHelperText = (intl: any, values: any) => {
  const cityDefaultEventPrice = currencyRoundedFormatter(
    values.city.defaultEventPrice || 0,
    values.currencySymbol || '$'
  );

  const cityPriceDescription = intl.formatMessage(
    {
      id: 'admin.shared.fixedPricingSymbolIncluded',
    },
    {
      city: values.city.title,
      price: cityDefaultEventPrice,
    }
  );

  return (
    <VerticalCenteredText>
      {intl.formatMessage({
        id: 'admin.shared.ticketPriceCopy',
      })}
      <br />
      {cityPriceDescription}
    </VerticalCenteredText>
  );
};

const CuratorGroupBasicInfoForm: React.FC<FormProps> = ({
  formikProps,
  validationErrors = {},
  isNewCuratorGroup,
}) => {
  const intl = useIntl();

  const { setFieldValue, touched, errors, values } = formikProps;

  return (
    <GenericFormContainer dataQaId="curator-group-create-form">
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.curatorGroupBasicInfo.form.name',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="curator-group-edit-form-name-formgroup"
          label={intl.formatMessage({
            id: 'admin.curatorGroupBasicInfo.form.nameOfGroup',
          })}
          errorMsg={
            errorMsgForField('name', touched, errors) ||
            // @ts-ignore
            validationErrors.name
          }
          required
        >
          <NameTextfield
            data-qaid="curator-group-edit-form-name-field"
            id="name"
            name="name"
            value={values.name}
            onChange={(e: any) => {
              setFieldValue('name', e.target.value);
            }}
            maxLength={80}
          />
        </FormGroup>
      </FormGroupContainer>
      {isNewCuratorGroup && (
        <>
          <FormSectionTitle>
            {intl.formatMessage({
              id: 'admin.curatorGroupBasicInfo.form.city',
            })}
          </FormSectionTitle>
          <FormGroupContainer>
            <FormGroup
              data-qaid="curator-group-edit-form-select-city-formgroup"
              label={intl.formatMessage({
                id: 'admin.curatorGroupBasicInfo.form.city',
              })}
              errorMsg={errorMsgForField('city', touched, errors)}
              required
            >
              <CitySelectorManualCSS
                parentContainer="curator-group-edit-form-select-city-formgroup"
                data-qaid="curator-group-edit-form-select-city"
                disableTopCities
                disableClosestCities
                initialValue={values.city}
                value={values.city}
                onChange={(city: City) => {
                  setFieldValue('city', city);
                }}
              />
            </FormGroup>
          </FormGroupContainer>
        </>
      )}
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.curatorGroupBasicInfo.form.type',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="curator-group-edit-form-curator-group-type-formgroup"
          label={intl.formatMessage({
            id: 'admin.curatorGroupBasicInfo.form.typeOfGroup',
          })}
          errorMsg={errorMsgForField('curatorGroupType', touched, errors)}
          required
        >
          <>
            <Spacer mt={6} />
            <Radio
              id="curatorGroupTypeCommercial"
              value="commercial"
              name="curatorGroupTypeCommercial"
              checked={values.curatorGroupType == 'commercial'}
              onChange={() => {
                setFieldValue('curatorGroupType', 'commercial');
              }}
            >
              {intl.formatMessage({
                id: 'admin.curatorGroupBasicInfo.form.commercial',
              })}
            </Radio>
            <Spacer mt={4} />
            <Radio
              id="curatorGroupTypeNonCommercial"
              value="non_commercial"
              name="curatorGroupTypeNonCommercial"
              checked={values.curatorGroupType == 'non_commercial'}
              onChange={() => {
                setFieldValue('curatorGroupType', 'non_commercial');
              }}
            >
              {intl.formatMessage({
                id: 'admin.curatorGroupBasicInfo.form.nonCommercial',
              })}
            </Radio>
          </>
        </FormGroup>
      </FormGroupContainer>
      {!isNewCuratorGroup && (
        <>
          <FormSectionTitle>
            {intl.formatMessage({
              id: 'admin.curatorGroupBasicInfo.form.status',
            })}
          </FormSectionTitle>
          <FormGroupContainer>
            <FormGroup
              data-qaid="curator-group-edit-form-status-formgroup"
              label=""
            >
              <>
                <Radio
                  id="statusActive"
                  value="active"
                  name="statusActive"
                  checked={values.status == 'active'}
                  onChange={() => {
                    setFieldValue('status', 'active');
                  }}
                >
                  {intl.formatMessage({
                    id: 'shared.active',
                  })}
                </Radio>
                <Spacer mt={4} />
                <Radio
                  id="statusInactive"
                  value="inactive"
                  name="statusInactive"
                  checked={values.status == 'inactive'}
                  onChange={() => {
                    setFieldValue('status', 'inactive');
                  }}
                >
                  {intl.formatMessage({
                    id: 'shared.inactive',
                  })}
                </Radio>
              </>
            </FormGroup>
          </FormGroupContainer>
        </>
      )}
      {!isNewCuratorGroup && values.curatorGroupType == 'commercial' && (
        <FormGroupContainer>
          <TicketPriceFormField
            fieldName="eventPrice"
            currencySymbol={values.currencySymbol}
            price={values.eventPrice}
            priceHelperText={renderPriceHelperText(intl, values)}
            touched={touched}
            errors={errors}
            setFieldValue={setFieldValue}
          />
        </FormGroupContainer>
      )}
    </GenericFormContainer>
  );
};

export default CuratorGroupBasicInfoForm;
