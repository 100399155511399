import React from 'react';
import styled from 'styled-components';

import { Icon } from 'app/shared/components/atoms/IconManualCSS';
import { ToolTip, ToolTipContainer } from 'app/shared/components/atoms/ToolTip';

interface IconListProps {
  iconList: IconInfo[];
  iconGroup?: boolean;
}

export interface IconInfo {
  iconName: string;
  tipText: string;
}

interface MainContainerProps {
  iconGroup: boolean;
}

const MainContainer = styled.div<MainContainerProps>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: ${props => (props.iconGroup ? '25px' : '35px')};
  margin-left: ${props => (props.iconGroup ? '-3px' : '-7px')};
`;

const BoxedTooltipContainer = styled(ToolTipContainer)`
  display: flex;
  align-items: center;
  justify-content: center;
  height 25px;
  width: 25px;
`;

const ColoredIcon = styled(Icon)`
  &::before {
    color: #98035a;
  }
`;

interface RenderIconProps {
  iconName: string;
  tipText: string;
  index?: number;
}

const renderIcon = ({ iconName, tipText, index }: RenderIconProps) => {
  return (
    <BoxedTooltipContainer key={index || 0} data-qaid={`icon-list-${iconName}`}>
      <ColoredIcon name={iconName} />
      <ToolTip>{tipText}</ToolTip>
    </BoxedTooltipContainer>
  );
};

export const IconList: React.FC<IconListProps> = ({
  iconList,
  iconGroup = false,
}) => (
  <MainContainer iconGroup={iconGroup}>
    {iconList.map((iconInfo: IconInfo, index: number) =>
      renderIcon({
        iconName: iconInfo.iconName,
        tipText: iconInfo.tipText,
        index,
      })
    )}
  </MainContainer>
);
