import styled, { css } from 'styled-components';

const NoneTag = styled.div`
  ${({ theme }) => css`
    width: 45px;
    height: 16px;
    top: 0px;
    background: ${theme.colors.darkSmoke};
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 1.5px;
    color: #ffffff;
    text-align: center;
  `}
`;

export default NoneTag;
