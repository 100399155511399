import { Coordinates } from 'app/typings/coordinates';
import findDistanceBetween from 'app/shared/utils/findDistanceBetween';

export default function findNearestSofarCities(
  coordinates: Coordinates,
  cityData: any
) {
  const cities = cityData.filter(
    (city: any) => city.latitude && city.longitude
  );

  const citiesWithDistance = cities.map((city: any) => ({
    ...city,
    distanceBetween: findDistanceBetween(coordinates, city),
  }));

  const sortedCities = citiesWithDistance.sort((a: any, b: any) => {
    const distanceA = a.distanceBetween;
    const distanceB = b.distanceBetween;
    return distanceA < distanceB ? -1 : distanceA > distanceB ? 1 : 0;
  });

  return sortedCities.slice(0, 3);
}
