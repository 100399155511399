import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { get } from 'app/shared/utils/get';
import { buildFullName } from 'app/shared/utils/string';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { UpdateUserRoles } from 'app/admin/graphql/users/mutationHooks';
import { DetailsFormMainContainer } from 'app/admin/components/atoms/DetailContent';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

import UserRoleAddForm from './UserRoleAddForm';
import UserRoleAddFormSchema from './UserRoleAddFormSchema';

interface Props {
  contentProps?: any;
  setFormSubmitAction: (func: Function) => void;
  setDisplayConfirmation: (set: boolean) => void;
  setIsSubmitting: (set: boolean) => void;
  navigateTo?: (routeData: object) => void;
  hide?: VoidFunction;
}

const UserRoleAdd: React.FC<Props> = ({
  contentProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  navigateTo,
  hide,
}) => {
  const intl = useIntl();

  const [validationErrors, setValidationErrors] = useState<object>({});

  const action = get(contentProps, 'action', '');

  const getPageTitle = (action: string) => {
    switch (action) {
      case 'addRoleToAnyUser':
        return intl.formatMessage({
          id: 'admin.users.userRoleAdd.addInternalUser',
        });
      case 'addCuratorRoleToAnyUser':
        return intl.formatMessage({
          id: 'admin.users.userRoleAdd.addTeamMember',
        });
      case 'addInitialRoleToUser':
        return buildFullName(
          get(contentProps, 'user.firstName', ''),
          get(contentProps, 'user.lastName', '')
        );
      case 'addRoleToUser':
        return buildFullName(
          get(contentProps, 'user.firstName', ''),
          get(contentProps, 'user.lastName', '')
        );
      default:
        return intl.formatMessage({
          id: 'admin.users.userRoleAdd.addInternalUser',
        });
    }
  };

  const formInitialValues = {
    action,
    userId:
      action === 'addRoleToAnyUser' || action === 'addCuratorRoleToAnyUser'
        ? null
        : get(contentProps, 'user.id', undefined),
    role: '',
    roleResourceType: '',
    curatorGroup: '',
    city: '',
    businessOwner: '',
  };

  const getResourceId = (values: any) =>
    values.curatorGroup
      ? values.curatorGroup.id
      : values.city
      ? values.city.id
      : values.businessOwner
      ? values.businessOwner.id
      : undefined;

  const getResourceType = (values: any) =>
    values.curatorGroup
      ? 'CuratorGroup'
      : values.city
      ? 'City'
      : values.businessOwner
      ? 'BusinessOwner'
      : undefined;

  const getOnSuccess = (action: string) => {
    switch (action) {
      case 'addRoleToAnyUser':
        return () => {
          contentProps.refetchInternalUsers &&
            contentProps.refetchInternalUsers();
          hide && hide();
        };
      case 'addCuratorRoleToAnyUser':
        return () => {
          navigateTo &&
            navigateTo({
              routeName: 'curator-group-details',
              routeProps: {
                defaultOpenSection: 'team',
                id: contentProps.curatorGroupIdsToLimitBy[0],
                name: '',
                shouldRefetchCuratorGroup: true,
              },
            });
        };
      case 'addInitialRoleToUser':
        return () => {
          navigateTo &&
            navigateTo({
              routeName: 'user-details',
              routeProps: {
                defaultOpenSection: 'basicInfo',
                ...contentProps.user,
                curatorGroupIdsToLimitBy: contentProps.curatorGroupIdsToLimitBy,
                refetchInternalUsers: contentProps.refetchInternalUsers,
                shouldRefetchUser: true,
              },
            });
        };
      case 'addRoleToUser':
        return () => {
          navigateTo &&
            navigateTo({
              routeName: 'internal-user-details',
              routeProps: {
                defaultOpenSection: 'roles',
                ...contentProps.user,
                curatorGroupIdsToLimitBy: contentProps.curatorGroupIdsToLimitBy,
                refetchInternalUsers: contentProps.refetchInternalUsers,
              },
            });
        };
      default:
        return () => {
          hide && hide();
        };
    }
  };

  const updateUserRolesAction = UpdateUserRoles();

  const handleSubmit = useSubmitAction({
    submitAction: updateUserRolesAction,
    submitVariables: (values: any) => ({
      id: values.userId,
      roleToAdd: values.role,
      resourceId: getResourceId(values),
      resourceType: getResourceType(values),
    }),
    successMsg: intl.formatMessage({
      id:
        action === 'addInitialRoleToUser'
          ? 'admin.users.userRoleAdd.form.successMessageMadeInternalUser'
          : 'admin.users.userRoleAdd.form.successMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.users.userRoleAdd.form.failureMessage',
    }),
    onSuccess: getOnSuccess(action),
    onValidationError: validationErrors => {
      setValidationErrors(validationErrors);
    },
  });

  return (
    <ModalContentContainer>
      <DetailsFormMainContainer>
        <DetailsHeader title={getPageTitle(action)} />
        <GenericForm
          formInitialValues={formInitialValues}
          renderFormComponent={(renderProps: any) => (
            <UserRoleAddForm
              formikProps={renderProps.formikProps}
              validationErrors={validationErrors}
              curatorGroupIdsToLimitBy={
                contentProps.curatorGroupIdsToLimitBy || []
              }
            />
          )}
          onSubmit={handleSubmit}
          formSchema={UserRoleAddFormSchema(intl)}
          setFormSubmitAction={setFormSubmitAction}
          setDisplayConfirmation={setDisplayConfirmation}
          setIsSubmitting={setIsSubmitting}
          dataQaId="user-add-user-role-form-modal"
        />
      </DetailsFormMainContainer>
    </ModalContentContainer>
  );
};

export default UserRoleAdd;
