import React from 'react';
import { FormikProps } from 'formik';
import { useIntl } from 'react-intl';

import { errorMsgForField } from 'app/shared/utils/form';
import { FlexDivWithSpacing } from 'app/shared/components/atoms/FlexDivWithSpacing';
import {
  FormGroupContainer,
  FormSectionTitle,
} from 'app/shared/components/atoms/FormContent';
import { Body } from 'app/shared/components/atoms/TypographyManualCSS';
import FormGroup from 'app/shared/components/molecules/FormGroup';
import RichTextEditorComponent from 'app/admin/components/molecules/RichTextEditor';

interface Props extends FormikProps<any> {
  fieldName: string;
  fieldRequired?: boolean;
  fieldMaxCharacters: number;
  titleIntlId: string;
  descriptionIntlId?: string;
  labelIntlId: string;
  dataQaidPrefix: string;
  enableLinks?: boolean;
}

interface HeaderProps {
  titleIntlId: string;
  descriptionIntlId?: string;
}

const Header: React.FC<HeaderProps> = ({ titleIntlId, descriptionIntlId }) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <FormSectionTitle mb={1}>
        {formatMessage({ id: titleIntlId })}
      </FormSectionTitle>
      {descriptionIntlId && (
        <FlexDivWithSpacing mb={3}>
          <Body>{formatMessage({ id: descriptionIntlId })}</Body>
        </FlexDivWithSpacing>
      )}
    </>
  );
};

export const RichTextEditorFormField: React.FC<Props> = ({
  fieldName,
  fieldRequired = false,
  fieldMaxCharacters,
  titleIntlId,
  descriptionIntlId,
  labelIntlId,
  dataQaidPrefix,
  touched,
  setTouched,
  errors,
  values,
  setFieldValue,
  enableLinks = false,
}) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Header titleIntlId={titleIntlId} descriptionIntlId={descriptionIntlId} />
      <FormGroupContainer>
        <FormGroup
          data-qaid={`${dataQaidPrefix}-formgroup`}
          label={formatMessage({ id: labelIntlId })}
          errorMsg={errorMsgForField(fieldName, touched, errors)}
          required={fieldRequired}
        >
          <RichTextEditorComponent
            name={fieldName}
            currentValue={values[fieldName]}
            onChange={value => {
              setTouched({ ...touched, [fieldName]: true });
              setFieldValue(fieldName, value);
            }}
            maxCharacters={fieldMaxCharacters}
            enableLinks={enableLinks}
          />
        </FormGroup>
      </FormGroupContainer>
    </>
  );
};
