import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { get } from 'app/shared/utils/get';
import useModal from 'app/shared/utils/useModal';
import { ListPage, ListPageContext } from 'app/shared/context/ListPage';
import { LoadingBlocks } from 'app/shared/components/atoms/LoadingBlocks';
import TruncatedByCharText from 'app/shared/components/atoms/TruncatedByCharText';
import RoutableModal from 'app/shared/components/molecules/RoutableModal';
import { GlobalError } from 'app/shared/components/pages/Status';
import { GetDuplicateArtists } from 'app/admin/graphql/artistMerges/queryHooks';
import ListingFooter from 'app/admin/components/molecules/ListingFooter';
import ListingNoResults from 'app/admin/components/molecules/ListingNoResults';
import ListingControls from 'app/admin/components/organisms/ListingControls';
import ListingHeader from 'app/admin/components/organisms/ListingHeader';
import ListTable from 'app/admin/components/organisms/ListTable';
import ListPageTemplate from 'app/admin/components/templates/ListPage';
import Layout from 'app/admin/layouts/ListPage';

import { sortOptions } from './options';

const pageStateConfig = {
  filterNames: [],
  defaultSort: { by: 'title', direction: 'asc' },
  textSearchParamName: 'duplicate_artist_search',
};

const TopNote = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  width: 1180px;
`;

const SelectArtistsLink = styled.span`
  ${({ theme }) => css`
    cursor: pointer;
    color: ${theme.colors.green600};
    padding-left: 10px;
  `}
`;

const SelectArtistsFromSetLinkContainer = styled.span`
  ${({ theme }) => css`
    cursor: pointer;
    color: ${theme.colors.green600};
  `}
`;

interface SelectArtistsFromSetLinkProps {
  title: string;
  setDuplicatedArtistTitle: Function;
  toggleArtistMergeSelectArtistsFromSetModal: Function;
}

const SelectArtistsFromSetLink: React.FC<SelectArtistsFromSetLinkProps> = ({
  title,
  setDuplicatedArtistTitle,
  toggleArtistMergeSelectArtistsFromSetModal,
}) => {
  const intl = useIntl();

  const handleOnClick = () => {
    setDuplicatedArtistTitle(title);
    toggleArtistMergeSelectArtistsFromSetModal();
  };

  return (
    <SelectArtistsFromSetLinkContainer onClick={() => handleOnClick()}>
      {intl.formatMessage({
        id: 'admin.artistMerge.selectArtistsToMerge',
      })}
    </SelectArtistsFromSetLinkContainer>
  );
};

const AdminArtistMerge: React.FC = () => {
  const intl = useIntl();
  const pageState = useContext(ListPageContext);
  const PER_PAGE = 25;

  const [duplicatedArtistTitle, setDuplicatedArtistTitle] = useState<
    string | undefined
  >(undefined);
  const [
    artistMergeSelectArtistsModal,
    toggleArtistMergeSelectArtistsModal,
  ] = useModal();
  const [
    artistMergeSelectArtistsFromSetModal,
    toggleArtistMergeSelectArtistsFromSetModal,
  ] = useModal();

  const {
    loading: loadingDuplicateArtists,
    error: errorDuplicateArtists,
    data: dataDuplicateArtists,
    refetch: refetchDuplicateArtists,
  } = GetDuplicateArtists({
    duplicateArtistSearch: pageState.textSearchState,
    orderBy: pageState.sortState.by,
    orderDirection: pageState.sortState.direction,
    page: pageState.page,
    perPage: PER_PAGE,
  });

  useEffect(() => {
    pageState.updatePageUrl();
  }, [pageState.page, pageState.sortState, pageState.textSearchState]);

  if (
    (!loadingDuplicateArtists && !dataDuplicateArtists) ||
    errorDuplicateArtists
  ) {
    return <GlobalError />;
  }

  const duplicateArtists = get(
    dataDuplicateArtists,
    'duplicateArtists.duplicateArtists',
    []
  );

  const columnsConfig = [
    {
      headerText: intl.formatMessage({
        id: 'admin.artistMerge.artistTitle',
      }),
      columnWidth: 750,
    },
    {
      headerText: intl.formatMessage({
        id: 'admin.artistMerge.numberOfDuplicates',
      }),
      columnWidth: 200,
    },
    {
      headerText: '',
      columnWidth: 230,
    },
  ];

  const rowValues = (duplicateArtist: any) => [
    <TruncatedByCharText
      text={duplicateArtist.title}
      truncateLength={65}
      key={1}
    />,
    duplicateArtist.numArtists,
    <SelectArtistsFromSetLink
      title={duplicateArtist.title}
      setDuplicatedArtistTitle={setDuplicatedArtistTitle}
      toggleArtistMergeSelectArtistsFromSetModal={
        toggleArtistMergeSelectArtistsFromSetModal
      }
      key={1}
    />,
  ];

  const modalsContent = () => (
    <>
      {artistMergeSelectArtistsModal.isShowing && (
        <RoutableModal
          hide={artistMergeSelectArtistsModal.hide}
          initialRouteProps={{ refetchDuplicateArtists }}
          initialRouteName="artist-merge"
          dataQaidSuffix="admin-artist-merge"
        />
      )}
      {artistMergeSelectArtistsFromSetModal.isShowing && (
        <RoutableModal
          hide={artistMergeSelectArtistsFromSetModal.hide}
          initialRouteProps={{ refetchDuplicateArtists, duplicatedArtistTitle }}
          initialRouteName="artist-merge"
          dataQaidSuffix="admin-artist-merge"
        />
      )}
    </>
  );

  return (
    <Layout scrollDisabled={false}>
      <ListPageTemplate
        pageTitle={intl.formatMessage({
          id: 'admin.artistMerge.pageTitle',
        })}
        modalsContent={modalsContent}
      >
        <ListingHeader
          pageTitle={intl.formatMessage({
            id: 'admin.artistMerge.pageTitle',
          })}
          searchPlaceholder={intl.formatMessage({
            id: 'admin.artistMerge.textSearch.placeholder',
          })}
          searchValue={pageState.textSearchState}
          onSearch={pageState.handleTextSearch}
          dataQaidPrefix="artist-merge"
        />
        <TopNote>
          {intl.formatMessage({
            id: 'admin.artistMerge.topNote',
          })}
          <SelectArtistsLink
            onClick={() => toggleArtistMergeSelectArtistsModal()}
          >
            {intl.formatMessage({
              id: 'admin.artistMerge.selectArtists',
            })}
          </SelectArtistsLink>
        </TopNote>
        <div>
          <ListingControls
            sortOptions={sortOptions}
            orderBy={pageState.sortState.by}
            orderDirection={pageState.sortState.direction}
            totalRecords={
              dataDuplicateArtists &&
              dataDuplicateArtists.duplicateArtists.metadata.totalRecords
            }
            loading={loadingDuplicateArtists}
            onSort={pageState.handleSortChange}
            onReset={pageState.handleResetFilters}
            dataQaidPrefix="artist-merge"
          />
        </div>

        <ListTable
          columnsConfig={columnsConfig}
          rowValues={rowValues}
          dataQaidPrefix="artist-merge"
          data={duplicateArtists}
          loadingContent={
            loadingDuplicateArtists ? (
              <LoadingBlocks.Rectangle width="100%" height="300px" />
            ) : null
          }
          noResultsContent={
            <ListingNoResults
              entityName={intl.formatMessage({
                id: 'admin.artistMerge.noResultsEntityName',
              })}
              numResults={duplicateArtists.length}
              loading={loadingDuplicateArtists}
            />
          }
          footerContent={
            <ListingFooter
              numTotalRecords={get(
                dataDuplicateArtists,
                'duplicateArtists.metadata.totalRecords',
                undefined
              )}
              perPage={PER_PAGE}
              currentPage={pageState.page}
              onPageChange={pageState.handlePageChange}
              loading={loadingDuplicateArtists}
              dataQaidPrefix="artist-merge"
            />
          }
        />
      </ListPageTemplate>
    </Layout>
  );
};

const AdminArtistMergeWrapper: React.FC = () => (
  <ListPage config={pageStateConfig}>
    <AdminArtistMerge />
  </ListPage>
);

export default AdminArtistMergeWrapper;
