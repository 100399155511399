import { useQuery } from 'react-apollo';

import {
  GetGlobalSettingsData,
  GetGlobalSettingsVariables,
} from 'app/typings/globalSettings';
import { queryParams } from 'app/shared/utils/queries';
import { GET_GLOBAL_SETTINGS } from 'app/shared/graphql/globalSettings/queries';

export const GetGlobalSettings = (params: GetGlobalSettingsVariables = {}) => {
  return useQuery<GetGlobalSettingsData>(
    GET_GLOBAL_SETTINGS,
    queryParams(params)
  );
};
