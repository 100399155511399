import { Event, EventPreview, Property, PropertyWithSource } from 'app/typings';
import { merchandisingPropertyGroupNames } from 'app/shared/utils/events';
import { useFallbackCollection } from 'app/shared/utils/useFallbackCollection';

export const useMerchandisingProperties = ({
  event,
}: {
  event?: Event | EventPreview;
}) => {
  const eventCollection = useFallbackCollection({
    eventCollections: event?.eventCollections,
  });

  if (!event || !event.merchandisingProperties) {
    return null;
  }
  const { merchandisingProperties } = event;

  return merchandisingPropertyGroupNames.reduce(
    (acc: PropertyWithSource[], groupName) => {
      const eventMerchandisingProperty = merchandisingProperties.find(
        prop => prop.propertyGroupName === groupName
      );
      const collectionMerchandisingProperty:
        | Property
        | undefined = eventCollection?.merchandisingProperties?.find(
        prop => prop.propertyGroupName === groupName
      );

      if (eventMerchandisingProperty) {
        acc.push({ ...eventMerchandisingProperty, source: 'event' });
      } else if (collectionMerchandisingProperty) {
        acc.push({ ...collectionMerchandisingProperty, source: 'collection' });
      }
      return acc;
    },
    []
  );
};
