import React, { useEffect } from 'react';
import { Formik } from 'formik';
import styled, { css } from 'styled-components';

import { useCurrentTheme } from 'app/shared/theme';
import { refocusToFirstError } from 'app/shared/utils/form';
import {
  Caption,
  Overline,
} from 'app/shared/components/atoms/TypographyManualCSS';

const FlyoverFormGroupWrapper = styled.div``;

interface FlyoverFormGroupProps {
  label: string;
  required?: boolean;
  errorMsg?: string;
  children: any;
  dataQaId?: string;
  id?: string;
}

export const FlyoverFormGroup: React.FC<FlyoverFormGroupProps> = ({
  label,
  required,
  children,
  errorMsg,
  dataQaId,
  id,
}) => {
  const theme = useCurrentTheme();

  return (
    <FlyoverFormGroupWrapper id={id} data-qaid={dataQaId}>
      <Overline
        color={errorMsg ? theme.colors.redRedWine : undefined}
        data-qaid={`${dataQaId}-label`}
        spaceAfter={3}
      >
        {label}
        {required ? (
          <span
            style={{
              color: errorMsg ? theme.colors.redRedWine : theme.colors.primary,
            }}
          >
            *
          </span>
        ) : null}{' '}
      </Overline>
      {children}
      {errorMsg && (
        <Caption
          color={theme.colors.redRedWine}
          data-qaid={`${dataQaId}-error`}
        >
          {errorMsg}
        </Caption>
      )}
    </FlyoverFormGroupWrapper>
  );
};

const FlyoverFormContainerWrapper = styled.div<{
  paddingTop: string | undefined;
}>`
  ${({ paddingTop, theme }) => css`
    ${theme.media.xs`
      padding-top: ${theme.ruler[8]}px;
    `};

    ${theme.media.md`
      padding-top: ${paddingTop || theme.ruler[4]}px;
    `};
  `}
`;

interface FlyoverFormContainerProps {
  renderFormComponent: any;
  onSubmit: (values: any) => Promise<any> | void;
  formInitialValues: any;
  formSchema: any;
  dataQaId?: string;
  validateOnChange?: boolean;
  paddingTop?: string;
}

export const FlyoverFormContainer: React.FC<FlyoverFormContainerProps> = ({
  renderFormComponent,
  onSubmit,
  formInitialValues,
  formSchema,
  dataQaId,
  validateOnChange = false,
  paddingTop,
}) => {
  return (
    <>
      <FlyoverFormContainerWrapper data-qaid={dataQaId} paddingTop={paddingTop}>
        <Formik
          data-qaid="flyover-form-formik"
          initialValues={{
            ...formInitialValues,
          }}
          validationSchema={formSchema}
          onSubmit={onSubmit}
          validateOnBlur={false}
          validateOnChange={validateOnChange}
        >
          {formikProps => {
            // eslint-disable-next-line
            useEffect(() => {
              refocusToFirstError(formikProps.errors, formikProps.isSubmitting);
            });

            return (
              <>
                {renderFormComponent({
                  formikProps,
                })}
              </>
            );
          }}
        </Formik>
      </FlyoverFormContainerWrapper>
    </>
  );
};
