import React from 'react';
import styled, { css } from 'styled-components';

import { useCurrentTheme } from 'app/shared/theme';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { StreamlineIcon } from 'app/shared/components/atoms/StreamlineIcon';

interface Props {
  icon: any;
  label?: string;
  onClick?: () => void;
  isOpen?: boolean;
  isContained?: boolean;
  isCompact?: boolean;
  dataQaid?: string;
}

const StyledLabel = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.green600};
  `}
`;

interface IconLinkContainerProps {
  isOpen?: boolean;
  isContained?: boolean;
  isCompact?: boolean;
}

const IconLinkContainer = styled.a<IconLinkContainerProps>`
  ${({ theme, isOpen, isContained, isCompact }) => css`
    cursor: pointer;
    display: flex;
    flex-grow: 0;
    flex-direction: column;
    align-self: flex-end;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 5px;
    font-size: 10px;
    line-height: 150%;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: ${theme.colors.green600};
    height: ${theme.ruler[14]}px;
    width: ${theme.ruler[14]}px;
    border-radius: 10px;
    background: ${isOpen ? '#def2e7' : theme.colors.whiteDenim};
    margin: ${isContained ? '6px 5px 0 0' : '0'};
    i::before {
      color: ${theme.colors.green600};
    }

    ${isCompact &&
      css`
        width: auto;
        height: auto;
        background-color: transparent;
      `}

    ${theme.media.lg`
    justify-content: center;
    padding-bottom: 0px;
  `};
    ${theme.media.xl`
    justify-content: center;
    padding-bottom: 0px;
  `};
  `}
`;

const ActionIcon: React.FC<Props> = ({
  icon,
  label,
  onClick = () => {},
  isOpen = false,
  isContained = false,
  isCompact = false,
  dataQaid = '',
}) => {
  const theme = useCurrentTheme();

  return (
    <IconLinkContainer
      onClick={onClick}
      isOpen={isOpen}
      isContained={isContained}
      isCompact={isCompact}
      data-qaid={dataQaid}
    >
      <StreamlineIcon icon={icon} size={14} stroke={theme.colors.green600} />
      {label && (
        <>
          <Spacer mt={1} />
          <StyledLabel>{label}</StyledLabel>
        </>
      )}
    </IconLinkContainer>
  );
};

export default ActionIcon;
