import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { get } from 'app/shared/utils/get';
import { useTShirtSizeBreakpoint as useBreakpoint } from 'app/shared/utils/useBreakpoint';
import { Container } from 'app/shared/components/atoms/Container';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Body2, H3 } from 'app/shared/components/atoms/TypographyManualCSS';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import YouTubePlayer from 'app/shared/components/organisms/Prismic/YouTubePlayer';
import { DetailsInfoMainContainer } from 'app/admin/components/atoms/DetailContent';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

interface Props {
  contentProps?: any;
}

const BodyContainer = styled(Container)`
  padding: 40px 0px 0px 0px !important;
`;

const Title = styled(H3)`
  margin: 0;
  width: 100%;
  text-align: left;
`;

const VideoInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ArtistAllVideos: React.FC<Props> = ({ contentProps }) => {
  const intl = useIntl();
  const currentBreakpoint = useBreakpoint();
  const isMobile = currentBreakpoint === 'xs' || currentBreakpoint === 'sm';

  return (
    <ModalContentContainer>
      <DetailsInfoMainContainer>
        <DetailsHeader
          title={get(contentProps, 'artistData.title', '')}
          imageUrl={get(contentProps, 'artistData.imageUrl', '')}
          largeImageUrl={get(contentProps, 'artistData.largeImageUrl', '')}
          imageStyle="circle"
        />
        <BodyContainer>
          <Title>
            {intl.formatMessage({
              id: 'admin.artistDirectory.allVideos.title',
            })}
          </Title>
          <Spacer mb={10} />
          <Grid gap={isMobile ? '0' : '60px'}>
            {get(contentProps, 'artistData.videos', []).map(
              (video: any, idx: number) => {
                return (
                  <Col xs={12} sm={12} lg={4} xl={4} key={idx}>
                    <YouTubePlayer url={video.url} />
                    <Spacer mt={1} mb={2}>
                      <VideoInfo>
                        <div>
                          <Body2>{video.displayName}</Body2>
                          <Spacer mt={1} />
                          <Body2>{get(video, 'event.city.title', '')}</Body2>
                        </div>
                      </VideoInfo>
                    </Spacer>
                    {!isMobile && <Body2>{video.description}</Body2>}
                  </Col>
                );
              }
            )}
          </Grid>
        </BodyContainer>
      </DetailsInfoMainContainer>
    </ModalContentContainer>
  );
};

export default ArtistAllVideos;
