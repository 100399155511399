import React from 'react';
import styled, { css } from 'styled-components';

import { Textfield } from 'app/shared/components/atoms/Textfield';

interface DateProps {
  day?: number;
  month?: number;
  year?: number;
  label?: string;
  handleValueChange: Function;
}

const TextfieldNumber = styled(Textfield)`
  ${({ theme }) => css`
    min-width: 10px;
    margin-right: 4px;
    width: 33%;
    padding: 0px 8px;

    ${theme.media.xs`
      font-size: 16px;
    `};

    ${theme.media.md`
      font-size: 10px;
    `};
  `}
`;

const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 8px;
`;

const DateInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 10px;
  width: 100%;
`;

const DateLabelContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    font-weight: normal;
    margin-bottom: 8px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    line-height: 1.6;
    ${theme.media.xs`
      font-size: ${theme.fontSizes.overline.xs};
    `};

    ${theme.media.lg`
      font-size: ${theme.fontSizes.overline.lg};
    `};
  `}
`;

const DateInput: React.FC<DateProps> = ({
  day,
  month,
  year,
  label,
  handleValueChange,
}) => {
  let isStartDate = false;
  if (label?.toLowerCase() === 'start date') {
    isStartDate = true;
  }
  return (
    <DateInputContainer>
      <DateLabelContainer>{label}</DateLabelContainer>
      <DateContainer>
        <TextfieldNumber
          placeholder="YYYY"
          value={year || ''}
          pattern="[0-9]*"
          maxLength={4}
          onChange={e => {
            handleValueChange(
              isStartDate,
              'year',
              parseInt(e.target.value, 10) || undefined
            );
          }}
        />
        <TextfieldNumber
          placeholder="MM"
          value={month || ''}
          pattern="[0-9]*"
          maxLength={2}
          onChange={e => {
            handleValueChange(
              isStartDate,
              'month',
              parseInt(e.target.value, 10) || undefined
            );
          }}
        />
        <TextfieldNumber
          placeholder="DD"
          value={day || ''}
          pattern="[0-9]*"
          maxLength={2}
          onChange={e => {
            handleValueChange(
              isStartDate,
              'day',
              parseInt(e.target.value, 10) || undefined
            );
          }}
        />
      </DateContainer>
    </DateInputContainer>
  );
};

export default DateInput;
