import React from 'react';
import { useIntl } from 'react-intl';

import { Event } from 'app/typings';
import { NoneWrapper } from 'app/admin/components/atoms/None';

interface Props {
  event: Event;
}

const PartnerPromotionDisplayValue: React.FC<Props> = ({ event }) => {
  const intl = useIntl();
  if (!event.partnerPromotionSectionEnabled) {
    return (
      <>
        <NoneWrapper>
          {intl.formatMessage({
            id: 'no',
          })}
        </NoneWrapper>
        <div>
          {intl.formatMessage({
            id: 'admin.eventPlanner.partnerPromotion.displayValueFalse',
          })}
        </div>
      </>
    );
  }

  const partnerPromotionImages = event.images?.partnerPromotion?.ratio16x9;

  if (!partnerPromotionImages || partnerPromotionImages.length === 0) {
    return (
      <>
        <NoneWrapper>
          {intl.formatMessage({
            id: 'no',
          })}
        </NoneWrapper>
        <div>
          {intl.formatMessage({
            id: 'admin.eventPlanner.partnerPromotion.displayValueMissingImage',
          })}
        </div>
      </>
    );
  }

  return (
    <>
      <div>
        {intl.formatMessage({
          id: 'yes',
        })}
      </div>
      <div>
        {intl.formatMessage({
          id: 'admin.eventPlanner.partnerPromotion.displayValueWillDisplay',
        })}
      </div>
    </>
  );
};

export default PartnerPromotionDisplayValue;
