import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { get } from 'app/shared/utils/get';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { UpdatePrimaryRole } from 'app/admin/graphql/roles/mutationHooks';
import { DetailsFormMainContainer } from 'app/admin/components/atoms/DetailContent';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

import PrimaryRoleBasicInfoEditForm from './PrimaryRoleBasicInfoEditForm';
import PrimaryRoleBasicInfoEditFormSchema from './PrimaryRoleBasicInfoEditFormSchema';

interface Props {
  contentProps?: any;
  setFormSubmitAction: (func: Function) => void;
  setDisplayConfirmation: (set: boolean) => void;
  setIsSubmitting: (set: boolean) => void;
  navigateTo: (routeData: object) => void;
}

const PrimaryRoleBasicInfoEdit: React.FC<Props> = ({
  contentProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  navigateTo,
}) => {
  const intl = useIntl();

  const [validationErrors, setValidationErrors] = useState<object>({});

  const formInitialValues = {
    displayName: get(contentProps, 'displayName', '') || '',
    shortDisplayName: get(contentProps, 'shortDisplayName', '') || '',
  };

  const updatePrimaryRoleAction = UpdatePrimaryRole();

  const handleSubmit = useSubmitAction({
    submitAction: updatePrimaryRoleAction,
    submitVariables: (values: any) => ({
      primaryRoleId: get(contentProps, 'id', undefined),
      displayName: values.displayName,
      shortDisplayName: values.shortDisplayName,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.primaryRoleBasicInfo.form.successMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.primaryRoleBasicInfo.form.failureMessage',
    }),
    onSuccess: () => {
      navigateTo({
        routeName: 'primary-roles',
        routeProps: {},
      });
    },
    onValidationError: validationErrors =>
      setValidationErrors(validationErrors),
    validationErrorKeysForDisplay: { display_name: 'name' },
  });

  return (
    <ModalContentContainer>
      <DetailsFormMainContainer>
        <DetailsHeader
          title={`${intl.formatMessage({
            id: 'admin.primaryRoleBasicInfo.title',
          })} ${get(contentProps, 'displayName', '')}`}
        />
        <GenericForm
          formInitialValues={formInitialValues}
          renderFormComponent={(renderProps: any) => (
            <PrimaryRoleBasicInfoEditForm
              formikProps={renderProps.formikProps}
              validationErrors={validationErrors}
            />
          )}
          onSubmit={handleSubmit}
          formSchema={PrimaryRoleBasicInfoEditFormSchema(intl)}
          setFormSubmitAction={setFormSubmitAction}
          setDisplayConfirmation={setDisplayConfirmation}
          setIsSubmitting={setIsSubmitting}
          dataQaId="primary-role-edit-form"
        />
      </DetailsFormMainContainer>
    </ModalContentContainer>
  );
};

export default PrimaryRoleBasicInfoEdit;
