import * as Yup from 'yup';

import { maxEventPriceMainUnits } from 'app/admin/utils/events';
import {
  maxTicketsPerAttendeeMaxValue,
  maxTicketsPerAttendeeMinValue,
} from 'app/admin/utils/eventTicketsConfig';

const EventDetailsTicketingEditFormSchema = (intl: any, venueId: number) =>
  Yup.object().shape({
    numTicketsAvailableForSale: Yup.number()
      .required(
        intl.formatMessage({
          id: 'form.requiredField',
        })
      )
      .typeError(
        intl.formatMessage({
          id: 'form.pleaseEnterANumber',
        })
      )
      .min(0)
      .test(
        'greater-than-capacity',
        intl.formatMessage({
          id: 'admin.eventBasicInfo.tafsGreaterThanCapacityError',
        }),
        function(value: number) {
          if (!venueId) {
            return true;
          }
          const capacity = this.parent.capacity;
          return value <= capacity;
        }
      )
      .nullable(),
    ticketPrice: Yup.string()
      .nullable()
      .matches(/^\d+$/, {
        message: intl.formatMessage({
          id: 'admin.eventBasicInfo.form.numberPriceError',
        }),
      })
      .test(
        'ticket-price-too-large',
        intl.formatMessage(
          {
            id: 'admin.eventBasicInfo.form.numberPriceTooLargeError',
          },
          {
            maxPrice: String(maxEventPriceMainUnits),
          }
        ),
        function(value: string) {
          return (
            (!value && Number(value) != 0) ||
            Number(value) <= maxEventPriceMainUnits
          );
        }
      ),
    eventBookingFee: Yup.string()
      .nullable()
      .trim()
      .matches(/^\d+\.\d\d$/, {
        message: intl.formatMessage({
          id: 'admin.eventBasicInfo.form.bookingFeeError',
        }),
      }),
    maxTicketsPerAttendee: Yup.string()
      .required(
        intl.formatMessage({
          id: 'form.requiredField',
        })
      )
      .matches(/^\d+$/, {
        message: intl.formatMessage({
          id: 'form.numberError',
        }),
      })
      .test(
        'isValidNumber',
        intl.formatMessage(
          {
            id: 'admin.citySettings.form.maxTicketsPerAttendeeError',
          },
          {
            min_tickets: maxTicketsPerAttendeeMinValue,
            max_tickets: maxTicketsPerAttendeeMaxValue,
          }
        ),
        function(value) {
          return value
            ? Number(value) >= maxTicketsPerAttendeeMinValue &&
                Number(value) <= maxTicketsPerAttendeeMaxValue
            : true;
        }
      ),
    maximumPromoCodeUses: Yup.string()
      .matches(/^\d+$/, {
        message: intl.formatMessage({
          id: 'admin.eventTicketingInfo.numberError',
        }),
      })
      .test(
        'isValidNumber',
        intl.formatMessage({
          id: 'admin.eventTicketingInfo.numPromoCodeUsesError',
        }),
        function(value) {
          return value ? Number(value) >= 0 && Number(value) <= 1000 : true;
        }
      ),
    maximumGuaranteedPromoCodeUses: Yup.string()
      .matches(/^\d+$/, {
        message: intl.formatMessage({
          id: 'admin.eventTicketingInfo.numberError',
        }),
      })
      .test(
        'isValidNumber',
        intl.formatMessage({
          id: 'admin.eventTicketingInfo.numPromoCodeUsesError',
        }),
        function(value) {
          return value ? Number(value) >= 0 && Number(value) <= 1000 : true;
        }
      ),
    maximumCustomPromoCodeUses: Yup.string()
      .matches(/^\d+$/, {
        message: intl.formatMessage({
          id: 'admin.eventTicketingInfo.numberError',
        }),
      })
      .test(
        'isValidNumber',
        intl.formatMessage({
          id: 'admin.eventTicketingInfo.numPromoCodeUsesError',
        }),
        function(value) {
          return value ? Number(value) >= 0 && Number(value) <= 1000 : true;
        }
      ),
    maximumPercentagePromoCodeUses: Yup.string()
      .matches(/^\d+$/, {
        message: intl.formatMessage({
          id: 'admin.eventTicketingInfo.numberError',
        }),
      })
      .test(
        'isValidNumber',
        intl.formatMessage({
          id: 'admin.eventTicketingInfo.numPromoCodeUsesError',
        }),
        function(value) {
          return value ? Number(value) >= 0 && Number(value) <= 1000 : true;
        }
      ),
    dealAmount: Yup.number().nullable(),
  });

export default EventDetailsTicketingEditFormSchema;
