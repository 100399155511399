import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import {
  AnchorOrigin,
  calculatePopperPosition,
  KeepInViewPort,
  Offset,
  PopoverDomEl,
  TransformOrigin,
} from 'app/shared/utils/calculatePopperPosition';
import { getPosition } from 'app/shared/utils/usePosition';
import useScrollPosition from 'app/shared/utils/useScrollPosition';
import useWindowSize from 'app/shared/utils/useWindowSize';

export interface PopperProps {
  anchorOrigin?: AnchorOrigin;
  transformOrigin?: TransformOrigin;
  offset?: Offset;
  keepInViewPort?: KeepInViewPort | boolean;
  flip?: boolean;
  reactToChange?: number | boolean;
  width?: string;
  zIndex?: number | null;
}

interface Props extends PopperProps {
  anchorEl: any;
  containerEl?: any;
  children: any;
}

interface CalculatedPosition {
  x: number;
  y: number;
  width: number;
  height: number;
  isFlipped: boolean;
}

const Container = styled.div<{ ref?: any }>`
  ${({ theme }) => css`
    z-index: ${theme.zIndex.navbar + 10};
    position: fixed;

    ${theme.media.md`
      z-index: ${theme.zIndex.navbar - 10};
    `}
  `}
`;

export const Popper: React.FC<Props> = ({
  anchorEl,
  containerEl,
  anchorOrigin = { vertical: 'bottom', horizontal: 'left' },
  transformOrigin = { vertical: 'top', horizontal: 'left' },
  offset,
  keepInViewPort = false,
  flip = false,
  children,
  reactToChange,
  width,
  zIndex = 500,
}) => {
  const popoverRef = useRef<HTMLDivElement>();
  const [calculatedPosition, setCalculatedPosition] = useState<
    CalculatedPosition
  >({
    x: -10000,
    y: -10000,
    width: 0,
    height: 0,
    isFlipped: false,
  });
  const [popoverElRect, setPopoverElRect] = useState<PopoverDomEl>({
    width: 0,
    height: 0,
  });
  const windowSize = useWindowSize();

  const updateCalculatedPosition = useCallback(
    (updatedAnchorEl: React.RefObject<HTMLDivElement>) => {
      if (updatedAnchorEl.current) {
        const pos = getPosition(updatedAnchorEl.current);

        const calculated = calculatePopperPosition(
          windowSize,
          pos,
          anchorOrigin,
          transformOrigin,
          popoverElRect,
          keepInViewPort,
          flip,
          offset
        );

        setCalculatedPosition(calculated);
      }
    },
    [
      windowSize,
      anchorOrigin,
      transformOrigin,
      popoverElRect,
      offset,
      keepInViewPort,
      flip,
    ]
  );

  useEffect(() => {
    if (popoverRef.current) {
      setPopoverElRect(getPosition(popoverRef.current));
    }
  }, [popoverRef, reactToChange]);

  useEffect(() => {
    updateCalculatedPosition(anchorEl);
  }, [
    anchorEl,
    popoverElRect,
    anchorOrigin,
    transformOrigin,
    updateCalculatedPosition,
    windowSize,
  ]);

  useScrollPosition(
    () => {
      if (popoverRef.current) {
        setPopoverElRect(getPosition(popoverRef.current));
      }
      updateCalculatedPosition(anchorEl);
    },
    [],
    containerEl,
    !containerEl
  );

  const { y, x, isFlipped, width: anchorElWidth } = calculatedPosition;

  if (typeof children === 'function') {
    return children({
      ref: popoverRef,
      isFlipped,
      style: {
        top: `${y}px`,
        left: `${x}px`,
        position: 'fixed',
        width: width === 'auto' ? anchorElWidth : width,
        zIndex,
      },
    });
  }

  return (
    <Container
      ref={popoverRef}
      style={{
        top: `${y}px`,
        left: `${x}px`,
        position: 'fixed',
        width: width === 'auto' ? anchorElWidth : width,
        zIndex: zIndex === null ? undefined : zIndex,
      }}
    >
      {typeof children === 'function' ? children({ isFlipped }) : children}
    </Container>
  );
};
