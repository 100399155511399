import React from 'react';
import styled, { css } from 'styled-components';

import { ArrowLeftIcon } from 'app/shared/components/atoms/IconLibrary';
import { Image } from 'app/shared/components/atoms/ImageManualCSS';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';

interface Props {
  contentProps?: any;
  hide: any;
}

const MainContainer = styled.div`
  padding: 15px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  padding: 0px 50px 20px 50px;
`;

const BackLinkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  cursor: pointer;
`;

const BackLinkArrow = styled.div``;

const BackLinkText = styled.div`
  ${({ theme }) => css`
    font-size: 10px;
    line-height: 150%;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: ${theme.colors.primary};
    padding-left: 12px;
  `}
`;

interface FullImageWrapperProps {
  paddingTop: string;
}

const FullImageWrapper = styled.div<FullImageWrapperProps>`
  width: auto;
  height: 600px;
  padding-top: ${props => props.paddingTop};
  overflow: hidden;
`;

const FullImage = styled(Image)`
  width: auto;
  height: 600px;
`;

const LargeImage: React.FC<Props> = ({ contentProps, hide }) => {
  return (
    <ModalContentContainer>
      <MainContainer>
        <ContentContainer>
          {contentProps.backButtonText && (
            <BackLinkWrapper onClick={hide}>
              <BackLinkArrow>
                <ArrowLeftIcon />
              </BackLinkArrow>
              <BackLinkText>{contentProps.backButtonText}</BackLinkText>
            </BackLinkWrapper>
          )}
          <FullImageWrapper
            paddingTop={contentProps.backButtonText ? '10px' : '30px'}
          >
            {contentProps.largeImageUrl && (
              <FullImage
                src={contentProps.largeImageUrl}
                width="100%"
                data-qaid={`${contentProps.dataQaidPrefix}-large-image`}
              />
            )}
          </FullImageWrapper>
        </ContentContainer>
      </MainContainer>
    </ModalContentContainer>
  );
};

export default LargeImage;
