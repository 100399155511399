import { useIntl } from 'react-intl';

import { Event, EventPreview } from 'app/typings';
import { useFallbackCollection } from 'app/shared/utils/useFallbackCollection';

export const useMerchandisingHeadlineAndDescription = ({
  event,
}: {
  event?: Event | EventPreview;
}) => {
  const { formatMessage } = useIntl();
  const eventCollection = useFallbackCollection({
    eventCollections: event?.eventCollections,
  });

  if (!event) {
    return {
      title: '',
      description: '',
      descriptionSource: '',
      headline: '',
      headlineSource: '',
    };
  }

  const defaultValues = {
    description: formatMessage({
      id: 'event.eventCharacteristicsList.aboutThisConcertDefault',
    }),
    headline: formatMessage({
      id: 'event.eventCharacteristicsList.aboutThisConcert',
    }),
  };

  const title = eventCollection?.title || '';

  const description =
    event.externalEventNotes ||
    eventCollection?.eventDescription ||
    eventCollection?.description ||
    defaultValues.description;

  const headline =
    event.headline || eventCollection?.eventHeadline || defaultValues.headline;

  const descriptionSource = event.externalEventNotes
    ? 'eventCustom'
    : eventCollection?.eventDescription
    ? 'collection'
    : eventCollection?.description
    ? 'collection'
    : 'eventDefault';

  const headlineSource = event.headline
    ? 'eventCustom'
    : eventCollection?.eventHeadline
    ? 'collection'
    : 'eventDefault';

  return {
    title,
    description,
    descriptionSource,
    headline,
    headlineSource,
  };
};
