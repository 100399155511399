import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { getIdsSubmitVariable } from 'app/shared/utils/form';
import { get } from 'app/shared/utils/get';
import useModal from 'app/shared/utils/useModal';
import { GetCitiesLite } from 'app/shared/graphql/cities/queryHooks';
import { ListPage, ListPageContext } from 'app/shared/context/ListPage';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import { LoadingBlocks } from 'app/shared/components/atoms/LoadingBlocks';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import RoutableModal from 'app/shared/components/molecules/RoutableModal';
import { GlobalError } from 'app/shared/components/pages/Status';
import { CityInfoForLimitingByCity } from 'app/admin/utils/cityPermissions';
import {
  filterInfoListFilteredByCheckOnFilterName,
  getCityOptions,
  getFilterNamesAndOptionsForTitleMapping,
} from 'app/admin/utils/optionLists';
import { GetBookingRequests } from 'app/admin/graphql/bookingRequests/queryHooks';
import { CityTitles as CityTitlesLimitedBy } from 'app/admin/components/atoms/CityTitles';
import ListingFooter from 'app/admin/components/molecules/ListingFooter';
import ListingNoResults from 'app/admin/components/molecules/ListingNoResults';
import BookingRequestCard from 'app/admin/components/organisms/BookingRequestCard';
import CardGrid, { CardType } from 'app/admin/components/organisms/CardGrid';
import ListingControls from 'app/admin/components/organisms/ListingControls';
import ListingFilter from 'app/admin/components/organisms/ListingFilter';
import ListingHeader from 'app/admin/components/organisms/ListingHeader';
import ListPageTemplate from 'app/admin/components/templates/ListPage';
import Layout from 'app/admin/layouts/ListPage';

import { sortOptions, staticOptions } from './options';
import Analytics from './tracking';

const pageStateConfig = {
  filterNames: ['city', 'status', 'availability_dates'],
  defaultSort: { by: 'created_at', direction: 'desc' },
  textSearchParamName: 'booking_request_search',
};

const AdminBookingRequests: React.FC = () => {
  const intl = useIntl();
  const pageState = useContext(ListPageContext);
  const PER_PAGE = 18;

  const {
    loading: loadingCities,
    error: errorCities,
    data: dataCities,
  } = GetCitiesLite();

  const { cityIdsToLimitBy, cityTitlesToLimitBy } = CityInfoForLimitingByCity(
    'artist.bookingRequestList.viewByCity',
    dataCities
  );

  const cityOptions = getCityOptions(dataCities);

  const filterDropdownOptionsInfoList = filterInfoListFilteredByCheckOnFilterName(
    [
      {
        filterName: 'city',
        dropdownParams: {
          // @ts-ignore
          searchBar: true,
          title: intl.formatMessage({
            id: 'admin.bookingRequests.filter.city',
          }),
          groupBy: 'country',
          options: cityOptions,
        },
      },
      {
        filterName: 'status',
        dropdownParams: {
          title: intl.formatMessage({
            id: 'admin.bookingRequests.filter.acceptedDeclined',
          }),
          options: staticOptions.status,
        },
      },
      {
        filterName: 'availability_dates',
        dropdownParams: {
          title: intl.formatMessage({
            id: 'admin.bookingRequests.filter.availability',
          }),
          options: staticOptions.availabilityDates,
        },
      },
    ],
    {
      city: !cityIdsToLimitBy,
    }
  );

  const [
    viewArtistTourRequestModal,
    toggleViewArtistTourRequestModal,
  ] = useModal();
  const [
    acceptBookingRequestModal,
    toggleAcceptBookingRequestModal,
  ] = useModal();
  const [bookingRequestData, setBookingRequestData] = useState<any>({});

  const {
    loading,
    error,
    data,
    refetch: refetchBookingRequests,
  } = GetBookingRequests({
    city: !cityIdsToLimitBy ? pageState.filterListVariable('city') : undefined,
    cityIds: getIdsSubmitVariable(cityIdsToLimitBy),
    status: pageState.filterListVariable('status') || 'received',
    availabilityDates: pageState.filterListVariable('availability_dates'),
    bookingRequestSearch: pageState.textSearchState,
    orderBy: pageState.sortState.by,
    orderDirection: pageState.sortState.direction,
    page: pageState.page,
    perPage: PER_PAGE,
  });

  useEffect(() => {
    pageState.setupFilterLabelTitleMapping(
      getFilterNamesAndOptionsForTitleMapping(filterDropdownOptionsInfoList)
    );
  }, [dataCities]);

  useEffect(() => {
    pageState.updatePageUrl();
  }, [
    pageState.page,
    pageState.sortState,
    pageState.filterState,
    pageState.textSearchState,
    pageState.detailData,
    pageState.detailsModal.isShowing,
  ]);

  useEffect(() => {
    Analytics.pushDataLayer();
  }, [pageState.filterState, pageState.textSearchState]);

  if (
    (!loading && !data) ||
    error ||
    (!loadingCities && !dataCities) ||
    errorCities
  ) {
    return <GlobalError />;
  }

  const modalsContent = () => (
    <>
      {viewArtistTourRequestModal.isShowing && (
        <RoutableModal
          hide={viewArtistTourRequestModal.hide}
          initialRouteProps={bookingRequestData}
          initialRouteName="booking-request-tour-request"
          dataQaidSuffix="admin-request-artist-tour"
        />
      )}
      {acceptBookingRequestModal.isShowing && (
        <RoutableModal
          hide={acceptBookingRequestModal.hide}
          initialRouteProps={bookingRequestData}
          initialRouteName="booking-request-accept"
          dataQaidSuffix="admin-accept-booking-request"
        />
      )}
    </>
  );

  return (
    <Layout scrollDisabled={pageState.detailsModal.isShowing}>
      <ListPageTemplate
        pageTitle={intl.formatMessage({
          id: 'admin.bookingRequests.pageTitle',
        })}
        modalsContent={modalsContent}
      >
        <ListingHeader
          pageTitle={intl.formatMessage({
            id: 'admin.bookingRequests.pageTitle',
          })}
          searchPlaceholder={intl.formatMessage({
            id: 'admin.bookingRequests.textSearch.placeholder',
          })}
          searchValue={pageState.textSearchState}
          onSearch={pageState.handleTextSearch}
          dataQaidPrefix="booking-request"
        />
        <CityTitlesLimitedBy cityTitles={cityTitlesToLimitBy} />
        <div>
          <ListingControls
            sortOptions={sortOptions}
            orderBy={pageState.sortState.by}
            orderDirection={pageState.sortState.direction}
            totalRecords={data && data.bookingRequests.metadata.totalRecords}
            loading={loading}
            onSort={pageState.handleSortChange}
            onReset={pageState.handleResetFilters}
            dataQaidPrefix="booking-request"
          />
          <ListingFilter
            filterTitle={intl.formatMessage({
              id: 'admin.bookingRequests.filterTitle',
            })}
            textSearchString={pageState.textSearchState}
            handleTextSearchLabelClose={pageState.handleTextSearchLabelClose}
            labelTitleMapping={pageState.filterLabelTitleMapping}
            dropdownOptionsInfoList={filterDropdownOptionsInfoList}
            filterState={pageState.filterState}
            handleRemoveFilter={pageState.handleRemoveFilter}
            handleFilterChange={pageState.handleFilterChange}
          />
        </div>

        <div>
          <CardGrid
            objectData={get(data, 'bookingRequests.bookingRequests', [])}
            renderCardComponent={(bookingRequest: CardType, i: number) => (
              // @ts-ignore
              <BookingRequestCard
                index={i}
                onViewArtistTourRequest={(bookingRequestData: any) => {
                  setBookingRequestData(bookingRequestData);
                  toggleViewArtistTourRequestModal();
                }}
                onAcceptBookingRequest={(bookingRequestData: any) => {
                  setBookingRequestData(bookingRequestData);
                  toggleAcceptBookingRequestModal();
                }}
                refetchBookingRequests={refetchBookingRequests}
                {...bookingRequest}
              />
            )}
            dataQaid="admin-booking-requests-list"
            loading={loading}
            loadingComponent={
              <LoadingBlocks.Rectangle width="100%" height="420px" />
            }
            hideDividerOnSize="xs"
          />
          <DottedLine />
          <Spacer mb={2} />
        </div>

        <ListingNoResults
          entityName={intl.formatMessage({
            id: 'admin.bookingRequests.noResultsEntityName',
          })}
          numResults={get(
            data,
            'bookingRequests.bookingRequests.length',
            undefined
          )}
          loading={loading}
        />

        <ListingFooter
          numTotalRecords={get(
            data,
            'bookingRequests.metadata.totalRecords',
            undefined
          )}
          perPage={PER_PAGE}
          currentPage={pageState.page}
          onPageChange={pageState.handlePageChange}
          loading={loading}
          dataQaidPrefix="booking-requests"
        />
      </ListPageTemplate>
    </Layout>
  );
};

const AdminBookingRequestsWrapper: React.FC = () => (
  <ListPage config={pageStateConfig} analytics={Analytics}>
    <AdminBookingRequests />
  </ListPage>
);

export default AdminBookingRequestsWrapper;
