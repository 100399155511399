import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { City } from 'app/typings/cities';
import { currencyFormatter } from 'app/shared/utils/currencyFormatter';
import { COMMERCIAL_PARTNERSHIP_EVENT } from 'app/shared/utils/events';
import { errorMsgForField } from 'app/shared/utils/form';
import usePermission from 'app/shared/utils/usePermission';
import {
  FormGroupContainer,
  FormSectionTitle,
} from 'app/shared/components/atoms/FormContent';
import GenericFormContainer from 'app/shared/components/atoms/GenericFormContainer';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import { BaseLink } from 'app/shared/components/atoms/LinkManualCSS';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import FormGroup from 'app/shared/components/molecules/FormGroup';
import { renderPriceHelperText } from 'app/admin/components/molecules/EventPlannerUtils';
import { NumberTextfield } from 'app/admin/components/molecules/Textfield';
import { BigNumberTextfield } from 'app/admin/components/molecules/Textfield';
import TicketPriceFormField from 'app/admin/components/molecules/TicketPriceFormField';

const StyledLink = styled(BaseLink)`
  ${({ theme }) => css`
    color: ${theme.colors.green600};
  `}
`;

const HelperText = styled.div`
  font-size: 14px;
  font-style: italic;
  margin-top: 10px;
  margin-bottom: 15px;
`;

interface FormProps {
  formikProps: any;
  venueId?: number;
  eventType?: string;
  city?: City;
}

interface PromoCodeFieldOverrideHelperTextProps {
  cityValue?: number;
}

const PromoCodeFieldOverrideHelperText: React.FC<PromoCodeFieldOverrideHelperTextProps> = ({
  cityValue,
}) => {
  const intl = useIntl();

  return (
    <HelperText>
      {intl.formatMessage(
        {
          id: 'admin.eventTicketingInfo.promoCodeFieldOverrideHelperText',
        },
        { cityValue }
      )}
    </HelperText>
  );
};

const EventDetailsTicketingEditForm: React.FC<FormProps> = ({
  formikProps,
  venueId,
  eventType,
  city,
}) => {
  const intl = useIntl();

  const hasViewVenueDirectoryPermission = usePermission('venue.list.access');

  const { setFieldValue, values, errors, touched } = formikProps;

  return (
    <GenericFormContainer dataQaId="event-ticketing-section-edit-form">
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'shared.capacity',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        {venueId ? (
          <Grid>
            <Col lg={1} md={2} sm={3} xs={3}>
              {values.capacity}
            </Col>
            {hasViewVenueDirectoryPermission && (
              <Col lg={11} md={10} sm={9} xs={9}>
                <StyledLink
                  href={`/admin/venues?venue_id=${venueId}`}
                  openInNewTab={true}
                >
                  {intl.formatMessage({
                    id: 'admin.eventVenueInfo.editInVenueDirectory',
                  })}
                </StyledLink>
              </Col>
            )}
          </Grid>
        ) : (
          <>{intl.formatMessage({ id: 'admin.notApplicable' })}</>
        )}
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.shared.ticketsAvailable',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="event-ticketing-form-tickets-available-formgroup"
          label={intl.formatMessage({
            id: 'admin.shared.ticketsAvailable',
          })}
          errorMsg={errorMsgForField(
            'numTicketsAvailableForSale',
            touched,
            errors
          )}
          required
        >
          <NumberTextfield
            data-qaid="event-ticketing-form-tickets-available-field"
            id="numTicketsAvailableForSale"
            name="numTicketsAvailableForSale"
            value={values.numTicketsAvailableForSale}
            onChange={(e: any) => {
              setFieldValue('numTicketsAvailableForSale', e.target.value);
            }}
            maxLength={4}
          />
        </FormGroup>
      </FormGroupContainer>
      {values.attendeeFlow == 'buy' && (
        <>
          <FormSectionTitle>
            {intl.formatMessage({
              id: 'admin.eventTicketingInfo.ticketPrice',
            })}
          </FormSectionTitle>
          <FormGroupContainer>
            <TicketPriceFormField
              fieldName="ticketPrice"
              currencySymbol={values.currencySymbol}
              price={values.ticketPrice}
              priceHelperText={renderPriceHelperText(intl, values)}
              touched={touched}
              errors={errors}
              setFieldValue={setFieldValue}
            />
            <Spacer mt={6} />
            <FormGroup
              data-qaid="event-ticketing-form-booking-fee-formgroup"
              label={
                values.cityBookingFee || values.cityBookingFee == 0
                  ? intl.formatMessage(
                      {
                        id: 'admin.eventTicketingInfo.bookingFeeWithCityValue',
                      },
                      {
                        cityBookingFee: currencyFormatter(
                          values.cityBookingFee || 0,
                          values.currencySymbol || '$'
                        ),
                      }
                    )
                  : values.countryBookingFee || values.countryBookingFee == 0
                  ? intl.formatMessage(
                      {
                        id:
                          'admin.eventTicketingInfo.bookingFeeWithCountryValue',
                      },
                      {
                        countryBookingFee: currencyFormatter(
                          values.countryBookingFee || 0,
                          values.currencySymbol || '$'
                        ),
                      }
                    )
                  : intl.formatMessage({
                      id: 'admin.eventTicketingInfo.bookingFee',
                    })
              }
              errorMsg={errorMsgForField('eventBookingFee', touched, errors)}
            >
              <BigNumberTextfield
                data-qaid="event-ticketing-form-booking-fee"
                id="eventBookingFee"
                name="eventBookingFee"
                value={values.eventBookingFee}
                onChange={(e: any) => {
                  setFieldValue('eventBookingFee', e.target.value);
                }}
                maxLength={10}
              />
            </FormGroup>
          </FormGroupContainer>
        </>
      )}
      {values.attendeeFlow == 'buy' && (
        <>
          <FormSectionTitle>
            {intl.formatMessage({
              id: 'admin.shared.ticketingInvites',
            })}
          </FormSectionTitle>
          <FormGroupContainer>
            <FormGroup
              data-qaid="event-ticketing-form-max-tickets-per-attendee-formgroup"
              label={intl.formatMessage({
                id: 'admin.shared.maxTicketsPerAttendee',
              })}
              errorMsg={errorMsgForField(
                'maxTicketsPerAttendee',
                touched,
                errors
              )}
              required
            >
              <NumberTextfield
                data-qaid="event-ticketing-form-max-tickets-per-attendee-field"
                id="maxTicketsPerAttendee"
                name="maxTicketsPerAttendee"
                value={values.maxTicketsPerAttendee}
                onChange={(e: any) => {
                  setFieldValue('maxTicketsPerAttendee', e.target.value);
                }}
                maxLength={2}
              />
            </FormGroup>
          </FormGroupContainer>
        </>
      )}
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.eventTicketingInfo.promoCodes',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="event-ticketing-form-maximum-promo-code-uses-formgroup"
          label={intl.formatMessage({
            id: 'admin.eventTicketingInfo.maximumPromoCodeUses',
          })}
          errorMsg={errorMsgForField('maximumPromoCodeUses', touched, errors)}
        >
          <Grid>
            <Col lg={1} md={2} sm={2} xs={12}>
              <NumberTextfield
                data-qaid="event-ticketing-form-maximum-promo-code-uses-field"
                id="maximumPromoCodeUses"
                name="maximumPromoCodeUses"
                value={values.maximumPromoCodeUses}
                onChange={(e: any) => {
                  setFieldValue('maximumPromoCodeUses', e.target.value);
                }}
                maxLength={4}
              />
            </Col>
            <Col lg={11} md={10} sm={10} xs={12}>
              <PromoCodeFieldOverrideHelperText
                cityValue={city && city.maximumPromoCodeUses}
              />
            </Col>
          </Grid>
        </FormGroup>
        <Spacer mb={6} />
        <FormGroup
          data-qaid="event-ticketing-form-maximum-percentage-promo-code-uses-formgroup"
          label={intl.formatMessage({
            id: 'admin.eventTicketingInfo.maximumPercentagePromoCodeUses',
          })}
          errorMsg={errorMsgForField(
            'maximumPercentagePromoCodeUses',
            touched,
            errors
          )}
        >
          <Grid>
            <Col lg={1} md={2} sm={2} xs={12}>
              <NumberTextfield
                data-qaid="event-ticketing-form-maximum-percentage-promo-code-uses-field"
                id="maximumPercentagePromoCodeUses"
                name="maximumPercentagePromoCodeUses"
                value={values.maximumPercentagePromoCodeUses}
                onChange={(e: any) => {
                  setFieldValue(
                    'maximumPercentagePromoCodeUses',
                    e.target.value
                  );
                }}
                maxLength={4}
              />
            </Col>
            <Col lg={11} md={10} sm={10} xs={12}>
              <PromoCodeFieldOverrideHelperText
                cityValue={city && city.maximumPercentagePromoCodeUses}
              />
            </Col>
          </Grid>
        </FormGroup>
        <Spacer mb={6} />
        <FormGroup
          data-qaid="event-ticketing-form-maximum-guaranteed-promo-code-uses-formgroup"
          label={intl.formatMessage({
            id: 'admin.eventTicketingInfo.maximumGuaranteedPromoCodeUses',
          })}
          errorMsg={errorMsgForField(
            'maximumGuaranteedPromoCodeUses',
            touched,
            errors
          )}
        >
          <Grid>
            <Col lg={1} md={2} sm={2} xs={12}>
              <NumberTextfield
                data-qaid="event-ticketing-form-maximum-guaranteed-promo-code-uses-field"
                id="maximumGuaranteedPromoCodeUses"
                name="maximumGuaranteedPromoCodeUses"
                value={values.maximumGuaranteedPromoCodeUses}
                onChange={(e: any) => {
                  setFieldValue(
                    'maximumGuaranteedPromoCodeUses',
                    e.target.value
                  );
                }}
                maxLength={4}
              />
            </Col>
            <Col lg={11} md={10} sm={10} xs={12}>
              <PromoCodeFieldOverrideHelperText
                cityValue={city && city.maximumGuaranteedPromoCodeUses}
              />
            </Col>
          </Grid>
        </FormGroup>
        <Spacer mb={6} />
        <FormGroup
          data-qaid="event-ticketing-form-maximum-custom-promo-code-uses-formgroup"
          label={intl.formatMessage({
            id: 'admin.eventTicketingInfo.maximumCustomPromoCodeUses',
          })}
          errorMsg={errorMsgForField(
            'maximumCustomPromoCodeUses',
            touched,
            errors
          )}
        >
          <Grid>
            <Col lg={1} md={2} sm={2} xs={12}>
              <NumberTextfield
                data-qaid="event-ticketing-form-maximum-custom-promo-code-uses-field"
                id="maximumCustomPromoCodeUses"
                name="maximumCustomPromoCodeUses"
                value={values.maximumCustomPromoCodeUses}
                onChange={(e: any) => {
                  setFieldValue('maximumCustomPromoCodeUses', e.target.value);
                }}
                maxLength={4}
              />
            </Col>
            <Col lg={11} md={10} sm={10} xs={12}>
              <PromoCodeFieldOverrideHelperText
                cityValue={city && city.maximumCustomPromoCodeUses}
              />
            </Col>
          </Grid>
        </FormGroup>
      </FormGroupContainer>
      {eventType == COMMERCIAL_PARTNERSHIP_EVENT && (
        <>
          <FormSectionTitle>
            {intl.formatMessage({
              id: 'admin.eventTicketingInfo.dealAmount',
            })}
          </FormSectionTitle>
          <FormGroupContainer>
            <FormGroup
              data-qaid="event-ticketing-form-deal-amount-formgroup"
              label={intl.formatMessage(
                {
                  id: 'admin.eventTicketingInfo.dealAmountWithCurrencySymbol',
                },
                {
                  currencySymbol: values.currencySymbol,
                }
              )}
              errorMsg={errorMsgForField('dealAmount', touched, errors)}
            >
              <NumberTextfield
                data-qaid="event-ticketing-form-deal-amount-field"
                id="dealAmount"
                name="dealAmount"
                value={
                  values.dealAmount && values.dealAmount.toFixed
                    ? values.dealAmount.toFixed(2)
                    : undefined
                }
                onChange={(e: any) => {
                  setFieldValue('dealAmount', e.target.value);
                }}
                maxLength={6}
              />
            </FormGroup>
          </FormGroupContainer>
        </>
      )}
    </GenericFormContainer>
  );
};

export default EventDetailsTicketingEditForm;
