import { css } from 'styled-components';

import { InputProps } from 'app/typings/input';

export const withTextfieldStyle = ({
  theme,
  hasError,
  errorColor = 'redRedWine',
}: InputProps) => css`
  width: 100%;
  height: ${theme.dimensions.input.height};
  background-color: ${theme.colors.whiteDenim};
  border-radius: ${theme.borderRadius.input};
  border: 1px solid ${theme.colors.warmGrey};
  padding: 0 ${theme.ruler[4]}px;
  outline: none;
  box-shadow: none;
  transition: ${theme.utils.transition()};

  letter-spacing: ${theme.fontStyles.body2.letterSpacing};
  color: ${theme.fontColors.secondary};
  font-size: ${theme.fontSizes.body2.xs};

  &::placeholder {
    color: ${theme.colors.warmGrey};
  }

  &:hover {
    border-color: ${theme.colors.darkBorder};
  }

  &:focus {
    border-color: ${theme.colors.backToBlack};
  }

  &:disabled {
    -webkit-text-fill-color: ${theme.colors.warmGrey};
    opacity: 1;
    color: ${theme.colors.warmGrey};
    border-color: ${theme.colors.warmGrey};

    &::placeholder {
      color: ${theme.colors.warmGrey};
    }
  }

  ${theme.media.xs`
    font-size: ${theme.fontSizes.body2.xs};
  `};

  ${theme.media.lg`
    font-size: ${theme.fontSizes.body2.lg};
  `};

  ${hasError &&
    css`
      border-color: ${theme.colors[errorColor]};
    `}
`;
