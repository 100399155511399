import {
  Image as ImageType,
  ObjectImageMap,
  ObjectImagePurpose,
} from 'app/typings';
import {
  baseQueryStringParams,
  defaultImageFileLocation,
  defaultOutputQuality,
  imagesConfig,
} from 'app/shared/utils/imagesConfig';

type AspectRatio = '1:1' | '4:1' | '4:3' | '4:5' | '5:4' | '16:9';

type GqlAspectRatioName =
  | 'ratio1x1'
  | 'ratio4x1'
  | 'ratio4x3'
  | 'ratio4x5'
  | 'ratio5x4'
  | 'ratio16x9';

interface BuildBaseUrlProps {
  fileLocationAndFilename: string;
  aspectRatio?: AspectRatio | string;
  aspectRatioOverride?: AspectRatio;
  outputQuality?: number;
}

interface BuildFullUrlProps {
  fileLocationAndFilename?: string;
  objectType: string;
  useCase: string;
  size: string;
}

interface HasValidConfigProps {
  objectType: string;
  useCase: string;
}

interface GetSizesAndAspectRatiosToUseProps {
  objectType: string;
  useCase: string;
}

interface GetRatioImagesProps {
  objectType: string;
  useCase: string;
  images?: ObjectImageMap;
}

interface GetDefaultImageFileLocationAndFilenameProps {
  objectType: string;
  useCase: string;
}

interface GetFileLocationAndFilenameProps {
  ratioImages?: ObjectImagePurpose;
  aspectRatio?: AspectRatio;
}

interface GetImageSourceMapProps {
  objectType: string;
  useCase: string;
  images?: ObjectImageMap;
}

interface GetImageUrlForImageObjectProps {
  image: ImageType;
}

// NOTE: The mobile/tablet/smallDesktop/largeDesktop sizing system is for use with themes of the CommmonTheme type,
// particularly with the new-design-style Image atom (app/shared/components/atoms/Image).
// The small/medium/large sizing system is meant for use with themes of the ManualCSSTheme type.
// Please only use ONE sizing system in a given image map. For example, you do NOT want both `srcSmall` and `srcMobile`.
// Both sizing systems include the default src.

export interface ImageSourceMap {
  src: string;
  srcSmall?: string;
  srcMedium?: string;
  srcLarge?: string;
  srcMobile?: string;
  srcTablet?: string;
  srcSmallDesktop?: string;
  srcLargeDesktop?: string;
}

interface IsSourceMapEmptyProps {
  sourceMap: ImageSourceMap;
}

const aspectRatioMap = {
  '1:1': 'ratio1x1' as GqlAspectRatioName,
  '4:1': 'ratio4x1' as GqlAspectRatioName,
  '4:3': 'ratio4x3' as GqlAspectRatioName,
  '4:5': 'ratio4x5' as GqlAspectRatioName,
  '5:4': 'ratio5x4' as GqlAspectRatioName,
  '16:9': 'ratio16x9' as GqlAspectRatioName,
};

const tShirtSizes = ['srcSmall', 'srcMedium', 'srcLarge'];
const breakpointSizes = [
  'srcMobile',
  'srcTablet',
  'srcSmallDesktop',
  'srcLargeDesktop',
];

const sizes = ['src', ...tShirtSizes, ...breakpointSizes];

function fullyQualifyURL(fileURL: string) {
  if (fileURL.startsWith('https://')) {
    return fileURL;
  } else {
    return `https://${fileURL}`;
  }
}

function isFileCompressable(fileURL: string) {
  return (
    !!fileURL.includes('.jpg?') ||
    !!fileURL.includes('.jpeg?') ||
    !!fileURL.includes('.JPG?') ||
    !!fileURL.includes('.JPEG?')
  );
}

function addOutputQualityParam(fileURL: string, outputQuality?: number) {
  if (isFileCompressable(fileURL)) {
    const outputQualityToUse = outputQuality || defaultOutputQuality;
    return `${fileURL}&q=${outputQualityToUse}`;
  } else {
    return fileURL;
  }
}

// Returns the url minus size-dependent (eg. srcSmall, srcTablet, etc.) params (usually width/height) e.g.:
// https://sofar-sounds-live.imgix.net/images/Event-43219-1-1700081205205-image.jpg?ixlib=rails-4.3.1&auto=format&fit=crop&crop=entropy&ar=1:1&q=35
function buildBaseUrl({
  fileLocationAndFilename,
  aspectRatio,
  aspectRatioOverride,
  outputQuality,
}: BuildBaseUrlProps): string {
  let url = '';

  if (fileLocationAndFilename.includes('?')) {
    // Urls that start off WITH query params:
    // - Custom uploaded images coming from backend
    // BUT:
    // - These query params sometimes already include width/height params (e.g. w=300), so we have to remove them
    //   so that we can add the correct width/height params for the size later (small, large, tablet, etc.)
    // - These query params are sometimes missing the aspect ratio param (e.g. ar=1:1), so we have to add it
    let fileLocationAndFilenameCleaned = fileLocationAndFilename
      .replace(/&w=\d+/, '')
      .replace(/&h=\d+/, '');
    if (aspectRatioOverride) {
      fileLocationAndFilenameCleaned = fileLocationAndFilenameCleaned.replace(
        /&ar=[\d:]+/,
        ''
      );
    }
    const aspectRatioToUse = aspectRatioOverride || aspectRatio;
    const aspectRatioParam = !fileLocationAndFilenameCleaned.includes('ar=')
      ? `&ar=${aspectRatioToUse}`
      : '';
    url = `${fileLocationAndFilenameCleaned}${aspectRatioParam}`;
  } else {
    // Urls that start off WITHOUT any query params:
    // - Default images assigned in frontend (see imagesConfig)
    // - Auto-generated (image pool) images coming from backend
    // - Old, refile-based images pre-dating the image model that were backfilled into the images table,
    //   whose fileLocationAndFilename might look like e.g.:
    //     https://sofar-sounds-live.imgix.net/store/222f4ea1ca0a115a213e23a8536754bb53806d64dd366f74e13c419756d1
    const aspectRatioToUse = aspectRatioOverride || aspectRatio;
    const aspectRatioParam = aspectRatioToUse ? `&ar=${aspectRatioToUse}` : '';
    url = `${fileLocationAndFilename}?${baseQueryStringParams}${aspectRatioParam}`;
  }

  return fullyQualifyURL(addOutputQualityParam(url, outputQuality));
}

// Returns the full url ready to be used and assigned to a size (eg. srcSmall, srcTablet, etc.) in the source map e.g.:
// https://sofar-sounds-live.imgix.net/images/Event-43219-1-1700081205205-image.jpg?ixlib=rails-4.3.1&auto=format&fit=crop&crop=entropy&ar=1:1&q=35&w=600
function buildFullUrl({
  fileLocationAndFilename,
  objectType,
  useCase,
  size,
}: BuildFullUrlProps): string {
  const useCaseInfo = imagesConfig[objectType].useCases[useCase];

  const aspectRatio = useCaseInfo.aspectRatio;
  const aspectRatiosBySize = useCaseInfo.aspectRatiosBySize;
  const aspectRatioOverridesBySize = useCaseInfo.aspectRatioOverridesBySize;
  const extraParamsBySize = useCaseInfo.extraParamsBySize;
  const outputQuality = useCaseInfo.outputQuality;

  const aspectRatioOverride =
    aspectRatioOverridesBySize &&
    aspectRatioOverridesBySize.hasOwnProperty(size)
      ? aspectRatioOverridesBySize[size]
      : undefined;

  if (!fileLocationAndFilename || !extraParamsBySize) {
    return '';
  }

  const baseUrl = buildBaseUrl({
    fileLocationAndFilename,
    aspectRatio:
      aspectRatiosBySize && aspectRatiosBySize[size]
        ? aspectRatiosBySize[size]
        : aspectRatio,
    aspectRatioOverride,
    outputQuality,
  });

  if (baseUrl) {
    return `${baseUrl}${extraParamsBySize[size]}`;
  } else {
    return '';
  }
}

// Returns the 'raw' url (file_location + filename) for a default image, without any
// dynamically-applied modifications like:
// - adding missing query string or missing aspect ratio
// - overriding aspect ratio
// - adding output quality param
// - adding width/height params
function getDefaultImageFileLocationAndFilename({
  objectType,
  useCase,
}: GetDefaultImageFileLocationAndFilenameProps): string {
  const defaultImageFilename =
    imagesConfig[objectType].useCases[useCase].defaultImageFilename;

  if (defaultImageFilename) {
    return `${defaultImageFileLocation}/${defaultImageFilename}`;
  } else {
    return '';
  }
}

// Returns the 'raw' url (file_location + filename) from the images table in the backend, without any
// dynamically-applied modifications like:
// - adding missing query string or missing aspect ratio
// - overriding aspect ratio
// - adding output quality param
// - adding width/height params
function getFileLocationAndFilename({
  ratioImages,
  aspectRatio,
}: GetFileLocationAndFilenameProps): string {
  if (!ratioImages || !aspectRatio) {
    return '';
  }

  const gqlRatioName = aspectRatioMap[aspectRatio];
  const imagesForAspectRatio = ratioImages[gqlRatioName];
  const srcImage =
    imagesForAspectRatio &&
    imagesForAspectRatio[imagesForAspectRatio.length - 1];

  if (srcImage) {
    return `${srcImage.fileLocation}/${srcImage.filename}`;
  } else {
    return '';
  }
}

function getDefaultSourceMap(): ImageSourceMap {
  // @ts-ignore
  return sizes.reduce((obj, size: string) => ({ ...obj, [size]: '' }), {});
}

function isSourceMapEmpty({ sourceMap }: IsSourceMapEmptyProps): boolean {
  return sizes.every((size: string) => !sourceMap[size]);
}

function hasValidConfig({ objectType, useCase }: HasValidConfigProps): boolean {
  if (!imagesConfig[objectType]?.useCases) {
    return false;
  }

  const useCaseInfo = imagesConfig[objectType].useCases[useCase];

  const imagePurpose = useCaseInfo.imagePurpose;
  const aspectRatio = useCaseInfo.aspectRatio;
  const aspectRatiosBySize = useCaseInfo.aspectRatiosBySize;

  if (
    !imagePurpose ||
    (!aspectRatio && !aspectRatiosBySize) ||
    (aspectRatio && aspectRatiosBySize)
  ) {
    return false;
  }

  return true;
}

function getSizesAndAspectRatiosToUse({
  objectType,
  useCase,
}: GetSizesAndAspectRatiosToUseProps): {} {
  const useCaseInfo = imagesConfig[objectType].useCases[useCase];

  const aspectRatio = useCaseInfo.aspectRatio;
  const aspectRatiosBySize = useCaseInfo.aspectRatiosBySize;

  return sizes.reduce(
    (obj, size: string) => ({
      ...obj,
      [size]:
        aspectRatiosBySize && aspectRatiosBySize[size]
          ? aspectRatiosBySize[size]
          : aspectRatio,
    }),
    {}
  );
}

function getRatioImages({
  objectType,
  useCase,
  images,
}: GetRatioImagesProps): ObjectImagePurpose {
  const useCaseInfo = imagesConfig[objectType].useCases[useCase];

  const imagePurpose = useCaseInfo.imagePurpose;
  return images ? images[imagePurpose] : undefined;
}

// There are TWO MAIN methods to retrieve an image url in our image system:
//
// 1. SOURCE MAP IN FRONTEND:  For image_model objects ONLY - backend retrieves raw image info from object_images
//    and images db tables and returns it to frontend, frontend picks out the data it needs and constructs the
//    url(s), including all handling of default images - result is an image source map with fully-qualified urls
//    including all needed Imgix query string params, for a set of 4 or 5 sources (depending on which sizing
//    system you choose): EITHER the sources will be [src, srcSmall, srcMedium, srcLarge] OR they will be
//    [src, srcMobile, srcTablet, srcSmallDesktop, srcLargeDesktop]. Please do not combine sizing systems!
//    - Uses function getImageSourceMap
//    - This method is typically used for public-facing, highly responsive, critical UIs like city pages,
//      event pages, etc.
//
// 2. SINGLE URL IN BACKEND:  For BOTH image_model and refile_attachment objects - backend calls
//    Images::ImageUrlRetrievalService which retrieves raw image info from either object_images/images db tables
//    or model table, picks out data it needs, and constructs a single url, including all handling of default images -
//    result is a single fully-qualified url including all needed Imgix query string params - url is either
//    returned to frontend or used in backend
//    - For frontend, this method is typically used for admin-facing UIs that just need to display the image, without
//      responsiveness, so that users know what it looks like
//    - For backend, this method is typically used for images in emails and third-party APIs
//
// There is also a THIRD METHOD to retrieve a single image url in the FRONTEND, but only for a specialized purpose
// for image_model objects - backend retrieves raw image info from object_images and images db tables and returns
// it to frontend, frontend picks out the data it needs and constructs a single url - result is a single
// fully-qualified url including all needed Imgix query string params
//    - Uses function getImageUrlForImageObject
//    - This method is used ONLY when some frontend code has just an Image object, not the full list of
//      object_images - e.g. see ImageList component
//

export function getImageSourceMap({
  objectType,
  useCase,
  images,
}: GetImageSourceMapProps): ImageSourceMap {
  const sourceMap = getDefaultSourceMap();

  if (!hasValidConfig({ objectType, useCase })) {
    return sourceMap;
  }

  const sizesAndAspectRatiosToUse = getSizesAndAspectRatiosToUse({
    objectType,
    useCase,
  });

  const ratioImages = getRatioImages({
    objectType,
    useCase,
    images,
  });

  for (const size in sizesAndAspectRatiosToUse) {
    const fileLocationAndFilename = getFileLocationAndFilename({
      ratioImages,
      aspectRatio: sizesAndAspectRatiosToUse[size],
    });
    const fullUrl = buildFullUrl({
      fileLocationAndFilename,
      objectType,
      useCase,
      size,
    });
    if (fullUrl) {
      sourceMap[size] = fullUrl;
    }
  }

  if (isSourceMapEmpty({ sourceMap })) {
    const defaultImageFileLocationAndFilename = getDefaultImageFileLocationAndFilename(
      {
        objectType,
        useCase,
      }
    );
    for (const size of sizes) {
      const fullUrl = buildFullUrl({
        fileLocationAndFilename: defaultImageFileLocationAndFilename,
        objectType,
        useCase,
        size,
      });
      sourceMap[size] = fullUrl || '';
    }
  }

  return sourceMap;
}

export function getImageUrlForImageObject({
  image,
}: GetImageUrlForImageObjectProps): string {
  return buildBaseUrl({
    fileLocationAndFilename: `${image.fileLocation}/${image.filename}`,
    aspectRatio: image.ratio,
  });
}

export function isDefaultImage(imageUrl: string) {
  return imageUrl.includes('default-images');
}
