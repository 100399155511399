import React from 'react';
import styled from 'styled-components';

import {
  ArrowLeftIcon,
  ArrowRightIcon,
} from 'app/shared/components/atoms/IconLibrary';

interface Props {
  onPreviousClick: VoidFunction;
  onNextClick: VoidFunction;
}

const MainContainer = styled.div`
  display: block;
`;

const NavArrowsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  position: absolute;
  width: 100%;
  top: 20px;
`;

const ArrowLink = styled.div``;

const CrewCustomHeader: React.FunctionComponent<Props> = ({
  onPreviousClick,
  onNextClick,
}) => {
  return (
    <MainContainer>
      <NavArrowsContainer>
        <ArrowLink>
          <ArrowLeftIcon iconSize={30} onClick={() => onPreviousClick()} />
        </ArrowLink>
        <ArrowLink>
          <ArrowRightIcon iconSize={30} onClick={() => onNextClick()} />
        </ArrowLink>
      </NavArrowsContainer>
    </MainContainer>
  );
};

export default CrewCustomHeader;
