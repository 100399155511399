import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import {
  BusinessOwner,
  City,
  CuratorGroup,
  GetPrimaryRolesData,
  User,
} from 'app/typings';
import { errorMsgForField } from 'app/shared/utils/form';
import { get } from 'app/shared/utils/get';
import {
  FormGroupContainer,
  FormSectionTitle,
} from 'app/shared/components/atoms/FormContent';
import GenericFormContainer from 'app/shared/components/atoms/GenericFormContainer';
import { LoadingError } from 'app/shared/components/atoms/LoadingError';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import FormGroup from 'app/shared/components/molecules/FormGroup';
import Select from 'app/shared/components/molecules/SelectManualCSS';
import CitySelectorManualCSS from 'app/shared/components/organisms/CitySelectorManualCSS';
import {
  curatorEditablePrimaryRoleKeys,
  roleResourceTypeLabels,
  roleResourceTypes,
} from 'app/admin/utils/users';
import { GetBusinessOwners } from 'app/admin/graphql/businessOwners/queryHooks';
import { GetCuratorGroupsLite } from 'app/admin/graphql/curatorGroups/queryHooks';
import { GetPrimaryRoles } from 'app/admin/graphql/roles/queryHooks';
import EditFormLoadingBlocks from 'app/admin/components/atoms/EditFormLoadingBlocks';
import UserTypeahead from 'app/admin/components/molecules/UserTypeahead';

interface FormProps {
  formikProps: any;
  validationErrors?: object;
  curatorGroupIdsToLimitBy: number[];
}

const SelectUserContainer = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  max-width: 500px;
`;

const getPrimaryRoleOptions = (
  dataPrimaryRoles: GetPrimaryRolesData | undefined,
  isLimitedByCuratorGroups: boolean
) => {
  let primaryRoles = get(dataPrimaryRoles, 'primaryRoles.primaryRoles', []);
  if (isLimitedByCuratorGroups) {
    primaryRoles = primaryRoles.filter((primaryRole: any) =>
      curatorEditablePrimaryRoleKeys.includes(primaryRole.key)
    );
  }
  return primaryRoles.map((primaryRole: any) => ({
    value: primaryRole.key,
    label: primaryRole.resourceType
      ? `${primaryRole.name} (By ${roleResourceTypeLabels[
          primaryRole.resourceType
        ] || primaryRole.resourceType})`
      : primaryRole.name,
    resourceType: primaryRole.resourceType,
  }));
};

const getCuratorGroupData = (
  dataCuratorGroups: any,
  curatorGroupIds?: number[]
) => {
  const curatorGroups = get(
    dataCuratorGroups,
    'curatorGroups.curatorGroups',
    []
  );
  if (curatorGroupIds && curatorGroupIds.length > 0) {
    return curatorGroups.filter((curatorGroup: any) =>
      curatorGroupIds.includes(curatorGroup.id)
    );
  }
  return curatorGroups;
};

const UserRoleAddForm: React.FC<FormProps> = ({
  formikProps,
  validationErrors = {},
  curatorGroupIdsToLimitBy,
}) => {
  const intl = useIntl();

  const isLimitedByCuratorGroups = !!(curatorGroupIdsToLimitBy.length > 0);

  const {
    loading: loadingPrimaryRoles,
    error: errorPrimaryRoles,
    data: dataPrimaryRoles,
  } = GetPrimaryRoles({
    resourceTypes: roleResourceTypes,
  });

  const {
    loading: loadingCuratorGroups,
    error: errorCuratorGroups,
    data: dataCuratorGroups,
  } = GetCuratorGroupsLite({});

  const {
    loading: loadingBusinessOwners,
    error: errorBusinessOwners,
    data: dataBusinessOwners,
  } = GetBusinessOwners({
    archivedStatus: 'not_archived',
    skipPagination: true,
  });

  if (errorPrimaryRoles || errorCuratorGroups || errorBusinessOwners) {
    return <LoadingError whatsBeingLoaded="this form" />;
  }

  if (
    loadingPrimaryRoles ||
    !dataPrimaryRoles ||
    loadingCuratorGroups ||
    !dataCuratorGroups ||
    loadingBusinessOwners ||
    !dataBusinessOwners
  ) {
    return <EditFormLoadingBlocks />;
  }

  const curatorGroupOptions = getCuratorGroupData(
    dataCuratorGroups,
    curatorGroupIdsToLimitBy
  );

  const businessOwnerOptions = get(
    dataBusinessOwners,
    'businessOwners.businessOwners',
    []
  );

  const { setFieldValue, touched, values, errors } = formikProps;

  const primaryRoleOptions = getPrimaryRoleOptions(
    dataPrimaryRoles,
    isLimitedByCuratorGroups
  );

  return (
    <GenericFormContainer dataQaId="user-add-user-role-form">
      <FormSectionTitle>
        {intl.formatMessage({
          id:
            values.action == 'addInitialRoleToUser'
              ? 'admin.users.userRoleAdd.form.makeUserAnInternalUser'
              : 'admin.users.userRoleAdd.form.title',
        })}
      </FormSectionTitle>
      <FormGroupContainer data-qaid="user-add-user-role-select-role-formgroup-container">
        {(values.action === 'addRoleToAnyUser' ||
          values.action === 'addCuratorRoleToAnyUser') && (
          <>
            <FormGroup
              data-qaid="user-add-user-role-select-user-formgroup"
              label={intl.formatMessage({
                id: 'admin.users.userRoleAdd.form.userFieldLabel',
              })}
              errorMsg={errorMsgForField('userId', touched, errors)}
              required
            >
              <SelectUserContainer>
                <UserTypeahead
                  setSelectedUser={(user: User | undefined) => {
                    setFieldValue('userId', user ? user.id : undefined);
                  }}
                />
              </SelectUserContainer>
            </FormGroup>
            <Spacer mb={8} />
          </>
        )}
        <FormGroup
          data-qaid="user-add-user-role-select-role-formgroup"
          label={intl.formatMessage({
            id: 'admin.users.userRoleAdd.form.roleFieldLabel',
          })}
          errorMsg={
            errorMsgForField('role', touched, errors) ||
            // @ts-ignore
            validationErrors.general
          }
          required
        >
          <Select
            data-qaid="user-add-user-role-form-role-field"
            name="role"
            id="role"
            onChange={option => {
              setFieldValue('role', option ? option.value : undefined);
              setFieldValue(
                'roleResourceType',
                option ? option.resourceType : ''
              );
            }}
            options={primaryRoleOptions}
            getOptionLabel={(opt: any) => opt.label}
            hasError={!!errorMsgForField('role', touched, errors)}
            placeholder={intl.formatMessage({
              id: 'admin.users.userRoleAdd.form.rolePlaceholder',
            })}
          />
        </FormGroup>
        {values.roleResourceType == 'CuratorGroup' && (
          <>
            <Spacer mb={6} />
            <FormGroup
              data-qaid="user-add-user-role-select-curator-group-formgroup"
              label={intl.formatMessage({
                id: 'admin.users.userRoleAdd.form.curatorGroupFieldLabel',
              })}
              errorMsg={errorMsgForField('curatorGroupId', touched, errors)}
              required
            >
              <Select
                data-qaid="user-add-user-role-form-curator-group-selector"
                searchable
                getOptionLabel={(opt: any) => opt.name}
                defaultValue={undefined}
                options={curatorGroupOptions}
                placeholder={intl.formatMessage({
                  id: 'admin.users.userRoleAdd.form.curatorGroupPlaceholder',
                })}
                onChange={(curatorGroup: CuratorGroup) => {
                  setFieldValue('curatorGroup', curatorGroup);
                }}
              />
            </FormGroup>
          </>
        )}
        {!isLimitedByCuratorGroups && values.roleResourceType == 'City' && (
          <>
            <Spacer mb={8} />
            <FormGroup
              data-qaid="user-add-user-role-select-city-formgroup"
              label={intl.formatMessage({
                id: 'admin.users.userRoleAdd.form.cityFieldLabel',
              })}
              errorMsg={errorMsgForField('city', touched, errors)}
              required
            >
              <CitySelectorManualCSS
                parentContainer="admin-add-role-to-user"
                data-qaid="user-add-user-role-form-city-selector"
                disableTopCities
                disableClosestCities
                initialValue={undefined}
                value={values.resource}
                onChange={(city: City) => {
                  setFieldValue('city', city);
                }}
              />
            </FormGroup>
          </>
        )}
        {!isLimitedByCuratorGroups &&
          values.roleResourceType == 'BusinessOwner' && (
            <>
              <Spacer mb={6} />
              <FormGroup
                data-qaid="user-add-user-role-select-business-owner-formgroup"
                label={intl.formatMessage({
                  id: 'admin.users.userRoleAdd.form.businessOwnerFieldLabel',
                })}
                errorMsg={errorMsgForField('businessOwnerId', touched, errors)}
                required
              >
                <Select
                  data-qaid="user-add-user-role-form-business-owner-selector"
                  searchable
                  getOptionLabel={(opt: any) => opt.title}
                  defaultValue={undefined}
                  options={businessOwnerOptions}
                  placeholder={intl.formatMessage({
                    id: 'admin.users.userRoleAdd.form.businessOwnerPlaceholder',
                  })}
                  onChange={(businessOwner: BusinessOwner) => {
                    setFieldValue('businessOwner', businessOwner);
                  }}
                />
              </FormGroup>
            </>
          )}
      </FormGroupContainer>
    </GenericFormContainer>
  );
};

export default UserRoleAddForm;
