import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { H6 } from 'app/shared/components/atoms/TypographyManualCSS';

export const NoneWrapper = styled(H6)`
  ${({ theme }) => css`
    color: ${theme.colors.darkSmoke};
    font-weight: normal;
    line-height: 1.5;
    margin: 0;
  `}
`;

export const None: React.FC = () => {
  const intl = useIntl();

  return (
    <NoneWrapper data-qaid="none-wrapper">
      {intl.formatMessage({ id: 'none' })}
    </NoneWrapper>
  );
};
