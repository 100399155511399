import * as Yup from 'yup';

const crewEventSurveyFormSchema = () =>
  Yup.object().shape({
    eventRating: Yup.number().required(),
    mcOverallFeeling: Yup.string().required(),
    venueOverallFeeling: Yup.string().required(),
    venueComfortOverallFeeling: Yup.string().required(),
    artistFeedback: Yup.object().test({
      name: 'artist-feedback-test',
      test: (value: any) => {
        return !Object.values(value).some((val: any) => !val.overallFeeling);
      },
    }),
  });

export default crewEventSurveyFormSchema;
