import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { get } from 'app/shared/utils/get';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { UpdateContinent } from 'app/admin/graphql/continents/mutationHooks';
import { DetailsFormMainContainer } from 'app/admin/components/atoms/DetailContent';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

import ContinentEditForm from './ContinentEditForm';
import ContinentEditFormSchema from './ContinentEditFormSchema';

interface Props {
  contentProps?: any;
  setFormSubmitAction: (func: Function) => void;
  setDisplayConfirmation: (set: boolean) => void;
  setIsSubmitting: (set: boolean) => void;
  navigateTo: (routeData: object) => void;
}

const ContinentEdit: React.FC<Props> = ({
  contentProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  navigateTo,
}) => {
  const intl = useIntl();

  const [validationErrors, setValidationErrors] = useState<object>({});

  const formInitialValues = {
    title: get(contentProps, 'title', '') || '',
  };

  const updateContinentAction = UpdateContinent();

  const handleSubmit = useSubmitAction({
    submitAction: updateContinentAction,
    submitVariables: (values: any) => ({
      continentId: get(contentProps, 'id', undefined),
      title: values.title,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.continentBasicInfo.successMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.continentBasicInfo.failureMessage',
    }),
    onSuccess: () => {
      navigateTo({
        routeName: 'continents',
        routeProps: {},
      });
    },
    onValidationError: validationErrors =>
      setValidationErrors(validationErrors),
  });

  return (
    <ModalContentContainer>
      <DetailsFormMainContainer>
        <DetailsHeader
          title={`${intl.formatMessage({
            id: 'admin.continentBasicInfo.continent',
          })} ${get(contentProps, 'title', '')}`}
        />
        <GenericForm
          formInitialValues={formInitialValues}
          renderFormComponent={(renderProps: any) => (
            <ContinentEditForm
              formikProps={renderProps.formikProps}
              validationErrors={validationErrors}
            />
          )}
          onSubmit={handleSubmit}
          formSchema={ContinentEditFormSchema(intl)}
          setFormSubmitAction={setFormSubmitAction}
          setDisplayConfirmation={setDisplayConfirmation}
          setIsSubmitting={setIsSubmitting}
          dataQaId="continent-edit-form"
        />
      </DetailsFormMainContainer>
    </ModalContentContainer>
  );
};

export default ContinentEdit;
