import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { ArtistDetailData } from 'app/typings/artists';
import { dateFormatter } from 'app/shared/utils/datetime';
import { get } from 'app/shared/utils/get';
import { artistSocialUrls } from 'app/shared/utils/socialLinks';
import usePermission from 'app/shared/utils/usePermission';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import { AddIcon, EditIcon } from 'app/shared/components/atoms/IconLibrary';
import { GenericLink } from 'app/shared/components/atoms/LinkManualCSS';
import { Spacer, Spacer12 } from 'app/shared/components/atoms/Spacer';
import { Body2 } from 'app/shared/components/atoms/TypographyManualCSS';
import HelpIconAndFlyover from 'app/shared/components/molecules/HelpIconAndFlyover';
import YouTubePlayer from 'app/shared/components/organisms/Prismic/YouTubePlayer';
import { DetailHeading as Heading } from 'app/admin/components/atoms/DetailContent';
import LoadMoreButton from 'app/admin/components/atoms/LoadMoreButton';
import { None } from 'app/admin/components/atoms/None';
import { UserLink } from 'app/admin/components/molecules/EntityLink';
import SocialLinkList from 'app/admin/components/molecules/SocialLinkList';

interface Props {
  sectionData: ArtistDetailData;
  callbacks: { [name: string]: Function };
}

interface VideoLinkProps {
  url: string;
  idx: number;
}

const HeadingWithHelpIconContainer = styled.div`
  display: -webkit-inline-box;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const HelpIconAndFlyoverContainer = styled.div`
  padding-left: 10px;
  padding-bottom: 10px;
`;

const IconLinkNextToHelpIconContainer = styled.div`
  padding-left: 20px;
  padding-bottom: 10px;
`;

const VideoInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const UserLinkContainer = styled.div`
  font-size: 14px;
  display: flex;
`;

const UserLinkText = styled.div`
  margin-right: 7px;
`;

const StyledBody2 = styled(Body2)`
  margin-top: -10px;
`;

interface VideoContainerProps {
  isLast: boolean;
}

const VideoContainer = styled.div<VideoContainerProps>`
  margin-bottom: ${props => (props.isLast ? '0' : '25px')};
`;

const SectionMedia: React.FC<Props> = ({ sectionData, callbacks }) => {
  const intl = useIntl();

  const hasAccessArtistsPermission = usePermission('artist.list.accessBasic');

  const PREVIEW_COUNT = 3;
  const videosCount = get(sectionData, 'videos.length', 0);
  const displayAllVideos = videosCount > PREVIEW_COUNT;
  const slicedVideos =
    sectionData.videos && videosCount > 0
      ? sectionData.videos.slice(0, PREVIEW_COUNT)
      : [];

  const artistApplicationVideos = [
    sectionData.artistApplication?.portfolioUrl1,
    sectionData.artistApplication?.portfolioUrl2,
    sectionData.artistApplication?.portfolioUrl3,
  ];

  //Filter out empty portfolio urls
  const filteredArtistApplicationVideos = artistApplicationVideos.filter(
    v => v
  );

  const updatedAt = sectionData.reviewedAt;
  const approvedBy = sectionData.reviewedBy;

  const VideoLink: React.FC<VideoLinkProps> = ({ url, idx }) => {
    return (
      <>
        <GenericLink url={url}>
          {intl.formatMessage({
            id: 'admin.artistDirectory.sectionMedia.livePerformanceVideo',
          })}{' '}
          {` ${idx + 1}`}
        </GenericLink>
      </>
    );
  };

  return (
    <MainContainer data-qaid="section-media-container">
      <Grid cols={4} gap="30">
        <Col xs={12} sm={1} md={1} lg={1} xl={1} key={1}>
          <SocialLinkList
            socialLinks={sectionData.socialUrls}
            socialLinksToDisplay={artistSocialUrls}
            toggleEditModal={callbacks.toggleSocialsEditModal}
            helpIconFlyoverBody={intl.formatMessage({
              id: 'admin.artistDirectory.sectionMedia.linksHelpText',
            })}
            helpIconInfoByLink={{
              urlSupport: {
                title: intl.formatMessage({
                  id: 'admin.artistDirectory.sectionMedia.supportLink',
                }),
                body: intl.formatMessage({
                  id: 'admin.artistDirectory.sectionMedia.supportLinkHelpText',
                }),
                body2: null,
              },
            }}
          />
        </Col>
        <Col xs={12} sm={1} md={1} lg={1} xl={1} key={2}>
          <HeadingWithHelpIconContainer>
            <Heading>
              {intl.formatMessage({
                id: 'shared.videos',
              })}
            </Heading>
            <HelpIconAndFlyoverContainer>
              <HelpIconAndFlyover
                title={intl.formatMessage({
                  id: 'shared.videos',
                })}
                bodyId="admin.artistDirectory.sectionMedia.videosHelpText"
              />
            </HelpIconAndFlyoverContainer>
            {hasAccessArtistsPermission && (
              <IconLinkNextToHelpIconContainer>
                <AddIcon
                  iconSize={14}
                  onClick={
                    callbacks &&
                    (() => callbacks.toggleVideoEditAndSetVideoData())
                  }
                />
              </IconLinkNextToHelpIconContainer>
            )}
          </HeadingWithHelpIconContainer>
          <div data-qaid="videos-container">
            {slicedVideos.length > 0 ? (
              slicedVideos.map((video: any, idx: number) => (
                <VideoContainer
                  isLast={idx === slicedVideos.length - 1}
                  key={idx}
                >
                  <YouTubePlayer url={video.url} />
                  <Spacer mt={1} mb={2}>
                    <VideoInfo>
                      <div>
                        <Body2>{video.displayName}</Body2>
                        <Spacer mt={1} />
                        <Body2>
                          {get(
                            sectionData.performances &&
                              sectionData.performances.find(
                                (p: any) =>
                                  p &&
                                  p.id ===
                                    get(video, 'performance.id', undefined)
                              ),
                            'event.city.title',
                            ''
                          )}
                        </Body2>
                      </div>
                      {hasAccessArtistsPermission && (
                        <EditIcon
                          iconSize={14}
                          onClick={
                            callbacks &&
                            (() =>
                              callbacks.toggleVideoEditAndSetVideoData({
                                title: video.displayName,
                                description: video.description,
                                performanceId: get(
                                  video,
                                  'performance.id',
                                  undefined
                                ),
                                link: video.url,
                                id: video.id,
                                showOnFeaturedVideos:
                                  video.showOnFeaturedVideos,
                              }))
                          }
                        />
                      )}
                    </VideoInfo>
                  </Spacer>
                  <Body2>{video.description}</Body2>
                </VideoContainer>
              ))
            ) : (
              <>
                <None />
                <Spacer mb={8} />
              </>
            )}
          </div>
          {displayAllVideos && (
            <LoadMoreButton
              data-qaid="all-videos-button"
              onClick={() => callbacks.toggleAllVideos()}
            >
              {intl.formatMessage({
                id: 'admin.artistDirectory.sectionMedia.all',
              })}
            </LoadMoreButton>
          )}
        </Col>
        {sectionData.artistApplication && (
          <Col xs={12} sm={1} md={1} lg={1} xl={1} key={3}>
            <HeadingWithHelpIconContainer>
              <Heading>
                {intl.formatMessage({
                  id:
                    'admin.artistDirectory.sectionMedia.artistApplicationVideoLinks',
                })}
              </Heading>
              <HelpIconAndFlyoverContainer>
                <HelpIconAndFlyover
                  title={intl.formatMessage({
                    id:
                      'admin.artistDirectory.sectionMedia.artistApplicationVideoLinks',
                  })}
                  bodyId="admin.artistDirectory.sectionMedia.artistApplicationVideosHelpText"
                />
              </HelpIconAndFlyoverContainer>
            </HeadingWithHelpIconContainer>
            <StyledBody2>
              {intl.formatMessage({
                id: 'admin.artistDirectory.sectionMedia.dateAdded',
              })}
              {': '}
              {updatedAt &&
                dateFormatter(updatedAt, 'fullMonthDateWithOrdinalAndYear')}
            </StyledBody2>
            <UserLinkContainer>
              <UserLinkText>
                <Body2>
                  {intl.formatMessage({
                    id: 'admin.artistDirectory.sectionMedia.approvedBy',
                  })}
                  {': '}
                </Body2>
              </UserLinkText>
              {approvedBy ? (
                <UserLink user={approvedBy} truncateLength={30} />
              ) : (
                <None />
              )}
            </UserLinkContainer>
            <Spacer12 />
            <div data-qaid="application-videos-container">
              {filteredArtistApplicationVideos.length > 0 ? (
                filteredArtistApplicationVideos.map(
                  (video: any, idx: number) =>
                    video && (
                      <VideoContainer
                        isLast={idx === artistApplicationVideos.length - 1}
                        key={idx}
                      >
                        <YouTubePlayer url={video} />
                        <VideoInfo>
                          <Body2>
                            <VideoLink url={video} idx={idx} />
                          </Body2>
                        </VideoInfo>
                      </VideoContainer>
                    )
                )
              ) : (
                <None />
              )}
            </div>
          </Col>
        )}
      </Grid>
    </MainContainer>
  );
};

export default SectionMedia;
