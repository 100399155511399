import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Venue } from 'app/typings';
import { Offer, VenueOffer, VenueOfferStatus } from 'app/typings/offers';
import { ManualCSSColors } from 'app/shared/theme';
import useModal from 'app/shared/utils/useModal';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { ToolTip, ToolTipContainer } from 'app/shared/components/atoms/ToolTip';
import { Overline } from 'app/shared/components/atoms/TypographyManualCSS';
import ConfirmationModal from 'app/shared/components/molecules/ConfirmationModal';
import InfoBox from 'app/shared/components/molecules/Flyover';
import { CreateCancellation } from 'app/admin/graphql/cancellations/mutationHooks';
import IconLabel from 'app/admin/components/molecules/IconLabel';
import { KeyValueInfoList } from 'app/admin/components/molecules/KeyValueInfoList';
import CancellationReasonForm from 'app/admin/components/organisms/CancellationReasonForm';
import EventPlannerVenueFlyover from 'app/admin/components/organisms/EventPlannerVenueFlyover';
import { ReactComponent as MoodQuestion } from 'icons/streamline-regular/social-medias-rewards-rating/mood/mood-question.svg';

interface VenueProps {
  show?: boolean;
  cityId: number;
  citySlug: string;
  cityTitle: string;
  eventId: number;
  isEventCancelled: boolean;
  isEventInPast: boolean;
  isEventPublished: boolean;
  isVenueConfirmed: boolean;
  numTicketsAvailableForSale: number;
  type: string;
  venue: Venue;
  venueOffers?: any;
  displayConfig: any;
  canEditEvent?: boolean;
  offer?: Offer;
}

const KeyValueInfoListContainer = styled.div`
  height: auto;
`;

const GrayedOutContainer = styled.div`
  color: gray;
`;

const IconLabelNoPointer = styled(IconLabel)`
  cursor: default;
`;

const TitleOverline = styled(Overline)`
  margin-bottom: 5px;
`;

const eventShadedColors = {
  mainBackground: '#E8E8E8',
  status: 'blueSmoke',
  artistVenueConfirmed: 'macyGrey',
  guestsProgressBarFilled: 'lightBlueSmoke',
  guestsProgressBarUnfilled: 'whiteDenim',
};

const filteredVenueOffers = (venueOffers: VenueOffer[]) =>
  venueOffers.filter(
    (venueOffer: VenueOffer) => venueOffer.status !== VenueOfferStatus.CLOSED
  );

const EventVenue: React.FC<VenueProps> = ({
  show = true,
  venueOffers,
  isVenueConfirmed,
  isEventCancelled,
  isEventInPast,
  isEventPublished,
  cityId,
  citySlug,
  cityTitle,
  eventId,
  type,
  venue,
  numTicketsAvailableForSale,
  displayConfig,
  canEditEvent,
  offer,
}) => {
  const intl = useIntl();

  const [venueState, setVenueState] = useState<Venue | undefined>(venue);
  const [venueConfirmedState, setVenueConfirmedState] = useState(
    isVenueConfirmed || false
  );
  const [flyoverClosed, setFlyoverClosed] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [offerState, setOfferState] = useState<Offer | undefined>(offer);
  const [venueOffersState, setVenueOffersState] = useState<VenueOffer[]>(
    filteredVenueOffers(venueOffers) || []
  );
  // NOTE: cancellableVenueId saves the venueId before resetting the venueState
  // after venue is removed to update the cancellation reason
  const [cancellableVenueId, setCancellableVenueId] = useState<number>(0);
  const [
    cancellationReasonValidationError,
    setCancellationReasonValidationError,
  ] = useState<string | undefined>(undefined);
  const [
    displayCancellationReasonForm,
    setDisplayCancellationReasonForm,
  ] = useState<boolean>(false);
  const [cancellationReasonData, setCancellationReasonData] = useState<
    | {
        cancellationReasonId?: number;
        cancellationReasonDescription?: string;
        reasonDescription?: string;
      }
    | undefined
  >(undefined);
  const [
    cancellationReasonConfirmationModal,
    toggleCancellationReasonConfirmationModal,
  ] = useModal();

  const activeOffers = venueOffersState.filter(
    (venueOffer: any) => !['booked', 'closed'].includes(venueOffer.status)
  );
  const acceptedOffers = venueOffersState.filter(
    (venueOffer: any) => venueOffer.status === 'accepted'
  );

  const capacity =
    venueState && venueState.id
      ? venueState.capacity
      : intl.formatMessage({ id: 'admin.notApplicable' });

  const venueKeysAndValuesList = [
    {
      key: intl.formatMessage({
        id: 'shared.capacity',
      }),
      value: displayConfig.displayCapacity
        ? capacity.toString()
        : intl.formatMessage({ id: 'admin.notApplicable' }),
    },
    {
      key: intl.formatMessage({
        id: 'admin.eventPlanner.suggestedTickets',
      }),
      value:
        displayConfig.displaySuggestedTickets && numTicketsAvailableForSale
          ? numTicketsAvailableForSale.toString()
          : intl.formatMessage({ id: 'admin.notApplicable' }),
    },
  ];

  useEffect(() => {
    setVenueConfirmedState(isVenueConfirmed || false);
    setVenueState(venue);
  }, [isVenueConfirmed, venue]);

  const handleCreateCancellation = useSubmitAction({
    submitAction: CreateCancellation(),
    submitVariables: () => ({
      cancellableId: cancellableVenueId,
      cancellableType: 'Venue',
      cancellationReasonId: cancellationReasonData?.cancellationReasonId,
      eventId,
      reasonDescription: cancellationReasonData?.reasonDescription,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.eventPlanner.venues.createCancellation.success',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.eventPlanner.venues.createCancellation.failure',
    }),
    onSuccess: () => {
      setIsSubmitting(false);
      if (cancellationReasonConfirmationModal.isShowing) {
        toggleCancellationReasonConfirmationModal();
      }
    },
  });

  if (!show) {
    return null;
  }

  const renderCancellationReasonForm = () => {
    return (
      <CancellationReasonForm
        cancellableType="Venue"
        otherReasonDescriptionPlaceholderText={intl.formatMessage({
          id: 'admin.eventPlanner.venues.whyWasTheVenueRemoved',
        })}
        validationError={cancellationReasonValidationError}
        onChange={({
          cancellationReasonId,
          cancellationReasonDescription,
          otherReasonDescription,
        }) => {
          setCancellationReasonValidationError(undefined);
          setCancellationReasonData({
            cancellationReasonId,
            cancellationReasonDescription,
            reasonDescription: otherReasonDescription,
          });
        }}
      />
    );
  };

  const renderCancellationReasonConfirmationModal = () => (
    <ConfirmationModal
      onCancel={() => {
        setDisplayCancellationReasonForm(false);
        cancellationReasonConfirmationModal.hide();
      }}
      description={intl.formatMessage({
        id: 'admin.eventPlanner.venues.pleaseSelectReasonForRemovingVenue',
      })}
      onConfirm={() => {
        if (
          displayCancellationReasonForm &&
          !cancellationReasonData?.cancellationReasonId
        ) {
          setCancellationReasonValidationError('missing_cancellation_reason');
        } else if (
          displayCancellationReasonForm &&
          cancellationReasonData?.cancellationReasonDescription === 'Other' &&
          !cancellationReasonData?.reasonDescription
        ) {
          setCancellationReasonValidationError(
            'missing_other_reason_description'
          );
        } else {
          setDisplayCancellationReasonForm(false);
          setIsSubmitting(true);
          handleCreateCancellation();
        }
      }}
      isLoading={isSubmitting}
      customContent={
        displayCancellationReasonForm ? renderCancellationReasonForm() : null
      }
    />
  );

  return (
    <div data-qaid="event-planner-card-venue">
      <TitleOverline>
        {intl.formatMessage({
          id: 'shared.venue',
        })}
      </TitleOverline>
      {!displayConfig.displayVenue ? (
        <GrayedOutContainer>
          {intl.formatMessage({ id: 'admin.notApplicable' })}
        </GrayedOutContainer>
      ) : canEditEvent ? (
        !(venueState && venueState.id) ? (
          <InfoBox
            showCaret={false}
            width="300px"
            onStateChange={(isOpen: boolean) => {
              setFlyoverClosed(!isOpen);
            }}
            triggerElement={
              <div data-qaid="venue-icon-label">
                <IconLabel
                  icon={
                    activeOffers && activeOffers.length > 0
                      ? MoodQuestion
                      : undefined
                  }
                  color={
                    acceptedOffers && acceptedOffers.length > 0
                      ? 'greenOnions'
                      : undefined
                  }
                  iconColor={
                    acceptedOffers && acceptedOffers.length > 0
                      ? 'greenOnions'
                      : 'blackBetty'
                  }
                  labelText={
                    acceptedOffers && acceptedOffers.length > 1
                      ? intl.formatMessage({
                          id: 'admin.eventPlanner.shared.multiple',
                        })
                      : acceptedOffers && acceptedOffers.length > 0
                      ? acceptedOffers[0]?.venue?.venueName ||
                        acceptedOffers[0]?.venue?.address
                      : activeOffers && activeOffers.length > 0
                      ? intl.formatMessage({
                          id: 'admin.eventPlanner.shared.invited',
                        })
                      : undefined
                  }
                  labelTextColor="blueSmoke"
                  showNotification={acceptedOffers.length > 0}
                />
              </div>
            }
            shouldFlyoverClose={flyoverClosed}
            innerContent={
              <EventPlannerVenueFlyover
                venue={venueState}
                venueOffers={activeOffers}
                isVenueConfirmed={venueConfirmedState}
                cityId={cityId}
                citySlug={citySlug}
                cityTitle={cityTitle}
                eventId={eventId}
                eventType={type}
                isEventCancelled={isEventCancelled}
                isEventInPast={isEventInPast}
                isEventPublished={isEventPublished}
                onSuccess={(
                  createdObject: {
                    venue: Venue;
                    offer?: Offer;
                    venueOffers?: VenueOffer[];
                  },
                  isVenueConfirmed: boolean
                ) => {
                  setFlyoverClosed(true);
                  if (
                    createdObject.venue &&
                    createdObject.venue !== venueState
                  ) {
                    setVenueState && setVenueState(createdObject.venue);
                  }
                  if (
                    createdObject?.offer?.status !== VenueOfferStatus.CLOSED
                  ) {
                    if (createdObject?.offer?.venueOffers) {
                      setVenueOffersState(
                        filteredVenueOffers(createdObject.offer.venueOffers)
                      );
                    } else {
                      setVenueOffersState([]);
                    }
                  } else if (
                    createdObject?.offer?.status === VenueOfferStatus.CLOSED
                  ) {
                    setVenueOffersState([]);
                  }
                  setOfferState(createdObject?.offer);
                  setVenueConfirmedState &&
                    setVenueConfirmedState(isVenueConfirmed);
                  createdObject?.venueOffers &&
                    setVenueOffersState &&
                    setVenueOffersState(createdObject?.venueOffers);
                }}
                offer={offerState}
              />
            }
          />
        ) : (
          <>
            <ToolTipContainer>
              <InfoBox
                showCaret={false}
                width="300px"
                onStateChange={(isOpen: boolean) => {
                  setFlyoverClosed(!isOpen);
                }}
                triggerElement={
                  <div data-qaid="venue-icon-label">
                    <IconLabel
                      color={venueConfirmedState ? 'purplePills' : undefined}
                      icon={venueConfirmedState ? 'check' : undefined}
                      draft={venueConfirmedState ? undefined : true}
                      invertIcon
                      labelText={
                        venueState && venueState.venueName
                          ? venueState.venueName
                          : venueState.address
                      }
                    />
                  </div>
                }
                shouldFlyoverClose={flyoverClosed}
                innerContent={
                  <EventPlannerVenueFlyover
                    venue={venueState}
                    isVenueConfirmed={venueConfirmedState}
                    cityId={cityId}
                    citySlug={citySlug}
                    cityTitle={cityTitle}
                    eventId={eventId}
                    eventType={type}
                    isEventCancelled={isEventCancelled}
                    isEventInPast={isEventInPast}
                    isEventPublished={isEventPublished}
                    onSuccess={(
                      updatedObject: {
                        venue: Venue;
                        offer?: Offer;
                        venueOffers?: VenueOffer[];
                      },
                      isVenueConfirmed: boolean
                    ) => {
                      setFlyoverClosed(true);
                      setCancellableVenueId(venueState.id);
                      setVenueState(updatedObject.venue);
                      if (!updatedObject.venue && venueConfirmedState) {
                        setDisplayCancellationReasonForm(true);
                        toggleCancellationReasonConfirmationModal();
                      }
                      setVenueConfirmedState &&
                        setVenueConfirmedState(isVenueConfirmed);
                      setOfferState(updatedObject?.offer);
                      updatedObject?.venueOffers &&
                        setVenueOffersState &&
                        setVenueOffersState(updatedObject?.venueOffers);
                    }}
                    offer={offerState}
                  />
                }
              />
              <ToolTip>
                {venueState && (venueState.venueName || venueState.address)}
              </ToolTip>
            </ToolTipContainer>
          </>
        )
      ) : !(venueState && venueState.id) ? (
        <div data-qaid="venue-icon-label-uneditable">
          <IconLabelNoPointer />
        </div>
      ) : (
        <>
          <ToolTipContainer>
            <div data-qaid="venue-icon-label-uneditable">
              <IconLabelNoPointer
                color={
                  (venueConfirmedState
                    ? eventShadedColors.artistVenueConfirmed
                    : undefined) as ManualCSSColors
                }
                labelTextColor={
                  (venueConfirmedState
                    ? eventShadedColors.artistVenueConfirmed
                    : undefined) as ManualCSSColors
                }
                icon={venueConfirmedState ? 'check' : undefined}
                draft={venueConfirmedState ? undefined : true}
                invertIcon
                labelText={
                  venueState && venueState.venueName
                    ? venueState.venueName
                    : venueState.address
                }
              />
            </div>
            <ToolTip>
              {venueState && (venueState.venueName || venueState.address)}
            </ToolTip>
          </ToolTipContainer>
        </>
      )}

      <KeyValueInfoListContainer>
        <KeyValueInfoList keysAndValues={venueKeysAndValuesList} />
      </KeyValueInfoListContainer>

      <DottedLine />
      <Spacer mt={2} />

      {cancellationReasonConfirmationModal.isShowing &&
        renderCancellationReasonConfirmationModal()}
    </div>
  );
};

export default EventVenue;
