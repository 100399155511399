import React, { useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import {
  ContentWrapper,
  HeaderWrapper,
} from 'app/shared/components/atoms/FlyoverContent';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import {
  Body2,
  Overline,
} from 'app/shared/components/atoms/TypographyManualCSS';
import { CreateCrewMessage } from 'app/admin/graphql/staffMembers/mutationHooks';
import FlyoverFooter from 'app/admin/components/molecules/FlyoverFooter';
import RichTextEditor from 'app/admin/components/molecules/RichTextEditor';

interface Props {
  eventId: number;
  onSuccess: Function;
}

const EditorContainer = styled.div`
  height: 200px;
`;

const CharacterCountWarning = styled.div<{ warning: boolean }>`
  ${({ theme, warning }) => css`
    font-size: 10px;
    color: ${warning ? theme.colors.redRedWine : theme.colors.backToBlack};
  `};
`;

interface BreakingNewsHeaderProps {
  intl: IntlShape;
}

const BreakingNewsHeader = (props: BreakingNewsHeaderProps) => {
  const { intl } = props;

  return (
    <HeaderWrapper>
      <Overline>
        {intl.formatMessage({
          id: 'admin.eventPlanner.team.addBreakingNews',
        })}
      </Overline>
    </HeaderWrapper>
  );
};

interface AddCrewMessageProps {
  intl: IntlShape;
  eventId: number;
  onSuccess: Function;
}

const AddCrewMessage = (props: AddCrewMessageProps) => {
  const { intl, eventId, onSuccess } = props;
  const BODY_CHARACTER_LIMIT = 1000;
  const [messageBody, setMessageBody] = useState<any>(undefined);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const createCrewMessageAction = CreateCrewMessage();

  const handleAddCrewMessage = useSubmitAction({
    submitAction: createCrewMessageAction,
    submitVariables: () => ({
      body: messageBody,
      eventId,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.eventPlanner.team.addBreakingNewsSuccess',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.eventPlanner.team.addBreakingNewsFailure',
    }),
    onSuccess: () => {
      setIsSubmitting(false);
      setMessageBody('');
      onSuccess && onSuccess();
    },
  });

  const stripTags = (value: string) => {
    return value.replace(/(<([^>]+)>)/gi, '');
  };

  const stripWhitespace = (value: string) => {
    return value.replace(/\s/g, '').replace(/&nbsp;/g, '');
  };

  const isEmpty = (html: string) => {
    return stripWhitespace(stripTags(html)) === '';
  };

  const isAboveCharacterLimit = (html: string) => {
    return stripTags(html).length > BODY_CHARACTER_LIMIT;
  };

  const remainingCharacters = (html: string) => {
    return BODY_CHARACTER_LIMIT - stripTags(html).length;
  };

  const remainingChars = messageBody
    ? remainingCharacters(messageBody)
    : BODY_CHARACTER_LIMIT;

  return (
    <>
      <ContentWrapper>
        <EditorContainer>
          <RichTextEditor
            onChange={value => {
              setMessageBody(value);
            }}
            disabled={false}
            name="crew-message-editor"
            currentValue={messageBody}
            maxHeight={200}
          />
        </EditorContainer>
        {remainingChars < 100 && (
          <CharacterCountWarning warning={remainingChars < 0}>
            {remainingChars}{' '}
            {intl.formatMessage({
              id: 'admin.eventPlanner.team.charactersRemaining',
            })}
          </CharacterCountWarning>
        )}
        <Spacer mt={8} />
        <Body2>
          <i>
            {intl.formatMessage({
              id: 'admin.eventPlanner.team.breakingNewsWarning',
            })}
          </i>
        </Body2>
      </ContentWrapper>
      <FlyoverFooter
        buttonText={intl.formatMessage({
          id: 'form.save',
        })}
        buttonDisabled={
          !messageBody ||
          isEmpty(messageBody) ||
          isAboveCharacterLimit(messageBody)
        }
        onClickButton={() => {
          setIsSubmitting(true);
          handleAddCrewMessage();
        }}
        isSubmitting={isSubmitting}
        dataQaid="add-crew-message-submit-button"
      />
    </>
  );
};

const EventPlannerBreakingNewsFlyover: React.FC<Props> = ({
  eventId,
  onSuccess,
}) => {
  const intl = useIntl();

  return (
    <div data-qaid="breaking-news-flyover">
      <BreakingNewsHeader intl={intl} />
      <Spacer mt={1} />
      <AddCrewMessage intl={intl} eventId={eventId} onSuccess={onSuccess} />
    </div>
  );
};

export default EventPlannerBreakingNewsFlyover;
