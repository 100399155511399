import React from 'react';
import styled from 'styled-components';

import { useCurrentTheme } from 'app/shared/theme';
import { StreamlineIcon } from 'app/shared/components/atoms/StreamlineIcon';
import { ToolTip, ToolTipContainer } from 'app/shared/components/atoms/ToolTip';

interface IconButtonProps {
  icon: any;
  disabled?: boolean;
  tipText?: string;
  color?: string;
  size?: number;
}

const BoxedTooltipContainer = styled(ToolTipContainer)`
  display: flex;
  align-items: center;
  justify-content: center;
  height 25px;
  width: 25px;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height 25px;
  width: 25px;
`;

export const IconButton: React.FC<IconButtonProps> = ({
  icon,
  disabled = false,
  size = 16,
  tipText,
  color,
}) => {
  const theme = useCurrentTheme();

  if (tipText) {
    return (
      <BoxedTooltipContainer>
        <StreamlineIcon
          icon={icon}
          stroke={
            disabled ? theme.colors.blueSmoke : color || theme.colors.green600
          }
          size={size}
        />
        <ToolTip>{tipText}</ToolTip>
      </BoxedTooltipContainer>
    );
  }

  return (
    <IconWrapper>
      <StreamlineIcon
        icon={icon}
        stroke={
          disabled ? theme.colors.blueSmoke : color || theme.colors.green600
        }
        size={size}
      />
    </IconWrapper>
  );
};

export default IconButton;
