import * as Yup from 'yup';

const notesMaxLength = 1500;

const themeEditFormSchema = (intl: any) =>
  Yup.object().shape({
    title: Yup.string().required(
      intl.formatMessage({
        id: 'form.requiredField',
      })
    ),
    notes: Yup.string()
      .nullable()
      .test(
        'notes-too-long',
        intl.formatMessage(
          {
            id: 'admin.themeEdit.form.notesTooLong',
          },
          {
            maxLength: String(notesMaxLength),
          }
        ),
        function(value) {
          return value ? value.length <= notesMaxLength : true;
        }
      ),
  });

export default themeEditFormSchema;
