import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { User } from 'app/typings/users';
import { errorMsgForField } from 'app/shared/utils/form';
import { get } from 'app/shared/utils/get';
import { useGoogleMapsApi } from 'app/shared/utils/useGoogleMapsApi';
import usePermission from 'app/shared/utils/usePermission';
import { GetCitiesLite } from 'app/shared/graphql/cities/queryHooks';
import { GetContactRelationships } from 'app/shared/graphql/contactRelationships/queryHooks';
import {
  FormGroupContainer,
  FormHelpText,
  FormSectionTitle,
} from 'app/shared/components/atoms/FormContent';
import GenericFormContainer from 'app/shared/components/atoms/GenericFormContainer';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import { LoadingError } from 'app/shared/components/atoms/LoadingError';
import StyledTextarea from 'app/shared/components/atoms/SmallTextarea';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Textfield } from 'app/shared/components/atoms/Textfield';
import { Body2 } from 'app/shared/components/atoms/TypographyManualCSS';
import CheckboxGroup from 'app/shared/components/molecules/CheckboxGroup';
import ContactInfoFieldGroup from 'app/shared/components/molecules/ContactInfoFieldGroup';
import FormGroup from 'app/shared/components/molecules/FormGroup';
import PlaceSelect from 'app/shared/components/molecules/PlaceSelect';
import RadioGroup from 'app/shared/components/molecules/RadioGroup';
import Select from 'app/shared/components/molecules/SelectManualCSS';
import { getArtistEligibilityOptions } from 'app/admin/utils/optionLists';
import { GetArtistEligibilities } from 'app/admin/graphql/artists/queryHooks';
import { GetPerformingRightsOrganisations } from 'app/admin/graphql/performingRightsOrganisations/queryHooks';
import { GetTags } from 'app/admin/graphql/tags/queryHooks';
import EditFormLoadingBlocks from 'app/admin/components/atoms/EditFormLoadingBlocks';
import { AssociatedUser } from 'app/admin/components/molecules/AssociatedUser';
import UserTypeahead from 'app/admin/components/molecules/UserTypeahead';
import { SIGNED_STATUSES } from 'app/admin/components/organisms/ArtistDetails/SectionBasicInfo';

import { bookingStatusOptions } from './options';

interface FormProps {
  formikProps: any;
  validationErrors?: object;
  numContactInfos?: number;
  associatedUsers?: User[];
  isNewArtist?: boolean;
  needsReview?: boolean;
}

const StyledTextfield = styled(Textfield)`
  ${({ theme }) => css`
    display: block;
    width: 100%;

    ${theme.media.lg`
      width: 50%;
    `};
  `}
`;

const NoAssociatedUsers = styled.div`
  font-size: 14px;
  letter-spacing: 0.1px;
  margin-bottom: 25px;
`;

const AddAssociatedUserContainer = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  max-width: 500px;
`;

const AddAssociatedUser = styled.div`
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.1px;
  margin-bottom: 10px;
`;

interface Tag {
  title: string;
  value: string;
  isEmptySpace?: boolean;
}

const fillWithEmpty = (
  tagValues: any[],
  maxLength: number,
  emptyObject: any
) => {
  const emptySpaces = maxLength - tagValues.length;

  if (emptySpaces <= 0) {
    return tagValues;
  }

  const emptyValues = Array(emptySpaces).fill(emptyObject);

  return [...tagValues, ...emptyValues];
};

const rowsToColumns = (tagValues: Tag[][], length: number) => {
  const flatValues: Tag[] = [];

  for (let i = 0; i < length; i++) {
    const indexedOptions = tagValues.map(tagValue => tagValue[i]);
    flatValues.push(...indexedOptions);
  }

  return flatValues;
};

const getLongestOfArraysLength = (arrayOfArrays: Tag[][]) =>
  Math.max(...arrayOfArrays.map((arr: Tag[]) => arr.length));

const tagsRequiringFullArtistsAccess = ['Booking Paused'];

const tagsOptions = (tagsData: any, hasAccessArtistsPermission?: boolean) => {
  const tagsDataByTagGroup = tagsData.tags.tagGroupNames.reduce(
    (result: object, tagGroupName: string) => {
      result[tagGroupName] = [];
      return result;
    },
    {}
  );

  for (const tag of tagsData.tags.tags) {
    if (
      tagsRequiringFullArtistsAccess.includes(tag.name) &&
      !hasAccessArtistsPermission
    ) {
      continue;
    }
    tagsDataByTagGroup[tag.tagGroupName].push({
      title: tag.name,
      value: tag.id,
    });
  }

  const tagLists: Tag[][] = Object.values(tagsDataByTagGroup);

  const longestTagListLength = getLongestOfArraysLength(tagLists);

  const emptyObject = {
    title: '',
    value: '',
    isEmptySpace: true,
  };

  const tagListsFilledEmpty = tagLists.map((tagValues: any) =>
    fillWithEmpty(tagValues, longestTagListLength, emptyObject)
  );

  const options = rowsToColumns(tagListsFilledEmpty, longestTagListLength);

  return options;
};

const renderAssociatedUsers = (
  associatedUsers: User[] | undefined,
  userIdsToUnassociate: number[],
  removeAssociatedUser: Function,
  intl: any
) => {
  if (associatedUsers && associatedUsers.length > 0) {
    return associatedUsers.map((user: User, i: number) => (
      <AssociatedUser
        user={user}
        userWillBeUnassociatedOnSave={!!userIdsToUnassociate.includes(user.id)}
        removeAssociatedUser={removeAssociatedUser}
        associatedUserRemoveMsg={intl.formatMessage({
          id: 'admin.artistBasicInfo.form.unassociateWarning',
        })}
        key={i}
      />
    ));
  } else {
    return (
      <NoAssociatedUsers>
        {intl.formatMessage({
          id: 'admin.artistBasicInfo.form.noAssociatedUsers',
        })}
      </NoAssociatedUsers>
    );
  }
};

const ArtistBasicInfoForm: React.FC<FormProps> = ({
  formikProps,
  validationErrors = {},
  numContactInfos,
  associatedUsers,
  isNewArtist,
  needsReview,
}) => {
  const intl = useIntl();

  const hasEditArtistSelfBookingPermission = usePermission(
    'artist.artistSelfBooking.edit'
  );
  const hasEditArtistMemberPermission = usePermission(
    'artist.artistMember.edit'
  );
  const hasCreateAllEventTypesPermission = usePermission(
    'event.eventAllTypes.create'
  );
  const hasAccessArtistsPermission = usePermission('artist.list.access');
  const hasEditNeedsReviewPermission = usePermission('artist.needsReview.edit');

  useGoogleMapsApi({ libraries: ['places'] });

  const getCitiesLiteResponse = GetCitiesLite({
    activeStatus: 'all',
  });

  const { loading: loadingTags, error: errorTags, data: dataTags } = GetTags({
    tagContext: 'Artist',
  });

  const {
    loading: loadingArtistEligibilities,
    error: errorArtistEligibilities,
    data: dataArtistEligibilities,
  } = GetArtistEligibilities();

  const {
    loading: loadingContactRelationships,
    error: errorContactRelationships,
    data: dataContactRelationships,
  } = GetContactRelationships({ contactableType: 'Artist' });

  const {
    loading: loadingPerformingRightsOrganisations,
    error: errorPerformingRightsOrganisations,
    data: dataPerformingRightsOrganisations,
  } = GetPerformingRightsOrganisations();

  const { setFieldValue, touched, errors, values } = formikProps;

  if (
    getCitiesLiteResponse.error ||
    errorTags ||
    errorArtistEligibilities ||
    errorContactRelationships ||
    errorPerformingRightsOrganisations
  ) {
    return <LoadingError whatsBeingLoaded="this form" />;
  }

  if (
    getCitiesLiteResponse.loading ||
    !getCitiesLiteResponse.data ||
    loadingTags ||
    !dataTags ||
    loadingArtistEligibilities ||
    !dataArtistEligibilities ||
    loadingContactRelationships ||
    !dataContactRelationships ||
    loadingPerformingRightsOrganisations ||
    !dataPerformingRightsOrganisations
  ) {
    return <EditFormLoadingBlocks />;
  }

  const setTagsFieldValue = (newValues: number[]) => {
    if (!hasAccessArtistsPermission) {
      const tagIdsHiddenBecauseRequirePermission = get(
        dataTags,
        'tags.tags',
        []
      )
        .filter((tag: any) => tagsRequiringFullArtistsAccess.includes(tag.name))
        .map((tag: any) => tag.id);

      for (const tagId of tagIdsHiddenBecauseRequirePermission) {
        if (values.initialTags.includes(tagId) && !newValues.includes(tagId)) {
          newValues.push(tagId);
        }
      }
    }

    setFieldValue('tags', newValues);
  };

  const addAssociatedUser = (userId: number | undefined) => {
    setFieldValue('userIdToAssociate', userId);
  };

  const removeAssociatedUser = (userId: number) => {
    if (!values.userIdsToUnassociate.includes(userId)) {
      setFieldValue(
        'userIdsToUnassociate',
        values.userIdsToUnassociate.concat([userId])
      );
    }
  };

  const contactRelationships =
    // @ts-ignore
    dataContactRelationships.contactRelationships.contactRelationships;

  return (
    <GenericFormContainer dataQaId="artist-about-section-edit-form">
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.artistBasicInfo.form.artistName',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="artist-about-section-edit-form-title-formgroup"
          label={intl.formatMessage({
            id: 'admin.artistBasicInfo.form.artistName',
          })}
          errorMsg={errorMsgForField('title', touched, errors)}
          required
        >
          <StyledTextfield
            data-qaid="artist-about-section-edit-form-title-field"
            id="title"
            name="title"
            value={values.title}
            onChange={(e: any) => {
              setFieldValue('title', e.target.value);
            }}
            maxLength={200}
          />
        </FormGroup>
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.artistBasicInfo.form.artistHomeCity',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <PlaceSelect
          label={intl.formatMessage({
            id: 'artistProfile.homeCity',
          })}
          placeholder={intl.formatMessage({
            id: 'artistProfile.homeCityPlaceholder',
          })}
          onChange={({
            homeCityDescription,
            homeCity,
            homeState,
            homeCountryCode,
            homeCityLatitude,
            homeCityLongitude,
            closestSofarCityId,
          }) => {
            setFieldValue('homeCityDescription', homeCityDescription);
            setFieldValue('homeCity', homeCity);
            setFieldValue('homeState', homeState);
            setFieldValue('homeCountryCode', homeCountryCode);
            setFieldValue('homeCityLatitude', homeCityLatitude);
            setFieldValue('homeCityLongitude', homeCityLongitude);
            setFieldValue('closestSofarCityId', closestSofarCityId);
          }}
          name="homeCityDescription"
          initialValue={values.homeCityDescription}
          initialPlaceLatitude={values.homeCityLatitude}
          initialPlaceLongitude={values.homeCityLongitude}
          cities={getCitiesLiteResponse}
          errorMsg={errorMsgForField('homeCityDescription', touched, errors)}
        />
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.artistBasicInfo.form.contactInfo',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <ContactInfoFieldGroup
          name="primary"
          title={intl.formatMessage({
            id: 'shared.primaryContact',
          })}
          helpText={intl.formatMessage({
            id: 'admin.artistBasicInfo.form.primaryContactHelpText',
          })}
          values={values.contactInfos.primaryContactInfo}
          setFieldValue={setFieldValue}
          errorMsgForField={errorMsgForField}
          touched={touched}
          errors={errors}
          contactRelationships={contactRelationships}
          dataQaidPrefix="artist"
        />
        <ContactInfoFieldGroup
          name="secondary"
          title={intl.formatMessage({
            id: 'shared.contact2',
          })}
          values={values.contactInfos.secondaryContactInfo}
          setFieldValue={setFieldValue}
          errorMsgForField={errorMsgForField}
          touched={touched}
          errors={errors}
          contactRelationships={contactRelationships}
          dataQaidPrefix="artist"
        />
        <ContactInfoFieldGroup
          name="tertiary"
          title={intl.formatMessage({
            id: 'shared.contact3',
          })}
          values={values.contactInfos.tertiaryContactInfo}
          setFieldValue={setFieldValue}
          errorMsgForField={errorMsgForField}
          touched={touched}
          errors={errors}
          contactRelationships={contactRelationships}
          last={true}
          dataQaidPrefix="artist"
        />
        {!!(numContactInfos && numContactInfos > 3) && (
          <>
            <Spacer mb={6} />
            <Body2>
              {intl.formatMessage({
                id: 'admin.artistBasicInfo.form.moreContactsExist',
              })}
            </Body2>
          </>
        )}
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'shared.tags',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <CheckboxGroup
          name="tags"
          options={tagsOptions(dataTags, hasAccessArtistsPermission)}
          selectedValues={values.tags}
          onChange={values => setTagsFieldValue(values)}
        />
      </FormGroupContainer>
      {hasCreateAllEventTypesPermission && (
        <>
          <FormSectionTitle>
            {intl.formatMessage({
              id: 'admin.artistBasicInfo.form.eligibility',
            })}
          </FormSectionTitle>
          <FormGroupContainer>
            <CheckboxGroup
              name="eligibilities"
              options={getArtistEligibilityOptions(dataArtistEligibilities)}
              selectedValues={values.eligibilities}
              onChange={values => setFieldValue('eligibilities', values)}
            />
          </FormGroupContainer>
        </>
      )}
      {hasCreateAllEventTypesPermission && !isNewArtist && (
        <>
          <FormSectionTitle>
            {intl.formatMessage({
              id: 'admin.artistBasicInfo.form.bookingStatus',
            })}
          </FormSectionTitle>
          <FormGroupContainer>
            <CheckboxGroup
              name="bookingStatus"
              options={
                hasEditArtistSelfBookingPermission
                  ? bookingStatusOptions
                  : bookingStatusOptions.filter(
                      (opt: any) => opt.value != 'is_self_booking_enabled'
                    )
              }
              selectedValues={values.bookingStatus}
              onChange={newValues => {
                if (
                  !values.bookingStatus.includes('should_not_book') &&
                  newValues.includes('should_not_book')
                ) {
                  setFieldValue('bookingStatus', ['should_not_book']);
                } else if (
                  !values.bookingStatus.includes('is_self_booking_enabled') &&
                  newValues.includes('is_self_booking_enabled')
                ) {
                  setFieldValue('bookingStatus', ['is_self_booking_enabled']);
                } else {
                  setFieldValue('bookingStatus', []);
                }
              }}
            />
          </FormGroupContainer>
        </>
      )}
      {hasEditNeedsReviewPermission && needsReview && (
        <>
          <FormSectionTitle>
            {intl.formatMessage({
              id: 'admin.artistBasicInfo.form.needsReview',
            })}
          </FormSectionTitle>
          <FormGroupContainer>
            <RadioGroup
              name="needsReview"
              options={[
                {
                  title: intl.formatMessage({
                    id: 'yes',
                  }),
                  value: true,
                },
                {
                  title: intl.formatMessage({
                    id: 'no',
                  }),
                  value: false,
                },
              ]}
              selectedValue={values.needsReview}
              onChange={value => setFieldValue('needsReview', value)}
            />
          </FormGroupContainer>
        </>
      )}
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.artistBasicInfo.form.management',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <Grid>
          <Col xs={12} sm={6}>
            <FormGroup
              data-qaid="artist-about-section-edit-form-performing-rights-organisation-formgroup"
              label="PRO"
              errorMsg={errorMsgForField(
                'performingRightsOrganisation',
                touched,
                errors
              )}
            >
              <Select
                data-qaid="artist-about-section-edit-form-performing-rights-organisation-field"
                name="performing-rights-organisation"
                id="performing-rights-organisation"
                onChange={option => {
                  setFieldValue('performingRightsOrganisation', option);
                }}
                // @ts-ignore
                options={dataPerformingRightsOrganisations.performingRightsOrganisations.performingRightsOrganisations.map(
                  (e: any) => ({
                    value: e.id,
                    label: get(e, 'country.title', undefined)
                      ? `${e.title} - ${e.country.title}`
                      : e.title,
                  })
                )}
                getOptionLabel={(opt: any) => {
                  return opt.label;
                }}
                hasError={
                  !!errorMsgForField(
                    'performingRightsOrganisation',
                    touched,
                    errors
                  )
                }
                placeholder={intl.formatMessage({
                  id: 'admin.artistBasicInfo.form.selectAPro',
                })}
                defaultValue={values.performingRightsOrganisation}
              />
            </FormGroup>
          </Col>
          <Col xs={12} sm={6}>
            <FormGroup
              data-qaid="artist-about-section-edit-form-signed-status-formgroup"
              label={intl.formatMessage({
                id: 'admin.artistBasicInfo.form.labelStatus',
              })}
              errorMsg={errorMsgForField('signedStatus', touched, errors)}
            >
              <Select
                data-qaid="artist-about-section-edit-form-signed-status-field"
                name="signed-status"
                id="signed-status"
                onChange={option => {
                  setFieldValue('signedStatus', option);
                }}
                options={Object.keys(SIGNED_STATUSES).map(key => {
                  return { value: key, label: SIGNED_STATUSES[key] };
                })}
                getOptionLabel={(opt: any) => {
                  return opt.label;
                }}
                hasError={!!errorMsgForField('signedStatus', touched, errors)}
                placeholder={intl.formatMessage({
                  id: 'admin.artistBasicInfo.form.selectALabelStatus',
                })}
                defaultValue={values.signedStatus}
              />
            </FormGroup>
          </Col>
        </Grid>
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.artistBasicInfo.form.description',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormHelpText>
          {intl.formatMessage({
            id: 'admin.artistBasicInfo.form.descriptionHelpText',
          })}
        </FormHelpText>
        <StyledTextarea
          data-qaid="artist-about-section-edit-form-description-field"
          id="description"
          name="description"
          value={values.description}
          onChange={(e: any) => {
            setFieldValue('description', e.target.value);
          }}
          maxLength={2000}
        />
      </FormGroupContainer>
      {hasEditArtistMemberPermission && (
        <>
          <FormSectionTitle>
            {intl.formatMessage({
              id: 'admin.artistBasicInfo.form.associatedUsers',
            })}
          </FormSectionTitle>
          <FormGroupContainer>
            {renderAssociatedUsers(
              associatedUsers,
              values.userIdsToUnassociate,
              removeAssociatedUser,
              intl
            )}
            <FormGroup
              data-qaid="associated-users-formgroup"
              label=""
              // @ts-ignore
              errorMsg={validationErrors.artist_id}
            >
              <AddAssociatedUserContainer>
                <AddAssociatedUser>
                  {intl.formatMessage({
                    id: 'admin.artistBasicInfo.form.addUser',
                  })}
                </AddAssociatedUser>
                <UserTypeahead
                  setSelectedUser={(user: User | undefined) => {
                    addAssociatedUser(user && user.id);
                  }}
                />
              </AddAssociatedUserContainer>
            </FormGroup>
          </FormGroupContainer>
        </>
      )}
    </GenericFormContainer>
  );
};

export default ArtistBasicInfoForm;
