import React from 'react';
import styled from 'styled-components';

import { truncateByChar } from 'app/shared/utils/string';
import { ToolTip } from 'app/shared/components/atoms/ToolTip';

interface Props {
  text: string | null;
  truncateLength: number;
  appendText?: string;
  isLink?: boolean;
}

interface LongTextTooltipContainerProps {
  isLink?: boolean;
}

const LongTextTooltip = styled(ToolTip)`
  left: 0%;
  margin-left: 0px;
  max-width: 400px;
  padding: 10px;
  white-space: normal;
`;

const LongTextTooltipContainer = styled.span<LongTextTooltipContainerProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${props => props.isLink && `color: ${props.theme.colors.green600};`}

  &:hover ${LongTextTooltip} {
    visibility: visible;
    opacity: 1;
  }
`;

const TruncatedByCharText: React.FC<Props> = ({
  text,
  truncateLength,
  appendText,
  isLink = false,
}) => {
  if (text && text.length > truncateLength) {
    const truncatedText = truncateByChar(text, truncateLength, appendText);
    return (
      <LongTextTooltipContainer isLink={isLink}>
        {truncatedText}
        <LongTextTooltip>{text}</LongTextTooltip>
      </LongTextTooltipContainer>
    );
  } else {
    return <>{text}</>;
  }
};

export default TruncatedByCharText;
