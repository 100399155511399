import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { errorMsgForField } from 'app/shared/utils/form';
import usePermission from 'app/shared/utils/usePermission';
import { FormGroupContainer } from 'app/shared/components/atoms/FormContent';
import { Icon } from 'app/shared/components/atoms/IconManualCSS';
import { GenericLink } from 'app/shared/components/atoms/LinkManualCSS';
import FormGroup from 'app/shared/components/molecules/FormGroup';

const AssociatedCompetitionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const AssociatedCompetitionDisplayContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: flex-start;
`;

const TrashCanIconActive = styled(Icon)`
  ${({ theme }) => css`
    &::before {
      color: ${theme.colors.green600};
    }
    cursor: pointer;
  `}
`;

const TrashCanIconInactive = styled(Icon)`
  ${({ theme }) => css`
    &::before {
      color: ${theme.colors.blueSmoke};
    }
  `}
`;

const CompetitionRemoveContainer = styled.div`
  padding-left: 9px;
`;

const CompetitionContainer = styled.div`
  font-size: 14px;
  letter-spacing: 0.4px;
  padding-top: 4px;
  margin-bottom: 12px;
`;

const PublishedStatusRemoveMsgContainer = styled.div`
  ${({ theme }) => css`
    font-size: 14px;
    color: ${theme.colors.redRedWine};
    margin-top: -9px;
    margin-bottom: 12px;
  `}
`;

const AssociatedCompetitionRemoveMsgContainer = styled.div`
  font-size: 14px;
  margin-top: -9px;
  margin-bottom: 12px;
`;

interface CompetitionFormGroupProps {
  formikProps: any;
  eventId?: number;
  status?: string;
}

const CompetitionFormGroup: React.FC<CompetitionFormGroupProps> = ({
  formikProps,
  status,
  eventId,
}) => {
  const intl = useIntl();
  const { setFieldValue, values, errors, touched } = formikProps;

  const hasAccessCompetitionsPermission = usePermission('competition.access');

  const isTrashcanInactive = (status: string | undefined) => {
    if (
      (status && status === 'published') ||
      values.competitionsToUnassociate.includes(values.competition.id)
    ) {
      return true;
    }

    return false;
  };

  const removeAssociatedCompetition = (competitionId: number) => {
    setFieldValue(
      'competitionsToUnassociate',
      values.competitionsToUnassociate.concat([competitionId])
    );
  };

  return (
    <>
      {values.competition && values.competition.id ? (
        <FormGroupContainer>
          <FormGroup
            data-qaid="event-basic-info-form-themes-formgroup"
            label={intl.formatMessage({
              id: 'admin.eventBasicInfo.competitionName',
            })}
            errorMsg={errorMsgForField('competition', touched, errors)}
          >
            <>
              <AssociatedCompetitionContainer>
                <AssociatedCompetitionDisplayContainer>
                  <CompetitionContainer>
                    {values.competition.title}
                  </CompetitionContainer>

                  {isTrashcanInactive(status) ? (
                    <CompetitionRemoveContainer>
                      <TrashCanIconInactive name="trashCan" size="14px" />
                    </CompetitionRemoveContainer>
                  ) : (
                    <CompetitionRemoveContainer
                      onClick={() =>
                        removeAssociatedCompetition(values.competition.id)
                      }
                    >
                      <TrashCanIconActive name="trashCan" size="14px" />
                    </CompetitionRemoveContainer>
                  )}
                </AssociatedCompetitionDisplayContainer>
                {values.competitionsToUnassociate.includes(
                  values.competition.id
                ) && (
                  <AssociatedCompetitionRemoveMsgContainer>
                    {intl.formatMessage({
                      id: 'admin.eventBasicInfo.form.unassociateWarning',
                    })}
                  </AssociatedCompetitionRemoveMsgContainer>
                )}
                {status === 'published' && (
                  <PublishedStatusRemoveMsgContainer>
                    {intl.formatMessage({
                      id: 'admin.eventBasicInfo.form.publishedWarning',
                    })}
                  </PublishedStatusRemoveMsgContainer>
                )}
              </AssociatedCompetitionContainer>
              {hasAccessCompetitionsPermission && (
                <GenericLink
                  url={`/admin/competitions?competition_id=${values.competition.id}`}
                >
                  {intl.formatMessage({
                    id: 'admin.eventBasicInfo.manageCompetition',
                  })}
                </GenericLink>
              )}
            </>
          </FormGroup>
        </FormGroupContainer>
      ) : (
        hasAccessCompetitionsPermission && (
          <FormGroupContainer>
            <FormGroup
              data-qaid="event-basic-info-form-themes-formgroup"
              label={intl.formatMessage(
                {
                  id: 'admin.eventBasicInfo.addCompetition',
                },
                {
                  eventId,
                }
              )}
              errorMsg={errorMsgForField('competition', touched, errors)}
            >
              <GenericLink url="/admin/competitions">
                {intl.formatMessage({
                  id: 'admin.eventBasicInfo.newCompetition',
                })}
              </GenericLink>
            </FormGroup>
          </FormGroupContainer>
        )
      )}
    </>
  );
};

export default CompetitionFormGroup;
