import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { errorMsgForField } from 'app/shared/utils/form';
import { Checkbox } from 'app/shared/components/atoms/CheckboxManualCSS';
import {
  FormGroupContainer,
  FormSectionTitle,
} from 'app/shared/components/atoms/FormContent';
import GenericFormContainer from 'app/shared/components/atoms/GenericFormContainer';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import FormGroup from 'app/shared/components/molecules/FormGroup';
import { numTicketsSoldArtistCompensationTiers } from 'app/admin/utils/artistPayments';
import { BigNumberTextfield } from 'app/admin/components/molecules/Textfield';

interface FormProps {
  formikProps: any;
  validationErrors?: object;
  isNewCountry?: boolean;
}

const ArtistCompensationTiersContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ArtistCompensationTierContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 7px;
`;

const ArtistCompensationTierLabel = styled.div`
  font-weight: 600;
  padding-top: 3px;
  width: 200px;
`;

const ArtistCompensationTierField = styled.div`
  padding-left: 8px;
  width: 300px;
`;

const CountrySettingsEditForm: React.FC<FormProps> = ({ formikProps }) => {
  const intl = useIntl();

  const { setFieldValue, touched, errors, values } = formikProps;

  return (
    <GenericFormContainer dataQaId="country-settings-section-edit-form">
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.countryBasicInfo.form.bookingFee',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="country-settings-edit-form-booking-fee-formgroup"
          label={intl.formatMessage({
            id: 'admin.countryBasicInfo.form.bookingFeeWithHelpText',
          })}
          errorMsg={errorMsgForField('bookingFee', touched, errors)}
        >
          <BigNumberTextfield
            data-qaid="country-settings-edit-form-booking-fee-field"
            id="bookingFee"
            name="bookingFee"
            value={values.bookingFee}
            onChange={(e: any) => {
              setFieldValue('bookingFee', e.target.value);
            }}
            maxLength={10}
          />
        </FormGroup>
        <Spacer mb={6} />
        <Checkbox
          id="country-settings-edit-form-display-booking-fee-field"
          data-qaid="country-settings-edit-form-display-booking-fee-field"
          checked={values.displayBookingFee}
          name="displayBookingFee"
          onChange={e => setFieldValue('displayBookingFee', e.target.checked)}
        >
          {intl.formatMessage({
            id: 'admin.countryBasicInfo.form.displayBookingFee',
          })}
        </Checkbox>
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.countryBasicInfo.form.artistCompensation',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <Checkbox
          id="country-settings-edit-form-is-tiered-artist-compensation-enabled-field"
          data-qaid="country-settings-edit-form-is-tiered-artist-compensation-enabled-field"
          checked={values.isTieredArtistCompensationEnabled}
          name="isTieredArtistCompensationEnabled"
          onChange={e =>
            setFieldValue('isTieredArtistCompensationEnabled', e.target.checked)
          }
        >
          {intl.formatMessage({
            id: 'admin.countryBasicInfo.form.useTieredArtistCompensation',
          })}
        </Checkbox>
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.countryBasicInfo.form.defaultArtistCompensation',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="country-settings-edit-form-artist-compensation-formgroup"
          label={intl.formatMessage({
            id: 'admin.countryBasicInfo.form.defaultArtistCompensation',
          })}
          errorMsg={errorMsgForField('artistCompensation', touched, errors)}
          required
        >
          <BigNumberTextfield
            data-qaid="country-settings-edit-form-artist-compensation-field"
            id="artistCompensation"
            name="artistCompensation"
            value={values.artistCompensation}
            onChange={(e: any) => {
              setFieldValue('artistCompensation', e.target.value);
            }}
            maxLength={10}
          />
        </FormGroup>
        <Spacer mb={6} />
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.countryBasicInfo.form.tieredArtistCompensation',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <ArtistCompensationTiersContainer>
          {Object.entries(numTicketsSoldArtistCompensationTiers).map(
            ([tierNumInfo, tierInfo]) => {
              const tierNum = Number(tierNumInfo.replace(/tier/, ''));
              return (
                <ArtistCompensationTierContainer key={tierNum}>
                  <ArtistCompensationTierLabel>
                    {intl.formatMessage(
                      {
                        id: 'admin.countries.heading.tierNum',
                      },
                      {
                        tierNum: tierNum.toString(),
                        numTickets: tierInfo,
                      }
                    )}
                  </ArtistCompensationTierLabel>
                  <ArtistCompensationTierField>
                    <FormGroup
                      data-qaid={`country-settings-edit-form-artist-compensation-tier-${tierNum}-formgroup`}
                      label=""
                      errorMsg={errorMsgForField(
                        `artistCompensationTier${tierNum}`,
                        touched,
                        errors
                      )}
                    >
                      <BigNumberTextfield
                        data-qaid={`country-settings-edit-form-artist-compensation-tier-${tierNum}-field`}
                        id={`artistCompensationTier${tierNum}`}
                        name={`artistCompensationTier${tierNum}`}
                        value={values[`artistCompensationTier${tierNum}`]}
                        onChange={(e: any) => {
                          setFieldValue(
                            `artistCompensationTier${tierNum}`,
                            e.target.value
                          );
                        }}
                        maxLength={10}
                      />
                    </FormGroup>
                  </ArtistCompensationTierField>
                </ArtistCompensationTierContainer>
              );
            }
          )}
        </ArtistCompensationTiersContainer>
      </FormGroupContainer>
    </GenericFormContainer>
  );
};

export default CountrySettingsEditForm;
