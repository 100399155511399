import React, { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';

import useModal from 'app/shared/utils/useModal';
import { GetCitiesForGroupByBusinessOwner } from 'app/shared/graphql/cities/queryHooks';
import { ListPage, ListPageContext } from 'app/shared/context/ListPage';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import { LoadingBlocks } from 'app/shared/components/atoms/LoadingBlocks';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import RoutableModal from 'app/shared/components/molecules/RoutableModal';
import { GlobalError } from 'app/shared/components/pages/Status';
import {
  getBusinessOwnerOptions,
  getCityWithBusinessOwnerOptions,
  getFilterNamesAndOptionsForTitleMapping,
} from 'app/admin/utils/optionLists';
import { GetBusinessOwners } from 'app/admin/graphql/businessOwners/queryHooks';
import { GetEventCollectionsForAdmin } from 'app/admin/graphql/eventCollections/queryHooks';
import ListingFooter from 'app/admin/components/molecules/ListingFooter';
import ListingNoResults from 'app/admin/components/molecules/ListingNoResults';
import CardGrid, { CardType } from 'app/admin/components/organisms/CardGrid';
import EventCollectionCard from 'app/admin/components/organisms/EventCollectionCard';
import ListingControls from 'app/admin/components/organisms/ListingControls';
import ListingFilter from 'app/admin/components/organisms/ListingFilter';
import ListingHeader from 'app/admin/components/organisms/ListingHeader';
import ListPageTemplate from 'app/admin/components/templates/ListPage';
import Layout from 'app/admin/layouts/ListPage';

import { sortOptions, staticOptions } from './options';
import Analytics from './tracking';

const pageStateConfig = {
  filterNames: ['business_owner', 'city', 'collection_type', 'status'],
  defaultSort: { by: 'created_at', direction: 'desc' },
  textSearchParamName: 'event_collection_search',
  idParamName: 'event_collection_id',
};

const AdminCollections: React.FC = () => {
  const intl = useIntl();
  const pageState = useContext(ListPageContext);
  const PER_PAGE = 18;

  const [addCollectionModal, toggleAddCollectionModal] = useModal();

  const businessOwnerFilterValue = pageState.filterListVariable(
    'business_owner'
  );

  const {
    loading: loadingCities,
    error: errorCities,
    data: dataCities,
  } = GetCitiesForGroupByBusinessOwner({
    activeStatus: 'all',
    businessOwner: businessOwnerFilterValue,
  });

  const {
    loading: loadingBusinessOwners,
    error: errorBusinessOwners,
    data: dataBusinessOwners,
  } = GetBusinessOwners({
    archivedStatus: 'not_archived',
    skipPagination: true,
  });

  const cityOptions = getCityWithBusinessOwnerOptions(dataCities);
  const businessOwnerOptions = getBusinessOwnerOptions(dataBusinessOwners);

  const businessOwnerFilterIsApplied =
    businessOwnerFilterValue && businessOwnerFilterValue.length > 1;

  const filterDropdownOptionsInfoList = [
    {
      filterName: 'business_owner',
      dropdownParams: {
        searchBar: false,
        title: intl.formatMessage({
          id: 'admin.eventCollections.filter.businessOwner',
        }),
        options: businessOwnerOptions,
      },
    },
    {
      filterName: 'city',
      filterDependentOn: businessOwnerFilterIsApplied
        ? 'business_owner'
        : undefined,
      dropdownParams: {
        searchBar: true,
        title: intl.formatMessage({
          id: 'admin.eventCollections.filter.city',
        }),
        groupBy: businessOwnerFilterIsApplied
          ? 'businessOwnerGroupName'
          : 'country',
        groupById: businessOwnerFilterIsApplied
          ? 'businessOwnerCachedSlug'
          : 'country',
        options: cityOptions,
      },
    },
    {
      filterName: 'collection_type',
      dropdownParams: {
        title: intl.formatMessage({
          id: 'admin.eventCollections.filter.collectionType',
        }),
        options: staticOptions(intl).collectionType,
      },
    },
    {
      filterName: 'status',
      dropdownParams: {
        title: intl.formatMessage({
          id: 'admin.eventCollections.filter.status',
        }),
        options: staticOptions(intl).status,
      },
    },
  ];

  const {
    loading: loadingEventCollections,
    error: errorEventCollections,
    data: dataEventCollections,
    refetch: refetchEventCollections,
  } = GetEventCollectionsForAdmin({
    eventCollectionSearch: pageState.textSearchState,
    businessOwner: businessOwnerFilterValue,
    city: pageState.filterListVariable('city'),
    collectionType: pageState.filterListVariable('collection_type'),
    status: pageState.filterListVariable('status') || 'active,inactive',
    orderBy: pageState.sortState.by,
    orderDirection: pageState.sortState.direction,
    page: pageState.page,
    perPage: PER_PAGE,
  });

  useEffect(() => {
    pageState.updateDetailsModal();
  }, []);

  useEffect(() => {
    pageState.updateScrollPositionOnPage();
  }, [pageState.detailsModal.isShowing]);

  useEffect(() => {
    pageState.setupFilterLabelTitleMapping(
      getFilterNamesAndOptionsForTitleMapping(filterDropdownOptionsInfoList)
    );
  }, [dataCities, dataBusinessOwners]);

  useEffect(() => {
    pageState.handleRemoveOrphanDependentFilters({
      parentFilter: 'business_owner',
      dependentFilter: 'city',
      dependentFilterOptions: cityOptions,
    });
  }, [cityOptions, pageState]);

  useEffect(() => {
    pageState.updatePageUrl();
  }, [
    pageState.page,
    pageState.sortState,
    pageState.filterState,
    pageState.textSearchState,
    pageState.detailData,
    pageState.detailsModal.isShowing,
    pageState.viewModeState,
  ]);

  useEffect(() => {
    Analytics.pushDataLayer();
  }, [pageState.filterState, pageState.textSearchState]);

  if (
    (!loadingEventCollections && !dataEventCollections) ||
    errorEventCollections ||
    (!loadingCities && !dataCities) ||
    errorCities ||
    (!loadingBusinessOwners && !dataBusinessOwners) ||
    errorBusinessOwners
  ) {
    return <GlobalError />;
  }

  const modalsContent = () => (
    <>
      {pageState.detailsModal.isShowing && (
        <RoutableModal
          hide={pageState.detailsModal.hide}
          initialRouteProps={pageState.detailData}
          initialRouteName="event-collection-details"
          dataQaidSuffix="admin-event-collection-details"
        />
      )}
      {addCollectionModal.isShowing && (
        <RoutableModal
          hide={addCollectionModal.hide}
          initialRouteProps={{
            refetchEventCollections,
          }}
          initialRouteName="event-collection-create"
          dataQaidSuffix="admin-create-event-collection"
        />
      )}
    </>
  );

  return (
    <Layout scrollDisabled={pageState.detailsModal.isShowing}>
      <ListPageTemplate
        pageTitle={intl.formatMessage({
          id: 'admin.eventCollections.title',
        })}
        modalsContent={modalsContent}
      >
        <ListingHeader
          pageTitle={intl.formatMessage({
            id: 'admin.eventCollections.title',
          })}
          addEntityText={intl.formatMessage({
            id: 'admin.eventCollections.addCollection',
          })}
          onClickAddEntity={toggleAddCollectionModal}
          searchPlaceholder={intl.formatMessage({
            id: 'admin.eventCollections.textSearch.simplePlaceholder',
          })}
          searchValue={pageState.textSearchState}
          onSearch={pageState.handleTextSearch}
          dataQaidPrefix="event-collections"
        />
        <div>
          <ListingControls
            sortOptions={sortOptions(intl)}
            orderBy={pageState.sortState.by}
            orderDirection={pageState.sortState.direction}
            totalRecords={
              dataEventCollections &&
              dataEventCollections.eventCollections.metadata.totalRecords
            }
            loading={loadingEventCollections}
            onSort={pageState.handleSortChange}
            onReset={pageState.handleResetFilters}
            dataQaidPrefix="event-collections"
          />
          <ListingFilter
            filterTitle={intl.formatMessage({
              id: 'admin.eventCollections.filterTitle',
            })}
            textSearchString={pageState.textSearchState}
            handleTextSearchLabelClose={pageState.handleTextSearchLabelClose}
            labelTitleMapping={pageState.filterLabelTitleMapping}
            dropdownOptionsInfoList={filterDropdownOptionsInfoList}
            filterState={pageState.filterState}
            handleRemoveFilter={pageState.handleRemoveFilter}
            handleFilterChange={pageState.handleFilterChange}
          />
        </div>

        <div>
          <CardGrid
            objectData={
              dataEventCollections?.eventCollections?.eventCollections || []
            }
            renderCardComponent={(eventCollection: CardType, i: number) => (
              // @ts-ignore
              <EventCollectionCard
                index={i}
                onShowDetails={pageState.toggleDetailsModalAndSetDetailData}
                {...eventCollection}
              />
            )}
            dataQaid="admin-crew-users-list"
            loading={loadingEventCollections}
            loadingComponent={
              <LoadingBlocks.Rectangle width="100%" height="420px" />
            }
            hideDividerOnSize="xs"
          />
          <DottedLine />
          <Spacer mb={2} />
        </div>

        <ListingNoResults
          entityName={intl.formatMessage({
            id: 'admin.eventCollections.noResultsEntityName',
          })}
          numResults={
            dataEventCollections?.eventCollections.eventCollections.length
          }
          loading={loadingEventCollections}
        />

        <ListingFooter
          numTotalRecords={
            dataEventCollections?.eventCollections.metadata.totalRecords
          }
          perPage={PER_PAGE}
          currentPage={pageState.page}
          onPageChange={pageState.handlePageChange}
          loading={loadingEventCollections}
          dataQaidPrefix="collections-directory"
        />
      </ListPageTemplate>
    </Layout>
  );
};

const AdminCollectionsWrapper: React.FC = () => (
  <ListPage config={pageStateConfig} analytics={Analytics}>
    <AdminCollections />
  </ListPage>
);

export default AdminCollectionsWrapper;
