import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { errorMsgForField } from 'app/shared/utils/form';
import GenericFormContainer from 'app/shared/components/atoms/GenericFormContainer';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Textarea } from 'app/shared/components/atoms/Textarea';
import { Textfield } from 'app/shared/components/atoms/Textfield';
import { FlyoverFormGroup } from 'app/admin/components/molecules/FlyoverFormContent';
import { SmallNumberTextfield } from 'app/admin/components/molecules/Textfield';

interface FormProps {
  formikProps: any;
  setFormSubmitAction?: (func: Function) => void;
  validationErrors?: object;
}

const StyledTextfield = styled(Textfield)`
  ${({ theme }) => css`
    display: block;
    width: 100%;
    ${theme.media.lg`
      width: 90%;
    `};
  `}
`;

const GuestlistVipEditForm: React.FC<FormProps> = ({
  formikProps,
  setFormSubmitAction,
  validationErrors = {},
}) => {
  const intl = useIntl();

  useEffect(() => {
    setFormSubmitAction && setFormSubmitAction(() => formikProps.submitForm);
  }, [
    formikProps.isSubmitting,
    formikProps.submitForm,
    formikProps.dirty,
    setFormSubmitAction,
  ]);

  const { setFieldValue, touched, errors, values } = formikProps;

  return (
    <GenericFormContainer dataQaId="guestlist-vip-edit-form">
      <FlyoverFormGroup
        data-qaid="guestlist-vip-edit-form-full-name-formgroup"
        label={intl.formatMessage({
          id: 'admin.guestlist.vipEdit.fullName',
        })}
        errorMsg={errorMsgForField('fullName', touched, errors)}
        required
      >
        <StyledTextfield
          data-qaid="guestlist-vip-edit-form-full-name"
          id="fullName"
          name="fullName"
          value={values.fullName}
          onChange={(e: any) => {
            setFieldValue('fullName', e.target.value);
          }}
          maxLength={80}
        />
      </FlyoverFormGroup>
      <Spacer mb={3} />
      <FlyoverFormGroup
        data-qaid="guestlist-vip-edit-form-email-formgroup"
        label={intl.formatMessage({
          id: 'shared.email',
        })}
        errorMsg={errorMsgForField('email', touched, errors)}
        required
      >
        <StyledTextfield
          data-qaid="guestlist-vip-edit-form-email"
          id="email"
          name="email"
          value={values.email}
          onChange={(e: any) => {
            setFieldValue('email', e.target.value);
          }}
          maxLength={100}
        />
      </FlyoverFormGroup>
      <Spacer mb={3} />
      <FlyoverFormGroup
        data-qaid="guestlist-vip-edit-form-reference-formgroup"
        label={intl.formatMessage({
          id: 'admin.guestlist.vipEdit.reference',
        })}
        errorMsg={errorMsgForField('reference', touched, errors)}
        required
      >
        <Textarea
          data-qaid="guestlist-vip-edit-form-reference-field"
          id="reference"
          name="reference"
          value={values.reference}
          placeholder=""
          onChange={(e: any) => {
            setFieldValue('reference', e.target.value);
          }}
          maxLength={200}
        />
      </FlyoverFormGroup>
      <Spacer mb={3} />
      <FlyoverFormGroup
        data-qaid="guestlist-vip-edit-form-tickets-count-formgroup"
        label={intl.formatMessage({
          id: 'admin.guestlist.vipEdit.ticketsCount',
        })}
        errorMsg={
          errorMsgForField('ticketsCount', touched, errors) ||
          // @ts-ignore
          validationErrors.general
        }
        required
      >
        <SmallNumberTextfield
          data-qaid="guestlist-vip-edit-form-tickets-count-field"
          id="ticketsCount"
          name="ticketsCount"
          value={values.ticketsCount}
          onChange={(e: any) => {
            setFieldValue('ticketsCount', e.target.value);
          }}
          maxLength={2}
        />
      </FlyoverFormGroup>
    </GenericFormContainer>
  );
};

export default GuestlistVipEditForm;
