import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { get } from 'app/shared/utils/get';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { userFullNameOrEmail } from 'app/admin/utils/users';
import { UpdateUserFromCrewDirectory } from 'app/admin/graphql/users/mutationHooks';
import { DetailsFormMainContainer } from 'app/admin/components/atoms/DetailContent';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

import CrewUserBasicInfoForm from './CrewUserBasicInfoForm';
import CrewUserBasicInfoFormSchema from './CrewUserBasicInfoFormSchema';

interface Props {
  contentProps?: any;
  setFormSubmitAction: (func: Function) => void;
  setDisplayConfirmation: (set: boolean) => void;
  setIsSubmitting: (set: boolean) => void;
  navigateTo: (routeData: object) => void;
}

const CrewUserBasicInfoEdit: React.FC<Props> = ({
  contentProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  navigateTo,
}) => {
  const intl = useIntl();

  const [validationErrors, setValidationErrors] = useState<object>({});

  const formInitialValues = {
    firstName: get(contentProps, 'firstName', '') || '',
    lastName: get(contentProps, 'lastName', '') || '',
    email: get(contentProps, 'email', '') || '',
    countryCode: get(contentProps, 'mobile.countryCodeIso3166', '') || '',
    mobile: get(contentProps, 'mobile.numberBody', '') || '',
    slackUserId: get(contentProps, 'slackUserId', '') || '',
  };

  const userName = userFullNameOrEmail(contentProps);

  const updateUserFromCrewDirectoryAction = UpdateUserFromCrewDirectory();

  const handleSubmit = useSubmitAction({
    submitAction: updateUserFromCrewDirectoryAction,
    submitVariables: (values: any) => ({
      userId: get(contentProps, 'id', undefined),
      firstName: values.firstName || null,
      lastName: values.lastName || null,
      email: values.email || null,
      mobile: {
        countryCode: values.countryCode || null,
        numberBody: values.mobile || null,
      },
      slackUserId: values.slackUserId || null,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.userBasicInfo.form.successMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.userBasicInfo.form.failureMessage',
    }),
    onSuccess: (response: any) => {
      navigateTo({
        routeName: 'crew-user-details',
        routeProps: {
          defaultOpenSection: 'basicInfo',
          ...response.data.updateUser.user,
        },
      });
    },
    onValidationError: (validationErrors: any) =>
      setValidationErrors(validationErrors),
    validationErrorKeysForDisplay: {
      mobile: 'mobile number',
    },
  });

  return (
    <ModalContentContainer>
      <DetailsFormMainContainer>
        <DetailsHeader title={userName} />
        <GenericForm
          formInitialValues={formInitialValues}
          renderFormComponent={(renderProps: any) => (
            <CrewUserBasicInfoForm
              formikProps={renderProps.formikProps}
              validationErrors={validationErrors}
            />
          )}
          onSubmit={handleSubmit}
          formSchema={CrewUserBasicInfoFormSchema(intl)}
          setFormSubmitAction={setFormSubmitAction}
          setDisplayConfirmation={setDisplayConfirmation}
          setIsSubmitting={setIsSubmitting}
          dataQaId="crew-user-about-section-edit-form"
        />
      </DetailsFormMainContainer>
    </ModalContentContainer>
  );
};

export default CrewUserBasicInfoEdit;
