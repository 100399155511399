import {
  number as YupNumber,
  object as YupObject,
  string as YupString,
} from 'yup';

import { isEmpty } from 'app/shared/utils/object';
import { SUPPORTED_IMAGE_FORMATS } from 'app/shared/components/organisms/ImageUploaderWithCropper';

const ImageUploaderFormSchema = (intl: any) => {
  YupObject().shape({
    mainImage: YupObject()
      .shape({
        url: YupString(),
        filename: YupString(),
        contentType: YupString(),
        size: YupNumber(),
        id: YupString(),
      })
      .test(
        'isCropped',
        () =>
          intl.formatMessage({
            id: 'admin.imageUploaderForm.imageMustBeCropped',
          }),
        value => {
          if (isEmpty(value)) {
            return true;
          }

          return value.id;
        }
      )
      .test('fileSize', 'File too large', value => {
        if (isEmpty(value)) {
          return true;
        }

        return value.size <= 25000 * 1024;
      })
      .test('fileFormat', 'Unsupported image format', value => {
        if (isEmpty(value)) {
          return true;
        }

        return Object.keys(SUPPORTED_IMAGE_FORMATS).includes(value.contentType);
      })
      .nullable(),
  });
};

export default ImageUploaderFormSchema;
