export const sortOptions = [
  {
    title: 'Soonest Start Time',
    value: {
      by: 'start_time',
      direction: 'asc',
    },
  },
  {
    title: 'Latest Start Time',
    value: {
      by: 'start_time',
      direction: 'desc',
    },
  },
  {
    title: 'Artist (A - Z)',
    value: {
      by: 'artist_name',
      direction: 'asc',
    },
  },
  {
    title: 'Artist (Z - A)',
    value: {
      by: 'artist_name',
      direction: 'desc',
    },
  },
  {
    title: 'Location (A - Z)',
    value: {
      by: 'location',
      direction: 'asc',
    },
  },
  {
    title: 'Location (Z - A)',
    value: {
      by: 'location',
      direction: 'desc',
    },
  },
];

export const scopeOptions = [
  {
    title: 'Upcoming',
    value: 'upcoming',
  },
  {
    title: 'Past',
    value: 'past',
  },
];

export const streamTypeOptions = [
  {
    title: 'Live Stream',
    value: 'live_stream',
  },
  {
    title: 'Rerun',
    value: 'rerun',
  },
];
