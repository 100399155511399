import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { get } from 'app/shared/utils/get';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import DetailsEventList from 'app/admin/components/organisms/DetailsEventList';

interface Props {
  sectionData: any;
  callbacks: { [name: string]: Function };
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const SectionEvents: React.FC<Props> = ({ sectionData, callbacks }) => {
  const intl = useIntl();

  if (!sectionData) {
    return null;
  }

  const { data } = sectionData;

  const upcomingEvents = get(data, 'upcomingEvents', undefined);
  const upcomingEventsMetadata = get(data, 'upcomingEventsMetadata', undefined);

  const pastEvents = get(data, 'pastEvents', undefined);
  const pastEventsMetadata = get(data, 'pastEventsMetadata', undefined);

  return (
    <MainContainer data-qaid="section-events">
      <Grid cols={4}>
        {upcomingEvents && (
          <Col xs={12} sm={12} md={1} lg={1} xl={1} key={2}>
            <DetailsEventList
              title={intl.formatMessage({
                id: 'admin.artistDirectory.events.upcomingShows',
              })}
              events={upcomingEvents}
              totalNumEvents={upcomingEventsMetadata.totalRecords}
              onClickSeeAll={() =>
                callbacks.toggleAllEventsAndSetType('EventsUpcoming')
              }
              dataQaidPrefix="upcoming-events"
            />
          </Col>
        )}
        {pastEvents && (
          <Col xs={12} sm={12} md={1} lg={1} xl={1} key={3}>
            <DetailsEventList
              title={intl.formatMessage({
                id: 'admin.artistDirectory.events.pastShows',
              })}
              onClickSeeAll={() =>
                callbacks.toggleAllEventsAndSetType('EventsPast')
              }
              events={pastEvents}
              totalNumEvents={pastEventsMetadata.totalRecords}
              dataQaidPrefix="past-events"
            />
          </Col>
        )}
      </Grid>
    </MainContainer>
  );
};

export default SectionEvents;
