import React from 'react';
import styled from 'styled-components';

import { Version } from 'app/typings';
import TruncatedByCharText from 'app/shared/components/atoms/TruncatedByCharText';

const ChangeDiv = styled.div<{ shaded?: boolean; lastColumn?: boolean }>`
  ${props => props.shaded && `background-color: ${props.theme.colors.macyGrey}`}
  ${props => props.lastColumn && 'margin-right: 12px'}
`;

const formatVersionChange = (value: any) => {
  if (typeof value === 'boolean') {
    return `${value}`;
  }

  if (!value) {
    return;
  }

  if (typeof value === 'string') {
    if (value.includes('url_facebook')) {
      value = JSON.parse(value.replace(/\\/g, ''));
    }

    const date = new Date(value).toUTCString();
    if (date && date !== 'Invalid Date') {
      value = date;
    }
  }

  if (typeof value === 'object' && !Array.isArray(value)) {
    const entries = Object.keys(value).map(objectKey => {
      return `${objectKey}: ${value[objectKey]}`;
    });
    return entries.join(', ');
  }

  return value;
};

export const rowValue = ({
  version,
  key,
}: {
  version: Version;
  key: string;
}) => {
  const { versionFrom, versionTo, changeset } = version;
  const changed = changeset && typeof changeset[key] !== 'undefined';

  return [
    <ChangeDiv key={key} shaded={changed}>
      {key}
    </ChangeDiv>,
    <ChangeDiv key={`from-${key}`} id={`from-${key}`} shaded={changed}>
      {versionFrom && (
        <TruncatedByCharText
          text={formatVersionChange(versionFrom[key])}
          truncateLength={45}
        />
      )}
    </ChangeDiv>,
    <ChangeDiv
      key={`to-${key}`}
      id={`to-${key}`}
      shaded={changed}
      lastColumn={true}
    >
      {versionTo && (
        <TruncatedByCharText
          text={formatVersionChange(versionTo[key])}
          truncateLength={45}
        />
      )}
    </ChangeDiv>,
  ];
};
