import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { errorMsgForField } from 'app/shared/utils/form';
import {
  FormGroupContainer,
  FormHelpText,
  FormSectionTitle,
} from 'app/shared/components/atoms/FormContent';
import GenericFormContainer from 'app/shared/components/atoms/GenericFormContainer';
import { Textfield } from 'app/shared/components/atoms/Textfield';
import FormGroup from 'app/shared/components/molecules/FormGroup';

interface FormProps {
  formikProps: any;
  validationErrors?: object;
}

const TemplateNameTextfield = styled(Textfield)`
  display: block;
  width: 75%;
`;

const CompetitionTemplateCreateForm: React.FC<FormProps> = ({
  formikProps,
  validationErrors = {},
}) => {
  const intl = useIntl();

  const { setFieldValue, touched, errors, values } = formikProps;

  return (
    <GenericFormContainer dataQaId="competition-template-create-form">
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.competitionTemplateCreate.form.templateName',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormHelpText>
          {intl.formatMessage({
            id: 'admin.competitionTemplateCreate.form.createHelpText',
          })}
        </FormHelpText>
        <FormGroup
          data-qaid="competition-template-create-form-template-name-formgroup"
          label={intl.formatMessage({
            id: 'admin.competitionTemplateCreate.form.templateName',
          })}
          errorMsg={
            errorMsgForField('templateName', touched, errors) ||
            // @ts-ignore
            validationErrors.template_name
          }
          required
        >
          <TemplateNameTextfield
            data-qaid="competition-template-create-form-template-name-field"
            id="templateName"
            name="templateName"
            value={values.templateName}
            onChange={(e: any) => {
              setFieldValue('templateName', e.target.value);
            }}
            maxLength={50}
          />
        </FormGroup>
      </FormGroupContainer>
    </GenericFormContainer>
  );
};

export default CompetitionTemplateCreateForm;
