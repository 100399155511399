import React from 'react';
import DayPicker from 'react-day-picker';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { useCurrentTheme } from 'app/shared/theme';

import DefaultHeader from './CustomHeader';

import 'react-day-picker/lib/style.css';

interface Props {
  numberOfMonthsToDisplay?: number;
  values?: any;
  onChange: (dates: any) => void;
  enablePastDays?: boolean;
  disableToday?: boolean;
  firstDayOfTheWeek?: number; // 0 (Sunday) || 1 (Monday) || 2 (Tuesday) || and so on
  customizedHeader?: any;
  hasBlackOutDatesStyling?: boolean;
  hasMultiSelect?: boolean;
  maxNumberOfSelections?: number;
}

const StyledDate = styled.div`
  ${({ theme }) => css`
    color: white;
    padding: 11px 12px;
    border-radius: 50%;

    ${theme.media.xs`
      padding: 7px 8px;
      font-size: 13px;
    `}

    ${theme.media.sm`
      padding: 9px 10px;
      font-size: 14px;
    `}

    ${theme.media.md`
      padding: 11px 12px;
      font-size: 16px;
    `}
  `}
`;

const convertToLocalEquivalentOfUTC = (date: Date | string | number) => {
  // Given: date object OR string OR number
  // Returns: date object which when displayed in client browser (using client
  //   browser's local time zone), displays with actual values in the UTC time zone
  //   (even though the time zone is displayed as the browser's local time zone)
  //
  // Used for e.g. tricking react-day-picker to display days using the UTC
  // date instead of the browser's local date e.g. if we pass 2023-05-13 to
  // react-day-picker as a selected day, we need it to highlight/select May 13 -
  // if we didn't do this conversion, then react-day-picker would highlight/select
  // May 12, since it converts whatever date you pass in into user browser's
  // local time zone, which for Sofar is usually EST, and:
  //   2023-05-13T00:00:00 in UTC = 2023-05-12T19:00:00 in EST
  //
  // react-day-picker not allowing you to specify the time zone of the date (vs. just
  // always using browser's time zone) is a known bug, discussed here:
  //   https://github.com/gpbl/react-day-picker/issues/130 (older issue, with v7)
  //   https://github.com/gpbl/react-day-picker/issues/1389 (same issue, with v8)
  //
  // As of May 2023, react-day-picker is otherwise well-maintained and widely-used,
  // so we'd like to keep using it instead of switching to a different day picker

  const d = new Date(date);
  const offset = d.getTimezoneOffset();
  d.setMinutes(d.getMinutes() + offset);
  return new Date(
    d.getFullYear(),
    d.getMonth(),
    d.getDate(),
    d.getHours(),
    d.getMinutes(),
    d.getSeconds()
  );
};

const DatePicker: React.FunctionComponent<Props> = ({
  numberOfMonthsToDisplay = 1,
  values = [],
  onChange = () => {},
  enablePastDays = false,
  disableToday = false,
  firstDayOfTheWeek = 0,
  customizedHeader,
  hasBlackOutDatesStyling = false,
  hasMultiSelect = true,
  maxNumberOfSelections,
}) => {
  const intl = useIntl();
  const theme = useCurrentTheme();

  const translate = (key: string) =>
    intl.formatMessage({
      id: key,
    });

  const styles = `.DayPicker-Day {
      color: white;
      padding: 5px;
    }

    .DayPicker-Caption {
      text-align: center;
    }

    .DayPicker:active,
    .DayPicker:focus,
    .DayPicker-wrapper:active,
    .DayPicker-wrapper:focus,
    .DayPicker-Day:active,
    .DayPicker-Day:focus,
    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover,
    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside),
    .DayPicker-Day--selected,
    .DayPicker-Day--highlighted {
      background-color: transparent;
      outline: none;
    }

    .DayPicker-Day > div {
      background-color: ${
        hasBlackOutDatesStyling
          ? theme.colors.orangeCrush
          : 'rgba(148, 148, 148, 0.5)'
      };
      color: white;
    }

    .DayPicker-Day--disabled {
      background-color: transparent !important;
    }

    .DayPicker-Day.DayPicker-Day--disabled > div {
      background-color: rgba(199, 199, 199, 50%);
    }

    .DayPicker-Day.DayPicker-Day--highlighted > div {
      background-color: ${
        hasBlackOutDatesStyling
          ? theme.colors.blueMagic
          : theme.colors.orangeCrush
      };
      color: white;
    }

    .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
      background-color: ${theme.colors.silverSprings};
    }

    @media (max-width: 767px) {
      .DayPicker-Day {
        padding: 2px;
      }
    }
  `;

  const WEEKDAYS_SHORT = [
    translate('weekdays.short.sun'),
    translate('weekdays.short.mon'),
    translate('weekdays.short.tue'),
    translate('weekdays.short.wed'),
    translate('weekdays.short.thu'),
    translate('weekdays.short.fri'),
    translate('weekdays.short.sat'),
  ];
  const MONTHS = [
    translate('months.short.jan').toUpperCase(),
    translate('months.short.feb').toUpperCase(),
    translate('months.short.mar').toUpperCase(),
    translate('months.short.apr').toUpperCase(),
    translate('months.short.may').toUpperCase(),
    translate('months.short.jun').toUpperCase(),
    translate('months.short.jul').toUpperCase(),
    translate('months.short.aug').toUpperCase(),
    translate('months.short.sep').toUpperCase(),
    translate('months.short.oct').toUpperCase(),
    translate('months.short.nov').toUpperCase(),
    translate('months.short.dec').toUpperCase(),
  ];

  const parseDate = (time: any) => convertToLocalEquivalentOfUTC(time);

  // Returns e.g. '2022-11-16'
  const getDateStrFromDate = (date: any) =>
    date.toISOString().split('T')[0] || '';

  const handleDayClick = (day: any, modifiers: any = {}) => {
    if (modifiers.disabled) {
      return;
    }

    const clickedDay = getDateStrFromDate(day);

    let updatedSelectedDays;

    if (hasMultiSelect) {
      if (modifiers.selected) {
        // User has UNSELECTED the day (yes, confusing - 'modifiers.selected' means they just unselected this day)
        updatedSelectedDays = values.filter(
          (selectedDay: string) => selectedDay !== clickedDay
        );
        onChange(updatedSelectedDays);
      } else {
        // User has SELECTED the day
        updatedSelectedDays = values.concat(clickedDay).sort();
      }
    } else {
      updatedSelectedDays = [clickedDay];
    }

    if (
      maxNumberOfSelections &&
      updatedSelectedDays.length > maxNumberOfSelections
    ) {
      return;
    }

    onChange(updatedSelectedDays);
  };

  const modifiers = {
    highlighted: values.map((date: string) => parseDate(date)),
  };

  const disabledDays = () => {
    const disabledDays: any = [];
    if (!enablePastDays) {
      disabledDays.push({ before: new Date() });
    }
    if (disableToday) {
      disabledDays.push(new Date());
    }
    return disabledDays;
  };

  const renderDay = (day: Date) => {
    return <StyledDate>{day.getDate()}</StyledDate>;
  };

  return (
    <div>
      <style>{styles}</style>
      <DayPicker
        modifiers={modifiers}
        weekdaysShort={WEEKDAYS_SHORT}
        months={MONTHS}
        onDayClick={handleDayClick}
        selectedDays={values.map((date: string) => parseDate(date))}
        renderDay={renderDay}
        navbarElement={customizedHeader || DefaultHeader}
        pagedNavigation={numberOfMonthsToDisplay !== 1}
        numberOfMonths={numberOfMonthsToDisplay}
        disabledDays={disabledDays()}
        firstDayOfWeek={firstDayOfTheWeek}
        initialMonth={hasMultiSelect ? new Date() : parseDate(values[0])}
      />
    </div>
  );
};

export default DatePicker;
