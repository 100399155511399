import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { ContactInfo, Event, Performance } from 'app/typings';
import usePermission from 'app/shared/utils/usePermission';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { getArtistPronouns } from 'app/admin/utils/artists';
import {
  CircledOverline,
  SectionText,
  SectionTitle,
  SectionWithIcon,
  Subheading,
} from 'app/admin/components/atoms/CrewEventContent';
import IconInCircle from 'app/admin/components/atoms/IconInCircle';
import SocialIconList from 'app/admin/components/molecules/SocialIconList';
import CrewEventContactInfo from 'app/admin/components/organisms/CrewEventContactInfo';
import { ReactComponent as MusicGenreTeam } from 'icons/streamline-regular/music-audio/music-genres/music-genre-team.svg';

interface EventInfoProps {
  event: Event;
  performance: Performance;
  position: number;
}

const numberKeys = {
  1: 'one',
  2: 'two',
  3: 'three',
  4: 'four',
  5: 'five',
};

const AudioSupportText = styled.span`
  text-transform: capitalize;
`;

const CrewEventArtistInfo: React.FC<EventInfoProps> = ({
  position,
  performance,
  event,
}) => {
  const intl = useIntl();
  const artist = performance?.artist;
  const hasArtistViewPermission = usePermission('artist.list.view');
  const dayOfShowOrPrimaryContactInfo =
    artist?.contactInfos?.find(
      (contactInfo: ContactInfo) => contactInfo.isDayOfShowContact
    ) ||
    artist?.contactInfos?.find(
      (contactInfo: ContactInfo) => contactInfo.position === 1
    );
  const artistPreferredPronouns =
    (artist?.preferredPronouns?.length &&
      getArtistPronouns(intl, artist?.preferredPronouns)) ||
    [];

  return (
    <SectionWithIcon>
      <SectionTitle>
        <IconInCircle
          borderCircle={true}
          circleBackgroundColor="whiteDenim"
          dataQaIdSuffix="music-genre-team"
          iconColor="orangeCrush"
          iconName={MusicGenreTeam}
        />
        {intl.formatMessage({ id: 'shared.artist' })}{' '}
        {intl.formatMessage({ id: numberKeys[position] })}
      </SectionTitle>
      <Spacer mt={4} />
      {performance ? (
        <>
          {performance.artist.pastEventsCount === 0 && (
            <>
              <SectionText>
                <CircledOverline inverted={true}>
                  {intl.formatMessage({
                    id:
                      'admin.crewEvent.section.artistInfo.artistsFirstConcert',
                  })}
                </CircledOverline>
              </SectionText>
            </>
          )}
          <Spacer mt={2} />
          {(event.city.title != artist?.closestSofarCityTitle ||
            !artist?.closestSofarCityTitle) && (
            <>
              <SectionText>
                <CircledOverline inverted={true}>
                  {intl.formatMessage({
                    id: 'admin.crewEvent.section.artistInfo.artistIsTraveling',
                  })}
                </CircledOverline>
              </SectionText>
            </>
          )}
          <SectionText>
            <Spacer mt={4} />
            <>
              <Subheading>
                {intl.formatMessage({
                  id: 'shared.artist',
                })}
                :
              </Subheading>
              &nbsp;
              {artist.title || intl.formatMessage({ id: 'none' })}
            </>
            <Spacer mt={4} />
            <>
              <Subheading>
                {intl.formatMessage({
                  id: 'admin.crewEvent.section.artistInfo.pronouns',
                })}
                :
              </Subheading>
              {artistPreferredPronouns?.length ? (
                artistPreferredPronouns?.map(
                  (pronoun: { title: string }, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        {index > 0 && ', '} {pronoun.title}
                      </React.Fragment>
                    );
                  }
                )
              ) : (
                <>
                  &nbsp;
                  {intl.formatMessage({
                    id: 'admin.crewEvent.section.artistInfo.noPronouns',
                  })}
                </>
              )}
            </>
            <Spacer mt={4} />
            <>
              <Subheading>
                {intl.formatMessage({
                  id: 'admin.crewEvent.section.artistInfo.homeCity',
                })}
                :
              </Subheading>
              &nbsp;
              {artist.homeCityDescription || intl.formatMessage({ id: 'none' })}
            </>
            <Spacer mt={4} />
            <>
              <Subheading>
                {intl.formatMessage({
                  id: 'admin.crewEvent.section.artistInfo.sofarConcerts',
                })}
                :
              </Subheading>
              &nbsp;
              {artist.pastEventsCount}
            </>
            {hasArtistViewPermission && (
              <>
                <Spacer mt={4} />
                <CrewEventContactInfo
                  label={intl.formatMessage({
                    id: 'admin.crewEvent.section.artistInfo.dayOfShowContact',
                  })}
                  contactInfo={dayOfShowOrPrimaryContactInfo}
                />
              </>
            )}
            <>
              <Subheading>
                {intl.formatMessage({
                  id: 'admin.crewEvent.section.artistInfo.setupName',
                })}
                :
              </Subheading>
              &nbsp;
              {performance.artistSetup?.setupName ||
                intl.formatMessage({ id: 'unknown' })}
            </>
            <Spacer mt={4} />
            <>
              <Subheading>
                {intl.formatMessage({
                  id: 'admin.crewEvent.section.artistInfo.performers',
                })}
                :
              </Subheading>
              &nbsp;
              {performance.artistSetup?.numPerformers ||
                intl.formatMessage({ id: 'unknown' })}
            </>
            <Spacer mt={4} />
            <>
              <Subheading>
                {intl.formatMessage({
                  id: 'admin.crewEvent.section.artistInfo.productionSupport',
                })}
                :
              </Subheading>
              &nbsp;
              <AudioSupportText>
                {performance.artistSetup?.audioSupport.replace('_', ' ') ||
                  intl.formatMessage({ id: 'unknown' })}
              </AudioSupportText>
            </>
            <Spacer mt={4} />
            <>
              <Subheading>
                {intl.formatMessage({
                  id: 'admin.crewEvent.section.artistInfo.additionalSupport',
                })}
                :
              </Subheading>
              &nbsp;
              {performance.artistSetup?.additionalSupportNotes ||
                intl.formatMessage({ id: 'unknown' })}
            </>
            <Spacer mt={4} />
            <>
              <Subheading>
                {intl.formatMessage({
                  id: 'admin.crewEvent.section.artistInfo.instruments',
                })}
                :
              </Subheading>
              &nbsp;
              {performance.artistSetup?.instrumentNotes ||
                intl.formatMessage({ id: 'unknown' })}
            </>
            <Spacer mt={4} />
            <>
              <Subheading>
                {intl.formatMessage({
                  id: 'admin.crewEvent.section.artistInfo.artistsBiography',
                })}
                :
              </Subheading>
              &nbsp;
              {artist.description || intl.formatMessage({ id: 'none' })}
            </>
            <Spacer mt={4} />
            {artist.socialUrls && (
              <SocialIconList
                socialUrls={artist.socialUrls}
                dataQaid="crew-event-artist"
              />
            )}
          </SectionText>
        </>
      ) : (
        <>
          <Subheading>
            {intl.formatMessage({
              id: 'shared.artist',
            })}
            :
          </Subheading>
          &nbsp;
          {intl.formatMessage({
            id: 'admin.crewEvent.section.artistInfo.notYetBooked',
          })}
        </>
      )}
    </SectionWithIcon>
  );
};

export default CrewEventArtistInfo;
