import React from 'react';
import { useIntl } from 'react-intl';

import { City, CityApiResponse, Neighborhood } from 'app/typings';
import { get } from 'app/shared/utils/get';
import { GetCity } from 'app/shared/graphql/cities/queryHooks';
import { Icon } from 'app/shared/components/atoms/IconManualCSS';
import { MenuItem } from 'app/shared/components/atoms/Menu';
import { Body2 } from 'app/shared/components/atoms/TypographyManualCSS';
import Select from 'app/shared/components/molecules/SelectManualCSS';
import { SelectState } from 'app/shared/components/molecules/SelectManualCSS';

interface Props {
  city?: City;
  onChange: (option: Neighborhood) => void;
  invertColor?: boolean;
  initialWidth?: string;
  name?: string;
  hasError?: boolean;
  disableTopCities?: boolean;
  'data-qaid'?: string;
  initialValue?: Neighborhood | null;
  value?: Neighborhood | null;
}

const getState = (city: CityApiResponse) => {
  if (city.loading) {
    return SelectState.loading;
  } else if (city.error) {
    return SelectState.error;
  }

  return SelectState.ready;
};

const getData = (cityInfo: CityApiResponse) => {
  return get(cityInfo, 'data.city.neighborhoods', []);
};

const NeighborhoodSelector: React.FC<Props> = ({
  city,
  onChange,
  initialValue,
  value,
  ...rest
}) => {
  const cityDetails = GetCity({ id: city && city.id });
  const intl = useIntl();

  return (
    <Select<Neighborhood>
      {...rest}
      state={getState(cityDetails)}
      name="neighborhood"
      searchable
      onChange={onChange}
      placeholder={intl.formatMessage({ id: 'shared.selectANeighborhood' })}
      options={city ? getData(cityDetails) : []}
      getOptionLabel={o => o.title}
      renderOption={(o, props) => (
        <MenuItem
          key={o.title}
          {...props}
          data-qaid={`${rest['data-qaid']}-option`}
        >
          <Body2 as="span">{o.title}</Body2>
        </MenuItem>
      )}
      renderLeftIcon={<Icon name="search" />}
      defaultValue={initialValue}
      value={value}
    />
  );
};

export default NeighborhoodSelector;
