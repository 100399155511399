import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Artist } from 'app/typings/artists';
import { dateFormatter } from 'app/shared/utils/datetime';
import { get } from 'app/shared/utils/get';
import { upperFirst } from 'app/shared/utils/string';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import { LinkButton } from 'app/shared/components/atoms/Button';
import { ContentWrapper } from 'app/shared/components/atoms/FlyoverContent';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import { AddIcon } from 'app/shared/components/atoms/IconLibrary';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Spinner } from 'app/shared/components/atoms/SpinnerManualCSS';
import {
  Body2,
  Overline,
} from 'app/shared/components/atoms/TypographyManualCSS';
import InfoBox from 'app/shared/components/molecules/Flyover';
import { validateOfferArtistLimit } from 'app/admin/utils/offers';
import { AddArtistToOffer } from 'app/admin/graphql/offers/mutationHooks';
import {
  DetailHeading as Heading,
  DetailValueContainer as ValueContainer,
} from 'app/admin/components/atoms/DetailContent';
import { None } from 'app/admin/components/atoms/None';
import ArtistCircleThumbnailAndText from 'app/admin/components/molecules/ArtistCircleThumbnailAndText';
import ArtistTypeahead from 'app/admin/components/molecules/ArtistTypeahead';
import { UserLink } from 'app/admin/components/molecules/EntityLink';
import FlyoverFooter from 'app/admin/components/molecules/FlyoverFooter';
import { maxNumArtistsByNumPositions } from 'app/admin/components/organisms/OfferCreate/maxOfferSizeValidation';

interface Props {
  sectionData: any;
}

const InputWrapper = styled.div`
  display: flex;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const ValueContainerRow = styled.div`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.4px;
  float: left;
  clear: both;
  display: block;
  width: 100%;
`;

const StyledLinkButton = styled(LinkButton)`
  display: flex;
  align-items: center;
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const SectionBookingInvitationDetails: React.FC<Props> = ({
  sectionData = {},
}) => {
  const intl = useIntl();
  const { offer, setOfferInfo, modalRef } = sectionData;
  const { artists, events } = offer || {};

  const getUniqueNeighborhoodTitles = () => {
    if (!events) {
      return [];
    }

    return [...new Set(events.map((event: any) => event.neighborhood?.title))];
  };

  const [selectedArtist, setSelectedArtist] = useState<Artist | undefined>(
    undefined
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [flyoverClosed, setFlyoverClosed] = useState(true);

  const addArtistToOfferAction = AddArtistToOffer();
  const handleAddArtistToOffer = useSubmitAction({
    submitAction: addArtistToOfferAction,
    submitVariables: () => ({
      offerId: offer.id,
      artistId: selectedArtist?.id,
    }),
    successMsg: intl.formatMessage(
      {
        id: 'admin.offerDetails.addArtistToOffer.successMessage',
      },
      {
        artistName: selectedArtist?.title,
      }
    ),
    failureMsg: intl.formatMessage(
      {
        id: 'admin.offerDetails.addArtistToOffer.failureMessage',
      },
      {
        artistName: selectedArtist?.title,
      }
    ),
    onSuccess: (response: any) => {
      const offerInfo = get(response, 'data.addArtistToOffer.offer', {});
      setSelectedArtist(undefined);
      setIsSubmitting(false);
      setFlyoverClosed(true);
      setOfferInfo({ offer: offerInfo });
    },
  });

  return (
    <MainContainer data-qaid="section-booking-invitation-details">
      <Grid cols={4}>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={1}>
          <Heading>
            {intl.formatMessage({
              id:
                'admin.offerDetails.bookingInvitationDetails.invitationStatus',
            })}
          </Heading>
          <ValueContainer data-qaid="offer-status">
            {offer.status ? upperFirst(offer.status) : <None />}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.shared.createdBy',
            })}
          </Heading>
          <ValueContainer data-qaid="offer-created-by">
            {offer.createdBy ? (
              <UserLink user={offer.createdBy} truncateLength={30} />
            ) : (
              <None />
            )}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.shared.dateCreated',
            })}
          </Heading>
          <ValueContainer data-qaid="offer-date-created">
            {offer.createdAt ? (
              dateFormatter(offer.createdAt, 'shortMonthDateAndYear')
            ) : (
              <None />
            )}
          </ValueContainer>
        </Col>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={2}>
          <Heading>
            {intl.formatMessage({
              id: 'shared.city',
            })}
          </Heading>
          <ValueContainer data-qaid="offer-city">
            {offer.city ? offer.city.title : <None />}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'shared.neighborhoods',
            })}
          </Heading>
          <ValueContainer data-qaid="offer-neighborhoods">
            {getUniqueNeighborhoodTitles().length ? (
              getUniqueNeighborhoodTitles().map(
                (neighborhoodTitle: any, index: number) => (
                  <ValueContainerRow key={`neighborhood-${index}`}>
                    {neighborhoodTitle}
                  </ValueContainerRow>
                )
              )
            ) : (
              <None />
            )}
          </ValueContainer>
        </Col>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={3}>
          <Heading>
            {intl.formatMessage({
              id: 'admin.shared.artistsInvited',
            })}
          </Heading>
          <ValueContainer data-qaid="offer-artists-invited">
            {artists && artists.length ? (
              artists.map((artist: Artist, i: number) => (
                <React.Fragment key={i}>
                  <ArtistCircleThumbnailAndText artist={artist} />
                  <Spacer mt={4} />
                </React.Fragment>
              ))
            ) : (
              <None />
            )}
            {validateOfferArtistLimit(offer, maxNumArtistsByNumPositions) ? (
              <InfoBox
                showCaret={false}
                width="300px"
                offset={{ horizontal: -80 }}
                triggerElement={
                  <StyledLinkButton
                    onClick={() => setFlyoverClosed(false)}
                    data-qaid="add-artist-to-offer-btn"
                  >
                    <AddIcon />
                    <Spacer mr={4} />
                    {intl.formatMessage({
                      id: 'admin.offerDetails.addArtistToOffer.addArtist',
                    })}
                  </StyledLinkButton>
                }
                containerRef={modalRef}
                shouldFlyoverClose={flyoverClosed}
                innerContent={
                  <div data-qaid="offer-add-artist-flyover">
                    {isSubmitting ? (
                      <ContentWrapper>
                        <SpinnerContainer>
                          <Spinner size="35px" />
                        </SpinnerContainer>
                      </ContentWrapper>
                    ) : (
                      <>
                        <ContentWrapper>
                          <Spacer mt={4} />
                          <Overline>Select Artist</Overline>
                          <InputWrapper>
                            <ArtistTypeahead
                              setSelectedArtist={setSelectedArtist}
                              clearFieldOnSelect={false}
                              showArtistEmails={false}
                              data-qaid="add-artist-typeahead"
                            />
                          </InputWrapper>
                          {selectedArtist &&
                          artists.some(
                            (artist: any) => artist.id === selectedArtist.id
                          ) ? (
                            <Spacer mt={8} mb={8}>
                              <Body2>
                                <strong>
                                  {intl.formatMessage(
                                    {
                                      id:
                                        'admin.offerDetails.addArtistToOffer.alreadyOnOffer',
                                    },
                                    {
                                      artistName: selectedArtist.title,
                                    }
                                  )}
                                </strong>
                              </Body2>
                            </Spacer>
                          ) : (
                            <Spacer mt={20} />
                          )}
                          <Body2>
                            <i>
                              {intl.formatMessage({
                                id:
                                  'admin.offerDetails.addArtistToOffer.disclaimer',
                              })}
                            </i>
                          </Body2>
                        </ContentWrapper>
                        <FlyoverFooter
                          buttonText={intl.formatMessage({
                            id: 'admin.offerDetails.addArtistToOffer.addArtist',
                          })}
                          buttonDisabled={
                            !selectedArtist ||
                            artists.some(
                              (artist: any) => artist.id === selectedArtist.id
                            )
                          }
                          onClickButton={() => {
                            setIsSubmitting(true);
                            handleAddArtistToOffer();
                          }}
                          dataQaid="add-artist-submit-button"
                        />
                      </>
                    )}
                  </div>
                }
              />
            ) : (
              <StyledLinkButton disabled>
                <AddIcon iconColor="grey" />
                <Spacer mr={4} />
                {intl.formatMessage({
                  id: 'admin.offerDetails.addArtistToOffer.addArtist',
                })}
              </StyledLinkButton>
            )}
          </ValueContainer>
        </Col>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={4}>
          <Heading>
            {intl.formatMessage({
              id: 'admin.offerDetails.bookingInvitationDetails.datesOfOffer',
            })}
          </Heading>
          <ValueContainer data-qaid="offer-dates-of-offer">
            {events ? (
              events.map((event: any, index: number) => (
                <ValueContainerRow key={`offer-date-${index}`}>
                  {dateFormatter(
                    event.localStartsAt,
                    'shortWeekdayShortMonthDateAndYear'
                  )}
                </ValueContainerRow>
              ))
            ) : (
              <None />
            )}
          </ValueContainer>
        </Col>
      </Grid>
    </MainContainer>
  );
};

export default SectionBookingInvitationDetails;
