import React, { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { getIdsSubmitVariable } from 'app/shared/utils/form';
import { get } from 'app/shared/utils/get';
import useModal from 'app/shared/utils/useModal';
import usePermission from 'app/shared/utils/usePermission';
import { GetCitiesLite } from 'app/shared/graphql/cities/queryHooks';
import { ListPage, ListPageContext } from 'app/shared/context/ListPage';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import { LoadingBlocks } from 'app/shared/components/atoms/LoadingBlocks';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import RoutableModal from 'app/shared/components/molecules/RoutableModal';
import { GlobalError } from 'app/shared/components/pages/Status';
import { CuratorGroupInfoForLimitingByCuratorGroup } from 'app/admin/utils/curatorGroupPermissions';
import {
  getCityOptions,
  getCuratorGroupOptions,
  getFilterNamesAndOptionsForTitleMapping,
  getPrimaryRoleOptions,
} from 'app/admin/utils/optionLists';
import {
  curatorPrimaryRoleKeys,
  roleResourceTypes,
} from 'app/admin/utils/users';
import { GetCuratorGroupsLite } from 'app/admin/graphql/curatorGroups/queryHooks';
import { GetPrimaryRoles } from 'app/admin/graphql/roles/queryHooks';
import { GetUsersForInternalUserDirectory } from 'app/admin/graphql/users/queryHooks';
import { CuratorGroupNames as CuratorGroupNamesLimitedBy } from 'app/admin/components/atoms/CuratorGroupNames';
import ListingFooter from 'app/admin/components/molecules/ListingFooter';
import ListingNoResults from 'app/admin/components/molecules/ListingNoResults';
import CardGrid, { CardType } from 'app/admin/components/organisms/CardGrid';
import InternalUserCard from 'app/admin/components/organisms/InternalUserCard';
import ListingControls from 'app/admin/components/organisms/ListingControls';
import ListingFilter from 'app/admin/components/organisms/ListingFilter';
import ListingHeader from 'app/admin/components/organisms/ListingHeader';
import ListPageTemplate from 'app/admin/components/templates/ListPage';
import Layout from 'app/admin/layouts/ListPage';

import {
  associatedWithArtistsOptions,
  eventsStaffedOptions,
  loginActivityOptions,
  sortOptions,
} from './options';
import Analytics from './tracking';

const pageStateConfig = {
  filterNames: [
    'role',
    'role_city',
    'role_curator_group',
    'events_staffed',
    'associated_with_artists',
    'login_activity',
  ],
  defaultSort: { by: 'user_name', direction: 'asc' },
  textSearchParamName: 'user_search',
  idParamName: 'user_id',
};

const AdminInternalUsers: React.FC = () => {
  const intl = useIntl();
  const pageState = useContext(ListPageContext);
  const PER_PAGE = 18;

  const hasAccessUsersPermission = usePermission('user.list.access');

  const {
    loading: loadingCities,
    error: errorCities,
    data: dataCities,
  } = GetCitiesLite({
    activeStatus: 'all',
  });

  const {
    loading: loadingPrimaryRoles,
    error: errorPrimaryRoles,
    data: dataPrimaryRoles,
  } = GetPrimaryRoles();

  const {
    loading: loadingCuratorGroups,
    error: errorCuratorGroups,
    data: dataCuratorGroups,
  } = GetCuratorGroupsLite({});

  const {
    curatorGroupNamesToLimitBy,
    curatorGroupIdsToLimitBy,
  } = CuratorGroupInfoForLimitingByCuratorGroup(
    'user.internalList.viewByCuratorGroup',
    dataCuratorGroups
  );

  const isLimitedByCuratorGroups = !!(
    curatorGroupIdsToLimitBy && curatorGroupIdsToLimitBy.length > 0
  );

  const cityOptions = getCityOptions(dataCities);
  const primaryRoleOptions = getPrimaryRoleOptions(
    dataPrimaryRoles,
    roleResourceTypes
  );
  const eventPrimaryRoleOptions = getPrimaryRoleOptions(dataPrimaryRoles, [
    'Event',
    'As',
  ]);
  const curatorGroupOptions = getCuratorGroupOptions(dataCuratorGroups);

  const fullEventsStaffedOptions = eventsStaffedOptions.eventsStaffed.concat(
    eventPrimaryRoleOptions
  );

  const filterDropdownOptionsInfoList = isLimitedByCuratorGroups
    ? []
    : [
        {
          filterName: 'role',
          dropdownParams: {
            title: intl.formatMessage({
              id: 'admin.shared.roles',
            }),
            options: primaryRoleOptions,
          },
        },
        {
          filterName: 'role_city',
          dropdownParams: {
            searchBar: true,
            title: intl.formatMessage({
              id: 'admin.users.filter.roleCity',
            }),
            groupBy: 'country',
            options: cityOptions,
          },
        },
        {
          filterName: 'role_curator_group',
          dropdownParams: {
            searchBar: true,
            title: intl.formatMessage({
              id: 'admin.users.filter.roleCuratorGroup',
            }),
            options: curatorGroupOptions,
          },
        },
        {
          filterName: 'events_staffed',
          dropdownParams: {
            title: intl.formatMessage({
              id: 'admin.users.filter.eventsStaffed',
            }),
            options: fullEventsStaffedOptions,
          },
        },
        {
          filterName: 'associated_with_artists',
          dropdownParams: {
            title: intl.formatMessage({
              id: 'admin.users.filter.associatedWithArtists',
            }),
            options: associatedWithArtistsOptions.associatedWithArtists,
          },
        },
        {
          filterName: 'login_activity',
          dropdownParams: {
            title: intl.formatMessage({
              id: 'admin.users.filter.loginActivity',
            }),
            options: loginActivityOptions.loginActivity,
          },
        },
      ];

  const [addRoleAnyUserModal, toggleAddRoleAnyUserModal] = useModal();

  const {
    loading,
    error,
    data,
    refetch: refetchInternalUsers,
  } = GetUsersForInternalUserDirectory({
    // If user doesn't have hasAccessUsersPermission AND we haven't loaded curatorGroupIdsToLimitBy yet,
    // default userSearch to arbitrarily random long string which guarantees no
    // user exists for that search, which ensures no users are returned from backend,
    // which ensures page displays no users
    //
    // TODO: Long-term, use useLazyQuery here to run the query only when we need to (after we know
    // which permissions the viewing user has)
    userSearch:
      !hasAccessUsersPermission && !isLimitedByCuratorGroups
        ? 'gjiewohbxkkwempnwegpksgwepczpqx'
        : pageState.textSearchState,
    role: isLimitedByCuratorGroups
      ? curatorPrimaryRoleKeys.join(',')
      : pageState.filterListVariable('role') || 'any_role',
    roleCity: pageState.filterListVariable('role_city'),
    roleCuratorGroup: isLimitedByCuratorGroups
      ? getIdsSubmitVariable(curatorGroupIdsToLimitBy)
      : pageState.filterListVariable('role_curator_group'),
    eventsStaffed: !hasAccessUsersPermission
      ? undefined
      : pageState.filterListVariable('events_staffed'),
    associatedWithArtists: !hasAccessUsersPermission
      ? undefined
      : pageState.filterState.associated_with_artists[0] ===
        'associated_with_artists'
      ? true
      : undefined,
    loginActivity: !hasAccessUsersPermission
      ? undefined
      : pageState.filterListVariable('login_activity'),
    orderBy: pageState.sortState.by,
    orderDirection: pageState.sortState.direction,
    page: pageState.page,
    perPage: PER_PAGE,
  });

  useEffect(() => {
    pageState.updateScrollPositionOnPage();
  }, [pageState.detailsModal.isShowing]);

  useEffect(() => {
    pageState.updateDetailsModal();
  }, []);

  useEffect(() => {
    pageState.setupFilterLabelTitleMapping(
      getFilterNamesAndOptionsForTitleMapping(filterDropdownOptionsInfoList)
    );
  }, [dataCities, dataPrimaryRoles, dataCuratorGroups]);

  useEffect(() => {
    pageState.updatePageUrl();
  }, [
    pageState.page,
    pageState.sortState,
    pageState.filterState,
    pageState.textSearchState,
    pageState.detailData,
    pageState.detailsModal.isShowing,
    pageState.viewModeState,
  ]);

  useEffect(() => {
    Analytics.pushDataLayer();
  }, [pageState.filterState, pageState.textSearchState]);

  if (
    (!loading && !data) ||
    error ||
    (!loadingCities && !dataCities) ||
    errorCities ||
    (!loadingPrimaryRoles && !dataPrimaryRoles) ||
    errorPrimaryRoles ||
    (!loadingCuratorGroups && !dataCuratorGroups) ||
    errorCuratorGroups
  ) {
    return <GlobalError />;
  }

  const modalsContent = () => (
    <>
      {pageState.detailsModal.isShowing && (
        <RoutableModal
          hide={pageState.detailsModal.hide}
          initialRouteProps={{
            ...pageState.detailData,
            curatorGroupIdsToLimitBy,
            refetchInternalUsers,
          }}
          initialRouteName="internal-user-details"
          dataQaidSuffix="admin-edit-internal-user-details"
        />
      )}
      {addRoleAnyUserModal.isShowing && (
        <RoutableModal
          hide={addRoleAnyUserModal.hide}
          initialRouteProps={{
            action: 'addRoleToAnyUser',
            curatorGroupIdsToLimitBy,
            refetchInternalUsers,
          }}
          initialRouteName="user-role-add"
          dataQaidSuffix="admin-add-user-role"
        />
      )}
    </>
  );

  return (
    <Layout scrollDisabled={pageState.detailsModal.isShowing}>
      <ListPageTemplate
        pageTitle={intl.formatMessage({
          id: 'admin.internalUsers.pageTitle',
        })}
        modalsContent={modalsContent}
      >
        <ListingHeader
          pageTitle={intl.formatMessage({
            id: 'admin.internalUsers.pageTitle',
          })}
          addEntityText={intl.formatMessage({
            id: 'admin.internalUsers.addInternalUser',
          })}
          onClickAddEntity={toggleAddRoleAnyUserModal}
          searchPlaceholder={intl.formatMessage({
            id: 'admin.users.textSearch.placeholder',
          })}
          searchValue={pageState.textSearchState}
          onSearch={pageState.handleTextSearch}
          dataQaidPrefix="internal-user"
        />
        <CuratorGroupNamesLimitedBy
          curatorGroupNames={curatorGroupNamesToLimitBy}
        />
        <div>
          <ListingControls
            sortOptions={sortOptions}
            orderBy={pageState.sortState.by}
            orderDirection={pageState.sortState.direction}
            totalRecords={data && data.users.metadata.totalRecords}
            loading={loading}
            onSort={pageState.handleSortChange}
            onReset={pageState.handleResetFilters}
            dataQaidPrefix="internal-user"
          />
          {!isLimitedByCuratorGroups && (
            <ListingFilter
              filterTitle={intl.formatMessage({
                id: 'admin.users.filterTitle',
              })}
              textSearchString={pageState.textSearchState}
              handleTextSearchLabelClose={pageState.handleTextSearchLabelClose}
              labelTitleMapping={pageState.filterLabelTitleMapping}
              dropdownOptionsInfoList={filterDropdownOptionsInfoList}
              filterState={pageState.filterState}
              handleRemoveFilter={pageState.handleRemoveFilter}
              handleFilterChange={pageState.handleFilterChange}
            />
          )}
        </div>

        <div>
          <CardGrid
            objectData={get(data, 'users.users', [])}
            renderCardComponent={(user: CardType, i: number) => (
              // @ts-ignore
              <InternalUserCard
                index={i}
                onShowDetails={pageState.toggleDetailsModalAndSetDetailData}
                refetchInternalUsers={refetchInternalUsers}
                {...user}
              />
            )}
            dataQaid="admin-internal-users-list"
            loading={loading}
            loadingComponent={
              <LoadingBlocks.Rectangle width="100%" height="420px" />
            }
            hideDividerOnSize="xs"
          />
          <DottedLine />
          <Spacer mb={2} />
        </div>

        <ListingNoResults
          entityName={intl.formatMessage({
            id: 'admin.users.noResultsEntityName',
          })}
          numResults={get(data, 'users.users.length', undefined)}
          loading={loading}
        />

        <ListingFooter
          numTotalRecords={get(data, 'users.metadata.totalRecords', undefined)}
          perPage={PER_PAGE}
          currentPage={pageState.page}
          onPageChange={pageState.handlePageChange}
          loading={loading}
          dataQaidPrefix="internal-users"
        />
      </ListPageTemplate>
    </Layout>
  );
};

const AdminInternalUsersWrapper: React.FC = () => (
  <ListPage config={pageStateConfig} analytics={Analytics}>
    <AdminInternalUsers />
  </ListPage>
);

export default AdminInternalUsersWrapper;
