import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { get } from 'app/shared/utils/get';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import {
  getTitleFormInitialValue,
  getTitlesSubmitVariable,
} from 'app/admin/utils/titleByLocale';
import { UpdateCountry } from 'app/admin/graphql/countries/mutationHooks';
import { DetailsFormMainContainer } from 'app/admin/components/atoms/DetailContent';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

import CountryBasicInfoForm from './CountryBasicInfoForm';
import CountryBasicInfoFormSchema from './CountryBasicInfoFormSchema';

interface Props {
  contentProps?: any;
  setFormSubmitAction: (func: Function) => void;
  setDisplayConfirmation: (set: boolean) => void;
  setIsSubmitting: (set: boolean) => void;
  navigateTo: (routeData: object) => void;
}

const CountryBasicInfoEdit: React.FC<Props> = ({
  contentProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  navigateTo,
}) => {
  const intl = useIntl();

  const [validationErrors, setValidationErrors] = useState<object>({});

  const formInitialValues = {
    isNewCountry: false,
    titleEn: getTitleFormInitialValue(contentProps, 'en'),
    titleEs: getTitleFormInitialValue(contentProps, 'es'),
    titleIt: getTitleFormInitialValue(contentProps, 'it'),
    continentId: get(contentProps, 'continent.id', null),
    aliases: get(contentProps, 'aliases', ''),
    currency: get(contentProps, 'currency', ''),
    currencySymbol: get(contentProps, 'currencySymbol', ''),
    exchangeRateWithUsd: (
      get(contentProps, 'exchangeRateWithUsd', '') || ''
    ).toString(),
  };

  const updateCountryAction = UpdateCountry();

  const handleSubmit = useSubmitAction({
    submitAction: updateCountryAction,
    submitVariables: (values: any) => ({
      countryId: get(contentProps, 'id', undefined),
      titles: getTitlesSubmitVariable(values),
      continentId: values.continentId,
      aliases: values.aliases,
      currency: values.currency,
      currencySymbol: values.currencySymbol,
      exchangeRateWithUsd: values.exchangeRateWithUsd
        ? parseFloat(values.exchangeRateWithUsd)
        : null,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.countryBasicInfo.form.successMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.countryBasicInfo.form.failureMessage',
    }),
    onSuccess: response => {
      navigateTo({
        routeName: 'country-details',
        routeProps: {
          defaultOpenSection: 'basicInfo',
          ...response.data.updateCountry.country,
        },
      });
    },
    onValidationError: validationErrors => {
      setValidationErrors(validationErrors);
    },
  });

  return (
    <ModalContentContainer>
      <DetailsFormMainContainer>
        <DetailsHeader title={get(contentProps, 'title', '')} />
        <GenericForm
          formInitialValues={formInitialValues}
          renderFormComponent={(renderProps: any) => (
            <CountryBasicInfoForm
              formikProps={renderProps.formikProps}
              validationErrors={validationErrors}
            />
          )}
          onSubmit={handleSubmit}
          formSchema={CountryBasicInfoFormSchema(intl)}
          setFormSubmitAction={setFormSubmitAction}
          setDisplayConfirmation={setDisplayConfirmation}
          setIsSubmitting={setIsSubmitting}
          dataQaId="country-about-section-edit-form"
        />
      </DetailsFormMainContainer>
    </ModalContentContainer>
  );
};

export default CountryBasicInfoEdit;
