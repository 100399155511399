import React, { useContext, useEffect } from 'react';
import loadable from '@loadable/component';
import { useIntl } from 'react-intl';

import { Venue } from 'app/typings';
import { getIdsSubmitVariable } from 'app/shared/utils/form';
import { get } from 'app/shared/utils/get';
import useModal from 'app/shared/utils/useModal';
import usePermission from 'app/shared/utils/usePermission';
import { GetCitiesForGroupByBusinessOwner } from 'app/shared/graphql/cities/queryHooks';
import { GetProperties } from 'app/shared/graphql/properties/queryHooks';
import { ListPage, ListPageContext } from 'app/shared/context/ListPage';
import { LoadingBlocks } from 'app/shared/components/atoms/LoadingBlocks';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Spinner } from 'app/shared/components/atoms/SpinnerManualCSS';
import { CityInfoForLimitingByCity } from 'app/admin/utils/cityPermissions';
import {
  filterInfoListFilteredByCheckOnFilterName,
  getBusinessOwnerOptions,
  getCityWithBusinessOwnerOptions,
  getFilterNamesAndOptionsForTitleMapping,
  getMaxPaInputsOptions,
  getNeighborhoodOptions,
  getPropertyOptions,
  getTagOptions,
  titleValueOptionsWithNoneOption,
} from 'app/admin/utils/optionLists';
import { availableDayOptions } from 'app/admin/utils/venues';
import { GetBusinessOwners } from 'app/admin/graphql/businessOwners/queryHooks';
import { GetNeighborhoods } from 'app/admin/graphql/neighborhoods/queryHooks';
import { GetTags } from 'app/admin/graphql/tags/queryHooks';
import {
  GetVenueBasicInfo,
  GetVenues,
  GetVenuesForMap,
} from 'app/admin/graphql/venues/queryHooks';
import { CityTitles as CityTitlesLimitedBy } from 'app/admin/components/atoms/CityTitles';
import { CardType } from 'app/admin/components/organisms/CardGrid';
import ListingHeader from 'app/admin/components/organisms/ListingHeader';
import VenueClusterInfoWindow from 'app/admin/components/organisms/VenueClusterInfoWindow';
import Layout from 'app/admin/layouts/ListPage';

import {
  capacityOptions,
  eventScopeOptions,
  hostingStatusOptions,
  sortOptions,
  ticketOptions,
} from './options';
import Analytics from './tracking';

interface HideProps {
  shouldRefetchVenues?: boolean;
}

const GlobalError = loadable(
  () => import('app/shared/components/pages/Status'),
  {
    resolveComponent: components => components.GlobalError,
  }
);
const ListPageTemplate = loadable(() =>
  import('app/admin/components/templates/ListPage')
);
const ListingControls = loadable(() =>
  import('app/admin/components/organisms/ListingControls')
);
const ListingFilter = loadable(() =>
  import('app/admin/components/organisms/ListingFilter')
);
const ListingFooter = loadable(() =>
  import('app/admin/components/molecules/ListingFooter')
);
const RoutableModal = loadable(() =>
  import('app/shared/components/molecules/RoutableModal')
);
const ListingNoResults = loadable(() =>
  import('app/admin/components/molecules/ListingNoResults')
);
const CardGrid = loadable(() =>
  import('app/admin/components/organisms/CardGrid')
);
const VenueCard = loadable(() =>
  import('app/admin/components/organisms/VenueCard')
);
const DottedLine = loadable(() =>
  import('app/shared/components/atoms/DottedLine')
);
const MapWithMarkers = loadable(() =>
  import('app/admin/components/organisms/MapWithMarkers')
);

const pageStateConfig = {
  filterNames: [
    'available_days',
    'business_owner',
    'capacity',
    'characteristic',
    'city',
    'event_scope',
    'hosting_status',
    'neighborhood',
    'num_tickets_available_for_sale',
    'tag',
    'venue_category',
    'max_pa_inputs',
  ],
  defaultSort: { by: 'past_events_count', direction: 'desc' },
  defaultViewMode: 'list',
  viewModes: ['list', 'map'],
  textSearchParamName: 'venue_search',
  idParamName: 'venue_id',
};

// To force neighborhoods filter to still appear even when no options, so that 'you must select a city' message appears
const dummyNeighborhoodOptions = [
  {
    title: 'Dummy neighborhood',
    value: 'dummy_neighborhood',
    fields: {
      cityGroupName: 'Dummy city',
      cityCachedSlug: 'dummy_city',
    },
  },
];

const AdminVenues: React.FC = () => {
  const intl = useIntl();
  const pageState = useContext(ListPageContext);
  const PER_PAGE = 18;

  const hasViewVenuesByCityPermission = usePermission('venue.list.viewByCity');

  const {
    data: dataCities,
    loading: loadingCities,
    error: errorCities,
  } = GetCitiesForGroupByBusinessOwner({
    businessOwner: hasViewVenuesByCityPermission
      ? undefined
      : pageState.filterListVariable('business_owner'),
  });

  const {
    loading: loadingNeighborhoods,
    error: errorNeighborhoods,
    data: dataNeighborhoods,
  } = GetNeighborhoods({
    city: pageState.filterListVariable('city'),
    skip: !pageState.filterListVariable('city'),
  });

  const {
    data: dataBusinessOwners,
    loading: loadingBusinessOwners,
    error: errorBusinessOwners,
  } = GetBusinessOwners({
    archivedStatus: 'not_archived',
    skipPagination: true,
  });

  const { cityIdsToLimitBy, cityTitlesToLimitBy } = CityInfoForLimitingByCity(
    'venue.list.viewByCity',
    dataCities && dataCities.cities
  );

  const { loading: loadingTags, error: errorTags, data: dataTags } = GetTags({
    tagContext: 'Venue',
  });

  const {
    loading: loadingProperties,
    error: errorProperties,
    data: dataProperties,
  } = GetProperties({
    propertyContext: 'Venue',
  });

  const {
    loading: loadingVenueCategories,
    error: errorVenueCategories,
    data: dataVenueCategories,
  } = GetTags({
    tagContext: 'VenueCategory',
  });

  const cityOptions = getCityWithBusinessOwnerOptions(dataCities);
  const businessOwnerOptions = getBusinessOwnerOptions(dataBusinessOwners);
  const neighborhoodOptions = pageState.filterListVariable('city')
    ? getNeighborhoodOptions(dataNeighborhoods, cityIdsToLimitBy)
    : dummyNeighborhoodOptions;
  const tagOptions = getTagOptions(dataTags);
  const venueTypeOptions = getTagOptions(dataVenueCategories);
  const characteristicsOptions = getPropertyOptions(dataProperties, true);

  const businessOwnerFilterIsApplied =
    pageState.filterListVariable('business_owner') &&
    // @ts-ignore
    pageState.filterListVariable('business_owner').length > 1;

  const renderInfoWindowContent = (markerDataProps: {
    data: any;
    loading: boolean;
    error: string;
  }) => {
    const {
      data: dataBasicInfo,
      loading: loadingBasicInfo,
      error: errorBasicInfo,
    } = markerDataProps;

    return (
      <VenueCard
        {...(dataBasicInfo ? dataBasicInfo.venue : {})}
        index={get(dataBasicInfo, 'venue.id', undefined)}
        onShowDetails={pageState.toggleDetailsModalAndSetDetailData}
        loading={loadingBasicInfo}
        error={errorBasicInfo}
      />
    );
  };

  const renderClusterInfoWindowContent = (clusterDataProps: {
    markersInCluster: any;
    setSelectedMarkerId: (id: number) => void;
    onMarkerClick: (marker: any) => void;
  }) => <VenueClusterInfoWindow {...clusterDataProps} />;

  const canDisplayMap = () =>
    cityIdsToLimitBy ||
    (pageState.filterState.city && pageState.filterState.city.length > 0) ||
    (pageState.filterState.business_owner &&
      pageState.filterState.business_owner.length > 0);

  const isValidVenueMarker = (venue: Venue) =>
    !!venue.latitude && !!venue.longitude;

  const maxPaInputsOptions = titleValueOptionsWithNoneOption(
    getMaxPaInputsOptions(intl)
  );

  const filterDropdownOptionsInfoList = filterInfoListFilteredByCheckOnFilterName(
    [
      {
        filterName: 'available_days',
        dropdownParams: {
          title: intl.formatMessage({
            id: 'admin.venueDirectory.filter.title.availableDays',
          }),
          options: availableDayOptions,
        },
      },
      {
        filterName: 'business_owner',
        // @ts-ignore
        dropdownParams: {
          searchBar: false,
          title: intl.formatMessage({
            id: 'admin.venueDirectory.filter.title.businessOwners',
          }),
          options: businessOwnerOptions,
        },
      },
      {
        filterName: 'city',
        filterDependentOn: businessOwnerFilterIsApplied
          ? 'business_owner'
          : undefined,
        // @ts-ignore
        dropdownParams: {
          searchBar: true,
          title: intl.formatMessage({
            id: 'admin.venueDirectory.filter.title.sofarCities',
          }),
          groupBy: businessOwnerFilterIsApplied
            ? 'businessOwnerGroupName'
            : 'country',
          // @ts-ignore
          groupById: businessOwnerFilterIsApplied
            ? 'businessOwnerCachedSlug'
            : 'country',
          options: cityOptions,
        },
      },
      {
        filterName: 'neighborhood',
        filterDependentOn: 'city',
        dropdownParams: {
          searchBar: true,
          title: intl.formatMessage({
            id: 'shared.neighborhoods',
          }),
          groupBy: 'cityGroupName',
          // @ts-ignore
          groupById: 'cityCachedSlug',
          options: neighborhoodOptions,
        },
      },

      {
        filterName: 'characteristic',
        dropdownParams: {
          title: intl.formatMessage({
            id: 'admin.shared.characteristics',
          }),
          options: characteristicsOptions,
          groupBy: 'propertyGroupName',
          groupNames: get(characteristicsOptions, 'propertyGroupNames', []),
        },
      },
      {
        filterName: 'capacity',
        dropdownParams: {
          title: intl.formatMessage({
            id: 'shared.capacity',
          }),
          options: capacityOptions.capacityRanges,
        },
      },
      {
        filterName: 'num_tickets_available_for_sale',
        dropdownParams: {
          title: intl.formatMessage({
            id: 'admin.venueDirectory.filter.title.ticketsAvailable',
          }),
          options: ticketOptions.ticketRanges,
        },
      },
      {
        filterName: 'event_scope',
        dropdownParams: {
          title: intl.formatMessage({
            id: 'shared.date',
          }),
          options: eventScopeOptions.eventScope,
        },
      },
      {
        filterName: 'tag',
        dropdownParams: {
          searchBar: true,
          title: intl.formatMessage({
            id: 'shared.tags',
          }),
          groupBy: 'tagGroupName',
          groupNames: get(dataTags, 'tags.tagGroupNames', []),
          options: tagOptions,
        },
      },
      {
        filterName: 'venue_category',
        dropdownParams: {
          searchBar: true,
          title: intl.formatMessage({
            id: 'admin.shared.type',
          }),
          groupNames: get(dataVenueCategories, 'tags.tagGroupNames', []),
          options: venueTypeOptions,
        },
      },
      {
        filterName: 'hosting_status',
        dropdownParams: {
          title: intl.formatMessage({
            id: 'shared.status',
          }),
          options: hostingStatusOptions.hostingStatuses,
        },
      },
      {
        filterName: 'max_pa_inputs',
        dropdownParams: {
          title: intl.formatMessage({
            id: 'admin.venueDirectory.filter.title.production',
          }),
          options: maxPaInputsOptions,
        },
      },
    ],
    {
      business_owner: !cityIdsToLimitBy,
      city: !cityIdsToLimitBy,
      neighborhood: !cityIdsToLimitBy,
    }
  );

  const [addVenueModal, toggleAddVenueModal] = useModal();

  const getVenuesHook =
    pageState.viewModeState === 'map' ? GetVenuesForMap : GetVenues;

  const { loading, error, data, refetch: refetchVenues } = getVenuesHook({
    availableDays: pageState.filterListVariable('available_days'),
    businessOwner: !cityIdsToLimitBy
      ? pageState.filterListVariable('business_owner')
      : undefined,
    capacity: pageState.filterListVariable('capacity'),
    characteristic: pageState.filterListVariable('characteristic'),
    city: !cityIdsToLimitBy ? pageState.filterListVariable('city') : undefined,
    cityIds: getIdsSubmitVariable(cityIdsToLimitBy),
    eventScope: pageState.filterListVariable('event_scope'),
    hostingStatus: pageState.filterListVariable('hosting_status'),
    maxPaInputs: pageState.filterListVariable('max_pa_inputs'),
    neighborhood: !cityIdsToLimitBy
      ? pageState.filterListVariable('neighborhood')
      : undefined,
    numTicketsAvailableForSale: pageState.filterListVariable(
      'num_tickets_available_for_sale'
    ),
    orderBy: pageState.sortState.by,
    orderDirection: pageState.sortState.direction,
    page: pageState.viewModeState === 'list' ? pageState.page : undefined,
    perPage: pageState.viewModeState === 'list' ? PER_PAGE : undefined,
    skipPagination: pageState.viewModeState !== 'list',
    tag: pageState.filterListVariable('tag'),
    venueCategory: pageState.filterListVariable('venue_category'),
    venueSearch: pageState.textSearchState,
  });

  useEffect(() => {
    const preloadedComponents = [
      VenueCard,
      DottedLine,
      CardGrid,
      ListPageTemplate,
      ListingControls,
      ListingFilter,
    ];
    preloadedComponents.map(c => c.preload());

    pageState.updateDetailsModal();
  }, []);

  useEffect(() => {
    pageState.updateScrollPositionOnPage();
  }, [pageState.detailsModal.isShowing]);

  useEffect(() => {
    pageState.setupFilterLabelTitleMapping(
      getFilterNamesAndOptionsForTitleMapping(filterDropdownOptionsInfoList)
    );
  }, [dataCities, dataTags, dataProperties, dataVenueCategories]);

  useEffect(() => {
    pageState.handleRemoveOrphanDependentFilters({
      parentFilter: 'business_owner',
      dependentFilter: 'city',
      dependentFilterOptions: cityOptions,
    });
  }, [cityOptions, pageState]);

  useEffect(() => {
    pageState.updatePageUrl();
  }, [
    pageState.page,
    pageState.sortState,
    pageState.filterState,
    pageState.textSearchState,
    pageState.detailData,
    pageState.detailsModal.isShowing,
    pageState.viewModeState,
  ]);

  useEffect(() => {
    Analytics.pushDataLayer();
  }, [pageState.filterState, pageState.textSearchState]);

  if (
    (!loading && !data) ||
    error ||
    (!loadingCities && !dataCities) ||
    errorCities ||
    (!loadingNeighborhoods &&
      !dataNeighborhoods &&
      pageState.filterListVariable('city')) ||
    errorNeighborhoods ||
    (!loadingBusinessOwners && !dataBusinessOwners) ||
    errorBusinessOwners ||
    (!loadingTags && !dataTags) ||
    errorTags ||
    (!loadingProperties && !dataProperties) ||
    errorProperties ||
    (!loadingVenueCategories && !dataVenueCategories) ||
    errorVenueCategories
  ) {
    return <GlobalError />;
  }

  const modalsContent = () => (
    <>
      {pageState.detailsModal.isShowing && (
        <RoutableModal
          hide={({ shouldRefetchVenues = false }: HideProps = {}) => {
            pageState.detailsModal.hide();
            if (shouldRefetchVenues) {
              refetchVenues();
            }
          }}
          initialRouteProps={{ ...pageState.detailData, refetchVenues }}
          initialRouteName="venue-details"
          dataQaidSuffix="admin-edit-venue-details"
        />
      )}
      {addVenueModal.isShowing && (
        <RoutableModal
          hide={addVenueModal.hide}
          initialRouteProps={{}}
          initialRouteName="venue-create"
          dataQaidSuffix="admin-create-venue"
        />
      )}
    </>
  );

  return (
    <Layout scrollDisabled={pageState.detailsModal.isShowing}>
      <ListPageTemplate
        pageTitle={intl.formatMessage({
          id: 'admin.venueDirectory.pageTitle',
        })}
        modalsContent={modalsContent}
      >
        <ListingHeader
          pageTitle={intl.formatMessage({
            id: 'admin.venueDirectory.pageTitle',
          })}
          addEntityText={intl.formatMessage({
            id: 'admin.venueDirectory.addVenue',
          })}
          onClickAddEntity={toggleAddVenueModal}
          searchPlaceholder={intl.formatMessage({
            id: 'admin.venueDirectory.textSearch.placeholder',
          })}
          searchValue={pageState.textSearchState}
          onSearch={pageState.handleTextSearch}
          dataQaidPrefix="venue"
        />
        <CityTitlesLimitedBy cityTitles={cityTitlesToLimitBy} />
        <div>
          <ListingControls
            sortOptions={sortOptions}
            orderBy={pageState.sortState.by}
            orderDirection={pageState.sortState.direction}
            viewMode={pageState.viewModeState}
            hideResultsText={
              pageState.viewModeState === 'map' && !canDisplayMap()
            }
            totalRecords={data && data.venues.metadata.totalRecords}
            loading={loading}
            onViewModeChange={() => {
              if (pageState.viewModeState === 'map') {
                pageState.handleViewModeChange('list');
              } else {
                pageState.handleViewModeChange('map');
              }
            }}
            onSort={pageState.handleSortChange}
            onReset={pageState.handleResetFilters}
            dataQaidPrefix="venue"
          />
          <ListingFilter
            filterTitle={intl.formatMessage({
              id: 'admin.venueDirectory.filterTitle',
            })}
            textSearchString={pageState.textSearchState}
            handleTextSearchLabelClose={pageState.handleTextSearchLabelClose}
            labelTitleMapping={pageState.filterLabelTitleMapping}
            dropdownOptionsInfoList={filterDropdownOptionsInfoList}
            filterState={pageState.filterState}
            handleRemoveFilter={pageState.handleRemoveFilter}
            handleFilterChange={pageState.handleFilterChange}
          />
        </div>

        <div>
          {pageState.viewModeState === 'list' ? (
            <CardGrid
              objectData={get(data, 'venues.venues', [])}
              renderCardComponent={(venue: CardType, i: number) => (
                // @ts-ignore
                <VenueCard
                  index={i}
                  onShowDetails={pageState.toggleDetailsModalAndSetDetailData}
                  {...venue}
                />
              )}
              dataQaid="admin-venues-list"
              loading={loading}
              loadingComponent={
                <LoadingBlocks.Rectangle width="100%" height="420px" />
              }
              hideDividerOnSize="xs"
            />
          ) : (
            canDisplayMap() &&
            (loading && pageState.viewModeState !== 'map' ? (
              <div style={{ textAlign: 'center' }}>
                <div style={{ display: 'inline-block' }}>
                  <Spinner />
                </div>
              </div>
            ) : (
              <MapWithMarkers
                markers={get(data, 'venues.venues', []).filter(
                  isValidVenueMarker
                )}
                renderInfoWindowContent={renderInfoWindowContent}
                renderClusterInfoWindowContent={renderClusterInfoWindowContent}
                mapContainerStyle={{
                  overflowAnchor: 'none',
                  maxHeight: '650px',
                }}
                fetchMarkerData={GetVenueBasicInfo}
                data-qaid="admin-venues-map"
              />
            ))
          )}
          <DottedLine />
          <Spacer mb={2} />
        </div>

        {pageState.viewModeState === 'map' && !canDisplayMap() ? (
          <ListingNoResults
            entityName={intl.formatMessage({
              id: 'shared.venue',
            })}
            numResults={0}
            loading={false}
            subtitle={intl.formatMessage({
              id: 'admin.venueDirectory.needsCityOrBusinessOwnerFilter',
            })}
            data-qaid="admin-venues-map-needs-city-or-business-owner-filter"
          />
        ) : (
          <ListingNoResults
            entityName={intl.formatMessage({
              id: 'shared.venue',
            })}
            numResults={get(data, 'venues.venues.length', undefined)}
            loading={loading}
          />
        )}

        {pageState.viewModeState === 'list' && (
          <ListingFooter
            numTotalRecords={get(
              data,
              'venues.metadata.totalRecords',
              undefined
            )}
            perPage={PER_PAGE}
            currentPage={pageState.page}
            onPageChange={pageState.handlePageChange}
            loading={loading}
            dataQaidPrefix="venues"
          />
        )}
      </ListPageTemplate>
    </Layout>
  );
};

const AdminVenuesWrapper: React.FC = () => (
  <ListPage config={pageStateConfig} analytics={Analytics}>
    <AdminVenues />
  </ListPage>
);

export default AdminVenuesWrapper;
