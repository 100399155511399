import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { City } from 'app/typings/cities';
import { Country } from 'app/typings/countries';
import { Event } from 'app/typings/events';
import { PurchaserType } from 'app/typings/promoCodes';
import { get } from 'app/shared/utils/get';
import { removeEntriesByKey } from 'app/shared/utils/object';
import { GetCountries } from 'app/shared/graphql/countries/queryHooks';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import {
  FormGroupContainer,
  FormSectionTitle,
} from 'app/shared/components/atoms/FormContent';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import { DeleteIcon } from 'app/shared/components/atoms/IconLibrary';
import { LoadingError } from 'app/shared/components/atoms/LoadingError';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import Select from 'app/shared/components/molecules/SelectManualCSS';
import CitySelectorManualCSS from 'app/shared/components/organisms/CitySelectorManualCSS';
import { eventOwnerTypeLabels, eventTypeLabels } from 'app/admin/utils/events';
import {
  getObjectOptions,
  getObjectOptionsPreservingOrder,
} from 'app/admin/utils/optionLists';
import AddIconAndText from 'app/admin/components/atoms/AddIconAndText';
import EditFormLoadingBlocks from 'app/admin/components/atoms/EditFormLoadingBlocks';
import { EventSelector } from 'app/admin/components/organisms/EventSelector';

interface Props {
  ruleTypes: string[];
  ruleDayOfWeek: string;
  ruleDaysOfWeek: string[];
  ruleCity: City;
  ruleCities: City[];
  ruleCountry: Country;
  ruleEvent: Event;
  ruleEventType: string;
  ruleEventOwnerTypes: string[];
  rulePurchaserType: PurchaserType;
  handleAddRuleType: (ruleType: string) => void;
  handleDeleteRuleType: (ruleType: string) => void;
  handleChangeRuleDayOfWeek: (dayOfWeek: string) => void;
  handleChangeRuleDaysOfWeek: (daysOfWeek: string[]) => void;
  handleChangeRuleCity: (city: City) => void;
  handleChangeRuleCities: (cities: City[]) => void;
  handleChangeRuleCountry: (country: Country) => void;
  handleChangeRuleEvent: (event: Event | null) => void;
  handleChangeRuleEventType: (eventType: string) => void;
  handleChangeRuleEventOwnerTypes: (eventOwnerTypes: string[]) => void;
  handleChangeRulePurchaserType: (purchaserType: PurchaserType) => void;
  formValidationErrors: string[];
}

interface RuleTypeDeleteProps {
  handleDelete: Function;
}

const RuleTypeSelectorContainer = styled.div`
  max-width: 590px;
  margin-bottom: 10px;
`;

const RuleTypeFormValidationError = styled.div`
  ${({ theme }) => css`
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.3px;
    font-weight: 400;
    color: ${theme.colors.redRedWine};
    margin-bottom: 20px;
  `}
`;

const ValidationError = styled.div`
  margin-bottom: 7px;
`;

const RuleTypeSelectorText = styled.div`
  padding-top: 10px;
`;

const RuleTypes = styled.div`
  margin-bottom: 20px;
`;

const RuleType = styled.div`
  margin-bottom: 20px;
`;

const RuleTypeText = styled.div`
  width: 210px;
  font-weight: 600;
  white-space: nowrap;
  padding-top: 10px;
`;

const RuleTypeDetailSelector = styled.div`
  width: 270px;
`;

const RuleTypeDeleteContainer = styled.div`
  width: 60px;
  padding-top: 10px;
  padding-right: 20px;
`;

const SelectedRuleItems = styled.div`
  margin-top: 17px;
  margin-bottom: 10px;
`;

const SelectedRuleItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 10px;
`;

const SelectedRuleItemText = styled.div`
  width: 240px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: auto;
`;

const SelectedRuleItemDelete = styled.div`
  width: 40px;
  padding-top: 2px;
  padding-left: 8px;
`;

const allRuleTypes = {
  for_day_of_week: 'on a certain day of the week',
  for_days_of_week: 'on certain days of the week',
  for_city: 'in a certain city',
  for_multi_city: 'in certain cities',
  for_country: 'in a certain country',
  for_event: 'for a certain concert',
  for_event_type: 'for a certain concert type',
  for_event_owner_types: 'for certain owner types',
  for_purchaser_type: 'for certain ticket purchasers',
};

const daysOfWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const RuleTypeDelete: React.FC<RuleTypeDeleteProps> = ({ handleDelete }) => (
  <RuleTypeDeleteContainer>
    <DeleteIcon onClick={() => handleDelete()} />
  </RuleTypeDeleteContainer>
);

const RulesForm: React.FC<Props> = ({
  ruleTypes,
  ruleDayOfWeek,
  ruleDaysOfWeek,
  ruleCity,
  ruleCities,
  ruleCountry,
  ruleEvent,
  ruleEventType,
  ruleEventOwnerTypes,
  rulePurchaserType,
  handleAddRuleType,
  handleDeleteRuleType,
  handleChangeRuleDayOfWeek,
  handleChangeRuleDaysOfWeek,
  handleChangeRuleCity,
  handleChangeRuleCities,
  handleChangeRuleCountry,
  handleChangeRuleEvent,
  handleChangeRuleEventType,
  handleChangeRuleEventOwnerTypes,
  handleChangeRulePurchaserType,
  formValidationErrors,
}) => {
  const intl = useIntl();

  const [ruleTypesSelectable, setRuleTypesSelectable] = useState<object>(
    allRuleTypes
  );
  const [ruleTypeToAdd, setRuleTypeToAdd] = useState<string>('');
  const [selectedDaysOfWeek, setSelectedDaysOfWeek] = useState<string[]>(
    ruleDaysOfWeek
  );
  const [selectedCities, setSelectedCities] = useState<City[]>(ruleCities);
  const [selectedOwnerTypes, setSelectedOwnerTypes] = useState<string[]>(
    ruleEventOwnerTypes
  );
  const [selectedEvent, setSelectedEvent] = useState<Event | undefined>(
    ruleEvent
  );

  const {
    loading: loadingCountries,
    error: errorCountries,
    data: dataCountries,
  } = GetCountries({
    skipPagination: true,
  });

  if (errorCountries) {
    return <LoadingError whatsBeingLoaded="this form" />;
  }

  if (loadingCountries || !dataCountries) {
    return <EditFormLoadingBlocks />;
  }

  const ruleTypeOptions = getObjectOptionsPreservingOrder(
    ruleTypesSelectable,
    Object.keys(allRuleTypes)
  );

  const dayOfWeekOptions = getObjectOptions(
    daysOfWeek.reduce((o, day) => ({ ...o, [day]: day }), {})
  );

  const eventTypeOptions = getObjectOptions(eventTypeLabels);
  const eventOwnerTypeOptions = getObjectOptions(eventOwnerTypeLabels);

  const onAddRuleType = () => {
    handleAddRuleType(ruleTypeToAdd);
    setRuleTypesSelectable(
      removeEntriesByKey(ruleTypesSelectable, [ruleTypeToAdd])
    );
    setRuleTypeToAdd('');
  };

  const onDeleteRuleType = (ruleType: string) => {
    handleDeleteRuleType(ruleType);
    setRuleTypesSelectable(
      Object.assign(ruleTypesSelectable, {
        [ruleType]: allRuleTypes[ruleType],
      })
    );
    if (ruleType == 'for_days_of_week') {
      setSelectedDaysOfWeek([]);
    }
    if (ruleType == 'for_multi_city') {
      setSelectedCities([]);
    }
    if (ruleType == 'for_event_owner_types') {
      setSelectedOwnerTypes([]);
    }
    if (ruleType == 'for_event') {
      setSelectedEvent(undefined);
    }
  };

  const onAddDayOfWeek = (dayOfWeek: string) => {
    const newSelectedDaysOfWeek = [
      ...new Set(selectedDaysOfWeek.concat([dayOfWeek])),
    ];
    setSelectedDaysOfWeek(newSelectedDaysOfWeek);
    handleChangeRuleDaysOfWeek(newSelectedDaysOfWeek);
  };

  const onDeleteDayOfWeek = (dayOfWeek: string) => {
    const newSelectedDaysOfWeek = selectedDaysOfWeek.filter(
      (d: string) => d != dayOfWeek
    );
    setSelectedDaysOfWeek(newSelectedDaysOfWeek);
    handleChangeRuleDaysOfWeek(newSelectedDaysOfWeek);
  };

  const onAddCity = (city: City) => {
    if (city) {
      const newSelectedCities = [...new Set(selectedCities.concat([city]))];
      setSelectedCities(newSelectedCities);
      handleChangeRuleCities(newSelectedCities);
    }
  };

  const onDeleteCity = (city: City) => {
    const newSelectedCities = selectedCities.filter(
      (c: City) => c.id != city.id
    );
    setSelectedCities(newSelectedCities);
    handleChangeRuleCities(newSelectedCities);
  };

  const onAddOwnerType = (ownerType: string) => {
    if (ownerType) {
      const newSelectedOwnerTypes = [
        ...new Set(selectedOwnerTypes.concat([ownerType])),
      ];
      setSelectedOwnerTypes(newSelectedOwnerTypes);
      handleChangeRuleEventOwnerTypes(newSelectedOwnerTypes);
    }
  };

  const onDeleteOwnerType = (ownerType: string) => {
    const newSelectedOwnerTypes = selectedOwnerTypes.filter(
      (t: string) => t !== ownerType
    );
    setSelectedOwnerTypes(newSelectedOwnerTypes);
    handleChangeRuleEventOwnerTypes(newSelectedOwnerTypes);
  };

  const onAddEvent = (event: Event) => {
    setSelectedEvent(event);
    handleChangeRuleEvent(event || null);
  };

  return (
    <>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.promoCodeBasicInfo.form.customRulesHeader',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <RuleTypeSelectorContainer>
          <Grid cols={2}>
            <Col xs={12} sm={12} md={1} lg={1} xl={1}>
              <RuleTypeSelectorText>
                {intl.formatMessage({
                  id: 'admin.promoCodeBasicInfo.form.customRulesSelectRuleType',
                })}
              </RuleTypeSelectorText>
            </Col>
            <Col xs={12} sm={12} md={1} lg={1} xl={1}>
              <Select
                data-qaid="promo-code-edit-form-rule-type-field"
                getOptionLabel={(opt: any) => opt.title}
                options={ruleTypeOptions}
                placeholder={intl.formatMessage({
                  id: 'admin.promoCodeBasicInfo.form.customRulesPlaceholder',
                })}
                value={
                  ruleTypeToAdd
                    ? { title: daysOfWeek[ruleTypeToAdd], value: ruleTypeToAdd }
                    : null
                }
                onChange={(opt: any) => {
                  if (opt) {
                    setRuleTypeToAdd(opt.value);
                  }
                }}
                initialWidth="350px"
              />
            </Col>
          </Grid>
        </RuleTypeSelectorContainer>
        {formValidationErrors.length > 0 && (
          <RuleTypeFormValidationError>
            {formValidationErrors.map((error: string, index: number) => (
              <ValidationError key={index}>{error}</ValidationError>
            ))}
          </RuleTypeFormValidationError>
        )}
        <AddIconAndText
          text={intl.formatMessage({
            id: 'admin.promoCodeBasicInfo.form.customRulesAddRule',
          })}
          textSizeType="small"
          onClick={() => onAddRuleType()}
        />
        <DottedLine />
        <Spacer mb={8} />
        <RuleTypes>
          {ruleTypes.includes('for_day_of_week') && (
            <RuleType>
              <Grid cols={3}>
                <Col xs={12} sm={12} md={1} lg={1} xl={1}>
                  <RuleTypeText>
                    {intl.formatMessage({
                      id:
                        'admin.promoCodeBasicInfo.form.customRulesRuleTypeTextDayOfWeek',
                    })}
                  </RuleTypeText>
                </Col>
                <Col xs={12} sm={12} md={1} lg={1} xl={1}>
                  <RuleTypeDetailSelector>
                    <Select
                      data-qaid="rules-form-rule-type-day-of-week-field"
                      getOptionLabel={(opt: any) => opt.title}
                      defaultValue={
                        ruleDayOfWeek
                          ? {
                              title: ruleDayOfWeek,
                              value: ruleDayOfWeek,
                            }
                          : null
                      }
                      // @ts-ignore
                      options={dayOfWeekOptions}
                      placeholder={intl.formatMessage({
                        id:
                          'admin.promoCodeBasicInfo.form.customRulesRuleTypeDayOfWeekPlaceholder',
                      })}
                      onChange={(opt: any) =>
                        handleChangeRuleDayOfWeek(opt.value)
                      }
                      initialWidth="260px"
                    />
                  </RuleTypeDetailSelector>
                </Col>
                <Col xs={12} sm={12} md={1} lg={1} xl={1}>
                  <RuleTypeDelete
                    handleDelete={() => onDeleteRuleType('for_day_of_week')}
                  />
                </Col>
              </Grid>
            </RuleType>
          )}
          {ruleTypes.includes('for_days_of_week') && (
            <RuleType>
              <Grid cols={3}>
                <Col xs={12} sm={12} md={1} lg={1} xl={1}>
                  <RuleTypeText>
                    {intl.formatMessage({
                      id:
                        'admin.promoCodeBasicInfo.form.customRulesRuleTypeTextDaysOfWeek',
                    })}
                  </RuleTypeText>
                </Col>
                <Col xs={12} sm={12} md={1} lg={1} xl={1}>
                  <RuleTypeDetailSelector>
                    <Select
                      data-qaid="rules-form-rule-type-days-of-week-field"
                      getOptionLabel={(opt: any) => opt.title}
                      // @ts-ignore
                      options={dayOfWeekOptions}
                      placeholder={intl.formatMessage({
                        id:
                          'admin.promoCodeBasicInfo.form.customRulesRuleTypeDaysOfWeekPlaceholder',
                      })}
                      value={
                        selectedDaysOfWeek.length > 0
                          ? { title: '', value: '' }
                          : undefined
                      }
                      onChange={opt => onAddDayOfWeek(opt.value)}
                      initialWidth="260px"
                    />
                    <SelectedRuleItems>
                      {selectedDaysOfWeek.map((dayOfWeek, index) => (
                        <SelectedRuleItem key={index}>
                          <SelectedRuleItemText>
                            {dayOfWeek}
                          </SelectedRuleItemText>
                          <SelectedRuleItemDelete>
                            <DeleteIcon
                              onClick={() => onDeleteDayOfWeek(dayOfWeek)}
                            />
                          </SelectedRuleItemDelete>
                        </SelectedRuleItem>
                      ))}
                    </SelectedRuleItems>
                  </RuleTypeDetailSelector>
                </Col>
                <Col xs={12} sm={12} md={1} lg={1} xl={1}>
                  <RuleTypeDelete
                    handleDelete={() => onDeleteRuleType('for_days_of_week')}
                  />
                </Col>
              </Grid>
            </RuleType>
          )}
          {ruleTypes.includes('for_city') && (
            <RuleType>
              <Grid cols={3}>
                <Col xs={12} sm={12} md={1} lg={1} xl={1}>
                  <RuleTypeText>
                    {intl.formatMessage({
                      id:
                        'admin.promoCodeBasicInfo.form.customRulesRuleTypeTextCity',
                    })}
                  </RuleTypeText>
                </Col>
                <Col xs={12} sm={12} md={1} lg={1} xl={1}>
                  <RuleTypeDetailSelector>
                    <CitySelectorManualCSS
                      data-qaid="rules-form-rule-type-city-field"
                      parentContainer="promo-codd-edit-form"
                      disableTopCities
                      disableClosestCities
                      initialValue={ruleCity}
                      value={ruleCity}
                      onChange={(opt: any) => handleChangeRuleCity(opt)}
                      initialWidth="260px"
                    />
                  </RuleTypeDetailSelector>
                </Col>
                <Col xs={12} sm={12} md={1} lg={1} xl={1}>
                  <RuleTypeDelete
                    handleDelete={() => onDeleteRuleType('for_city')}
                  />
                </Col>
              </Grid>
            </RuleType>
          )}
          {ruleTypes.includes('for_multi_city') && (
            <RuleType>
              <Grid cols={3}>
                <Col xs={12} sm={12} md={1} lg={1} xl={1}>
                  <RuleTypeText>
                    {intl.formatMessage({
                      id:
                        'admin.promoCodeBasicInfo.form.customRulesRuleTypeTextMultiCity',
                    })}
                  </RuleTypeText>
                </Col>
                <Col xs={12} sm={12} md={1} lg={1} xl={1}>
                  <RuleTypeDetailSelector>
                    <CitySelectorManualCSS
                      data-qaid="rules-form-rule-type-cities-field"
                      parentContainer="promo-codd-edit-form"
                      disableTopCities
                      disableClosestCities
                      value={undefined}
                      onChange={(opt: any) => onAddCity(opt)}
                      initialWidth="260px"
                    />
                    <SelectedRuleItems>
                      {selectedCities.map((city, index) => (
                        <SelectedRuleItem key={index}>
                          <SelectedRuleItemText>
                            {city.title}
                          </SelectedRuleItemText>
                          <SelectedRuleItemDelete>
                            <DeleteIcon onClick={() => onDeleteCity(city)} />
                          </SelectedRuleItemDelete>
                        </SelectedRuleItem>
                      ))}
                    </SelectedRuleItems>
                  </RuleTypeDetailSelector>
                </Col>
                <Col xs={12} sm={12} md={1} lg={1} xl={1}>
                  <RuleTypeDelete
                    handleDelete={() => onDeleteRuleType('for_multi_city')}
                  />
                </Col>
              </Grid>
            </RuleType>
          )}
          {ruleTypes.includes('for_country') && (
            <RuleType>
              <Grid cols={3}>
                <Col xs={12} sm={12} md={1} lg={1} xl={1}>
                  <RuleTypeText>
                    {intl.formatMessage({
                      id:
                        'admin.promoCodeBasicInfo.form.customRulesRuleTypeTextCountry',
                    })}
                  </RuleTypeText>
                </Col>
                <Col xs={12} sm={12} md={1} lg={1} xl={1}>
                  <RuleTypeDetailSelector>
                    <Select
                      data-qaid="rules-form-rule-type-country-field"
                      searchable
                      getOptionLabel={(opt: any) => opt.title}
                      defaultValue={ruleCountry}
                      options={get(dataCountries, 'countries.countries', [])}
                      placeholder={intl.formatMessage({
                        id:
                          'admin.promoCodeBasicInfo.form.customRulesRuleTypeCountryPlaceholder',
                      })}
                      onChange={opt => handleChangeRuleCountry(opt)}
                      initialWidth="260px"
                    />
                  </RuleTypeDetailSelector>
                </Col>
                <Col xs={12} sm={12} md={1} lg={1} xl={1}>
                  <RuleTypeDelete
                    handleDelete={() => onDeleteRuleType('for_country')}
                  />
                </Col>
              </Grid>
            </RuleType>
          )}
          {ruleTypes.includes('for_event') && (
            <RuleType>
              <Grid cols={3}>
                <Col xs={12} sm={12} md={1} lg={1} xl={1}>
                  <RuleTypeText>
                    {intl.formatMessage({
                      id:
                        'admin.promoCodeBasicInfo.form.customRulesRuleTypeTextEvent',
                    })}
                  </RuleTypeText>
                </Col>
                <Col xs={12} sm={12} md={1} lg={1} xl={1}>
                  <RuleTypeDetailSelector>
                    <EventSelector
                      selectedEvent={selectedEvent}
                      setEvent={onAddEvent}
                    />
                  </RuleTypeDetailSelector>
                </Col>
                <Col xs={12} sm={12} md={1} lg={1} xl={1}>
                  <RuleTypeDelete
                    handleDelete={() => onDeleteRuleType('for_event')}
                  />
                </Col>
              </Grid>
            </RuleType>
          )}
          {ruleTypes.includes('for_event_type') && (
            <RuleType>
              <Grid cols={3}>
                <Col xs={12} sm={12} md={1} lg={1} xl={1}>
                  <RuleTypeText>
                    {intl.formatMessage({
                      id:
                        'admin.promoCodeBasicInfo.form.customRulesRuleTypeTextEventType',
                    })}
                  </RuleTypeText>
                </Col>
                <Col xs={12} sm={12} md={1} lg={1} xl={1}>
                  <RuleTypeDetailSelector>
                    <Select
                      data-qaid="rules-form-rule-type-event-type-field"
                      getOptionLabel={(opt: any) => opt.title}
                      defaultValue={
                        ruleEventType
                          ? {
                              title: eventTypeLabels[ruleEventType],
                              value: ruleEventType,
                            }
                          : null
                      }
                      options={eventTypeOptions}
                      placeholder={intl.formatMessage({
                        id:
                          'admin.promoCodeBasicInfo.form.customRulesRuleTypeEventTypePlaceholder',
                      })}
                      onChange={opt => handleChangeRuleEventType(opt.value)}
                      initialWidth="260px"
                    />
                  </RuleTypeDetailSelector>
                </Col>
                <Col xs={12} sm={12} md={1} lg={1} xl={1}>
                  <RuleTypeDelete
                    handleDelete={() => onDeleteRuleType('for_event_type')}
                  />
                </Col>
              </Grid>
            </RuleType>
          )}
          {ruleTypes.includes('for_event_owner_types') && (
            <RuleType>
              <Grid cols={3}>
                <Col xs={12} sm={12} md={1} lg={1} xl={1}>
                  <RuleTypeText>
                    {intl.formatMessage({
                      id:
                        'admin.promoCodeBasicInfo.form.customRulesRuleTypeTextEventOwnerTypes',
                    })}
                  </RuleTypeText>
                </Col>
                <Col xs={12} sm={12} md={1} lg={1} xl={1}>
                  <RuleTypeDetailSelector>
                    <Select
                      data-qaid="rules-form-rule-type-event-owner-types-field"
                      getOptionLabel={(opt: any) => opt.title}
                      options={eventOwnerTypeOptions}
                      placeholder={intl.formatMessage({
                        id:
                          'admin.promoCodeBasicInfo.form.customRulesRuleTypeEventOwnerTypePlaceholder',
                      })}
                      onChange={opt => onAddOwnerType(opt.value)}
                      initialWidth="260px"
                    />
                    <SelectedRuleItems>
                      {selectedOwnerTypes.map((ownerType, index) => (
                        <SelectedRuleItem key={index}>
                          <SelectedRuleItemText>
                            {eventOwnerTypeLabels[ownerType]}
                          </SelectedRuleItemText>
                          <SelectedRuleItemDelete>
                            <DeleteIcon
                              onClick={() => onDeleteOwnerType(ownerType)}
                            />
                          </SelectedRuleItemDelete>
                        </SelectedRuleItem>
                      ))}
                    </SelectedRuleItems>
                  </RuleTypeDetailSelector>
                </Col>
                <Col xs={12} sm={12} md={1} lg={1} xl={1}>
                  <RuleTypeDelete
                    handleDelete={() =>
                      onDeleteRuleType('for_event_owner_types')
                    }
                  />
                </Col>
              </Grid>
            </RuleType>
          )}
          {ruleTypes.includes('for_purchaser_type') && (
            <RuleType>
              <Grid cols={3}>
                <Col xs={3} sm={3} md={1} lg={1} xl={1}>
                  <RuleTypeText>
                    {intl.formatMessage({
                      id:
                        'admin.promoCodeBasicInfo.form.customRulesRuleTypeTextPurchaserType',
                    })}
                  </RuleTypeText>
                </Col>
                <Col xs={3} sm={3} md={1} lg={1} xl={1}>
                  <RuleTypeDetailSelector>
                    <Select
                      data-qaid="rules-form-rule-type-event-type-field"
                      getOptionLabel={(opt: any) => opt.title}
                      defaultValue={
                        rulePurchaserType
                          ? {
                              title: `${rulePurchaserType} purchasers`,
                              value: rulePurchaserType,
                            }
                          : null
                      }
                      options={[
                        { title: 'first-time purchasers', value: 'first-time' },
                        { title: 'returning purchasers', value: 'returning' },
                      ]}
                      placeholder={intl.formatMessage({
                        id:
                          'admin.promoCodeBasicInfo.form.customRulesRuleTypePurchaserTypePlaceholder',
                      })}
                      onChange={(opt: any) =>
                        handleChangeRulePurchaserType(opt.value)
                      }
                      initialWidth="260px"
                    />
                  </RuleTypeDetailSelector>
                </Col>
                <Col xs={3} sm={3} md={1} lg={1} xl={1}>
                  <RuleTypeDelete
                    handleDelete={() => onDeleteRuleType('for_purchaser_type')}
                  />
                </Col>
              </Grid>
            </RuleType>
          )}
        </RuleTypes>
      </FormGroupContainer>
    </>
  );
};

export default RulesForm;
