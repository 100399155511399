import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { useCurrentTheme } from 'app/shared/theme';
import { Checkbox } from 'app/shared/components/atoms/CheckboxManualCSS';
import { Spacer4, Spacer8 } from 'app/shared/components/atoms/Spacer';
import { StreamlineIcon } from 'app/shared/components/atoms/StreamlineIcon';
import { Overline } from 'app/shared/components/atoms/TypographyManualCSS';
import { ReactComponent as Feedback } from 'icons/streamline-regular/messages-chat-smileys/messages-speech-bubbles/messages-bubble-square-smile.svg';

interface OneClickFilterState {
  isEnabled?: boolean;
}

interface Props {
  oneClickFilterState: OneClickFilterState;
  handleOneClickFilterChange: Function;
  labelTextKey: string;
  displayFeedbackButton?: boolean;
  loadingStaffMemberInfo?: boolean;
  toggleModal?: VoidFunction;
}

const MainContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row:
    flex-wrap: wrap;
    border-radius: 10px;
    height: 40px;
    padding: 10px;
    margin: 15px 0px;
    background-color: ${theme.colors.darkSmoke};
  `}
`;

const IconButton = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-grow: 1;
    flex-direction: row-reverse;
    cursor: pointer;
    margin-right: 8px;
    color: ${theme.colors.whiteDenim};
    font-weight: normal;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    line-height: 1.6;
    margin-top: 2px;
  `}
`;

const StyledCheckbox = styled(Checkbox)`
  color: white;
`;

const StyledOverline = styled(Overline)`
  ${({ theme }) => css`
    color: ${theme.colors.whiteDenim};
    margin-bottom: 5px;
    white-space: nowrap;
  `};
`;

const StyledStreamlineIcon = styled(StreamlineIcon)`
  margin-top: 2px;
  margin-right: 4px;
`;

const ListingOneClickFilter: React.FC<Props> = ({
  oneClickFilterState,
  handleOneClickFilterChange,
  labelTextKey,
  displayFeedbackButton,
  loadingStaffMemberInfo,
  toggleModal,
}) => {
  const intl = useIntl();
  const theme = useCurrentTheme();

  return (
    <MainContainer>
      <Spacer4 />
      <StyledCheckbox
        color="whiteDenim"
        id="listing-one-click-filter"
        data-qaid="listing-one-click-filter"
        checked={oneClickFilterState.isEnabled}
        name="listingOneClickFilter"
        onChange={e => {
          handleOneClickFilterChange(e.target.checked);
        }}
      >
        <StyledOverline>
          {intl.formatMessage({
            id: labelTextKey,
          })}
        </StyledOverline>
      </StyledCheckbox>
      {displayFeedbackButton && !loadingStaffMemberInfo && (
        <IconButton onClick={toggleModal} data-qaid="crew-feedback-button">
          <StyledStreamlineIcon
            icon={Feedback}
            size={16}
            stroke={theme.colors.whiteDenim}
          />
          <Spacer8 />
          {intl.formatMessage({
            id: 'shared.feedback',
          })}
        </IconButton>
      )}
    </MainContainer>
  );
};

export default ListingOneClickFilter;
