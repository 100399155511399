import { Coordinates } from 'app/typings/coordinates';

export default function findDistanceBetween(
  baseCoordinates: Coordinates,
  otherCoordinates: Coordinates
) {
  const p = Math.PI / 180;
  const c = Math.cos;
  const a =
    0.5 -
    c((baseCoordinates.latitude - otherCoordinates.latitude) * p) / 2 +
    (c(otherCoordinates.latitude * p) *
      c(baseCoordinates.latitude * p) *
      (1 - c((baseCoordinates.longitude - otherCoordinates.longitude) * p))) /
      2;

  // distance between both locations (km)
  return 12742 * Math.asin(Math.sqrt(a));
}
