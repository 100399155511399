import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { PrimaryRole } from 'app/typings/roles';
import { dateFormatter } from 'app/shared/utils/datetime';
import { dataGetter } from 'app/shared/utils/queries';
import { buildFullName } from 'app/shared/utils/string';
import useModal from 'app/shared/utils/useModal';
import usePermission from 'app/shared/utils/usePermission';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import ConfirmationModal from 'app/shared/components/molecules/ConfirmationModal';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { curatorPrimaryRoleKeys } from 'app/admin/utils/users';
import { UpdateUserRoles } from 'app/admin/graphql/users/mutationHooks';
import { GetInternalUserBasicInfo } from 'app/admin/graphql/users/queryHooks';
import { DetailsMainContainer } from 'app/admin/components/atoms/DetailContent';
import AccordionSections from 'app/admin/components/organisms/AccordionSections';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';
import { ReactComponent as InformationCircle } from 'icons/streamline-regular/interface-essential/alerts/information-circle.svg';
import { ReactComponent as Lock6 } from 'icons/streamline-regular/interface-essential/lock-unlock/lock-6.svg';

import SectionBasicInfo from './SectionBasicInfo';
import SectionRoles from './SectionRoles';

interface Props {
  navigateTo: (routeData: any) => void;
  contentProps: any;
  hide: VoidFunction;
}

const detailsHeaderSummaryInfoList = (
  currentSignInAt: string | undefined,
  createdAt: string | undefined,
  signInCount: number | undefined
) => {
  const signInCountDescription = 'Logins';

  const currentSignInAtMonth = currentSignInAt
    ? dateFormatter(currentSignInAt, 'shortMonth').toUpperCase()
    : 'N/A';

  const currentSignInAtYear = currentSignInAt
    ? dateFormatter(currentSignInAt, 'longYear')
    : null;

  const currentSignInAtDescription = currentSignInAt
    ? `${currentSignInAtYear} \n Last Login`
    : 'Last Login';

  const createdAtMonth = createdAt
    ? dateFormatter(createdAt, 'shortMonth').toUpperCase()
    : 'N/A';

  const createdAtYear = createdAt ? dateFormatter(createdAt, 'longYear') : null;

  const createdAtDescription = createdAt
    ? `${createdAtYear} \n Signup`
    : 'Signup';

  return [
    {
      backgroundColor: '#ebedef',
      mainInfo:
        signInCount || signInCount == 0 ? signInCount.toString() : 'N/A',
      description: signInCountDescription,
    },
    {
      backgroundColor: '#ebf5fb',
      mainInfo: currentSignInAtMonth,
      description: currentSignInAtDescription,
    },
    {
      backgroundColor: '#f3f9ed',
      mainInfo: createdAtMonth,
      description: createdAtDescription,
    },
  ];
};

const userHasRemainingRoles = (
  primaryRoles: PrimaryRole[],
  isLimitedByCuratorGroups: boolean
) => {
  if (isLimitedByCuratorGroups) {
    return !!primaryRoles.some((primaryRole: PrimaryRole) =>
      curatorPrimaryRoleKeys.includes(primaryRole.key)
    );
  } else {
    return !!(primaryRoles.length > 0);
  }
};

const InternalUserDetails: React.FC<Props> = ({
  contentProps,
  hide,
  navigateTo,
}) => {
  const {
    id,
    firstName,
    lastName,
    currentSignInAt,
    createdAt,
    signInCount,
    primaryRoles,
    refetchInternalUsers,
  } = contentProps;

  const intl = useIntl();

  const hasEditUserRolesPermission = usePermission('user.roles.edit');

  const [headerData, setHeaderData] = useState({
    firstName,
    lastName,
    currentSignInAt,
    createdAt,
    signInCount,
    primaryRoles,
    isLoaded: !!createdAt,
  });

  const [removeAllRolesModal, toggleRemoveAllRolesModal] = useModal();
  const [removeRoleModal, toggleRemoveRoleModal] = useModal();
  const [roleData, setRoleData] = useState<any>(undefined);

  const {
    loading: loadingBasicInfo,
    error: errorBasicInfo,
    data: dataBasicInfo,
    refetch: refetchUserBasicInfo,
  } = GetInternalUserBasicInfo({
    id,
    fetchPolicy: 'cache-and-network',
  });

  const isLimitedByCuratorGroups = !!(
    contentProps.curatorGroupIdsToLimitBy &&
    contentProps.curatorGroupIdsToLimitBy.length > 0
  );

  const sectionsConfig = {
    basicInfo: {
      title: intl.formatMessage({
        id: 'admin.users.details.sectionTitle.basicInfo',
      }),
      icon: InformationCircle,
      iconColor: 'blueChristmas',
      sectionComponent: SectionBasicInfo,
      dataKey: 'user',
      displayEditIcon: hasEditUserRolesPermission,
      loading: loadingBasicInfo,
      data: dataBasicInfo ? dataBasicInfo.user : undefined,
      callbacks: {
        toggleSectionEditModal: () =>
          navigateTo({
            routeName: 'internal-user-basic-info-edit',
            routeProps: dataBasicInfo ? dataBasicInfo.user : undefined,
          }),
      },
    },
    roles: {
      title: intl.formatMessage({
        id: 'admin.users.details.sectionTitle.roles',
      }),
      icon: Lock6,
      iconColor: 'checkBerry',
      iconCircle: true,
      sectionComponent: SectionRoles,
      dataKey: 'user',
      displayEditIcon: false,
      loading: loadingBasicInfo,
      data: dataBasicInfo ? dataBasicInfo.user : undefined,
      callbacks: {
        toggleAddRoleModal: () =>
          navigateTo({
            routeName: 'user-role-add',
            routeProps: {
              user: {
                id,
                firstName: headerData.firstName,
                lastName: headerData.lastName,
              },
              action: 'addRoleToUser',
              curatorGroupIdsToLimitBy: contentProps.curatorGroupIdsToLimitBy,
              refetchInternalUsers,
            },
          }),
        toggleRemoveRoleAndSetRoleData: (roleData: any) => {
          setRoleData(roleData);
          toggleRemoveRoleModal();
        },
      },
    },
  };

  const updateUserRolesAction = UpdateUserRoles();

  const handleRemoveAllUserRoles = useSubmitAction({
    submitAction: updateUserRolesAction,
    submitVariables: () => ({
      id,
      removeAllRoles: true,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.users.details.removeAllRoles.successMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.users.details.removeAllRoles.failureMessage',
    }),
    onSuccess: () => {
      removeAllRolesModal.hide();
      hide();
      refetchInternalUsers && refetchInternalUsers();
    },
  });

  const handleRemoveUserRole = useSubmitAction({
    submitAction: updateUserRolesAction,
    submitVariables: () => ({
      id,
      roleToRemove: roleData.roleToRemove,
      resourceId: roleData.resourceId,
      resourceType: roleData.resourceType,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.users.details.removeRole.successMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.users.details.removeRole.failureMessage',
    }),
    onSuccess: (response: any) => {
      removeRoleModal.hide();
      if (
        userHasRemainingRoles(
          response.data.updateUserRoles.user.primaryRoles,
          isLimitedByCuratorGroups
        )
      ) {
        refetchUserBasicInfo();
      } else {
        refetchInternalUsers && refetchInternalUsers();
        hide();
      }
    },
  });

  useEffect(() => {
    const getDataBasicInfo = dataGetter(dataBasicInfo, 'user');
    if (dataBasicInfo) {
      setHeaderData({
        firstName: getDataBasicInfo('firstName'),
        lastName: getDataBasicInfo('lastName'),
        currentSignInAt: getDataBasicInfo('currentSignInAt'),
        createdAt: getDataBasicInfo('createdAt'),
        signInCount: getDataBasicInfo('signInCount'),
        primaryRoles: getDataBasicInfo('primaryRoles'),
        isLoaded: true,
      });
    }
  }, [dataBasicInfo, loadingBasicInfo]);

  if ((!loadingBasicInfo && !dataBasicInfo) || errorBasicInfo) {
    hide();
    return null;
  }

  const detailsHeaderActionLinksInfo = (
    toggleRemoveAllRolesModal: Function,
    hasEditUserRolesPermission: boolean,
    primaryRoles?: PrimaryRole[]
  ) => {
    if (hasEditUserRolesPermission) {
      return {
        link1: {
          text: intl.formatMessage({
            id: 'admin.users.details.removeAllRolesLink',
          }),
          active: primaryRoles && primaryRoles.length > 0 ? true : false,
          onClickAction: () => toggleRemoveAllRolesModal(),
        },
      };
    } else {
      return undefined;
    }
  };

  const summaryInfoList = detailsHeaderSummaryInfoList(
    headerData.currentSignInAt,
    headerData.createdAt,
    headerData.signInCount
  );

  const actionLinksInfo = detailsHeaderActionLinksInfo(
    toggleRemoveAllRolesModal,
    hasEditUserRolesPermission,
    headerData.primaryRoles
  );

  const userName = buildFullName(headerData.firstName, headerData.lastName);

  return (
    <ModalContentContainer data-qaid="internal-user-details-main-modal">
      <DetailsMainContainer>
        <DetailsHeader
          title={
            userName ||
            intl.formatMessage({
              id: 'admin.users.details.noName',
            })
          }
          summaryInfoList={summaryInfoList}
          actionLinksInfo={actionLinksInfo}
          loading={!headerData.isLoaded}
        />
        <AccordionSections
          sectionsConfig={sectionsConfig}
          defaultOpenSection={contentProps.defaultOpenSection}
        />
      </DetailsMainContainer>
      {removeAllRolesModal.isShowing && (
        <ConfirmationModal
          onCancel={() => removeAllRolesModal.hide()}
          description={intl.formatMessage({
            id: 'admin.users.details.removeAllRoles.confirmationMessage',
          })}
          // @ts-ignore
          onConfirm={handleRemoveAllUserRoles}
        />
      )}
      {removeRoleModal.isShowing && (
        <ConfirmationModal
          onCancel={() => removeRoleModal.hide()}
          description={intl.formatMessage(
            {
              id: 'admin.users.details.removeRole.confirmationMessage',
            },
            {
              fullRoleName: roleData.fullRoleName,
            }
          )}
          // @ts-ignore
          onConfirm={handleRemoveUserRole}
        />
      )}
    </ModalContentContainer>
  );
};

export default InternalUserDetails;
