export const sortOptions = [
  {
    title: 'Name (A - Z)',
    value: {
      by: 'user_name',
      direction: 'asc',
    },
  },
  {
    title: 'Name (Z - A)',
    value: {
      by: 'user_name',
      direction: 'desc',
    },
  },
  {
    title: 'Email (A - Z)',
    value: {
      by: 'email',
      direction: 'asc',
    },
  },
  {
    title: 'Email (Z - A)',
    value: {
      by: 'email',
      direction: 'desc',
    },
  },
  {
    title: 'Most recently logged in',
    value: {
      by: 'current_sign_in_at',
      direction: 'desc',
    },
  },
  {
    title: 'Least recently logged in',
    value: {
      by: 'current_sign_in_at',
      direction: 'asc',
    },
  },
  {
    title: 'Most recently signed up',
    value: {
      by: 'created_at',
      direction: 'desc',
    },
  },
  {
    title: 'Least recently signed up',
    value: {
      by: 'created_at',
      direction: 'asc',
    },
  },
  {
    title: 'Most active (most logins)',
    value: {
      by: 'sign_in_count',
      direction: 'desc',
    },
  },
  {
    title: 'Least active (fewest logins)',
    value: {
      by: 'sign_in_count',
      direction: 'asc',
    },
  },
];

export const eventsStaffedOptions = {
  eventsStaffed: [
    {
      title: 'Worked at least one concert',
      value: 'staffed_at_least_1_event',
    },
    {
      title: 'Never worked a concert',
      value: 'staffed_0_events',
    },
  ],
};

export const associatedWithArtistsOptions = {
  associatedWithArtists: [
    {
      title: 'Associated with artist',
      value: 'associated_with_artists',
    },
  ],
};

export const loginActivityOptions = {
  loginActivity: [
    {
      title: 'Logged in Last 30 Days',
      value: 'logged_in_last_30_days',
    },
    {
      title: 'Logged in Last 7 Days',
      value: 'logged_in_last_7_days',
    },
    {
      title: 'No Logins Past Year',
      value: 'not_logged_in_last_year',
    },
    {
      title: 'Signed up Last 30 Days',
      value: 'signed_up_last_30_days',
    },
  ],
};
