import React from 'react';
import { useIntl } from 'react-intl';

import { get } from 'app/shared/utils/get';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { emailGroupSettingsLabels } from 'app/admin/utils/events';
import { UpdateEventEmails } from 'app/admin/graphql/emails/mutationHooks';
import { DetailsFormMainContainer } from 'app/admin/components/atoms/DetailContent';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

import EventEmailSettingsEditForm from './EventEmailSettingsEditForm';
import EventEmailSettingsEditFormSchema from './EventEmailSettingsEditFormSchema';

interface FormProps {
  contentProps: any;
  event: any;
  setFormSubmitAction?: (func: Function) => void;
  setDisplayConfirmation?: (set: boolean) => void;
  setIsSubmitting?: (set: boolean) => void;
  navigateTo: (routeData: object) => void;
}

const filterDisableableEmailGroupsAndLabels = (
  localizedEmailGroupLabels: any,
  emailGroupsAndEmails: any
) => {
  const disableableEmailGroupsAndLabels = {};

  Object.keys(localizedEmailGroupLabels).map((emailGroup: string) => {
    emailGroupsAndEmails[emailGroup] &&
      (disableableEmailGroupsAndLabels[`${emailGroup}`] = []) &&
      emailGroupsAndEmails[emailGroup].map((email: any) => {
        email.isDisableable &&
          disableableEmailGroupsAndLabels[`${emailGroup}`].push(email);
      });
  });
  return disableableEmailGroupsAndLabels;
};

const getEventEmailSettingsFormInitialValues = (
  localizedEmailGroupLabels: any,
  emailGroupsAndEmails: any
) => {
  const emailSettingsFormValues = {};
  Object.keys(localizedEmailGroupLabels).map(
    (emailGroup: string) =>
      emailGroupsAndEmails[emailGroup] &&
      emailGroupsAndEmails[emailGroup].map((email: any) => {
        email.isDisableable &&
          (emailSettingsFormValues[email.id] = !email.isDisabled);
      })
  );
  return emailSettingsFormValues;
};

const getEventEmailSettingsSubmitVariable = (emailSettingsFormValues: any) => {
  const eventEmailSettings = [];
  for (let email in emailSettingsFormValues) {
    let isEmailEnabled = emailSettingsFormValues[email];

    eventEmailSettings.push({
      name: email,
      isEnabled: isEmailEnabled,
    });
  }
  return eventEmailSettings;
};

const EventEmailSettingsEdit: React.FC<FormProps> = ({
  contentProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  navigateTo,
}) => {
  const intl = useIntl();

  const { emailGroupsAndEmails, event } = contentProps;

  const localStartsAt = get(event, 'localStartsAt', '');
  const cityTitle = get(event, 'city.title', '');
  const neighborhoodTitle = get(event, 'neighborhood.title', '');
  const localizedEmailGroupLabels = emailGroupSettingsLabels(intl);
  const disableableEmailGroupsAndEmails = filterDisableableEmailGroupsAndLabels(
    localizedEmailGroupLabels,
    emailGroupsAndEmails
  );
  const formInitialValues = {
    useFromEmailForAllEmails: get(event, 'useFromEmailForAllEmails', false),
    fromEmail: get(event, 'fromEmail', '') || '',
    artistOpsId: get(event, 'mainArtistOps.id', '') || null,
    crewOpsId: get(event, 'mainCrewOps.id', '') || null,
    venueOpsId: get(event, 'mainVenueOps.id', '') || null,
    eventEmailSettings: getEventEmailSettingsFormInitialValues(
      localizedEmailGroupLabels,
      emailGroupsAndEmails
    ),
  };
  const updateEventEmail = UpdateEventEmails();

  const handleUpdateEventEmails = useSubmitAction({
    submitAction: updateEventEmail,
    submitVariables: (values: any) => ({
      eventId: event && event.id,
      useFromEmailForAllEmails: values.useFromEmailForAllEmails,
      fromEmail: values.fromEmail,
      artistOpsId: values.artistOpsId,
      crewOpsId: values.crewOpsId,
      venueOpsId: values.venueOpsId,
      eventEmailSettings: getEventEmailSettingsSubmitVariable(
        values.eventEmailSettings
      ),
    }),
    successMsg: intl.formatMessage({
      id: 'admin.eventBasicInfo.successMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.eventBasicInfo.failureMessage',
    }),
    onSuccess: () => {
      navigateTo({
        routeName: 'event-details',
        routeProps: {
          defaultOpenSection: 'emailsInfo',
          ...event,
        },
      });
    },
  });

  return (
    <ModalContentContainer>
      <DetailsFormMainContainer>
        <DetailsHeader
          title={cityTitle}
          subTitle={neighborhoodTitle}
          loading={!(!!cityTitle || !!localStartsAt)}
          imageStyle="calendar"
          detailsDate={localStartsAt}
        />
        <GenericForm
          formInitialValues={formInitialValues}
          renderFormComponent={(renderProps: any) => (
            <EventEmailSettingsEditForm
              formikProps={renderProps.formikProps}
              emailGroupsAndEmails={disableableEmailGroupsAndEmails}
              emailGroupLabels={localizedEmailGroupLabels}
              cityCachedSlug={get(event, 'city.cachedSlug', '')}
              eventOrganizedAs={event?.eventOrganizedAs}
            />
          )}
          onSubmit={handleUpdateEventEmails}
          formSchema={EventEmailSettingsEditFormSchema(intl)}
          setFormSubmitAction={setFormSubmitAction}
          setDisplayConfirmation={setDisplayConfirmation}
          setIsSubmitting={setIsSubmitting}
          dataQaId="event-email-settings-form"
        />
      </DetailsFormMainContainer>
    </ModalContentContainer>
  );
};

export default EventEmailSettingsEdit;
