import * as Yup from 'yup';

const ArtistApplicationReviewFormSchema = (intl: any) => {
  Yup.object().shape({
    artistApplicationDecision: Yup.string()
      .trim()
      .required(
        intl.formatMessage({
          id: 'form.requiredField',
        })
      ),
    customEmailMessage: Yup.string()
      .nullable()
      .notRequired()
      .max(500),
  });
};

export default ArtistApplicationReviewFormSchema;
