import React, { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { KeyValue } from 'app/typings/generics';
import { Property } from 'app/typings/properties';
import { useCurrentTheme } from 'app/shared/theme';
import useModal from 'app/shared/utils/useModal';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import LazyImgixImage from 'app/shared/components/atoms/LazyImgixLoad';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Spinner } from 'app/shared/components/atoms/SpinnerManualCSS';
import { StreamlineIcon } from 'app/shared/components/atoms/StreamlineIcon';
import { ToolTip, ToolTipContainer } from 'app/shared/components/atoms/ToolTip';
import TruncatedByCharText from 'app/shared/components/atoms/TruncatedByCharText';
import {
  Body2,
  Overline,
} from 'app/shared/components/atoms/TypographyManualCSS';
import InfoBox from 'app/shared/components/molecules/Flyover';
import RoutableModal from 'app/shared/components/molecules/RoutableModal';
import { KeyValueInfoList } from 'app/admin/components/molecules/KeyValueInfoList';
import {
  ListingCardTopTag,
  ListingCardTopTags,
} from 'app/admin/components/molecules/ListingCardTopTag';
import { PropertyGroupList } from 'app/admin/components/molecules/PropertyGroupList';
import { TagElementList } from 'app/admin/components/molecules/TagElementList';
import {
  CardControls,
  ControlsInfo,
} from 'app/admin/components/organisms/CardControls';

interface ListingCardProps {
  listingCardTopTag?: ListingCardTopTag;
  topFlyoverInfo?: TopFlyoverInfo;
  imageStyle?: string;
  imageUrl?: string;
  largeImageUrl?: string;
  title: string;
  titleTruncateCharLength?: number;
  subtitle?: string;
  subtitleTruncateCharLength?: number;
  subtitleAction?: VoidFunction;
  topContent?: VoidFunction;
  tagElementsInfoList?: TagElementsProps[];
  propertyElementsInfoList?: PropertyElementsProps[];
  keysAndValuesList?: KeyValue[];
  controlsInfoList?: ControlsInfo[];
  onShowDetails?: Function;
  onShowDetailsParams?: object;
  showDetailsTipText?: string;
  dataQaidPrefix: string;
  index?: number;
  height: string;
  loading?: boolean;
  error?: string;
  modalRef?: any;
}

interface TopFlyoverInfo {
  icon: any;
  iconEnabled: boolean;
  tipText: string;
  infoBoxContent?: any;
}

interface TagElementsProps {
  tagElements?: any;
  listTitle: string;
  tagElementColor: string;
  containerHeight?: string;
}

interface PropertyElementsProps {
  listTitle: string;
  propertyElements?: Property[];
}

interface CardWrapperProps {
  height: string;
}

const CardWrapper = styled.div<CardWrapperProps>`
  ${props => `
    background-color: ${props.theme.colors.silverSprings};
    width: 100%;
    max-width: 240px;
    height: ${props.height || '600px'} !important;
    border-radius: ${props.theme.ruler[3]}px;
    padding: ${props.theme.ruler[4]}px;
    display: flex;
    flex-direction: column;
  `}
`;

const TopTagWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-right: -40px;
  padding-top: 5px;
`;

const ThumbnailCircle = styled.div`
  ${({ theme }) => css`
    width: ${theme.ruler[20]}px;
    height: ${theme.ruler[20]}px;
    border-radius: ${theme.ruler[10]}px;
    background-color: ${theme.colors.blueSmoke};
    overflow: hidden;
    cursor: pointer;
  `}
`;

const ThumbnailLandscape = styled.div`
  ${({ theme }) => css`
    width: auto;
    height: 125px;
    border-top-left-radius: ${theme.ruler[3]}px;
    border-top-right-radius: ${theme.ruler[3]}px;
    margin-top: -${theme.ruler[4]}px;
    margin-left: -${theme.ruler[4]}px;
    margin-right: -${theme.ruler[4]}px;
    background-color: ${theme.colors.blueSmoke};
    overflow: hidden;
    cursor: pointer;
  `}
`;

interface TopInfoContainerProps {
  height: string;
}

const TopInfoContainer = styled.div<TopInfoContainerProps>`
  height: ${props => props.height || '165px'};
`;

const TopInfoUpperContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: flex-start;
  width: 100%;
`;

export const TagElementListContainer = styled.div<{
  containerHeight: string | undefined;
}>`
  min-height: 87px;

  ${props =>
    props.containerHeight &&
    `
    min-height: ${props.containerHeight};
    max-height: ${props.containerHeight};
  `}
`;

const PropertyElementListContainer = styled.div`
  height: 110px;
`;

const KeyValueInfoListContainer = styled.div`
  height: auto;
`;

const CardTitle = styled(Body2)`
  font-weight: 700;
  letter-spacing: 0.5px;
`;

const CardSubtitle = styled(Body2)`
  font-size: 12px !important;
`;

const CardSubtitleWithAction = styled(CardSubtitle)`
  ${({ theme }) => css`
    color: ${theme.colors.primary};
    cursor: pointer;
  `}
`;

const None = styled(Body2)`
  ${({ theme }) => css`
    color: ${theme.colors.backToBlack};

    ${theme.media.xs`
      font-size: 12px !important;
    `}
  `}
`;

const SpinnerWrapper = styled.div`
  width: 200px;
`;

const TopFlyoverContainer = styled.div``;

const BoxedTooltipContainer = styled(ToolTipContainer)`
  display: flex;
  align-items: center;
  justify-content: center;
  height 25px;
  width: 25px;
`;

const PointerA = styled.a`
  cursor: pointer;
`;

interface DisplayIconProps {
  theme: any;
  enabled: boolean;
  icon: any;
  iconSize: number | undefined;
}

const displayIcon = ({
  theme,
  enabled,
  icon,
  iconSize = 16,
}: DisplayIconProps) => {
  const color = enabled ? theme.colors.green600 : theme.colors.blueSmoke;
  return <StreamlineIcon icon={icon} size={iconSize} stroke={color} />;
};

interface IconWithInfoBoxProps {
  topFlyoverInfo: TopFlyoverInfo;
  listingCardRef?: React.RefObject<HTMLDivElement>;
}

const IconWithInfoBox: React.FC<IconWithInfoBoxProps> = ({
  topFlyoverInfo,
  listingCardRef,
}) => {
  const theme = useCurrentTheme();
  const [flyoverClosed, setFlyoverClosed] = useState(false);

  return (
    <PointerA>
      <BoxedTooltipContainer>
        <InfoBox
          zIndex={102}
          anchorRef={listingCardRef}
          showCaret={false}
          width="300px"
          triggerElement={displayIcon({
            theme,
            enabled: topFlyoverInfo.iconEnabled,
            icon: topFlyoverInfo.icon,
            iconSize: undefined,
          })}
          keepInViewPort={{
            vertical: true,
            horizontal: true,
          }}
          onStateChange={isOpen =>
            setFlyoverClosed && setFlyoverClosed(!isOpen)
          }
          shouldFlyoverClose={flyoverClosed}
          innerContent={topFlyoverInfo.infoBoxContent(setFlyoverClosed)}
        />
        <ToolTip>{topFlyoverInfo.tipText}</ToolTip>
      </BoxedTooltipContainer>
    </PointerA>
  );
};

interface TopFlyoverProps {
  topFlyoverInfo: TopFlyoverInfo;
  listingCardRef?: React.RefObject<HTMLDivElement>;
}

const TopFlyover: React.FC<TopFlyoverProps> = ({
  topFlyoverInfo,
  listingCardRef,
}) => {
  return (
    <TopFlyoverContainer>
      <IconWithInfoBox
        topFlyoverInfo={topFlyoverInfo}
        listingCardRef={listingCardRef}
      />
    </TopFlyoverContainer>
  );
};

interface TopInfoProps {
  imageStyle?: string;
  imageUrl?: string;
  toggleViewLargeImageModal: VoidFunction | undefined;
  title: string;
  titleTruncateCharLength: number;
  subtitle?: string;
  subtitleTruncateCharLength: number;
  subtitleAction?: VoidFunction;
  loading?: boolean;
  error?: string;
  topFlyoverInfo?: TopFlyoverInfo;
  dataQaidPrefix: string;
}

const TopInfo: React.FC<TopInfoProps> = ({
  imageStyle,
  imageUrl,
  toggleViewLargeImageModal = () => {},
  title,
  titleTruncateCharLength,
  subtitle,
  subtitleTruncateCharLength,
  subtitleAction,
  loading,
  error,
  topFlyoverInfo,
  dataQaidPrefix,
}) => {
  const height =
    imageStyle === 'landscape'
      ? '200px'
      : imageStyle === 'circle'
      ? '165px'
      : subtitle
      ? '90px'
      : '60px';
  return (
    <TopInfoContainer height={height}>
      <TopInfoUpperContainer>
        {imageStyle === 'circle' && (
          <ThumbnailCircle onClick={toggleViewLargeImageModal}>
            {imageUrl && (
              <LazyImgixImage
                src={imageUrl}
                sizes="80px"
                dataQaid={`${dataQaidPrefix}-card-image`}
              />
            )}
          </ThumbnailCircle>
        )}
        {imageStyle === 'landscape' && (
          <ThumbnailLandscape onClick={toggleViewLargeImageModal}>
            {imageUrl && (
              <LazyImgixImage
                src={imageUrl}
                sizes="240px"
                aspectRatio="1.6:1"
                dataQaid={`${dataQaidPrefix}-card-image`}
              />
            )}
          </ThumbnailLandscape>
        )}
        {topFlyoverInfo && <TopFlyover topFlyoverInfo={topFlyoverInfo} />}
      </TopInfoUpperContainer>

      {imageStyle === 'circle' && <Spacer mb={4} />}
      {imageStyle === 'landscape' && <Spacer mb={3} />}
      {!imageStyle && <Spacer mb={1} />}

      {loading ? (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      ) : (
        <CardTitle data-qaid={`${dataQaidPrefix}-card-title`}>
          {error ? (
            error
          ) : (
            <TruncatedByCharText
              text={title}
              truncateLength={titleTruncateCharLength}
            />
          )}
        </CardTitle>
      )}

      {!loading && !error && (
        <>
          {subtitle && subtitleAction && (
            <CardSubtitleWithAction
              onClick={subtitleAction}
              data-qaid={`${dataQaidPrefix}-card-subtitle`}
            >
              <TruncatedByCharText
                text={subtitle}
                truncateLength={subtitleTruncateCharLength}
              />
            </CardSubtitleWithAction>
          )}
          {subtitle && !subtitleAction && (
            <CardSubtitle data-qaid={`${dataQaidPrefix}-card-subtitle`}>
              <TruncatedByCharText
                text={subtitle}
                truncateLength={subtitleTruncateCharLength}
              />
            </CardSubtitle>
          )}
        </>
      )}
    </TopInfoContainer>
  );
};

const renderTopContent = (topContent: VoidFunction | undefined) =>
  topContent ? topContent() : null;

const renderTagElements = (
  tagElementsInfoList: TagElementsProps[] | undefined
) => {
  if (tagElementsInfoList && tagElementsInfoList.length > 0) {
    return tagElementsInfoList.map(
      (tagElementsInfo: TagElementsProps, i: number) => {
        return (
          <TagElements
            tagElements={tagElementsInfo.tagElements}
            listTitle={tagElementsInfo.listTitle}
            tagElementColor={tagElementsInfo.tagElementColor}
            containerHeight={tagElementsInfo && tagElementsInfo.containerHeight}
            key={i}
          />
        );
      }
    );
  }
  return null;
};

const TagElements: React.FC<TagElementsProps> = ({
  tagElements,
  listTitle,
  tagElementColor,
  containerHeight,
}) => {
  return (
    <TagElementListContainer containerHeight={containerHeight}>
      <Overline>
        {tagElements && tagElements.length > 0
          ? `${listTitle}: ${tagElements.length}`
          : `${listTitle}`}
      </Overline>
      <TagElementList
        tagElements={tagElements}
        tagElementColor={tagElementColor}
        containerHeight={containerHeight}
        displayShowAllLink={false}
      />
    </TagElementListContainer>
  );
};

const PropertyElements: React.FC<PropertyElementsProps> = ({
  propertyElements,
  listTitle,
}) => {
  const intl = useIntl();

  if (propertyElements && propertyElements.length > 0) {
    const properties = propertyElements.map(element => {
      return {
        iconName: element.iconName,
        tipText: element.name,
        propertyGroupId: element.propertyGroupId,
      };
    });
    return (
      <PropertyElementListContainer>
        <Overline>{listTitle}</Overline>
        <PropertyGroupList propertyElements={properties} />
      </PropertyElementListContainer>
    );
  }
  return (
    <PropertyElementListContainer>
      <Overline>{listTitle}</Overline>
      <None>{intl.formatMessage({ id: 'none' })}</None>
    </PropertyElementListContainer>
  );
};

const renderProperties = (
  propertyElementsInfoList: PropertyElementsProps[] | undefined,
  dataQaidPrefix: string
) => {
  if (dataQaidPrefix !== 'venue' || !propertyElementsInfoList) {
    return null;
  }

  return (
    <PropertyElements
      propertyElements={propertyElementsInfoList[0].propertyElements}
      listTitle={propertyElementsInfoList[0].listTitle}
    />
  );
};

const renderKeyValueInfo = (
  keysAndValuesList: KeyValue[] | undefined,
  dataQaidPrefix: string
) => {
  if (keysAndValuesList && keysAndValuesList.length > 0) {
    return (
      <KeyValueInfoListContainer
        data-qaid={`${dataQaidPrefix}-card-key-value-info`}
      >
        <KeyValueInfoList keysAndValues={keysAndValuesList} />
      </KeyValueInfoListContainer>
    );
  }
  return null;
};

const ListingCard: React.FC<ListingCardProps> = ({
  listingCardTopTag,
  topFlyoverInfo,
  imageStyle,
  imageUrl,
  largeImageUrl,
  title,
  titleTruncateCharLength = 18,
  subtitle,
  subtitleTruncateCharLength = 32,
  subtitleAction,
  topContent,
  tagElementsInfoList,
  propertyElementsInfoList,
  keysAndValuesList,
  controlsInfoList,
  onShowDetails,
  onShowDetailsParams,
  showDetailsTipText,
  dataQaidPrefix,
  modalRef,
  index,
  height,
  loading,
  error,
}) => {
  const listingCardRef = useRef<any>();
  const [viewLargeImageModal, toggleViewLargeImageModal] = useModal();

  return (
    <CardWrapper
      data-qaid={`admin-${dataQaidPrefix}-card`}
      height={height}
      ref={listingCardRef}
    >
      {listingCardTopTag && (
        <TopTagWrapper>
          <ListingCardTopTags tags={[listingCardTopTag]} />
        </TopTagWrapper>
      )}
      <TopInfo
        imageStyle={imageStyle}
        imageUrl={imageUrl}
        toggleViewLargeImageModal={toggleViewLargeImageModal}
        title={title}
        titleTruncateCharLength={titleTruncateCharLength}
        subtitleTruncateCharLength={subtitleTruncateCharLength}
        subtitle={subtitle}
        subtitleAction={subtitleAction}
        loading={loading}
        error={error}
        topFlyoverInfo={topFlyoverInfo}
        dataQaidPrefix={dataQaidPrefix}
      />
      {!loading && (
        <>
          {renderTopContent(topContent)}
          {renderProperties(propertyElementsInfoList, dataQaidPrefix)}
          {renderTagElements(tagElementsInfoList)}
          {renderKeyValueInfo(keysAndValuesList, dataQaidPrefix)}
        </>
      )}
      {controlsInfoList && (
        <>
          <DottedLine />
          <CardControls
            controlsInfoList={controlsInfoList}
            onClickShowDetails={
              onShowDetails &&
              (() => {
                onShowDetails(onShowDetailsParams);
              })
            }
            showDetailsTipText={showDetailsTipText}
            dataQaidPrefix={dataQaidPrefix}
            cardIndex={index}
            listingCardRef={listingCardRef}
            modalRef={modalRef}
          />
        </>
      )}
      {viewLargeImageModal.isShowing && (
        <RoutableModal
          hide={viewLargeImageModal.hide}
          initialRouteProps={{
            largeImageUrl,
          }}
          initialRouteName="large-image"
          dataQaidSuffix="edit-large-image"
        />
      )}
    </CardWrapper>
  );
};

export default ListingCard;
