import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { errorMsgForField } from 'app/shared/utils/form';
import {
  FormGroupContainer,
  FormSectionTitle,
} from 'app/shared/components/atoms/FormContent';
import GenericFormContainer from 'app/shared/components/atoms/GenericFormContainer';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import { Textfield } from 'app/shared/components/atoms/Textfield';
import FormGroup from 'app/shared/components/molecules/FormGroup';
import Select from 'app/shared/components/molecules/SelectManualCSS';
import { HRIS_SYSTEMS } from 'app/admin/utils/users';

interface FormProps {
  formikProps: any;
}

const EmailTextfield = styled(Textfield)`
  ${({ theme }) => css`
    display: block;
    width: 100%;
    ${theme.media.lg`
        width: 48%;
      `};
  `}
`;

const SlackUserIdTextfield = styled(Textfield)`
  ${({ theme }) => css`
    display: block;
    width: 100%;
    ${theme.media.lg`
        width: 30%;
      `};
  `}
`;

const HrisIdTextfield = styled(Textfield)`
  display: block;
  width: 100%;
`;

const InternalUserBasicInfoForm: React.FC<FormProps> = ({ formikProps }) => {
  const intl = useIntl();

  const { setFieldValue, touched, errors, values } = formikProps;

  return (
    <GenericFormContainer dataQaId="internal-user-about-section-edit-form">
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.userBasicInfo.heading.additionalEmail',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="internal-user-additional-email-formgroup"
          label={intl.formatMessage({
            id: 'admin.userBasicInfo.heading.additionalEmail',
          })}
          errorMsg={errorMsgForField('additionalEmail', touched, errors)}
        >
          <EmailTextfield
            data-qaid="internal-user-additional-email-field"
            id="additionalEmail"
            name="additionalEmail"
            value={values.additionalEmail}
            onChange={(e: any) => {
              setFieldValue('additionalEmail', e.target.value);
            }}
            maxLength={100}
          />
        </FormGroup>
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.userBasicInfo.heading.slackUserId',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="internal-user-slack-user-id-formgroup"
          label={intl.formatMessage({
            id: 'admin.userBasicInfo.heading.slackUserId',
          })}
          errorMsg={errorMsgForField('slackUserId', touched, errors)}
        >
          <SlackUserIdTextfield
            data-qaid="internal-user-slack-user-id-field"
            id="slackUserId"
            name="slackUserId"
            value={values.slackUserId}
            onChange={(e: any) => {
              setFieldValue('slackUserId', e.target.value);
            }}
            maxLength={15}
          />
        </FormGroup>
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.userBasicInfo.heading.hrisDetails',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <Grid>
          <Col xs={12} sm={6}>
            <FormGroup
              data-qaid="internal-user-hris-system-formgroup"
              label={intl.formatMessage({
                id: 'admin.userBasicInfo.heading.hrisSystem',
              })}
              errorMsg={errorMsgForField('hrisSystemKey', touched, errors)}
              required={values.hrisId}
            >
              <Select
                data-qaid="internal-user-basic-info-form-hris-system-field"
                name="hris-system"
                id="hris-system"
                onChange={option => {
                  setFieldValue('hrisSystem', option) &&
                    setFieldValue('hrisSystemKey', option.value);
                }}
                options={Object.keys(HRIS_SYSTEMS).map(key => {
                  return { value: key, label: HRIS_SYSTEMS[key] };
                })}
                getOptionLabel={(opt: any) => {
                  return opt.label;
                }}
                hasError={!!errorMsgForField('hrisSystem', touched, errors)}
                placeholder={intl.formatMessage({
                  id: 'admin.userBasicInfo.heading.hrisSystem',
                })}
                defaultValue={values.hrisSystem}
              />
            </FormGroup>
          </Col>
          <Col xs={12} sm={6}>
            <FormGroup
              data-qaid="internal-user-hris-id-formgroup"
              label={intl.formatMessage({
                id: 'admin.userBasicInfo.heading.hrisId',
              })}
              errorMsg={errorMsgForField('hrisId', touched, errors)}
              required={values.hrisSystem.value}
            >
              <HrisIdTextfield
                id="hrisId"
                name="hrisId"
                value={values.hrisId}
                onChange={(e: any) => {
                  setFieldValue('hrisId', e.target.value);
                }}
                data-qaid="internal-user-basic-info-form-hris-id-field"
                maxLength={25}
              />
            </FormGroup>
          </Col>
        </Grid>
      </FormGroupContainer>
    </GenericFormContainer>
  );
};

export default InternalUserBasicInfoForm;
