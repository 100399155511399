import React from 'react';
import { Document } from '@react-pdf/renderer';

import { LandscapePDF } from './LandscapePdf';
import { PortraitPDF } from './PortraitPdf';

interface TodaysEventsPDFProps {
  cityTitle: string;
  citySlug: string;
  isLandscape?: boolean;
}

const DOCUMENT_VERSION = 'cityqr-v1.1-25oct2022';

export const TodaysEventsPDF: React.FC<TodaysEventsPDFProps> = ({
  cityTitle,
  citySlug,
}) => {
  return (
    <Document>
      <PortraitPDF
        cityTitle={cityTitle}
        citySlug={citySlug}
        version={DOCUMENT_VERSION}
        imgixDomain={process.env.IMGIX_DOMAIN}
      />
      <LandscapePDF
        cityTitle={cityTitle}
        citySlug={citySlug}
        version={DOCUMENT_VERSION}
        imgixDomain={process.env.IMGIX_DOMAIN}
      />
    </Document>
  );
};
