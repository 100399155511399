import * as Yup from 'yup';

import { EMAIL_ON_ACID_ADDRESS } from './EmailTestForm';

const EmailTestFormSchema = (intl: any) =>
  Yup.object().shape({
    toEmail: Yup.string()
      .email(
        intl.formatMessage({
          id: 'admin.emailTest.toEmailMustBeValid',
        })
      )
      .required(
        intl.formatMessage({
          id: 'admin.emailTest.toEmailIsRequired',
        })
      )
      .test(
        'toEmail',
        intl.formatMessage({
          id: 'admin.emailTest.onlySofarSoundsEmails',
        }),
        function(value) {
          const regex = /\S+@sofarsounds.com$/g;
          return value === EMAIL_ON_ACID_ADDRESS || regex.test(value);
        }
      ),
    useCase: Yup.string().required(
      intl.formatMessage({
        id: 'admin.emailTest.useCaseIsRequired',
      })
    ),
  });

export default EmailTestFormSchema;
