import styled, { css } from 'styled-components';

import {
  withFlex,
  WithFlexProps,
} from 'app/shared/components/styleUtils/manualCSS/withFlex';

// TODO do we need different sizes per container?
// i.e. "small" or "large" container in addition to the default
// max-width?
interface ContainerProps extends WithFlexProps {
  size?: string;
}

const withContainerStyle = () => css`
  ${({ theme }) => css`
    width: 100%;
    margin: 0 auto;
    padding: ${theme.dimensions.container.padding};

    ${theme.media.sm`
      max-width: ${theme.dimensions.container.maxWidthSm};
      padding: ${theme.dimensions.container.paddingSm};
    `}

    ${theme.media.md`
      max-width: ${theme.dimensions.container.maxWidthMd};
    `}

    ${theme.media.lg`
      max-width: ${theme.dimensions.container.maxWidthLg};
    `}

    ${theme.media.xl`
      max-width: ${theme.dimensions.container.maxWidthXl};
    `}
  `}
`;

export const Container = styled.div<ContainerProps>`
  ${withContainerStyle};
  ${withFlex};
`;
