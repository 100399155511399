import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { MainImage } from 'app/typings/artists';
import { useAnalyticsContext } from 'app/shared/utils';
import { upperFirstLeaveRestUnchanged } from 'app/shared/utils/string';
import useModal from 'app/shared/utils/useModal';
import { UpdateImagesForRefile } from 'app/shared/graphql/images/mutationHooks';
import { NotifyContext } from 'app/shared/context/Notify';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import ConfirmationModal from 'app/shared/components/molecules/ConfirmationModal';
import IconAndTextLink from 'app/shared/components/molecules/IconAndTextLink';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import ImageUploaderForm from 'app/shared/components/organisms/ImageUploaderForm/ImageUploaderForm';
import ImageUploaderFormSchema from 'app/shared/components/organisms/ImageUploaderForm/ImageUploaderFormSchema';
import { ReactComponent as Bin } from 'icons/streamline-regular/interface-essential/delete/bin.svg';

interface Props {
  objectType: string;
  objectId: number;
  imageFieldName: string;
  imageFieldDescription: string;
  imageUrl: string;
  cropperRatio: any;
  onUploadSuccess: (response?: any) => void;
  onDeleteSuccess: (response?: any) => void;
  defaultImageTextRegex?: string;
  renderHeaderContent?: (headerActionLinksInfo?: any) => JSX.Element;
  headerTitle: string;
  headerSubtitle?: string;
  setFormSubmitAction: (func: Function) => void;
  setDisplayConfirmation: (set: boolean) => void;
  setIsSubmitting: (set: boolean) => void;
  setDisplaySubmitButton: (set: boolean) => void;
  dataQaidPrefix: string;
}

const ImageEdit: React.FC<Props> = ({
  objectType,
  objectId,
  imageFieldName,
  imageFieldDescription,
  imageUrl,
  cropperRatio,
  onUploadSuccess,
  onDeleteSuccess,
  defaultImageTextRegex,
  renderHeaderContent,
  headerTitle,
  headerSubtitle,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  setDisplaySubmitButton,
  dataQaidPrefix,
}) => {
  const intl = useIntl();
  const notifyContext = useContext(NotifyContext);
  const { trackAnalyticsEvent } = useAnalyticsContext();

  const [cropAction, setCropAction] = useState<
    () => Promise<Record<string, any>>
  >(
    () =>
      async function() {
        return {};
      }
  );
  const [displayDeleteIcon, setDisplayDeleteIcon] = useState<boolean>(true);
  const [isDeletingCurrentImage, setIsDeletingCurrentImage] = useState<boolean>(
    false
  );
  const [
    deleteImageConfirmationModal,
    toggleDeleteImageConfirmationModal,
  ] = useModal();

  const deleteCurrentImage = async () => {
    setIsDeletingCurrentImage(true);
    const response = await UpdateImagesForRefile({
      imageableObjectType: objectType,
      imageableObjectId: objectId,
      refileImage: {
        contentType: '',
        filename: '',
        id: '',
        size: 0,
      },
      refileKey: imageFieldName,
    });
    if (response.data) {
      setIsDeletingCurrentImage(false);
      notifyContext.addMessage(
        intl.formatMessage(
          {
            id: 'admin.imageEdit.currentImageDeletionSuccessMessage',
          },
          {
            imageFieldDescription,
          }
        )
      );
      onDeleteSuccess && onDeleteSuccess(response);
    } else {
      trackAnalyticsEvent('Delete Image Failure', {
        imageableObjectType: objectType,
        imageableObjectId: objectId,
        error: response,
      });
      return notifyContext.addMessage(
        intl.formatMessage(
          {
            id: 'admin.imageEdit.currentImageDeletionErrorMessage',
          },
          {
            imageFieldDescription,
          }
        )
      );
    }
  };

  const formInitialValues = {
    [imageFieldName]: imageUrl,
  };

  useEffect(() => {
    const isDefaultImage = (imageUrl: string) => {
      if (defaultImageTextRegex) {
        const regex = RegExp(defaultImageTextRegex);
        return regex.test(imageUrl);
      } else {
        return false;
      }
    };
    if (!imageUrl || isDefaultImage(imageUrl)) {
      setDisplayDeleteIcon(false);
    }
  }, [imageUrl, formInitialValues, defaultImageTextRegex]);

  const subheaderText = () => (
    <IconAndTextLink
      icon={Bin}
      text={intl.formatMessage({
        id: 'admin.imageEdit.header.deleteCurrentImage',
      })}
    />
  );

  const headerActionLinksInfo = () => {
    return {
      link1: {
        text: subheaderText(),
        active: true,
        onClickAction: toggleDeleteImageConfirmationModal,
      },
    };
  };

  return (
    <>
      <ModalContentContainer>
        {renderHeaderContent &&
          renderHeaderContent(
            displayDeleteIcon ? headerActionLinksInfo() : undefined
          )}
        <GenericForm
          formInitialValues={formInitialValues}
          renderFormComponent={(renderProps: any) => {
            return (
              <ImageUploaderForm
                formikProps={renderProps.formikProps}
                setDisplaySubmitButton={setDisplaySubmitButton}
                title={headerTitle}
                subtitle={headerSubtitle}
                cropperRatio={cropperRatio}
                dataQaidPrefix={dataQaidPrefix}
                // @ts-ignore
                setCropAction={setCropAction}
              />
            );
          }}
          onSubmit={async () => {
            try {
              const savedImage: MainImage = await cropAction();
              if (savedImage) {
                const response = await UpdateImagesForRefile({
                  imageableObjectType: objectType,
                  imageableObjectId: objectId,
                  refileImage: {
                    contentType: savedImage.contentType,
                    filename: savedImage.filename,
                    id: savedImage.id,
                    size: savedImage.size,
                  },
                  refileKey: imageFieldName,
                });
                if (response.data) {
                  notifyContext.addMessage(
                    intl.formatMessage(
                      {
                        id: 'admin.imageEdit.successMessage',
                      },
                      {
                        imageFieldDescription: upperFirstLeaveRestUnchanged(
                          imageFieldDescription
                        ),
                      }
                    )
                  );
                  onUploadSuccess && onUploadSuccess(response);
                } else {
                  trackAnalyticsEvent('Save Image Failure', {
                    imageableObjectType: objectType,
                    imageableObjectId: objectId,
                    refileImage: {
                      contentType: savedImage.contentType,
                      filename: savedImage.filename,
                      id: savedImage.id,
                      size: savedImage.size,
                    },
                    imageFieldDescription,
                    error: response,
                  });
                  return notifyContext.addMessage(
                    intl.formatMessage(
                      {
                        id: 'admin.imageEdit.errorMessage',
                      },
                      {
                        imageFieldDescription,
                      }
                    )
                  );
                }
              }
            } catch (e) {
              trackAnalyticsEvent('Save Image Failure', {
                imageableObjectType: objectType,
                imageableObjectId: objectId,
                imageFieldDescription,
                error: e,
              });
              notifyContext.addMessage(
                intl.formatMessage(
                  {
                    id: 'admin.imageEdit.errorMessageWithDescription',
                  },
                  {
                    imageFieldDescription,
                  }
                )
              );
            }
          }}
          formSchema={ImageUploaderFormSchema(intl)}
          setFormSubmitAction={setFormSubmitAction}
          setDisplayConfirmation={setDisplayConfirmation}
          setIsSubmitting={setIsSubmitting}
          dataQaId={`${dataQaidPrefix}-upload-form`}
        />
      </ModalContentContainer>
      {deleteImageConfirmationModal.isShowing && (
        <ConfirmationModal
          onCancel={() => deleteImageConfirmationModal.hide()}
          description={intl.formatMessage({
            id: 'imageHeader.currentImage.deletionMessage',
          })}
          onConfirm={deleteCurrentImage}
          isLoading={isDeletingCurrentImage}
          confirmationButtonText={intl.formatMessage({
            id: 'imageHeader.currentImage.delete',
          })}
        />
      )}
    </>
  );
};

export default ImageEdit;
