import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { City, Event, Property } from 'app/typings';
import { UpdateEventCollectionData } from 'app/typings/eventCollections';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { Title } from 'app/shared/components/atoms/ModalTitle';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { UpdateCollection } from 'app/admin/graphql/eventCollections/mutationHooks';
import { DetailsFormMainContainer } from 'app/admin/components/atoms/DetailContent';

import {
  CollectionBasicInfoForm,
  CollectionBasicInfoFormSchema,
} from './CollectionBasicInfoForm';

interface Props {
  contentProps: any;
  setFormSubmitAction: (func: Function) => void;
  setDisplayConfirmation: (set: boolean) => void;
  setIsSubmitting: (set: boolean) => void;
  navigateTo: (routeData: object) => void;
}

const getMerchandisingProperties = (merchandisingProperties?: any[]) =>
  merchandisingProperties?.reduce(
    (obj: any, property: Property) => ({
      ...obj,
      [property.propertyGroupName || '']: property.id,
    }),
    {}
  ) || {};

const getCityDisplayType = (eventCollectionCities: any[]) => {
  if (
    (eventCollectionCities || []).length === 0 ||
    (eventCollectionCities || []).every(
      (eventCollectionCity: any) => !eventCollectionCity.position
    )
  ) {
    return 'no_cities';
  } else if (
    (eventCollectionCities || []).every(
      (eventCollectionCity: any) => !!eventCollectionCity.position
    )
  ) {
    return 'all_cities';
  } else {
    return 'selected_cities';
  }
};

const getSelectedCities = (eventCollectionCities: any[]) =>
  (eventCollectionCities || [])
    .filter((eventCollectionCity: any) => !!eventCollectionCity.position)
    .map((eventCollectionCity: any) => eventCollectionCity.city);

const CollectionBasicInfoEdit: React.FC<Props> = ({
  contentProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  navigateTo,
}) => {
  const intl = useIntl();
  const [validationErrors, setValidationErrors] = useState<object>({});

  const formInitialValues = {
    isNewCollection: false,
    title: contentProps.title,
    cachedSlug: contentProps.cachedSlug || '',
    description: contentProps.description || '',
    eventHeadline: contentProps.eventHeadline || '',
    eventDescription: contentProps.eventDescription || '',
    merchandisingProperties: getMerchandisingProperties(
      contentProps.merchandisingProperties
    ),
    whatToExpect: contentProps.whatToExpect || '',
    events: contentProps.events || [],
    status: contentProps.status || '',
    cityDisplayType:
      contentProps.cityDisplayType ||
      getCityDisplayType(contentProps.eventCollectionCities),
    selectedCities: getSelectedCities(contentProps.eventCollectionCities),
  };

  const updateCollectionAction = UpdateCollection();

  const handleUpdateCollection = useSubmitAction({
    submitAction: updateCollectionAction,
    submitVariables: (values: any) => ({
      eventCollectionId: contentProps.id,
      title: values.title,
      cachedSlug: values.cachedSlug,
      description: values.description,
      eventHeadline: values.eventHeadline,
      eventDescription: values.eventDescription,
      merchandisingProperties: Object.values(
        values.merchandisingProperties
      ).filter(n => n),
      whatToExpect: values.whatToExpect,
      eventIds: values.events.map((event: Event) => event.id),
      cityDisplayType: values.cityDisplayType,
      selectedCityIds: values.selectedCities.map((city: City) => city.id),
      status: values.status,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.collectionBasicInfo.form.edit.successMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.collectionBasicInfo.form.edit.failureMessage',
    }),
    onSuccess: ({ data }: { data: UpdateEventCollectionData }) => {
      navigateTo({
        routeName: 'event-collection-details',
        routeProps: {
          id: data.updateEventCollection.eventCollection.id,
        },
      });
    },
    onValidationError: validationErrors =>
      setValidationErrors(validationErrors),
  });

  return (
    <ModalContentContainer>
      <DetailsFormMainContainer>
        <Title>
          {intl.formatMessage({
            id: 'admin.collectionBasicInfo.form.edit.title',
          })}
        </Title>
        <GenericForm
          formInitialValues={formInitialValues}
          renderFormComponent={(renderProps: any) => (
            <CollectionBasicInfoForm
              formikProps={renderProps.formikProps}
              contentProps={contentProps}
              validationErrors={validationErrors}
            />
          )}
          onSubmit={handleUpdateCollection}
          formSchema={CollectionBasicInfoFormSchema(intl)}
          setFormSubmitAction={setFormSubmitAction}
          setDisplayConfirmation={setDisplayConfirmation}
          setIsSubmitting={setIsSubmitting}
          dataQaId="collection-update-form"
        />
      </DetailsFormMainContainer>
    </ModalContentContainer>
  );
};

export default CollectionBasicInfoEdit;
