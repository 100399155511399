import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { EmailMessageAssociatedObject } from 'app/typings/emailMessages';
import { KeyValue } from 'app/typings/generics';
import { User } from 'app/typings/users';
import { useCurrentTheme } from 'app/shared/theme';
import { dateFormatter } from 'app/shared/utils/datetime';
import useModal from 'app/shared/utils/useModal';
import usePermission from 'app/shared/utils/usePermission';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import { StreamlineIcon } from 'app/shared/components/atoms/StreamlineIcon';
import {
  Body2,
  Overline,
} from 'app/shared/components/atoms/TypographyManualCSS';
import ConfirmationModal from 'app/shared/components/molecules/ConfirmationModal';
import { emailMessageStatusLabels } from 'app/admin/utils/emails';
import { ResendEmail } from 'app/admin/graphql/emailMessages/mutationHooks';
import { KeyAboveValueInfoList } from 'app/admin/components/molecules/KeyAboveValueInfoList';
import ListingCard from 'app/admin/components/organisms/ListingCard';
import { ReactComponent as ArrowThickRight1 } from 'icons/streamline-regular/arrows-diagrams/arrows/arrow-thick-right-1.svg';
import { ReactComponent as Check2 } from 'icons/streamline-regular/interface-essential/form-validation/check-2.svg';
import { ReactComponent as RemoveSquare } from 'icons/streamline-regular/interface-essential/form-validation/remove-square.svg';
import { ReactComponent as View1 } from 'icons/streamline-regular/interface-essential/view/view-1.svg';

interface EmailMessageCardProps {
  id: number;
  index: number;
  emailName: string;
  subject: string;
  recipient: string;
  sender: string;
  hasContent?: boolean;
  status: string;
  numOpens: number;
  numClicks: number;
  messagable: EmailMessageAssociatedObject;
  domainObject: EmailMessageAssociatedObject;
  sentBy: User;
  createdAt: string;
  onViewEmail: (emailMessageData: any) => void;
  refetchEmailMessages: VoidFunction;
}

const TopContentContainer = styled.div`
  height: 575px;
`;

const DateKeyValueContainer = styled.div`
  height: 67px;
`;

const DateValueContainer = styled(Body2)`
  font-size: 12px !important;
  color: #111111;
  background: #f5f5f5;
`;

const StatusKeyValueContainer = styled.div`
  height: 50px;
`;

const StatusWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 4px;
`;

const StatusValueContainer = styled(Body2)`
  font-size: 12px !important;
  color: #111111;
  background: #f5f5f5;
  margin-left: 7px;
`;

const getAssociatedObjectKey = (
  messagable: EmailMessageAssociatedObject,
  domainObject: EmailMessageAssociatedObject
) => {
  if (messagable && messagable.objectType == 'Artist') {
    return 'Artist';
  } else if (messagable && messagable.objectType == 'Venue') {
    return 'Venue';
  } else if (messagable && messagable.objectType == 'User') {
    return 'User';
  } else if (domainObject && domainObject.objectType == 'Event') {
    return 'Concert';
  } else {
    return ' ';
  }
};

const getAssociatedObjectValue = (
  messagable: EmailMessageAssociatedObject,
  domainObject: EmailMessageAssociatedObject
) => {
  if (messagable && messagable.objectType == 'Artist') {
    return messagable.name;
  } else if (messagable && messagable.objectType == 'Venue') {
    return messagable.name;
  } else if (messagable && messagable.objectType == 'User') {
    return messagable.name;
  } else if (domainObject && domainObject.objectType == 'Event') {
    return domainObject.name;
  } else {
    return ' ';
  }
};

const getAssociatedObjectUrl = (
  messagable: EmailMessageAssociatedObject,
  domainObject: EmailMessageAssociatedObject,
  hasViewArtistsPermission: boolean,
  hasViewVenuesPermission: boolean,
  hasViewUsersPermission: boolean,
  hasViewEventsPermission: boolean
) => {
  if (messagable && messagable.objectType == 'Artist') {
    return hasViewArtistsPermission
      ? `/admin/artists?artist_id=${messagable.id}`
      : undefined;
  } else if (messagable && messagable.objectType == 'Venue') {
    return hasViewVenuesPermission
      ? `/admin/venues?venue_id=${messagable.id}`
      : undefined;
  } else if (messagable && messagable.objectType == 'User') {
    return hasViewUsersPermission
      ? `/admin/users?user_id=${messagable.id}`
      : undefined;
  } else if (domainObject && domainObject.objectType == 'Event') {
    return hasViewEventsPermission
      ? `/admin/concert-planner?event_id=${domainObject.id}`
      : undefined;
  } else {
    return undefined;
  }
};

const renderTopContent = (
  keysAboveValuesList: KeyValue[],
  domainObject: EmailMessageAssociatedObject,
  createdAt: string,
  status: string,
  intl: any,
  theme: any
) => {
  const dateSent = dateFormatter(
    createdAt,
    'shortMonthDateAndYear',
    domainObject && domainObject.timezone ? domainObject.timezone : undefined
  );
  const timeSent = dateFormatter(
    createdAt,
    'h:mm a zzz',
    domainObject && domainObject.timezone ? domainObject.timezone : undefined
  );

  const statusIcon = status == 'sent' ? Check2 : RemoveSquare;
  const statusColor =
    status == 'sent' ? theme.colors.green600 : theme.colors.redRedWine;
  return (
    <TopContentContainer>
      <DateKeyValueContainer>
        <Overline>
          {intl.formatMessage({
            id: 'admin.emailMessages.listingCard.dateSent',
          })}
        </Overline>
        <DateValueContainer>
          {dateSent}
          <br />
          {timeSent}
        </DateValueContainer>
      </DateKeyValueContainer>
      <KeyAboveValueInfoList keysAndValues={keysAboveValuesList} />
      <StatusKeyValueContainer>
        <Overline>
          {intl.formatMessage({
            id: 'shared.status',
          })}
        </Overline>
        <StatusWrapper>
          <StreamlineIcon icon={statusIcon} size={16} stroke={statusColor} />
          <StatusValueContainer>
            {emailMessageStatusLabels[status]}
          </StatusValueContainer>
        </StatusWrapper>
      </StatusKeyValueContainer>
    </TopContentContainer>
  );
};

const isTestEmail = (subject: string) => RegExp(/^TEST: .+$/).test(subject);

const EmailMessageCard: React.FC<EmailMessageCardProps> = ({
  id,
  index,
  emailName,
  subject,
  recipient,
  sender,
  hasContent,
  status,
  numOpens,
  numClicks,
  messagable,
  domainObject,
  createdAt,
  onViewEmail,
  refetchEmailMessages,
}) => {
  const intl = useIntl();
  const theme = useCurrentTheme();
  const [resendModal, toggleResendModal] = useModal();

  const hasViewArtistsPermission = usePermission('artist.list.view');
  const hasViewVenuesPermission = usePermission('venue.list.view');
  const hasViewUsersPermission = usePermission('user.list.access');
  const hasViewEventsPermission = usePermission('event.list.view');

  const associatedObjectKey = getAssociatedObjectKey(messagable, domainObject);
  const associatedObjectValue = getAssociatedObjectValue(
    messagable,
    domainObject
  );
  const associatedObjectUrl = getAssociatedObjectUrl(
    messagable,
    domainObject,
    hasViewArtistsPermission,
    hasViewVenuesPermission,
    hasViewUsersPermission,
    hasViewEventsPermission
  );

  const keysAboveValuesList = [
    {
      key: intl.formatMessage({
        id: 'admin.emailMessages.listingCard.sentTo',
      }),
      value: recipient,
    },
    {
      key: associatedObjectKey,
      value: associatedObjectValue,
      url: associatedObjectUrl,
    },
    {
      key: intl.formatMessage({
        id: 'admin.emailMessages.listingCard.sentFrom',
      }),
      value: sender,
    },
    {
      key: intl.formatMessage({
        id: 'admin.shared.emails.subject',
      }),
      value: subject,
      wrapValue: true,
      valueTruncateCharLength: 55,
    },
    {
      key: intl.formatMessage({
        id: 'admin.emailMessages.listingCard.opens',
      }),
      value: numOpens.toString(),
    },
    {
      key: intl.formatMessage({
        id: 'admin.emailMessages.listingCard.clicks',
      }),
      value: numClicks.toString(),
    },
  ];

  const controlsInfoList = [
    {
      action: hasContent
        ? () => {
            onViewEmail({ id });
          }
        : undefined,
      icon: View1,
      tipText: intl.formatMessage({
        id: hasContent
          ? 'admin.emailMessages.listingCard.view'
          : 'admin.emailMessages.listingCard.viewNotAvailable',
      }),
    },
    {
      // Test emails sent by the email test tool use fake data generated on the fly but
      // not actually saved in the db, so there are no persistent messagable or domain_object
      // records to load when retrieving the saved email_message to resend, so we can't
      // resend the email (though we CAN view the email, since the entire raw content
      // was saved in the email_message)
      action:
        hasContent && !isTestEmail(subject)
          ? () => {
              toggleResendModal();
            }
          : undefined,
      icon: ArrowThickRight1,
      tipText: intl.formatMessage({
        id:
          hasContent && !isTestEmail(subject)
            ? 'admin.emailMessages.listingCard.resend'
            : 'admin.emailMessages.listingCard.resendNotAvailable',
      }),
    },
  ];

  const resendEmailAction = ResendEmail();

  const handleResendEmail = useSubmitAction({
    submitAction: resendEmailAction,
    submitVariables: () => ({ emailMessageId: id }),
    successMsg: intl.formatMessage(
      {
        id: 'admin.emailMessages.listingCard.resendSuccessMessage',
      },
      {
        recipient,
      }
    ),
    failureMsg: intl.formatMessage({
      id: 'admin.emailMessages.listingCard.resendFailureMessage',
    }),
    onSuccess: () => {
      resendModal.hide();
      refetchEmailMessages();
    },
  });

  return (
    <>
      <ListingCard
        title={emailName}
        topContent={() =>
          renderTopContent(
            keysAboveValuesList,
            domainObject,
            createdAt,
            status,
            intl,
            theme
          )
        }
        dataQaidPrefix="email-messages"
        index={index}
        height="585px"
        controlsInfoList={controlsInfoList}
      />
      {resendModal.isShowing && (
        <ConfirmationModal
          onCancel={() => resendModal.hide()}
          confirmationButtonText={intl.formatMessage({
            id: 'admin.emailMessages.listingCard.send',
          })}
          description={intl.formatMessage(
            {
              id: 'admin.emailMessages.listingCard.resendEmailConfirmation',
            },
            {
              recipient,
            }
          )}
          onConfirm={handleResendEmail}
        />
      )}
    </>
  );
};

export default EmailMessageCard;
