import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { EventCollection } from 'app/typings/eventCollections';
import { dateFormatter, getDateParts } from 'app/shared/utils/datetime';
import TruncatedByCharText from 'app/shared/components/atoms/TruncatedByCharText';
import {
  Body2,
  H2,
  Overline,
} from 'app/shared/components/atoms/TypographyManualCSS';
import { eventTypeOptions } from 'app/admin/utils/events';

interface DateProps {
  show?: boolean;
  localStartsAt?: string;
  displayCalendar?: boolean;
  greyedOut?: boolean;
  displayStyle?: string;
}

interface DateContentProps {
  displayCalendar?: boolean;
  displayStyle?: string;
}

interface DayProps {
  displayCalendar?: boolean;
  displayStyle?: string;
  color?: string;
}

interface LocationProps {
  show?: boolean;
  cityTitle?: string;
  neighborhoodTitle?: string;
  color?: string;
}

interface EventTypeProps {
  show?: boolean;
  type?: string;
  color?: string;
}

interface EventCollectionsProps {
  show?: boolean;
  eventCollections?: EventCollection[];
  color?: string;
}

interface EventIdProps {
  show?: boolean;
  eventId?: number;
}

const VerticalCenteredText = styled.div`
  font-size: 14px;
  font-style: italic;
`;

const DateContent = styled.div<DateContentProps>`
  ${({ theme, displayCalendar, displayStyle }) => css`
    background-color: ${
      displayCalendar && displayStyle !== 'white' ? '#34495E' : 'none'
    };
    display: grid;
    color: ${
      displayCalendar ? (displayStyle === 'white' ? 'black' : 'white') : 'none'
    };
    height: ${displayCalendar ? '125px' : 'none'};
    padding: ${
      displayCalendar
        ? displayStyle === 'white'
          ? '15px 0px'
          : '15px'
        : 'none'
    };
    border-radius: ${displayCalendar ? '5px' : 'none'};
    width: ${displayCalendar ? '125px' : 'none'};

    ${theme.media.xs`
      height: ${displayCalendar ? '97px' : 'none'};
      padding: ${
        displayCalendar
          ? displayStyle === 'white'
            ? '8px 0px'
            : '8px 8px 8px 10px'
          : 'none'
      };
      border-radius: ${displayCalendar ? '10px' : 'none'};
      width: ${displayCalendar ? '97px' : 'none'};
    `}
    ${theme.media.sm`
      height: ${displayCalendar ? '125px' : 'none'};
      padding: ${
        displayCalendar
          ? displayStyle === 'white'
            ? '15px 0px'
            : '15px'
          : 'none'
      };
      border-radius: ${displayCalendar ? '5px' : 'none'};
      width: ${displayCalendar ? '125px' : 'none'};
    `}
    ${theme.media.md`
      height: ${displayCalendar ? '125px' : 'none'};
      padding: ${
        displayCalendar
          ? displayStyle === 'white'
            ? '15px 0px'
            : '15px'
          : 'none'
      };
      border-radius: ${displayCalendar ? '5px' : 'none'};
      width: ${displayCalendar ? '125px' : 'none'};
    `}
    ${theme.media.lg`
      height: ${displayCalendar ? '125px' : 'none'};
      padding: ${
        displayCalendar
          ? displayStyle === 'white'
            ? '15px 0px'
            : '15px'
          : 'none'
      };
      border-radius: ${displayCalendar ? '5px' : 'none'};
      width: ${displayCalendar ? '125px' : 'none'};
    `}
    ${theme.media.xl`
      height: ${displayCalendar ? '125px' : 'none'};
      padding: ${
        displayCalendar
          ? displayStyle === 'white'
            ? '15px 0px'
            : '15px'
          : 'none'
      };
      border-radius: ${displayCalendar ? '5px' : 'none'};
      width: ${displayCalendar ? '125px' : 'none'};
    `}
  `}
`;

const LocationContent = styled.div`
  height: 50px;
`;

const DatePart = styled(Overline)<{
  displayCalendar: boolean;
  displayStyle?: string;
}>`
  ${({ theme, displayCalendar, color, displayStyle }) => css`
    padding-top: 0px;
    width: 100%;
    color: ${displayCalendar && displayStyle !== 'white'
      ? theme.colors.whiteDenim
      : theme.colors.backToBlack};
    ${color &&
      css`
        color: ${theme.colors[color]};
      `}
  `}
`;

const Day = styled(H2)<DayProps>`
  ${({ theme, displayCalendar, color, displayStyle }) => css`
    color: ${
      displayCalendar && displayStyle !== 'white'
        ? theme.colors.whiteDenim
        : theme.colors.backToBlack
    };
    ${color &&
      css`
        color: ${theme.colors[color]};
      `}
    ${theme.media.xs`
      margin-top: -10px;
      margin-bottom: -9px;
      font-size: 42px;
    `}
    ${theme.media.sm`
      margin-top: -4px;
      margin-bottom: -4px;
    `}
    ${theme.media.md`
      margin-top: -4px;
      margin-bottom: -4px;
    `}
    ${theme.media.lg`
      margin-bottom: ${displayCalendar ? '-20px' : '-7px'};
      margin-top: ${displayCalendar ? '-20px' : '-10px'};
    `}
  `}
`;

const Month = styled.div``;

const Year = styled.div`
  margin-top: -2px;
`;

const City = styled(Body2)`
  font-weight: 700;
  letter-spacing: 0.5px;
  margin-top: 10px;
`;

const Neighborhood = styled(Body2)`
  letter-spacing: 0.5px;
  margin-bottom: 10px;
`;

const EventTypeValue = styled(Body2)<any>`
  ${({ color }) => css`
    letter-spacing: 0.5px;
    ${color && `color: ${color};`}
  `}
`;

const EventCollectionsValue = styled(Body2)<any>`
  ${({ color }) => css`
    letter-spacing: 0.5px;
    ${color && `color: ${color};`}
  `}
`;

const EventIdValue = styled(Body2)`
  letter-spacing: 0.5px;
`;

const EventIdWrapper = styled.div``;

export const AdminListingCard = styled.div<any>`
  ${({ theme, color }) => css`
    width: 100%;
    max-width: 240px;
    margin: 0;
    position: relative;
    background-color: ${color || theme.colors.silverSprings};
    border-radius: 10px;
    padding: ${theme.ruler[4]}px;
    display: inline-block;
  `}
`;

export const EventDate: React.FC<DateProps> = ({
  show = true,
  localStartsAt,
  displayCalendar = false,
  greyedOut = false,
  displayStyle,
}) => {
  if (!show || !localStartsAt) {
    return null;
  }
  const { weekday, month, day, year } = getDateParts(localStartsAt);

  return (
    <DateContent
      data-qaid="admin-event-card-date"
      displayCalendar={displayCalendar}
      displayStyle={displayStyle}
    >
      <DatePart
        color={greyedOut ? 'blueSmoke' : undefined}
        displayCalendar={displayCalendar}
        displayStyle={displayStyle}
      >
        {weekday}
      </DatePart>
      <Day
        color={greyedOut ? 'blueSmoke' : undefined}
        displayCalendar={greyedOut ? undefined : displayCalendar}
        displayStyle={displayStyle}
      >
        {day}
      </Day>
      <DatePart
        color={greyedOut ? 'blueSmoke' : undefined}
        displayCalendar={displayCalendar}
        displayStyle={displayStyle}
      >
        <Month>{month}</Month>
        <Year>{year}</Year>
      </DatePart>
    </DateContent>
  );
};

export const EventLocation: React.FC<LocationProps> = ({
  show = true,
  cityTitle,
  neighborhoodTitle,
  color = undefined,
}) => {
  if (!show) {
    return null;
  }
  return (
    <>
      <LocationContent data-qaid="admin-event-card-location">
        <City color={color}>
          <TruncatedByCharText text={cityTitle || null} truncateLength={18} />
        </City>
        <Neighborhood color={color}>
          <TruncatedByCharText
            text={neighborhoodTitle || null}
            truncateLength={18}
          />
        </Neighborhood>
      </LocationContent>
    </>
  );
};

export const EventType: React.FC<EventTypeProps> = ({
  show = true,
  type,
  color = undefined,
}) => {
  const intl = useIntl();
  if (!show) {
    return null;
  }
  const typeToDisplay = eventTypeOptions(intl).find(option => {
    return option.value === type;
  });
  return (
    <>
      <div data-qaid="admin-event-card-event-type-text">
        <Overline color={color}>
          {intl.formatMessage({
            id: 'admin.eventPlanner.showTypeHeader',
          })}
        </Overline>
        <EventTypeValue color={color}>
          {typeToDisplay
            ? typeToDisplay.titleShort || typeToDisplay.title
            : type}
        </EventTypeValue>
      </div>
    </>
  );
};

export const EventCollections: React.FC<EventCollectionsProps> = ({
  show = true,
  eventCollections,
  color = undefined,
}) => {
  const intl = useIntl();
  if (!show) {
    return null;
  }
  const eventCollectionsStr =
    eventCollections &&
    eventCollections.length > 0 &&
    eventCollections
      .sort((a: EventCollection, b: EventCollection) => {
        const collectionA = new Date(a.createdAt || new Date()).getTime();
        const collectionB = new Date(b.createdAt || new Date()).getTime();
        return collectionB - collectionA;
      })
      .map((eventCollection: EventCollection) => eventCollection.title)
      .join(', ');
  return (
    <>
      <div data-qaid="admin-event-card-event-collections-text">
        <Overline color={color}>
          {intl.formatMessage({
            id: 'admin.eventPlanner.collections',
          })}
        </Overline>
        <EventCollectionsValue color={color}>
          {eventCollectionsStr ? (
            <TruncatedByCharText
              text={eventCollectionsStr}
              truncateLength={18}
            />
          ) : (
            <>
              {intl.formatMessage({
                id: 'none',
              })}
            </>
          )}
        </EventCollectionsValue>
      </div>
    </>
  );
};

export const EventId: React.FC<EventIdProps> = ({ show = true, eventId }) => {
  if (!show) {
    return null;
  }
  return (
    <>
      <EventIdWrapper>
        <Overline>Concert ID</Overline>
        <EventIdValue>{eventId}</EventIdValue>
      </EventIdWrapper>
    </>
  );
};

export const renderPriceHelperText = (intl: any, values: any) => {
  let cityPriceDescription;

  if (values.cityHasTieredPrice) {
    cityPriceDescription = intl.formatMessage(
      {
        id: 'admin.shared.cityTieredPricing',
      },
      {
        date: dateFormatter(values.dayOfWeek, 'longWeekday'),
        city: values.cityTitle,
        currencySymbol: values.currencySymbol,
        price: values.cityTicketPrice,
      }
    );
  } else {
    cityPriceDescription = intl.formatMessage(
      {
        id: 'admin.shared.fixedPricing',
      },
      {
        city: values.cityTitle,
        currencySymbol: values.currencySymbol,
        price: values.cityTicketPrice,
      }
    );
  }

  return (
    <VerticalCenteredText>
      {intl.formatMessage({
        id: 'admin.shared.ticketPriceCopy',
      })}
      <br />
      {cityPriceDescription}
    </VerticalCenteredText>
  );
};

export const detailsHeaderSummaryInfoList = (
  eventType: string,
  eventAttendeeGuestsConfirmedCount: number,
  remainingSpaces: number,
  intl: any
) => {
  const typeToDisplay = eventTypeOptions(intl).find(option => {
    return option.value === eventType;
  });

  return [
    {
      backgroundColor: '#ebedef',
      mainInfo:
        typeToDisplay &&
        typeToDisplay.shortName &&
        typeToDisplay.shortName.toString(),
      description: 'Show Type',
    },
    {
      backgroundColor: '#ebf5fb',
      mainInfo:
        eventAttendeeGuestsConfirmedCount &&
        eventAttendeeGuestsConfirmedCount.toString(),
      description: 'Tickets Sold',
    },
    {
      backgroundColor: '#f3f9ed',
      mainInfo: remainingSpaces && remainingSpaces.toString(),
      description: 'Tickets Remaining',
    },
  ];
};
