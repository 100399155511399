import * as Yup from 'yup';

import { isEmpty } from 'app/shared/utils/object';

const crewUnavailabilityDatesFormSchema = (intl: any) =>
  Yup.object().shape({
    dates: Yup.array()
      .of(Yup.string())
      .test(
        'dates',
        intl.formatMessage({
          id: 'form.selectAtLeastOneDate',
        }),
        function(arr) {
          return !isEmpty(arr);
        }
      ),
  });

export default crewUnavailabilityDatesFormSchema;
