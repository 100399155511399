import gql from 'graphql-tag';

export const UPDATE_IMAGES = gql`
  mutation updateImages(
    $imageableObjectId: Int!
    $imageableObjectType: String!
    $images: [ObjectImageInput!]
  ) {
    updateImages(
      imageableObjectId: $imageableObjectId
      imageableObjectType: $imageableObjectType
      images: $images
    ) {
      success
    }
  }
`;

export const UPDATE_IMAGES_FOR_REFILE = gql`
  mutation updateImages(
    $imageableObjectId: Int!
    $imageableObjectType: String!
    $refileImage: RefileImageInput
    $refileKey: String
  ) {
    updateImages(
      imageableObjectId: $imageableObjectId
      imageableObjectType: $imageableObjectType
      refileImage: $refileImage
      refileKey: $refileKey
    ) {
      success
      artist {
        id
        title
        imageUrl
        largeImageUrl
        upcomingEventsCount
        pastEventsCount
        citiesPlayedCount
        lastEventAt
      }
      competition {
        id
        title
        status
        backgroundImageUrl
        logoUrl
        moreInfoImageUrl
        shareImageUrl
      }
      venue {
        id
        imageUrl
        largeImageUrl
        isArchived
        venueName
        upcomingEventsCount
        pastEventsCount
        lastEventAt
        properties {
          id
          propertyGroupId
          key
          name
          iconName
          position
          infoUrl
        }
      }
    }
  }
`;
