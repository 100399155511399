import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { ArtistDetailData } from 'app/typings/artists';
import { BookingAvailabilityDate } from 'app/typings/bookingPreferences';
import { Property } from 'app/typings/properties';
import { User } from 'app/typings/users';
import { dateFormatter } from 'app/shared/utils/datetime';
import { get } from 'app/shared/utils/get';
import { isEmpty } from 'app/shared/utils/object';
import usePermission from 'app/shared/utils/usePermission';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Overline } from 'app/shared/components/atoms/TypographyManualCSS';
import {
  getBookingPreferenceCities,
  getSpecificAvailabilityCitiesAndDates,
} from 'app/admin/utils/artists';
import {
  demographicGroupNames,
  getArtistPronouns,
} from 'app/admin/utils/artists';
import { bookingStatusLabels } from 'app/admin/utils/artists';
import {
  DetailHeading as Heading,
  DetailSmallHeading as SmallHeading,
  DetailSmallValue as SmallValue,
  DetailSubheading as Subheading,
  DetailValueContainer as ValueContainer,
} from 'app/admin/components/atoms/DetailContent';
import ExpandableText from 'app/admin/components/atoms/ExpandableText';
import { None, NoneWrapper } from 'app/admin/components/atoms/None';
import HeadingWithHelpIcon from 'app/admin/components/molecules/DetailHeadingWithHelpIcon';
import { UserLink } from 'app/admin/components/molecules/EntityLink';
import { OtherContactInfos } from 'app/admin/components/molecules/OtherContactInfos';
import { PrimaryContactInfo } from 'app/admin/components/molecules/PrimaryContactInfo';
import { TagElementList } from 'app/admin/components/molecules/TagElementList';

interface Props {
  sectionData: ArtistDetailData;
}

export const SIGNED_STATUSES = {
  unknown: 'Unknown',
  independent: 'Independent',
  signed: 'Signed',
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const TagElementListsContainer = styled.div`
  width: 170px;
`;

const ManagementContainer = styled.ul`
  margin-top: 0px;
  margin-bottom: 0px;
  padding-left: 20px;
`;

const ManagementBulletPoint = styled.li`
  font-size: 10px;
  color: #10ad52;
  padding-left: 6px;
`;

const ManagementText = styled.span`
  font-size: 14px;
  color: #000000;
`;

const UserContainer = styled.div`
  margin-bottom: 7px;
`;

const InlineNone = styled(None)`
  display: inline;
`;

const DemographicGroupContainer = styled.div`
  margin-bottom: 20px;
`;

const StyledOverline = styled(Overline)`
  display: block;
  margin-bottom: 2px;
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 6px;
`;

const StyledCity = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
`;

export const SmokedTextContainer = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.blueSmoke};
    font-size: 12px;
    margin-bottom: 10px;
    font-style: italic;
  `}
`;

const BookingStatusValue = styled.div`
  margin-bottom: 7px;
`;

export const getArtistDemographicInfo = (
  demographics: Property[],
  intl: any
) => {
  const artistdemographicGroupNames = demographicGroupNames(intl);
  return Object.keys(artistdemographicGroupNames).map(
    (group: string, index: number) => {
      const demographicGroupName = group.replace('/', ' ');
      const demographicsFilteredByGroup = demographics.filter(
        (demographic: any) =>
          demographic.propertyGroupName == demographicGroupName
      );

      if (!demographicsFilteredByGroup.length) {
        return;
      }

      const demographicGroupValues = demographicsFilteredByGroup.map(
        (property: Property) => {
          return intl.formatMessage({
            id: `artistProfile.checkboxOption.${property.key}`,
          });
        }
      );

      return (
        <DemographicGroupContainer key={index}>
          <StyledOverline spaceAfter={4}>{demographicGroupName}</StyledOverline>
          <> {demographicGroupValues.join(', ')}</>
        </DemographicGroupContainer>
      );
    }
  );
};

const renderAssociatedUsers = (artistData: ArtistDetailData) => {
  if (artistData.associatedUsers && artistData.associatedUsers.length > 0) {
    return artistData.associatedUsers.map((user: User, i: number) => (
      <UserContainer key={i}>
        <UserLink user={user} truncateLength={30} />
      </UserContainer>
    ));
  } else {
    return <None />;
  }
};

const renderSpecificAvailabilityCitiesAndDates = (
  {
    city,
    bookingAvailabilityDates,
  }: {
    city: string;
    bookingAvailabilityDates: BookingAvailabilityDate[];
  },
  index: number
) => {
  if (bookingAvailabilityDates.length == 0) {
    return;
  }

  return (
    <ValueContainer key={index}>
      <StyledCity>{city}</StyledCity>

      {bookingAvailabilityDates.map(
        (bookingAvailabilityDate: BookingAvailabilityDate, i: number) => (
          <div key={i}>
            {dateFormatter(
              bookingAvailabilityDate.date,
              'longWeekdayLongMonthDateAndYear'
            )}
          </div>
        )
      )}
    </ValueContainer>
  );
};

const SectionBasicInfo: React.FC<Props> = ({ sectionData }) => {
  const intl = useIntl();

  const hasAccessArtistsPermission = usePermission('artist.list.access');
  const hasViewArtistDemographicInfoPermission = usePermission(
    'artist.demographicInfo.view'
  );
  const demographics = get(sectionData, 'demographics', []);
  const preferredPronouns = get(sectionData, 'preferredPronouns', []);
  const artistPreferredPronouns =
    preferredPronouns.length && getArtistPronouns(intl, preferredPronouns);

  const bookingPreferencesNotes = get(
    sectionData,
    'bookingPreferencesNotes',
    null
  );

  const {
    availableLastMinuteCities,
    generalAvailabilityCities,
    specificAvailabilityCities,
    legacyAvailabilityCities,
  } = getBookingPreferenceCities(sectionData.bookingPreferences);

  let specificAvailabilityCitiesAndDates: any = [];

  if (specificAvailabilityCities) {
    specificAvailabilityCitiesAndDates = getSpecificAvailabilityCitiesAndDates(
      sectionData.bookingPreferences
    );
  }

  return (
    <MainContainer data-qaid="section-about">
      <Grid cols={4}>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={1}>
          <Heading>
            {intl.formatMessage({
              id: 'admin.artistDirectory.aboutSection.artistHomeCity',
            })}
          </Heading>
          <ValueContainer data-qaid="artist-home-city">
            {sectionData.homeCityDescription ? (
              sectionData.homeCityDescription
            ) : (
              <None />
            )}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.artistDirectory.aboutSection.closestSofarCity',
            })}
          </Heading>
          <ValueContainer>
            {sectionData.closestSofarCityTitle ? (
              `${sectionData.closestSofarCityTitle}, ${sectionData.closestSofarCityCountry}`
            ) : (
              <None />
            )}
          </ValueContainer>
          <HeadingWithHelpIcon
            title={intl.formatMessage({
              id: 'shared.primaryContact',
            })}
            body={intl.formatMessage({
              id: 'admin.artistDirectory.aboutSection.primaryContactHelpText',
            })}
          >
            {intl.formatMessage({
              id: 'shared.primaryContact',
            })}
          </HeadingWithHelpIcon>
          <ValueContainer>
            <PrimaryContactInfo contactInfos={sectionData.contactInfos} />
          </ValueContainer>
          <HeadingWithHelpIcon
            title={intl.formatMessage({
              id: 'admin.artistDirectory.aboutSection.otherContacts',
            })}
            bodyId="admin.artistDirectory.aboutSection.otherContactsHelpText"
          >
            {intl.formatMessage({
              id: 'admin.artistDirectory.aboutSection.otherContacts',
            })}
          </HeadingWithHelpIcon>
          <ValueContainer>
            <OtherContactInfos contactInfos={sectionData.contactInfos} />
          </ValueContainer>
          {hasAccessArtistsPermission && (
            <>
              <Heading>
                {intl.formatMessage({
                  id: 'admin.artistDirectory.aboutSection.associatedUsers',
                })}
              </Heading>
              <ValueContainer>
                {renderAssociatedUsers(sectionData)}
              </ValueContainer>
            </>
          )}
          <Heading>
            {intl.formatMessage({
              id: 'admin.artistDirectory.aboutSection.bookingStatus',
            })}
          </Heading>
          <ValueContainer>
            {sectionData.shouldNotBook && (
              <BookingStatusValue>
                {bookingStatusLabels.should_not_book}
              </BookingStatusValue>
            )}
            {sectionData.isSelfBookingEnabled && (
              <BookingStatusValue>
                {bookingStatusLabels.is_self_booking_enabled}
              </BookingStatusValue>
            )}
            {!sectionData.shouldNotBook &&
              !sectionData.isSelfBookingEnabled && <None />}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.artistDirectory.aboutSection.needsReview',
            })}
          </Heading>
          <ValueContainer>
            {sectionData.needsReview
              ? intl.formatMessage({
                  id: 'yes',
                })
              : intl.formatMessage({
                  id: 'no',
                })}
          </ValueContainer>
        </Col>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={2}>
          <TagElementListsContainer>
            <Heading>
              {sectionData.tags && sectionData.tags.length > 0
                ? `Tags: ${sectionData.tags.length}`
                : 'Tags'}
            </Heading>
            <ValueContainer>
              <TagElementList
                tagElements={
                  // @ts-ignore
                  sectionData.tags
                }
                tagElementColor="#34495E"
                displayShowAllLink={true}
              />
            </ValueContainer>
            <Heading>
              {intl.formatMessage({
                id: 'admin.artistDirectory.aboutSection.genres',
              })}
            </Heading>
            <ValueContainer>
              <TagElementList
                tagElements={sectionData.genres}
                tagElementColor="#9B525F"
                displayShowAllLink={true}
              />
            </ValueContainer>
            {hasAccessArtistsPermission && (
              <>
                <Heading>
                  {sectionData.artistEligibilities &&
                  sectionData.artistEligibilities.length > 0
                    ? `${intl.formatMessage({
                        id: 'admin.artistDirectory.aboutSection.eligibility',
                      })}: ${sectionData.artistEligibilities.length}`
                    : intl.formatMessage({
                        id: 'admin.artistDirectory.aboutSection.eligibility',
                      })}
                </Heading>
                <ValueContainer>
                  <TagElementList
                    tagElements={
                      // @ts-ignore
                      sectionData.artistEligibilities
                    }
                    tagElementColor="#008736"
                    displayShowAllLink={true}
                  />
                </ValueContainer>
              </>
            )}
          </TagElementListsContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.shared.forms',
            })}
          </Heading>
          <ValueContainer>
            <None />
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.artistDirectory.aboutSection.management',
            })}
          </Heading>
          <ValueContainer>
            <ManagementContainer>
              <ManagementBulletPoint>
                <ManagementText>
                  {intl.formatMessage({
                    id: 'admin.artistDirectory.aboutSection.pro',
                  })}
                  :{' '}
                  {!isEmpty(sectionData.performingRightsOrganisation) ? (
                    sectionData.performingRightsOrganisation.title
                  ) : (
                    <InlineNone />
                  )}
                </ManagementText>
              </ManagementBulletPoint>
              <ManagementBulletPoint>
                <ManagementText>
                  {intl.formatMessage({
                    id: 'admin.artistDirectory.aboutSection.labelStatus',
                  })}
                  : {SIGNED_STATUSES[sectionData.signedStatus || 'unknown']}
                </ManagementText>
              </ManagementBulletPoint>
            </ManagementContainer>
          </ValueContainer>
        </Col>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={3}>
          <HeadingWithHelpIcon
            title={intl.formatMessage({
              id: 'admin.shared.artists.artistPronouns',
            })}
            body={intl.formatMessage({
              id: 'admin.shared.artists.artistPronounsHelpText',
            })}
            data-qaid="artist-pronouns"
          >
            {intl.formatMessage({
              id: 'admin.shared.artists.artistPronouns',
            })}
          </HeadingWithHelpIcon>
          <ValueContainer>
            {artistPreferredPronouns ? (
              artistPreferredPronouns.map(
                (pronoun: { title: string }, i: number) => {
                  return <div key={i + 1}>{pronoun.title} </div>;
                }
              )
            ) : (
              <NoneWrapper>
                {intl.formatMessage({
                  id: 'admin.shared.artists.artistHasNotSelectedPronouns',
                })}
              </NoneWrapper>
            )}
          </ValueContainer>
          <HeadingWithHelpIcon
            title={intl.formatMessage({
              id: 'admin.artistDirectory.aboutSection.description',
            })}
            body={intl.formatMessage({
              id: 'admin.artistDirectory.aboutSection.descriptionHelpText',
            })}
          >
            {intl.formatMessage({
              id: 'admin.artistDirectory.aboutSection.description',
            })}
          </HeadingWithHelpIcon>
          <Subheading>
            {intl.formatMessage({
              id: 'admin.artistDirectory.aboutSection.descriptionSubheader',
            })}
          </Subheading>
          <ExpandableText text={sectionData.description} truncateLength={400} />
          {hasViewArtistDemographicInfoPermission && (
            <>
              <Heading>
                {intl.formatMessage({
                  id: 'admin.artistDirectory.aboutSection.demographicInfo',
                })}
              </Heading>
              <ValueContainer>
                {demographics.length > 0 ? (
                  getArtistDemographicInfo(demographics, intl)
                ) : (
                  <None />
                )}
              </ValueContainer>
            </>
          )}
        </Col>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={4}>
          <>
            <StyledHeading>
              {intl.formatMessage({
                id:
                  'admin.artistDirectory.aboutSection.artistBookingPreferences',
              })}
            </StyledHeading>
            <ValueContainer>
              {sectionData.bookingPreferencesUpdatedAt ? (
                <SmokedTextContainer>
                  {intl.formatMessage({
                    id: 'admin.artistDetails.updated',
                  })}{' '}
                  {dateFormatter(
                    sectionData.bookingPreferencesUpdatedAt,
                    'shortMonthDateAndYear'
                  )}
                </SmokedTextContainer>
              ) : (
                <Spacer mb={8} />
              )}
              <>
                <SmallHeading>
                  {intl.formatMessage({
                    id: 'admin.shared.generalAvailability',
                  })}
                </SmallHeading>
                <SmallValue>
                  <TagElementList
                    tagElements={generalAvailabilityCities}
                    tagElementColor="#3F51B5"
                    displayShowAllLink={true}
                  />
                </SmallValue>
                <SmallHeading>
                  {intl.formatMessage({
                    id: 'admin.artistBasicInfo.details.legacyAvailability',
                  })}
                  <SmokedTextContainer>
                    {intl.formatMessage({
                      id:
                        'admin.artistBasicInfo.details.legacyAvailability.details',
                    })}
                  </SmokedTextContainer>
                </SmallHeading>
                <SmallValue>
                  <TagElementList
                    tagElements={legacyAvailabilityCities}
                    tagElementColor="#3498DB"
                    displayShowAllLink={true}
                  />
                </SmallValue>
                <SmallHeading>
                  {intl.formatMessage({
                    id: 'admin.shared.lastMinuteAvailability',
                  })}
                </SmallHeading>
                <SmallValue>
                  <TagElementList
                    tagElements={availableLastMinuteCities}
                    tagElementColor="#9B59B6"
                    displayShowAllLink={true}
                  />
                </SmallValue>
                <SmallHeading>
                  {intl.formatMessage({
                    id: 'admin.shared.specificAvailability',
                  })}
                </SmallHeading>
                <SmallValue>
                  <TagElementList
                    tagElements={specificAvailabilityCities}
                    tagElementColor="#039842"
                    displayShowAllLink={true}
                  />
                </SmallValue>
                {specificAvailabilityCitiesAndDates.length > 0 &&
                  specificAvailabilityCitiesAndDates.map(
                    (
                      cityAndDates: {
                        city: string;
                        bookingAvailabilityDates: BookingAvailabilityDate[];
                      },
                      index: number
                    ) =>
                      renderSpecificAvailabilityCitiesAndDates(
                        cityAndDates,
                        index
                      )
                  )}
              </>
              <SmallHeading>
                {intl.formatMessage({
                  id: 'admin.artistDetails.notes',
                })}
              </SmallHeading>
              <SmallValue>
                <ExpandableText
                  allowHTML
                  text={bookingPreferencesNotes}
                  truncateLength={50}
                />
              </SmallValue>
            </ValueContainer>
          </>
        </Col>
      </Grid>
    </MainContainer>
  );
};

export default SectionBasicInfo;
