import { useEffect, useState } from 'react';

interface Props {
  scriptSrc: string;
  scriptId: string;
}

// Takes a script src, adds it to the document body and then fires the onload callback when the script tag
// has been added to the body - if a script tag with given id already exists in DOM, don't add it again
export const useAddScript = ({ scriptSrc, scriptId }: Props) => {
  const [addedScript, setAddedScript] = useState(false);

  useEffect(() => {
    if (document.getElementById(scriptId)) {
      setAddedScript(true);
    } else {
      const scriptTag = document.createElement('script');
      scriptTag.src = scriptSrc;
      scriptTag.id = scriptId;
      document.body.appendChild(scriptTag);
      scriptTag.onload = () => {
        setAddedScript(true);
      };
    }
  }, [scriptSrc, scriptId]);

  return addedScript;
};
