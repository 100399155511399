import React from 'react';
import styled from 'styled-components';

import { CuratorGroup } from 'app/typings/curatorGroups';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import FromEmailSettings from 'app/admin/components/organisms/FromEmailSettings';

interface Props {
  sectionData: CuratorGroup;
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const SectionEmails: React.FC<Props> = ({ sectionData }) => {
  return (
    <MainContainer data-qaid="section-emails">
      <Grid cols={4}>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={1}>
          <FromEmailSettings
            useFromEmailForAllEmails={sectionData.useFromEmailForAllEmails}
            fromEmail={sectionData.fromEmail}
            mainArtistOps={sectionData.mainArtistOps}
            mainCrewOps={sectionData.mainCrewOps}
            mainVenueOps={sectionData.mainVenueOps}
          ></FromEmailSettings>
        </Col>
      </Grid>
    </MainContainer>
  );
};

export default SectionEmails;
