import styled, { css } from 'styled-components';

import {
  withFlex,
  WithFlexProps,
} from 'app/shared/components/styleUtils/manualCSS/withFlex';
import {
  withSpacing,
  WithSpacingProps,
} from 'app/shared/components/styleUtils/manualCSS/withSpacing';

export interface FlexDivWithSpacingProps
  extends WithFlexProps,
    WithSpacingProps {
  widthProp?: string;
  heightProp?: string;
}

export const FlexDivWithSpacing = styled.div<FlexDivWithSpacingProps>`
  ${withFlex};
  ${withSpacing};

  ${({ heightProp, widthProp }) => css`
    ${heightProp && `height: ${heightProp};`}
    ${widthProp && `width: ${widthProp};`}
  `}
`;

export default FlexDivWithSpacing;
