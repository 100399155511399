import styled, { css } from 'styled-components';

export const DetailHeading = styled.div<{ mb?: string }>`
  margin-bottom: ${props => (props.mb ? props.mb : '10px')};
  font-weight: 600;
`;

export const DetailSubheading = styled.h6`
  font-size: 10px;
  font-weight: 400;
  margin: -4px 0 12px;
`;

export const DetailValueContainer = styled.div`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.4px;
  margin-bottom: 25px;
`;

export const DetailSmallHeading = styled.div`
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 5px;
`;

export const DetailSmallValue = styled.div`
  margin-bottom: 18px;
`;

export const DetailsMainContainer = styled.div`
  ${({ theme }) => css`
    padding: 10px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 3px;

    ${theme.media.md`
      padding: 20px 0px 0px 20px;
    `}
  `}
`;

export const DetailsFormMainContainer = styled.div`
  ${({ theme }) => css`
    padding: 10px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: left;
    border-radius: 3px;

    ${theme.media.md`
      padding: 20px 0px 0px 20px;
    `}
  `}
`;

export const DetailsInfoMainContainer = styled.div`
  ${({ theme }) => css`
    padding: 20px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 3px;

    ${theme.media.md`
      padding: 20px 0px 0px 20px;
    `}
  `}
`;
