import React from 'react';
import { useIntl } from 'react-intl';

import { useCurrentTheme } from 'app/shared/theme';
import { errorMsgForField } from 'app/shared/utils/form';
import { useIsTShirtSizeBreakpointSize as useIsBreakpointSize } from 'app/shared/utils/useBreakpoint';
import { FlexDivWithSpacing } from 'app/shared/components/atoms/FlexDivWithSpacing';
import { FormGroupContainer } from 'app/shared/components/atoms/FormContent';
import { Textfield } from 'app/shared/components/atoms/Textfield';
import { Body2 } from 'app/shared/components/atoms/TypographyManualCSS';
import FormGroup from 'app/shared/components/molecules/FormGroup';

import { CollectionBasicInfoFormSectionHeader } from './components';
import { CollectionBasicInfoFormikProps } from './typings';

interface Props extends CollectionBasicInfoFormikProps {
  validationErrors?: object;
}

export const CachedSlugSection: React.FC<Props> = ({
  values,
  setFieldValue,
  errors,
  validationErrors,
  touched,
  setTouched,
}) => {
  const { formatMessage } = useIntl();
  const theme = useCurrentTheme();
  const { isMobile } = useIsBreakpointSize();
  const formGroupFlexProps = isMobile
    ? { alignItems: 'flex-start', flexDirection: 'column' }
    : { alignItems: 'flex-end', flexDirection: 'row', gap: 2 };

  return (
    <>
      <CollectionBasicInfoFormSectionHeader
        titleIntlId="admin.collectionBasicInfo.form.cachedSlug"
        descriptionIntlId="admin.collectionBasicInfo.form.cachedSlugDescription"
      />
      <FormGroupContainer>
        <FlexDivWithSpacing {...formGroupFlexProps}>
          <FlexDivWithSpacing
            heightProp={theme.dimensions.buttonHeight}
            alignItems="center"
          >
            <Body2>https://www.sofarsounds.com/lists/</Body2>
          </FlexDivWithSpacing>
          <FlexDivWithSpacing widthProp="100%">
            <FormGroup
              data-qaid="collection-about-section-edit-form-cached-slug-formgroup"
              label={formatMessage({
                id: 'admin.collectionBasicInfo.form.cachedSlugLabel',
              })}
              errorMsg={
                errorMsgForField('cachedSlug', touched, errors) ||
                // @ts-ignore
                validationErrors.cached_slug
              }
            >
              <Textfield
                data-qaid="collection-about-section-edit-form-cached-slug-field"
                id="cachedSlug"
                name="cachedSlug"
                value={values.cachedSlug}
                onChange={(e: any) => {
                  setTouched({ ...touched, cachedSlug: true });
                  setFieldValue('cachedSlug', e.target.value);
                }}
                maxLength={200}
              />
            </FormGroup>
          </FlexDivWithSpacing>
        </FlexDivWithSpacing>
      </FormGroupContainer>
    </>
  );
};
