import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import {
  DetailHeading as Heading,
  DetailValueContainer as ValueContainer,
} from 'app/admin/components/atoms/DetailContent';
import ExpandableText from 'app/admin/components/atoms/ExpandableText';
import { NoneWrapper } from 'app/admin/components/atoms/None';

interface Props {
  gearStorage?: string;
  hasTapWater?: boolean;
  noiseRestrictions?: string;
}

const MainContainer = styled.div`
  width: 100%;
`;

const GearStorageContainer = styled(ValueContainer)`
  margin-bottom: -11px;
`;

const TapWaterContainer = styled(ValueContainer)`
  margin-bottom: 12px;
`;

const SubHeading = styled.span`
  font-weight: 600;
  font-size: 14px;
  display: inline;
`;

const ArtistEventLogisticsNotes: React.FC<Props> = ({
  gearStorage,
  hasTapWater,
  noiseRestrictions,
}) => {
  const intl = useIntl();

  return (
    <MainContainer data-qaid="artist-event-logistics-notes">
      <Heading>
        {intl.formatMessage({
          id: 'admin.artistEventLogisticsNotes.artistExperience',
        })}
      </Heading>
      <GearStorageContainer>
        <SubHeading>
          {intl.formatMessage({
            id: 'admin.shared.gearStorage',
          })}
          :
        </SubHeading>
        <ExpandableText text={gearStorage} truncateLength={150} />
      </GearStorageContainer>
      <TapWaterContainer>
        <SubHeading>
          {intl.formatMessage({
            id: 'admin.artistEventLogisticsNotes.tapWater',
          })}
          :
        </SubHeading>
        {hasTapWater == null ? (
          <NoneWrapper>Unknown</NoneWrapper>
        ) : hasTapWater ? (
          ' Yes'
        ) : (
          ' No'
        )}
      </TapWaterContainer>
      <ValueContainer>
        <SubHeading>
          {intl.formatMessage({
            id: 'admin.shared.noiseRestrictions',
          })}
          :
        </SubHeading>
        <ExpandableText text={noiseRestrictions} truncateLength={150} />
      </ValueContainer>
    </MainContainer>
  );
};

export default ArtistEventLogisticsNotes;
