import * as Yup from 'yup';

import { validateUrlNoHttp } from 'app/shared/utils/urls';

const competitionTemplateEditFormSchema = (intl: any) =>
  Yup.object().shape({
    templateName: Yup.string().required(
      intl.formatMessage({
        id: 'form.requiredField',
      })
    ),
    maxWinners: Yup.string()
      .matches(/^\d+$/, {
        message: intl.formatMessage({
          id: 'admin.competitionCreate.form.maxWinnersError',
        }),
      })
      .test(
        'isValidNumber',
        intl.formatMessage({
          id: 'admin.competitionCreate.form.maxWinnersRangeError',
        }),
        function(value) {
          return value ? Number(value) >= 1 && Number(value) <= 1500 : true;
        }
      ),
    thirdPartyCtaLink: Yup.string()
      .nullable()
      .when('thirdPartyCtaText', {
        is: value => !!value,
        then: Yup.string()
          .nullable()
          .required(
            intl.formatMessage({
              id: 'form.requiredField',
            })
          )
          .test(
            'isUrl',
            intl.formatMessage({
              id: 'admin.competitionContent.form.linkValidationNoHttp',
            }),
            function(value) {
              return value ? validateUrlNoHttp(value) : true;
            }
          ),
      }),
    featuredContentCtaLink: Yup.string()
      .nullable()
      .when('featuredContentCtaText', {
        is: value => !!value,
        then: Yup.string()
          .nullable()
          .required(
            intl.formatMessage({
              id: 'form.requiredField',
            })
          )
          .test(
            'isUrl',
            intl.formatMessage({
              id: 'admin.competitionContent.form.linkValidationNoHttp',
            }),
            function(value) {
              return value ? validateUrlNoHttp(value) : true;
            }
          ),
        else: Yup.string().nullable(),
      }),
    optInCopy: Yup.string()
      .nullable()
      .when('turnOnOptIn', {
        is: true,
        then: Yup.string()
          .nullable()
          .required(
            intl.formatMessage({
              id: 'form.requiredField',
            })
          ),
      }),
  });

export default competitionTemplateEditFormSchema;
