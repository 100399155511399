import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { Attendee, EventAttendee } from 'app/typings';
import { Event } from 'app/typings/events';
import { Ticket } from 'app/typings/tickets';
import { useCurrentTheme } from 'app/shared/theme';
import { eventAttendeeOccasions } from 'app/shared/utils/events';
import { useFeatureFlags } from 'app/shared/utils/useFeatureFlags';
import {
  ToggleCloseIcon,
  ToggleOpenIcon,
} from 'app/shared/components/atoms/IconLibrary';
import TruncatedByCharText from 'app/shared/components/atoms/TruncatedByCharText';
import { Overline } from 'app/shared/components/atoms/TypographyManualCSS';
import ShadedOval from 'app/admin/components/atoms/ShadedOval';
import { TagElement } from 'app/admin/components/molecules/TagElementList';
import { GuestlistTickets } from 'app/admin/components/organisms/GuestlistTickets';

const ShadedOvalContainer = styled(ShadedOval)``;

const ClickableRegion = styled.div`
  cursor: pointer;
`;

const AttendeeTopTagWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-left: 0px;
  padding-top: 13px;
`;

const AttendeeTopTagContainer = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  padding: 0px 0px 0px 0px;
`;

const StyledTag = styled(TagElement)`
  text-align: center;
  padding: 1px 5px;
  margin-top: -21px;
  margin-right: -2px;
  height: 18px;
  border-radius: 0px 0px 2px 2px;

  @media screen and (min-width: 1200px) and (max-width: 1300px) {
    font-size: 9px;
  }
`;

const AttendeeName = styled.div`
  font-size: 14px;
  line-height: 21px;
  font-weight: 700;
  letter-spacing: 0.1px;
  white-space: normal;
  margin-top: 5px;
  margin-bottom: 4px;
`;

const AttendeeEmail = styled.div`
  font-size: 12px;
  font-weight: normal;
  line-height: 18px;
  letter-spacing: 0.4px;
  white-space: normal;
  margin-bottom: 9px;
`;

const AttendeePastEventsCountContainer = styled.div<{
  greenBackground?: boolean;
}>`
  ${props => `
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 10px;
    line-height: 10px;
    font-weight: 700;
    letter-spacing: 1.5px;
    white-space: nowrap;
    text-transform: uppercase;
    color: ${
      props.greenBackground
        ? props.theme.colors.whiteDenim
        : props.theme.colors.green600
    };
    background-color: ${
      props.greenBackground ? props.theme.colors.green600 : 'inherit'
    };
    padding: 6px 9px 6px 9px;
    width: fit-content;
    margin-top: 8px;
    margin-bottom: 8px;
    border-radius: 32px;
    ${!props.greenBackground &&
      `border: 1px solid ${props.theme.colors.green600};`}
  `}
`;

const AttendeePastEventsCountOne = styled.div``;

const AttendeePastEventsCountSup = styled.div`
  font-size: 8px;
  line-height: 8px;
`;

const AttendeePastEventsCountShow = styled.div`
  padding-left: 5px;
`;

const AttendeeNotes = styled.div`
  font-size: 12px;
  font-weight: normal;
  line-height: 18px;
  letter-spacing: 0.4px;
  white-space: normal;
  margin-top: 5px;
  margin-bottom: 7px;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-right: 8px;
  padding-bottom: 5px;
`;

const NumTickets = styled.div`
  ${({ theme }) => css`
    width: 40%;
    ${theme.media.md`
      width: 83%;
    `};
    white-space: nowrap;
  `}
`;

const OccasionContainer = styled.div`
  ${({ theme }) => css`
    width: 60%;
    ${theme.media.md`
      width: 17%;
    `};
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
  `}
`;

const OccasionLabel = styled.div`
  vertical-align: top;
  white-space: nowrap;
  padding-right: 5px;
`;

const OccasionValue = styled.div`
  vertical-align: top;
  font-size: 12px;
  font-weight: normal;
  line-height: 18px;
  letter-spacing: 0.4px;
  white-space: nowrap;
  padding-top: 4px;
`;

const OccasionToggleDetails = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    cursor: pointer;
    color: ${theme.colors.green600};
  `}
`;

const OccasionToggleDetailsIcon = styled.div`
  padding-left: 5px;
  padding-top: 4px;
`;

const OccasionDetails = styled.div`
  vertical-align: top;
  font-size: 12px;
  font-weight: normal;
  line-height: 18px;
  letter-spacing: 0.4px;
  white-space: normal;
  text-align: right;
  padding-right: 8px;
  padding-bottom: 5px;
`;

const numGuestsAttended = (attendee: any) => {
  const numAttendedEventAttendee =
    attendee.eventAttendee && attendee.eventAttendee.ticketsAttendedCount;
  const numAttendedVipAttendee =
    attendee.vipAttendee && attendee.vipAttendee.ticketsAttendedCount;
  return numAttendedEventAttendee || numAttendedVipAttendee || 0;
};

export const numGuestsTotal = (attendee: any) => {
  const numTotalEventAttendee =
    attendee.eventAttendee && attendee.eventAttendee.ticketsCount;
  const numTotalVipAttendee =
    attendee.vipAttendee && attendee.vipAttendee.ticketsCount;
  return numTotalEventAttendee || numTotalVipAttendee || 0;
};

const doesTicketMatchSearch = (ticket: any, textSearch: string) => {
  const textSearchCleaned = textSearch.trim().toLowerCase();
  const ticketString = `${ticket.firstName || ''} ${ticket.lastName ||
    ''} ${ticket.email || ''}`;
  return ticketString
    .trim()
    .toLowerCase()
    .includes(textSearchCleaned);
};

interface TopTag {
  tagElementColor: string;
  name: string;
}

interface AttendeeTopTagProps {
  tagElement: TopTag;
  index: number;
}

const AttendeeTopTag: React.FC<AttendeeTopTagProps> = ({
  tagElement,
  index,
}) => {
  return (
    <AttendeeTopTagContainer>
      <StyledTag
        key={index}
        tagElementColor={tagElement.tagElementColor}
        textColor="#fff"
        data-qaid={`attendee-top-tag-${index}`}
      >
        {tagElement.name}
      </StyledTag>
    </AttendeeTopTagContainer>
  );
};

interface AttendeePastEventsCountProps {
  eventAttendee: EventAttendee;
}

export const AttendeePastEventsCount: React.FC<AttendeePastEventsCountProps> = ({
  eventAttendee,
}) => {
  const intl = useIntl();

  return (
    <>
      {eventAttendee.pastEventsCount == 0 ? (
        <AttendeePastEventsCountContainer greenBackground={true}>
          <AttendeePastEventsCountOne>1</AttendeePastEventsCountOne>
          <AttendeePastEventsCountSup>st</AttendeePastEventsCountSup>
          <AttendeePastEventsCountShow>
            {intl.formatMessage({
              id: 'admin.guestlistDayOfShow.show',
            })}
          </AttendeePastEventsCountShow>
        </AttendeePastEventsCountContainer>
      ) : eventAttendee.pastEventsCount == 1 ? (
        <AttendeePastEventsCountContainer>
          {intl.formatMessage({
            id: 'admin.guestlistDayOfShow.oneShow',
          })}
        </AttendeePastEventsCountContainer>
      ) : (
        <AttendeePastEventsCountContainer>
          {intl.formatMessage(
            {
              id: 'admin.guestlistDayOfShow.xShows',
            },
            {
              numEvents: eventAttendee.pastEventsCount?.toString(),
            }
          )}
        </AttendeePastEventsCountContainer>
      )}
    </>
  );
};

interface AttendeeCardProps {
  attendee: Attendee;
  event: Event;
  navigateTo: (routeData: object) => void;
  textSearch: string;
}

export const AttendeeCard: React.FC<AttendeeCardProps> = ({
  attendee,
  event,
  navigateTo,
  textSearch,
}) => {
  const intl = useIntl();
  const theme = useCurrentTheme();
  const {
    featureFlagsEnabled: {
      dosGuestlistEventCount: dosGuestlistEventCountEnabled,
    },
  } = useFeatureFlags(['dos_guestlist_event_count']);

  const [isOccasionDetailsOpen, setIsOccasionDetailsOpen] = useState<boolean>(
    false
  );
  const toggleIsOccasionDetailsOpen = () =>
    setIsOccasionDetailsOpen(!isOccasionDetailsOpen);

  const onClickAttendeeCard = (attendee: any) => {
    navigateTo({
      routeName: 'guestlist-attendance-edit',
      routeProps: {
        attendee,
        event_id: event.id,
      },
    });
  };

  const name =
    attendee.name ||
    intl.formatMessage({
      id: 'unknown',
    });
  const email =
    attendee.email ||
    intl.formatMessage({
      id: 'unknown',
    });

  const numAttended = numGuestsAttended(attendee);
  const numTotal = numGuestsTotal(attendee);

  const topTag = attendee.vipAttendee
    ? {
        tagElementColor: theme.colors.paintItBlack,
        name: 'VIP',
      }
    : undefined;

  const OccasionToggleIcon = isOccasionDetailsOpen
    ? ToggleCloseIcon
    : ToggleOpenIcon;

  return (
    <ShadedOvalContainer>
      <ClickableRegion onClick={() => onClickAttendeeCard(attendee)}>
        {topTag && (
          <AttendeeTopTagWrapper>
            <AttendeeTopTag tagElement={topTag} index={attendee.id || 0} />
          </AttendeeTopTagWrapper>
        )}
        <AttendeeName>
          <TruncatedByCharText text={name} truncateLength={50} />
        </AttendeeName>
        {attendee.eventAttendee && dosGuestlistEventCountEnabled && (
          <AttendeePastEventsCount eventAttendee={attendee.eventAttendee} />
        )}
        <AttendeeEmail>
          <TruncatedByCharText text={email} truncateLength={50} />
        </AttendeeEmail>
        <GuestlistTickets
          attendee={attendee}
          isTicketNameHighlighted={(ticket: Ticket) =>
            textSearch !== '' && doesTicketMatchSearch(ticket, textSearch)
          }
        />
        {attendee.eventAttendee?.generalNotes && (
          <AttendeeNotes>{attendee.eventAttendee.generalNotes}</AttendeeNotes>
        )}
        {attendee.vipAttendee?.reference && (
          <AttendeeNotes>{attendee.vipAttendee?.reference}</AttendeeNotes>
        )}
      </ClickableRegion>
      <FooterContainer>
        <NumTickets>
          <ClickableRegion onClick={() => onClickAttendeeCard(attendee)}>
            <Overline>
              {intl.formatMessage(
                {
                  id: 'admin.guestlistDayOfShow.tickets',
                },
                {
                  numAttended: numAttended.toString(),
                  numTotal: numTotal.toString(),
                }
              )}
            </Overline>
          </ClickableRegion>
        </NumTickets>
        {attendee.eventAttendee?.occasion && (
          <OccasionContainer>
            <OccasionLabel>
              <Overline>
                {intl.formatMessage({
                  id: 'admin.guestlistDayOfShow.occasion',
                })}
                :
              </Overline>
            </OccasionLabel>
            {attendee.eventAttendee?.occasionDetails ? (
              <OccasionToggleDetails onClick={toggleIsOccasionDetailsOpen}>
                <OccasionValue>
                  {eventAttendeeOccasions[attendee.eventAttendee?.occasion]}
                </OccasionValue>
                <OccasionToggleDetailsIcon>
                  <OccasionToggleIcon iconHoverColor="green" />
                </OccasionToggleDetailsIcon>
              </OccasionToggleDetails>
            ) : (
              <OccasionValue>
                {eventAttendeeOccasions[attendee.eventAttendee?.occasion]}
              </OccasionValue>
            )}
          </OccasionContainer>
        )}
      </FooterContainer>
      {isOccasionDetailsOpen && (
        <>
          <OccasionDetails>
            {attendee.eventAttendee?.occasionDetails}
          </OccasionDetails>
        </>
      )}
    </ShadedOvalContainer>
  );
};
