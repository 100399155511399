import React from 'react';

import { City } from 'app/typings/cities';
import { FlexDivWithSpacing } from 'app/shared/components/atoms/FlexDivWithSpacing';
import { DeleteIcon } from 'app/shared/components/atoms/IconLibrary';
import { Spacer } from 'app/shared/components/atoms/Spacer';

const NearbySecondaryCity = ({
  city,
  removeSecondaryCity,
}: {
  city: City;
  removeSecondaryCity: any;
}) => {
  return (
    <FlexDivWithSpacing mb={1} gap={1} alignItems="center">
      <span>{city.title}</span>
      <DeleteIcon iconSize={12} onClick={() => removeSecondaryCity(city)} />
      <Spacer mt={4} />
    </FlexDivWithSpacing>
  );
};

export const NearbySecondaryCities = ({
  cities,
  removeSecondaryCity,
}: {
  cities: City[];
  removeSecondaryCity: any;
}) => {
  if (!cities || cities.length === 0) {
    return null;
  }

  return (
    <>
      <Spacer mt={6} />
      {cities.map((city: City, i: number) => (
        <NearbySecondaryCity
          key={i}
          city={city}
          removeSecondaryCity={removeSecondaryCity}
        />
      ))}
    </>
  );
};
