import React from 'react';
import { useIntl } from 'react-intl';

import ConfirmationModal from 'app/shared/components/molecules/ConfirmationModal';

interface Props {
  cancelCuratorGroupUpdate: VoidFunction;
  updateCuratorGroup: VoidFunction;
}

const CuratorGroupUpdateConfirmationModal: React.FC<Props> = ({
  cancelCuratorGroupUpdate,
  updateCuratorGroup,
}) => {
  const intl = useIntl();

  return (
    <ConfirmationModal
      onCancel={cancelCuratorGroupUpdate}
      title={intl.formatMessage({
        id: 'admin.curatorGroupUpdate.confirmationTitle',
      })}
      description={intl.formatMessage({
        id: 'admin.curatorGroupUpdate.confirmationMessage',
      })}
      onConfirm={updateCuratorGroup}
      confirmationButtonText={intl.formatMessage({
        id: 'yes',
      })}
    />
  );
};

export default CuratorGroupUpdateConfirmationModal;
