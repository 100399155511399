import React from 'react';
import { useIntl } from 'react-intl';

import { get } from 'app/shared/utils/get';
import ImageMultiEdit from 'app/shared/components/organisms/ImageMultiEdit';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

interface Props {
  contentProps?: any;
  setFormSubmitAction: (func: Function) => void;
  setDisplayConfirmation: (set: boolean) => void;
  setIsSubmitting: (set: boolean) => void;
  navigateTo: (routeData: object) => void;
}

const getRenderHeaderContent = (cityTitle?: string) => {
  return () => <DetailsHeader title={cityTitle} loading={!cityTitle} />;
};

const CityPromoHeaderImageUploader: React.FC<Props> = ({
  contentProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  navigateTo,
}) => {
  const intl = useIntl();

  const cityTitle = get(contentProps, 'title', undefined);

  const onUploadSuccess = () => {
    navigateTo({
      routeName: 'city-details',
      routeProps: {
        defaultOpenSection: 'basicInfo',
        id: contentProps.id,
        title: cityTitle,
      },
    });
  };

  const ratioDescriptions = {
    '16:9': intl.formatMessage({
      id: 'admin.multiImageEdit.ratioDescriptionCityPromoHeader16x9',
    }),
    '5:4': intl.formatMessage({
      id: 'admin.multiImageEdit.ratioDescriptionCityPromoHeader5x4',
    }),
  };

  return (
    <>
      <ImageMultiEdit
        objectType="City"
        objectId={contentProps.id}
        imagePurpose="promo_header"
        ratios={['16:9', '5:4']}
        ratioDescriptions={ratioDescriptions}
        imageFieldDescription={intl.formatMessage({
          id: 'admin.cityPromoHeaderImageEdit.imageFieldDescription',
        })}
        renderHeaderContent={getRenderHeaderContent(cityTitle)}
        whatImagesAreForDescription={intl.formatMessage({
          id: 'admin.cityPromoHeaderImageEdit.thisCityForTheHomePage',
        })}
        onUploadSuccess={onUploadSuccess}
        setFormSubmitAction={setFormSubmitAction}
        setDisplayConfirmation={setDisplayConfirmation}
        setIsSubmitting={setIsSubmitting}
        dataQaidPrefix="city-image-upload-form"
      />
    </>
  );
};

export default CityPromoHeaderImageUploader;
