import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { CuratorGroup } from 'app/typings/curatorGroups';
import { Role } from 'app/typings/roles';
import { User } from 'app/typings/users';
import usePermission from 'app/shared/utils/usePermission';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import { GenericLink } from 'app/shared/components/atoms/LinkManualCSS';
import { curatorPrimaryRoleKeys } from 'app/admin/utils/users';
import AddIconAndText from 'app/admin/components/atoms/AddIconAndText';
import { InternalUserLink } from 'app/admin/components/molecules/EntityLink';

interface Props {
  sectionData: CuratorGroup;
  callbacks: { [name: string]: Function };
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const InternalUsersLinkContainer = styled.div`
  margin-bottom: 35px;
`;

const UserAndRolesContainer = styled.div`
  margin-bottom: 35px;
`;

const UserAndRoleContainer = styled.div`
  margin-bottom: 25px;
`;

const UserRole = styled.div`
  white-space: nowrap;
  margin-top: 3px;
`;

const sortRoles = (roles?: Role[]) =>
  roles?.sort((a: Role, b: Role) => {
    if (
      curatorPrimaryRoleKeys.indexOf(b.key) <=
      curatorPrimaryRoleKeys.indexOf(a.key)
    ) {
      return 1;
    } else {
      return -1;
    }
  }) || [];

const sortUserInfos = (userInfos: UserInfo[]) =>
  userInfos.sort((a: UserInfo, b: UserInfo) => {
    if (
      curatorPrimaryRoleKeys.indexOf(b.roleForSort?.key || '') <
      curatorPrimaryRoleKeys.indexOf(a.roleForSort?.key || '')
    ) {
      return 1;
    } else if (
      curatorPrimaryRoleKeys.indexOf(b.roleForSort?.key || '') ===
      curatorPrimaryRoleKeys.indexOf(a.roleForSort?.key || '')
    ) {
      return (a.user.firstName || '') > (b.user.firstName || '') ? 1 : -1;
    } else {
      return -1;
    }
  });

const getCuratorGroupRoles = (sectionData: CuratorGroup, roles?: Role[]) =>
  sortRoles(
    roles?.filter(
      (role: Role) =>
        role.resourceType === 'CuratorGroup' &&
        role.resourceId === sectionData.id
    ) || []
  );

const getRoleForSort = (roles: Role[]) => sortRoles(roles)[0];

const getUserInfos = (sectionData: CuratorGroup) => {
  if (sectionData.users && sectionData.users.length > 0) {
    return sortUserInfos(
      sectionData.users.map((user: User) => {
        const roles = getCuratorGroupRoles(sectionData, user?.roles);
        const roleForSort = getRoleForSort(roles);
        return {
          user,
          roles,
          roleForSort,
        };
      })
    );
  } else {
    return [];
  }
};

interface UserInfo {
  user: User;
  roles: Role[];
  roleForSort?: Role;
}

interface UserAndRoleProps {
  userInfo: UserInfo;
}

const UserAndRole: React.FC<UserAndRoleProps> = ({ userInfo }) => {
  return (
    <UserAndRoleContainer>
      <InternalUserLink user={userInfo.user} />
      {userInfo.roles.map((role: Role, i: number) => (
        <UserRole key={i}>{role.name}</UserRole>
      ))}
    </UserAndRoleContainer>
  );
};

interface UserAndRolesProps {
  sectionData: CuratorGroup;
}

const UserAndRoles: React.FC<UserAndRolesProps> = ({ sectionData }) => {
  const intl = useIntl();

  const userInfos = getUserInfos(sectionData);

  if (userInfos.length > 0) {
    return (
      <UserAndRolesContainer>
        {userInfos.map((userInfo: UserInfo, i: number) => (
          <React.Fragment key={i}>
            <UserAndRole userInfo={userInfo} />
          </React.Fragment>
        ))}
      </UserAndRolesContainer>
    );
  } else {
    return (
      <UserAndRolesContainer>
        {intl.formatMessage({
          id: 'admin.curatorGroupTeam.teamHasNoMembers',
        })}
      </UserAndRolesContainer>
    );
  }
};

const SectionTeam: React.FC<Props> = ({ sectionData, callbacks }) => {
  const intl = useIntl();

  const hasViewInternalUsersPermission = usePermission(
    'user.internalList.view'
  );

  return (
    <MainContainer data-qaid="section-team">
      <Grid cols={1}>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={1}>
          {hasViewInternalUsersPermission && (
            <InternalUsersLinkContainer>
              {intl.formatMessage({
                id: 'admin.curatorGroupTeam.youCanManageYourTeam',
              })}{' '}
              <GenericLink url="/admin/internal-users">
                {intl.formatMessage({
                  id: 'admin.curatorGroupTeam.internalUserDirectory',
                })}
              </GenericLink>
              .
            </InternalUsersLinkContainer>
          )}
          <UserAndRoles sectionData={sectionData} />
          <AddIconAndText
            text={intl.formatMessage({
              id: 'admin.curatorGroupTeam.addTeamMember',
            })}
            textSizeType="large"
            onClick={() => callbacks.toggleAddUserModal()}
          />
        </Col>
      </Grid>
    </MainContainer>
  );
};

export default SectionTeam;
