import React, { useState } from 'react';

import ModalNavigation from './ModalNavigation';
import OuterModalContainer, {
  BackgroundProps,
  ContentBackgroundProps,
} from './OuterModalContainer';

interface Props extends BackgroundProps, ContentBackgroundProps {
  hide: (hideProps?: any) => void;
  initialRouteProps: object;
  initialRouteName: string;
  dataQaidSuffix: string;
}

const RoutableModal: React.FC<Props> = ({
  left = '0',
  top = '0',
  position = 'fixed',
  width = '100%',
  height = '80%',
  hide,
  initialRouteProps,
  initialRouteName,
  dataQaidSuffix,
}) => {
  const [fireCloseEvent, setFireCloseEvent] = useState(false);
  const [displayConfirmation, setDisplayConfirmation] = useState(false);
  return (
    <OuterModalContainer
      left={left}
      top={top}
      position={position}
      width={width}
      height={height}
      displayConfirmation={displayConfirmation}
      setFireCloseEvent={setFireCloseEvent}
      hide={hide}
      renderContent={(modalRef: React.RefObject<HTMLDivElement>) => {
        return (
          <ModalNavigation
            hide={hide}
            initialRouteProps={initialRouteProps}
            initialRouteName={initialRouteName}
            modalRef={modalRef}
            fireCloseEvent={fireCloseEvent}
            setFireCloseEvent={setFireCloseEvent}
            displayConfirmation={displayConfirmation}
            setDisplayConfirmation={setDisplayConfirmation}
            dataQaidSuffix={dataQaidSuffix}
          />
        );
      }}
    />
  );
};

export default RoutableModal;
