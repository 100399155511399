import React, { useState } from 'react';
import {
  closestCenter,
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { City } from 'app/typings/cities';
import { EventCollection } from 'app/typings/eventCollections';
import usePermission from 'app/shared/utils/usePermission';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import { UpdateEventCollectionPositions } from 'app/admin/graphql/eventCollections/mutationHooks';

import CollectionRow from './CollectionRow';

interface Props {
  sectionData: City;
  callbacks: { [name: string]: Function };
}

const MainContainer = styled.div`
  width: 100%;
`;

const CollectionsOrderingDescription = styled.span`
  ${({ theme }) => css`
    font-size: 14px;
    a {
      text-decoration: none;
      color: ${theme.colors.green600};
    }
  `}
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 14px;
  margin-bottom: 12px;
  font-weight: 700;
`;

const IconHeader = styled.div`
  width: 30px;
`;

const UpcomingEventsCountHeader = styled.div`
  width: 80px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const TitleHeader = styled.div`
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const SlugHeader = styled.div`
  ${({ theme }) => css`
    width: auto;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    ${theme.media.xs`
      display: none;
    `}

    ${theme.media.lg`
      display: flex;
    `}
  `}
`;

const shouldDisplayCollection = (collection: any) =>
  !!collection.position && collection.status === 'active';

interface CollectionsListProps {
  hasCollectionsAdminPermission: boolean;
  sensors: any;
  handleDragEnd: (event: any) => void;
  isSubmitting: boolean;
  collectionPositions: string[];
  collectionsByPosition: { [position: number]: EventCollection[] };
}

const CollectionsList: React.FC<CollectionsListProps> = ({
  hasCollectionsAdminPermission,
  sensors,
  handleDragEnd,
  isSubmitting,
  collectionPositions,
  collectionsByPosition,
}) => {
  const intl = useIntl();

  return (
    <>
      <CollectionsOrderingDescription
        dangerouslySetInnerHTML={{
          __html: hasCollectionsAdminPermission
            ? (intl.formatHTMLMessage(
                {
                  id: 'admin.cityCollections.collectionsOrdering',
                },
                {
                  adminCollectionsUrl: '/admin/collections',
                }
              ) as string)
            : (intl.formatMessage({
                id: 'admin.cityCollections.collectionsOrderingShort',
              }) as string),
        }}
      />
      <HeaderContainer>
        <IconHeader></IconHeader>
        <TitleHeader>
          {intl.formatMessage({
            id: 'admin.cityCollections.collectionName',
          })}
        </TitleHeader>
        <SlugHeader>
          {intl.formatMessage({ id: 'admin.cityCollections.urlSlug' })}
        </SlugHeader>
        <UpcomingEventsCountHeader>
          {intl.formatMessage({
            id: 'admin.cityCollections.upcomingConcerts',
          })}
        </UpcomingEventsCountHeader>
      </HeaderContainer>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={collectionPositions}
          strategy={verticalListSortingStrategy}
        >
          {collectionPositions.map((position: any) => {
            return (
              <CollectionRow
                key={position}
                collection={collectionsByPosition[position]}
                isDragDisabled={isSubmitting}
              />
            );
          })}
        </SortableContext>
      </DndContext>
    </>
  );
};

const SectionCollections: React.FC<Props> = ({ sectionData }) => {
  const intl = useIntl();
  const hasCollectionsAdminPermission = usePermission('event.list.view');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const collectionsByPos = {};
  (sectionData.eventCollections || [])
    .filter(shouldDisplayCollection)
    .forEach(
      (collection: any) => (collectionsByPos[collection.position] = collection)
    );
  const [collectionsByPosition, setCollectionsByPosition] = useState(
    collectionsByPos
  );
  const [collectionPositions, setCollectionPositions] = useState(
    Object.keys(collectionsByPosition)
  );
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    })
  );

  const handleUpdateEventCollectionPositions = useSubmitAction({
    submitAction: UpdateEventCollectionPositions(),
    submitVariables: (values: any) => ({
      eventCollectionPositions: values
        .map((collectionPosition: any, index: number) => [
          index + 1,
          collectionsByPosition[collectionPosition],
        ])
        .filter(
          (collectionPos: any) => collectionPos[0] !== collectionPos[1].position
        )
        .map((collectionPos: any) => ({
          eventCollectionId: collectionPos[1].id,
          position: collectionPos[0],
        })),
      cityId: sectionData.id,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.cityCollections.updatePositions.success',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.cityCollections.updatePositions.failure',
    }),
    onSuccess: (response: any) => {
      const collectionsByPos = {};
      (
        response.data?.updateEventCollectionPositions?.city?.eventCollections ||
        []
      )
        .filter(shouldDisplayCollection)
        .forEach(
          (collection: any) =>
            (collectionsByPos[collection.position] = collection)
        );
      setCollectionsByPosition(collectionsByPos);
      setCollectionPositions(Object.keys(collectionsByPos));
      setIsSubmitting(false);
    },
    onError: () => {
      // @ts-ignore
      setCollectionPositions(collectionPositions.toSorted());
      setIsSubmitting(false);
    },
  });

  const handleDragEnd = (event: any) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      setIsSubmitting(true);
      const oldIndex = collectionPositions.indexOf(active.id);
      const newIndex = collectionPositions.indexOf(over.id);
      const newCollectionPositions = arrayMove(
        collectionPositions,
        oldIndex,
        newIndex
      );
      setCollectionPositions(newCollectionPositions);
      handleUpdateEventCollectionPositions(newCollectionPositions);
    }
  };

  const noCollectionsMessage = hasCollectionsAdminPermission
    ? (intl.formatHTMLMessage(
        {
          id: 'admin.cityCollections.noCollections',
        },
        {
          adminCollectionsUrl: '/admin/collections',
        }
      ) as string)
    : (intl.formatMessage({
        id: 'admin.cityCollections.noCollectionsShort',
      }) as string);

  return (
    <MainContainer data-qaid="section-collections">
      {collectionPositions.length > 0 ? (
        <CollectionsList
          hasCollectionsAdminPermission={hasCollectionsAdminPermission}
          sensors={sensors}
          handleDragEnd={handleDragEnd}
          isSubmitting={isSubmitting}
          collectionPositions={collectionPositions}
          collectionsByPosition={collectionsByPosition}
        />
      ) : (
        <CollectionsOrderingDescription
          dangerouslySetInnerHTML={{
            __html: noCollectionsMessage,
          }}
        />
      )}
    </MainContainer>
  );
};

export default SectionCollections;
