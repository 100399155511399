import React from 'react';
import styled, { css } from 'styled-components';

import IconInCircle from 'app/admin/components/atoms/IconInCircle';
import { ReactComponent as Pencil } from 'icons/streamline-regular/interface-essential/edit/pencil.svg';

interface Props {
  text: string;
  onClick: VoidFunction;
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: auto;
  cursor: pointer;
`;

const TextContainer = styled.div`
  ${({ theme }) => css`
    font-size: 10px;
    line-height: 150%;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: ${theme.colors.green600};
  `}
`;

const IconContainer = styled.div`
  padding-left: 10px;
`;

const EditIconAndTextLink: React.FC<Props> = ({ text, onClick }) => {
  return (
    <MainContainer onClick={onClick}>
      <TextContainer>{text}</TextContainer>
      <IconContainer>
        <IconInCircle
          circleBackgroundColor="green50"
          iconSize={16}
          iconName={Pencil}
          iconColor="green600"
          borderCircle={false}
          dataQaIdSuffix="pencil"
        />
      </IconContainer>
    </MainContainer>
  );
};

export default EditIconAndTextLink;
