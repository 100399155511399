import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { CreateGdprDataRemovalProcess } from 'app/admin/graphql/gdpr/mutationHooks';

import GdprDataRemovalForm from './GdprDataRemovalForm';
import GdprDataRemovalFormSchema from './GdprDataRemovalFormSchema';

const GdprDataRemoval: React.FC = () => {
  const intl = useIntl();

  const [validationErrors, setValidationErrors] = useState<object>({});

  const formInitialValues = {
    email: '',
  };

  const createGdprDataRemovalProcessAction = CreateGdprDataRemovalProcess();

  const handleSubmit = useSubmitAction({
    submitAction: createGdprDataRemovalProcessAction,
    submitVariables: (values: any) => ({
      email: values.email,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.gdprDataRemovalForm.successMsg',
    }),
    successMsgValuesForDisplay: {
      email: (values: any) => values.email,
    },
    failureMsg: intl.formatMessage({
      id: 'admin.gdprDataRemovalForm.failureMsg',
    }),
    onSuccess: (_response?: any, resetForm?: any) => {
      resetForm && resetForm();
      setValidationErrors({});
    },
    onValidationError: validationErrors =>
      setValidationErrors(validationErrors),
  });

  return (
    <GenericForm
      formInitialValues={formInitialValues}
      renderFormComponent={(renderProps: any) => (
        <GdprDataRemovalForm
          formikProps={renderProps.formikProps}
          validationErrors={validationErrors}
        />
      )}
      onSubmit={handleSubmit}
      formSchema={GdprDataRemovalFormSchema}
      dataQaId="gdpr-data-removal"
    />
  );
};

export default GdprDataRemoval;
