import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { EmailGroup } from 'app/typings/emailTypes';
import { LoadingBlocks } from 'app/shared/components/atoms/LoadingBlocks';
import { LoadingError } from 'app/shared/components/atoms/LoadingError';
import { GetEmailTestsLazy } from 'app/admin/graphql/emailTest/queryHooks';
import { GetEmailTypes } from 'app/admin/graphql/emailTypes/queryHooks';

import EmailTest from './components/EmailTest';
import MenuTabs from './components/MenuTabs';

const MainContainer = styled.div`
  @media screen and (min-width: 1000px) {
    max-width: 1300px;
  }
  padding: 0px 25px 25px 0px;
`;

const SummaryContainer = styled.div`
  text-align: left;
  margin-bottom: 25px;
`;

const EmailTestsContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    background: ${theme.colors.whiteDenim};
  `}
`;

const EmailTestingForm: React.FC = () => {
  const intl = useIntl();
  const [searchParams, setSearchParams] = useSearchParams();
  const [tabActiveLabel, setTabActiveLabel] = useState('');

  const {
    loading: loadingEmailTypes,
    error: errorEmailTypes,
    data: dataEmailTypes,
  } = GetEmailTypes();

  const emailGroups = dataEmailTypes?.emailTypes?.emailGroups ?? [];

  const emailGroupKeysAndLabels = {
    ...emailGroups.reduce((obj: {}, emailGroup: EmailGroup) => {
      obj[emailGroup.key] = emailGroup.name;
      return obj;
    }, {}),
    All: 'All',
  };

  const emailGroupForLabel = (emailGroupLabel: string) =>
    Object.keys(emailGroupKeysAndLabels).find(
      (key: any) => emailGroupKeysAndLabels[key] == emailGroupLabel
    );

  const [
    fetchEmailTests,
    {
      loading: loadingEmailTests,
      error: errorEmailTests,
      data: dataEmailTests,
    },
  ] = GetEmailTestsLazy({
    // emailType is actually email group e.g. 'Artist' - TODO: rename this param both backend and frontend
    emailType: emailGroupForLabel(tabActiveLabel),
    orderBy: 'name',
    orderDirection: 'asc',
  });

  useEffect(() => {
    if (emailGroups.length > 0) {
      fetchEmailTests();
    }
  }, [emailGroups, fetchEmailTests]);

  useEffect(() => {
    if (emailGroups.length > 1) {
      const emailGroupParam = searchParams.get('emailGroup');
      const titleizedEmailGroupParam =
        emailGroupParam &&
        emailGroupParam.charAt(0).toUpperCase() + emailGroupParam.slice(1);

      const matchingEmailGroupName =
        !!titleizedEmailGroupParam &&
        emailGroupKeysAndLabels[titleizedEmailGroupParam];

      if (matchingEmailGroupName) {
        setTabActiveLabel(matchingEmailGroupName);
      } else {
        setTabActiveLabel(emailGroups[0].name);
      }
    }
  }, [emailGroupKeysAndLabels, emailGroups, searchParams]);

  if (errorEmailTypes || errorEmailTests) {
    return <LoadingError whatsBeingLoaded="this form" />;
  }

  if (
    loadingEmailTypes ||
    !dataEmailTypes ||
    loadingEmailTests ||
    !dataEmailTests
  ) {
    return <LoadingBlocks.Rectangle width="100%" height="300px" />;
  }

  const handleTabClick = (label: string) => {
    setSearchParams({ emailGroup: emailGroupForLabel(label) || '' });
    fetchEmailTests();
  };

  const emailTests = dataEmailTests.emailTests.emailTests.map(
    (emailTest: any) => <EmailTest key={emailTest.key} emailTest={emailTest} />
  );

  return (
    <MainContainer>
      <MenuTabs
        labels={Object.values(emailGroupKeysAndLabels)}
        activeLabel={tabActiveLabel}
        handleTabClick={handleTabClick}
        isLoading={loadingEmailTests}
      />
      <SummaryContainer>
        {intl.formatMessage(
          { id: 'admin.emailTest.totalEmails' },
          { emailTestName: tabActiveLabel.toLowerCase() }
        )}
        : {emailTests.length}
      </SummaryContainer>
      <EmailTestsContainer>{emailTests}</EmailTestsContainer>
    </MainContainer>
  );
};

export default EmailTestingForm;
