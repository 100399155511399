import * as Yup from 'yup';

import { getNotesFormSchema } from 'app/admin/utils/string';

const venueBookingInformationEditFormSchema = (intl: any) =>
  Yup.object().shape({
    availabilityNotes: getNotesFormSchema(intl),
  });

export default venueBookingInformationEditFormSchema;
