import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { SlackMessage } from 'app/typings/slacks';
import usePermission from 'app/shared/utils/usePermission';
import { GenericLink } from 'app/shared/components/atoms/LinkManualCSS';

interface Props {
  failedMessage: SlackMessage;
}

interface RecipientProps {
  userCanSeeInternalLink: boolean;
  failedMessage: SlackMessage;
}

const ErrorMessage = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.redRedWine};
    font-size: 12px;

    a {
      color: ${theme.colors.redRedWine};
      text-decoration: underline;
    }
  `}
`;

const FailedMessageRecipient: React.FC<RecipientProps> = ({
  userCanSeeInternalLink,
  failedMessage,
}) => {
  if (userCanSeeInternalLink) {
    return (
      <GenericLink
        url={`/admin/internal-users?user_id=${failedMessage.messagable?.id}`}
      >
        {failedMessage.messagable?.name}
      </GenericLink>
    );
  } else {
    return <>{failedMessage.messagable?.name}</>;
  }
};

const FailedSlackMessage: React.FC<Props> = ({ failedMessage }) => {
  const intl = useIntl();
  const userCanSeeLink = usePermission('user.internalList.view');

  return (
    <ErrorMessage key={failedMessage.id}>
      {intl.formatMessage({
        id: 'admin.eventPlanner.team.cancellationSlackMessageFailedNote',
      })}
      <FailedMessageRecipient
        failedMessage={failedMessage}
        userCanSeeInternalLink={userCanSeeLink}
      />
      {intl.formatMessage({
        id: 'admin.eventPlanner.team.cancellationSlackMessageFailedDescription',
      })}
    </ErrorMessage>
  );
};

export default FailedSlackMessage;
