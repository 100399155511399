import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

interface FileUploaderProps {
  styledFileUploaderComponent: React.ReactElement;
  onChange: (values: any) => void;
}

const SUPPORTED_FILE_FORMATS = {
  'image/jpg': ['.jpg'],
  'image/jpeg': ['.jpeg'],
  'image/png': ['.png'],
  'application/pdf': ['.pdf'],
};

const ErrorMessageContainer = styled.div`
  ${({ theme }) => css`
    font-size: 10px;
    font-weight: normal;
    letter-spacing: 0.3px;
    line-height: 1.6;
    margin: 0;
    padding: 0;
    margin-bottom: 0px;
    display: inline-block;
    color: ${theme.colors.redRedWine};
  `}
`;

const FileUploader: React.FC<FileUploaderProps> = ({
  styledFileUploaderComponent,
  onChange,
}) => {
  const intl = useIntl();
  const maxFileSize = 5e6; // set to 5Mb in bytes

  const [errorMessages, setErrorMessages] = useState<
    { code: string; message: any }[]
  >([]);

  const readUploadedFile = async (fileToRead: any) => {
    const fileReader = new FileReader();

    return new Promise((resolve, reject) => {
      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = () => {
        fileReader.abort();

        reject(new DOMException('Problem processing the chosen file.'));
      };

      try {
        fileReader.readAsDataURL(fileToRead);
      } catch (error) {
        // eslint-disable-next-line
        console.error(
          '** An error occured while reading the uploaded file: ',
          error
        );
      }
    });
  };

  const formData = (file: any) => {
    const data = new FormData();
    data.append('file', file);
    return data;
  };

  const request = async (file: any, cacheUrl: any) =>
    new Promise(resolve => {
      const xhr = new XMLHttpRequest();

      xhr.open('POST', cacheUrl, true);

      xhr.onload = () => {
        resolve(JSON.parse(xhr.response));
      };
      xhr.onerror = () => {
        resolve(undefined);
        // eslint-disable-next-line
        console.error('** An error occurred during the XMLHttpRequest');
      };
      xhr.send(formData(file));
    });

  const onDrop = async (acceptedFiles: any, rejectedFiles: any) => {
    let file = null;

    if (rejectedFiles.length) {
      // eslint-disable-next-line
      console.error('** Uploaded files were rejected: ', rejectedFiles);
      file = rejectedFiles[0];

      setErrorMessages(file.errors);
    }

    if (acceptedFiles.length) {
      file = acceptedFiles[0];

      const url = await readUploadedFile(file);
      const response: any = await request(file, '/attachments/cache');
      const data = {
        filename: file.name,
        contentType: file.type,
        size: file.size,
        id: response.id,
        url,
      };

      onChange(data);
    }
  };

  const getUserFriendlyErrorMessages = (
    errorCode: string,
    errorMessage: string
  ) => {
    switch (errorCode) {
      case 'file-invalid-type':
        return intl.formatMessage(
          {
            id: 'fileUploader.fileSizeErrroMsg',
          },
          {
            maxFileSize: maxFileSize / 1000000,
          }
        );
      case 'file-too-large':
        return intl.formatMessage({
          id: 'fileUploader.fileFormatErrorMsg',
        });
      default:
        return errorMessage;
    }
  };

  const displayErrorMessages = () => {
    return errorMessages.map((error, index) => {
      const { code, message } = error;
      return (
        <ErrorMessageContainer
          data-qaid={`file-uploader-error-${index}`}
          key={index}
        >
          {getUserFriendlyErrorMessages(code, message)}
        </ErrorMessageContainer>
      );
    });
  };

  return (
    <div data-qaid="artist-forms-upload">
      <Dropzone
        accept={SUPPORTED_FILE_FORMATS}
        maxSize={maxFileSize}
        multiple={false}
        onDrop={(acceptedFiles: any, rejectedFiles: any) =>
          onDrop(acceptedFiles, rejectedFiles)
        }
      >
        {({ getRootProps, getInputProps }) => {
          return (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              {styledFileUploaderComponent}
              {errorMessages && displayErrorMessages()}
            </div>
          );
        }}
      </Dropzone>
    </div>
  );
};

export default FileUploader;
