// See also related Images::ImagesConfig in backend

export const baseQueryStringParams =
  'ixlib=rails-4.3.1&auto=format&fit=crop&crop=entropy';

// e.g. https://sofar-sounds-live.imgix.net/images/default-images
export const defaultImageFileLocation = `${process.env.IMGIX_DOMAIN}/images/default-images`;

export const defaultOutputQuality = 60;

// [object_type]: [REQ - e.g. City]
//   imageMechanism: [REQ - one of: refile_attachment|image_model]
//   useCases: [OPT - REQ if imageMechanism=image_model]
//     [use_case_name]: [REQ - e.g. thumbnail]
//        imagePurpose: [REQ - e.g. header]
//        aspectRatio: [OPT - e.g. 4:1, either aspectRatio or aspectRatiosBySize is REQ, stored ratio from backend to use,
//                     for source maps, used for all sizes]
//        aspectRatiosBySize: [OPT - either aspectRatio or aspectRatiosBySize is REQ, stored ratios from backend to use,
//                            only for source maps]
//          src: [OPT - e.g. 1:1]
//          srcSmall: [OPT - e.g. 4:1]
//          srcMedium: [OPT - e.g. 4:3]
//          etc...
//        aspectRatioOverridesBySize: [OPT - overrides both aspectRatio and aspectRatiosBySize, enforces any whole-number ratio
//                                    at display time, only for source maps]
//          src: [OPT - e.g. 23:9]
//          srcSmall: [OPT - e.g. 5:2]
//          srcMedium: [OPT - e.g. 8:7]
//          etc...
//        extraParamsBySize: [OPT - any extra size-specific query string params to add to image url, only for source maps]
//          src: [OPT - e.g. '&w=400']
//          srcSmall: [OPT - e.g. '&w=400']
//          srcMedium: [OPT - e.g. '&w=400']
//          etc...
//        outputQuality: [OPT - 0-100, e.g. 30, if present, use this, else fall back to global defaultOutputQuality]
//        defaultImageFilename: [OPT - e.g. city/default-city.jpg, if present and there is no custom uploaded image, use this]
//
// There are TWO types of cropping and aspect ratio in our image system:
//
// 1. STORED CROPPING/RATIO - The Imgix query string params stored in the 'images' db table which specify how to
//    crop the image for that record - stored as a full query string after the raw image file name - we store
//    each possible ratio for that image in a separate image record (e.g. an EventCollection has two ratios,
//    so the database has two image records for each EventCollection image), and the backend returns all the
//    images to the frontend keyed off of ratio - when you specify an 'aspectRatio', that indicates which ratio
//    image coming from the backend to use
// 2. OVERRIDE CROPPING/RATIO - An aspect ratio that we 'force' the image into at runtime, throwing away the
//    stored aspect ratio stored with the image in the database and applying the new aspect ratio (which can
//    be any whole-number ratio, not just one of the possible stored ratios) at display time for a particular
//    size (e.g. srcSmall) - for occasional cases where we need to display the image in a ratio close to,
//    but not identical to, the stored ratio
//
export const imagesConfig = {
  Artist: {
    imageMechanism: 'refile_attachment',
  },
  City: {
    imageMechanism: 'image_model',
    useCases: {
      main: {
        imagePurpose: 'header',
        aspectRatio: '1:1',
        aspectRatioOverridesBySize: {
          srcSmall: '4:5',
          srcMedium: '4:5',
          srcLarge: '4:5',
          srcMobile: '1:1',
          srcTablet: '4:5',
          srcSmallDesktop: '4:5',
          srcLargeDesktop: '4:5',
        },
        extraParamsBySize: {
          src: '&w=800',
          srcSmall: '&w=400',
          srcMedium: '&w=600',
          srcLarge: '&w=800',
          srcMobile: '&w=400',
          srcTablet: '&w=600',
          srcSmallDesktop: '&w=800',
          srcLargeDesktop: '&w=800',
        },
        outputQuality: 50,
        defaultImageFilename: 'city/default-city.jpg',
      },
      homePage: {
        imagePurpose: 'promoHeader',
        aspectRatiosBySize: {
          srcSmall: '5:4',
          srcMedium: '16:9',
          srcLarge: '16:9',
          srcMobile: '5:4',
          srcTablet: '16:9',
          srcSmallDesktop: '16:9',
          srcLargeDesktop: '16:9',
        },
        extraParamsBySize: {
          src: '&w=800',
          srcSmall: '&w=500',
          srcMedium: '&w=750',
          srcLarge: '&w=1000',
          srcMobile: '&w=480',
          srcTablet: '&w=900',
          srcSmallDesktop: '&w=1600',
          srcLargeDesktop: '&w=3000',
        },
        outputQuality: 50,
        // TODO: Insert actual default image here
        defaultImageFilename: 'city/default-city.jpg',
      },
    },
  },
  Competition: {
    imageMechanism: 'refile_attachment',
  },
  Event: {
    imageMechanism: 'image_model',
    useCases: {
      main: {
        imagePurpose: 'header',
        aspectRatio: '16:9',
        extraParamsBySize: {
          src: '',
          srcSmall: '&w=500',
          srcMedium: '&w=750',
          srcLarge: '&w=1000',
          srcMobile: '&w=480',
          srcTablet: '&w=900',
          srcSmallDesktop: '&w=1600',
          srcLargeDesktop: '&w=3000',
        },
        outputQuality: 35,
      },
    },
  },
  EventCollection: {
    imageMechanism: 'image_model',
    useCases: {
      main: {
        imagePurpose: 'header',
        aspectRatio: '1:1',
        extraParamsBySize: {
          src: '',
          srcSmall: '&w=500',
          srcMedium: '&w=750',
          srcLarge: '&w=1000',
          srcMobile: '&w=480',
          srcTablet: '&w=900',
          srcSmallDesktop: '&w=1600',
          srcLargeDesktop: '&w=3000',
        },
        outputQuality: 35,
      },
      thumbnail: {
        imagePurpose: 'header',
        aspectRatio: '16:9',
        extraParamsBySize: {
          src: '',
          srcSmall: '&w=400',
          srcMedium: '&w=600',
          srcLarge: '&w=800',
          srcMobile: '&w=300',
          srcTablet: '&w=400',
          srcSmallDesktop: '&w=600',
          srcLargeDesktop: '&w=800',
        },
      },
      eventMainFallback: {
        imagePurpose: 'header',
        aspectRatio: '16:9',
        extraParamsBySize: {
          src: '',
          srcSmall: '&w=500',
          srcMedium: '&w=750',
          srcLarge: '&w=1000',
          srcMobile: '&w=480',
          srcTablet: '&w=900',
          srcSmallDesktop: '&w=1600',
          srcLargeDesktop: '&w=3000',
        },
        outputQuality: 35,
      },
    },
  },
  Venue: {
    imageMechanism: 'refile_attachment',
  },
};
