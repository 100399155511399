import React from 'react';
import { useIntl } from 'react-intl';

import { City, Neighborhood, Tag } from 'app/typings';
import { KeyValue } from 'app/typings/generics';
import { Property } from 'app/typings/properties';
import { dateFormatter } from 'app/shared/utils/datetime';
import { KeyAboveValueInfoList } from 'app/admin/components/molecules/KeyAboveValueInfoList';
import ListingCard from 'app/admin/components/organisms/ListingCard';
import { ReactComponent as EmailActionUnread } from 'icons/streamline-regular/emails/email-actions/email-action-unread.svg';

interface VenueCardProps {
  id?: number;
  index?: number;
  imageUrl?: string;
  largeImageUrl?: string;
  capacity?: number;
  venueType?: string;
  venueName?: string;
  neighborhood?: Neighborhood;
  address?: string;
  emailAddress?: string;
  city?: City;
  tags?: Tag[];
  properties?: Property[];
  onShowDetails?: Function;
  pastEventsCount?: number;
  upcomingEventsCount?: number;
  lastEventAt?: string;
  numTicketsAvailableForSale?: number;
  hostingStatus?: {
    key: string;
    name: string;
  };
  loading?: boolean;
  error?: string;
}

const VenueCard: React.FC<VenueCardProps> = ({
  id,
  index,
  imageUrl,
  largeImageUrl,
  tags = [],
  properties = [],
  capacity = 0,
  venueName = 'None',
  address = 'None',
  emailAddress = 'None',
  city = { title: 'None' },
  neighborhood = { title: 'None' },
  onShowDetails = () => {},
  pastEventsCount = 0,
  upcomingEventsCount = 0,
  lastEventAt = null,
  numTicketsAvailableForSale = 0,
  hostingStatus = {},
  loading = false,
  error = undefined,
}) => {
  const intl = useIntl();

  const tagElementsInfoList = [
    {
      tagElements: tags,
      listTitle: intl.formatMessage({
        id: 'admin.venueDirectory.listingCard.tags',
      }),
      tagElementColor: '#34495E',
    },
  ];

  const propertiesInfoList = [
    {
      listTitle: intl.formatMessage({
        id: 'admin.shared.characteristics',
      }),
      propertyElements: properties,
    },
  ];

  const keysAboveValuesList = [
    {
      key: intl.formatMessage({
        id: 'admin.venueDirectory.listingCard.sofarCity',
      }),
      value: city.title,
    },
    {
      key: intl.formatMessage({
        id: 'admin.venueDirectory.listingCard.neighborhood',
      }),
      value:
        neighborhood?.title === null || !neighborhood?.title
          ? intl.formatMessage({
              id: 'none',
            })
          : neighborhood.title,
    },
  ];

  const keysAndValuesList = [
    {
      key: intl.formatMessage({
        id: 'admin.venueDirectory.listingCard.capacity',
      }),
      value: capacity.toString(),
    },
    {
      key: intl.formatMessage({
        id: 'admin.venueDirectory.listingCard.ticketsAvailable',
      }),
      value: numTicketsAvailableForSale
        ? numTicketsAvailableForSale.toString()
        : '0',
    },
    {
      key: intl.formatMessage({
        id: 'admin.venueDirectory.listingCard.totalConcerts',
      }),
      value: pastEventsCount.toString(),
    },
    {
      key: intl.formatMessage({
        id: 'admin.venueDirectory.listingCard.lastConcert',
      }),
      value: lastEventAt
        ? dateFormatter(lastEventAt, 'shortMonthAndLongYear')
        : intl.formatMessage({
            id: 'none',
          }),
    },
    {
      key: intl.formatMessage({
        id: 'admin.venueDirectory.listingCard.upcomingConcerts',
      }),
      value: upcomingEventsCount.toString(),
    },
    {
      key: intl.formatMessage({
        id: 'admin.venueDirectory.listingCard.status',
      }),
      value: hostingStatus.name ? hostingStatus.name : 'None',
    },
  ];

  const controlsInfoList = [
    {
      href: emailAddress ? `mailto:${emailAddress || ''}` : '',
      icon: EmailActionUnread,
      tipText: intl.formatMessage({
        id: 'admin.venueDirectory.listingCard.emailTheOwner',
      }),
    },
  ];

  const renderTopContent = (keysAboveValuesList: KeyValue[] | undefined) => {
    if (keysAboveValuesList && keysAboveValuesList.length > 0) {
      return <KeyAboveValueInfoList keysAndValues={keysAboveValuesList} />;
    }
    return null;
  };

  const onShowDetailsParams = {
    id,
    venueName,
    imageUrl,
    largeImageUrl,
    pastEventsCount,
    upcomingEventsCount,
    lastEventAt,
  };

  const getVenueNameFallback = () => {
    const isResidential = properties.some(
      (property: Property) => property.key == 'residential'
    );
    return isResidential
      ? intl.formatMessage({
          id: 'admin.venueCard.title.residence',
        })
      : intl.formatMessage({
          id: 'none',
        });
  };

  return (
    <ListingCard
      imageStyle="landscape"
      imageUrl={imageUrl}
      title={
        venueName === null || !venueName ? getVenueNameFallback() : venueName
      }
      largeImageUrl={largeImageUrl}
      subtitle={address || 'None'}
      topContent={() => renderTopContent(keysAboveValuesList)}
      tagElementsInfoList={tagElementsInfoList}
      propertyElementsInfoList={propertiesInfoList}
      keysAndValuesList={keysAndValuesList}
      controlsInfoList={controlsInfoList}
      onShowDetails={onShowDetails}
      onShowDetailsParams={onShowDetailsParams}
      showDetailsTipText={intl.formatMessage({
        id: 'admin.venueDirectory.listingCard.venueDetails',
      })}
      dataQaidPrefix="venue"
      index={index}
      height="700px"
      loading={loading}
      error={error}
    />
  );
};

export default VenueCard;
