import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { errorMsgForField } from 'app/shared/utils/form';
import {
  FormGroupContainer,
  FormHelpText,
  FormSectionTitle,
} from 'app/shared/components/atoms/FormContent';
import GenericFormContainer from 'app/shared/components/atoms/GenericFormContainer';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import { Radio } from 'app/shared/components/atoms/Radio';
import StyledTextarea from 'app/shared/components/atoms/SmallTextarea';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Textfield } from 'app/shared/components/atoms/Textfield';
import FormGroup from 'app/shared/components/molecules/FormGroup';
import Select from 'app/shared/components/molecules/SelectManualCSS';
import { getMaxPaInputsOptions } from 'app/admin/utils/optionLists';
import RichTextEditor from 'app/admin/components/molecules/RichTextEditor';
import Timepicker from 'app/admin/components/molecules/TimePicker';

interface FormProps {
  formikProps: any;
  setFormSubmitAction?: (func: Function) => void;
  setDisplayConfirmation?: (set: boolean) => void;
  setIsSubmitting?: (set: boolean) => void;
}

const RecommendedCrewCountTextField = styled(Textfield)`
  display: block;
  width: 60px;
`;

const VenueBasicInfoForm: React.FC<FormProps> = ({
  formikProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
}) => {
  const intl = useIntl();

  useEffect(() => {
    setFormSubmitAction && setFormSubmitAction(() => formikProps.submitForm);
    setDisplayConfirmation && setDisplayConfirmation(formikProps.dirty);
    setIsSubmitting && setIsSubmitting(formikProps.isSubmitting);
  }, [
    formikProps.isSubmitting,
    formikProps.submitForm,
    formikProps.dirty,
    setFormSubmitAction,
    setDisplayConfirmation,
    setIsSubmitting,
  ]);

  const { setFieldValue, touched, errors, values } = formikProps;

  const maxPaInputsOptions = getMaxPaInputsOptions(intl);

  const maxPaInputsOptionsMap = Object.fromEntries(
    maxPaInputsOptions.map(opt => [opt.value, opt])
  );

  return (
    <GenericFormContainer dataQaId="venue-event-setup-section-edit-form">
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.shared.eventTimes.loadInLoadOutTimes',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <Grid>
          <Col lg={3} md={4} sm={5} xs={6}>
            <FormGroup
              data-qaid="venue-event-setup-form-crew-load-in-field-formgroup"
              label={intl.formatMessage({
                id: 'admin.shared.eventTimes.crewLoadInTime',
              })}
              errorMsg={errorMsgForField('localCrewLoadInAt', touched, errors)}
              required
            >
              <Timepicker
                data-qaid="venue-event-setup-form-crew-load-in-field"
                name="localCrewLoadInAt"
                value={values.localCrewLoadInAt}
                onChange={(value: any) => {
                  setFieldValue('localCrewLoadInAt', value);
                }}
              />
            </FormGroup>
          </Col>
          <Col lg={3} md={4} sm={5} xs={6}>
            <FormGroup
              data-qaid="venue-event-setup-form-artist-load-in-field-formgroup"
              label={intl.formatMessage({
                id: 'admin.shared.eventTimes.artistLoadInTime',
              })}
              errorMsg={errorMsgForField(
                'localArtistLoadInAt',
                touched,
                errors
              )}
              required
            >
              <Timepicker
                data-qaid="venue-event-setup-form-artist-load-in-field"
                name="localArtistLoadInAt"
                value={values.localArtistLoadInAt}
                onChange={(value: any) => {
                  setFieldValue('localArtistLoadInAt', value);
                }}
              />
            </FormGroup>
          </Col>
        </Grid>
        <Spacer mb={4} />
        <Grid>
          <Col lg={3} md={4} sm={5} xs={6}>
            <FormGroup
              data-qaid="venue-event-setup-form-guests-arrive-at-field-formgroup"
              label={intl.formatMessage({
                id: 'admin.shared.eventTimes.guestsArriveTime',
              })}
              errorMsg={errorMsgForField(
                'localGuestsArriveAt',
                touched,
                errors
              )}
              required
            >
              <Timepicker
                data-qaid="venue-event-setup-form-guests-arrive-at-field"
                name="localGuestsArriveAt"
                value={values.localGuestsArriveAt}
                onChange={(value: any) => {
                  setFieldValue('localGuestsArriveAt', value);
                }}
              />
            </FormGroup>
          </Col>
          <Col lg={3} md={4} xs={6}>
            <FormGroup
              data-qaid="venue-event-setup-form-artist-starts-at-field-formgroup"
              label={intl.formatMessage({
                id: 'admin.shared.eventTimes.startTime',
              })}
              errorMsg={errorMsgForField('localStartsAt', touched, errors)}
              required
            >
              <Timepicker
                data-qaid="venue-event-setup-form-starts-at-field"
                name="localStartsAt"
                value={values.localStartsAt}
                onChange={(value: any) => {
                  setFieldValue('localStartsAt', value);
                }}
              />
            </FormGroup>
          </Col>
        </Grid>
        <Spacer mb={4} />
        <FormGroup
          data-qaid="venue-event-setup-form-artist-ends-at-field-formgroup"
          label={intl.formatMessage({
            id: 'admin.shared.eventTimes.endsTime',
          })}
          errorMsg={errorMsgForField('localEndsAt', touched, errors)}
        >
          <Timepicker
            data-qaid="venue-event-setup-form-ends-at-field"
            name="localEndsAt"
            value={values.localEndsAt}
            onChange={(value: any) => {
              setFieldValue('localEndsAt', value);
            }}
          />
        </FormGroup>
        <>
          <Spacer mb={8} />
          <FormGroup
            data-qaid="venue-event-setup-form-timing-notes-formgroup"
            label={intl.formatMessage({
              id: 'admin.venueEventSetup.timingNotes',
            })}
            errorMsg={errorMsgForField('timingNotes', touched, errors)}
          >
            <RichTextEditor
              name="timingNotes"
              currentValue={values.timingNotes}
              onChange={(value: any) => setFieldValue('timingNotes', value)}
            />
          </FormGroup>
          <Spacer mb={8} />
          <FormGroup
            data-qaid="venue-event-setup-form-load-in-notes-formgroup"
            label={intl.formatMessage({
              id: 'admin.venueEventSetup.loadInNotes',
            })}
            errorMsg={errorMsgForField('loadInNotes', touched, errors)}
          >
            <RichTextEditor
              name="loadInNotes"
              currentValue={values.loadInNotes}
              onChange={(value: any) => setFieldValue('loadInNotes', value)}
            />
          </FormGroup>
        </>
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'shared.crew',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormHelpText>
          {intl.formatMessage({
            id: 'admin.crewPortal.setup.recommendedCrewDescription',
          })}
        </FormHelpText>
        <FormGroup
          data-qaid="venue-event-setup-form-recommended-crew-count-formgroup"
          label={intl.formatMessage({
            id: 'admin.shared.recommendedTeam',
          })}
          errorMsg={errorMsgForField('recommendedCrewCount', touched, errors)}
        >
          <Grid>
            <Col xs={4} lg={6}>
              <RecommendedCrewCountTextField
                data-qaid="venue-event-setup-form-recommended-crew-count-field"
                id="recommendedCrewCount"
                name="recommendedCrewCount"
                value={values.recommendedCrewCount}
                onChange={(e: any) => {
                  setFieldValue('recommendedCrewCount', e.target.value);
                }}
                maxLength={1}
              />
            </Col>
          </Grid>
        </FormGroup>
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.shared.otherInternalNotes',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="venue-event-setup-form-venue-notes-formgroup"
          label={intl.formatMessage({
            id: 'admin.venueEventSetup.venueNotes',
          })}
          errorMsg={errorMsgForField('internalVenueNotes', touched, errors)}
        >
          <RichTextEditor
            name="internalVenueNotes"
            currentValue={values.internalVenueNotes}
            onChange={(value: any) =>
              setFieldValue('internalVenueNotes', value)
            }
          />
        </FormGroup>
      </FormGroupContainer>
      <>
        <FormSectionTitle>
          {intl.formatMessage({
            id: 'admin.venueEventSetup.suppliesNeeded',
          })}
        </FormSectionTitle>
        <FormGroupContainer>
          <FormGroup
            data-qaid="venue-event-setup-form-supplies-needed-notes-formgroup"
            label={intl.formatMessage({
              id: 'admin.venueEventSetup.suppliesNeeded',
            })}
            errorMsg={errorMsgForField('suppliesNeededNotes', touched, errors)}
          >
            <RichTextEditor
              name="suppliesNeededNotes"
              currentValue={values.suppliesNeededNotes}
              onChange={(value: any) =>
                setFieldValue('suppliesNeededNotes', value)
              }
            />
          </FormGroup>
        </FormGroupContainer>
        <FormSectionTitle>
          {intl.formatMessage({
            id: 'admin.shared.notesForMc',
          })}
        </FormSectionTitle>
        <FormGroupContainer>
          <FormGroup
            data-qaid="venue-event-setup-form-mc-notes-formgroup"
            label={intl.formatMessage({
              id: 'admin.shared.notesForMc',
            })}
            errorMsg={errorMsgForField('mcNotes', touched, errors)}
          >
            <RichTextEditor
              name="mcNotes"
              currentValue={values.mcNotes}
              onChange={(value: any) => setFieldValue('mcNotes', value)}
            />
          </FormGroup>
        </FormGroupContainer>
        <FormSectionTitle>
          {intl.formatMessage({
            id: 'admin.shared.checkInProcess',
          })}
        </FormSectionTitle>
        <FormGroupContainer>
          <FormGroup
            data-qaid="venue-event-setup-form-check-in-process-notes-formgroup"
            label={intl.formatMessage({
              id: 'admin.shared.checkInProcess',
            })}
            errorMsg={errorMsgForField('checkInProcessNotes', touched, errors)}
          >
            <RichTextEditor
              name="checkInProcessNotes"
              currentValue={values.checkInProcessNotes}
              onChange={(value: any) =>
                setFieldValue('checkInProcessNotes', value)
              }
            />
          </FormGroup>
        </FormGroupContainer>
        <FormSectionTitle>
          {intl.formatMessage({
            id: 'admin.venueEventSetup.stageSetup',
          })}
        </FormSectionTitle>
        <FormGroupContainer>
          <FormGroup
            data-qaid="venue-event-setup-form-stage-setup-notes-formgroup"
            label={intl.formatMessage({
              id: 'admin.venueEventSetup.stageSetup',
            })}
            errorMsg={errorMsgForField('stageSetupNotes', touched, errors)}
          >
            <RichTextEditor
              name="stageSetupNotes"
              currentValue={values.stageSetupNotes}
              onChange={(value: any) => setFieldValue('stageSetupNotes', value)}
            />
          </FormGroup>
        </FormGroupContainer>
        <FormSectionTitle>
          {intl.formatMessage({
            id: 'admin.venueEventSetup.merchTablePlacement',
          })}
        </FormSectionTitle>
        <FormGroupContainer>
          <FormGroup
            data-qaid="venue-event-setup-form-merch-table-placement-notes-formgroup"
            label={intl.formatMessage({
              id: 'admin.venueEventSetup.merchTablePlacement',
            })}
            errorMsg={errorMsgForField(
              'merchTablePlacementNotes',
              touched,
              errors
            )}
          >
            <RichTextEditor
              name="merchTablePlacementNotes"
              currentValue={values.merchTablePlacementNotes}
              onChange={(value: any) =>
                setFieldValue('merchTablePlacementNotes', value)
              }
            />
          </FormGroup>
        </FormGroupContainer>
        <FormSectionTitle>
          {intl.formatMessage({
            id: 'admin.venueEventSetup.bathroomNotes',
          })}
        </FormSectionTitle>
        <FormGroupContainer>
          <FormGroup
            data-qaid="venue-event-setup-form-bathroom-notes-formgroup"
            label={intl.formatMessage({
              id: 'admin.venueEventSetup.bathroomNotes',
            })}
            errorMsg={errorMsgForField('bathroomNotes', touched, errors)}
          >
            <RichTextEditor
              name="mcNotes"
              currentValue={values.bathroomNotes}
              onChange={(value: any) => setFieldValue('bathroomNotes', value)}
            />
          </FormGroup>
        </FormGroupContainer>
        <FormSectionTitle>
          {intl.formatMessage({
            id: 'admin.shared.internalSeatingNotes',
          })}
        </FormSectionTitle>
        <FormGroupContainer>
          <FormGroup
            data-qaid="venue-event-setup-form-internal-seating-notes-formgroup"
            label={intl.formatMessage({
              id: 'admin.shared.internalSeatingNotes',
            })}
            errorMsg={errorMsgForField('seatingNotes', touched, errors)}
          >
            <RichTextEditor
              name="internal-seating-notes-editor"
              currentValue={values.seatingNotes}
              onChange={value => {
                setFieldValue('seatingNotes', value);
              }}
            />
          </FormGroup>
        </FormGroupContainer>
        <FormSectionTitle>
          {intl.formatMessage({
            id: 'admin.shared.audioNotes',
          })}
        </FormSectionTitle>
        <FormGroupContainer>
          <FormGroup
            data-qaid="venue-event-setup-form-audio-notes-formgroup"
            label={intl.formatMessage({
              id: 'admin.shared.audioNotes',
            })}
            errorMsg={errorMsgForField('audioNotes', touched, errors)}
          >
            <RichTextEditor
              name="audioNotes"
              currentValue={values.audioNotes}
              onChange={(value: any) => setFieldValue('audioNotes', value)}
            />
          </FormGroup>
        </FormGroupContainer>
      </>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.shared.paRequired',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="max-pa-inputs-formgroup"
          label={intl.formatMessage({
            id: 'admin.shared.typeOfPa',
          })}
          errorMsg={errorMsgForField('maxPaInputs', touched, errors)}
        >
          <Select
            onChange={(opt: any) => setFieldValue('maxPaInputs', opt.value)}
            placeholder={intl.formatMessage({
              id: 'admin.shared.selectPa',
            })}
            options={maxPaInputsOptions}
            getOptionLabel={(opt: any) => opt.title}
            value={maxPaInputsOptionsMap[values.maxPaInputs]}
            initialWidth="150px"
          />
        </FormGroup>
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.venueEventSetup.artistExperience',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <Grid>
          <Col xs={12}>
            <FormGroup
              data-qaid="venue-event-setup-form-gear-storage-formgroup"
              label={intl.formatMessage({
                id: 'admin.shared.gearStorage',
              })}
              errorMsg={errorMsgForField('gearStorage', touched, errors)}
            >
              <StyledTextarea
                data-qaid="venue-event-setup-form-gear-storage-field"
                id="gearStorage"
                name="gearStorage"
                value={values.gearStorage}
                onChange={(e: any) => {
                  setFieldValue('gearStorage', e.target.value);
                }}
                maxLength={500}
              />
            </FormGroup>
          </Col>
          <Col xs={12}>
            <FormGroup
              data-qaid="venue-event-setup-form-tap-water-formgroup"
              label={intl.formatMessage({
                id: 'admin.venueEventSetup.tapWater',
              })}
            >
              <>
                <Spacer mt={4} />
                <Radio
                  id="tapWaterPresent"
                  value="true"
                  name="tapWaterPresent"
                  checked={values.hasTapWater == true}
                  onChange={() => {
                    setFieldValue('hasTapWater', true);
                  }}
                >
                  Yes
                </Radio>
                <Spacer mt={4} />
                <Radio
                  id="tapWaterNotPresent"
                  value="false"
                  name="tapWaterNotPresent"
                  checked={values.hasTapWater == false}
                  onChange={() => {
                    setFieldValue('hasTapWater', false);
                  }}
                >
                  No
                </Radio>
                <Spacer mt={4} />
                <Radio
                  id="tapWaterStatusUnknown"
                  value=""
                  name="tapWaterStatusUnknown"
                  checked={values.hasTapWater == null}
                  onChange={() => {
                    setFieldValue('hasTapWater', null);
                  }}
                >
                  Unknown
                </Radio>
              </>
            </FormGroup>
          </Col>
        </Grid>
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.venueEventSetup.restrictions',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="venue-event-setup-form-noise-restrictions-formgroup"
          label={intl.formatMessage({
            id: 'admin.venueEventSetup.noiseRestrictions',
          })}
          errorMsg={errorMsgForField('noiseRestrictions', touched, errors)}
        >
          <StyledTextarea
            data-qaid="venue-event-setup-form-noise-restrictions-field"
            id="noiseRestrictions"
            name="noiseRestrictions"
            value={values.noiseRestrictions}
            onChange={(e: any) => {
              setFieldValue('noiseRestrictions', e.target.value);
            }}
            maxLength={500}
          />
        </FormGroup>
      </FormGroupContainer>
    </GenericFormContainer>
  );
};

export default VenueBasicInfoForm;
