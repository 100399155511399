import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { Event } from 'app/typings/events';
import { dateFormatter } from 'app/shared/utils/datetime';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import {
  Body,
  Body2,
  H5,
} from 'app/shared/components/atoms/TypographyManualCSS';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { DetailsInfoMainContainer } from 'app/admin/components/atoms/DetailContent';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

const StyledDetailsInfoMainContainer = styled(DetailsInfoMainContainer)`
  h3 {
    white-space: nowrap;
  }
`;

const StyledH5 = styled(H5)`
  margin-bottom: 0;
`;

const StyledBody = styled(Body)`
  ${({ theme }) => css`
    color: ${theme.colors.backToBlack};
  `}
`;

const StyledBody2 = styled(Body2)`
  ${({ theme }) => css`
    color: ${theme.colors.backToBlack};
  `}
`;

const FeedbackContainer = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.silverSprings}
    border-radius: 8px;
    cursor: pointer;
    margin: 16px 16px;
    max-width: 400px;
    padding: 32px 32px;
    width: 100%;

    ${theme.media.xs`
      max-width: 400px;
      margin: 16px 0px;
    `};

    ${theme.media.md`
      max-width: 600px;
      margin: 16px 16px;
    `};
  `}
`;

const CrewFeedbackableEvents: React.FC = ({
  contentProps,
  navigateTo,
}: any) => {
  const intl = useIntl();

  const { eventsPendingFeedback } = contentProps;

  const handleEventClick = (eventId: string) => {
    navigateTo({
      routeName: 'crew-feedback-event',
      routeProps: {
        eventId,
        eventsPendingFeedback,
      },
    });
  };

  return (
    <ModalContentContainer data-qaid="crew-feedbackable-events">
      <StyledDetailsInfoMainContainer>
        <DetailsHeader
          title={intl.formatMessage({
            id: 'admin.crewPortal.crewFeedback.concertFeedback',
          })}
          subTitle={
            !eventsPendingFeedback || eventsPendingFeedback.length === 0
              ? intl.formatMessage({
                  id: 'admin.crewPortal.crewFeedback.feedbackComplete',
                })
              : intl.formatMessage({
                  id: 'admin.crewPortal.crewFeedback.feedbackRequest',
                })
          }
        />
      </StyledDetailsInfoMainContainer>

      {eventsPendingFeedback &&
        eventsPendingFeedback.length > 0 &&
        eventsPendingFeedback.map((event: Event, i: number) => (
          <FeedbackContainer key={i} onClick={() => handleEventClick(event.id)}>
            <StyledH5>{event.city?.title}</StyledH5>
            <StyledBody2>{event.venue?.neighborhood?.title}</StyledBody2>
            <Spacer mt={4} />
            <StyledBody>
              {dateFormatter(event.localStartsAt, 'shortMonthDateAndYear')}
            </StyledBody>
            <StyledBody>
              {event.venue?.venueName || event.venue?.address}
            </StyledBody>
            <StyledBody>
              {event.performances &&
                event.performances
                  .map(
                    (performance: any) =>
                      performance.artist && performance.artist.title
                  )
                  .join(', ')}
            </StyledBody>
          </FeedbackContainer>
        ))}
    </ModalContentContainer>
  );
};

export default CrewFeedbackableEvents;
