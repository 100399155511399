import React from 'react';
import styled from 'styled-components';

interface Props {
  children: any;
  'data-qaid'?: string;
}

const Layout = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const LoadingLayout: React.FC<Props> = ({
  children,
  'data-qaid': dataQaid,
}) => <Layout data-qaid={dataQaid}>{children}</Layout>;

export default LoadingLayout;
