import React from 'react';
import { useIntl } from 'react-intl';

import { get } from 'app/shared/utils/get';
import {
  artistSocialUrls,
  getSocialUrlsFormInitialValues,
  getSocialUrlsSubmitVariable,
} from 'app/shared/utils/socialLinks';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import { UpdateArtist } from 'app/shared/graphql/artists/mutationHooks';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { DetailsFormMainContainer } from 'app/admin/components/atoms/DetailContent';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

import ArtistSocialsEditForm from './ArtistSocialsEditForm';
import ArtistSocialsEditFormSchema from './ArtistSocialsEditFormSchema';

interface Props {
  contentProps?: any;
  setFormSubmitAction: (func: Function) => void;
  setDisplayConfirmation: (set: boolean) => void;
  setIsSubmitting: (set: boolean) => void;
  navigateTo: (routeData: object) => void;
}

const ArtistSocialsEdit: React.FC<Props> = ({
  contentProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  navigateTo,
}) => {
  const intl = useIntl();

  const formInitialValues = {
    socialUrls: getSocialUrlsFormInitialValues(
      artistSocialUrls,
      contentProps,
      'artistData.socialUrls'
    ),
  };

  const updateArtistAction = UpdateArtist();

  const handleUpdateArtist = useSubmitAction({
    submitAction: updateArtistAction,
    submitVariables: (values: any) => ({
      artistId: get(contentProps, 'artistData.id', undefined),
      socialUrls: getSocialUrlsSubmitVariable(values),
    }),
    successMsg: intl.formatMessage({
      id: 'admin.artistBasicInfo.form.successMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.artistBasicInfo.form.failureMessage',
    }),
    onSuccess: (response: any) => {
      navigateTo({
        routeName: 'artist-details',
        routeProps: {
          defaultOpenSection: 'media',
          ...response.data.updateArtist.artist,
        },
      });
    },
  });

  return (
    <ModalContentContainer>
      <DetailsFormMainContainer>
        <DetailsHeader
          title={get(contentProps, 'artistData.title', '')}
          imageUrl={get(contentProps, 'artistData.imageUrl', '')}
          largeImageUrl={get(contentProps, 'artistData.largeImageUrl', '')}
          imageStyle="circle"
        />
        <GenericForm
          formInitialValues={formInitialValues}
          renderFormComponent={(renderProps: any) => (
            <ArtistSocialsEditForm formikProps={renderProps.formikProps} />
          )}
          onSubmit={handleUpdateArtist}
          formSchema={ArtistSocialsEditFormSchema(intl)}
          setFormSubmitAction={setFormSubmitAction}
          setDisplayConfirmation={setDisplayConfirmation}
          setIsSubmitting={setIsSubmitting}
          dataQaId="artist-socials-edit-form"
        />
      </DetailsFormMainContainer>
    </ModalContentContainer>
  );
};

export default ArtistSocialsEdit;
