import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { errorMsgForField } from 'app/shared/utils/form';
import { Checkbox } from 'app/shared/components/atoms/CheckboxManualCSS';
import {
  FormGroupContainer,
  FormSectionTitle,
} from 'app/shared/components/atoms/FormContent';
import GenericFormContainer from 'app/shared/components/atoms/GenericFormContainer';
import StyledTextarea from 'app/shared/components/atoms/SmallTextarea';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Textfield } from 'app/shared/components/atoms/Textfield';
import FormGroup from 'app/shared/components/molecules/FormGroup';
import { ARTIST_APPLICATION_EMAIL_FIELDS } from 'app/admin/components/organisms/CityEmailsEdit';
import FromEmailSettingsEditForm from 'app/admin/components/organisms/FromEmailSettingsEditForm';

interface FormProps {
  formikProps: any;
  cityCachedSlug?: string;
  cityOperatingModel?: string;
}

const StyledTextfield = styled(Textfield)`
  ${({ theme }) => css`
    display: block;
    width: 100%;
    ${theme.media.lg`
      width: 48%;
    `};
  `}
`;
const StyledFormGroup = styled(FormGroup)`
  margin-bottom: 10px;
`;

const CityEmailsEditForm: React.FC<FormProps> = ({
  formikProps,
  cityCachedSlug,
  cityOperatingModel,
}) => {
  const intl = useIntl();

  const { setFieldValue, touched, errors, values } = formikProps;

  return (
    <GenericFormContainer dataQaId="city-emails-section-edit-form">
      <FromEmailSettingsEditForm
        setFieldValue={setFieldValue}
        touched={touched}
        errors={errors}
        values={values}
        cityCachedSlug={cityCachedSlug}
        overrideHelpTextType={
          cityOperatingModel ==
          ('sofar_operated' || 'sofar_operated_and_curator')
            ? 'city_o_and_o'
            : 'city_curator'
        }
      />
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.cityEmails.form.emailBlurbs',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          label={intl.formatMessage({
            id: 'admin.cityEmails.form.inviteEmailMessage',
          })}
          data-qaid="city-emails-form-invite-email-message-formgroup"
          errorMsg={errorMsgForField('inviteEmailMessage', touched, errors)}
        >
          <StyledTextarea
            data-qaid="city-emails-form-invite-email-message-field"
            id="inviteEmailMessage"
            name="inviteEmailMessage"
            value={values.inviteEmailMessage}
            onChange={(e: any) => {
              setFieldValue('inviteEmailMessage', e.target.value);
            }}
            maxLength={1500}
          />
        </FormGroup>
        <Spacer mb={6} />
        <FormGroup
          label={intl.formatMessage({
            id: 'admin.cityEmails.form.revealAddressEmailMessage',
          })}
          data-qaid="city-emails-form-reveal-address-email-message-formgroup"
          errorMsg={errorMsgForField(
            'revealAddressEmailMessage',
            touched,
            errors
          )}
        >
          <StyledTextarea
            data-qaid="city-emails-form-reveal-address-email-message-field"
            id="revealAddressEmailMessage"
            name="revealAddressEmailMessage"
            value={values.revealAddressEmailMessage}
            onChange={(e: any) => {
              setFieldValue('revealAddressEmailMessage', e.target.value);
            }}
            maxLength={1500}
          />
        </FormGroup>
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.cityEmails.form.automatedEmails',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <Checkbox
          id="city-emails-section-edit-form-is-automated-email-enabled-field"
          data-qaid="city-emails-section-edit-form-is-automated-email-enabled-field"
          checked={values.isAutomatedEmailEnabled}
          name="isAutomatedEmailEnabled"
          onChange={e =>
            setFieldValue('isAutomatedEmailEnabled', e.target.checked)
          }
        >
          {intl.formatMessage({
            id: 'admin.cityEmails.form.isAutomatedEmailEnabled',
          })}
        </Checkbox>
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.cityEmails.form.artistApplications',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <Checkbox
          id="city-emails-section-edit-form-artist-application-notifications-field"
          data-qaid="city-emails-section-edit-form-artist-application-notifications-field"
          checked={values.isArtistApplicationNotificationEnabled}
          name="isArtistApplicationNotificationEnabled"
          onChange={e =>
            setFieldValue(
              'isArtistApplicationNotificationEnabled',
              e.target.checked
            )
          }
        >
          {intl.formatMessage({
            id: 'admin.cityEmails.form.isArtistApplicationNotificationEnabled',
          })}
        </Checkbox>
        <Spacer mb={6} />
        {ARTIST_APPLICATION_EMAIL_FIELDS.map(
          (emailField: string, index: number) => {
            const i = index + 1;
            return (
              <StyledFormGroup
                label={
                  index == 0
                    ? intl.formatMessage({
                        id:
                          'admin.cityEmails.form.artistApplicationNotificationEmail',
                      })
                    : undefined
                }
                data-qaid={`city-emails-info-form-artist-application-notification-email-${i}-formgroup`}
                errorMsg={errorMsgForField(
                  `artistApplicationNotificationEmails.${emailField}`,
                  touched,
                  errors
                )}
                key={i}
              >
                <StyledTextfield
                  data-qaid={`city-emails-info-form-artist-application-notification-email-field-${i}`}
                  id={`${emailField}`}
                  name={`${emailField}`}
                  value={
                    values.artistApplicationNotificationEmails[`${emailField}`]
                  }
                  onChange={(e: any) => {
                    setFieldValue(
                      `artistApplicationNotificationEmails[${emailField}]`,
                      e.target.value
                    );
                  }}
                  maxLength={100}
                />
              </StyledFormGroup>
            );
          }
        )}
      </FormGroupContainer>
    </GenericFormContainer>
  );
};

export default CityEmailsEditForm;
