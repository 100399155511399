import React from 'react';
import styled, { css } from 'styled-components';

import { KeyValue } from 'app/typings/generics';
import { BaseLink } from 'app/shared/components/atoms/LinkManualCSS';
import TruncatedByCharText from 'app/shared/components/atoms/TruncatedByCharText';
import {
  Body2,
  Overline,
} from 'app/shared/components/atoms/TypographyManualCSS';

interface Props {
  keysAndValues: KeyValue[];
  valueTruncateCharLength?: number;
  dataQaidPrefix?: string;
}

const InfoRow = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    height: auto;
    align-items: flex-end;
    padding-top: ${theme.ruler[1]}px;
  `}
`;

const InfoLeft = styled.div`
  flex-grow: 1;
  text-align: left;
  display: flex;
`;

const InfoRight = styled.div`
  flex-grow: 1;
  text-align: right;
`;

const InfoRightContents = styled.div`
  display: flex;
  width: 100%;
  align-content: flex-end;
`;

const InfoTextWrapper = styled.div`
  flex-grow: 1;
  padding-left: 2px;
`;

export const InfoText = styled(Body2)`
  ${({ theme }) => css`
    ${theme.media.xs`
      font-size: 12px;
    `}
  `}
`;

const Link = styled(BaseLink)`
  ${({ theme }) => css`
    color: ${theme.colors.green600};
  `}
`;

export const KeyValueInfoList: React.FC<Props> = ({
  keysAndValues,
  valueTruncateCharLength = 15,
  dataQaidPrefix,
}) => {
  return (
    <div
      data-qaid={
        dataQaidPrefix
          ? `${dataQaidPrefix}-key-value-info-list`
          : 'key-value-info-list'
      }
    >
      {keysAndValues.map(obj => (
        <InfoRow key={obj.key}>
          <InfoLeft>
            <Overline>{obj.key}</Overline>
          </InfoLeft>
          <InfoRight>
            {obj.url && !obj.valueForUrl && (
              <InfoText>
                <Link href={obj.url} openInNewTab={true}>
                  {obj.value}
                </Link>
              </InfoText>
            )}
            {obj.url && obj.valueForUrl && (
              <InfoRightContents>
                <InfoTextWrapper>
                  <InfoText>{obj.value}</InfoText>
                </InfoTextWrapper>
                <InfoTextWrapper>
                  <InfoText>
                    (
                    <Link href={obj.url} openInNewTab={true}>
                      {obj.valueForUrl}
                    </Link>
                    )
                  </InfoText>
                </InfoTextWrapper>
              </InfoRightContents>
            )}
            {!obj.url && !obj.renderValue && (
              <InfoText>
                <TruncatedByCharText
                  text={obj.value}
                  truncateLength={
                    obj.valueTruncateCharLength || valueTruncateCharLength
                  }
                />
              </InfoText>
            )}
            {obj.renderValue && obj.renderValue(obj.value)}
          </InfoRight>
        </InfoRow>
      ))}
    </div>
  );
};
