import React from 'react';
import styled, { css } from 'styled-components';

import { withShadow } from 'app/shared/components/styleUtils/manualCSS/withShadow';
import { withTextfieldStyle } from 'app/shared/components/styleUtils/manualCSS/withTextfieldStyle';
import { Icon } from 'app/shared/components/atoms/IconManualCSS';

interface SelectInputProps {
  isOpen?: boolean;
  readonly?: boolean;
}

interface InputProps {
  theme: any;
  hasError?: boolean;
}

interface InputWrapper {
  children?: any;
  isOpen?: boolean;
  hasError?: boolean;
}

interface Props {
  placeholder?: string;
  readonly?: boolean;
  isOpen?: boolean;
  innerRef?: React.RefObject<any>;
  value?: any;
  hasError?: boolean;
  name?: string;
  id?: string;
  'data-qaid'?: string;
  onSearchChange: (e: string) => void;
  autoFocus?: boolean;
}

interface ButtonProps {
  isOpen?: boolean;
  children?: any;
}

const Button = styled.button<ButtonProps>`
${({ theme }) => css`
  flex-basis: 50px;
  border: none;
  &:hover {
    cursor: pointer;
  }
  &:focus {
    border-color: ${theme.colors.macyGrey}
    outline: none;
  `}
  }
`;

const InputWrapper = styled.div<InputWrapper>`
  ${({ isOpen }) => css`
    display: flex;
    flex: center;
    ${withTextfieldStyle};
    padding-right: 0px;

    ${isOpen &&
      css`
        cursor: pointer;
        ${withShadow({ depth: 3 })}
      `}
  `}
`;

const InputStyle = styled.input<InputProps & SelectInputProps>`
  ${({ theme, readOnly }) => css`
    border-right: 0px;
    width: 100%;
    border: 0px;
    font-size: ${theme.fontSizes.body2.xs};
    letter-spacing: 0.1px;
    color: ${theme.colors.backToBlack};

    ${theme.media.xs`
      font-size: 16px;
    `};

    ${theme.media.md`
      font-size: 14px;
    `}

    &:hover {
      outline: none;
    }

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: ${theme.colors.blueSmoke};
    }

    ${readOnly &&
      css`
        cursor: pointer;
      `}
  `}
`;

const Input: React.FC<Props> = ({
  isOpen,
  placeholder,
  innerRef,
  value = '',
  readonly,
  hasError,
  name,
  id,
  'data-qaid': qaId,
  onSearchChange,
  autoFocus = false,
}) => {
  const handleChange = (e: any) => {
    onSearchChange(e.target.value);
  };

  return (
    <InputWrapper
      id={id}
      hasError={hasError}
      isOpen={isOpen}
      ref={innerRef}
      data-qaid={qaId}
    >
      <InputStyle
        value={value}
        readOnly={readonly}
        isOpen={isOpen}
        placeholder={placeholder}
        name={name}
        data-qaid={`${qaId}-input`}
        onChange={handleChange}
        autoFocus={autoFocus}
        autoComplete="off"
      />
      <Button isOpen={isOpen} type="button" data-qaid={`${qaId}-toggle`}>
        <Icon name={isOpen ? 'caretUp' : 'caretDown'} />
      </Button>
    </InputWrapper>
  );
};

export default Input;
