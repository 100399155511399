import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { City } from 'app/typings';
import { KeyValue } from 'app/typings/generics';
import { dateFormatter } from 'app/shared/utils/datetime';
import { buildFullName } from 'app/shared/utils/string';
import { KeyAboveValueInfoList } from 'app/admin/components/molecules/KeyAboveValueInfoList';
import ListingCard from 'app/admin/components/organisms/ListingCard';
import { birthYearPreferNotToSayOption } from 'app/admin/components/organisms/UserBasicInfoEdit/options';
import { ReactComponent as EmailActionUnread } from 'icons/streamline-regular/emails/email-actions/email-action-unread.svg';

interface UserCardProps {
  id: number;
  index: number;
  firstName: string;
  lastName: string;
  email: string;
  homeCity: City;
  cityOther?: string;
  gender: string;
  birthYear: string;
  currentSignInAt: string;
  createdAt: string;
  signInCount: number;
  onShowDetails: Function;
}

const TopContentContainer = styled.div`
  height: 100px;
`;

const getGenderAbbreviated = (gender: string) =>
  gender.replace(/ \/ Prefer not to say/, '');

const getAge = (birthYear: string) => {
  const currentYear = new Date().getFullYear();
  return (currentYear - Number(birthYear)).toString();
};

const UserCard: React.FC<UserCardProps> = ({
  id,
  index,
  firstName,
  lastName,
  email,
  homeCity,
  cityOther,
  gender,
  birthYear,
  currentSignInAt,
  createdAt,
  signInCount,
  onShowDetails,
}) => {
  const intl = useIntl();

  const keysAboveValuesList = [
    {
      key: intl.formatMessage({
        id: 'admin.users.listingCard.city',
      }),
      value: homeCity ? homeCity.title : cityOther || 'None',
    },
    {
      key: intl.formatMessage({
        id: 'shared.email',
      }),
      value: email || 'None',
    },
  ];

  const keysAndValuesList = [
    {
      key: intl.formatMessage({
        id: 'admin.users.listingCard.gender',
      }),
      value: gender ? getGenderAbbreviated(gender) : 'None',
    },
    {
      key: intl.formatMessage({
        id: 'admin.users.listingCard.age',
      }),
      value: birthYear
        ? birthYear === birthYearPreferNotToSayOption
          ? 'Prefer none'
          : getAge(birthYear)
        : 'None',
    },
    {
      key: intl.formatMessage({
        id: 'admin.users.listingCard.logins',
      }),
      value: signInCount.toString(),
    },
    {
      key: intl.formatMessage({
        id: 'admin.users.listingCard.lastLogin',
      }),
      value: currentSignInAt
        ? dateFormatter(currentSignInAt, 'shortMonthDateAndYear')
        : 'None',
    },
    {
      key: intl.formatMessage({
        id: 'admin.users.listingCard.signup',
      }),
      value: dateFormatter(createdAt, 'shortMonthDateAndYear'),
    },
  ];

  const controlsInfoList = [
    {
      href: email ? `mailto:${email || ''}` : '',
      icon: EmailActionUnread,
      tipText: intl.formatMessage({
        id: 'admin.users.listingCard.emailUser',
      }),
    },
  ];

  const userName = buildFullName(firstName, lastName);

  const renderTopContent = (keysAboveValuesList: KeyValue[]) => {
    return (
      <TopContentContainer>
        <KeyAboveValueInfoList keysAndValues={keysAboveValuesList} />
      </TopContentContainer>
    );
  };

  const onShowDetailsParams = {
    id,
    firstName,
    lastName,
    currentSignInAt,
    createdAt,
    signInCount,
    index,
  };

  return (
    <>
      <ListingCard
        title={
          userName ||
          intl.formatMessage({
            id: 'admin.users.details.noName',
          })
        }
        topContent={() => renderTopContent(keysAboveValuesList)}
        keysAndValuesList={keysAndValuesList}
        controlsInfoList={controlsInfoList}
        onShowDetails={onShowDetails}
        onShowDetailsParams={onShowDetailsParams}
        showDetailsTipText={intl.formatMessage({
          id: 'admin.users.listingCard.userDetails',
        })}
        dataQaidPrefix="users"
        index={index}
        height="330px"
      />
    </>
  );
};

export default UserCard;
