import React, { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { get } from 'app/shared/utils/get';
import { GetCitiesForGroupByBusinessOwner } from 'app/shared/graphql/cities/queryHooks';
import { ListPage, ListPageContext } from 'app/shared/context/ListPage';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import { LoadingBlocks } from 'app/shared/components/atoms/LoadingBlocks';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import RoutableModal from 'app/shared/components/molecules/RoutableModal';
import { GlobalError } from 'app/shared/components/pages/Status';
import {
  getBusinessOwnerOptions,
  getCityWithBusinessOwnerOptions,
  getFilterNamesAndOptionsForTitleMapping,
} from 'app/admin/utils/optionLists';
import { GetBusinessOwners } from 'app/admin/graphql/businessOwners/queryHooks';
import { GetUsersForCrewDirectory } from 'app/admin/graphql/users/queryHooks';
import ListingFooter from 'app/admin/components/molecules/ListingFooter';
import ListingNoResults from 'app/admin/components/molecules/ListingNoResults';
import CardGrid, { CardType } from 'app/admin/components/organisms/CardGrid';
import CrewUserCard from 'app/admin/components/organisms/CrewUserCard';
import ListingControls from 'app/admin/components/organisms/ListingControls';
import ListingFilter from 'app/admin/components/organisms/ListingFilter';
import ListingHeader from 'app/admin/components/organisms/ListingHeader';
import ListPageTemplate from 'app/admin/components/templates/ListPage';
import Layout from 'app/admin/layouts/ListPage';

import { crewStatusOptions, eventRoleOptions, sortOptions } from './options';
import Analytics from './tracking';

const pageStateConfig = {
  filterNames: [
    'event_role',
    'event_role_city',
    'event_role_business_owner',
    'crew_status',
  ],
  defaultSort: { by: 'last_staffed_event_at', direction: 'desc' },
  textSearchParamName: 'user_search',
  idParamName: 'user_id',
};

const AdminCrew: React.FC = () => {
  const intl = useIntl();
  const pageState = useContext(ListPageContext);
  const PER_PAGE = 18;

  const {
    loading: loadingCities,
    error: errorCities,
    data: dataCities,
  } = GetCitiesForGroupByBusinessOwner({
    activeStatus: 'all',
    businessOwner: pageState.filterListVariable('event_role_business_owner'),
  });

  const {
    loading: loadingBusinessOwners,
    error: errorBusinessOwners,
    data: dataBusinessOwners,
  } = GetBusinessOwners({
    archivedStatus: 'not_archived',
    skipPagination: true,
  });

  const cityOptions = getCityWithBusinessOwnerOptions(dataCities);
  const businessOwnerOptions = getBusinessOwnerOptions(dataBusinessOwners);

  const businessOwnerFilterIsApplied =
    pageState.filterListVariable('event_role_business_owner') &&
    // @ts-ignore
    pageState.filterListVariable('event_role_business_owner').length > 1;

  const filterDropdownOptionsInfoList = [
    {
      filterName: 'event_role_business_owner',
      dropdownParams: {
        searchBar: false,
        title: intl.formatMessage({
          id: 'admin.users.filter.roleBusinessOwner',
        }),
        options: businessOwnerOptions,
      },
    },
    {
      filterName: 'event_role_city',
      filterDependentOn: businessOwnerFilterIsApplied
        ? 'event_role_business_owner'
        : undefined,
      // @ts-ignore
      dropdownParams: {
        searchBar: true,
        title: intl.formatMessage({
          id: 'admin.users.filter.roleCity',
        }),
        groupBy: businessOwnerFilterIsApplied
          ? 'businessOwnerGroupName'
          : 'country',
        // @ts-ignore
        groupById: businessOwnerFilterIsApplied
          ? 'businessOwnerCachedSlug'
          : 'country',
        options: cityOptions,
      },
    },
    {
      filterName: 'event_role',
      dropdownParams: {
        title: intl.formatMessage({
          id: 'admin.shared.roles',
        }),
        options: eventRoleOptions(intl),
      },
    },
    {
      filterName: 'crew_status',
      dropdownParams: {
        title: intl.formatMessage({
          id: 'admin.users.filter.status',
        }),
        options: crewStatusOptions(intl),
      },
    },
  ];

  const { loading, error, data } = GetUsersForCrewDirectory({
    userSearch: pageState.textSearchState,
    eventRole: pageState.filterListVariable('event_role'),
    eventRoleCity: pageState.filterListVariable('event_role_city'),
    eventRoleBusinessOwner: pageState.filterListVariable(
      'event_role_business_owner'
    ),
    isCrew: true,
    crewStatus: pageState.filterListVariable('crew_status') || 'active',
    orderBy: pageState.sortState.by,
    orderDirection: pageState.sortState.direction,
    page: pageState.page,
    perPage: PER_PAGE,
  });

  useEffect(() => {
    pageState.updateScrollPositionOnPage();
  }, [pageState.detailsModal.isShowing]);

  useEffect(() => {
    pageState.updateDetailsModal();
  }, []);

  useEffect(() => {
    pageState.setupFilterLabelTitleMapping(
      getFilterNamesAndOptionsForTitleMapping(filterDropdownOptionsInfoList)
    );
  }, [dataCities, dataBusinessOwners]);

  useEffect(() => {
    pageState.handleRemoveOrphanDependentFilters({
      parentFilter: 'event_role_business_owner',
      dependentFilter: 'event_role_city',
      dependentFilterOptions: cityOptions,
    });
  }, [cityOptions, pageState]);

  useEffect(() => {
    pageState.updatePageUrl();
  }, [
    pageState.page,
    pageState.sortState,
    pageState.filterState,
    pageState.textSearchState,
    pageState.detailData,
    pageState.detailsModal.isShowing,
  ]);

  useEffect(() => {
    Analytics.pushDataLayer();
  }, [pageState.filterState, pageState.textSearchState]);

  if (
    (!loading && !data) ||
    error ||
    (!loadingCities && !dataCities) ||
    errorCities ||
    (!loadingBusinessOwners && !dataBusinessOwners) ||
    errorBusinessOwners
  ) {
    return <GlobalError />;
  }

  const modalsContent = () => (
    <>
      {pageState.detailsModal.isShowing && (
        <RoutableModal
          hide={pageState.detailsModal.hide}
          initialRouteProps={{
            ...pageState.detailData,
          }}
          initialRouteName="crew-user-details"
          dataQaidSuffix="admin-crew-user-details"
        />
      )}
    </>
  );

  return (
    <Layout scrollDisabled={pageState.detailsModal.isShowing}>
      <ListPageTemplate
        pageTitle={intl.formatMessage({
          id: 'admin.crewDirectory.title',
        })}
        modalsContent={modalsContent}
      >
        <ListingHeader
          pageTitle={intl.formatMessage({
            id: 'admin.crewDirectory.title',
          })}
          searchPlaceholder={intl.formatMessage({
            id: 'admin.users.textSearch.simplePlaceholder',
          })}
          searchValue={pageState.textSearchState}
          onSearch={pageState.handleTextSearch}
          dataQaidPrefix="crew-directory"
        />
        <div>
          <ListingControls
            sortOptions={sortOptions}
            orderBy={pageState.sortState.by}
            orderDirection={pageState.sortState.direction}
            totalRecords={data && data.users.metadata.totalRecords}
            loading={loading}
            onSort={pageState.handleSortChange}
            onReset={pageState.handleResetFilters}
            dataQaidPrefix="crew-directory"
          />
          <ListingFilter
            filterTitle={intl.formatMessage({
              id: 'admin.users.filterTitle',
            })}
            textSearchString={pageState.textSearchState}
            handleTextSearchLabelClose={pageState.handleTextSearchLabelClose}
            labelTitleMapping={pageState.filterLabelTitleMapping}
            dropdownOptionsInfoList={filterDropdownOptionsInfoList}
            filterState={pageState.filterState}
            handleRemoveFilter={pageState.handleRemoveFilter}
            handleFilterChange={pageState.handleFilterChange}
          />
        </div>

        <div>
          <CardGrid
            objectData={get(data, 'users.users', [])}
            renderCardComponent={(user: CardType, i: number) => (
              // @ts-ignore
              <CrewUserCard
                index={i}
                onShowDetails={pageState.toggleDetailsModalAndSetDetailData}
                {...user}
              />
            )}
            dataQaid="admin-crew-users-list"
            loading={loading}
            loadingComponent={
              <LoadingBlocks.Rectangle width="100%" height="420px" />
            }
            hideDividerOnSize="xs"
          />
          <DottedLine />
          <Spacer mb={2} />
        </div>

        <ListingNoResults
          entityName={intl.formatMessage({
            id: 'admin.users.noResultsEntityName',
          })}
          numResults={get(data, 'users.users.length', undefined)}
          loading={loading}
        />

        <ListingFooter
          numTotalRecords={get(data, 'users.metadata.totalRecords', undefined)}
          perPage={PER_PAGE}
          currentPage={pageState.page}
          onPageChange={pageState.handlePageChange}
          loading={loading}
          dataQaidPrefix="crew-directory"
        />
      </ListPageTemplate>
    </Layout>
  );
};

const AdminCrewWrapper: React.FC = () => (
  <ListPage config={pageStateConfig} analytics={Analytics}>
    <AdminCrew />
  </ListPage>
);

export default AdminCrewWrapper;
