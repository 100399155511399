import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Event } from 'app/typings/events';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import {
  InlineHtml,
  SectionText,
  SectionTitle,
  SectionWithIcon,
} from 'app/admin/components/atoms/CrewEventContent';
import IconInCircle from 'app/admin/components/atoms/IconInCircle';
import { ReactComponent as ConcertDj } from 'icons/streamline-regular/entertainment-events-hobbies/concerts-night-clubs/concert-dj.svg';

interface EventInfoProps {
  event: Event;
}

export const audioEquipmentMapping = {
  unknown: 'Unknown',
  acoustic: 'Acoustic',
  two_ch: '2-channel PA',
  ten_ch: '10-channel PA',
};

const Subheading = styled.span`
  font-weight: 600;
`;

const getAudioRoles = (event: Event, intl: any) => {
  let audioRoles = [];

  if (event.isAudioStaffMemberActivated) {
    audioRoles.push(intl.formatMessage({ id: 'admin.shared.audioEngineer' }));
  }

  if (event.isGearRunnerStaffMemberActivated) {
    audioRoles.push(intl.formatMessage({ id: 'admin.shared.gearSupport' }));
  }

  if (audioRoles.length > 0) {
    return audioRoles.join(', ');
  }

  return null;
};

const getAudioEquipment = (event: Event) => {
  return audioEquipmentMapping[event.maxPaInputs.key];
};

const CrewEventAudioInfo: React.FC<EventInfoProps> = ({ event }) => {
  const intl = useIntl();
  return (
    <SectionWithIcon>
      <SectionTitle>
        <IconInCircle
          borderCircle={true}
          circleBackgroundColor="whiteDenim"
          dataQaIdSuffix="concert-dj"
          iconColor="greenOnions"
          iconName={ConcertDj}
        />
        {intl.formatMessage({ id: 'admin.crewEvent.section.audioInfo' })}
      </SectionTitle>
      <SectionText>
        <Spacer mt={4} />
        <>
          <Subheading>
            {intl.formatMessage({ id: 'admin.crewEvent.audioInfo.audioRoles' })}
            :{' '}
          </Subheading>
          {getAudioRoles(event, intl) || intl.formatMessage({ id: 'none' })}
        </>
        <Spacer mt={4} />
        <>
          <Subheading>
            {intl.formatMessage({
              id: 'admin.crewEvent.audioInfo.audioEquipment',
            })}
            :{' '}
          </Subheading>
          {getAudioEquipment(event) || intl.formatMessage({ id: 'none' })}
        </>
        <Spacer mt={4} />
        <>
          <Subheading>
            {intl.formatMessage({ id: 'admin.crewEvent.audioInfo.isFilmed' })}:{' '}
          </Subheading>
          {event.isFilmed
            ? intl.formatMessage({ id: 'yes' })
            : intl.formatMessage({ id: 'no' })}
        </>
        <Spacer mt={4} />
        <>
          <Subheading>
            {intl.formatMessage({ id: 'admin.crewEvent.audioInfo.audioNotes' })}
            :{' '}
          </Subheading>
          <InlineHtml
            dangerouslySetInnerHTML={{
              __html: event?.audioNotes || intl.formatMessage({ id: 'none' }),
            }}
          />
        </>
      </SectionText>
    </SectionWithIcon>
  );
};

export default CrewEventAudioInfo;
