import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Venue } from 'app/typings';
import LazyImgixImage from 'app/shared/components/atoms/LazyImgixLoad';
import ThumbnailCircle from 'app/admin/components/atoms/ThumbnailCircle';

import AudienceReaction from './AudienceReaction';
import CrewEventSurveyCard, {
  CrewEventSurveyCardTitle,
} from './CrewEventSurveyCard';

interface Props {
  venue: Venue;
  onChange: (value: any) => void;
  selectedValue: string;
}

const ThumbnailContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
`;

const AudienceReactionVenue: React.FC<Props> = ({
  venue,
  onChange,
  selectedValue,
}) => {
  const intl = useIntl();
  return (
    <>
      <CrewEventSurveyCard>
        <CrewEventSurveyCardTitle>
          {intl.formatMessage({ id: 'admin.crewPortal.crewFeedback.toVenue' })}
        </CrewEventSurveyCardTitle>
        <ThumbnailContainer>
          <ThumbnailCircle>
            {venue?.imageUrl && (
              <LazyImgixImage src={venue.imageUrl} sizes="44px" />
            )}
          </ThumbnailCircle>
          {venue?.venueName}
        </ThumbnailContainer>
        <AudienceReaction
          idNamePrefix="venue"
          onChange={onChange}
          selectedValue={selectedValue}
        />
      </CrewEventSurveyCard>
    </>
  );
};

export default AudienceReactionVenue;
