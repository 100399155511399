import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { City } from 'app/typings/cities';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import BooleanSetting from 'app/admin/components/atoms/BooleanSetting';
import {
  DetailHeading as Heading,
  DetailValueContainer as ValueContainer,
} from 'app/admin/components/atoms/DetailContent';
import ExpandableText from 'app/admin/components/atoms/ExpandableText';
import { None } from 'app/admin/components/atoms/None';
import FromEmailSettings from 'app/admin/components/organisms/FromEmailSettings';

interface Props {
  sectionData: City;
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const SectionEmails: React.FC<Props> = ({ sectionData }) => {
  const intl = useIntl();

  return (
    <MainContainer data-qaid="section-emails">
      <Grid cols={4}>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={1}>
          <FromEmailSettings
            useFromEmailForAllEmails={sectionData.useFromEmailForAllEmails}
            fromEmail={sectionData.fromEmail}
            mainArtistOps={sectionData.mainArtistOps}
            mainCrewOps={sectionData.mainCrewOps}
            mainVenueOps={sectionData.mainVenueOps}
          ></FromEmailSettings>
        </Col>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={2}>
          <Heading>
            {intl.formatMessage({
              id: 'admin.cityEmails.heading.inviteEmailMessage',
            })}
          </Heading>
          <ExpandableText
            text={sectionData.inviteEmailMessage}
            truncateLength={200}
          />
          <Heading>
            {intl.formatMessage({
              id: 'admin.cityEmails.heading.revealAddressEmailMessage',
            })}
          </Heading>
          <ExpandableText
            text={sectionData.revealAddressEmailMessage}
            truncateLength={200}
          />
        </Col>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={3}>
          <BooleanSetting
            value={sectionData.isAutomatedEmailEnabled}
            headingText={intl.formatMessage({
              id: 'admin.cityEmails.heading.isAutomatedEmailEnabled',
            })}
          />
          <BooleanSetting
            value={sectionData.isArtistApplicationNotificationEnabled}
            headingText={intl.formatMessage({
              id:
                'admin.cityEmails.heading.isArtistApplicationNotificationEnabled',
            })}
          />
          <Heading>
            {intl.formatMessage({
              id: 'admin.cityEmails.heading.artistApplicationNotificationEmail',
            })}
          </Heading>
          <ValueContainer>
            {sectionData.artistApplicationNotificationEmails &&
            sectionData.artistApplicationNotificationEmails.length > 0 ? (
              sectionData.artistApplicationNotificationEmails.map(
                (email: string, index: number) => {
                  return <div key={index}> {email} </div>;
                }
              )
            ) : (
              <None />
            )}
          </ValueContainer>
        </Col>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={4}></Col>
      </Grid>
    </MainContainer>
  );
};

export default SectionEmails;
