import React from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Event } from 'app/typings/events';
import { dateFormatter } from 'app/shared/utils/datetime';
import { eventAttendeeOccasions } from 'app/shared/utils/events';
import { get } from 'app/shared/utils/get';
import { useFeatureFlags } from 'app/shared/utils/useFeatureFlags';
import usePermission from 'app/shared/utils/usePermission';
import { LoadingBlocks } from 'app/shared/components/atoms/LoadingBlocks';
import { LoadingError } from 'app/shared/components/atoms/LoadingError';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import TruncatedByCharText from 'app/shared/components/atoms/TruncatedByCharText';
import SEO from 'app/shared/components/molecules/SEO';
import Layout from 'app/shared/layouts/Print';
import { GetAttendees } from 'app/admin/graphql/attendees/queryHooks';
import { GetEventInfo } from 'app/admin/graphql/events/queryHooks';
import { numGuestsTotal } from 'app/admin/components/organisms/GuestlistDayOfShow';
import { GuestlistTickets } from 'app/admin/components/organisms/GuestlistTickets';

const PrintMainContainer = styled.div`
  display: block;
  width: 100%;
  padding: 0px 40px 20px 40px;
  overflow: visible;
`;

const PrintTitle = styled.div`
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 20px;
  white-space: normal;
`;

const PrintEventDate = styled.div`
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  padding-bottom: 8px;
  white-space: normal;
`;

const PrintEventLocation = styled.div`
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  padding-bottom: 20px;
  white-space: normal;
`;

const PrintTotalRecords = styled.div`
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  padding-bottom: 30px;
  white-space: normal;
`;

const PrintLoadingResultsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding: 20px 0px 10px 0px;
`;

const PrintListingResultsContainer = styled.div`
  display: block;
  width: 100%;
`;

const PrintListingResultContainer = styled.div`
  margin-bottom: 12px;
  @media print {
    break-inside: avoid;
  }
`;

const PrintAttendeeNameAndNumEventsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 7px;
`;

const PrintAttendeeName = styled.div`
  font-size: 14px;
  line-height: 21px;
  font-weight: 700;
  letter-spacing: 0.1px;
  white-space: normal;
`;

const PrintAttendeeNumEvents = styled.div`
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  letter-spacing: 0.1px;
  white-space: nowrap;
  padding-left: 10px;
`;

const PrintAttendeeEmail = styled.div`
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  letter-spacing: 0.1px;
  white-space: normal;
`;

const PrintAttendeeOccasionInfo = styled.div`
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  letter-spacing: 0.1px;
  white-space: normal;
  padding-bottom: 7px;
`;

const PrintAttendeeNotes = styled.div`
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  letter-spacing: 0.1px;
  white-space: normal;
  padding-bottom: 7px;
`;

const PrintNumTickets = styled.div`
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  letter-spacing: 0.1px;
  white-space: normal;
  padding-bottom: 20px;
`;

const PrintListingNoResults = styled.div`
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 30px;
`;

interface PrintListingResultProps {
  attendee: any;
}

const PrintListingResult: React.FC<PrintListingResultProps> = ({
  attendee,
}) => {
  const intl = useIntl();
  const {
    featureFlagsEnabled: {
      dosGuestlistEventCount: dosGuestlistEventCountEnabled,
    },
  } = useFeatureFlags(['dos_guestlist_event_count']);

  const name =
    (attendee.name ||
      intl.formatMessage({
        id: 'unknown',
      })) + (attendee.vipAttendee ? ' - VIP' : '');
  const email =
    attendee.email ||
    intl.formatMessage({
      id: 'unknown',
    });

  const numTotal = numGuestsTotal(attendee);

  return (
    <PrintListingResultContainer>
      <PrintAttendeeNameAndNumEventsContainer>
        <PrintAttendeeName>
          <TruncatedByCharText text={name} truncateLength={60} />
        </PrintAttendeeName>
        {attendee.eventAttendee && dosGuestlistEventCountEnabled && (
          <PrintAttendeeNumEvents>
            (
            {attendee.eventAttendee?.pastEventsCount == 0
              ? intl.formatMessage({
                  id: 'admin.guestlistDayOfShow.firstShow',
                })
              : attendee.eventAttendee?.pastEventsCount == 1
              ? intl.formatMessage({
                  id: 'admin.guestlistDayOfShow.oneShow',
                })
              : intl.formatMessage(
                  {
                    id: 'admin.guestlistDayOfShow.xShows',
                  },
                  {
                    numEvents: attendee.eventAttendee?.pastEventsCount?.toString(),
                  }
                )}
            )
          </PrintAttendeeNumEvents>
        )}
      </PrintAttendeeNameAndNumEventsContainer>
      <PrintAttendeeEmail>
        <TruncatedByCharText text={email} truncateLength={60} />
      </PrintAttendeeEmail>
      {attendee.eventAttendee && <Spacer mt={3} />}
      <GuestlistTickets attendee={attendee} displayTicketStatus={false} />
      {attendee.eventAttendee?.generalNotes && (
        <PrintAttendeeNotes>
          {attendee.eventAttendee.generalNotes}
        </PrintAttendeeNotes>
      )}
      {attendee.eventAttendee?.occasion && (
        <PrintAttendeeOccasionInfo>
          {intl.formatMessage({
            id: 'admin.guestlistDayOfShow.occasion',
          })}
          {': '}
          {eventAttendeeOccasions[attendee.eventAttendee?.occasion]}
          {attendee.eventAttendee?.occasionDetails && (
            <> ({attendee.eventAttendee?.occasionDetails})</>
          )}
        </PrintAttendeeOccasionInfo>
      )}
      <PrintNumTickets>
        {intl.formatMessage(
          {
            id: 'admin.guestlistDayOfShow.ticketsTotalOnly',
          },
          {
            numTotal: numTotal.toString(),
          }
        )}
      </PrintNumTickets>
    </PrintListingResultContainer>
  );
};

interface PrintListingResultsProps {
  attendees?: any[];
}

const PrintListingResults: React.FC<PrintListingResultsProps> = ({
  attendees,
}) => {
  return (
    <PrintListingResultsContainer>
      {attendees &&
        attendees.map((attendee: any, index: number) => (
          <PrintListingResult attendee={attendee} key={index} />
        ))}
    </PrintListingResultsContainer>
  );
};

const eventDate = (event?: Event) =>
  event
    ? dateFormatter(event.localStartsAt, 'shortWeekdayShortMonthDateAndYear')
    : '';

const getNeighborhoodTitle = (event?: Event) =>
  event?.isVenueConfirmed && !!event?.venue
    ? event?.venue?.neighborhood?.title
    : event?.neighborhood?.title;

const eventLocation = (event?: Event) => {
  const cityTitle = event?.city?.title;
  const neighborhoodTitle = getNeighborhoodTitle(event);
  return neighborhoodTitle ? `${cityTitle} / ${neighborhoodTitle}` : cityTitle;
};

const getEventId = (location?: any) => {
  const params = location ? new URLSearchParams(location.search) : undefined;
  return params && params.get('event_id')
    ? Number(params.get('event_id'))
    : undefined;
};

const AdminPrintGuestlistDayOfShow: React.FC = () => {
  const intl = useIntl();
  const hasGuestlistAccessPermission = usePermission('event.guestlist.access');

  const location = useLocation();

  const eventId = getEventId(location);

  const {
    loading: loadingEvent,
    error: errorEvent,
    data: dataEvent,
  } = GetEventInfo({
    id: eventId,
    includeDraftEvents: true,
    fetchPolicy: 'cache-and-network',
  });

  const {
    loading: loadingAttendees,
    error: errorAttendees,
    data: dataAttendees,
  } = GetAttendees({
    eventId,
    isAttending: true,
    orderBy: 'name',
    orderDirection: 'asc',
  });

  const loading = loadingEvent || loadingAttendees;
  const event = get(dataEvent, 'event', undefined);
  const attendees = get(dataAttendees, 'attendees.attendees', []);

  if (
    (!loadingEvent && !dataEvent) ||
    errorEvent ||
    (!loadingAttendees && !dataAttendees) ||
    errorAttendees
  ) {
    return (
      <LoadingError
        whatsBeingLoaded={intl.formatMessage({
          id: 'admin.eventPlanner.guestlist.theGuestlist',
        })}
      />
    );
  }

  if (!hasGuestlistAccessPermission) {
    return null;
  }

  const numResults = attendees.length;
  const totalRecords = get(
    dataAttendees,
    'attendees.attendeesMetadata.ticketsCounts.ticketsCountTotalForResults',
    0
  );

  const eventDateDisplay = eventDate(event);
  const eventLocationDisplay = eventLocation(event);

  const pageTitle = `${intl.formatMessage({
    id: 'admin.guestlistDayOfShow.dayOfShowGuestlist',
  })} ${eventDateDisplay} ${eventLocationDisplay}`;

  return (
    <Layout>
      <SEO title={`${pageTitle} | A Live Music Experience`} />
      <PrintMainContainer>
        <PrintTitle>
          {intl.formatMessage({
            id: 'admin.guestlistDayOfShow.dayOfShowGuestlist',
          })}
        </PrintTitle>
        <PrintEventDate>{eventDateDisplay}</PrintEventDate>
        <PrintEventLocation>{eventLocationDisplay}</PrintEventLocation>
        <PrintTotalRecords>
          {loading || !totalRecords
            ? null
            : intl.formatMessage(
                {
                  id: 'admin.guestlistDayOfShow.expectedGuests',
                },
                {
                  numGuests: totalRecords,
                }
              )}
        </PrintTotalRecords>
        {loading ? (
          <PrintLoadingResultsContainer>
            <LoadingBlocks.Rectangle width="90%" height="300px" />
          </PrintLoadingResultsContainer>
        ) : numResults > 0 ? (
          <PrintListingResults attendees={attendees} />
        ) : (
          <PrintListingNoResults>
            {intl.formatMessage({
              id: 'admin.guestlistDayOfShow.noResultsPlain',
            })}
          </PrintListingNoResults>
        )}
      </PrintMainContainer>
    </Layout>
  );
};

export default AdminPrintGuestlistDayOfShow;
