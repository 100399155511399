import React, { useState } from 'react';
import {
  closestCenter,
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { EventPlannerEvent } from 'app/typings/events';
import {
  Offer,
  PerformanceOffer,
  PerformanceOfferStatus,
} from 'app/typings/offers';
import { DISCOVERY_EVENT, FEATURED_SET_EVENT } from 'app/shared/utils/events';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import { BaggageIcon } from 'app/shared/components/atoms/IconLibrary';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { ToolTip, ToolTipContainer } from 'app/shared/components/atoms/ToolTip';
import { Overline } from 'app/shared/components/atoms/TypographyManualCSS';
import { performancesForPositions } from 'app/admin/utils/artists';
import { filterActivePerformanceOffers } from 'app/admin/utils/offers';
import { UpdatePerformancePositions } from 'app/admin/graphql/performances/mutationHooks';
import Artist from 'app/admin/components/organisms/EventPlannerCard/Artist';

interface ArtistsProps {
  event: EventPlannerEvent;
  canEditEvent: boolean;
  show?: boolean;
  onCreateOffer: (position: number) => void;
}

const ArtistsContainer = styled.div`
  height: 210px;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const FlexWrapRight = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

const BaggageIconWrapper = styled.div`
  padding-right: 10px;
`;

const FourthArtistContainer = styled.div<{ disabled?: boolean }>`
  ${({ theme, disabled }) => css`
    ${disabled
      ? css`
          color: ${theme.colors.blueSmoke};
          pointer-events: none;
        `
      : css`
          color: ${theme.colors.green600};
        `}
  `}
`;

const TitleOverline = styled(Overline)`
  margin-bottom: 5px;
`;

const EventArtists: React.FC<ArtistsProps> = ({
  show = true,
  event,
  canEditEvent,
  onCreateOffer,
}) => {
  const intl = useIntl();
  const {
    haveAnyArtistsIndicatedSpecificAvailabilityOnEventDate,
    performances,
    performanceOffers: rawPerformanceOffers,
  } = event;

  const performanceOffers = rawPerformanceOffers.filter(
    (perfOffer: any) => perfOffer.status !== PerformanceOfferStatus.CLOSED
  );

  const positionsArray = event.type === FEATURED_SET_EVENT ? [1] : [1, 2, 3];
  const additionalArtist = event.type === FEATURED_SET_EVENT ? [] : [4];

  const performanceItems = performancesForPositions(
    performances,
    positionsArray
  ).map((perfItem: any, index: number) => ({
    performance: perfItem,
    id: index + 1,
  }));

  const additionalPerformanceItem = performancesForPositions(
    performances,
    additionalArtist
  ).map((perfItem: any, index: number) => ({
    performance: perfItem,
    id: index + 1,
  }));

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    })
  );

  const [perfItems, setPerfItems] = useState(performanceItems);
  const [additionalPerfItem, setAdditionalPerfItem] = useState(
    additionalPerformanceItem
  );

  const [perfOffers, setPerfOffers] = useState(performanceOffers || []);

  const perfOffersByPosition = perfOffers.reduce(
    (retVal: object, perfOffer: PerformanceOffer) => {
      if (
        perfOffer?.position &&
        perfOffer.offer?.status !== PerformanceOfferStatus.CLOSED
      ) {
        (retVal[perfOffer.position] = retVal[perfOffer.position] || []).push(
          perfOffer
        );
      }
      return retVal;
    },
    {}
  );
  const performanceOffersForEvent = Object.values(perfOffersByPosition).flat();

  const handleUpdatePerformancePositions = useSubmitAction({
    submitAction: UpdatePerformancePositions(),
    submitVariables: values => {
      return values;
    },
    successMsg: intl.formatMessage({
      id: 'admin.eventPlanner.artists.updatePositions.success',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.eventPlanner.artists.updatePositions.failure',
    }),
    onSuccess: response => {
      setPerfItems(
        performancesForPositions(
          response.data.updatePerformancePositions.performances,
          positionsArray
        ).map((perfItem: any, index: number) => ({
          performance: perfItem,
          id: index + 1,
        }))
      );
    },
  });

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    const activeOffers = filterActivePerformanceOffers(perfOffers);

    if (active.id !== over.id && activeOffers.length === 0) {
      const oldIndex = active.id - 1;
      const newIndex = over.id - 1;

      const newPerfItems = arrayMove(
        perfItems,
        oldIndex,
        newIndex
      ).map((item: any, index: number) => ({ ...item, id: index + 1 }));

      const performancePositions = newPerfItems
        .filter(
          (perfItem: any) =>
            perfItem.performance &&
            perfItem.performance.position !== perfItem.id
        )
        .map((perfItem: any) => ({
          position: perfItem.id,
          performanceId: perfItem.performance.id,
        }));

      if (performancePositions.length === 0) {
        return;
      }

      setPerfItems(newPerfItems);
      handleUpdatePerformancePositions({ performancePositions });
    }
  };

  if (!show) {
    return null;
  }

  const filteredPerfItems = perfItems.filter(
    (p: any) => p.performance !== null
  );

  const fourthArtistEnabled =
    event.type === DISCOVERY_EVENT &&
    (filteredPerfItems.length >= 3 ||
      additionalPerfItem[0]?.performance !== null);

  return (
    <div data-qaid="event-planner-card-artist">
      <ArtistsContainer>
        <StyledContainer>
          <TitleOverline>
            {intl.formatMessage({
              id: 'shared.artists',
            })}
            :
            {filteredPerfItems.length +
              (additionalPerfItem[0]?.performance ? 1 : 0)}
          </TitleOverline>
          <FlexWrapRight>
            {haveAnyArtistsIndicatedSpecificAvailabilityOnEventDate && (
              <BaggageIconWrapper>
                <ToolTipContainer>
                  <BaggageIcon />
                  <ToolTip>
                    {intl.formatMessage({
                      id: 'admin.eventPlanner.artistSpecificAvailability',
                    })}
                  </ToolTip>
                </ToolTipContainer>
              </BaggageIconWrapper>
            )}
            <FourthArtistContainer disabled={!fourthArtistEnabled}>
              <Artist
                key={
                  additionalPerfItem &&
                  additionalPerfItem[0] &&
                  additionalPerfItem[0].id
                }
                performance={
                  additionalPerfItem &&
                  additionalPerfItem[0] &&
                  additionalPerfItem[0].performance
                }
                performances={[
                  ...perfItems.map((perfItem: any) => perfItem.performance),
                  additionalPerfItem.performance,
                ]}
                performanceOffers={[]}
                performanceOffersForEvent={
                  performanceOffersForEvent as PerformanceOffer[]
                }
                position={4}
                event={event}
                canEditEvent={canEditEvent}
                displayAdditionalArtist={true}
                enableAdditionalArtist={fourthArtistEnabled}
                onChange={(obj: { performance: Performance }) => {
                  const newPerfItem = additionalPerfItem[0];
                  newPerfItem.performance = obj.performance;
                  setAdditionalPerfItem([newPerfItem]);
                }}
                onCreateOffer={undefined}
                updatePerformanceOffers={undefined}
              />
            </FourthArtistContainer>
          </FlexWrapRight>
        </StyledContainer>
        <Spacer mt={2} />
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={perfItems}
            strategy={verticalListSortingStrategy}
          >
            {perfItems.map((perfItem: any, index: number) => {
              return (
                <Artist
                  key={perfItem.id}
                  performance={perfItem.performance}
                  performances={[
                    ...perfItems.map((perfItem: any) => perfItem.performance),
                    additionalPerfItem.performance,
                  ]}
                  performanceOffers={perfOffersByPosition[perfItem.id] || []}
                  performanceOffersForEvent={
                    performanceOffersForEvent as PerformanceOffer[]
                  }
                  position={perfItem.id}
                  event={event}
                  canEditEvent={canEditEvent}
                  onChange={(obj: {
                    performance: Performance;
                    offer?: Offer;
                  }) => {
                    const newPerfItems = perfItems;
                    newPerfItems[index].performance = obj.performance;
                    setPerfItems(newPerfItems);

                    if (obj.offer) {
                      if (obj.offer.status === PerformanceOfferStatus.CLOSED) {
                        setPerfOffers(
                          perfOffers.filter(
                            (perfOffer: PerformanceOffer) =>
                              perfOffer.offer?.id !== obj.offer?.id
                          )
                        );
                      } else {
                        setPerfOffers([
                          ...(perfOffers || []),
                          ...(obj.offer.performanceOffers || []),
                        ]);
                      }
                    }
                  }}
                  onCreateOffer={onCreateOffer}
                  updatePerformanceOffers={(
                    performanceOffersData: PerformanceOffer[]
                  ) => {
                    setPerfOffers([...performanceOffersData]);
                  }}
                />
              );
            })}
          </SortableContext>
        </DndContext>
      </ArtistsContainer>
      <DottedLine />
    </div>
  );
};

export default EventArtists;
