import styled, { css } from 'styled-components';

interface BadgeProps {
  children: any;
  color?: 'primary' | 'default' | 'warning';
  clickable?: boolean;
  disabled?: boolean;
}

export const Badge = styled.div<BadgeProps>`
  ${({ theme, color, disabled, clickable }) => css`
    width: 36px;
    height: 36px;
    margin: 0;
    padding: 0;
    font-size: ${theme.fontSizes.body2.xs};
    transition: ${theme.utils.transition()};
    border: none;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    user-select: none;

    ${theme.media.xs`
      font-size: ${theme.fontSizes.body2.xs};
    `}

    ${theme.media.lg`
      font-size: ${theme.fontSizes.body2.lg};
    `}

    ${clickable &&
      css`
        cursor: pointer;

        &:hover {
          background-color: rgba(17, 17, 17, 0.1);
        }
      `}

    ${color === 'primary' &&
      css`
        background-color: ${theme.colors.primary};
        color: ${theme.colors.primaryText};
        font-weight: 400;

        ${clickable &&
          css`
            &:hover {
              background-color: ${theme.colors.green800};
            }
          `}
      `}

    ${color === 'warning' &&
      css`
        background-color: #ec5d2a;
        color: ${theme.colors.primaryText};
        font-weight: 400;
      `}

    ${color === 'default' &&
      css`
        background-color: ${theme.colors.macyGrey};
        color: ${theme.colors.backToBlack};
        font-weight: 400;

        ${clickable &&
          css`
            &:hover {
              background-color: ${theme.colors.blueSmoke};
            }
          `}
      `}

    ${disabled &&
      css`
        user-select: none;
        cursor: not-allowed;

        &:hover {
          background-color: transparent;
        }
      `}
  `}
`;
