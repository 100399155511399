import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Country } from 'app/typings/countries';
import { currencyFormatter } from 'app/shared/utils/currencyFormatter';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import { numTicketsSoldArtistCompensationTiers } from 'app/admin/utils/artistPayments';
import BooleanSetting from 'app/admin/components/atoms/BooleanSetting';
import {
  DetailHeading as Heading,
  DetailValueContainer as ValueContainer,
} from 'app/admin/components/atoms/DetailContent';
import { None } from 'app/admin/components/atoms/None';
import { SimpleKeyValueInfoList } from 'app/admin/components/molecules/SimpleKeyValueInfoList';

interface Props {
  sectionData: Country;
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const SectionSettings: React.FC<Props> = ({ sectionData }) => {
  const intl = useIntl();

  const artistCompensationTierKeysAndValues = Object.entries(
    numTicketsSoldArtistCompensationTiers
  ).map(([tierNumInfo, tierInfo]) => {
    const tierNum = Number(tierNumInfo.replace(/tier/, ''));
    const rawValue = sectionData[`artistCompensationTier${tierNum}`];
    return {
      key: intl.formatMessage(
        {
          id: 'admin.countries.heading.tierNum',
        },
        {
          tierNum: tierNum.toString(),
          numTickets: tierInfo,
        }
      ),
      value:
        rawValue || rawValue == 0
          ? currencyFormatter(rawValue, sectionData.currencySymbol || '$')
          : undefined,
    };
  });

  return (
    <MainContainer data-qaid="section-settings">
      <Grid cols={4}>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={1}>
          <Heading>
            {intl.formatMessage({
              id: 'admin.countries.heading.bookingFee',
            })}
          </Heading>
          <ValueContainer>
            {sectionData.bookingFee || sectionData.bookingFee == 0 ? (
              currencyFormatter(
                sectionData.bookingFee,
                sectionData.currencySymbol || '$'
              )
            ) : (
              <None />
            )}
          </ValueContainer>
          <BooleanSetting
            value={sectionData.displayBookingFee}
            headingText={intl.formatMessage({
              id: 'admin.countries.heading.displayBookingFee',
            })}
          />
        </Col>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={2}>
          <BooleanSetting
            value={sectionData.isTieredArtistCompensationEnabled}
            headingText={intl.formatMessage({
              id: 'admin.countries.heading.userTieredArtistCompensation',
            })}
          />
        </Col>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={3}>
          <Heading>
            {intl.formatMessage({
              id: 'admin.countries.heading.defaultArtistCompensation',
            })}
          </Heading>
          <ValueContainer>
            {sectionData.artistCompensation ||
            sectionData.artistCompensation == 0 ? (
              currencyFormatter(
                sectionData.artistCompensation,
                sectionData.currencySymbol || '$'
              )
            ) : (
              <None />
            )}
          </ValueContainer>
        </Col>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={4}>
          <Heading>
            {intl.formatMessage({
              id: 'admin.countries.heading.tieredArtistCompensation',
            })}
          </Heading>
          <SimpleKeyValueInfoList
            keysAndValues={artistCompensationTierKeysAndValues}
            keyColWidth="200px"
          />
        </Col>
      </Grid>
    </MainContainer>
  );
};

export default SectionSettings;
