import React, { useState } from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { EventPreview } from 'app/typings/events';
import {
  COMMERCIAL_PARTNERSHIP_EVENT,
  DISCOVERY_EVENT,
  FEATURED_SET_EVENT,
  PRIVATE_DISCOVERY_EVENT,
} from 'app/shared/utils/events';
import {
  getCurrencyFormInitialValue,
  getCurrencyRoundedFormInitialValue,
  getCurrencySubmitVariable,
} from 'app/shared/utils/form';
import { get, getInteger } from 'app/shared/utils/get';
import useModal from 'app/shared/utils/useModal';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import UpdateOptionsModal from 'app/shared/components/organisms/UpdateOptionsModal';
import { UpdateCity } from 'app/admin/graphql/cities/mutationHooks';
import { GetEventsForCityEdit } from 'app/admin/graphql/events/queryHooks';
import { DetailsFormMainContainer } from 'app/admin/components/atoms/DetailContent';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

import CitySettingsEditForm from './CitySettingsEditForm';
import CitySettingsEditFormSchema from './CitySettingsEditFormSchema';

const ErrorMessage = styled.div<any>`
  ${({ theme }) => css`
    width: 100%;
    color: ${theme.colors.redRedWine};
    font-size: ${theme.fontSizes.caption};
  `}
`;

interface Props {
  contentProps?: any;
  setFormSubmitAction: (func: Function) => void;
  setDisplayConfirmation: (set: boolean) => void;
  setIsSubmitting: (set: boolean) => void;
  navigateTo: (routeData: object) => void;
}

const CitySettingsEdit: React.FC<Props> = ({
  contentProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  navigateTo,
}) => {
  const intl = useIntl();
  const [submitData, setSubmitData] = useState({});
  const [updateOptionsModal, toggleUpdateOptionsModal] = useModal();

  const {
    error: errorEvents,
    loading: loadingEvents,
    data: dataEvents,
  } = GetEventsForCityEdit({
    city: contentProps.cachedSlug,
    upcoming: true,
    type: [
      DISCOVERY_EVENT,
      FEATURED_SET_EVENT,
      COMMERCIAL_PARTNERSHIP_EVENT,
      PRIVATE_DISCOVERY_EVENT,
    ].toString(),
    page: 1,
    perPage: 1000,
  });

  const eventOptions = dataEvents?.events?.events || [];

  const pricingTypeIsTiered = () =>
    getInteger(contentProps, 'tieredPrice.priceSun', '') ||
    getInteger(contentProps, 'tieredPrice.priceSun', '') == 0;

  const formInitialValues = {
    pricingType: pricingTypeIsTiered() ? 'tiered' : 'fixed',
    priceSun: getInteger(contentProps, 'tieredPrice.priceSun', ''),
    priceMon: getInteger(contentProps, 'tieredPrice.priceMon', ''),
    priceTue: getInteger(contentProps, 'tieredPrice.priceTue', ''),
    priceWed: getInteger(contentProps, 'tieredPrice.priceWed', ''),
    priceThu: getInteger(contentProps, 'tieredPrice.priceThu', ''),
    priceFri: getInteger(contentProps, 'tieredPrice.priceFri', ''),
    priceSat: getInteger(contentProps, 'tieredPrice.priceSat', ''),
    defaultEventPrice: getCurrencyRoundedFormInitialValue(
      contentProps,
      'defaultEventPrice'
    ),
    bookingFee: getCurrencyFormInitialValue(contentProps, 'bookingFee'),
    countryBookingFee: get(contentProps, 'country.bookingFee', ''),
    displayBookingFee: get(contentProps, 'displayBookingFee', null),
    isTieredArtistCompensationEnabled: get(
      contentProps,
      'isTieredArtistCompensationEnabled',
      false
    ),
    maxTicketsPerAttendee: get(contentProps, 'maxTicketsPerAttendee', '') || '',
    isAutoInviteEnabled: get(contentProps, 'isAutoInviteEnabled', false),
    inviteFirstDayPercentage: getInteger(
      contentProps,
      'inviteFirstDayPercentage',
      ''
    ),
    inviteSubsequentDaysPercentage: getInteger(
      contentProps,
      'inviteSubsequentDaysPercentage',
      ''
    ),
    isAutoStopApplyEnabled: get(contentProps, 'isAutoStopApplyEnabled', false),
    maximumPromoCodeUses: getInteger(contentProps, 'maximumPromoCodeUses', ''),
    maximumGuaranteedPromoCodeUses: getInteger(
      contentProps,
      'maximumGuaranteedPromoCodeUses',
      ''
    ),
    maximumCustomPromoCodeUses: getInteger(
      contentProps,
      'maximumCustomPromoCodeUses',
      ''
    ),
    maximumPercentagePromoCodeUses: getInteger(
      contentProps,
      'maximumPercentagePromoCodeUses',
      ''
    ),
    sendPromoCodesToFirstTimers: get(
      contentProps,
      'sendPromoCodesToFirstTimers',
      false
    ),
    isUnluckyEmailPromoCodeEnabled: get(
      contentProps,
      'isUnluckyEmailPromoCodeEnabled',
      false
    ),
    topGlobalCity: get(contentProps, 'topGlobalCity', false),
    topCity: get(contentProps, 'topCity', false),
    noindex: get(contentProps, 'noindex', false),
  };

  const integerOrUndefined = (value: string | undefined) =>
    value || value === '0' ? Number(value) : undefined;

  const integerOrNull = (value: string | undefined) =>
    value || value === '0' ? Number(value) : null;

  const updateCityAction = UpdateCity();

  const handleUpdateCity = useSubmitAction({
    submitAction: updateCityAction,
    submitVariables: (values: any) => ({
      cityId: get(contentProps, 'id', undefined),
      tieredPrice: {
        priceSun: integerOrUndefined(values.priceSun),
        priceMon: integerOrUndefined(values.priceMon),
        priceTue: integerOrUndefined(values.priceTue),
        priceWed: integerOrUndefined(values.priceWed),
        priceThu: integerOrUndefined(values.priceThu),
        priceFri: integerOrUndefined(values.priceFri),
        priceSat: integerOrUndefined(values.priceSat),
      },
      defaultEventPrice: getCurrencySubmitVariable(values.defaultEventPrice, 0),
      bookingFee: getCurrencySubmitVariable(values.bookingFee, null),
      displayBookingFee: values.displayBookingFee,
      isTieredArtistCompensationEnabled:
        values.isTieredArtistCompensationEnabled,
      maxTicketsPerAttendee: integerOrUndefined(values.maxTicketsPerAttendee),
      isAutoInviteEnabled: values.isAutoInviteEnabled,
      inviteFirstDayPercentage: integerOrNull(values.inviteFirstDayPercentage),
      inviteSubsequentDaysPercentage: integerOrNull(
        values.inviteSubsequentDaysPercentage
      ),
      isAutoStopApplyEnabled: values.isAutoStopApplyEnabled,
      maximumPromoCodeUses: integerOrUndefined(values.maximumPromoCodeUses),
      maximumGuaranteedPromoCodeUses: integerOrUndefined(
        values.maximumGuaranteedPromoCodeUses
      ),
      maximumCustomPromoCodeUses: integerOrUndefined(
        values.maximumCustomPromoCodeUses
      ),
      maximumPercentagePromoCodeUses: integerOrUndefined(
        values.maximumPercentagePromoCodeUses
      ),
      sendPromoCodesToFirstTimers: values.sendPromoCodesToFirstTimers,
      isUnluckyEmailPromoCodeEnabled: values.isUnluckyEmailPromoCodeEnabled,
      topGlobalCity: values.topGlobalCity,
      topCity: values.topCity,
      noindex: values.noindex,
      applyMethod: values.applyMethod,
      selectedValues: values.selectedValues,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.cityBasicInfo.form.successMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.cityBasicInfo.form.failureMessage',
    }),
    onSuccess: response => {
      navigateTo({
        routeName: 'city-details',
        routeProps: {
          defaultOpenSection: 'settings',
          ...response.data.updateCity.city,
        },
      });
    },
  });

  const fieldsRequiringUpdateOptions = [
    'maxTicketsPerAttendee',
    'maximumPromoCodeUses',
    'maximumGuaranteedPromoCodeUses',
    'maximumCustomPromoCodeUses',
    'maximumPercentagePromoCodeUses',
  ];

  return (
    <ModalContentContainer>
      <DetailsFormMainContainer>
        <DetailsHeader title={get(contentProps, 'title', '')} />
        <GenericForm
          formInitialValues={formInitialValues}
          renderFormComponent={(renderProps: any) => (
            <CitySettingsEditForm
              formikProps={renderProps.formikProps}
              currencySymbol={get(contentProps, 'currencySymbol', '')}
            />
          )}
          onSubmit={data => {
            if (
              fieldsRequiringUpdateOptions.every(
                (field: string) => formInitialValues[field] === data[field]
              ) ||
              eventOptions.length === 0
            ) {
              handleUpdateCity(data);
            } else {
              setIsSubmitting(false);
              setSubmitData(data);
              return toggleUpdateOptionsModal();
            }
          }}
          formSchema={CitySettingsEditFormSchema(intl)}
          setFormSubmitAction={setFormSubmitAction}
          setDisplayConfirmation={setDisplayConfirmation}
          setIsSubmitting={setIsSubmitting}
          dataQaId="city-settings-section-edit-form"
        />
        {updateOptionsModal.isShowing &&
          (errorEvents ? (
            <ErrorMessage data-qaid="city-settings-events-error">
              {intl.formatMessage({
                id: 'admin.citySettings.form.getEventsError',
              })}
            </ErrorMessage>
          ) : (
            <UpdateOptionsModal
              onCancel={() => updateOptionsModal.hide()}
              onConfirm={(data: any) => {
                setIsSubmitting(true);
                updateOptionsModal.hide();
                handleUpdateCity({
                  ...submitData,
                  ...data,
                });
              }}
              dataQaidPrefix="city-settings-options-modal"
              modalType="city"
              isLoading={loadingEvents}
              title={intl.formatMessage({
                id: 'admin.updateOptionsModal.title',
              })}
              options={eventOptions.map((ev: EventPreview) => ({
                title:
                  ev.localStartsAt &&
                  moment.utc(ev.localStartsAt).format('MMM DD YYYY hh:mm'),
                value: ev.id,
              }))}
            />
          ))}
      </DetailsFormMainContainer>
    </ModalContentContainer>
  );
};

export default CitySettingsEdit;
