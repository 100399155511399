import React from 'react';
import styled from 'styled-components';

import { Container } from 'app/shared/components/atoms/Container';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import SEO from 'app/shared/components/molecules/SEO';

interface Props {
  pageTitle: string;
  modalsContent?: VoidFunction;
  children: any;
}

const MainContainer = styled(Container)`
  @media screen and (min-width: 1300px) {
    max-width: 1260px;
  }
`;

const ListPage: React.FC<Props> = ({ pageTitle, modalsContent, children }) => {
  return (
    <>
      <SEO title={`${pageTitle} | A Live Music Experience`} />
      <MainContainer>
        <Spacer mb={8} />
        {children}
      </MainContainer>
      {modalsContent && modalsContent()}
    </>
  );
};

export default ListPage;
