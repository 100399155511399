import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { EventCollection, Theme } from 'app/typings';
import { currencyMainUnitsFormatter } from 'app/shared/utils/currencyFormatter';
import { FEATURED_SET_EVENT } from 'app/shared/utils/events';
import { errorMsgForField } from 'app/shared/utils/form';
import { get } from 'app/shared/utils/get';
import { objectForObjectId } from 'app/shared/utils/object';
import { useFallbackCollection } from 'app/shared/utils/useFallbackCollection';
import usePermission from 'app/shared/utils/usePermission';
import { Checkbox } from 'app/shared/components/atoms/CheckboxManualCSS';
import DatePicker from 'app/shared/components/atoms/DatePicker';
import { FlexDivWithSpacing } from 'app/shared/components/atoms/FlexDivWithSpacing';
import {
  FormGroupContainer,
  FormSectionTitle,
} from 'app/shared/components/atoms/FormContent';
import GenericFormContainer from 'app/shared/components/atoms/GenericFormContainer';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import { BaseLink } from 'app/shared/components/atoms/LinkManualCSS';
import { LoadingError } from 'app/shared/components/atoms/LoadingError';
import StyledTextarea from 'app/shared/components/atoms/SmallTextarea';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Textfield } from 'app/shared/components/atoms/Textfield';
import { Body } from 'app/shared/components/atoms/TypographyManualCSS';
import FormGroup from 'app/shared/components/molecules/FormGroup';
import RadioGroup from 'app/shared/components/molecules/RadioGroup';
import Select from 'app/shared/components/molecules/SelectManualCSS';
import {
  eventMerchandisingDescriptionMaxLength,
  eventMerchandisingHeadlineMaxLength,
} from 'app/admin/utils/events';
import {
  labelValueOptionsWithNoneOption,
  optionsWithNoneOption,
} from 'app/admin/utils/optionLists';
import { GetBusinessOwners } from 'app/admin/graphql/businessOwners/queryHooks';
import { GetEventFormatExperiments } from 'app/admin/graphql/eventFormatExperiments/queryHooks';
import { GetThemesLite } from 'app/admin/graphql/themes/queryHooks';
import EditFormLoadingBlocks from 'app/admin/components/atoms/EditFormLoadingBlocks';
import { renderPriceHelperText } from 'app/admin/components/molecules/EventPlannerUtils';
import MerchandisingPropertyGroupsEditFormFields from 'app/admin/components/molecules/MerchandisingPropertyGroupsEditFormFields';
import RichTextEditor from 'app/admin/components/molecules/RichTextEditor';
import SimpleDatePicker from 'app/admin/components/molecules/SimpleDatePicker';
import { BigNumberTextfield } from 'app/admin/components/molecules/Textfield';
import { NumberTextfield } from 'app/admin/components/molecules/Textfield';
import TicketPriceFormField from 'app/admin/components/molecules/TicketPriceFormField';
import Timepicker from 'app/admin/components/molecules/TimePicker';
import CompetitionFormGroup from 'app/admin/components/organisms/CompetitionFormGroup';

import {
  formatTypeOptions as formatTypeOptionsRaw,
  sofarOperatedMarketTypeOptions,
} from './options';

const StyledLink = styled(BaseLink)`
  ${({ theme }) => css`
    color: ${theme.colors.green600};
  `}
`;

const VerticalCenteredText = styled.div`
  font-size: 14px;
`;

const getEventCollectionOptions = (eventCollections?: EventCollection[]) => {
  const eventCollectionsActive = eventCollections?.filter(
    eventCollection => eventCollection.status === 'active'
  );
  return eventCollectionsActive && eventCollectionsActive.length > 1
    ? eventCollectionsActive.map((eventCollection: any) => ({
        id: eventCollection.id,
        title: eventCollection.title,
      }))
    : [];
};

const getDefaultEventCollectionOption = (
  fallbackEventCollection?: EventCollection | null
) =>
  fallbackEventCollection
    ? {
        id: fallbackEventCollection.id,
        title: fallbackEventCollection.title,
      }
    : null;

interface FormProps {
  formikProps: any;
  venueId?: number;
  eventId?: number;
  status?: string;
  eventOrganizedAs?: string;
  eventCollections?: EventCollection[];
}

const EventBasicInfoEditForm: React.FC<FormProps> = ({
  formikProps,
  venueId,
  eventId,
  status,
  eventOrganizedAs,
  eventCollections,
}) => {
  const intl = useIntl();

  const hasEditBusinessOwnersPermission = usePermission('businessOwner.edit');
  const hasEditThemesPermission = usePermission('theme.edit');
  const hasViewVenuesPermission = usePermission('venue.list.view');
  const hasCompetitionAccessPermission = usePermission('competition.access');

  const fallbackEventCollection = useFallbackCollection({ eventCollections });

  const {
    loading: loadingBusinessOwners,
    error: errorBusinessOwners,
    data: dataBusinessOwners,
  } = GetBusinessOwners({
    archivedStatus: 'not_archived',
    skipPagination: true,
  });

  const {
    loading: loadingEventFormatExperiments,
    error: errorEventFormatExperiments,
    data: dataEventFormatExperiments,
  } = GetEventFormatExperiments();

  const {
    loading: loadingThemes,
    error: errorThemes,
    data: dataThemes,
  } = GetThemesLite({});

  const { setFieldValue, values, errors, touched } = formikProps;

  if (errorBusinessOwners || errorEventFormatExperiments || errorThemes) {
    return <LoadingError whatsBeingLoaded="this form" />;
  }

  if (
    loadingBusinessOwners ||
    !dataBusinessOwners ||
    loadingEventFormatExperiments ||
    !dataEventFormatExperiments ||
    loadingThemes ||
    !dataThemes
  ) {
    return <EditFormLoadingBlocks />;
  }

  const businessOwnerOptions = optionsWithNoneOption(
    get(dataBusinessOwners, 'businessOwners.businessOwners', [])
  );

  const formatTypeOptions = labelValueOptionsWithNoneOption(
    formatTypeOptionsRaw
  );

  const eventFormatExperimentOptions = get(
    dataEventFormatExperiments,
    'eventFormatExperiments.eventFormatExperiments',
    []
  );

  const themeOptions = [{ id: 0, title: 'None' }].concat(
    get(dataThemes, 'themes.themes', [])
  );

  const resolveThemeByThemeTitle = (title: string): Theme | undefined => {
    const allThemes = get(dataThemes, 'themes.themes', []);
    return allThemes.find(
      (theme: Theme) => theme.title.toLowerCase() === title.toLowerCase()
    );
  };

  const eventCollectionOptions = getEventCollectionOptions(eventCollections);

  const defaultEventCollection = getDefaultEventCollectionOption(
    fallbackEventCollection
  );

  const businessOwnerForBusinessOwnerId = (businessOwnerId: number | null) =>
    objectForObjectId(
      get(dataBusinessOwners, 'businessOwners.businessOwners', []),
      businessOwnerId
    );

  const eventFormatExperimentForEventFormatExperimentId = (
    eventFormatExperimentId: number | null
  ) =>
    objectForObjectId(
      get(
        dataEventFormatExperiments,
        'eventFormatExperiments.eventFormatExperiments',
        []
      ),
      eventFormatExperimentId
    );

  const optionForField = (options: any, type: string | null): any | undefined =>
    options
      ? options.find((option: any) => option && option.value === type)
      : undefined;

  return (
    <GenericFormContainer dataQaId="event-basic-info-section-edit-form">
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.eventBasicInfo.concertDate',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="event-basic-info-form-concert-date-formgroup"
          label={intl.formatMessage({
            id: 'admin.shared.eventBasicInfo.selectDate',
          })}
          errorMsg={errorMsgForField('eventDate', touched, errors)}
        >
          <DatePicker
            values={[values.eventDate]}
            onChange={values => setFieldValue('eventDate', values[0])}
            hasMultiSelect={false}
          />
        </FormGroup>
      </FormGroupContainer>
      {values.eventType === FEATURED_SET_EVENT && (
        <>
          <FormSectionTitle>
            {intl.formatMessage({
              id: 'admin.shared.eventPlanner.ticketsOnSale',
            })}
          </FormSectionTitle>
          <FormGroupContainer>
            <FormGroup
              label={intl.formatMessage({
                id: 'admin.eventPlanner.overview.onSaleDate',
              })}
              data-qaid="event-basic-info-on-sale-at"
              errorMsg={errorMsgForField('onSaleAt', touched, errors)}
              required
            >
              <SimpleDatePicker
                data-qaid="event-basic-info-on-sale-at-datepicker"
                name="onSaleAt"
                value={values.onSaleAt}
                placeholder={intl.formatMessage({
                  id: 'admin.shared.eventBasicInfo.selectDate',
                })}
                onChange={(e: any) => {
                  const date = e.startOf('day');
                  setFieldValue('onSaleAt', date);
                }}
              />
            </FormGroup>
          </FormGroupContainer>
        </>
      )}
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.shared.eventTimes.loadInLoadOutTimes',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <Grid>
          <Col lg={3} md={4} sm={5} xs={6}>
            <FormGroup
              data-qaid="event-basic-info-edit-form-crew-load-in-field-formgroup"
              label={intl.formatMessage({
                id: 'admin.shared.eventTimes.crewLoadInTime',
              })}
              errorMsg={errorMsgForField('localCrewLoadInAt', touched, errors)}
              required
            >
              <Timepicker
                data-qaid="event-basic-info-edit-form-crew-load-in-field"
                name="localCrewLoadInAt"
                value={values.localCrewLoadInAt}
                onChange={(value: any) => {
                  setFieldValue('localCrewLoadInAt', value);
                }}
              />
            </FormGroup>
          </Col>
          <Col lg={3} md={4} sm={5} xs={6}>
            <FormGroup
              data-qaid="event-basic-info-edit-form-artist-load-in-field-formgroup"
              label={intl.formatMessage({
                id: 'admin.shared.eventTimes.artistLoadInTime',
              })}
              errorMsg={errorMsgForField(
                'localArtistLoadInAt',
                touched,
                errors
              )}
              required
            >
              <Timepicker
                data-qaid="event-basic-info-edit-form-artist-load-in-field"
                name="localArtistLoadInAt"
                value={values.localArtistLoadInAt}
                onChange={(value: any) => {
                  setFieldValue('localArtistLoadInAt', value);
                }}
              />
            </FormGroup>
          </Col>
        </Grid>
        <Spacer mb={4} />
        <Grid>
          <Col lg={3} md={4} sm={5} xs={6}>
            <FormGroup
              data-qaid="event-basic-info-edit-form-guests-arrive-at-field-formgroup"
              label={intl.formatMessage({
                id: 'admin.shared.eventTimes.guestsArriveTime',
              })}
              errorMsg={errorMsgForField(
                'localGuestsArriveAt',
                touched,
                errors
              )}
              required
            >
              <Timepicker
                data-qaid="event-basic-info-edit-form-guests-arrive-at-field"
                name="localGuestsArriveAt"
                value={values.localGuestsArriveAt}
                onChange={(value: any) => {
                  setFieldValue('localGuestsArriveAt', value);
                }}
              />
            </FormGroup>
          </Col>
          <Col lg={3} md={4} xs={6}>
            <FormGroup
              data-qaid="event-basic-info-edit-form-artist-starts-at-field-formgroup"
              label={intl.formatMessage({
                id: 'admin.shared.eventTimes.startTime',
              })}
              errorMsg={errorMsgForField('localStartsAt', touched, errors)}
              required
            >
              <Timepicker
                data-qaid="event-basic-info-edit-form-starts-at-field"
                name="localStartsAt"
                value={values.localStartsAt}
                onChange={(value: any) => {
                  setFieldValue('localStartsAt', value);
                }}
              />
            </FormGroup>
          </Col>
        </Grid>
        <Spacer mb={4} />
        <FormGroup
          data-qaid="event-basic-info-edit-form-artist-ends-at-field-formgroup"
          label={intl.formatMessage({
            id: 'admin.shared.eventTimes.endsTime',
          })}
          errorMsg={errorMsgForField('localEndsAt', touched, errors)}
        >
          <Timepicker
            data-qaid="event-basic-info-edit-form-ends-at-field"
            name="localEndsAt"
            value={values.localEndsAt}
            onChange={(value: any) => {
              setFieldValue('localEndsAt', value);
            }}
          />
        </FormGroup>
        <Spacer mb={4} />
        <FormGroup
          data-qaid="timing-notes-formgroup"
          label={intl.formatMessage({
            id: 'admin.shared.timeNotes',
          })}
          errorMsg={errorMsgForField('timingNotes', touched, errors)}
        >
          <RichTextEditor
            name="timing-notes-editor"
            currentValue={values.timingNotes}
            onChange={(value: any) => setFieldValue('timingNotes', value)}
          />
        </FormGroup>
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'shared.capacity',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        {venueId ? (
          <Grid>
            <Col lg={1} md={2} sm={3} xs={3}>
              {values.capacity}
            </Col>
            {hasViewVenuesPermission && (
              <Col lg={11} md={10} sm={9} xs={9}>
                <StyledLink
                  href={`/admin/venues?venue_id=${venueId}`}
                  openInNewTab={true}
                >
                  {intl.formatMessage({
                    id: 'admin.eventVenueInfo.editInVenueDirectory',
                  })}
                </StyledLink>
              </Col>
            )}
          </Grid>
        ) : (
          <>{intl.formatMessage({ id: 'admin.notApplicable' })}</>
        )}
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.shared.ticketsAvailable',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="event-basic-info-form-tickets-available-formgroup"
          label={intl.formatMessage({
            id: 'admin.shared.ticketsAvailable',
          })}
          errorMsg={errorMsgForField(
            'numTicketsAvailableForSale',
            touched,
            errors
          )}
          required
        >
          <NumberTextfield
            data-qaid="event-basic-info-form-tickets-available-field"
            id="numTicketsAvailableForSale"
            name="numTicketsAvailableForSale"
            value={values.numTicketsAvailableForSale}
            onChange={(e: any) => {
              setFieldValue('numTicketsAvailableForSale', e.target.value);
            }}
            maxLength={4}
          />
        </FormGroup>
      </FormGroupContainer>
      {values.attendeeFlow == 'buy' && (
        <>
          <FormSectionTitle>
            {intl.formatMessage({
              id: 'admin.shared.ticketPrice',
            })}
          </FormSectionTitle>
          <FormGroupContainer>
            <TicketPriceFormField
              fieldName="ticketPrice"
              currencySymbol={values.currencySymbol}
              price={values.ticketPrice}
              priceHelperText={renderPriceHelperText(intl, values)}
              touched={touched}
              errors={errors}
              setFieldValue={setFieldValue}
            />
          </FormGroupContainer>
        </>
      )}
      {hasEditBusinessOwnersPermission && eventOrganizedAs === 'o_and_o' && (
        <>
          <FormSectionTitle>
            {intl.formatMessage({
              id: 'admin.eventPlanner.overview.marketType',
            })}
          </FormSectionTitle>
          <FormGroupContainer>
            <FormGroup
              data-qaid="event-basic-info-form-sofar-operated-market-type-formgroup"
              label={intl.formatMessage({
                id: 'admin.eventPlanner.overview.marketType',
              })}
              errorMsg={errorMsgForField(
                'sofarOperatedMarketType',
                touched,
                errors
              )}
            >
              <Select
                data-qaid="event-basic-info-form-sofar-operated-market-type-field"
                name="sofarOperatedMarketType"
                id="sofarOperatedMarketType"
                getOptionLabel={(opt: any) => opt.label}
                defaultValue={optionForField(
                  sofarOperatedMarketTypeOptions,
                  values.sofarOperatedMarketType
                )}
                options={sofarOperatedMarketTypeOptions}
                placeholder={intl.formatMessage({
                  id:
                    'admin.eventPlanner.form.sofarOperatedMarketTypePlaceholder',
                })}
                onChange={option =>
                  setFieldValue(
                    'sofarOperatedMarketType',
                    option && option.value ? option.value : null
                  )
                }
                initialWidth="200px"
              />
            </FormGroup>
          </FormGroupContainer>
        </>
      )}
      {hasEditBusinessOwnersPermission && eventOrganizedAs === 'o_and_o' && (
        <>
          <FormSectionTitle>
            {intl.formatMessage({
              id: 'shared.businessOwner',
            })}
          </FormSectionTitle>
          <FormGroupContainer>
            <FormGroup
              label={intl.formatMessage({
                id: 'shared.businessOwner',
              })}
              data-qaid="event-basic-info-form-business-owner-formgroup"
              errorMsg={errorMsgForField('businessOwnerId', touched, errors)}
            >
              <Select
                data-qaid="event-basic-info-form-business-owner-field"
                searchable
                getOptionLabel={(opt: any) => opt.title}
                defaultValue={businessOwnerForBusinessOwnerId(
                  values.businessOwnerId
                )}
                options={businessOwnerOptions}
                placeholder={intl.formatMessage({
                  id: 'admin.cityBasicInfo.form.businessOwnerPlaceholder',
                })}
                onChange={businessOwner =>
                  setFieldValue(
                    'businessOwnerId',
                    businessOwner && businessOwner.id ? businessOwner.id : null
                  )
                }
              />
            </FormGroup>
          </FormGroupContainer>
        </>
      )}
      {eventOrganizedAs === 'o_and_o' && (
        <>
          <FormSectionTitle>
            {intl.formatMessage({
              id: 'shared.experimentalConcert',
            })}
          </FormSectionTitle>
          <FormGroupContainer>
            <Checkbox
              id="event-basic-info-form-is-event-format-experiment-field"
              data-qaid="event-basic-info-form-is-event-format-experiment-field"
              checked={values.isEventFormatExperiment}
              name="isEventFormatExperiment"
              onChange={e =>
                setFieldValue('isEventFormatExperiment', e.target.checked)
              }
            >
              {intl.formatMessage({
                id: 'admin.eventPlanner.form.concertIsAnExperiment',
              })}
            </Checkbox>
            {values.isEventFormatExperiment && (
              <>
                <Spacer mb={6} />
                <FormGroup
                  label={intl.formatMessage({
                    id: 'admin.eventPlanner.form.experimentLabel',
                  })}
                  data-qaid="event-basic-info-form-event-format-experiment-formgroup"
                  errorMsg={errorMsgForField(
                    'eventFormatExperimentId',
                    touched,
                    errors
                  )}
                >
                  <Select
                    data-qaid="event-basic-info-form-event-format-experiment-field"
                    searchable
                    getOptionLabel={(opt: any) => opt.title}
                    defaultValue={eventFormatExperimentForEventFormatExperimentId(
                      values.eventFormatExperimentId
                    )}
                    options={eventFormatExperimentOptions}
                    placeholder={intl.formatMessage({
                      id: 'admin.eventPlanner.form.experimentPlaceholder',
                    })}
                    onChange={eventFormatExperiment =>
                      setFieldValue(
                        'eventFormatExperimentId',
                        eventFormatExperiment && eventFormatExperiment.id
                          ? eventFormatExperiment.id
                          : null
                      )
                    }
                  />
                </FormGroup>
              </>
            )}
          </FormGroupContainer>
        </>
      )}
      {eventOrganizedAs === 'o_and_o' && (
        <>
          <FormSectionTitle>
            {intl.formatMessage({
              id: 'admin.eventPlanner.overview.formatType',
            })}
          </FormSectionTitle>
          <FormGroupContainer>
            <FormGroup
              data-qaid="event-basic-info-form-format-type-formgroup"
              label={intl.formatMessage({
                id: 'admin.eventPlanner.overview.formatType',
              })}
              errorMsg={errorMsgForField('formatType', touched, errors)}
            >
              <Select
                data-qaid="event-basic-info-form-format-type-field"
                name="formatType"
                id="formatType"
                getOptionLabel={(opt: any) => opt.label}
                defaultValue={optionForField(
                  formatTypeOptions,
                  values.formatType
                )}
                options={formatTypeOptions}
                placeholder={intl.formatMessage({
                  id: 'admin.eventPlanner.form.formatTypePlaceholder',
                })}
                onChange={option =>
                  setFieldValue(
                    'formatType',
                    option && option.value ? option.value : null
                  )
                }
                initialWidth="300px"
              />
            </FormGroup>
          </FormGroupContainer>
        </>
      )}
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.eventBasicInfo.artistCompensation',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="event-basic-info-form-event-artist-compensation-formgroup"
          label={
            !(
              values.eventArtistCompensation ||
              values.eventArtistCompensation === 0
            )
              ? intl.formatMessage(
                  {
                    id:
                      'admin.eventBasicInfo.artistCompensationWithCountryValue',
                  },
                  {
                    countryArtistCompensation: currencyMainUnitsFormatter(
                      values.artistCompensation || 0,
                      values.currencySymbol || '$'
                    ),
                  }
                )
              : intl.formatMessage({
                  id: 'admin.eventBasicInfo.artistCompensation',
                })
          }
          errorMsg={errorMsgForField(
            'eventArtistCompensation',
            touched,
            errors
          )}
        >
          <BigNumberTextfield
            data-qaid="event-basic-info-form-event-artist-compensation"
            id="eventArtistCompensation"
            name="eventArtistCompensation"
            value={values.eventArtistCompensation}
            onChange={(e: any) => {
              setFieldValue('eventArtistCompensation', e.target.value);
            }}
            maxLength={10}
          />
        </FormGroup>
      </FormGroupContainer>

      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.eventBasicInfo.themes',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="event-basic-info-form-themes-formgroup"
          label={intl.formatMessage({
            id: 'admin.eventBasicInfo.themes',
          })}
          errorMsg={errorMsgForField('theme', touched, errors)}
        >
          <Grid>
            <Col lg={6} md={6} sm={9} xs={9}>
              <Select
                data-qaid="event-basic-info-form-themes-field"
                options={themeOptions}
                getOptionLabel={(opt: any) => opt.title}
                searchable={true}
                placeholder={intl.formatMessage({
                  id: 'admin.eventBasicInfo.selectTheme',
                })}
                onChange={(themeObj: any) => {
                  // Checking for ID because we don't want to save 'None' in DB (ID is 0 for None option, 0 makes this condition evaluate to false)
                  setFieldValue('theme', themeObj?.id ? themeObj.title : null);
                }}
                defaultValue={
                  values.theme ? resolveThemeByThemeTitle(values.theme) : null
                }
              />
            </Col>
            {hasEditThemesPermission && (
              <Col lg={6} md={6} sm={3} xs={3}>
                <VerticalCenteredText>
                  <StyledLink href="/admin/themes" openInNewTab={true}>
                    {intl.formatMessage({
                      id: 'admin.eventBasicInfo.editThemes',
                    })}
                  </StyledLink>
                </VerticalCenteredText>
              </Col>
            )}
          </Grid>
        </FormGroup>
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.eventBasicInfo.isSpecialEvent',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <Grid>
          <Col sm={12}>
            <RadioGroup
              name="isSpecialEvent"
              options={[
                {
                  title: intl.formatMessage({
                    id: 'yes',
                  }),
                  value: true,
                },
                {
                  title: intl.formatMessage({
                    id: 'no',
                  }),
                  value: false,
                  selected: true,
                },
              ]}
              selectedValue={values.isSpecialEvent}
              onChange={value => setFieldValue('isSpecialEvent', value)}
            />
          </Col>
          <Col sm={8} xs={12}>
            <FormGroup
              data-qaid="special-event-url"
              label={intl.formatMessage({
                id: 'admin.eventBasicInfo.specialEventUrl',
              })}
              errorMsg={errorMsgForField('specialEventUrl', touched, errors)}
              required={!!values.isSpecialEvent}
            >
              <Textfield
                data-qaid="special-event-url"
                id="specialEventUrl"
                name="specialEventUrl"
                value={values.specialEventUrl}
                onChange={(e: any) => {
                  setFieldValue('specialEventUrl', e.target.value);
                }}
              />
            </FormGroup>
          </Col>
        </Grid>
      </FormGroupContainer>
      <FormSectionTitle mb={1}>
        {intl.formatMessage({
          id: 'admin.eventBasicInfo.concertMerchandising',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        {eventCollectionOptions && eventCollectionOptions.length > 0 && (
          <>
            <FormGroup
              data-qaid="event-basic-info-form-primary-event-collection-id-formgroup"
              label={intl.formatMessage({
                id: 'admin.eventBasicInfo.defaultCollection',
              })}
              errorMsg={errorMsgForField(
                'primaryEventCollectionId',
                touched,
                errors
              )}
            >
              <Grid>
                <Col lg={6} md={6} sm={9} xs={9}>
                  <Select
                    data-qaid="event-basic-info-form-primary-event-collection-id-field"
                    options={eventCollectionOptions}
                    getOptionLabel={(opt: any) => opt.title}
                    placeholder={intl.formatMessage({
                      id: 'admin.eventBasicInfo.selectCollection',
                    })}
                    onChange={(eventCollectionObj: any) => {
                      setFieldValue(
                        'primaryEventCollectionId',
                        eventCollectionObj?.id
                      );
                    }}
                    defaultValue={defaultEventCollection}
                  />
                </Col>
              </Grid>
            </FormGroup>
            <Spacer mt={7} />
          </>
        )}
        <FlexDivWithSpacing mb={5}>
          <Body>
            {fallbackEventCollection
              ? intl.formatMessage(
                  {
                    id:
                      'admin.eventBasicInfo.concertMerchandisingDescriptionWithCollection',
                  },
                  {
                    collectionTitle: fallbackEventCollection.title,
                  }
                )
              : intl.formatMessage({
                  id: 'admin.eventBasicInfo.concertMerchandisingDescription',
                })}
          </Body>
        </FlexDivWithSpacing>
        <FormGroup
          data-qaid="headline-formgroup"
          label={intl.formatMessage({
            id: 'admin.eventBasicInfo.concertHeadline',
          })}
          errorMsg={errorMsgForField('headline', touched, errors)}
        >
          <Textfield
            data-qaid="headline-field"
            id="headline"
            name="headline"
            value={values.headline}
            onChange={(e: any) => {
              setFieldValue('headline', e.target.value);
            }}
            maxLength={eventMerchandisingHeadlineMaxLength}
          />
        </FormGroup>
        <Spacer mb={6} />
        <FormGroup
          data-qaid="external-event-notes-formgroup"
          label={intl.formatMessage({
            id: 'admin.eventBasicInfo.concertDescription',
          })}
          errorMsg={errorMsgForField('externalEventNotes', touched, errors)}
        >
          <StyledTextarea
            data-qaid="external-event-notes-field"
            id="externalEventNotes"
            name="externalEventNotes"
            value={values.externalEventNotes}
            onChange={(e: any) => {
              setFieldValue('externalEventNotes', e.target.value);
            }}
            maxLength={eventMerchandisingDescriptionMaxLength}
          />
        </FormGroup>
        <Spacer mb={6} />
        <MerchandisingPropertyGroupsEditFormFields
          setFieldValue={setFieldValue}
          touched={touched}
          errors={errors}
          values={values}
          propertyContext="EventMerchandising"
          dataQaId="merchandising-properties"
        />
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.eventBasicInfo.isConcertFilmed',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <RadioGroup
          name="isFilmed"
          options={[
            {
              title: intl.formatMessage({
                id: 'yes',
              }),
              value: true,
            },
            {
              title: intl.formatMessage({
                id: 'no',
              }),
              value: false,
              selected: true,
            },
          ]}
          selectedValue={values.isFilmed}
          onChange={value => setFieldValue('isFilmed', value)}
        />
      </FormGroupContainer>
      {hasCompetitionAccessPermission && (
        <>
          <FormSectionTitle>
            {intl.formatMessage({
              id: 'shared.competition',
            })}
          </FormSectionTitle>
          <CompetitionFormGroup
            eventId={eventId}
            status={status}
            formikProps={formikProps}
          />
        </>
      )}
    </GenericFormContainer>
  );
};

export default EventBasicInfoEditForm;
