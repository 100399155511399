import gql from 'graphql-tag';

export const GET_PROPERTIES = gql`
  query GetProperties($propertyContext: String!) {
    properties(propertyContext: $propertyContext) {
      properties {
        id
        key
        name
        iconName
        position
        propertyGroupName
      }
      propertyGroupNames
    }
  }
`;
