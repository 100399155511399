import React from 'react';
import styled, { css } from 'styled-components';

interface Props {
  children: any;
  'data-qaid'?: string;
  centerContent?: boolean;
}

interface CenterProp {
  centerContent?: boolean;
  children: any;
  'data-qaid'?: string;
}

const Layout = styled.div`
  overflow-y: visible;
  height: auto;
  width: 100%;
`;

const CenterContainer = styled.div<CenterProp>`
  margin-top: 10px;
  ${({ centerContent }) =>
    centerContent &&
    css`
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    `}
`;

const PrintLayout: React.FC<Props> = ({
  children,
  centerContent,
  'data-qaid': dataQaid = 'print-layout',
  ...rest
}) => {
  return (
    <Layout data-qaid={dataQaid} {...rest}>
      <CenterContainer centerContent={centerContent}>
        {children}
      </CenterContainer>
    </Layout>
  );
};

export default PrintLayout;
