import React from 'react';
import styled from 'styled-components';

import { LoadingBlocks } from 'app/shared/components/atoms/LoadingBlocks';

import MenuTab from './MenuTab';

interface Props {
  labels: string[];
  activeLabel: string;
  handleTabClick: any;
  isLoading: boolean;
}

const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 30px;
  margin-bottom: 15px;
  padding: 0px 25px 0px 0px;
  min-height: 0px;
`;

const TableTopBar = styled.div`
  display: flex;
  justify-items: flex-start;
  width: 100%;
  padding: 0;
  align-items: center;
  margin-left: auto;
`;

const MenuTabs: React.FC<Props> = ({
  labels,
  activeLabel,
  handleTabClick,
  isLoading,
}) => {
  const tabs = labels.map((label: string, index: number) => (
    <MenuTab
      label={label}
      activeLabel={activeLabel}
      handleClick={handleTabClick}
      key={index}
    />
  ));
  return (
    <Tabs>
      {tabs}
      <TableTopBar>
        {isLoading && <LoadingBlocks.Circle width="20px" />}
      </TableTopBar>
    </Tabs>
  );
};

export default MenuTabs;
