import * as Yup from 'yup';

const continentEditFormSchema = (intl: any) =>
  Yup.object().shape({
    title: Yup.string().required(
      intl.formatMessage({
        id: 'form.requiredField',
      })
    ),
    continentCode: Yup.string()
      .when('isNewContinent', {
        is: true,
        then: Yup.string().required(
          intl.formatMessage({
            id: 'form.requiredField',
          })
        ),
      })
      .matches(/^[A-Z][A-Z]$/, {
        message: intl.formatMessage({
          id: 'admin.continentBasicInfo.form.continentCodeError',
        }),
      }),
  });

export default continentEditFormSchema;
