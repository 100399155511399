import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import DottedLine from 'app/shared/components/atoms/DottedLine';
import {
  ContentWrapper,
  HeaderWrapper,
} from 'app/shared/components/atoms/FlyoverContent';
import { Spacer } from 'app/shared/components/atoms/Spacer';

interface Props {
  onSuccess?: Function;
}

const HeaderText = styled.span`
  font-size: 10px;
  font-weight: normal;
  text-transform: uppercase;
  -webkit-letter-spacing: 1.5px;
  -moz-letter-spacing: 1.5px;
  -ms-letter-spacing: 1.5px;
  letter-spacing: 1.5px;
  line-height: 1.6;
  margin: 0;
  padding: 0;
  margin-bottom: 0px;
  display: inline-block;
`;

const Link = styled.a`
  ${({ theme }) => css`
    color: ${theme.colors.green600};
    font-size: 14px;
    cursor: pointer;
    display: flex;
  `}
`;

const ArtistsActionsFlyover: React.FC<Props> = ({ onSuccess = () => {} }) => {
  const intl = useIntl();

  return (
    <>
      <HeaderWrapper>
        <HeaderText>
          {intl.formatMessage({
            id: 'admin.artistDirectory.actions',
          })}
        </HeaderText>
        <Spacer mt={4} />
        <DottedLine />
      </HeaderWrapper>
      <Spacer mt={4} />
      <ContentWrapper>
        <Link data-qaid="create-offer-link" onClick={() => onSuccess()}>
          {intl.formatMessage({
            id: 'admin.artistDirectory.createBookingInvitation',
          })}
        </Link>
      </ContentWrapper>
    </>
  );
};

export default ArtistsActionsFlyover;
