import React from 'react';
import { useIntl } from 'react-intl';

import { get } from 'app/shared/utils/get';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { UpdateCompetition } from 'app/admin/graphql/competitions/mutationHooks';
import { DetailsFormMainContainer } from 'app/admin/components/atoms/DetailContent';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

import CompetitionContentEditForm from './CompetitionContentEditForm';
import CompetitionContentEditFormSchema from './CompetitionContentEditFormSchema';

interface Props {
  contentProps?: any;
  setFormSubmitAction: (func: Function) => void;
  setDisplayConfirmation: (set: boolean) => void;
  setIsSubmitting: (set: boolean) => void;
  navigateTo: (routeData: object) => void;
}

const CompetitionContentEdit: React.FC<Props> = ({
  contentProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  navigateTo,
}) => {
  const intl = useIntl();

  const formInitialValues = {
    intro: get(contentProps, 'intro', '') || '',
    thirdPartyCtaText: get(contentProps, 'thirdPartyCtaText', '') || '',
    thirdPartyCtaLink: get(contentProps, 'thirdPartyCtaLink', '') || '',
    subtitle: get(contentProps, 'subtitle', '') || '',
    subtitleTwo: get(contentProps, 'subtitleTwo', '') || '',
    description: get(contentProps, 'description', '') || '',
    postDescription: get(contentProps, 'postDescription', '') || '',
    winnerCopy: get(contentProps, 'winnerCopy', '') || '',
    expiredCopy: get(contentProps, 'expiredCopy', '') || '',
    moreInfoTitle: get(contentProps, 'moreInfoTitle', '') || '',
    moreInfoSubheading: get(contentProps, 'moreInfoSubheading', '') || '',
    moreInfoPreview: get(contentProps, 'moreInfoPreview', '') || '',
    featuredContentCtaText:
      get(contentProps, 'featuredContentCtaText', '') || '',
    featuredContentCtaLink:
      get(contentProps, 'featuredContentCtaLink', '') || '',
  };

  const updateCompetitionAction = UpdateCompetition();

  const handleUpdateCompetition = useSubmitAction({
    submitAction: updateCompetitionAction,
    submitVariables: (values: any) => ({
      competitionId: get(contentProps, 'id', undefined),
      intro: values.intro,
      thirdPartyCtaText: values.thirdPartyCtaText,
      thirdPartyCtaLink: values.thirdPartyCtaLink,
      subtitle: values.subtitle,
      subtitleTwo: values.subtitleTwo,
      description: values.description,
      postDescription: values.postDescription,
      winnerCopy: values.winnerCopy,
      expiredCopy: values.expiredCopy,
      moreInfoTitle: values.moreInfoTitle,
      moreInfoSubheading: values.moreInfoSubheading,
      moreInfoPreview: values.moreInfoPreview,
      featuredContentCtaText: values.featuredContentCtaText,
      featuredContentCtaLink: values.featuredContentCtaLink,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.competitionContent.form.successMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.competitionContent.form.failureMessage',
    }),
    onSuccess: response => {
      navigateTo({
        routeName: 'competition-details',
        routeProps: {
          defaultOpenSection: 'content',
          ...response.data.updateCompetition.competition,
        },
      });
    },
  });

  return (
    <ModalContentContainer>
      <DetailsFormMainContainer>
        <DetailsHeader title={get(contentProps, 'title', '')} />
        <GenericForm
          formInitialValues={formInitialValues}
          renderFormComponent={(renderProps: any) => (
            <CompetitionContentEditForm formikProps={renderProps.formikProps} />
          )}
          onSubmit={handleUpdateCompetition}
          formSchema={CompetitionContentEditFormSchema(intl)}
          setFormSubmitAction={setFormSubmitAction}
          setDisplayConfirmation={setDisplayConfirmation}
          setIsSubmitting={setIsSubmitting}
          dataQaId="competition-content-section-edit-form"
        />
      </DetailsFormMainContainer>
    </ModalContentContainer>
  );
};

export default CompetitionContentEdit;
