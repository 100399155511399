import React from 'react';
import { useIntl } from 'react-intl';

import {
  getDatesSubmitVariable,
  getDateSubmitVariable,
} from 'app/shared/utils/datetime';
import { DISCOVERY_EVENT } from 'app/shared/utils/events';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { HasACommercialCuratorGroup } from 'app/admin/utils/curatorGroupPermissions';
import { CreateEvents } from 'app/admin/graphql/events/mutationHooks';
import { DetailsFormMainContainer } from 'app/admin/components/atoms/DetailContent';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

import EventCreateForm from './EventCreateForm';
import EventCreateFormSchema from './EventCreateFormSchema';

interface Props {
  contentProps?: any;
  setFormSubmitAction?: (func: Function) => void;
  setFormSecondarySubmitAction?: (func: Function) => void;
  setDisplayConfirmation?: (set: boolean) => void;
  setIsSubmitting?: (set: boolean) => void;
  setIsSubmittingSecondary?: (set: boolean) => void;
  navigateTo: (routeData: object) => void;
  isSubmitting: boolean;
  isSubmittingSecondary: boolean;
  hide: any;
}

const EventCreate: React.FC<Props> = ({
  contentProps,
  setFormSubmitAction,
  setFormSecondarySubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  setIsSubmittingSecondary,
  isSubmitting,
  isSubmittingSecondary,
  hide,
}) => {
  const intl = useIntl();

  const canCreateAnyTicketingFlow = HasACommercialCuratorGroup();

  const formInitialValues = {
    eventType: DISCOVERY_EVENT,
    eventStatus: 'published',
    eventPurchasable: 'active',
    attendeeTicketingFlow: canCreateAnyTicketingFlow ? 'buy' : undefined,
    partner: null,
    campaign: null,
    dates: [],
    city: null,
    neighborhood: null,
    venueId: null,
    onSaleAt: null,
    ticketPrice: null,
    artist: null,
    artistConfirmed: 'not_confirmed',
    formatType: null,
  };

  function getOnSaleAtSubmitVariable(values: any): string | undefined {
    let onSaleAt = undefined;

    if (values && values.eventPurchasable == 'active') {
      const today = new Date();
      onSaleAt = new Date(
        Date.UTC(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          12,
          0,
          0
        )
      );
    }

    if (onSaleAt) {
      return getDateSubmitVariable(onSaleAt.toISOString());
    } else {
      return undefined;
    }
  }

  const createEventsAction = CreateEvents();
  const handleCreateEvents = useSubmitAction({
    submitAction: createEventsAction,
    submitVariables: values => ({
      eventType: values.eventType,
      eventStatus: values.eventStatus,
      attendeeTicketingFlow: values.attendeeTicketingFlow,
      campaignId: (values.campaign && values.campaign.id) || undefined,
      dates: getDatesSubmitVariable(values.dates),
      city: values.city && values.city.cachedSlug,
      neighborhood: values.neighborhood && values.neighborhood.cachedSlug,
      venueId: values.venueId,
      onSaleAt: getOnSaleAtSubmitVariable(values),
      isPurchasable: values.eventPurchasable === 'active' ? true : false,
      formatType: values.formatType,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.eventCreate.successMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.eventCreate.failureMessage',
    }),
    onSuccess: (_: any, resetForm: any) => {
      if (isSubmittingSecondary) {
        resetForm();
      } else {
        hide();
      }
      setIsSubmitting && setIsSubmitting(false);
      setIsSubmittingSecondary && setIsSubmittingSecondary(false);
      contentProps && contentProps.onSuccess && contentProps.onSuccess();
    },
  });

  return (
    <ModalContentContainer>
      <DetailsFormMainContainer>
        <DetailsHeader
          title={intl.formatMessage({
            id: 'admin.eventCreate.createNewEvents',
          })}
        />
        <GenericForm
          formInitialValues={formInitialValues}
          renderFormComponent={(renderProps: any) => (
            <EventCreateForm
              formikProps={renderProps.formikProps}
              setFormSubmitAction={setFormSubmitAction}
              setFormSecondarySubmitAction={setFormSecondarySubmitAction}
              setDisplayConfirmation={setDisplayConfirmation}
              setIsSubmitting={setIsSubmitting}
              setIsSubmittingSecondary={setIsSubmittingSecondary}
              isSubmitting={isSubmitting}
              isSubmittingSecondary={isSubmittingSecondary}
            />
          )}
          onSubmit={handleCreateEvents}
          formSchema={EventCreateFormSchema(intl)}
          dataQaId="event-create-form"
        />
      </DetailsFormMainContainer>
    </ModalContentContainer>
  );
};

export default EventCreate;
