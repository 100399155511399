import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Spacer } from 'app/shared/components/atoms/Spacer';
import { TagElement } from 'app/admin/components/molecules/TagElementList';

interface EventTagsProps {
  status?: string;
  canEditEvent?: boolean;
  isPurchasable?: boolean;
  isAvailable?: boolean;
  isAppliable: boolean;
  isSoldOut?: boolean;
  attendeeFlow: string;
  numTicketsAvailableForSale?: number;
}

const TagsOuterWrapper = styled.div`
  margin-bottom: 8px;
`;

const TagsWrapper = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  padding: 0px 25px 0px 0px;
`;

/*
  When displaying Confirm Off and Apply Off tags together, they don't look good on screen sizes
  between 1200px and 1300px because the width of the summary card is a bit smaller. That's why we're reducing the font size of the tag by 1px on those screen sizes. It doesn't look 100% great, but it's
  a quick improvement. Mark approved it
*/
const StyledTag = styled(TagElement)`
  text-align: center;
  padding: 1px 5px;
  margin-top: -21px;
  margin-right: -2px;
  height: 18px;
  border-radius: 0px 0px 2px 2px;

  @media screen and (min-width: 1200px) and (max-width: 1300px) {
    font-size: 9px;
  }
`;

const eventShadedColors = {
  mainBackground: '#E8E8E8',
  status: 'blueSmoke',
  artistVenueConfirmed: 'macyGrey',
  guestsProgressBarFilled: 'lightBlueSmoke',
  guestsProgressBarUnfilled: 'whiteDenim',
};

const EventTags: React.FC<EventTagsProps> = ({
  status = 'published',
  canEditEvent,
  isAppliable,
  isAvailable,
  isSoldOut,
  attendeeFlow,
}) => {
  const intl = useIntl();
  let tags: any = [];
  const isBuyFlow = attendeeFlow === 'buy';

  const tagsConfig = {
    applyOff: {
      tagElementColor: '#9B525F',
      name: intl.formatMessage({
        id: 'admin.eventPlanner.applyOff',
      }),
    },
    cancelled: {
      tagElementColor: '#EF4423',
      name: intl.formatMessage({
        id: 'admin.eventPlanner.cancelledStatus',
      }),
    },
    confirmOff: {
      tagElementColor: '#9B59B6',
      name: intl.formatMessage({
        id: 'admin.eventPlanner.confirmOff',
      }),
    },
    draft: {
      tagElementColor: '#3498DB',
      name: intl.formatMessage({
        id: 'shared.draft',
      }),
    },
    paused: {
      tagElementColor: '#F39C12',
      name: intl.formatMessage({
        id: 'admin.eventPlanner.ticketsPaused',
      }),
    },
    soldOut: {
      tagElementColor: '#97cb54',
      name: intl.formatMessage({
        id: 'admin.eventPlanner.soldOut',
      }),
    },
  };

  if (status !== 'published') {
    tags.push(tagsConfig[status]);
  } else {
    if (!isAvailable) {
      if (isSoldOut) {
        tags.push(tagsConfig.soldOut);
      } else {
        tags.push(tagsConfig[isBuyFlow ? 'paused' : 'confirmOff']);
      }
    }

    if (!isBuyFlow && !isAppliable) {
      tags.push(tagsConfig.applyOff);
    }
  }

  return (
    <TagsOuterWrapper>
      <TagsWrapper>
        {tags.map((tagElement: any, i: number) => (
          <React.Fragment key={i}>
            <StyledTag
              key={i}
              tagElementColor={
                canEditEvent
                  ? tagElement.tagElementColor
                  : eventShadedColors.status
              }
              textColor="#fff"
              data-qaid={`tag-element-${i}`}
            >
              {tagElement.name}
            </StyledTag>
            <Spacer mr={1} />
          </React.Fragment>
        ))}
      </TagsWrapper>
    </TagsOuterWrapper>
  );
};

export default EventTags;
