import React from 'react';
import styled, { css } from 'styled-components';

import { GeneralEvent } from 'app/typings/events';
import { dateFormatter } from 'app/shared/utils/datetime';
import { BaseLink } from 'app/shared/components/atoms/LinkManualCSS';
import DetailText from 'app/admin/components/atoms/DetailText';

interface Props {
  event: GeneralEvent;
}

const CityNameDetailText = styled(DetailText)`
  ${({ theme }) => css`
    color: ${theme.colors.green600};
    margin-bottom: 5px;
  `}
`;

const availabilityRange = (startDate: string, endDate: string) => {
  const yearStart = dateFormatter(startDate, 'longYear');
  const yearFinish = dateFormatter(endDate, 'longYear');
  const dayStart = dateFormatter(startDate, 'shortMonthAndDate');
  const endDay = dateFormatter(endDate, 'shortMonthAndDate');

  if (yearStart === yearFinish) {
    return endDay === dayStart
      ? `${dayStart}, ${yearStart}`
      : `${dayStart} - ${endDay}, ${yearStart}`;
  }

  return `${dateFormatter(
    startDate,
    'shortMonthDateAndYear'
  )} - ${dateFormatter(endDate, 'shortMonthDateAndYear')}`;
};

const BookingRequestSummary: React.FC<Props> = ({ event }) => {
  const cityTitle = event.city.title || '';

  return (
    <>
      <BaseLink
        href="/admin/booking-requests"
        openInNewTab={true}
        data-qaid="link"
      >
        <CityNameDetailText data-qaid="city-title">
          {cityTitle}
        </CityNameDetailText>
      </BaseLink>
      {event.availabilityStart && event.availabilityEnd && (
        <DetailText data-qaid="date">
          {availabilityRange(event.availabilityStart, event.availabilityEnd)}
        </DetailText>
      )}
    </>
  );
};

export default BookingRequestSummary;
