import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { SocialUrls } from 'app/typings/socialUrls';
import usePermission from 'app/shared/utils/usePermission';
import { EditIcon } from 'app/shared/components/atoms/IconLibrary';
import { BaseLink } from 'app/shared/components/atoms/LinkManualCSS';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import TruncatedByCharText from 'app/shared/components/atoms/TruncatedByCharText';
import { Body2 } from 'app/shared/components/atoms/TypographyManualCSS';
import HelpIconAndFlyover from 'app/shared/components/molecules/HelpIconAndFlyover';
import {
  DetailHeading as Heading,
  DetailValueContainer as ValueContainer,
} from 'app/admin/components/atoms/DetailContent';
import { None } from 'app/admin/components/atoms/None';

interface SocialLinkListProps {
  socialLinks: SocialUrls | undefined;
  socialLinksToDisplay?: string[];
  helpIconFlyoverBody?: string;
  helpIconInfoByLink?: object;
  toggleEditModal?: Function;
}

const MainContainer = styled.div`
  margin-bottom: 25px;
`;

const HeadingAndEditLinkContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const EditIconWrapper = styled.div`
  margin-left: 10px;
`;

const StyledValueContainer = styled(ValueContainer)`
  margin-bottom: 15px;
`;

const Subheading = styled.div`
  font-size: 14px;
  padding-bottom: 10px;
`;

const TextWithHelpIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const HelpIconAndFlyoverContainer = styled.div`
  padding-left: 10px;
  padding-bottom: 10px;
`;

const Body2ForHelpIcon = styled.div`
  margin-top: -10px;
`;

const SocialLink = styled(BaseLink)`
  ${({ theme }) => css`
    color: ${theme.colors.green600};
  `}
`;

const urlHeadings = {
  urlFacebook: 'Facebook',
  urlTwitter: 'Twitter',
  urlWebsite: 'Website',
  urlSoundcloud: 'SoundCloud',
  urlYoutube: 'YouTube',
  urlInstagram: 'Instagram',
  urlVKontacte: 'VKontacte',
  urlBandcamp: 'Bandcamp',
  urlSpotify: 'Spotify',
  urlSupport: 'Support',
};

const getSocialLinkKeys = (socialLinksToDisplay: string[] | undefined) => {
  const socialLinkKeysDefault = Object.keys(urlHeadings);
  return socialLinksToDisplay
    ? socialLinksToDisplay.filter(value =>
        socialLinkKeysDefault.includes(value)
      )
    : socialLinkKeysDefault;
};

const SocialLinkList: React.FC<SocialLinkListProps> = ({
  socialLinks,
  socialLinksToDisplay,
  helpIconFlyoverBody,
  helpIconInfoByLink,
  toggleEditModal,
}) => {
  const intl = useIntl();
  const socialLinkKeys = getSocialLinkKeys(socialLinksToDisplay);
  const hasAccessArtistsPermission = usePermission('artist.list.accessBasic');

  return (
    <MainContainer>
      <HeadingAndEditLinkContainer>
        <Heading>
          {intl.formatMessage({
            id: 'admin.socialLinkList.links',
          })}
        </Heading>
        {hasAccessArtistsPermission && toggleEditModal && (
          <EditIconWrapper>
            <EditIcon
              iconSize={14}
              onClick={() => toggleEditModal()}
              dataQaid="edit-socials-icon"
            />
          </EditIconWrapper>
        )}
      </HeadingAndEditLinkContainer>
      <TextWithHelpIconContainer>
        <Subheading>
          {intl.formatMessage({
            id: 'admin.socialLinkList.socialMediaLinks',
          })}
        </Subheading>
        {helpIconFlyoverBody && (
          <HelpIconAndFlyoverContainer>
            <HelpIconAndFlyover
              title={intl.formatMessage({
                id: 'admin.socialLinkList.socialMediaLinks',
              })}
              body={helpIconFlyoverBody}
              body2={undefined}
            />
          </HelpIconAndFlyoverContainer>
        )}
      </TextWithHelpIconContainer>
      <Spacer mb={3} />
      {socialLinks ? (
        socialLinkKeys.map((key: string, index: number) => (
          <React.Fragment key={index}>
            {helpIconInfoByLink && helpIconInfoByLink[key] ? (
              <TextWithHelpIconContainer>
                <Body2ForHelpIcon>
                  <Body2>{urlHeadings[key]}</Body2>
                </Body2ForHelpIcon>
                <HelpIconAndFlyoverContainer>
                  <HelpIconAndFlyover
                    title={helpIconInfoByLink[key].title}
                    body={helpIconInfoByLink[key].body}
                    body2={helpIconInfoByLink[key].body2}
                  />
                </HelpIconAndFlyoverContainer>
              </TextWithHelpIconContainer>
            ) : (
              <Body2>{urlHeadings[key]}</Body2>
            )}
            <StyledValueContainer>
              {socialLinks[key] ? (
                <SocialLink
                  href={socialLinks[key]}
                  openInNewTab={true}
                  data-qaid={`social-link-${key.toLowerCase()}`}
                >
                  <TruncatedByCharText
                    text={socialLinks[key]}
                    truncateLength={35}
                    isLink={true}
                  />
                </SocialLink>
              ) : (
                <None />
              )}
            </StyledValueContainer>
          </React.Fragment>
        ))
      ) : (
        <None />
      )}
    </MainContainer>
  );
};

export default SocialLinkList;
