import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { Property } from 'app/typings/properties';
import { dateFormatter } from 'app/shared/utils/datetime';
import { get } from 'app/shared/utils/get';
import { dataGetter } from 'app/shared/utils/queries';
import { truncateByChar } from 'app/shared/utils/string';
import useModal from 'app/shared/utils/useModal';
import usePermission from 'app/shared/utils/usePermission';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import ConfirmationModal from 'app/shared/components/molecules/ConfirmationModal';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { HasPermissionForCity } from 'app/admin/utils/cityPermissions';
import { userFullNameOrEmail } from 'app/admin/utils/users';
import { SendEmail } from 'app/admin/graphql/emails/mutationHooks';
import {
  ArchiveVenue,
  DeleteVenuePhoto,
  ResetVenueTermsAndConditions,
} from 'app/admin/graphql/venues/mutationHooks';
import { GetVenueBasicInfo } from 'app/admin/graphql/venues/queryHooks';
import { DetailsMainContainer } from 'app/admin/components/atoms/DetailContent';
import AccordionSections from 'app/admin/components/organisms/AccordionSections';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';
import { ReactComponent as InformationCircle } from 'icons/streamline-regular/interface-essential/alerts/information-circle.svg';
import { ReactComponent as SocialVideoYoutubeClip } from 'icons/streamline-regular/logos/videos/social-video-youtube-clip.svg';
import { ReactComponent as ModernMusicDj } from 'icons/streamline-regular/music-audio/modern-music/modern-music-dj.svg';
import { ReactComponent as MusicGenreTeam } from 'icons/streamline-regular/music-audio/music-genres/music-genre-team.svg';
import { ReactComponent as MultipleChat } from 'icons/streamline-regular/users/geomertic-close-up-multiple-users/multiple-chat.svg';

import SectionBasicInfo from './SectionBasicInfo';
import SectionBookingInformation from './SectionBookingInformation';
import SectionEvents from './SectionEvents';
import SectionEventSetup from './SectionEventSetup';
import SectionFanExperience from './SectionFanExperience';
import SectionPhotos from './SectionPhotos';

interface Props {
  contentProps: any;
  navigateTo: (routeData: any) => void;
  hide: (hideProps?: any) => VoidFunction;
}

const detailsHeaderSummaryInfoList = (
  upcomingEventsCount: number | undefined,
  pastEventsCount: number | undefined,
  lastEventAt: string | undefined
) => {
  const upcomingEventsCountDescription = `Upcoming ${
    upcomingEventsCount === 1 ? 'Concert' : 'Concerts'
  }`;

  const pastEventsCountDescription = `Total ${
    pastEventsCount === 1 ? 'Concert' : 'Concerts'
  }`;

  const lastEventMonth = lastEventAt
    ? dateFormatter(lastEventAt, 'shortMonth').toUpperCase()
    : 'N/A';

  const lastEventYear = lastEventAt
    ? dateFormatter(lastEventAt, 'longYear')
    : null;

  const lastEventDescription = lastEventAt
    ? `${lastEventYear} \n Last Concert`
    : 'Last Concert';

  return [
    {
      backgroundColor: '#ebedef',
      mainInfo: upcomingEventsCount && upcomingEventsCount.toString(),
      description: upcomingEventsCountDescription,
    },
    {
      backgroundColor: '#ebf5fb',
      mainInfo: pastEventsCount && pastEventsCount.toString(),
      description: pastEventsCountDescription,
    },
    {
      backgroundColor: '#f3f9ed',
      mainInfo: lastEventMonth,
      description: lastEventDescription,
    },
  ];
};

const detailsHeaderActionLinksInfo = (
  toggleArchiveVenueConfirmationModal: VoidFunction,
  isArchived: boolean,
  hasArchiveVenuePermission: boolean,
  intl: any
) => {
  return {
    link1: {
      text: intl.formatMessage({
        id: 'admin.venueDirectory.archive',
      }),
      active: hasArchiveVenuePermission && !isArchived,
      onClickAction: toggleArchiveVenueConfirmationModal,
    },
  };
};

const primaryContactEmail = (dataBasicInfo?: any) => {
  const contactInfos = get(dataBasicInfo, 'venue.contactInfos', []);
  const primaryContactInfo = contactInfos.find((ci: any) => ci.position == 1);
  return primaryContactInfo && primaryContactInfo.email;
};

const VenueDetails: React.FC<Props> = ({ contentProps, hide, navigateTo }) => {
  const {
    id,
    imageUrl,
    largeImageUrl,
    isArchived,
    venueName,
    upcomingEventsCount,
    pastEventsCount,
    lastEventAt,
    properties,
  } = contentProps;

  const intl = useIntl();

  const [
    archiveVenueConfirmationModal,
    toggleArchiveVenueConfirmationModal,
  ] = useModal();
  const [
    resendConfirmEmailConfirmationModal,
    toggleResendConfirmEmailConfirmationModal,
  ] = useModal();
  const [
    unlinkUserConfirmationModal,
    toggleUnlinkUserConfirmationModal,
  ] = useModal();

  const [previewConfirmationModal, togglePreviewConfirmationModal] = useModal();

  const [venuePhoto, setVenuePhoto] = useState<{
    id?: number;
    name?: string;
  }>({});

  const [headerData, setHeaderData] = useState({
    imageUrl,
    largeImageUrl,
    venueName,
    upcomingEventsCount,
    pastEventsCount,
    lastEventAt,
    isArchived,
    isLoaded: !!venueName || !!imageUrl,
    properties,
  });

  const [isArchivingVenue, setIsArchivingVenue] = useState<boolean>(false);

  const {
    loading: loadingBasicInfo,
    error: errorBasicInfo,
    data: dataBasicInfo,
    refetch: refetchBasicInfo,
  } = GetVenueBasicInfo({
    id,
    page: 1,
    perPage: 3,
    fetchPolicy: 'no-cache',
  });

  const hasArchiveAllVenuesPermission = usePermission('venue.list.access');
  const hasArchiveCreationTypeIcoVenuePermission = usePermission(
    'venue.list.accessBasicByCity',
    get(dataBasicInfo, 'venue.city.id', undefined)
  );

  const hasArchiveVenuePermission =
    hasArchiveAllVenuesPermission ||
    (hasArchiveCreationTypeIcoVenuePermission &&
      get(dataBasicInfo, 'venue.creationType', '') == 'curator');

  const sectionsConfig = {
    basicInfo: {
      title: intl.formatMessage({
        id: 'admin.venueDirectory.details.sectionTitle.basicInfo',
      }),
      icon: InformationCircle,
      iconColor: 'blueChristmas',
      sectionComponent: SectionBasicInfo,
      dataKey: 'venue',
      displayEditIcon: true,
      loading: loadingBasicInfo,
      data: dataBasicInfo
        ? {
            ...dataBasicInfo.venue,
          }
        : undefined,
      callbacks: {
        toggleSectionEditModal: () =>
          navigateTo({
            routeName: 'venue-basic-info-edit',
            routeProps: dataBasicInfo ? dataBasicInfo.venue : undefined,
          }),
        toggleResendConfirmEmailConfirmationModal,
        toggleUnlinkUserConfirmationModal,
      },
    },
    bookingInformation: {
      title: intl.formatMessage({
        id: 'admin.venueDirectory.details.sectionTitle.bookingInformation',
      }),
      icon: ModernMusicDj,
      iconColor: 'purpleHaze',
      iconCircle: true,
      sectionComponent: SectionBookingInformation,
      dataKey: 'venueBookingInformation',
      displayEditIcon: true,
      loading: loadingBasicInfo,
      data: dataBasicInfo ? dataBasicInfo.venue : undefined,
      callbacks: {
        toggleSectionEditModal: () =>
          navigateTo({
            routeName: 'venue-booking-information-edit',
            routeProps: dataBasicInfo ? dataBasicInfo.venue : undefined,
          }),
      },
    },
    eventSetup: {
      title: intl.formatMessage({
        id: 'admin.venueDirectory.details.sectionTitle.eventSetup',
      }),
      icon: ModernMusicDj,
      iconColor: 'checkBerry',
      iconCircle: true,
      sectionComponent: SectionEventSetup,
      dataKey: 'venueEventSetup',
      displayEditIcon: true,
      loading: loadingBasicInfo,
      data: dataBasicInfo
        ? {
            ...dataBasicInfo.venue,
          }
        : undefined,
      callbacks: {
        toggleSectionEditModal: () =>
          navigateTo({
            routeName: 'venue-event-setup-edit',
            routeProps: dataBasicInfo ? dataBasicInfo.venue : undefined,
          }),
      },
    },
    fanExperience: {
      title: intl.formatMessage({
        id: 'admin.shared.fanExperience',
      }),
      icon: MultipleChat,
      iconColor: 'redRedWine',
      iconCircle: true,
      sectionComponent: SectionFanExperience,
      dataKey: 'venueFanExperience',
      displayEditIcon: true,
      loading: loadingBasicInfo,
      data: dataBasicInfo
        ? {
            ...dataBasicInfo.venue,
          }
        : undefined,
      callbacks: {
        toggleSectionEditModal: () =>
          navigateTo({
            routeName: 'venue-fan-experience-edit',
            routeProps: dataBasicInfo ? dataBasicInfo.venue : undefined,
          }),
      },
    },
    events: {
      title: intl.formatMessage({
        id: 'shared.concerts',
      }),
      icon: MusicGenreTeam,
      iconColor: 'orangeCrush',
      iconCircle: true,
      sectionComponent: SectionEvents,
      dataKey: 'venueEvents',
      displayEditIcon: false,
      loading: loadingBasicInfo,
      data: dataBasicInfo
        ? {
            data: dataBasicInfo.venue,
            eventType: 'venues',
          }
        : undefined,
      callbacks: {
        toggleAllEventsAndSetType: (eventType: string) =>
          navigateTo({
            routeName: 'venue-all-events',
            routeProps: dataBasicInfo
              ? {
                  eventType: `venue${eventType}`,
                  id,
                  title: headerData.venueName,
                  imageUrl: headerData.imageUrl,
                  largeImageUrl: headerData.largeImageUrl,
                  imageStyle: 'landscape',
                  dataQaidPrefix: 'venue-events',
                }
              : undefined,
          }),
      },
    },
    photos: {
      title: intl.formatMessage({
        id: 'admin.venueDirectory.details.sectionTitle.photos',
      }),
      icon: SocialVideoYoutubeClip,
      iconColor: 'greenOnions',
      iconCircle: true,
      sectionComponent: SectionPhotos,
      dataKey: 'venuePhotos',
      displayEditIcon: false,
      loading: loadingBasicInfo,
      data: {
        ...dataBasicInfo,
        setVenuePhoto,
        togglePreviewConfirmationModal,
        refetchBasicInfo,
        navigateTo,
      },
      callbacks: {},
    },
  };

  const onEditProfileImageClickModal = () => {
    navigateTo({
      routeName: 'venue-profile-image-uploader',
      routeProps: dataBasicInfo ? dataBasicInfo.venue : undefined,
    });
  };

  useEffect(() => {
    const getDataBasicInfo = dataGetter(dataBasicInfo, 'venue');
    if (dataBasicInfo) {
      setHeaderData({
        venueName: getDataBasicInfo('venueName'),
        imageUrl: getDataBasicInfo('imageUrl'),
        largeImageUrl: getDataBasicInfo('largeImageUrl'),
        lastEventAt: getDataBasicInfo('lastEventAt'),
        upcomingEventsCount: getDataBasicInfo('upcomingEventsCount'),
        pastEventsCount: getDataBasicInfo('pastEventsCount'),
        isArchived: getDataBasicInfo('isArchived'),
        isLoaded: true,
        properties: getDataBasicInfo('properties'),
      });
    }
  }, [dataBasicInfo]);

  const summaryInfoList = detailsHeaderSummaryInfoList(
    headerData.upcomingEventsCount,
    headerData.pastEventsCount,
    headerData.lastEventAt
  );

  const archiveVenueAction = ArchiveVenue();

  const handleArchiveVenue = useSubmitAction({
    beforeSubmit: () => setIsArchivingVenue(true),
    submitAction: archiveVenueAction,
    submitVariables: () => ({
      id: contentProps.id,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.venueDirectory.venueArchiveSuccessMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.venueDirectory.venueArchiveFailureMessage',
    }),
    onSuccess: () => {
      setIsArchivingVenue(false);
      hide({ shouldRefetchVenues: true });
    },
  });

  const sendEmailAction = SendEmail();

  const handleResendConfirmEmail = useSubmitAction({
    submitAction: sendEmailAction,
    submitVariables: () => ({
      emailKey: 'VenueConfirmDetails',
      venueId: contentProps.id,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.venueDirectory.venueResendConfirmEmailSuccessMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.venueDirectory.venueResendConfirmEmailFailureMessage',
    }),
    onSuccess: () => {
      resendConfirmEmailConfirmationModal.hide();
      refetchBasicInfo();
    },
  });

  const deleteVenuePhotoAction = DeleteVenuePhoto();

  const handleDeleteVenuePhoto = useSubmitAction({
    submitAction: deleteVenuePhotoAction,
    submitVariables: () => ({
      venuePhotoId: venuePhoto.id,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.venueDirectory.photoDeleteSuccessMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.venueDirectory.photoDeleteFailureMessage',
    }),
    onSuccess: () => {
      previewConfirmationModal.hide();
      refetchBasicInfo();
    },
  });

  const ResetVenueTermsAndConditionsAction = ResetVenueTermsAndConditions();

  const handleUnlinkUser = useSubmitAction({
    submitAction: ResetVenueTermsAndConditionsAction,
    submitVariables: () => ({
      id: contentProps.id,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.venueDirectory.venueUnlinkUserSuccessMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.venueDirectory.venueUnlinkUserFailureMessage',
    }),
    onSuccess: () => {
      unlinkUserConfirmationModal.hide();
      refetchBasicInfo();
    },
  });

  const getVenueNameFallback = () => {
    if (!headerData || !headerData.properties) {
      return null;
    }
    const isResidential = headerData.properties.some(
      (property: Property) => property.key === 'residential'
    );
    return isResidential
      ? intl.formatMessage({
          id: 'admin.venueCard.title.residence',
        })
      : intl.formatMessage({
          id: 'none',
        });
  };

  if (
    !HasPermissionForCity(
      get(dataBasicInfo, 'venue.city.id', undefined),
      'venue.list.viewByCity'
    )
  ) {
    hide();
    return null;
  }

  if ((!loadingBasicInfo && !dataBasicInfo) || errorBasicInfo) {
    hide();
    return null;
  }

  return (
    <>
      <ModalContentContainer data-qaid="venue-details-main-modal">
        <DetailsMainContainer>
          <DetailsHeader
            title={headerData.venueName || getVenueNameFallback()}
            imageUrl={headerData.imageUrl}
            largeImageUrl={headerData.largeImageUrl}
            summaryInfoList={summaryInfoList}
            actionLinksInfo={detailsHeaderActionLinksInfo(
              toggleArchiveVenueConfirmationModal,
              headerData.isArchived,
              hasArchiveVenuePermission,
              intl
            )}
            loading={!headerData.isLoaded}
            imageStyle="landscape"
            displayEditIcon={true}
            onEditIconClick={onEditProfileImageClickModal}
            largeImageBackButtonText={intl.formatMessage({
              id: 'admin.venueDirectory.largeImageBackButtonText',
            })}
          />
          <AccordionSections
            sectionsConfig={sectionsConfig}
            defaultOpenSection={contentProps.defaultOpenSection}
          />
        </DetailsMainContainer>
      </ModalContentContainer>
      {archiveVenueConfirmationModal.isShowing && (
        <ConfirmationModal
          onCancel={() => archiveVenueConfirmationModal.hide()}
          description={intl.formatMessage({
            id: 'admin.venueDirectory.venueArchiveConfirmation',
          })}
          onConfirm={handleArchiveVenue}
          isLoading={isArchivingVenue}
          confirmationButtonText={intl.formatMessage({
            id: 'admin.venueDirectory.venueArchiveConfirmationText',
          })}
        />
      )}
      {resendConfirmEmailConfirmationModal.isShowing && (
        <ConfirmationModal
          onCancel={() => resendConfirmEmailConfirmationModal.hide()}
          description={intl.formatMessage(
            {
              id: 'admin.venueDirectory.venueResendConfirmEmailConfirmation',
            },
            {
              venueName: headerData.venueName || 'this venue',
              email:
                primaryContactEmail(dataBasicInfo) ||
                intl.formatMessage({
                  id: 'admin.venueDirectory.theirPrimaryEmail',
                }),
            }
          )}
          onConfirm={handleResendConfirmEmail}
          isLoading={isArchivingVenue}
          confirmationButtonText={intl.formatMessage({
            id: 'admin.venueDirectory.venueResendConfirmEmailConfirmationText',
          })}
        />
      )}
      {unlinkUserConfirmationModal.isShowing && (
        <ConfirmationModal
          onCancel={() => unlinkUserConfirmationModal.hide()}
          description={intl.formatMessage(
            {
              id: 'admin.venueDirectory.venueUnlinkUserConfirmation',
            },
            {
              venueName: headerData.venueName || 'this venue',
              userName: get(
                dataBasicInfo,
                'venue.termsAndConditionsAcceptedBy',
                undefined
              )
                ? userFullNameOrEmail(
                    get(
                      dataBasicInfo,
                      'venue.termsAndConditionsAcceptedBy',
                      undefined
                    )
                  )
                : 'this user',
            }
          )}
          onConfirm={handleUnlinkUser}
          isLoading={isArchivingVenue}
          confirmationButtonText={intl.formatMessage({
            id: 'admin.venueDirectory.venueUnlinkUserConfirmationText',
          })}
        />
      )}
      {previewConfirmationModal.isShowing && venuePhoto.id && (
        <ConfirmationModal
          onCancel={() => previewConfirmationModal.hide()}
          description={intl.formatMessage(
            {
              id: 'admin.venueDirectory.photoDeleteConfirmation',
            },
            {
              imageName: truncateByChar(venuePhoto.name || null, 20),
            }
          )}
          onConfirm={handleDeleteVenuePhoto}
          confirmationButtonText={intl.formatMessage({
            id: 'shared.delete',
          })}
          cancellationButtonText={intl.formatMessage({
            id: 'shared.cancel',
          })}
        />
      )}
    </>
  );
};

export default VenueDetails;
