import gql from 'graphql-tag';

import { artistSocialUrls } from 'app/shared/utils/socialLinks';

export const UPDATE_ARTIST = gql`
  mutation updateArtist(
    $artistId: Int!
    $title: String
    $email: String
    $homeCityDescription: String
    $homeCity: String
    $homeState: String
    $homeCountryCode: String
    $homeCityLatitude: Float
    $homeCityLongitude: Float
    $closestSofarCityId: Int
    $description: String
    $preferredPronoun: String
    $socialUrls: SocialUrlsInput
    $mainImage: RefileImageInput
    $status: Int
    $processTags: Boolean
    $tags: [Int!]
    $processEligibilities: Boolean
    $eligibilities: [String!]
    $isSelfBookingEnabled: Boolean
    $needsReview: Boolean
    $shouldNotBook: Boolean
    $performingRightsOrganisationId: Int
    $contactInfos: [MutateContactInfo!]
    $signedStatus: String
    $userIdToAssociate: Int
    $userIdsToUnassociate: [Int!]
    $processProperties: Boolean
    $properties: [Int!]
    $latestWorkTitle: String
    $latestWorkDescription: String
    $latestWorkUrl: String
    $bookingPreferencesNotes: String
    $bookingPreferences: [ArtistBookingPreferencesInput!]
  ) {
    updateArtist(
      artistId: $artistId
      title: $title
      email: $email
      homeCityDescription: $homeCityDescription
      homeCity: $homeCity
      homeState: $homeState
      homeCountryCode: $homeCountryCode
      homeCityLatitude: $homeCityLatitude
      homeCityLongitude: $homeCityLongitude
      closestSofarCityId: $closestSofarCityId
      description: $description
      preferredPronoun: $preferredPronoun
      socialUrls: $socialUrls
      mainImage: $mainImage
      status: $status
      processTags: $processTags
      tags: $tags
      processEligibilities: $processEligibilities
      eligibilities: $eligibilities
      isSelfBookingEnabled: $isSelfBookingEnabled
      needsReview: $needsReview
      shouldNotBook: $shouldNotBook
      performingRightsOrganisationId: $performingRightsOrganisationId
      contactInfos: $contactInfos
      signedStatus: $signedStatus
      userIdToAssociate: $userIdToAssociate
      userIdsToUnassociate: $userIdsToUnassociate
      processProperties: $processProperties
      properties: $properties
      latestWorkTitle: $latestWorkTitle
      latestWorkDescription: $latestWorkDescription
      latestWorkUrl: $latestWorkUrl
      bookingPreferencesNotes: $bookingPreferencesNotes
      bookingPreferences: $bookingPreferences
    ) {
      success
      artist {
        id
        email
        title
        homeCityDescription
        homeCity
        homeState
        homeCountryCode
        homeCityLatitude
        homeCityLongitude
        closestSofarCityTitle
        closestSofarCityCountry
        closestSofarCityId
        imageUrl
        largeImageUrl
        description
        signedStatus
        bookingPreferencesNotes
        bookingPreferencesUpdatedAt
        bookingPreferences {
          id
          artistBookingAvailabilityDates {
            id
            date
          }
          isAvailableLastMinute
          isAvailableOnCertainDates
          isGenerallyAvailable
          city {
            id
            title
            cityOperatingModel
            country {
              id
              title
            }
          }
        }
        tags {
          id
          name
        }
        artistEligibilities {
          key
          name
        }
        isSelfBookingEnabled
        needsReview
        shouldNotBook
        performingRightsOrganisation {
          id
          title
          country {
            id
            title
          }
        }
        associatedUsers {
          id
          firstName
          lastName
          email
        }
        contactInfos {
          id
          firstName
          lastName
          email
          phoneNumber
          position
          isDayOfShowContact
          contactRelationship {
            id
            name
          }
        }
        socialUrls {
          ${artistSocialUrls.join(' ')}
        }
      }
    }
  }
`;
