import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { CuratorGroup } from 'app/typings/curatorGroups';
import { currencyRoundedFormatter } from 'app/shared/utils/currencyFormatter';
import { dateFormatter } from 'app/shared/utils/datetime';
import { capitalize, snakeCaseToDash } from 'app/shared/utils/string';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import {
  DetailHeading as Heading,
  DetailValueContainer as ValueContainer,
} from 'app/admin/components/atoms/DetailContent';
import { None } from 'app/admin/components/atoms/None';
import { CountryLink } from 'app/admin/components/molecules/EntityLink';
import { CityLink } from 'app/admin/components/molecules/EntityLink';

interface Props {
  sectionData: CuratorGroup;
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const ValueContainerWrapped = styled(ValueContainer)`
  white-space: normal;
`;

const SectionBasicInfo: React.FC<Props> = ({ sectionData }) => {
  const intl = useIntl();

  const ticketPriceForDisplay = currencyRoundedFormatter(
    sectionData.eventPrice || 0,
    sectionData.currencySymbol || '$'
  );

  return (
    <MainContainer data-qaid="section-about">
      <Grid cols={4}>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={1}>
          <Heading>
            {intl.formatMessage({
              id: 'admin.curatorGroupBasicInfo.heading.curatorGroup',
            })}
          </Heading>
          <ValueContainerWrapped>{sectionData.name}</ValueContainerWrapped>
          <Heading>
            {intl.formatMessage({
              id: 'admin.shared.type',
            })}
          </Heading>
          <ValueContainer>
            {capitalize(snakeCaseToDash(sectionData.curatorGroupType))}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.curatorGroupBasicInfo.heading.groupStatus',
            })}
          </Heading>
          <ValueContainer>{capitalize(sectionData.status)}</ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.curatorGroupBasicInfo.heading.groupSince',
            })}
          </Heading>
          <ValueContainer>
            {dateFormatter(sectionData.createdAt, 'shortMonthDateAndYear')}
          </ValueContainer>
        </Col>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={2}>
          <Heading>
            {intl.formatMessage({
              id: 'shared.country',
            })}
          </Heading>
          <ValueContainer>
            {sectionData.city ? (
              <CountryLink country={sectionData.city.country} />
            ) : null}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'shared.city',
            })}
          </Heading>
          <ValueContainer>
            {sectionData.city ? <CityLink city={sectionData.city} /> : null}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.curatorGroupBasicInfo.heading.timeZone',
            })}
          </Heading>
          <ValueContainer>
            {sectionData.city ? sectionData.city.timezone : null}
          </ValueContainer>
          {sectionData.eventPrice || sectionData.eventPrice == 0 ? (
            <>
              <Heading>
                {intl.formatMessage({
                  id: 'admin.shared.ticketPrice',
                })}
              </Heading>
              <ValueContainer data-qaid="ticket-price-view">
                {ticketPriceForDisplay}
              </ValueContainer>
            </>
          ) : (
            <>
              <Heading>
                {intl.formatMessage({
                  id: 'admin.shared.ticketPrice',
                })}
              </Heading>
              <None />
            </>
          )}
        </Col>
      </Grid>
    </MainContainer>
  );
};

export default SectionBasicInfo;
