import React, { useContext, useEffect, useState } from 'react';
import { IntlProvider, useIntl } from 'react-intl';
import fanLocale from 'src/locales/en/fan.json';
import styled, { css } from 'styled-components';
import { v4 as uuid } from 'uuid';

import { CitiesApiResponse, City } from 'app/typings/cities';
import { ClosestCitiesApiResponse } from 'app/typings/closestCities';
import { useCurrentTheme } from 'app/shared/theme';
import { useAnalyticsContext } from 'app/shared/utils';
import { get } from 'app/shared/utils/get';
import { useCitiesSearchOnInput } from 'app/shared/utils/useCitiesSearchOnInput';
import { GetClosestCitiesLazy } from 'app/shared/graphql/cities/queryHooks';
import { GetCitiesForCitySelectorLazy } from 'app/shared/graphql/cities/queryHooks';
import { AuthContext } from 'app/shared/context/Auth';
import { useCityContext } from 'app/shared/context/City';
import { IntlContext } from 'app/shared/context/Intl';
import { Icon } from 'app/shared/components/atoms/IconManualCSS';
import { MenuItem } from 'app/shared/components/atoms/Menu';
import { Body2 } from 'app/shared/components/atoms/TypographyManualCSS';
import { SelectState } from 'app/shared/components/molecules/SelectManualCSS';
import Select from 'app/shared/components/molecules/SelectManualCSS';
import { ActionButtonColors } from 'app/shared/components/molecules/SelectManualCSS/Input';

const defaultOptionClick = (option: City) => {
  if (option) {
    window.location.replace(`/cities/${option.cachedSlug}`);
  }
};

interface Props {
  onChange?: (city: City) => void;
  onBlur?: () => void;
  invertColor?: boolean;
  actionButtonColors?: ActionButtonColors;
  initialWidth?: string;
  name?: string;
  hasError?: boolean;
  disabled?: boolean;
  disableTopCities?: boolean;
  disableClosestCities?: boolean;
  showInactiveCities?: boolean;
  'data-qaid'?: string;
  initialValue?: City;
  value?: City;
  cityIds?: number[];
  placeholder?: string;
  parentContainer?: string;
  recommendedOptionsTitle?: string;
  recommendedOptionsTitleSingular?: string;
  mostVisitedOptionsTitle?: string;
  className?: string;
  groupBy?: (option: City) => string;
  showCountry?: boolean;
  showCityOperatingModel?: boolean;
  cityOperatingModels?: string[];
  keepInViewPort?: boolean;
  orderBy?: string;
  orderDirection?: string;
}

const SelectOptionText = styled(Body2)`
  ${({ theme }) => css`
    ${theme.media.xs`
      font-size: ${theme.fontSizes.selectOption};
    `}

    ${theme.media.lg`
      font-size: ${theme.fontSizes.selectOption};
    `}
  `}
`;

// TODO: look at gracefully handling if one fails and one doesn't
const getState = (
  topGlobalCities: CitiesApiResponse,
  closestCities: ClosestCitiesApiResponse,
  cities: CitiesApiResponse,
  operatingModelCities: CitiesApiResponse
) => {
  if (
    topGlobalCities.loading ||
    closestCities.loading ||
    cities.loading ||
    operatingModelCities.loading
  ) {
    return SelectState.loading;
  } else if (
    topGlobalCities.error ||
    closestCities.error ||
    cities.error ||
    operatingModelCities.error
  ) {
    return SelectState.error;
  }

  return SelectState.ready;
};

const getData = (response: any, cityIds?: number[]) => {
  const cities =
    response && response.data
      ? response.data.cities?.cities || response.data.cities
      : [];
  if (cityIds && cityIds.length > 0) {
    return cities.filter((city: any) => cityIds.includes(city.id));
  }
  return cities;
};

function filterCitiesBy(city: City, query: string) {
  const q = query.trim().toLowerCase();
  const optTitle = city.title.toLowerCase();
  const aliases = (city.aliases || '').toLowerCase();
  return optTitle.indexOf(q) > -1 || aliases.indexOf(q) > -1;
}

const CitySelector: React.FC<Props> = ({
  onChange,
  cityOperatingModels,
  disableTopCities,
  disableClosestCities,
  showInactiveCities,
  initialValue,
  value,
  cityIds,
  placeholder,
  parentContainer,
  initialWidth,
  recommendedOptionsTitle,
  recommendedOptionsTitleSingular,
  mostVisitedOptionsTitle,
  disabled = false,
  showCountry = true,
  showCityOperatingModel = false,
  orderBy = 'title',
  orderDirection = 'asc',
  name = 'city',
  ...rest
}) => {
  const [loadTopCities, topCitiesResponse] = GetCitiesForCitySelectorLazy({});

  const [
    loadOperatingModelCities,
    operatingModelCitiesResponse,
  ] = GetCitiesForCitySelectorLazy({});

  const [loadClosestCities, closestCitiesResponse] = GetClosestCitiesLazy({});
  const theme = useCurrentTheme();
  const [citySearchInput, setCitySearchInput] = useState('');
  const { formatMessage } = useIntl();
  const { visa } = useContext(AuthContext);
  const cityContextValue = useCityContext();
  const { trackAnalyticsEvent } = useAnalyticsContext();
  const [citySearchSessionID, setCitySearchSessionID] = useState<string>(); // string to uniquely id a single flow of searching for a city for tracking
  const [
    analyticsBringToSofarShownTracked,
    setAnalyticsBringToSofarShownTracked,
  ] = useState(false);

  useEffect(() => {
    if (!disableTopCities) {
      loadTopCities({ variables: { topGlobalCity: true } });
    }
  }, [disableTopCities, loadTopCities]);

  useEffect(() => {
    if (cityOperatingModels) {
      loadOperatingModelCities({
        variables: {
          cityOperatingModel: cityOperatingModels.join(','),
          orderBy,
          orderDirection,
        },
      });
    }
  }, [cityOperatingModels, loadOperatingModelCities, orderBy, orderDirection]);

  useEffect(() => {
    if (
      !disableClosestCities &&
      cityContextValue &&
      !cityContextValue.closestCities
    ) {
      loadClosestCities();
    }
  }, [disableClosestCities, cityContextValue, loadClosestCities]);

  useEffect(() => {
    if (
      cityContextValue &&
      closestCitiesResponse &&
      !cityContextValue.closestCities
    ) {
      cityContextValue.setClosestCities(
        get(closestCitiesResponse, 'data.closestCities.cities', undefined)
      );
    }
  }, [cityContextValue, closestCitiesResponse]);

  useEffect(() => {
    if (value && citySearchInput.length === 0) {
      setCitySearchInput(value.title);
    }
  }, [value, citySearchInput]);

  const citiesResponse = useCitiesSearchOnInput({
    citySearchSessionID,
    citySearchInput,
    showAllCitiesOnMount: disableTopCities && disableClosestCities,
    showInactiveCities,
    filterBy: filterCitiesBy,
    orderBy,
    orderDirection,
  });

  const state = getState(
    topCitiesResponse,
    closestCitiesResponse,
    citiesResponse,
    operatingModelCitiesResponse
  );

  const options = cityOperatingModels
    ? getData(operatingModelCitiesResponse, cityIds)
    : getData(citiesResponse, cityIds);

  const isVisaValidated = !!visa?.eligible;

  useEffect(() => {
    if (
      !analyticsBringToSofarShownTracked &&
      state === SelectState.ready &&
      options.length === 0 &&
      !!citySearchInput
    ) {
      setAnalyticsBringToSofarShownTracked(true);
      trackAnalyticsEvent('City Dropdown Bring Sofar to City Shown', {
        city_search_session_id: citySearchSessionID,
        dropdown_context: parentContainer,
        city_search_input: citySearchInput,
        is_visa_validated: isVisaValidated,
      });
    }
  }, [
    state,
    options,
    citySearchInput,
    analyticsBringToSofarShownTracked,
    trackAnalyticsEvent,
    citySearchSessionID,
    parentContainer,
    isVisaValidated,
  ]);

  function handleOpen() {
    const newCitySearchSessionID = uuid(); //TODO: Update to crypto.randomUUID() once Safari supports it: https://developer.mozilla.org/en-US/docs/Web/API/Crypto/randomUUID
    trackAnalyticsEvent('City Selector Opened', {
      city_search_session_id: newCitySearchSessionID,
      dropdown_context: parentContainer,
      is_visa_validated: isVisaValidated,
    });
    setCitySearchSessionID(newCitySearchSessionID);
  }

  const onChangeHandler = (city: City) => {
    const handler = onChange || defaultOptionClick;

    if (city) {
      trackAnalyticsEvent('City Dropdown Option Selected', {
        city_search_session_id: citySearchSessionID,
        dropdown_context: parentContainer,
        selected_city_slug: city.cachedSlug,
        city_search_input: citySearchInput,
        is_visa_validated: isVisaValidated,
      });
    }

    handler(city);
  };

  function getRecommendedOptionsTitle(options: City[]) {
    if (options.length === 1) {
      return recommendedOptionsTitleSingular
        ? recommendedOptionsTitleSingular
        : formatMessage({ id: 'select.recommendedOptionsTitleSingular' });
    }
    return recommendedOptionsTitle
      ? recommendedOptionsTitle
      : formatMessage({ id: 'select.recommendedOptionsTitle' });
  }

  const noOptionsOption = {
    label: formatMessage(
      {
        id: 'select.noOptionsLabel',
      },
      {
        citySearchInput,
      }
    ),
    onClick: () => {
      trackAnalyticsEvent('City Dropdown Bring Sofar to City Selected', {
        city_search_session_id: citySearchSessionID,
        dropdown_context: parentContainer,
        city_search_input: citySearchInput,
        is_visa_validated: isVisaValidated,
      });
      window.location.replace('/about/curators');
    },
  };

  return (
    <Select<City>
      {...rest}
      state={state}
      searchable
      onChange={onChangeHandler}
      placeholder={placeholder || formatMessage({ id: 'shared.selectACity' })}
      name={name}
      options={disabled ? [] : options}
      getOptionLabel={o => o.title}
      recommendedOptions={
        cityContextValue && !disableClosestCities
          ? cityContextValue.closestCities
          : undefined
      }
      getRecommendedOptionsTitle={getRecommendedOptionsTitle}
      mostVisitedOptions={
        disableTopCities ? undefined : getData(topCitiesResponse)
      }
      getMostVisitedOptionsTitle={
        mostVisitedOptionsTitle
          ? () => mostVisitedOptionsTitle
          : () => formatMessage({ id: 'select.mostVisitedOptionsTitle' })
      }
      filterBy={filterCitiesBy}
      renderOption={(o, props) => (
        <MenuItem
          key={o.cachedSlug}
          {...props}
          data-qaid={`${rest['data-qaid']}-option`}
        >
          <SelectOptionText as="span">{o.title}</SelectOptionText>
          {showCountry && (
            <SelectOptionText as="span" color={theme.colors.blueSmoke}>
              , {o.country.title}
            </SelectOptionText>
          )}
          {showCityOperatingModel && (
            <SelectOptionText as="span" color={theme.colors.blueSmoke}>
              {` (${o.cityOperatingModelDescription})`}
            </SelectOptionText>
          )}
        </MenuItem>
      )}
      renderLeftIcon={<Icon name="search" />}
      defaultValue={initialValue}
      value={value}
      initialWidth={initialWidth}
      inputValue={citySearchInput}
      setInputValue={setCitySearchInput}
      onOpen={handleOpen}
      noOptionsOption={noOptionsOption}
    />
  );
};

const CitySelectorWithIntl: React.FC<Props> = props => {
  const {
    currentLanguage: { key: currentLanguageKey },
  } = useContext(IntlContext);

  return (
    <IntlProvider locale={currentLanguageKey} messages={fanLocale}>
      <CitySelector {...props} />
    </IntlProvider>
  );
};

export default CitySelectorWithIntl;
