import React from 'react';
import { useIntl } from 'react-intl';

import { get } from 'app/shared/utils/get';
import {
  getSocialUrlsFormInitialValues,
  getSocialUrlsSubmitVariable,
  venueSocialUrls,
} from 'app/shared/utils/socialLinks';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { CreateVenue } from 'app/admin/graphql/venues/mutationHooks';
import { DetailsFormMainContainer } from 'app/admin/components/atoms/DetailContent';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

import VenueBasicInfoForm from './VenueBasicInfoForm';
import VenueBasicInfoFormSchema from './VenueBasicInfoFormSchema';

interface Props {
  contentProps?: any;
  setFormSubmitAction?: (func: Function) => void;
  setDisplayConfirmation?: (set: boolean) => void;
  setIsSubmitting?: (set: boolean) => void;
  navigateTo: (routeData: object) => void;
}

const contactInfoInitialValues = (position: number) => ({
  id: undefined,
  firstName: undefined,
  lastName: undefined,
  email: undefined,
  contactRelationship: {
    name: undefined,
    id: undefined,
  },
  phoneNumber: undefined,
  position,
  isDayOfShowContact: false,
});

const VenueCreate: React.FC<Props> = ({
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  navigateTo,
}) => {
  const intl = useIntl();

  const formInitialValues = {
    isNewVenue: true,
    venueName: '',
    address: '',
    address2: '',
    latitude: '',
    longitude: '',
    city: undefined,
    neighborhood: undefined,
    closestStation: '',
    properties: [],
    capacity: '',
    numTicketsAvailableForSale: '',
    numGuestTickets: '0',
    contactInfos: {
      primaryContactInfo: contactInfoInitialValues(1),
      secondaryContactInfo: contactInfoInitialValues(2),
      tertiaryContactInfo: contactInfoInitialValues(3),
    },
    tags: [],
    venueCategories: [],
    socialUrls: getSocialUrlsFormInitialValues(venueSocialUrls),
    mapUrl: '',
    streetViewUrl: '',
    venueFeePresent: false,
    venueFeeDescription: '',
    maxPaInputs: '',
  };

  const createVenueAction = CreateVenue();

  const handleCreateVenue = useSubmitAction({
    submitAction: createVenueAction,
    submitVariables: (values: any) => ({
      address: values.address,
      address2: values.address2,
      venueName: values.venueName,
      cityId: get(values, 'city.id', undefined),
      contactInfos: Object.values(get(values, 'contactInfos', {})),
      closestStation: values.closestStation,
      neighborhoodId: get(values, 'neighborhood.id', undefined),
      processProperties: true,
      properties: values.properties,
      capacity: get(values, 'capacity', '').toString(),
      numTicketsAvailableForSale: get(
        values,
        'numTicketsAvailableForSale',
        ''
      ).toString(),
      numGuestTickets: values.numGuestTickets.toString(),
      tags: values.tags,
      processTags: true,
      venueCategories: values.venueCategories,
      socialUrls: getSocialUrlsSubmitVariable(values),
      mapUrl: values.mapUrl,
      streetViewUrl: values.streetViewUrl,
      venueFeePresent: values.venueFeePresent,
      venueFeeDescription: values.venueFeeDescription,
      maxPaInputs: values.maxPaInputs,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.venueCreate.successMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.venueCreate.failureMessage',
    }),
    onSuccess: (response: any) => {
      navigateTo({
        routeName: 'venue-details',
        routeProps: {
          id: get(response.data, 'createVenue.venue.id', undefined),
        },
      });
    },
  });

  return (
    <ModalContentContainer>
      <DetailsFormMainContainer>
        <DetailsHeader
          title={intl.formatMessage({
            id: 'admin.venueCreate.title',
          })}
          imageUrl=""
          imageStyle="landscape"
          isNewForm={true}
        />
        <GenericForm
          formInitialValues={formInitialValues}
          renderFormComponent={(renderProps: any) => (
            <VenueBasicInfoForm
              formikProps={renderProps.formikProps}
              setFormSubmitAction={setFormSubmitAction}
              setDisplayConfirmation={setDisplayConfirmation}
              setIsSubmitting={setIsSubmitting}
              isNewVenue={true}
            />
          )}
          onSubmit={handleCreateVenue}
          formSchema={VenueBasicInfoFormSchema(intl)}
          dataQaId="venue-create-form"
        />
      </DetailsFormMainContainer>
    </ModalContentContainer>
  );
};

export default VenueCreate;
