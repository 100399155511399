import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { ManualCSSColors } from 'app/shared/theme';
import {
  currencyFormatter,
  currencyRoundedMainUnitsFormatter,
} from 'app/shared/utils/currencyFormatter';
import { COMMERCIAL_PARTNERSHIP_EVENT } from 'app/shared/utils/events';
import usePermission from 'app/shared/utils/usePermission';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import { Overline } from 'app/shared/components/atoms/TypographyManualCSS';
import {
  eventOrganizedAsLabels,
  eventTicketPriceForDisplay,
} from 'app/admin/utils/events';
import { eventTypeDisplayConfig } from 'app/admin/utils/eventTypeDisplayConfig';
import {
  DetailHeading as Heading,
  DetailValueContainer as ValueContainer,
} from 'app/admin/components/atoms/DetailContent';
import { None } from 'app/admin/components/atoms/None';
import ProgressBar from 'app/admin/components/atoms/ProgressBar';

interface Props {
  sectionData: any;
  callbacks: { [name: string]: Function };
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const StyledOverline = styled(Overline)`
  margin-bottom: 24px;
`;

const ProgressBarContainer = styled.div<any>`
  width: 165px;
  margin-bottom: 8px;
  margin-top: 8px;
`;

const GuestlistLink = styled.div`
  ${({ theme }) => css`
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 20px;
    color: ${theme.colors.green600};
    cursor: pointer;
  `}
`;

const HelperText = styled.div`
  font-size: 10px;
  font-weight: 500;
  margin-top: 2px;
`;

const eventShadedColors = {
  guestsProgressBarFilled: 'darkSmoke',
  guestsProgressBarUnfilled: 'macyGrey',
};

const SectionTicketingInfo: React.FC<Props> = ({ sectionData, callbacks }) => {
  const intl = useIntl();

  const { event } = sectionData;
  const eventType = event.type;
  const attendeeTicketingFlow = event.attendeeFlow;
  const displayConfig = eventTypeDisplayConfig[eventType];
  const hasGuestlistAccessPermission = usePermission('event.guestlist.access');

  const promoCodeNoValueText = (fieldName: string) => (
    <>
      <None />
      <HelperText>
        {intl.formatMessage(
          {
            id: 'admin.eventPlanner.ticketing.promoCodeFieldOverrideHelperText',
          },
          { cityValue: event.city && event.city[fieldName] }
        )}
      </HelperText>
    </>
  );

  const notApplicableText = intl.formatMessage({ id: 'admin.notApplicable' });

  const currencySymbol =
    eventType == COMMERCIAL_PARTNERSHIP_EVENT
      ? event.city.currencySymbol
      : event.currencySymbol;

  const displayValues = {
    organizedAs: event.eventOrganizedAs
      ? eventOrganizedAsLabels(intl)[event.eventOrganizedAs]
      : notApplicableText,
    numTicketsAvailableForSale: displayConfig.displayTicketsAvailable
      ? event.numTicketsAvailableForSale
      : notApplicableText,
    maximumPromoCodeUses: displayConfig.displayPromoCodeDetails
      ? event.maximumPromoCodeUses || event.maximumPromoCodeUses == 0
        ? event.maximumPromoCodeUses
        : promoCodeNoValueText('maximumPromoCodeUses')
      : notApplicableText,
    maximumPercentagePromoCodeUses: displayConfig.displayPromoCodeDetails
      ? event.maximumPercentagePromoCodeUses ||
        event.maximumPercentagePromoCodeUses == 0
        ? event.maximumPercentagePromoCodeUses
        : promoCodeNoValueText('maximumPercentagePromoCodeUses')
      : notApplicableText,
    maximumGuaranteedPromoCodeUses: displayConfig.displayPromoCodeDetails
      ? event.maximumGuaranteedPromoCodeUses ||
        event.maximumGuaranteedPromoCodeUses == 0
        ? event.maximumGuaranteedPromoCodeUses
        : promoCodeNoValueText('maximumGuaranteedPromoCodeUses')
      : notApplicableText,
    maximumCustomPromoCodeUses: displayConfig.displayPromoCodeDetails
      ? event.maximumCustomPromoCodeUses ||
        event.maximumCustomPromoCodeUses == 0
        ? event.maximumCustomPromoCodeUses
        : promoCodeNoValueText('maximumCustomPromoCodeUses')
      : notApplicableText,
    maxTicketsPerAttendee: displayConfig.displayMaxTicketsPerAttendee
      ? event.maxTicketsPerAttendee
      : notApplicableText,
    revenue:
      attendeeTicketingFlow == 'buy' && displayConfig.displayRevenue
        ? currencyRoundedMainUnitsFormatter(
            event.revenue || 0,
            currencySymbol || '$'
          )
        : notApplicableText,
    ticketRevenue:
      attendeeTicketingFlow == 'buy' && displayConfig.displayRevenue
        ? currencyRoundedMainUnitsFormatter(
            event.ticketRevenue || 0,
            currencySymbol || '$'
          )
        : notApplicableText,
    totalBookingFee:
      attendeeTicketingFlow == 'buy' && displayConfig.displayRevenue
        ? currencyRoundedMainUnitsFormatter(
            event.totalBookingFee || 0,
            currencySymbol || '$'
          )
        : notApplicableText,
    dealAmount: displayConfig.displayDealAmount
      ? currencyFormatter(event.dealAmount || 0, currencySymbol || '$')
      : notApplicableText,
  };

  const guestsProgressPercentage =
    event.eventAttendeeGuestsConfirmedCount && event.numTicketsAvailableForSale
      ? (event.eventAttendeeGuestsConfirmedCount /
          event.numTicketsAvailableForSale) *
        100
      : 0;

  const ticketPriceForDisplay = eventTicketPriceForDisplay(event);

  return (
    <MainContainer data-qaid="section-event-ticketing">
      <Grid cols={4}>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={1}>
          <StyledOverline>
            {intl.formatMessage({
              id: 'admin.eventPlanner.ticketing.ticketInfo',
            })}
          </StyledOverline>
          <Heading>
            {intl.formatMessage({
              id: 'admin.shared.organizedAs',
            })}
          </Heading>
          <ValueContainer data-qaid="event-organized-as">
            {event.eventOrganizedAs &&
              eventOrganizedAsLabels(intl)[event.eventOrganizedAs]}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.eventPlanner.ticketing.ticketSales',
            })}
          </Heading>
          <ValueContainer data-qaid="event-ticket-sales">
            <ProgressBarContainer>
              {displayConfig.displayGuestsProgressBar ? (
                <ProgressBar
                  percentage={guestsProgressPercentage}
                  width="100%"
                  color={
                    eventShadedColors.guestsProgressBarFilled as ManualCSSColors
                  }
                  unfilledColor={
                    eventShadedColors.guestsProgressBarUnfilled as ManualCSSColors
                  }
                />
              ) : (
                notApplicableText
              )}
            </ProgressBarContainer>
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'shared.capacity',
            })}
          </Heading>
          <ValueContainer data-qaid="event-capacity">
            {displayConfig.displayCapacity &&
            event.isVenueConfirmed &&
            event.venue
              ? intl.formatMessage(
                  {
                    id: 'admin.eventPlanner.ticketing.capacity',
                  },
                  {
                    numGuests: event.venue?.capacity,
                  }
                )
              : notApplicableText}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.shared.ticketsAvailable',
            })}
          </Heading>
          <ValueContainer data-qaid="event-tickets-available">
            {displayValues.numTicketsAvailableForSale}
          </ValueContainer>
          {attendeeTicketingFlow === 'buy' && displayConfig.displayTicketPrice && (
            <>
              <Heading>
                {intl.formatMessage({
                  id: 'admin.shared.ticketPrice',
                })}
              </Heading>
              <ValueContainer data-qaid="ticket-price-view">
                {ticketPriceForDisplay}
              </ValueContainer>
            </>
          )}
          <Heading>
            {intl.formatMessage({
              id: 'admin.eventPlanner.ticketing.ticketsSold',
            })}
          </Heading>
          <ValueContainer data-qaid="event-tickets-sold">
            {attendeeTicketingFlow == 'buy' ? (
              <>
                <div>
                  {intl.formatMessage(
                    {
                      id: 'admin.eventPlanner.ticketing.ticketsSoldGuests',
                    },
                    {
                      numGuests: event.eventAttendeeGuestsConfirmedCount,
                    }
                  )}
                </div>
                {intl.formatMessage(
                  {
                    id:
                      'admin.eventPlanner.ticketing.ticketsSoldEventAttendees',
                  },
                  {
                    numEventAttendees: event.eventAttendeesConfirmedCount,
                  }
                )}
              </>
            ) : (
              notApplicableText
            )}
          </ValueContainer>
          {displayConfig.displayBookingFee &&
            (event.eventOrganizedAs == 'curator_owner_commercial_event' ||
              event.eventOrganizedAs == 'o_and_o') && (
              <>
                <Heading>
                  {intl.formatMessage({
                    id: 'admin.eventPlanner.ticketing.bookingFee',
                  })}
                </Heading>
                <ValueContainer>
                  {event.eventBookingFee || event.eventBookingFee == 0 ? (
                    currencyFormatter(
                      event.eventBookingFee,
                      currencySymbol || '$'
                    )
                  ) : (
                    <>
                      <None />
                      {event.city?.bookingFee || event.city?.bookingFee == 0 ? (
                        <HelperText>
                          {intl.formatMessage(
                            {
                              id:
                                'admin.eventPlanner.ticketing.bookingFeeOverrideCityHelperText',
                            },
                            {
                              cityValue: currencyFormatter(
                                event.city?.bookingFee,
                                currencySymbol || '$'
                              ),
                            }
                          )}
                        </HelperText>
                      ) : (
                        (event.city?.country?.bookingFee ||
                          event.city?.country?.bookingFee == 0) && (
                          <HelperText>
                            {intl.formatMessage(
                              {
                                id:
                                  'admin.eventPlanner.ticketing.bookingFeeOverrideCountryHelperText',
                              },
                              {
                                countryValue: currencyFormatter(
                                  event.city?.country?.bookingFee,
                                  currencySymbol || '$'
                                ),
                              }
                            )}
                          </HelperText>
                        )
                      )}
                    </>
                  )}
                </ValueContainer>
              </>
            )}
        </Col>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={2}>
          <StyledOverline>
            {intl.formatMessage({
              id: 'admin.eventPlanner.ticketing.attendance',
            })}
          </StyledOverline>
          {hasGuestlistAccessPermission && (
            <GuestlistLink
              onClick={() => callbacks.toggleGuestlistModal()}
              data-qaid="guestlist-link"
            >
              {intl.formatMessage({
                id: 'admin.eventPlanner.ticketing.guestlist',
              })}
            </GuestlistLink>
          )}
          <Heading>
            {intl.formatMessage({
              id: 'admin.eventPlanner.ticketing.applied',
            })}
          </Heading>
          <ValueContainer data-qaid="event-applied">
            {attendeeTicketingFlow == 'apply' ? (
              <>
                <div>
                  {intl.formatMessage(
                    {
                      id: 'admin.eventPlanner.ticketing.appliedGuests',
                    },
                    {
                      numGuests: event.eventAttendeeGuestsAppliedCount,
                    }
                  )}
                </div>
                {intl.formatMessage(
                  {
                    id: 'admin.eventPlanner.ticketing.appliedEventAttendees',
                  },
                  {
                    numEventAttendees: event.eventAttendeesAppliedCount,
                  }
                )}
              </>
            ) : (
              notApplicableText
            )}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.eventPlanner.ticketing.invited',
            })}
          </Heading>
          <ValueContainer data-qaid="event-invited">
            {attendeeTicketingFlow == 'apply' ? (
              <>
                <div>
                  {intl.formatMessage(
                    {
                      id: 'admin.eventPlanner.ticketing.invitedGuests',
                    },
                    {
                      numGuests: event.eventAttendeeGuestsGuestlistedCount,
                    }
                  )}
                </div>
                {intl.formatMessage(
                  {
                    id: 'admin.eventPlanner.ticketing.invitedEventAttendees',
                  },
                  {
                    numEventAttendees: event.eventAttendeesGuestlistedCount,
                  }
                )}
              </>
            ) : (
              notApplicableText
            )}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.eventPlanner.ticketing.cantGo',
            })}
          </Heading>
          <ValueContainer data-qaid="event-cant-go">
            <div>
              {intl.formatMessage(
                {
                  id: 'admin.eventPlanner.ticketing.cantGoGuests',
                },
                {
                  numGuests: event.eventAttendeeGuestsCantGoCount,
                }
              )}
            </div>
            {intl.formatMessage(
              {
                id:
                  attendeeTicketingFlow == 'apply'
                    ? 'admin.eventPlanner.ticketing.cantGoEventAttendeesApply'
                    : 'admin.eventPlanner.ticketing.cantGoEventAttendeesBuy',
              },
              {
                numEventAttendees: event.eventAttendeesCantGoCount,
              }
            )}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.eventPlanner.ticketing.confirmedYes',
            })}
          </Heading>
          <ValueContainer data-qaid="event-confirmed">
            <div>
              {intl.formatMessage(
                {
                  id: 'admin.eventPlanner.ticketing.confirmedGuests',
                },
                {
                  numGuests: event.eventAttendeeGuestsConfirmedCount,
                }
              )}
            </div>
            <div>
              {intl.formatMessage(
                {
                  id: 'admin.eventPlanner.ticketing.confirmedVips',
                },
                {
                  numGuests: event.vipAttendeeGuestsCount,
                }
              )}
            </div>
            {intl.formatMessage(
              {
                id: 'admin.eventPlanner.ticketing.confirmedDayOfShowStaff',
              },
              {
                numStaff: event.staffDayOfShowCount,
              }
            )}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.eventPlanner.ticketing.totalHeadcount',
            })}
          </Heading>
          <ValueContainer data-qaid="event-expected-headcount">
            <div>
              {intl.formatMessage(
                {
                  id: 'admin.eventPlanner.ticketing.totalHeadcountPeople',
                },
                {
                  numPeople: event.totalHeadcount,
                }
              )}
            </div>
            <HelperText>
              {intl.formatMessage({
                id: 'admin.eventPlanner.ticketing.totalHeadcountNote',
              })}
            </HelperText>
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.citySettings.heading.maxTicketsPerAttendee',
            })}
          </Heading>
          <ValueContainer data-qaid="event-max-tickets-per-attendee">
            {displayValues.maxTicketsPerAttendee}
          </ValueContainer>
        </Col>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={3}>
          <StyledOverline>
            {intl.formatMessage({
              id: 'admin.eventPlanner.ticketing.promoCodes',
            })}
          </StyledOverline>
          <Heading>
            {intl.formatMessage({
              id: 'admin.citySettings.heading.maximumPromoCodeUses',
            })}
          </Heading>
          <ValueContainer data-qaid="event-max-promo-codes">
            {displayValues.maximumPromoCodeUses}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.citySettings.heading.maximumPercentagePromoCodeUses',
            })}
          </Heading>
          <ValueContainer data-qaid="event-max-percentage-promo-codes">
            {displayValues.maximumPercentagePromoCodeUses}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.citySettings.heading.maximumGuaranteedPromoCodeUses',
            })}
          </Heading>
          <ValueContainer data-qaid="event-max-guaranteed-promo-codes">
            {displayValues.maximumGuaranteedPromoCodeUses}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.citySettings.heading.maximumCustomPromoCodeUses',
            })}
          </Heading>
          <ValueContainer data-qaid="event-max-custom-promo-codes">
            {displayValues.maximumCustomPromoCodeUses}
          </ValueContainer>
        </Col>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={4}>
          <StyledOverline>
            {intl.formatMessage({
              id: 'admin.eventPlanner.ticketing.revenue',
            })}
          </StyledOverline>
          <Heading>
            {intl.formatMessage({
              id: 'admin.eventPlanner.ticketing.totalRevenue',
            })}
          </Heading>
          <ValueContainer data-qaid="event-revenue">
            <div>
              {displayValues.revenue}{' '}
              {displayConfig.displayRevenue &&
                intl
                  .formatMessage({
                    id: 'admin.eventPlanner.guests.totalRevenue',
                  })
                  .toLowerCase()}
            </div>
            {displayConfig.displayRevenue && (
              <div>
                {displayValues.ticketRevenue}{' '}
                {intl
                  .formatMessage({
                    id: 'admin.eventPlanner.guests.ticketRevenue',
                  })
                  .toLowerCase()}
              </div>
            )}
            {displayConfig.displayRevenue && (
              <div>
                {displayValues.totalBookingFee}{' '}
                {intl
                  .formatMessage({
                    id: 'admin.eventPlanner.guests.bookingFees',
                  })
                  .toLowerCase()}
              </div>
            )}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.eventPlanner.ticketing.dealAmount',
            })}
          </Heading>
          <ValueContainer data-qaid="event-deal-amount">
            {displayValues.dealAmount}
          </ValueContainer>
        </Col>
      </Grid>
    </MainContainer>
  );
};

export default SectionTicketingInfo;
