import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { dateFormatter } from 'app/shared/utils/datetime';
import { dataGetter } from 'app/shared/utils/queries';
import { buildFullName } from 'app/shared/utils/string';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { GetUserBasicInfo } from 'app/admin/graphql/users/queryHooks';
import { DetailsMainContainer } from 'app/admin/components/atoms/DetailContent';
import AccordionSections from 'app/admin/components/organisms/AccordionSections';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';
import { ReactComponent as InformationCircle } from 'icons/streamline-regular/interface-essential/alerts/information-circle.svg';
import { ReactComponent as SocialVideoYoutubeClip } from 'icons/streamline-regular/logos/videos/social-video-youtube-clip.svg';
import { ReactComponent as MusicGenreTeam } from 'icons/streamline-regular/music-audio/music-genres/music-genre-team.svg';

import SectionBasicInfo from './SectionBasicInfo';
import SectionCompetitions from './SectionCompetitions';
import SectionEvents from './SectionEvents';

interface Props {
  navigateTo: (routeData: any) => void;
  contentProps: any;
  hide: VoidFunction;
}

const UserDetails: React.FC<Props> = ({ contentProps, hide, navigateTo }) => {
  const {
    id,
    firstName,
    lastName,
    currentSignInAt,
    createdAt,
    signInCount,
  } = contentProps;

  const intl = useIntl();

  const [headerData, setHeaderData] = useState({
    firstName,
    lastName,
    currentSignInAt,
    createdAt,
    signInCount,
    isLoaded: !!createdAt,
  });

  const {
    loading: loadingBasicInfo,
    error: errorBasicInfo,
    data: dataBasicInfo,
    refetch: refetchUser,
  } = GetUserBasicInfo({
    id,
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (contentProps.shouldRefetchUser) {
      refetchUser();
    }
  }, [contentProps.shouldRefetchUser, refetchUser]);

  const sectionsConfig = {
    basicInfo: {
      title: intl.formatMessage({
        id: 'admin.users.details.sectionTitle.basicInfo',
      }),
      icon: InformationCircle,
      iconColor: 'blueChristmas',
      sectionComponent: SectionBasicInfo,
      dataKey: 'user',
      displayEditIcon: true,
      loading: loadingBasicInfo,
      data: dataBasicInfo ? dataBasicInfo.user : undefined,
      callbacks: {
        toggleSectionEditModal: () =>
          navigateTo({
            routeName: 'user-basic-info-edit',
            routeProps: dataBasicInfo ? dataBasicInfo.user : undefined,
          }),
        toggleAddRoleModal: () =>
          navigateTo({
            routeName: 'user-role-add',
            routeProps: {
              user: {
                id,
                firstName: headerData.firstName,
                lastName: headerData.lastName,
              },
              action: 'addInitialRoleToUser',
            },
          }),
      },
    },
    events: {
      title: intl.formatMessage({
        id: 'shared.concerts',
      }),
      icon: MusicGenreTeam,
      iconColor: 'orangeCrush',
      iconCircle: true,
      sectionComponent: SectionEvents,
      dataKey: 'user',
      displayEditIcon: false,
      loading: loadingBasicInfo,
      data: dataBasicInfo ? dataBasicInfo.user : undefined,
      callbacks: {},
    },
    competitions: {
      title: intl.formatMessage({
        id: 'admin.users.details.sectionTitle.competitions',
      }),
      icon: SocialVideoYoutubeClip,
      iconColor: 'greenOnions',
      iconCircle: true,
      sectionComponent: SectionCompetitions,
      dataKey: 'user',
      displayEditIcon: false,
      loading: loadingBasicInfo,
      data: dataBasicInfo ? dataBasicInfo.user : undefined,
      callbacks: {},
    },
  };

  useEffect(() => {
    const getDataBasicInfo = dataGetter(dataBasicInfo, 'user');
    if (dataBasicInfo) {
      setHeaderData({
        firstName: getDataBasicInfo('firstName'),
        lastName: getDataBasicInfo('lastName'),
        currentSignInAt: getDataBasicInfo('currentSignInAt'),
        createdAt: getDataBasicInfo('createdAt'),
        signInCount: getDataBasicInfo('signInCount'),
        isLoaded: true,
      });
    }
  }, [dataBasicInfo, loadingBasicInfo]);

  if ((!loadingBasicInfo && !dataBasicInfo) || errorBasicInfo) {
    hide();
    return null;
  }

  const detailsHeaderSummaryInfoList = (
    currentSignInAt: string | undefined,
    createdAt: string | undefined,
    signInCount: number | undefined
  ) => {
    const signInCountDescription = intl.formatMessage({
      id: 'admin.users.details.logins',
    });

    const currentSignInAtMonth = currentSignInAt
      ? dateFormatter(currentSignInAt, 'shortMonth').toUpperCase()
      : 'N/A';

    const currentSignInAtYear = currentSignInAt
      ? dateFormatter(currentSignInAt, 'longYear')
      : null;

    const currentSignInAtDescription = currentSignInAt
      ? `${currentSignInAtYear} \n ${intl.formatMessage({
          id: 'admin.users.details.lastLogin',
        })}`
      : intl.formatMessage({
          id: 'admin.users.details.lastLogin',
        });

    const createdAtMonth = createdAt
      ? dateFormatter(createdAt, 'shortMonth').toUpperCase()
      : 'N/A';

    const createdAtYear = createdAt
      ? dateFormatter(createdAt, 'longYear')
      : null;

    const createdAtDescription = createdAt
      ? `${createdAtYear} \n ${intl.formatMessage({
          id: 'admin.users.details.signup',
        })}`
      : intl.formatMessage({
          id: 'admin.users.details.signup',
        });

    return [
      {
        backgroundColor: '#ebedef',
        mainInfo:
          signInCount || signInCount == 0 ? signInCount.toString() : 'N/A',
        description: signInCountDescription,
      },
      {
        backgroundColor: '#ebf5fb',
        mainInfo: currentSignInAtMonth,
        description: currentSignInAtDescription,
      },
      {
        backgroundColor: '#f3f9ed',
        mainInfo: createdAtMonth,
        description: createdAtDescription,
      },
    ];
  };

  const summaryInfoList = detailsHeaderSummaryInfoList(
    headerData.currentSignInAt,
    headerData.createdAt,
    headerData.signInCount
  );

  const userName = buildFullName(headerData.firstName, headerData.lastName);

  return (
    <ModalContentContainer data-qaid="user-details-main-modal">
      <DetailsMainContainer>
        <DetailsHeader
          title={
            userName ||
            intl.formatMessage({
              id: 'admin.users.details.noName',
            })
          }
          summaryInfoList={summaryInfoList}
          loading={!headerData.isLoaded}
        />
        <AccordionSections
          sectionsConfig={sectionsConfig}
          defaultOpenSection={contentProps.defaultOpenSection}
        />
      </DetailsMainContainer>
    </ModalContentContainer>
  );
};

export default UserDetails;
