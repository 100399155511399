import React from 'react';
import styled from 'styled-components';

import { Spinner } from 'app/shared/components/atoms/SpinnerManualCSS';

const Container = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
  height: 90%;
`;

export const ModalLoading: React.FC = () => {
  return (
    <Container>
      <Spinner />
    </Container>
  );
};
