export const buildEventCollectionUrl = ({
  citySlug,
  collectionSlug,
}: {
  citySlug?: string;
  collectionSlug: string;
}) => {
  return citySlug
    ? `/cities/${citySlug}/lists/${collectionSlug}`
    : `/lists/${collectionSlug}`;
};
