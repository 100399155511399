import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { Artist } from 'app/typings/artists';
import { User } from 'app/typings/users';
import { dateFormatter } from 'app/shared/utils/datetime';
import { errorMsgForField } from 'app/shared/utils/form';
import { get } from 'app/shared/utils/get';
import { artistSocialUrls } from 'app/shared/utils/socialLinks';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import { FormGroupContainer } from 'app/shared/components/atoms/FormContent';
import { Image } from 'app/shared/components/atoms/ImageManualCSS';
import { LoadingBlocks } from 'app/shared/components/atoms/LoadingBlocks';
import { LoadingError } from 'app/shared/components/atoms/LoadingError';
import { Radio } from 'app/shared/components/atoms/Radio';
import StyledTextarea from 'app/shared/components/atoms/SmallTextarea';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Textfield } from 'app/shared/components/atoms/Textfield';
import TruncatedByCharText from 'app/shared/components/atoms/TruncatedByCharText';
import { H3 } from 'app/shared/components/atoms/TypographyManualCSS';
import FormGroup from 'app/shared/components/molecules/FormGroup';
import { GetArtistMergeInfo } from 'app/admin/graphql/artistMerges/queryHooks';
import { UserLink } from 'app/admin/components/molecules/EntityLink';

interface Props {
  setFieldValue: any;
  touched: any;
  errors: any;
  values: any;
  validationErrors?: object;
}

const MainContainer = styled.div`
  padding: 20px 0px 10px 0px;
`;

const TopNote = styled.div``;

const TopNoteText = styled(H3)``;

const ArtistSummariesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  padding-bottom: 25px;
`;

const ArtistSummaryBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    background-color: ${theme.colors.silverSprings};
    padding: 20px 20px 10px 20px;
    margin-right: 30px;
  `}
`;

const SummaryItem = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
`;

const SummaryItemLabel = styled.div`
  font-weight: 600;
  width: 165px;
  white-space: nowrap;
`;

const SummaryItemValue = styled.div`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.4px;
  padding-left: 10px;
  width: 325px;
  white-space: nowrap;
`;

const AssociatedUsersNote = styled.div`
  font-size: 12px;
  line-height: 18px;
  padding-bottom: 10px;
  white-space: normal;
`;

const VerifyNote = styled.div`
  font-size: 16px;
  line-height: 24px;
  padding-top: 15px;
  padding-bottom: 35px;
`;

const VerifyNoteWarning = styled.span`
  ${({ theme }) => css`
    font-weight: 600;
    color: ${theme.colors.redRedWine};
    padding-left: 10px;
  `}
`;

const ChooseInfoNote = styled.div``;

const ChooseInfoNoteText = styled(H3)``;

const TitleTextfield = styled(Textfield)`
  display: block;
  width: 300px;
`;

const UsersContainer = styled.div``;

const UserContainer = styled.div`
  margin-bottom: 4px;
`;

const MainImageOption = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 200px;
`;

const RadioContainer = styled.div`
  padding-right: 10px;
`;

const ImageContainer = styled.div``;

const CircleImageContainer = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: rgba(219, 219, 219, 0.5);
  overflow: hidden;
`;

const CircleImage = styled(Image)`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
`;

const ErrorContainer = styled.div`
  ${({ theme }) => css`
    font-size: 14px;
    font-weight: 600;
    color: ${theme.colors.redRedWine};
    padding-bottom: 25px;
  `}
`;

const FieldHelpText = styled.h6`
  font-size: 10px;
  font-weight: 500;
  margin: -2px 0px 11px 0px;
`;

interface AssociatedUsersProps {
  artist: Artist;
}

const AssociatedUsers: React.FC<AssociatedUsersProps> = ({ artist }) => {
  const intl = useIntl();

  if (artist.associatedUsers && artist.associatedUsers.length > 0) {
    return (
      <UsersContainer>
        {artist.associatedUsers.map((user: User, i: number) => (
          <UserContainer key={i}>
            <UserLink user={user} truncateLength={30} />
          </UserContainer>
        ))}
      </UsersContainer>
    );
  } else {
    return (
      <UsersContainer>
        {intl.formatMessage({
          id: 'none',
        })}
      </UsersContainer>
    );
  }
};

const ArtistMergeMergeArtists: React.FC<Props> = ({
  setFieldValue,
  touched,
  errors,
  values,
  validationErrors,
}) => {
  const intl = useIntl();

  const {
    loading: loadingArtists,
    error: errorArtists,
    data: dataArtists,
  } = GetArtistMergeInfo({
    artistId1: values.artist1.id,
    artistId2: values.artist2.id,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  if ((!loadingArtists && !dataArtists) || errorArtists) {
    return (
      <LoadingError
        whatsBeingLoaded={intl.formatMessage({
          id: 'admin.artistMerge.modal.theArtists',
        })}
      />
    );
  }

  const artist1 = get(dataArtists, 'artistMergeInfo.artist1', undefined);
  const artist2 = get(dataArtists, 'artistMergeInfo.artist2', undefined);

  return (
    <>
      <DottedLine />
      <MainContainer>
        {// @ts-ignore
        validationErrors.general && (
          <ErrorContainer>
            {
              // @ts-ignore
              validationErrors.general
            }
          </ErrorContainer>
        )}
        <TopNote>
          <TopNoteText>
            {intl.formatMessage({
              id: 'admin.artistMerge.modal.youAreAboutToMerge',
            })}
          </TopNoteText>
        </TopNote>
        <ArtistSummariesContainer>
          {loadingArtists && (
            <LoadingBlocks.Rectangle width="100%" height="300px" />
          )}
          {artist1 &&
            artist2 &&
            [artist1, artist2].map((artist: Artist) => (
              <ArtistSummaryBox key={artist.id}>
                <SummaryItem>
                  <SummaryItemLabel>
                    {intl.formatMessage({
                      id: 'admin.artistMerge.modal.infoName',
                    })}
                  </SummaryItemLabel>
                  <SummaryItemValue>
                    <TruncatedByCharText
                      text={artist.title || null}
                      truncateLength={38}
                    />
                  </SummaryItemValue>
                </SummaryItem>
                <SummaryItem>
                  <SummaryItemLabel>
                    {intl.formatMessage({
                      id: 'admin.artistMerge.modal.infoId',
                    })}
                  </SummaryItemLabel>
                  <SummaryItemValue>{artist.id}</SummaryItemValue>
                </SummaryItem>
                <SummaryItem>
                  <SummaryItemLabel>
                    {intl.formatMessage({
                      id: 'admin.artistMerge.modal.infoEmail',
                    })}
                  </SummaryItemLabel>
                  <SummaryItemValue>
                    <TruncatedByCharText
                      text={
                        artist.email ||
                        intl.formatMessage({
                          id: 'none',
                        })
                      }
                      truncateLength={38}
                    />
                  </SummaryItemValue>
                </SummaryItem>
                <SummaryItem>
                  <SummaryItemLabel>
                    {intl.formatMessage({
                      id: 'admin.artistMerge.modal.infoHomeCity',
                    })}
                  </SummaryItemLabel>
                  <SummaryItemValue>
                    <TruncatedByCharText
                      text={
                        artist.homeCityDescription ||
                        intl.formatMessage({
                          id: 'none',
                        })
                      }
                      truncateLength={35}
                    />
                  </SummaryItemValue>
                </SummaryItem>
                <SummaryItem>
                  <SummaryItemLabel>
                    {intl.formatMessage({
                      id: 'admin.artistMerge.modal.infoAssociatedUsers',
                    })}
                    {artist.associatedUsers &&
                      artist.associatedUsers.length > 0 &&
                      '*'}
                  </SummaryItemLabel>
                  <SummaryItemValue>
                    <AssociatedUsers artist={artist} />
                  </SummaryItemValue>
                </SummaryItem>
                <SummaryItem>
                  <SummaryItemLabel>
                    {intl.formatMessage({
                      id: 'admin.shared.added',
                    })}
                  </SummaryItemLabel>
                  <SummaryItemValue>
                    {artist.reviewedAt &&
                      dateFormatter(artist.reviewedAt, 'shortMonthDateAndYear')}
                  </SummaryItemValue>
                </SummaryItem>
                <SummaryItem>
                  <SummaryItemLabel>
                    {intl.formatMessage({
                      id: 'admin.artistMerge.modal.infoUpcomingConcerts',
                    })}
                  </SummaryItemLabel>
                  <SummaryItemValue>
                    {artist.upcomingEventsCount}
                  </SummaryItemValue>
                </SummaryItem>
                <SummaryItem>
                  <SummaryItemLabel>
                    {intl.formatMessage({
                      id: 'admin.artistMerge.modal.infoPastConcerts',
                    })}
                  </SummaryItemLabel>
                  <SummaryItemValue>{artist.pastEventsCount}</SummaryItemValue>
                </SummaryItem>
                <SummaryItem>
                  <SummaryItemLabel>
                    {intl.formatMessage({
                      id: 'admin.artistMerge.modal.infoLastConcert',
                    })}
                  </SummaryItemLabel>
                  <SummaryItemValue>
                    {artist.lastEventAt
                      ? dateFormatter(
                          artist.lastEventAt,
                          'shortMonthDateAndYear'
                        )
                      : intl.formatMessage({
                          id: 'notApplicable',
                        })}
                  </SummaryItemValue>
                </SummaryItem>
              </ArtistSummaryBox>
            ))}
        </ArtistSummariesContainer>
        {((artist1 &&
          artist1.associatedUsers &&
          artist1.associatedUsers.length > 0) ||
          (artist2 &&
            artist2.associatedUsers &&
            artist2.associatedUsers.length > 0)) && (
          <AssociatedUsersNote>
            {intl.formatMessage({
              id: 'admin.artistMerge.modal.associatedUsersNote',
            })}
          </AssociatedUsersNote>
        )}
        <VerifyNote>
          {intl.formatMessage({
            id: 'admin.artistMerge.modal.pleaseVerify',
          })}
          <VerifyNoteWarning>
            {intl.formatMessage({
              id: 'admin.artistMerge.modal.anyInfoNotChosen',
            })}
          </VerifyNoteWarning>
        </VerifyNote>
        <ChooseInfoNote>
          <ChooseInfoNoteText>
            {intl.formatMessage({
              id: 'admin.artistMerge.modal.chooseWhichInfo',
            })}
          </ChooseInfoNoteText>
        </ChooseInfoNote>
        <FormGroupContainer>
          <FormGroup
            data-qaid="artist-merge-form-title-artist-formgroup"
            label={intl.formatMessage({
              id: 'admin.artistMerge.modal.formName',
            })}
            errorMsg={errorMsgForField('title', touched, errors)}
            required
          >
            <>
              <Spacer mt={4} />
              <Radio
                id="titleArtist1"
                value="artist1"
                name="titleArtist1"
                checked={values.titleArtistId == values.artist1.id}
                onChange={() => {
                  setFieldValue('titleArtistId', values.artist1.id);
                  setFieldValue('titleOther', '');
                  setFieldValue('title', artist1.title);
                }}
              >
                {artist1 && artist1.title}
              </Radio>
              <Spacer mt={4} />
              <Radio
                id="titleArtist2"
                value="artist2"
                name="titleArtist2"
                checked={values.titleArtistId == values.artist2.id}
                onChange={() => {
                  setFieldValue('titleArtistId', values.artist2.id);
                  setFieldValue('titleOther', '');
                  setFieldValue('title', artist2.title);
                }}
              >
                {artist2 && artist2.title}
              </Radio>
              <Spacer mt={4} />
              <TitleTextfield
                data-qaid="artist-merge-form-title-artist-field"
                id="titleOther"
                name="titleOther"
                value={values.titleOther}
                onChange={(e: any) => {
                  setFieldValue('titleOther', e.target.value);
                  setFieldValue('titleArtistId', undefined);
                  setFieldValue('title', e.target.value);
                }}
                maxLength={200}
              />
              <Spacer mt={2} />
            </>
          </FormGroup>
          <Spacer mt={6} />
          <FormGroup
            data-qaid="artist-merge-form-email-artist-formgroup"
            label={intl.formatMessage({
              id: 'admin.artistMerge.modal.formEmail',
            })}
            errorMsg={errorMsgForField('email', touched, errors)}
            required
          >
            <>
              <Spacer mt={4} />
              <Radio
                id="emailArtist1"
                value="artist1"
                name="emailArtist1"
                checked={values.emailArtistId == values.artist1.id}
                onChange={() => {
                  setFieldValue('emailArtistId', values.artist1.id);
                  setFieldValue('emailOther', '');
                  setFieldValue('email', artist1.email);
                }}
              >
                {artist1 &&
                  (artist1.email ||
                    intl.formatMessage({
                      id: 'none',
                    }))}
              </Radio>
              <Spacer mt={4} />
              <Radio
                id="emailArtist2"
                value="artist2"
                name="emailArtist2"
                checked={values.emailArtistId == values.artist2.id}
                onChange={() => {
                  setFieldValue('emailArtistId', values.artist2.id);
                  setFieldValue('emailOther', '');
                  setFieldValue('email', artist2.email);
                }}
              >
                {artist2 &&
                  (artist2.email ||
                    intl.formatMessage({
                      id: 'none',
                    }))}
              </Radio>
              <Spacer mt={4} />
              <Textfield
                data-qaid="artist-merge-form-email-artist-field"
                id="emailOther"
                name="emailOther"
                value={values.emailOther}
                onChange={(e: any) => {
                  setFieldValue('emailOther', e.target.value);
                  setFieldValue('emailArtistId', undefined);
                  setFieldValue('email', e.target.value);
                }}
                maxLength={100}
              />
              <Spacer mt={2} />
            </>
          </FormGroup>
          <Spacer mt={6} />
          <FormGroup
            data-qaid="artist-merge-form-cached-slug-artist-formgroup"
            label={intl.formatMessage({
              id: 'admin.artistMerge.modal.formUrlSlug',
            })}
            errorMsg={errorMsgForField('cachedSlug', touched, errors)}
            required
          >
            <>
              <FieldHelpText>
                {intl.formatMessage({
                  id: 'admin.artistMerge.modal.formUrlSlugHelpText',
                })}
              </FieldHelpText>
              <Spacer mt={4} />
              <Radio
                id="cachedSlugArtist1"
                value="artist1"
                name="cachedSlugArtist1"
                checked={values.cachedSlugArtistId == values.artist1.id}
                onChange={() => {
                  setFieldValue('cachedSlugArtistId', values.artist1.id);
                  setFieldValue('cachedSlug', artist1.cachedSlug);
                }}
              >
                {artist1 && artist1.cachedSlug}
              </Radio>
              <Spacer mt={4} />
              <Radio
                id="cachedSlugArtist2"
                value="artist2"
                name="cachedSlugArtist2"
                checked={values.cachedSlugArtistId == values.artist2.id}
                onChange={() => {
                  setFieldValue('cachedSlugArtistId', values.artist2.id);
                  setFieldValue('cachedSlug', artist2.cachedSlug);
                }}
              >
                {artist2 && artist2.cachedSlug}
              </Radio>
              <Spacer mt={2} />
            </>
          </FormGroup>
          <Spacer mt={6} />
          <FormGroup
            data-qaid="artist-merge-form-home-city-artist-formgroup"
            label={intl.formatMessage({
              id: 'admin.artistMerge.modal.formHomeCity',
            })}
            errorMsg={errorMsgForField(
              'homeCityDescriptionArtistId',
              touched,
              errors
            )}
          >
            <>
              <Spacer mt={4} />
              <Radio
                id="homeCityDescriptionArtist1"
                value="artist1"
                name="homeCityDescriptionArtist1"
                checked={
                  values.homeCityDescriptionArtistId == values.artist1.id
                }
                onChange={() => {
                  setFieldValue(
                    'homeCityDescriptionArtistId',
                    values.artist1.id
                  );
                }}
              >
                {artist1 &&
                  (artist1.homeCityDescription ||
                    intl.formatMessage({
                      id: 'none',
                    }))}
              </Radio>
              <Spacer mt={4} />
              <Radio
                id="homeCityDescriptionArtist2"
                value="artist2"
                name="homeCityDescriptionArtist2"
                checked={
                  values.homeCityDescriptionArtistId == values.artist2.id
                }
                onChange={() => {
                  setFieldValue(
                    'homeCityDescriptionArtistId',
                    values.artist2.id
                  );
                }}
              >
                {artist2 &&
                  (artist2.homeCityDescription ||
                    intl.formatMessage({
                      id: 'none',
                    }))}
              </Radio>
            </>
          </FormGroup>
          <Spacer mt={8} />
          <FormGroup
            data-qaid="artist-merge-form-main-image-artist-formgroup"
            label={intl.formatMessage({
              id: 'admin.artistMerge.modal.formProfileImage',
            })}
            errorMsg={errorMsgForField('mainImageArtistId', touched, errors)}
          >
            <>
              <Spacer mt={4} />
              <MainImageOption>
                <RadioContainer>
                  <Radio
                    id="mainImageArtist1"
                    value="artist1"
                    name="mainImageArtist1"
                    checked={values.mainImageArtistId == values.artist1.id}
                    onChange={() => {
                      setFieldValue('mainImageArtistId', values.artist1.id);
                    }}
                  ></Radio>
                </RadioContainer>
                <ImageContainer>
                  {artist1 && artist1.imageUrl && (
                    <CircleImageContainer>
                      <CircleImage src={artist1.imageUrl} />
                    </CircleImageContainer>
                  )}
                </ImageContainer>
              </MainImageOption>
              <Spacer mt={4} />
              <MainImageOption>
                <RadioContainer>
                  <Radio
                    id="mainImageArtist2"
                    value="artist2"
                    name="mainImageArtist2"
                    checked={values.mainImageArtistId == values.artist2.id}
                    onChange={() => {
                      setFieldValue('mainImageArtistId', values.artist2.id);
                    }}
                  ></Radio>
                </RadioContainer>
                <ImageContainer>
                  {artist2 && artist2.imageUrl && (
                    <CircleImageContainer>
                      <CircleImage src={artist2.imageUrl} />
                    </CircleImageContainer>
                  )}
                </ImageContainer>
              </MainImageOption>
            </>
          </FormGroup>
          <Spacer mt={8} />
          <FormGroup
            data-qaid="artist-merge-form-description-artist-formgroup"
            label={intl.formatMessage({
              id: 'admin.artistMerge.modal.formBiography',
            })}
            errorMsg={errorMsgForField('description', touched, errors)}
          >
            <>
              <Spacer mt={4} />
              <Radio
                id="descriptionArtist1"
                value="artist1"
                name="descriptionArtist1"
                checked={values.descriptionArtistId == values.artist1.id}
                onChange={() => {
                  setFieldValue('descriptionArtistId', values.artist1.id);
                  setFieldValue('descriptionOther', '');
                  setFieldValue('description', artist1.description);
                }}
              >
                {artist1 &&
                  (artist1.description ||
                    intl.formatMessage({
                      id: 'none',
                    }))}
              </Radio>
              <Spacer mt={4} />
              <Radio
                id="descriptionArtist2"
                value="artist2"
                name="descriptionArtist2"
                checked={values.descriptionArtistId == values.artist2.id}
                onChange={() => {
                  setFieldValue('descriptionArtistId', values.artist2.id);
                  setFieldValue('descriptionOther', '');
                  setFieldValue('description', artist2.description);
                }}
              >
                {artist2 &&
                  (artist2.description ||
                    intl.formatMessage({
                      id: 'none',
                    }))}
              </Radio>
              <Spacer mt={4} />
              <StyledTextarea
                data-qaid="artist-merge-form-description-artist-field"
                id="descriptionOther"
                name="descriptionOther"
                value={values.descriptionOther}
                onChange={(e: any) => {
                  setFieldValue('descriptionOther', e.target.value);
                  setFieldValue('descriptionArtistId', undefined);
                  setFieldValue('description', e.target.value);
                }}
                maxLength={2000}
              />
            </>
          </FormGroup>
          {artistSocialUrls.map((fieldName: string, i: number) => (
            <React.Fragment key={i}>
              <Spacer mt={8} />
              <FormGroup
                data-qaid={`artist-merge-form-${fieldName}-artist-formgroup`}
                label={intl.formatMessage({
                  id: `admin.artistMerge.modal.form${fieldName}`,
                })}
              >
                <>
                  <Spacer mt={4} />
                  <Radio
                    id={`${fieldName}Artist1`}
                    value="artist1"
                    name={`${fieldName}Artist1`}
                    checked={
                      values[`${fieldName}ArtistId`] == values.artist1.id
                    }
                    onChange={() => {
                      setFieldValue(`${fieldName}ArtistId`, values.artist1.id);
                      setFieldValue(`${fieldName}Other`, '');
                      setFieldValue(fieldName, artist1.socialUrls[fieldName]);
                    }}
                  >
                    {artist1 &&
                      (artist1.socialUrls[fieldName] ||
                        intl.formatMessage({
                          id: 'none',
                        }))}
                  </Radio>
                  <Spacer mt={4} />
                  <Radio
                    id={`${fieldName}Artist2`}
                    value="artist2"
                    name={`${fieldName}Artist2`}
                    checked={
                      values[`${fieldName}ArtistId`] == values.artist2.id
                    }
                    onChange={() => {
                      setFieldValue(`${fieldName}ArtistId`, values.artist2.id);
                      setFieldValue(`${fieldName}Other`, '');
                      setFieldValue(fieldName, artist2.socialUrls[fieldName]);
                    }}
                  >
                    {artist2 &&
                      (artist2.socialUrls[fieldName] ||
                        intl.formatMessage({
                          id: 'none',
                        }))}
                  </Radio>
                  <Spacer mt={4} />
                  <Textfield
                    data-qaid={`artist-merge-form-${fieldName}-artist`}
                    id={`${fieldName}Other`}
                    name={`${fieldName}Other`}
                    value={values[`${fieldName}Other`]}
                    onChange={(e: any) => {
                      setFieldValue(`${fieldName}Other`, e.target.value);
                      setFieldValue(`${fieldName}ArtistId`, undefined);
                      setFieldValue(fieldName, e.target.value);
                    }}
                    maxLength={100}
                  />
                </>
              </FormGroup>
            </React.Fragment>
          ))}
        </FormGroupContainer>
      </MainContainer>
    </>
  );
};

export default ArtistMergeMergeArtists;
