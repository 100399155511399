import React from 'react';
import styled, { css } from 'styled-components';

import { Ticket } from 'app/typings/tickets';
import { YesIcon } from 'app/shared/components/atoms/IconLibrary';

export const eventAttendeeTicketFullNameOrEmail = (ticket: Ticket) => {
  if (ticket.firstName && ticket.lastName) {
    return [ticket.firstName, ticket.lastName].join(' ');
  } else if (ticket.firstName) {
    return ticket.firstName;
  } else if (ticket.lastName) {
    return ticket.lastName;
  } else if (ticket.email) {
    return ticket.email;
  } else {
    return '';
  }
};

// - Make sure primary ticket appears FIRST in list even if firstName/lastName/email
//   wouldn't be first in list alphabetically, by forcing to start of list with 'AAAAAA'
// - Make sure tickets with no firstName/lastName/email at all appear LAST in list,
//   by forcing to end of list with 'ZZZZZZ[ticket-id]', that list of tickets with
//   no firstName/lastName/email at all will be sorted by TICKET ID
// In other words, sort tickets by the following, in order:
// - is primary
// - firstName/lastName/email
// - id
export const sortEventAttendeeTickets = (tickets?: Ticket[]) => {
  return tickets
    ? tickets.sort((a: Ticket, b: Ticket) =>
        (a.primary
          ? 'AAAAAA'
          : eventAttendeeTicketFullNameOrEmail(a).toUpperCase() ||
            `ZZZZZZ${a.id}`) >
        (b.primary
          ? 'AAAAAA'
          : eventAttendeeTicketFullNameOrEmail(b).toUpperCase() ||
            `ZZZZZZ${b.id}`)
          ? 1
          : -1
      )
    : [];
};

const MainContainer = styled.div`
  margin-bottom: 10px;
`;

const TicketInfo = styled.div`
  display: flex;
  flex-direction: row;
  line-height: 20px;
`;

const TicketStatus = styled.div`
  width: 24px;
`;

const TicketStatusCheckbox = styled.div`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.backToBlack};
    width: 16px;
    height: 16px;
    margin: 3px;
    margin-right: 5px;
  `}
`;

const TicketName = styled.div<{ highlighted: boolean }>`
  ${({ theme, highlighted }) => css`
    background-color: ${highlighted ? theme.colors.yellowSubmarine : undefined};
  `}
  font-size: 12px;
  padding: 0px 4px;
`;

interface GuestlistTicketsProps {
  attendee: any;
  displayTicketStatus?: boolean;
  isTicketNameHighlighted?: (ticket: Ticket) => boolean;
}

export const GuestlistTickets: React.FC<GuestlistTicketsProps> = ({
  attendee,
  displayTicketStatus = true,
  isTicketNameHighlighted = () => false,
}) => {
  return (
    <MainContainer>
      {attendee.eventAttendee ? (
        <>
          {sortEventAttendeeTickets(attendee.eventAttendee.tickets).map(
            (ticket: any, index: number) => (
              <TicketInfo key={index}>
                {displayTicketStatus ? (
                  <TicketStatus>
                    {ticket.hasAttended && <YesIcon />}
                  </TicketStatus>
                ) : (
                  <TicketStatusCheckbox />
                )}
                <TicketName
                  highlighted={
                    isTicketNameHighlighted && isTicketNameHighlighted(ticket)
                  }
                >
                  {ticket.firstName && ticket.lastName
                    ? `${ticket.firstName} ${ticket.lastName}`
                    : ticket.email || `Ticket ${index + 1}`}
                </TicketName>
              </TicketInfo>
            )
          )}
        </>
      ) : null}
    </MainContainer>
  );
};
