import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { Performance, PerformanceOffer } from 'app/typings';
import { ArtistSetup } from 'app/typings/artistSetups';
import { dateFormatter } from 'app/shared/utils/datetime';
import usePermission from 'app/shared/utils/usePermission';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import { CreateArtistSetup } from 'app/shared/graphql/artistSetups/mutationHooks';
import { ContentWrapper } from 'app/shared/components/atoms/FlyoverContent';
import { Radio } from 'app/shared/components/atoms/Radio';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Spinner } from 'app/shared/components/atoms/SpinnerManualCSS';
import { Overline } from 'app/shared/components/atoms/TypographyManualCSS';
import { ArtistLink } from 'app/admin/components/molecules/EntityLink';
import FlyoverFooter from 'app/admin/components/molecules/FlyoverFooter';

interface Props {
  performanceOfferForArtist?: PerformanceOffer;
  performance: Performance | undefined;
  artistSetups: ArtistSetup[];
  handleFlyoverClose?: Function;
  loadingArtistSetups?: boolean;
  errorArtistSetups?: any;
  setIsSubmitting: Function;
  selectedArtistSetup: ArtistSetup | null;
  setSelectedArtistSetup: Function;
  onSuccess: Function;
}

const StyledRadio = styled(Radio)`
  label {
    align-items: flex-start;
  }
`;

const SetupLabel = styled.span`
  font-size: 14px;
`;

const AudioSupportLabel = styled.span`
  text-transform: capitalize;
`;

const SetupLastUpdated = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.blueSmoke};
    font-size: 10px;
  `}
`;

const NoSetupsMessage = styled.span`
  font-size: 13px;
  font-style: italic;
`;

const NoSetupsArtistLink = styled.div`
  margin-top: 15px;
`;

const SetupDescription = styled.div`
  font-size: 12px;
  margin-left: 30px;
  margin-top: -12px;

  strong {
    font-weight: 400;
  }
`;

const ArtistSetupSelect: React.FC<Props> = ({
  performanceOfferForArtist,
  performance,
  artistSetups,
  handleFlyoverClose,
  loadingArtistSetups,
  errorArtistSetups,
  setIsSubmitting,
  selectedArtistSetup,
  setSelectedArtistSetup,
  onSuccess,
}) => {
  const intl = useIntl();
  const hasViewArtistsPermission = usePermission('artist.list.view');

  const performanceSetup = artistSetups.find(
    (artistSetup: ArtistSetup) => artistSetup.setuppableType === 'Performance'
  );

  const setupOptions =
    performanceSetup && performanceSetup.id
      ? [
          performanceSetup,
          ...artistSetups.filter(
            (artistSetup: ArtistSetup) =>
              artistSetup.id !== performanceSetup.derivedFrom &&
              artistSetup.id !== performanceSetup.id
          ),
        ]
      : artistSetups;

  if (performanceSetup && !selectedArtistSetup) {
    setSelectedArtistSetup(performanceSetup);
  }

  if (!performanceSetup && !selectedArtistSetup && performanceOfferForArtist) {
    const preSelectedArtistSetup = artistSetups.find(
      (artistSetup: ArtistSetup) =>
        artistSetup.setupName ===
        performanceOfferForArtist.artistSetup?.setupName
    );
    if (preSelectedArtistSetup) {
      setSelectedArtistSetup(preSelectedArtistSetup);
    }
  }

  const handleCreateArtistSetup = useSubmitAction({
    submitAction: CreateArtistSetup(),
    submitVariables: () => ({
      setupName: selectedArtistSetup && selectedArtistSetup.setupName,
      numPerformers: selectedArtistSetup && selectedArtistSetup.numPerformers,
      audioSupport: selectedArtistSetup && selectedArtistSetup.audioSupport,
      additionalSupportNotes:
        selectedArtistSetup && selectedArtistSetup.additionalSupportNotes,
      instrumentNotes:
        selectedArtistSetup && selectedArtistSetup.instrumentNotes,
      performanceId: performance && performance.id,
      derivedFrom:
        selectedArtistSetup &&
        (selectedArtistSetup.derivedFrom || selectedArtistSetup.id),
      isPrimarySetup: selectedArtistSetup && selectedArtistSetup.isPrimarySetup,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.eventPlanner.artists.createArtistSetup.success',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.eventPlanner.artists.createArtistSetup.failure',
    }),
    onSuccess: () => {
      onSuccess && onSuccess(performance);
    },
    onValidationError: () => {
      setIsSubmitting(false);
    },
  });

  const createArtistSetupAction = () => {
    setIsSubmitting(true);
    handleCreateArtistSetup();
  };

  if ((!loadingArtistSetups && !artistSetups) || errorArtistSetups) {
    onSuccess && onSuccess(performance, undefined);
  }

  return (
    <div data-qaid="update-artist-setup-flyover">
      <ContentWrapper>
        <Overline>
          {intl.formatMessage({
            id:
              'admin.eventPlanner.artists.createArtistSetup.updateArtistSetup',
          })}
        </Overline>
        <Spacer mt={4} />

        {loadingArtistSetups ? (
          <Spinner />
        ) : artistSetups.length === 0 ? (
          <>
            {hasViewArtistsPermission ? (
              <>
                <NoSetupsMessage>
                  {intl.formatMessage({
                    id:
                      'admin.eventPlanner.artists.createArtistSetup.noArtistSetupsCreateOne',
                  })}
                </NoSetupsMessage>
                <NoSetupsArtistLink>
                  {performance && (
                    <ArtistLink
                      artist={performance.artist}
                      truncateLength={28}
                    />
                  )}
                </NoSetupsArtistLink>
              </>
            ) : (
              <NoSetupsMessage>
                {intl.formatMessage({
                  id:
                    'admin.eventPlanner.artists.createArtistSetup.noArtistSetups',
                })}
              </NoSetupsMessage>
            )}
          </>
        ) : (
          setupOptions.map((setup: any, index: number) => {
            return (
              <React.Fragment key={index}>
                <StyledRadio
                  id={`artistSetup-${index}`}
                  value="active"
                  name={`artistSetup-${index}`}
                  checked={
                    (selectedArtistSetup &&
                      selectedArtistSetup.id === setup.id) ||
                    false
                  }
                  onChange={() => setSelectedArtistSetup(setup)}
                  data-qaid={`artist-setup-radio-button-${index}`}
                >
                  <SetupLabel>
                    {setup.setupName}
                    {setup.isPrimarySetup &&
                      ` (${intl.formatMessage({
                        id: 'admin.shared.primary',
                      })})`}
                  </SetupLabel>
                </StyledRadio>
                <SetupDescription>
                  <p>
                    <SetupLastUpdated>
                      <i>
                        {intl.formatMessage({
                          id: 'admin.shared.updatedOn',
                        })}{' '}
                        {setup.lastUpdatedAt
                          ? `on ${dateFormatter(
                              setup.lastUpdatedAt,
                              'longMonthDateAndYear'
                            )}`
                          : dateFormatter(
                              setup.createdAt,
                              'longMonthDateAndYear'
                            )}
                      </i>
                    </SetupLastUpdated>
                  </p>
                  <p>
                    <strong>
                      {intl.formatMessage({
                        id: 'admin.shared.numPerformers',
                      })}
                      :
                    </strong>{' '}
                    {setup.numPerformers}
                  </p>
                  <p>
                    <strong>
                      {intl.formatMessage({
                        id: 'admin.shared.audioSupport',
                      })}
                      :
                    </strong>{' '}
                    <AudioSupportLabel>
                      {setup.audioSupport.replace('_', ' ')}
                    </AudioSupportLabel>
                  </p>
                  <p>
                    <strong>
                      {intl.formatMessage({
                        id: 'admin.shared.additionalSupportNotes',
                      })}
                      :
                    </strong>
                    <br />
                    {setup.additionalSupportNotes ||
                      intl.formatMessage({
                        id: 'none',
                      })}
                  </p>
                  <p>
                    <strong>
                      {intl.formatMessage({
                        id: 'admin.shared.instrumentNotes',
                      })}
                      :
                    </strong>
                    <br />
                    {setup.instrumentNotes ||
                      intl.formatMessage({
                        id: 'none',
                      })}
                  </p>
                </SetupDescription>
                <Spacer mt={4} />
              </React.Fragment>
            );
          })
        )}
      </ContentWrapper>
      <FlyoverFooter
        buttonText={
          artistSetups.length === 0
            ? intl.formatMessage({
                id: 'ok',
              })
            : intl.formatMessage({
                id: 'admin.eventPlanner.artists.createArtistSetup.update',
              })
        }
        buttonDisabled={
          artistSetups.length !== 0 && selectedArtistSetup === null
        }
        onClickButton={() => {
          if (artistSetups.length === 0 && handleFlyoverClose) {
            handleFlyoverClose();
          } else {
            createArtistSetupAction();
          }
        }}
        dataQaid="update-artist-setup-submit-button"
      />
    </div>
  );
};

export default ArtistSetupSelect;
