import React from 'react';
import styled, { css } from 'styled-components';

import { withShadow } from 'app/shared/components/styleUtils/manualCSS/withShadow';

interface MenuItemProps {
  children: string | React.ReactNode;
  active?: boolean;
  disabled?: boolean;
  onClick?: () => any;
  invertColor?: boolean;
  'data-qaid'?: string;
}

export const MenuItem = styled.li<MenuItemProps>`
  ${({ theme, active, disabled, invertColor }) => css`
    margin-top: 0px;
    list-style: none;
    padding: ${theme.dimensions.menuItem.padding};
    font-size: ${theme.fontSizes.body2.xs};
    letter-spacing: ${theme.fontStyles.body2.letterSpacing};
    cursor: pointer;

    color: ${invertColor ? theme.colors.whiteDenim : theme.colors.backToBlack};

    ${theme.media.xs`
      font-size: ${theme.fontSizes.body2.xs};
    `}

    ${theme.media.lg`
      font-size: ${theme.fontSizes.body2.lg};
    `}

    &:hover, &:focus {
      background-color: ${invertColor
        ? theme.colors.green400
        : theme.colors.silverSprings};
      color: ${invertColor ? theme.colors.backToBlack : 'inherit'};
    }

    ${active &&
      css`
        background-color: ${invertColor
          ? theme.colors.green400
          : theme.colors.silverSprings};
        color: ${invertColor ? theme.colors.backToBlack : 'inherit'};
      `}

    ${disabled &&
      css`
        cursor: initial;
        color: ${theme.colors.blueSmoke};

        &:hover,
        &:focus {
          background-color: transparent;
        }
      `}
  `}
`;

interface MenuProps {
  children?: any;
  width?: string;
  bordered?: boolean;
  depth?: any;
  invertColor?: boolean;
}

export const Menu = styled.ul<MenuProps>`
  ${({ depth = 3, theme, width, bordered, invertColor }) => css`
    ${withShadow({ depth })};

    margin: 0;
    padding: 0;
    border-radius: ${theme.borderRadius.default};
    background-color: ${theme.colors.whiteDenim};
    max-height: 300px;
    width: ${width || 'auto'};
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    ${bordered &&
      css`
        border: 1px solid ${theme.colors.macyGrey};

        ${invertColor &&
          css`
            border: 1px solid ${theme.colors.primary};
          `}
      `}

    ${invertColor &&
      css`
        background-color: ${theme.colors.blackBetty};
        color: ${theme.colors.whiteDenim};
      `}
  `};
`;
