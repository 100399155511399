import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { EventStaffMember } from 'app/typings/events';
import { Theme } from 'app/typings/themes';
import { User } from 'app/typings/users';
import { Venue } from 'app/typings/venues';
import { useCurrentTheme } from 'app/shared/theme';
import { dateFormatter } from 'app/shared/utils/datetime';
import { upperFirst } from 'app/shared/utils/string';
import usePermission from 'app/shared/utils/usePermission';
import { AuthContext } from 'app/shared/context/Auth';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { ToolTip, ToolTipContainer } from 'app/shared/components/atoms/ToolTip';
import TruncatedByCharText from 'app/shared/components/atoms/TruncatedByCharText';
import {
  Body2,
  Overline,
} from 'app/shared/components/atoms/TypographyManualCSS';
import IconAndTextLink from 'app/shared/components/molecules/IconAndTextLink';
import { CrewRoleStatus } from 'app/admin/utils/crew';
import { useEventStaffAddBySelfPermissions } from 'app/admin/utils/crew';
import {
  AdminListingCard,
  EventDate,
  EventLocation,
  EventType,
} from 'app/admin/components/molecules/EventPlannerUtils';
import IconButton from 'app/admin/components/molecules/IconButton';
import { TagElement } from 'app/admin/components/molecules/TagElementList';
import CrewRoleCircle from 'app/admin/components/organisms/CrewRoleCircle';
import { ReactComponent as NotesBookText } from 'icons/streamline-regular/content/notes/notes-book-text.svg';
import { ReactComponent as Checklist } from 'icons/streamline-regular/interface-essential/form-edition/checklist.svg';
import { ReactComponent as LoveHat } from 'icons/streamline-regular/romance/love/love-hat.svg';

import { eventRoleLabels } from './labels';

interface Props {
  id: number;
  localStartsAt: string;
  citySlug: string;
  cityTitle: string;
  cancelled?: boolean;
  neighborhoodTitle?: string;
  theme?: Theme;
  type?: string;
  index: number;
  staff?: EventStaffMember[];
  refetchEvents: Function;
  venue: Venue;
  crewLoadInAt?: string;
  onShowGuestlistDayOfShow?: Function;
  numTicketsAvailableForSale?: number;
}

interface CrewProps {
  eventId: number;
  staff?: EventStaffMember[];
  localStartsAt: string;
  crewLoadInAt?: string;
  cancelled?: boolean;
  citySlug: string;
  cityTitle: string;
  refetchEvents: Function;
}

interface EventStaffObject {
  role: string; // e.g. 'crew_lead'
  label: string; // e.g. 'Crew Lead'
  shortLabel?: string; // e.g. 'Lead'
  position: number;
  group: number;
  userId?: number;
  assigned: boolean;
}

const EventBasics = styled.div`
  margin-bottom: 15px;
`;

const EventTopContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  width: 100%;
`;

const EventDateAndTheme = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: flex-start;
  width: 100%;
`;

const InlineControls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const InlineControlsContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin: 20px 0 0 0;
  flex-grow: 1;
  justify-content: flex-end;
  width: 100%;
`;

const CrewRoleSection = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 5px;
`;

const CrewRoleWrapper = styled.div`
  max-width: 42px;
  margin-right: 8px;
`;

const CancelledTagElementWrapper = styled.div`
  height: 32px;
  margin-top: -15px;
`;

const StyledTag = styled(TagElement)`
  text-align: center;
  padding: 1px 5px;
  height: 18px;
  border-radius: 0px 0px 2px 2px;
  width: fit-content;
`;

export const getLocaleDateTime = (localStartsAt: string) => {
  const localeDateTime = dateFormatter(
    localStartsAt,
    'shortWeekdayShortMonthDateHoursAndMinutesWithTimezone'
  );

  const formattedLocaleDateTime = localeDateTime.toString().split(' ');

  return {
    weekday: formattedLocaleDateTime[0].slice(0, -1),
    month: formattedLocaleDateTime[1],
    day: formattedLocaleDateTime[2].slice(0, -1),
    time: formattedLocaleDateTime[3],
    meridian: formattedLocaleDateTime[4]
      ? formattedLocaleDateTime[4].toLowerCase()
      : '',
  };
};

const getRoleStatus = (
  staffObject: EventStaffObject,
  currentUser: User | null,
  rolePermissions: { [roleKey: string]: boolean }
): CrewRoleStatus => {
  if (staffObject.assigned) {
    if (staffObject.role && currentUser) {
      if (!staffObject.userId) {
        return rolePermissions[staffObject.role]
          ? CrewRoleStatus.AVAILABLE
          : CrewRoleStatus.UNAVAILABLE;
      } else if (currentUser.id === staffObject.userId) {
        return CrewRoleStatus.FILLED_BY_CURRENT_USER;
      } else if (currentUser.id !== staffObject.userId) {
        return CrewRoleStatus.FILLED;
      }
    }
  }
  return CrewRoleStatus.NOT_APPLICABLE;
};

const getEventStaffObjects = (
  intl: any,
  eventStaffMembers?: EventStaffMember[]
) => {
  let mapRoleToUser =
    eventStaffMembers &&
    eventStaffMembers.reduce((map, eventStaffMember) => {
      map[eventStaffMember.role.key] = {
        user: eventStaffMember.user,
        assigned: true,
      };
      return map;
    }, {});

  let staffObjects: any = [];

  eventRoleLabels(intl).forEach(eventRoleLabel => {
    const eventStaffUser = mapRoleToUser && mapRoleToUser[eventRoleLabel.role];

    if (eventStaffUser) {
      let staffObject: EventStaffObject;
      if (eventStaffUser.user) {
        staffObject = {
          ...eventRoleLabel,
          userId: eventStaffUser.user.id,
          assigned: eventStaffUser.assigned,
        };
      } else {
        staffObject = {
          ...eventRoleLabel,
          assigned: eventStaffUser.assigned,
        };
      }
      staffObjects.push(staffObject);
    } else {
      staffObjects.push(eventRoleLabel);
    }
  });

  return staffObjects;
};

const getEventStaffObjectsForGroup = (
  staffObjects: EventStaffObject[],
  group: number
) =>
  staffObjects.filter(
    (staffObject: EventStaffObject) => staffObject.group === group
  );

interface CrewRoleListProps {
  staffObjects: EventStaffObject[];
  eventId: number;
  localStartsAt: string;
  crewLoadInAt?: string;
  cancelled?: boolean;
  citySlug: string;
  cityTitle: string;
  refetchEvents: Function;
}

const CrewRoleList: React.FC<CrewRoleListProps> = ({
  staffObjects,
  eventId,
  localStartsAt,
  crewLoadInAt,
  cancelled,
  citySlug,
  cityTitle,
  refetchEvents,
}) => {
  const { user: currentUser } = useContext(AuthContext);
  const theme = useCurrentTheme();
  const rolePermissions = useEventStaffAddBySelfPermissions();

  return (
    <CrewRoleSection>
      {staffObjects.map((staffObject: EventStaffObject, key: number) => (
        <CrewRoleWrapper data-qaid={`crew-list-group-${key}`} key={key}>
          <Overline color={cancelled ? theme.colors.blueSmoke : undefined}>
            {staffObject.shortLabel || ''}
          </Overline>
          <CrewRoleCircle
            eventId={eventId}
            citySlug={citySlug}
            cityTitle={cityTitle}
            localStartsAt={localStartsAt}
            crewLoadInAt={crewLoadInAt}
            cancelled={cancelled}
            staffObject={staffObject}
            roleStatus={getRoleStatus(
              staffObject,
              currentUser,
              rolePermissions
            )}
            refetchEvents={refetchEvents}
          />
        </CrewRoleWrapper>
      ))}
    </CrewRoleSection>
  );
};

const EventCrew: React.FC<CrewProps> = ({
  eventId,
  staff = [],
  localStartsAt,
  crewLoadInAt,
  cancelled,
  citySlug,
  cityTitle,
  refetchEvents,
}) => {
  const intl = useIntl();
  const { user: currentUser } = useContext(AuthContext);
  const theme = useCurrentTheme();

  if (!staff || !currentUser) {
    return null;
  }

  let staffObjects = getEventStaffObjects(intl, staff);

  return (
    <div data-qaid="crew-roles-group">
      <Overline color={cancelled ? theme.colors.blueSmoke : undefined}>
        {intl.formatMessage({
          id: 'admin.crewPortal.roles',
        })}
      </Overline>
      <CrewRoleList
        staffObjects={getEventStaffObjectsForGroup(staffObjects, 1)}
        eventId={eventId}
        localStartsAt={localStartsAt}
        crewLoadInAt={crewLoadInAt}
        cancelled={cancelled}
        citySlug={citySlug}
        cityTitle={cityTitle}
        refetchEvents={refetchEvents}
      />
      <CrewRoleList
        staffObjects={getEventStaffObjectsForGroup(staffObjects, 2)}
        eventId={eventId}
        localStartsAt={localStartsAt}
        crewLoadInAt={crewLoadInAt}
        cancelled={cancelled}
        citySlug={citySlug}
        cityTitle={cityTitle}
        refetchEvents={refetchEvents}
      />
      <CrewRoleList
        staffObjects={getEventStaffObjectsForGroup(staffObjects, 3)}
        eventId={eventId}
        localStartsAt={localStartsAt}
        crewLoadInAt={crewLoadInAt}
        cancelled={cancelled}
        citySlug={citySlug}
        cityTitle={cityTitle}
        refetchEvents={refetchEvents}
      />
      <CrewRoleList
        staffObjects={getEventStaffObjectsForGroup(staffObjects, 4)}
        eventId={eventId}
        localStartsAt={localStartsAt}
        crewLoadInAt={crewLoadInAt}
        cancelled={cancelled}
        citySlug={citySlug}
        cityTitle={cityTitle}
        refetchEvents={refetchEvents}
      />
    </div>
  );
};

const EventVenueDetails: React.FC<any> = ({
  venue,
  crewLoadInAt,
  color = undefined,
  numTicketsAvailableForSale,
}) => {
  const intl = useIntl();
  const crewLoadInTitle = upperFirst(
    intl.formatMessage({
      id: 'admin.shared.eventTimes.crewLoadInTime',
    })
  );

  const venueName = venue
    ? venue.venueName || venue.address
    : intl.formatMessage({
        id: 'admin.crewPortal.venue.comingSoon',
      });

  return (
    <div data-qaid="crew-venue-group">
      <Spacer mb={4} />
      <Body2 color={color}>
        <TruncatedByCharText text={venueName} truncateLength={18} />
      </Body2>
      <Body2 color={color}>
        {crewLoadInTitle}: {crewLoadInAt}
      </Body2>
      <Body2 color={color}>
        {intl.formatMessage({
          id: 'shared.capacity',
        })}
        : {venue?.capacity || 'TBD'}
      </Body2>
      <Body2 color={color}>
        {intl.formatMessage({
          id: 'admin.shared.ticketsAvailable',
        })}
        : {numTicketsAvailableForSale}
      </Body2>
    </div>
  );
};

const CrewPortalCard: React.FC<Props> = ({
  id,
  localStartsAt,
  crewLoadInAt,
  cancelled,
  citySlug,
  cityTitle,
  neighborhoodTitle,
  theme: eventTheme,
  type,
  index,
  staff,
  refetchEvents,
  venue,
  onShowGuestlistDayOfShow = () => {},
  numTicketsAvailableForSale,
}) => {
  const intl = useIntl();
  const theme = useCurrentTheme();
  const hasGuestlistAccessPermission = usePermission('event.guestlist.access');

  const onShowGuestlistDayOfShowParams = {
    id,
  };

  return (
    <AdminListingCard key={index.toString()} data-qaid="crew-portal-card">
      <EventBasics>
        <EventTopContent>
          <CancelledTagElementWrapper data-qaid="cancelled-tag-container">
            {cancelled && (
              <StyledTag
                tagElementColor={theme.colors.redRedWine}
                textColor={theme.colors.whiteDenim}
              >
                {intl.formatMessage({
                  id: 'admin.eventPlanner.cancelledStatus',
                })}
              </StyledTag>
            )}
          </CancelledTagElementWrapper>
          <EventDateAndTheme>
            <EventDate
              show={true}
              localStartsAt={localStartsAt}
              greyedOut={cancelled}
            />
            <InlineControlsContainer>
              <InlineControls>
                {eventTheme ? (
                  <ToolTipContainer>
                    <IconButton icon={LoveHat} disabled={false} />
                    <ToolTip>{eventTheme.title}</ToolTip>
                  </ToolTipContainer>
                ) : (
                  <IconButton icon={LoveHat} disabled={true} />
                )}
              </InlineControls>
            </InlineControlsContainer>
          </EventDateAndTheme>
        </EventTopContent>
        <EventLocation
          show={true}
          cityTitle={cityTitle}
          neighborhoodTitle={neighborhoodTitle}
          color={cancelled ? theme.colors.blueSmoke : undefined}
        />
        <EventType
          show={true}
          type={type}
          color={cancelled ? theme.colors.blueSmoke : undefined}
        />
        <EventVenueDetails
          numTicketsAvailableForSale={numTicketsAvailableForSale}
          venue={venue}
          crewLoadInAt={crewLoadInAt}
          color={cancelled ? theme.colors.blueSmoke : undefined}
        />
      </EventBasics>
      <DottedLine />
      <EventCrew
        eventId={id}
        staff={staff}
        localStartsAt={localStartsAt}
        crewLoadInAt={crewLoadInAt}
        cancelled={cancelled}
        citySlug={citySlug}
        cityTitle={cityTitle}
        refetchEvents={refetchEvents}
      />
      <DottedLine />
      <>
        {hasGuestlistAccessPermission && (
          <IconAndTextLink
            icon={Checklist}
            text={intl.formatMessage({
              id: 'admin.crewPortal.cardControls.viewGuestlist',
            })}
            onClick={
              !cancelled
                ? () => onShowGuestlistDayOfShow(onShowGuestlistDayOfShowParams)
                : undefined
            }
            dataQaid="guestlist-crew-portal-card-btn"
            buttonStyle="unfilled"
            disabled={cancelled}
          />
        )}
        <IconAndTextLink
          icon={NotesBookText}
          text={intl.formatMessage({
            id: 'admin.crewPortal.cardControls.concertDetails',
          })}
          url={`/admin/crew-portal/events/${id}`}
          dataQaid="concert-details-crew-portal-card-btn"
          buttonStyle="unfilled"
        />
      </>
    </AdminListingCard>
  );
};

export default CrewPortalCard;
