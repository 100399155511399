import React from 'react';
import styled, { css } from 'styled-components';

import useDeviceDetector from 'app/shared/utils/useDeviceDetector';
import { getPosition } from 'app/shared/utils/usePosition';
import { Keyline } from 'app/shared/components/atoms/Keyline';
import { Menu, MenuItem } from 'app/shared/components/atoms/Menu';
import { Popper } from 'app/shared/components/atoms/PopperManualCSS';
import { Portal } from 'app/shared/components/atoms/Portal';
import { SelectState } from 'app/shared/components/molecules/SelectManualCSS/index';

import GroupedOptions from './Grouped';
import MenuHeader from './MenuHeader';
import SimpleOptions from './Simple';

export interface OptionsListProps<T> {
  getOptionLabel: (opt: T) => string;
  renderOption?: (option: T, props: any) => React.ReactNode;
  recommendedOptions?: T[];
  getRecommendedOptionsTitle?: (options: T[]) => string;
  mostVisitedOptions?: T[];
  getMostVisitedOptionsTitle?: (options: T[]) => string;
  userIsSearching?: boolean;
}

export interface NoOptionsOption {
  label: string;
  onClick?: () => void;
}

const defaultNoOptionsOption: NoOptionsOption = {
  label: 'No options...',
  onClick: undefined,
};

interface Props<T> extends OptionsListProps<T> {
  qaId?: string;
  options: T[];
  isOpen: boolean;
  innerRef: React.RefObject<HTMLDivElement>;
  onOptionClick: (option: T) => void;
  noOptionsOption?: NoOptionsOption;
  searchable: boolean;
  groupBy?: (option: T) => string;
  state?: SelectState;
  keepInViewPort?: boolean;
}

const AdvancedMenu = styled(Menu)<{ isOpen: boolean; isFlipped: boolean }>`
  ${({ isOpen, isFlipped }) =>
    isOpen &&
    !isFlipped &&
    css`
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    `}

  ${({ isOpen, isFlipped }) =>
    isOpen &&
    isFlipped &&
    css`
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      box-shadow: 0 0px 20px 0 rgba(0, 0, 0, 0.19);
    `}
`;

const MobileContainer = styled.div`
  position: absolute;
  z-index: 20;
`;

const Options = <T extends {}>({
  innerRef,
  isOpen,
  qaId,
  getOptionLabel,
  renderOption,
  options,
  onOptionClick,
  noOptionsOption = defaultNoOptionsOption,
  recommendedOptions,
  getRecommendedOptionsTitle,
  mostVisitedOptions,
  getMostVisitedOptionsTitle,
  userIsSearching,
  state,
  groupBy,
  searchable,
  keepInViewPort = true,
}: Props<T>) => {
  const isMobile = useDeviceDetector() === 'mobile';
  const showRecommendedOptions =
    recommendedOptions !== undefined &&
    recommendedOptions.length > 0 &&
    !userIsSearching;
  const showMostVisitedOptions =
    mostVisitedOptions !== undefined &&
    mostVisitedOptions.length > 0 &&
    !userIsSearching;

  if (!isOpen) {
    return null;
  }

  const renderOptions = () => {
    switch (state) {
      case SelectState.loading: {
        return (
          <MenuItem disabled data-qaid={`${qaId}-loading-msg`}>
            Loading...
          </MenuItem>
        );
      }
      case SelectState.error: {
        return (
          <MenuItem disabled data-qaid={`${qaId}-error-msg`}>
            Could not load options!
          </MenuItem>
        );
      }
      case SelectState.ready: {
        if (options.length === 0 && userIsSearching) {
          const onNoOptionsClick = noOptionsOption.onClick;
          return (
            <MenuItem
              data-qaid={`${qaId}-empty-msg`}
              onClick={onNoOptionsClick}
              disabled={!onNoOptionsClick}
            >
              {noOptionsOption.label}
            </MenuItem>
          );
        }

        if (
          (showRecommendedOptions || showMostVisitedOptions) &&
          !userIsSearching
        ) {
          const typedRecommendedOptions = recommendedOptions as T[];
          const typedMostVisitedOptions = mostVisitedOptions as T[];

          return (
            <>
              {showRecommendedOptions && (
                <>
                  <MenuHeader
                    data-qaid={`${qaId}-recommended-header`}
                    showTopAccent={true}
                  >
                    {getRecommendedOptionsTitle
                      ? getRecommendedOptionsTitle(typedRecommendedOptions)
                      : `Recommended ${typedRecommendedOptions.length}`}
                  </MenuHeader>
                  <SimpleOptions
                    options={typedRecommendedOptions}
                    getOptionLabel={getOptionLabel}
                    onOptionClick={onOptionClick}
                    renderOption={renderOption}
                    qaId={qaId}
                  />
                </>
              )}
              {showRecommendedOptions && showMostVisitedOptions && (
                <Keyline lineColor="warmGrey" mt={0} mb={6} />
              )}
              {showMostVisitedOptions && (
                <>
                  <MenuHeader
                    data-qaid={`${qaId}-popular-header`}
                    showTopAccent={!showRecommendedOptions}
                  >
                    {getMostVisitedOptionsTitle
                      ? getMostVisitedOptionsTitle(typedMostVisitedOptions)
                      : `Top ${typedMostVisitedOptions.length}`}
                  </MenuHeader>
                  <SimpleOptions
                    options={typedMostVisitedOptions}
                    getOptionLabel={getOptionLabel}
                    onOptionClick={onOptionClick}
                    renderOption={renderOption}
                    qaId={qaId}
                  />
                </>
              )}
            </>
          );
        }

        return (
          <>
            {!groupBy && (
              <SimpleOptions
                qaId={qaId}
                options={options}
                getOptionLabel={getOptionLabel}
                onOptionClick={onOptionClick}
                renderOption={renderOption}
              />
            )}

            {groupBy && (
              <GroupedOptions
                qaId={qaId}
                options={options}
                groupBy={groupBy}
                getOptionLabel={getOptionLabel}
                onOptionClick={onOptionClick}
                renderOption={renderOption}
              />
            )}
          </>
        );
      }
    }

    return null;
  };

  /*
    The Popper component doesn't behave as expected on mobile browsers when an input
    is focused and the soft keyboard is triggered. Additionally, many mobile browsers
    treat fixed and absolute positioned elements as static when an input is focused. Because
    of all this, the Popper calculations fail, displaying the select options in the wrong position.
    This alternative uses a dumber component that requires less calculations and it's a bit less flexible, in an attempt to give mobile users a better experience when using searchable select
    fields.
  */
  if (isMobile && searchable) {
    const anchorRefPositionAttributes = getPosition(innerRef.current);

    return (
      <MobileContainer
        style={{
          top: `${(innerRef as any).current.offsetTop +
            anchorRefPositionAttributes.height}px`,
          width: `${anchorRefPositionAttributes.width}px`,
        }}
      >
        <AdvancedMenu
          isOpen={isOpen}
          isFlipped={false}
          bordered
          data-qaid={`${qaId}-${showMostVisitedOptions ? 'popular' : 'menu'}`}
        >
          {renderOptions()}
        </AdvancedMenu>
      </MobileContainer>
    );
  }

  return (
    <Portal dom={document.body}>
      <Popper
        anchorEl={innerRef}
        reactToChange={options.length}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        offset={{
          vertical:
            showRecommendedOptions && !showMostVisitedOptions ? -172 : -2,
        }}
        width="auto"
        flip={keepInViewPort}
      >
        {({
          ref,
          style,
          isFlipped,
        }: {
          ref: any;
          style: any;
          isFlipped: boolean;
        }) => (
          <AdvancedMenu
            ref={ref}
            style={style}
            isOpen={isOpen}
            isFlipped={isFlipped}
            bordered
            data-qaid={`${qaId}-${showMostVisitedOptions ? 'popular' : 'menu'}`}
          >
            {renderOptions()}
          </AdvancedMenu>
        )}
      </Popper>
    </Portal>
  );
};

export default Options;
