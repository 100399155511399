import React, { useContext, useEffect, useState } from 'react';
import loadable from '@loadable/component';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { Artist } from 'app/typings/artists';
import { GetOffersData, Offer } from 'app/typings/offers';
import { useCurrentTheme } from 'app/shared/theme';
import { get } from 'app/shared/utils/get';
import { GetCitiesLite } from 'app/shared/graphql/cities/queryHooks';
import { AuthContext } from 'app/shared/context/Auth';
import { ListPage, ListPageContext } from 'app/shared/context/ListPage';
import { Checkbox } from 'app/shared/components/atoms/CheckboxManualCSS';
import { LoadingBlocks } from 'app/shared/components/atoms/LoadingBlocks';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Overline } from 'app/shared/components/atoms/TypographyManualCSS';
import { artistTitleWithEmail } from 'app/admin/utils/artists';
import {
  getCityOptions,
  getFilterNamesAndOptionsForTitleMapping,
} from 'app/admin/utils/optionLists';
import { GetOffers } from 'app/admin/graphql/offers/queryHooks';
import ArtistTypeahead from 'app/admin/components/molecules/ArtistTypeahead';
import CardGrid, { CardType } from 'app/admin/components/organisms/CardGrid';
import ListingControls from 'app/admin/components/organisms/ListingControls';
import ListingHeader from 'app/admin/components/organisms/ListingHeader';
import OfferCard from 'app/admin/components/organisms/OfferCard';
import ListPageTemplate from 'app/admin/components/templates/ListPage';
import Layout from 'app/admin/layouts/ListPage';

import { sortOptions } from './options';

const GlobalError = loadable(
  () => import('app/shared/components/pages/Status'),
  {
    resolveComponent: components => components.GlobalError,
  }
);
const ListingFilter = loadable(() =>
  import('app/admin/components/organisms/ListingFilter')
);
const ListingNoResults = loadable(() =>
  import('app/admin/components/molecules/ListingNoResults')
);
const ListingFooter = loadable(() =>
  import('app/admin/components/molecules/ListingFooter')
);
const RoutableModal = loadable(() =>
  import('app/shared/components/molecules/RoutableModal')
);

const pageStateConfig = {
  filterNames: ['artist_ids', 'created_date', 'city', 'status'],
  defaultSort: { by: 'created_at', direction: 'desc' },
  textSearchParamName: 'offer_search',
  idParamName: 'offer_id',
};

const getArtistFilterValue = (artist: Artist) =>
  `${artist.id}-${artistTitleWithEmail(artist)}`;

const CheckboxContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row:
    flex-wrap: wrap;
    border-radius: 16px;
    height: 44px;
    padding: 12px;
    padding-left: 18px;
    margin: 15px 0px;
    background-color: ${theme.colors.darkSmoke};
  `}
`;

const StyledOverline = styled(Overline)`
  display: block;
`;

interface ArtistTypeaheadFilterProps {
  setSelectedValue: Function;
}

const ArtistTypeaheadFilter: React.FC<ArtistTypeaheadFilterProps> = ({
  setSelectedValue,
}) => {
  return (
    <ArtistTypeahead
      setSelectedArtist={(artist: Artist | undefined) => {
        if (artist) {
          setSelectedValue(getArtistFilterValue(artist));
        }
      }}
    />
  );
};

const AdminOffers: React.FC = () => {
  const PER_PAGE = 18;
  const intl = useIntl();
  const { user } = useContext(AuthContext);
  const pageState = useContext(ListPageContext);
  const theme = useCurrentTheme();
  const [filterByCurrentUser, setFilterByCurrentUser] = useState(false);
  const [offersData, setOffersData] = useState<GetOffersData | undefined>(
    undefined
  );

  const { data: dataCities } = GetCitiesLite();

  const getArtistIdsSubmitVariable = () =>
    pageState.filterState.artist_ids.length > 0
      ? pageState.filterState.artist_ids
          .map((value: string) => value.split('-')[0])
          .join(',')
      : undefined;

  const { loading, error, data } = GetOffers({
    offerSearch: pageState.textSearchState,
    offerType: 'artist',
    orderBy: pageState.sortState.by,
    orderDirection: pageState.sortState.direction,
    page: pageState.page,
    perPage: PER_PAGE,
    status: pageState.filterListVariable('status') || 'open',
    city: pageState.filterListVariable('city'),
    artistIds: getArtistIdsSubmitVariable(),
    createdDate: pageState.filterListVariable('created_date'),
    createdById: filterByCurrentUser ? user?.id : undefined,
  });

  useEffect(() => {
    if (data) {
      setOffersData(data);
    }
  }, [data]);

  useEffect(() => {
    const preloadedComponents = [
      GlobalError,
      ListingFilter,
      ListingNoResults,
      ListingFooter,
      RoutableModal,
    ];
    preloadedComponents.map(c => c.preload());

    pageState.updateDetailsModal();
  }, []);

  useEffect(() => {
    pageState.updateScrollPositionOnPage();
  }, [pageState.detailsModal.isShowing]);

  useEffect(() => {
    pageState.updatePageUrl();
  }, [
    pageState.page,
    pageState.sortState,
    pageState.filterState,
    pageState.textSearchState,
    pageState.detailData,
    pageState.detailsModal.isShowing,
  ]);

  const modalsContent = () => (
    <>
      {pageState.detailsModal.isShowing && (
        <RoutableModal
          hide={pageState.detailsModal.hide}
          initialRouteProps={{
            ...pageState.detailData,
            onCloseOffer: (data: any) => {
              const newOffersData = offersData;
              const offerId = get(data, 'data.closeOffer.offer.id', undefined);
              const closedOfferIndex =
                newOffersData &&
                newOffersData.offers.offers.findIndex(
                  (offer: Offer) => offer.id === offerId
                );
              if (
                newOffersData &&
                closedOfferIndex !== undefined &&
                closedOfferIndex >= 0
              ) {
                newOffersData.offers.offers[closedOfferIndex].status = 'closed';
              }
              setOffersData(newOffersData);
            },
          }}
          initialRouteName="offer-details"
          dataQaidSuffix="admin-edit-offer-details"
        />
      )}
    </>
  );

  const cityOptions = getCityOptions(dataCities);

  const filterDropdownOptionsInfoList = [
    {
      filterName: 'status',
      dropdownParams: {
        searchBar: false,
        title: intl.formatMessage({
          id: 'admin.offerDirectory.filter.title.status',
        }),
        options: [
          {
            title: 'Open',
            value: 'open',
          },
          {
            title: 'Closed',
            value: 'closed',
          },
        ],
      },
    },
    {
      filterName: 'created_date',
      dropdownParams: {
        searchBar: false,
        title: intl.formatMessage({
          id: 'admin.offerDirectory.filter.title.dateOfInvitation',
        }),
        options: [
          {
            title: intl.formatMessage({
              id: 'admin.offerDirectory.filter.option.createdInLastDay',
            }),
            value: 'created_last_1_day',
          },
          {
            title: intl.formatMessage({
              id: 'admin.offerDirectory.filter.option.createdInLastWeek',
            }),
            value: 'created_last_7_days',
          },
          {
            title: intl.formatMessage({
              id: 'admin.offerDirectory.filter.option.createdInLastMonth',
            }),
            value: 'created_last_30_days',
          },
          {
            title: intl.formatMessage({
              id: 'admin.offerDirectory.filter.option.createdInLast3Months',
            }),
            value: 'created_last_90_days',
          },
        ],
      },
    },
    {
      filterName: 'city',
      dropdownParams: {
        searchBar: true,
        title: intl.formatMessage({
          id: 'admin.offerDirectory.filter.title.sofarCities',
        }),
        groupBy: 'country',
        groupById: 'country',
        options: cityOptions,
      },
    },
    {
      filterName: 'artist_ids',
      dropdownParams: {
        searchBar: false,
        title: intl.formatMessage({
          id: 'admin.offerDirectory.filter.title.artists',
        }),
        options: [],
        typeaheadFilterComponent: ArtistTypeaheadFilter,
      },
    },
  ];

  useEffect(() => {
    pageState.setupFilterLabelTitleMapping(
      getFilterNamesAndOptionsForTitleMapping(
        filterDropdownOptionsInfoList,
        pageState.filterState
      )
    );
  }, [dataCities, pageState.filterState]);

  if (!loading && error) {
    return <GlobalError />;
  }

  return (
    <Layout scrollDisabled={pageState.detailsModal.isShowing}>
      <ListPageTemplate
        pageTitle={intl.formatMessage({
          id: 'admin.artistBookingInvitations.pageTitle',
        })}
        modalsContent={modalsContent}
      >
        <ListingHeader
          pageTitle={intl.formatMessage({
            id: 'admin.artistBookingInvitations.pageTitle',
          })}
          searchPlaceholder={intl.formatMessage({
            id: 'admin.offerDirectory.textSearch.placeholder',
          })}
          searchValue={pageState.textSearchState}
          onSearch={pageState.handleTextSearch}
          dataQaidPrefix="offers"
        />
        <div>
          <ListingControls
            sortOptions={sortOptions}
            orderBy={pageState.sortState.by}
            orderDirection={pageState.sortState.direction}
            totalRecords={offersData && offersData.offers.metadata.totalRecords}
            loading={loading}
            onSort={pageState.handleSortChange}
            onReset={() => {
              setFilterByCurrentUser(false);
              pageState.handleResetFilters();
            }}
            dataQaidPrefix="offers"
          />
          <CheckboxContainer>
            <Checkbox
              id="my-offers-only"
              data-qaid="my-offers-only"
              checked={filterByCurrentUser}
              name="my-offers-only"
              color="whiteDenim"
              onChange={(e: any) => {
                setFilterByCurrentUser(e.target.checked);
                pageState.handlePageChange(1);
              }}
            >
              <StyledOverline color={theme.colors.whiteDenim}>
                {intl.formatMessage({
                  id: 'admin.offerDirectory.filter.myBookingInvitationsOnly',
                })}
              </StyledOverline>
            </Checkbox>
          </CheckboxContainer>
          <ListingFilter
            filterTitle={intl.formatMessage({
              id: 'admin.offerDirectory.filterTitle',
            })}
            textSearchString={pageState.textSearchState}
            handleTextSearchLabelClose={pageState.handleTextSearchLabelClose}
            labelTitleMapping={pageState.filterLabelTitleMapping}
            dropdownOptionsInfoList={filterDropdownOptionsInfoList}
            filterState={pageState.filterState}
            handleRemoveFilter={pageState.handleRemoveFilter}
            handleFilterChange={pageState.handleFilterChange}
          />
        </div>
        <div>
          <CardGrid
            objectData={get(offersData, 'offers.offers', [])}
            renderCardComponent={(offer: CardType, i: number) => (
              // @ts-ignore
              <OfferCard
                index={i}
                onShowDetails={pageState.toggleDetailsModalAndSetDetailData}
                {...offer}
              />
            )}
            dataQaid="admin-offers-list"
            loading={loading}
            loadingComponent={
              <LoadingBlocks.Rectangle width="100%" height="420px" />
            }
            hideDividerOnSize="xs"
          />
        </div>
        <ListingNoResults
          entityName={intl.formatMessage({ id: 'shared.invitations' })}
          numResults={get(offersData, 'offers.offers.length', undefined)}
          loading={loading}
        />
        <Spacer mt={8} />
        <ListingFooter
          numTotalRecords={get(
            offersData,
            'offers.metadata.totalRecords',
            undefined
          )}
          perPage={PER_PAGE}
          currentPage={pageState.page}
          onPageChange={pageState.handlePageChange}
          loading={loading}
          dataQaidPrefix="offers"
        />
      </ListPageTemplate>
    </Layout>
  );
};

const AdminOffersWrapper: React.FC = () => {
  return (
    <ListPage config={pageStateConfig}>
      <AdminOffers />
    </ListPage>
  );
};

export default AdminOffersWrapper;
