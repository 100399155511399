export interface Document {
  id: number;
  documentId?: string;
  filename: string;
  contentType: string;
  size: number;
  url?: string;
}

export interface SignedForm {
  id: number;
  url?: string;
  documentId: string;
  filename: string;
  documentType: string;
  uploadableId: number;
  uploadableType: string;
  createdAt: string;
}

export interface CreateSignedFormVariables {
  document: Document;
  documentType: string;
  uploadableId: number;
  uploadableType: string;
}

export interface CreateSignedFormData {
  success: boolean;
  signedForm: SignedForm;
}

export interface DeleteSignedFormData {
  success: boolean;
}

export enum DocumentTypes {
  RELEASE_FORM = 'ReleaseForm',
  TERMS_AND_CONDITIONS_FORM = 'TermsAndConditionsForm',
}
