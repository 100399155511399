import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Event } from 'app/typings';
import useModal from 'app/shared/utils/useModal';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import IconAndTextLink from 'app/shared/components/molecules/IconAndTextLink';
import RoutableModal from 'app/shared/components/molecules/RoutableModal';
import {
  InlineHtml,
  SectionText,
  SectionTitle,
  SectionWithIcon,
} from 'app/admin/components/atoms/CrewEventContent';
import IconInCircle from 'app/admin/components/atoms/IconInCircle';
import { ReactComponent as Photo } from 'icons/streamline-regular/images-photography/pictures/picture-polaroid-human.svg';
import { ReactComponent as TaskListCheck1 } from 'icons/streamline-regular/work-office-companies/tasks/task-list-check-1.svg';

interface EventInfoProps {
  event: Event;
}

const Subheading = styled.span`
  font-weight: 600;
`;

const CrewEventVenueSetupInfo: React.FC<EventInfoProps> = ({ event }) => {
  const [venuePhotoModal, toggleVenuePhotoModal] = useModal();

  const intl = useIntl();

  return (
    <SectionWithIcon>
      <SectionTitle>
        <IconInCircle
          borderCircle={true}
          circleBackgroundColor="whiteDenim"
          dataQaIdSuffix="task-list"
          iconColor="blueChristmas"
          iconName={TaskListCheck1}
        />
        {intl.formatMessage({ id: 'admin.crewEvent.section.venueSetupInfo' })}
      </SectionTitle>
      <Spacer mt={4} />
      <IconAndTextLink
        icon={Photo}
        text={intl.formatMessage({
          id: 'admin.crewEvent.section.venueSetupInfo.viewPhotosOfVenueSetup',
        })}
        onClick={
          //@ts-ignore
          event?.venue?.venuePhotos?.length > 0
            ? toggleVenuePhotoModal
            : undefined
        }
        dataQaid="crew-event-view-photos"
        buttonStyle="unfilled"
        disabled={
          //@ts-ignore
          !event.venue || event?.venue?.venuePhotos.length === 0
        }
      />
      <SectionText>
        <Spacer mt={4} />
        <>
          <Subheading>
            {intl.formatMessage({
              id: 'admin.crewEvent.section.venueSetupInfo.notesForMc',
            })}
            :{' '}
          </Subheading>
          <InlineHtml
            dangerouslySetInnerHTML={{
              __html: event?.mcNotes || intl.formatMessage({ id: 'none' }),
            }}
          />
        </>
        <Spacer mt={4} />
        <>
          <Subheading>
            {intl.formatMessage({
              id: 'admin.crewEvent.section.venueSetupInfo.checkIn',
            })}
            :{' '}
          </Subheading>
          <InlineHtml
            dangerouslySetInnerHTML={{
              __html:
                event?.venue?.checkInProcessNotes ||
                intl.formatMessage({ id: 'none' }),
            }}
          />
        </>
        <Spacer mt={4} />
        <>
          <Subheading>
            {intl.formatMessage({
              id: 'admin.shared.seating',
            })}
            :{' '}
          </Subheading>
          {event?.seating?.name || intl.formatMessage({ id: 'none' })}
        </>
        <Spacer mt={4} />
        <>
          <Subheading>
            {intl.formatMessage({
              id: 'admin.crewEvent.section.venueSetupInfo.seatingNotes',
            })}
            :{' '}
          </Subheading>
          <InlineHtml
            dangerouslySetInnerHTML={{
              __html: event?.seatingNotes || intl.formatMessage({ id: 'none' }),
            }}
          />
        </>
        <Spacer mt={4} />
        <>
          <Subheading>
            {intl.formatMessage({
              id: 'admin.crewEvent.section.venueSetupInfo.stageSetup',
            })}
            :{' '}
          </Subheading>
          <InlineHtml
            dangerouslySetInnerHTML={{
              __html:
                event?.venue?.stageSetupNotes ||
                intl.formatMessage({ id: 'none' }),
            }}
          />
        </>
        <Spacer mt={4} />
        <>
          <Subheading>
            {intl.formatMessage({
              id: 'admin.crewEvent.section.venueSetupInfo.merchTable',
            })}
            :{' '}
          </Subheading>
          <InlineHtml
            dangerouslySetInnerHTML={{
              __html:
                event?.venue?.merchTablePlacementNotes ||
                intl.formatMessage({ id: 'none' }),
            }}
          />
        </>
        <Spacer mt={4} />
        <>
          <Subheading>
            {intl.formatMessage({
              id: 'admin.crewEvent.section.venueSetupInfo.bathroomNotes',
            })}
            :{' '}
          </Subheading>
          <InlineHtml
            dangerouslySetInnerHTML={{
              __html:
                event?.venue?.bathroomNotes ||
                intl.formatMessage({ id: 'none' }),
            }}
          />
        </>
        <Spacer mt={4} />
        <>
          <Subheading>
            {intl.formatMessage({
              id: 'admin.crewEvent.section.venueSetupInfo.otherInternalNotes',
            })}
            :{' '}
          </Subheading>
          <InlineHtml
            dangerouslySetInnerHTML={{
              __html:
                event?.internalVenueNotes || intl.formatMessage({ id: 'none' }),
            }}
          />
        </>
      </SectionText>
      {venuePhotoModal.isShowing && (
        <RoutableModal
          hide={venuePhotoModal.hide}
          initialRouteProps={{
            venueName: event.venue?.venueName,
            venuePhotos: event.venue?.venuePhotos,
            timezone: event.city?.timezone,
          }}
          initialRouteName="venue-photos"
          dataQaidSuffix="admin-venue-photos"
        />
      )}
    </SectionWithIcon>
  );
};

export default CrewEventVenueSetupInfo;
