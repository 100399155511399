import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { ManualCSSColors } from 'app/shared/theme';
import { currencyRoundedMainUnitsFormatter } from 'app/shared/utils/currencyFormatter';
import usePermission from 'app/shared/utils/usePermission';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import { Overline } from 'app/shared/components/atoms/TypographyManualCSS';
import ProgressBar from 'app/admin/components/atoms/ProgressBar';
import { KeyValueInfoList } from 'app/admin/components/molecules/KeyValueInfoList';

interface GuestsProps {
  show?: boolean;
  vipAttendeeGuestsCount?: number;
  numTicketsAvailableForSale?: number;
  eventAttendeeGuestsConfirmedCount?: number;
  totalGuestsConfirmedCount?: number;
  revenue?: number;
  currencySymbol?: string;
  displayConfig: any;
  canEditEvent?: boolean;
  eventOrganizedAs?: string;
}

const TitleOverline = styled(Overline)`
  margin-bottom: 5px;
`;

const GrayedOutContainer = styled.div`
  color: gray;
`;

const ProgressBarContainer = styled.div<any>`
  ${({ display }) => css`
    visibility: ${display ? 'visible' : 'hidden'};
    margin-bottom: 8px;
    margin-top: 8px;
  `}
`;

const eventShadedColors = {
  mainBackground: '#E8E8E8',
  status: 'blueSmoke',
  artistVenueConfirmed: 'macyGrey',
  guestsProgressBarFilled: 'lightBlueSmoke',
  guestsProgressBarUnfilled: 'whiteDenim',
};

const EventGuests: React.FC<GuestsProps> = ({
  show = true,
  vipAttendeeGuestsCount,
  numTicketsAvailableForSale,
  eventAttendeeGuestsConfirmedCount,
  totalGuestsConfirmedCount,
  revenue,
  currencySymbol,
  displayConfig,
  canEditEvent,
  eventOrganizedAs,
}) => {
  const intl = useIntl();
  const hasEventListAccessPermission = usePermission('event.list.access');
  const displayHelper = (display: boolean, field: any) =>
    display ? field : intl.formatMessage({ id: 'admin.notApplicable' });
  if (!show) {
    return null;
  }

  const getRevenueField = () => {
    if (eventOrganizedAs === 'curator_owner_noncommercial_event') {
      return 'N/A';
    }
    if (!hasEventListAccessPermission && eventOrganizedAs === 'o_and_o') {
      return '--';
    }
    return revenue || revenue === 0
      ? `${currencyRoundedMainUnitsFormatter(revenue, currencySymbol || '$')}`
      : '--';
  };

  const keysAndValuesList = [
    {
      key: intl.formatMessage({
        id: 'admin.shared.ticketsAvailable',
      }),
      value: displayHelper(
        displayConfig.displayTicketsAvailable,
        numTicketsAvailableForSale ? numTicketsAvailableForSale.toString() : '0'
      ),
    },
    {
      key: intl.formatMessage({
        id: 'admin.eventPlanner.guests.confirmed',
      }),
      value: displayHelper(
        displayConfig.displayTicketsConfirmed,
        eventAttendeeGuestsConfirmedCount
          ? eventAttendeeGuestsConfirmedCount.toString()
          : '0'
      ),
    },
    {
      key: intl.formatMessage({ id: 'admin.eventPlanner.guests.vipAttendees' }),
      value: displayHelper(
        displayConfig.displayTotalGuests,
        vipAttendeeGuestsCount ? vipAttendeeGuestsCount.toString() : '0'
      ),
    },
    {
      key: intl.formatMessage({ id: 'admin.eventPlanner.guests.totalGuests' }),
      value: displayHelper(
        displayConfig.displayTotalGuests,
        totalGuestsConfirmedCount ? totalGuestsConfirmedCount.toString() : '0'
      ),
    },
    {
      key: intl.formatMessage({ id: 'admin.eventPlanner.guests.revenue' }),
      value: displayHelper(displayConfig.displayRevenue, getRevenueField()),
    },
  ];

  const allAttendeesCount =
    typeof totalGuestsConfirmedCount !== 'undefined' &&
    typeof numTicketsAvailableForSale !== 'undefined'
      ? Math.min(totalGuestsConfirmedCount, numTicketsAvailableForSale)
      : 0;

  const guestPercentage =
    typeof numTicketsAvailableForSale !== 'undefined'
      ? (allAttendeesCount / numTicketsAvailableForSale) * 100
      : 0;

  return (
    <div data-qaid="event-planner-card-guests">
      <TitleOverline>
        {intl.formatMessage({
          id: 'admin.eventPlanner.guests.label',
        })}
      </TitleOverline>
      {!displayConfig.displayVenue ? (
        <GrayedOutContainer>
          {intl.formatMessage({ id: 'admin.notApplicable' })}
        </GrayedOutContainer>
      ) : (
        <ProgressBarContainer
          display={(numTicketsAvailableForSale != 0).toString()}
          data-qaid="event-planner-card-guests-progress"
        >
          {canEditEvent ? (
            <ProgressBar percentage={guestPercentage} width="100%" />
          ) : (
            <ProgressBar
              percentage={guestPercentage}
              width="100%"
              color={
                eventShadedColors.guestsProgressBarFilled as ManualCSSColors
              }
              unfilledColor={
                eventShadedColors.guestsProgressBarUnfilled as ManualCSSColors
              }
            />
          )}
        </ProgressBarContainer>
      )}
      <KeyValueInfoList
        dataQaidPrefix="event-guests"
        keysAndValues={keysAndValuesList}
      />
      <DottedLine />
    </div>
  );
};

export default EventGuests;
