import React from 'react';
import styled from 'styled-components';

const FormContainer = styled.div`
  width: 100%;
`;

interface GenericFormContainerProps {
  dataQaId?: string;
  children: any;
}

const GenericFormContainer: React.FC<GenericFormContainerProps> = ({
  dataQaId,
  children,
}) => {
  return (
    <FormContainer data-qaid={`${dataQaId}-container`}>
      <form
        id={dataQaId}
        data-qaid={dataQaId}
        onSubmit={(e: any) => {
          e.preventDefault();
        }}
      >
        {children}
      </form>
    </FormContainer>
  );
};

export default GenericFormContainer;
