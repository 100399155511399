import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { get } from 'app/shared/utils/get';
import useModal from 'app/shared/utils/useModal';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import { AddIcon } from 'app/shared/components/atoms/IconLibrary';
import { LoadingBlocks } from 'app/shared/components/atoms/LoadingBlocks';
import TruncatedByCharText from 'app/shared/components/atoms/TruncatedByCharText';
import { H2 } from 'app/shared/components/atoms/TypographyManualCSS';
import ConfirmationModal from 'app/shared/components/molecules/ConfirmationModal';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { DeleteContinent } from 'app/admin/graphql/continents/mutationHooks';
import { GetContinents } from 'app/admin/graphql/continents/queryHooks';
import { DetailHeading as Heading } from 'app/admin/components/atoms/DetailContent';
import ListTable from 'app/admin/components/organisms/ListTable';

interface Props {
  navigateTo: (routeData: any) => void;
  hide: VoidFunction;
}

const MainContainer = styled.div`
  ${({ theme }) => css`
    padding: 10px 0px 0px 0px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: left;

    ${theme.media.md`
    padding: 20px 0px 0px 20px;
  `}
  `}
`;

const Title = styled(H2)`
  ${({ theme }) => css`
    font-size: 16px;
    font-weight: normal;
    line-height: 1.2em;
    margin-bottom: 25px;

    ${theme.media.xl`
    font-size: 34px;
    margin-bottom: 40px;
    margin-left: 0px;
  `};
  `}
`;

const ContinentHeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 20px;
  white-space: nowrap;
`;

const ContinentHeading = styled(Heading)`
  margin-bottom: 0;
`;

const AddIconWrapper = styled.div`
  margin-left: 10px;
  margin-top: -2px;
`;

const NoContinents = styled.div`
  white-space: nowrap;
  width: 100%;
`;

const Continents: React.FC<Props> = ({ navigateTo, hide }) => {
  const intl = useIntl();

  const [continentDeleteModal, toggleContinentDeleteModal] = useModal();
  const [continentData, setContinentData] = useState<any>(undefined);

  const {
    loading: loadingContinents,
    error: errorContinents,
    data: dataContinents,
    refetch: refetchContinents,
  } = GetContinents({
    fetchPolicy: 'cache-and-network',
  });

  const deleteContinentAction = DeleteContinent();

  const handleDeleteContinent = useSubmitAction({
    submitAction: deleteContinentAction,
    submitVariables: () => ({
      continentId: continentData && continentData.id,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.continentBasicInfo.deleteSuccess',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.continentBasicInfo.deleteFailure',
    }),
    onSuccess: () => {
      continentDeleteModal.hide();
      refetchContinents();
    },
  });

  if ((!loadingContinents && !dataContinents) || errorContinents) {
    hide();
    return null;
  }

  const columnsConfig = [
    {
      headerText: intl.formatMessage({
        id: 'admin.continents.continent',
      }),
      columnWidth: 210,
    },
    {
      headerText: intl.formatMessage({
        id: 'admin.continents.continentCode',
      }),
      columnWidth: 140,
    },
    {
      headerText: intl.formatMessage({
        id: 'admin.continents.countries',
      }),
      columnWidth: 100,
    },
  ];

  const continentsData = get(dataContinents, 'continents.continents', []);

  const rowValues = (continent: any) => {
    return [
      <TruncatedByCharText
        text={continent.title}
        truncateLength={27}
        key={continent.id}
      />,
      continent.continentCode,
      continent.countriesCount,
    ];
  };

  const toggleContinentCreateModal = () =>
    navigateTo({
      routeName: 'continent-create',
      routeProps: {},
    });

  const toggleContinentEditModal = (continentData: any) =>
    navigateTo({
      routeName: 'continent-edit',
      routeProps: {
        id: continentData.id,
        title: continentData.title,
      },
    });

  const toggleContinentDeleteAndSetContinentData = (continentData: any) => {
    setContinentData(continentData);
    toggleContinentDeleteModal();
  };

  return (
    <ModalContentContainer data-qaid="continents-modal">
      <MainContainer>
        <Title>
          {intl.formatMessage({
            id: 'admin.continents.editContinents',
          })}
        </Title>
        <ContinentHeadingContainer>
          <ContinentHeading>
            {intl.formatMessage(
              {
                id: 'admin.continents.continentsCount',
              },
              {
                count: get(dataContinents, 'continents.continents', []).length,
              }
            )}
          </ContinentHeading>
          <AddIconWrapper>
            <AddIcon iconSize={12} onClick={toggleContinentCreateModal} />
          </AddIconWrapper>
        </ContinentHeadingContainer>
        <ListTable
          columnsConfig={columnsConfig}
          rowValues={rowValues}
          onEdit={(obj: any) =>
            toggleContinentEditModal({
              id: obj.id,
              title: obj.title,
            })
          }
          onDelete={(obj: any) =>
            toggleContinentDeleteAndSetContinentData({
              id: obj.id,
              title: obj.title,
            })
          }
          showDelete={(obj: any) => obj.countriesCount == 0}
          data={continentsData}
          loadingContent={
            loadingContinents ? (
              <LoadingBlocks.Rectangle width="100%" height="300px" />
            ) : null
          }
          noResultsContent={
            !loadingContinents &&
            continentsData.length == 0 && (
              <NoContinents>
                {intl.formatMessage({
                  id: 'admin.continents.noContinents',
                })}
              </NoContinents>
            )
          }
        />
      </MainContainer>
      {continentDeleteModal.isShowing && (
        <ConfirmationModal
          onCancel={() => continentDeleteModal.hide()}
          description={intl.formatMessage(
            {
              id: 'admin.continentBasicInfo.deleteConfirmation',
            },
            {
              title: continentData && continentData.title,
            }
          )}
          onConfirm={handleDeleteContinent}
        />
      )}
    </ModalContentContainer>
  );
};

export default Continents;
