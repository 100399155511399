import React from 'react';
import styled, { css } from 'styled-components';

import { EventAttendee } from 'app/typings/eventAttendees';
import { currencyFormatter } from 'app/shared/utils/currencyFormatter';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import TruncatedByCharText from 'app/shared/components/atoms/TruncatedByCharText';
import { Overline } from 'app/shared/components/atoms/TypographyManualCSS';

const HeaderWrapper = styled.div`
  padding: 16px 16px 0px 16px;
  margin-bottom: 2px;
`;

const AttendeeName = styled.div`
  font-size: 14px;
  line-height: 21px;
  font-weight: 700;
  letter-spacing: 0.1px;
  white-space: normal;
  margin-top: 5px;
  margin-bottom: 4px;
`;

const AttendeeEmail = styled.div`
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  letter-spacing: 0.4px;
  font-weight: normal;
  white-space: normal;
  margin-bottom: 9px;
`;

const AttendeeExtraInfo = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.overline.xs};
  `}
  line-height: 1.6;
  font-weight: normal;
  letter-spacing: 1.5px;
  font-weight: normal;
  text-transform: uppercase;
  white-space: normal;
`;

const AttendeeTicketsCount = styled(AttendeeExtraInfo)`
  margin-top: 10px;
  margin-bottom: 2px;
`;

const AttendeeTotalPaid = styled(AttendeeExtraInfo)`
  margin-bottom: 17px;
`;

const TicketsCountValue = styled.span`
  padding-left: 4px;
`;

interface GuestlistAttendeeDetailsHeaderProps {
  eventAttendee: EventAttendee;
  currencySymbol?: string;
  displayExtraInfo?: boolean;
  intl?: any;
}

export const GuestlistAttendeeDetailsHeader: React.FC<GuestlistAttendeeDetailsHeaderProps> = ({
  eventAttendee,
  currencySymbol,
  displayExtraInfo,
  intl,
}) => {
  return (
    <HeaderWrapper>
      <Overline>
        {intl.formatMessage({
          id: 'admin.guestlist.attendeeDetails.currentGuest',
        })}
      </Overline>
      <AttendeeName>
        {eventAttendee.fullName ? (
          <TruncatedByCharText
            text={eventAttendee.fullName}
            truncateLength={30}
          />
        ) : (
          intl.formatMessage({
            id: 'unknown',
          })
        )}
      </AttendeeName>
      <AttendeeEmail>
        {eventAttendee.email ? (
          <TruncatedByCharText text={eventAttendee.email} truncateLength={35} />
        ) : (
          intl.formatMessage({
            id: 'unknown',
          })
        )}
      </AttendeeEmail>
      {displayExtraInfo ? (
        <>
          <AttendeeTicketsCount>
            {intl.formatMessage({
              id: 'admin.guestlist.attendeeDetails.ticketsCount',
            })}
            <TicketsCountValue>{eventAttendee.ticketsCount}</TicketsCountValue>
          </AttendeeTicketsCount>
          <AttendeeTotalPaid>
            {intl.formatMessage({
              id: 'admin.guestlist.attendeeDetails.totalPaid',
            })}
            <TicketsCountValue>
              {currencyFormatter(
                eventAttendee.totalTicketCost || 0,
                currencySymbol || ''
              )}
            </TicketsCountValue>
          </AttendeeTotalPaid>
        </>
      ) : (
        <Spacer mb={4} />
      )}
      <DottedLine />
    </HeaderWrapper>
  );
};
