export const getClosesAt = (closeDate: any, closeTime: string) => {
  const datePart = closeDate ? closeDate.format('YYYY-MM-DD') : '';
  const timePart = closeTime
    ? closeTime
        .replace(/.+T/, '')
        .replace(/\..+/, '')
        .replace(/:\d\d$/, '')
    : '';
  if (datePart && timePart) {
    return `${datePart} ${timePart}`;
  } else {
    return '';
  }
};
