import React from 'react';
import { FormikProps } from 'formik';

import GenericFormContainer from 'app/shared/components/atoms/GenericFormContainer';
import { RichTextEditorFormField } from 'app/admin/components/molecules/RichTextEditorFormField';

import { CachedSlugSection } from './CachedSlugSection';
import { CitiesSection } from './CitiesSection';
import { EventMerchandisingSection } from './EventMerchandisingSection';
import { EventsSection } from './EventsSection';
import { ImageSection } from './ImageSection';
import { StatusSection } from './StatusSection';
import { TitleSection } from './TitleSection';
import { FormFields } from './typings';
export * from './CollectionBasicInfoFormSchema';

interface FormProps {
  formikProps: FormikProps<FormFields>;
  contentProps: any;
  validationErrors?: object;
  isNewEventCollection?: boolean;
}

export const CollectionBasicInfoForm: React.FC<FormProps> = ({
  formikProps,
  contentProps,
  validationErrors,
  isNewEventCollection,
}) => {
  const isNewCollection = !contentProps?.id;

  return (
    <GenericFormContainer dataQaId="collection-about-section-edit-form">
      <TitleSection {...formikProps} />
      {!isNewCollection && (
        <CachedSlugSection
          validationErrors={validationErrors}
          {...formikProps}
        />
      )}
      <ImageSection
        titleIntlId="admin.collectionBasicInfo.form.image"
        descriptionIntlId="admin.collectionBasicInfo.form.imageDescription"
        isNewCollection={isNewCollection}
        images={contentProps.images}
      />
      <RichTextEditorFormField
        fieldName="description"
        fieldMaxCharacters={225}
        titleIntlId="admin.collectionBasicInfo.form.description"
        descriptionIntlId="admin.collectionBasicInfo.form.descriptiveTextDescription"
        labelIntlId="admin.collectionBasicInfo.form.descriptionLabel"
        dataQaidPrefix="collection-about-section-edit-form-description"
        enableLinks={true}
        {...formikProps}
      />
      {!isNewEventCollection && <EventMerchandisingSection {...formikProps} />}
      <RichTextEditorFormField
        fieldName="whatToExpect"
        fieldMaxCharacters={500}
        titleIntlId="admin.collectionBasicInfo.form.whatToExpect"
        descriptionIntlId="admin.collectionBasicInfo.form.whatToExpectDescription"
        labelIntlId="admin.collectionBasicInfo.form.whatToExpectLabel"
        dataQaidPrefix="collection-about-section-edit-form-whatToExpect"
        {...formikProps}
      />
      <EventsSection {...formikProps} />
      <CitiesSection {...formikProps} />
      {!isNewCollection && <StatusSection {...formikProps} />}
    </GenericFormContainer>
  );
};
