import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { User } from 'app/typings/users';
import { errorMsgForField } from 'app/shared/utils/form';
import { get } from 'app/shared/utils/get';
import { Checkbox } from 'app/shared/components/atoms/CheckboxManualCSS';
import {
  FormGroupContainer,
  FormHelpText,
} from 'app/shared/components/atoms/FormContent';
import { LoadingError } from 'app/shared/components/atoms/LoadingError';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Textfield } from 'app/shared/components/atoms/Textfield';
import FormGroup from 'app/shared/components/molecules/FormGroup';
import Select from 'app/shared/components/molecules/SelectManualCSS';
import { userFullNameOrEmail } from 'app/admin/utils/users';
import { GetUsersForFromEmailSettingsEdit } from 'app/admin/graphql/users/queryHooks';
import EditFormLoadingBlocks from 'app/admin/components/atoms/EditFormLoadingBlocks';

interface FromEmailSettingsEditFormProps {
  setFieldValue: any;
  touched: any;
  errors: any;
  values: any;
  cityCachedSlug?: string;
  curatorGroupId?: number;
  overrideHelpTextType?: string;
}

const StyledTextfield = styled(Textfield)`
  ${({ theme }) => css`
    display: block;
    width: 100%;
    ${theme.media.lg`
      width: 48%;
    `};
  `}
`;

const getUserLabel = (user: any) => userFullNameOrEmail(user) || '';

const resolveUserByUserId = (
  users: User[],
  userId: number | null
): User | undefined => users.find((user: User) => user.id === userId);

const FromEmailSettingsEditFormFields: React.FC<FromEmailSettingsEditFormProps> = ({
  setFieldValue,
  touched,
  errors,
  values,
  cityCachedSlug,
  curatorGroupId,
  overrideHelpTextType,
}) => {
  const intl = useIntl();

  const {
    loading: loadingUsers,
    error: errorUsers,
    data: dataUsers,
  } = GetUsersForFromEmailSettingsEdit({
    role: 'any_role',
    roleCity: cityCachedSlug,
    roleCuratorGroup: curatorGroupId ? String(curatorGroupId) : undefined,
    page: 1,
    perPage: 1000,
  });

  if (errorUsers) {
    return <LoadingError whatsBeingLoaded="this form" />;
  }

  if (loadingUsers || !dataUsers) {
    return <EditFormLoadingBlocks />;
  }

  const users = get(dataUsers, 'users.users', []);

  const overrideHelpTextTypesAndTextKeys = {
    event_o_and_o: 'admin.emailSection.form.overrideHelpTextForEventOAndO',
    event_curator: 'admin.emailSection.form.overrideHelpTextForEventCurator',
    curator_group: 'admin.emailSection.form.overrideHelpTextForCuratorGroup',
    city_o_and_o: 'admin.emailSection.form.overrideHelpTextForCityOAndO',
    city_curator: 'admin.emailSection.form.overrideHelpTextForCityCurator',
  };

  return (
    <>
      <FormGroupContainer>
        {overrideHelpTextType &&
          Object.keys(overrideHelpTextTypesAndTextKeys).includes(
            overrideHelpTextType
          ) && (
            <>
              <FormHelpText>
                {intl.formatMessage({
                  id: overrideHelpTextTypesAndTextKeys[overrideHelpTextType],
                })}
              </FormHelpText>
              <Spacer mb={3} />
            </>
          )}
        <FormGroup
          data-qaid="city-emails-form-from-email-formgroup"
          label={intl.formatMessage({
            id: 'admin.emailSection.form.fromEmail',
          })}
          errorMsg={errorMsgForField('fromEmail', touched, errors)}
        >
          <StyledTextfield
            data-qaid="city-emails-form-from-email-field"
            id="fromEmail"
            name="fromEmail"
            value={values.fromEmail}
            onChange={(e: any) => {
              setFieldValue('fromEmail', e.target.value);
            }}
            maxLength={100}
          />
        </FormGroup>
        <Spacer mb={4} />
        <Checkbox
          id="city-emails-section-edit-form-use-from-email-for-all-emails-field"
          data-qaid="city-emails-section-edit-form-use-from-email-for-all-emails-field"
          checked={values.useFromEmailForAllEmails}
          name="useFromEmailForAllEmails"
          onChange={e =>
            setFieldValue('useFromEmailForAllEmails', e.target.checked)
          }
        >
          {intl.formatMessage({
            id: 'admin.emailSection.form.useFromEmailForAllEmails',
          })}
        </Checkbox>
        {!values.useFromEmailForAllEmails && (
          <>
            <Spacer mb={8} />
            <FormGroup
              label={intl.formatMessage({
                id: 'admin.emailSection.form.artistOpsId',
              })}
              data-qaid="city-emails-form-artist-ops-id-formgroup"
              errorMsg={errorMsgForField('artistOpsId', touched, errors)}
            >
              <Select
                data-qaid="city-emails-form-artist-ops-id-field"
                searchable
                getOptionLabel={(opt: any) => getUserLabel(opt)}
                defaultValue={resolveUserByUserId(users, values.artistOpsId)}
                options={users}
                placeholder={intl.formatMessage({
                  id: 'admin.emailSection.form.selectUserPlaceholder',
                })}
                onChange={user =>
                  setFieldValue('artistOpsId', user && user.id ? user.id : null)
                }
                initialWidth="400px"
              />
            </FormGroup>
            <Spacer mb={6} />
            <FormGroup
              label={intl.formatMessage({
                id: 'admin.emailSection.form.venueOpsId',
              })}
              data-qaid="city-emails-form-venue-ops-id-formgroup"
              errorMsg={errorMsgForField('venueOpsId', touched, errors)}
            >
              <Select
                data-qaid="city-emails-form-venue-ops-id-field"
                searchable
                getOptionLabel={(opt: any) => getUserLabel(opt)}
                defaultValue={resolveUserByUserId(users, values.venueOpsId)}
                options={users}
                placeholder={intl.formatMessage({
                  id: 'admin.emailSection.form.selectUserPlaceholder',
                })}
                onChange={user =>
                  setFieldValue('venueOpsId', user && user.id ? user.id : null)
                }
                initialWidth="400px"
              />
            </FormGroup>
            <Spacer mb={6} />
            <FormGroup
              label={intl.formatMessage({
                id: 'admin.emailSection.form.crewOpsId',
              })}
              data-qaid="city-emails-form-crew-ops-id-formgroup"
              errorMsg={errorMsgForField('crewOpsId', touched, errors)}
            >
              <Select
                data-qaid="city-emails-form-crew-ops-id-field"
                searchable
                getOptionLabel={(opt: any) => getUserLabel(opt)}
                defaultValue={resolveUserByUserId(users, values.crewOpsId)}
                options={users}
                placeholder={intl.formatMessage({
                  id: 'admin.emailSection.form.selectUserPlaceholder',
                })}
                onChange={user =>
                  setFieldValue('crewOpsId', user && user.id ? user.id : null)
                }
                initialWidth="400px"
              />
            </FormGroup>
          </>
        )}
      </FormGroupContainer>
    </>
  );
};

export default FromEmailSettingsEditFormFields;
