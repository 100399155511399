import React from 'react';
import { useIntl } from 'react-intl';

import { errorMsgForField } from 'app/shared/utils/form';
import { FormGroupContainer } from 'app/shared/components/atoms/FormContent';
import FormGroup from 'app/shared/components/molecules/FormGroup';
import RadioGroup from 'app/shared/components/molecules/RadioGroup';

import { CollectionBasicInfoFormSectionHeader } from './components';
import { CollectionBasicInfoFormikProps } from './typings';

export const StatusSection: React.FC<CollectionBasicInfoFormikProps> = ({
  values,
  initialValues,
  setFieldValue,
  touched,
  setTouched,
  errors,
}) => {
  const { formatMessage } = useIntl();

  const statusOptions = [
    {
      value: 'active',
      title: formatMessage({
        id: 'admin.collectionBasicInfo.form.statusActive',
      }),
    },
    {
      value: 'inactive',
      title: formatMessage({
        id: 'admin.collectionBasicInfo.form.statusInactive',
      }),
    },
    {
      value: 'archived',
      title: formatMessage({
        id: 'admin.collectionBasicInfo.form.statusArchived',
      }),
    },
  ];

  return (
    <>
      <CollectionBasicInfoFormSectionHeader
        titleIntlId="admin.collectionBasicInfo.form.status"
        descriptionIntlId="admin.collectionBasicInfo.form.statusDescription"
      />
      <FormGroupContainer>
        <FormGroup
          data-qaid="collection-about-section-edit-form-status-formgroup"
          label={formatMessage({
            id: 'admin.collectionBasicInfo.form.statusLabel',
          })}
          errorMsg={errorMsgForField('status', touched, errors)}
          required
        >
          <RadioGroup
            name="status"
            options={statusOptions}
            selectedValue={values.status}
            onChange={(value: any) => {
              setTouched({ ...touched, status: true });
              setFieldValue('status', value);
              if (value !== initialValues.status) {
                if (initialValues.status === 'active') {
                  setFieldValue('cityDisplayType', 'no_cities');
                } else if (value === 'active') {
                  setFieldValue('cityDisplayType', 'all_cities');
                }
              }
            }}
          />
        </FormGroup>
      </FormGroupContainer>
    </>
  );
};
