import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { extractContactInfoInitialValuesByPosition } from 'app/shared/utils/contactInfos';
import { get } from 'app/shared/utils/get';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import { UpdateArtist } from 'app/shared/graphql/artists/mutationHooks';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { DetailsFormMainContainer } from 'app/admin/components/atoms/DetailContent';
import { SIGNED_STATUSES } from 'app/admin/components/organisms/ArtistDetails/SectionBasicInfo';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

import ArtistBasicInfoForm from './ArtistBasicInfoForm';
import ArtistBasicInfoFormSchema from './ArtistBasicInfoFormSchema';

interface Props {
  contentProps?: any;
  setFormSubmitAction: (func: Function) => void;
  setDisplayConfirmation: (set: boolean) => void;
  setIsSubmitting: (set: boolean) => void;
  navigateTo: (routeData: object) => void;
}

const ArtistBasicInfoEdit: React.FC<Props> = ({
  contentProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  navigateTo,
}) => {
  const intl = useIntl();

  const [validationErrors, setValidationErrors] = useState<object>({});

  const signedStatusValue = get(contentProps, 'signedStatus', 'unknown');
  const contactInfos = get(contentProps, 'contactInfos', []);

  const formInitialValues = {
    title: get(contentProps, 'title', ''),
    homeCityDescription: get(contentProps, 'homeCityLatitude', undefined)
      ? get(contentProps, 'homeCityDescription', '')
      : '',
    homeCity: get(contentProps, 'homeCity', ''),
    homeState: get(contentProps, 'homeState', ''),
    homeCountryCode: get(contentProps, 'homeCountryCode', ''),
    homeCityLatitude: get(contentProps, 'homeCityLatitude', undefined),
    homeCityLongitude: get(contentProps, 'homeCityLongitude', undefined),
    closestSofarCityId: get(contentProps, 'closestSofarCityId', undefined),
    description: get(contentProps, 'description', ''),
    tags: get(contentProps, 'tags', []).map((e: any) => e.id),
    initialTags: get(contentProps, 'tags', []).map((e: any) => e.id),
    eligibilities: get(contentProps, 'artistEligibilities', []).map(
      (e: any) => e.key
    ),
    bookingStatus: [
      get(contentProps, 'shouldNotBook', false) ? 'should_not_book' : null,
      get(contentProps, 'isSelfBookingEnabled', false)
        ? 'is_self_booking_enabled'
        : null,
    ].filter(n => n),
    needsReview: get(contentProps, 'needsReview', false),
    performingRightsOrganisation: {
      value: get(contentProps, 'performingRightsOrganisation.id', undefined),
      label: get(contentProps, 'performingRightsOrganisation.title', undefined),
    },
    contactInfos: {
      primaryContactInfo: extractContactInfoInitialValuesByPosition(
        contactInfos,
        1
      ),
      secondaryContactInfo: extractContactInfoInitialValuesByPosition(
        contactInfos,
        2
      ),
      tertiaryContactInfo: extractContactInfoInitialValuesByPosition(
        contactInfos,
        3
      ),
    },
    signedStatus: {
      value: signedStatusValue,
      label: SIGNED_STATUSES[signedStatusValue],
    },
    userIdToAssociate: undefined,
    userIdsToUnassociate: [],
  };

  const updateArtistAction = UpdateArtist();

  const handleUpdateArtist = useSubmitAction({
    submitAction: updateArtistAction,
    submitVariables: (values: any) => ({
      artistId: get(contentProps, 'id', undefined),
      title: values.title,
      homeCityDescription: values.homeCityDescription,
      homeCity: values.homeCity,
      homeState: values.homeState,
      homeCountryCode: values.homeCountryCode,
      homeCityLatitude: values.homeCityLatitude,
      homeCityLongitude: values.homeCityLongitude,
      closestSofarCityId: values.closestSofarCityId,
      description: values.description,
      processTags: true,
      tags: values.tags,
      processEligibilities: true,
      eligibilities: values.eligibilities,
      isSelfBookingEnabled: values.bookingStatus.includes(
        'is_self_booking_enabled'
      ),
      needsReview: values.needsReview,
      shouldNotBook: values.bookingStatus.includes('should_not_book'),
      performingRightsOrganisationId: get(
        values,
        'performingRightsOrganisation.value',
        undefined
      ),
      contactInfos: Object.values(get(values, 'contactInfos', {})),
      signedStatus: get(values, 'signedStatus.value', undefined),
      userIdToAssociate: values.userIdToAssociate,
      userIdsToUnassociate:
        values.userIdsToUnassociate.length > 0
          ? values.userIdsToUnassociate
          : undefined,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.artistBasicInfo.form.successMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.artistBasicInfo.form.failureMessage',
    }),
    onSuccess: (response: any) => {
      navigateTo({
        routeName: 'artist-details',
        routeProps: {
          defaultOpenSection: 'basicInfo',
          ...response.data.updateArtist.artist,
        },
      });
    },
    onValidationError: validationErrors =>
      setValidationErrors(validationErrors),
    validationErrorKeysForDisplay: { artist_id: 'artist' },
  });

  return (
    <ModalContentContainer>
      <DetailsFormMainContainer>
        <DetailsHeader
          title={get(contentProps, 'title', '')}
          imageUrl={get(contentProps, 'imageUrl', '')}
          largeImageUrl={get(contentProps, 'largeImageUrl', '')}
          imageStyle="circle"
        />
        <GenericForm
          formInitialValues={formInitialValues}
          renderFormComponent={(renderProps: any) => (
            <ArtistBasicInfoForm
              formikProps={renderProps.formikProps}
              validationErrors={validationErrors}
              numContactInfos={get(contentProps, 'contactInfos', []).length}
              associatedUsers={get(contentProps, 'associatedUsers', [])}
              needsReview={get(contentProps, 'needsReview', false)}
            />
          )}
          onSubmit={handleUpdateArtist}
          formSchema={ArtistBasicInfoFormSchema(intl)}
          setFormSubmitAction={setFormSubmitAction}
          setDisplayConfirmation={setDisplayConfirmation}
          setIsSubmitting={setIsSubmitting}
          dataQaId="artist-about-section-edit-form"
        />
      </DetailsFormMainContainer>
    </ModalContentContainer>
  );
};

export default ArtistBasicInfoEdit;
