import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { errorMsgForField } from 'app/shared/utils/form';
import { get } from 'app/shared/utils/get';
import usePermission from 'app/shared/utils/usePermission';
import { GetProperties } from 'app/shared/graphql/properties/queryHooks';
import {
  FormGroupContainer,
  FormSectionTitle,
} from 'app/shared/components/atoms/FormContent';
import GenericFormContainer from 'app/shared/components/atoms/GenericFormContainer';
import { BaseLink } from 'app/shared/components/atoms/LinkManualCSS';
import { LoadingError } from 'app/shared/components/atoms/LoadingError';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import FormGroup from 'app/shared/components/molecules/FormGroup';
import HelpIconAndFlyover from 'app/shared/components/molecules/HelpIconAndFlyover';
import RadioGroup from 'app/shared/components/molecules/RadioGroup';
import Select from 'app/shared/components/molecules/SelectManualCSS';
import { SEATINGS } from 'app/admin/utils/events';
import {
  getMaxPaInputsOptions,
  getPropertyOptions,
  getSeatingOptions,
} from 'app/admin/utils/optionLists';
import EditFormLoadingBlocks from 'app/admin/components/atoms/EditFormLoadingBlocks';
import CheckboxGroupGrouped from 'app/admin/components/molecules/CheckboxGroupGrouped';
import RichTextEditor from 'app/admin/components/molecules/RichTextEditor';

const StyledLink = styled(BaseLink)`
  ${({ theme }) => css`
    color: ${theme.colors.green600};
  `}
`;

const FormGroupContainerMessage = styled.div`
  ${({ theme }) => css`
    padding-bottom: 20px;
    color: ${theme.colors.darkSmoke};
  `}
`;

const MaxPaInputsHelpIconAndFlyover: React.FC = () => {
  const intl = useIntl();

  return (
    <>
      <Spacer ml={3} />
      <HelpIconAndFlyover
        title={intl.formatMessage({
          id: 'admin.eventVenueInfo.changingPa',
        })}
        bodyId="admin.eventVenueInfo.changingPaDescription1"
        bodyId2="admin.eventVenueInfo.changingPaDescription2"
        bodyId3="admin.eventVenueInfo.changingPaDescription3"
        bodyId4="admin.eventVenueInfo.changingPaDescription4"
        bodyId5="admin.eventVenueInfo.changingPaDescription5"
      />
    </>
  );
};

interface FormProps {
  formikProps: any;
  venueId?: number;
  eventType?: string;
  eventOrganizedAs?: string;
}

const EventDetailsVenueEditForm: React.FC<FormProps> = ({
  formikProps,
  venueId,
  eventType,
  eventOrganizedAs,
}) => {
  const intl = useIntl();

  const hasViewVenuesPermission = usePermission('venue.list.view');

  const {
    loading: loadingProperties,
    error: errorProperties,
    data: dataProperties,
  } = GetProperties({
    propertyContext: eventType,
  });

  const { setFieldValue, values, errors, touched } = formikProps;

  const maxPaInputsOptions = getMaxPaInputsOptions(intl);

  const maxPaInputsOptionsMap = Object.fromEntries(
    maxPaInputsOptions.map(opt => [opt.value, opt])
  );

  const propertyOptionDependencies = {
    // This option -> is depended on by this list of options
    commercial: ['residential'],
    residential: ['commercial'],
    accessible: ['not_accessible'],
    not_accessible: ['accessible'],
    alcohol_not_permitted_or_sold: ['byob', 'alcohol_for_sale', 'no_red_wine'],
    smoking_permitted: ['smoking_not_permitted'],
    smoking_not_permitted: ['smoking_permitted'],
    free_public_parking_nearby: ['no_parking'],
    paid_parking: ['no_parking'],
    no_parking: ['free_public_parking_nearby', 'paid_parking'],
    over_25_only: ['over_18_only', 'over_21_only', 'over_23_only'],
    over_23_only: ['over_18_only', 'over_21_only', 'over_25_only'],
    over_21_only: ['over_18_only', 'over_23_only', 'over_25_only'],
    over_18_only: ['over_21_only', 'over_23_only', 'over_25_only'],
    face_mask_required: ['face_mask_not_required'],
    face_mask_not_required: ['face_mask_required'],
  };

  if (errorProperties) {
    return <LoadingError whatsBeingLoaded="this form" />;
  }

  if (loadingProperties || !dataProperties) {
    return <EditFormLoadingBlocks />;
  }

  return (
    <GenericFormContainer dataQaId="venue-about-section-edit-form">
      {hasViewVenuesPermission && (
        <>
          <FormSectionTitle>
            {intl.formatMessage({
              id: 'admin.eventVenueInfo.venueDetails',
            })}
          </FormSectionTitle>
          <FormGroupContainer>
            <StyledLink
              href={`/admin/venues?venue_id=${venueId}`}
              openInNewTab={true}
            >
              {intl.formatMessage({
                id: 'admin.eventVenueInfo.editInVenueDirectory',
              })}
            </StyledLink>
          </FormGroupContainer>
        </>
      )}
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.eventVenueInfo.venueCharacteristics',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroupContainerMessage>
          {intl.formatMessage({
            id: 'admin.eventVenueInfo.venueCharacteristicsWarning',
          })}
        </FormGroupContainerMessage>
        <CheckboxGroupGrouped
          name="properties"
          options={getPropertyOptions(dataProperties, true)}
          optionDependencies={propertyOptionDependencies}
          selectedValues={values.properties || []}
          onChange={(values: any) => setFieldValue('properties', values)}
          groupBy="propertyGroupName"
          groupNames={get(dataProperties, 'properties.propertyGroupNames', [])}
        />
      </FormGroupContainer>
      <>
        <FormSectionTitle>
          {intl.formatMessage({
            id: 'admin.shared.suppliesNeeded',
          })}
        </FormSectionTitle>
        <FormGroupContainer>
          <FormGroup
            data-qaid="supplies-needed-notes-formgroup"
            label={intl.formatMessage({
              id: 'admin.shared.suppliesNeeded',
            })}
            errorMsg={errorMsgForField('suppliesNeededNotes', touched, errors)}
          >
            <RichTextEditor
              name="supplies-needed-notes-editor"
              currentValue={values.suppliesNeededNotes}
              onChange={(value: any) =>
                setFieldValue('suppliesNeededNotes', value)
              }
            />
          </FormGroup>
        </FormGroupContainer>
      </>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.shared.fanExperience',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="event-fan-experience-form-external-venue-notes-formgroup"
          label={intl.formatMessage({
            id: 'admin.shared.fanFacingDescription',
          })}
          errorMsg={errorMsgForField('externalVenueNotes', touched, errors)}
        >
          <RichTextEditor
            name="externalVenueNotes"
            currentValue={values.externalVenueNotes}
            onChange={value => {
              setFieldValue('externalVenueNotes', value);
            }}
          />
        </FormGroup>
        <Spacer mb={8} />
        <FormGroup
          data-qaid="event-fan-experience-form-invite-email-message-formgroup"
          label={intl.formatMessage({
            id: 'admin.shared.inviteEmail',
          })}
          errorMsg={errorMsgForField('inviteEmailMessage', touched, errors)}
        >
          <RichTextEditor
            name="inviteEmailMessage"
            currentValue={values.inviteEmailMessage}
            onChange={value => {
              setFieldValue('inviteEmailMessage', value);
            }}
          />
        </FormGroup>
        <Spacer mb={8} />
        <FormGroup
          data-qaid="event-fan-experience-form-reveal-address-email-message-formgroup"
          label={intl.formatMessage({
            id: 'admin.shared.addressRevealEmail',
          })}
          errorMsg={errorMsgForField(
            'revealAddressEmailMessage',
            touched,
            errors
          )}
        >
          <RichTextEditor
            name="revealAddressEmailMessage"
            currentValue={values.revealAddressEmailMessage}
            onChange={value => {
              setFieldValue('revealAddressEmailMessage', value);
            }}
          />
        </FormGroup>
        <Spacer mb={8} />
        <FormGroup
          data-qaid="event-fan-experience-form-seating-formgroup"
          label={intl.formatMessage({
            id: 'admin.shared.seating',
          })}
          errorMsg={errorMsgForField('seating', touched, errors)}
        >
          <RadioGroup
            name="seating"
            options={getSeatingOptions(SEATINGS)}
            selectedValue={values.seating}
            onChange={value => setFieldValue('seating', value)}
          />
        </FormGroup>
        <Spacer mb={8} />
        <FormGroup
          data-qaid="internal-seating-notes-formgroup"
          label={intl.formatMessage({
            id: 'admin.shared.internalSeatingNotes',
          })}
          errorMsg={errorMsgForField('seatingNotes', touched, errors)}
        >
          <RichTextEditor
            name="internal-seating-notes-editor"
            currentValue={values.seatingNotes}
            onChange={(value: any) => setFieldValue('seatingNotes', value)}
          />
        </FormGroup>
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.shared.notesForMc',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="mc-notes-formgroup"
          label={intl.formatMessage({
            id: 'admin.shared.notesForMc',
          })}
          errorMsg={errorMsgForField('mcNotes', touched, errors)}
        >
          <RichTextEditor
            name="mc-notes-editor"
            currentValue={values.mcNotes}
            onChange={(value: any) => setFieldValue('mcNotes', value)}
          />
        </FormGroup>
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.shared.paRequired',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="max-pa-inputs-formgroup"
          label={intl.formatMessage({
            id: 'admin.shared.typeOfPa',
          })}
          errorMsg={errorMsgForField('maxPaInputs', touched, errors)}
          renderIcon={() =>
            eventOrganizedAs == 'o_and_o' ? (
              <MaxPaInputsHelpIconAndFlyover />
            ) : (
              undefined
            )
          }
        >
          <Select
            onChange={(opt: any) => setFieldValue('maxPaInputs', opt.value)}
            placeholder={intl.formatMessage({
              id: 'admin.shared.selectPa',
            })}
            options={maxPaInputsOptions}
            getOptionLabel={(opt: any) => opt.title}
            value={maxPaInputsOptionsMap[values.maxPaInputs]}
            initialWidth="150px"
          />
        </FormGroup>
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.shared.audioNotes',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="audio-notes-formgroup"
          label={intl.formatMessage({
            id: 'admin.shared.audioNotes',
          })}
          errorMsg={errorMsgForField('audioNotes', touched, errors)}
        >
          <RichTextEditor
            name="audio-notes-editor"
            currentValue={values.audioNotes}
            onChange={(value: any) => setFieldValue('audioNotes', value)}
          />
        </FormGroup>
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.eventVenueInfo.otherInternalNotes',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroupContainerMessage>
          {intl.formatMessage({
            id: 'admin.eventVenueInfo.internalVenueNotesWarning',
          })}
        </FormGroupContainerMessage>
        <FormGroup
          data-qaid="internal-venue-notes-formgroup"
          label={intl.formatMessage({
            id: 'admin.eventVenueInfo.internalVenueNotes',
          })}
          errorMsg={errorMsgForField('internalVenueNotes', touched, errors)}
        >
          <RichTextEditor
            name="internal-venue-notes-editor"
            currentValue={values.internalVenueNotes}
            onChange={(value: any) =>
              setFieldValue('internalVenueNotes', value)
            }
          />
        </FormGroup>
      </FormGroupContainer>
    </GenericFormContainer>
  );
};

export default EventDetailsVenueEditForm;
