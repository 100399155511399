import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { get } from 'app/shared/utils/get';
import { LoadingError } from 'app/shared/components/atoms/LoadingError';
import { Spinner } from 'app/shared/components/atoms/SpinnerManualCSS';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { GetEmailMessage } from 'app/admin/graphql/emailMessages/queryHooks';

interface Props {
  contentProps?: any;
}

const MainContainer = styled.div`
  padding: 20px 0;
`;

const LoadingIndicatorContainer = styled.div`
  text-align: center;
  padding: 250px 30px 60px 30px;
  width: 100%;
`;

const LoadingIndicator: React.FC = () => {
  return (
    <LoadingIndicatorContainer>
      <Spinner />
    </LoadingIndicatorContainer>
  );
};

const EmailMessageContent: React.FC<Props> = ({ contentProps }) => {
  const intl = useIntl();

  const { id } = contentProps;

  const { loading, error, data } = GetEmailMessage({
    id,
  });

  if (loading) {
    return <LoadingIndicator />;
  }

  if ((!loading && !data) || error) {
    return (
      <LoadingError
        whatsBeingLoaded={intl.formatMessage({
          id: 'admin.emailMessages.emailContent.thisEmail',
        })}
      />
    );
  }

  const content = get(data, 'emailMessage.content', '');

  return (
    <ModalContentContainer
      data-qaid="email-message-content-modal"
      padding="30px 0"
    >
      <MainContainer
        dangerouslySetInnerHTML={{ __html: content }}
      ></MainContainer>
    </ModalContentContainer>
  );
};

export default EmailMessageContent;
