import * as Yup from 'yup';

import { validateUrlNoHttp } from 'app/shared/utils/urls';
import { isHour2AfterHour1 } from 'app/admin/utils/datetime';
import { maxEventPriceMainUnits } from 'app/admin/utils/events';
import {
  eventMerchandisingDescriptionMaxLength,
  eventMerchandisingHeadlineMaxLength,
} from 'app/admin/utils/events';
import { getNotesFormSchema } from 'app/admin/utils/string';

const EventBasicInfoEditFormSchema = (intl: any, venueId?: number) =>
  Yup.object().shape({
    localCrewLoadInAt: Yup.string()
      .nullable()
      .required(
        intl.formatMessage({
          id: 'form.requiredField',
        })
      ),
    localArtistLoadInAt: Yup.string()
      .nullable()
      .required(
        intl.formatMessage({
          id: 'form.requiredField',
        })
      ),
    localGuestsArriveAt: Yup.string()
      .nullable()
      .required(
        intl.formatMessage({
          id: 'form.requiredField',
        })
      ),
    localStartsAt: Yup.string()
      .nullable()
      .required(
        intl.formatMessage({
          id: 'form.requiredField',
        })
      )
      .test(
        'less-than-local-ends-at',
        intl.formatMessage({
          id: 'admin.eventBasicInfo.startsAtLaterThanEndsAtError',
        }),
        function(value: string) {
          const localEndsAt = this.parent.localEndsAt;
          if (!localEndsAt) {
            return true;
          }
          if (value) {
            return isHour2AfterHour1(value, localEndsAt);
          }
          return false;
        }
      ),
    localEndsAt: Yup.string()
      .test(
        'greater-than-local-starts-at',
        intl.formatMessage({
          id: 'admin.eventBasicInfo.endsAtEarlierThanStartsAtError',
        }),
        function(value: string) {
          const localStartsAt = this.parent.localStartsAt;
          if (!value || !localStartsAt) {
            return true;
          }
          return isHour2AfterHour1(localStartsAt, value);
        }
      )
      .nullable(),
    numTicketsAvailableForSale: Yup.number()
      .required(
        intl.formatMessage({
          id: 'form.requiredField',
        })
      )
      .typeError(
        intl.formatMessage({
          id: 'form.pleaseEnterANumber',
        })
      )
      .min(0)
      .test(
        'greater-than-capacity',
        intl.formatMessage({
          id: 'admin.eventBasicInfo.tafsGreaterThanCapacityError',
        }),
        function(value: number) {
          if (!venueId) {
            return true;
          }
          const capacity = this.parent.capacity;
          return value <= capacity;
        }
      )
      .nullable(),
    ticketPrice: Yup.string()
      .nullable()
      .matches(/^\d+$/, {
        message: intl.formatMessage({
          id: 'admin.eventBasicInfo.form.numberPriceError',
        }),
      })
      .test(
        'ticket-price-too-large',
        intl.formatMessage(
          {
            id: 'admin.eventBasicInfo.form.numberPriceTooLargeError',
          },
          {
            maxPrice: String(maxEventPriceMainUnits),
          }
        ),
        function(value: string) {
          return (
            (!value && Number(value) != 0) ||
            Number(value) <= maxEventPriceMainUnits
          );
        }
      ),
    eventFormatExperimentId: Yup.number()
      .nullable()
      .test(
        'experiment-not-selected',
        intl.formatMessage({
          id: 'admin.eventBasicInfo.form.experimentNotSelectedError',
        }),
        function(value?: number) {
          return !(this.parent.isEventFormatExperiment && !value);
        }
      ),
    eventArtistCompensation: Yup.string()
      .nullable()
      .trim()
      .matches(/^\d+\.\d\d$/, {
        message: intl.formatMessage({
          id: 'admin.eventBasicInfo.form.artistCompensationError',
        }),
      }),
    specialEventUrl: Yup.string()
      .nullable()
      .when('isSpecialEvent', {
        is: true,
        then: Yup.string().required(
          intl.formatMessage({
            id: 'form.requiredField',
          })
        ),
      })
      .test(
        'isUrl',
        intl.formatMessage({
          id: 'admin.eventBasicInfo.urlValidationNoHttp',
        }),
        function(value) {
          return value ? validateUrlNoHttp(value) : true;
        }
      ),
    headline: Yup.string()
      .nullable()
      .max(eventMerchandisingHeadlineMaxLength),
    externalEventNotes: Yup.string()
      .nullable()
      .max(eventMerchandisingDescriptionMaxLength),
    timingNotes: getNotesFormSchema(intl),
  });

export default EventBasicInfoEditFormSchema;
