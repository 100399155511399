import React from 'react';
import styled, { css } from 'styled-components';

import { AddIcon } from 'app/shared/components/atoms/IconLibrary';

interface Props {
  text: string;
  textSizeType?: string;
  onClick?: VoidFunction;
  dataQaid?: string;
  sentenceCaseText?: boolean;
}

const MainContainer = styled.div`
  ${({ theme }) => css`
    font-size: 16px;
    color: ${theme.colors.green600};
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
  `}
`;

const IconContainer = styled.div`
  margin-top: 4px;
  margin-right: 9px;
`;

interface TextContainerProps {
  textSizeType?: string;
  sentenceCaseText?: boolean;
}

const TextContainer = styled.div<TextContainerProps>`
  ${props => `
    font-size: ${
      props.textSizeType == 'large'
        ? '16px'
        : props.textSizeType == 'small'
        ? '10px'
        : '14px'
    };
    color: ${props.theme.colors.green600};
    line-height: 150%;
    letter-spacing: ${props.sentenceCaseText ? 'normal' : '1.5px'};
    text-transform: ${props.sentenceCaseText ? 'normal' : 'uppercase'};
    margin-top: 2px;
    margin-right: 5px;
  `}
`;

const AddIconAndText: React.FC<Props> = ({
  text,
  textSizeType = 'medium',
  onClick,
  dataQaid = 'AddIconAndText',
  sentenceCaseText,
}) => {
  return (
    <>
      {onClick ? (
        <MainContainer data-qaid={dataQaid} onClick={onClick}>
          <IconContainer>
            <AddIcon />
          </IconContainer>
          <TextContainer
            textSizeType={textSizeType}
            sentenceCaseText={sentenceCaseText}
          >
            {text}
          </TextContainer>
        </MainContainer>
      ) : (
        <MainContainer data-qaid={dataQaid}>
          <IconContainer>
            <AddIcon />
          </IconContainer>
          <TextContainer
            textSizeType={textSizeType}
            sentenceCaseText={sentenceCaseText}
          >
            {text}
          </TextContainer>
        </MainContainer>
      )}
    </>
  );
};

export default AddIconAndText;
