import React from 'react';
import styled from 'styled-components';

import Pagination from 'app/shared/components/molecules/Pagination';

interface Props {
  numTotalRecords: number | undefined;
  perPage: number;
  currentPage: number;
  onPageChange: (p: number) => void;
  loading: boolean;
  dataQaidPrefix?: string;
}

const RightAlign = styled.div`
  text-align: right;
`;

const PaginationContainer = styled.div`
  width: auto;
  display: inline-block;
`;

const ListingFooter: React.FC<Props> = ({
  numTotalRecords,
  perPage,
  currentPage,
  onPageChange,
  loading,
  dataQaidPrefix,
}) => {
  const handlePageChange = (p: number) => {
    onPageChange(p);
    window.scrollTo({ top: 0, behavior: 'auto' });
  };

  return (
    <RightAlign>
      <PaginationContainer data-qaid="pagination-container">
        {!loading && numTotalRecords && numTotalRecords > perPage ? (
          <Pagination
            data-qaid={`${dataQaidPrefix}-list-pagination`}
            totalRecords={numTotalRecords}
            perPage={perPage}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        ) : null}
      </PaginationContainer>
    </RightAlign>
  );
};

export default ListingFooter;
