import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { CreateCompetitionTemplate } from 'app/admin/graphql/competitionTemplates/mutationHooks';
import { DetailsFormMainContainer } from 'app/admin/components/atoms/DetailContent';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

import CompetitionTemplateCreateForm from './CompetitionTemplateCreateForm';
import CompetitionTemplateCreateFormSchema from './CompetitionTemplateCreateFormSchema';

interface Props {
  setFormSubmitAction?: (func: Function) => void;
  setDisplayConfirmation?: (set: boolean) => void;
  setIsSubmitting?: (set: boolean) => void;
  navigateTo: (routeData: object) => void;
}

const CompetitionTemplateCreate: React.FC<Props> = ({
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  navigateTo,
}) => {
  const intl = useIntl();

  const [validationErrors, setValidationErrors] = useState<object>({});

  const formInitialValues = {
    templateName: '',
  };

  const createCompetitionTemplateAction = CreateCompetitionTemplate();

  const handleCreateCompetitionTemplate = useSubmitAction({
    submitAction: createCompetitionTemplateAction,
    submitVariables: (values: any) => ({
      templateName: values.templateName,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.competitionTemplateCreate.successMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.competitionTemplateCreate.failureMessage',
    }),
    onSuccess: () => {
      navigateTo({
        routeName: 'competition-templates',
        routeProps: {},
      });
    },
    onValidationError: validationErrors =>
      setValidationErrors(validationErrors),
    validationErrorKeysForDisplay: { template_name: 'template name' },
  });

  return (
    <ModalContentContainer>
      <DetailsFormMainContainer>
        <DetailsHeader
          title={intl.formatMessage({
            id: 'admin.competitionTemplateCreate.title',
          })}
          isNewForm={true}
        />
        <GenericForm
          formInitialValues={formInitialValues}
          renderFormComponent={(renderProps: any) => (
            <CompetitionTemplateCreateForm
              formikProps={renderProps.formikProps}
              validationErrors={validationErrors}
            />
          )}
          onSubmit={handleCreateCompetitionTemplate}
          formSchema={CompetitionTemplateCreateFormSchema(intl)}
          setFormSubmitAction={setFormSubmitAction}
          setDisplayConfirmation={setDisplayConfirmation}
          setIsSubmitting={setIsSubmitting}
          dataQaId="competition-template-create-form"
        />
      </DetailsFormMainContainer>
    </ModalContentContainer>
  );
};

export default CompetitionTemplateCreate;
