/* eslint-disable no-useless-escape */
import * as Yup from 'yup';

const validYoutubeUrlBase1 = 'https://youtu.be/';
// eslint-disable-next-line
const validYoutubeUrlBase2 = 'https://www.youtube.com/watch?v=';

const validYoutubeUrlBaseRegex = new RegExp(
  `^(${validYoutubeUrlBase1}|${validYoutubeUrlBase2.replace(/\?/, '\\?')}).*$`
);

const artistVideoEditFormSchema = (intl: any) =>
  Yup.object().shape({
    title: Yup.string()
      .trim()
      .required(
        intl.formatMessage({
          id: 'form.requiredField',
        })
      ),
    link: Yup.string()
      .trim()
      .required(
        intl.formatMessage({
          id: 'form.requiredField',
        })
      )
      .matches(validYoutubeUrlBaseRegex, {
        message: intl.formatMessage(
          {
            id: 'admin.artistDirectory.invalidYoutubeUrl',
          },
          {
            validUrlBase1: validYoutubeUrlBase1,
            validUrlBase2: validYoutubeUrlBase2,
          }
        ),
      }),
    performanceId: Yup.number().required(
      intl.formatMessage({
        id: 'form.requiredField',
      })
    ),
    description: Yup.string().trim(),
    showOnFeaturedVideos: Yup.boolean(),
  });

export default artistVideoEditFormSchema;
