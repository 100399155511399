import React from 'react';
import { FormattedHTMLMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

import { useCurrentTheme } from 'app/shared/theme';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import { StreamlineIcon } from 'app/shared/components/atoms/StreamlineIcon';
import {
  Body2,
  Overline,
} from 'app/shared/components/atoms/TypographyManualCSS';
import Flyover from 'app/shared/components/molecules/SimpleFlyover';
import { ReactComponent as QuestionHelpSquare } from 'icons/streamline-regular/interface-essential/help/question-help-square.svg';

interface Props {
  title?: string;
  body?: string;
  body2?: string;
  body3?: string;
  bodyId?: string;
  bodyId2?: string;
  bodyId3?: string;
  bodyId4?: string;
  bodyId5?: string;
}

const TitleContainer = styled.div`
  padding-bottom: 4px;
`;

const BodyContainer = styled.div`
  font-size: 14px;
  line-height: 22px;
  white-space: normal;
  padding-top: 10px;
`;

const Title = styled(Body2)`
  font-weight: 400;
  letter-spacing: 0.5px;
  white-space: nowrap;
`;

interface HelpFlyoverProps {
  title?: string;
  body?: string;
  body2?: string;
  body3?: string;
  bodyId?: string;
  bodyId2?: string;
  bodyId3?: string;
  bodyId4?: string;
  bodyId5?: string;
}

export const HelpFlyover: React.FC<HelpFlyoverProps> = ({
  title,
  body,
  body2,
  body3,
  bodyId,
  bodyId2,
  bodyId3,
  bodyId4,
  bodyId5,
}) => {
  const intl = useIntl();

  return (
    <div>
      <Overline spaceAfter={2}>
        {intl.formatMessage({
          id: 'admin.shared.help',
        })}
      </Overline>
      {title && (
        <>
          <TitleContainer>
            <Title>{title}</Title>
          </TitleContainer>
          <DottedLine />
        </>
      )}
      {bodyId && (
        <BodyContainer>
          <FormattedHTMLMessage id={bodyId} />
        </BodyContainer>
      )}
      {bodyId2 && (
        <BodyContainer>
          <FormattedHTMLMessage id={bodyId2} />
        </BodyContainer>
      )}
      {bodyId3 && (
        <BodyContainer>
          <FormattedHTMLMessage id={bodyId3} />
        </BodyContainer>
      )}
      {bodyId4 && (
        <BodyContainer>
          <FormattedHTMLMessage id={bodyId4} />
        </BodyContainer>
      )}
      {bodyId5 && (
        <BodyContainer>
          <FormattedHTMLMessage id={bodyId5} />
        </BodyContainer>
      )}
      {body && <BodyContainer>{body}</BodyContainer>}
      {body2 && <BodyContainer>{body2}</BodyContainer>}
      {body3 && <BodyContainer>{body3}</BodyContainer>}
    </div>
  );
};

const HelpIconAndFlyover: React.FC<Props> = ({
  title,
  body,
  body2,
  body3,
  bodyId,
  bodyId2,
  bodyId3,
  bodyId4,
  bodyId5,
}) => {
  const theme = useCurrentTheme();

  return (
    <Flyover
      zIndex={120}
      triggerElement={
        <StreamlineIcon
          icon={QuestionHelpSquare}
          size={10}
          stroke={theme.colors.blueChristmas}
        />
      }
      innerElement={
        <HelpFlyover
          title={title}
          body={body}
          body2={body2}
          body3={body3}
          bodyId={bodyId}
          bodyId2={bodyId2}
          bodyId3={bodyId3}
          bodyId4={bodyId4}
          bodyId5={bodyId5}
        />
      }
    />
  );
};

export default HelpIconAndFlyover;
