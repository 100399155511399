import React, { useContext, useEffect, useState } from 'react';
import loadable from '@loadable/component';
import { useIntl } from 'react-intl';

import { Venue } from 'app/typings';
import { Artist } from 'app/typings/artists';
import { getIdsSubmitVariable } from 'app/shared/utils/form';
import { get } from 'app/shared/utils/get';
import useModal from 'app/shared/utils/useModal';
import usePermission from 'app/shared/utils/usePermission';
import { GetCitiesForGroupByBusinessOwner } from 'app/shared/graphql/cities/queryHooks';
import { ListPage, ListPageContext } from 'app/shared/context/ListPage';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import { LoadingBlocks } from 'app/shared/components/atoms/LoadingBlocks';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import RoutableModal from 'app/shared/components/molecules/RoutableModal';
import { artistTitleWithEmail } from 'app/admin/utils/artists';
import { CityInfoForLimitingByCity } from 'app/admin/utils/cityPermissions';
import { CuratorGroupInfoForLimitingByCuratorGroup } from 'app/admin/utils/curatorGroupPermissions';
import { eventOrganizedAsLabels } from 'app/admin/utils/events';
import { eventTypeOptions } from 'app/admin/utils/events';
import {
  filterInfoListFilteredByCheckOnFilterName,
  getBusinessOwnerOptions,
  getCityWithBusinessOwnerOptions,
  getEventCollectionOptions,
  getEventOrganizedAsOptions,
  getFilterNamesAndOptionsForTitleMapping,
  getMaxPaInputsOptions,
  getNeighborhoodOptions,
  getThemeOptions,
  titleValueOptionsWithNoneOption,
} from 'app/admin/utils/optionLists';
import { venueNameAndAddress } from 'app/admin/utils/venues';
import { GetBusinessOwners } from 'app/admin/graphql/businessOwners/queryHooks';
import { GetEventCollectionsForAdmin } from 'app/admin/graphql/eventCollections/queryHooks';
import { GetEventsForEventPlanner } from 'app/admin/graphql/events/queryHooks';
import { GetNeighborhoods } from 'app/admin/graphql/neighborhoods/queryHooks';
import { GetThemesLite } from 'app/admin/graphql/themes/queryHooks';
import { CityTitles as CityTitlesLimitedBy } from 'app/admin/components/atoms/CityTitles';
import ArtistTypeahead from 'app/admin/components/molecules/ArtistTypeahead';
import ListingFooter from 'app/admin/components/molecules/ListingFooter';
import VenueTypeahead from 'app/admin/components/molecules/VenueTypeahead';
import CardGrid from 'app/admin/components/organisms/CardGrid';
import EventPlannerCard from 'app/admin/components/organisms/EventPlannerCard';
import ListingHeader from 'app/admin/components/organisms/ListingHeader';
import ListPageTemplate from 'app/admin/components/templates/ListPage';
import Layout from 'app/admin/layouts/ListPage';

import {
  bookingsOptions,
  dateOptions,
  notYetBookedOptions,
  showFieldsOptions,
  sortOptions,
  statusOptions,
  ticketOptions,
} from './options';
import Analytics from './tracking';

const ListingControls = loadable(() =>
  import('app/admin/components/organisms/ListingControls')
);
const ListingFilter = loadable(() =>
  import('app/admin/components/organisms/ListingFilter')
);
const ListingOneClickFilter = loadable(() =>
  import('app/admin/components/molecules/ListingOneClickFilter')
);
const ListingNoResults = loadable(() =>
  import('app/admin/components/molecules/ListingNoResults')
);
const GlobalError = loadable(
  () => import('app/shared/components/pages/Status'),
  {
    resolveComponent: components => components.GlobalError,
  }
);

const pageStateConfig = {
  filterNames: [
    'city',
    'date',
    'business_owner',
    'neighborhood',
    'type',
    'event_organized_as',
    'status',
    'num_tickets_available_for_sale',
    'artist_ids',
    'venue_ids',
    'offer_id',
    'theme',
    'event_collection',
    'not_yet_booked',
    'max_pa_inputs',
    'bookings',
  ],
  defaultSort: { by: 'start', direction: 'asc' },
  textSearchParamName: 'event_search',
  idParamName: 'event_id',
  defaultDisplayFields: [
    'status',
    'artists',
    'venue',
    'team',
    'production',
    'guests',
    'details',
  ],
};

const rewriteDateFilterValue = (key: string, value: string) => {
  if (key === 'date' && value.indexOf('--') !== -1) {
    const dateParts = value.split('--');
    return `${dateParts[0]} - ${dateParts[1]}`;
  }
  return undefined;
};

const getArtistFilterValue = (artist: Artist) =>
  `${artist.id}-${artistTitleWithEmail(artist)}`;

interface ArtistTypeaheadFilterProps {
  setSelectedValue: Function;
}

const ArtistTypeaheadFilter: React.FC<ArtistTypeaheadFilterProps> = ({
  setSelectedValue,
}) => {
  return (
    <ArtistTypeahead
      setSelectedArtist={(artist: Artist | undefined) => {
        if (artist) {
          setSelectedValue(getArtistFilterValue(artist));
        }
      }}
    />
  );
};

const getVenueFilterValue = (venue: Venue) =>
  `${venue.id}-${venueNameAndAddress(venue)}`;

interface VenueTypeaheadFilterProps {
  setSelectedValue: Function;
}

const VenueTypeaheadFilter: React.FC<VenueTypeaheadFilterProps> = ({
  setSelectedValue,
}) => {
  return (
    <VenueTypeahead
      setSelectedVenue={(venue: Venue | undefined) => {
        if (venue) {
          setSelectedValue(getVenueFilterValue(venue));
        }
      }}
    />
  );
};

// To force neighborhoods filter to still appear even when no options, so that 'you must select a city' message appears
const dummyNeighborhoodOptions = [
  {
    title: 'Dummy neighborhood',
    value: 'dummy_neighborhood',
    fields: {
      cityGroupName: 'Dummy city',
      cityCachedSlug: 'dummy_city',
    },
  },
];

const AdminEventPlanner: React.FC = () => {
  const intl = useIntl();
  const pageState = useContext(ListPageContext);
  const [createEventModal, toggleCreateEventModal] = useModal();
  const [createOfferModal, toggleCreateOfferModal] = useModal();
  const [guestlistModal, toggleGuestlistModal] = useModal();
  const [createOfferEventData, setCreateOfferEventData] = useState<any>({});
  const [guestlistEventData, setGuestlistEventData] = useState<any>({});

  const PER_PAGE = 30;

  const hasAccessEventsPermission = usePermission('event.list.access');
  const hasCreateEventPermission = usePermission('event.create');

  const {
    hasByCuratorGroupPermission,
    curatorGroupIdsToLimitBy,
  } = CuratorGroupInfoForLimitingByCuratorGroup(
    'event.list.viewByCuratorGroup'
  );

  const {
    data: dataCities,
    loading: loadingCities,
    error: errorCities,
  } = GetCitiesForGroupByBusinessOwner({
    businessOwner: pageState.filterListVariable('business_owner'),
  });

  const {
    loading: loadingNeighborhoods,
    error: errorNeighborhoods,
    data: dataNeighborhoods,
  } = GetNeighborhoods({
    city: pageState.filterListVariable('city'),
    skip: !pageState.filterListVariable('city'),
  });

  const {
    loading: loadingBusinessOwners,
    error: errorBusinessOwners,
    data: dataBusinessOwners,
  } = GetBusinessOwners({
    archivedStatus: 'not_archived',
    skipPagination: true,
  });

  const { cityIdsToLimitBy, cityTitlesToLimitBy } = CityInfoForLimitingByCity(
    'event.list.viewByCity',
    dataCities && dataCities.cities
  );
  const cityOptions = getCityWithBusinessOwnerOptions(dataCities);
  const businessOwnerOptions = getBusinessOwnerOptions(dataBusinessOwners);
  const neighborhoodOptions = pageState.filterListVariable('city')
    ? getNeighborhoodOptions(dataNeighborhoods, cityIdsToLimitBy)
    : dummyNeighborhoodOptions;

  const businessOwnerFilterIsApplied =
    pageState.filterListVariable('business_owner') &&
    // @ts-ignore
    pageState.filterListVariable('business_owner').length > 1;

  const {
    loading: loadingThemes,
    error: errorThemes,
    data: dataThemes,
  } = GetThemesLite({});

  const themeOptions = [
    {
      title: intl.formatMessage({
        id: 'admin.offerDirectory.filter.option.noTheme',
      }),
      value: null,
    },
  ].concat(getThemeOptions(dataThemes));

  const maxPaInputsOptions = titleValueOptionsWithNoneOption(
    getMaxPaInputsOptions(intl)
  );

  const {
    loading: loadingEventCollections,
    error: errorEventCollections,
    data: dataEventCollections,
  } = GetEventCollectionsForAdmin({
    orderBy: 'title',
    orderDirection: 'asc',
    skipPagination: true,
  });

  const eventCollectionOptions = [
    {
      title: intl.formatMessage({
        id: 'admin.eventPlanner.filter.noCollection',
      }),
      value: null,
    },
    {
      title: intl.formatMessage({
        id: 'admin.eventPlanner.filter.anyCollection',
      }),
      value: 'any',
    },
  ].concat(getEventCollectionOptions(dataEventCollections));

  const filterDropdownOptionsInfoList = filterInfoListFilteredByCheckOnFilterName(
    [
      {
        filterName: 'business_owner',
        dropdownParams: {
          searchBar: false,
          title: intl.formatMessage({
            id: 'shared.businessOwners',
          }),
          options: businessOwnerOptions,
        },
      },
      {
        filterName: 'city',
        filterDependentOn: businessOwnerFilterIsApplied
          ? 'business_owner'
          : undefined,
        dropdownParams: {
          searchBar: true,
          title: intl.formatMessage({
            id: 'admin.eventPlanner.filter.title.sofarCities',
          }),
          // @ts-ignore
          groupBy: businessOwnerFilterIsApplied
            ? 'businessOwnerGroupName'
            : 'country',
          groupById: businessOwnerFilterIsApplied
            ? 'businessOwnerCachedSlug'
            : 'country',
          options: cityOptions,
        },
      },
      {
        filterName: 'neighborhood',
        filterDependentOn: 'city',
        dropdownParams: {
          searchBar: true,
          title: intl.formatMessage({
            id: 'shared.neighborhoods',
          }),
          // @ts-ignore
          groupBy: 'cityGroupName',
          groupById: 'cityCachedSlug',
          options: neighborhoodOptions,
        },
      },
      {
        filterName: 'date',
        dropdownParams: {
          searchBar: false,
          title: intl.formatMessage({
            id: 'admin.eventPlanner.filter.title.dates',
          }),
          hasDateInput: true,
          options: dateOptions(),
        },
      },
      {
        filterName: 'type',
        dropdownParams: {
          searchBar: false,
          title: intl.formatMessage({
            id: 'admin.eventPlanner.filter.title.eventType',
          }),
          options: eventTypeOptions(intl),
        },
      },
      {
        filterName: 'event_organized_as',
        dropdownParams: {
          searchBar: false,
          title: intl.formatMessage({
            id: 'admin.shared.organizedAs',
          }),
          options: getEventOrganizedAsOptions(eventOrganizedAsLabels(intl)),
        },
      },
      {
        filterName: 'status',
        dropdownParams: {
          searchBar: false,
          title: intl.formatMessage({
            id: 'admin.eventPlanner.filter.title.concertStatus',
          }),
          options: statusOptions,
        },
      },
      {
        filterName: 'num_tickets_available_for_sale',
        dropdownParams: {
          searchBar: false,
          title: intl.formatMessage({
            id: 'admin.eventPlanner.filter.title.ticketsAvailable',
          }),
          options: ticketOptions,
        },
      },
      {
        filterName: 'artist_ids',
        dropdownParams: {
          searchBar: false,
          title: intl.formatMessage({
            id: 'admin.eventPlanner.filter.title.artists',
          }),
          options: [],
          typeaheadFilterComponent: ArtistTypeaheadFilter,
        },
      },
      {
        filterName: 'venue_ids',
        dropdownParams: {
          searchBar: false,
          title: intl.formatMessage({
            id: 'admin.eventPlanner.filter.title.venues',
          }),
          options: [],
          typeaheadFilterComponent: VenueTypeaheadFilter,
        },
      },
      {
        filterName: 'theme',
        dropdownParams: {
          searchBar: true,
          title: intl.formatMessage({
            id: 'admin.offerCreate.filter.theme',
          }),
          options: themeOptions,
        },
      },
      {
        filterName: 'event_collection',
        dropdownParams: {
          searchBar: true,
          title: intl.formatMessage({
            id: 'admin.eventPlanner.filter.title.collection',
          }),
          options: eventCollectionOptions,
        },
      },
      {
        filterName: 'not_yet_booked',
        dropdownParams: {
          searchBar: false,
          title: intl.formatMessage({
            id: 'admin.eventPlanner.venueInfo.notYetBooked',
          }),
          options: notYetBookedOptions(intl),
          groupBy: 'groupName',
          groupNames: 'groupName',
        },
      },
      {
        filterName: 'max_pa_inputs',
        dropdownParams: {
          title: intl.formatMessage({
            id: 'admin.eventPlanner.filter.production',
          }),
          options: maxPaInputsOptions,
        },
      },
    ],
    {
      business_owner: !cityIdsToLimitBy,
      city: !cityIdsToLimitBy,
      eventCollectionOptions: !cityIdsToLimitBy,
      neighborhood: !cityIdsToLimitBy,
      type: hasAccessEventsPermission,
    }
  );

  hasAccessEventsPermission &&
    filterDropdownOptionsInfoList.push({
      filterName: 'bookings',
      dropdownParams: {
        title: intl.formatMessage({
          id: 'admin.eventPlanner.filter.title.bookings',
        }),
        options: bookingsOptions(intl),
      },
    });

  const getArtistIdsSubmitVariable = () =>
    pageState.filterState.artist_ids.length > 0
      ? pageState.filterState.artist_ids
          .map((value: string) => value.split('-')[0])
          .join(',')
      : undefined;

  const getVenueIdsSubmitVariable = () =>
    pageState.filterState.venue_ids.length > 0
      ? pageState.filterState.venue_ids
          .map((value: string) => value.split('-')[0])
          .join(',')
      : undefined;

  const getCuratorGroupSubmitVariable = () =>
    hasByCuratorGroupPermission && pageState.oneClickFilterState.isEnabled
      ? getIdsSubmitVariable(curatorGroupIdsToLimitBy)
      : undefined;

  const isSearchingForEventId = () =>
    pageState.textSearchState && /^\d+$/.test(pageState.textSearchState.trim());

  const shouldFilterByUpcoming = () =>
    !(pageState.filterListVariable('date') || '').includes('past') &&
    !(pageState.filterListVariable('date') || '').includes('year') &&
    !(pageState.filterListVariable('date') || '').includes('-') &&
    !isSearchingForEventId();

  const offerIdFromUrl = new URLSearchParams(window.location.search).get(
    'offer_id'
  );
  const offerId = offerIdFromUrl ? parseInt(offerIdFromUrl) : undefined;

  const {
    loading: loadingEvents,
    error: errorEvents,
    data: dataEvents,
    refetch: refetchEvents,
  } = GetEventsForEventPlanner({
    eventSearch: pageState.textSearchState,
    city: !cityIdsToLimitBy ? pageState.filterListVariable('city') : undefined,
    cityIds: getIdsSubmitVariable(cityIdsToLimitBy),
    businessOwner: pageState.filterListVariable('business_owner'),
    neighborhood: pageState.filterListVariable('neighborhood'),
    excludeCancelled:
      !pageState.filterListVariable('status')?.includes('cancelled') &&
      !isSearchingForEventId()
        ? true
        : undefined,
    date: pageState.filterListVariable('date'),
    upcomingOrCurrent: shouldFilterByUpcoming(),
    artistIds: getArtistIdsSubmitVariable(),
    venueIds: getVenueIdsSubmitVariable(),
    curatorGroup: getCuratorGroupSubmitVariable(),
    theme: pageState.filterListVariable('theme'),
    eventCollection: pageState.filterListVariable('event_collection'),
    offerId,
    type: pageState.filterListVariable('type'),
    eventOrganizedAs: pageState.filterListVariable('event_organized_as'),
    maxPaInputs: pageState.filterListVariable('max_pa_inputs'),
    notYetBooked: pageState.filterListVariable('not_yet_booked'),
    bookings: pageState.filterListVariable('bookings'),
    status: pageState.filterListVariable('status'),
    numTicketsAvailableForSale: pageState.filterListVariable(
      'num_tickets_available_for_sale'
    ),
    orderBy: pageState.sortState.by,
    orderDirection: pageState.sortState.direction,
    page: pageState.page,
    perPage: PER_PAGE,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    pageState.setRefetchListPage(refetchEvents);
  }, [refetchEvents]);

  useEffect(() => {
    pageState.updateScrollPositionOnPage();
  }, [pageState.detailsModal.isShowing]);

  useEffect(() => {
    pageState.updateDetailsModal();
  }, []);

  useEffect(() => {
    pageState.setupFilterLabelTitleMapping(
      getFilterNamesAndOptionsForTitleMapping(
        filterDropdownOptionsInfoList,
        pageState.filterState
      )
    );
  }, [dataCities, pageState.filterState]);

  useEffect(() => {
    pageState.handleRemoveOrphanDependentFilters({
      parentFilter: 'business_owner',
      dependentFilter: 'city',
      dependentFilterOptions: cityOptions,
    });
  }, [cityOptions, pageState]);

  useEffect(() => {
    pageState.updatePageUrl();
  }, [
    pageState.page,
    pageState.sortState,
    pageState.filterState,
    pageState.textSearchState,
    pageState.detailData,
    pageState.detailsModal.isShowing,
    pageState.displayFields,
  ]);

  useEffect(() => {
    Analytics.pushDataLayer();
  }, [pageState.filterState, pageState.textSearchState]);

  if (
    (!loadingEvents && !dataEvents) ||
    errorEvents ||
    (!loadingCities && !dataCities) ||
    errorCities ||
    (!loadingNeighborhoods &&
      !dataNeighborhoods &&
      pageState.filterListVariable('city')) ||
    errorNeighborhoods ||
    (!loadingBusinessOwners && !dataBusinessOwners) ||
    errorBusinessOwners ||
    (!loadingThemes && !dataThemes) ||
    errorThemes ||
    (!loadingEventCollections && !dataEventCollections) ||
    errorEventCollections
  ) {
    return <GlobalError />;
  }

  const modalsContent = () => (
    <>
      {pageState.detailsModal.isShowing && (
        <RoutableModal
          hide={pageState.detailsModal.hide}
          initialRouteProps={{ ...pageState.detailData }}
          initialRouteName="event-details"
          dataQaidSuffix="admin-edit-event-details"
        />
      )}
      {createEventModal.isShowing && (
        <RoutableModal
          hide={createEventModal.hide}
          initialRouteProps={{
            onSuccess: refetchEvents,
          }}
          initialRouteName="event-create"
          dataQaidSuffix="admin-create-event"
        />
      )}
      {createOfferModal.isShowing && (
        <RoutableModal
          hide={createOfferModal.hide}
          initialRouteProps={{
            event: createOfferEventData.event,
            position: createOfferEventData.position,
            creatingOfferForSingleEvent: true,
            refetchEvents,
          }}
          initialRouteName="offer-create"
          dataQaidSuffix="admin-create-offer"
        />
      )}
      {guestlistModal.isShowing && (
        <RoutableModal
          hide={guestlistModal.hide}
          initialRouteProps={{ ...guestlistEventData }}
          initialRouteName="guestlist"
          dataQaidSuffix="admin-edit-event-guestlist"
        />
      )}
    </>
  );

  return (
    <Layout scrollDisabled={pageState.detailsModal.isShowing}>
      <ListPageTemplate
        pageTitle={intl.formatMessage({
          id: 'admin.eventPlanner.title',
        })}
        modalsContent={modalsContent}
      >
        <ListingHeader
          pageTitle={intl.formatMessage({
            id: 'admin.eventPlanner.title',
          })}
          addEntityText={
            hasCreateEventPermission
              ? intl.formatMessage({
                  id: 'admin.eventPlanner.addConcert',
                })
              : undefined
          }
          onClickAddEntity={
            hasCreateEventPermission ? toggleCreateEventModal : undefined
          }
          searchPlaceholder={intl.formatMessage({
            id: 'admin.eventPlanner.searchConcert',
          })}
          searchValue={pageState.textSearchState}
          onSearch={pageState.handleTextSearch}
          dataQaidPrefix="event-planner"
        />

        <CityTitlesLimitedBy cityTitles={cityTitlesToLimitBy} />
        <div>
          <ListingControls
            sortOptions={sortOptions}
            orderBy={pageState.sortState.by}
            orderDirection={pageState.sortState.direction}
            totalRecords={
              dataEvents &&
              dataEvents.events &&
              dataEvents.events.metadata.totalRecords
            }
            loading={loadingEvents}
            onSort={pageState.handleSortChange}
            onReset={pageState.handleResetFilters}
            dataQaidPrefix="event-planner"
            selectedDisplayFields={pageState.displayFields}
            handleDisplayFieldsChange={pageState.handleDisplayFieldsChange}
            selectedFieldDisplayOptions={showFieldsOptions}
          />

          {hasByCuratorGroupPermission && (
            <ListingOneClickFilter
              oneClickFilterState={pageState.oneClickFilterState}
              handleOneClickFilterChange={pageState.handleOneClickFilterChange}
              labelTextKey="admin.eventPlanner.filter.myConcertsOnly"
            />
          )}

          <ListingFilter
            filterTitle={intl.formatMessage({
              id: 'admin.eventPlanner.filterTitle',
            })}
            textSearchString={pageState.textSearchState}
            handleTextSearchLabelClose={pageState.handleTextSearchLabelClose}
            labelTitleMapping={pageState.filterLabelTitleMapping}
            dropdownOptionsInfoList={filterDropdownOptionsInfoList}
            filterState={pageState.filterState}
            handleRemoveFilter={pageState.handleRemoveFilter}
            handleFilterChange={pageState.handleFilterChange}
            rewriteValue={rewriteDateFilterValue}
          />
        </div>

        <>
          <div>
            <CardGrid
              objectData={get(dataEvents, 'events.events', [])}
              renderCardComponent={(event: any, i: number) => (
                // @ts-ignore
                <EventPlannerCard
                  index={i}
                  displayFields={pageState.displayFields}
                  event={event}
                  onShowDetails={pageState.toggleDetailsModalAndSetDetailData}
                  onCreateOffer={(position: number) => {
                    setCreateOfferEventData({
                      event,
                      position,
                    });
                    toggleCreateOfferModal();
                  }}
                  onViewGuestlist={(eventData: any) => {
                    setGuestlistEventData(eventData);
                    toggleGuestlistModal();
                  }}
                />
              )}
              dataQaid="admin-event-planner-list"
              loading={loadingEvents}
              loadingComponent={
                <LoadingBlocks.Rectangle width="100%" height="420px" />
              }
              hideDividerOnSize="xs"
            />
            <DottedLine />
            <Spacer mb={2} />
          </div>
          <ListingNoResults
            entityName="Event"
            numResults={get(dataEvents, 'events.events.length', undefined)}
            loading={loadingEvents}
          />
          <ListingFooter
            numTotalRecords={get(
              dataEvents,
              'events.metadata.totalRecords',
              undefined
            )}
            perPage={PER_PAGE}
            currentPage={pageState.page}
            onPageChange={pageState.handlePageChange}
            loading={loadingEvents}
            dataQaidPrefix="events"
          />
        </>
      </ListPageTemplate>
    </Layout>
  );
};

const AdminEventPlannerWrapper: React.FC = () => {
  return (
    <ListPage config={pageStateConfig} analytics={Analytics}>
      <AdminEventPlanner />
    </ListPage>
  );
};

export default AdminEventPlannerWrapper;
