import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { useCurrentTheme } from 'app/shared/theme';
import { makeOpaque } from 'app/shared/utils/colors';
import { transformImg } from 'app/shared/utils/string';
import { useTShirtSizeBreakpoint as useBreakpoint } from 'app/shared/utils/useBreakpoint';
import { Container } from 'app/shared/components/atoms/Container';
import { Image } from 'app/shared/components/atoms/ImageManualCSS';
import {
  Body,
  H1,
  Overline,
} from 'app/shared/components/atoms/TypographyManualCSS';

export interface ImgMap {
  xs: string;
  sm: string;
  md: string;
  lg: string;
  xl: string;
}

interface Props {
  img: ImgMap;
  children: any;
  align?: 'center' | 'left' | 'right';
  alignItems?: string;
  xtraLarge?: boolean;
  jumbo?: boolean;
  auto?: boolean;
  'data-qaid'?: string;
  greyscale?: boolean;
  tint?: boolean;
  logoImg?: string;
  maxPageContentWidth?: number;
  mobileSmHero?: boolean;
  lazy?: boolean;
  minMobileHeight?: string;
  noContentStyles?: boolean;
  as?: 'header' | 'div';
  mobilePadding?: boolean;
  className?: string;
}

interface Composition {
  Title: React.FC;
  Body: React.FC;
  LeadIn: React.FC;
}

export const Div = styled.div<{
  noContentStyles?: boolean;
  alignItems?: string;
}>`
  ${({ noContentStyles, alignItems }) => css`
    ${noContentStyles &&
      css`
        width: 100%;
        height: 100%;

        ${alignItems == 'end' &&
          css`
            height: auto;
          `}
      `}
  `}
`;

export const StyledContainer = styled(Container)<{
  maxPageContentWidth?: number;
  noContentStyles: boolean;
  alignItems?: string;
}>`
  ${({ maxPageContentWidth, noContentStyles, alignItems }) => css`
    position: relative;
    display: block;

    ${noContentStyles &&
      css`
        width: 100%;
        height: 100%;

        ${alignItems == 'end' &&
          css`
            height: auto;
          `}
      `}

    ${maxPageContentWidth &&
      css`
        max-width: ${maxPageContentWidth};
        padding-right: 0;
        padding-left: 0;
      `}
  `}
`;

const StyledBody = styled(Body)`
  ${({ theme }) => css`
    color: ${theme.colors.whiteDenim};
    max-width: 600px;
    display: inline-block;
  `}
`;

const heightCalculation = (
  xtraLarge?: boolean,
  jumbo?: boolean,
  auto?: boolean,
  jumboSize?: string,
  xlSize?: string,
  smSize?: string,
  mobileSmHero?: boolean
) => {
  if (xtraLarge && !mobileSmHero) {
    return xlSize;
  } else if (jumbo && !mobileSmHero) {
    return jumboSize;
  } else if (auto) {
    return 'auto';
  } else {
    return smSize;
  }
};

export const Wrapper = styled.header<{
  img: ImgMap;
  align?: 'center' | 'left' | 'right';
  alignItems?: string;
  xtraLarge?: boolean;
  jumbo?: boolean;
  auto?: boolean;
  greyscale?: boolean;
  tint?: boolean;
  mobileSmHero?: boolean;
  lazy?: boolean;
  minMobileHeight?: string;
  noContentStyles?: boolean;
  mobilePadding?: boolean;
}>`
  ${({
    theme,
    img,
    align,
    alignItems,
    xtraLarge,
    jumbo,
    auto,
    greyscale,
    tint,
    mobileSmHero,
    lazy = false,
    minMobileHeight,
    noContentStyles,
    mobilePadding,
  }) => css`
    width: 100%;
    background-image: url(${lazy ? transformImg(img.xs, 'w=8') : img.xs});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    display: flex;
    align-items: ${alignItems || 'center'};
    text-align: center;
    justify-content: center;
    font-family: ${theme.fontStyles.h1.fontFamily};


    min-height: ${heightCalculation(
      xtraLarge,
      jumbo,
      auto,
      '649px',
      '532px',
      minMobileHeight,
      mobileSmHero
    )};
    max-height: ${heightCalculation(
      xtraLarge,
      jumbo,
      auto,
      '649px',
      '532px',
      '450px',
      mobileSmHero
    )};
    height: ${heightCalculation(
      xtraLarge,
      jumbo,
      auto,
      '649px',
      '532px',
      'auto',
      mobileSmHero
    )};

    ${align === 'left' &&
      css`
        justify-content: left;
        text-align: left;
      `}

    ${!mobilePadding &&
      css`
        padding: ${theme.ruler[12]}px 0;
      `}

    ${greyscale &&
      css`
        filter: grayscale(100%);
      `}

    ${tint &&
      css`
        background-blend-mode: multiply;
        background-color: ${makeOpaque(theme.colors.backToBlack, 0.5)};
      `}

    ${Container} ${Div} {
      ${!noContentStyles &&
        css`
          max-width: 80%;
        `}

      ${theme.media.sm`
        margin: auto;
        justify-content: center;
      `}
    }

    ${theme.media.xs`
      background-image: url(${lazy ? transformImg(img.xs, 'w=8') : img.xs});
      height: 180px;
      ${mobilePadding &&
        css`
          padding: ${theme.ruler[27]}px 0 0 0;
        `}
    `}

    ${theme.media.sm`
      background-image: url(${lazy ? transformImg(img.sm, 'w=8') : img.sm});
      height: 390px;
    `}

    ${theme.media.md`
    background-image: url(${lazy ? transformImg(img.md, 'w=8') : img.md});
    height: ${heightCalculation(
      xtraLarge,
      jumbo,
      auto,
      '649px',
      '640px',
      '340px',
      mobileSmHero
    )};
  `}

  ${theme.media.lg`
  text-align: center;
  background-image: url(${lazy ? transformImg(img.lg, 'w=8') : img.lg});
  padding: ${theme.ruler[12]}px 0;
  height: ${heightCalculation(
    xtraLarge,
    jumbo,
    auto,
    '649px',
    '640px',
    '375px'
  )};

  ${Container} ${Div} ${Overline} {
    ${align === 'left' &&
      css`
        justify-content: left;
        text-align: left;
      `}
    ${align === 'right' &&
      css`
        text-align: right;
      `}
  }

  ${Container} ${Div} {
    max-width: 90%;

    ${align === 'left' &&
      css`
        margin: 0;
        text-align: left;
        justify-content: left;
      `}
    ${align === 'right' &&
      css`
        margin: 0 0 0 auto;
        text-align: right;
      `}
  }
`}

    ${!noContentStyles &&
      css`
      ${Body} {
        margin-bottom: 0;
      }

      ${Container} ${Div} ${Overline} {
        justify-content: center;
      }

      ${Container} ${Div} {
        margin: ${theme.dimensions.container.innerMargin};
        justify-content: center;
        max-width: 90%;
      }

      ${theme.media.md`
        ${Container} ${Div} {
          max-width: 70%;
          margin: auto;
          justify-content: center;
        }
      `}

      ${theme.media.lg`
        ${Container} ${Div} ${Overline} {
          ${align === 'left' &&
            css`
              justify-content: left;
              text-align: left;
            `}

          ${align === 'right' &&
            css`
              text-align: right;
            `}
        }

        ${Container} ${Div} {
          max-width: 90%;

          ${align === 'left' &&
            css`
              margin: 0;
              text-align: left;
              justify-content: left;
            `}

          ${align === 'right' &&
            css`
              margin: 0 0 0 auto;
              text-align: right;
            `}
        }

        ${Body} {
          margin-bottom: ${theme.ruler[6]}px;
        }
      `}
    `}

    ${theme.media.xl`
      background-image: url(${lazy ? transformImg(img.xs, 'w=8') : img.xl});
    `}
  `}
`;

export const StyledLogo = styled(Image)`
  ${({ theme }) => css`
    position: relative;
    bottom: 0;
    right: 0;
    width: 12%;
    min-width: 100px;
    margin-bottom: ${theme.ruler[6]}px;

    ${theme.media.lg`
      position: absolute;
    `}
  `}
`;

const StyledH1 = styled(H1)`
  ${({ theme }) => css`
    > div > h2 {
      font-family: ${theme.fontStyles.h1.fontFamily};
    }
    > div > p {
      margin: 0px;
      font-family: ${theme.fontStyles.h1.fontFamily};
    }
    > div > span {
      font-family: ${theme.fontStyles.h1.fontFamily};
    }
    > div {
      font-family: ${theme.fontStyles.h1.fontFamily};
    }
  `}
`;

const Hero: React.FC<Props> & Composition = ({
  'data-qaid': qaId = 'default-hero',
  as = 'header',
  img,
  children,
  align = 'left',
  alignItems,
  xtraLarge = false,
  jumbo = false,
  auto = false,
  greyscale = false,
  tint = true,
  mobileSmHero = false,
  lazy = false,
  logoImg,
  maxPageContentWidth,
  minMobileHeight = '275px',
  noContentStyles = false,
  mobilePadding = false,
  className,
}) => {
  useEffect(() => {
    // @ts-ignore
    import('lazysizes/plugins/unveilhooks/ls.unveilhooks');
  }, []);
  const currentBreakpoint = useBreakpoint();
  const [dataBg, setDataBg] = useState(img[currentBreakpoint]);

  useEffect(() => {
    const backgroundToSet = img[currentBreakpoint];
    if (dataBg !== backgroundToSet) {
      setDataBg(backgroundToSet);
    }
  }, [img, currentBreakpoint, dataBg, setDataBg]);

  return (
    <Wrapper
      img={img}
      as={as}
      data-qaid={`${qaId}-wrapper`}
      greyscale={greyscale}
      align={align}
      alignItems={alignItems}
      xtraLarge={xtraLarge}
      jumbo={jumbo}
      auto={auto}
      tint={tint}
      mobileSmHero={mobileSmHero}
      className={lazy ? 'lazyload' : className}
      data-bg={dataBg}
      lazy={lazy}
      minMobileHeight={minMobileHeight}
      noContentStyles={noContentStyles}
      mobilePadding={mobilePadding}
    >
      <StyledContainer
        maxPageContentWidth={maxPageContentWidth}
        noContentStyles={noContentStyles}
        alignItems={alignItems}
      >
        <Div noContentStyles={noContentStyles} alignItems={alignItems}>
          {React.Children.map(children, child => {
            if (child) {
              return React.cloneElement(child, {
                'data-qaid': qaId,
              });
            }

            return null;
          })}
        </Div>
        {logoImg && <StyledLogo src={logoImg} data-qaid={`${qaId}-logo`} />}
      </StyledContainer>
    </Wrapper>
  );
};

interface ElementProps {
  'data-qaid'?: string;
}

const HeroLeadIn: React.FC<ElementProps> = ({
  children,
  'data-qaid': qaId,
}) => {
  const theme = useCurrentTheme();

  return (
    <Overline color={theme.colors.whiteDenim} data-qaid={`${qaId}-lead-in`}>
      {children}
    </Overline>
  );
};

const HeroTitle: React.FC<ElementProps> = ({ children, 'data-qaid': qaId }) => {
  const theme = useCurrentTheme();

  return (
    <StyledH1
      color={theme.colors.whiteDenim}
      spaceAfter={2}
      data-qaid={`${qaId}-title`}
    >
      {children}
    </StyledH1>
  );
};

const HeroBody: React.FC<ElementProps> = ({ children, 'data-qaid': qaId }) => {
  return (
    <StyledBody data-qaid={`${qaId}-body`} as="span">
      {children}
    </StyledBody>
  );
};

Hero.LeadIn = HeroLeadIn;
Hero.Title = HeroTitle;
Hero.Body = HeroBody;

export default Hero;
