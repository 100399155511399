import React, { CSSProperties } from 'react';
import styled, { css } from 'styled-components';

import { isEnvironmentProdOrLocal } from 'app/lib/environmentChecker';
import { City } from 'app/typings';
import { useCurrentTheme } from 'app/shared/theme';
import { useIsTShirtSizeBreakpointSize as useIsBreakpointSize } from 'app/shared/utils/useBreakpoint';
import { useNavbarContext } from 'app/shared/context';
import { ThemeWrapper } from 'app/shared/components/atoms';
import { NavbarAuthModal } from 'app/shared/components/organisms/Navbar';
import { MobileNav } from 'app/admin/components/molecules/MobileNavManualCSS';

import { NavbarDefaultMainContainer } from './NavbarDefaultMainContainer';

interface Props {
  isLandingPage?: boolean;
  position?: CSSProperties['position'];
  backgroundColor?: string;
  containerMaxWidth?: number;
  useResponsiveHeight?: boolean;
  hideCitySelector?: boolean;
  eventId?: string;
  city?: City;
  suppressAllLinks?: boolean;
  mobileNavMenuData: any;
  isAdmin?: boolean;
  disableContentWrapperBorder?: boolean;
  MainContainerComponent?: React.ComponentType<any>;
  ComponenAfterMainContainer?: React.ComponentType<any>;
}

const NavbarWrapperContainer = styled.div<{
  position?: CSSProperties['position'];
  backgroundColor?: string;
  useResponsiveHeight?: boolean;
  hideCitySelector?: boolean;
}>`
  ${({
    theme,
    position,
    backgroundColor,
    useResponsiveHeight,
    hideCitySelector,
  }) => css`
    height: ${theme.dimensions.navbarHeight[
      useResponsiveHeight
        ? hideCitySelector
          ? 'xsNoCitySelector'
          : 'xs'
        : 'lg'
    ]};
    width: 100%;
    z-index: ${theme.zIndex.navbar};
    background: ${!isEnvironmentProdOrLocal()
      ? theme.colors.orangeCrush
      : backgroundColor || theme.colors.whiteDenim};
    position: ${position || 'relative'};
    margin: 0;

    ${useResponsiveHeight &&
      css`
        ${theme.media.md`
          height: ${
            theme.dimensions.navbarHeight[
              hideCitySelector ? 'mdNoCitySelector' : 'md'
            ]
          };
        `}
        ${theme.media.lg`
          height: ${theme.dimensions.navbarHeight.lg};
        `}
      `};
  `}
`;

const NavbarContent = styled.div<{ disableBorder?: boolean }>`
  ${({ theme, disableBorder }) => css`
    width: 100%;
    height: fit-content;
    border-top: ${disableBorder
      ? 'none'
      : `solid 1px ${theme.colors.backToBlack}`};
    border-bottom: ${disableBorder
      ? 'none'
      : `solid 1px ${theme.colors.backToBlack}`};
    padding: 0;

    ${theme.media.xs`
      display: block;
      margin-bottom: ${theme.ruler[1]}px;
    `};
    ${theme.media.lg`
      display: flex;
      align-items: center;
      align-content: center;
      flex-direction: column;
      justify-content: space-around;
    `};
  `}
`;

export const NavbarWrapper: React.FC<Props> = ({
  position,
  backgroundColor,
  containerMaxWidth,
  useResponsiveHeight = true,
  hideCitySelector = false,
  eventId,
  city,
  suppressAllLinks = false,
  mobileNavMenuData,
  isAdmin = false,
  disableContentWrapperBorder = false,
  MainContainerComponent = NavbarDefaultMainContainer,
  ComponenAfterMainContainer,
  children,
}) => {
  const themeName = useCurrentTheme()?.name;
  const { isMobile, isTablet } = useIsBreakpointSize();
  const { isLandingPage } = useNavbarContext();

  return (
    <NavbarWrapperContainer
      position={position}
      backgroundColor={backgroundColor}
      useResponsiveHeight={useResponsiveHeight}
      hideCitySelector={hideCitySelector}
    >
      <NavbarAuthModal eventId={eventId} city={city} />
      {(isMobile || isTablet) && !isLandingPage && !suppressAllLinks && (
        <MobileNav
          mobileNavMenuData={mobileNavMenuData}
          isAdmin={isAdmin}
          data-qaid="mobile-nav"
        />
      )}
      <NavbarContent
        disableBorder={disableContentWrapperBorder}
        data-qaid="navbar"
      >
        <MainContainerComponent
          alignItems="center"
          justifyContent="space-between"
          containerMaxWidth={containerMaxWidth}
          useResponsiveHeight={useResponsiveHeight}
        >
          <ThemeWrapper themeName={themeName}>{children}</ThemeWrapper>
        </MainContainerComponent>
        {ComponenAfterMainContainer && <ComponenAfterMainContainer />}
      </NavbarContent>
    </NavbarWrapperContainer>
  );
};
