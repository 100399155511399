import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { EventPreview } from 'app/typings';
import { useAnalyticsContext } from 'app/shared/utils';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { Spinner } from 'app/shared/components/atoms/SpinnerManualCSS';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { GetEventInfo } from 'app/admin/graphql/events/queryHooks';
import { CreateEventFeedback } from 'app/admin/graphql/feedbacks/mutationHooks';
import { DetailsInfoMainContainer } from 'app/admin/components/atoms/DetailContent';
import {
  EventDate,
  EventLocation,
} from 'app/admin/components/molecules/EventPlannerUtils';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

import CrewEventSurveyForm from './CrewEventSurveyForm';
import CrewEventSurveyFormSchema from './CrewEventSurveyFormSchema';

interface Props {
  contentProps?: any;
  setFormSubmitAction: (func: Function) => void;
  setDisplayConfirmation: (set: boolean) => void;
  setIsSubmitting: (set: boolean) => void;
  setCloseAction: (set: Function) => void;
  isSubmitting: boolean;
  navigateTo: (routeData: object) => void;
  hide: Function;
}

const StyledDetailsInfoMainContainer = styled(DetailsInfoMainContainer)`
  h3 {
    white-space: nowrap;
  }
  align-items: flex-start;
`;

const StyledDetailContentContainer = styled.div`
  ${({ theme }) => css`
    align-items: flex-start;
    ${theme.media.xs`
      margin-left: 0px;
    `};
    ${theme.media.md`
      margin-left: 25px;
    `};
    ${theme.media.xl`
      flex-direction: row;
    `};
  `}
`;

const CrewEventSurvey: React.FC<Props> = ({
  contentProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  setCloseAction,
  isSubmitting,
  navigateTo,
}) => {
  const { eventId, eventsPendingFeedback } = contentProps;
  const { trackAnalyticsEvent } = useAnalyticsContext();

  const intl = useIntl();
  const { data, loading } = GetEventInfo({
    id: eventId,
    includeDraftEvents: false,
  });

  const eventInfo = data?.event as EventPreview;

  const createEventFeedbackAction = CreateEventFeedback();

  const handleCreateEventFeedback = useSubmitAction({
    submitAction: createEventFeedbackAction,
    submitVariables: (values: any) => ({
      eventId: eventInfo && eventInfo.id,
      eventRating: values.eventRating,
      artistFeedbacks: Object.values(values.artistFeedback).map(
        (artistVal: any) => ({
          artistId: artistVal.artistId,
          feedbackPhraseId: artistVal.feedbackPhraseId,
          overallFeeling: artistVal.overallFeeling,
        })
      ),
      mcOverallFeeling: values.mcOverallFeeling,
      venueOverallFeeling: values.venueOverallFeeling,
      venueComfortOverallFeeling: values.venueComfortOverallFeeling,
      eventFeedbackNotes: values.eventFeedbackNotes,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.crewPortal.crewFeedback.form.successMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.crewPortal.crewFeedback.form.failureMessage',
    }),
    onSuccess: () => {
      navigateTo({
        routeName: 'crew-feedback-events',
        routeProps: {
          eventsPendingFeedback: eventsPendingFeedback.filter(
            (e: any) => e.id !== eventId
          ),
        },
      });
    },
    onError: (e: any) => {
      trackAnalyticsEvent('Create Event Feedback Failure', {
        eventId: eventInfo && eventInfo.id,
        errorType: 'onError',
        error: e,
      });
    },
    onValidationError: (e: any) => {
      trackAnalyticsEvent('Create Event Feedback Failure', {
        eventId: eventInfo && eventInfo.id,
        errorType: 'onValidationError',
        error: e,
      });
    },
  });

  const formInitialValues = {
    artistFeedback:
      (eventInfo &&
        Object.fromEntries(
          eventInfo.performances.map(p => [
            p.artist.id,
            { title: p.artist.title, artistId: p.artist.id },
          ])
        )) ||
      {},
  };

  return (
    <>
      <ModalContentContainer data-qaid="crew-event-survey">
        <StyledDetailsInfoMainContainer>
          <DetailsHeader
            title={intl.formatMessage({
              id: 'admin.crewPortal.crewFeedback.concertFeedback',
            })}
          />

          {loading ? (
            <Spinner />
          ) : (
            eventInfo && (
              <StyledDetailContentContainer>
                <EventDate
                  show={true}
                  localStartsAt={eventInfo?.localStartsAt}
                  displayCalendar={true}
                  displayStyle="white"
                />
                <EventLocation
                  show={true}
                  cityTitle={eventInfo.city.title}
                  neighborhoodTitle={eventInfo.neighborhood?.title}
                />
                <GenericForm
                  formInitialValues={formInitialValues}
                  renderFormComponent={(renderProps: any) => (
                    <CrewEventSurveyForm
                      formikProps={renderProps.formikProps}
                      navigateTo={navigateTo}
                      setFormSubmitAction={setFormSubmitAction}
                      setCloseAction={setCloseAction}
                      setDisplayConfirmation={setDisplayConfirmation}
                      setIsSubmitting={setIsSubmitting}
                      isSubmitting={isSubmitting}
                      eventsPendingFeedback={eventsPendingFeedback}
                      eventInfo={eventInfo}
                    />
                  )}
                  onSubmit={(values: any) => {
                    handleCreateEventFeedback(values);
                  }}
                  formSchema={CrewEventSurveyFormSchema()}
                  dataQaId="crew-feedback-survey-form"
                  paddingTop="15"
                />
              </StyledDetailContentContainer>
            )
          )}
        </StyledDetailsInfoMainContainer>
      </ModalContentContainer>
    </>
  );
};

export default CrewEventSurvey;
