import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { errorMsgForField } from 'app/shared/utils/form';
import {
  FormGroupContainer,
  FormSectionTitle,
} from 'app/shared/components/atoms/FormContent';
import GenericFormContainer from 'app/shared/components/atoms/GenericFormContainer';
import { Textfield } from 'app/shared/components/atoms/Textfield';
import FormGroup from 'app/shared/components/molecules/FormGroup';

interface FormProps {
  formikProps: any;
}

const StyledTextfield = styled(Textfield)`
  ${({ theme }) => css`
    display: block;
    width: 100%;
    ${theme.media.lg`
        width: 48%;
      `};
  `}
`;

const NeighborhoodBasicInfoForm: React.FC<FormProps> = ({ formikProps }) => {
  const intl = useIntl();

  const { setFieldValue, touched, errors, values } = formikProps;

  return (
    <GenericFormContainer dataQaId="neighborhood-edit-form">
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'shared.title',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="neighborhood-edit-form-title-formgroup"
          label={intl.formatMessage({
            id: 'shared.title',
          })}
          errorMsg={errorMsgForField('title', touched, errors)}
          required
        >
          <StyledTextfield
            data-qaid="neighborhood-edit-form-title-field"
            id="title"
            name="title"
            value={values.title}
            onChange={(e: any) => {
              setFieldValue('title', e.target.value);
            }}
            maxLength={40}
          />
        </FormGroup>
      </FormGroupContainer>
    </GenericFormContainer>
  );
};

export default NeighborhoodBasicInfoForm;
