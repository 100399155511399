import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { useCurrentTheme } from 'app/shared/theme';
import { dateFormatter } from 'app/shared/utils/datetime';
import { get } from 'app/shared/utils/get';
import { StreamlineIcon } from 'app/shared/components/atoms/StreamlineIcon';
import TruncatedByCharText from 'app/shared/components/atoms/TruncatedByCharText';
import { H2 } from 'app/shared/components/atoms/TypographyManualCSS';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { GetBookingRequests } from 'app/admin/graphql/bookingRequests/queryHooks';
import { None } from 'app/admin/components/atoms/None';
import ListTable from 'app/admin/components/organisms/ListTable';
import { ReactComponent as CalendarCheck } from 'icons/streamline-regular/interface-essential/date-calendar/calendar-check.svg';
import { ReactComponent as CalendarDisable } from 'icons/streamline-regular/interface-essential/date-calendar/calendar-disable.svg';

interface Props {
  navigateTo: (routeData: any) => void;
  contentProps: any;
  hide: VoidFunction;
}

const MainContainer = styled.div`
  ${({ theme }) => css`
    padding: 10px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: left;

    ${theme.media.md`
      padding: 20px 0px 0px 20px;
    `}
  `}
`;

const Title = styled(H2)`
  ${({ theme }) => css`
    font-size: 16px;
    font-weight: normal;
    line-height: 1.2em;
    margin-bottom: 3px;

    ${theme.media.xl`
      font-size: 34px;
      margin-bottom: 15px;
      margin-left: 0px;
    `};
  `}
`;

const ArtistName = styled.div`
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.4px;
  margin-bottom: 35px;
`;

const BookingRequestList = styled.div`
  margin-bottom: 45px;
`;

const NoBookingRequests = styled.div`
  margin-top: 35px;
`;

const InfoRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.4px;
  margin-bottom: 15px;
`;

const InfoLabel = styled.div`
  font-weight: 600;
  white-space: nowrap;
  min-width: 130px;
`;

const InfoValue = styled.div`
  padding-left: 5px;
`;

const AcceptedIcon: React.FC = () => {
  const theme = useCurrentTheme();

  return (
    <StreamlineIcon
      icon={CalendarCheck}
      size={16}
      stroke={theme.colors.green600}
    />
  );
};

const DeclinedIcon: React.FC = () => {
  const theme = useCurrentTheme();

  return (
    <StreamlineIcon
      icon={CalendarDisable}
      size={16}
      stroke={theme.colors.redRedWine}
    />
  );
};

const BookingRequestTourRequest: React.FC<Props> = ({ contentProps, hide }) => {
  const { artist, bandSetup, notes, createdAt } = contentProps;

  const intl = useIntl();

  const {
    loading: loadingBookingRequests,
    error: errorBookingRequests,
    data: dataBookingRequests,
  } = GetBookingRequests({
    artistId: artist.id,
    availabilityDates: 'end_in_future',
    orderBy: 'availability_dates',
    orderDirection: 'asc',
    page: 1,
    perPage: 1000,
  });

  if (
    (!loadingBookingRequests && !dataBookingRequests) ||
    errorBookingRequests
  ) {
    hide();
    return null;
  }

  const columnsConfig = [
    {
      headerText: intl.formatMessage({
        id: 'shared.city',
      }),
      columnWidth: 150,
    },
    {
      headerText: intl.formatMessage({
        id: 'admin.bookingRequests.artistTourRequest.availability',
      }),
      columnWidth: 190,
    },
    {
      headerText: intl.formatMessage({
        id: 'admin.bookingRequests.artistTourRequest.accepted',
      }),
      columnWidth: 80,
      isCentered: true,
    },
  ];

  const rowValues = (bookingRequest: any) => [
    <TruncatedByCharText
      text={bookingRequest.city.title}
      truncateLength={16}
      key={1}
    />,
    bookingRequest.availabilityStart && bookingRequest.availabilityEnd
      ? dateFormatter(
          bookingRequest.availabilityStart,
          'shortMonthDateAndYear'
        ) +
        ' - ' +
        dateFormatter(bookingRequest.availabilityEnd, 'shortMonthDateAndYear')
      : intl.formatMessage({
          id: 'admin.bookingRequests.artistTourRequest.notEntered',
        }),
    bookingRequest.status == 'accepted' ? (
      <AcceptedIcon />
    ) : bookingRequest.status == 'declined' ? (
      <DeclinedIcon />
    ) : null,
  ];

  return (
    <ModalContentContainer data-qaid="artist-tour-request-modal">
      <MainContainer>
        <Title>
          {intl.formatMessage({
            id: 'admin.bookingRequests.artistTourRequest.tourRequest',
          })}
        </Title>
        <ArtistName>{artist.title}</ArtistName>
        <BookingRequestList>
          <ListTable
            columnsConfig={columnsConfig}
            rowValues={rowValues}
            data={get(
              dataBookingRequests,
              'bookingRequests.bookingRequests',
              []
            )}
            noResultsContent={
              get(dataBookingRequests, 'bookingRequests.bookingRequests', []) &&
              get(dataBookingRequests, 'bookingRequests.bookingRequests', [])
                .length == 0 && (
                <NoBookingRequests>
                  {intl.formatMessage({
                    id:
                      'admin.bookingRequests.artistTourRequest.noOtherBookingRequests',
                  })}
                </NoBookingRequests>
              )
            }
          />
        </BookingRequestList>
        <InfoRow>
          <InfoLabel>
            {intl.formatMessage({
              id: 'admin.shared.submitted',
            })}
            :
          </InfoLabel>
          <InfoValue>
            {dateFormatter(createdAt, 'shortMonthDateAndYear')}
          </InfoValue>
        </InfoRow>
        <InfoRow>
          <InfoLabel>
            {intl.formatMessage({
              id: 'shared.email',
            })}
            :
          </InfoLabel>
          <InfoValue>{artist.email}</InfoValue>
        </InfoRow>
        <InfoRow>
          <InfoLabel>
            {intl.formatMessage({
              id: 'admin.bookingRequests.artistTourRequest.bandSetup',
            })}
            :
          </InfoLabel>
          <InfoValue>{bandSetup || <None />}</InfoValue>
        </InfoRow>
        <InfoRow>
          <InfoLabel>
            {intl.formatMessage({
              id: 'admin.bookingRequests.artistTourRequest.notes',
            })}
            :
          </InfoLabel>
          <InfoValue>
            {notes || (
              <None>
                {intl.formatMessage({
                  id: 'none',
                })}
              </None>
            )}
          </InfoValue>
        </InfoRow>
      </MainContainer>
    </ModalContentContainer>
  );
};

export default BookingRequestTourRequest;
