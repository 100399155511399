import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import {
  OutlineButton,
  PrimaryButton,
} from 'app/shared/components/atoms/Button';

const ActionButtonsContainer = styled.div`
  ${({ theme }) => css`
    position: sticky;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 74px;
    background: #000;
    display: flex;
    flex-direction: row;
    padding: 15px;
    justify-content: space-between;
    z-index: 10;

    ${theme.media.xs`
    width: 100%;
  `}

    ${theme.media.xl`
    width: 100%;
  `}
  `}
`;

const LeftButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const RightButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const BackButton = styled(OutlineButton)`
  ${({ theme }) => css`
    margin-right: 10px;

    ${theme.media.xs`
      border-radius: ${theme.borderRadius.buttonSmall};
      max-width: 125px;
      height: 44px;
      padding: 0 10px;
      font-size: ${theme.fontSizes.button.xs};
      line-height: 15px;
    `}

    ${theme.media.sm`
      border-radius: ${theme.borderRadius.button};
      max-width: 100%;
      height: 44px;
      padding: 0 25px;
      font-size: ${theme.fontSizes.button.lg};
      line-height: 19px;
    `}
  `}
`;

const ForwardButton = styled(PrimaryButton)`
  ${({ theme }) => css`
    margin-right: 10px;

    ${theme.media.xs`
      border-radius: ${theme.borderRadius.buttonSmall};
      max-width: 125px;
      height: 44px;
      padding: 0 10px;
      font-size: ${theme.fontSizes.button.xs};
      line-height: 15px;
    `}

    ${theme.media.sm`
      border-radius: ${theme.borderRadius.button};
      max-width: 100%;
      height: 44px;
      padding: 0 25px;
      font-size: ${theme.fontSizes.button.lg};
      line-height: 19px;
    `}
  `}
`;

const SecondarySubmitButton = styled(PrimaryButton)`
  ${({ theme }) => css`
    margin-right: 10px;

    ${theme.media.xs`
      border-radius: ${theme.borderRadius.buttonSmall};
      max-width: 125px;
      height: 44px;
      padding: 0 10px;
      font-size: ${theme.fontSizes.button.xs};
      line-height: 15px;
    `}

    ${theme.media.sm`
      border-radius: ${theme.borderRadius.button};
      max-width: 100%;
      height: 44px;
      padding: 0 25px;
      font-size: ${theme.fontSizes.button.lg};
      line-height: 19px;
    `}
  `}
`;

const SubmitButton = styled(PrimaryButton)`
  ${({ theme }) => css`
    margin-right: 10px;

    ${theme.media.xs`
      border-radius: ${theme.borderRadius.buttonSmall};
      max-width: 125px;
      height: 44px;
      padding: 0 10px;
      font-size: ${theme.fontSizes.button.xs};
      line-height: 15px;
    `}

    ${theme.media.sm`
      border-radius: ${theme.borderRadius.button};
      max-width: 100%;
      height: 44px;
      padding: 0 25px;
      font-size: ${theme.fontSizes.button.lg};
      line-height: 19px;
    `}
  `}
`;

const setButtonConfigDefaults = (
  buttonConfig: ButtonConfig,
  defaultTextKey: string
) => ({
  textKey: buttonConfig.hasOwnProperty('textKey')
    ? buttonConfig.textKey
    : defaultTextKey,
  onClick: buttonConfig.hasOwnProperty('onClick')
    ? buttonConfig.onClick
    : () => {},
  displayed: buttonConfig.hasOwnProperty('displayed')
    ? buttonConfig.displayed
    : false,
  disabled: buttonConfig.hasOwnProperty('disabled')
    ? buttonConfig.disabled
    : false,
  loading: buttonConfig.hasOwnProperty('loading')
    ? buttonConfig.loading
    : false,
});

export interface ButtonConfig {
  textKey?: string;
  onClick?: VoidFunction;
  displayed?: boolean;
  disabled?: boolean;
  loading?: boolean;
}

interface Props {
  backButtonConfig?: ButtonConfig;
  forwardButtonConfig?: ButtonConfig;
  secondarySubmitButtonConfig?: ButtonConfig;
  submitButtonConfig?: ButtonConfig;
  dataQaidSuffix: string;
}

export const ModalFooter: React.FC<Props> = ({
  backButtonConfig = {},
  forwardButtonConfig = {},
  secondarySubmitButtonConfig = {},
  submitButtonConfig = {},
  dataQaidSuffix = {},
}) => {
  const intl = useIntl();

  backButtonConfig = setButtonConfigDefaults(backButtonConfig, 'form.back');
  forwardButtonConfig = setButtonConfigDefaults(
    forwardButtonConfig,
    'form.next'
  );
  secondarySubmitButtonConfig = setButtonConfigDefaults(
    secondarySubmitButtonConfig,
    'form.saveAndAddMore'
  );
  submitButtonConfig = setButtonConfigDefaults(submitButtonConfig, 'form.save');

  return (
    <ActionButtonsContainer>
      <LeftButtonsContainer>
        {backButtonConfig.displayed && (
          <BackButton
            data-qaid="footer-back-button"
            color="white"
            disabled={backButtonConfig.disabled}
            onClick={() =>
              backButtonConfig.onClick && backButtonConfig.onClick()
            }
          >
            {intl.formatMessage({
              id: backButtonConfig.textKey,
            })}
          </BackButton>
        )}
      </LeftButtonsContainer>
      {(forwardButtonConfig.displayed ||
        secondarySubmitButtonConfig.displayed ||
        submitButtonConfig.displayed) && (
        <RightButtonsContainer>
          {forwardButtonConfig.displayed && (
            <ForwardButton
              data-qaid="footer-forward-button"
              onClick={() =>
                forwardButtonConfig.onClick && forwardButtonConfig.onClick()
              }
              type="button"
              loading={forwardButtonConfig.loading}
              disabled={forwardButtonConfig.disabled}
            >
              {intl.formatMessage({
                id: forwardButtonConfig.textKey,
              })}
            </ForwardButton>
          )}
          {secondarySubmitButtonConfig.displayed && (
            <SecondarySubmitButton
              data-qaid="footer-secondary-submit-button"
              onClick={() =>
                secondarySubmitButtonConfig.onClick &&
                secondarySubmitButtonConfig.onClick()
              }
              type="submit"
              loading={secondarySubmitButtonConfig.loading}
              disabled={secondarySubmitButtonConfig.disabled}
            >
              {intl.formatMessage({
                id: secondarySubmitButtonConfig.textKey,
              })}
            </SecondarySubmitButton>
          )}
          {submitButtonConfig.displayed && (
            <SubmitButton
              data-qaid={`footer-submit-button-${dataQaidSuffix}`}
              onClick={() =>
                submitButtonConfig.onClick && submitButtonConfig.onClick()
              }
              type="submit"
              loading={submitButtonConfig.loading}
              disabled={submitButtonConfig.disabled}
            >
              {intl.formatMessage({
                id: submitButtonConfig.textKey,
              })}
            </SubmitButton>
          )}
        </RightButtonsContainer>
      )}
    </ActionButtonsContainer>
  );
};
