import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { EventPreview, EventStaffMember, User } from 'app/typings';
import { Artist } from 'app/typings/artists';
import { get } from 'app/shared/utils/get';
import useModal from 'app/shared/utils/useModal';
import { PrimaryButton } from 'app/shared/components/atoms/Button';
import { FormGroupContainer } from 'app/shared/components/atoms/FormContent';
import GenericFormContainer from 'app/shared/components/atoms/GenericFormContainer';
import { Spinner } from 'app/shared/components/atoms/SpinnerManualCSS';
import { Textarea } from 'app/shared/components/atoms/Textarea';
import ConfirmationModal from 'app/shared/components/molecules/ConfirmationModal';
import FormGroup from 'app/shared/components/molecules/FormGroup';
import RadioGroup from 'app/shared/components/molecules/RadioGroup';
import { GetFeedbackPhrases } from 'app/admin/graphql/feedbacks/queryHooks';

import AudienceReactionArtists from './AudienceReactionArtists';
import AudienceReactionMC from './AudienceReactionMC';
import AudienceReactionVenue from './AudienceReactionVenue';
import ConcertRating from './ConcertRating';
import CrewEventSurveyCard, {
  CrewEventSurveyCardSubTitle,
  CrewEventSurveyCardTitle,
} from './CrewEventSurveyCard';

interface FormProps {
  formikProps: any;
  setFormSubmitAction?: (func: Function) => void;
  setDisplayConfirmation?: (set: boolean) => void;
  setCloseAction?: (func: Function) => void;
  setIsSubmitting?: (set: boolean) => void;
  navigateTo?: any;
  isSubmitting: boolean;
  eventInfo?: any;
  eventsPendingFeedback?: any;
}

const LongSpacer = styled.div`
  margin-top: 300px;
`;

const crewEventSurveyVenueComfortOptions = () => {
  return [
    { title: 'Poor', value: 'negative' },
    { title: 'Neutral', value: 'neutral' },
    { title: 'Excellent', value: 'positive' },
  ];
};

const CrewEventSurveyForm: React.FC<FormProps> = ({
  formikProps,
  setFormSubmitAction,
  setCloseAction,
  setDisplayConfirmation,
  setIsSubmitting,
  isSubmitting,
  navigateTo,
  eventInfo,
  eventsPendingFeedback,
}) => {
  useEffect(() => {
    setFormSubmitAction && setFormSubmitAction(() => formikProps.submitForm);
    setDisplayConfirmation && setDisplayConfirmation(formikProps.dirty);
    setIsSubmitting && setIsSubmitting(formikProps.isSubmitting);
  }, [
    formikProps.isSubmitting,
    formikProps.submitForm,
    formikProps.dirty,
    setFormSubmitAction,
    setDisplayConfirmation,
    setIsSubmitting,
  ]);

  useEffect(() => {
    setCloseAction &&
      setCloseAction(() => {
        return () =>
          navigateTo({
            routeName: 'crew-feedback-events',
            routeProps: {
              eventsPendingFeedback,
            },
          });
      });
  }, []);

  useEffect(() => {
    if (Object.keys(formikProps.errors).length > 0 && isSubmitting) {
      setIsSubmitting && setIsSubmitting(false);
    }
  }, [formikProps.errors, isSubmitting, setIsSubmitting]);

  const intl = useIntl();
  const [
    crewFeedbackConfirmationModal,
    toggleCrewFeedbackConfirmationModal,
  ] = useModal();
  const {
    setFieldValue,
    values,
    errors,
    touched,
    submitForm,
    dirty,
  } = formikProps;
  const [negativeArtistTitles, setNegativeArtistTitles] = useState('');

  const errorMsgForField = (field: string, touched: any, errors: any) =>
    touched[field] && errors[field] ? errors[field] : null;

  const { loading, data } = GetFeedbackPhrases();

  return loading ? (
    <Spinner />
  ) : (
    <GenericFormContainer dataQaId="crew-event-survey-form">
      <FormGroupContainer>
        <FormGroup
          label=""
          data-qaid="crew-event-survey-form-event-rating"
          errorMsg={errorMsgForField('eventRating', touched, errors)}
        >
          <ConcertRating
            val={values.eventRating}
            onChange={(value: any) => setFieldValue('eventRating', value)}
          />
        </FormGroup>
      </FormGroupContainer>
      <FormGroupContainer>
        <FormGroup
          label=""
          data-qaid="crew-event-survey-form-artist-feedback"
          errorMsg={errorMsgForField('artistFeedback', touched, errors)}
        >
          <AudienceReactionArtists
            artists={eventInfo?.performances.map((p: any) => p.artist)}
            onChangeOverallFeeling={(artist: Artist, option: string) => {
              const newArtistFeedback = values.artistFeedback;
              if (!newArtistFeedback[artist.id]) {
                newArtistFeedback[artist.id] = {};
              }
              newArtistFeedback[artist.id].overallFeeling = option;
              setFieldValue('artistFeedback', newArtistFeedback);
            }}
            onChangeFeedbackString={(artist: Artist, option: any) => {
              const newArtistFeedback = values.artistFeedback;
              newArtistFeedback[artist.id].feedbackPhraseId = option;
              setFieldValue('artistFeedback', newArtistFeedback);
            }}
            selectedValues={values.artistFeedback}
            feedbackPhrases={get(
              data,
              'feedbackPhrases.feedbackPhrases',
              undefined
            )}
          />
        </FormGroup>
      </FormGroupContainer>
      <FormGroupContainer>
        <FormGroup
          label=""
          data-qaid="crew-event-survey-form-mc"
          errorMsg={errorMsgForField('mcOverallFeeling', touched, errors)}
        >
          <AudienceReactionMC
            emcee={getEmceeFromEvent(eventInfo)}
            onChange={(option: string) =>
              setFieldValue('mcOverallFeeling', option)
            }
            selectedValue={values.mcOverallFeeling}
          />
        </FormGroup>
      </FormGroupContainer>
      <FormGroupContainer>
        <FormGroup
          label=""
          data-qaid="crew-event-survey-form-venue"
          errorMsg={errorMsgForField('venueOverallFeeling', touched, errors)}
        >
          <AudienceReactionVenue
            venue={eventInfo?.venue}
            onChange={(option: string) =>
              setFieldValue('venueOverallFeeling', option)
            }
            selectedValue={values.venueOverallFeeling}
          />
        </FormGroup>
      </FormGroupContainer>
      <FormGroupContainer>
        <FormGroup
          label=""
          data-qaid="crew-event-survey-form-venue-comfort"
          errorMsg={errorMsgForField(
            'venueComfortOverallFeeling',
            touched,
            errors
          )}
        >
          <CrewEventSurveyCard>
            <CrewEventSurveyCardTitle>
              {intl.formatMessage({
                id: 'admin.crewPortal.crewFeedback.toVenueComfort',
              })}
            </CrewEventSurveyCardTitle>
            <RadioGroup
              name="venue-comfort"
              options={crewEventSurveyVenueComfortOptions()}
              selectedValue={values.venueComfortOverallFeeling}
              onChange={(option: string) =>
                setFieldValue('venueComfortOverallFeeling', option)
              }
            />
          </CrewEventSurveyCard>
        </FormGroup>
      </FormGroupContainer>
      <FormGroupContainer>
        <FormGroup
          label=""
          data-qaid="crew-event-survey-form-event-notes"
          errorMsg={errorMsgForField('eventFeedbackNotes', touched, errors)}
        >
          <CrewEventSurveyCard>
            <CrewEventSurveyCardTitle>
              {intl.formatMessage({
                id: 'admin.crewPortal.crewFeedback.anythingElseToShare',
              })}
            </CrewEventSurveyCardTitle>
            <CrewEventSurveyCardSubTitle>
              {intl.formatMessage({
                id: 'admin.crewPortal.crewFeedback.includeRecommendations',
              })}
            </CrewEventSurveyCardSubTitle>
            <Textarea
              id="anythingElseShare"
              name="anythingElseShare"
              onChange={opt =>
                setFieldValue('eventFeedbackNotes', opt.target.value)
              }
              value={values.eventFeedbackNotes}
            />
          </CrewEventSurveyCard>
        </FormGroup>
      </FormGroupContainer>

      <PrimaryButton
        data-qaid="submit-button"
        loading={isSubmitting}
        type="submit"
        onClick={() => {
          const negativeArtists = Object.values(values.artistFeedback).filter(
            (feedbackVal: any) => feedbackVal.overallFeeling === 'negative'
          );
          if (negativeArtists.length > 0) {
            setNegativeArtistTitles(
              negativeArtists.map((artist: any) => artist.title).join(', ')
            );
            toggleCrewFeedbackConfirmationModal();
          } else {
            submitForm();
          }
        }}
        disabled={Object.keys(errors).length > 0 || !dirty}
      >
        {intl.formatMessage({ id: 'form.submit' })}
      </PrimaryButton>
      <LongSpacer />
      {crewFeedbackConfirmationModal.isShowing && (
        <ConfirmationModal
          onCancel={() => crewFeedbackConfirmationModal.hide()}
          confirmationButtonText="Yes"
          onConfirm={() => {
            crewFeedbackConfirmationModal.hide();
            submitForm();
          }}
          description={intl.formatMessage(
            {
              id: 'admin.crewPortal.crewFeedback.confirmationMessage',
            },
            {
              artistTitles: negativeArtistTitles,
            }
          )}
        />
      )}
    </GenericFormContainer>
  );
};

const getEmceeFromEvent = (eventInfo: EventPreview): User => {
  const staffMembers = get(eventInfo, 'staff', []);
  const staffMember = staffMembers.find(
    (staffMember: EventStaffMember) => staffMember.role.key === 'crew_emcee'
  );
  return staffMember?.user;
};

export default CrewEventSurveyForm;
