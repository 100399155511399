import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { City } from 'app/typings/cities';
import { AddIcon } from 'app/shared/components/atoms/IconLibrary';
import TruncatedByCharText from 'app/shared/components/atoms/TruncatedByCharText';
import { DetailHeading as Heading } from 'app/admin/components/atoms/DetailContent';
import ListTable from 'app/admin/components/organisms/ListTable';

interface Props {
  sectionData: City;
  callbacks: { [name: string]: Function };
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const NeighborhoodHeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 20px;
  white-space: nowrap;
`;

const NeighborhoodHeading = styled(Heading)`
  margin-bottom: 0;
`;

const AddIconWrapper = styled.div`
  margin-left: 12px;
  margin-top: 2px;
`;

const NoNeighborhoods = styled.div`
  white-space: nowrap;
  width: 100%;
`;

const SectionNeighborhoods: React.FC<Props> = ({ sectionData, callbacks }) => {
  const intl = useIntl();

  const columnsConfig = [
    {
      headerText: intl.formatMessage({
        id: 'shared.neighborhood',
      }),
      columnWidth: 250,
    },
    {
      headerText: intl.formatMessage({
        id: 'shared.venues',
      }),
      columnWidth: 80,
    },
  ];

  const rowValues = (neighborhood: any) => [
    <TruncatedByCharText
      text={neighborhood.title}
      truncateLength={28}
      key={1}
    />,
    neighborhood.venuesCount,
  ];

  return (
    <MainContainer data-qaid="section-neighborhood">
      <NeighborhoodHeadingContainer>
        <NeighborhoodHeading>
          {intl.formatMessage(
            {
              id: 'admin.cityNeighborhoods.title',
            },
            {
              count: sectionData.neighborhoodsFullInfo
                ? sectionData.neighborhoodsFullInfo.length
                : 0,
            }
          )}
        </NeighborhoodHeading>
        <AddIconWrapper>
          <AddIcon onClick={() => callbacks.toggleNeighborhoodCreateModal()} />
        </AddIconWrapper>
      </NeighborhoodHeadingContainer>
      <ListTable
        columnsConfig={columnsConfig}
        rowValues={rowValues}
        onEdit={(obj: any) =>
          callbacks.toggleNeighborhoodEditModal({
            id: obj.id,
            title: obj.title,
          })
        }
        onDelete={(obj: any) =>
          callbacks.toggleNeighborhoodDeleteAndSetNeighborhoodData({
            id: obj.id,
            title: obj.title,
          })
        }
        showDelete={(obj: any) => obj.venuesCount == 0}
        data={sectionData.neighborhoodsFullInfo}
        noResultsContent={
          sectionData.neighborhoodsFullInfo &&
          // @ts-ignore
          sectionData.neighborhoodsFullInfo.length == 0 && (
            <NoNeighborhoods>
              {intl.formatMessage({
                id: 'admin.cityNeighborhoods.noNeighborhoods',
              })}
            </NoNeighborhoods>
          )
        }
      />
    </MainContainer>
  );
};

export default SectionNeighborhoods;
