import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { get } from 'app/shared/utils/get';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { UpdateCampaign } from 'app/admin/graphql/campaigns/mutationHooks';
import { DetailsFormMainContainer } from 'app/admin/components/atoms/DetailContent';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

import CampaignEditForm from './CampaignEditForm';
import CampaignEditFormSchema from './CampaignEditFormSchema';

interface Props {
  contentProps?: any;
  setFormSubmitAction: (func: Function) => void;
  setDisplayConfirmation: (set: boolean) => void;
  setIsSubmitting: (set: boolean) => void;
  hide: VoidFunction;
}

const CampaignEdit: React.FC<Props> = ({
  contentProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  hide,
}) => {
  const intl = useIntl();

  const [validationErrors, setValidationErrors] = useState<object>({});

  const formInitialValues = {
    title: get(contentProps, 'title', ''),
    partnerId: get(contentProps, 'partner.id', null),
    agencyId: get(contentProps, 'agency.id', null),
    accountManagerUser: get(contentProps, 'accountManagerUser', undefined),
    accountManagerUserIdToAssociate: undefined,
    unassociateAccountManagerUserId: false,
    salesRepUser: get(contentProps, 'salesRepUser', undefined),
    salesRepUserIdToAssociate: undefined,
    unassociateSalesRepUserId: false,
  };

  const updateCampaignAction = UpdateCampaign();

  const handleSubmit = useSubmitAction({
    submitAction: updateCampaignAction,
    submitVariables: (values: any) => ({
      campaignId: get(contentProps, 'id', undefined),
      title: values.title,
      partnerId: values.partnerId,
      agencyId: values.agencyId,
      accountManagerUserId:
        values.accountManagerUserIdToAssociate ||
        (values.unassociateAccountManagerUserId
          ? null
          : values.accountManagerUser
          ? values.accountManagerUser.id
          : null),
      salesRepUserId:
        values.salesRepUserIdToAssociate ||
        (values.unassociateSalesRepUserId
          ? null
          : values.salesRepUser
          ? values.salesRepUser.id
          : null),
    }),
    successMsg: intl.formatMessage({
      id: 'admin.campaignEdit.successMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.campaignEdit.failureMessage',
    }),
    onSuccess: () => {
      contentProps.refetchCampaigns();
      hide();
    },
    onValidationError: validationErrors =>
      setValidationErrors(validationErrors),
  });

  return (
    <ModalContentContainer>
      <DetailsFormMainContainer>
        <DetailsHeader
          title={`${intl.formatMessage({
            id: 'admin.campaignEdit.title',
          })} ${get(contentProps, 'title', '')}`}
        />
        <GenericForm
          formInitialValues={formInitialValues}
          renderFormComponent={(renderProps: any) => (
            <CampaignEditForm
              formikProps={renderProps.formikProps}
              validationErrors={validationErrors}
            />
          )}
          onSubmit={handleSubmit}
          formSchema={CampaignEditFormSchema(intl)}
          setFormSubmitAction={setFormSubmitAction}
          setDisplayConfirmation={setDisplayConfirmation}
          setIsSubmitting={setIsSubmitting}
          dataQaId="campaign-edit-form"
        />
      </DetailsFormMainContainer>
    </ModalContentContainer>
  );
};

export default CampaignEdit;
