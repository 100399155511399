import React from 'react';
import { useIntl } from 'react-intl';

import { dateFormatter } from 'app/shared/utils/datetime';
import { snakecaseToTitleCase } from 'app/shared/utils/string';
import {
  cityOperatingModelLabelsShort,
  sofarOperatedMarketTypeLabelsShort,
} from 'app/admin/utils/cities';
import ListingCard from 'app/admin/components/organisms/ListingCard';
import { ReactComponent as MapsPin2 } from 'icons/streamline-regular/maps-navigation/maps/maps-pin-2.svg';

const CityCard: React.FC<any> = ({
  id,
  title,
  latitude,
  longitude,
  cityOperatingModel,
  sofarOperatedMarketType,
  cityStatus,
  createdAt,
  country,
  businessOwner,
  onShowDetails,
}) => {
  const intl = useIntl();

  const keysAndValuesList = [
    {
      key: intl.formatMessage({
        id: 'admin.cities.listingCard.cityOperatingModel',
      }),
      value: cityOperatingModelLabelsShort[cityOperatingModel],
    },
    {
      key: intl.formatMessage({
        id: 'admin.cities.listingCard.sofarOperatedMarketType',
      }),
      value:
        cityOperatingModel == 'curator'
          ? intl.formatMessage({ id: 'admin.notApplicable' })
          : sofarOperatedMarketType
          ? sofarOperatedMarketTypeLabelsShort[sofarOperatedMarketType]
          : 'None',
    },
    {
      key: intl.formatMessage({
        id: 'admin.cities.listingCard.businessOwner',
      }),
      value:
        cityOperatingModel == 'curator'
          ? intl.formatMessage({ id: 'admin.notApplicable' })
          : businessOwner
          ? businessOwner.title
          : 'None',
    },
    {
      key: intl.formatMessage({
        id: 'admin.cities.listingCard.cityStatus',
      }),
      value: snakecaseToTitleCase(cityStatus),
    },
    {
      key: intl.formatMessage({
        id: 'admin.cities.listingCard.citySince',
      }),
      value: dateFormatter(createdAt, 'shortMonthDateAndYear'),
    },
  ];

  const controlsInfoList = [
    {
      href: `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`,
      icon: MapsPin2,
      tipText: intl.formatMessage({
        id: 'admin.cities.cityMap',
      }),
    },
  ];

  const onShowDetailsParams = {
    id,
    title,
  };

  return (
    <ListingCard
      title={title}
      subtitle={country.title}
      keysAndValuesList={keysAndValuesList}
      dataQaidPrefix="city"
      height="240px"
      controlsInfoList={controlsInfoList}
      onShowDetails={onShowDetails}
      onShowDetailsParams={onShowDetailsParams}
      showDetailsTipText={intl.formatMessage({
        id: 'admin.cities.cityDetails',
      })}
    />
  );
};

export default CityCard;
