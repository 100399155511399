export const sortOptions = [
  {
    title: 'Curator Group Name (A - Z)',
    value: {
      by: 'name',
      direction: 'asc',
    },
  },
  {
    title: 'Curator Group Name (Z - A)',
    value: {
      by: 'name',
      direction: 'desc',
    },
  },
];

export const curatorGroupTypeOptions = [
  {
    title: 'Commercial',
    value: 'commercial',
  },
  {
    title: 'Non-commercial',
    value: 'non_commercial',
  },
];

export const statusOptions = [
  {
    title: 'Active',
    value: 'active',
  },
  {
    title: 'Inactive',
    value: 'inactive',
  },
];
