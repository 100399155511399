import React from 'react';
import { useIntl } from 'react-intl';

import { errorMsgForField } from 'app/shared/utils/form';
import { FormGroupContainer } from 'app/shared/components/atoms/FormContent';
import { Textfield } from 'app/shared/components/atoms/Textfield';
import FormGroup from 'app/shared/components/molecules/FormGroup';

import { CollectionBasicInfoFormSectionHeader } from './components';
import { CollectionBasicInfoFormikProps } from './typings';

export const TitleSection: React.FC<CollectionBasicInfoFormikProps> = ({
  touched,
  setTouched,
  errors,
  values,
  setFieldValue,
}) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <CollectionBasicInfoFormSectionHeader
        titleIntlId="admin.collectionBasicInfo.form.title"
        descriptionIntlId="admin.collectionBasicInfo.form.titleDescription"
      />
      <FormGroupContainer>
        <FormGroup
          data-qaid="collection-about-section-edit-form-title-formgroup"
          label={formatMessage({
            id: 'admin.collectionBasicInfo.form.titleLabel',
          })}
          errorMsg={errorMsgForField('title', touched, errors)}
          required
        >
          <Textfield
            data-qaid="collection-about-section-edit-form-title-field"
            id="title"
            name="title"
            value={values.title}
            onChange={(e: any) => {
              setTouched({ ...touched, title: true });
              setFieldValue('title', e.target.value);
            }}
            maxLength={200}
          />
        </FormGroup>
      </FormGroupContainer>
    </>
  );
};
