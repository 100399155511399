import React from 'react';
import styled, { css } from 'styled-components';

import { KeyValue } from 'app/typings/generics';
import { convertToQaId } from 'app/shared/utils/string';
import { BaseLink } from 'app/shared/components/atoms/LinkManualCSS';
import TruncatedByCharText from 'app/shared/components/atoms/TruncatedByCharText';
import {
  Body2,
  Overline,
} from 'app/shared/components/atoms/TypographyManualCSS';

interface Props {
  keysAndValues: KeyValue[];
  valueTruncateCharLength?: number;
  containerHeight?: string;
}

interface ValueProps {
  wrapValue?: boolean;
  height?: string;
}

const KeyValueContainer = styled.div<ValueProps>`
  height: ${props => props.height || (props.wrapValue ? '80px' : '50px')};
`;

const ValueContainer = styled(Body2)<ValueProps>`
  ${({ theme, wrapValue }) => css`
    color: ${theme.colors.backToBlack};
    white-space: ${wrapValue ? 'normal' : 'nowrap'};

    ${theme.media.xs`
      font-size: 12px;
    `}
  `}
`;

const ValueWithActionContainer = styled(ValueContainer)`
  ${({ theme }) => css`
    color: ${theme.colors.green600};
    cursor: pointer;
  `}
`;

const Link = styled(BaseLink)`
  ${({ theme }) => css`
    color: ${theme.colors.green600};
  `}
`;

export const KeyAboveValueInfoList: React.FC<Props> = ({
  keysAndValues,
  valueTruncateCharLength = 23,
  containerHeight,
}) => {
  return (
    <div>
      {keysAndValues.map(obj => (
        <KeyValueContainer
          wrapValue={obj.wrapValue}
          key={obj.key}
          height={containerHeight}
        >
          <Overline>{obj.key}</Overline>
          {obj.action ? (
            <ValueWithActionContainer
              wrapValue={obj.wrapValue}
              data-qaid={`key-value-list-item-${convertToQaId(obj.key)}`}
              onClick={obj.action}
            >
              <TruncatedByCharText
                text={obj.value}
                truncateLength={
                  obj.valueTruncateCharLength || valueTruncateCharLength
                }
              />
            </ValueWithActionContainer>
          ) : (
            <ValueContainer
              wrapValue={obj.wrapValue}
              data-qaid={`key-value-list-item-${convertToQaId(obj.key)}`}
            >
              {obj.url ? (
                <Link href={obj.url} openInNewTab={true}>
                  <TruncatedByCharText
                    text={obj.value}
                    truncateLength={
                      obj.valueTruncateCharLength || valueTruncateCharLength
                    }
                    isLink={true}
                  />
                </Link>
              ) : (
                <TruncatedByCharText
                  text={obj.value}
                  truncateLength={
                    obj.valueTruncateCharLength || valueTruncateCharLength
                  }
                />
              )}
            </ValueContainer>
          )}
        </KeyValueContainer>
      ))}
    </div>
  );
};
