import React, { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { getIdsSubmitVariable } from 'app/shared/utils/form';
import { get } from 'app/shared/utils/get';
import { GetCitiesLite } from 'app/shared/graphql/cities/queryHooks';
import { ListPage, ListPageContext } from 'app/shared/context/ListPage';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import { LoadingBlocks } from 'app/shared/components/atoms/LoadingBlocks';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { GlobalError } from 'app/shared/components/pages/Status';
import { CityInfoForLimitingByCity } from 'app/admin/utils/cityPermissions';
import {
  filterInfoListFilteredByCheckOnFilterName,
  getCityOptions,
  getFilterNamesAndOptionsForTitleMapping,
} from 'app/admin/utils/optionLists';
import { GetVenueApplications } from 'app/admin/graphql/venueApplications/queryHooks';
import { CityTitles as CityTitlesLimitedBy } from 'app/admin/components/atoms/CityTitles';
import ListingFooter from 'app/admin/components/molecules/ListingFooter';
import ListingNoResults from 'app/admin/components/molecules/ListingNoResults';
import CardGrid, { CardType } from 'app/admin/components/organisms/CardGrid';
import ListingControls from 'app/admin/components/organisms/ListingControls';
import ListingFilter from 'app/admin/components/organisms/ListingFilter';
import ListingHeader from 'app/admin/components/organisms/ListingHeader';
import VenueApplicationCard from 'app/admin/components/organisms/VenueApplicationCard';
import ListPageTemplate from 'app/admin/components/templates/ListPage';
import Layout from 'app/admin/layouts/ListPage';

import { sortOptions, staticOptions } from './options';
import Analytics from './tracking';

const pageStateConfig = {
  filterNames: ['city', 'archived_status', 'capacity'],
  defaultSort: { by: 'created_at', direction: 'desc' },
  textSearchParamName: 'venue_application_search',
};

const AdminVenueApplications: React.FC = () => {
  const intl = useIntl();
  const pageState = useContext(ListPageContext);
  const PER_PAGE = 18;

  const {
    loading: loadingCities,
    error: errorCities,
    data: dataCities,
  } = GetCitiesLite();

  const { cityIdsToLimitBy, cityTitlesToLimitBy } = CityInfoForLimitingByCity(
    'venueApplication.viewByCity',
    dataCities
  );

  const cityOptions = getCityOptions(dataCities);

  const filterDropdownOptionsInfoList = filterInfoListFilteredByCheckOnFilterName(
    [
      {
        filterName: 'city',
        dropdownParams: {
          // @ts-ignore
          searchBar: true,
          title: intl.formatMessage({
            id: 'admin.venueApplications.filter.city',
          }),
          groupBy: 'country',
          options: cityOptions,
        },
      },
      {
        filterName: 'archived_status',
        dropdownParams: {
          title: intl.formatMessage({
            id: 'admin.venueApplications.filter.archivedApproved',
          }),
          options: staticOptions.archivedStatus,
        },
      },
      {
        filterName: 'capacity',
        dropdownParams: {
          title: intl.formatMessage({
            id: 'shared.capacity',
          }),
          options: staticOptions.capacityRanges,
        },
      },
    ],
    {
      city: !cityIdsToLimitBy,
    }
  );

  const {
    loading,
    error,
    data,
    refetch: refetchVenueApplications,
  } = GetVenueApplications({
    city: !cityIdsToLimitBy ? pageState.filterListVariable('city') : undefined,
    cityIds: getIdsSubmitVariable(cityIdsToLimitBy),
    archivedStatus:
      pageState.filterListVariable('archived_status') || 'not_archived',
    capacity: pageState.filterListVariable('capacity'),
    venueApplicationSearch: pageState.textSearchState,
    orderBy: pageState.sortState.by,
    orderDirection: pageState.sortState.direction,
    page: pageState.page,
    perPage: PER_PAGE,
  });

  useEffect(() => {
    pageState.setupFilterLabelTitleMapping(
      getFilterNamesAndOptionsForTitleMapping(filterDropdownOptionsInfoList)
    );
  }, [dataCities]);

  useEffect(() => {
    pageState.updatePageUrl();
  }, [
    pageState.page,
    pageState.sortState,
    pageState.filterState,
    pageState.textSearchState,
    pageState.detailData,
    pageState.detailsModal.isShowing,
  ]);

  useEffect(() => {
    Analytics.pushDataLayer();
  }, [pageState.filterState, pageState.textSearchState]);

  if (
    (!loading && !data) ||
    error ||
    (!loadingCities && !dataCities) ||
    errorCities
  ) {
    return <GlobalError />;
  }

  return (
    <Layout scrollDisabled={pageState.detailsModal.isShowing}>
      <ListPageTemplate
        pageTitle={intl.formatMessage({
          id: 'admin.venueApplications.pageTitle',
        })}
      >
        <ListingHeader
          pageTitle={intl.formatMessage({
            id: 'admin.venueApplications.pageTitle',
          })}
          searchPlaceholder={intl.formatMessage({
            id: 'admin.venueApplications.textSearch.placeholder',
          })}
          searchValue={pageState.textSearchState}
          onSearch={pageState.handleTextSearch}
          dataQaidPrefix="venue-application"
        />
        <CityTitlesLimitedBy cityTitles={cityTitlesToLimitBy} />
        <div>
          <ListingControls
            sortOptions={sortOptions}
            orderBy={pageState.sortState.by}
            orderDirection={pageState.sortState.direction}
            totalRecords={data && data.venueApplications.metadata.totalRecords}
            loading={loading}
            onSort={pageState.handleSortChange}
            onReset={pageState.handleResetFilters}
            dataQaidPrefix="venue-application"
          />
          <ListingFilter
            filterTitle={intl.formatMessage({
              id: 'admin.venueApplications.filterTitle',
            })}
            textSearchString={pageState.textSearchState}
            handleTextSearchLabelClose={pageState.handleTextSearchLabelClose}
            labelTitleMapping={pageState.filterLabelTitleMapping}
            dropdownOptionsInfoList={filterDropdownOptionsInfoList}
            filterState={pageState.filterState}
            handleRemoveFilter={pageState.handleRemoveFilter}
            handleFilterChange={pageState.handleFilterChange}
          />
        </div>

        <div>
          <CardGrid
            objectData={get(data, 'venueApplications.venueApplications', [])}
            renderCardComponent={(venue: CardType, i: number) => (
              // @ts-ignore
              <VenueApplicationCard
                index={i}
                refetchVenueApplications={refetchVenueApplications}
                {...venue}
              />
            )}
            dataQaid="admin-venue-applications-list"
            loading={loading}
            loadingComponent={
              <LoadingBlocks.Rectangle width="100%" height="420px" />
            }
            hideDividerOnSize="xs"
          />
          <DottedLine />
          <Spacer mb={2} />
        </div>

        <ListingNoResults
          entityName={intl.formatMessage({
            id: 'admin.venueApplications.noResultsEntityName',
          })}
          numResults={get(
            data,
            'venueApplications.venueApplications.length',
            undefined
          )}
          loading={loading}
        />

        <ListingFooter
          numTotalRecords={get(
            data,
            'venueApplications.metadata.totalRecords',
            undefined
          )}
          perPage={PER_PAGE}
          currentPage={pageState.page}
          onPageChange={pageState.handlePageChange}
          loading={loading}
          dataQaidPrefix="venue-applications"
        />
      </ListPageTemplate>
    </Layout>
  );
};

const AdminVenueApplicationsWrapper: React.FC = () => (
  <ListPage config={pageStateConfig} analytics={Analytics}>
    <AdminVenueApplications />
  </ListPage>
);

export default AdminVenueApplicationsWrapper;
