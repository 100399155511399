import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { None } from 'app/admin/components/atoms/None';
import ToggleText from 'app/admin/components/atoms/ToggleText';

interface Props {
  itemComponents: JSX.Element[];
  truncateNumItems?: number;
}

const MainContainer = styled.div`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.4px;
  margin-bottom: 25px;
`;

const ToggleContainer = styled.div`
  margin-top: 8px;
`;

const ExpandableList: React.FC<Props> = ({
  itemComponents,
  truncateNumItems = 5,
}) => {
  const intl = useIntl();

  const [showFullList, setShowFullList] = useState(false);

  return (
    <MainContainer data-qaid="expandable-list-main-container">
      {itemComponents.length > 0 ? (
        <>
          {(showFullList
            ? itemComponents
            : itemComponents.slice(0, truncateNumItems)
          ).map((itemComponent: JSX.Element) => {
            return itemComponent;
          })}
        </>
      ) : (
        <None data-qaid="expandable-list-none" />
      )}
      {itemComponents.length > truncateNumItems && (
        <ToggleContainer data-qaid="expandable-list-toggle-container">
          <ToggleText onClick={() => setShowFullList(!showFullList)}>
            {showFullList
              ? intl.formatMessage({
                  id: 'less',
                })
              : intl.formatMessage({
                  id: 'more',
                })}
          </ToggleText>
        </ToggleContainer>
      )}
    </MainContainer>
  );
};

export default ExpandableList;
