import * as Yup from 'yup';

import { getSocialUrlsFormSchema } from 'app/shared/utils/socialLinks';
import { validateUrlNoHttp } from 'app/shared/utils/urls';
import { getNotesFormSchema } from 'app/admin/utils/string';

const MAX_CAPACITY = 500;

const venueBasicInfoEditFormSchema = (intl: any) =>
  Yup.object().shape({
    venueName: Yup.string()
      .trim()
      .nullable(),
    address: Yup.string().required(
      intl.formatMessage({
        id: 'form.requiredField',
      })
    ),
    address2: Yup.string()
      .trim()
      .nullable(),
    city: Yup.object()
      .required(
        intl.formatMessage({
          id: 'form.requiredField',
        })
      )
      .nullable(),
    closestStation: Yup.string()
      .trim()
      .nullable(),
    numTicketsAvailableForSale: Yup.number()
      .required(
        intl.formatMessage(
          {
            id: 'form.fieldCanNotBeEmpty',
          },
          {
            field: 'Tickets Available',
          }
        )
      )
      .typeError(
        intl.formatMessage({
          id: 'form.pleaseEnterANumber',
        })
      )
      .min(0)
      .max(
        MAX_CAPACITY,
        intl.formatMessage(
          {
            id: 'form.fieldValueCanNotExceedMax',
          },
          {
            field: 'Tickets Available',
            max: MAX_CAPACITY,
          }
        )
      )
      .test(
        'greater-than-capacity',
        intl.formatMessage({
          id: 'admin.venueBasicInfo.availableTicketsIsAboveCapacity',
        }),
        function(value) {
          const capacity = this.parent.capacity;
          return value <= capacity;
        }
      )
      .nullable(),
    capacity: Yup.number()
      .required(
        intl.formatMessage(
          {
            id: 'form.fieldCanNotBeEmpty',
          },
          {
            field: 'Capacity',
          }
        )
      )
      .typeError(
        intl.formatMessage({
          id: 'form.pleaseEnterANumber',
        })
      )
      .min(0)
      .max(
        MAX_CAPACITY,
        intl.formatMessage(
          {
            id: 'form.fieldValueCanNotExceedMax',
          },
          {
            field: 'Capacity',
            max: MAX_CAPACITY,
          }
        )
      )
      .nullable(),
    maxPaInputs: Yup.string().when('isNewVenue', {
      is: true,
      then: Yup.string().required(
        intl.formatMessage({
          id: 'form.requiredField',
        })
      ),
    }),
    numGuestTickets: Yup.number()
      .required(
        intl.formatMessage(
          {
            id: 'form.fieldCanNotBeEmpty',
          },
          {
            field: 'Guest Tickets',
          }
        )
      )
      .typeError(
        intl.formatMessage({
          id: 'form.pleaseEnterANumber',
        })
      )
      .min(0)
      .nullable(),
    contactInfos: Yup.object().shape({
      primaryContactInfo: Yup.object().shape({
        email: Yup.string()
          .email(
            intl.formatMessage(
              {
                id: 'form.fieldMustBeValidEmail',
              },
              {
                field: 'Email',
              }
            )
          )
          .required(
            intl.formatMessage({
              id: 'form.requiredField',
            })
          ),
        phoneNumber: Yup.string()
          .trim()
          .matches(/^[-\d().+]+( [-\d.]+)*$/, {
            message: intl.formatMessage(
              {
                id: 'form.fieldMustBeValidPhoneNumber',
              },
              {
                field: 'Phone',
              }
            ),
          })
          .when('isDayOfShowContact', {
            is: true,
            then: Yup.string().required(
              intl.formatMessage({
                id: 'form.requiredField',
              })
            ),
            otherwise: Yup.string().nullable(),
          }),
      }),
      secondaryContactInfo: Yup.object().shape({
        email: Yup.string()
          .email(
            intl.formatMessage(
              {
                id: 'form.fieldMustBeValidEmail',
              },
              {
                field: 'Email',
              }
            )
          )
          .when(['firstName', 'lastName'], {
            is: (firstName, lastName) => firstName || lastName,
            then: Yup.string()
              .email()
              .required(
                intl.formatMessage({
                  id: 'form.requiredField',
                })
              ),
            otherwise: Yup.string().nullable(),
          }),
        phoneNumber: Yup.string()
          .trim()
          .matches(/^[-\d().+]+( [-\d.]+)*$/, {
            message: intl.formatMessage(
              {
                id: 'form.fieldMustBeValidPhoneNumber',
              },
              {
                field: 'Phone',
              }
            ),
          })
          .when('isDayOfShowContact', {
            is: true,
            then: Yup.string().required(
              intl.formatMessage({
                id: 'form.requiredField',
              })
            ),
            otherwise: Yup.string().nullable(),
          }),
      }),
      tertiaryContactInfo: Yup.object().shape({
        email: Yup.string()
          .email(
            intl.formatMessage(
              {
                id: 'form.fieldMustBeValidEmail',
              },
              {
                field: 'Email',
              }
            )
          )
          .when(['firstName', 'lastName'], {
            is: (firstName, lastName) => firstName || lastName,
            then: Yup.string()
              .email()
              .required(
                intl.formatMessage({
                  id: 'form.requiredField',
                })
              ),
            otherwise: Yup.string().nullable(),
          }),
        phoneNumber: Yup.string()
          .trim()
          .matches(/^[-\d().+]+( [-\d.]+)*$/, {
            message: intl.formatMessage(
              {
                id: 'form.fieldMustBeValidPhoneNumber',
              },
              {
                field: 'Phone',
              }
            ),
          })
          .when('isDayOfShowContact', {
            is: true,
            then: Yup.string().required(
              intl.formatMessage({
                id: 'form.requiredField',
              })
            ),
            otherwise: Yup.string().nullable(),
          }),
      }),
    }),
    socialUrls: getSocialUrlsFormSchema(intl),
    streetViewUrl: Yup.string()
      .nullable()
      .test(
        'isUrl',
        intl.formatMessage(
          {
            id: 'form.fieldMustBeValidUrl',
          },
          {
            field: 'Street View URL',
          }
        ),
        function(value) {
          return value ? validateUrlNoHttp(value) : true;
        }
      ),
    venueFeeDescription: Yup.string().when('venueFeePresent', {
      is: true,
      then: Yup.string()
        .trim()
        .required(
          intl.formatMessage(
            {
              id: 'form.fieldCanNotBeEmpty',
            },
            {
              field: intl.formatMessage({
                id: 'admin.venueBasicInfo.fee',
              }),
            }
          )
        ),
      otherwise: Yup.string().nullable(),
    }),
    parkingNotes: getNotesFormSchema(intl),
  });

export default venueBasicInfoEditFormSchema;
