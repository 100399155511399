import styled, { css } from 'styled-components';

import { StyledBaseButton } from 'app/shared/components/atoms/Button';
import { StreamlineIcon } from 'app/shared/components/atoms/StreamlineIcon';

interface MenuIconButtonProps {
  display?: string;
}

export const IconButton = styled(StyledBaseButton)<MenuIconButtonProps>`
  ${({ theme, display }) => css`
    display: ${display ? display : ''};
    cursor: pointer;

    background: transparent;

    &:hover {
      color: ${theme.colors.whiteDenim};
    }

    border: none;
    padding: 0;
  `}
`;

export const StyledStreamlineIcon = styled(StreamlineIcon)`
  margin-top: 2px;
  margin-right: 4px;
`;
