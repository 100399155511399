import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { ArtistSetup } from 'app/typings/artistSetups';
import { dateFormatter } from 'app/shared/utils/datetime';
import { get } from 'app/shared/utils/get';
import { objectFilteredByBooleanConditionOnKeys } from 'app/shared/utils/object';
import { dataGetter } from 'app/shared/utils/queries';
import useModal from 'app/shared/utils/useModal';
import usePermission from 'app/shared/utils/usePermission';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import { DeleteArtistSetup } from 'app/shared/graphql/artistSetups/mutationHooks';
import { Spacer40 } from 'app/shared/components/atoms/Spacer';
import { Spinner } from 'app/shared/components/atoms/SpinnerManualCSS';
import ConfirmationModal from 'app/shared/components/molecules/ConfirmationModal';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { GetArtistBasicInfo } from 'app/admin/graphql/artists/queryHooks';
import { GetArtistEventsInfo } from 'app/admin/graphql/events/queryHooks';
import { DeleteFeedback } from 'app/admin/graphql/feedbacks/mutationHooks';
import { GetFeedbacks } from 'app/admin/graphql/feedbacks/queryHooks';
import { DetailsMainContainer } from 'app/admin/components/atoms/DetailContent';
import AccordionSections from 'app/admin/components/organisms/AccordionSections';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';
import { ReactComponent as InformationCircle } from 'icons/streamline-regular/interface-essential/alerts/information-circle.svg';
import { ReactComponent as SocialVideoYoutubeClip } from 'icons/streamline-regular/logos/videos/social-video-youtube-clip.svg';
import { ReactComponent as MessagesBubbleSquareSmile } from 'icons/streamline-regular/messages-chat-smileys/messages-speech-bubbles/messages-bubble-square-smile.svg';
import { ReactComponent as ModernMusicDj } from 'icons/streamline-regular/music-audio/modern-music/modern-music-dj.svg';
import { ReactComponent as MusicGenreTeam } from 'icons/streamline-regular/music-audio/music-genres/music-genre-team.svg';

import SectionBasicInfo from './SectionBasicInfo';
import SectionEvents from './SectionEvents';
import SectionFeedback from './SectionFeedback';
import SectionMedia from './SectionMedia';
import SectionSetupOptions from './SectionSetupOptions';

interface Props {
  navigateTo: (routeData: any) => void;
  contentProps: any;
  hide: VoidFunction;
}

const detailsHeaderActionLinksInfo = (intl: any) => {
  return {
    link1: {
      text: intl.formatMessage({
        id: 'admin.artistDirectory.details.archive',
      }),
      active: false,
      onClickAction: () => {},
    },
  };
};

const detailsHeaderSummaryInfoList = (
  upcomingEventsCount: number | undefined,
  pastEventsCount: number | undefined,
  citiesPlayedCount: number | undefined,
  lastEventAt: string | undefined,
  intl: any
) => {
  const upcomingEventsCountDescription =
    upcomingEventsCount === 1
      ? intl.formatMessage({
          id: 'admin.artistDirectory.details.upcomingShow',
        })
      : intl.formatMessage({
          id: 'admin.artistDirectory.details.upcomingShows',
        });

  const pastEventsCountDescription =
    citiesPlayedCount === 1
      ? pastEventsCount === 1
        ? intl.formatMessage({
            id: 'admin.artistDirectory.details.showIn1City',
          })
        : intl.formatMessage({
            id: 'admin.artistDirectory.details.showsIn1City',
          })
      : intl.formatMessage(
          {
            id: 'admin.artistDirectory.details.showsInXCities',
          },
          {
            citiesCount: citiesPlayedCount,
          }
        );
  const lastEventMonth = lastEventAt
    ? dateFormatter(lastEventAt, 'shortMonth').toUpperCase()
    : intl.formatMessage({
        id: 'admin.notApplicable',
      });

  const lastEventYear = lastEventAt
    ? dateFormatter(lastEventAt, 'longYear')
    : null;

  const lastEventDescription = lastEventAt
    ? `${lastEventYear} \n ${intl.formatMessage({
        id: 'admin.artistDirectory.details.lastShow',
      })}`
    : intl.formatMessage({
        id: 'admin.artistDirectory.details.lastShow',
      });

  return [
    {
      backgroundColor: '#ebedef',
      mainInfo: upcomingEventsCount && upcomingEventsCount.toString(),
      description: upcomingEventsCountDescription,
    },
    {
      backgroundColor: '#ebf5fb',
      mainInfo: pastEventsCount && pastEventsCount.toString(),
      description: pastEventsCountDescription,
    },
    {
      backgroundColor: '#f3f9ed',
      mainInfo: lastEventMonth,
      description: lastEventDescription,
    },
  ];
};

const ArtistDetails: React.FC<Props> = ({ contentProps, hide, navigateTo }) => {
  const {
    id,
    title,
    imageUrl,
    largeImageUrl,
    upcomingEventsCount,
    pastEventsCount,
    citiesPlayedCount,
    lastEventAt,
  } = contentProps;
  const intl = useIntl();
  const hasAccessArtistsPermission = usePermission('artist.list.access');
  const hasBasicAccessArtistsPermission = usePermission(
    'artist.list.accessBasic'
  );

  const [headerData, setHeaderData] = useState({
    title,
    imageUrl,
    largeImageUrl,
    upcomingEventsCount,
    pastEventsCount,
    citiesPlayedCount,
    lastEventAt,
    isLoaded: title !== undefined && imageUrl !== undefined,
  });

  const [feedbackDeleteModal, toggleFeedbackDeleteModal] = useModal();
  const [artistSetupDeleteModal, toggleArtistSetupDeleteModal] = useModal();
  const [feedbackData, setFeedbackData] = useState<any>(undefined);
  const [artistSetupData, setArtistSetupData] = useState<any>(undefined);

  const {
    loading: loadingBasicInfo,
    error: errorBasicInfo,
    data: dataBasicInfo,
    refetch: refetchArtist,
  } = GetArtistBasicInfo({
    id,
    fetchPolicy: 'no-cache',
  });

  const {
    loading: loadingEvents,
    error: errorEvents,
    data: dataEvents,
  } = GetArtistEventsInfo({
    id,
  });

  const {
    loading: loadingFeedback,
    error: errorFeedback,
    data: dataFeedback,
    refetch: refetchFeedbacks,
  } = GetFeedbacks({
    feedbackSubjectId: id,
    feedbackSubjectType: 'Artist',
    page: 1,
    perPage: 1000,
    fetchPolicy: 'no-cache',
  });

  const videos = get(dataBasicInfo, 'artist.videos', []);
  const socialUrls = get(dataBasicInfo, 'artist.socialUrls', {});
  const artistId = dataBasicInfo
    ? get(dataBasicInfo, 'artist.id', undefined)
    : undefined;

  const sectionsConfig = objectFilteredByBooleanConditionOnKeys(
    {
      basicInfo: {
        title: intl.formatMessage({
          id: 'admin.artistDirectory.details.sectionTitle.basicInfo',
        }),
        icon: InformationCircle,
        iconColor: 'blueChristmas',
        sectionComponent: SectionBasicInfo,
        dataKey: 'artist',
        displayEditIcon: hasBasicAccessArtistsPermission,
        loading: loadingBasicInfo,
        data: dataBasicInfo ? dataBasicInfo.artist : undefined,
        callbacks: {
          toggleSectionEditModal: () =>
            navigateTo({
              routeName: 'artist-basic-info-edit',
              routeProps: dataBasicInfo ? dataBasicInfo.artist : undefined,
            }),
        },
      },
      events: {
        title: intl.formatMessage({
          id: 'shared.concerts',
        }),
        icon: MusicGenreTeam,
        iconColor: 'orangeCrush',
        iconCircle: true,
        sectionComponent: SectionEvents,
        dataKey: 'artistEventsInfo',
        loading: loadingEvents,
        data: dataEvents
          ? {
              // @ts-ignore
              data: dataEvents.artistEventsInfo,
              eventType: 'artists',
              artistId,
            }
          : undefined,
        extraData: {
          artistName: dataBasicInfo
            ? get(dataBasicInfo, 'artist.title', undefined)
            : undefined,
          artistId,
        },
        callbacks: {
          toggleAllEventsAndSetType: (eventType: string) =>
            navigateTo({
              routeName: 'artist-all-events',
              routeProps:
                dataEvents && dataBasicInfo
                  ? {
                      eventType: `artist${eventType}`,
                      id,
                      artistId,
                      title: headerData.title,
                      imageUrl: headerData.imageUrl,
                      largeImageUrl: headerData.largeImageUrl,
                      imageStyle: 'circle',
                      dataQaidPrefix: 'artist-events',
                    }
                  : undefined,
            }),
        },
      },
      media: {
        title: intl.formatMessage({
          id: 'admin.artistDirectory.details.sectionTitle.media',
        }),
        icon: SocialVideoYoutubeClip,
        iconColor: 'greenOnions',
        iconCircle: true,
        sectionComponent: SectionMedia,
        dataKey: 'artist',
        loading: loadingBasicInfo,
        data: dataBasicInfo ? dataBasicInfo.artist : undefined,
        callbacks: {
          toggleAllVideos: () =>
            navigateTo({
              routeName: 'artist-all-videos',
              routeProps: {
                artistData: {
                  id,
                  imageUrl: headerData.imageUrl,
                  largeImageUrl: headerData.largeImageUrl,
                  title: headerData.title,
                  videos,
                },
              },
            }),
          toggleVideoEditAndSetVideoData: (videoData: any) =>
            navigateTo({
              routeName: 'artist-video-edit',
              routeProps: {
                artistData: {
                  id,
                  imageUrl: headerData.imageUrl,
                  largeImageUrl: headerData.largeImageUrl,
                  title: headerData.title,
                },
                videoData,
                performances: get(dataBasicInfo, 'artist.performances', []),
              },
            }),
          toggleSocialsEditModal: () =>
            navigateTo({
              routeName: 'artist-socials-edit',
              routeProps: {
                artistData: {
                  id,
                  imageUrl: headerData.imageUrl,
                  largeImageUrl: headerData.largeImageUrl,
                  title: headerData.title,
                  socialUrls,
                },
              },
            }),
        },
      },
      feedback: {
        title: intl.formatMessage({
          id: 'shared.feedback',
        }),
        icon: MessagesBubbleSquareSmile,
        iconColor: 'purplePills',
        iconCircle: true,
        sectionComponent: SectionFeedback,
        dataKey: 'feedbacks',
        loading: loadingFeedback,
        data: dataFeedback ? dataFeedback.feedbacks : undefined,
        callbacks: {
          toggleFeedbackCreateModal: () =>
            navigateTo({
              routeName: 'artist-feedback-create',
              routeProps: {
                artistData: {
                  id,
                  imageUrl: headerData.imageUrl,
                  largeImageUrl: headerData.largeImageUrl,
                  title: headerData.title,
                },
                eventData: dataEvents,
              },
            }),
          toggleDeleteFeedbackandSetFeedbackData: (feedbackData: any) => {
            setFeedbackData(feedbackData);
            toggleFeedbackDeleteModal();
          },
          toggleAllFeedbacksAndSetType: (type: string) =>
            navigateTo({
              routeName: 'artist-all-feedbacks',
              routeProps: {
                type,
                artistData: {
                  id,
                  imageUrl: headerData.imageUrl,
                  largeImageUrl: headerData.largeImageUrl,
                  title: headerData.title,
                  feedbacks: get(
                    dataFeedback,
                    'feedbacks.feedbacks',
                    undefined
                  ),
                },
              },
            }),
        },
      },
      setupOptions: {
        title: intl.formatMessage({
          id: 'admin.artistDirectory.details.sectionTitle.setupOptions',
        }),
        icon: ModernMusicDj,
        iconColor: 'checkBerry',
        iconCircle: true,
        sectionComponent: SectionSetupOptions,
        dataKey: 'artist',
        displayEditIcon: false,
        loading: loadingBasicInfo,
        data: dataBasicInfo ? dataBasicInfo.artist : undefined,
        callbacks: {
          toggleArtistSetupCreateModal: () => {
            navigateTo({
              routeName: 'artist-setup-edit',
              routeProps: {
                userContext: 'admin',
                action: 'createArtistSetup',
                artistId: id,
                artist: dataBasicInfo && dataBasicInfo.artist,
              },
            });
          },
          toggleArtistSetupEditModal: (artistSetup: ArtistSetup) => {
            navigateTo({
              routeName: 'artist-setup-edit',
              routeProps: {
                userContext: 'admin',
                action: 'updateArtistSetup',
                artistId: id,
                artistSetup,
                artist: dataBasicInfo && dataBasicInfo.artist,
              },
            });
          },
          toggleDeleteArtistSetupandSetArtistSetupData: (
            artistSetupData: any
          ) => {
            setArtistSetupData(artistSetupData);
            toggleArtistSetupDeleteModal();
          },
        },
      },
    },
    {
      ['feedback']: hasAccessArtistsPermission,
    }
  );

  const onEditProfileImageClickModal = () => {
    navigateTo({
      routeName: 'artist-profile-image-uploader',
      routeProps: dataBasicInfo ? dataBasicInfo?.artist : undefined,
    });
  };

  useEffect(() => {
    const getDataBasicInfo = dataGetter(dataBasicInfo, 'artist');
    if (dataBasicInfo) {
      setHeaderData({
        title: getDataBasicInfo('title'),
        imageUrl: getDataBasicInfo('imageUrl'),
        largeImageUrl: getDataBasicInfo('largeImageUrl'),
        pastEventsCount: getDataBasicInfo('pastEventsCount', 0),
        upcomingEventsCount: getDataBasicInfo('upcomingEventsCount', 0),
        lastEventAt: getDataBasicInfo('lastEventAt'),
        citiesPlayedCount: getDataBasicInfo('citiesPlayedCount', 0),
        isLoaded: true,
      });
    }
  }, [dataBasicInfo]);

  const deleteFeedbackAction = DeleteFeedback();

  const handleDeleteFeedback = useSubmitAction({
    submitAction: deleteFeedbackAction,
    submitVariables: () => ({
      feedbackId: feedbackData && feedbackData.id,
    }),
    successMsg: intl.formatMessage(
      {
        id: 'admin.artistDirectory.sectionFeedback.deleteSuccess',
      },
      {
        date:
          feedbackData &&
          dateFormatter(feedbackData.feedbackableAt, 'shortMonthDateAndYear'),
      }
    ),
    failureMsg: intl.formatMessage({
      id: 'admin.artistDirectory.sectionFeedback.deleteFailure',
    }),
    onSuccess: () => {
      feedbackDeleteModal.hide();
      refetchFeedbacks();
    },
  });

  const deleteArtistSetupAction = DeleteArtistSetup();

  const handleDeleteArtistSetup = useSubmitAction({
    submitAction: deleteArtistSetupAction,
    submitVariables: () => ({
      artistSetupId: artistSetupData && artistSetupData.id,
    }),
    successMsg: intl.formatMessage(
      {
        id: 'admin.artistDirectory.sectionSetupOptions.deleteSuccess',
      },
      {
        name: artistSetupData && artistSetupData.setupName,
      }
    ),
    failureMsg: intl.formatMessage({
      id: 'admin.artistDirectory.sectionSetupOptions.deleteFailure',
    }),
    onSuccess: () => {
      artistSetupDeleteModal.hide();
      refetchArtist();
    },
  });

  if (
    (!loadingBasicInfo && !dataBasicInfo) ||
    errorBasicInfo ||
    (!loadingEvents && !dataEvents) ||
    errorEvents ||
    (!loadingFeedback && !dataFeedback) ||
    errorFeedback
  ) {
    hide();
    return null;
  }

  const summaryInfoList = detailsHeaderSummaryInfoList(
    headerData.upcomingEventsCount,
    headerData.pastEventsCount,
    headerData.citiesPlayedCount,
    headerData.lastEventAt,
    intl
  );

  return (
    <ModalContentContainer data-qaid="artist-details-main-modal">
      <DetailsMainContainer>
        <DetailsHeader
          title={headerData.title}
          imageUrl={headerData.imageUrl}
          largeImageUrl={headerData.largeImageUrl}
          summaryInfoList={summaryInfoList}
          actionLinksInfo={detailsHeaderActionLinksInfo(intl)}
          loading={!headerData.isLoaded}
          imageStyle="circle"
          displayEditIcon={true}
          onEditIconClick={onEditProfileImageClickModal}
          largeImageBackButtonText={intl.formatMessage({
            id: 'admin.artistDirectory.largeImageBackButtonText',
          })}
        />
        {!headerData.isLoaded ? (
          <>
            <Spacer40 />
            <Spinner />
          </>
        ) : (
          <AccordionSections
            sectionsConfig={sectionsConfig}
            defaultOpenSection={contentProps.defaultOpenSection}
          />
        )}
      </DetailsMainContainer>
      {feedbackDeleteModal.isShowing && (
        <ConfirmationModal
          onCancel={() => feedbackDeleteModal.hide()}
          description={intl.formatMessage({
            id: 'admin.artistDirectory.sectionFeedback.deleteConfirmation',
          })}
          onConfirm={handleDeleteFeedback}
        />
      )}
      {artistSetupDeleteModal.isShowing && (
        <ConfirmationModal
          onCancel={() => artistSetupDeleteModal.hide()}
          description={intl.formatMessage(
            {
              id:
                'admin.artistDirectory.sectionSetupOptions.deleteConfirmation',
            },
            {
              name: artistSetupData.setupName,
            }
          )}
          onConfirm={handleDeleteArtistSetup}
        />
      )}
    </ModalContentContainer>
  );
};

export default ArtistDetails;
