import React from 'react';
import { useIntl } from 'react-intl';

import { errorMsgForField } from 'app/shared/utils/form';
import useModal from 'app/shared/utils/useModal';
import { PrimaryButton } from 'app/shared/components/atoms/Button';
import GenericFormContainer from 'app/shared/components/atoms/GenericFormContainer';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Textfield } from 'app/shared/components/atoms/Textfield';
import ConfirmationModal from 'app/shared/components/molecules/ConfirmationModal';
import FormGroup from 'app/shared/components/molecules/FormGroup';

interface FormProps {
  formikProps: any;
  validationErrors?: any;
}

const GdprDataRemovalForm: React.FC<FormProps> = ({
  formikProps,
  validationErrors = {},
}) => {
  const intl = useIntl();

  const [confirmationModal, toggleConfirmationModal] = useModal();

  const { setFieldValue, touched, errors, values } = formikProps;

  const onModalConfirm = () => {
    toggleConfirmationModal();
    formikProps.submitForm();
  };

  const onFormSubmit = () => {
    if (touched.email && formikProps.isValid) {
      toggleConfirmationModal();
    } else {
      formikProps.submitForm();
    }
  };

  return (
    <GenericFormContainer dataQaId="gdpr-data-removal-form">
      <Grid>
        <Col xs={12} md={12} lg={10} xl={8}>
          <FormGroup
            data-qaid="gdpr-data-removal-form-group-email"
            label={intl.formatMessage({
              id: 'admin.gdprDataRemovalForm.email',
            })}
            errorMsg={
              errorMsgForField('email', touched, errors) ||
              // @ts-ignore
              validationErrors.general
            }
            required
          >
            <Textfield
              type="text"
              id="email"
              name="email"
              value={values.email}
              onChange={(e: any) => {
                setFieldValue('email', e.target.value);
              }}
              maxLength={200}
            />
          </FormGroup>
        </Col>
      </Grid>
      <Spacer mb={8} />
      <Grid>
        <Col xs={12} sm={8} md={6} lg={4}>
          <PrimaryButton
            block
            disabled={!formikProps.dirty}
            data-qaid="gdpr-data-removal-form-submit-btn"
            onClick={onFormSubmit}
            type="submit"
            loading={formikProps.isSubmitting}
          >
            {intl.formatMessage({
              id: 'admin.gdprDataRemovalForm.remove',
            })}
          </PrimaryButton>
        </Col>
      </Grid>
      {confirmationModal.isShowing && (
        <ConfirmationModal
          onCancel={() => toggleConfirmationModal()}
          confirmationButtonText={intl.formatMessage({
            id: 'yes',
          })}
          onConfirm={onModalConfirm}
          description={intl.formatMessage(
            {
              id: 'admin.gdprDataRemovalForm.areYouSure',
            },
            {
              email: values.email,
            }
          )}
        />
      )}
    </GenericFormContainer>
  );
};

export default GdprDataRemovalForm;
