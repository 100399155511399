import styled from 'styled-components';

import { Textarea } from 'app/shared/components/atoms/Textarea';

const SmallTextarea = styled(Textarea)`
  display: block;
  width: 100%;
  height: 120px;
`;

export default SmallTextarea;
