import styled, { css } from 'styled-components';

export default styled.hr<{ inverted?: boolean }>`
  ${({ inverted = false }) => css`
    width: 100%;
    padding: 0.15em;
    border: none;
    background-image: radial-gradient(
      circle at 1px,
      ${inverted ? '#FFF' : '#000'} 1px,
      ${inverted ? 'rgba(255, 255, 255, 0)' : 'rgba(0, 0, 0, 0)'} 1px
    );
    background-image: -webkit-radial-gradient(
      6px center,
      circle,
      ${inverted ? '#FFF' : '#000'} 1px,
      ${inverted ? 'rgba(255, 255, 255, 0)' : 'rgba(0, 0, 0, 0)'} 1px
    );
    background-size: 16px 6px;
  `}
`;
