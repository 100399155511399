import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { CrewMessage } from 'app/typings/staffMembers';
import useModal from 'app/shared/utils/useModal';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import ConfirmationModal from 'app/shared/components/molecules/ConfirmationModal';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { ArchiveCrewMessage } from 'app/admin/graphql/staffMembers/mutationHooks';
import { GetCrewMessages } from 'app/admin/graphql/staffMembers/queryHooks';
import {
  SectionTitle,
  SectionWithIcon,
} from 'app/admin/components/atoms/CrewEventContent';
import IconInCircle from 'app/admin/components/atoms/IconInCircle';
import { renderCrewMessage } from 'app/admin/components/organisms/CrewEventBreakingNews/shared';
import { ReactComponent as Megaphone } from 'icons/streamline-regular/interface-essential/share/megaphone.svg';

interface Props {
  contentProps?: any;
}

const ViewAllBreakingNews: React.FC<Props> = ({ contentProps }) => {
  const intl = useIntl();
  const { eventId, messages, isAdmin, user } = contentProps;
  const [crewMessages, setCrewMessages] = useState(messages || []);
  const lastMessageIndex = messages.length - 1;
  const truncateMessage = false;

  // Do not remove the below query call - need it to make the deletion of crew messages work
  const {
    loading: loadingCrewMessages,
    data: dataCrewMessages,
    error: errorCrewMessages,
    refetch: refetchCrewMessages,
  } = GetCrewMessages({
    eventId,
    isArchived: false,
  });

  useEffect(() => {
    if (dataCrewMessages && !loadingCrewMessages) {
      setCrewMessages(dataCrewMessages.crewMessages?.crewMessages);
    }
  }, [dataCrewMessages]);

  const [
    archiveCrewMessageConfirmationModal,
    toggleArchiveCrewMessageConfirmationModal,
  ] = useModal();
  const [
    archiveCrewMessageIsSubmitting,
    setArchiveCrewMessageIsSubmitting,
  ] = useState(false);
  const [crewMessageIdToRemove, setCrewMessageIdToRemove] = useState<
    number | null
  >(null);

  const archiveCrewMessageAction = ArchiveCrewMessage();
  const handleArchiveCrewMessage = useSubmitAction({
    submitAction: archiveCrewMessageAction,
    submitVariables: () => ({
      id: crewMessageIdToRemove,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.eventPlanner.team.deleteBreakingNewsSuccess',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.eventPlanner.team.deleteBreakingNewsFailure',
    }),
    onSuccess: () => {
      setArchiveCrewMessageIsSubmitting(false);
      toggleArchiveCrewMessageConfirmationModal();
      refetchCrewMessages();
    },
  });

  const onRemove = (id: number) => {
    setCrewMessageIdToRemove(id);
    toggleArchiveCrewMessageConfirmationModal();
  };

  if ((!loadingCrewMessages && !dataCrewMessages) || errorCrewMessages) {
    return null;
  }

  return (
    <ModalContentContainer>
      <SectionWithIcon inverted={!isAdmin}>
        <SectionTitle inverted={!isAdmin}>
          {!isAdmin && (
            <IconInCircle
              borderCircle={true}
              circleBackgroundColor="whiteDenim"
              dataQaIdSuffix="megaphone"
              iconColor="blueChristmas"
              iconName={Megaphone}
            />
          )}
          {intl.formatMessage({ id: 'admin.crewEvent.section.breakingNews' })}
        </SectionTitle>
        {crewMessages.map((message: CrewMessage, index: number) =>
          renderCrewMessage(
            message,
            intl,
            lastMessageIndex,
            index,
            isAdmin,
            user,
            onRemove,
            truncateMessage
          )
        )}
      </SectionWithIcon>
      {archiveCrewMessageConfirmationModal.isShowing && (
        <ConfirmationModal
          onCancel={() => archiveCrewMessageConfirmationModal.hide()}
          description={intl.formatMessage({
            id: 'admin.eventPlanner.team.deleteBreakingNewsConfirmation',
          })}
          onConfirm={() => {
            setArchiveCrewMessageIsSubmitting(true);
            handleArchiveCrewMessage();
          }}
          confirmationButtonText={intl.formatMessage({ id: 'delete' })}
          isLoading={archiveCrewMessageIsSubmitting}
        />
      )}
    </ModalContentContainer>
  );
};

export default ViewAllBreakingNews;
