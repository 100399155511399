import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { NoIcon, YesIcon } from 'app/shared/components/atoms/IconLibrary';
import {
  DetailHeading as Heading,
  DetailValueContainer as ValueContainer,
} from 'app/admin/components/atoms/DetailContent';

interface Props {
  value: boolean | undefined;
  headingText: string;
  helperText?: string;
}

const BooleanSettingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 5px;
`;

const BooleanSettingText = styled.div`
  padding-left: 8px;
`;

const HelperText = styled.div`
  font-size: 10px;
  font-weight: 500;
  margin-top: 0px;
  margin-bottom: 15px;
`;

const BooleanSetting: React.FC<Props> = ({
  value,
  headingText,
  helperText,
}) => {
  const intl = useIntl();

  return (
    <>
      <Heading>{headingText}</Heading>
      <ValueContainer>
        <BooleanSettingContainer>
          {value ? <YesIcon /> : <NoIcon />}
          <BooleanSettingText>
            {value
              ? intl.formatMessage({
                  id: 'yes',
                })
              : intl.formatMessage({
                  id: 'no',
                })}
          </BooleanSettingText>
        </BooleanSettingContainer>
        {helperText && <HelperText>{helperText}</HelperText>}
      </ValueContainer>
    </>
  );
};

export default BooleanSetting;
