import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import * as Yup from 'yup';

import { Ticket } from 'app/typings/tickets';
import { get } from 'app/shared/utils/get';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import TruncatedByCharText from 'app/shared/components/atoms/TruncatedByCharText';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { UpdateEventAttendeeAttendance } from 'app/admin/graphql/eventAttendees/mutationHooks';
import { UpdateVipAttendee } from 'app/admin/graphql/vipAttendees/mutationHooks';

import GuestlistAttendanceEditForm from './GuestlistAttendanceEditForm';

interface Props {
  contentProps?: any;
  setFormSubmitAction: (func: Function) => void;
  setDisplayConfirmation: (set: boolean) => void;
  setIsSubmitting: (set: boolean) => void;
  navigateTo: (routeData: object) => void;
}

const FormMainContainer = styled.div`
  padding: 0px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
`;

const AttendeeHeaderContainer = styled.div`
  margin-bottom: 0px;
`;

const AttendeeName = styled.div`
  font-size: 14px;
  line-height: 21px;
  font-weight: 700;
  letter-spacing: 0.1px;
  white-space: normal;
  margin-top: 5px;
  margin-bottom: 4px;
`;

const AttendeeEmail = styled.div`
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  letter-spacing: 0.4px;
  font-weight: normal;
  white-space: normal;
  margin-bottom: 9px;
`;

interface AttendeeHeaderProps {
  attendee: any;
}

const AttendeeHeader: React.FC<AttendeeHeaderProps> = ({ attendee }) => {
  const intl = useIntl();

  const name =
    attendee.name ||
    intl.formatMessage({
      id: 'unknown',
    });
  const email =
    attendee.email ||
    intl.formatMessage({
      id: 'unknown',
    });

  return (
    <AttendeeHeaderContainer>
      <AttendeeName>
        <TruncatedByCharText text={name} truncateLength={60} />
      </AttendeeName>
      <AttendeeEmail>
        <TruncatedByCharText text={email} truncateLength={60} />
      </AttendeeEmail>
    </AttendeeHeaderContainer>
  );
};

const numVipsGuestsAttended = (attendee: any) => {
  const numAttendedVipAttendee = attendee.vipAttendee?.ticketsAttendedCount;
  return numAttendedVipAttendee || 0;
};

const numGuestsTotal = (attendee: any) => {
  const numTotalEventAttendee =
    attendee.eventAttendee && attendee.eventAttendee.ticketsCount;
  const numTotalVipAttendee =
    attendee.vipAttendee && attendee.vipAttendee.ticketsCount;
  return numTotalEventAttendee || numTotalVipAttendee || 0;
};

const vipTicketsSelectedFromNumAttended = (
  numAttended: number,
  numTotal: number
) =>
  [...Array(numTotal).keys()].reduce(
    (obj: object, num: number) => ({
      ...obj,
      [num + 1]: !!(num + 1 <= numAttended),
    }),
    {}
  );

const numVipsAttendedFromTicketsSelected = (ticketsSelected: object) =>
  Object.values(ticketsSelected).filter((isSelected: boolean) => isSelected)
    .length;

const GuestlistAttendanceEdit: React.FC<Props> = ({
  contentProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  navigateTo,
}) => {
  const intl = useIntl();
  const attendee = get(contentProps, 'attendee', undefined);

  const numVipsAttended = numVipsGuestsAttended(attendee);
  const numTotal = numGuestsTotal(attendee);

  const formInitialValues = {
    vipTicketsSelected: vipTicketsSelectedFromNumAttended(
      numVipsAttended,
      numTotal
    ),
    eventAttendeeTicketIdsSelected: attendee.eventAttendee?.tickets
      ?.filter((ticket: Ticket) => ticket.hasAttended)
      .map((ticket: Ticket) => ticket.id),
  };

  const updateEventAttendeeAttendanceAction = UpdateEventAttendeeAttendance();

  const handleUpdateEventAttendeeAttendance = useSubmitAction({
    submitAction: updateEventAttendeeAttendanceAction,
    submitVariables: (values: any) => ({
      eventAttendeeId: get(
        contentProps,
        'attendee.eventAttendee.id',
        undefined
      ),
      ticketIdsAttended: values.eventAttendeeTicketIdsSelected,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.guestlistDayOfShow.attendanceEdit.successMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.guestlistDayOfShow.attendanceEdit.failureMessage',
    }),
    onSuccess: () => {
      navigateTo({
        routeName: 'guestlist-day-of-show',
        routeProps: {
          id: get(contentProps, 'event_id', undefined),
        },
      });
      contentProps.refetchAttendees && contentProps.refetchAttendees();
    },
  });

  const updateVipAttendeeAction = UpdateVipAttendee();

  const handleUpdateVipAttendee = useSubmitAction({
    submitAction: updateVipAttendeeAction,
    submitVariables: (values: any) => ({
      vipAttendeeId: get(contentProps, 'attendee.vipAttendee.id', undefined),
      ticketsAttendedCount: numVipsAttendedFromTicketsSelected(
        values.vipTicketsSelected
      ),
    }),
    successMsg: intl.formatMessage({
      id: 'admin.guestlistDayOfShow.attendanceEdit.successMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.guestlistDayOfShow.attendanceEdit.failureMessage',
    }),
    onSuccess: () => {
      navigateTo({
        routeName: 'guestlist-day-of-show',
        routeProps: {
          id: get(contentProps, 'event_id', undefined),
        },
      });
    },
  });

  return (
    <ModalContentContainer>
      <FormMainContainer>
        <AttendeeHeader attendee={attendee} />
        <GenericForm
          formInitialValues={formInitialValues}
          renderFormComponent={(renderProps: any) => (
            <GuestlistAttendanceEditForm
              formikProps={renderProps.formikProps}
              numTotal={numTotal}
              attendee={attendee}
            />
          )}
          onSubmit={
            attendee.eventAttendee
              ? handleUpdateEventAttendeeAttendance
              : handleUpdateVipAttendee
          }
          formSchema={Yup.object().shape({})}
          setFormSubmitAction={setFormSubmitAction}
          setDisplayConfirmation={setDisplayConfirmation}
          setIsSubmitting={setIsSubmitting}
          dataQaId="guestlist-attendance-edit-form"
          paddingTop="0px"
        />
      </FormMainContainer>
    </ModalContentContainer>
  );
};

export default GuestlistAttendanceEdit;
