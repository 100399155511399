import React from 'react';
import { useIntl } from 'react-intl';

import { get } from 'app/shared/utils/get';
import ImageMultiEdit from 'app/shared/components/organisms/ImageMultiEdit';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

interface Props {
  contentProps?: any;
  setFormSubmitAction: (func: Function) => void;
  setDisplayConfirmation: (set: boolean) => void;
  setIsSubmitting: (set: boolean) => void;
  navigateTo: (routeData: object) => void;
}

const getRenderHeaderContent = (eventCollectionTitle?: string) => {
  return () => (
    <DetailsHeader
      title={eventCollectionTitle}
      loading={!eventCollectionTitle}
    />
  );
};

const EventCollectionHeaderImageUploader: React.FC<Props> = ({
  contentProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  navigateTo,
}) => {
  const intl = useIntl();

  const eventCollectionTitle = get(
    contentProps,
    'eventCollectionTitle',
    undefined
  );

  const onUploadSuccess = () => {
    navigateTo({
      routeName: 'event-collection-details',
      routeProps: {
        defaultOpenSection: 'basicInfo',
        id: contentProps.id,
        title: eventCollectionTitle,
      },
    });
  };

  const ratioDescriptions = {
    '1:1': intl.formatMessage({
      id: 'admin.multiImageEdit.ratioDescriptionEventCollection1x1',
    }),
    '16:9': intl.formatMessage({
      id: 'admin.multiImageEdit.ratioDescriptionEventCollection16x9',
    }),
  };

  return (
    <>
      <ImageMultiEdit
        objectType="EventCollection"
        objectId={contentProps.id}
        imagePurpose="header"
        ratios={['1:1', '16:9']}
        ratioDescriptions={ratioDescriptions}
        imageFieldDescription={intl.formatMessage({
          id: 'admin.eventCollectionHeaderImageEdit.imageFieldDescription',
        })}
        renderHeaderContent={getRenderHeaderContent(eventCollectionTitle)}
        whatImagesAreForDescription="this collection"
        onUploadSuccess={onUploadSuccess}
        setFormSubmitAction={setFormSubmitAction}
        setDisplayConfirmation={setDisplayConfirmation}
        setIsSubmitting={setIsSubmitting}
        dataQaidPrefix="event-collection-image-upload-form"
      />
    </>
  );
};

export default EventCollectionHeaderImageUploader;
