import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { Spacer } from 'app/shared/components/atoms/Spacer';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { DetailsMainContainer } from 'app/admin/components/atoms/DetailContent';
import PhotoCarousel from 'app/admin/components/molecules/PhotoCarousel';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

interface Props {
  navigateTo: (routeData: any) => void;
  contentProps: any;
  hide: (hideProps?: any) => VoidFunction;
}

const PhotoContainer = styled.div`
  ${({ theme }) => css`
    ${theme.media.xs`
      margin-left: 15px;
      height: 250px;
      width: 380px;
    `}

    ${theme.media.sm`
      margin-left: 0px;
      height: 250px;
    `}

    ${theme.media.md`
      height: 250px;
      width: 750px;
    `};
  `}
`;

const ViewVenuePhotos: React.FC<Props> = ({ contentProps }) => {
  const intl = useIntl();

  return (
    <ModalContentContainer data-qaid="venue-photos-modal">
      <DetailsMainContainer>
        <DetailsHeader
          title={intl.formatMessage({
            id: 'admin.crewEvent.section.venueSetupInfo.venueSetupPhotos',
          })}
        />
        <Spacer mb={5} />
        <PhotoContainer>
          <PhotoCarousel
            title={contentProps.venueName}
            timezone={contentProps.timezone}
            photos={contentProps.venuePhotos}
            label={`${intl.formatMessage({
              id: 'admin.crewPortal.venue.label',
            })}:`}
          />
        </PhotoContainer>
      </DetailsMainContainer>
    </ModalContentContainer>
  );
};

export default ViewVenuePhotos;
