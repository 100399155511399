import React, { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { EventStaffMember } from 'app/typings';
import { SlackMessage } from 'app/typings/slacks';
import { get } from 'app/shared/utils/get';
import { buildFullName } from 'app/shared/utils/string';
import useModal from 'app/shared/utils/useModal';
import usePermission from 'app/shared/utils/usePermission';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Spinner } from 'app/shared/components/atoms/SpinnerManualCSS';
import ConfirmationModal from 'app/shared/components/molecules/ConfirmationModal';
import InfoBox from 'app/shared/components/molecules/Flyover';
import IconAndTextLink from 'app/shared/components/molecules/IconAndTextLink';
import { eventStaffConfig } from 'app/admin/utils/crew';
import { HasEditPermissionForEvent as hasEditPermissionForEvent } from 'app/admin/utils/events';
import { ArchiveCrewMessage } from 'app/admin/graphql/staffMembers/mutationHooks';
import {
  GetCrewMessages,
  GetCrewSlackMessages,
} from 'app/admin/graphql/staffMembers/queryHooks';
import AddIconAndText from 'app/admin/components/atoms/AddIconAndText';
import { DetailHeading as Heading } from 'app/admin/components/atoms/DetailContent';
import IconLabel from 'app/admin/components/molecules/IconLabel';
import AdminEventBreakingNews from 'app/admin/components/organisms/CrewEventBreakingNews/AdminEventBreakingNews';
import EventPlannerBreakingNewsFlyover from 'app/admin/components/organisms/EventPlannerBreakingNewsFlyover';
import EventPlannerTeamMemberFlyover from 'app/admin/components/organisms/EventPlannerTeamMemberFlyover';
import { ReactComponent as BrowserPageAccount } from 'icons/streamline-regular/programing-apps-websites/website-development/browser-page-account.svg';

interface Props {
  sectionData: any;
  callbacks: { [name: string]: Function };
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const RolesGroupContainer = styled.div`
  ${({ theme }) => css`
    padding: 18px 14px;
    border-radius: 15px;
    background-color: ${theme.colors.silverSprings};
    width: 100%;
  `}
`;

const RoleNote = styled.div`
  ${({ theme }) => css`
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    font-style: italic;
    font-weight: 400;
    color: ${theme.colors.blueSmoke};
    padding-top: 16px;
    padding-bottom: 7px;
  `}
`;

const IconLabelNoPointer = styled(IconLabel)`
  cursor: default;
`;

const AddNewsContainer = styled.div`
  width: fit-content;
`;

const StyledIconAndTextLink = styled(IconAndTextLink)`
  width: fit-content !important;
`;

const SectionTeam: React.FC<Props> = ({ sectionData, callbacks }) => {
  const intl = useIntl();

  const { event, modalRef, navigateTo } = sectionData;

  const [flyoverClosed, setFlyoverClosed] = useState(false);
  const [staffState, setStaffState] = useState(get(event, 'staff', []));
  const [crewMessageIdToRemove, setCrewMessageIdToRemove] = useState<
    number | null
  >(null);
  const [
    archiveCrewMessageIsSubmitting,
    setArchiveCrewMessageIsSubmitting,
  ] = useState(false);
  const hasCreateCrewMessagePermission = usePermission(
    'event.crewMessage.create'
  );
  const hasAccessCrewPortalPermission = usePermission('event.crew.access');

  const {
    loading: loadingCrewMessages,
    data: dataCrewMessages,
    refetch: refetchCrewMessages,
  } = GetCrewMessages({
    eventId: event?.id,
    isArchived: false,
  });

  const {
    loading: loadingCancellactionSlackMessages,
    data: dataCancellationSlackMessages,
  } = GetCrewSlackMessages({
    eventId: event?.id,
    slackKey: 'CrewEventCancelled',
    skip: event && !event.cancelled,
  });

  const failedCancellationSlackMessages = dataCancellationSlackMessages?.slackMessages?.slackMessages?.filter(
    (message: SlackMessage) => !message.isSuccess
  );

  const canEditEvent = hasEditPermissionForEvent(
    get(event, 'eventOrganizedAs', undefined),
    get(event, 'curatorGroup', undefined)
  );

  const [
    archiveCrewMessageConfirmationModal,
    toggleArchiveCrewMessageConfirmationModal,
  ] = useModal();

  const archiveCrewMessageAction = ArchiveCrewMessage();
  const handleArchiveCrewMessage = useSubmitAction({
    submitAction: archiveCrewMessageAction,
    submitVariables: () => ({
      id: crewMessageIdToRemove,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.eventPlanner.team.deleteBreakingNewsSuccess',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.eventPlanner.team.deleteBreakingNewsFailure',
    }),
    onSuccess: () => {
      setArchiveCrewMessageIsSubmitting(false);
      toggleArchiveCrewMessageConfirmationModal();
      refetchCrewMessages();
    },
  });

  const getRoleLabelKeyForRole = (roleName: string) => {
    const eventStaffObject = eventStaffConfig[roleName];

    return eventStaffObject ? eventStaffObject.labelKey : '';
  };

  const getUserFullName = (user: any) =>
    buildFullName(user.firstName, user.lastName) || user.email;

  const renderLabelForRole = (roleKey: string) => {
    const staffMember = staffState.find(
      (staffMember: EventStaffMember) => staffMember.role.key === roleKey
    );

    const roleIsFilled = staffMember && staffMember.user && staffMember.user.id;
    const roleIsActive = !!staffMember;

    const getIconName = () => {
      if (roleIsFilled) {
        return 'check';
      }

      if (roleIsActive) {
        return 'plus';
      }

      return undefined;
    };

    const getColor = () => {
      if (roleIsFilled) {
        return 'purplePills';
      }

      if (roleIsActive) {
        return undefined;
      }

      return 'silverSprings';
    };

    const roleLabelKey = getRoleLabelKeyForRole(roleKey);
    const roleLabel = intl.formatMessage({
      id: `admin.eventPlanner.team.${roleLabelKey}`,
    });
    const iconName = getIconName();

    if (canEditEvent) {
      return (
        <InfoBox
          showCaret={false}
          width="350px"
          triggerElement={
            <div data-qaid={`${roleLabelKey}-icon-label`}>
              <IconLabel
                color={getColor()}
                onClick={() => setFlyoverClosed(false)}
                icon={iconName}
                emptyIcon={!iconName}
                invertIcon
                upperLabelText={
                  roleIsFilled ? roleLabel.toUpperCase() : undefined
                }
                labelText={
                  roleIsFilled
                    ? getUserFullName(staffMember.user)
                    : roleLabel.toUpperCase()
                }
                hasSmallLabelText={true}
              />
            </div>
          }
          onStateChange={isOpen => setFlyoverClosed(!isOpen)}
          shouldFlyoverClose={flyoverClosed}
          innerContent={
            <EventPlannerTeamMemberFlyover
              eventId={get(event, 'id', undefined)}
              localStartsAt={get(event, 'localStartsAt', undefined)}
              citySlug={get(event, 'city.cachedSlug', undefined)}
              eventOrganizedAs={get(event, 'eventOrganizedAs', undefined)}
              eventStaffMember={staffMember}
              roleLabel={roleLabel}
              roleKey={roleKey}
              callbacks={callbacks}
              onSuccess={(updatedStaff: EventStaffMember[]) => {
                setFlyoverClosed(true);
                setStaffState(updatedStaff);
              }}
            />
          }
        />
      );
    } else {
      return (
        <>
          <IconLabelNoPointer
            color={getColor()}
            onClick={() => {}}
            icon={iconName}
            emptyIcon={!iconName}
            invertIcon
            upperLabelText={roleIsFilled ? roleLabel.toUpperCase() : undefined}
            labelText={
              roleIsFilled
                ? getUserFullName(staffMember.user)
                : roleLabel.toUpperCase()
            }
            hasSmallLabelText={true}
          />
          <Spacer mt={2} />
        </>
      );
    }
  };

  const eventOrganizedAs = get(event, 'eventOrganizedAs', undefined);
  const addNewsButtonRef = useRef<any>();

  return (
    <>
      <MainContainer data-qaid="section-team">
        <Grid>
          {eventOrganizedAs === 'o_and_o' && (
            <Col xs={12} sm={12} md={6} lg={3} xl={3} key={4}>
              <Heading>
                {intl.formatMessage({
                  id: 'admin.eventPlanner.team.breakingNews',
                })}
              </Heading>

              {hasCreateCrewMessagePermission && (
                <InfoBox
                  zIndex={102}
                  anchorRef={addNewsButtonRef}
                  containerRef={modalRef}
                  showCaret={false}
                  width="300px"
                  triggerElement={
                    <AddNewsContainer ref={addNewsButtonRef}>
                      <AddIconAndText
                        text={intl.formatMessage({
                          id: 'admin.eventPlanner.team.addNews',
                        })}
                        onClick={() => {}}
                        dataQaid="add-news-button"
                        sentenceCaseText
                      />
                    </AddNewsContainer>
                  }
                  keepInViewPort={{
                    vertical: true,
                    horizontal: true,
                  }}
                  onStateChange={isOpen =>
                    setFlyoverClosed && setFlyoverClosed(!isOpen)
                  }
                  shouldFlyoverClose={flyoverClosed}
                  innerContent={
                    <EventPlannerBreakingNewsFlyover
                      eventId={get(event, 'id', undefined)}
                      onSuccess={() => {
                        setFlyoverClosed(true);
                        refetchCrewMessages();
                      }}
                    />
                  }
                />
              )}

              {loadingCrewMessages || loadingCancellactionSlackMessages ? (
                <>
                  <Spacer mt={4} />
                  <Spinner />
                </>
              ) : (
                <AdminEventBreakingNews
                  messages={dataCrewMessages?.crewMessages.crewMessages || []}
                  failedCancellationMessages={
                    failedCancellationSlackMessages || []
                  }
                  event={event}
                  eventId={event.id}
                  onRemove={(id: number) => {
                    setCrewMessageIdToRemove(id);
                    toggleArchiveCrewMessageConfirmationModal();
                  }}
                  navigateTo={navigateTo}
                  isAdmin
                  truncateMessage
                />
              )}
            </Col>
          )}
          <Col xs={12} sm={12} md={6} lg={3} xl={3} key={1}>
            <Heading>
              {intl.formatMessage({
                id: 'admin.eventPlanner.team.primaryRoles',
              })}
            </Heading>
            <Spacer mt={2} />
            <RolesGroupContainer>
              {renderLabelForRole('crew_lead')}
              <Spacer mt={2} />
              {renderLabelForRole('crew_emcee')}
              {eventOrganizedAs === 'o_and_o' && (
                <>
                  <Spacer mt={2} />
                  {renderLabelForRole('booker')}
                </>
              )}
              {eventOrganizedAs === 'o_and_o' && (
                <>
                  <RoleNote>
                    {intl.formatMessage({
                      id: 'admin.eventPlanner.team.virtualConcertsOnly',
                    })}
                  </RoleNote>
                  <Spacer mt={2} />
                  {renderLabelForRole('moderator')}
                  <Spacer mt={2} />
                  {renderLabelForRole('producer')}
                </>
              )}
            </RolesGroupContainer>
          </Col>
          <Col xs={12} sm={12} md={6} lg={3} xl={3} key={2}>
            <Heading>
              {intl.formatMessage({
                id: 'admin.eventPlanner.team.support',
              })}
            </Heading>
            <Spacer mt={2} />
            <RolesGroupContainer>
              {renderLabelForRole('day_of_show_support_1')}
              <Spacer mt={2} />
              {renderLabelForRole('day_of_show_support_2')}
              <Spacer mt={2} />
              {renderLabelForRole('day_of_show_support_3')}
              <Spacer mt={2} />
              {renderLabelForRole('day_of_show_support_4')}
            </RolesGroupContainer>
          </Col>
          <Col xs={12} sm={12} md={6} lg={3} xl={3} key={3}>
            <Heading>
              {intl.formatMessage({
                id: 'admin.eventPlanner.team.audioVideoPhoto',
              })}
            </Heading>
            <Spacer mt={2} />
            <RolesGroupContainer>
              {renderLabelForRole('audio')}
              <Spacer mt={2} />
              {renderLabelForRole('gear_runner')}
              <Spacer mt={2} />
              {renderLabelForRole('video_1')}
              <Spacer mt={2} />
              {renderLabelForRole('video_2')}
              <Spacer mt={2} />
              {renderLabelForRole('video_3')}
              <Spacer mt={2} />
              {renderLabelForRole('photo')}
            </RolesGroupContainer>
          </Col>
        </Grid>
        {hasAccessCrewPortalPermission && (
          <>
            <Spacer mt={8} />
            <StyledIconAndTextLink
              icon={BrowserPageAccount}
              text={intl.formatMessage({
                id: 'admin.eventPlanner.team.viewCrewFacingDetailPage',
              })}
              url={`/admin/crew-portal/events/${event?.id}`}
            />
          </>
        )}
      </MainContainer>
      {archiveCrewMessageConfirmationModal.isShowing && (
        <ConfirmationModal
          onCancel={() => archiveCrewMessageConfirmationModal.hide()}
          description={intl.formatMessage({
            id: 'admin.eventPlanner.team.deleteBreakingNewsConfirmation',
          })}
          onConfirm={() => {
            setArchiveCrewMessageIsSubmitting(true);
            handleArchiveCrewMessage();
          }}
          confirmationButtonText={intl.formatMessage({ id: 'delete' })}
          isLoading={archiveCrewMessageIsSubmitting}
        />
      )}
    </>
  );
};

export default SectionTeam;
