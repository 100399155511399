import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Container } from 'app/shared/components/atoms/Container';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Body2, H1, H5 } from 'app/shared/components/atoms/TypographyManualCSS';
import SEO from 'app/shared/components/molecules/SEO';
import Layout from 'app/admin/layouts/Admin';

import GdprDataRemoval from './GdprDataRemoval';

// @ts-ignore
const MainContainer = styled(Container)`
  @media screen and (min-width: 1300px) {
    max-width: 1260px;
  }
`;

const AdminGdprDataRemovalForm: React.FC = () => {
  const intl = useIntl();

  return (
    <Layout data-qaid="gdpr-data-removal-form">
      <SEO title="Admin Gdpr Data Removal Form" />
      <Spacer mb={8} />
      <MainContainer>
        <H1>
          {intl.formatMessage({ id: 'admin.gdprDataRemovalForm.pageTitle' })}
        </H1>
        <Grid>
          <Col xs={12} sm={8} md={6}>
            <H5>
              {intl.formatMessage({
                id: 'admin.gdprDataRemovalForm.thisToolWillSafelyRemove',
              })}
            </H5>
            <Spacer mb={2} />
            <Body2>
              -{' '}
              {intl.formatMessage({
                id: 'admin.gdprDataRemovalForm.deletingStripeCustomer',
              })}
            </Body2>
            <Body2>
              -{' '}
              {intl.formatMessage({
                id: 'admin.gdprDataRemovalForm.anonymizingKlaviyoData',
              })}
            </Body2>
            <Body2>
              -{' '}
              {intl.formatMessage({
                id: 'admin.gdprDataRemovalForm.destroyingOrAnonymizing',
              })}
            </Body2>
            <Spacer mb={4} />
            <H5>
              {intl.formatMessage({
                id: 'admin.gdprDataRemovalForm.howDoesItWork',
              })}
            </H5>
            <Body2>
              {intl.formatMessage({
                id: 'admin.gdprDataRemovalForm.toProceedEnterTheUser',
              })}
            </Body2>
            <GdprDataRemoval />
          </Col>
        </Grid>
      </MainContainer>
    </Layout>
  );
};

export default AdminGdprDataRemovalForm;
