import React from 'react';
import { useIntl } from 'react-intl';

import { artistSocialUrls } from 'app/shared/utils/socialLinks';
import GenericFormContainer from 'app/shared/components/atoms/GenericFormContainer';
import SocialLinkFormFields from 'app/admin/components/molecules/SocialLinkFormFields';

interface FormProps {
  formikProps: any;
}

const ArtistSocialsEditForm: React.FC<FormProps> = ({ formikProps }) => {
  const intl = useIntl();

  const { setFieldValue, touched, errors, values } = formikProps;

  return (
    <GenericFormContainer dataQaId="artist-socials-edit-form">
      <SocialLinkFormFields
        setFieldValue={setFieldValue}
        touched={touched}
        errors={errors}
        values={values}
        socialLinkKeys={artistSocialUrls}
        titleText={intl.formatMessage({
          id: 'admin.artistSocialsEdit.form.editLinks',
        })}
        topHelpText={intl.formatMessage({
          id: 'admin.artistSocialsEdit.form.linksHelpText',
        })}
        helpTextByLink={{
          urlSupport: intl.formatMessage({
            id: 'admin.artistSocialsEdit.form.supportLinkHelpText',
          }),
        }}
        dataQaidPrefix="artist-social-edit-form"
      />
    </GenericFormContainer>
  );
};

export default ArtistSocialsEditForm;
