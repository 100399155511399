import styled, { css } from 'styled-components';

import { withTextfieldStyle } from 'app/shared/components/styleUtils/manualCSS/withTextfieldStyle';
import { withUpdatedLinkStyle } from 'app/shared/components/styleUtils/withLinkStyleCSS';

export const getSpaceAfter = (defaultSpace: number, spaceAfter?: number) => {
  return spaceAfter !== undefined && spaceAfter >= 0
    ? spaceAfter
    : defaultSpace;
};

export const withH1Style = ({ spaceAfter }: any) => css`
  ${({ theme }) => css`
    ${theme.fontStyles.h1.fontFamily &&
      css`
        font-family: ${theme.fontStyles.h1.fontFamily};
      `};
    font-size: ${theme.fontSizes.h1.xs};
    font-weight: 400;
    margin: 0;
    padding: 0;
    margin-bottom: ${theme.ruler[getSpaceAfter(10, spaceAfter)]}px;
    color: ${theme.fontColors.secondary};
    line-height: ${theme.fontStyles.h1.lineHeight};
    text-transform: ${theme.fontStyles.h1.textTransform};
    letter-spacing: ${theme.fontStyles.h1.letterSpacing};

    ${theme.media.md`
        font-size: ${theme.fontSizes.h1.md};
      `};

    ${theme.media.lg`
        font-size: ${theme.fontSizes.h1.lg};
      `};
  `}
`;

export const withH2Style = ({ spaceAfter }: any) => css`
  ${({ theme }) => css`
    ${theme.fontStyles.h2.fontFamily &&
      css`
        font-family: ${theme.fontStyles.h2.fontFamily} !important;
      `};
    font-size: ${theme.fontSizes.h2.xs};
    font-weight: 400;
    margin: 0;
    padding: 0;
    margin-bottom: ${theme.ruler[getSpaceAfter(8, spaceAfter)]}px;
    color: ${theme.fontColors.secondary};
    line-height: ${theme.fontStyles.h2.lineHeight};
    text-transform: ${theme.fontStyles.h2.textTransform};
    letter-spacing: ${theme.fontStyles.h2.letterSpacing};

    ${theme.media.md`
        font-size: ${theme.fontSizes.h2.md};
      `};

    ${theme.media.lg`
        font-size: ${theme.fontSizes.h2.lg};
      `};
  `}
`;

export const withH3Style = ({ spaceAfter }: any) => css`
  ${({ theme }) => css`
    ${theme.fontStyles.h3.fontFamily &&
      css`
        font-family: ${theme.fontStyles.h3.fontFamily};
      `};
    font-size: ${theme.fontSizes.h3.xs};
    font-weight: 400;
    margin: 0;
    padding: 0;
    margin-bottom: ${theme.ruler[getSpaceAfter(8, spaceAfter)]}px;
    color: ${theme.fontColors.secondary};
    line-height: ${theme.fontStyles.h3.lineHeight};
    text-transform: ${theme.fontStyles.h3.textTransform};
    letter-spacing: ${theme.fontStyles.h3.letterSpacing};

    ${theme.media.md`
        font-size: ${theme.fontSizes.h3.md};
      `};

    ${theme.media.lg`
        font-size: ${theme.fontSizes.h3.lg};
      `};
  `}
`;

export const withH4Style = ({ spaceAfter }: any) => css`
  ${({ theme }) => css`
    ${theme.fontStyles.h4.fontFamily &&
      css`
        font-family: ${theme.fontStyles.h4.fontFamily};
      `};
    font-size: ${theme.fontSizes.h4.xs};
    font-weight: 400;
    margin: 0;
    padding: 0;
    margin-bottom: ${theme.ruler[getSpaceAfter(6, spaceAfter)]}px;
    color: ${theme.fontColors.secondary};
    line-height: ${theme.fontStyles.h4.lineHeight};
    text-transform: ${theme.fontStyles.h4.textTransform};
    letter-spacing: ${theme.fontStyles.h4.letterSpacing};

    ${theme.media.md`
        font-size: ${theme.fontSizes.h4.md};
      `};

    ${theme.media.lg`
        font-size: ${theme.fontSizes.h4.lg};
      `};
  `}
`;

export const withH5Style = ({ spaceAfter }: any) => css`
  ${({ theme }) => css`
    ${theme.fontStyles.h5.fontFamily &&
      css`
        font-family: ${theme.fontStyles.h5.fontFamily};
      `};
    font-size: ${theme.fontSizes.h5.xs};
    font-weight: 400;
    margin: 0;
    padding: 0;
    margin-bottom: ${theme.ruler[getSpaceAfter(4, spaceAfter)]}px;
    color: ${theme.fontColors.secondary};
    line-height: ${theme.fontStyles.h5.lineHeight};
    text-transform: ${theme.fontStyles.h5.textTransform};
    letter-spacing: ${theme.fontStyles.h5.letterSpacing};

    ${theme.media.md`
        font-size: ${theme.fontSizes.h5.md};
      `};

    ${theme.media.lg`
        font-size: ${theme.fontSizes.h5.lg};
      `};
  `}
`;

export const withH6Style = ({ spaceAfter }: any) => css`
  ${({ theme }) => css`
    ${theme.fontStyles.h6.fontFamily &&
      css`
        font-family: ${theme.fontStyles.h6.fontFamily};
      `};
    font-size: ${theme.fontSizes.h6.xs};
    font-weight: 400;
    margin: 0;
    padding: 0;
    margin-bottom: ${theme.ruler[getSpaceAfter(4, spaceAfter)]}px;
    color: ${theme.fontColors.secondary};
    line-height: ${theme.fontStyles.h6.lineHeight};
    text-transform: ${theme.fontStyles.h6.textTransform};
    letter-spacing: ${theme.fontStyles.h6.letterSpacing};

    ${theme.media.md`
        font-size: ${theme.fontSizes.h6.md};
      `};

    ${theme.media.lg`
        font-size: ${theme.fontSizes.h6.lg};
      `};
  `}
`;

export default styled.div`
  ${({ theme }) => css`
    h1 {
      ${withH1Style}
    }

    h2 {
      ${withH2Style}
    }

    h2 > strong {
      ${withH2Style}
    }

    h3 {
      ${withH3Style}
    }

    h4 {
      ${withH4Style}
    }

    h5 {
      ${withH5Style}
    }

    h6 {
      ${withH6Style}
    }

    a {
      ${withUpdatedLinkStyle}
    }

    .no-margin {
      margin-bottom: 0px;
    }

    .overline {
      color: ${theme.colors.primary};
      font-size: ${theme.fontSizes.overline};
      font-weight: normal;
      text-transform: uppercase;
      letter-spacing: 1.5px;
      line-height: 1.6;
      margin: 0;
      padding: 0;
      margin-bottom: 0px;
      display: inline-block;
    }

    .stripe-input {
      ${withTextfieldStyle};
      padding: 11px 16px;

      &.error {
        border-color: ${theme.colors.redRedWine};
      }
    }

    .checkout-v2-stripe-input {
      font-size: 16px;
      line-height: 16px;
      font-weight: 400;
      color: ${theme.colors.backToBlack};
      padding-top: 6px;

      &.error {
        border-color: ${theme.colors.redRedWine};
      }
    }

    .button {
      border-radius: ${theme.borderRadius.button};

      cursor: pointer;
      font-weight: 400;
      height: ${theme.dimensions.button.default.height};
      padding: ${theme.dimensions.button.default.padding};
      letter-spacing: 0.8px;
      outline: none;
      text-decoration: none;
      text-transform: uppercase;
      display: inline-block;

      ${theme.media.xs`
        font-size: ${theme.fontSizes.button.xs};
      `};

      ${theme.media.lg`
        font-size: ${theme.fontSizes.button.lg};
      `};

      &:disabled {
        cursor: not-allowed;
        pointer-events: none;
      }

      background: ${theme.colors.primary};
      border: 1px solid ${theme.colors.primary};
      color: ${theme.colors.whiteDenim};

      &:hover {
        background-color: ${theme.colors.green900};
      }

      &:focus {
        background-color: ${theme.colors.primary};
      }

      &:disabled {
        background-color: ${theme.colors.blueSmoke};
        border-color: ${theme.colors.blueSmoke};
      }

      i:before {
        color: ${theme.colors.whiteDenim};
      }

      a {
        color: ${theme.colors.backToBlack} !important;
        text-decoration: none;
        vertical-align: middle;
        line-height: 41px;
      }
    }
  `}
`;
