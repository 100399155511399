import styled, { css } from 'styled-components';

import { H6 } from 'app/shared/components/atoms/TypographyManualCSS';

interface DetailTextProps {
  greyedOut?: boolean;
}

const DetailText = styled(H6)<DetailTextProps>`
  ${({ theme, greyedOut }) => css`
    color: ${greyedOut ? theme.colors.darkSmoke : theme.colors.backToBlack};
    font-weight: normal;
    line-height: 1.5;
    margin: 0;
  `}
`;

export default DetailText;
