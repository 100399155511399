import React from 'react';
import styled, { css } from 'styled-components';

import { H2 } from 'app/shared/components/atoms/TypographyManualCSS';

interface ColoredCircleInfoProps {
  backgroundColor: string;
  mainInfo: string | number | undefined;
  description: string;
}

interface RoundBackgroundProps {
  backgroundColor: string;
  diameterXs: string;
  diameterSm: string;
}

const RoundBackground = styled.div`
  ${({ theme }) => css`
    ${theme.media.xs`
      width: ${(props: RoundBackgroundProps) => props.diameterXs};
      height: ${(props: RoundBackgroundProps) => props.diameterXs};
    `};
    ${theme.media.sm`
      width: ${(props: RoundBackgroundProps) => props.diameterSm};
      height: ${(props: RoundBackgroundProps) => props.diameterSm};
      padding: 0 12px;
    `};
    ${theme.media.xl`
    padding: 0;
  `};
    border-radius: 50%;
    background-color: ${(props: RoundBackgroundProps) => props.backgroundColor};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
  `}
`;

const RoundMainInfo = styled(H2)`
  margin-bottom: 0px;
`;

const RoundDescription = styled.p`
  ${({ theme }) => css`
    text-align: center;
    font-size: 14px;
    display: block;
    width: 100%;
    margin: 0;
    white-space: pre-line;
    ${theme.media.xl`
      width: 60%;
    `};
  `}
`;

export const ColoredCircleInfo: React.FC<ColoredCircleInfoProps> = ({
  backgroundColor,
  mainInfo,
  description,
}) => {
  return (
    <RoundBackground
      backgroundColor={backgroundColor}
      diameterXs="95px"
      diameterSm="130px"
    >
      <RoundMainInfo>{mainInfo}</RoundMainInfo>
      <RoundDescription>{description}</RoundDescription>
    </RoundBackground>
  );
};
