import React, { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Event } from 'app/typings/events';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import { FlyoverContext } from 'app/shared/context/Flyover';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import {
  FlyoverBackLink,
  FlyoverNote,
} from 'app/shared/components/atoms/FlyoverContent';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Overline } from 'app/shared/components/atoms/TypographyManualCSS';
import { CreateVipAttendee } from 'app/admin/graphql/vipAttendees/mutationHooks';
import FlyoverFooter from 'app/admin/components/molecules/FlyoverFooter';
import { FlyoverFormContainer } from 'app/admin/components/molecules/FlyoverFormContent';

import GuestlistVipEditForm from './GuestlistVipEditForm';
import GuestlistVipEditFormSchema from './GuestlistVipEditFormSchema';

interface Props {
  event?: Event;
  onSuccess: Function;
}

const Title = styled.div`
  padding: 15px 0px 10px 0px;
`;

const GuestlistVipCreate: React.FC<Props> = ({ event, onSuccess }) => {
  const intl = useIntl();
  const [formSubmitAction, setFormSubmitAction] = useState(() => () => {});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [validationErrors, setValidationErrors] = useState<object>({});
  const { closeFlyover } = useContext(FlyoverContext);

  const formInitialValues = {
    fullName: '',
    email: '',
    reference: '',
    ticketsCount: '',
  };

  const createVipAttendeeAction = CreateVipAttendee();

  const handleSubmit = useSubmitAction({
    beforeSubmit: () => setIsSubmitting(true),
    submitAction: createVipAttendeeAction,
    submitVariables: (values: any) => ({
      eventId: event && event.id,
      fullName: values.fullName,
      email: values.email,
      reference: values.reference,
      ticketsCount: Number(values.ticketsCount),
    }),
    successMsg: intl.formatMessage({
      id: 'admin.guestlist.vipEdit.createSuccessMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.guestlist.vipEdit.createFailureMessage',
    }),
    onSuccess: () => {
      setIsSubmitting(false);
      closeFlyover();
      onSuccess();
    },
    onValidationError: validationErrors => {
      setIsSubmitting(false);
      setValidationErrors(validationErrors);
    },
  });

  return (
    <div data-qaid="guestlist-vip-create">
      <FlyoverBackLink
        noPadding={true}
        onClickButton={() => {
          closeFlyover();
          onSuccess();
        }}
      />
      <Title>
        <Overline>
          {intl.formatMessage({
            id: 'admin.guestlist.vipEdit.addVip',
          })}
        </Overline>
      </Title>
      <DottedLine />
      <FlyoverFormContainer
        validateOnChange
        formInitialValues={formInitialValues}
        renderFormComponent={(renderProps: any) => (
          <GuestlistVipEditForm
            formikProps={renderProps.formikProps}
            // @ts-ignore
            setFormSubmitAction={setFormSubmitAction}
            validationErrors={validationErrors}
          />
        )}
        formSchema={GuestlistVipEditFormSchema(intl)}
        dataQaId="guestlist-vip-create-form"
        onSubmit={handleSubmit}
      />
      <Spacer mb={3} />
      <FlyoverNote>
        {intl.formatMessage({
          id: 'admin.guestlist.vipEdit.vipWillReceiveEmail',
        })}
      </FlyoverNote>
      <Spacer mt={8} />
      <FlyoverFooter
        buttonText={intl.formatMessage({
          id: 'admin.guestlist.vipEdit.addVip',
        })}
        onClickButton={() => formSubmitAction && formSubmitAction()}
        isSubmitting={isSubmitting}
      />
    </div>
  );
};

export default GuestlistVipCreate;
