import React from 'react';
import { useIntl } from 'react-intl';

import { Event } from 'app/typings';
import { SlackMessage } from 'app/typings/slacks';
import { dateFormatter } from 'app/shared/utils/datetime';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Overline } from 'app/shared/components/atoms/TypographyManualCSS';
import { userFullNameOrEmail } from 'app/admin/utils/users';
import { SectionText } from 'app/admin/components/atoms/CrewEventContent';
import { DetailHeading as Heading } from 'app/admin/components/atoms/DetailContent';
import ExpandableText from 'app/admin/components/atoms/ExpandableText';

import FailedSlackMessage from './FailedSlackMessage';
import {
  CrewMessageContainer,
  CrewMessageInfo,
  CrewMessageText,
} from './shared';

interface Props {
  event: Event;
  failedMessages: SlackMessage[];
}

const FailedCancellationSlackMessages: React.FC<Props> = ({
  event,
  failedMessages,
}) => {
  const intl = useIntl();

  if (
    !event ||
    failedMessages.length === 0 ||
    event.cancellations.length === 0
  ) {
    return null;
  }

  const lastCancellation = event.cancellations[event.cancellations.length - 1];

  const createdByUser = userFullNameOrEmail(lastCancellation.createdBy);
  const createdAtDate = dateFormatter(
    lastCancellation.updatedAt,
    'shortWeekdayShortMonthDateAndYear',
    event.city.timezone
  );
  const createdAtTime = dateFormatter(
    lastCancellation.updatedAt,
    'hoursAndMinutesWithSpacedAMPM',
    event.city.timezone
  );

  return (
    <>
      <Spacer mb={5} />
      <DottedLine />
      <Spacer mb={5} />
      <SectionText>
        <Heading>
          {intl.formatMessage({
            id: 'admin.crewEvent.section.cancellationMessages.title',
          })}
        </Heading>
      </SectionText>
      <SectionText>
        <CrewMessageContainer>
          <CrewMessageInfo>
            <Overline>{createdByUser}</Overline>
          </CrewMessageInfo>
          <Spacer mb={2} />
          <CrewMessageInfo>
            <Overline>
              {createdAtDate} - {createdAtTime}
            </Overline>
          </CrewMessageInfo>
        </CrewMessageContainer>
      </SectionText>
      <CrewMessageText>
        <ExpandableText
          allowHTML
          text={
            lastCancellation.reasonDescription ||
            lastCancellation.cancellationReason?.description
          }
          truncateLength={70}
        />
      </CrewMessageText>
      {failedMessages.map(failedMessage => (
        <FailedSlackMessage
          key={failedMessage.id}
          failedMessage={failedMessage}
        />
      ))}
    </>
  );
};

export default FailedCancellationSlackMessages;
