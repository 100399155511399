import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Country } from 'app/typings/countries';
import usePermission from 'app/shared/utils/usePermission';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import { GenericLink } from 'app/shared/components/atoms/LinkManualCSS';
import {
  DetailHeading as Heading,
  DetailValueContainer as ValueContainer,
} from 'app/admin/components/atoms/DetailContent';
import { None } from 'app/admin/components/atoms/None';

interface Props {
  sectionData: Country;
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const SectionBasicInfo: React.FC<Props> = ({ sectionData }) => {
  const intl = useIntl();

  const hasAccessCitiesPermission = usePermission('city.list.access');

  return (
    <MainContainer data-qaid="section-about">
      <Grid cols={4}>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={1}>
          <Heading>
            {intl.formatMessage({
              id: 'shared.title',
            })}
          </Heading>
          <ValueContainer>{sectionData.title}</ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.countries.heading.continent',
            })}
          </Heading>
          <ValueContainer>
            {sectionData.continent && sectionData.continent.title}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.countries.heading.countryCode',
            })}
          </Heading>
          <ValueContainer>{sectionData.countryCode}</ValueContainer>
        </Col>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={2}>
          <Heading>
            {intl.formatMessage({
              id: 'shared.cities',
            })}
          </Heading>
          <ValueContainer>
            {hasAccessCitiesPermission ? (
              <GenericLink
                url={`/admin/city-directory?country=${sectionData.cachedSlug}`}
              >
                {sectionData.citiesCount}
              </GenericLink>
            ) : (
              sectionData.citiesCount
            )}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.countries.heading.urlSlug',
            })}
          </Heading>
          <ValueContainer>{sectionData.cachedSlug}</ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.countries.heading.aliases',
            })}
          </Heading>
          <ValueContainer>{sectionData.aliases || <None />}</ValueContainer>
        </Col>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={3}>
          <Heading>
            {intl.formatMessage({
              id: 'admin.countries.heading.currencyCode',
            })}
          </Heading>
          <ValueContainer>{sectionData.currency || <None />}</ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.countries.heading.currencySymbol',
            })}
          </Heading>
          <ValueContainer>
            {sectionData.currencySymbol || <None />}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.countries.heading.exchangeRateWithUsd',
            })}
          </Heading>
          <ValueContainer>
            {sectionData.exchangeRateWithUsd ? (
              sectionData.exchangeRateWithUsd
            ) : (
              <None />
            )}
          </ValueContainer>
        </Col>
      </Grid>
    </MainContainer>
  );
};

export default SectionBasicInfo;
