import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { Event } from 'app/typings/events';
import { dateFormatter } from 'app/shared/utils/datetime';
import {
  DeleteIcon,
  SearchIcon,
} from 'app/shared/components/atoms/IconLibrary';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Textfield } from 'app/shared/components/atoms/Textfield';
import TruncatedByCharText from 'app/shared/components/atoms/TruncatedByCharText';
import { Body } from 'app/shared/components/atoms/TypographyManualCSS';
import { GetEventForEventSelectorLazy } from 'app/admin/graphql/events/queryHooks';
import { EventLink } from 'app/admin/components/molecules/EntityLink';

interface Props {
  selectedEvent: Event | undefined;
  setEvent: (event: Event) => void;
}

interface MultipleEventSelectorProps {
  selectedEvents: Event[];
  setEvents: (events: Event[]) => void;
}

const MainContainer = styled.div`
  margin-bottom: 10px;
`;

const SearchEvent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 0px;
`;

const SearchEventField = styled.div`
  width: 165px;
`;

const SearchEventIcon = styled.div`
  width: 60px;
  padding-top: 12px;
  padding-left: 5px;
`;

const EventIdTextfield = styled(Textfield)`
  display: block;
  width: 150px;
`;

const SearchError = styled.div`
  ${({ theme }) => css`
    font-size: 14px;
    font-weight: 400;
    color: ${theme.colors.redRedWine};
    margin-top: 15px;
    margin-bottom: 20px;
  `}
`;

const SearchResultContainer = styled.div`
  ${({ theme }) => css`
    width: 270px;
    border: 1px solid #ccc;
    border-top: none;
    background-color: ${theme.colors.whiteDenim};
    padding: 8px;
    white-space: nowrap;
    cursor: pointer;
    margin-bottom: 10px;
  `}
`;

const SelectedEvent = styled.div`
  margin-top: 4px;
  margin-bottom: 4x;
  width: 80px;
  white-space: nowrap;
`;

const isValidEventId = (eventId: string | null) => {
  if (eventId && /^\d+$/.test(eventId.trim()) && Number(eventId) >= 1) {
    return true;
  } else {
    return false;
  }
};

const renderEventName = (event: Event) => {
  const neighborhoodStr = event.venue?.neighborhood?.title
    ? `, ${event.venue.neighborhood.title}`
    : '';
  return `${dateFormatter(event.localStartsAt, 'shortMonthDateAndYear')} ${
    event.city.title
  }${neighborhoodStr}`;
};

interface SearchResultProps {
  event: Event;
  onSelectEvent: VoidFunction;
}

const SearchResult: React.FC<SearchResultProps> = ({
  event,
  onSelectEvent,
}) => {
  return (
    <SearchResultContainer
      data-qaid="event-search-result"
      onClick={() => onSelectEvent()}
    >
      <TruncatedByCharText
        text={renderEventName(event) || null}
        truncateLength={25}
      />
    </SearchResultContainer>
  );
};

interface EventPickerProps {
  setEvent: (event: Event) => void;
}

const EventPicker: React.FC<EventPickerProps> = ({ setEvent }) => {
  const intl = useIntl();

  const [eventId, setEventId] = useState<number | undefined>(undefined);
  const [eventIdEntered, setEventIdEntered] = useState<string>('');
  const [showSearchResult, setShowSearchResult] = useState(true);

  const [
    fetchEvent,
    { loading: loadingEvent, error: errorEvent, data: dataEvent },
  ] = GetEventForEventSelectorLazy({
    id: eventId,
    includeDraftEvents: true,
  });

  const handleSearch = () => {
    setShowSearchResult(true);
    if (isValidEventId(eventIdEntered)) {
      setEventId(Number(eventIdEntered));
    }
  };

  const handleSelectEvent = () => {
    if (dataEvent) {
      setEvent(dataEvent.event);
      setShowSearchResult(false);
      setEventIdEntered('');
    }
  };

  useEffect(() => {
    if (eventId) {
      fetchEvent();
    }
  }, [eventId, fetchEvent]);

  return (
    <MainContainer>
      <SearchEvent>
        <SearchEventField>
          <EventIdTextfield
            value={eventIdEntered}
            data-qaid="event-picker-event-id-field"
            id="eventId"
            name="eventId"
            placeholder={intl.formatMessage({
              id: 'admin.eventPicker.eventIdPlaceholder',
            })}
            onChange={(e: any) => {
              setEventIdEntered(e.target.value);
            }}
            maxLength={8}
          />
        </SearchEventField>
        <SearchEventIcon>
          <SearchIcon onClick={() => handleSearch()} />
        </SearchEventIcon>
      </SearchEvent>
      {errorEvent && (
        <SearchError>
          {intl.formatMessage({
            id: 'admin.eventPicker.eventNotFound',
          })}
        </SearchError>
      )}
      {!loadingEvent &&
        !errorEvent &&
        dataEvent &&
        dataEvent.event &&
        showSearchResult && (
          <SearchResult
            event={dataEvent.event}
            onSelectEvent={() => handleSelectEvent()}
          />
        )}
    </MainContainer>
  );
};

export const EventSelector: React.FC<Props> = ({ selectedEvent, setEvent }) => {
  return (
    <>
      {!selectedEvent && <EventPicker setEvent={setEvent} />}
      {selectedEvent && (
        <SelectedEvent>
          <EventLink
            event={selectedEvent}
            truncateLength={30}
            renderEventName={renderEventName}
          />
        </SelectedEvent>
      )}
    </>
  );
};

export const MultipleEventsSelector: React.FC<MultipleEventSelectorProps> = ({
  selectedEvents,
  setEvents,
}) => {
  const EventSelectorContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  `;

  const EventRemove = styled.div`
    padding-left: 20px;
  `;

  const eventDateComparator = (event1: Event, event2: Event) => {
    // sort by event localStartsAt descending
    return (
      new Date(event2.localStartsAt).getTime() -
      new Date(event1.localStartsAt).getTime()
    );
  };

  const setEvent = (event: Event) => {
    if (selectedEvents.some(e => e.id === event.id)) {
      return;
    }

    // Need to clone so formik change detection will work correctly
    const selectedEventsClone = [...selectedEvents];
    selectedEventsClone.push(event);
    setEvents(selectedEventsClone.sort(eventDateComparator));
  };

  const removeEvent = (eventId: string) => {
    const events = selectedEvents.filter(
      (event: Event) => event.id !== eventId
    );
    setEvents(events.sort(eventDateComparator));
  };

  const selectedEventsCities = [
    ...new Set(selectedEvents.map(event => event.city.title)),
  ].sort();
  const sortedEvents = selectedEvents.sort(eventDateComparator);

  return (
    <>
      <EventPicker setEvent={setEvent} />
      {selectedEventsCities &&
        selectedEventsCities.map((cityTitle: string) => (
          <React.Fragment key={cityTitle}>
            <Body fontWeight="bold">{cityTitle}</Body>
            {sortedEvents
              .filter(event => event.city.title === cityTitle)
              .map(event => (
                <SelectedEvent key={event.id}>
                  <EventSelectorContainer>
                    <EventLink
                      event={event}
                      truncateLength={30}
                      renderEventName={renderEventName}
                      includeSearchQueryParam
                    />
                    <EventRemove>
                      <DeleteIcon onClick={() => removeEvent(event.id)} />
                    </EventRemove>
                  </EventSelectorContainer>
                </SelectedEvent>
              ))}
            <Spacer mb={3} />
          </React.Fragment>
        ))}
    </>
  );
};
