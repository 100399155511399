import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { get } from 'app/shared/utils/get';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { getTitlesSubmitVariable } from 'app/admin/utils/titleByLocale';
import { CreateCountry } from 'app/admin/graphql/countries/mutationHooks';
import { DetailsFormMainContainer } from 'app/admin/components/atoms/DetailContent';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

import CountryBasicInfoForm from './CountryBasicInfoForm';
import CountryBasicInfoFormSchema from './CountryBasicInfoFormSchema';

interface Props {
  contentProps?: any;
  setFormSubmitAction?: (func: Function) => void;
  setDisplayConfirmation?: (set: boolean) => void;
  setIsSubmitting?: (set: boolean) => void;
  navigateTo: (routeData: object) => void;
}

const CountryCreate: React.FC<Props> = ({
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  navigateTo,
}) => {
  const intl = useIntl();

  const [validationErrors, setValidationErrors] = useState<object>({});

  const formInitialValues = {
    isNewCountry: true,
    titleEn: '',
    titleEs: '',
    titleIt: '',
    continentId: null,
    countryCode: '',
    aliases: '',
    currency: '',
    currencySymbol: '',
    exchangeRateWithUsd: '',
  };

  const createCountryAction = CreateCountry();

  const handleCreateCountry = useSubmitAction({
    submitAction: createCountryAction,
    submitVariables: (values: any) => ({
      titles: getTitlesSubmitVariable(values),
      continentId: values.continentId,
      countryCode: values.countryCode,
      aliases: values.aliases,
      currency: values.currency,
      currencySymbol: values.currencySymbol,
      exchangeRateWithUsd: values.exchangeRateWithUsd
        ? parseFloat(values.exchangeRateWithUsd)
        : null,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.countryCreate.successMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.countryCreate.failureMessage',
    }),
    onSuccess: (response: any) => {
      navigateTo({
        routeName: 'country-details',
        routeProps: {
          id: get(response.data, 'createCountry.country.id', undefined),
        },
      });
    },
    onValidationError: validationErrors => {
      setValidationErrors(validationErrors);
    },
    validationErrorKeysForDisplay: { country_code: 'country code' },
  });

  return (
    <ModalContentContainer>
      <DetailsFormMainContainer>
        <DetailsHeader
          title={intl.formatMessage({
            id: 'admin.countryCreate.title',
          })}
          isNewForm={true}
        />
        <GenericForm
          formInitialValues={formInitialValues}
          renderFormComponent={(renderProps: any) => (
            <CountryBasicInfoForm
              formikProps={renderProps.formikProps}
              validationErrors={validationErrors}
              isNewCountry={true}
            />
          )}
          onSubmit={handleCreateCountry}
          formSchema={CountryBasicInfoFormSchema(intl)}
          setFormSubmitAction={setFormSubmitAction}
          setDisplayConfirmation={setDisplayConfirmation}
          setIsSubmitting={setIsSubmitting}
          dataQaId="country-create-form"
        />
      </DetailsFormMainContainer>
    </ModalContentContainer>
  );
};

export default CountryCreate;
