import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { PrimaryButton } from 'app/shared/components/atoms/Button';
import {
  CloseIcon,
  SearchPlainIcon,
} from 'app/shared/components/atoms/IconLibrary';
import { Spinner } from 'app/shared/components/atoms/SpinnerManualCSS';
import { Textfield } from 'app/shared/components/atoms/Textfield';

interface Props {
  placeholder: string;
  onSearch: (search: string) => void;
  value?: string;
  buttonText?: string;
  dataQaidPrefix?: string;
  onClearClick?: () => void;
  searchLoading?: boolean;
  showSearchButton?: boolean;
  inputPadding?: string; // TODO: Remove snowflake padding during brand refresh
}

const ButtonSpacer = styled.div`
  ${({ theme }) => css`
    display: inline;
    margin-left: ${theme.ruler[2]}px;
    margin-right: ${theme.ruler[2]}px;
  `}
`;

const ButtonsContainer = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.ruler[6]}px;
    display: inline-block;
  `}
`;

const SearchControls = styled.div`
  padding-top: 10px;
  display: flex;
  width: 100%;
`;

const SearchIconWrapper = styled.div`
  ${({ theme }) => css`
    display: inline;
    margin: 14px 18px 10px 18px;
    position: absolute;
    i::before {
      color: ${theme.colors.backToBlack};
    }
  `}
`;

const RightIcon = styled.div`
  position: relative;
  margin: 0;
  top: 0;
  right: 0;
  height: 0;
  width: 0;
`;

const ClearIconButton = styled.button`
  ${({ theme }) => css`
    position: absolute;
    top: -2px;
    right: ${theme.ruler[1]}px;
    padding: ${theme.ruler[3]}px;
    display: flex;
    align-items: center;
    i::before {
      color: ${theme.colors.backToBlack};
    }

    // Eliminate default button styles
    background: none;
    color: inherit;
    border: none;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    margin: 0;
  `}
`;

const SpinnerWrapper = styled.div`
  ${({ theme }) => css`
    position: absolute;
    right: ${theme.ruler[1]}px;
    padding: ${theme.ruler[3]}px;
  `}
`;

const SearchField = styled(Textfield)<{ inputPadding: string }>`
  ${({ theme, inputPadding }) => css`
    width: 100%;
    margin-bottom: ${theme.ruler[2]}px;
    padding-left: ${theme.ruler[12]}px;
    padding-right: ${theme.ruler[10]}px;
    padding: ${inputPadding};
    flex-grow: 1;
    display: inline-flex;
    border: 1px solid ${theme.colors.backToBlack};
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    &::-webkit-search-cancel-button {
      display: none;
    }
  `}
`;

const SearchInput: React.FC<Props> = ({
  placeholder,
  onSearch,
  value,
  onClearClick = () => {},
  searchLoading = false,
  buttonText = 'Search',
  showSearchButton = true,
  inputPadding = '0px 40px 0px 48px',
  dataQaidPrefix,
}) => {
  const [searchString, setSearchString] = useState(value || '');

  useEffect(() => {
    if (value === undefined) {
      return setSearchString('');
    }
    setSearchString(value);
  }, [value]);

  function handleSearch() {
    if (searchString.trim()) {
      onSearch(searchString.trim());
    }
  }

  function handleSearchChange(changeObject: any) {
    const value = changeObject.target.value;
    setSearchString(value);
  }

  function handleSearchKeyUp(changeObject: any) {
    if (changeObject.keyCode === 13) {
      handleSearch();
    }
  }

  function handleClearClick() {
    setSearchString('');
    onClearClick();
  }

  return (
    <SearchControls data-qaid={`${dataQaidPrefix}-input`}>
      <SearchIconWrapper>
        <SearchPlainIcon iconSize={16} />
      </SearchIconWrapper>
      <SearchField
        data-qaid={`${dataQaidPrefix}-field`}
        placeholder={placeholder}
        value={searchString || ''}
        onChange={handleSearchChange}
        onKeyUp={handleSearchKeyUp}
        type="search"
        inputPadding={inputPadding}
      />
      <RightIcon>
        {searchLoading && (
          <SpinnerWrapper data-qaid={`${dataQaidPrefix}-input-spinner`}>
            <Spinner size="16px" />
          </SpinnerWrapper>
        )}
        {!searchLoading && searchString && (
          <ClearIconButton
            onClick={handleClearClick}
            data-qaid={`${dataQaidPrefix}-input-clear-button`}
          >
            <CloseIcon iconSize={10} iconColor="black" />
          </ClearIconButton>
        )}
      </RightIcon>
      {showSearchButton && (
        <ButtonsContainer>
          <ButtonSpacer>
            <PrimaryButton
              onClick={handleSearch}
              data-qaid={`${dataQaidPrefix}-button`}
            >
              {buttonText}
            </PrimaryButton>
          </ButtonSpacer>
        </ButtonsContainer>
      )}
    </SearchControls>
  );
};

export default SearchInput;
