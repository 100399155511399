import gql from 'graphql-tag';

export const GET_ARTIST_SETUPS = gql`
  query GetArtistSetups(
    $setuppableId: Int
    $setuppableType: String
    $page: Int
    $perPage: Int
  ) {
    artistSetups(
      setuppableId: $setuppableId
      setuppableType: $setuppableType
      page: $page
      perPage: $perPage
    ) {
      artistSetups {
        id
        setupName
        numPerformers
        isPrimarySetup
        audioSupport
        additionalSupportNotes
        instrumentNotes
        setuppableId
        setuppableType
        lastUpdatedAt
        createdAt
        derivedFrom
        applicablePerformances {
          id
          artistSetup {
            id
            setupName
            numPerformers
            isPrimarySetup
            audioSupport
            additionalSupportNotes
            instrumentNotes
            setuppableId
            setuppableType
            lastUpdatedAt
            createdAt
          }
          event {
            startsAt
            localStartsAt
            city {
              title
            }
            venue {
              venueName
            }
          }
        }
      }
      metadata {
        totalRecords
        currentPage
      }
    }
  }
`;
