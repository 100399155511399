import styled, { css } from 'styled-components';

interface ItemContainerProps {
  align?: 'left' | 'right';
}
export default styled.div<ItemContainerProps>`
  ${({ theme, align = 'left' }) => css`
    display: none;

    ${theme.media.lg`
      ${align === 'left' &&
        css`
          margin-left: 24px;
        `}
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 8px;
      flex: 1;

      ${align === 'right' &&
        css`
          flex: auto;
          justify-content: flex-end;
        `}
    `}
  `}
`;
