import * as Yup from 'yup';

const promoCodeBasicInfoFormSchema = (intl: any) =>
  Yup.object().shape({
    numPromoCodes: Yup.string().when('isNewPromoCode', {
      is: true,
      then: Yup.string()
        .required(
          intl.formatMessage({
            id: 'form.requiredField',
          })
        )
        .matches(/^[1-9]\d*$/, {
          message: intl.formatMessage({
            id: 'admin.promoCodeBasicInfo.form.numPromoCodesError',
          }),
        }),
    }),
    curatorGroup: Yup.object().when('hasCuratorGroupField', {
      is: true,
      then: Yup.object().required(
        intl.formatMessage({
          id: 'form.requiredField',
        })
      ),
    }),
    type: Yup.string()
      .typeError(
        intl.formatMessage({
          id: 'form.requiredField',
        })
      )
      .required(
        intl.formatMessage({
          id: 'form.requiredField',
        })
      ),
    codePrefix: Yup.string().when('isNewPromoCode', {
      is: true,
      then: Yup.string()
        .required(
          intl.formatMessage({
            id: 'form.requiredField',
          })
        )
        .min(
          3,
          intl.formatMessage({
            id: 'admin.promoCodeBasicInfo.form.codeLengthError',
          })
        )
        .matches(/^[0-9A-Za-z]{2}[0-9A-Za-z-_]*[0-9A-Za-z]$/, {
          message: intl.formatMessage({
            id: 'admin.promoCodeBasicInfo.form.codeError',
          }),
        }),
    }),
    percentageDiscount: Yup.string().when('type', {
      is: value =>
        value == 'PercentageDiscount' || value == 'PercentageDiscountSingleUse',
      then: Yup.string()
        .required(
          intl.formatMessage({
            id: 'form.requiredField',
          })
        )
        .matches(/^[1-9]\d?\d?$/, {
          message: intl.formatMessage({
            id: 'admin.promoCodeBasicInfo.form.percentageDiscountError',
          }),
        }),
    }),
    customPricingEntries: Yup.object().when('type', {
      is: value =>
        value == 'CustomPricing' || value == 'CustomPricingSingleUse',
      then: Yup.object()
        .required(
          intl.formatMessage({
            id: 'form.requiredField',
          })
        )
        .test(
          'custom-pricing-required',
          intl.formatMessage({
            id: 'admin.promoCodeBasicInfo.form.customPricingError',
          }),
          function() {
            const customPricingEntries = this.parent.customPricingEntries;
            return Object.keys(customPricingEntries).length == 10;
          }
        ),
    }),
    validFrom: Yup.date()
      .nullable()
      .required(
        intl.formatMessage({
          id: 'form.requiredField',
        })
      ),
    expiresAt: Yup.date()
      .nullable()
      .required(
        intl.formatMessage({
          id: 'form.requiredField',
        })
      ),
    maxUses: Yup.string()
      .nullable()
      .when('hasUnlimitedUses', {
        is: false,
        then: Yup.string()
          .nullable()
          .required(
            intl.formatMessage({
              id: 'form.requiredField',
            })
          )
          .matches(/^[1-9]\d*$/, {
            message: intl.formatMessage({
              id: 'admin.promoCodeBasicInfo.form.numUsesEnterNumError',
            }),
          }),
      }),
    maxUsesPerUser: Yup.string()
      .nullable()
      .when('hasUnlimitedUsesPerUser', {
        is: false,
        then: Yup.string()
          .nullable()
          .required(
            intl.formatMessage({
              id: 'form.requiredField',
            })
          )
          .matches(/^[1-9]\d*$/, {
            message: intl.formatMessage({
              id: 'admin.promoCodeBasicInfo.form.numUsesEnterNumError',
            }),
          }),
      }),
    ruleDayOfWeek: Yup.string().when('ruleTypes', {
      is: value => value.includes('for_day_of_week'),
      then: Yup.string().required(
        intl.formatMessage({
          id:
            'admin.promoCodeBasicInfo.form.customRulesDayOfWeekValidationError',
        })
      ),
    }),
    ruleDaysOfWeek: Yup.array()
      .of(Yup.string())
      .when('ruleTypes', {
        is: value => value.includes('for_days_of_week'),
        then: Yup.array()
          .of(Yup.string())
          .required(
            intl.formatMessage({
              id:
                'admin.promoCodeBasicInfo.form.customRulesDaysOfWeekValidationError',
            })
          ),
      }),
    ruleCity: Yup.object()
      .nullable()
      .when('ruleTypes', {
        is: value => value.includes('for_city'),
        then: Yup.object()
          .nullable()
          .required(
            intl.formatMessage({
              id:
                'admin.promoCodeBasicInfo.form.customRulesCityValidationError',
            })
          ),
      }),
    ruleCities: Yup.array()
      .of(Yup.object())
      .when('ruleTypes', {
        is: value => value.includes('for_multi_city'),
        then: Yup.array()
          .of(Yup.object())
          .required(
            intl.formatMessage({
              id:
                'admin.promoCodeBasicInfo.form.customRulesCitiesValidationError',
            })
          ),
      }),
    ruleCountry: Yup.object()
      .nullable()
      .when('ruleTypes', {
        is: value => value.includes('for_country'),
        then: Yup.object()
          .nullable()
          .required(
            intl.formatMessage({
              id:
                'admin.promoCodeBasicInfo.form.customRulesCountryValidationError',
            })
          ),
      }),
    ruleEvent: Yup.object()
      .nullable()
      .when('ruleTypes', {
        is: value => value.includes('for_event'),
        then: Yup.object()
          .nullable()
          .required(
            intl.formatMessage({
              id:
                'admin.promoCodeBasicInfo.form.customRulesEventValidationError',
            })
          ),
      }),
    ruleEventType: Yup.string().when('ruleTypes', {
      is: value => value.includes('for_event_type'),
      then: Yup.string().required(
        intl.formatMessage({
          id:
            'admin.promoCodeBasicInfo.form.customRulesEventTypeValidationError',
        })
      ),
    }),
    rulePurchaserType: Yup.string()
      .nullable()
      .when('ruleTypes', {
        is: value => value.includes('for_purchaser_type'),
        then: Yup.string()
          .nullable()
          .required(
            intl.formatMessage({
              id:
                'admin.promoCodeBasicInfo.form.customRulesPurchaserTypeValidationError',
            })
          ),
      }),
  });

export default promoCodeBasicInfoFormSchema;
