import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { EventAttendee } from 'app/typings/eventAttendees';
import { Event } from 'app/typings/events';
import { dateFormatter } from 'app/shared/utils/datetime';
import { eventAttendeeOccasions } from 'app/shared/utils/events';
import { get } from 'app/shared/utils/get';
import { GetEventAttendeeForAdmin } from 'app/admin/graphql/eventAttendees/queryHooks';
import {
  LoadingError,
  LoadingIndicator,
} from 'app/admin/components/atoms/GuestlistLoading';
import { GuestlistAttendeeDetailsHeader } from 'app/admin/components/molecules/GuestlistAttendeeDetailsHeader';
import { KeyAboveValueInfoList } from 'app/admin/components/molecules/KeyAboveValueInfoList';
import { KeyValueInfoList } from 'app/admin/components/molecules/KeyValueInfoList';

interface Props {
  eventAttendeeId?: number;
  event?: Event;
}

interface AttendeeDetailsProps {
  eventAttendee: EventAttendee;
  event?: Event;
  intl?: any;
}

const DetailsWrapper = styled.div`
  padding: 8px 16px 24px 16px;
  margin-bottom: 10px;
`;

const getOccasionText = (eventAttendee: EventAttendee) => {
  if (!eventAttendee.occasion) {
    return null;
  }

  let occasionText = eventAttendeeOccasions[eventAttendee.occasion];
  if (eventAttendee.occasionDetails) {
    occasionText = `${occasionText} (${eventAttendee?.occasionDetails})`;
  }

  return occasionText;
};

const AttendeeDetails: React.FC<AttendeeDetailsProps> = ({
  eventAttendee,
  event,
  intl,
}) => {
  const keysAndValuesList = [
    {
      key: intl.formatMessage({
        id:
          event && event.attendeeFlow == 'buy'
            ? 'admin.guestlist.attendeeDetails.datePurchased'
            : 'admin.guestlist.attendeeDetails.dateApplied',
      }),
      value: dateFormatter(
        eventAttendee.createdAt || '',
        'shortMonthDateAndYear'
      ),
    },
    {
      key: intl.formatMessage({
        id: 'admin.guestlist.attendeeDetails.lastConcert',
      }),
      value: eventAttendee.userLastEventAt
        ? dateFormatter(eventAttendee.userLastEventAt, 'shortMonthDateAndYear')
        : intl.formatMessage({
            id: 'none',
          }),
    },
    {
      key: intl.formatMessage({
        id: 'admin.guestlist.attendeeDetails.concertsAttended',
      }),
      value: eventAttendee.userPastEventsCount
        ? eventAttendee.userPastEventsCount.toString()
        : '0',
    },
  ];

  const occasionText = getOccasionText(eventAttendee);

  const keysAboveValuesList = occasionText
    ? [
        {
          key: intl.formatMessage({
            id: 'admin.guestlist.attendeeDetails.occasion',
          }),
          value: occasionText,
          wrapValue: true,
        },
      ]
    : null;

  return (
    <DetailsWrapper>
      <KeyValueInfoList keysAndValues={keysAndValuesList} />
      {keysAboveValuesList && (
        <KeyAboveValueInfoList
          keysAndValues={keysAboveValuesList}
          valueTruncateCharLength={100}
        />
      )}
    </DetailsWrapper>
  );
};

const GuestlistAttendeeDetails: React.FC<Props> = ({
  eventAttendeeId,
  event,
}) => {
  const intl = useIntl();

  const { data, loading, error } = GetEventAttendeeForAdmin({
    id: eventAttendeeId,
  });

  if (loading) {
    return <LoadingIndicator />;
  }

  if ((!loading && !data) || error) {
    return <LoadingError />;
  }

  const eventAttendee = get(data, 'eventAttendee', undefined);

  return (
    <div data-qaid="guestlist-attendee-details">
      <GuestlistAttendeeDetailsHeader
        eventAttendee={eventAttendee}
        intl={intl}
      />
      <AttendeeDetails
        eventAttendee={eventAttendee}
        event={event}
        intl={intl}
      />
    </div>
  );
};

export default GuestlistAttendeeDetails;
