import gql from 'graphql-tag';

export const CREATE_ARTIST_SETUP = gql`
  mutation createArtistSetup(
    $setupName: String!
    $numPerformers: Int!
    $audioSupport: String!
    $isPrimarySetup: Boolean
    $additionalSupportNotes: String
    $instrumentNotes: String
    $artistId: Int
    $performanceId: Int
    $derivedFrom: Int
  ) {
    createArtistSetup(
      setupName: $setupName
      numPerformers: $numPerformers
      audioSupport: $audioSupport
      isPrimarySetup: $isPrimarySetup
      additionalSupportNotes: $additionalSupportNotes
      instrumentNotes: $instrumentNotes
      artistId: $artistId
      performanceId: $performanceId
      derivedFrom: $derivedFrom
    ) {
      success
      artistSetup {
        id
        setupName
        numPerformers
        isPrimarySetup
        audioSupport
        additionalSupportNotes
        instrumentNotes
        setuppableId
        setuppableType
        lastUpdatedAt
        createdAt
        derivedFrom
      }
    }
  }
`;

export const DELETE_ARTIST_SETUP = gql`
  mutation deleteArtistSetup($artistSetupId: Int!) {
    deleteArtistSetup(artistSetupId: $artistSetupId) {
      success
    }
  }
`;

export const UPDATE_ARTIST_SETUP = gql`
  mutation updateArtistSetup(
    $artistSetupId: Int!
    $setupName: String!
    $numPerformers: Int!
    $audioSupport: String!
    $isPrimarySetup: Boolean
    $additionalSupportNotes: String
    $instrumentNotes: String
    $applyMethod: String
    $selectedValues: [String!]
  ) {
    updateArtistSetup(
      artistSetupId: $artistSetupId
      setupName: $setupName
      numPerformers: $numPerformers
      audioSupport: $audioSupport
      isPrimarySetup: $isPrimarySetup
      additionalSupportNotes: $additionalSupportNotes
      instrumentNotes: $instrumentNotes
      applyMethod: $applyMethod
      selectedValues: $selectedValues
    ) {
      success
      artistSetup {
        id
        setupName
        numPerformers
        isPrimarySetup
        audioSupport
        additionalSupportNotes
        instrumentNotes
        setuppableId
        setuppableType
        lastUpdatedAt
        createdAt
        derivedFrom
      }
    }
  }
`;
