import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { Email } from 'app/typings/emails';
import { Event } from 'app/typings/events';
import usePermission from 'app/shared/utils/usePermission';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import TruncatedByCharText from 'app/shared/components/atoms/TruncatedByCharText';
import { Body2 } from 'app/shared/components/atoms/TypographyManualCSS';
import HelpIconAndFlyover from 'app/shared/components/molecules/HelpIconAndFlyover';
import { CardControls } from 'app/admin/components/organisms/CardControls';
import EventSendEmail from 'app/admin/components/organisms/EventSendEmail';
import { ReactComponent as EmailActionUnread } from 'icons/streamline-regular/emails/email-actions/email-action-unread.svg';
import { ReactComponent as ListBullets } from 'icons/streamline-regular/interface-essential/lists/list-bullets.svg';
import { ReactComponent as View1 } from 'icons/streamline-regular/interface-essential/view/view-1.svg';

interface EmailCardProps {
  email: Email;
  event: Event;
  index?: number;
  onPreviewEventEmail: (emailData: any) => void;
}

const CardWrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.silverSprings};
    width: 100%;
    max-width: 240px;
    height: 230px !important;
    border-radius: ${theme.ruler[3]}px;
    padding: ${theme.ruler[4]}px;
    margin-right: 22px;
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
  `}
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 35px;
  padding-top: 5px;
  padding-bottom: 20px;
`;

const EmailNameContainer = styled.div``;

const InfoIconEmailNameContainer = styled.div`
  padding-left: 10px;
  padding-bottom: 2px;
`;

const InfoIconEmailCountsContainer = styled.div`
  padding-left: 10px;
  padding-bottom: 8px;
`;

const EmailName = styled(Body2)<{ greyedOut: boolean }>`
  ${props => `
    font-weight: 700;
    letter-spacing: 0.5px;
    ${props.greyedOut && `color: ${props.theme.colors.lightBlueSmoke};`}
    white-space: nowrap;
  `}
`;

const Description = styled.div<{ greyedOut: boolean }>`
  ${props => `
    font-size: 14px;
    line-height: 18px;
    ${props.greyedOut && `color: ${props.theme.colors.lightBlueSmoke};`}
    white-space: normal;
    height: 180px;
    padding-bottom: 20px;
  `}
`;

const NumEmailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 28px;
  padding-top: 5px;
  padding-bottom: 20px;
`;

const NumEmails = styled.div<{ greyedOut: boolean }>`
  ${props => `
    font-size: 14px;
    line-height: 18px;
    ${props.greyedOut && `color: ${props.theme.colors.lightBlueSmoke};`}
    white-space: normal;
    height: 25px;
    padding-bottom: 20px;
  `}
`;

const entitySentEmailTexts = {
  Artist: 'admin.eventPlanner.emails.emailedArtist',
  Crew: 'admin.eventPlanner.emails.emailedCrew',
  Guest: 'admin.eventPlanner.emails.emailedGuest',
  Venue: 'admin.eventPlanner.emails.emailedVenue',
};

const entityEmailCountsHelpTexts1 = {
  Artist: 'admin.eventPlanner.emails.emailCountsHelpText1Artist',
  Crew: 'admin.eventPlanner.emails.emailCountsHelpText1Crew',
  Guest: 'admin.eventPlanner.emails.emailCountsHelpText1Guest',
  GuestEmailsSentToTickets:
    'admin.eventPlanner.emails.emailTicketsCountsHelpText1Guest',
  Venue: 'admin.eventPlanner.emails.emailCountsHelpText1Venue',
};

const entityEmailCountsHelpTexts2 = {
  Artist: 'admin.eventPlanner.emails.emailCountsHelpText2Artist',
  Guest: 'admin.eventPlanner.emails.emailCountsHelpText2Guest',
  Venue: 'admin.eventPlanner.emails.emailCountsHelpText2Venue',
};

const guestEmailsSentToTicketsHelperText = [
  'GuestRevealAddress',
  'GuestPostEvent',
  'GuestDoorsOpen',
];

const EmailCard: React.FC<EmailCardProps> = ({
  email,
  event,
  index,
  onPreviewEventEmail,
}) => {
  const intl = useIntl();

  const hasAccessEmailMessagesPermission = usePermission('emailMessage.access');

  const EventSendEmailContent = (setFlyoverClosed: Function) => (
    <EventSendEmail
      email={email}
      event={event}
      onSuccess={() => {
        setFlyoverClosed(true);
      }}
    />
  );

  const controlsInfoList = [
    {
      action: email.isPreviewable
        ? () => {
            onPreviewEventEmail({
              emailKey: email.id,
              emailName: email.emailName,
            });
          }
        : undefined,
      icon: View1,
      tipText: intl.formatMessage({
        id: email.isPreviewable
          ? 'admin.eventPlanner.emails.emailCardPreview'
          : 'admin.eventPlanner.emails.emailCardPreviewNotAvailable',
      }),
    },
    {
      infoBoxContent: email.isSendable ? EventSendEmailContent : undefined,
      icon: EmailActionUnread,
      tipText: intl.formatMessage({
        id: email.isSendable
          ? 'admin.eventPlanner.emails.emailCardSend'
          : 'admin.eventPlanner.emails.emailCardSendNotAvailable',
      }),
    },
    {
      href: hasAccessEmailMessagesPermission
        ? `/admin/emails?email_key=${email.id}&event_id=${event.id}`
        : undefined,
      icon: ListBullets,
      tipText: intl.formatMessage({
        id: 'admin.eventPlanner.emails.emailCardSentEmails',
      }),
    },
  ];

  return (
    <CardWrapper data-qaid="admin-email-card">
      <HeaderContainer>
        <EmailNameContainer>
          <EmailName greyedOut={email.isDisabled || false}>
            <TruncatedByCharText text={email.emailName} truncateLength={21} />
          </EmailName>
        </EmailNameContainer>
        <InfoIconEmailNameContainer>
          <HelpIconAndFlyover
            title={email.emailName}
            body={email.longDescription || ''}
          />
        </InfoIconEmailNameContainer>
      </HeaderContainer>
      <Description greyedOut={email.isDisabled || false}>
        {email.isDisabled
          ? intl.formatMessage({
              id: 'admin.eventPlanner.emails.deactivated',
            })
          : email.description}
      </Description>
      <NumEmailsContainer>
        <NumEmails greyedOut={email.isDisabled || false}>
          {email.isEmailCountsEnabled &&
            intl.formatMessage(
              {
                id: entitySentEmailTexts[email.emailGroup || ''],
              },
              {
                numSent: email.numSent,
                numTotal: email.numTotal,
              }
            )}
        </NumEmails>
        <InfoIconEmailCountsContainer>
          {email.isEmailCountsEnabled && (
            <HelpIconAndFlyover
              body={
                guestEmailsSentToTicketsHelperText.includes(email.id)
                  ? intl.formatMessage({
                      id:
                        entityEmailCountsHelpTexts1[
                          email.emailGroup + 'EmailsSentToTickets' || ''
                        ],
                    })
                  : intl.formatMessage({
                      id: entityEmailCountsHelpTexts1[email.emailGroup || ''],
                    })
              }
              body2={
                email.emailGroup == 'Crew'
                  ? undefined
                  : guestEmailsSentToTicketsHelperText.includes(email.id)
                  ? undefined
                  : intl.formatMessage({
                      id: entityEmailCountsHelpTexts2[email.emailGroup || ''],
                    })
              }
            />
          )}
        </InfoIconEmailCountsContainer>
      </NumEmailsContainer>
      <DottedLine />
      <CardControls
        controlsInfoList={controlsInfoList}
        dataQaidPrefix="admin-email-card"
        cardIndex={index}
      />
    </CardWrapper>
  );
};

export default EmailCard;
