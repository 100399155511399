import React from 'react';
import styled, { css } from 'styled-components';

import { ChildMenu } from 'app/typings/navbar';
import { adminManualCSSTheme } from 'app/shared/theme';
import { FlexDivWithSpacing } from 'app/shared/components/atoms/FlexDivWithSpacing';
import { Body2 } from 'app/shared/components/atoms/TypographyManualCSS';

const StyledLink = styled(FlexDivWithSpacing)<{
  isSelected?: boolean;
  isAdmin?: boolean;
  withoutSelectedStyle?: boolean;
}>`
  ${({ theme, isSelected, isAdmin, withoutSelectedStyle }) => css`
    display: block;
    p {
      color: ${theme.colors.whiteDenim};
    }

    ${isSelected &&
      css`
        background-color: ${isAdmin
          ? adminManualCSSTheme.colors.green800
          : theme.colors.primary};
        p {
          color: ${isAdmin
            ? theme.colors.whiteDenim
            : theme.colors.backToBlack};
        }
      `}

    ${!withoutSelectedStyle &&
      css`
        &:focus,
        &:hover,
        &:active {
          background-color: ${isAdmin
            ? adminManualCSSTheme.colors.green800
            : theme.colors.primary};
          p {
            color: ${isAdmin
              ? theme.colors.whiteDenim
              : theme.colors.backToBlack};
          }
        }
      `}

      ${withoutSelectedStyle &&
        css`
          &:active {
            background-color: ${isAdmin
              ? adminManualCSSTheme.colors.green800
              : theme.colors.primary};
            p {
              color: ${isAdmin
                ? theme.colors.whiteDenim
                : theme.colors.backToBlack};
            }
          }
        `}
  `}
`;

interface ChildMenuItemMobileProps {
  childData: ChildMenu;
  qaId: string;
  isSelected: boolean;
  isAdmin?: boolean;
  parentIndex?: number;
  index: number;
  openInNewTab?: boolean;
}

export const ChildMenuItemMobile: React.FC<ChildMenuItemMobileProps> = ({
  childData,
  qaId,
  isSelected,
  isAdmin,
  index,
  parentIndex,
  openInNewTab = false,
}) => {
  const target = openInNewTab ? '_blank' : '_self';
  const rel = openInNewTab ? 'noopener' : undefined;

  return (
    <StyledLink
      as="a"
      href={childData.link}
      key={`${qaId}-${childData.key}-link`}
      isSelected={isSelected}
      isAdmin={isAdmin}
      withoutSelectedStyle={childData.withoutSelectedStyle}
      pt={3}
      pr={6}
      pb={3}
      pl={6}
      target={target}
      rel={rel}
      onClick={childData.onClick}
    >
      <Body2
        key={`${qaId}-${childData.key}`}
        data-qaid={`${qaId}-child-menu-item-${parentIndex}-${index}`}
      >
        {childData.title}
      </Body2>
    </StyledLink>
  );
};
