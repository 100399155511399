import React from 'react';
import styled from 'styled-components';

const CityTitlesContainer = styled.div`
  margin-top: 0x;
  margin-bottom: 10x;
  font-size: 24px;
  font-weight: 600;
`;

interface CityTitlesProps {
  cityTitles: string[] | undefined;
}

export const CityTitles: React.FC<CityTitlesProps> = ({ cityTitles }) => {
  return cityTitles && cityTitles.length > 0 ? (
    <CityTitlesContainer>{cityTitles.sort().join(' and ')}</CityTitlesContainer>
  ) : null;
};
