import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { truncateByWord } from 'app/shared/utils/string';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import { ToolTip } from 'app/shared/components/atoms/ToolTip';
import { Body2, H3, H6 } from 'app/shared/components/atoms/TypographyManualCSS';

interface Props {
  markersInCluster: any;
  setSelectedMarkerId: (id: number) => void;
  onMarkerClick: (marker: any) => void;
}

const Container = styled.div`
  max-width: 240px;
  padding: 40px 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const HeaderContainer = styled.div`
  padding: 10px;
`;

const Title = styled(H3)`
  margin-bottom: 5px;
`;

const Description = styled(Body2)`
  font-size: 12px;
  margin-bottom: 12px;
`;

const VenueListContainer = styled.div`
  overflow-y: auto;
  padding: 0px 10px;
  flex: 1;
`;

const VenueInfoContainer = styled.div`
  ${({ theme }) => css`
    padding: 15px 0px;

    &:hover {
      cursor: pointer;
      & > p {
        text-decoration: underline;
        text-decoration-color: ${theme.colors.green600};
      }

      & > h6 {
        text-decoration: underline;
      }
    }
  `}
`;

const VenueNameText = styled(H6)`
  margin-bottom: 5px;
`;

const VenueAddressText = styled(Body2)`
  ${({ theme }) => css`
    font-size: 12px;
    color: ${theme.colors.green600};
  `}
`;

const VenueNameToolTip = styled(ToolTip)`
  left: 0%;
  margin-left: 0px;
  max-width: 400px;
  padding-left: 10px;
  padding-right: 10px;
  z-index: 33;
`;

const VenueNameToolTipContainer = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &:hover ${VenueNameToolTip} {
    visibility: visible;
    opacity: 1;
  }
`;

const VenueClusterInfoWindow: React.FC<Props> = ({
  markersInCluster,
  setSelectedMarkerId,
  onMarkerClick,
}) => {
  const intl = useIntl();

  const VENUE_NAME_TRUNCATE_LENGTH = 37;

  const renderVenueName = (venueName: string) => {
    if (venueName.length <= VENUE_NAME_TRUNCATE_LENGTH) {
      return venueName;
    }

    return (
      <VenueNameToolTipContainer>
        {truncateByWord(venueName, VENUE_NAME_TRUNCATE_LENGTH, '…')}
        <VenueNameToolTip>{venueName}</VenueNameToolTip>
      </VenueNameToolTipContainer>
    );
  };

  return (
    <Container>
      <HeaderContainer>
        <Title>
          {intl.formatMessage({
            id: 'admin.venueDirectory.venueCluster',
          })}
        </Title>
        <Description>
          {intl.formatMessage(
            {
              id: 'admin.venueDirectory.venueClusterDescription',
            },
            {
              numberOfVenues: markersInCluster.length,
            }
          )}
        </Description>
        <DottedLine />
      </HeaderContainer>
      <VenueListContainer>
        {markersInCluster.map((marker: any) => (
          <VenueInfoContainer
            onClick={() => {
              setSelectedMarkerId(marker.id);
              onMarkerClick && onMarkerClick(marker);
            }}
            key={marker.id}
          >
            <VenueNameText>
              {marker.venueName ? renderVenueName(marker.venueName) : 'None'}
            </VenueNameText>
            <VenueAddressText>{marker.address}</VenueAddressText>
          </VenueInfoContainer>
        ))}
      </VenueListContainer>
    </Container>
  );
};

export default VenueClusterInfoWindow;
