import * as Yup from 'yup';

import {
  maxTicketsPerAttendeeMaxValue,
  maxTicketsPerAttendeeMinValue,
} from 'app/admin/utils/eventTicketsConfig';

const notAllTieredPricesEntered = (formValues: any) => {
  const pricingType = formValues.pricingType;
  const priceSun = formValues.priceSun;
  const priceMon = formValues.priceMon;
  const priceTue = formValues.priceTue;
  const priceWed = formValues.priceWed;
  const priceThu = formValues.priceThu;
  const priceFri = formValues.priceFri;
  const priceSat = formValues.priceSat;
  return (
    pricingType == 'tiered' &&
    (!priceSun ||
      !priceMon ||
      !priceTue ||
      !priceWed ||
      !priceThu ||
      !priceFri ||
      !priceSat)
  );
};

const citySettingsEditFormSchema = (intl: any) =>
  Yup.object().shape({
    defaultEventPrice: Yup.string()
      .matches(/^\d+$/, {
        message: intl.formatMessage({
          id: 'admin.citySettings.form.numberPriceError',
        }),
      })
      .test(
        'no-default-event-price-entered',
        intl.formatMessage({
          id: 'admin.citySettings.form.noDefaultEventPriceEnteredError',
        }),
        function() {
          return !(
            this.parent.pricingType == 'fixed' && !this.parent.defaultEventPrice
          );
        }
      ),
    priceSun: Yup.string()
      .nullable()
      .matches(/^\d+$/, {
        message: intl.formatMessage({
          id: 'admin.citySettings.form.numberPriceError',
        }),
      })
      .test(
        'not-all-tiered-prices-entered',
        intl.formatMessage({
          id: 'admin.citySettings.form.notAllTieredPricesEnteredError',
        }),
        function() {
          return !(
            notAllTieredPricesEntered(this.parent) && !this.parent.priceSun
          );
        }
      ),
    priceMon: Yup.string()
      .nullable()
      .matches(/^\d+$/, {
        message: intl.formatMessage({
          id: 'admin.citySettings.form.numberPriceError',
        }),
      })
      .test(
        'not-all-tiered-prices-entered',
        intl.formatMessage({
          id: 'admin.citySettings.form.notAllTieredPricesEnteredError',
        }),
        function() {
          return !(
            notAllTieredPricesEntered(this.parent) && !this.parent.priceMon
          );
        }
      ),
    priceTue: Yup.string()
      .nullable()
      .matches(/^\d+$/, {
        message: intl.formatMessage({
          id: 'admin.citySettings.form.numberPriceError',
        }),
      })
      .test(
        'not-all-tiered-prices-entered',
        intl.formatMessage({
          id: 'admin.citySettings.form.notAllTieredPricesEnteredError',
        }),
        function() {
          return !(
            notAllTieredPricesEntered(this.parent) && !this.parent.priceTue
          );
        }
      ),
    priceWed: Yup.string()
      .nullable()
      .matches(/^\d+$/, {
        message: intl.formatMessage({
          id: 'admin.citySettings.form.numberPriceError',
        }),
      })
      .test(
        'not-all-tiered-prices-entered',
        intl.formatMessage({
          id: 'admin.citySettings.form.notAllTieredPricesEnteredError',
        }),
        function() {
          return !(
            notAllTieredPricesEntered(this.parent) && !this.parent.priceWed
          );
        }
      ),
    priceThu: Yup.string()
      .nullable()
      .matches(/^\d+$/, {
        message: intl.formatMessage({
          id: 'admin.citySettings.form.numberPriceError',
        }),
      })
      .test(
        'not-all-tiered-prices-entered',
        intl.formatMessage({
          id: 'admin.citySettings.form.notAllTieredPricesEnteredError',
        }),
        function() {
          return !(
            notAllTieredPricesEntered(this.parent) && !this.parent.priceThu
          );
        }
      ),
    priceFri: Yup.string()
      .nullable()
      .matches(/^\d+$/, {
        message: intl.formatMessage({
          id: 'admin.citySettings.form.numberPriceError',
        }),
      })
      .test(
        'not-all-tiered-prices-entered',
        intl.formatMessage({
          id: 'admin.citySettings.form.notAllTieredPricesEnteredError',
        }),
        function() {
          return !(
            notAllTieredPricesEntered(this.parent) && !this.parent.priceFri
          );
        }
      ),
    priceSat: Yup.string()
      .nullable()
      .matches(/^\d+$/, {
        message: intl.formatMessage({
          id: 'admin.citySettings.form.numberPriceError',
        }),
      })
      .test(
        'not-all-tiered-prices-entered',
        intl.formatMessage({
          id: 'admin.citySettings.form.notAllTieredPricesEnteredError',
        }),
        function() {
          return !(
            notAllTieredPricesEntered(this.parent) && !this.parent.priceSat
          );
        }
      ),
    bookingFee: Yup.string()
      .nullable()
      .trim()
      .matches(/^\d+\.\d\d$/, {
        message: intl.formatMessage({
          id: 'admin.citySettings.form.bookingFeeError',
        }),
      }),
    maxTicketsPerAttendee: Yup.string()
      .required(
        intl.formatMessage({
          id: 'form.requiredField',
        })
      )
      .matches(/^\d+$/, {
        message: intl.formatMessage({
          id: 'form.numberError',
        }),
      })
      .test(
        'isValidNumber',
        intl.formatMessage(
          {
            id: 'admin.citySettings.form.maxTicketsPerAttendeeError',
          },
          {
            min_tickets: maxTicketsPerAttendeeMinValue,
            max_tickets: maxTicketsPerAttendeeMaxValue,
          }
        ),
        function(value) {
          return value
            ? Number(value) >= maxTicketsPerAttendeeMinValue &&
                Number(value) <= maxTicketsPerAttendeeMaxValue
            : true;
        }
      ),
    inviteFirstDayPercentage: Yup.string()
      .nullable()
      .matches(/^\d+$/, {
        message: intl.formatMessage({
          id: 'admin.citySettings.form.numberError',
        }),
      })
      .test(
        'isValidNumber',
        intl.formatMessage({
          id: 'admin.citySettings.form.numberPercentageError',
        }),
        function(value) {
          return value ? Number(value) >= 1 && Number(value) < 101 : true;
        }
      ),
    inviteSubsequentDaysPercentage: Yup.string()
      .nullable()
      .matches(/^\d+$/, {
        message: intl.formatMessage({
          id: 'admin.citySettings.form.numberError',
        }),
      })
      .test(
        'isValidNumber',
        intl.formatMessage({
          id: 'admin.citySettings.form.numberPercentageError',
        }),
        function(value) {
          return value ? Number(value) >= 1 && Number(value) < 101 : true;
        }
      ),
    maximumPromoCodeUses: Yup.string()
      .required(
        intl.formatMessage({
          id: 'form.requiredField',
        })
      )
      .matches(/^\d+$/, {
        message: intl.formatMessage({
          id: 'admin.citySettings.form.numberError',
        }),
      })
      .test(
        'isValidNumber',
        intl.formatMessage({
          id: 'admin.citySettings.form.numPromoCodeUsesError',
        }),
        function(value) {
          return value ? Number(value) >= 0 && Number(value) <= 1000 : true;
        }
      ),
    maximumGuaranteedPromoCodeUses: Yup.string()
      .required(
        intl.formatMessage({
          id: 'form.requiredField',
        })
      )
      .matches(/^\d+$/, {
        message: intl.formatMessage({
          id: 'admin.citySettings.form.numberError',
        }),
      })
      .test(
        'isValidNumber',
        intl.formatMessage({
          id: 'admin.citySettings.form.numPromoCodeUsesError',
        }),
        function(value) {
          return value ? Number(value) >= 0 && Number(value) <= 1000 : true;
        }
      ),
    maximumCustomPromoCodeUses: Yup.string()
      .required(
        intl.formatMessage({
          id: 'form.requiredField',
        })
      )
      .matches(/^\d+$/, {
        message: intl.formatMessage({
          id: 'admin.citySettings.form.numberError',
        }),
      })
      .test(
        'isValidNumber',
        intl.formatMessage({
          id: 'admin.citySettings.form.numPromoCodeUsesError',
        }),
        function(value) {
          return value ? Number(value) >= 0 && Number(value) <= 1000 : true;
        }
      ),
    maximumPercentagePromoCodeUses: Yup.string()
      .required(
        intl.formatMessage({
          id: 'form.requiredField',
        })
      )
      .matches(/^\d+$/, {
        message: intl.formatMessage({
          id: 'admin.citySettings.form.numberError',
        }),
      })
      .test(
        'isValidNumber',
        intl.formatMessage({
          id: 'admin.citySettings.form.numPromoCodeUsesError',
        }),
        function(value) {
          return value ? Number(value) >= 0 && Number(value) <= 1000 : true;
        }
      ),
  });

export default citySettingsEditFormSchema;
