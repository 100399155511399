import React, { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { get } from 'app/shared/utils/get';
import useModal from 'app/shared/utils/useModal';
import usePermission from 'app/shared/utils/usePermission';
import { ListPage, ListPageContext } from 'app/shared/context/ListPage';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import { LoadingBlocks } from 'app/shared/components/atoms/LoadingBlocks';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import RoutableModal from 'app/shared/components/molecules/RoutableModal';
import { GlobalError } from 'app/shared/components/pages/Status';
import {
  getContinentOptions,
  getFilterNamesAndOptionsForTitleMapping,
} from 'app/admin/utils/optionLists';
import { GetContinents } from 'app/admin/graphql/continents/queryHooks';
import { GetCountriesFullInfo } from 'app/admin/graphql/countries/queryHooks';
import EditIconAndTextLink from 'app/admin/components/atoms/EditIconAndTextLink';
import ListingFooter from 'app/admin/components/molecules/ListingFooter';
import ListingNoResults from 'app/admin/components/molecules/ListingNoResults';
import CardGrid, { CardType } from 'app/admin/components/organisms/CardGrid';
import CountryCard from 'app/admin/components/organisms/CountryCard';
import ListingControls from 'app/admin/components/organisms/ListingControls';
import ListingFilter from 'app/admin/components/organisms/ListingFilter';
import ListingHeader from 'app/admin/components/organisms/ListingHeader';
import ListPageTemplate from 'app/admin/components/templates/ListPage';
import Layout from 'app/admin/layouts/ListPage';

import { sortOptions } from './options';
import Analytics from './tracking';

interface HideProps {
  shouldRefetchCountries?: boolean;
}

const pageStateConfig = {
  filterNames: ['continent'],
  defaultSort: { by: 'title', direction: 'asc' },
  textSearchParamName: 'country_search',
  idParamName: 'country_id',
};

const EditContinentLinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding-right: 10px;
  margin-bottom: 15px;
`;

const AdminCountries: React.FC = () => {
  const intl = useIntl();
  const pageState = useContext(ListPageContext);
  const PER_PAGE = 18;

  const hasAccessContinentsPermission = usePermission('continent.access');

  const {
    loading: loadingContinents,
    error: errorContinents,
    data: dataContinents,
  } = GetContinents();

  const continentOptions = getContinentOptions(dataContinents);

  const filterDropdownOptionsInfoList = [
    {
      filterName: 'continent',
      dropdownParams: {
        searchBar: true,
        title: intl.formatMessage({
          id: 'admin.countries.filter.continent',
        }),
        options: continentOptions,
      },
    },
  ];

  const [addCountryModal, toggleAddCountryModal] = useModal();
  const [continentsEditModal, toggleContinentsEditModal] = useModal();

  const {
    loading: loadingCountries,
    error: errorCountries,
    data: dataCountries,
    refetch: refetchCountries,
  } = GetCountriesFullInfo({
    countrySearch: pageState.textSearchState,
    continent: pageState.filterListVariable('continent'),
    orderBy: pageState.sortState.by,
    orderDirection: pageState.sortState.direction,
    page: pageState.page,
    perPage: PER_PAGE,
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    pageState.updateDetailsModal();
  }, []);

  useEffect(() => {
    pageState.updateScrollPositionOnPage();
  }, [pageState.detailsModal.isShowing]);

  useEffect(() => {
    pageState.setupFilterLabelTitleMapping(
      getFilterNamesAndOptionsForTitleMapping(filterDropdownOptionsInfoList)
    );
  }, [dataContinents, dataCountries]);

  useEffect(() => {
    pageState.updatePageUrl();
  }, [
    pageState.page,
    pageState.sortState,
    pageState.filterState,
    pageState.textSearchState,
    pageState.detailData,
    pageState.detailsModal.isShowing,
    pageState.viewModeState,
  ]);

  if (
    (!loadingContinents && !dataContinents) ||
    errorContinents ||
    (!loadingCountries && !dataCountries) ||
    errorCountries
  ) {
    return <GlobalError />;
  }

  const modalsContent = () => (
    <>
      {pageState.detailsModal.isShowing && (
        <RoutableModal
          hide={({ shouldRefetchCountries = false }: HideProps = {}) => {
            pageState.detailsModal.hide();
            if (shouldRefetchCountries) {
              refetchCountries();
            }
          }}
          initialRouteProps={pageState.detailData}
          initialRouteName="country-details"
          dataQaidSuffix="admin-edit-country-details"
        />
      )}
      {addCountryModal.isShowing && (
        <RoutableModal
          hide={addCountryModal.hide}
          initialRouteProps={{}}
          initialRouteName="country-create"
          dataQaidSuffix="admin-create-country"
        />
      )}
      {continentsEditModal.isShowing && (
        <RoutableModal
          hide={continentsEditModal.hide}
          initialRouteProps={{}}
          initialRouteName="continents"
          dataQaidSuffix="admin-edit-continents"
        />
      )}
    </>
  );

  return (
    <Layout scrollDisabled={pageState.detailsModal.isShowing}>
      <ListPageTemplate
        pageTitle={intl.formatMessage({
          id: 'admin.countries.pageTitle',
        })}
        modalsContent={modalsContent}
      >
        <ListingHeader
          pageTitle={intl.formatMessage({
            id: 'admin.countries.pageTitle',
          })}
          addEntityText={intl.formatMessage({
            id: 'admin.countries.addCountry',
          })}
          onClickAddEntity={toggleAddCountryModal}
          searchPlaceholder={intl.formatMessage({
            id: 'admin.countries.textSearch.placeholder',
          })}
          searchValue={pageState.textSearchState}
          onSearch={pageState.handleTextSearch}
          dataQaidPrefix="country"
        />
        {hasAccessContinentsPermission && (
          <EditContinentLinkContainer>
            <EditIconAndTextLink
              text={intl.formatMessage({
                id: 'admin.countries.editContinents',
              })}
              onClick={toggleContinentsEditModal}
            />
          </EditContinentLinkContainer>
        )}
        <div>
          <ListingControls
            sortOptions={sortOptions}
            orderBy={pageState.sortState.by}
            orderDirection={pageState.sortState.direction}
            totalRecords={
              dataCountries && dataCountries.countries.metadata.totalRecords
            }
            loading={loadingCountries}
            onSort={pageState.handleSortChange}
            onReset={pageState.handleResetFilters}
            dataQaidPrefix="country"
          />
          <ListingFilter
            filterTitle={intl.formatMessage({
              id: 'admin.countries.filterTitle',
            })}
            textSearchString={pageState.textSearchState}
            handleTextSearchLabelClose={pageState.handleTextSearchLabelClose}
            labelTitleMapping={pageState.filterLabelTitleMapping}
            dropdownOptionsInfoList={filterDropdownOptionsInfoList}
            filterState={pageState.filterState}
            handleRemoveFilter={pageState.handleRemoveFilter}
            handleFilterChange={pageState.handleFilterChange}
          />
        </div>

        <div>
          <CardGrid
            objectData={get(dataCountries, 'countries.countries', [])}
            renderCardComponent={(country: CardType, i: number) => (
              // @ts-ignore
              <CountryCard
                index={i}
                onShowDetails={pageState.toggleDetailsModalAndSetDetailData}
                {...country}
              />
            )}
            dataQaid="admin-countries-list"
            loading={loadingCountries}
            loadingComponent={
              <LoadingBlocks.Rectangle width="100%" height="420px" />
            }
            hideDividerOnSize="xs"
          />
          <DottedLine />
          <Spacer mb={2} />
        </div>

        <ListingNoResults
          entityName={intl.formatMessage({
            id: 'admin.countries.noResultsEntityName',
          })}
          numResults={get(
            dataCountries,
            'countries.countries.length',
            undefined
          )}
          loading={loadingCountries}
        />

        <ListingFooter
          numTotalRecords={get(
            dataCountries,
            'countries.metadata.totalRecords',
            undefined
          )}
          perPage={PER_PAGE}
          currentPage={pageState.page}
          onPageChange={pageState.handlePageChange}
          loading={loadingCountries}
          dataQaidPrefix="countries"
        />
      </ListPageTemplate>
    </Layout>
  );
};

const AdminCountriesWrapper: React.FC = () => (
  <ListPage config={pageStateConfig} analytics={Analytics}>
    <AdminCountries />
  </ListPage>
);

export default AdminCountriesWrapper;
