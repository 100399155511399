import React from 'react';
import { useIntl } from 'react-intl';

import { FormSectionTitle } from 'app/shared/components/atoms/FormContent';
import FromEmailSettingsEditFormFields from 'app/admin/components/organisms/FromEmailSettingsEditFormFields';

interface FromEmailSettingsEditFormProps {
  setFieldValue: any;
  touched: any;
  errors: any;
  values: any;
  cityCachedSlug?: string;
  curatorGroupId?: number;
  overrideHelpTextType?: string;
}

const FromEmailSettingsEditForm: React.FC<FromEmailSettingsEditFormProps> = ({
  setFieldValue,
  touched,
  errors,
  values,
  cityCachedSlug,
  curatorGroupId,
  overrideHelpTextType,
}) => {
  const intl = useIntl();

  return (
    <>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.emailSection.form.fromEmails',
        })}
      </FormSectionTitle>
      <FromEmailSettingsEditFormFields
        setFieldValue={setFieldValue}
        touched={touched}
        errors={errors}
        values={values}
        cityCachedSlug={cityCachedSlug}
        curatorGroupId={curatorGroupId}
        overrideHelpTextType={overrideHelpTextType}
      />
    </>
  );
};

export default FromEmailSettingsEditForm;
