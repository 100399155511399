export const sortOptions = [
  {
    title: 'Name (A - Z)',
    value: {
      by: 'user_name',
      direction: 'asc',
    },
  },
  {
    title: 'Name (Z - A)',
    value: {
      by: 'user_name',
      direction: 'desc',
    },
  },
  {
    title: 'Email (A - Z)',
    value: {
      by: 'email',
      direction: 'asc',
    },
  },
  {
    title: 'Email (Z - A)',
    value: {
      by: 'email',
      direction: 'desc',
    },
  },
  {
    title: 'Shortest Time Since Last Concert',
    value: {
      by: 'last_staffed_event_at',
      direction: 'desc',
    },
  },
  {
    title: 'Longest Time Since Last Concert',
    value: {
      by: 'last_staffed_event_at',
      direction: 'asc',
    },
  },
  {
    title: 'Most Concerts Crewed',
    value: {
      by: 'past_staffed_events_count',
      direction: 'desc',
    },
  },
  {
    title: 'Least Concerts Crewed',
    value: {
      by: 'past_staffed_events_count',
      direction: 'asc',
    },
  },
];

export const eventRoleOptions = (intl: any) => {
  const options = [
    {
      title: intl.formatMessage({
        id: 'admin.crewPortal.crewRole.dropdownOption.lead',
      }),
      value: 'crew_lead',
    },
    {
      title: intl.formatMessage({
        id: 'admin.crewPortal.crewRole.dropdownOption.emcee',
      }),
      value: 'crew_emcee',
    },
    {
      title: intl.formatMessage({
        id: 'admin.crewPortal.crewRole.dropdownOption.support',
      }),
      value: 'day_of_show_support',
    },
    {
      title: intl.formatMessage({
        id: 'admin.crewPortal.crewRole.dropdownOption.audio',
      }),
      value: 'audio',
    },
    {
      title: intl.formatMessage({
        id: 'admin.users.gearSupport',
      }),
      value: 'gear_runner',
    },
    {
      title: intl.formatMessage({
        id: 'admin.crewPortal.crewRole.dropdownOption.photo',
      }),
      value: 'photo',
    },
    {
      title: intl.formatMessage({
        id: 'admin.crewPortal.crewRole.dropdownOption.video',
      }),
      value: 'video',
    },
    {
      title: intl.formatMessage({
        id: 'admin.users.booker',
      }),
      value: 'booker',
    },
    {
      title: intl.formatMessage({
        id: 'admin.users.moderator',
      }),
      value: 'moderator',
    },
    {
      title: intl.formatMessage({
        id: 'admin.users.producer',
      }),
      value: 'producer',
    },
  ];

  return options;
};

export const crewStatusOptions = (intl: any) => {
  return [
    {
      title: intl.formatMessage({
        id: 'shared.active',
      }),
      value: 'active',
    },
    {
      title: intl.formatMessage({
        id: 'shared.inactive',
      }),
      value: 'inactive',
    },
  ];
};
