import { useQuery } from 'react-apollo';

import {
  GetPropertiesData,
  GetPropertiesVariables,
} from 'app/typings/properties';
import { queryParams } from 'app/shared/utils/queries';
import { GET_PROPERTIES } from 'app/shared/graphql/properties/queries';

export const GetProperties = (params: GetPropertiesVariables = {}) => {
  return useQuery<GetPropertiesData>(GET_PROPERTIES, queryParams(params));
};
