import React from 'react';
import styled, { css } from 'styled-components';

import { ManualCSSColors } from 'app/shared/theme';
import { useCurrentTheme } from 'app/shared/theme';
import { Icon } from 'app/shared/components/atoms/IconManualCSS';
import { StreamlineIcon } from 'app/shared/components/atoms/StreamlineIcon';

interface CircleProps {
  circleBackgroundColor: string;
  iconSize?: number;
  iconColor?: ManualCSSColors;
  borderCircle?: boolean;
  circleSize?: number;
  circleBorderRadius?: number;
}

interface IconProps {
  isStreamlineIcon?: boolean;
  iconName?: any; // String | Icon type from Streamline Icon
  dataQaIdSuffix: string;
}

export const CircleWrapper = styled.div<CircleProps>`
  ${({
    theme,
    circleBackgroundColor,
    iconColor,
    borderCircle,
    circleSize,
    circleBorderRadius,
  }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${circleSize || theme.ruler[10]}px;
    height: ${circleSize || theme.ruler[10]}px;
    border: ${borderCircle && iconColor
      ? `2px solid ${theme.colors[iconColor]}`
      : 'none'};
    border-radius: ${circleBorderRadius || theme.ruler[5]}px;
    padding: 12px 8px 8px 8px;
    background-color: ${theme.colors[circleBackgroundColor] ||
      circleBackgroundColor};
  `}
`;

const IconInCircle: React.FC<IconProps & CircleProps> = ({
  circleBackgroundColor,
  iconSize = 16,
  iconName,
  iconColor,
  borderCircle = false,
  isStreamlineIcon = true,
  dataQaIdSuffix,
  circleSize,
  circleBorderRadius,
}) => {
  const theme = useCurrentTheme();
  const iconQaid = `icon-${dataQaIdSuffix}`;

  return (
    <CircleWrapper
      borderCircle={borderCircle}
      circleBackgroundColor={circleBackgroundColor}
      iconColor={iconColor}
      circleSize={circleSize}
      circleBorderRadius={circleBorderRadius}
      data-qaid="circle-icon-wrapper"
    >
      {isStreamlineIcon ? (
        <div data-qaid={iconQaid}>
          <StreamlineIcon
            icon={iconName}
            size={iconSize}
            stroke={theme.colors[iconColor || 'whiteDenim']}
          />
        </div>
      ) : (
        <Icon
          name={iconName}
          size={iconSize + 'px'}
          color={iconColor}
          data-qaid={iconQaid}
        />
      )}
    </CircleWrapper>
  );
};

export default IconInCircle;
