import React, { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { get } from 'app/shared/utils/get';
import usePermission from 'app/shared/utils/usePermission';
import { ListPage, ListPageContext } from 'app/shared/context/ListPage';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import { ChecklistIcon } from 'app/shared/components/atoms/IconLibrary';
import { Icon } from 'app/shared/components/atoms/IconManualCSS';
import { LoadingBlocks } from 'app/shared/components/atoms/LoadingBlocks';
import { LoadingError } from 'app/shared/components/atoms/LoadingError';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import SearchInput from 'app/shared/components/molecules/SearchInput';
import Flyover from 'app/shared/components/molecules/SimpleFlyover';
import { getFilterNamesAndOptionsForTitleMapping } from 'app/admin/utils/optionLists';
import { GetAttendees } from 'app/admin/graphql/attendees/queryHooks';
import { GetEventInfo } from 'app/admin/graphql/events/queryHooks';
import { DetailsInfoMainContainer } from 'app/admin/components/atoms/DetailContent';
import { DetailsImage } from 'app/admin/components/molecules/DetailsImage';
import { DetailsTitle } from 'app/admin/components/molecules/DetailsTitle';
import ListingNoResults from 'app/admin/components/molecules/ListingNoResults';
import CardGrid, { CardType } from 'app/admin/components/organisms/CardGrid';
import GuestlistCard from 'app/admin/components/organisms/GuestlistCard';
import GuestlistVipCreate from 'app/admin/components/organisms/GuestlistVipEdit/GuestlistVipCreate';
import ListingControls from 'app/admin/components/organisms/ListingControls';
import ListingFilter from 'app/admin/components/organisms/ListingFilter';

import {
  appliedPromoCodeStatusOptions,
  sortOptions,
  statusOptions,
  vipStatusOptions,
} from './options';

interface Props {
  contentProps?: any;
  navigateTo: (routeData: object) => void;
  hide: VoidFunction;
}

const pageStateConfig = {
  filterNames: ['status', 'vip_status', 'applied_promo_code_status'],
  defaultSort: { by: 'name', direction: 'asc' },
  textSearchParamName: 'attendee_search',
  useDefaultSort: true,
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media screen and (min-width: 1300px) {
    max-width: 1260px;
  }
  padding: 0;
  width: 100%;
`;

const ContentsContainer = styled.div`
  width: 100%;
`;

const HeaderUpperContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    ${theme.media.sm`
      margin-left: 45px;
    `};
    ${theme.media.md`
      margin-left: 25px;
    `};
    ${theme.media.lg`
      margin-left: 15px;
    `};
    ${theme.media.xl`
      margin: 0;
      flex-direction: row;
    `};
  `}
`;

const HeaderLeftBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    width: 100%;
    ${theme.media.xl`
        width: 55%;
      `};
  `}
`;

const HeaderRightBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 40px;
    ${theme.media.xl`
      margin-top: 0;
      width: 45%;
    `};
  `}
`;

const ListingHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;

const GuestlistDayOfShowLinkContainer = styled.div`
  ${({ theme }) => css`
    ${theme.media.xl`
      margin-left: -19px;
    `};
    margin-bottom: 7px;
  `}
`;

const AddVipControls = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-end;
    margin: 0 8px 20px 0;
    flex-grow: 1;

    ${theme.media.lg`
      margin: 0 30px 20px 0;
    `}

    ${theme.media.xl`
      margin: 0 40px 20px 0;
    `}
  `}
`;

const IconWrapper = styled.a`
  ${({ theme }) => css`
    display: flex;
    cursor: pointer;
    white-space: nowrap;
    color: ${theme.colors.green600};
  `}
`;

const BaseIcon = styled(Icon)`
  margin-right: 6px;
`;

const ActiveIcon = styled(BaseIcon)`
  ${({ theme }) => css`
    &::before {
      color: ${theme.colors.green600};
    }
  `}
`;

const BaseIconText = styled.div`
  font-size: 10px;
  line-height: 150%;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  margin-top: 2px;
`;

const ActiveIconText = styled(BaseIconText)`
  ${({ theme }) => css`
    color: ${theme.colors.green600};
  `}
`;

const SearchInputWrapper = styled.div`
  ${({ theme }) => css`
    ${theme.media.xs`
        flex: 3;
      `};

    ${theme.media.md`
        flex: 1;
      `};
  `}
`;

const Guestlist: React.FC<Props> = ({ contentProps, navigateTo, hide }) => {
  const intl = useIntl();
  const pageState = useContext(ListPageContext);

  const hasGuestlistAccessPermission = usePermission('event.guestlist.access');

  const {
    loading: loadingEvent,
    error: errorEvent,
    data: dataEvent,
    refetch: refetchEvent,
  } = GetEventInfo({
    id: get(contentProps, 'id', null),
    includeDraftEvents: true,
    fetchPolicy: 'cache-and-network',
  });

  const {
    loading: loadingAttendees,
    error: errorAttendees,
    data: dataAttendees,
    refetch: refetchAttendees,
  } = GetAttendees({
    eventId: get(contentProps, 'id', null),
    attendeeSearch: pageState.textSearchState,
    status: pageState.filterListVariable('status'),
    vipStatus: pageState.filterListVariable('vip_status'),
    appliedPromoCodeStatus: pageState.filterListVariable(
      'applied_promo_code_status'
    ),
    orderBy: pageState.sortState.by,
    orderDirection: pageState.sortState.direction,
    fetchPolicy: 'network-only',
  });

  const event = get(dataEvent, 'event', undefined);

  const ticketsCountByStatus = {
    applied: get(
      dataAttendees,
      'attendees.attendeesMetadata.ticketsCounts.ticketsCountStatusApplied',
      ''
    ),
    guestlisted: get(
      dataAttendees,
      'attendees.attendeesMetadata.ticketsCounts.ticketsCountStatusGuestlisted',
      ''
    ),
    confirmed: get(
      dataAttendees,
      'attendees.attendeesMetadata.ticketsCounts.ticketsCountStatusConfirmed',
      ''
    ),
    cant_go: get(
      dataAttendees,
      'attendees.attendeesMetadata.ticketsCounts.ticketsCountStatusCantGo',
      ''
    ),
    expired: get(
      dataAttendees,
      'attendees.attendeesMetadata.ticketsCounts.ticketsCountStatusExpired',
      ''
    ),
  };

  const filterDropdownOptionsInfoList = [
    {
      filterName: 'status',
      dropdownParams: {
        title: intl.formatMessage({
          id: 'shared.status',
        }),
        options: statusOptions(
          ticketsCountByStatus,
          (event && event.attendeeFlow) || null,
          !!((event && event.competition) || null)
        ),
      },
    },
    {
      filterName: 'vip_status',
      dropdownParams: {
        title: intl.formatMessage({
          id: 'admin.eventPlanner.guestlist.vip',
        }),
        options: vipStatusOptions(
          get(
            dataAttendees,
            'attendees.attendeesMetadata.ticketsCounts.ticketsCountIsVip',
            ''
          )
        ),
      },
    },
    {
      filterName: 'applied_promo_code_status',
      dropdownParams: {
        title: intl.formatMessage({
          id: 'admin.eventPlanner.guestlist.promoCodeUsed',
        }),
        options: appliedPromoCodeStatusOptions(
          get(
            dataAttendees,
            'attendees.attendeesMetadata.ticketsCounts.ticketsCountHasAppliedPromoCode',
            ''
          )
        ),
      },
    },
  ];

  useEffect(() => {
    pageState.setupFilterLabelTitleMapping(
      getFilterNamesAndOptionsForTitleMapping(filterDropdownOptionsInfoList)
    );
  }, [dataAttendees]);

  if (
    (!loadingEvent && !dataEvent) ||
    errorEvent ||
    (!loadingAttendees && !dataAttendees) ||
    errorAttendees
  ) {
    return (
      <LoadingError
        whatsBeingLoaded={intl.formatMessage({
          id: 'admin.eventPlanner.guestlist.theGuestlist',
        })}
      />
    );
  }

  const onClickGuestlistDayOfShowLink = () => {
    navigateTo({
      routeName: 'guestlist-day-of-show',
      routeProps: {
        id: get(contentProps, 'id', null),
      },
    });
  };

  const localStartsAt = event && event.localStartsAt;
  const cityTitle = event && event.city.title;
  const neighborhoodTitle = event && event.neighborhood?.title;
  const subTitle = neighborhoodTitle
    ? `${cityTitle} / ${neighborhoodTitle}`
    : cityTitle;

  const GuestlistDayOfShowLink: React.FC = () => {
    return (
      <GuestlistDayOfShowLinkContainer>
        <ChecklistIcon
          iconSize={20}
          onClick={() => onClickGuestlistDayOfShowLink()}
        />
      </GuestlistDayOfShowLinkContainer>
    );
  };

  const GuestAddVipContent = () => (
    <GuestlistVipCreate
      event={event}
      onSuccess={() => {
        refetchEvent && refetchEvent();
        refetchAttendees && refetchAttendees();
      }}
    />
  );

  if (!hasGuestlistAccessPermission) {
    hide();
    return null;
  }

  return (
    <ModalContentContainer>
      <DetailsInfoMainContainer>
        <HeaderUpperContainer>
          <HeaderLeftBox>
            <Grid>
              <DetailsImage
                imageStyle="calendar"
                loading={loadingAttendees}
                detailsDate={localStartsAt}
              />
              <Col xs={5} sm={7}>
                <DetailsTitle
                  title={intl.formatMessage({
                    id: 'admin.eventPlanner.guestlist.guests',
                  })}
                  subTitle={subTitle}
                  aboveTitleComponent={GuestlistDayOfShowLink}
                  loading={loadingAttendees}
                  detailsDate={localStartsAt}
                />
              </Col>
            </Grid>
          </HeaderLeftBox>
          <HeaderRightBox>
            <ListingHeaderContainer>
              <Flyover
                triggerElement={
                  <AddVipControls>
                    <IconWrapper data-qaid="add-vip-button">
                      <ActiveIcon name="plus" size="16px" />
                      <ActiveIconText>
                        {' '}
                        {intl.formatMessage({
                          id: 'admin.eventPlanner.guestlist.addVip',
                        })}
                      </ActiveIconText>
                    </IconWrapper>
                  </AddVipControls>
                }
                innerElement={GuestAddVipContent()}
                zIndex={102}
              />
              <SearchInputWrapper>
                <SearchInput
                  placeholder={intl.formatMessage({
                    id: 'admin.eventPlanner.guestlist.textSearchPlaceholder',
                  })}
                  value={pageState.textSearchState || undefined}
                  onSearch={pageState.handleTextSearch}
                  dataQaidPrefix="guestlist-search"
                />
              </SearchInputWrapper>
            </ListingHeaderContainer>
          </HeaderRightBox>
        </HeaderUpperContainer>
        <MainContainer>
          <ListingControls
            sortOptions={sortOptions}
            orderBy={pageState.sortState.by}
            orderDirection={pageState.sortState.direction}
            resultsTextStringId="admin.eventPlanner.guestlist.showingXResults"
            totalRecords={get(
              dataAttendees,
              'attendees.attendeesMetadata.ticketsCounts.ticketsCountTotalForResults',
              0
            )}
            loading={loadingAttendees}
            onSort={pageState.handleSortChange}
            onReset={pageState.handleResetFilters}
            dataQaidPrefix="guestlist"
          />
          <ListingFilter
            filterTitle={intl.formatMessage({
              id: 'admin.eventPlanner.guestlist.filterTitle',
            })}
            textSearchString={pageState.textSearchState}
            handleTextSearchLabelClose={pageState.handleTextSearchLabelClose}
            labelTitleMapping={pageState.filterLabelTitleMapping}
            dropdownOptionsInfoList={filterDropdownOptionsInfoList}
            filterState={pageState.filterState}
            handleRemoveFilter={pageState.handleRemoveFilter}
            handleFilterChange={pageState.handleFilterChange}
          />

          <ContentsContainer>
            <CardGrid
              objectData={get(dataAttendees, 'attendees.attendees', [])}
              renderCardComponent={(attendee: CardType, i: number) => (
                // @ts-ignore
                <GuestlistCard
                  index={i}
                  {...attendee}
                  event={event}
                  refetchEvent={refetchEvent}
                  refetchAttendees={refetchAttendees}
                  modalRef={contentProps.modalRef}
                />
              )}
              dataQaid="admin-guestlist"
              loading={loadingAttendees}
              loadingComponent={
                <LoadingBlocks.Rectangle width="100%" height="420px" />
              }
              hideDividerOnSize="xs"
            />
            <DottedLine />
            <Spacer mb={2} />
            <ListingNoResults
              entityName={intl.formatMessage({
                id: 'admin.eventPlanner.guestlist.noResultsEntityName',
              })}
              numResults={get(
                dataAttendees,
                'attendees.attendeesMetadata.ticketsCounts.ticketsCountTotalForResults',
                undefined
              )}
              loading={loadingAttendees}
            />
          </ContentsContainer>
        </MainContainer>
      </DetailsInfoMainContainer>
    </ModalContentContainer>
  );
};

const GuestlistWrapper: React.FC<Props> = ({
  contentProps,
  navigateTo,
  hide,
}) => (
  <ListPage config={pageStateConfig}>
    <Guestlist
      contentProps={contentProps}
      navigateTo={navigateTo}
      hide={hide}
    />
  </ListPage>
);

export default GuestlistWrapper;
