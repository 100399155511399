import React from 'react';
import Dropzone from 'react-dropzone';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { Col } from 'app/shared/components/atoms/GridManualCSS';
import { Icon } from 'app/shared/components/atoms/IconManualCSS';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Caption, H6 } from 'app/shared/components/atoms/TypographyManualCSS';
import { SUPPORTED_IMAGE_FORMATS } from 'app/shared/components/organisms/ImageUploaderWithCropper';

const SubtitleText = styled.p`
  font-size: 12px;
`;

interface ImageUploader {
  preview?: string;
  formatAndUploadSizeInfo?: string;
  setActiveDrag: (value: boolean) => void;
  activeDrag?: boolean;
  setImageUploads: (value: any, index: number) => void;
  index: number;
  setActiveImageUploadsIndex: (value: any) => void;
  previewShape: string;
  setActiveComponent: (value: string) => void;
}

const ImageUploader: React.FC<ImageUploader> = ({
  activeDrag,
  setActiveDrag,
  setImageUploads,
  preview,
  previewShape,
  formatAndUploadSizeInfo = 'imageUploader.defaultFormatAndUploadSizeInfo',
  index,
  setActiveImageUploadsIndex,
  setActiveComponent,
}) => {
  const intl = useIntl();

  const Border = styled.div<{ previewShape: string }>`
    ${({ theme, previewShape }) => css`
      background-color: ${theme.colors.silverSprings};
      border-radius: ${previewShape === 'circle' ? '50%' : '5px'};
      display: block;
      padding: 30px;
      min-width: 380px;
      min-height: 100%;

      ${theme.media.xs`
        min-width: 320px;
      `}
    `}
  `;

  const ActiveDropZone = styled.div<{ previewShape: string }>`
    ${({ theme }) => css`
      background-color: ${activeDrag
        ? theme.colors.green50
        : theme.colors.whiteDenim};
      border: 1px dashed
        ${activeDrag ? theme.colors.green500 : theme.colors.backToBlack};
      border-radius: inherit;
      border-width: 1px;
      display: inline-grid;
      justify-content: center;
      padding: 10px;
      min-width: 280px;
      &:focus {
        outline: none;
      }
      &:hover {
        background-color: ${theme.colors.green50};
      }

      ${theme.media.xs`
        min-width: 260px;
      `}
    `}
  `;

  const DarkSmokeCaption = styled(Caption)`
    ${({ theme }) => css`
      color: ${theme.colors.darkSmoke};
    `}
  `;

  const PreviewImage = styled.div<{ previewShape: string }>`
    ${({ theme, previewShape }) => css`
      background-image: url(${preview});
      background-position: center;
      background-size: cover;
      border: 1px solid ${theme.colors.green500};
      border-radius: ${previewShape === 'circle' ? '50%' : '5px'};
      position: relative;
      width: 100%;
      &:after {
        content: '';
        display: block;
        padding-bottom: 100%;
      }
    `}
  `;

  const CloseButton = styled.div`
    align-items: center;
    background: black;
    border-radius: 50%;
    display: flex;
    height: 20px;
    justify-content: center;
    padding: 0px;
    cursor: pointer;
    position: absolute;
    right: -8px;
    top: -8px;
    width: 20px;
  `;

  const IconWrapper = styled.div`
    text-align: center;
    padding: 10px 0px 0px 0px;
  `;

  const PrimaryButtonMock = styled.div`
    ${({ theme }) => css`
      border-radius: ${theme.borderRadius.button};
      cursor: pointer;
      font-weight: 400;
      height: ${theme.dimensions.button.default.height};
      padding: ${theme.dimensions.button.default.padding};
      letter-spacing: 0.8px;
      outline: none;
      text-decoration: none;
      text-align: center;
      text-transform: uppercase;
      background: ${theme.colors.primary};
      border: 1px solid ${theme.colors.primary};
      padding: 10px 0px 0px 0px;
      color: ${theme.colors.whiteDenim};

      ${theme.media.xs`
        font-size: ${theme.fontSizes.button.xs};
      `};

      ${theme.media.lg`
        font-size: ${theme.fontSizes.button.lg};
      `};
    }`}
  `;

  const handleNewFile = (file: any) => {
    const preview = URL.createObjectURL(file);
    const newImage = {
      name: file.name,
      size: file.size,
      contentType: file.type,
      url: preview,
    };

    setImageUploads(newImage, index);
    setActiveImageUploadsIndex(index);
  };

  const onDrop = (acceptedFiles: any) => {
    handleNewFile(acceptedFiles[0]);
    setActiveDrag(false);
    setActiveComponent('cropper');
  };

  const onFilesAdded = (event: any) => {
    const { files } = event.target;
    const file = files[0];
    handleNewFile(file);
    setActiveComponent('cropper');
  };

  const removePreview = () => {
    setImageUploads({}, index);
  };

  return (
    <div data-qaid="image-uploader">
      <Dropzone
        key="image-dropzone"
        maxSize={2.5e7} // set to 25Mb in bytes
        accept={SUPPORTED_IMAGE_FORMATS}
        multiple={false}
        onDragEnter={() => setActiveDrag(true)}
        onDragLeave={() => setActiveDrag(false)}
        onDropAccepted={(acceptedFiles: any) => onDrop(acceptedFiles)}
      >
        {({ getRootProps, getInputProps }) => (
          <Col sm={12} xs={12}>
            {preview ? (
              <PreviewImage
                previewShape={previewShape}
                data-qaid="image-uploader-preview"
              >
                <CloseButton
                  onClick={removePreview}
                  data-qaid="image-uploader-remove"
                >
                  <Icon name="close" size="10px" color="whiteDenim" />
                </CloseButton>
              </PreviewImage>
            ) : (
              <Border previewShape="square">
                <H6 spaceAfter={3}>
                  {intl.formatMessage({
                    id: 'imageUploader.uploadTitle',
                  })}
                </H6>
                <SubtitleText>
                  {intl.formatMessage({
                    id: 'imageUploader.uploadSubtitle',
                  })}
                </SubtitleText>
                <Spacer mt={2} />
                <ActiveDropZone {...getRootProps()} previewShape="square">
                  <input
                    {...getInputProps()}
                    onChange={onFilesAdded}
                    data-qaid="image-uploader-input"
                  />
                  <IconWrapper>
                    <Icon name="photoUpload" size="26px" color="green500" />
                  </IconWrapper>
                  <Spacer mb={1} />
                  <PrimaryButtonMock>
                    {intl.formatMessage({
                      id: 'imageUploader.uploadPhoto',
                    })}
                  </PrimaryButtonMock>
                  <Spacer mb={2} />
                  <DarkSmokeCaption>
                    {intl.formatMessage({
                      id: formatAndUploadSizeInfo,
                    })}
                  </DarkSmokeCaption>
                </ActiveDropZone>
              </Border>
            )}
          </Col>
        )}
      </Dropzone>
    </div>
  );
};

export default ImageUploader;
