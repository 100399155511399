import React from 'react';
import { useIntl } from 'react-intl';

import { MenuItem } from 'app/shared/components/atoms/Menu';

interface OptionsProps {
  isLoading: boolean;
  options: any;
  onOptionClick: (value: number | string, option: any) => void;
  getOptionLabel?: (option: any) => string;
  optionValueKey?: string;
}

const Options: React.FC<OptionsProps> = ({
  isLoading,
  options,
  onOptionClick,
  getOptionLabel,
  optionValueKey,
}) => {
  const intl = useIntl();

  if (isLoading) {
    return (
      <MenuItem disabled className="suppress-outside-click">
        {intl.formatMessage({ id: 'typeahead.loadingOptions' })}
      </MenuItem>
    );
  }

  if (!options) {
    return (
      <MenuItem disabled className="suppress-outside-click">
        {intl.formatMessage({ id: 'typeahead.errorPleaseTryAgain' })}
      </MenuItem>
    );
  }

  return (
    <>
      {options.map((option: any, index: number) => {
        const value = optionValueKey ? option[optionValueKey] : option.id;
        const label = getOptionLabel ? getOptionLabel(option) : option.label;
        return (
          <MenuItem
            data-qaid={label}
            key={index}
            value={value}
            onClick={() => onOptionClick(value, option)}
            className="suppress-outside-click"
          >
            {label}
          </MenuItem>
        );
      })}
    </>
  );
};

export default Options;
