import React, { useContext, useEffect } from 'react';
import loadable from '@loadable/component';
import Cookies from 'js-cookie';
import { useIntl } from 'react-intl';

import { dateFormatter } from 'app/shared/utils/datetime';
import { DISCOVERY_EVENT } from 'app/shared/utils/events';
import { getIdsSubmitVariable } from 'app/shared/utils/form';
import { get } from 'app/shared/utils/get';
import useModal from 'app/shared/utils/useModal';
import usePermission from 'app/shared/utils/usePermission';
import { GetCitiesForGroupByBusinessOwner } from 'app/shared/graphql/cities/queryHooks';
import { ListPage, ListPageContext } from 'app/shared/context/ListPage';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import { LoadingBlocks } from 'app/shared/components/atoms/LoadingBlocks';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import RoutableModal from 'app/shared/components/molecules/RoutableModal';
import { CityInfoForLimitingByCity } from 'app/admin/utils/cityPermissions';
import {
  filterInfoListFilteredByCheckOnFilterName,
  getFilterNamesAndOptionsForTitleMapping,
  getNeighborhoodOptions,
} from 'app/admin/utils/optionLists';
import { GetEventsForCrewPortal } from 'app/admin/graphql/events/queryHooks';
import { GetNeighborhoods } from 'app/admin/graphql/neighborhoods/queryHooks';
import { GetStaffMember } from 'app/admin/graphql/staffMembers/queryHooks';
import ListingFooter from 'app/admin/components/molecules/ListingFooter';
import CardGrid from 'app/admin/components/organisms/CardGrid';
import CrewPortalCard from 'app/admin/components/organisms/CrewPortalCard';
import ListingHeader from 'app/admin/components/organisms/ListingHeader';
import ListPageTemplate from 'app/admin/components/templates/ListPage';
import Layout from 'app/admin/layouts/ListPage';

import {
  dateOptions,
  roleOptions,
  sortOptions,
  statusOptions,
} from './options';
import Analytics from './tracking';

const ListingControls = loadable(() =>
  import('app/admin/components/organisms/ListingControls')
);
const ListingFilter = loadable(() =>
  import('app/admin/components/organisms/ListingFilter')
);
const ListingOneClickFilter = loadable(() =>
  import('app/admin/components/molecules/ListingOneClickFilter')
);
const ListingNoResults = loadable(() =>
  import('app/admin/components/molecules/ListingNoResults')
);
const GlobalError = loadable(
  () => import('app/shared/components/pages/Status'),
  {
    resolveComponent: components => components.GlobalError,
  }
);

const pageStateConfig = {
  filterNames: ['date', 'neighborhood', 'role_available', 'status'],
  defaultSort: { by: 'start', direction: 'asc' },
  textSearchParamName: 'event_search',
  idParamName: 'event_id',
  genericModalIdParamName: 'guestlist_event_id',
};

function eventOccurredWithinLast3Months(eventStartsAt: string) {
  // 90 days which is close enough to 3 months, we don't need an exact number, just a rough
  // number to prevent feedback modal from popping up automatically for very old events
  const THREE_MONTHS_IN_MILLISECONDS = 60 * 60 * 24 * 90 * 1000;
  const eventDate = new Date(eventStartsAt).getTime();
  const currentDate = new Date().getTime();

  return currentDate - eventDate < THREE_MONTHS_IN_MILLISECONDS;
}

const anyEventsPendingFeedbackWithinLast3Months = (events?: any[]) =>
  events?.some((event: any) =>
    eventOccurredWithinLast3Months(event.localStartsAt)
  );

const AdminCrewPortal: React.FC = () => {
  const intl = useIntl();
  const pageState = useContext(ListPageContext);
  const PER_PAGE = 30;

  const [availabilityModal, toggleAvailabilityModal] = useModal();
  const [crewFeedbackModal, toggleCrewFeedbackModal] = useModal();
  const hasCrewAllEventTypesPermission = usePermission(
    'event.crewAllEventTypes.access'
  );

  const {
    loading: loadingCities,
    error: errorCities,
    data: dataCities,
  } = GetCitiesForGroupByBusinessOwner({});

  const { cityIdsToLimitBy, cityTitlesToLimitBy } = CityInfoForLimitingByCity(
    'event.crew.viewByCity',
    dataCities
  );

  const {
    loading: loadingNeighborhoods,
    error: errorNeighborhoods,
    data: dataNeighborhoods,
  } = GetNeighborhoods({
    cityIds: cityIdsToLimitBy?.join(','),
    skip: !cityIdsToLimitBy,
  });

  const limitToCities =
    cityTitlesToLimitBy && cityTitlesToLimitBy.length
      ? ' in ' + cityTitlesToLimitBy.join(', ')
      : '';

  const neighborhoodOptions = getNeighborhoodOptions(
    dataNeighborhoods,
    cityIdsToLimitBy
  );

  const filterDropdownOptionsInfoList = filterInfoListFilteredByCheckOnFilterName(
    [
      {
        filterName: 'date',
        dropdownParams: {
          searchBar: false,
          title: intl.formatMessage({
            id: 'admin.crewPortal.filter.dates',
          }),
          options: dateOptions,
        },
      },
      {
        filterName: 'neighborhood',
        dropdownParams: {
          searchBar: true,
          title: intl.formatMessage({
            id: 'shared.neighborhoods',
          }),
          // @ts-ignore
          groupBy: 'cityGroupName',
          groupById: 'cityCachedSlug',
          options: neighborhoodOptions,
        },
      },
      {
        filterName: 'role_available',
        dropdownParams: {
          searchBar: false,
          title: intl.formatMessage({
            id: 'admin.crewPortal.filter.rolesAvailable',
          }),
          options: roleOptions(intl),
        },
      },
      {
        filterName: 'status',
        dropdownParams: {
          searchBar: false,
          title: intl.formatMessage({
            id: 'admin.crewPortal.filter.concertStatus',
          }),
          options: statusOptions,
        },
      },
    ],
    {
      neighborhood: cityIdsToLimitBy && neighborhoodOptions.length !== 0,
    }
  );

  const getEventTypesSubmitVariable = () =>
    hasCrewAllEventTypesPermission ? undefined : [DISCOVERY_EVENT].toString();

  const shouldFilterByUpcoming = (dateFilter = '') =>
    !dateFilter.includes('past');

  const {
    loading: loadingEvents,
    error: errorEvents,
    data: dataEvents,
    refetch: refetchEvents,
  } = GetEventsForCrewPortal({
    type: getEventTypesSubmitVariable(),
    eventOrganizedAs: 'o_and_o',
    cityIds: getIdsSubmitVariable(cityIdsToLimitBy),
    neighborhood: pageState.filterListVariable('neighborhood'),
    upcomingOrCurrent: shouldFilterByUpcoming(
      pageState.filterListVariable('date')
    ),
    status:
      pageState.filterListVariable('status') == 'exclude_cancelled'
        ? 'published'
        : 'published,cancelled',
    date: pageState.filterListVariable('date'),
    roleAvailable: pageState.filterListVariable('role_available'),
    isStaffedByCurrentUser: pageState.oneClickFilterState.isEnabled,
    orderBy: pageState.sortState.by,
    orderDirection: pageState.sortState.direction,
    page: pageState.page,
    perPage: PER_PAGE,
    fetchPolicy: 'cache-and-network',
  });

  const {
    loading: loadingStaffMemberInfo,
    error: errorStaffMemberInfo,
    data: dataStaffMemberInfo,
  } = GetStaffMember();

  useEffect(() => {
    pageState.updateDetailsModal();
    pageState.updateGenericModal();
  }, []);

  useEffect(() => {
    pageState.updateScrollPositionOnPage();
  }, [pageState.detailsModal.isShowing, pageState.genericModal.isShowing]);

  useEffect(() => {
    pageState.setupFilterLabelTitleMapping(
      getFilterNamesAndOptionsForTitleMapping(filterDropdownOptionsInfoList)
    );
  }, [dataCities]);

  useEffect(() => {
    pageState.updatePageUrl();
  }, [
    pageState.page,
    pageState.sortState,
    pageState.filterState,
    pageState.textSearchState,
    pageState.detailData,
    pageState.genericData,
    pageState.detailsModal.isShowing,
    pageState.genericModal.isShowing,
  ]);

  useEffect(() => {
    if (
      !Cookies.get('crewFeedbackPopupShown') &&
      anyEventsPendingFeedbackWithinLast3Months(
        dataStaffMemberInfo?.staffMember?.eventsPendingFeedback
      )
    ) {
      toggleCrewFeedbackModal();

      const oneDayFromNow = 1;
      Cookies.set('crewFeedbackPopupShown', 'true', {
        expires: oneDayFromNow,
      });
    }
  }, [dataStaffMemberInfo]);

  if (
    (!loadingEvents && !dataEvents) ||
    errorEvents ||
    (!loadingCities && !dataCities) ||
    errorCities ||
    (!loadingNeighborhoods && !dataNeighborhoods && cityIdsToLimitBy) ||
    errorNeighborhoods ||
    (!loadingStaffMemberInfo && !dataStaffMemberInfo) ||
    errorStaffMemberInfo
  ) {
    return <GlobalError />;
  }

  const modalsContent = () => {
    return (
      <>
        {pageState.genericModal.isShowing && (
          <RoutableModal
            hide={pageState.genericModal.hide}
            initialRouteName="guestlist-day-of-show"
            initialRouteProps={{ ...pageState.genericData }}
            dataQaidSuffix="admin-view-crew-event-guestlist-day-of-show"
          />
        )}
        {availabilityModal.isShowing && (
          <RoutableModal
            hide={availabilityModal.hide}
            initialRouteName="crew-unavailability-dates"
            initialRouteProps={{
              staffMember: dataStaffMemberInfo
                ? {
                    id: dataStaffMemberInfo.staffMember.id,
                    staffMemberAvailabilityDates:
                      dataStaffMemberInfo.staffMember
                        .staffMemberAvailabilityDates,
                  }
                : undefined,
            }}
            dataQaidSuffix="admin-edit-crew-availability-dates"
          />
        )}
        {crewFeedbackModal.isShowing && (
          <RoutableModal
            width="375px"
            hide={crewFeedbackModal.hide}
            initialRouteName="crew-feedback-events"
            initialRouteProps={{
              eventsPendingFeedback:
                dataStaffMemberInfo?.staffMember?.eventsPendingFeedback,
            }}
            dataQaidSuffix="admin-crew-feedback-events"
          />
        )}
      </>
    );
  };

  return (
    <Layout
      scrollDisabled={
        pageState.detailsModal.isShowing ||
        pageState.genericModal.isShowing ||
        availabilityModal.isShowing
      }
    >
      <ListPageTemplate
        pageTitle={intl.formatMessage(
          {
            id: 'admin.crewPortal.title',
          },
          {
            cities: limitToCities,
          }
        )}
        modalsContent={modalsContent}
      >
        <ListingHeader
          pageTitle={intl.formatMessage(
            {
              id: 'admin.crewPortal.title',
            },
            {
              cities: limitToCities,
            }
          )}
          availabilityText={intl.formatMessage({
            id: 'admin.crewPortal.availability',
          })}
          onAvailabilityClick={
            loadingStaffMemberInfo || !dataStaffMemberInfo
              ? undefined
              : toggleAvailabilityModal
          }
          dataQaidPrefix="crew-portal"
        />
        <div>
          <ListingControls
            sortOptions={sortOptions}
            orderBy={pageState.sortState.by}
            orderDirection={pageState.sortState.direction}
            viewMode={pageState.viewModeState}
            totalRecords={
              dataEvents &&
              dataEvents.events &&
              dataEvents.events.metadata.totalRecords
            }
            loading={loadingEvents || loadingCities}
            onSort={pageState.handleSortChange}
            onReset={pageState.handleResetFilters}
            dataQaidPrefix="crew-portal"
          />
          <ListingOneClickFilter
            oneClickFilterState={pageState.oneClickFilterState}
            handleOneClickFilterChange={pageState.handleOneClickFilterChange}
            labelTextKey="admin.crewPortal.filter.myConcertsOnly"
            displayFeedbackButton={true}
            loadingStaffMemberInfo={loadingStaffMemberInfo}
            toggleModal={toggleCrewFeedbackModal}
          />
          <ListingFilter
            filterTitle={intl.formatMessage({
              id: 'admin.crewPortal.filterTitle',
            })}
            textSearchString={pageState.textSearchState}
            handleTextSearchLabelClose={pageState.handleTextSearchLabelClose}
            labelTitleMapping={pageState.filterLabelTitleMapping}
            dropdownOptionsInfoList={filterDropdownOptionsInfoList}
            filterState={pageState.filterState}
            handleRemoveFilter={pageState.handleRemoveFilter}
            handleFilterChange={pageState.handleFilterChange}
          />
        </div>
        <div>
          <CardGrid
            objectData={get(dataEvents, 'events.events', [])}
            renderCardComponent={(event: any, i: number) => (
              <CrewPortalCard
                index={i}
                id={event.id}
                localStartsAt={event.localStartsAt}
                cityTitle={event.city && event.city.title}
                citySlug={event.city && event.city.cachedSlug}
                cancelled={event.cancelled}
                neighborhoodTitle={event.venue?.neighborhood?.title}
                theme={event.theme}
                type={event.type}
                staff={event.staff}
                refetchEvents={refetchEvents}
                venue={event.venue}
                crewLoadInAt={
                  event.crewLoadInAt &&
                  dateFormatter(
                    event.crewLoadInAt,
                    'hoursAndMinutesWithSpacedAMPM',
                    event.city && event.city.timezone
                  ).toLowerCase()
                }
                onShowGuestlistDayOfShow={
                  pageState.toggleGenericModalAndSetGenericData
                }
                numTicketsAvailableForSale={event.numTicketsAvailableForSale}
              />
            )}
            dataQaid="admin-crew-portal-list"
            loading={loadingEvents || loadingCities}
            loadingComponent={
              <LoadingBlocks.Rectangle width="100%" height="420px" />
            }
            hideDividerOnSize="xs"
          />
          <DottedLine />
          <Spacer mb={2} />
        </div>
        <ListingNoResults
          entityName={intl.formatMessage({
            id: 'admin.crewPortal.noResultsEntityName',
          })}
          numResults={get(dataEvents, 'events.events.length', undefined)}
          loading={loadingEvents || loadingCities}
        />
        <ListingFooter
          numTotalRecords={get(
            dataEvents,
            'events.metadata.totalRecords',
            undefined
          )}
          perPage={PER_PAGE}
          currentPage={pageState.page}
          onPageChange={pageState.handlePageChange}
          loading={loadingEvents || loadingCities}
          dataQaidPrefix="events"
        />
      </ListPageTemplate>
    </Layout>
  );
};

const AdminCrewPortalWrapper: React.FC = () => (
  <ListPage config={pageStateConfig} analytics={Analytics}>
    <AdminCrewPortal />
  </ListPage>
);

export default AdminCrewPortalWrapper;
