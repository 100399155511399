import React from 'react';
import styled, { css } from 'styled-components';

import { useTShirtSizeBreakpoint as useBreakpoint } from 'app/shared/utils/useBreakpoint';
import { Logo } from 'app/shared/components/atoms/LogoManualCSS';
import { getLogoWidth } from 'app/admin/utils/logos';

const MainContainer = styled.div`
  display: flex;
  flex-direction: row:
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 150px;
  padding: 20px 0;
  background-color: #111;

  ${({ theme }) => css`
    ${theme.media.xs`
      padding: 0;
      height: 80px;
    `}

    ${theme.media.md`
      height: 150px;
      padding: 20px 0;
      margin: 45px 0 0 0;
    `}
  `}
`;

const StyledLogo = styled(Logo)`
  opacity: 0.5;
`;

const Footer: React.FC = () => {
  const currentBreakpoint = useBreakpoint();
  const isMobile = currentBreakpoint === 'xs' || currentBreakpoint === 'sm';

  return (
    <MainContainer>
      <StyledLogo width={getLogoWidth(isMobile, '60px', '100px')} invert />
    </MainContainer>
  );
};

export default Footer;
