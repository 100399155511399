import { eventAttendeeStatuses } from 'app/admin/utils/guestlist';

export const sortOptions = [
  {
    title: 'Name (A - Z)',
    value: {
      by: 'name',
      direction: 'asc',
    },
  },
  {
    title: 'Name (Z - A)',
    value: {
      by: 'name',
      direction: 'desc',
    },
  },
];

export const statusOptions = (
  counts: object,
  attendeeFlow: string,
  hasCompetition?: boolean
) => {
  let statusKeys = Object.keys(eventAttendeeStatuses);
  if (attendeeFlow == 'buy' && !hasCompetition) {
    statusKeys = statusKeys.filter(
      (s: string) => !['applied', 'guestlisted'].includes(s)
    );
  }
  if (!hasCompetition) {
    statusKeys = statusKeys.filter((s: string) => !['expired'].includes(s));
  }
  return statusKeys.map((key: string) => ({
    title: `${eventAttendeeStatuses[key]} (${counts[key].toString()})`,
    value: key,
  }));
};

export const vipStatusOptions = (countIsVip: number) => [
  {
    title: `VIP (${countIsVip.toString()})`,
    value: 'is_vip',
  },
];

export const appliedPromoCodeStatusOptions = (
  countHasAppliedPromoCode: number
) => [
  {
    title: `Used promo code (${countHasAppliedPromoCode.toString()})`,
    value: 'has_applied_promo_code',
  },
];
