import React from 'react';
import { useIntl } from 'react-intl';

import { get } from 'app/shared/utils/get';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { UpdateNeighborhood } from 'app/admin/graphql/neighborhoods/mutationHooks';
import { DetailsFormMainContainer } from 'app/admin/components/atoms/DetailContent';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

import NeighborhoodBasicInfoForm from './NeighborhoodBasicInfoForm';
import NeighborhoodBasicInfoFormSchema from './NeighborhoodBasicInfoFormSchema';

interface Props {
  contentProps?: any;
  setFormSubmitAction: (func: Function) => void;
  setDisplayConfirmation: (set: boolean) => void;
  setIsSubmitting: (set: boolean) => void;
  navigateTo: (routeData: object) => void;
}

const NeighborhoodBasicInfoEdit: React.FC<Props> = ({
  contentProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  navigateTo,
}) => {
  const intl = useIntl();

  const formInitialValues = {
    title: get(contentProps, 'title', '') || '',
  };

  const updateNeighborhoodAction = UpdateNeighborhood();

  const handleSubmit = useSubmitAction({
    submitAction: updateNeighborhoodAction,
    submitVariables: (values: any) => ({
      neighborhoodId: get(contentProps, 'id', undefined),
      title: values.title,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.neighborhoodBasicInfo.form.successMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.neighborhoodBasicInfo.form.failureMessage',
    }),
    onSuccess: () => {
      navigateTo({
        routeName: 'city-details',
        routeProps: {
          defaultOpenSection: 'neighborhoods',
          id: contentProps.city.id,
        },
      });
    },
  });

  return (
    <ModalContentContainer>
      <DetailsFormMainContainer>
        <DetailsHeader title={get(contentProps, 'title', '')} />
        <GenericForm
          formInitialValues={formInitialValues}
          renderFormComponent={(renderProps: any) => (
            <NeighborhoodBasicInfoForm formikProps={renderProps.formikProps} />
          )}
          onSubmit={handleSubmit}
          formSchema={NeighborhoodBasicInfoFormSchema(intl)}
          setFormSubmitAction={setFormSubmitAction}
          setDisplayConfirmation={setDisplayConfirmation}
          setIsSubmitting={setIsSubmitting}
          dataQaId="neighborhood-edit-form"
        />
      </DetailsFormMainContainer>
    </ModalContentContainer>
  );
};

export default NeighborhoodBasicInfoEdit;
