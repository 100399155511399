import React from 'react';
import { useIntl } from 'react-intl';

import { StaffMemberAvailabilityDate } from 'app/typings/staffMembers';
import { getDatesSubmitVariable } from 'app/shared/utils/datetime';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { UpdateStaffMember } from 'app/admin/graphql/staffMembers/mutationHooks';
import { DetailsFormMainContainer } from 'app/admin/components/atoms/DetailContent';
import CrewUnavailabilityDatesForm from 'app/admin/components/organisms/CrewUnavailabilityDates/CrewUnavailabilityDatesForm';
import CrewUnavailabilityDatesFormSchema from 'app/admin/components/organisms/CrewUnavailabilityDates/CrewUnavailabilityDatesFormSchema';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

interface Props {
  contentProps?: any;
  setFormSubmitAction?: (func: Function) => void;
  setDisplayConfirmation?: (set: boolean) => void;
  setIsSubmitting?: (set: boolean) => void;
  navigateTo: (routeData: object) => void;
  isSubmitting: boolean;
  hide: VoidFunction;
}

const CrewUnavailabilityDates: React.FC<Props> = ({
  contentProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  isSubmitting,
  hide,
}) => {
  const intl = useIntl();

  const formInitialValues = {
    dates: contentProps.staffMember
      ? contentProps.staffMember.staffMemberAvailabilityDates
          .filter(
            (availabilityDate: StaffMemberAvailabilityDate) =>
              !availabilityDate.isAvailable
          )
          .map(
            (availabilityDate: StaffMemberAvailabilityDate) =>
              availabilityDate.date
          )
      : [],
  };

  const updateStaffMemberAction = UpdateStaffMember();

  const handleUpdateStaffMember = useSubmitAction({
    submitAction: updateStaffMemberAction,
    submitVariables: (values: any) => ({
      staffMemberId: contentProps.staffMember.id,
      staffMemberAvailabilityDates: getDatesSubmitVariable(values.dates),
    }),
    successMsg: intl.formatMessage({
      id: 'admin.crewPortal.blackoutDate.successMsg',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.crewPortal.blackoutDate.failureMsg',
    }),
    onSuccess: () => {
      hide();
    },
  });

  return (
    <ModalContentContainer data-qaid="crew-availability-dates-modal">
      <DetailsFormMainContainer>
        <DetailsHeader
          title={intl.formatMessage({
            id: 'admin.crewPortal.availability',
          })}
          subHeader={[
            {
              info: intl.formatMessage({
                id: 'admin.crewPortal.blackoutDate.headerInfo',
              }),
            },
            {
              info: intl.formatMessage({
                id: 'admin.crewPortal.blackoutDate.available',
              }),
            },
            {
              info: intl.formatMessage({
                id: 'admin.crewPortal.blackoutDate.notAvailable',
              }),
            },
          ]}
        />
        <GenericForm
          formInitialValues={formInitialValues}
          renderFormComponent={(renderProps: any) => (
            <CrewUnavailabilityDatesForm
              formikProps={renderProps.formikProps}
              setFormSubmitAction={setFormSubmitAction}
              setDisplayConfirmation={setDisplayConfirmation}
              setIsSubmitting={setIsSubmitting}
              isSubmitting={isSubmitting}
            />
          )}
          onSubmit={handleUpdateStaffMember}
          formSchema={CrewUnavailabilityDatesFormSchema(intl)}
          dataQaId="crew-availability-datepicker-form"
          paddingTop="15"
        />
      </DetailsFormMainContainer>
    </ModalContentContainer>
  );
};

export default CrewUnavailabilityDates;
