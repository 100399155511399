import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import useDisableScroll from 'app/shared/utils/useDisableScroll';
import useKeyDown from 'app/shared/utils/useKeyDown';
import useOutsideClick from 'app/shared/utils/useOutsideClick';
import { MenuItem } from 'app/shared/components/atoms/Menu';
import { Popper } from 'app/shared/components/atoms/PopperManualCSS';
import { Portal } from 'app/shared/components/atoms/Portal';

import Input from './Input';
import Menu from './Menu';
import Options from './Options';

interface TypeaheadProps {
  placeholder: string;
  children?: any;
  handleOptionClick?: (value: string | number, option: any) => void;
  readonly?: boolean;
  disableScrollWhenOpen?: boolean;
  hasError?: boolean;
  name?: string;
  id?: string;
  'data-qaid'?: string;
  options: any[];
  isLoading?: boolean;
  getOptionLabel?: (option: any) => string;
  optionValueKey?: string;
  realTimeSearch?: boolean;
  onSearch?: Function;
  value?: string;
  autoFocus?: boolean;
  isTypeaheadOpen?: boolean | undefined;
}

const Typeahead: React.FC<TypeaheadProps> = ({
  placeholder,
  readonly,
  handleOptionClick,
  disableScrollWhenOpen = false,
  hasError,
  name,
  id,
  'data-qaid': qaId,
  options,
  isLoading,
  optionValueKey,
  getOptionLabel,
  realTimeSearch = false,
  onSearch,
  value,
  autoFocus,
  isTypeaheadOpen = undefined,
}) => {
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(isTypeaheadOpen || false);
  const ref = useRef<any>();
  const [selectValue, setSelectValue] = useState<string | number>(value || '');
  const [filteredOptions, setFilteredOptions] = useState<any>([]);

  useDisableScroll(isOpen, disableScrollWhenOpen);

  useOutsideClick(ref, () => {
    setIsOpen(false);
  });

  useKeyDown('Escape', () => {
    setIsOpen(false);
  });

  const optionClick = (value: string | number, option: any) => {
    const label = getOptionLabel ? getOptionLabel(option) : option.label;
    setSelectValue(label);
    setIsOpen(false);
    handleOptionClick ? handleOptionClick(value, option) : null;
  };

  useEffect(() => {
    if (realTimeSearch) {
      setFilteredOptions(options);
    }
  }, [options, realTimeSearch]);

  useEffect(() => {
    if (value || value == '') {
      setSelectValue(value);
    }
  }, [value]);

  const onSearchChange = (searchString: string) => {
    if (realTimeSearch && onSearch) {
      onSearch(searchString);
    } else {
      if (isLoading || !options) {
        return;
      }
      const filteredOptions = options.filter(o => {
        const label = getOptionLabel ? getOptionLabel(o) : o.label;
        return label.toLowerCase().includes(searchString.toLowerCase());
      });
      setFilteredOptions(filteredOptions);
    }
    setIsOpen(true);
  };

  const renderOptions = () => {
    if (filteredOptions.length) {
      return (
        <Options
          isLoading={!!isLoading}
          options={filteredOptions}
          onOptionClick={optionClick}
          getOptionLabel={getOptionLabel}
          optionValueKey={optionValueKey}
        />
      );
    }

    return (
      <MenuItem
        data-qaid="typeahead-no-results"
        key="typeahead-no-results"
        onClick={() => {}}
      >
        {intl.formatMessage({ id: 'typeahead.noResults' })}
      </MenuItem>
    );
  };

  const refWidth = ref.current && ref.current.clientWidth;

  return (
    <>
      <Input
        id={id}
        readonly={readonly}
        innerRef={ref}
        isOpen={isOpen}
        value={selectValue}
        placeholder={placeholder}
        hasError={hasError}
        name={name}
        data-qaid={qaId}
        onSearchChange={onSearchChange}
        autoFocus={autoFocus}
      />
      {isOpen && (
        <Portal dom={document.body}>
          <Popper
            anchorEl={ref}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Menu isSelect depth={3} width={refWidth}>
              {renderOptions()}
            </Menu>
          </Popper>
        </Portal>
      )}
    </>
  );
};

export default Typeahead;
