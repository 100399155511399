import { useMutation } from 'react-apollo';

import {
  CreateArtistSetupData,
  DeleteArtistSetupData,
  UpdateArtistSetupData,
} from 'app/typings/artistSetups';
import {
  CREATE_ARTIST_SETUP,
  DELETE_ARTIST_SETUP,
  UPDATE_ARTIST_SETUP,
} from 'app/shared/graphql/artistSetups/mutations';

export const CreateArtistSetup = () => {
  return useMutation<CreateArtistSetupData>(CREATE_ARTIST_SETUP)[0];
};

export const UpdateArtistSetup = () => {
  return useMutation<UpdateArtistSetupData>(UPDATE_ARTIST_SETUP)[0];
};

export const DeleteArtistSetup = () => {
  return useMutation<DeleteArtistSetupData>(DELETE_ARTIST_SETUP)[0];
};
