import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Ticket } from 'app/typings/tickets';
import { Checkbox } from 'app/shared/components/atoms/CheckboxManualCSS';
import GenericFormContainer from 'app/shared/components/atoms/GenericFormContainer';
import ShadedOval from 'app/admin/components/atoms/ShadedOval';
import {
  eventAttendeeTicketFullNameOrEmail,
  sortEventAttendeeTickets,
} from 'app/admin/components/organisms/GuestlistTickets';

interface FormProps {
  formikProps: any;
  numTotal: number;
  attendee: any;
  eventAttendeeTickets?: Ticket[];
}

const TicketOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  width: 100%;
  padding: 30px 0px 10px 0px;
`;

const StyledShadedOval = styled(ShadedOval)`
  padding: 15px;
  cursor: pointer;
`;

interface EventAttendeeTicketOptionProps {
  ticketNum: number;
  eventAttendeeTicket: Ticket;
  isTicketSelected: boolean;
  onClickEventAttendeeTicket: Function;
}

const EventAttendeeTicketOption: React.FC<EventAttendeeTicketOptionProps> = ({
  ticketNum,
  onClickEventAttendeeTicket,
  isTicketSelected,
  eventAttendeeTicket,
}) => {
  const intl = useIntl();
  const id = eventAttendeeTicket.id;

  return (
    <StyledShadedOval>
      <Checkbox
        id={id.toString()}
        data-qaid={`select-ticket-${ticketNum}`}
        checked={isTicketSelected}
        name={`select-ticket-${id}`}
        onChange={e => {
          onClickEventAttendeeTicket(id, e.target.checked);
        }}
      >
        {eventAttendeeTicketFullNameOrEmail(eventAttendeeTicket) ||
          intl.formatMessage(
            {
              id: 'admin.guestlistDayOfShow.attendanceEdit.ticket',
            },
            {
              num: ticketNum,
            }
          )}
      </Checkbox>
    </StyledShadedOval>
  );
};

interface EventAttendeeTicketOptionsProps {
  ticketIdsSelected: Number[];
  onClickEventAttendeeTicket: Function;
  eventAttendeeTickets: Ticket[];
}

const EventAttendeeTicketOptions: React.FC<EventAttendeeTicketOptionsProps> = ({
  ticketIdsSelected,
  onClickEventAttendeeTicket,
  eventAttendeeTickets,
}) => (
  <TicketOptionsContainer>
    {eventAttendeeTickets.map((ticket: Ticket, index: number) => (
      <EventAttendeeTicketOption
        ticketNum={index + 1}
        eventAttendeeTicket={ticket}
        isTicketSelected={ticketIdsSelected.includes(ticket.id)}
        onClickEventAttendeeTicket={onClickEventAttendeeTicket}
        key={index}
      />
    ))}
  </TicketOptionsContainer>
);

interface VipTicketOptionProps {
  ticketNum: number;
  isTicketSelected: boolean;
  onClickVipTicket: Function;
}

const VipTicketOption: React.FC<VipTicketOptionProps> = ({
  ticketNum,
  isTicketSelected,
  onClickVipTicket,
}) => {
  const intl = useIntl();

  return (
    <StyledShadedOval>
      <Checkbox
        id={`select-ticket-${ticketNum}`}
        data-qaid={`select-ticket-${ticketNum}`}
        checked={isTicketSelected}
        name={`select-ticket-${ticketNum}`}
        onChange={e => {
          onClickVipTicket(ticketNum, e.target.checked);
        }}
      >
        {intl.formatMessage(
          {
            id: 'admin.guestlistDayOfShow.attendanceEdit.ticket',
          },
          {
            num: ticketNum,
          }
        )}
      </Checkbox>
    </StyledShadedOval>
  );
};

interface VipTicketOptionsProps {
  vipTicketsSelected: object;
  onClickVipTicket: Function;
}

const VipTicketOptions: React.FC<VipTicketOptionsProps> = ({
  vipTicketsSelected,
  onClickVipTicket,
}) => {
  const ticketNums = Object.keys(vipTicketsSelected).map((key: string) =>
    Number(key)
  );

  return (
    <TicketOptionsContainer>
      {ticketNums.map((ticketNum: number) => (
        <VipTicketOption
          ticketNum={ticketNum}
          isTicketSelected={vipTicketsSelected[ticketNum.toString()]}
          onClickVipTicket={onClickVipTicket}
          key={ticketNum}
        />
      ))}
    </TicketOptionsContainer>
  );
};

const areAllEventAttendeeTicketsSelected = (
  ticketIdsSelected: number[],
  attendee: any
) => ticketIdsSelected.length === attendee.eventAttendee.tickets.length;

const areAllVipTicketsSelected = (vipTicketsSelected: object) =>
  !Object.keys(vipTicketsSelected).some(
    (ticketNum: string) => !vipTicketsSelected[ticketNum]
  );

const ticketsSelectedFromNumAttended = (
  numAttended: number,
  numTotal: number
) =>
  [...Array(numTotal).keys()].reduce(
    (obj: object, num: number) => ({
      ...obj,
      [num + 1]: !!(num + 1 <= numAttended),
    }),
    {}
  );

const GuestlistAttendanceEditForm: React.FC<FormProps> = ({
  formikProps,
  numTotal,
  attendee,
}) => {
  const intl = useIntl();

  const { setFieldValue, values } = formikProps;

  const onClickAllTickets = (isAllTicketsSelected: boolean) => {
    if (isAllTicketsSelected) {
      if (attendee.eventAttendee) {
        setFieldValue(
          'eventAttendeeTicketIdsSelected',
          attendee.eventAttendee.tickets.map((ticket: Ticket) => ticket.id)
        );
      } else {
        setFieldValue(
          'vipTicketsSelected',
          ticketsSelectedFromNumAttended(numTotal, numTotal)
        );
      }
    } else {
      if (attendee.eventAttendee) {
        setFieldValue('eventAttendeeTicketIdsSelected', []);
      } else {
        setFieldValue(
          'vipTicketsSelected',
          ticketsSelectedFromNumAttended(0, numTotal)
        );
      }
    }
  };

  const onClickEventAttendeeTicket = (
    ticketId: number,
    isTicketSelected: boolean
  ) => {
    let newTicketIdsSelected = [];
    if (isTicketSelected) {
      newTicketIdsSelected = values.eventAttendeeTicketIdsSelected.concat([
        ticketId,
      ]);
    } else {
      newTicketIdsSelected = values.eventAttendeeTicketIdsSelected.filter(
        (ticketIdSelected: number) => ticketIdSelected != ticketId
      );
    }
    setFieldValue('eventAttendeeTicketIdsSelected', newTicketIdsSelected);
  };

  const onClickVipTicket = (ticketNum: number, isTicketSelected: boolean) => {
    const newVipTicketsSelected = { ...values.vipTicketsSelected };
    newVipTicketsSelected[ticketNum.toString()] = isTicketSelected;
    setFieldValue('vipTicketsSelected', newVipTicketsSelected);
  };

  const eventAttendeeTickets = sortEventAttendeeTickets(
    attendee.eventAttendee?.tickets
  );

  return (
    <GenericFormContainer dataQaId="guestlist-attendance-edit-form">
      <Checkbox
        id="select-all-tickets"
        data-qaid="select-all-tickets"
        checked={
          attendee.eventAttendee
            ? areAllEventAttendeeTicketsSelected(
                values.eventAttendeeTicketIdsSelected,
                attendee
              )
            : areAllVipTicketsSelected(values.vipTicketsSelected)
        }
        name="selectAllTickets"
        onChange={e => {
          onClickAllTickets(e.target.checked);
        }}
      >
        {intl.formatMessage({
          id: 'admin.guestlistDayOfShow.attendanceEdit.checkAll',
        })}
      </Checkbox>
      {attendee.eventAttendee ? (
        <EventAttendeeTicketOptions
          ticketIdsSelected={values.eventAttendeeTicketIdsSelected}
          eventAttendeeTickets={eventAttendeeTickets}
          onClickEventAttendeeTicket={onClickEventAttendeeTicket}
        />
      ) : (
        <VipTicketOptions
          vipTicketsSelected={values.vipTicketsSelected}
          onClickVipTicket={onClickVipTicket}
        />
      )}
    </GenericFormContainer>
  );
};

export default GuestlistAttendanceEditForm;
