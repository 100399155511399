import React from 'react';
import styled, { css } from 'styled-components';

interface Props {
  label: string;
  activeLabel: string;
  handleClick: any;
}

const Tab = styled.li`
  list-style: none;
  display: inline-flex;
  margin-right: 10px;
  margin-bottom: 12px;
`;

interface TabButtonProps {
  isActive?: boolean;
}

const TabButton = styled.button<TabButtonProps>`
  ${({ theme, isActive }) => css`
    background: none;
    border: none;
    font-size: 15px;
    text-transform: capitalize;
    color: ${theme.colors.backToBlack};
    font-weight: 600;
    outline: none;
    white-space: nowrap;
    &:hover {
      cursor: pointer;
    }

    ${isActive && `border-bottom: 3px solid ${theme.colors.green600};`}
  `};
`;

const MenuTab: React.FC<Props> = ({ label, activeLabel, handleClick }) => {
  return (
    <Tab>
      <div>
        <TabButton
          type="button"
          onClick={() => handleClick(label)}
          key={label}
          isActive={label == activeLabel}
        >
          {label.toUpperCase()}
        </TabButton>
      </div>
    </Tab>
  );
};

export default MenuTab;
