import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { City, Neighborhood, Venue } from 'app/typings';
import {
  COMMERCIAL_PARTNERSHIP_EVENT,
  DISCOVERY_EVENT,
  PRIVATE_DISCOVERY_EVENT,
} from 'app/shared/utils/events';
import { fromEntries } from 'app/shared/utils/object';
import usePermission from 'app/shared/utils/usePermission';
import DatePicker from 'app/shared/components/atoms/DatePicker';
import {
  FormGroupContainer,
  FormSectionTitle,
} from 'app/shared/components/atoms/FormContent';
import GenericFormContainer from 'app/shared/components/atoms/GenericFormContainer';
import { AlertIcon } from 'app/shared/components/atoms/IconLibrary';
import { GenericLink } from 'app/shared/components/atoms/LinkManualCSS';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Body } from 'app/shared/components/atoms/TypographyManualCSS';
import FormGroup from 'app/shared/components/molecules/FormGroup';
import RadioGroup from 'app/shared/components/molecules/RadioGroup';
import Select from 'app/shared/components/molecules/SelectManualCSS';
import CitySelectorManualCSS from 'app/shared/components/organisms/CitySelectorManualCSS';
import { CityInfoForLimitingByCity } from 'app/admin/utils/cityPermissions';
import { HasACommercialCuratorGroup } from 'app/admin/utils/curatorGroupPermissions';
import { eventTypeOptions } from 'app/admin/utils/events';
import { labelValueOptionsWithNoneOption } from 'app/admin/utils/optionLists';
import NeighborhoodSelector from 'app/admin/components/molecules/NeighborhoodSelector';
import VenueTypeahead from 'app/admin/components/molecules/VenueTypeahead';
import CampaignFormGroup from 'app/admin/components/organisms/CampaignFormGroup';
import { formatTypeOptions as formatTypeOptionsRaw } from 'app/admin/components/organisms/EventBasicInfoEdit/options';

interface FormProps {
  formikProps: any;
  setFormSubmitAction?: (func: Function) => void;
  setFormSecondarySubmitAction?: (func: Function) => void;
  setDisplayConfirmation?: (set: boolean) => void;
  setIsSubmitting?: (set: boolean) => void;
  setIsSubmittingSecondary?: (set: boolean) => void;
  isSubmitting: boolean;
  isSubmittingSecondary: boolean;
}

const StyledFormSectionTitle = styled(FormSectionTitle)`
  margin-bottom: 5px;
`;

const StyledBody = styled(Body)`
  margin-bottom: 10px;
  white-space: pre-wrap;
`;

const WarningTextWrapper = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
`;

const WarningTextBox = styled(Body)`
  font-weight: bold;
  flex-grow: 1;
`;

const WarningTextIcon = styled.div`
  ${({ theme }) => css`
    flex-grow: 0;
    padding: 4px 16px;
    i::before {
      color: ${theme.colors.royalBlood};
      font-size: 32px;
    }
  `}
`;

const StyledFormGroupContainer = styled(FormGroupContainer)`
  ${({ theme }) => css`
    ${theme.media.xs`
      text-align: center;
      padding: 10px;
    `}

    ${theme.media.sm`
      text-align: left;
      padding: 20px;
    `}
  `}
`;

const SelectVenueContainer = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  max-width: 500px;
`;

const VenuesLink = styled.div`
  padding-top: 7px;
  padding-bottom: 7px;
`;

const VenueMustSelectCity = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
`;

const DisabledVenuesLink = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.macyGrey};
  `}
`;

const WarningText = (props: any) => (
  <WarningTextWrapper>
    <WarningTextIcon>
      <AlertIcon iconSize={28} />
    </WarningTextIcon>
    <WarningTextBox>{props.children}</WarningTextBox>
  </WarningTextWrapper>
);

const EventCreateForm: React.FC<FormProps> = ({
  formikProps,
  setFormSubmitAction,
  setFormSecondarySubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  setIsSubmittingSecondary,
  isSubmitting,
  isSubmittingSecondary,
}) => {
  const intl = useIntl();

  useEffect(() => {
    const handlePrimarySubmit = () => {
      const submitActions = [
        (): any => setIsSubmitting && setIsSubmitting(true),
        formikProps.submitForm,
      ];
      submitActions.forEach((action: Function) => action());
    };

    const handleSecondarySubmit = () => {
      const submitActions = [
        () => setIsSubmittingSecondary && setIsSubmittingSecondary(true),
        formikProps.submitForm,
      ];
      submitActions.forEach((action: Function) => action());
    };

    setFormSubmitAction && setFormSubmitAction(() => handlePrimarySubmit);
    setFormSecondarySubmitAction &&
      setFormSecondarySubmitAction(() => handleSecondarySubmit);
    setDisplayConfirmation && setDisplayConfirmation(formikProps.dirty);
  }, [
    formikProps.isSubmitting,
    formikProps.submitForm,
    formikProps.dirty,
    setFormSubmitAction,
    setDisplayConfirmation,
    setIsSubmitting,
    setFormSecondarySubmitAction,
    setIsSubmittingSecondary,
  ]);

  useEffect(() => {
    if (Object.keys(formikProps.errors).length > 0 && isSubmitting) {
      setIsSubmitting && setIsSubmitting(false);
    }
  }, [formikProps.errors, isSubmitting, setIsSubmitting]);

  useEffect(() => {
    if (Object.keys(formikProps.errors).length > 0 && isSubmittingSecondary) {
      setIsSubmittingSecondary && setIsSubmittingSecondary(false);
    }
  }, [formikProps.errors, isSubmittingSecondary, setIsSubmittingSecondary]);

  const { cityIdsToLimitBy } = CityInfoForLimitingByCity(
    'event.list.accessBasicByCity'
  );
  const hasCreateAllEventTypesPermission = usePermission(
    'event.eventAllTypes.create'
  );

  const hasPartnerAccessPermission = usePermission('partner.access');
  const hasCampaignAccessPermission = usePermission('campaign.access');
  const hasViewVenuesPermission = usePermission('venue.list.view');

  const canCreateAnyTicketingFlow = HasACommercialCuratorGroup();

  const {
    setFieldValue,
    setValues,
    values,
    errors,
    touched,
    dirty,
  } = formikProps;

  const errorMsgForField = (field: string, touched: any, errors: any) =>
    touched[field] && errors[field] ? errors[field] : null;

  const typeOptions = eventTypeOptions(intl);
  const typeOptionsMapping = fromEntries(
    typeOptions.map(opt => [opt.value, opt.title])
  );

  const formatTypeOptions = labelValueOptionsWithNoneOption(
    formatTypeOptionsRaw
  );

  useEffect(() => {
    setFieldValue('isPublishable', true);
    setFieldValue('eventStatus', values.eventStatus);
  }, [values.eventType, values, setFieldValue]);

  return (
    <GenericFormContainer dataQaId="event-create-form">
      {hasCreateAllEventTypesPermission ? (
        <>
          <StyledFormSectionTitle>
            {intl.formatMessage({
              id: 'admin.eventCreate.eventType',
            })}
          </StyledFormSectionTitle>
          <StyledBody>
            {intl.formatMessage({
              id: 'admin.eventCreate.selectOne',
            })}
          </StyledBody>
          <FormGroupContainer>
            <RadioGroup
              name="eventType"
              options={typeOptions.filter(opt =>
                [
                  COMMERCIAL_PARTNERSHIP_EVENT,
                  DISCOVERY_EVENT,
                  PRIVATE_DISCOVERY_EVENT,
                ].includes(opt.value)
              )}
              selectedValue={values.eventType}
              onChange={value => setFieldValue('eventType', value)}
            />
            <Spacer mb={4} />
          </FormGroupContainer>
        </>
      ) : (
        <>
          <StyledFormSectionTitle>
            {intl.formatMessage({
              id: 'admin.eventCreate.eventTypeDiscovery',
            })}
          </StyledFormSectionTitle>
          <Spacer mb={8} />
        </>
      )}
      {hasCreateAllEventTypesPermission && (
        <>
          <FormSectionTitle>
            {intl.formatMessage({
              id: 'admin.eventCreate.formatType',
            })}
          </FormSectionTitle>
          <FormGroupContainer>
            <FormGroup
              data-qaid="event-create-format-type-formgroup"
              label={intl.formatMessage({
                id: 'admin.eventCreate.formatType',
              })}
              errorMsg={errorMsgForField('formatType', touched, errors)}
            >
              <Select
                data-qaid="event-create-format-type-field"
                name="formatType"
                id="formatType"
                getOptionLabel={(opt: any) => opt.label}
                defaultValue={null}
                options={formatTypeOptions}
                placeholder={intl.formatMessage({
                  id: 'admin.eventCreate.formatTypePlaceholder',
                })}
                onChange={(option: any) =>
                  setFieldValue(
                    'formatType',
                    option && option.value ? option.value : null
                  )
                }
                initialWidth="300px"
              />
            </FormGroup>
          </FormGroupContainer>
        </>
      )}
      {canCreateAnyTicketingFlow && (
        <>
          <StyledFormSectionTitle>
            {intl.formatMessage({
              id: 'admin.shared.organizedAs',
            })}
          </StyledFormSectionTitle>
          <StyledBody>
            {intl.formatMessage({
              id: 'admin.eventCreate.selectOne',
            })}
          </StyledBody>
          <StyledFormGroupContainer>
            <FormGroup
              label=""
              data-qaid="event-create-attendee-ticketing-flow"
              errorMsg={errorMsgForField(
                'attendeeTicketingFlow',
                touched,
                errors
              )}
            >
              <RadioGroup
                name="attendeeTicketingFlow"
                options={[
                  {
                    title: intl.formatMessage({
                      id: 'admin.eventCreate.commercialCurator',
                    }),
                    value: 'buy',
                    note: intl.formatMessage({
                      id: 'admin.eventCreate.commercialCuratorNote',
                    }),
                  },
                  {
                    title: intl.formatMessage({
                      id: 'admin.eventCreate.noncommercialCurator',
                    }),
                    value: 'apply',
                    note: intl.formatMessage({
                      id: 'admin.eventCreate.noncommercialCuratorNote',
                    }),
                  },
                ]}
                selectedValue={values.attendeeTicketingFlow}
                onChange={value =>
                  setFieldValue('attendeeTicketingFlow', value)
                }
              />
            </FormGroup>
            <Spacer mb={4} />
          </StyledFormGroupContainer>
        </>
      )}
      <StyledFormSectionTitle>
        {intl.formatMessage({
          id: 'admin.eventCreate.eventDates',
        })}
      </StyledFormSectionTitle>
      <StyledBody>
        {intl.formatMessage({
          id: 'admin.eventCreate.selectAtLeastOne',
        })}
      </StyledBody>
      <StyledFormGroupContainer>
        <FormGroup
          label=""
          data-qaid="event-create-form-dates"
          errorMsg={errorMsgForField('dates', touched, errors)}
        >
          <DatePicker
            values={values.dates}
            onChange={values => setFieldValue('dates', values)}
          />
        </FormGroup>
        <Spacer mb={4} />
      </StyledFormGroupContainer>
      <StyledFormSectionTitle>
        {intl.formatMessage({
          id: 'admin.eventCreate.eventLocation',
        })}
      </StyledFormSectionTitle>
      <StyledBody>
        {intl.formatMessage({
          id: 'admin.eventCreate.chooseACity',
        })}
      </StyledBody>
      <StyledFormGroupContainer>
        <FormGroup
          label="City"
          data-qaid="event-create-city"
          errorMsg={errorMsgForField('city', touched, errors)}
          required={true}
        >
          <CitySelectorManualCSS
            disableTopCities
            disableClosestCities
            initialValue={values.city}
            value={values.city}
            data-qaid="event-create-form-city-selector"
            onChange={(city: City) => {
              setValues({
                ...values,
                city,
                neighborhood: null,
                venueId: null,
              });
            }}
            cityIds={cityIdsToLimitBy}
          />
        </FormGroup>
        <Spacer mt={2} />
        <FormGroup
          label="Neighborhood"
          data-qaid="event-create-city"
          errorMsg={errorMsgForField('neighborhood', touched, errors)}
        >
          <NeighborhoodSelector
            city={values.city}
            initialValue={values.neighborhood}
            value={values.neighborhood}
            data-qaid="event-create-form-neighborhood-selector"
            onChange={(neighborhood: Neighborhood) =>
              setFieldValue('neighborhood', neighborhood)
            }
          />
        </FormGroup>
        <Spacer mb={4} />
      </StyledFormGroupContainer>
      <StyledFormSectionTitle>
        {intl.formatMessage({
          id: 'shared.venue',
        })}
      </StyledFormSectionTitle>
      <StyledFormGroupContainer>
        {values.city ? (
          <>
            <FormGroup
              data-qaid="event-create-venue"
              label={intl.formatMessage({
                id: 'admin.eventCreate.searchByVenue',
              })}
              errorMsg={errorMsgForField('venueId', touched, errors)}
            >
              <SelectVenueContainer>
                <VenueTypeahead
                  citySlugs={[values.city.cachedSlug]}
                  setSelectedVenue={(venue: Venue | undefined) => {
                    setFieldValue('venueId', venue ? venue.id : undefined);
                  }}
                  showVenueAddressAndCity={true}
                />
              </SelectVenueContainer>
            </FormGroup>
            <VenuesLink>
              {values.venueId && hasViewVenuesPermission ? (
                <GenericLink url={`/admin/venues?venue_id=${values.venueId}`}>
                  {intl.formatMessage({
                    id: 'admin.eventCreate.viewVenueProfile',
                  })}
                </GenericLink>
              ) : (
                <DisabledVenuesLink>
                  {intl.formatMessage({
                    id: 'admin.eventCreate.viewVenueProfile',
                  })}
                </DisabledVenuesLink>
              )}
            </VenuesLink>
          </>
        ) : (
          <VenueMustSelectCity>
            {intl.formatMessage({
              id: 'admin.eventCreate.mustSelectCity',
            })}
          </VenueMustSelectCity>
        )}
      </StyledFormGroupContainer>
      {(values.eventType === COMMERCIAL_PARTNERSHIP_EVENT ||
        values.eventType === PRIVATE_DISCOVERY_EVENT) && (
        <>
          <FormSectionTitle>
            {intl.formatMessage({
              id: 'admin.eventCreate.partnerCampaign',
            })}
          </FormSectionTitle>
          <CampaignFormGroup
            formikProps={formikProps}
            hasPartnerAccessPermission={hasPartnerAccessPermission}
            hasCampaignAccessPermission={hasCampaignAccessPermission}
            required={false}
            dataQaidPrefix="event-create-form"
          />
        </>
      )}
      <StyledFormSectionTitle>
        {intl.formatMessage({
          id: 'shared.status',
        })}
      </StyledFormSectionTitle>
      <StyledBody>
        {intl.formatMessage({
          id: 'admin.eventCreate.eventStatusMessage',
        })}
      </StyledBody>
      <StyledFormGroupContainer>
        <FormGroup
          label=""
          data-qaid="event-create-status"
          errorMsg={errorMsgForField('eventStatus', touched, errors)}
        >
          <RadioGroup
            name="eventStatus"
            options={[
              {
                title: intl.formatMessage({
                  id: 'admin.eventCreate.published',
                }),
                value: 'published',
              },
              {
                title: intl.formatMessage({
                  id: 'shared.draft',
                }),
                value: 'draft',
              },
            ]}
            selectedValue={values.eventStatus}
            onChange={value => setFieldValue('eventStatus', value)}
            disabled={!values.isPublishable}
          />
        </FormGroup>
        <Spacer mb={4} />
      </StyledFormGroupContainer>
      <StyledFormSectionTitle>
        {intl.formatMessage({
          id: 'admin.eventCreate.eventPurchasableTitle',
        })}
      </StyledFormSectionTitle>
      <StyledBody>
        {intl.formatMessage({
          id: 'admin.eventCreate.eventPurchasableMessage',
        })}
      </StyledBody>
      <StyledFormGroupContainer>
        <FormGroup
          label=""
          data-qaid="event-create-purchasable"
          errorMsg={errorMsgForField('eventPurchasable', touched, errors)}
        >
          <RadioGroup
            name="eventPurchasable"
            options={[
              {
                title: intl.formatMessage({
                  id: 'shared.active',
                }),
                value: 'active',
              },
              {
                title: intl.formatMessage({
                  id: 'admin.eventCreate.purchasablePaused',
                }),
                value: 'paused',
              },
            ]}
            selectedValue={values.eventPurchasable}
            onChange={value => setFieldValue('eventPurchasable', value)}
            disabled={!values.isPublishable}
          />
        </FormGroup>
        <Spacer mb={4} />
      </StyledFormGroupContainer>
      {dirty && Object.keys(errors).length === 0 && (
        <WarningText>
          {intl.formatMessage(
            {
              id: 'admin.eventCreate.warningMessage',
            },
            {
              eventCount: values.dates.length,
              eventType: typeOptionsMapping[values.eventType],
              eventString:
                values.dates.length > 1
                  ? intl.formatMessage({
                      id: 'admin.eventCreate.concerts',
                    })
                  : intl.formatMessage({
                      id: 'admin.eventCreate.concert',
                    }),
              neighborhoodCity: values.neighborhood
                ? `${intl.formatMessage(
                    { id: 'admin.eventCreate.neighborhoodOf' },
                    {
                      neighborhood:
                        values.neighborhood && values.neighborhood.title,
                    }
                  )} ${values.city && values.city.title}`
                : values.city && values.city.title,
              eventStatus:
                values.eventStatus === 'published'
                  ? intl
                      .formatMessage({
                        id: 'admin.eventCreate.published',
                      })
                      .toLowerCase()
                  : intl
                      .formatMessage({
                        id: 'admin.eventCreate.drafts',
                      })
                      .toLowerCase(),
            }
          )}
        </WarningText>
      )}
    </GenericFormContainer>
  );
};

export default EventCreateForm;
