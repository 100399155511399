import React, { useContext, useEffect } from 'react';

import { Artist } from 'app/typings/artists';
import { Event } from 'app/typings/events';
import { scrollToTop } from 'app/shared/utils/modal';
import { ListPage, ListPageContext } from 'app/shared/context/ListPage';
import GenericFormContainer from 'app/shared/components/atoms/GenericFormContainer';

import {
  getArtistIdsSubmitVariable,
  getEventPositionsSubmitVariable,
  InvalidEventInfo,
} from './index';
import {
  getHasValidationErrorOverMaxOfferSize,
  getMaxNumValues,
} from './maxOfferSizeValidation';
import OfferCreateFormStep1 from './OfferCreateFormStep1';
import OfferCreateFormStep2 from './OfferCreateFormStep2';
import OfferCreateFormStep3 from './OfferCreateFormStep3';

interface FormProps {
  formikProps: any;
  modalRef: React.RefObject<HTMLDivElement>;
  setButtonConfigs?: (buttonConfigs: any) => void;
  setGoBackAction?: (func: Function) => void;
  setGoForwardAction?: (func: Function) => void;
  setFormSubmitAction?: (func: Function) => void;
  setDisplayConfirmation?: (set: boolean) => void;
  setIsSubmitting?: (set: boolean) => void;
  invalidEventInfo: InvalidEventInfo;
  setInvalidEventInfo: (info: InvalidEventInfo) => void;
  defaultInvalidEventInfo: InvalidEventInfo;
  hasValidationErrorOverMaxOfferSize: boolean;
  setHasValidationErrorOverMaxOfferSize: (value: boolean) => void;
  hideStep1?: boolean;
  formStep: string;
  setFormStep?: (step: string) => void;
  setSubtitle: (subtitle: string) => void;
  creatingOfferForSingleEvent: boolean;
}

const pageStateConfig = {
  filterNames: [
    'neighborhood',
    'open_positions',
    'theme',
    'num_tickets_available_for_sale',
    'max_pa_inputs',
  ],
  defaultSort: { by: 'start', direction: 'asc' },
  useDefaultSort: true,
};

const anyArtistIdsAndEventPositionsToSubmit = (
  artists: Artist[],
  events: Event[],
  positions: number[],
  invalidEventInfo: InvalidEventInfo
) => {
  const eventPositions = getEventPositionsSubmitVariable(
    events,
    positions,
    invalidEventInfo
  );
  const artistIds = getArtistIdsSubmitVariable(
    artists,
    events,
    invalidEventInfo
  );
  return eventPositions.length > 0 && artistIds.length > 0;
};

const OfferCreateForm: React.FC<FormProps> = ({
  formikProps,
  modalRef,
  setButtonConfigs,
  setGoBackAction,
  setGoForwardAction,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  invalidEventInfo,
  setInvalidEventInfo,
  defaultInvalidEventInfo,
  hasValidationErrorOverMaxOfferSize,
  setHasValidationErrorOverMaxOfferSize,
  formStep,
  hideStep1,
  setFormStep,
  setSubtitle,
  creatingOfferForSingleEvent,
}) => {
  const pageState = useContext(ListPageContext);

  const { setFieldValue, touched, errors, values } = formikProps;

  useEffect(() => {
    const goToStep = (formStep: string) => {
      setFormStep && setFormStep(formStep);
      // This is needed in order to scroll content back to top when navigating back or forward
      scrollToTop(modalRef);
    };
    const getBackButtonTextKey = () => {
      switch (formStep) {
        case 'step1':
          return 'form.cancel';
        case 'step2':
          return 'form.back';
        case 'step3':
          return 'form.back';
        default:
          return 'form.back';
      }
    };
    const getBackButtonDisplayed = () => {
      return !(formStep === 'step2' && hideStep1);
    };
    const getForwardButtonDisplayed = () => {
      switch (formStep) {
        case 'step1':
          return true;
        case 'step2':
          return true;
        case 'step3':
          return false;
        default:
          return true;
      }
    };
    const getForwardButtonDisabled = () => {
      switch (formStep) {
        case 'step1':
          return !values.name || !values.city || values.artists.length == 0;
        case 'step2':
          return values.events.length == 0 || values.positions.length == 0;
        case 'step3':
          return false;
        default:
          return false;
      }
    };
    const getSubmitButtonTextKey = () => 'admin.offerCreate.sendOffer';
    const getSubmitButtonDisplayed = () => {
      switch (formStep) {
        case 'step1':
          return false;
        case 'step2':
          return false;
        case 'step3':
          return true;
        default:
          return false;
      }
    };
    const getSubmitButtonDisabled = () => {
      switch (formStep) {
        case 'step1':
          return false;
        case 'step2':
          return false;
        case 'step3':
          return (
            !anyArtistIdsAndEventPositionsToSubmit(
              values.artists,
              values.events,
              values.positions,
              invalidEventInfo
            ) || hasValidationErrorOverMaxOfferSize
          );
        default:
          return false;
      }
    };

    const getButtonConfigs = () => ({
      back: {
        textKey: getBackButtonTextKey(),
        displayed: getBackButtonDisplayed(),
      },
      forward: {
        displayed: getForwardButtonDisplayed(),
        disabled: getForwardButtonDisabled(),
      },
      submit: {
        textKey: getSubmitButtonTextKey(),
        displayed: getSubmitButtonDisplayed(),
        disabled: getSubmitButtonDisabled(),
      },
    });
    const getGoBackAction: Function = () => () => {
      if (formStep == 'step1') {
        return undefined;
      } else {
        return () => {
          switch (formStep) {
            case 'step2':
              return goToStep('step1');
            case 'step3':
              setInvalidEventInfo(defaultInvalidEventInfo);
              return goToStep(creatingOfferForSingleEvent ? 'step1' : 'step2');
            default:
              return goToStep('step1');
          }
        };
      }
    };

    const getGoForwardAction: Function = () => () => () => {
      switch (formStep) {
        case 'step1':
          return goToStep(creatingOfferForSingleEvent ? 'step3' : 'step2');
        case 'step2':
          return goToStep('step3');
        default:
          return goToStep('step3');
      }
    };
    setButtonConfigs && setButtonConfigs(getButtonConfigs());
    setGoBackAction && setGoBackAction(getGoBackAction());
    setGoForwardAction && setGoForwardAction(getGoForwardAction());
    setFormSubmitAction && setFormSubmitAction(() => formikProps.submitForm);
    setDisplayConfirmation &&
      setDisplayConfirmation(formikProps.dirty || hideStep1);
    setIsSubmitting && setIsSubmitting(formikProps.isSubmitting);
    setHasValidationErrorOverMaxOfferSize(
      getHasValidationErrorOverMaxOfferSize(
        values.artists,
        values.events,
        values.positions,
        invalidEventInfo
      )
    );
  }, [
    formikProps.isSubmitting,
    formikProps.submitForm,
    formikProps.dirty,
    modalRef,
    setButtonConfigs,
    setGoBackAction,
    setGoForwardAction,
    setFormSubmitAction,
    setDisplayConfirmation,
    setIsSubmitting,
    formStep,
    setFormStep,
    values.name,
    values.city,
    values.artists,
    values.recommendedArtists,
    values.events,
    values.positions,
    invalidEventInfo,
    setInvalidEventInfo,
    defaultInvalidEventInfo,
    creatingOfferForSingleEvent,
    hasValidationErrorOverMaxOfferSize,
    setHasValidationErrorOverMaxOfferSize,
    hideStep1,
  ]);

  const { maxNumEvents, maxNumArtists } = getMaxNumValues(values.positions);

  setSubtitle(
    values.city && values.name ? `${values.city.title} | ${values.name}` : ''
  );

  return (
    <GenericFormContainer dataQaId="offer-create-form">
      {formStep == 'step1' && (
        <OfferCreateFormStep1
          setFieldValue={setFieldValue}
          touched={touched}
          errors={errors}
          values={values}
          creatingOfferForSingleEvent={creatingOfferForSingleEvent}
        />
      )}
      {formStep == 'step2' && (
        <OfferCreateFormStep2
          setFieldValue={setFieldValue}
          touched={touched}
          errors={errors}
          values={values}
          pageState={pageState}
        />
      )}
      {formStep == 'step3' && (
        <OfferCreateFormStep3
          setFieldValue={setFieldValue}
          touched={touched}
          errors={errors}
          values={values}
          invalidEventInfo={invalidEventInfo}
          hasValidationErrorOverMaxOfferSize={
            hasValidationErrorOverMaxOfferSize
          }
          maxNumEvents={maxNumEvents}
          maxNumArtists={maxNumArtists}
        />
      )}
    </GenericFormContainer>
  );
};

const OfferCreateFormWrapper: React.FC<FormProps> = ({
  formikProps,
  modalRef,
  setButtonConfigs,
  setGoBackAction,
  setGoForwardAction,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  invalidEventInfo,
  setInvalidEventInfo,
  defaultInvalidEventInfo,
  hasValidationErrorOverMaxOfferSize,
  setHasValidationErrorOverMaxOfferSize,
  hideStep1,
  formStep,
  setFormStep,
  setSubtitle,
  creatingOfferForSingleEvent,
}) => (
  <ListPage config={pageStateConfig}>
    <OfferCreateForm
      formikProps={formikProps}
      modalRef={modalRef}
      setButtonConfigs={setButtonConfigs}
      setGoBackAction={setGoBackAction}
      setGoForwardAction={setGoForwardAction}
      setFormSubmitAction={setFormSubmitAction}
      setDisplayConfirmation={setDisplayConfirmation}
      setIsSubmitting={setIsSubmitting}
      invalidEventInfo={invalidEventInfo}
      setInvalidEventInfo={setInvalidEventInfo}
      defaultInvalidEventInfo={defaultInvalidEventInfo}
      hasValidationErrorOverMaxOfferSize={hasValidationErrorOverMaxOfferSize}
      setHasValidationErrorOverMaxOfferSize={
        setHasValidationErrorOverMaxOfferSize
      }
      hideStep1={hideStep1}
      formStep={formStep}
      setFormStep={setFormStep}
      setSubtitle={setSubtitle}
      creatingOfferForSingleEvent={creatingOfferForSingleEvent}
    />
  </ListPage>
);

export default OfferCreateFormWrapper;
