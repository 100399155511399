import React, { useEffect } from 'react';

import { useIsTShirtSizeBreakpointSize as useIsBreakpointSize } from 'app/shared/utils/useBreakpoint';
import { useNavbarContext } from 'app/shared/context';
import {
  IconButton,
  StyledStreamlineIcon,
} from 'app/shared/components/molecules/IconButtonManualCSS';
import { ReactComponent as HotDogMenuIcon } from 'icons/streamline-bold/hotDogMenu.svg';

interface MenuButtonProps {
  display?: string;
  displayOnTablet?: boolean;
}

export const MenuButton: React.FC<MenuButtonProps> = ({
  display,
  displayOnTablet,
}) => {
  const { isMobile, isTablet } = useIsBreakpointSize();
  const { isMobileMenuOpen, setIsMobileMenuOpen } = useNavbarContext();

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isMobileMenuOpen]);

  return (
    <>
      {(isMobile || (isTablet && displayOnTablet)) && (
        <IconButton
          onClick={() => setIsMobileMenuOpen(true)}
          data-qaid="menu-button"
          display={display}
        >
          <StyledStreamlineIcon
            icon={HotDogMenuIcon}
            height={20}
            width={24}
            hoverStroke="white"
          />
        </IconButton>
      )}
    </>
  );
};
