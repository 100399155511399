import React, { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { get } from 'app/shared/utils/get';
import { GetCitiesLite } from 'app/shared/graphql/cities/queryHooks';
import { ListPage, ListPageContext } from 'app/shared/context/ListPage';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import { LoadingBlocks } from 'app/shared/components/atoms/LoadingBlocks';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import RoutableModal from 'app/shared/components/molecules/RoutableModal';
import { GlobalError } from 'app/shared/components/pages/Status';
import {
  getCityOptions,
  getFilterNamesAndOptionsForTitleMapping,
} from 'app/admin/utils/optionLists';
import { GetUsers } from 'app/admin/graphql/users/queryHooks';
import ListingFooter from 'app/admin/components/molecules/ListingFooter';
import ListingNoResults from 'app/admin/components/molecules/ListingNoResults';
import CardGrid, { CardType } from 'app/admin/components/organisms/CardGrid';
import ListingControls from 'app/admin/components/organisms/ListingControls';
import ListingFilter from 'app/admin/components/organisms/ListingFilter';
import ListingHeader from 'app/admin/components/organisms/ListingHeader';
import UserCard from 'app/admin/components/organisms/UserCard';
import ListPageTemplate from 'app/admin/components/templates/ListPage';
import Layout from 'app/admin/layouts/ListPage';

import {
  associatedWithArtistsOptions,
  loginActivityOptions,
  sortOptions,
} from './options';
import Analytics from './tracking';

const pageStateConfig = {
  filterNames: ['city', 'associated_with_artists', 'login_activity'],
  defaultSort: { by: 'user_name', direction: 'asc' },
  textSearchParamName: 'user_search',
  idParamName: 'user_id',
};

const AdminUsers: React.FC = () => {
  const intl = useIntl();
  const pageState = useContext(ListPageContext);
  const PER_PAGE = 18;

  const {
    loading: loadingCities,
    error: errorCities,
    data: dataCities,
  } = GetCitiesLite({
    activeStatus: 'all',
  });

  const cityOptions = getCityOptions(dataCities);

  const filterDropdownOptionsInfoList = [
    {
      filterName: 'city',
      dropdownParams: {
        searchBar: true,
        title: intl.formatMessage({
          id: 'admin.users.filter.city',
        }),
        groupBy: 'country',
        options: cityOptions,
      },
    },
    {
      filterName: 'associated_with_artists',
      dropdownParams: {
        title: intl.formatMessage({
          id: 'admin.users.filter.associatedWithArtists',
        }),
        options: associatedWithArtistsOptions.associatedWithArtists,
      },
    },
    {
      filterName: 'login_activity',
      dropdownParams: {
        title: intl.formatMessage({
          id: 'admin.users.filter.loginActivity',
        }),
        options: loginActivityOptions.loginActivity,
      },
    },
  ];

  const { loading, error, data } = GetUsers({
    city: pageState.filterListVariable('city'),
    associatedWithArtists:
      pageState.filterState.associated_with_artists[0] ===
      'associated_with_artists'
        ? true
        : undefined,
    loginActivity: pageState.filterListVariable('login_activity'),
    userSearch: pageState.textSearchState,
    orderBy: pageState.sortState.by,
    orderDirection: pageState.sortState.direction,
    page: pageState.page,
    perPage: PER_PAGE,
  });

  useEffect(() => {
    pageState.updateScrollPositionOnPage();
  }, [pageState.detailsModal.isShowing]);

  useEffect(() => {
    pageState.updateDetailsModal();
  }, []);

  useEffect(() => {
    pageState.setupFilterLabelTitleMapping(
      getFilterNamesAndOptionsForTitleMapping(filterDropdownOptionsInfoList)
    );
  }, [dataCities]);

  useEffect(() => {
    pageState.updatePageUrl();
  }, [
    pageState.page,
    pageState.sortState,
    pageState.filterState,
    pageState.textSearchState,
    pageState.detailData,
    pageState.detailsModal.isShowing,
    pageState.viewModeState,
  ]);

  useEffect(() => {
    Analytics.pushDataLayer();
  }, [pageState.filterState, pageState.textSearchState]);

  if (
    (!loading && !data) ||
    error ||
    (!loadingCities && !dataCities) ||
    errorCities
  ) {
    return <GlobalError />;
  }

  const modalsContent = () => (
    <>
      {pageState.detailsModal.isShowing && (
        <RoutableModal
          hide={pageState.detailsModal.hide}
          initialRouteProps={pageState.detailData}
          initialRouteName="user-details"
          dataQaidSuffix="admin-edit-user-details"
        />
      )}
    </>
  );

  return (
    <Layout scrollDisabled={pageState.detailsModal.isShowing}>
      <ListPageTemplate
        pageTitle={intl.formatMessage({
          id: 'admin.users.pageTitle',
        })}
        modalsContent={modalsContent}
      >
        <ListingHeader
          pageTitle={intl.formatMessage({
            id: 'admin.users.pageTitle',
          })}
          searchPlaceholder={intl.formatMessage({
            id: 'admin.users.textSearch.placeholder',
          })}
          searchValue={pageState.textSearchState}
          onSearch={pageState.handleTextSearch}
          dataQaidPrefix="user"
        />
        <div>
          <ListingControls
            sortOptions={sortOptions}
            orderBy={pageState.sortState.by}
            orderDirection={pageState.sortState.direction}
            totalRecords={data && data.users.metadata.totalRecords}
            loading={loading}
            onSort={pageState.handleSortChange}
            onReset={pageState.handleResetFilters}
            dataQaidPrefix="user"
          />
          <ListingFilter
            filterTitle={intl.formatMessage({
              id: 'admin.users.filterTitle',
            })}
            textSearchString={pageState.textSearchState}
            handleTextSearchLabelClose={pageState.handleTextSearchLabelClose}
            labelTitleMapping={pageState.filterLabelTitleMapping}
            dropdownOptionsInfoList={filterDropdownOptionsInfoList}
            filterState={pageState.filterState}
            handleRemoveFilter={pageState.handleRemoveFilter}
            handleFilterChange={pageState.handleFilterChange}
          />
        </div>

        <div>
          <CardGrid
            objectData={get(data, 'users.users', [])}
            renderCardComponent={(user: CardType, i: number) => (
              // @ts-ignore
              <UserCard
                index={i}
                onShowDetails={pageState.toggleDetailsModalAndSetDetailData}
                {...user}
              />
            )}
            dataQaid="admin-users-list"
            loading={loading}
            loadingComponent={
              <LoadingBlocks.Rectangle width="100%" height="420px" />
            }
            hideDividerOnSize="xs"
          />
          <DottedLine />
          <Spacer mb={2} />
        </div>

        <ListingNoResults
          entityName={intl.formatMessage({
            id: 'admin.users.noResultsEntityName',
          })}
          numResults={get(data, 'users.users.length', undefined)}
          loading={loading}
        />

        <ListingFooter
          numTotalRecords={get(data, 'users.metadata.totalRecords', undefined)}
          perPage={PER_PAGE}
          currentPage={pageState.page}
          onPageChange={pageState.handlePageChange}
          loading={loading}
          dataQaidPrefix="users"
        />
      </ListPageTemplate>
    </Layout>
  );
};

const AdminUsersWrapper: React.FC = () => (
  <ListPage config={pageStateConfig} analytics={Analytics}>
    <AdminUsers />
  </ListPage>
);

export default AdminUsersWrapper;
