export const eventRoleLabels = (intl: any) => {
  return [
    {
      role: 'crew_lead',
      label: intl.formatMessage({
        id: 'admin.crewPortal.crewRole.lead',
      }),
      shortLabel: intl.formatMessage({
        id: 'admin.crewPortal.crewRole.lead',
      }),
      position: 1,
      group: 1,
    },
    {
      role: 'crew_emcee',
      label: intl.formatMessage({
        id: 'admin.crewPortal.crewRole.emcee',
      }),
      shortLabel: intl.formatMessage({
        id: 'admin.crewPortal.crewRole.emcee',
      }),
      position: 2,
      group: 1,
    },
    {
      role: 'day_of_show_support_1',
      label: intl.formatMessage({
        id: 'admin.crewPortal.crewRole.support',
      }),
      shortLabel: intl.formatMessage({
        id: 'admin.crewPortal.crewRole.support',
      }),
      position: 3,
      group: 1,
    },
    {
      role: 'day_of_show_support_2',
      label: intl.formatMessage({
        id: 'admin.crewPortal.crewRole.support',
      }),
      shortLabel: intl.formatMessage({
        id: 'admin.crewPortal.crewRole.support',
      }),
      position: 4,
      group: 2,
    },
    {
      role: 'day_of_show_support_3',
      label: intl.formatMessage({
        id: 'admin.crewPortal.crewRole.support',
      }),
      position: 5,
      group: 2,
    },
    {
      role: 'day_of_show_support_4',
      label: intl.formatMessage({
        id: 'admin.crewPortal.crewRole.support',
      }),
      position: 6,
      group: 2,
    },
    {
      role: 'audio',
      label: intl.formatMessage({
        id: 'admin.crewPortal.crewRole.audioEngineer',
      }),
      shortLabel: intl.formatMessage({
        id: 'admin.crewPortal.crewRole.audio',
      }),
      position: 7,
      group: 3,
    },
    {
      role: 'photo',
      label: intl.formatMessage({
        id: 'admin.crewPortal.crewRole.photographer',
      }),
      shortLabel: intl.formatMessage({
        id: 'admin.crewPortal.crewRole.photo',
      }),
      position: 8,
      group: 3,
    },
    {
      role: 'gear_runner',
      label: intl.formatMessage({
        id: 'admin.crewPortal.crewRole.gearRunner',
      }),
      shortLabel: intl.formatMessage({
        id: 'admin.crewPortal.crewRole.gear',
      }),
      position: 9,
      group: 3,
    },
    {
      role: 'video_1',
      label: intl.formatMessage({
        id: 'admin.crewPortal.crewRole.videographer',
      }),
      shortLabel: intl.formatMessage({
        id: 'admin.crewPortal.crewRole.video',
      }),
      position: 10,
      group: 4,
    },
    {
      role: 'video_2',
      label: intl.formatMessage({
        id: 'admin.crewPortal.crewRole.videographer',
      }),
      position: 11,
      group: 4,
    },
    {
      role: 'video_3',
      label: intl.formatMessage({
        id: 'admin.crewPortal.crewRole.videographer',
      }),
      position: 12,
      group: 4,
    },
  ];
};
