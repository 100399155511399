import * as Yup from 'yup';

import { validateInvalidDomainForFromEmail } from 'app/admin/utils/emails';
import { validDomainsForFromEmail } from 'app/admin/utils/events';

const cityEmailsEditFormSchema = (intl: any) =>
  Yup.object().shape({
    fromEmail: Yup.string()
      .email(
        intl.formatMessage(
          {
            id: 'form.fieldMustBeValidEmail',
          },
          {
            field: 'Email',
          }
        )
      )
      .test(
        'hasValidDomainForFromEmail',
        intl.formatMessage(
          {
            id: 'admin.emailSection.form.fromEmailError',
          },
          {
            domain1: validDomainsForFromEmail[0],
            domain2: validDomainsForFromEmail[1],
          }
        ),
        function(value) {
          return value ? validateInvalidDomainForFromEmail(value) : true;
        }
      ),
    artistApplicationNotificationEmails: Yup.object().shape({
      artistApplicationNotificationEmail1: Yup.string()
        .nullable()
        .email(
          intl.formatMessage(
            {
              id: 'form.fieldMustBeValidEmail',
            },
            {
              field: 'Email',
            }
          )
        ),
      artistApplicationNotificationEmail2: Yup.string()
        .nullable()
        .email(
          intl.formatMessage(
            {
              id: 'form.fieldMustBeValidEmail',
            },
            {
              field: 'Email',
            }
          )
        ),
      artistApplicationNotificationEmail3: Yup.string()
        .nullable()
        .email(
          intl.formatMessage(
            {
              id: 'form.fieldMustBeValidEmail',
            },
            {
              field: 'Email',
            }
          )
        ),
      artistApplicationNotificationEmail4: Yup.string()
        .nullable()
        .email(
          intl.formatMessage(
            {
              id: 'form.fieldMustBeValidEmail',
            },
            {
              field: 'Email',
            }
          )
        ),
      artistApplicationNotificationEmail5: Yup.string()
        .nullable()
        .email(
          intl.formatMessage(
            {
              id: 'form.fieldMustBeValidEmail',
            },
            {
              field: 'Email',
            }
          )
        ),
      artistApplicationNotificationEmail6: Yup.string()
        .nullable()
        .email(
          intl.formatMessage(
            {
              id: 'form.fieldMustBeValidEmail',
            },
            {
              field: 'Email',
            }
          )
        ),
    }),
  });

export default cityEmailsEditFormSchema;
