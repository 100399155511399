export const openPositionOptions = [
  {
    title: 'Slot 1',
    value: '1',
  },
  {
    title: 'Slot 2',
    value: '2',
  },
  {
    title: 'Slot 3',
    value: '3',
  },
];

export const ticketOptions = [
  {
    title: '0-50',
    value: '0-50',
  },
  {
    title: '51-100',
    value: '51-100',
  },
  {
    title: '101-150',
    value: '101-150',
  },
  {
    title: '151-200',
    value: '151-200',
  },
  {
    title: '200+',
    value: '200+',
  },
];
