import React from 'react';

import { Container } from 'app/shared/components/atoms/Container';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import { LoadingBlocks } from 'app/shared/components/atoms/LoadingBlocks';
import { Logo } from 'app/shared/components/atoms/LogoManualCSS';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import LoadingLayout from 'app/shared/layouts/Loading';

export default () => (
  <LoadingLayout data-qaid="loading-blocks">
    <Container>
      <Spacer mt={10} />
      <Logo width="68px" />
      <Spacer mt={8} />
      <Grid>
        <Col sm={12}>
          <LoadingBlocks.Rectangle height="400px" width="100%" mb={1} />
          <Spacer mb={10} />
        </Col>
        <Col sm={4}>
          <LoadingBlocks.Box width="150px" mb={10} />
          <LoadingBlocks.Rectangle width="99%" height="20px" mb={1} />
          <LoadingBlocks.Rectangle width="94%" height="20px" mb={1} />
          <LoadingBlocks.Rectangle width="97%" height="20px" mb={1} />
        </Col>
        <Col sm={4}>
          <LoadingBlocks.Box width="150px" mb={10} />
          <LoadingBlocks.Rectangle width="99%" height="20px" mb={1} />
          <LoadingBlocks.Rectangle width="94%" height="20px" mb={1} />
          <LoadingBlocks.Rectangle width="97%" height="20px" mb={1} />
        </Col>
        <Col sm={4}>
          <LoadingBlocks.Box width="150px" mb={10} />
          <LoadingBlocks.Rectangle width="99%" height="20px" mb={1} />
          <LoadingBlocks.Rectangle width="94%" height="20px" mb={1} />
          <LoadingBlocks.Rectangle width="97%" height="20px" mb={1} />
        </Col>
        <Spacer mb={10} />
      </Grid>
      <Grid>
        <Col xs={12} sm={6} rowSpan={2}>
          <LoadingBlocks.Rectangle width="100%" height="425px" mb={1} />
        </Col>
        <Col xs={12} sm={6}>
          <LoadingBlocks.Rectangle width="100%" height="200px" mb={1} />
        </Col>
        <Col xs={6} sm={3}>
          <LoadingBlocks.Rectangle width="100%" height="200px" mb={1} />
        </Col>
        <Col xs={6} sm={3}>
          <LoadingBlocks.Rectangle width="100%" height="200px" mb={1} />
        </Col>
        <Spacer mb={10} />
      </Grid>
    </Container>
  </LoadingLayout>
);
