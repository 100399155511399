import React from 'react';
import { useIntl } from 'react-intl';

import { Artist } from 'app/typings';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import { Spacer } from 'app/shared/components/atoms/Spacer';

import AudienceReactionArtist from './AudienceReactionArtist';
import CrewEventSurveyCard, {
  CrewEventSurveyCardTitle,
} from './CrewEventSurveyCard';

interface Props {
  artists?: Artist[];
  onChangeOverallFeeling: any;
  onChangeFeedbackString: any;
  selectedValues: any;
  feedbackPhrases: any;
}

const AudienceReactionArtists: React.FC<Props> = ({
  artists,
  onChangeOverallFeeling,
  onChangeFeedbackString,
  selectedValues,
  feedbackPhrases,
}) => {
  const intl = useIntl();

  return (
    <CrewEventSurveyCard>
      <CrewEventSurveyCardTitle>
        {intl.formatMessage({ id: 'admin.crewPortal.crewFeedback.toArtist' })}
      </CrewEventSurveyCardTitle>
      {artists &&
        artists.map((artist, i) => (
          <React.Fragment key={artist.id}>
            <AudienceReactionArtist
              artist={artist}
              selectedValueOverallFeeling={
                selectedValues[artist.id] &&
                selectedValues[artist.id].overallFeeling
              }
              selectedValueFeedbackString={
                selectedValues[artist.id] &&
                selectedValues[artist.id].feedbackString
              }
              onChangeOverallFeeling={onChangeOverallFeeling}
              onChangeFeedbackString={onChangeFeedbackString}
              feedbackPhrases={feedbackPhrases}
            />
            {artists && i < artists.length - 1 && (
              <>
                <Spacer mt={4} />
                <DottedLine />
                <Spacer mt={4} />
              </>
            )}
          </React.Fragment>
        ))}
    </CrewEventSurveyCard>
  );
};

export default AudienceReactionArtists;
