export const sortOptions = [
  {
    title: 'Title (A - Z)',
    value: {
      by: 'title',
      direction: 'asc',
    },
  },
  {
    title: 'Title (Z - A)',
    value: {
      by: 'title',
      direction: 'desc',
    },
  },
];

export const staticOptions = {
  archivedStatus: [
    {
      title: 'Archived',
      value: 'archived',
    },
  ],
};
