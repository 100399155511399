import { EventCollection } from 'app/typings';

export const useFallbackCollection = ({
  eventCollections,
}: {
  eventCollections?: EventCollection[];
}) => {
  if (!eventCollections || eventCollections.length == 0) {
    return null;
  }

  const eventCollectionsActive = eventCollections?.filter(
    eventCollection => eventCollection.status === 'active'
  );

  if (eventCollectionsActive && eventCollectionsActive.length > 0) {
    const eventCollectionPrimary = eventCollectionsActive.find(
      (eventCollection: EventCollection) => eventCollection.isPrimaryCollection
    );

    if (eventCollectionPrimary) {
      return eventCollectionPrimary;
    }

    const eventCollectionsSorted = eventCollectionsActive.sort((a, b) => {
      const collectionDateA = new Date(a.createdAt || new Date()).getTime();
      const collectionDateB = new Date(b.createdAt || new Date()).getTime();
      return collectionDateB - collectionDateA;
    });

    return eventCollectionsSorted[0];
  }

  return null;
};
