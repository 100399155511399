import React, { useContext, useRef } from 'react';
import styled, { css, keyframes } from 'styled-components';

import { useAnalyticsContext } from 'app/shared/utils';
import { makeOpaque } from 'app/shared/utils/colors';
import { AuthContext } from 'app/shared/context/Auth';
import { StreamlineIcon } from 'app/shared/components/atoms/StreamlineIcon';
import { ReactComponent as Arrow } from 'icons/streamline-regular/interface-essential/navigate/navigation-right-circle-1.svg';

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const buttonTextStyle = ({ theme }: any) => css`
  font-weight: ${theme.fontStyles.button.fontWeight};
  letter-spacing: 0.8px;
  outline: none;
  text-decoration: none;
  text-transform: uppercase;
  transition: ${theme.utils.transition()};

  ${theme.media.xs`
    font-size: ${theme.fontSizes.button.xs};
  `};

  ${theme.media.lg`
    font-size: ${theme.fontSizes.button.lg};
  `};
`;

interface StyledBaseButtonProps {
  loading?: boolean | false;
  small?: boolean;
  block?: boolean;
}

export interface ButtonProps
  extends React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    React.AriaAttributes,
    StyledBaseButtonProps {
  backgroundColor?: string;
  borderColor?: string;
  textColor?: string;
  padding?: string;
  hasError?: boolean;
}

const BaseButton: React.FC<ButtonProps> = ({
  loading,
  small,
  block,
  backgroundColor,
  borderColor,
  textColor,
  padding,
  hasError,
  ...props
}) => {
  const { trackAnalyticsEvent } = useAnalyticsContext();
  const { visitorType } = useContext(AuthContext);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const { children, onClick } = props;

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    const buttonNode = buttonRef.current;
    if (buttonNode) {
      trackAnalyticsEvent('Button Clicked', {
        location: buttonNode.baseURI,
        text: buttonNode.textContent,
        dataQaid: buttonNode.dataset.qaid,
        visitor_type: visitorType,
      });
    }

    if (onClick) {
      return onClick(event);
    }
  }

  return (
    <button
      {...props}
      ref={buttonRef}
      onClick={handleClick}
      data-loading={loading}
      data-small={small}
      data-block={block}
      data-background-color={backgroundColor}
      data-border-color={borderColor}
      data-text-color={textColor}
      data-padding={padding}
      data-has-error={hasError}
    >
      {children}
    </button>
  );
};

export const StyledBaseButton = styled(BaseButton)<StyledBaseButtonProps>`
  ${({ theme, loading, small, block }) => css`
    border-radius: ${theme.borderRadius.button};

    cursor: pointer;
    font-weight: ${theme.fontStyles.button.fontWeight};
    height: ${theme.dimensions.button.default.height};
    padding: ${theme.dimensions.button.default.padding};
    letter-spacing: 0.8px;
    outline: none;
    text-decoration: none;
    text-transform: uppercase;

    &:disabled {
      cursor: not-allowed;
      pointer-events: none;
    }

    // icon tag
    i {
      margin-right: ${theme.ruler[2]}px;
    }

    ${theme.media.xs`
      font-size: ${theme.fontSizes.button.xs};
    `};

    ${theme.media.lg`
      font-size: ${theme.fontSizes.button.lg};
    `};

    ${loading &&
      css`
        pointer-events: none;
        cursor: disabled;

        i {
          opacity: 0;
        }
      `}

    ${small &&
      css`
        height: ${theme.dimensions.button.small.height};
        padding: ${theme.dimensions.button.small.padding};
      `}

    ${block &&
      css`
        width: 100%;
      `}

  `}
`;

export const PrimaryButton = styled(StyledBaseButton)`
  ${({ theme, loading }) => css`
    background: ${theme.colors.primary};
    border: 1px solid ${theme.colors.primary};
    color: ${theme.colors.primaryText};

    &:hover {
      border: 1px solid ${theme.colors.primaryHover};
      background-color: ${theme.colors.primaryHover};
      text-decoration: none;
    }

    &:focus {
      border: 1px solid ${theme.colors.primaryActive};
      background-color: ${theme.colors.primaryActive};
    }

    &:disabled {
      color: ${theme.colors.disabledText};
      background-color: ${theme.colors.disabledBackground};
      border-color: ${theme.colors.disabledBackground};
    }

    i:before {
      color: ${theme.colors.whiteDenim};
    }

    ${loading &&
      css`
        color: transparent !important;
        background-color: ${theme.colors.disabledBackground};
        border-color: ${theme.colors.disabledBackground};
        position: relative;

        :after {
          animation: ${rotate360} 0.5s linear infinite;
          border: 0.1rem solid ${theme.colors.whiteDenim};
          border-radius: 50%;
          border-right-color: transparent;
          border-top-color: transparent;
          content: '';
          display: block;
          height: 0.8rem;
          left: 50%;
          margin-left: -0.4rem;
          margin-top: -0.4rem;
          position: absolute;
          top: 50%;
          width: 0.8rem;
          z-index: 1;
        }
      `};
  `}
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  ${({ theme, disabled, loading, block }) => css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding-top: ${theme.ruler[2]}px;
    padding-bottom: ${theme.ruler[2]}px;

    ${disabled &&
      css`
        > span > span > svg > path {
          stroke: ${theme.colors.disabledText};
        }
      `}

    ${loading &&
      css`
        > span {
          display: none;
        }
      `}

    ${block &&
      css`
        width: 100%;
      `}
  `}
`;

const IconWrapper = styled.span`
  margin-left: 10px;
  display: flex;

  > span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const PrimaryButtonWithArrow: React.FC<any> = ({
  children,
  block,
  ...props
}) => {
  return (
    <StyledPrimaryButton block={block} {...props}>
      {children}
      <IconWrapper>
        <StreamlineIcon size={20} icon={Arrow} />
      </IconWrapper>
    </StyledPrimaryButton>
  );
};

interface OutlineButtonProps {
  color?: 'primary' | 'black' | 'white';
}

export const OutlineButton = styled(PrimaryButton)<OutlineButtonProps>`
  ${({ theme, loading, color }) => css`
    background: transparent;
    ${theme.fontStyles.button.fontFamily &&
      `font-family: ${theme.fontStyles.button.fontFamily};`}
    color: ${theme.colors.secondaryText};

    i:before {
      color: ${theme.colors.secondaryText};
    }

    &:hover {
      border-color: ${theme.colors.primaryHover};
      color: ${theme.colors.secondaryText};
      background-color: transparent;

      i:before {
        color: ${theme.colors.secondaryText};
      }
    }

    &:focus {
      background-color: ${makeOpaque(theme.colors.green600, 0.1)};
    }

    &:disabled {
      background-color: transparent;
      color: ${theme.colors.blueSmoke};

      i:before {
        color: ${theme.colors.blueSmoke};
      }
    }

    ${color === 'black' &&
      css`
        border-color: ${theme.colors.blackBetty};
        color: ${theme.colors.backToBlack};

        i:before {
          color: ${theme.colors.backToBlack};
        }

        &:hover {
          border-color: ${theme.colors.backToBlack};
          color: ${theme.colors.backToBlack};

          i:before {
            color: ${theme.colors.backToBlack};
          }
        }

        &:focus {
          background-color: ${theme.colors.macyGrey};
        }
      `}

    ${color === 'white' &&
      css`
        border-color: ${theme.colors.whiteDenim};
        color: ${theme.colors.whiteDenim};
        background-color: transparent;

        i:before {
          color: ${theme.colors.whiteDenim};
        }

        &:hover {
          border-color: ${theme.colors.whiteDenim};
          color: ${theme.colors.whiteDenim};
          background-color: transparent;

          i:before {
            color: ${theme.colors.whiteDenim};
          }
        }

        &:focus {
          border-color: ${theme.colors.silverSprings};
          background: rgba(219, 219, 219, 0.1); //macyGrey at 10%
        }
        &:disabled {
          background-color: ${theme.colors.backToBlack};
        }
      `}

    ${loading &&
      css`
        :after {
          border-color: ${theme.colors.blueSmoke};
          border-right-color: transparent;
          border-top-color: transparent;
        }
      `}
  `}
`;

export const LinkButton = styled(OutlineButton)`
  ${({ theme }) => css`
    background: ${theme.colors.whiteDenim};
    border: none;
    color: ${theme.colors.secondaryText};

    i:before {
      color: ${theme.colors.primary};
    }

    &:hover {
      border: none;
      color: ${theme.colors.primaryHover};

      i:before {
        color: ${theme.colors.green900};
      }
    }

    &:focus {
      background-color: ${makeOpaque(theme.colors.green600, 0.1)};
    }

    &:disabled {
      background-color: ${theme.colors.whiteDenim};
    }
  `};
`;

export const TextButton = styled(OutlineButton)`
  ${({ theme }) => css`
    background: ${theme.colors.whiteDenim};
    border: none;
    padding: 0;

    i:before {
      color: ${theme.colors.primary};
    }

    &:hover {
      color: ${theme.colors.green900};

      i:before {
        color: ${theme.colors.green900};
      }
    }

    &:focus {
      background-color: ${makeOpaque(theme.colors.green600, 0.1)};
    }

    &:disabled {
      background-color: ${theme.colors.whiteDenim};
    }
  `};
`;
