import React, { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { getIdsSubmitVariable } from 'app/shared/utils/form';
import { get } from 'app/shared/utils/get';
import useModal from 'app/shared/utils/useModal';
import usePermission from 'app/shared/utils/usePermission';
import { AuthContext } from 'app/shared/context/Auth';
import { ListPage, ListPageContext } from 'app/shared/context/ListPage';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import { LoadingBlocks } from 'app/shared/components/atoms/LoadingBlocks';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import RoutableModal from 'app/shared/components/molecules/RoutableModal';
import { GlobalError } from 'app/shared/components/pages/Status';
import { CuratorGroupInfoForLimitingByCuratorGroup } from 'app/admin/utils/curatorGroupPermissions';
import {
  getCampaignOptions,
  getFilterNamesAndOptionsForTitleMapping,
  getGenerationReasonOptions,
  getPromoCodeTypeOptions,
} from 'app/admin/utils/optionLists';
import { GetCampaigns } from 'app/admin/graphql/campaigns/queryHooks';
import {
  GetGenerationReasons,
  GetPromoCodes,
  GetPromoCodeTypes,
} from 'app/admin/graphql/promoCodes/queryHooks';
import ListingFooter from 'app/admin/components/molecules/ListingFooter';
import ListingNoResults from 'app/admin/components/molecules/ListingNoResults';
import CardGrid, { CardType } from 'app/admin/components/organisms/CardGrid';
import ListingControls from 'app/admin/components/organisms/ListingControls';
import ListingFilter from 'app/admin/components/organisms/ListingFilter';
import ListingHeader from 'app/admin/components/organisms/ListingHeader';
import PromoCodeCard from 'app/admin/components/organisms/PromoCodeCard';
import ListPageTemplate from 'app/admin/components/templates/ListPage';
import Layout from 'app/admin/layouts/ListPage';

import { sortOptions, staticOptions } from './options';

interface HideProps {
  shouldRefetchPromoCodes?: boolean;
}

const pageStateConfig = {
  filterNames: [
    'type_group',
    'type',
    'status',
    'expires_at',
    'generation_reason',
    'campaign',
  ],
  defaultSort: { by: 'expires_at', direction: 'desc' },
  textSearchParamName: 'promo_code_search',
  idParamName: 'promo_code_id',
};

const AdminPromoCodes: React.FC = () => {
  const intl = useIntl();
  const { user } = useContext(AuthContext);
  const pageState = useContext(ListPageContext);
  const PER_PAGE = 18;

  const hasAccessPromoCodesByCreatorPermission = usePermission(
    'promoCode.accessByCreator'
  );
  const hasDownloadPromoCodesPermission = usePermission('campaign.access');

  const {
    curatorGroupIdsToLimitBy,
  } = CuratorGroupInfoForLimitingByCuratorGroup(
    'promoCode.accessByCuratorGroup'
  );

  const {
    data: dataGenerationReasons,
    loading: loadingGenerationReasons,
    error: errorGenerationReasons,
  } = GetGenerationReasons();

  const {
    data: dataPromoCodeTypes,
    loading: loadingPromoCodeTypes,
    error: errorPromoCodeTypes,
  } = GetPromoCodeTypes();

  const {
    data: dataCampaigns,
    loading: loadingCampaigns,
    error: errorCampaigns,
  } = GetCampaigns({
    skipPagination: true,
  });

  const generationReasonOptions = getGenerationReasonOptions(
    dataGenerationReasons
  );
  const promoCodeTypeOptions = getPromoCodeTypeOptions(dataPromoCodeTypes);
  const campaignOptions = getCampaignOptions(dataCampaigns);

  const filterDropdownOptionsInfoList = [
    {
      filterName: 'type_group',
      dropdownParams: {
        title: intl.formatMessage({
          id: 'admin.promoCodes.filter.typeGroup',
        }),
        options: staticOptions.typeGroup,
      },
    },
    {
      filterName: 'type',
      dropdownParams: {
        title: intl.formatMessage({
          id: 'admin.shared.type',
        }),
        groupBy: 'typeGroupName',
        groupNames: get(
          dataPromoCodeTypes,
          'promoCodeTypes.promoCodeTypeGroupNames',
          []
        ),
        options: promoCodeTypeOptions,
      },
    },
    {
      filterName: 'status',
      dropdownParams: {
        title: intl.formatMessage({
          id: 'shared.status',
        }),
        options: staticOptions.status,
      },
    },
    {
      filterName: 'expires_at',
      dropdownParams: {
        title: intl.formatMessage({
          id: 'admin.promoCodes.filter.expiresAt',
        }),
        options: staticOptions.expiresAt,
      },
    },
    {
      filterName: 'generation_reason',
      dropdownParams: {
        title: intl.formatMessage({
          id: 'admin.promoCodes.filter.generationReason',
        }),
        options: generationReasonOptions,
      },
    },
    {
      filterName: 'campaign',
      dropdownParams: {
        searchBar: true,
        title: intl.formatMessage({
          id: 'admin.promoCodes.filter.campaign',
        }),
        options: campaignOptions,
      },
    },
  ];

  const [addPromoCodesModal, toggleAddPromoCodesModal] = useModal();

  const { loading, error, data, refetch: refetchPromoCodes } = GetPromoCodes({
    promoCodeSearch: pageState.textSearchState,
    typeGroup: pageState.filterListVariable('type_group'),
    type: pageState.filterListVariable('type'),
    status: pageState.filterListVariable('status'),
    expiresAt: pageState.filterListVariable('expires_at'),
    generationReason: pageState.filterListVariable('generation_reason'),
    campaign: pageState.filterListVariable('campaign'),
    curatorGroup: getIdsSubmitVariable(curatorGroupIdsToLimitBy),
    createdById: hasAccessPromoCodesByCreatorPermission ? user?.id : undefined,
    orderBy: pageState.sortState.by,
    orderDirection: pageState.sortState.direction,
    page: pageState.page,
    perPage: PER_PAGE,
  });

  useEffect(() => {
    pageState.updateScrollPositionOnPage();
  }, [pageState.detailsModal.isShowing]);

  useEffect(() => {
    pageState.updateDetailsModal();
  }, []);

  useEffect(() => {
    pageState.setupFilterLabelTitleMapping(
      getFilterNamesAndOptionsForTitleMapping(filterDropdownOptionsInfoList)
    );
  }, [data, dataGenerationReasons, dataPromoCodeTypes, dataCampaigns]);

  useEffect(() => {
    pageState.updatePageUrl();
  }, [
    pageState.page,
    pageState.sortState,
    pageState.filterState,
    pageState.textSearchState,
    pageState.detailData,
    pageState.detailsModal.isShowing,
  ]);

  if (
    (!loading && !data) ||
    error ||
    (!loadingGenerationReasons && !dataGenerationReasons) ||
    errorGenerationReasons ||
    (!loadingPromoCodeTypes && !dataPromoCodeTypes) ||
    errorPromoCodeTypes ||
    (!loadingCampaigns && !dataCampaigns) ||
    errorCampaigns
  ) {
    return <GlobalError />;
  }

  const oneAndOnlyOneCampaignFilteredBy =
    pageState.filterListVariable('campaign') &&
    // @ts-ignore
    pageState.filterListVariable('campaign').split(',').length == 1;

  const currentQueryString = new URLSearchParams(
    window.location.search
  ).toString();
  const downloadDisabledText = oneAndOnlyOneCampaignFilteredBy
    ? undefined
    : intl.formatMessage({
        id: 'admin.promoCodes.downloadCsvExplanation',
      });

  let downloadText;
  let downloadUrl;
  if (hasDownloadPromoCodesPermission) {
    downloadText = intl.formatMessage({
      id: 'admin.promoCodes.downloadCsv',
    });
    downloadUrl = `/admin/download-promo-codes.csv?${currentQueryString}`;
  } else {
    downloadText = undefined;
    downloadUrl = undefined;
  }

  const modalsContent = () => (
    <>
      {pageState.detailsModal.isShowing && (
        <RoutableModal
          hide={({ shouldRefetchPromoCodes = false }: HideProps = {}) => {
            pageState.detailsModal.hide();
            if (shouldRefetchPromoCodes) {
              refetchPromoCodes();
            }
          }}
          initialRouteProps={{
            ...pageState.detailData,
            refetchPromoCodes,
          }}
          initialRouteName="promo-code-details"
          dataQaidSuffix="admin-edit-promo-code-details"
        />
      )}
      {addPromoCodesModal.isShowing && (
        <RoutableModal
          hide={addPromoCodesModal.hide}
          initialRouteProps={{
            refetchPromoCodes,
          }}
          initialRouteName="promo-code-create"
          dataQaidSuffix="admin-create-promo-code"
        />
      )}
    </>
  );

  return (
    <Layout scrollDisabled={pageState.detailsModal.isShowing}>
      <ListPageTemplate
        pageTitle={intl.formatMessage({
          id: 'admin.promoCodes.pageTitle',
        })}
        modalsContent={modalsContent}
      >
        <ListingHeader
          pageTitle={intl.formatMessage({
            id: 'admin.promoCodes.pageTitle',
          })}
          addEntityText={intl.formatMessage({
            id: 'admin.promoCodes.addPromoCodes',
          })}
          onClickAddEntity={toggleAddPromoCodesModal}
          downloadText={downloadText}
          downloadUrl={downloadUrl}
          downloadDisabledText={downloadDisabledText}
          searchPlaceholder={intl.formatMessage({
            id: 'admin.promoCodes.textSearch.placeholder',
          })}
          searchValue={pageState.textSearchState}
          onSearch={pageState.handleTextSearch}
          dataQaidPrefix="promo-code"
        />
        <div>
          <ListingControls
            sortOptions={sortOptions}
            orderBy={pageState.sortState.by}
            orderDirection={pageState.sortState.direction}
            totalRecords={data && data.promoCodes.metadata.totalRecords}
            loading={loading}
            onSort={pageState.handleSortChange}
            onReset={pageState.handleResetFilters}
            dataQaidPrefix="promo-code"
          />
          <ListingFilter
            filterTitle={intl.formatMessage({
              id: 'admin.promoCodes.filterTitle',
            })}
            textSearchString={pageState.textSearchState}
            handleTextSearchLabelClose={pageState.handleTextSearchLabelClose}
            labelTitleMapping={pageState.filterLabelTitleMapping}
            dropdownOptionsInfoList={filterDropdownOptionsInfoList}
            filterState={pageState.filterState}
            handleRemoveFilter={pageState.handleRemoveFilter}
            handleFilterChange={pageState.handleFilterChange}
          />
        </div>

        <div>
          <CardGrid
            objectData={get(data, 'promoCodes.promoCodes', [])}
            renderCardComponent={(promoCode: CardType, i: number) => (
              // @ts-ignore
              <PromoCodeCard
                index={i}
                onShowDetails={pageState.toggleDetailsModalAndSetDetailData}
                refetchPromoCodes={refetchPromoCodes}
                {...promoCode}
              />
            )}
            dataQaid="admin-promo-codes-list"
            loading={loading}
            loadingComponent={
              <LoadingBlocks.Rectangle width="100%" height="420px" />
            }
            hideDividerOnSize="xs"
          />
          <DottedLine />
          <Spacer mb={2} />
        </div>

        <ListingNoResults
          entityName={intl.formatMessage({
            id: 'admin.promoCodes.noResultsEntityName',
          })}
          numResults={get(data, 'promoCodes.promoCodes.length', undefined)}
          loading={loading}
        />

        <ListingFooter
          numTotalRecords={get(
            data,
            'promoCodes.metadata.totalRecords',
            undefined
          )}
          perPage={PER_PAGE}
          currentPage={pageState.page}
          onPageChange={pageState.handlePageChange}
          loading={loading}
          dataQaidPrefix="promo-codes"
        />
      </ListPageTemplate>
    </Layout>
  );
};

const AdminPromoCodesWrapper: React.FC = () => (
  <ListPage config={pageStateConfig}>
    <AdminPromoCodes />
  </ListPage>
);

export default AdminPromoCodesWrapper;
