import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { Event } from 'app/typings/events';
import { get } from 'app/shared/utils/get';
import usePermission from 'app/shared/utils/usePermission';
import { ListPage, ListPageContext } from 'app/shared/context/ListPage';
import { Checkbox } from 'app/shared/components/atoms/CheckboxManualCSS';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import { LoadingBlocks } from 'app/shared/components/atoms/LoadingBlocks';
import { LoadingError } from 'app/shared/components/atoms/LoadingError';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Textfield } from 'app/shared/components/atoms/Textfield';
import { H5 } from 'app/shared/components/atoms/TypographyManualCSS';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { GetAttendees } from 'app/admin/graphql/attendees/queryHooks';
import { GetEventInfo } from 'app/admin/graphql/events/queryHooks';
import ActionIcon from 'app/admin/components/atoms/ActionIcon';
import { DetailsInfoMainContainer } from 'app/admin/components/atoms/DetailContent';
import { DetailsImage } from 'app/admin/components/molecules/DetailsImage';
import { DetailsTitle } from 'app/admin/components/molecules/DetailsTitle';
import { ReactComponent as PrintText } from 'icons/streamline-regular/interface-essential/print/print-text.svg';
import { ReactComponent as SynchronizeArrow } from 'icons/streamline-regular/interface-essential/synchronize/synchronize-arrow.svg';

import { AttendeeCard } from './AttendeeCard';

interface Props {
  contentProps?: any;
  navigateTo: (routeData: object) => void;
  hide: VoidFunction;
}

const pageStateConfig = {
  filterNames: ['include_all_attendees'],
  defaultSort: { by: 'name', direction: 'asc' },
  textSearchParamName: 'attendee_search',
  useDefaultSort: true,
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media screen and (min-width: 1300px) {
    max-width: 1260px;
  }
  padding: 0;
  width: 100%;
`;

const HeaderUpperContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    ${theme.media.sm`
      margin-left: 45px;
    `};
    ${theme.media.md`
      margin-left: 25px;
    `};
    ${theme.media.lg`
      margin-left: 15px;
    `};
    ${theme.media.xl`
      margin: 0;
      flex-direction: row;
    `};
  `}
`;

const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    width: 100%;
    ${theme.media.xl`
        width: 58%;
      `};
  `}
`;

const ListingControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const ListCount = styled.div`
  display: flex;
  flex-direction: column-reverse;
  flex-grow: 1;
  p {
    height: fit-content;
  }
`;

const StyledH5 = styled(H5)`
  margin-bottom: 5px;
`;

const SmallMarginDottedLine = styled(DottedLine)`
  margin: 2px -15px 2px 0px;
`;

const SearchControlsContainer = styled.div`
  padding-top: 10px;
  width: 100%;
`;

const SearchField = styled(Textfield)`
  ${({ theme }) => css`
    width: 100%;
    max-width: 400px;
    margin-bottom: ${theme.ruler[2]}px;
    padding-left: ${theme.ruler[4]}px;
    flex-grow: 1;
    display: inline-flex;
  `}
`;

const FilterControlsContainer = styled.div`
  padding-top: 12px;
  width: 100%;
`;

const LoadingResultsContainer = styled.div`
  width: 100%;
  padding: 20px 0px 10px 0px;
`;

const ListingResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  width: 100%;
  padding: 20px 0px 10px 0px;
`;

const NoResults = styled(H5)`
  ${({ theme }) => css`
    text-align: center;
    font-weight: normal;
    color: ${theme.colors.blueSmoke};
    margin-top: 20px;
    margin-bottom: 30px;
  `}
`;

export const numGuestsAttended = (attendee: any) => {
  const numAttendedEventAttendee =
    attendee.eventAttendee && attendee.eventAttendee.ticketsAttendedCount;
  const numAttendedVipAttendee =
    attendee.vipAttendee && attendee.vipAttendee.ticketsAttendedCount;
  return numAttendedEventAttendee || numAttendedVipAttendee || 0;
};

export const numGuestsTotal = (attendee: any) => {
  const numTotalEventAttendee =
    attendee.eventAttendee && attendee.eventAttendee.ticketsCount;
  const numTotalVipAttendee =
    attendee.vipAttendee && attendee.vipAttendee.ticketsCount;
  return numTotalEventAttendee || numTotalVipAttendee || 0;
};

interface ListingControlsProps {
  totalRecords?: number;
  labelStringId?: string;
  loading: boolean;
  event?: Event;
  refetchEvent?: Function;
  refetchAttendees?: Function;
  onReset: VoidFunction;
}

const ListingControls: React.FC<ListingControlsProps> = ({
  labelStringId,
  totalRecords,
  loading,
  event,
  onReset,
}) => {
  const intl = useIntl();

  const onClickPrint = () =>
    window.open(
      `/admin/print-day-of-show-guestlist?event_id=${event && event.id}`,
      '_blank'
    );

  return (
    <>
      <ListingControlsContainer>
        <ListCount>
          <StyledH5>
            {loading
              ? null
              : intl.formatMessage(
                  {
                    id: labelStringId,
                  },
                  {
                    numGuests: totalRecords && totalRecords.toLocaleString(),
                  }
                )}
          </StyledH5>
        </ListCount>
        <ActionIcon
          icon={SynchronizeArrow}
          label={intl.formatMessage({
            id: 'list.reset',
          })}
          onClick={onReset}
          dataQaid="guestlist-day-of-show-reset-filter"
        />
        <ActionIcon
          icon={PrintText}
          label={intl.formatMessage({
            id: 'print',
          })}
          onClick={onClickPrint}
          dataQaid="guestlist-day-of-show-print"
        />
      </ListingControlsContainer>
      <SmallMarginDottedLine />
      <Spacer mb={2} />
    </>
  );
};

interface SearchControlsProps {
  value?: string;
  onSearch: (search: string, allowEmpty?: boolean) => void;
}

const SearchControls: React.FC<SearchControlsProps> = ({ value, onSearch }) => {
  const intl = useIntl();
  const [searchString, setSearchString] = useState(value || '');

  const handleSearch = () => {
    onSearch(searchString.trim(), true);
  };

  const handleSearchChange = (changeObject: any) => {
    const value = changeObject.target.value;
    setSearchString(value);
  };

  useEffect(() => {
    if (value === undefined) {
      return setSearchString('');
    }
    setSearchString(value);
  }, [value]);

  useEffect(() => {
    handleSearch();
  }, [searchString]);

  return (
    <SearchControlsContainer>
      <SearchField
        data-qaid="guestlist-day-of-show-search-field"
        placeholder={intl.formatMessage({
          id: 'admin.guestlistDayOfShow.textSearchPlaceholder',
        })}
        value={searchString || ''}
        onChange={handleSearchChange}
        maxLength={50}
      />
    </SearchControlsContainer>
  );
};

interface FilterControlsProps {
  includeAllAttendees: boolean;
  setIncludeAllAttendees: Function;
}

const FilterControls: React.FC<FilterControlsProps> = ({
  includeAllAttendees,
  setIncludeAllAttendees,
}) => {
  const intl = useIntl();

  return (
    <FilterControlsContainer>
      <Checkbox
        id="filter-not-attended-attendees"
        data-qaid="filter-not-attended-attendees"
        checked={!includeAllAttendees}
        name="filterNotAttendedAttendees"
        onChange={e => {
          setIncludeAllAttendees(!e.target.checked);
        }}
      >
        {intl.formatMessage({
          id: 'admin.guestlistDayOfShow.showNotAttendedOnly',
        })}
      </Checkbox>
    </FilterControlsContainer>
  );
};

interface ListingResultsProps {
  attendees?: any[];
  event: Event;
  navigateTo: (routeData: object) => void;
  textSearch: string;
}

const ListingResults: React.FC<ListingResultsProps> = ({
  attendees,
  event,
  navigateTo,
  textSearch,
}) => {
  return (
    <ListingResultsContainer>
      {attendees &&
        attendees.map((attendee: any, index: number) => (
          <AttendeeCard
            attendee={attendee}
            event={event}
            navigateTo={navigateTo}
            key={index}
            textSearch={textSearch}
          />
        ))}
    </ListingResultsContainer>
  );
};

const ListingNoResults: React.FC = () => {
  const intl = useIntl();

  return (
    <div>
      <NoResults>
        {intl.formatMessage({ id: 'admin.guestlistDayOfShow.noResults' })}
      </NoResults>
    </div>
  );
};

const doesAttendeeMatchSearch = (attendee: any, textSearch: string) => {
  const textSearchCleaned = textSearch.trim().toLowerCase();

  const attendeeNameCleaned = attendee.name
    ? attendee.name.trim().toLowerCase()
    : '';
  const attendeeEmailCleaned = attendee.email
    ? attendee.email.trim().toLowerCase()
    : '';

  const ticketNamesCleaned = attendee.eventAttendee
    ? attendee.eventAttendee.tickets
        .filter((ticket: any) => !ticket.primary)
        .map((ticket: any) =>
          `${ticket.firstName || ''} ${ticket.lastName || ''}`
            .trim()
            .toLowerCase()
        )
        .join()
    : '';

  const ticketEmailsCleaned = attendee.eventAttendee
    ? attendee.eventAttendee.tickets
        .filter((ticket: any) => !ticket.primary)
        .map((ticket: any) => (ticket.email || '').trim().toLowerCase())
        .join()
    : '';

  if (
    [
      attendeeNameCleaned,
      ticketNamesCleaned,
      attendeeEmailCleaned,
      ticketEmailsCleaned,
    ]
      .join()
      .includes(textSearchCleaned)
  ) {
    return true;
  } else {
    return false;
  }
};

const doesAttendeeMatchFilter = (
  attendee: any,
  includeAllAttendees: boolean
) => {
  const numAttended = numGuestsAttended(attendee);
  const numTotal = numGuestsTotal(attendee);

  if (includeAllAttendees) {
    return true;
  } else if (numAttended < numTotal) {
    return true;
  } else {
    return false;
  }
};

const getFilteredAttendees = (
  attendees: any[],
  textSearch: string | undefined,
  includeAllAttendees: boolean
) => {
  if (textSearch) {
    attendees = attendees.filter((attendee: any) =>
      doesAttendeeMatchSearch(attendee, textSearch)
    );
  }
  attendees = attendees.filter((attendee: any) =>
    doesAttendeeMatchFilter(attendee, includeAllAttendees)
  );

  return attendees;
};

const GuestlistDayOfShow: React.FC<Props> = ({
  contentProps,
  navigateTo,
  hide,
}) => {
  const intl = useIntl();
  const pageState = useContext(ListPageContext);
  const hasGuestlistAccessPermission = usePermission('event.guestlist.access');

  const {
    loading: loadingEvent,
    error: errorEvent,
    data: dataEvent,
    refetch: refetchEvent,
  } = GetEventInfo({
    id: get(contentProps, 'id', null),
    includeDraftEvents: true,
    fetchPolicy: 'cache-and-network',
  });

  const {
    loading: loadingAttendees,
    error: errorAttendees,
    data: dataAttendees,
    refetch: refetchAttendees,
  } = GetAttendees({
    eventId: get(contentProps, 'id', null),
    isAttending: true,
    orderBy: pageState.sortState.by,
    orderDirection: pageState.sortState.direction,
  });

  const loading = loadingEvent || loadingAttendees;
  const event = get(dataEvent, 'event', undefined);
  const rawAttendees = get(dataAttendees, 'attendees.attendees', []);

  if (
    (!loadingEvent && !dataEvent) ||
    errorEvent ||
    (!loadingAttendees && !dataAttendees) ||
    errorAttendees
  ) {
    return (
      <LoadingError
        whatsBeingLoaded={intl.formatMessage({
          id: 'admin.eventPlanner.guestlist.theGuestlist',
        })}
      />
    );
  }

  if (!hasGuestlistAccessPermission) {
    hide();
    return null;
  }

  const localStartsAt = event && event.localStartsAt;
  const cityTitle = event && event.city.title;
  const neighborhoodTitle = event && event.neighborhood?.title;
  const subTitle = neighborhoodTitle
    ? `${cityTitle} / ${neighborhoodTitle}`
    : cityTitle;

  const includeAllAttendees =
    pageState.filterState.include_all_attendees[0] === 'true';

  const setIncludeAllAttendees = (includeAll: boolean) => {
    // @ts-ignore
    pageState.handleFilterChange('include_all_attendees', [
      includeAll ? 'true' : 'false',
    ])();
  };

  const attendees = getFilteredAttendees(
    rawAttendees,
    pageState.textSearchState || undefined,
    includeAllAttendees
  );

  const totalRecords = get(
    dataAttendees,
    'attendees.attendeesMetadata.ticketsCounts.ticketsCountTotalForResults',
    0
  );

  const numGuestsAttendingPerAttendee = rawAttendees.map((e: any) => {
    return numGuestsAttended(e);
  });

  const totalGuestsCheckedIn = numGuestsAttendingPerAttendee.reduce(
    (a: any, b: any) => a + b,
    0
  );

  const numResults = attendees.length;
  const guestsRemaining = totalRecords - totalGuestsCheckedIn;

  return (
    <ModalContentContainer>
      <DetailsInfoMainContainer>
        <HeaderUpperContainer>
          <Header>
            <Grid>
              <DetailsImage
                imageStyle="calendar"
                loading={loading}
                detailsDate={localStartsAt}
              />
              <Col xs={7} sm={9} md={8}>
                <DetailsTitle
                  title={intl.formatMessage({
                    id: 'admin.guestlistDayOfShow.dayOfShowGuestlist',
                  })}
                  subTitle={subTitle}
                  loading={loading}
                  detailsDate={localStartsAt}
                />
              </Col>
            </Grid>
          </Header>
        </HeaderUpperContainer>
        <ListingControls
          labelStringId={
            includeAllAttendees
              ? 'admin.guestlistDayOfShow.expectedGuests'
              : 'admin.guestlistDayOfShow.guestsRemaining'
          }
          totalRecords={includeAllAttendees ? totalRecords : guestsRemaining}
          loading={loadingAttendees}
          event={event}
          refetchEvent={refetchEvent}
          refetchAttendees={refetchAttendees}
          onReset={pageState.handleResetFilters}
        />
        <MainContainer>
          <SearchControls
            value={pageState.textSearchState || undefined}
            onSearch={pageState.handleTextSearch}
          />
          <FilterControls
            includeAllAttendees={includeAllAttendees}
            setIncludeAllAttendees={setIncludeAllAttendees}
          />
          {loading ? (
            <LoadingResultsContainer>
              <LoadingBlocks.Rectangle width="100%" height="300px" />
            </LoadingResultsContainer>
          ) : numResults > 0 ? (
            <ListingResults
              attendees={attendees}
              event={event}
              navigateTo={navigateTo}
              textSearch={pageState.textSearchState || ''}
            />
          ) : (
            <ListingNoResults />
          )}
        </MainContainer>
      </DetailsInfoMainContainer>
    </ModalContentContainer>
  );
};

const GuestlistDayOfShowWrapper: React.FC<Props> = ({
  contentProps,
  navigateTo,
  hide,
}) => (
  <ListPage config={pageStateConfig}>
    <GuestlistDayOfShow
      contentProps={contentProps}
      navigateTo={navigateTo}
      hide={hide}
    />
  </ListPage>
);

export default GuestlistDayOfShowWrapper;
