import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { User } from 'app/typings/users';
import { errorMsgForField } from 'app/shared/utils/form';
import { get } from 'app/shared/utils/get';
import { objectForObjectId } from 'app/shared/utils/object';
import {
  FormGroupContainer,
  FormSectionTitle,
} from 'app/shared/components/atoms/FormContent';
import GenericFormContainer from 'app/shared/components/atoms/GenericFormContainer';
import { LoadingError } from 'app/shared/components/atoms/LoadingError';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Textfield } from 'app/shared/components/atoms/Textfield';
import FormGroup from 'app/shared/components/molecules/FormGroup';
import Select from 'app/shared/components/molecules/SelectManualCSS';
import { optionsWithNoneOption } from 'app/admin/utils/optionLists';
import { GetAgencies } from 'app/admin/graphql/agencies/queryHooks';
import { GetPartners } from 'app/admin/graphql/partners/queryHooks';
import EditFormLoadingBlocks from 'app/admin/components/atoms/EditFormLoadingBlocks';
import { AssociatedUser } from 'app/admin/components/molecules/AssociatedUser';
import UserTypeahead from 'app/admin/components/molecules/UserTypeahead';

interface FormProps {
  formikProps: any;
  setFormSubmitAction?: (func: Function) => void;
  setDisplayConfirmation?: (set: boolean) => void;
  setIsSubmitting?: (set: boolean) => void;
  validationErrors?: object;
  isNewCampaign?: boolean;
}

const StyledTextfield = styled(Textfield)`
  ${({ theme }) => css`
    display: block;
    width: 100%;
    ${theme.media.lg`
      width: 48%;
    `};
  `}
`;

const NoAssociatedUser = styled.div`
  font-size: 14px;
  letter-spacing: 0.1px;
  margin-bottom: 25px;
`;

const AddAssociatedUserContainer = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  max-width: 500px;
`;

const AddAssociatedUser = styled.div`
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.1px;
  margin-bottom: 10px;
`;

const renderAssociatedUser = (
  user: User | undefined,
  unassociateUserId: boolean,
  removeAssociatedUser: Function,
  associatedUserRemoveMsg: string,
  noAssociatedUserMsg: string | undefined
) => {
  if (user) {
    return (
      <AssociatedUser
        user={user}
        userWillBeUnassociatedOnSave={unassociateUserId}
        removeAssociatedUser={removeAssociatedUser}
        associatedUserRemoveMsg={associatedUserRemoveMsg}
      />
    );
  } else if (noAssociatedUserMsg) {
    return <NoAssociatedUser>{noAssociatedUserMsg}</NoAssociatedUser>;
  }
  return null;
};

const CampaignEditForm: React.FC<FormProps> = ({
  formikProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  validationErrors = {},
  isNewCampaign = false,
}) => {
  const intl = useIntl();

  useEffect(() => {
    setFormSubmitAction && setFormSubmitAction(() => formikProps.submitForm);
    setDisplayConfirmation && setDisplayConfirmation(formikProps.dirty);
    setIsSubmitting && setIsSubmitting(formikProps.isSubmitting);
  }, [
    formikProps.isSubmitting,
    formikProps.submitForm,
    formikProps.dirty,
    setFormSubmitAction,
    setDisplayConfirmation,
    setIsSubmitting,
  ]);

  const {
    loading: loadingPartners,
    error: errorPartners,
    data: dataPartners,
  } = GetPartners({
    archivedStatus: 'not_archived',
    skipPagination: true,
  });

  const {
    loading: loadingAgencies,
    error: errorAgencies,
    data: dataAgencies,
  } = GetAgencies({
    archivedStatus: 'not_archived',
    skipPagination: true,
  });

  if (errorPartners || errorAgencies) {
    return <LoadingError whatsBeingLoaded="this form" />;
  }

  if (loadingPartners || !dataPartners || loadingAgencies || !dataAgencies) {
    return <EditFormLoadingBlocks />;
  }

  const partnerOptions = optionsWithNoneOption(
    get(dataPartners, 'partners.partners', [])
  );

  const agencyOptions = optionsWithNoneOption(
    get(dataAgencies, 'agencies.agencies', [])
  );

  const partnerForPartnerId = (partnerId: number | null) =>
    objectForObjectId(get(dataPartners, 'partners.partners', []), partnerId);

  const agencyForAgencyId = (agencyId: number | null) =>
    objectForObjectId(get(dataAgencies, 'agencies.agencies', []), agencyId);

  const { setFieldValue, touched, errors, values } = formikProps;

  const addAccountManager = (userId: number | undefined) => {
    setFieldValue('accountManagerUserIdToAssociate', userId);
    setFieldValue('unassociateAccountManagerUserId', true);
  };

  const removeAccountManager = () => {
    setFieldValue('unassociateAccountManagerUserId', true);
  };

  const addSalesRep = (userId: number | undefined) => {
    setFieldValue('salesRepUserIdToAssociate', userId);
    setFieldValue('unassociateSalesRepUserId', true);
  };

  const removeSalesRep = () => {
    setFieldValue('unassociateSalesRepUserId', true);
  };

  return (
    <GenericFormContainer dataQaId="campaign-edit-form">
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'shared.title',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="campaign-edit-form-title-formgroup"
          label={intl.formatMessage({
            id: 'shared.title',
          })}
          errorMsg={
            errorMsgForField('title', touched, errors) ||
            // @ts-ignore
            validationErrors.title
          }
          required
        >
          <StyledTextfield
            data-qaid="campaign-edit-form-title-field"
            id="title"
            name="title"
            value={values.title}
            onChange={(e: any) => {
              setFieldValue('title', e.target.value);
            }}
            maxLength={80}
          />
        </FormGroup>
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.campaignEdit.form.partnerAgency',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          label={intl.formatMessage({
            id: 'shared.partner',
          })}
          data-qaid="campaign-edit-form-partner-formgroup"
          errorMsg={errorMsgForField('partnerId', touched, errors)}
        >
          <Select
            data-qaid="campaign-edit-form-partner-field"
            searchable
            getOptionLabel={(opt: any) => opt.title}
            defaultValue={partnerForPartnerId(values.partnerId)}
            options={partnerOptions}
            placeholder={intl.formatMessage({
              id: 'admin.campaignEdit.form.partnerPlaceholder',
            })}
            onChange={partner =>
              setFieldValue(
                'partnerId',
                partner && partner.id ? partner.id : null
              )
            }
            initialWidth="400px"
          />
        </FormGroup>
        <Spacer mb={6} />
        <FormGroup
          label={intl.formatMessage({
            id: 'admin.campaignEdit.form.agency',
          })}
          data-qaid="campaign-edit-form-agency-formgroup"
          errorMsg={errorMsgForField('agencyId', touched, errors)}
        >
          <Select
            data-qaid="campaign-edit-form-agency-field"
            searchable
            getOptionLabel={(opt: any) => opt.title}
            defaultValue={agencyForAgencyId(values.agencyId)}
            options={agencyOptions}
            placeholder={intl.formatMessage({
              id: 'admin.campaignEdit.form.agencyPlaceholder',
            })}
            onChange={agency =>
              setFieldValue('agencyId', agency && agency.id ? agency.id : null)
            }
            initialWidth="400px"
          />
        </FormGroup>
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.campaignEdit.form.accountManager',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        {renderAssociatedUser(
          values.accountManagerUser,
          values.unassociateAccountManagerUserId,
          removeAccountManager,
          intl.formatMessage({
            id: 'admin.campaignEdit.form.unassociateAccountManagerWarning',
          }),
          isNewCampaign
            ? undefined
            : intl.formatMessage({
                id: 'admin.campaignEdit.form.noAssociatedAccountManager',
              })
        )}
        <FormGroup
          data-qaid="account-manager-user-formgroup"
          label=""
          // @ts-ignore
          errorMsg={validationErrors.account_manager_user_id}
        >
          <AddAssociatedUserContainer>
            <AddAssociatedUser>
              {intl.formatMessage({
                id: 'admin.campaignEdit.form.addUser',
              })}
            </AddAssociatedUser>
            <UserTypeahead
              setSelectedUser={(user: User | undefined) => {
                addAccountManager(user && user.id);
              }}
            />
          </AddAssociatedUserContainer>
        </FormGroup>
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.campaignEdit.form.salesRep',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        {renderAssociatedUser(
          values.salesRepUser,
          values.unassociateSalesRepUserId,
          removeSalesRep,
          intl.formatMessage({
            id: 'admin.campaignEdit.form.unassociateSalesRepWarning',
          }),
          isNewCampaign
            ? undefined
            : intl.formatMessage({
                id: 'admin.campaignEdit.form.noAssociatedSalesRep',
              })
        )}
        <FormGroup
          data-qaid="sales-rep-user-formgroup"
          label=""
          // @ts-ignore
          errorMsg={validationErrors.sales_rep_user_id}
        >
          <AddAssociatedUserContainer>
            <AddAssociatedUser>
              {intl.formatMessage({
                id: 'admin.campaignEdit.form.addUser',
              })}
            </AddAssociatedUser>
            <UserTypeahead
              setSelectedUser={(user: User | undefined) => {
                addSalesRep(user && user.id);
              }}
            />
          </AddAssociatedUserContainer>
        </FormGroup>
      </FormGroupContainer>
    </GenericFormContainer>
  );
};

export default CampaignEditForm;
