export const genderOptions = {
  gender: [
    {
      label: 'Male',
      value: 'Male',
    },
    {
      label: 'Female',
      value: 'Female',
    },
    {
      label: 'Other / Prefer not to say',
      value: 'Other / Prefer not to say',
    },
  ],
};

export const birthYearPreferNotToSayOption = 'Prefer not to say';

export const birthYearOptions = () => {
  const firstYear = '1899'; // Earliest value in birth_year field in Prod db
  const currentYear = new Date().getFullYear();
  const numYears = Number(currentYear) - Number(firstYear) + 1;
  let options = Array(numYears)
    .fill(null)
    .map((_, i) => (Number(firstYear) + i).toString())
    .reverse();
  options.push(birthYearPreferNotToSayOption);
  return {
    birthYear: options.map((o: any) => ({
      label: o,
      value: o,
    })),
  };
};
