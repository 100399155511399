import React from 'react';
import { useIntl } from 'react-intl';

import { OverallFeeling } from 'app/typings/feedbacks';
import { get } from 'app/shared/utils/get';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { CreateArtistFeedback } from 'app/admin/graphql/feedbacks/mutationHooks';
import { DetailsFormMainContainer } from 'app/admin/components/atoms/DetailContent';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

import ArtistFeedbackCreateForm from './ArtistFeedbackCreateForm';
import ArtistFeedbackCreateFormSchema from './ArtistFeedbackCreateFormSchema';

interface Props {
  contentProps?: any;
  setFormSubmitAction: (func: Function) => void;
  setDisplayConfirmation: (set: boolean) => void;
  setIsSubmitting: (set: boolean) => void;
  navigateTo: (routeData: object) => void;
}

const geUniqueNonEmptyValues = (object: object) => [
  ...new Set(Object.values(object).filter(x => x)),
];

const ArtistFeedbackCreate: React.FC<Props> = ({
  contentProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  navigateTo,
}) => {
  const intl = useIntl();

  const pastEvents = get(
    contentProps,
    'eventData.artistEventsInfo.pastEvents.events',
    []
  ).filter((event: any) => !event.cancelled && event.eventType != 'Broadcast');

  const defaultFeedbackPhrasesIds = {
    phrase1: null,
    phrase2: null,
    phrase3: null,
  };

  const formInitialValues = {
    event: null,
    feedbackPhrasesIds: defaultFeedbackPhrasesIds,
    wouldNotBookAgain: false,
    eventOptions: pastEvents,
  };

  const createArtistFeedbackAction = CreateArtistFeedback();

  const handleCreateArtistFeedback = useSubmitAction({
    submitAction: createArtistFeedbackAction,
    submitVariables: (values: any) => ({
      eventId: values.event.id,
      artistId: get(contentProps, 'artistData.id', undefined),
      feedbackPhrasesIds: geUniqueNonEmptyValues(values.feedbackPhrasesIds),
      overallFeeling: values.wouldNotBookAgain
        ? OverallFeeling.NEGATIVE
        : OverallFeeling.POSITIVE,
    }),
    successMsg: 'Feedback added!',
    failureMsg: "We couldn't add the feedback",
    onSuccess: () => {
      navigateTo({
        routeName: 'artist-details',
        routeProps: {
          defaultOpenSection: 'feedback',
          ...contentProps.artistData,
        },
      });
    },
  });

  return (
    <ModalContentContainer>
      <DetailsFormMainContainer>
        <DetailsHeader
          title={get(contentProps, 'artistData.title', '')}
          imageUrl={get(contentProps, 'artistData.imageUrl', '')}
          largeImageUrl={get(contentProps, 'artistData.largeImageUrl', '')}
          imageStyle="circle"
        />
        <GenericForm
          formInitialValues={formInitialValues}
          renderFormComponent={(renderProps: any) => (
            <ArtistFeedbackCreateForm
              formikProps={renderProps.formikProps}
              numContactInfos={get(contentProps, 'contactInfos', []).length}
            />
          )}
          onSubmit={handleCreateArtistFeedback}
          formSchema={ArtistFeedbackCreateFormSchema(intl)}
          setFormSubmitAction={setFormSubmitAction}
          setDisplayConfirmation={setDisplayConfirmation}
          setIsSubmitting={setIsSubmitting}
          dataQaId="artist-add-feedback-form-modal"
        />
      </DetailsFormMainContainer>
    </ModalContentContainer>
  );
};

export default ArtistFeedbackCreate;
