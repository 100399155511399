import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { dataGetter } from 'app/shared/utils/queries';
import usePermission from 'app/shared/utils/usePermission';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { GetCuratorGroup } from 'app/admin/graphql/curatorGroups/queryHooks';
import { DetailsMainContainer } from 'app/admin/components/atoms/DetailContent';
import AccordionSections from 'app/admin/components/organisms/AccordionSections';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';
import { ReactComponent as InformationCircle } from 'icons/streamline-regular/interface-essential/alerts/information-circle.svg';
import { ReactComponent as MessagesBubbleSquareSmile } from 'icons/streamline-regular/messages-chat-smileys/messages-speech-bubbles/messages-bubble-square-smile.svg';
import { ReactComponent as MultipleUsers1 } from 'icons/streamline-regular/users/geometric-full-body-multiple-users/multiple-users-1.svg';

import SectionBasicInfo from './SectionBasicInfo';
import SectionEmails from './SectionEmails';
import SectionTeam from './SectionTeam';

interface Props {
  contentProps: any;
  hide: VoidFunction;
  navigateTo: (routeData: any) => void;
}

const CuratorGroupDetails: React.FC<Props> = ({
  contentProps,
  hide,
  navigateTo,
}) => {
  const { id, name } = contentProps;

  const intl = useIntl();

  const hasEditCuratorGroupPermission = usePermission(
    'curatorGroup.list.accessBasic'
  );

  const [headerData, setHeaderData] = useState({
    name,
    isLoaded: !!name,
  });

  const {
    loading: loadingBasicInfo,
    error: errorBasicInfo,
    data: dataBasicInfo,
    refetch: refetchCuratorGroup,
  } = GetCuratorGroup({
    id,
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (contentProps.shouldRefetchCuratorGroup) {
      refetchCuratorGroup();
    }
  }, [contentProps.shouldRefetchCuratorGroup, refetchCuratorGroup]);

  const sectionsConfig = {
    basicInfo: {
      title: intl.formatMessage({
        id: 'admin.curatorGroups.details.sectionTitle.basicInfo',
      }),
      icon: InformationCircle,
      iconColor: 'blueChristmas',
      sectionComponent: SectionBasicInfo,
      displayEditIcon: hasEditCuratorGroupPermission,
      loading: loadingBasicInfo,
      data: dataBasicInfo ? dataBasicInfo.curatorGroup : undefined,
      callbacks: {
        toggleSectionEditModal: () =>
          navigateTo({
            routeName: 'curator-group-basic-info-edit',
            routeProps: dataBasicInfo ? dataBasicInfo.curatorGroup : undefined,
          }),
      },
    },
    emails: {
      title: intl.formatMessage({
        id: 'admin.shared.emails',
      }),
      icon: MessagesBubbleSquareSmile,
      iconColor: 'redRedWine',
      iconCircle: true,
      sectionComponent: SectionEmails,
      displayEditIcon: hasEditCuratorGroupPermission,
      loading: loadingBasicInfo,
      data: dataBasicInfo ? dataBasicInfo.curatorGroup : undefined,
      callbacks: {
        toggleSectionEditModal: () =>
          navigateTo({
            routeName: 'curator-group-emails-edit',
            routeProps: dataBasicInfo ? dataBasicInfo.curatorGroup : undefined,
          }),
      },
    },
    team: {
      title: intl.formatMessage({
        id: 'admin.shared.team',
      }),
      icon: MultipleUsers1,
      iconColor: 'orangeCrush',
      iconCircle: true,
      sectionComponent: SectionTeam,
      loading: loadingBasicInfo,
      data: dataBasicInfo ? dataBasicInfo.curatorGroup : undefined,
      callbacks: {
        toggleAddUserModal: () =>
          navigateTo({
            routeName: 'user-role-add',
            routeProps: {
              action: 'addCuratorRoleToAnyUser',
              cityIdsToLimitBy: [],
              curatorGroupIdsToLimitBy: [id],
            },
          }),
      },
    },
  };

  useEffect(() => {
    const getDataBasicInfo = dataGetter(dataBasicInfo, 'curatorGroup');
    if (dataBasicInfo) {
      setHeaderData({
        name: getDataBasicInfo('name'),
        isLoaded: true,
      });
    }
  }, [dataBasicInfo, loadingBasicInfo]);

  if ((!loadingBasicInfo && !dataBasicInfo) || errorBasicInfo) {
    hide();
    return null;
  }

  return (
    <ModalContentContainer data-qaid="curator-group-details-main-modal">
      <DetailsMainContainer>
        <DetailsHeader title={headerData.name} loading={!headerData.isLoaded} />
        <AccordionSections
          sectionsConfig={sectionsConfig}
          defaultOpenSection={contentProps.defaultOpenSection}
        />
      </DetailsMainContainer>
    </ModalContentContainer>
  );
};

export default CuratorGroupDetails;
