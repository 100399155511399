export const sortOptions = [
  {
    title: 'Code (A - Z)',
    value: {
      by: 'code',
      direction: 'asc',
    },
  },
  {
    title: 'Code (Z - A)',
    value: {
      by: 'code',
      direction: 'desc',
    },
  },
  {
    title: 'Type (A - Z)',
    value: {
      by: 'type',
      direction: 'asc',
    },
  },
  {
    title: 'Type (Z - A)',
    value: {
      by: 'type',
      direction: 'desc',
    },
  },
  {
    title: 'Oldest Expiration First',
    value: {
      by: 'expires_at',
      direction: 'asc',
    },
  },
  {
    title: 'Newest Expiration First',
    value: {
      by: 'expires_at',
      direction: 'desc',
    },
  },
  {
    title: 'Most Recently Added',
    value: {
      by: 'created_at',
      direction: 'desc',
    },
  },
  {
    title: 'Least Recently Added',
    value: {
      by: 'created_at',
      direction: 'asc',
    },
  },
];

export const staticOptions = {
  typeGroup: [
    {
      title: 'Marketing',
      value: 'marketing_codes',
    },
    {
      title: 'User',
      value: 'user_promo_codes',
    },
    {
      title: 'Partner',
      value: 'partner_codes',
    },
  ],
  status: [
    {
      title: 'Pending Start',
      value: 'pending_start',
    },
    {
      title: 'Active',
      value: 'active',
    },
    {
      title: 'Cancelled',
      value: 'cancelled',
    },
    {
      title: 'Expired',
      value: 'expired',
    },
  ],
  expiresAt: [
    {
      title: 'Next 7 Days',
      value: 'expires_next_7_days',
    },
    {
      title: 'Next 30 Days',
      value: 'expires_next_30_days',
    },
    {
      title: 'Next 90 Days',
      value: 'expires_next_90_days',
    },
  ],
};
