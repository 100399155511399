import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import NoneTag from 'app/admin/components/atoms/NoneTag';
import ToggleText from 'app/admin/components/atoms/ToggleText';

interface TagElementListProps {
  tagElements?: any;
  tagElementColor: string;
  textColor?: string;
  containerHeight?: string;
  displayShowAllLink: boolean;
}

const ListContainer = styled.div<{
  fullContent: boolean;
  containerHeight?: string;
}>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  margin-top: 3px;

  ${props =>
    !props.fullContent &&
    `
    overflow: hidden;
    height: 57px;
  `}

  ${props =>
    props.containerHeight &&
    `
    height: ${props.containerHeight};
    overflow: auto;
  `}
`;

export const TagElement = styled.div<{
  tagElementColor: string;
  textColor: string;
  marginBottom?: number;
}>`
  height: 16px;
  top: calc(50% - 16px / 2);
  padding: 0px 8px 1px 8px;
  margin-right: 3px;
  margin-bottom: ${props =>
    props.marginBottom !== undefined ? props.marginBottom : 3}px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  flex-shrink: 0;
  color: ${props => props.textColor};
  background: ${props => props.tagElementColor};
`;

const ToggleContainer = styled.div`
  margin-top: -2px;
`;

const renderTagElements = (
  tagElements: any,
  tagElementColor: string,
  textColor: string
) => {
  return tagElements.map((tagElement: any, i: number) => (
    <TagElement
      key={i}
      tagElementColor={tagElementColor}
      textColor={textColor}
      data-qaid={`tag-element-${i}`}
    >
      {tagElement.name}
    </TagElement>
  ));
};

export const TagElementList: React.FC<TagElementListProps> = ({
  tagElements = [],
  tagElementColor,
  containerHeight,
  textColor = '#ffffff',
  displayShowAllLink,
}) => {
  const [contentOverflows, setContentOverflows] = useState(false);
  const [showFullList, setShowFullList] = useState(false);
  const listContainerRef = useRef<any>();

  const handleToggleShowAll = () => {
    if (displayShowAllLink) {
      setShowFullList(!showFullList);
    }
  };

  useEffect(() => {
    const { scrollHeight, offsetHeight } = listContainerRef.current;
    if (scrollHeight > offsetHeight) {
      setContentOverflows(true);
    } else {
      setContentOverflows(false);
    }
  }, [displayShowAllLink, tagElements]);

  return (
    <div>
      <ListContainer
        ref={listContainerRef}
        containerHeight={containerHeight}
        fullContent={showFullList}
      >
        {!tagElements || tagElements.length == 0 ? (
          <NoneTag>NONE</NoneTag>
        ) : (
          renderTagElements(tagElements, tagElementColor, textColor)
        )}
      </ListContainer>
      {displayShowAllLink && contentOverflows && (
        <ToggleContainer data-qaid="tag-element-toggle">
          <ToggleText onClick={() => handleToggleShowAll()}>
            {showFullList ? 'LESS' : 'MORE'}
          </ToggleText>
        </ToggleContainer>
      )}
    </div>
  );
};
