import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { KeyValue } from 'app/typings/generics';
import { dateFormatter } from 'app/shared/utils/datetime';
import useModal from 'app/shared/utils/useModal';
import usePermission from 'app/shared/utils/usePermission';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import ConfirmationModal from 'app/shared/components/molecules/ConfirmationModal';
import { DeleteBroadcast } from 'app/admin/graphql/broadcasts/mutationHooks';
import { KeyAboveValueInfoList } from 'app/admin/components/molecules/KeyAboveValueInfoList';
import ListingCard from 'app/admin/components/organisms/ListingCard';
import { ReactComponent as Bin1 } from 'icons/streamline-regular/interface-essential/delete/bin-1.svg';
import { ReactComponent as Pencil } from 'icons/streamline-regular/interface-essential/edit/pencil.svg';

interface BroadcastCardProps {
  id: number;
  index: number;
  scheduledStartTimeUtc: string;
  artistId: number;
  artistTitle: string;
  location: string;
  streamType: string;
  embedUrl: string;
  extraInfoUrl: string;
  linkType: string;
  partnershipTag: string;
  hideDonation: boolean;
  donationText: string;
  onEditBroadcast: (broadcast: any) => void;
  refetchBroadcasts?: Function;
}

const TopContentContainer = styled.div`
  height: 310px;
`;

const renderTopContent = (keysAboveValuesList1: KeyValue[]) => {
  return (
    <TopContentContainer>
      <KeyAboveValueInfoList
        keysAndValues={keysAboveValuesList1}
        valueTruncateCharLength={20}
      />
    </TopContentContainer>
  );
};

const dateFormat = 'shortMonthDateAndYear';

const timeFormat = 'h:mm a zzz';

const datetimeFormat =
  'shortMonthDateYearHoursAndMinutesWithSpacedAMPMWithTimezone';

const BroadcastCard: React.FC<BroadcastCardProps> = ({
  id,
  scheduledStartTimeUtc,
  artistId,
  artistTitle,
  location,
  streamType,
  embedUrl,
  extraInfoUrl,
  linkType,
  partnershipTag,
  hideDonation,
  donationText,
  onEditBroadcast,
  refetchBroadcasts,
}) => {
  const intl = useIntl();
  const [deleteBroadcastModal, toggleDeleteBroadcastModal] = useModal();
  const hasViewArtistsPermission = usePermission('artist.list.view');

  const keysAboveValuesList = [
    {
      key: intl.formatMessage({
        id: 'shared.artist',
      }),
      value: artistTitle,
      url: hasViewArtistsPermission
        ? `/admin/artists?artist_id=${artistId}`
        : undefined,
    },
    {
      key: intl.formatMessage({
        id: 'admin.broadcasts.listingCard.location',
      }),
      value: location,
    },
    {
      key: intl.formatMessage({
        id: 'admin.broadcasts.listingCard.streamType',
      }),
      value: streamType,
    },
    {
      key: intl.formatMessage({
        id: 'admin.broadcasts.listingCard.embedUrl',
      }),
      value: embedUrl,
      url: embedUrl,
    },
    {
      key: intl.formatMessage({
        id: 'admin.broadcasts.listingCard.extraInfoUrl',
      }),
      value: extraInfoUrl || 'None',
      url: extraInfoUrl || undefined,
    },
  ];

  const keysAndValuesList = [
    {
      key: intl.formatMessage({
        id: 'admin.broadcasts.listingCard.linkType',
      }),
      value: linkType,
    },
    {
      key: intl.formatMessage({
        id: 'admin.broadcasts.listingCard.partnershipTag',
      }),
      value: partnershipTag || 'None',
      valueTruncateCharLength: 5,
    },
    {
      key: intl.formatMessage({
        id: 'admin.broadcasts.listingCard.hideDonation',
      }),
      value: hideDonation
        ? intl.formatMessage({ id: 'yes' })
        : intl.formatMessage({ id: 'no' }),
    },
    {
      key: intl.formatMessage({
        id: 'admin.broadcasts.listingCard.donationText',
      }),
      value: donationText,
      valueTruncateCharLength: 7,
    },
  ];

  const controlsInfoList = [
    {
      action: () => {
        onEditBroadcast({
          id,
          scheduledStartTimeUtc,
          artistId,
          artistTitle,
          location,
          streamType,
          embedUrl,
          extraInfoUrl,
          linkType,
          partnershipTag,
          hideDonation,
          donationText,
        });
      },
      icon: Pencil,
      tipText: intl.formatMessage({
        id: 'admin.broadcasts.listingCard.broadcastEdit',
      }),
    },
    {
      action: toggleDeleteBroadcastModal,
      icon: Bin1,
      tipText: intl.formatMessage({
        id: 'admin.broadcasts.listingCard.broadcastDelete',
      }),
    },
  ];

  const deleteBroadcastAction = DeleteBroadcast();

  const handleDeleteBroadcast = useSubmitAction({
    submitAction: deleteBroadcastAction,
    submitVariables: () => ({ broadcastId: id }),
    successMsg: intl.formatMessage({
      id: 'admin.broadcasts.listingCard.deleteSuccessMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.broadcasts.listingCard.deleteFailureMessage',
    }),
    onSuccess: () => {
      deleteBroadcastModal.hide();
      refetchBroadcasts && refetchBroadcasts();
    },
  });

  const startDate = dateFormatter(scheduledStartTimeUtc, dateFormat);
  const startTime = dateFormatter(scheduledStartTimeUtc, timeFormat);
  const startDatetime = dateFormatter(scheduledStartTimeUtc, datetimeFormat);

  return (
    <>
      <ListingCard
        title={startDate}
        subtitle={startTime}
        topContent={() => renderTopContent(keysAboveValuesList)}
        keysAndValuesList={keysAndValuesList}
        dataQaidPrefix="broadcast"
        height="470px"
        controlsInfoList={controlsInfoList}
      />
      {deleteBroadcastModal.isShowing && (
        <ConfirmationModal
          onCancel={() => deleteBroadcastModal.hide()}
          description={intl.formatMessage(
            {
              id: 'admin.broadcasts.listingCard.deleteConfirmation',
            },
            {
              startDatetime,
              location,
            }
          )}
          onConfirm={handleDeleteBroadcast}
        />
      )}
    </>
  );
};

export default BroadcastCard;
