import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { dateFormatter } from 'app/shared/utils/datetime';
import { get } from 'app/shared/utils/get';
import useModal from 'app/shared/utils/useModal';
import usePermission from 'app/shared/utils/usePermission';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import { ListPage, ListPageContext } from 'app/shared/context/ListPage';
import { BaseLink } from 'app/shared/components/atoms/LinkManualCSS';
import { LoadingBlocks } from 'app/shared/components/atoms/LoadingBlocks';
import TruncatedByCharText from 'app/shared/components/atoms/TruncatedByCharText';
import ConfirmationModal from 'app/shared/components/molecules/ConfirmationModal';
import RoutableModal from 'app/shared/components/molecules/RoutableModal';
import { GlobalError } from 'app/shared/components/pages/Status';
import {
  getAgencyOptions,
  getFilterNamesAndOptionsForTitleMapping,
  getPartnerOptions,
} from 'app/admin/utils/optionLists';
import { GetAgencies } from 'app/admin/graphql/agencies/queryHooks';
import { ArchiveCampaign } from 'app/admin/graphql/campaigns/mutationHooks';
import { GetCampaignsFullInfo } from 'app/admin/graphql/campaigns/queryHooks';
import { GetPartners } from 'app/admin/graphql/partners/queryHooks';
import { UserLink } from 'app/admin/components/molecules/EntityLink';
import ListingFooter from 'app/admin/components/molecules/ListingFooter';
import ListingNoResults from 'app/admin/components/molecules/ListingNoResults';
import ListingControls from 'app/admin/components/organisms/ListingControls';
import ListingFilter from 'app/admin/components/organisms/ListingFilter';
import ListingHeader from 'app/admin/components/organisms/ListingHeader';
import ListTable from 'app/admin/components/organisms/ListTable';
import ListPageTemplate from 'app/admin/components/templates/ListPage';
import Layout from 'app/admin/layouts/ListPage';

import { sortOptions, staticOptions } from './options';

const pageStateConfig = {
  filterNames: ['archived_status', 'partner', 'agency'],
  defaultSort: { by: 'title', direction: 'asc' },
  textSearchParamName: 'campaign_search',
};

interface PromoCodesLinkProps {
  campaignId: number;
  promoCodesCount?: number;
}

const PromoCodesLinked = styled.div`
  ${({ theme }) => css`
    a {
      color: ${theme.colors.green600};
    }
  `}
`;

const PromoCodesLink: React.FC<PromoCodesLinkProps> = ({
  campaignId,
  promoCodesCount,
}) => {
  const hasAccessPromoCodesPermission = usePermission('promoCode.access');

  return (
    <>
      {hasAccessPromoCodesPermission ? (
        <PromoCodesLinked>
          <BaseLink
            href={`/admin/promo-codes?campaign=${campaignId}`}
            openInNewTab={true}
          >
            {promoCodesCount}
          </BaseLink>
        </PromoCodesLinked>
      ) : (
        <div>{promoCodesCount}</div>
      )}
    </>
  );
};

const AdminCampaigns: React.FC = () => {
  const intl = useIntl();
  const pageState = useContext(ListPageContext);
  const PER_PAGE = 25;

  const {
    data: dataPartners,
    loading: loadingPartners,
    error: errorPartners,
  } = GetPartners({
    archivedStatus: 'not_archived',
    skipPagination: true,
  });

  const {
    data: dataAgencies,
    loading: loadingAgencies,
    error: errorAgencies,
  } = GetAgencies({
    archivedStatus: 'not_archived',
    skipPagination: true,
  });

  const partnerOptions = getPartnerOptions(dataPartners);
  const agencyOptions = getAgencyOptions(dataAgencies);

  const [addCampaignModal, toggleAddCampaignModal] = useModal();
  const [editCampaignModal, toggleEditCampaignModal] = useModal();
  const [archiveCampaignModal, toggleArchiveCampaignModal] = useModal();
  const [campaignData, setCampaignData] = useState<any>(undefined);

  const filterDropdownOptionsInfoList = [
    {
      filterName: 'archived_status',
      dropdownParams: {
        title: intl.formatMessage({
          id: 'admin.campaigns.filter.archived',
        }),
        options: staticOptions.archivedStatus,
      },
    },
    {
      filterName: 'partner',
      dropdownParams: {
        title: intl.formatMessage({
          id: 'shared.partner',
        }),
        options: partnerOptions,
      },
    },
    {
      filterName: 'agency',
      dropdownParams: {
        title: intl.formatMessage({
          id: 'admin.campaigns.filter.agency',
        }),
        options: agencyOptions,
      },
    },
  ];

  const {
    loading,
    error,
    data,
    refetch: refetchCampaignsFullInfo,
  } = GetCampaignsFullInfo({
    campaignSearch: pageState.textSearchState,
    archivedStatus:
      pageState.filterListVariable('archived_status') || 'not_archived',
    partner: pageState.filterListVariable('partner'),
    agency: pageState.filterListVariable('agency'),
    orderBy: pageState.sortState.by,
    orderDirection: pageState.sortState.direction,
    page: pageState.page,
    perPage: PER_PAGE,
  });

  const archiveCampaignAction = ArchiveCampaign();

  const handleArchiveCampaign = useSubmitAction({
    submitAction: archiveCampaignAction,
    submitVariables: () => ({
      id: campaignData && campaignData.id,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.campaigns.archiveSuccess',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.campaigns.archiveFailure',
    }),
    onSuccess: () => {
      refetchCampaignsFullInfo();
      archiveCampaignModal.hide();
    },
  });

  useEffect(() => {
    pageState.setupFilterLabelTitleMapping(
      getFilterNamesAndOptionsForTitleMapping(filterDropdownOptionsInfoList)
    );
  }, [data, dataPartners, dataAgencies]);

  useEffect(() => {
    pageState.updatePageUrl();
  }, [
    pageState.page,
    pageState.sortState,
    pageState.filterState,
    pageState.textSearchState,
    pageState.detailData,
    pageState.detailsModal.isShowing,
  ]);

  if (
    (!loading && !data) ||
    error ||
    (!loadingPartners && !dataPartners) ||
    errorPartners ||
    (!loadingAgencies && !dataAgencies) ||
    errorAgencies
  ) {
    return <GlobalError />;
  }

  const columnsConfig = [
    {
      headerText: intl.formatMessage({
        id: 'shared.title',
      }),
      columnWidth: 170,
    },
    {
      headerText: intl.formatMessage({
        id: 'shared.partner',
      }),
      columnWidth: 140,
    },
    {
      headerText: intl.formatMessage({
        id: 'admin.campaigns.agency',
      }),
      columnWidth: 140,
    },
    {
      headerText: intl.formatMessage({
        id: 'admin.campaigns.accountManager',
      }),
      columnWidth: 120,
    },
    {
      headerText: intl.formatMessage({
        id: 'admin.campaigns.salesRep',
      }),
      columnWidth: 120,
    },
    {
      headerText: intl.formatMessage({
        id: 'admin.campaigns.promoCodesWithCampaign',
      }),
      columnWidth: 100,
    },
    {
      headerText: intl.formatMessage({
        id: 'admin.campaigns.pastEventsWithCampaign',
      }),
      columnWidth: 100,
    },
    {
      headerText: intl.formatMessage({
        id: 'admin.campaigns.upcomingEventsWithCampaign',
      }),
      columnWidth: 120,
    },
    {
      headerText: intl.formatMessage({
        id: 'admin.shared.added',
      }),
      columnWidth: 120,
    },
  ];

  let campaignsData = get(data, 'campaigns.campaigns', []);

  const rowValues = (campaign: any) => [
    <TruncatedByCharText text={campaign.title} truncateLength={17} key={1} /> ||
      '',
    campaign.partner ? (
      <TruncatedByCharText text={campaign.partner.title} truncateLength={14} />
    ) : (
      ''
    ),
    campaign.agency ? (
      <TruncatedByCharText text={campaign.agency.title} truncateLength={14} />
    ) : (
      ''
    ),
    campaign.accountManagerUser ? (
      <UserLink user={campaign.accountManagerUser} truncateLength={12} />
    ) : (
      ''
    ),
    campaign.salesRepUser ? (
      <UserLink user={campaign.salesRepUser} truncateLength={12} />
    ) : (
      ''
    ),
    campaign.promoCodesWithCampaignCount ? (
      <PromoCodesLink
        campaignId={campaign.id}
        promoCodesCount={campaign.promoCodesWithCampaignCount}
      />
    ) : (
      '0'
    ),
    campaign.pastEventsWithCampaignCount,
    campaign.upcomingEventsWithCampaignCount,
    campaign.createdAt &&
      dateFormatter(campaign.createdAt, 'shortMonthDateAndYear'),
  ];

  const toggleEditCampaignAndSetCampaignData = (campaignData: any) => {
    setCampaignData(campaignData);
    toggleEditCampaignModal();
  };

  const toggleArchiveCampaignAndSetCampaignData = (campaignData: any) => {
    setCampaignData(campaignData);
    toggleArchiveCampaignModal();
  };

  const modalsContent = () => (
    <>
      {addCampaignModal.isShowing && (
        <RoutableModal
          hide={addCampaignModal.hide}
          initialRouteProps={{ refetchCampaigns: refetchCampaignsFullInfo }}
          initialRouteName="campaign-create"
          dataQaidSuffix="admin-create-campaign"
        />
      )}
      {editCampaignModal.isShowing && (
        <RoutableModal
          hide={editCampaignModal.hide}
          initialRouteProps={{
            ...campaignData,
            refetchCampaigns: refetchCampaignsFullInfo,
          }}
          initialRouteName="campaign-edit"
          dataQaidSuffix="admin-edit-campagin"
        />
      )}
    </>
  );

  return (
    <Layout scrollDisabled={pageState.detailsModal.isShowing}>
      <ListPageTemplate
        pageTitle={intl.formatMessage({
          id: 'admin.campaigns.pageTitle',
        })}
        modalsContent={modalsContent}
      >
        <ListingHeader
          pageTitle={intl.formatMessage({
            id: 'admin.campaigns.pageTitle',
          })}
          addEntityText={intl.formatMessage({
            id: 'admin.campaigns.addCampaign',
          })}
          onClickAddEntity={toggleAddCampaignModal}
          searchPlaceholder={intl.formatMessage({
            id: 'admin.campaigns.textSearch.placeholder',
          })}
          searchValue={pageState.textSearchState}
          onSearch={pageState.handleTextSearch}
          dataQaidPrefix="campaign"
        />
        <div>
          <ListingControls
            sortOptions={sortOptions}
            orderBy={pageState.sortState.by}
            orderDirection={pageState.sortState.direction}
            totalRecords={data && data.campaigns.metadata.totalRecords}
            loading={loading}
            onSort={pageState.handleSortChange}
            onReset={pageState.handleResetFilters}
            dataQaidPrefix="campaign"
          />
          <ListingFilter
            filterTitle={intl.formatMessage({
              id: 'admin.campaigns.filterTitle',
            })}
            textSearchString={pageState.textSearchState}
            handleTextSearchLabelClose={pageState.handleTextSearchLabelClose}
            labelTitleMapping={pageState.filterLabelTitleMapping}
            dropdownOptionsInfoList={filterDropdownOptionsInfoList}
            filterState={pageState.filterState}
            handleRemoveFilter={pageState.handleRemoveFilter}
            handleFilterChange={pageState.handleFilterChange}
          />
        </div>

        <ListTable
          columnsConfig={columnsConfig}
          rowValues={rowValues}
          onEdit={
            pageState.filterListVariable('archived_status') != 'archived'
              ? (obj: any) => toggleEditCampaignAndSetCampaignData({ ...obj })
              : undefined
          }
          onArchive={
            pageState.filterListVariable('archived_status') != 'archived'
              ? (obj: any) =>
                  toggleArchiveCampaignAndSetCampaignData({
                    id: obj.id,
                    title: obj.title,
                    upcomingEventsWithCampaignCount:
                      obj.upcomingEventsWithCampaignCount,
                  })
              : undefined
          }
          data={campaignsData}
          loadingContent={
            loading ? (
              <LoadingBlocks.Rectangle width="100%" height="300px" />
            ) : null
          }
          noResultsContent={
            <ListingNoResults
              entityName={intl.formatMessage({
                id: 'admin.campaigns.noResultsEntityName',
              })}
              numResults={get(data, 'campaigns.campaigns.length', undefined)}
              loading={loading}
            />
          }
          footerContent={
            <ListingFooter
              numTotalRecords={get(
                data,
                'campaigns.metadata.totalRecords',
                undefined
              )}
              perPage={PER_PAGE}
              currentPage={pageState.page}
              onPageChange={pageState.handlePageChange}
              loading={loading}
              dataQaidPrefix="campaign"
            />
          }
        />
        {archiveCampaignModal.isShowing && (
          <>
            {campaignData &&
            campaignData.upcomingEventsWithCampaignCount > 0 ? (
              <ConfirmationModal
                confirmationButtonText={intl.formatMessage({
                  id: 'okay',
                })}
                description={intl.formatMessage(
                  {
                    id: 'admin.campaigns.cannotArchiveNote',
                  },
                  {
                    title: campaignData && campaignData.title,
                    upcomingEventsWithCampaignCount:
                      campaignData &&
                      campaignData.upcomingEventsWithCampaignCount.toString(),
                  }
                )}
                onConfirm={() => archiveCampaignModal.hide()}
                onClose={() => archiveCampaignModal.hide()}
              />
            ) : (
              <ConfirmationModal
                onCancel={() => archiveCampaignModal.hide()}
                description={intl.formatMessage(
                  {
                    id: 'admin.campaigns.archiveConfirmation',
                  },
                  {
                    title: campaignData && campaignData.title,
                  }
                )}
                onConfirm={handleArchiveCampaign}
              />
            )}
          </>
        )}
      </ListPageTemplate>
    </Layout>
  );
};

const AdminCampaignsWrapper: React.FC = () => (
  <ListPage config={pageStateConfig}>
    <AdminCampaigns />
  </ListPage>
);

export default AdminCampaignsWrapper;
