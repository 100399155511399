import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { dateFormatter } from 'app/shared/utils/datetime';
import { get } from 'app/shared/utils/get';
import useModal from 'app/shared/utils/useModal';
import usePermission from 'app/shared/utils/usePermission';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import { ListPage, ListPageContext } from 'app/shared/context/ListPage';
import { LoadingBlocks } from 'app/shared/components/atoms/LoadingBlocks';
import TruncatedByCharText from 'app/shared/components/atoms/TruncatedByCharText';
import ConfirmationModal from 'app/shared/components/molecules/ConfirmationModal';
import RoutableModal from 'app/shared/components/molecules/RoutableModal';
import { GlobalError } from 'app/shared/components/pages/Status';
import { getFilterNamesAndOptionsForTitleMapping } from 'app/admin/utils/optionLists';
import { ArchiveBusinessOwner } from 'app/admin/graphql/businessOwners/mutationHooks';
import { GetBusinessOwners } from 'app/admin/graphql/businessOwners/queryHooks';
import ListingFooter from 'app/admin/components/molecules/ListingFooter';
import ListingNoResults from 'app/admin/components/molecules/ListingNoResults';
import ListingControls from 'app/admin/components/organisms/ListingControls';
import ListingFilter from 'app/admin/components/organisms/ListingFilter';
import ListingHeader from 'app/admin/components/organisms/ListingHeader';
import ListTable from 'app/admin/components/organisms/ListTable';
import ListPageTemplate from 'app/admin/components/templates/ListPage';
import Layout from 'app/admin/layouts/ListPage';

import { sortOptions, staticOptions } from './options';

const pageStateConfig = {
  filterNames: ['archived_status'],
  defaultSort: { by: 'title', direction: 'asc' },
  textSearchParamName: 'business_owner_search',
};

const AdminBusinessOwners: React.FC = () => {
  const intl = useIntl();
  const pageState = useContext(ListPageContext);
  const PER_PAGE = 25;

  const hasEditBusinessOwnerPermission = usePermission('businessOwner.edit');

  const [addBusinessOwnerModal, toggleAddBusinessOwnerModal] = useModal();
  const [editBusinessOwnerModal, toggleEditBusinessOwnerModal] = useModal();
  const [
    archiveBusinessOwnerModal,
    toggleArchiveBusinessOwnerModal,
  ] = useModal();
  const [businessOwnerData, setBusinessOwnerData] = useState<any>(undefined);

  const filterDropdownOptionsInfoList = [
    {
      filterName: 'archived_status',
      dropdownParams: {
        title: intl.formatMessage({
          id: 'shared.archived',
        }),
        options: staticOptions.archivedStatus,
      },
    },
  ];

  const {
    loading,
    error,
    data,
    refetch: refetchBusinessOwners,
  } = GetBusinessOwners({
    archivedStatus:
      pageState.filterListVariable('archived_status') || 'not_archived',
    businessOwnerSearch: pageState.textSearchState,
    orderBy: pageState.sortState.by,
    orderDirection: pageState.sortState.direction,
    page: pageState.page,
    perPage: PER_PAGE,
  });

  const archiveBusinessOwnerAction = ArchiveBusinessOwner();

  const handleArchiveBusinessOwner = useSubmitAction({
    submitAction: archiveBusinessOwnerAction,
    submitVariables: () => ({
      id: businessOwnerData && businessOwnerData.id,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.businessOwners.archiveSuccess',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.businessOwners.archiveFailure',
    }),
    onSuccess: () => {
      refetchBusinessOwners();
      archiveBusinessOwnerModal.hide();
    },
  });

  useEffect(() => {
    pageState.setupFilterLabelTitleMapping(
      getFilterNamesAndOptionsForTitleMapping(filterDropdownOptionsInfoList)
    );
  }, [data]);

  useEffect(() => {
    pageState.updatePageUrl();
  }, [
    pageState.page,
    pageState.sortState,
    pageState.filterState,
    pageState.textSearchState,
    pageState.detailData,
    pageState.detailsModal.isShowing,
  ]);

  if ((!loading && !data) || error) {
    return <GlobalError />;
  }

  const columnsConfig = [
    {
      headerText: intl.formatMessage({
        id: 'admin.businessOwners.businessOwner',
      }),
      columnWidth: 350,
    },
    {
      headerText: intl.formatMessage({
        id: 'admin.businessOwners.citiesUsingBusinessOwner',
      }),
      columnWidth: 200,
    },
    {
      headerText: intl.formatMessage({
        id: 'admin.shared.added',
      }),
      columnWidth: 120,
    },
  ];

  let businessOwnersData = get(data, 'businessOwners.businessOwners', []);

  const rowValues = (businessOwner: any) => [
    <TruncatedByCharText
      text={businessOwner.title}
      truncateLength={30}
      key={1}
    />,
    businessOwner.citiesCount || 0,
    businessOwner.createdAt &&
      dateFormatter(businessOwner.createdAt, 'shortMonthDateAndYear'),
  ];

  const toggleEditBusinessOwnerAndSetBusinessOwnerData = (
    businessOwnerData: any
  ) => {
    setBusinessOwnerData(businessOwnerData);
    toggleEditBusinessOwnerModal();
  };

  const toggleArchiveBusinessOwnerAndSetBusinessOwnerData = (
    businessOwnerData: any
  ) => {
    setBusinessOwnerData(businessOwnerData);
    toggleArchiveBusinessOwnerModal();
  };

  const modalsContent = () => (
    <>
      {addBusinessOwnerModal.isShowing && (
        <RoutableModal
          hide={addBusinessOwnerModal.hide}
          initialRouteProps={{ refetchBusinessOwners }}
          initialRouteName="business-owner-create"
          dataQaidSuffix="admin-create-business-owner"
        />
      )}
      {editBusinessOwnerModal.isShowing && (
        <RoutableModal
          hide={editBusinessOwnerModal.hide}
          initialRouteProps={{
            ...businessOwnerData,
            refetchBusinessOwners,
          }}
          initialRouteName="business-owner-edit"
          dataQaidSuffix="admin-edit-business-owner"
        />
      )}
    </>
  );

  return (
    <Layout scrollDisabled={pageState.detailsModal.isShowing}>
      <ListPageTemplate
        pageTitle={intl.formatMessage({
          id: 'admin.businessOwners.pageTitle',
        })}
        modalsContent={modalsContent}
      >
        <ListingHeader
          pageTitle={intl.formatMessage({
            id: 'admin.businessOwners.pageTitle',
          })}
          addEntityText={
            hasEditBusinessOwnerPermission
              ? intl.formatMessage({
                  id: 'admin.businessOwners.addBusinessOwner',
                })
              : undefined
          }
          onClickAddEntity={
            hasEditBusinessOwnerPermission
              ? toggleAddBusinessOwnerModal
              : undefined
          }
          searchPlaceholder={intl.formatMessage({
            id: 'admin.businessOwners.textSearch.placeholder',
          })}
          searchValue={pageState.textSearchState}
          onSearch={pageState.handleTextSearch}
          dataQaidPrefix="business-owner"
        />
        <div>
          <ListingControls
            sortOptions={sortOptions}
            orderBy={pageState.sortState.by}
            orderDirection={pageState.sortState.direction}
            totalRecords={data && data.businessOwners.metadata.totalRecords}
            loading={loading}
            onSort={pageState.handleSortChange}
            onReset={pageState.handleResetFilters}
            dataQaidPrefix="business-owner"
          />
          <ListingFilter
            filterTitle={intl.formatMessage({
              id: 'admin.businessOwners.filterTitle',
            })}
            textSearchString={pageState.textSearchState}
            handleTextSearchLabelClose={pageState.handleTextSearchLabelClose}
            labelTitleMapping={pageState.filterLabelTitleMapping}
            dropdownOptionsInfoList={filterDropdownOptionsInfoList}
            filterState={pageState.filterState}
            handleRemoveFilter={pageState.handleRemoveFilter}
            handleFilterChange={pageState.handleFilterChange}
          />
        </div>
        <ListTable
          columnsConfig={columnsConfig}
          rowValues={rowValues}
          onEdit={
            hasEditBusinessOwnerPermission &&
            pageState.filterListVariable('archived_status') != 'false'
              ? (obj: any) =>
                  toggleEditBusinessOwnerAndSetBusinessOwnerData({ ...obj })
              : undefined
          }
          onArchive={
            hasEditBusinessOwnerPermission &&
            pageState.filterListVariable('archived_status') != 'archived'
              ? (obj: any) =>
                  toggleArchiveBusinessOwnerAndSetBusinessOwnerData({
                    id: obj.id,
                    title: obj.title,
                    citiesCount: obj.citiesCount,
                  })
              : undefined
          }
          data={businessOwnersData}
          loadingContent={
            loading ? (
              <LoadingBlocks.Rectangle width="100%" height="300px" />
            ) : null
          }
          noResultsContent={
            <ListingNoResults
              entityName={intl.formatMessage({
                id: 'admin.businessOwners.noResultsEntityName',
              })}
              numResults={get(
                data,
                'businessOwners.businessOwners.length',
                undefined
              )}
              loading={loading}
            />
          }
          footerContent={
            <ListingFooter
              numTotalRecords={get(
                data,
                'businessOwners.metadata.totalRecords',
                undefined
              )}
              perPage={PER_PAGE}
              currentPage={pageState.page}
              onPageChange={pageState.handlePageChange}
              loading={loading}
              dataQaidPrefix="business-owners"
            />
          }
        />
        {archiveBusinessOwnerModal.isShowing && (
          <>
            {businessOwnerData && businessOwnerData.citiesCount > 0 ? (
              <ConfirmationModal
                confirmationButtonText={intl.formatMessage({
                  id: 'okay',
                })}
                description={intl.formatMessage(
                  {
                    id: 'admin.businessOwners.cannotArchiveNote',
                  },
                  {
                    title: businessOwnerData && businessOwnerData.title,
                    businessOwnerCitiesCount:
                      businessOwnerData &&
                      businessOwnerData.citiesCount.toString(),
                  }
                )}
                onConfirm={() => archiveBusinessOwnerModal.hide()}
                onClose={() => archiveBusinessOwnerModal.hide()}
              />
            ) : (
              <ConfirmationModal
                onCancel={() => archiveBusinessOwnerModal.hide()}
                description={intl.formatMessage(
                  {
                    id: 'admin.businessOwners.archiveConfirmation',
                  },
                  {
                    title: businessOwnerData && businessOwnerData.title,
                  }
                )}
                onConfirm={handleArchiveBusinessOwner}
              />
            )}
          </>
        )}
      </ListPageTemplate>
    </Layout>
  );
};

const AdminBusinessOwnersWrapper: React.FC = () => (
  <ListPage config={pageStateConfig}>
    <AdminBusinessOwners />
  </ListPage>
);

export default AdminBusinessOwnersWrapper;
