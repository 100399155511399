import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { dateFormatter } from 'app/shared/utils/datetime';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import { DeleteIcon } from 'app/shared/components/atoms/IconLibrary';
import { EditIcon } from 'app/shared/components/atoms/IconLibrary';
import LazyImgixImage from 'app/shared/components/atoms/LazyImgixLoad';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import TruncatedByCharText from 'app/shared/components/atoms/TruncatedByCharText';
import AddIconAndText from 'app/admin/components/atoms/AddIconAndText';
import ExpandableText from 'app/admin/components/atoms/ExpandableText';

interface Props {
  sectionData: any;
}

const SectionText = styled.div`
  font-size: 14px;
`;

const NoResults = styled.div`
  ${({ theme }) => css`
    font-size: 14px;
    color: ${theme.colors.blueSmoke};
    text-align: center;
  `}
`;

const Header = styled.div`
  justify-content: left;
  align-items: flex-start;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const Container = styled.div`
  ${({ theme }) => css`
    border-radius: 10px;
    height: 160px;
    overflow: hidden;
    cursor: pointer;

    ${theme.media.xs`
      width: 270px;
    `}

    ${theme.media.md`
      width: 240px;
    `}
  `}
`;

const StyledCol = styled(Col)`
  ${({ theme }) => css`
    ${theme.media.md`
      margin-right: 35px;
    `};

    margin-bottom: 35px;
  `}
`;

const GrayItalic = styled.div`
  ${({ theme }) => css`
    font-style: italic;
    color: ${theme.colors.blueSmoke};
  `}
`;

const Subheading = styled.span`
  font-weight: 600;
`;

const SectionPhotos: React.FC<Props> = ({ sectionData }) => {
  const intl = useIntl();
  const {
    venue,
    setVenuePhoto,
    togglePreviewConfirmationModal,
    navigateTo,
  } = sectionData;

  return (
    <>
      <Header>
        {venue.city?.cityOperatingModel !== 'curator' && (
          <>
            <SectionText>
              {intl.formatMessage({
                id: 'admin.eventPlanner.venueInfo.photosVisible',
              })}
            </SectionText>
            <Spacer mt={8} />
          </>
        )}
        <AddIconAndText
          text={intl.formatMessage({
            id: 'admin.eventPlanner.venueInfo.photo',
          })}
          textSizeType="medium"
          sentenceCaseText={true}
          onClick={() =>
            navigateTo({
              routeName: 'venue-setup-photo-uploader',
              routeProps: {
                venueId: venue?.id,
                venueName: venue?.venueName,
                headerImageUrl: venue?.imageUrl,
                venuePhoto: undefined,
              },
            })
          }
        />
      </Header>
      <Spacer mt={8} />
      {venue.venuePhotos?.length === 0 && (
        <NoResults>
          {intl.formatMessage({
            id: 'admin.eventPlanner.venueInfo.noPhotos',
          })}
        </NoResults>
      )}
      <MainContainer>
        <Grid cols={4} gap="30">
          {venue.venuePhotos?.map((photo: any, index: number) => (
            <StyledCol xs={12} sm={1} md={1} lg={1} xl={1} key={index}>
              <Container
                onClick={() => {
                  navigateTo({
                    routeName: 'venue-photo-carousel',
                    routeProps: {
                      photos: venue.venuePhotos,
                      venueId: venue?.id,
                      venueName: venue?.venueName,
                      headerImageUrl: venue?.imageUrl,
                      timezone: venue.city?.timezone,
                      index,
                      navigateTo,
                    },
                  });
                }}
              >
                <LazyImgixImage
                  src={photo.imageUrl || ''}
                  sizes="240px"
                  aspectRatio="1.5:1"
                  dataQaid="venue-image"
                />
              </Container>
              <Spacer mt={3} />
              <SectionText>
                <Grid gap="0px">
                  <Col xs={10}>
                    <Subheading>
                      <TruncatedByCharText
                        text={photo.title || ''}
                        truncateLength={60}
                      />
                    </Subheading>
                  </Col>
                  <Col xs={1}>
                    <EditIcon
                      iconSize={14}
                      onClick={() =>
                        navigateTo({
                          routeName: 'venue-setup-photo-uploader',
                          routeProps: {
                            venueId: venue?.id,
                            venueName: venue?.venueName,
                            headerImageUrl: venue?.imageUrl,
                            venuePhoto: venue?.venuePhotos[index],
                          },
                        })
                      }
                    />
                  </Col>
                  <Col xs={1}>
                    <DeleteIcon
                      iconSize={14}
                      onClick={() => {
                        setVenuePhoto({ id: photo.id, name: photo.title });
                        togglePreviewConfirmationModal();
                      }}
                      data-qaid="delete-image"
                    />
                  </Col>
                </Grid>
              </SectionText>
              <Spacer mt={2} />
              <SectionText>
                <GrayItalic>
                  {`${photo.createdBy?.firstName} ${photo.createdBy?.lastName}`}
                </GrayItalic>
                <Spacer mt={1} />
                <GrayItalic>
                  {intl.formatMessage({
                    id: 'admin.crewEvent.section.venueSetupInfo.updated',
                  })}
                  :{' '}
                  {dateFormatter(
                    photo.updatedAt,
                    'longMonthDateHoursAndMinutes',
                    venue.city.timezone
                  )}
                </GrayItalic>
              </SectionText>
              <Spacer mt={2} />
              {photo.description && (
                <ExpandableText
                  allowHTML
                  text={photo.description}
                  truncateLength={150}
                />
              )}
            </StyledCol>
          ))}
        </Grid>
      </MainContainer>
    </>
  );
};

export default SectionPhotos;
