import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Body, H3 } from 'app/shared/components/atoms/TypographyManualCSS';

interface Props {
  entityName: string;
  numResults?: number;
  loading: boolean;
  subtitle?: string;
  'data-qaid'?: string;
}

export const NoResults = styled(H3)`
  ${({ theme }) => css`
    text-align: center;
    font-weight: normal;
    color: ${theme.colors.blueSmoke};
    margin-bottom: 30px;
  `}
`;

const NoResultComment = styled(Body)`
  ${({ theme }) => css`
    text-align: center;
    color: ${theme.colors.blueSmoke};
    margin-bottom: 200px;
  `}
`;

const ListingNoResults: React.FC<Props> = ({
  entityName,
  numResults,
  loading,
  subtitle,
  'data-qaid': dataQaid,
}) => {
  const intl = useIntl();

  return (
    <div data-qaid={dataQaid}>
      {!loading && numResults == 0 && (
        <Spacer mt={12} mb={12}>
          <NoResults>No {entityName} Found</NoResults>
          <NoResultComment>
            {subtitle ||
              intl.formatMessage({ id: 'admin.listingNoResults.subtitle' })}
          </NoResultComment>
        </Spacer>
      )}
    </div>
  );
};

export default ListingNoResults;
