import * as Yup from 'yup';

const bookingRequestAcceptFormSchema = (intl: any) =>
  Yup.object().shape({
    performanceRequests: Yup.array()
      .of(
        Yup.object().shape({
          eventId: Yup.number(),
          position: Yup.number(),
        })
      )
      .min(
        1,
        intl.formatMessage({
          id: 'admin.bookingRequests.bookingRequestAccept.mustSelectEvents',
        })
      ),
  });

export default bookingRequestAcceptFormSchema;
