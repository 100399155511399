import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { Spacer } from 'app/shared/components/atoms/Spacer';
import DetailText from 'app/admin/components/atoms/DetailText';

const Link = styled.a`
  ${({ theme }) => css`
    color: ${theme.colors.green600};
  `}
`;

interface Props {
  firstName: string | undefined;
  lastName: string | undefined;
  email: string | undefined;
  phoneNumber: string | undefined;
  contactRelationship: string | undefined;
  isDayOfShowContact?: boolean | undefined;
}

const DayOfShowContactDetailText = styled(DetailText)`
  ${({ theme }) => css`
    color: black;
    font-size: 10px !important;
    width: 80px;
    background-color: ${theme.colors.greenOnions};
    text-align: center;
    margin-bottom: 5px;
    text-transform: uppercase;
  `}
`;

const ContactInfoCard: React.FC<Props> = ({
  firstName,
  lastName,
  email,
  phoneNumber,
  contactRelationship,
  isDayOfShowContact,
}) => {
  const intl = useIntl();

  return (
    <>
      {!!isDayOfShowContact && (
        <DayOfShowContactDetailText>
          {intl.formatMessage({
            id: 'admin.venueBasicInfo.dayOfShow',
          })}
        </DayOfShowContactDetailText>
      )}
      {!!contactRelationship && <div>{contactRelationship}</div>}
      {(!!firstName || !!lastName) && (
        <div>
          {!!firstName && `${firstName}`} {!!lastName && `${lastName}`}
        </div>
      )}
      {!!email && (
        <div>
          <Link href={`mailto:${email}`}>{email}</Link>
        </div>
      )}
      {!!phoneNumber && (
        <div>
          <Link href={`tel:${phoneNumber}`}>{phoneNumber}</Link>
        </div>
      )}
      <Spacer mb={4} />
    </>
  );
};

export default ContactInfoCard;
