import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { isEnvironmentProdOrLocal } from 'app/lib/environmentChecker';
import useDisableScroll from 'app/shared/utils/useDisableScroll';
import useOutsideClick from 'app/shared/utils/useOutsideClick';
import { Menu } from 'app/shared/components/atoms/Menu';
import {
  Popper,
  PopperProps,
} from 'app/shared/components/atoms/PopperManualCSS';
import { Portal } from 'app/shared/components/atoms/Portal';

import Trigger from './DropdownTrigger';

interface DropdownProps extends PopperProps {
  label?: string;
  renderLabel?: (arg?: any) => any;
  children: any;
  size?: 'small' | 'large';
  flyoutContainer?: boolean; // TODO rename to `hasFlyoutContainer` ?
  disableScrollWhenOpen?: boolean;
  invertColor?: boolean;
  'data-qaid'?: string;
  id?: string;
  className?: string;
  hoverDropdown?: boolean;
}

export const StyledMenu = styled(Menu)`
  ${({ theme }) => css`
    padding: ${theme.ruler[8]}px ${theme.ruler[6]}px;
  `}
`;

const DropdownContainer = styled.div<{ isOpen: boolean }>`
  display: inline-block;

  ${({ isOpen, theme }) =>
    isOpen &&
    css`
      span {
        color: ${isEnvironmentProdOrLocal()
          ? theme.colors.macyGrey
          : theme.colors.whiteDenim};
      }
    `}
`;

const HoverOverlay = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: -20px;
  left: -20px;
  right: -20px;
  bottom: -50px;
  cursor: pointer;
`;

const Dropdown: React.FC<DropdownProps> = ({
  label,
  renderLabel,
  children,
  flyoutContainer = true,
  disableScrollWhenOpen = false,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'left',
  },
  offset,
  keepInViewPort,
  size,
  invertColor,
  'data-qaid': qaId,
  id,
  className,
  hoverDropdown,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<any>();

  useDisableScroll(isOpen, disableScrollWhenOpen);

  useOutsideClick(ref, () => {
    setIsOpen(false);
  });

  let width = '200px';
  if (size === 'small') {
    width = '150px';
  } else if (size === 'large') {
    width = '250px';
  }

  return (
    <DropdownContainer
      className={className}
      isOpen={isOpen}
      onMouseEnter={() => hoverDropdown && setIsOpen(true)}
      onMouseLeave={() => hoverDropdown && setIsOpen(false)}
    >
      <Trigger
        id={id}
        ref={ref}
        onClick={() => setIsOpen(!isOpen)}
        data-qaid={qaId}
      >
        {renderLabel ? renderLabel(isOpen) : label}
      </Trigger>

      {isOpen && (
        <Portal dom={document.body}>
          <Popper
            offset={offset}
            anchorEl={ref}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
            keepInViewPort={keepInViewPort}
          >
            {flyoutContainer ? (
              <StyledMenu
                width={width}
                data-qaid={`${qaId}-flyout`}
                invertColor={invertColor}
              >
                {children}
              </StyledMenu>
            ) : (
              children
            )}
          </Popper>
        </Portal>
      )}
      {isOpen && <HoverOverlay isOpen />}
    </DropdownContainer>
  );
};

export default Dropdown;
