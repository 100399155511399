import React, { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { User } from 'app/typings/users';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import { AuthContext } from 'app/shared/context/Auth';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import {
  ContentWrapper,
  HeaderWrapper,
} from 'app/shared/components/atoms/FlyoverContent';
import { Spinner } from 'app/shared/components/atoms/SpinnerManualCSS';
import { Overline } from 'app/shared/components/atoms/TypographyManualCSS';
import { UpdateEventStaffMember } from 'app/admin/graphql/events/mutationHooks';

import ActivateRoleForm from './ActivateRoleForm';
import ManageRoleOption from './ManageRoleOption';
import RemoveRoleForm from './RemoveRoleForm';
import SwapShiftWithAvailableCrewConfirmation from './SwapShiftWithAvailableCrewConfirmation';
import SwapShiftWithAvailableCrewOption from './SwapShiftWithAvailableCrewOption';
import ValidationErrorForm from './ValidationErrorForm';

interface Props {
  eventId: number;
  citySlug: string;
  cityTitle: string;
  localStartsAt: string;
  crewLoadInAt?: string;
  initialState: string;
  staffObject: any;
  isSwappable?: boolean;
  onSuccess: Function;
  onSuccessWithoutRefetch?: Function;
}

const CenteredSpinner = styled(Spinner)`
  margin-left: 45%;
`;

const getHeaderTitle = (flyoverState: string) => {
  switch (flyoverState) {
    case 'activateRoleForm':
      return 'admin.crewPortal.crewRole.activateRoleForm';
    case 'manageRoleOption':
      return 'admin.crewPortal.crewRole.manageThisRole';
    case 'swapShiftWithAvailableCrewOption':
      return 'admin.crewPortal.crewRole.swapRoleWithOtherCrew';
    case 'swapShiftWithAvailableCrewConfirmation':
      return 'shared.confirmation';
    case 'removeRoleForm':
      return 'shared.confirmation';
    case 'validationErrorForm':
      return 'admin.crewPortal.crewRole.activateRoleForm';
    default:
      return 'admin.notApplicable';
  }
};

const renderHeader = (intl: any, flyoverState: string) => (
  <HeaderWrapper>
    <Overline>
      {intl.formatMessage({
        id: getHeaderTitle(flyoverState),
      })}
    </Overline>
    <DottedLine />
  </HeaderWrapper>
);

const CrewRoleFlyover: React.FC<Props> = ({
  eventId,
  citySlug,
  cityTitle,
  localStartsAt,
  crewLoadInAt,
  initialState,
  staffObject,
  isSwappable,
  onSuccess,
  onSuccessWithoutRefetch,
}) => {
  const intl = useIntl();
  const [flyoverState, setFlyoverState] = useState<string>(initialState);
  const [staffMemberUser, setStaffMemberUser] = useState<User | undefined>(
    undefined
  );
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const { user: currentUser } = useContext(AuthContext);

  const updateFlyoverState = (flyoverState: string) => {
    setFlyoverState(flyoverState);
  };

  const roleTitle = staffObject.label;

  const getSubmitDisplayMessageIds = (flyoverState: string) => {
    switch (flyoverState) {
      case 'activateRoleForm':
        return {
          success: {
            id: 'admin.crewPortal.crewRole.successfulSignupMsg',
            extraVars: {
              city: cityTitle,
              role: roleTitle,
            },
          },
          failure: {
            id: 'admin.crewPortal.crewRole.failureSignupMsg',
            extraVars: {
              city: cityTitle,
              role: roleTitle,
            },
          },
        };
      case 'removeRoleForm':
        return {
          success: {
            id: 'admin.crewPortal.crewRole.removeSuccessMsg',
            extraVars: {
              role: roleTitle,
            },
          },
          failure: {
            id: 'admin.crewPortal.crewRole.removeFailureMsg',
            extraVars: {
              role: roleTitle,
            },
          },
        };
      default:
        return {
          success: {
            id: 'admin.crewPortal.crewRole.removeSuccessMsg',
            extraVars: {
              role: roleTitle,
            },
          },
          failure: {
            id: 'admin.crewPortal.crewRole.removeFailureMsg',
            extraVars: {
              role: roleTitle,
            },
          },
        };
    }
  };

  const createUpdateEventStaffMemberAction = UpdateEventStaffMember();

  const handleUpdateEventStaffMember = useSubmitAction({
    submitAction: createUpdateEventStaffMemberAction,
    submitVariables: () => ({
      eventId,
      userId:
        flyoverState === 'activateRoleForm' && currentUser
          ? currentUser.id
          : (flyoverState === 'swapShiftWithAvailableCrewConfirmation' &&
              staffMemberUser &&
              staffMemberUser.id) ||
            undefined,
      roleKey: staffObject && staffObject.role,
      skipOverrideRestriction:
        flyoverState === 'swapShiftWithAvailableCrewConfirmation',
    }),
    successMsg: intl.formatMessage(
      {
        id: getSubmitDisplayMessageIds(flyoverState).success.id,
      },
      getSubmitDisplayMessageIds(flyoverState).success.extraVars
    ),
    failureMsg: intl.formatMessage(
      {
        id: getSubmitDisplayMessageIds(flyoverState).failure.id,
      },
      getSubmitDisplayMessageIds(flyoverState).failure.extraVars
    ),
    onValidationError: validationErrors => {
      setError(validationErrors.general);
      setIsSubmitting(false);
      setFlyoverState('validationErrorForm');
    },
    onSuccess: () => {
      setIsSubmitting(false);
      onSuccess && onSuccess();
    },
  });

  const renderCrewFlyover = () => {
    if (isSubmitting) {
      return (
        <ContentWrapper>
          <CenteredSpinner size="30px" />
        </ContentWrapper>
      );
    } else {
      switch (flyoverState) {
        case 'activateRoleForm':
          return (
            <ActivateRoleForm
              roleTitle={roleTitle}
              cityTitle={cityTitle}
              localStartsAt={localStartsAt}
              crewLoadInAt={crewLoadInAt}
              setIsSubmitting={setIsSubmitting}
              handleUpdateEventStaffMember={handleUpdateEventStaffMember}
            />
          );
        case 'manageRoleOption':
          return (
            <ManageRoleOption
              updateFlyoverState={updateFlyoverState}
              isSwappable={!!isSwappable}
            />
          );
        case 'swapShiftWithAvailableCrewOption':
          return (
            <SwapShiftWithAvailableCrewOption
              eventId={eventId}
              citySlug={citySlug}
              roleKey={staffObject.role}
              localStartsAt={localStartsAt}
              updateFlyoverState={updateFlyoverState}
              staffMemberUser={staffMemberUser}
              setStaffMemberUser={setStaffMemberUser}
            />
          );
        case 'swapShiftWithAvailableCrewConfirmation':
          return (
            <SwapShiftWithAvailableCrewConfirmation
              setIsSubmitting={setIsSubmitting}
              handleUpdateEventStaffMember={handleUpdateEventStaffMember}
            />
          );
        case 'removeRoleForm':
          return (
            <RemoveRoleForm
              roleTitle={roleTitle}
              setIsSubmitting={setIsSubmitting}
              handleUpdateEventStaffMember={handleUpdateEventStaffMember}
            />
          );
        case 'validationErrorForm':
          return (
            <ValidationErrorForm
              error={error}
              onSuccessWithoutRefetch={onSuccessWithoutRefetch}
              setIsSubmitting={setIsSubmitting}
            />
          );
        default:
          return <></>;
      }
    }
  };

  return (
    <div data-qaid="crew-flyover">
      {renderHeader(intl, flyoverState)}
      {renderCrewFlyover()}
    </div>
  );
};

export default CrewRoleFlyover;
