import { useMutation } from '@apollo/react-hooks';

import { Ticket } from 'app/typings/tickets';

import { UPDATE_TICKETS } from './mutations';

interface UpdateTicketsResponse {
  success: boolean;
  tickets: Ticket[];
}

interface UpdateTicketsParameters {
  tickets: Ticket[];
}

export const UpdateTickets = () => {
  return useMutation<UpdateTicketsResponse, UpdateTicketsParameters>(
    UPDATE_TICKETS
  )[0];
};
