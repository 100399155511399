import React from 'react';
import { useIntl } from 'react-intl';

import { ArtistApplicationRejectDecision } from 'app/typings/artistApplications';
import RadioGroup from 'app/shared/components/molecules/RadioGroup';

interface Props {
  values: any;
  setFieldValue: Function;
  setArtistApplicationRejectDecision: Function;
}

const ArtistApplicationRejectDecisionField: React.FC<Props> = ({
  values,
  setFieldValue,
  setArtistApplicationRejectDecision,
}) => {
  const intl = useIntl();

  return (
    <RadioGroup
      name="artistApplicationRejectDecision"
      data-qaid="artist-application-reject-options-field"
      options={[
        {
          title: intl.formatMessage({
            id: 'admin.artistApplication.details.declineAndNotify',
          }),
          value: ArtistApplicationRejectDecision.REJECT,
        },
        {
          title: intl.formatMessage({
            id: 'admin.artistApplication.details.declineDoNotNotify',
          }),
          value: ArtistApplicationRejectDecision.SOFT_REJECT,
        },
        {
          title: intl.formatMessage({
            id: 'admin.artistApplication.details.reapplyAndNotify',
          }),
          value: ArtistApplicationRejectDecision.SOFT_REJECT_REAPPLY,
        },
      ]}
      selectedValue={values.artistApplicationRejectDecision}
      onChange={value => {
        setFieldValue('artistApplicationRejectDecision', value);
        setArtistApplicationRejectDecision(value);
      }}
      spaceBetweenOptions={2}
    />
  );
};

export default ArtistApplicationRejectDecisionField;
