import React from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import SEO from 'app/shared/components/molecules/SEO';
import AcknowledgeUserAgreement from 'app/admin/components/templates/AcknowledgeUserAgreement';
import Layout from 'app/admin/layouts/AcknowledgeUserAgreement';

const BodyText = styled.p`
  text-align: left;
  margin-bottom: 30px;
`;

const BodyHeader = styled.div`
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 30px;
`;

const BodySectionHeader = styled.div`
  text-align: left;
  font-size: 18px;
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 30px;
`;

const BodySubSectionHeader = styled.div`
  text-align: left;
  font-weight: 600;
  margin-bottom: 10px;
`;

const BodyList = styled.ul`
  margin-bottom: 25px;
`;

const BodyListItem = styled.li`
  margin-bottom: 5px;
`;

const AdminCuratorAgreement: React.FC = () => {
  const intl = useIntl();
  const location = useLocation();

  return (
    <Layout>
      <SEO title="Admin Curator Agreement" />
      <AcknowledgeUserAgreement
        location={location}
        agreementName="curator"
        actions={['acknowledge']}
        agreementTitle={intl.formatMessage({
          id: 'admin.curatorAgreement.title',
        })}
        agreementIntroContent="Out of respect for the privacy of the Sofar community
      as well as to remain in compliance with various privacy regulations worldwide,
      we need all community members accessing Sofar platforms, technology, databases
      and/or data to agree to the following agreement. Please let the Community
      Team know if you have any questions."
      >
        <>
          <BodyText>
            I understand that, in accessing this platform and in my other
            activities representing Sofar Sounds, I will have access to Sofar
            proprietary information in confidence (“Confidential Information”).
            I will not utilize, copy or disclose Confidential Information for
            any reason, other than in connection with the organization of shows,
            as authorized by Sofar.
          </BodyText>
          <BodyText>
            “Confidential Information” means any Sofar proprietary information
            including, but not limited to all data concerning customers,
            artists, and hosts as well as finances.
          </BodyText>
          <BodyHeader>Sofar Safe Show Requirements </BodyHeader>
          <BodySectionHeader>
            1. General Safe Show Requirements
          </BodySectionHeader>
          <BodySubSectionHeader>Team requirements:</BodySubSectionHeader>
          <BodyList>
            <BodyListItem>
              Always start by understanding and making sure you’re following
              your local and regional government’s guidelines and
              recommendations for safe gatherings.
            </BodyListItem>
            <BodyListItem>
              There is a designated safety lead with access to Sofar emergency
              contacts and is familiar with the escalation plan.
            </BodyListItem>
            <BodyListItem>
              There is a designated team member monitoring the door and guest
              list throughout the event.
            </BodyListItem>
            <BodyListItem>
              At the beginning of the show, the MC identifies all exits/relevant
              safety information to the audience.
            </BodyListItem>
            <BodyListItem>
              Ensure there are enough team members to safely move heavier items
              of furniture.
            </BodyListItem>
          </BodyList>
          <BodySubSectionHeader>Pre-show requirements:</BodySubSectionHeader>
          <BodyList>
            <BodyListItem>
              The entrance and exit for the event must be easily identifiable
              and free of any obstructions.
            </BodyListItem>
            <BodyListItem>
              Exit signs should be hung in visible areas of the venue.
            </BodyListItem>
            <BodyListItem>
              There should be a safe and accessible area to load any equipment
              in/out.
            </BodyListItem>
            <BodyListItem>
              Ensure that there are no fire or electrical hazards (overloaded
              sockets, frayed wires, open flames, etc.).
            </BodyListItem>
            <BodyListItem>
              Prior to the show, identify the location of safety items (fire
              extinguishers, smoke and CO2 alarms).
            </BodyListItem>
            <BodyListItem>
              Identify any potential trip hazards, as well as loose stair
              railings/bannisters, and take preventative measures / block access
              if possible.
            </BodyListItem>
            <BodyListItem>
              Address any slippery surfaces, such as the areas near
              coolers/water dispensers and venue entrances. Take appropriate
              action as required.
            </BodyListItem>
            <BodyListItem>
              Ensure there is no access to items in the venue that may prove to
              be a hazard, accidentally leaned on or pulled down (curtains, art
              work, mirrors, low hanging chandeliers, etc).
            </BodyListItem>
            <BodyListItem>
              Ensure anything that is/could be construed as dangerous is safely
              put away/locked up.
            </BodyListItem>
            <BodyListItem>
              If pets are present, please ask the host to verify there is a safe
              space for the pet if needed.
            </BodyListItem>
          </BodyList>
          <BodySubSectionHeader>Venue requirements:</BodySubSectionHeader>
          <BodyList>
            <BodyListItem>
              Ensure that the venue has enough space to adhere to local
              regulations for social distancing requirements.
            </BodyListItem>
            <BodyListItem>
              Artists should be able to maintain a 2-4 meter (6-12 ft) distance
              from the audience. In order to minimize contact, it is recommended
              that instruments and backline should not be shared.
            </BodyListItem>
            <BodyListItem>
              For indoor shows, keep all available doors/windows open to improve
              ventilation throughout the show.
            </BodyListItem>
            <BodyListItem>
              Hand washing/sanitizing stations should be set up and available
              throughout the show and the space. Sanitize ‘high-touch’ areas
              prior to the show, during each break, and after the show.
            </BodyListItem>
          </BodyList>
        </>
      </AcknowledgeUserAgreement>
    </Layout>
  );
};

export default AdminCuratorAgreement;
