import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { errorMsgForField } from 'app/shared/utils/form';
import {
  FormGroupContainer,
  FormSectionTitle,
} from 'app/shared/components/atoms/FormContent';
import GenericFormContainer from 'app/shared/components/atoms/GenericFormContainer';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Textfield } from 'app/shared/components/atoms/Textfield';
import FormGroup from 'app/shared/components/molecules/FormGroup';
import Select from 'app/shared/components/molecules/SelectManualCSS';
import RichTextEditor from 'app/admin/components/molecules/RichTextEditor';

import {
  getRecipientTypeOptions,
  UrgentEmailKeys,
  urgentEmailTypeOptions,
} from './options';

interface FormProps {
  formikProps: any;
}

const EventSendUrgentEmailForm: React.FC<FormProps> = ({ formikProps }) => {
  const intl = useIntl();
  let [urgentEmailKey, setUrgentEmailType] = useState('');
  const { setFieldValue, errors, touched, values } = formikProps;

  useEffect(() => {
    if (!!urgentEmailKey && touched.urgentEmailKey) {
      setUrgentEmailType(values.urgentEmailKey);
    }
  }, [urgentEmailKey, touched.urgentEmailKey, values.urgentEmailKey]);

  return (
    <GenericFormContainer dataQaId="event-send-urgent-email-form">
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.eventPlanner.emails.urgentEmail',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="urgent-email-type-formgroup"
          label={intl.formatMessage({
            id: 'admin.eventPlanner.emails.selectEmailType',
          })}
          errorMsg={errorMsgForField('urgentEmailKey', touched, errors)}
          required
        >
          <Select
            id="urgentEmailKey"
            data-qaid="send-urgent-emails-form-urgent-email-type-field"
            options={urgentEmailTypeOptions}
            getOptionLabel={(opt: any) => opt.title}
            placeholder={intl.formatMessage({
              id: 'admin.eventPlanner.emails.selectEmailType',
            })}
            onChange={opt => {
              setFieldValue('urgentEmailKey', opt.value);
            }}
            defaultValue={null}
          />
        </FormGroup>
        <Spacer mb={8} />
        {values.urgentEmailKey === UrgentEmailKeys.GUEST_CUSTOM && (
          <>
            <FormGroup
              data-qaid="custom-email-subject-formgroup"
              label={intl.formatMessage({
                id: 'admin.shared.emails.subject',
              })}
              errorMsg={errorMsgForField('customEmailSubject', touched, errors)}
              required
            >
              <Textfield
                data-qaid="custom-email-subject"
                id="customEmailSubject"
                name="customEmailSubject"
                value={values.customEmailSubject}
                onChange={(e: any) => {
                  setFieldValue('customEmailSubject', e.target.value);
                }}
                maxLength={100}
              />
            </FormGroup>
            <Spacer mb={8} />
          </>
        )}
        {[
          UrgentEmailKeys.GUEST_CUSTOM,
          UrgentEmailKeys.GUEST_EVENT_CANCELLED,
        ].includes(values.urgentEmailKey) && (
          <FormGroup
            data-qaid="send-custom-email-form-custom-email-to-formgroup"
            label={intl.formatMessage({
              id: 'admin.shared.emails.to',
            })}
            errorMsg={errorMsgForField('recipientType', touched, errors)}
            required
          >
            <Select
              id="recipientType"
              data-qaid="custom-email-recipient-type-field"
              options={getRecipientTypeOptions(
                values.totalAttendeesConfirmedCount,
                values.totalAttendeesUnconfirmedCount,
                values.totalAttendeesCount
              )}
              getOptionLabel={(opt: any) => opt.title}
              placeholder={intl.formatMessage({
                id: 'select',
              })}
              onChange={opt => {
                setFieldValue('recipientType', opt.value);
              }}
              defaultValue={values.recipientType}
            />
          </FormGroup>
        )}
        {values.urgentEmailKey === UrgentEmailKeys.GUEST_CUSTOM && (
          <>
            <Spacer mb={8} />
            <FormGroup
              data-qaid="send-custom-email-form-urgent-email-message-formgroup"
              label={intl.formatMessage({
                id: 'admin.eventPlanner.emails.message',
              })}
              errorMsg={errorMsgForField('customEmailMessage', touched, errors)}
              required
            >
              <RichTextEditor
                name="customEmailMessage"
                currentValue={values.customEmailMessage}
                pasteAsText={false}
                onChange={value => {
                  setFieldValue('customEmailMessage', value);
                }}
              />
            </FormGroup>
          </>
        )}
        {values.urgentEmailKey === UrgentEmailKeys.GUEST_VENUE_CHANGED && (
          <FormGroup
            data-qaid="venue-change-email-new-address-formgroup"
            label={intl.formatMessage({
              id: 'admin.eventPlanner.emails.newAddress',
            })}
            errorMsg={errorMsgForField('newVenueAddress', touched, errors)}
            required
          >
            <Textfield
              data-qaid="new-venue-address"
              id="newVenueAddress"
              name="newVenueAddress"
              value={values.newVenueAddress}
              onChange={(e: any) => {
                setFieldValue('newVenueAddress', e.target.value);
              }}
              maxLength={200}
            />
          </FormGroup>
        )}
      </FormGroupContainer>
    </GenericFormContainer>
  );
};

export default EventSendUrgentEmailForm;
