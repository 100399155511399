import React from 'react';
import { useIntl } from 'react-intl';

import { Event } from 'app/typings';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { CreateEventCollection } from 'app/admin/graphql/eventCollections/mutationHooks';
import { DetailsFormMainContainer } from 'app/admin/components/atoms/DetailContent';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

import {
  CollectionBasicInfoForm,
  CollectionBasicInfoFormSchema,
} from './CollectionBasicInfoForm';

interface Props {
  contentProps: any;
  setFormSubmitAction: (func: Function) => void;
  setDisplayConfirmation?: (set: boolean) => void;
  setIsSubmitting?: (set: boolean) => void;
  navigateTo: (routeData: object) => void;
}

const CollectionCreate: React.FC<Props> = ({
  contentProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  navigateTo,
}) => {
  const intl = useIntl();

  const formInitialValues = {
    isNewCollection: true,
    name: '',
    description: '',
    whatToExpect: '',
    events: [],
  };

  const createCollectionAction = CreateEventCollection();

  const handleCreateCollection = useSubmitAction({
    submitAction: createCollectionAction,
    submitVariables: (values: any) => ({
      title: values.title,
      description: values.description,
      eventIds: values.events.map((event: Event) => event.id),
      whatToExpect: values.whatToExpect,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.collectionBasicInfo.form.create.successMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.collectionBasicInfo.form.create.failureMessage',
    }),
    onSuccess: (response: any) => {
      const id = response.data?.createEventCollection?.eventCollection?.id;
      contentProps.refetchEventCollections();
      navigateTo({
        routeName: 'event-collection-details',
        routeProps: {
          id,
        },
      });
    },
  });

  return (
    <ModalContentContainer>
      <DetailsFormMainContainer>
        <DetailsHeader
          title={intl.formatMessage({
            id: 'admin.collectionBasicInfo.form.create.title',
          })}
          isNewForm={true}
        />
        <GenericForm
          formInitialValues={formInitialValues}
          renderFormComponent={(renderProps: any) => (
            <CollectionBasicInfoForm
              formikProps={renderProps.formikProps}
              contentProps={contentProps}
              isNewEventCollection={true}
            />
          )}
          onSubmit={handleCreateCollection}
          formSchema={CollectionBasicInfoFormSchema(intl)}
          setFormSubmitAction={setFormSubmitAction}
          setDisplayConfirmation={setDisplayConfirmation}
          setIsSubmitting={setIsSubmitting}
          dataQaId="collection-create-form"
        />
      </DetailsFormMainContainer>
    </ModalContentContainer>
  );
};

export default CollectionCreate;
