export const columnsConfig = [
  {
    headerText: 'Field',
    columnWidth: 250,
  },
  {
    headerText: 'Changed From',
    columnWidth: 400,
  },
  {
    headerText: 'Changed To',
    columnWidth: 400,
  },
];
