import { useQuery } from 'react-apollo';

import {
  GetBroadcastsData,
  GetBroadcastsVariables,
} from 'app/typings/broadcasts';
import { queryParams } from 'app/shared/utils/queries';
import { GET_BROADCASTS } from 'app/shared/graphql/broadcasts/queries';

export const GetBroadcasts = (params: GetBroadcastsVariables = {}) => {
  return useQuery<GetBroadcastsData>(GET_BROADCASTS, queryParams(params));
};
