import React, { useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import styled, { css } from 'styled-components';

import { RemainingCharactersCounter } from './RemainingCharactersCounter';

interface Props {
  name: string;
  currentValue: any;
  onChange: (value: any) => void;
  disabled?: boolean;
  placeholder?: string;
  fontSize?: string;
  maxHeight?: number;
  maxCharacters?: number;
  enableLinks?: boolean;
  pasteAsText?: boolean;
}

const EditorContainer = styled.div`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.macyGrey};
    border-radius: 12px;
  `}
`;

const RichTextEditorComponent: React.FC<Props> = ({
  name,
  currentValue,
  disabled = false,
  onChange,
  fontSize,
  placeholder = '',
  maxHeight = 1000,
  maxCharacters,
  enableLinks = true,
  pasteAsText = true,
}) => {
  const [charactersLength, setCharactersLength] = useState(0);
  const [value, setValue] = useState(currentValue ?? '');

  const handleChange = (newValue: any, editor: any) => {
    const length = editor.getContent({ format: 'text' }).length;
    setValue(newValue);
    onChange && onChange(newValue);
    setCharactersLength(length);
  };

  const plugins = ['autoresize'];

  if (enableLinks) {
    plugins.push('link');
  }

  return (
    <EditorContainer id={name}>
      <RemainingCharactersCounter
        maxCharacters={maxCharacters}
        charactersLength={charactersLength}
      />
      <Editor
        apiKey={process.env.TINYMCE_API_KEY}
        value={value}
        onEditorChange={handleChange}
        init={{
          min_height: 200,
          max_height: maxHeight,
          menubar: false,
          plugins,
          toolbar: 'link bold italic undo redo',
          content_style: `body { font-size:${fontSize || '14px'}; }`,
          branding: false,
          elementpath: false,
          link_assume_external_targets: true,
          link_default_protocol: 'https',
          placeholder,
          paste_as_text: pasteAsText,
        }}
        disabled={disabled}
      />
    </EditorContainer>
  );
};

export default RichTextEditorComponent;
