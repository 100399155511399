import React, { useState } from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { Event } from 'app/typings/events';
import {
  getTimeFormInitialValue,
  getTimeSubmitVariable,
} from 'app/shared/utils/datetime';
import { get } from 'app/shared/utils/get';
import useModal from 'app/shared/utils/useModal';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import UpdateOptionsModal from 'app/shared/components/organisms/UpdateOptionsModal';
import { stripTags } from 'app/admin/utils/string';
import { GetEventsForVenueEdit } from 'app/admin/graphql/events/queryHooks';
import { UpdateVenue } from 'app/admin/graphql/venues/mutationHooks';
import { DetailsFormMainContainer } from 'app/admin/components/atoms/DetailContent';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

import VenueEventSetupEditForm from './VenueEventSetupEditForm';
import VenueEventSetupEditFormSchema from './VenueEventSetupEditFormSchema';

const ErrorMessage = styled.div<any>`
  ${({ theme }) => css`
    width: 100%;
    color: ${theme.colors.redRedWine};
    font-size: ${theme.fontSizes.caption};
  `}
`;

interface Props {
  contentProps?: any;
  setFormSubmitAction: (func: Function) => void;
  setDisplayConfirmation: (set: boolean) => void;
  setIsSubmitting: (set: boolean) => void;
  navigateTo: (routeData: object) => void;
}

const fieldsRequiringUpdateOptions = [
  'audioNotes',
  'internalVenueNotes',
  'localCrewLoadInAt',
  'localArtistLoadInAt',
  'localGuestsArriveAt',
  'localStartsAt',
  'localEndsAt',
  'maxPaInputs',
  'mcNotes',
  'seatingNotes',
  'suppliesNeededNotes',
  'timingNotes',
];

const VenueEventSetupEdit: React.FC<Props> = ({
  contentProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  navigateTo,
}) => {
  const intl = useIntl();
  const [submitData, setSubmitData] = useState({});
  const [changedFields, setChangedFields] = useState<string[]>([]);
  const [totalChangedFields, setTotalChangedFields] = useState<number>(0);
  const [updateOptionsModal, toggleUpdateOptionsModal] = useModal();
  const {
    error: errorEvents,
    loading: loadingEvents,
    data: dataEvents,
  } = GetEventsForVenueEdit({
    venueIds: contentProps.id.toString(),
    upcoming: true,
    page: 1,
    perPage: 1000,
  });

  const eventOptions = dataEvents?.events?.events || [];

  const formInitialValues = {
    recommendedCrewCount: get(contentProps, 'recommendedCrewCount', ''),
    audioNotes: get(contentProps, 'audioNotes', ''),
    maxPaInputs: get(contentProps, 'maxPaInputs.key', ''),
    bathroomNotes: get(contentProps, 'bathroomNotes', ''),
    checkInProcessNotes: get(contentProps, 'checkInProcessNotes', ''),
    internalVenueNotes: get(contentProps, 'internalVenueNotes', ''),
    loadInNotes: get(contentProps, 'loadInNotes', ''),
    mcNotes: get(contentProps, 'mcNotes', ''),
    merchTablePlacementNotes: get(contentProps, 'merchTablePlacementNotes', ''),
    stageSetupNotes: get(contentProps, 'stageSetupNotes', ''),
    suppliesNeededNotes: get(contentProps, 'suppliesNeededNotes', ''),
    seatingNotes: get(contentProps, 'seatingNotes', ''),
    timingNotes: get(contentProps, 'timingNotes', ''),
    gearStorage: get(contentProps, 'gearStorage', ''),
    hasTapWater: get(contentProps, 'hasTapWater', undefined),
    noiseRestrictions: get(contentProps, 'noiseRestrictions', ''),
    localCrewLoadInAt: getTimeFormInitialValue(
      get(contentProps, 'localCrewLoadInAt', '')
    ),
    localArtistLoadInAt: getTimeFormInitialValue(
      get(contentProps, 'localArtistLoadInAt', '')
    ),
    localGuestsArriveAt: getTimeFormInitialValue(
      get(contentProps, 'localGuestsArriveAt', '')
    ),
    localStartsAt: getTimeFormInitialValue(
      get(contentProps, 'localStartsAt', '')
    ),
    localEndsAt: getTimeFormInitialValue(get(contentProps, 'localEndsAt', '')),
  };

  const updateVenueAction = UpdateVenue();

  const handleUpdateVenue = useSubmitAction({
    submitAction: updateVenueAction,
    submitVariables: (values: any) => ({
      venueId: contentProps.id,
      recommendedCrewCount: values.recommendedCrewCount
        ? values.recommendedCrewCount.toString()
        : null,
      audioNotes: values.audioNotes,
      bathroomNotes: values.bathroomNotes,
      checkInProcessNotes: values.checkInProcessNotes,
      internalVenueNotes: values.internalVenueNotes,
      loadInNotes: values.loadInNotes,
      maxPaInputs: values.maxPaInputs,
      mcNotes: values.mcNotes,
      merchTablePlacementNotes: values.merchTablePlacementNotes,
      stageSetupNotes: values.stageSetupNotes,
      suppliesNeededNotes: values.suppliesNeededNotes,
      seatingNotes: values.seatingNotes,
      timingNotes: values.timingNotes,
      gearStorage: values.gearStorage,
      hasTapWater: values.hasTapWater,
      noiseRestrictions: values.noiseRestrictions,
      localCrewLoadInAt: getTimeSubmitVariable(values.localCrewLoadInAt),
      localArtistLoadInAt: getTimeSubmitVariable(values.localArtistLoadInAt),
      localGuestsArriveAt: getTimeSubmitVariable(values.localGuestsArriveAt),
      localStartsAt: getTimeSubmitVariable(values.localStartsAt),
      localEndsAt: getTimeSubmitVariable(values.localEndsAt),
      applyMethod: values.applyMethod,
      selectedValues: values.selectedValues,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.venueEventSetup.successMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.venueEventSetup.failureMessage',
    }),
    onSuccess: (response: any) => {
      navigateTo({
        routeName: 'venue-details',
        routeProps: {
          defaultOpenSection: 'eventSetup',
          ...response.data.updateVenue.venue,
        },
      });
    },
  });

  return (
    <ModalContentContainer>
      <DetailsFormMainContainer>
        <DetailsHeader
          title={contentProps.venueName}
          imageUrl={contentProps.imageUrl}
          largeImageUrl={contentProps.largeImageUrl}
          imageStyle="landscape"
        />
        <GenericForm
          formInitialValues={formInitialValues}
          renderFormComponent={(renderProps: any) => (
            <VenueEventSetupEditForm
              formikProps={renderProps.formikProps}
              setFormSubmitAction={setFormSubmitAction}
              setDisplayConfirmation={setDisplayConfirmation}
              setIsSubmitting={setIsSubmitting}
            />
          )}
          onSubmit={data => {
            const changedFieldsForUpdateOptions = fieldsRequiringUpdateOptions.filter(
              (field: string) =>
                stripTags(formInitialValues[field]) !== stripTags(data[field])
            );
            const numChangedFields = Object.keys(data).filter(
              field => formInitialValues[field] !== data[field]
            ).length;
            if (
              changedFieldsForUpdateOptions.length === 0 ||
              eventOptions.length === 0
            ) {
              handleUpdateVenue(data);
            } else {
              setIsSubmitting(false);
              setSubmitData(data);
              setChangedFields(changedFieldsForUpdateOptions);
              setTotalChangedFields(numChangedFields);
              return toggleUpdateOptionsModal();
            }
          }}
          formSchema={VenueEventSetupEditFormSchema(intl)}
          dataQaId="venue-event-setup-section-edit-form"
        />
        {updateOptionsModal.isShowing &&
          (errorEvents ? (
            <ErrorMessage data-qaid="venue-event-setup-events-error">
              {intl.formatMessage({
                id: 'admin.citySettings.form.getEventsError',
              })}
            </ErrorMessage>
          ) : (
            <UpdateOptionsModal
              onCancel={() => updateOptionsModal.hide()}
              onConfirm={(data: any) => {
                setIsSubmitting(true);
                updateOptionsModal.hide();
                handleUpdateVenue({
                  ...submitData,
                  ...data,
                });
              }}
              changedFields={changedFields}
              modalType="venue"
              isLoading={loadingEvents}
              dataQaidPrefix="venue-event-setup-options-modal"
              title={intl.formatMessage(
                {
                  id: 'admin.updateOptionsModal.applyChangesWarning',
                },
                {
                  updatedFields: changedFields.length,
                  totalFields: totalChangedFields,
                }
              )}
              options={contentProps.upcomingEvents.map((ev: Event) => ({
                title:
                  ev.localStartsAt &&
                  moment.utc(ev.localStartsAt).format('MMM DD YYYY hh:mmA'),
                value: ev.id,
              }))}
            />
          ))}
      </DetailsFormMainContainer>
    </ModalContentContainer>
  );
};

export default VenueEventSetupEdit;
