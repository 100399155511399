import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { PrimaryRole } from 'app/typings/roles';
import { User } from 'app/typings/users';
import { dateFormatter } from 'app/shared/utils/datetime';
import { get } from 'app/shared/utils/get';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import {
  ContentWrapper,
  FlyoverLoadingError,
  FlyoverLoadingIndicator,
  HeaderWrapper,
} from 'app/shared/components/atoms/FlyoverContent';
import TruncatedByCharText from 'app/shared/components/atoms/TruncatedByCharText';
import {
  Body2,
  Overline,
} from 'app/shared/components/atoms/TypographyManualCSS';
import { GetArtistBasicInfo } from 'app/admin/graphql/artists/queryHooks';
import { UserLink } from 'app/admin/components/molecules/EntityLink';

interface Props {
  id: number;
  title: string;
}

const Title = styled(Body2)`
  font-weight: 700;
  letter-spacing: 0.5px;
  white-space: nowrap;
`;

const ContentSectionWrapper = styled.div`
  padding: 2px 2px 2px 2px;
  margin-bottom: 10px;
`;

const HistoryEventNameWrapper = styled.div`
  margin-bottom: -6px;
`;

const ReviewedByWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: wrap;
  white-space: nowrap;
  font-size: 12px;
  padding-top: 10px;
  padding-bottom: 5px;
`;

const ReviewedByText = styled.div`
  padding-right: 5px;
`;

const PrimaryRoles = styled.div`
  padding-left: 5px;
`;

interface HeaderProps {
  title: string;
}

const Header: React.FC<HeaderProps> = ({ title }) => {
  const intl = useIntl();

  return (
    <HeaderWrapper>
      <Overline>
        {intl.formatMessage({
          id: 'admin.artistDirectory.artistHistory.artistHistory',
        })}
      </Overline>
      <Title>
        <TruncatedByCharText text={title} truncateLength={30} />
      </Title>
      <DottedLine />
    </HeaderWrapper>
  );
};

interface HistoryEventNameAndDateProps {
  name: string;
  date: string;
}

const HistoryEventNameAndDate: React.FC<HistoryEventNameAndDateProps> = ({
  name,
  date,
}) => {
  return (
    <>
      <HistoryEventNameWrapper>
        <Overline>{name}</Overline>
      </HistoryEventNameWrapper>
      <Overline>{dateFormatter(date, 'shortMonthDateAndYear')}</Overline>
    </>
  );
};

interface ReviewedProps {
  date: string;
  user?: User;
}

const Reviewed: React.FC<ReviewedProps> = ({ date, user }) => {
  const intl = useIntl();

  const primaryRoles =
    user &&
    user.primaryRoles &&
    user.primaryRoles
      .map((primaryRole: PrimaryRole) => primaryRole.name)
      .join(', ');

  return (
    <ContentSectionWrapper>
      <HistoryEventNameAndDate
        name={intl.formatMessage({
          id: 'admin.artistDirectory.artistHistory.reviewed',
        })}
        date={date}
      />
      {user && (
        <ReviewedByWrapper>
          <ReviewedByText>
            {intl.formatMessage({
              id: 'admin.artistDirectory.artistHistory.reviewedBy',
            })}
          </ReviewedByText>
          <UserLink user={user} truncateLength={20} />
          {primaryRoles && <PrimaryRoles>({primaryRoles})</PrimaryRoles>}
        </ReviewedByWrapper>
      )}
    </ContentSectionWrapper>
  );
};

const ArtistHistoryFlyover: React.FC<Props> = ({ id, title }) => {
  const {
    loading: loadingArtist,
    error: errorArtist,
    data: dataArtist,
  } = GetArtistBasicInfo({
    id,
    fetchPolicy: 'cache-and-network',
  });

  const artist = get(dataArtist, 'artist', {});

  if ((!loadingArtist && !dataArtist) || errorArtist) {
    return <FlyoverLoadingError whatsBeingLoaded="artist" />;
  }

  return (
    <div data-qaid="artist-history">
      <Header title={title} />
      {loadingArtist ? (
        <FlyoverLoadingIndicator />
      ) : (
        <ContentWrapper>
          <Reviewed date={artist.reviewedAt} user={artist.reviewedBy} />
        </ContentWrapper>
      )}
    </div>
  );
};

export default ArtistHistoryFlyover;
