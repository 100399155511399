import styled, { css } from 'styled-components';

const ShadedOval = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px;
    background: ${theme.colors.silverSprings};
    min-height: 45px;
    padding: 8px 10px 8px 18px;
    width: 100%;
    margin-bottom: 12px;
  `}
`;

export default ShadedOval;
