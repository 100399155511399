import React from 'react';
import { useIntl } from 'react-intl';

import { errorMsgForField } from 'app/shared/utils/form';
import { FormGroupContainer } from 'app/shared/components/atoms/FormContent';
import StyledTextarea from 'app/shared/components/atoms/SmallTextarea';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Textfield } from 'app/shared/components/atoms/Textfield';
import FormGroup from 'app/shared/components/molecules/FormGroup';
import {
  eventMerchandisingDescriptionMaxLength,
  eventMerchandisingHeadlineMaxLength,
} from 'app/admin/utils/events';
import MerchandisingPropertyGroupsEditFormFields from 'app/admin/components/molecules/MerchandisingPropertyGroupsEditFormFields';

import { CollectionBasicInfoFormSectionHeader } from './components';
import { CollectionBasicInfoFormikProps } from './typings';

export const EventMerchandisingSection: React.FC<CollectionBasicInfoFormikProps> = ({
  setFieldValue,
  touched,
  errors,
  values,
}) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <CollectionBasicInfoFormSectionHeader
        titleIntlId="admin.collectionBasicInfo.form.concertMerchandising"
        descriptionIntlId="admin.collectionBasicInfo.form.concertMerchandisingDescription"
      />
      <FormGroupContainer>
        <FormGroup
          data-qaid="collection-about-section-edit-form-event-headline-formgroup"
          label={formatMessage({
            id: 'admin.collectionBasicInfo.form.concertHeadline',
          })}
          errorMsg={errorMsgForField('eventHeadline', touched, errors)}
        >
          <Textfield
            data-qaid="collection-about-section-edit-form-event-headline-field"
            id="eventHeadline"
            name="eventHeadline"
            value={values.eventHeadline}
            onChange={(e: any) => {
              setFieldValue('eventHeadline', e.target.value);
            }}
            maxLength={eventMerchandisingHeadlineMaxLength}
          />
        </FormGroup>
        <Spacer mb={6} />
        <FormGroup
          data-qaid="collection-about-section-edit-form-event-description-formgroup"
          label={formatMessage({
            id: 'admin.collectionBasicInfo.form.concertDescription',
          })}
          errorMsg={errorMsgForField('eventDescription', touched, errors)}
        >
          <StyledTextarea
            data-qaid="collection-about-section-edit-form-event-description-field"
            id="eventDescription"
            name="eventDescription"
            value={values.eventDescription}
            onChange={(e: any) => {
              setFieldValue('eventDescription', e.target.value);
            }}
            maxLength={eventMerchandisingDescriptionMaxLength}
          />
        </FormGroup>
        <Spacer mb={6} />
        <MerchandisingPropertyGroupsEditFormFields
          setFieldValue={setFieldValue}
          touched={touched}
          errors={errors}
          values={values}
          propertyContext="EventCollectionMerchandising"
          dataQaId="collection-about-section-edit-form-merchandising-properties"
        />
      </FormGroupContainer>
    </>
  );
};
