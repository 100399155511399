import React from 'react';
import { useIntl } from 'react-intl';

import { get } from 'app/shared/utils/get';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { UpdateEvent } from 'app/admin/graphql/events/mutationHooks';
import { DetailsFormMainContainer } from 'app/admin/components/atoms/DetailContent';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

import EventDetailsVenueEditForm from './EventDetailsVenueEditForm';
import EventDetailsVenueEditFormSchema from './EventDetailsVenueEditFormSchema';

interface Props {
  contentProps?: any;
  setFormSubmitAction: (func: Function) => void;
  setDisplayConfirmation: (set: boolean) => void;
  setIsSubmitting: (set: boolean) => void;
  navigateTo: (routeData: object) => void;
}

const EventDetailsVenueEdit: React.FC<Props> = ({
  contentProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  navigateTo,
}) => {
  const intl = useIntl();
  const venueName = get(contentProps, 'venue.venueName', '');
  const venueId = get(contentProps, 'venue.id', '');
  const eventId = contentProps.id;

  const maxPaInputs = get(contentProps, 'maxPaInputs.key', undefined);
  const audioNotes = get(contentProps, 'audioNotes', undefined);
  const internalVenueNotes = get(contentProps, 'internalVenueNotes', undefined);
  const externalVenueNotes = get(contentProps, 'externalVenueNotes', undefined);
  const inviteEmailMessage = get(contentProps, 'inviteEmailMessage', undefined);
  const mcNotes = get(contentProps, 'mcNotes', undefined);
  const revealAddressEmailMessage = get(
    contentProps,
    'revealAddressEmailMessage',
    undefined
  );
  const seating = get(contentProps, 'seating.key', 'unknown');
  const seatingNotes = get(contentProps, 'seatingNotes', undefined);
  const suppliesNeededNotes = get(
    contentProps,
    'suppliesNeededNotes',
    undefined
  );
  const eventOrganizedAs = get(contentProps, 'eventOrganizedAs', undefined);

  const formInitialValues = {
    venueName,
    audioNotes,
    internalVenueNotes,
    inviteEmailMessage,
    externalVenueNotes,
    maxPaInputs,
    mcNotes,
    properties: get(contentProps, 'properties', []).map((e: any) => e.id),
    revealAddressEmailMessage,
    seating,
    seatingNotes,
    suppliesNeededNotes,
  };

  const updateEventAction = UpdateEvent();

  const handleUpdateEvent = useSubmitAction({
    submitAction: updateEventAction,
    submitVariables: (values: any) => ({
      eventId,
      venueName: values.venueName,
      processProperties: true,
      properties: values.properties,
      audioNotes: values.audioNotes,
      internalVenueNotes: values.internalVenueNotes,
      inviteEmailMessage: values.inviteEmailMessage,
      externalVenueNotes: values.externalVenueNotes,
      mcNotes: values.mcNotes,
      maxPaInputs: values.maxPaInputs,
      revealAddressEmailMessage: values.revealAddressEmailMessage,
      seating: values.seating,
      seatingNotes: values.seatingNotes,
      suppliesNeededNotes: values.suppliesNeededNotes,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.venueBasicInfo.successMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.venueBasicInfo.failureMessage',
    }),
    onSuccess: (response: any) => {
      navigateTo({
        routeName: 'event-details',
        routeProps: {
          defaultOpenSection: 'venueInfo',
          ...response.data.updateEvent.event,
        },
      });
    },
  });

  return (
    <ModalContentContainer>
      <DetailsFormMainContainer>
        <DetailsHeader
          title={venueName}
          imageUrl={get(contentProps, 'venue.imageUrl', '')}
          largeImageUrl={get(contentProps, 'venue.largeImageUrl', '')}
          imageStyle="landscape"
        />
        <GenericForm
          formInitialValues={formInitialValues}
          renderFormComponent={(renderProps: any) => (
            <EventDetailsVenueEditForm
              formikProps={renderProps.formikProps}
              venueId={venueId}
              eventType={contentProps.type}
              eventOrganizedAs={eventOrganizedAs}
            />
          )}
          onSubmit={handleUpdateEvent}
          formSchema={EventDetailsVenueEditFormSchema(intl)}
          setFormSubmitAction={setFormSubmitAction}
          setDisplayConfirmation={setDisplayConfirmation}
          setIsSubmitting={setIsSubmitting}
          dataQaId="event-details-venue-edit-form"
        />
      </DetailsFormMainContainer>
    </ModalContentContainer>
  );
};

export default EventDetailsVenueEdit;
