import React from 'react';

import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

export const getRenderHeaderContent = (
  imageStyle: string,
  title?: string,
  imageUrl?: string
) => {
  return (headerActionLinksInfo?: any) => (
    <DetailsHeader
      title={title}
      imageUrl={imageUrl}
      imageStyle={imageStyle}
      actionLinksInfo={headerActionLinksInfo}
    />
  );
};
