import React, { useContext } from 'react';
import styled, { css } from 'styled-components';

import { isEnvironmentProdOrLocal } from 'app/lib/environmentChecker';
import { AdminNavChildMenu, AdminNavParentMenu } from 'app/typings/navbar';
import { PermissionsContext } from 'app/shared/context/Permissions';
import { Menu, MenuItem } from 'app/shared/components/atoms/Menu';
import Dropdown from 'app/shared/components/molecules/Dropdown';
import BaseNavbar from 'app/admin/components/molecules/BaseNavbar';

import {
  isItemViewable,
  isLinkViewable,
  useAdminNavConfig,
} from './adminNavUtils';

interface ParentMenuItemProps {
  parentMenuItem: AdminNavParentMenu;
  alignRight: boolean;
  index: number;
}

const StyledMenuItem = styled(MenuItem)`
  ${({ theme }) => css`
    background-color: ${theme.colors.backToBlack};
    color: ${theme.colors.whiteDenim};
    font-size: 14px !important;
    &:hover {
      background-color: ${theme.colors.green800};
    }
  `}
`;

const IconText = styled.span`
  ${({ theme }) => css`
    font-size: 16px;
    color: ${isEnvironmentProdOrLocal()
      ? theme.colors.whiteDenim
      : theme.colors.backToBlack};
    z-index: 1;
    &:hover,
    &:focus,
    &:active {
      color: ${isEnvironmentProdOrLocal()
        ? theme.colors.macyGrey
        : theme.colors.whiteDenim};
      text-decoration: none;
    }
  `}
`;

interface ParentItemProps {
  parentMenuItem: AdminNavParentMenu;
}

const ParentItem: React.FC<ParentItemProps> = ({ parentMenuItem }) => (
  <BaseNavbar.Item
    data-qaid={`navbar-admin-${parentMenuItem.dataQaidSuffix}`}
    disabled={parentMenuItem.disabled}
    href={parentMenuItem.link}
    target={
      parentMenuItem.link && parentMenuItem.openInNewTab ? '_blank' : undefined
    }
  >
    <IconText>{parentMenuItem.title}</IconText>
  </BaseNavbar.Item>
);

const ParentMenuButton: React.FC<ParentMenuItemProps> = ({
  parentMenuItem,
  alignRight,
  index,
}) => {
  const permissionsContext = useContext(PermissionsContext);
  const featureFlagsParam = {};

  return isLinkViewable(
    parentMenuItem,
    permissionsContext,
    featureFlagsParam
  ) ? (
    parentMenuItem.childMenu &&
    parentMenuItem.childMenu.length === 1 &&
    parentMenuItem.collapseSingleChild ? (
      <ParentItem
        key={index}
        parentMenuItem={{
          ...parentMenuItem,
          link: parentMenuItem.childMenu[0]?.link,
          openInNewTab: parentMenuItem.childMenu[0]?.openInNewTab,
        }}
      />
    ) : (
      <Dropdown
        key={index}
        flyoutContainer={false}
        offset={{ vertical: 20 }}
        renderLabel={() => <ParentItem parentMenuItem={parentMenuItem} />}
        transformOrigin={
          alignRight ? { horizontal: 'right', vertical: 'top' } : undefined
        }
        anchorOrigin={
          alignRight ? { horizontal: 'right', vertical: 'bottom' } : undefined
        }
        hoverDropdown
      >
        <Menu width="240px">
          {(parentMenuItem.childMenu || []).map(
            (childMmenuItem: AdminNavChildMenu, index: number) =>
              isItemViewable(
                childMmenuItem,
                permissionsContext,
                featureFlagsParam
              ) && (
                <a
                  href={childMmenuItem.link}
                  key={index}
                  target={childMmenuItem.openInNewTab ? '_blank' : undefined}
                >
                  <StyledMenuItem
                    data-qaid={`navbar-admin-${childMmenuItem.dataQaidSuffix}`}
                    onClick={childMmenuItem.onClick}
                  >
                    {childMmenuItem.title}
                  </StyledMenuItem>
                </a>
              )
          )}
        </Menu>
      </Dropdown>
    )
  ) : null;
};

interface NavbarMenuButtonsProps {
  alignRight: boolean;
}

export const NavbarMenuButtons: React.FC<NavbarMenuButtonsProps> = ({
  alignRight,
}) => {
  const menuData = useAdminNavConfig();
  const menuDataToUse = menuData.filter(
    (parentMenuItem: AdminNavParentMenu) =>
      !!parentMenuItem.alignRight === !!alignRight
  );

  return (
    <BaseNavbar.ItemContainer align={`${alignRight ? 'right' : 'left'}`}>
      {menuDataToUse.map(
        (parentMenuItem: AdminNavParentMenu, index: number) => (
          <ParentMenuButton
            parentMenuItem={parentMenuItem}
            alignRight={alignRight || false}
            index={index}
            key={index}
          />
        )
      )}
    </BaseNavbar.ItemContainer>
  );
};
