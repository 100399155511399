import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Venue } from 'app/typings/venues';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import {
  DetailHeading as Heading,
  DetailValueContainer as ValueContainer,
} from 'app/admin/components/atoms/DetailContent';
import ExpandableText from 'app/admin/components/atoms/ExpandableText';
import { None } from 'app/admin/components/atoms/None';
import EventTimes from 'app/admin/components/molecules/EventTimes';
import ArtistEventLogisticsNotes from 'app/admin/components/organisms/ArtistEventLogisticsNotes';

interface Props {
  sectionData: Venue;
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const SectionEventSetup: React.FC<Props> = ({ sectionData }) => {
  const intl = useIntl();

  return (
    <MainContainer data-qaid="section-event-set-up">
      <Grid>
        <Col xs={12} sm={12} md={3} lg={3} xl={3} key={1}>
          <EventTimes
            crewLoadInAt={sectionData.localCrewLoadInAt}
            artistLoadInAt={sectionData.localArtistLoadInAt}
            guestsArriveAt={sectionData.localGuestsArriveAt}
            startsAt={sectionData.localStartsAt}
            endsAt={sectionData.localEndsAt}
          />
          <>
            <Heading style={{ marginTop: 20 }}>
              {intl.formatMessage({
                id: 'admin.venueEventSetup.timingNotes',
              })}
            </Heading>
            <ExpandableText
              allowHTML
              text={sectionData.timingNotes}
              truncateLength={120}
            />
            <Heading style={{ marginTop: 20 }}>
              {intl.formatMessage({
                id: 'admin.venueEventSetup.loadInNotes',
              })}
            </Heading>
            <ExpandableText
              allowHTML
              text={sectionData.loadInNotes}
              truncateLength={120}
            />
          </>
        </Col>

        <Col
          xs={12}
          sm={12}
          md={3}
          lg={3}
          xl={3}
          key={2}
          data-qaid="col-internal-venue-notes"
        >
          <>
            <Heading>
              {intl.formatMessage({
                id: 'admin.shared.recommendedTeam',
              })}
            </Heading>
            <ValueContainer>
              {sectionData.recommendedCrewCount || <None />}
            </ValueContainer>
          </>
          <Heading>
            {intl.formatMessage({
              id: 'admin.venueEventSetup.venueNotes',
            })}
          </Heading>
          <ExpandableText
            allowHTML
            text={sectionData.internalVenueNotes}
            truncateLength={120}
          />

          <>
            <Heading>
              {intl.formatMessage({
                id: 'admin.shared.suppliesNeeded',
              })}
            </Heading>
            <ExpandableText
              allowHTML
              text={sectionData.suppliesNeededNotes}
              truncateLength={120}
            />
            <Heading>
              {intl.formatMessage({
                id: 'admin.shared.notesForMc',
              })}
            </Heading>
            <ExpandableText
              allowHTML
              text={sectionData.mcNotes}
              truncateLength={120}
            />
          </>
        </Col>

        <Col
          xs={12}
          sm={12}
          md={3}
          lg={3}
          xl={3}
          key={3}
          data-qaid="col-venue-setup"
        >
          <Heading>
            {intl.formatMessage({
              id: 'admin.shared.checkInProcess',
            })}
          </Heading>
          <ExpandableText
            allowHTML
            text={sectionData.checkInProcessNotes}
            truncateLength={120}
          />
          <Heading>
            {intl.formatMessage({
              id: 'admin.venueEventSetup.stageSetup',
            })}
          </Heading>
          <ExpandableText
            allowHTML
            text={sectionData.stageSetupNotes}
            truncateLength={120}
          />
          <Heading>
            {intl.formatMessage({
              id: 'admin.venueEventSetup.merchTablePlacement',
            })}
          </Heading>
          <ExpandableText
            allowHTML
            text={sectionData.merchTablePlacementNotes}
            truncateLength={120}
          />
          <Heading>
            {intl.formatMessage({
              id: 'admin.shared.internalSeatingNotes',
            })}
          </Heading>
          <ValueContainer>
            <ExpandableText
              allowHTML
              text={sectionData.seatingNotes}
              truncateLength={120}
            />
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.shared.paInfo',
            })}
          </Heading>
          <ValueContainer>
            {sectionData.maxPaInputs?.name ? (
              sectionData.maxPaInputs?.name
            ) : (
              <None />
            )}
          </ValueContainer>
        </Col>

        <Col
          xs={12}
          sm={12}
          md={3}
          lg={3}
          xl={3}
          key={4}
          data-qaid="col-artist-experience"
        >
          <Heading>
            {intl.formatMessage({
              id: 'admin.venueEventSetup.bathroomNotes',
            })}
          </Heading>
          <ExpandableText
            allowHTML
            text={sectionData.bathroomNotes}
            truncateLength={120}
          />
          <Heading>
            {intl.formatMessage({
              id: 'admin.shared.audioNotes',
            })}
          </Heading>
          <ExpandableText
            allowHTML
            text={sectionData.audioNotes}
            truncateLength={120}
          />
          <ArtistEventLogisticsNotes
            gearStorage={sectionData.gearStorage}
            hasTapWater={sectionData.hasTapWater}
            noiseRestrictions={sectionData.noiseRestrictions}
          />
        </Col>
      </Grid>
    </MainContainer>
  );
};

export default SectionEventSetup;
