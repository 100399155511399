import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { KeyValue } from 'app/typings/generics';
import { dateFormatter } from 'app/shared/utils/datetime';
import { snakecaseToTitleCase } from 'app/shared/utils/string';
import usePermission from 'app/shared/utils/usePermission';
import { userFullNameOrEmail } from 'app/admin/utils/users';
import { KeyAboveValueInfoList } from 'app/admin/components/molecules/KeyAboveValueInfoList';
import ListingCard from 'app/admin/components/organisms/ListingCard';
import { ReactComponent as BrowserPageAccount } from 'icons/streamline-regular/programing-apps-websites/website-development/browser-page-account.svg';

const TopContentContainer = styled.div`
  height: 300px;
`;

const CompetitionCard: React.FC<any> = ({
  id,
  title,
  closesAt,
  status,
  uid,
  city,
  event,
  creator,
  createdAt,
  entriesCount,
  onShowDetails,
}) => {
  const intl = useIntl();

  const hasViewEventsPermission = usePermission('event.list.view');
  const hasAccessUsersPermission = usePermission('user.list.access');

  const keysAboveValuesList = [
    {
      key: intl.formatMessage({
        id: 'admin.competitions.listingCard.closingDate',
      }),
      value: dateFormatter(closesAt, 'shortMonthDateAndYear', city.timezone),
    },
    {
      key: intl.formatMessage({
        id: 'shared.status',
      }),
      value: snakecaseToTitleCase(status),
    },
    {
      key: intl.formatMessage({
        id: 'admin.competitions.listingCard.uid',
      }),
      value: uid,
    },
    {
      key: intl.formatMessage({
        id: 'shared.city',
      }),
      value: city.title,
    },
    {
      key: intl.formatMessage({
        id: 'shared.concert',
      }),
      value: event
        ? dateFormatter(event.localStartsAt, 'shortMonthDateAndYear')
        : intl.formatMessage({ id: 'none' }),
      url:
        event && hasViewEventsPermission
          ? `/admin/concert-planner?event_id=${event.id}`
          : undefined,
    },
    {
      key: intl.formatMessage({
        id: 'admin.shared.creator',
      }),
      value: userFullNameOrEmail(creator) || '',
      url: hasAccessUsersPermission
        ? `/admin/users?user_id=${creator.id}`
        : undefined,
    },
  ];

  const keysAndValuesList = [
    {
      key: intl.formatMessage({
        id: 'admin.competitions.listingCard.createdOn',
      }),
      value: dateFormatter(createdAt, 'shortMonthDateAndYear'),
    },
    {
      key: intl.formatMessage({
        id: 'admin.competitions.listingCard.entries',
      }),
      value: entriesCount.toString(),
    },
  ];

  const controlsInfoList = [
    {
      href: `/comp/${uid}`,
      icon: BrowserPageAccount,
      tipText: intl.formatMessage({
        id: 'admin.competitions.listingCard.competitionPage',
      }),
    },
  ];

  const onShowDetailsParams = {
    id,
    title,
    status,
  };

  const renderTopContent = (keysAboveValuesList: KeyValue[]) => {
    return (
      <TopContentContainer>
        <KeyAboveValueInfoList keysAndValues={keysAboveValuesList} />
      </TopContentContainer>
    );
  };

  return (
    <>
      <ListingCard
        title={
          title ||
          intl.formatMessage({
            id: 'admin.competitions.listingCard.noTitle',
          })
        }
        topContent={() => renderTopContent(keysAboveValuesList)}
        keysAndValuesList={keysAndValuesList}
        dataQaidPrefix="competition"
        height="470px"
        controlsInfoList={controlsInfoList}
        onShowDetails={onShowDetails}
        onShowDetailsParams={onShowDetailsParams}
        showDetailsTipText={intl.formatMessage({
          id: 'admin.competitions.listingCard.competitionDetails',
        })}
      />
    </>
  );
};

export default CompetitionCard;
