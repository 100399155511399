import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import {
  AddVenuesToOfferData,
  CloseOfferData,
  CreateOfferForVenuesData,
  Offer,
  VenueOffer,
} from 'app/typings/offers';
import { Venue } from 'app/typings/venues';
import { useCurrentTheme } from 'app/shared/theme';
import { useAnalyticsContext } from 'app/shared/utils';
import {
  DISCOVERY_EVENT,
  REMOVE_VENUE_SENTINEL,
} from 'app/shared/utils/events';
import { get } from 'app/shared/utils/get';
import usePermission from 'app/shared/utils/usePermission';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import {
  ContentWrapper,
  FlyoverNote,
  HeaderWrapper,
} from 'app/shared/components/atoms/FlyoverContent';
import { BaseLink } from 'app/shared/components/atoms/LinkManualCSS';
import { Radio } from 'app/shared/components/atoms/Radio';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Spinner } from 'app/shared/components/atoms/SpinnerManualCSS';
import { StreamlineIcon } from 'app/shared/components/atoms/StreamlineIcon';
import { Overline } from 'app/shared/components/atoms/TypographyManualCSS';
import { UpdateEvent } from 'app/admin/graphql/events/mutationHooks';
import {
  AddVenuesToOffer,
  CloseOffer as CloseOfferHook,
  CreateOfferForVenues as CreateOfferForVenuesHook,
} from 'app/admin/graphql/offers/mutationHooks';
import { None } from 'app/admin/components/atoms/None';
import CircleThumbnailAndText from 'app/admin/components/molecules/CircleThumbnailAndText';
import FlyoverFooter from 'app/admin/components/molecules/FlyoverFooter';
import RichTextEditor from 'app/admin/components/molecules/RichTextEditor';
import VenueTypeahead from 'app/admin/components/molecules/VenueTypeahead';
import { ReactComponent as Check1 } from 'icons/streamline-regular/interface-essential/form-validation/check-1.svg';
import { ReactComponent as Close } from 'icons/streamline-regular/interface-essential/form-validation/close.svg';
import { ReactComponent as Add } from 'icons/streamline-regular/interface-essential/remove-add/add.svg';
import { ReactComponent as MoodQuestion } from 'icons/streamline-regular/social-medias-rewards-rating/mood/mood-question.svg';
import { ReactComponent as SocialProfileClick } from 'icons/streamline-regular/social-medias-rewards-rating/social-profile/social-profile-click.svg';

interface Props {
  cityId: number;
  citySlug: string;
  cityTitle: string;
  eventId: number;
  eventType: string;
  isEventCancelled: boolean;
  isEventInPast: boolean;
  isEventPublished: boolean;
  isVenueConfirmed: boolean;
  onSuccess: Function;
  offer?: Offer;
  show?: boolean;
  venue?: any;
  venueOffers?: any;
}

const InputWrapper = styled.div`
  display: flex;
`;

const InlineSpacer = styled(Spacer)`
  display: inline;
`;

const IconWrapper = styled.div`
  display: flex;
  cursor: pointer;
  white-space: nowrap;
`;

const StyledIcon = styled.span`
  margin-right: 12px;
`;

const WarningMessage = styled.span`
  font-style: italic;
  font-size: 12px;
  display: block;
`;

const Link = styled(BaseLink)`
  ${({ theme }) => css`
    color: ${theme.colors.green600};
    font-size: 12px;
  `}
`;

const VenueDisabledLink = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.macyGrey};
    font-size: 12px;
  `}
`;

const ConfirmMessage = styled.span`
  font-size: 12px;
  font-weight: 600;
  padding: 16px;
  text-align: center;
  display: block;
`;

const RemoveMessage = styled.div`
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin: 25px 0px 12px 0px;
`;

const RadioLabel = styled.span`
  font-size: 12px;
`;

const StyledText = styled.div`
  font-size: 14px;
`;

const CommentText = styled.div`
  font-size: 12px;
`;

const isNullVenue = (venue: Venue) => !(venue && venue.id);
const venueNameOf = (venue: Venue) =>
  venue && (venue.venueName || venue.address);

const EventPlannerVenueFlyover: React.FC<Props> = ({
  venue,
  venueOffers,
  isVenueConfirmed,
  isEventCancelled,
  isEventInPast,
  isEventPublished,
  cityId,
  citySlug,
  cityTitle,
  eventId,
  eventType,
  onSuccess,
  offer,
}) => {
  const intl = useIntl();
  const theme = useCurrentTheme();

  const hasViewVenuesPermission = usePermission('venue.list.view');
  const hasAccessBasicEventsPermission = usePermission(
    'event.list.accessBasic'
  );
  const { trackAnalyticsEvent } = useAnalyticsContext();

  const [venueState, setVenueState] = useState('venueChoice');
  const [searchMethod, setSearchMethod] = useState<string | null>(null);
  const [selectedVenue, setSelectedVenueToAdd] = useState(venue);
  const [
    selectedVenueOffer,
    setSelectedVenueOffer,
  ] = useState<VenueOffer | null>(null);
  const [confirmStatus, setConfirmStatus] = useState(isVenueConfirmed);
  const [submitting, setSubmitting] = useState(false);

  const updateEventAction = UpdateEvent();

  const handleAddVenue = useSubmitAction({
    submitAction: updateEventAction,
    submitVariables: () => ({
      eventId,
      venueId: selectedVenue?.id,
      venueOfferId: selectedVenueOffer?.id,
    }),
    successMsg: intl.formatMessage(
      {
        id: 'admin.eventPlanner.venues.addVenueSuccess',
      },
      {
        venue: venueNameOf(selectedVenue),
      }
    ),
    failureMsg: intl.formatMessage(
      {
        id: 'admin.eventPlanner.venues.addVenueFailure',
      },
      {
        venue: venueNameOf(selectedVenue),
      }
    ),
    onSuccess: (response: any) => {
      setSubmitting(false);
      if (venueOffers?.length > 0 && offer) {
        trackAnalyticsEvent(
          'Venue Booking Invite: Invite overriden by another venue',
          {
            event_id: eventId,
            offer_id: offer.id,
            new_venue_id: selectedVenue.id,
          }
        );
      }
      const venueOffersUpdated =
        response?.data?.updateEvent?.event?.venueOffers;
      onSuccess &&
        onSuccess({ venue: selectedVenue, venueOffersUpdated }, false);
    },
  });

  const handleRemoveVenue = useSubmitAction({
    submitAction: updateEventAction,
    submitVariables: () => ({
      eventId,
      venueId: REMOVE_VENUE_SENTINEL,
    }),
    successMsg: intl.formatMessage(
      {
        id: 'admin.eventPlanner.venues.removeVenueSuccess',
      },
      {
        venue: venueNameOf(venue),
      }
    ),
    failureMsg: intl.formatMessage(
      {
        id: 'admin.eventPlanner.venues.removeVenueFailure',
      },
      {
        venue: venueNameOf(venue),
      }
    ),
    onSuccess: () => {
      setSubmitting(false);
      onSuccess && onSuccess({ venue: null }, null);
    },
  });

  const handleUpdateVenueStatus = useSubmitAction({
    submitAction: updateEventAction,
    submitVariables: () => ({
      eventId,
      venueId: venue && venue.id,
      isVenueConfirmed: confirmStatus,
    }),
    successMsg: intl.formatMessage(
      {
        id: 'admin.eventPlanner.venues.updateVenueStatusSuccess',
      },
      {
        venue: venueNameOf(venue),
        confirmStatus: confirmStatus
          ? intl.formatMessage({ id: 'admin.eventPlanner.venues.confirmed' })
          : intl.formatMessage({ id: 'admin.eventPlanner.venues.unconfirmed' }),
      }
    ),
    failureMsg: intl.formatMessage(
      {
        id: 'admin.eventPlanner.venues.updateVenueStatusFailure',
      },
      {
        venue: venueNameOf(venue),
        confirmStatus: confirmStatus
          ? intl.formatMessage({ id: 'admin.eventPlanner.venues.confirmed' })
          : intl.formatMessage({ id: 'admin.eventPlanner.venues.unconfirmed' }),
      }
    ),
    onSuccess: () => {
      setSubmitting(false);
      onSuccess && onSuccess({ venue }, confirmStatus);
    },
  });

  const VenueHeader = () => (
    <HeaderWrapper>
      {!isNullVenue(venue) ? (
        <>
          <CircleThumbnailAndText
            data-qaid="venue-header-thumbnail"
            imageUrl={venue && venue.imageUrl}
            text={venueNameOf(venue) || ''}
            linkUrl={
              !isNullVenue(venue) && hasViewVenuesPermission
                ? `/admin/venues?venue_id=${venue.id}`
                : undefined
            }
            titleText={intl.formatMessage({
              id: 'admin.eventPlanner.venues.currentVenue',
            })}
          />
          <Spacer mt={4} />
        </>
      ) : (
        <>
          <CircleThumbnailAndText
            data-qaid="venue-header-thumbnail-null"
            displayAsEmpty={true}
            text=""
            titleText={intl.formatMessage({
              id: 'admin.eventPlanner.venues.currentVenue',
            })}
          />
          <Spacer mt={4} />
        </>
      )}
      <DottedLine />
    </HeaderWrapper>
  );

  const VenueCard = () => {
    if (submitting) {
      return (
        <ContentWrapper>
          <Spinner />
        </ContentWrapper>
      );
    }
    switch (venueState) {
      case 'venueChoice':
        return <VenueChoice />;
      case 'addVenue':
        return <AddVenue />;
      case 'createOffer':
        return <CreateOfferForVenue />;
      case 'viewOffer':
        return <ViewOffer />;
      case 'closeOffer':
        return <CloseOffer />;
      case 'updateVenueStatus':
        return <UpdateVenueStatus />;
      case 'deleteVenue':
        return <DeleteVenue />;
      default:
        return <VenueChoice />;
    }
  };

  const renderAvailableVenue = (
    venue: Venue,
    venues: Venue[],
    removeVenueFromInvitation: Function
  ) => {
    return (
      <>
        <CircleThumbnailAndText
          data-qaid="available-venue-thumbnail"
          imageUrl={venue?.imageUrl}
          text={venueNameOf(venue)}
          subtext={
            <>
              <StyledText>{cityTitle}</StyledText>
              <StyledText>{venue?.neighborhood?.title}</StyledText>
              <StyledText>{`Capacity: ${venue?.capacity}`}</StyledText>
            </>
          }
          onRemove={() => removeVenueFromInvitation(venue, venues)}
          linkUrl={
            venue && hasViewVenuesPermission
              ? `/admin/venues?venue_id=${venue?.id}`
              : undefined
          }
          textSize="14px"
          textTruncateLength={30}
        />
        <Spacer mt={10} />
      </>
    );
  };

  const VenueChoice = () => {
    if (!(venue && venue.id)) {
      return (
        <ContentWrapper data-qaid="venue-choice-card">
          <IconWrapper>
            <Link
              data-qaid="add-venue-link"
              onClick={() => setVenueState('addVenue')}
            >
              <StyledIcon>
                <StreamlineIcon
                  icon={Add}
                  size={14}
                  stroke={theme.colors.green600}
                />
              </StyledIcon>
              <span>
                {intl.formatMessage({
                  id: 'admin.eventPlanner.venues.selectVenue',
                })}
              </span>
            </Link>
          </IconWrapper>
          {eventType === DISCOVERY_EVENT && venueOffers?.length > 0 && (
            <>
              <Spacer mt={4} />
              <IconWrapper>
                <Link
                  data-qaid="view-invitation-link"
                  onClick={() => {
                    setVenueState('viewOffer');
                  }}
                >
                  <StyledIcon>
                    <StreamlineIcon
                      icon={MoodQuestion}
                      size={14}
                      stroke={theme.colors.green600}
                    />
                  </StyledIcon>
                  <FormattedMessage id="admin.eventPlanner.venues.viewInvitationForThisConcert" />
                </Link>
              </IconWrapper>
            </>
          )}
          {eventType === DISCOVERY_EVENT &&
            isEventPublished &&
            !isEventInPast &&
            !venueOffers?.length && (
              <>
                <Spacer mt={4} />
                <IconWrapper>
                  <Link
                    data-qaid="create-invitation-link"
                    onClick={() => {
                      setVenueState('createOffer');
                    }}
                  >
                    <StyledIcon>
                      <StreamlineIcon
                        icon={MoodQuestion}
                        size={14}
                        stroke={theme.colors.green600}
                      />
                    </StyledIcon>
                    <FormattedMessage id="admin.eventPlanner.venues.createInvitationForThisConcert" />
                  </Link>
                </IconWrapper>
              </>
            )}
        </ContentWrapper>
      );
    }
    return (
      <ContentWrapper data-qaid="venue-choice-card">
        {!isVenueConfirmed && (
          <>
            <IconWrapper>
              <Link
                data-qaid="add-venue-link"
                onClick={() => setVenueState('addVenue')}
              >
                <StyledIcon>
                  <StreamlineIcon
                    icon={Add}
                    size={14}
                    stroke={theme.colors.green600}
                  />
                </StyledIcon>
                <span>
                  {intl.formatMessage({
                    id: 'admin.eventPlanner.venues.changeVenue',
                  })}
                </span>
              </Link>
            </IconWrapper>
            <Spacer mt={4} />
          </>
        )}

        <IconWrapper>
          <Link onClick={() => setVenueState('updateVenueStatus')}>
            <StyledIcon>
              <StreamlineIcon
                icon={Check1}
                size={14}
                stroke={theme.colors.green600}
              />
            </StyledIcon>
            <span>
              {intl.formatMessage({
                id: 'admin.eventPlanner.venues.checkOrChangeVenueStatus',
              })}
            </span>
          </Link>
        </IconWrapper>
        <Spacer mt={4} />

        <IconWrapper>
          <Link onClick={() => setVenueState('deleteVenue')}>
            <StyledIcon>
              <StreamlineIcon
                icon={Close}
                size={14}
                stroke={theme.colors.green600}
              />
            </StyledIcon>
            {intl.formatMessage({
              id: 'admin.eventPlanner.venues.removeVenueFromEvent',
            })}
          </Link>
        </IconWrapper>
      </ContentWrapper>
    );
  };

  const AddVenue = () => {
    return (
      <>
        <ContentWrapper>
          <Radio
            id="searchByName"
            checked={searchMethod === 'name'}
            onChange={() => setSearchMethod('name')}
            name="searchByName"
            data-qaid="add-venue-search-by-name-radio-button"
          >
            <RadioLabel>
              {intl.formatMessage({
                id: 'admin.eventPlanner.venues.searchByName',
              })}
            </RadioLabel>
          </Radio>
          <Spacer mt={4} />

          {searchMethod === 'name' && (
            <>
              <InputWrapper>
                <VenueTypeahead
                  citySlugs={[citySlug]}
                  setSelectedVenue={(venue: Venue | undefined) => {
                    setSelectedVenueToAdd(venue);
                  }}
                  prepopulatedTypeaheadSearchString={venueNameOf(selectedVenue)}
                  showVenueAddressAndCity={false}
                  key="add_venue_to_event"
                />
              </InputWrapper>

              <Spacer mt={4} />

              {!isNullVenue(selectedVenue) && hasViewVenuesPermission ? (
                <Link
                  href={`/admin/venues?venue_id=${selectedVenue.id}`}
                  openInNewTab={true}
                >
                  <StreamlineIcon
                    icon={SocialProfileClick}
                    stroke={theme.colors.green600}
                    size={16}
                  />
                  &nbsp; View Venue Profile
                </Link>
              ) : (
                <VenueDisabledLink>
                  <StreamlineIcon
                    icon={SocialProfileClick}
                    stroke={theme.colors.macyGrey}
                    size={16}
                  />
                  &nbsp; View Venue Profile
                </VenueDisabledLink>
              )}
              <Spacer mt={4} />
            </>
          )}

          <Radio id="selectFromAvailable" name="selectFromAvailable" disabled>
            <RadioLabel>
              {intl.formatMessage({
                id: 'admin.eventPlanner.venues.selectFromAvailable',
              })}
            </RadioLabel>
          </Radio>

          {!isNullVenue(selectedVenue) && (
            <>
              <Spacer mt={4} />
              <WarningMessage>
                {intl.formatMessage({
                  id:
                    'admin.eventPlanner.venues.venuesWillBeAddedWithDraftStatus',
                })}
              </WarningMessage>
            </>
          )}
        </ContentWrapper>
        <FlyoverFooter
          buttonText={intl.formatMessage({
            id: 'admin.eventPlanner.venues.submit',
          })}
          buttonDisabled={
            isNullVenue(selectedVenue) ||
            (venue && selectedVenue && selectedVenue.id === venue.id)
          }
          onClickButton={() => {
            setSubmitting(true);
            handleAddVenue();
          }}
          dataQaid="add-venue-submit-button"
        />
      </>
    );
  };

  const UpdateVenueStatus = () => {
    return (
      <>
        <ContentWrapper>
          <Overline>
            {intl.formatMessage({
              id: 'admin.shared.venueStatus',
            })}
          </Overline>
          <Spacer mt={4} />

          <Radio
            id="venue-status-radio-confirmed"
            name="venue-status-radio-confirmed"
            checked={confirmStatus}
            onChange={() => setConfirmStatus(true)}
          >
            <RadioLabel>
              {intl.formatMessage({
                id: 'admin.eventPlanner.venues.confirmed',
              })}
            </RadioLabel>
          </Radio>
          <Spacer mt={4} />

          <Radio
            id="venue-status-radio-unconfirmed"
            name="venue-status-radio-unconfirmed"
            checked={!confirmStatus}
            onChange={() => setConfirmStatus(false)}
          >
            <RadioLabel>
              {intl.formatMessage({
                id: 'admin.eventPlanner.venues.unconfirmed',
              })}
            </RadioLabel>
          </Radio>
        </ContentWrapper>

        <FlyoverFooter
          buttonText={intl.formatMessage({
            id: 'admin.eventPlanner.venues.submit',
          })}
          buttonDisabled={confirmStatus === isVenueConfirmed}
          onClickButton={() => {
            setSubmitting(true);
            handleUpdateVenueStatus();
          }}
          dataQaid="update-venue-status-submit-button"
        />
      </>
    );
  };

  const CreateOfferForVenue = () => {
    const [customMessage, setCustomMessage] = useState<String>(
      intl.formatMessage({
        id: 'admin.eventPlanner.venues.customMessageTemplate',
      })
    );
    const [selectedVenues, setSelectedVenues] = useState<Venue[]>([]);

    const isVenueAlreadySelected = (venue: Venue) =>
      selectedVenues.map((venue: Venue) => venue.id).includes(venue.id);

    const addVenueToInvitation = (venue: Venue | undefined) => {
      if (venue && !isVenueAlreadySelected(venue)) {
        setSelectedVenues([venue].concat(selectedVenues));
      }
    };

    const removeVenueFromInvitation = (venue: Venue, venues: Venue[]) => {
      setSelectedVenues(venues.filter((v: Venue) => v.id !== venue.id));
    };

    const createOfferForVenuesAction = CreateOfferForVenuesHook();

    const handleCreateOffer = useSubmitAction({
      submitAction: createOfferForVenuesAction,
      submitVariables: () => ({
        cityId,
        venueIds: selectedVenues.map(venue => venue.id),
        eventIds: [eventId],
        customEmailMessage: customMessage || '',
      }),
      successMsg: intl.formatMessage({
        id: 'admin.eventPlanner.venues.createInvitationSuccess',
      }),
      failureMsg: intl.formatMessage({
        id: 'admin.eventPlanner.venues.createInvitationFailure',
      }),
      onSuccess: (response: CreateOfferForVenuesData) => {
        const createdOffer = get(
          response,
          'data.createOfferForVenues.offer',
          {}
        );
        setSubmitting(false);
        onSuccess && onSuccess({ offer: createdOffer }, false);
      },
    });

    return (
      <>
        <ContentWrapper>
          <Overline>
            <FormattedMessage id="admin.eventPlanner.venues.selectVenues" />
          </Overline>
          <Spacer mt={4} />
          <>
            <InputWrapper>
              <VenueTypeahead
                citySlugs={[citySlug]}
                setSelectedVenue={(venue: Venue | undefined) => {
                  addVenueToInvitation(venue);
                }}
                prepopulatedTypeaheadSearchString={venueNameOf(
                  selectedVenues && selectedVenues[0]
                )}
                showVenueAddressAndCity={false}
                key="add_venue_to_invitation"
              />
              <InlineSpacer mr={2} />
            </InputWrapper>
            <Spacer mt={8} />
            {selectedVenues.map((venue: Venue, i: number) => (
              <React.Fragment key={i}>
                {renderAvailableVenue(
                  venue,
                  selectedVenues,
                  removeVenueFromInvitation
                )}
              </React.Fragment>
            ))}
          </>
          <Spacer mt={8} />
          <Overline>
            <FormattedMessage id="admin.eventPlanner.venues.addACustomMessage" />
          </Overline>
          <Spacer mt={4} />
          <RichTextEditor
            name="venue-invitation-email-editor"
            currentValue={customMessage}
            onChange={value => {
              setCustomMessage(value);
            }}
            fontSize="12px"
          />
          <Spacer mt={4} />
          <CommentText>
            <i>
              {intl.formatMessage({
                id: 'admin.eventPlanner.venues.eachHostWillReceiveAnEmail',
              })}
            </i>
          </CommentText>
        </ContentWrapper>
        <FlyoverFooter
          buttonText={intl.formatMessage({
            id: 'admin.eventPlanner.venues.sendBookingSelection',
          })}
          buttonDisabled={selectedVenues.length === 0}
          onClickButton={() => {
            setSubmitting(true);
            handleCreateOffer();
          }}
          dataQaid="create-offer-for-venue-submit-button"
        />
      </>
    );
  };

  const CloseOffer = () => {
    const params = {
      id: offer && offer.id,
    };

    const closeOfferAction = CloseOfferHook();
    const handleCloseOffer = useSubmitAction({
      submitAction: closeOfferAction,
      submitVariables: () => params,
      successMsg: intl.formatMessage(
        {
          id: 'admin.offerDetails.closeOffer.successMessage',
        },
        {
          name: offer && offer.name,
        }
      ),
      failureMsg: intl.formatMessage({
        id: 'admin.offerDetails.closeOffer.failureMessage',
      }),
      onSuccess: (response: CloseOfferData) => {
        const closedOffer = get(response, 'data.closeOffer.offer', {});
        setSubmitting(false);
        onSuccess &&
          onSuccess({ offer: closedOffer }, false, 'venueChoice', false);
      },
      onValidationError: () => {
        setSubmitting(false);
      },
    });

    const closeOffer = () => {
      setSubmitting(true);
      handleCloseOffer();
    };

    return (
      <div data-qaid="close-offer-confirmation-flyover">
        <ContentWrapper>
          <Overline>
            <FormattedMessage id="admin.offerDetails.closeBookingInvitation" />
          </Overline>
          <Spacer mt={4} />
          <RemoveMessage>
            <FormattedMessage
              id="admin.offerDetails.cancelConfirm"
              values={{ id: params.id }}
            />
          </RemoveMessage>
          <FlyoverNote>
            {intl.formatMessage({
              id: 'admin.offerDetails.cancelConfirmSubtitleAbbreviatedHosts',
            })}
          </FlyoverNote>
        </ContentWrapper>
        <FlyoverFooter
          buttonText={intl.formatMessage({
            id: 'admin.offerDetails.closeBookingInvitation',
          })}
          onClickButton={closeOffer}
          dataQaid="close-offer-submit-button"
        />
      </div>
    );
  };

  const ViewOffer = () => {
    const customMessage = offer?.customEmailMessage;

    const [selectedVenues, setSelectedVenues] = useState<Venue[]>([]);

    const isVenueAlreadySelected = (venue: Venue) =>
      selectedVenues.map((venue: Venue) => venue.id).includes(venue.id) ||
      venueOffers.some((venueOffer: VenueOffer) => {
        return venueOffer.venue.id === venue.id;
      });

    const addVenueToInvitation = (venue: Venue | undefined) => {
      if (venue && !isVenueAlreadySelected(venue)) {
        setSelectedVenues([venue].concat(selectedVenues));
      }
    };

    const removeVenueFromInvitation = (venue: Venue, venues: Venue[]) => {
      setSelectedVenues(venues.filter((v: Venue) => v.id !== venue.id));
    };

    const addVenuesToOfferAction = AddVenuesToOffer();

    const handleAddVenuesToOffer = useSubmitAction({
      submitAction: addVenuesToOfferAction,
      submitVariables: () => ({
        eventId,
        offerId: venueOffers[0].offer.id,
        venueIds: selectedVenues.map(venue => venue.id),
      }),
      successMsg: intl.formatMessage({
        id: 'admin.eventPlanner.venues.updateInvitationSuccess',
      }),
      failureMsg: intl.formatMessage({
        id: 'admin.eventPlanner.venues.updateInvitationFailure',
      }),
      onSuccess: (response: AddVenuesToOfferData) => {
        const updatedOffer = get(response, 'data.addVenuesToOffer.offer', {});
        setSubmitting(false);
        onSuccess && onSuccess({ offer: updatedOffer }, false);
      },
    });

    const venueOffersByStatus: any =
      (venueOffers &&
        venueOffers
          .sort((a: VenueOffer, b: VenueOffer) => {
            const compValFora = a.venue.venueName || a.venue.address || '';
            const compValForb = b.venue.venueName || b.venue.address || '';
            if (compValFora > compValForb) {
              return 1;
            }
            if (compValFora < compValForb) {
              return -1;
            }
            return 0;
          })
          .reduce((retVal: object, venueOffer: VenueOffer) => {
            if (venueOffer && venueOffer.status) {
              (retVal[venueOffer.status] =
                retVal[venueOffer.status] || []).push(venueOffer);
            }
            return retVal;
          }, {})) ||
      {};

    const renderVenueOffer = (venueOffer: VenueOffer, alignItems?: string) => (
      <CircleThumbnailAndText
        data-qaid="venue-offer-thumbnail"
        imageUrl={venueOffer.venue?.imageUrl}
        text={venueOffer.venue?.venueName || venueOffer.venue?.address}
        subtext={
          <>
            <StyledText>{cityTitle}</StyledText>
            <StyledText>{venueOffer.venue?.neighborhood?.title}</StyledText>
            <StyledText>{`Capacity: ${venueOffer.venue?.capacity}`}</StyledText>
          </>
        }
        textSize="14px"
        linkUrl={
          hasViewVenuesPermission
            ? `/admin/venues?venue_id=${venueOffer.venue?.id}`
            : undefined
        }
        titleText=""
        alignItems={alignItems}
      />
    );

    return (
      <div data-qaid="view-offer-flyover">
        <ContentWrapper>
          {!isEventCancelled && !isEventInPast && (
            <>
              <Overline>
                <FormattedMessage id="admin.eventPlanner.venues.selectVenues" />
              </Overline>
              <Spacer mt={4} />
              <>
                <InputWrapper>
                  <VenueTypeahead
                    citySlugs={[citySlug]}
                    setSelectedVenue={(venue: Venue | undefined) => {
                      addVenueToInvitation(venue);
                    }}
                    prepopulatedTypeaheadSearchString={venueNameOf(
                      selectedVenues && selectedVenues[0]
                    )}
                    showVenueAddressAndCity={false}
                    key="add_venue_to_invitation_from_view"
                  />
                  <InlineSpacer mr={2} />
                </InputWrapper>
                <Spacer mt={8} />
                {selectedVenues.map((venue: Venue, i: number) => (
                  <React.Fragment key={i}>
                    {renderAvailableVenue(
                      venue,
                      selectedVenues,
                      removeVenueFromInvitation
                    )}
                  </React.Fragment>
                ))}
              </>
            </>
          )}
          <Overline>
            <FormattedMessage id="admin.eventPlanner.shared.available" />
          </Overline>
          {!!venueOffersByStatus.accepted || <None />}
          <Spacer mt={4} />
          {venueOffersByStatus.accepted &&
            venueOffersByStatus.accepted.map(
              (venueOffer: VenueOffer, index: number) => (
                <React.Fragment key={index}>
                  <Radio
                    id={`acceptedOffer-${venueOffer.id}`}
                    name={`acceptedOffer-${venueOffer.id}`}
                    value={venueOffer.venue?.id.toString()}
                    checked={selectedVenue?.id === venueOffer?.venue?.id}
                    onChange={() => {
                      setSelectedVenueToAdd(venueOffer.venue);
                      setSelectedVenueOffer(venueOffer);
                    }}
                    data-qaid="accepted-offer-radio-button"
                  >
                    {renderVenueOffer(venueOffer, 'flex-start')}
                  </Radio>
                  <Spacer mt={4} />
                </React.Fragment>
              )
            )}
          <Overline>
            <FormattedMessage id="admin.eventPlanner.shared.noResponse" />
          </Overline>
          {!!venueOffersByStatus.open || <None />}
          <Spacer mt={4} />
          {venueOffersByStatus.open &&
            venueOffersByStatus.open.map(
              (venueOffer: VenueOffer, index: number) => (
                <React.Fragment key={index}>
                  {renderVenueOffer(venueOffer, 'flex-start')}
                  <Spacer mt={4} />
                </React.Fragment>
              )
            )}
          <Overline>
            <FormattedMessage id="admin.eventPlanner.shared.declined" />
          </Overline>
          {!!venueOffersByStatus.declined || <None />}
          <Spacer mt={4} />
          {venueOffersByStatus.declined &&
            venueOffersByStatus.declined.map(
              (venueOffer: VenueOffer, index: number) => (
                <React.Fragment key={index}>
                  {renderVenueOffer(venueOffer, 'flex-start')}
                  <Spacer mt={4} />
                </React.Fragment>
              )
            )}
          {customMessage && (
            <>
              <Overline>
                <FormattedMessage id="admin.eventPlanner.venues.customMessage" />
              </Overline>
              <Spacer mt={4} />
              <CommentText
                dangerouslySetInnerHTML={{ __html: customMessage }}
              />
            </>
          )}
        </ContentWrapper>
        {venueOffersByStatus.accepted && selectedVenues.length === 0 ? (
          <FlyoverFooter
            buttonText={intl.formatMessage({
              id: 'admin.eventPlanner.venues.confirmVenue',
            })}
            buttonDisabled={!selectedVenueOffer}
            onClickButton={() => {
              setSubmitting(true);
              handleAddVenue();
            }}
            dataQaid="view-offer-submit-button"
          />
        ) : hasAccessBasicEventsPermission && selectedVenues.length === 0 ? (
          <FlyoverFooter
            buttonText={intl.formatMessage({
              id: 'admin.offerDetails.closeBookingInvitation',
            })}
            onClickButton={() => {
              setVenueState('closeOffer');
            }}
            dataQaid="close-booking-invitation-button"
          />
        ) : (
          <FlyoverFooter
            buttonText={intl.formatMessage({
              id: 'admin.offerDetails.updateBookingInvitation',
            })}
            onClickButton={() => {
              setSubmitting(true);
              handleAddVenuesToOffer();
            }}
            dataQaid="update-booking-invitation-button"
          />
        )}
      </div>
    );
  };

  const DeleteVenue = () => {
    return (
      <>
        <ContentWrapper>
          <ConfirmMessage>
            {intl.formatMessage(
              {
                id: 'admin.eventPlanner.venues.confirmRemoveVenue',
              },
              {
                venue: venueNameOf(venue),
              }
            )}
          </ConfirmMessage>
        </ContentWrapper>
        <FlyoverFooter
          buttonText={intl.formatMessage({
            id: 'admin.shared.remove',
          })}
          onClickButton={() => {
            setSubmitting(true);
            handleRemoveVenue();
          }}
          dataQaid="delete-venue-submit-button"
        />
      </>
    );
  };

  return (
    <div data-qaid="venue-flyover">
      <VenueHeader />
      <VenueCard />
    </div>
  );
};

export default EventPlannerVenueFlyover;
