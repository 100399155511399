import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { EventPlannerEvent } from 'app/typings/events';
import { dateFormatter } from 'app/shared/utils/datetime';
import { FEATURED_SET_EVENT } from 'app/shared/utils/events';
import usePermission from 'app/shared/utils/usePermission';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import {
  HasEditPermissionForEvent as hasEditPermissionForEvent,
  HasPermissionForEvent as hasPermissionForEvent,
} from 'app/admin/utils/events';
import { eventTypeDisplayConfig } from 'app/admin/utils/eventTypeDisplayConfig';
import {
  AdminListingCard,
  EventCollections,
  EventDate,
  EventId,
  EventLocation,
  EventType,
} from 'app/admin/components/molecules/EventPlannerUtils';
import { CardControls } from 'app/admin/components/organisms/CardControls';
import { ReactComponent as MapsPin2 } from 'icons/streamline-regular/maps-navigation/maps/maps-pin-2.svg';
import { ReactComponent as MusicConcertTicket } from 'icons/streamline-regular/music-audio/music/music-concert-ticket.svg';
import { ReactComponent as BrowserPageAccount } from 'icons/streamline-regular/programing-apps-websites/website-development/browser-page-account.svg';

import EventArtists from './EventArtists';
import EventCrew from './EventCrew';
import EventDetails from './EventDetails';
import EventFeatures from './EventFeatures';
import EventGuests from './EventGuests';
import EventProduction from './EventProduction';
import EventTags from './EventTags';
import EventVenue from './EventVenue';

interface Props {
  index: number;
  displayFields: string[] | undefined | null;
  event: EventPlannerEvent;
  onShowDetails?: Function;
  onCreateOffer: (position: number) => void;
  onViewGuestlist: (eventData: any) => void;
}

const EventBasics = styled.div`
  margin-bottom: 15px;
  padding-top: 5px;
`;

const EventTopContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: flex-start;
  width: 100%;
`;

const eventShadedColors = {
  mainBackground: '#E8E8E8',
  status: 'blueSmoke',
  artistVenueConfirmed: 'macyGrey',
  guestsProgressBarFilled: 'lightBlueSmoke',
  guestsProgressBarUnfilled: 'whiteDenim',
};

const EventPlannerCard: React.FC<Props> = ({
  index,
  displayFields,
  event,
  onShowDetails = () => {},
  onCreateOffer,
  onViewGuestlist,
}) => {
  const intl = useIntl();

  const {
    id,
    attendeeFlow,
    city,
    commentsCount,
    curatorGroup,
    currencySymbol,
    type,
    eventAttendeeGuestsConfirmedCount,
    eventOrganizedAs,
    guestsArriveAt,
    isFilmed,
    eventFormatExperiment,
    isPublished,
    isSoldOut,
    localStartsAt,
    maxPaInputs,
    neighborhood,
    numTicketsAvailableForSale,
    isAppliable,
    isAvailable,
    isSpecialEvent,
    isVenueConfirmed,
    revenue,
    specialEventUrl,
    staff,
    eventCollections,
    status,
    totalGuestsConfirmedCount,
    venue,
    venueOffers,
    vipAttendeeGuestsCount,
  } = event;

  const offer = event?.venueOffers[0]?.offer;

  const hasGuestlistAccessPermission = usePermission('event.guestlist.access');
  const canViewEvent = hasPermissionForEvent(eventOrganizedAs, curatorGroup);
  const canEditEvent = hasEditPermissionForEvent(
    eventOrganizedAs,
    curatorGroup
  );
  const formattedGuestsArriveAt =
    guestsArriveAt &&
    dateFormatter(
      guestsArriveAt,
      'hoursAndMinutesWithSpacedAMPM',
      city.timezone
    );

  const showComponent = (component: string) => {
    if (displayFields) {
      return displayFields.includes(component);
    }
    return true;
  };

  const getEventPageUrl = () => {
    const displayConfig = type && eventTypeDisplayConfig[type];

    if (!isPublished) {
      return;
    }

    if (isSpecialEvent) {
      return specialEventUrl;
    }

    switch (type) {
      case FEATURED_SET_EVENT:
        return `/${displayConfig.cachedSlugPublicPageUrl}/${event.cachedSlug}`;
      default:
        return `/events/${id}`;
    }
  };

  const controlsInfoList = [
    {
      icon: MapsPin2,
      tipText: intl.formatMessage({
        id: 'admin.eventPlanner.cardControls.map',
      }),
    },
    {
      action:
        canViewEvent && hasGuestlistAccessPermission
          ? () => {
              onViewGuestlist({
                id,
              });
            }
          : undefined,
      icon: MusicConcertTicket,
      tipText: intl.formatMessage({
        id: 'admin.eventPlanner.cardControls.guestList',
      }),
    },
    {
      href: canViewEvent ? getEventPageUrl() : undefined,
      icon: BrowserPageAccount,
      tipText: intl.formatMessage({
        id: 'admin.eventPlanner.cardControls.eventPage',
      }),
    },
  ];

  const onShowDetailsParams = {
    id,
    city,
    neighborhood,
    localStartsAt,
    staff,
    type,
    eventAttendeeGuestsConfirmedCount,
    numTicketsAvailableForSale,
    eventOrganizedAs,
  };

  const mainBackgroundColor = canViewEvent
    ? undefined
    : eventShadedColors.mainBackground;

  const neighborhoodTitle =
    venue?.neighborhood?.title || (venue?.id && ' ') || neighborhood?.title;

  return (
    <AdminListingCard
      color={mainBackgroundColor}
      key={id}
      data-qaid="event-planner-card"
    >
      <EventBasics>
        <EventTopContent>
          <EventTags
            isAppliable={isAppliable}
            isAvailable={isAvailable}
            status={status}
            canEditEvent={canEditEvent}
            attendeeFlow={attendeeFlow}
            isSoldOut={isSoldOut}
          />
          <EventDate
            show={true}
            localStartsAt={localStartsAt}
            greyedOut={!canEditEvent}
          />
        </EventTopContent>
        <EventLocation
          show={true}
          cityTitle={city.title}
          neighborhoodTitle={neighborhoodTitle}
        />
        <EventType show={true} type={type} />
        <EventCollections show={true} eventCollections={eventCollections} />
        <EventId eventId={id} />
        <EventFeatures
          id={id}
          eventTheme={event.theme}
          commentsCount={commentsCount}
          maxPaInputs={maxPaInputs}
          isFilmed={isFilmed}
          eventFormatExperiment={eventFormatExperiment}
        />
      </EventBasics>
      <DottedLine />
      <EventArtists
        show={showComponent('artists')}
        event={event}
        canEditEvent={canEditEvent}
        onCreateOffer={onCreateOffer}
      />
      <EventVenue
        show={showComponent('venue')}
        venueOffers={venueOffers}
        cityId={city.id}
        citySlug={city.cachedSlug}
        cityTitle={city.title}
        eventId={id}
        isEventCancelled={event.status === 'cancelled'}
        isEventInPast={new Date().getTime() > Date.parse(event.startsAt)}
        isEventPublished={isPublished}
        type={type}
        displayConfig={eventTypeDisplayConfig[type]}
        venue={venue}
        isVenueConfirmed={isVenueConfirmed}
        numTicketsAvailableForSale={
          venue && venue.id
            ? venue.numTicketsAvailableForSale
            : numTicketsAvailableForSale
        }
        canEditEvent={canEditEvent}
        offer={offer}
      />
      <EventCrew
        show={showComponent('team')}
        staff={staff}
        canEditEvent={canEditEvent}
      />
      <EventProduction
        show={showComponent('production')}
        maxPaInputs={maxPaInputs}
        isFilmed={isFilmed}
        displayConfig={eventTypeDisplayConfig[type]}
      />
      <EventGuests
        show={showComponent('guests')}
        vipAttendeeGuestsCount={vipAttendeeGuestsCount}
        numTicketsAvailableForSale={numTicketsAvailableForSale}
        eventAttendeeGuestsConfirmedCount={eventAttendeeGuestsConfirmedCount}
        totalGuestsConfirmedCount={totalGuestsConfirmedCount}
        revenue={revenue}
        currencySymbol={currencySymbol}
        displayConfig={eventTypeDisplayConfig[type]}
        canEditEvent={canEditEvent}
        eventOrganizedAs={eventOrganizedAs}
      />
      <EventDetails
        show={showComponent('details')}
        guestsArriveAt={formattedGuestsArriveAt}
        eventOrganizedAs={eventOrganizedAs}
        curatorGroup={curatorGroup}
        partnerTitle={event.campaign?.partner?.title}
      />
      <CardControls
        controlsInfoList={controlsInfoList}
        onClickShowDetails={
          canViewEvent
            ? onShowDetails &&
              (() => {
                onShowDetails(onShowDetailsParams);
              })
            : undefined
        }
        showDetailsTipText={intl.formatMessage({
          id: 'admin.eventPlanner.cardControls.expandDetails',
        })}
        dataQaidPrefix="event-planner"
        cardIndex={index}
        extraHeight={false}
      />
    </AdminListingCard>
  );
};

export default EventPlannerCard;
