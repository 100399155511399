import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { MaxPaInputs } from 'app/typings';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import { Overline } from 'app/shared/components/atoms/TypographyManualCSS';
import { KeyValueInfoList } from 'app/admin/components/molecules/KeyValueInfoList';

interface ProductionProps {
  show: boolean;
  maxPaInputs: MaxPaInputs;
  isFilmed: boolean;
  displayConfig: any;
}

const TitleOverline = styled(Overline)`
  margin-bottom: 5px;
`;

const EventProduction: React.FC<ProductionProps> = ({
  show = true,
  maxPaInputs,
  isFilmed,
  displayConfig,
}) => {
  const intl = useIntl();
  const displayHelper = (display: boolean, field: any) =>
    display ? field : intl.formatMessage({ id: 'admin.notApplicable' });
  if (!show) {
    return null;
  }

  const keysAndValuesList = [
    {
      key: intl.formatMessage({
        id: 'admin.eventPlanner.production.maxPaInputs',
      }),
      value: displayHelper(
        displayConfig.displayMaxPaInputs,
        maxPaInputs && maxPaInputs.name
          ? maxPaInputs.name
          : intl.formatMessage({
              id: 'none',
            })
      ),
    },
    {
      key: intl.formatMessage({
        id: 'admin.shared.filmed',
      }),
      value: displayHelper(
        displayConfig.displayIsFilmed,
        isFilmed
          ? intl.formatMessage({
              id: 'yes',
            })
          : intl.formatMessage({
              id: 'no',
            })
      ),
    },
  ];

  return (
    <div data-qaid="event-planner-card-production">
      <TitleOverline>
        {intl.formatMessage({
          id: 'admin.eventPlanner.production.label',
        })}
      </TitleOverline>
      <KeyValueInfoList
        dataQaidPrefix="event-production"
        keysAndValues={keysAndValuesList}
      />
      <DottedLine />
    </div>
  );
};

export default EventProduction;
