import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { ArtistSetup } from 'app/typings/artistSetups';
import { dateFormatter } from 'app/shared/utils/datetime';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import {
  Body2,
  Overline,
} from 'app/shared/components/atoms/TypographyManualCSS';

interface Props {
  artistSetup: ArtistSetup;
}

const InfoFlyoverContainer = styled.div`
  padding: 15px 20px 30px 20px;
`;

const TitleContainer = styled.div`
  padding-bottom: 4px;
`;

const Title = styled(Body2)`
  font-weight: 700;
  letter-spacing: 0.5px;
  white-space: nowrap;
`;

const AudioSupportLabel = styled.span`
  text-transform: capitalize;
`;

const SetupName = styled.div``;

const SetupLastUpdated = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.blueSmoke};
    font-size: 12px;
  `};
`;

const SetupDescription = styled.div`
  font-size: 14px;

  strong {
    font-weight: 600;
  }
`;

const ArtistSetupDetails: React.FC<Props> = ({ artistSetup }) => {
  const intl = useIntl();
  const {
    setupName,
    createdAt,
    audioSupport,
    lastUpdatedAt,
    isPrimarySetup,
    numPerformers,
    additionalSupportNotes,
    instrumentNotes,
  } = artistSetup;

  return (
    <div data-qaid="artist-setup-details-flyover">
      <InfoFlyoverContainer>
        <Overline spaceAfter={2}>
          {intl.formatMessage({
            id: 'admin.artistApplication.details.moreInfo',
          })}
        </Overline>
        <TitleContainer>
          <Title>
            {intl.formatMessage({
              id: 'admin.artistApplication.details.setupDetails',
            })}
          </Title>
        </TitleContainer>
        <DottedLine />
        <Spacer mt={4} mb={4} />
        <SetupDescription>
          <SetupName>
            <strong>
              {setupName}
              {isPrimarySetup &&
                ` (${intl.formatMessage({
                  id: 'admin.shared.primary',
                })})`}
            </strong>
          </SetupName>
          <SetupLastUpdated>
            <i>
              {intl.formatMessage({
                id: 'admin.shared.updatedOn',
              })}
              {lastUpdatedAt
                ? dateFormatter(lastUpdatedAt, 'shortMonthDateAndYear')
                : dateFormatter(createdAt, 'shortMonthDateAndYear')}
            </i>
          </SetupLastUpdated>
          <p>
            <strong>
              {intl.formatMessage({
                id: 'admin.shared.numPerformers',
              })}
              :
            </strong>{' '}
            {numPerformers}
          </p>
          <p>
            <strong>
              {intl.formatMessage({
                id: 'admin.shared.audioSupport',
              })}
              :
            </strong>{' '}
            <AudioSupportLabel>
              {audioSupport.replace('_', ' ')}
            </AudioSupportLabel>
          </p>
          <p>
            <strong>
              {intl.formatMessage({
                id: 'admin.shared.additionalSupportNotes',
              })}
              :
            </strong>
            <br />
            {additionalSupportNotes ||
              intl.formatMessage({
                id: 'none',
              })}
          </p>
          <p>
            <strong>
              {intl.formatMessage({
                id: 'admin.shared.instrumentNotes',
              })}
              :
            </strong>
            <br />
            {instrumentNotes ||
              intl.formatMessage({
                id: 'none',
              })}
          </p>
        </SetupDescription>
      </InfoFlyoverContainer>
    </div>
  );
};

export default ArtistSetupDetails;
