import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { useCurrentTheme } from 'app/shared/theme';
import { dateFormatter } from 'app/shared/utils/datetime';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import LazyImgixImage from 'app/shared/components/atoms/LazyImgixLoad';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { StreamlineIcon } from 'app/shared/components/atoms/StreamlineIcon';
import { ReactComponent as ArrowLeft1 } from 'icons/streamline-regular/arrows-diagrams/arrows/arrow-left-1.svg';
import { ReactComponent as ArrowRight1 } from 'icons/streamline-regular/arrows-diagrams/arrows/arrow-right-1.svg';

interface CarouselProps {
  timezone: string;
  title: string;
  label: string;
  photos: any;
}

const CarouselContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const SectionText = styled.div`
  ${({ theme }) => css`
    ${theme.media.xs`
      font-size: 14px;
    `}
    ${theme.media.md`
      font-size: 16px;
    `}
  `}
`;

const PhotoCard = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.silverSprings};
    border-radius: 10px;
    padding: 0px 16px 16px 16px;
    overflow: hidden;
    width: 100%;
    display: flex;
    align-items: center;
  `}
`;

const PhotoCardContent = styled.div`
  width: 100%;
`;

const ControlsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const LeftControl = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const RightControl = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const DotControls = styled.div`
  display: flex;
  align-items: center;
  margin-left: 12px;
  margin-right: 12px;
`;

const Dot = styled.div<{ active?: boolean }>`
  ${({ active, theme }) => css`
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: ${active ? theme.colors.green600 : theme.colors.macyGrey};
    margin: 0px 4px;
    cursor: pointer;
  `}
`;

const IconWrapper = styled.div<{ active?: boolean }>`
  ${({ active }) => css`
    cursor: ${active ? 'pointer' : 'default'};
  `}
`;

const Subheading = styled.span`
  font-weight: 600;
`;

const InlineHtml = styled.div`
  display: inline;
  max-width: 400px;
`;

const GrayItalic = styled.div`
  ${({ theme }) => css`
    font-style: italic;
    color: ${theme.colors.blueSmoke};
  `}
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  padding: 0px;
`;

const PhotoCarousel: React.FC<CarouselProps> = ({
  timezone,
  title,
  photos,
  label,
}) => {
  const intl = useIntl();
  const theme = useCurrentTheme();

  const [index, setIndex] = useState(0);

  const validIndex = (newIndex: number) => {
    return newIndex >= 0 && newIndex < photos.length;
  };

  return (
    <Grid>
      <Col md={12} xs={12}>
        <CarouselContainer>
          <StyledRow>
            <ControlsContainer>
              <LeftControl>
                <IconWrapper
                  active={validIndex(index - 1)}
                  onClick={() => {
                    if (validIndex(index - 1)) {
                      setIndex(index - 1);
                    }
                  }}
                >
                  <StreamlineIcon
                    stroke={
                      validIndex(index - 1)
                        ? theme.colors.blackBetty
                        : theme.colors.macyGrey
                    }
                    icon={ArrowLeft1}
                    size={28}
                  />
                </IconWrapper>
              </LeftControl>
              <DotControls>
                {[...Array(photos.length).keys()].map((key: number) => (
                  <Dot
                    key={key}
                    onClick={() => setIndex(key)}
                    active={key === index}
                  />
                ))}
              </DotControls>
              <RightControl>
                <IconWrapper
                  active={validIndex(index + 1)}
                  onClick={() => {
                    if (validIndex(index + 1)) {
                      setIndex(index + 1);
                    }
                  }}
                >
                  <StreamlineIcon
                    stroke={
                      validIndex(index + 1)
                        ? theme.colors.blackBetty
                        : theme.colors.macyGrey
                    }
                    icon={ArrowRight1}
                    size={28}
                  />
                </IconWrapper>
              </RightControl>
            </ControlsContainer>
          </StyledRow>

          <Spacer mb={5} />
          <PhotoCard>
            <PhotoCardContent>
              <SectionText>
                <Spacer mt={4} />
                <>
                  <Subheading>{label}</Subheading>
                  &nbsp;
                  {title}
                </>
                <Spacer mt={4} />
              </SectionText>
              <LazyImgixImage
                src={photos[index].imageUrl || ''}
                sizes="750px"
                aspectRatio="1.6:1"
                dataQaid="venue-image"
              />
              <SectionText>
                <Spacer mt={2} />
                <Subheading>{photos[index]?.title || ''}</Subheading>
              </SectionText>
              <SectionText>
                <Spacer mt={2} />
                <GrayItalic>
                  {`${photos[index].createdBy?.firstName} ${photos[index].createdBy?.lastName}`}
                </GrayItalic>
                <Spacer mt={1} />
                <GrayItalic>
                  {intl.formatMessage({
                    id: 'admin.crewEvent.section.venueSetupInfo.updated',
                  })}
                  :{' '}
                  {dateFormatter(
                    photos[index].updatedAt,
                    'longMonthDateHoursAndMinutes',
                    timezone
                  )}
                </GrayItalic>
              </SectionText>
              <InlineHtml
                dangerouslySetInnerHTML={{
                  __html: photos[index]?.description || '',
                }}
              />
              <Spacer mt={2} />
            </PhotoCardContent>
          </PhotoCard>
        </CarouselContainer>
        <Spacer mb={4} />
      </Col>
    </Grid>
  );
};

export default PhotoCarousel;
