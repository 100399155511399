import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { City } from 'app/typings';
import { errorMsgForField } from 'app/shared/utils/form';
import { FormGroupContainer } from 'app/shared/components/atoms/FormContent';
import { DeleteIcon } from 'app/shared/components/atoms/IconLibrary';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Body } from 'app/shared/components/atoms/TypographyManualCSS';
import FormGroup from 'app/shared/components/molecules/FormGroup';
import RadioGroup from 'app/shared/components/molecules/RadioGroup';
import CitySelectorManualCSS from 'app/shared/components/organisms/CitySelectorManualCSS';

import { CollectionBasicInfoFormSectionHeader } from './components';
import { CollectionBasicInfoFormikProps } from './typings';

const CityContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;
`;

const IndentedSection = styled.div`
  margin-left: 28px;
`;

const IconLink = styled.div`
  cursor: pointer;
`;

const StyledNote = styled.p`
  font-size: 12px;
  font-style: italic;
`;

export const CitiesSection: React.FC<CollectionBasicInfoFormikProps> = ({
  values,
  setFieldValue,
  touched,
  errors,
}) => {
  const { formatMessage } = useIntl();
  const cityIdOptions = values.events
    .map((event: any) => event.city.id)
    .filter(
      (cityId: number) =>
        (values.selectedCities || []).findIndex(
          (city: City) => city.id === cityId
        ) === -1
    );

  return (
    <>
      <CollectionBasicInfoFormSectionHeader
        titleIntlId="admin.collectionBasicInfo.form.selectCities"
        descriptionIntlId="admin.collectionBasicInfo.form.citiesDescription"
      />
      <FormGroupContainer>
        <FormGroup
          data-qaid="collection-about-section-edit-form-cities-formgroup"
          errorMsg={errorMsgForField('events', touched, errors)}
        >
          <>
            <RadioGroup
              name="cityDisplayType"
              options={[
                {
                  value: 'all_cities',
                  title: formatMessage({
                    id: 'admin.collectionBasicInfo.form.allCities',
                  }),
                },
                {
                  value: 'no_cities',
                  title: formatMessage({
                    id: 'admin.collectionBasicInfo.form.noCities',
                  }),
                },
                {
                  value: 'selected_cities',
                  title: formatMessage({
                    id: 'admin.collectionBasicInfo.form.selectedCities',
                  }),
                },
              ]}
              selectedValue={values.cityDisplayType}
              disabled={values.status !== 'active'}
              onChange={value => setFieldValue('cityDisplayType', value)}
            />

            {(values.cityDisplayType || '') === 'selected_cities' &&
              values.status === 'active' && (
                <IndentedSection>
                  <Spacer mt={4} />
                  <Body>
                    <strong>{formatMessage({ id: 'shared.city' })}</strong>
                  </Body>
                  <Spacer mt={2} />
                  <CitySelectorManualCSS
                    data-qaid="collection-about-section-edit-form-city-selector"
                    parentContainer="collection-about-section-edit-form-cities-formgroup"
                    disableTopCities
                    disableClosestCities
                    disabled={cityIdOptions.length === 0}
                    cityIds={cityIdOptions}
                    initialValue={undefined}
                    initialWidth="300px"
                    value={undefined}
                    onChange={(city: City) => {
                      if (city) {
                        setFieldValue('selectedCities', [
                          ...values.selectedCities,
                          city,
                        ]);
                      }
                    }}
                  />

                  <StyledNote>
                    {formatMessage({
                      id: 'admin.collectionBasicInfo.form.selectCitiesNote',
                    })}
                  </StyledNote>

                  {values.selectedCities.length > 0 && (
                    <>
                      <Spacer mt={4} />
                      {values.selectedCities.map(
                        (city: City, index: number) => (
                          <CityContainer key={index}>
                            <Body>{city.title}</Body>
                            <IconLink
                              onClick={() => {
                                setFieldValue(
                                  'selectedCities',
                                  // @ts-ignore
                                  values.selectedCities.toSpliced(index, 1)
                                );
                              }}
                            >
                              <DeleteIcon />
                            </IconLink>
                          </CityContainer>
                        )
                      )}
                    </>
                  )}
                </IndentedSection>
              )}
          </>
        </FormGroup>
      </FormGroupContainer>
    </>
  );
};
