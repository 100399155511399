import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Competition } from 'app/typings/competitions';
import { EventAttendee } from 'app/typings/eventAttendees';
import usePermission from 'app/shared/utils/usePermission';
import { getDisplayStatus } from 'app/admin/utils/guestlist';
import DownloadLink from 'app/admin/components/atoms/DownloadLink';
import { UserLink } from 'app/admin/components/molecules/EntityLink';
import ListTable from 'app/admin/components/organisms/ListTable';

interface Props {
  sectionData: Competition;
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const NoEntries = styled.div`
  width: 100%;
`;

const SectionEntries: React.FC<Props> = ({ sectionData }) => {
  const intl = useIntl();

  const hasDownloadCompetitionEntriesPermission = usePermission(
    'competition.entries.download'
  );

  const columnsConfig = [
    {
      headerText: intl.formatMessage({
        id: 'admin.competitionEntries.entries.name',
      }),
      columnWidth: 300,
    },
    {
      headerText: intl.formatMessage({
        id: 'shared.status',
      }),
      columnWidth: 150,
    },
    {
      headerText: intl.formatMessage({
        id: 'admin.competitionEntries.entries.winner',
      }),
      columnWidth: 100,
    },
  ];

  const rowValues = (eventAttendee: EventAttendee) => [
    eventAttendee.user ? (
      <UserLink user={eventAttendee.user} truncateLength={22} key={1} />
    ) : (
      ''
    ),
    getDisplayStatus(eventAttendee.currentState),
    ['guestlisted', 'confirmed', 'reserved'].includes(
      eventAttendee.currentState
    )
      ? 'Yes'
      : 'No',
  ];

  const downloadUrl = `/admin/download-competition-entries.csv?id=${sectionData.id}`;

  return (
    <MainContainer data-qaid="section-entries">
      {hasDownloadCompetitionEntriesPermission &&
        sectionData.entries &&
        sectionData.entries.length > 0 && (
          <DownloadLink
            text={intl.formatMessage({
              id: 'admin.competitionEntries.heading.downloadEntriesCsv',
            })}
            url={downloadUrl}
          />
        )}
      <ListTable
        columnsConfig={columnsConfig}
        rowValues={rowValues}
        data={sectionData.entries}
        noResultsContent={
          sectionData.entries &&
          sectionData.entries.length == 0 && (
            <NoEntries>
              {intl.formatMessage({
                id: 'admin.competitionEntries.entries.noEntries',
              })}
            </NoEntries>
          )
        }
      />
    </MainContainer>
  );
};

export default SectionEntries;
