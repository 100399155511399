import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { currencyFormatter } from 'app/shared/utils/currencyFormatter';
import { errorMsgForField } from 'app/shared/utils/form';
import { Checkbox } from 'app/shared/components/atoms/CheckboxManualCSS';
import {
  FormGroupContainer,
  FormSectionTitle,
} from 'app/shared/components/atoms/FormContent';
import GenericFormContainer from 'app/shared/components/atoms/GenericFormContainer';
import { Radio } from 'app/shared/components/atoms/Radio';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import FormGroup from 'app/shared/components/molecules/FormGroup';
import RadioGroup from 'app/shared/components/molecules/RadioGroup';
import {
  BigNumberTextfield,
  NumberTextfield,
} from 'app/admin/components/molecules/Textfield';

interface FormProps {
  formikProps: any;
  currencySymbol?: string;
}

const PricingNote = styled.h6`
  font-size: 12px;
  font-weight: 500;
  margin: -3px 0 35px 0;
`;

const CurrencyNote = styled.span`
  font-weight: 600;
`;

const PricingDefaultEventPrice = styled.span`
  font-size: 16px;
  font-weight: 600;
`;

const PricingOr = styled.div`
  font-size: 20px;
  font-weight: 600;
`;

const PricingTieredPrice = styled.span`
  font-size: 16px;
  font-weight: 600;
`;

const TieredPricesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TieredPriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 7px;
`;

const TieredPriceLabel = styled.div`
  font-weight: 600;
  padding-top: 3px;
  width: 50px;
`;

const TieredPriceField = styled.div`
  padding-left: 8px;
  width: 300px;
`;

const DisplayBookingFee = styled.span`
  font-weight: 600;
  font-size: 14px;
`;

const HelperText = styled.div`
  font-size: 14px;
  font-style: italic;
  margin-top: 10px;
  margin-bottom: 15px;
`;

const CitySettingsEditForm: React.FC<FormProps> = ({
  formikProps,
  currencySymbol,
}) => {
  const intl = useIntl();

  const { setFieldValue, touched, errors, values } = formikProps;
  const shortDaysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  return (
    <GenericFormContainer dataQaId="city-settings-section-edit-form">
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.citySettings.form.pricing',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <PricingNote>
          {intl.formatMessage({
            id: 'admin.citySettings.form.pricingNote',
          })}{' '}
          <CurrencyNote>
            {intl.formatMessage(
              {
                id: 'admin.citySettings.form.currencyNote',
              },
              {
                currencySymbol,
              }
            )}
          </CurrencyNote>
        </PricingNote>
        <Radio
          id="pricingTypeFixed"
          value="fixed"
          name="pricingTypeFixed"
          checked={values.pricingType == 'fixed'}
          onChange={() => {
            setFieldValue('pricingType', 'fixed');
            setFieldValue('priceSun', '');
            setFieldValue('priceMon', '');
            setFieldValue('priceTue', '');
            setFieldValue('priceWed', '');
            setFieldValue('priceThu', '');
            setFieldValue('priceFri', '');
            setFieldValue('priceSat', '');
          }}
        >
          <PricingDefaultEventPrice>
            {intl.formatMessage({
              id: 'admin.citySettings.form.defaultEventPrice',
            })}
          </PricingDefaultEventPrice>
        </Radio>
        <Spacer mb={4} />
        <FormGroup
          data-qaid="city-settings-form-default-event-price-formgroup"
          label=""
          errorMsg={errorMsgForField('defaultEventPrice', touched, errors)}
        >
          <BigNumberTextfield
            data-qaid="city-settings-form-default-event-price-field"
            id="defaultEventPrice"
            name="defaultEventPrice"
            value={values.defaultEventPrice}
            onChange={(e: any) => {
              setFieldValue('defaultEventPrice', e.target.value);
            }}
            disabled={values.pricingType == 'tiered'}
            maxLength={8}
          />
        </FormGroup>
        <Spacer mb={7} />
        <PricingOr>
          {intl.formatMessage({
            id: 'admin.citySettings.form.pricingOr',
          })}
        </PricingOr>
        <Spacer mb={8} />
        <Radio
          id="pricingTypeTiered"
          value="tiered"
          name="pricingTypeTiered"
          checked={values.pricingType == 'tiered'}
          onChange={() => {
            setFieldValue('pricingType', 'tiered');
            setFieldValue('defaultEventPrice', '');
          }}
        >
          <PricingTieredPrice>
            {intl.formatMessage({
              id: 'admin.citySettings.form.tieredPrice',
            })}
          </PricingTieredPrice>
        </Radio>
        <Spacer mb={3} />
        <TieredPricesContainer>
          {shortDaysOfWeek.map((day: string, index: number) => (
            <TieredPriceContainer key={index}>
              <TieredPriceLabel>{day}</TieredPriceLabel>
              <TieredPriceField>
                <FormGroup
                  data-qaid={`city-settings-form-price-${day}-formgroup`}
                  label=""
                  errorMsg={errorMsgForField(`price${day}`, touched, errors)}
                >
                  <NumberTextfield
                    data-qaid={`city-settings-form-price-${day}-field`}
                    id={`price${day}`}
                    name={`price${day}`}
                    value={values[`price${day}`]}
                    onChange={(e: any) => {
                      setFieldValue(`price${day}`, e.target.value);
                    }}
                    disabled={values.pricingType == 'fixed'}
                    maxLength={10}
                  />
                </FormGroup>
              </TieredPriceField>
            </TieredPriceContainer>
          ))}
        </TieredPricesContainer>
        <Spacer mb={10} />
        <FormGroup
          data-qaid="city-settings-form-booking-fee-formgroup"
          label={
            values.countryBookingFee || values.countryBookingFee == 0
              ? intl.formatMessage(
                  {
                    id: 'admin.citySettings.form.bookingFeeWithCountryValue',
                  },
                  {
                    countryBookingFee: currencyFormatter(
                      values.countryBookingFee || 0,
                      currencySymbol || '$'
                    ),
                  }
                )
              : intl.formatMessage({
                  id: 'admin.citySettings.form.bookingFee',
                })
          }
          errorMsg={errorMsgForField('bookingFee', touched, errors)}
        >
          <BigNumberTextfield
            data-qaid="city-settings-form-booking-fee"
            id="bookingFee"
            name="bookingFee"
            value={values.bookingFee}
            onChange={(e: any) => {
              setFieldValue('bookingFee', e.target.value);
            }}
            maxLength={10}
          />
        </FormGroup>
        <Spacer mb={8} />
        <DisplayBookingFee>
          {intl.formatMessage({
            id: 'admin.citySettings.heading.displayBookingFee',
          })}
        </DisplayBookingFee>
        <RadioGroup
          name="displayBookingFee"
          options={[
            {
              title: intl.formatMessage({
                id: 'admin.citySettings.form.displayBookingFee.null',
              }),
              value: null,
            },
            {
              title: intl.formatMessage({
                id: 'admin.citySettings.form.displayBookingFee.true',
              }),
              value: true,
            },
            {
              title: intl.formatMessage({
                id: 'admin.citySettings.form.displayBookingFee.false',
              }),
              value: false,
            },
          ]}
          selectedValue={values.displayBookingFee}
          onChange={value => setFieldValue('displayBookingFee', value)}
        />
        <Spacer mb={7} />
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.citySettings.form.artistCompensation',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <Checkbox
          id="city-settings-form-is-tiered-artist-compensation-enabled-field"
          data-qaid="city-settings-form-is-tiered-artist-compensation-enabled-field"
          checked={values.isTieredArtistCompensationEnabled}
          name="isTieredArtistCompensationEnabled"
          onChange={e =>
            setFieldValue('isTieredArtistCompensationEnabled', e.target.checked)
          }
        >
          {intl.formatMessage({
            id: 'admin.citySettings.form.useTieredArtistCompensation',
          })}
        </Checkbox>
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.citySettings.form.ticketingAndInvites',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="city-settings-form-max-tickets-per-attendee-formgroup"
          label={intl.formatMessage({
            id: 'admin.citySettings.form.maxTicketsPerAttendee',
          })}
          errorMsg={errorMsgForField('maxTicketsPerAttendee', touched, errors)}
          required
        >
          <NumberTextfield
            data-qaid="city-settings-form-max-tickets-per-attendee-field"
            id="maxTicketsPerAttendee"
            name="maxTicketsPerAttendee"
            value={values.maxTicketsPerAttendee}
            onChange={(e: any) => {
              setFieldValue('maxTicketsPerAttendee', e.target.value);
            }}
            maxLength={2}
          />
        </FormGroup>
        <Spacer mb={6} />
        <Checkbox
          id="city-settings-section-edit-form-is-auto-invite-enabled-field"
          data-qaid="city-settings-section-edit-form-is-auto-invite-enabled-field"
          checked={values.isAutoInviteEnabled}
          name="isAutoInviteEnabled"
          onChange={e => setFieldValue('isAutoInviteEnabled', e.target.checked)}
        >
          {intl.formatMessage({
            id: 'admin.citySettings.form.isAutoInviteEnabled',
          })}
        </Checkbox>
        <Spacer mb={6} />
        <FormGroup
          data-qaid="city-settings-form-invite-first-day-percentage-formgroup"
          label={intl.formatMessage({
            id: 'admin.citySettings.form.inviteFirstDayPercentage',
          })}
          errorMsg={errorMsgForField(
            'inviteFirstDayPercentage',
            touched,
            errors
          )}
        >
          <NumberTextfield
            data-qaid="city-settings-form-invite-first-day-percentage-field"
            id="inviteFirstDayPercentage"
            name="inviteFirstDayPercentage"
            value={values.inviteFirstDayPercentage}
            onChange={(e: any) => {
              setFieldValue('inviteFirstDayPercentage', e.target.value);
            }}
            maxLength={3}
          />
        </FormGroup>
        <Spacer mb={6} />
        <FormGroup
          data-qaid="city-settings-form-invite-subsequent-days-percentage-formgroup"
          label={intl.formatMessage({
            id: 'admin.citySettings.form.inviteSubsequentDaysPercentage',
          })}
          errorMsg={errorMsgForField(
            'inviteSubsequentDaysPercentage',
            touched,
            errors
          )}
        >
          <NumberTextfield
            data-qaid="city-settings-form-invite-subsequent-days-percentage-field"
            id="inviteSubsequentDaysPercentage"
            name="inviteSubsequentDaysPercentage"
            value={values.inviteSubsequentDaysPercentage}
            onChange={(e: any) => {
              setFieldValue('inviteSubsequentDaysPercentage', e.target.value);
            }}
            maxLength={3}
          />
        </FormGroup>
        <Spacer mb={6} />
        <Checkbox
          id="city-settings-section-edit-form-is-auto-stop-apply-enabled-field"
          data-qaid="city-settings-section-edit-form-is-auto-stop-apply-enabled-field"
          checked={values.isAutoStopApplyEnabled}
          name="isAutoStopApplyEnabled"
          onChange={e =>
            setFieldValue('isAutoStopApplyEnabled', e.target.checked)
          }
        >
          {intl.formatMessage({
            id: 'admin.citySettings.form.isAutoStopApplyEnabled',
          })}
        </Checkbox>
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.citySettings.form.promoCodes',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="city-settings-form-maximum-promo-code-uses-formgroup"
          label={intl.formatMessage({
            id: 'admin.citySettings.form.maximumPromoCodeUses',
          })}
          errorMsg={errorMsgForField('maximumPromoCodeUses', touched, errors)}
          required
        >
          <NumberTextfield
            data-qaid="city-settings-form-maximum-promo-code-uses-field"
            id="maximumPromoCodeUses"
            name="maximumPromoCodeUses"
            value={values.maximumPromoCodeUses}
            onChange={(e: any) => {
              setFieldValue('maximumPromoCodeUses', e.target.value);
            }}
            maxLength={4}
          />
        </FormGroup>
        <Spacer mb={6} />
        <FormGroup
          data-qaid="city-settings-form-maximum-percentage-promo-code-uses-formgroup"
          label={intl.formatMessage({
            id: 'admin.citySettings.form.maximumPercentagePromoCodeUses',
          })}
          errorMsg={errorMsgForField(
            'maximumPercentagePromoCodeUses',
            touched,
            errors
          )}
          required
        >
          <NumberTextfield
            data-qaid="city-settings-form-maximum-percentage-promo-code-uses-field"
            id="maximumPercentagePromoCodeUses"
            name="maximumPercentagePromoCodeUses"
            value={values.maximumPercentagePromoCodeUses}
            onChange={(e: any) => {
              setFieldValue('maximumPercentagePromoCodeUses', e.target.value);
            }}
            maxLength={4}
          />
        </FormGroup>
        <Spacer mb={6} />
        <FormGroup
          data-qaid="city-settings-form-maximum-guaranteed-promo-code-uses-formgroup"
          label={intl.formatMessage({
            id: 'admin.citySettings.form.maximumGuaranteedPromoCodeUses',
          })}
          errorMsg={errorMsgForField(
            'maximumGuaranteedPromoCodeUses',
            touched,
            errors
          )}
          required
        >
          <NumberTextfield
            data-qaid="city-settings-form-maximum-guaranteed-promo-code-uses-field"
            id="maximumGuaranteedPromoCodeUses"
            name="maximumGuaranteedPromoCodeUses"
            value={values.maximumGuaranteedPromoCodeUses}
            onChange={(e: any) => {
              setFieldValue('maximumGuaranteedPromoCodeUses', e.target.value);
            }}
            maxLength={4}
          />
        </FormGroup>
        <Spacer mb={6} />
        <FormGroup
          data-qaid="city-settings-form-maximum-custom-promo-code-uses-formgroup"
          label={intl.formatMessage({
            id: 'admin.citySettings.form.maximumCustomPromoCodeUses',
          })}
          errorMsg={errorMsgForField(
            'maximumCustomPromoCodeUses',
            touched,
            errors
          )}
          required
        >
          <NumberTextfield
            data-qaid="city-settings-form-maximum-custom-promo-code-uses-field"
            id="maximumCustomPromoCodeUses"
            name="maximumCustomPromoCodeUses"
            value={values.maximumCustomPromoCodeUses}
            onChange={(e: any) => {
              setFieldValue('maximumCustomPromoCodeUses', e.target.value);
            }}
            maxLength={4}
          />
        </FormGroup>
        <Spacer mb={6} />
        <Checkbox
          id="city-settings-section-edit-form-send-promo-codes-to-first-timers-field"
          data-qaid="city-settings-section-edit-form-send-promo-codes-to-first-timers-field"
          checked={values.sendPromoCodesToFirstTimers}
          name="sendPromoCodesToFirstTimers"
          onChange={e =>
            setFieldValue('sendPromoCodesToFirstTimers', e.target.checked)
          }
        >
          {intl.formatMessage({
            id: 'admin.citySettings.form.sendPromoCodesToFirstTimers',
          })}
        </Checkbox>
        <Spacer mb={6} />
        <Checkbox
          id="city-settings-section-edit-form-is-unlucky-email-promo-code-enabled-field"
          data-qaid="city-settings-section-edit-form-is-unlucky-email-promo-code-enabled-field"
          checked={values.isUnluckyEmailPromoCodeEnabled}
          name="isUnluckyEmailPromoCodeEnabled"
          onChange={e =>
            setFieldValue('isUnluckyEmailPromoCodeEnabled', e.target.checked)
          }
        >
          {intl.formatMessage({
            id: 'admin.citySettings.form.isUnluckyEmailPromoCodeEnabled',
          })}
        </Checkbox>
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.citySettings.form.otherSettings',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <Checkbox
          id="city-settings-section-edit-form-top-global-city-field"
          data-qaid="city-settings-section-edit-form-top-global-city-field"
          checked={values.topGlobalCity}
          name="topGlobalCity"
          onChange={e => setFieldValue('topGlobalCity', e.target.checked)}
        >
          {intl.formatMessage({
            id: 'admin.citySettings.form.topGlobalCity',
          })}
        </Checkbox>
        <Spacer mb={2} />
        <HelperText>
          {intl.formatMessage({
            id: 'admin.citySettings.form.topGlobalCityHelpText',
          })}
        </HelperText>
        <Spacer mb={7} />
        <Checkbox
          id="city-settings-section-edit-form-top-city-field"
          data-qaid="city-settings-section-edit-form-top-city-field"
          checked={values.topCity}
          name="topCity"
          onChange={e => setFieldValue('topCity', e.target.checked)}
        >
          {intl.formatMessage({
            id: 'admin.citySettings.form.topCity',
          })}
        </Checkbox>
        <Spacer mb={2} />
        <HelperText>
          {intl.formatMessage({
            id: 'admin.citySettings.form.topCityHelpText',
          })}
        </HelperText>
        <Spacer mb={7} />
        <Checkbox
          id="city-settings-section-edit-form-noindex-field"
          data-qaid="city-settings-section-edit-form-noindex-field"
          checked={values.noindex}
          name="noindex"
          onChange={e => setFieldValue('noindex', e.target.checked)}
        >
          {intl.formatMessage({
            id: 'admin.citySettings.form.noindex',
          })}
        </Checkbox>
      </FormGroupContainer>
    </GenericFormContainer>
  );
};

export default CitySettingsEditForm;
