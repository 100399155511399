import React from 'react';
import { useIntl } from 'react-intl';

import { get } from 'app/shared/utils/get';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { SendUrgentEmail } from 'app/admin/graphql/emails/mutationHooks';
import { DetailsFormMainContainer } from 'app/admin/components/atoms/DetailContent';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

import EventSendUrgentEmailForm from './EventSendUrgentEmailForm';
import EventSendUrgentEmailFormSchema from './EventSendUrgentEmailFormSchema';

interface Props {
  contentProps?: any;
  setFormSubmitAction: (func: Function) => void;
  setDisplayConfirmation: (set: boolean) => void;
  setIsSubmitting: (set: boolean) => void;
  navigateTo: (routeData: object) => void;
}

const EventSendUrgentEmail: React.FC<Props> = ({
  contentProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  navigateTo,
}) => {
  const intl = useIntl();

  const eventId = get(contentProps, 'event.id', undefined);
  const localStartsAt = get(contentProps, 'event.localStartsAt', '');
  const cityTitle = get(contentProps, 'event.city.title', '');
  const neighborhoodTitle = get(contentProps, 'event.neighborhood.title', '');

  const formInitialValues = {
    urgentEmailKey: undefined,
    recipientType: undefined,
    customEmailSubject: '',
    customEmailMessage: '',
    totalAttendeesConfirmedCount: get(
      contentProps,
      'event.totalAttendeesConfirmedCount',
      0
    ),
    totalAttendeesUnconfirmedCount: get(
      contentProps,
      'event.totalAttendeesUnconfirmedCount',
      0
    ),
    totalAttendeesCount: get(contentProps, 'event.totalAttendeesCount', 0),
    newVenueAddress: undefined,
  };

  const sendUrgentEmailAction = SendUrgentEmail();

  const handleSendUrgentEmail = useSubmitAction({
    submitAction: sendUrgentEmailAction,
    submitVariables: (values: any) => ({
      emailKey: values.urgentEmailKey,
      eventId,
      recipientType: values.recipientType,
      emailSubject: values.customEmailSubject,
      emailBody: values.customEmailMessage,
      newAddress: values.newVenueAddress,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.eventPlanner.emails.urgentEmailSuccessMsg',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.eventPlanner.emails.urgentEmailErrorMsg',
    }),
    onSuccess: (response: any) => {
      navigateTo({
        routeName: 'event-details',
        routeProps: {
          defaultOpenSection: 'emailsInfo',
          urgentEmailKeySent: response.data.sendUrgentEmail.emailKey,
          ...contentProps.event,
        },
      });
    },
  });

  return (
    <ModalContentContainer>
      <DetailsFormMainContainer>
        <DetailsHeader
          title={cityTitle}
          subTitle={neighborhoodTitle}
          loading={!(!!cityTitle || !!localStartsAt)}
          imageStyle="calendar"
          detailsDate={localStartsAt}
        />
        <GenericForm
          formInitialValues={formInitialValues}
          renderFormComponent={(renderProps: any) => (
            <EventSendUrgentEmailForm formikProps={renderProps.formikProps} />
          )}
          onSubmit={handleSendUrgentEmail}
          formSchema={EventSendUrgentEmailFormSchema(intl)}
          setFormSubmitAction={setFormSubmitAction}
          setDisplayConfirmation={setDisplayConfirmation}
          setIsSubmitting={setIsSubmitting}
          dataQaId="event-send-urgent-email-form"
        />
      </DetailsFormMainContainer>
    </ModalContentContainer>
  );
};

export default EventSendUrgentEmail;
