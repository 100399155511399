import React, { useEffect, useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { EventPlannerEvent } from 'app/typings/events';
import {
  Offer,
  PerformanceOffer,
  PerformanceOfferStatus,
} from 'app/typings/offers';
import { Performance } from 'app/typings/performances';
import { ManualCSSColors } from 'app/shared/theme';
import { get } from 'app/shared/utils/get';
import useModal from 'app/shared/utils/useModal';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { ToolTip, ToolTipContainer } from 'app/shared/components/atoms/ToolTip';
import ConfirmationModal from 'app/shared/components/molecules/ConfirmationModal';
import InfoBox from 'app/shared/components/molecules/Flyover';
import {
  dedupePerformanceOffers,
  filterActivePerformanceOffers,
} from 'app/admin/utils/offers';
import { CreateCancellation } from 'app/admin/graphql/cancellations/mutationHooks';
import IconButton from 'app/admin/components/molecules/IconButton';
import IconLabel from 'app/admin/components/molecules/IconLabel';
import CancellationReasonForm from 'app/admin/components/organisms/CancellationReasonForm';
import EventPlannerArtistFlyover from 'app/admin/components/organisms/EventPlannerArtistFlyover';
import { ReactComponent as Ellipsis } from 'icons/streamline-regular/interface-essential/menu/navigation-menu-horizontal-3.svg';
import { ReactComponent as MoodQuestion } from 'icons/streamline-regular/social-medias-rewards-rating/mood/mood-question.svg';
import { ReactComponent as Star } from 'icons/streamline-regular/social-medias-rewards-rating/rating/rating-star.svg';

interface ArtistProps {
  performance: any;
  performances: any;
  performanceOffers: PerformanceOffer[];
  performanceOffersForEvent: PerformanceOffer[];
  position: number;
  event: EventPlannerEvent;
  canEditEvent?: boolean;
  onChange?: Function;
  displayAdditionalArtist?: boolean;
  enableAdditionalArtist?: boolean;
  onCreateOffer?: (position: number) => void;
  updatePerformanceOffers?: Function;
}

const IconLabelNoPointer = styled(IconLabel)`
  cursor: default;
`;

const eventShadedColors = {
  mainBackground: '#E8E8E8',
  status: 'blueSmoke',
  artistVenueConfirmed: 'macyGrey',
  guestsProgressBarFilled: 'lightBlueSmoke',
  guestsProgressBarUnfilled: 'whiteDenim',
};

const Artist: React.FC<ArtistProps> = ({
  performance,
  performances,
  performanceOffers,
  performanceOffersForEvent,
  position,
  event,
  canEditEvent,
  onChange,
  displayAdditionalArtist,
  enableAdditionalArtist,
  onCreateOffer,
}) => {
  const intl = useIntl();

  const [flyoverClosed, setFlyoverClosed] = useState(true);
  const [artistState, setArtistState] = useState(performance);
  const [offersState, setOffersState] = useState(performanceOffers || []);
  const [offerState, setOfferState] = useState(
    (performanceOffers && performanceOffers[0]?.offer) || undefined
  );
  const [artistFlyoverState, setArtistFlyoverState] = useState<
    undefined | string
  >(undefined);
  const [artistId, setArtistId] = useState(performance?.artist?.id);
  const [artistIsConfirmed, setArtistIsConfirmed] = useState(
    performance?.artistConfirmed
  );

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [
    cancellationReasonValidationError,
    setCancellationReasonValidationError,
  ] = useState<string | undefined>(undefined);
  const [
    displayCancellationReasonForm,
    setDisplayCancellationReasonForm,
  ] = useState<boolean>(false);
  const [cancellationReasonData, setCancellationReasonData] = useState<
    | {
        cancellationReasonId?: number;
        cancellationReasonDescription?: string;
        reasonDescription?: string;
      }
    | undefined
  >(undefined);
  const [
    cancellationReasonConfirmationModal,
    toggleCancellationReasonConfirmationModal,
  ] = useModal();

  useEffect(() => {
    if (performance !== artistState) {
      setArtistId(performance?.artist?.id);
      setArtistState(performance);
      setArtistIsConfirmed(performance && performance.artistConfirmed);
    }
  }, [performance]);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    // @ts-ignore
  } = useSortable({ id: position });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const [activeOffers, setActiveOffers] = useState(
    filterActivePerformanceOffers(offersState)
  );

  const [acceptedOffers, setAcceptedOffers] = useState(
    (offersState &&
      offersState.filter(
        (offer: PerformanceOffer) =>
          offer.status === PerformanceOfferStatus.ACCEPTED
      )) ||
      undefined
  );

  const updatePerformanceAndOffersState = (createdObject: {
    performance?: Performance;
    offer?: Offer;
  }) => {
    if (
      createdObject?.offer &&
      createdObject?.offer?.status !== PerformanceOfferStatus.CLOSED
    ) {
      setOffersState(
        dedupePerformanceOffers([
          ...offersState,
          ...(createdObject?.offer?.performanceOffers || []),
        ])
      );
      setOfferState(createdObject.offer || undefined);
    } else if (createdObject?.offer?.status === PerformanceOfferStatus.CLOSED) {
      setOffersState([]);
      setOfferState(undefined);
    }
    if (createdObject && createdObject.performance) {
      setArtistState(createdObject.performance);
    }
    onChange && onChange(createdObject);
  };

  const handleCreateCancellation = useSubmitAction({
    submitAction: CreateCancellation(),
    submitVariables: () => ({
      cancellableId: artistId,
      cancellableType: 'Artist',
      cancellationReasonId: cancellationReasonData?.cancellationReasonId,
      eventId: event.id,
      reasonDescription: cancellationReasonData?.reasonDescription,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.eventPlanner.artists.createCancellation.success',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.eventPlanner.artists.createCancellation.failure',
    }),
    onSuccess: () => {
      setIsSubmitting(false);
      if (cancellationReasonConfirmationModal.isShowing) {
        toggleCancellationReasonConfirmationModal();
      }
    },
  });

  const renderCancellationReasonForm = () => {
    return (
      <CancellationReasonForm
        cancellableType="Artist"
        otherReasonDescriptionPlaceholderText={intl.formatMessage({
          id: 'admin.eventPlanner.artists.whyWasTheArtistRemoved',
        })}
        validationError={cancellationReasonValidationError}
        onChange={({
          cancellationReasonId,
          cancellationReasonDescription,
          otherReasonDescription,
        }) => {
          setCancellationReasonValidationError(undefined);
          setCancellationReasonData({
            cancellationReasonId,
            cancellationReasonDescription,
            reasonDescription: otherReasonDescription,
          });
        }}
      />
    );
  };

  const renderCancellationReasonConfirmationModal = () => (
    <ConfirmationModal
      onCancel={() => {
        setDisplayCancellationReasonForm(false);
        cancellationReasonConfirmationModal.hide();
      }}
      description={intl.formatMessage({
        id: 'admin.eventPlanner.artists.pleaseSelectReasonForRemovingArtist',
      })}
      onConfirm={() => {
        if (
          displayCancellationReasonForm &&
          !cancellationReasonData?.cancellationReasonId
        ) {
          setCancellationReasonValidationError('missing_cancellation_reason');
        } else if (
          displayCancellationReasonForm &&
          cancellationReasonData?.cancellationReasonDescription === 'Other' &&
          !cancellationReasonData?.reasonDescription
        ) {
          setCancellationReasonValidationError(
            'missing_other_reason_description'
          );
        } else {
          setDisplayCancellationReasonForm(false);
          setIsSubmitting(true);
          handleCreateCancellation();
        }
        setActiveOffers([]);
        setAcceptedOffers([]);
      }}
      isLoading={isSubmitting}
      customContent={
        displayCancellationReasonForm ? renderCancellationReasonForm() : null
      }
    />
  );

  return (
    <>
      {canEditEvent ? (
        artistState ? (
          <div
            ref={flyoverClosed ? setNodeRef : undefined}
            style={flyoverClosed ? style : undefined}
            key={position}
            {...(flyoverClosed ? attributes : {})}
            {...(flyoverClosed ? listeners : {})}
          >
            <ToolTipContainer>
              <InfoBox
                showCaret={false}
                width="300px"
                onStateChange={(isOpen: boolean) => {
                  if (!isOpen) {
                    setFlyoverClosed(true);
                    setArtistFlyoverState(undefined);
                  } else {
                    setFlyoverClosed(false);
                  }
                }}
                triggerElement={
                  displayAdditionalArtist ? (
                    <IconButton
                      icon={Ellipsis}
                      disabled={artistState ? false : !enableAdditionalArtist}
                    />
                  ) : (
                    <div data-qaid={`artist-icon-label-${position}`}>
                      <IconLabel
                        color={
                          artistIsConfirmed || performance?.isArtistSelfBooked
                            ? 'purplePills'
                            : undefined
                        }
                        icon={
                          artistIsConfirmed && !performance?.isArtistSelfBooked
                            ? 'check'
                            : artistIsConfirmed &&
                              performance?.isArtistSelfBooked
                            ? Star
                            : ''
                        }
                        iconColor="whiteDenim"
                        draft={artistIsConfirmed ? undefined : true}
                        invertIcon
                        labelText={
                          get(artistState, 'artist.title', []) || undefined
                        }
                      />
                    </div>
                  )
                }
                shouldFlyoverClose={flyoverClosed}
                shouldFlyoverOpen={!flyoverClosed}
                innerContent={
                  <EventPlannerArtistFlyover
                    performance={artistState}
                    performances={performances}
                    performanceOffersForEvent={performanceOffersForEvent}
                    isConfirmed={artistIsConfirmed}
                    initialState={artistFlyoverState}
                    event={event}
                    position={position}
                    hasArtist
                    handleFlyoverClose={() => {
                      setFlyoverClosed(true);
                    }}
                    onSuccess={(
                      updatedObject: {
                        performance: Performance;
                        offer?: Offer;
                      },
                      isConfirmed: boolean,
                      flyoverState: string,
                      flyoverOpen: boolean
                    ) => {
                      setFlyoverClosed(!flyoverOpen);
                      if (
                        updatedObject.performance &&
                        updatedObject.performance != artistState
                      ) {
                        setArtistId(updatedObject.performance.artist.id);
                      }
                      setArtistState(updatedObject.performance);
                      setArtistIsConfirmed(isConfirmed);
                      setArtistFlyoverState(flyoverState);

                      if (
                        updatedObject?.offer?.status ===
                        PerformanceOfferStatus.CLOSED
                      ) {
                        setOffersState([]);
                        setOfferState(undefined);
                      } else {
                        setActiveOffers(
                          filterActivePerformanceOffers(
                            updatedObject?.offer?.performanceOffers || []
                          )
                        );
                        setAcceptedOffers(
                          (updatedObject?.offer?.performanceOffers &&
                            updatedObject?.offer?.performanceOffers.filter(
                              (offer: PerformanceOffer) =>
                                offer.status === PerformanceOfferStatus.ACCEPTED
                            )) ||
                            []
                        );
                      }
                      onChange && onChange(updatedObject);
                      if (!updatedObject.performance && artistIsConfirmed) {
                        setDisplayCancellationReasonForm(true);
                        toggleCancellationReasonConfirmationModal();
                      }
                    }}
                    offer={offerState}
                  />
                }
                data-qaid="artist-update-info-box"
              />
              {!enableAdditionalArtist && (
                <ToolTip>{get(artistState, 'artist.title', undefined)}</ToolTip>
              )}
            </ToolTipContainer>
            <Spacer mt={2} />
          </div>
        ) : (
          <div
            ref={flyoverClosed ? setNodeRef : undefined}
            style={flyoverClosed ? style : undefined}
            key={position}
            {...(flyoverClosed ? attributes : {})}
            {...(flyoverClosed ? listeners : {})}
          >
            <InfoBox
              showCaret={false}
              width="300px"
              onStateChange={(isOpen: boolean) => {
                if (!isOpen) {
                  setFlyoverClosed(true);
                  setArtistFlyoverState(undefined);
                } else {
                  setFlyoverClosed(false);
                }
              }}
              triggerElement={
                displayAdditionalArtist ? (
                  <IconButton
                    icon={Ellipsis}
                    disabled={!enableAdditionalArtist}
                  />
                ) : (
                  <div data-qaid={`artist-icon-label-${position}`}>
                    <IconLabel
                      icon={
                        activeOffers && activeOffers.length > 0
                          ? MoodQuestion
                          : undefined
                      }
                      color={
                        acceptedOffers && acceptedOffers.length > 0
                          ? 'greenOnions'
                          : undefined
                      }
                      iconColor={
                        acceptedOffers && acceptedOffers.length > 0
                          ? 'greenOnions'
                          : 'blackBetty'
                      }
                      labelText={
                        acceptedOffers && acceptedOffers.length > 1
                          ? intl.formatMessage({
                              id: 'admin.eventPlanner.shared.multiple',
                            })
                          : acceptedOffers && acceptedOffers.length > 0
                          ? acceptedOffers[0] &&
                            acceptedOffers[0].artist &&
                            acceptedOffers[0].artist.title
                          : activeOffers && activeOffers.length > 0
                          ? intl.formatMessage({
                              id: 'admin.eventPlanner.shared.invited',
                            })
                          : undefined
                      }
                      labelTextColor="blueSmoke"
                      showNotification={acceptedOffers.length > 0}
                    />
                  </div>
                )
              }
              shouldFlyoverClose={flyoverClosed}
              shouldFlyoverOpen={!flyoverClosed}
              innerContent={
                <EventPlannerArtistFlyover
                  event={event}
                  initialState={artistFlyoverState}
                  isConfirmed={artistIsConfirmed}
                  onSuccess={(
                    createdObject: { performance?: Performance; offer?: Offer },
                    isConfirmed: boolean,
                    flyoverState: string,
                    flyoverOpen: boolean
                  ) => {
                    setFlyoverClosed(!flyoverOpen);
                    if (createdObject && createdObject.performance) {
                      setArtistId(createdObject.performance.artist.id);
                    }
                    setArtistIsConfirmed(isConfirmed);
                    setArtistFlyoverState(flyoverState);
                    updatePerformanceAndOffersState(createdObject);
                  }}
                  onCreateOffer={
                    onCreateOffer
                      ? () => {
                          setFlyoverClosed(true);
                          onCreateOffer(position);
                        }
                      : undefined
                  }
                  performance={artistState}
                  performances={performances}
                  performanceOffers={offersState}
                  performanceOffersForEvent={performanceOffersForEvent}
                  offer={offerState}
                  position={position}
                />
              }
            />
            <Spacer mt={2} />
          </div>
        )
      ) : artistState ? (
        <React.Fragment key={position}>
          <ToolTipContainer>
            <div data-qaid={`artist-icon-label-uneditable-${position}`}>
              <IconLabelNoPointer
                color={
                  (artistIsConfirmed
                    ? eventShadedColors.artistVenueConfirmed
                    : undefined) as ManualCSSColors
                }
                labelTextColor={
                  (artistIsConfirmed
                    ? eventShadedColors.artistVenueConfirmed
                    : undefined) as ManualCSSColors
                }
                icon={artistIsConfirmed ? 'check' : ''}
                draft={artistIsConfirmed ? undefined : true}
                invertIcon
                labelText={get(artistState, 'artist.title', []) || undefined}
              />
            </div>
            <ToolTip>{get(artistState, 'artist.title', undefined)}</ToolTip>
          </ToolTipContainer>
          <Spacer mt={2} />
        </React.Fragment>
      ) : (
        <React.Fragment key={position}>
          <div data-qaid={`artist-icon-label-uneditable-${position}`}>
            {position > 3 ? (
              <IconButton icon={Ellipsis} disabled={true} />
            ) : (
              <>
                <IconLabelNoPointer />
                <Spacer mt={2} />
              </>
            )}
          </div>
        </React.Fragment>
      )}
      {cancellationReasonConfirmationModal.isShowing &&
        renderCancellationReasonConfirmationModal()}
    </>
  );
};

export default Artist;
