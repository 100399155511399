import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { CuratorGroup } from 'app/typings/curatorGroups';
import { get } from 'app/shared/utils/get';
import useModal from 'app/shared/utils/useModal';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { UpdateCuratorGroup } from 'app/admin/graphql/curatorGroups/mutationHooks';
import { DetailsFormMainContainer } from 'app/admin/components/atoms/DetailContent';
import CuratorGroupUpdateConfirmationModal from 'app/admin/components/organisms/CuratorGroupDetails/CuratorGroupUpdateConfirmationModal';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

import CuratorGroupEmailsEditForm from './CuratorGroupEmailsEditForm';
import CuratorGroupEmailsEditFormSchema from './CuratorGroupEmailsEditFormSchema';

interface Props {
  contentProps?: any;
  setFormSubmitAction: (func: Function) => void;
  setDisplayConfirmation: (set: boolean) => void;
  setIsSubmitting: (set: boolean) => void;
  navigateTo: (routeData: object) => void;
}

const CuratorGroupEmailsEdit: React.FC<Props> = ({
  contentProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  navigateTo,
}) => {
  const intl = useIntl();

  const formInitialValues = {
    fromEmail: get(contentProps, 'fromEmail', '') || '',
    useFromEmailForAllEmails: get(
      contentProps,
      'useFromEmailForAllEmails',
      false
    ),
    crewOpsId: get(contentProps, 'mainCrewOps.id', '') || null,
    venueOpsId: get(contentProps, 'mainVenueOps.id', '') || null,
    artistOpsId: get(contentProps, 'mainArtistOps.id', '') || null,
  };

  const updateCuratorGroupAction = UpdateCuratorGroup();

  const [formValues, setFormValues] = useState(formInitialValues);

  const [
    curatorGroupConfirmationModal,
    toggleCuratorGroupConfirmationModal,
  ] = useModal();

  const navigateToCuratorGroupDetailsEmails = (curatorGroup: CuratorGroup) => {
    navigateTo({
      routeName: 'curator-group-details',
      routeProps: {
        defaultOpenSection: 'emails',
        ...curatorGroup,
        shouldRefetchCuratorGroup: true,
      },
    });
  };

  const handleUpdateCuratorGroup = useSubmitAction({
    submitAction: updateCuratorGroupAction,
    submitVariables: (values: any) => ({
      curatorGroupId: get(contentProps, 'id', undefined),
      fromEmail: values.fromEmail,
      crewOpsId: values.crewOpsId,
      venueOpsId: values.venueOpsId,
      artistOpsId: values.artistOpsId,
      useFromEmailForAllEmails: values.useFromEmailForAllEmails,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.curatorGroupUpdate.successMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.curatorGroupUpdate.failureMessage',
    }),
    onSuccess: response => {
      navigateToCuratorGroupDetailsEmails(
        response.data.updateCuratorGroup.curatorGroup
      );
    },
  });

  const handleConfirmCuratorGroupUpdate = (values: any) => {
    setFormValues(values);
    toggleCuratorGroupConfirmationModal();
  };

  const handleCancelCuratorGroupUpdate = () => {
    curatorGroupConfirmationModal.hide();
    navigateToCuratorGroupDetailsEmails(contentProps);
  };

  return (
    <>
      <ModalContentContainer>
        <DetailsFormMainContainer>
          <DetailsHeader title={get(contentProps, 'name', '')} />
          <GenericForm
            formInitialValues={formInitialValues}
            renderFormComponent={(renderProps: any) => (
              <CuratorGroupEmailsEditForm
                formikProps={renderProps.formikProps}
                curatorGroupId={get(contentProps, 'id', undefined)}
              />
            )}
            onSubmit={handleConfirmCuratorGroupUpdate}
            formSchema={CuratorGroupEmailsEditFormSchema(intl)}
            setFormSubmitAction={setFormSubmitAction}
            setDisplayConfirmation={setDisplayConfirmation}
            setIsSubmitting={setIsSubmitting}
            dataQaId="city-emails-section-edit-form"
          />
        </DetailsFormMainContainer>
      </ModalContentContainer>
      {curatorGroupConfirmationModal.isShowing && (
        <CuratorGroupUpdateConfirmationModal
          cancelCuratorGroupUpdate={handleCancelCuratorGroupUpdate}
          updateCuratorGroup={() => handleUpdateCuratorGroup(formValues)}
        />
      )}
    </>
  );
};

export default CuratorGroupEmailsEdit;
