import gql from 'graphql-tag';

export const UPDATE_TICKETS = gql`
  mutation updateTickets($tickets: [TicketInput!]!) {
    updateTickets(tickets: $tickets) {
      tickets {
        id
        firstName
        lastName
        email
        primary
      }
    }
  }
`;
