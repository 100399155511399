import * as Yup from 'yup';

const competitionBasicInfoFormSchema = (intl: any) =>
  Yup.object().shape({
    title: Yup.string().required(
      intl.formatMessage({
        id: 'form.requiredField',
      })
    ),
    closesAtDate: Yup.string()
      .nullable()
      .required(
        intl.formatMessage({
          id: 'form.requiredField',
        })
      ),
    closesAtTime: Yup.string()
      .nullable()
      .required(
        intl.formatMessage({
          id: 'form.requiredField',
        })
      ),
    uid: Yup.string()
      .required(
        intl.formatMessage({
          id: 'form.requiredField',
        })
      )
      .matches(/^[A-Za-z0-9-]+$/, {
        message: intl.formatMessage({
          id: 'admin.competitionCreate.form.uidError',
        }),
      }),
    city: Yup.string()
      .nullable()
      .required(
        intl.formatMessage({
          id: 'form.requiredField',
        })
      ),
    event: Yup.string().when('isNewCompetition', {
      is: true,
      then: Yup.string()
        .nullable()
        .required(
          intl.formatMessage({
            id: 'form.requiredField',
          })
        ),
    }),
    maxWinners: Yup.string()
      .required(
        intl.formatMessage({
          id: 'form.requiredField',
        })
      )
      .matches(/^\d+$/, {
        message: intl.formatMessage({
          id: 'admin.competitionCreate.form.maxWinnersError',
        }),
      })
      .test(
        'isValidNumber',
        intl.formatMessage({
          id: 'admin.competitionCreate.form.maxWinnersRangeError',
        }),
        function(value) {
          return value ? Number(value) >= 1 && Number(value) <= 1500 : true;
        }
      ),
  });

export default competitionBasicInfoFormSchema;
