import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { Artist } from 'app/typings/artists';
import { User } from 'app/typings/users';
import { buildFullName } from 'app/shared/utils/string';
import usePermission from 'app/shared/utils/usePermission';
import { PrimaryButton } from 'app/shared/components/atoms/Button';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import { NoIcon, YesIcon } from 'app/shared/components/atoms/IconLibrary';
import { BaseLink } from 'app/shared/components/atoms/LinkManualCSS';
import TruncatedByCharText from 'app/shared/components/atoms/TruncatedByCharText';
import { mobileNumberFormatter } from 'app/admin/utils/mobile';
import {
  DetailHeading as Heading,
  DetailValueContainer as ValueContainer,
} from 'app/admin/components/atoms/DetailContent';
import ExpandableText from 'app/admin/components/atoms/ExpandableText';
import { None } from 'app/admin/components/atoms/None';
import { ArtistLink } from 'app/admin/components/molecules/EntityLink';
import { birthYearPreferNotToSayOption } from 'app/admin/components/organisms/UserBasicInfoEdit/options';

interface Props {
  sectionData: User;
  callbacks: { [name: string]: Function };
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const Link = styled(BaseLink)`
  ${({ theme }) => css`
    cursor: pointer;
    color: ${theme.colors.green600};
  `}
`;

const BirthYear = styled.span`
  padding-left: 5px;
`;

const BooleanSetting = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 5px;
`;

const BooleanSettingText = styled.div`
  padding-left: 8px;
`;

const SimpleKeyValue = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding-bottom: 5px;
`;

const SimpleKey = styled.div`
  width: 80px;
  white-space: nowrap;
`;

const SimpleValue = styled.div`
  padding-left: 5px;
`;

const UserRoleAddAction = styled.div`
  ${({ theme }) => css`
    text-align: left;
    padding-top: ${theme.ruler[3]}px;
  `}
`;

const getAge = (birthYear: string) => {
  const currentYear = new Date().getFullYear();
  return (currentYear - Number(birthYear)).toString();
};

const renderAssociatedArtists = (sectionData: User) => {
  if (
    sectionData.associatedArtists &&
    sectionData.associatedArtists.length > 0
  ) {
    return sectionData.associatedArtists.map((artist: Artist, i: number) => (
      <ArtistLink artist={artist} truncateLength={35} key={i} />
    ));
  } else {
    return <None />;
  }
};

const renderBooleanSettings = (sectionData: User, settings: object) => {
  return Object.keys(settings).map((settingKey: string, i: number) => (
    <BooleanSetting key={i}>
      {sectionData[settingKey] ? <YesIcon /> : <NoIcon />}
      <BooleanSettingText>{settings[settingKey]}</BooleanSettingText>
    </BooleanSetting>
  ));
};

const renderKeyValue = (key: string, value: string | undefined) => {
  return (
    <SimpleKeyValue>
      <SimpleKey>{key}</SimpleKey>
      <SimpleValue>{value || <None />}</SimpleValue>
    </SimpleKeyValue>
  );
};

const renderTruncatedByCharTextValue = (value: string | undefined) => {
  return value ? (
    <TruncatedByCharText text={value} truncateLength={40} />
  ) : (
    <None />
  );
};

const emailSettings = {
  isUnluckyEmailEnabled: 'Guest Unlucky Email',
};

const GeneralNotesTruncateLength = 80;

const SectionBasicInfo: React.FC<Props> = ({ sectionData, callbacks }) => {
  const intl = useIntl();

  const hasEditUserRolesPermission = usePermission('user.roles.edit');

  const userName = buildFullName(sectionData.firstName, sectionData.lastName);

  return (
    <MainContainer data-qaid="section-about">
      <Grid cols={4}>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={1}>
          <Heading>
            {intl.formatMessage({
              id: 'shared.name',
            })}
          </Heading>
          <ValueContainer>
            {renderTruncatedByCharTextValue(userName)}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'shared.homeCity',
            })}
          </Heading>
          <ValueContainer>
            {sectionData.homeCity ? (
              <>
                {sectionData.homeCity.title},{' '}
                {sectionData.homeCity.country.title}
                {sectionData.cityOther && <div>({sectionData.cityOther})</div>}
              </>
            ) : (
              <>
                {sectionData.cityOther ? (
                  <>{sectionData.cityOther}</>
                ) : (
                  <None />
                )}
              </>
            )}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'shared.email',
            })}
          </Heading>
          <ValueContainer>
            <Link href={`mailto:${sectionData.email}`} openInNewTab={true}>
              {renderTruncatedByCharTextValue(sectionData.email)}
            </Link>
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.userBasicInfo.heading.mobile',
            })}
          </Heading>
          <ValueContainer>
            {sectionData.mobile && sectionData.mobile.numberBody ? (
              <Link
                href={`tel:${mobileNumberFormatter(sectionData.mobile)}`}
                openInNewTab={true}
              >
                {mobileNumberFormatter(sectionData.mobile, true)}
              </Link>
            ) : (
              <None />
            )}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.userBasicInfo.heading.latitudeLongitude',
            })}
          </Heading>
          <ValueContainer>
            {renderKeyValue('Latitude', sectionData.latitude)}
            {renderKeyValue('Longitude', sectionData.longitude)}
          </ValueContainer>
        </Col>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={2}>
          <Heading>
            {intl.formatMessage({
              id: 'admin.userBasicInfo.heading.gender',
            })}
          </Heading>
          <ValueContainer>{sectionData.gender || <None />}</ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.userBasicInfo.heading.age',
            })}
          </Heading>
          <ValueContainer>
            {sectionData.birthYear ? (
              <>
                {sectionData.birthYear === birthYearPreferNotToSayOption ? (
                  <>{sectionData.birthYear}</>
                ) : (
                  <>
                    {getAge(sectionData.birthYear)}{' '}
                    <BirthYear>(b. {sectionData.birthYear})</BirthYear>
                  </>
                )}
              </>
            ) : (
              <None />
            )}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.userBasicInfo.heading.ticketsPurchased',
            })}
          </Heading>
          <ValueContainer>
            {sectionData.numTicketsPurchased ||
            sectionData.numTicketsPurchased == 0 ? (
              sectionData.numTicketsPurchased
            ) : (
              <None />
            )}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.userBasicInfo.heading.accountStatus',
            })}
          </Heading>
          <ValueContainer>
            {sectionData?.isGuest
              ? intl.formatMessage({
                  id: 'admin.userBasicInfo.value.guestAccount',
                })
              : intl.formatMessage({
                  id: 'admin.userBasicInfo.value.fullAccount',
                })}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.userBasicInfo.heading.associatedArtists',
            })}
          </Heading>
          <ValueContainer>
            {renderAssociatedArtists(sectionData)}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.userBasicInfo.heading.internalUser',
            })}
          </Heading>
          <ValueContainer>
            {sectionData.isInternal ? (
              <Link
                href={`/admin/internal-users?user_id=${sectionData.id}`}
                openInNewTab={true}
              >
                {intl.formatMessage({
                  id: 'yes',
                })}
              </Link>
            ) : (
              <>
                {intl.formatMessage({
                  id: 'no',
                })}
                {hasEditUserRolesPermission && (
                  <UserRoleAddAction>
                    <PrimaryButton
                      onClick={() => callbacks.toggleAddRoleModal()}
                      small={true}
                    >
                      {intl.formatMessage({
                        id: 'admin.userBasicInfo.makeInternalUser',
                      })}
                    </PrimaryButton>
                  </UserRoleAddAction>
                )}
              </>
            )}
          </ValueContainer>
        </Col>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={3}>
          <Heading>
            {intl.formatMessage({
              id: 'admin.userBasicInfo.heading.emailSettings',
            })}
          </Heading>
          <ValueContainer>
            {renderBooleanSettings(sectionData, emailSettings)}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.userBasicInfo.heading.attendanceNotes',
            })}
          </Heading>
          <ExpandableText
            text={sectionData.generalNotes}
            truncateLength={GeneralNotesTruncateLength}
          />
        </Col>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={4}>
          <Heading>
            {intl.formatMessage({
              id: 'admin.userBasicInfo.heading.howHeardAbout',
            })}
          </Heading>
          <ValueContainer>
            {sectionData.howHeardAbout || <None />}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.userBasicInfo.heading.signupUtmMedium',
            })}
          </Heading>
          <ValueContainer>
            {sectionData.signUpLastTouchMedium || <None />}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.userBasicInfo.heading.signupUtmSource',
            })}
          </Heading>
          <ValueContainer>
            {sectionData.signUpLastTouchSource || <None />}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.userBasicInfo.heading.signupUtmCampaign',
            })}
          </Heading>
          <ValueContainer>
            {renderTruncatedByCharTextValue(
              sectionData.signUpLastTouchCampaign
            )}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.userBasicInfo.heading.signupReferrer',
            })}
          </Heading>
          <ValueContainer>
            {renderTruncatedByCharTextValue(
              sectionData.signUpLastTouchReferrer
            )}
          </ValueContainer>
        </Col>
      </Grid>
    </MainContainer>
  );
};

export default SectionBasicInfo;
