import React, { useContext, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';

import { useIsTShirtSizeBreakpointSize as useIsBreakpointSize } from 'app/shared/utils/useBreakpoint';
import FlyoverProvider, { FlyoverContext } from 'app/shared/context/Flyover';
import { CloseIcon } from 'app/shared/components/atoms/IconLibrary';
import { Popper } from 'app/shared/components/atoms/PopperManualCSS';
import { Portal } from 'app/shared/components/atoms/Portal';

interface Props {
  triggerElement: JSX.Element;
  innerElement: JSX.Element;
  keepInViewPort?: VerticalHorizontalBooleanProps;
  // TODO: Prevent passing zIndex around
  zIndex?: number;
  containerRef?: React.RefObject<HTMLDivElement>;
  showCaret?: boolean;
}

interface VerticalHorizontalBooleanProps {
  vertical?: boolean;
  horizontal?: boolean;
}

const Trigger = styled.div`
  border: none;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  background-color: transparent;
`;

interface FlyoverContentProps {
  width?: string;
  maxHeight?: string;
  showCaret?: boolean;
}

const FlyoverContentContainer = styled.div<FlyoverContentProps>`
  ${({ theme, width, maxHeight, showCaret }) => css`
    display: flex;
    justify-content: space-between;
    margin: 0;

    background-color: ${theme.colors.whiteDenim};
    
    ${theme.media.xs`
      position: fixed;
      margin: 1.75rem 1rem;
      left: 0;
      top: 0;
      margin: 0;
      width: 100vw;
      height: 100vh;
      padding: 15px;
    `};

    ${theme.media.sm`
      position: static;
      border-radius: ${theme.borderRadius.large};
      box-shadow: 5px 5px 28px 0 rgba(0, 0, 0, 0.25);
      width: ${width || 'auto'};
      overflow: auto;
      height: auto;
      max-height: ${maxHeight};
      padding: 15px;
    `};

    &:after {
      content: ' ';
      position: absolute;
      bottom: 90%;
      left: 50%;
      margin-left: -25px
      border-style: solid;
      visibility: ${showCaret ? 'visible' : 'hidden'};
      border-left: 25px solid transparent;
      border-right: 25px solid transparent;
      border-bottom: 25px solid ${theme.colors.whiteDenim};
      border-top: 25px solid transparent;
    }
  `}
`;

const CloseButton = styled.div`
  position: absolute;
  cursor: pointer;
  width: 25px;
  right: 12px;
  top: 12px;
`;

const SimpleFlyover: React.FC<Props> = ({
  triggerElement,
  innerElement,
  keepInViewPort = {
    vertical: true,
    horizontal: true,
  },
  zIndex,
  containerRef,
  showCaret = false,
}) => {
  const { isOpen, openFlyover, closeFlyover } = useContext(FlyoverContext);

  const anchorRef = useRef<any>();
  const flyoverContainerRef = useRef<any>();

  const { isMobile } = useIsBreakpointSize();
  const zIndexPopper = isMobile ? 110 : zIndex;

  useEffect(() => {
    if (isMobile && isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isMobile, isOpen]);

  useEffect(() => {
    const handleDocumentClick = (event: Event) => {
      if (
        flyoverContainerRef.current &&
        !flyoverContainerRef.current.contains(event.target as Node) &&
        !(event.target as HTMLElement).classList.contains(
          'suppress-outside-click'
        )
      ) {
        closeFlyover();
      }
    };
    document.addEventListener('mousedown', handleDocumentClick);
  }, [isOpen]);

  return (
    <>
      <Trigger ref={anchorRef} onClick={openFlyover}>
        {triggerElement}
      </Trigger>
      {isOpen && (
        <Portal dom={document.body}>
          <Popper
            anchorEl={anchorRef}
            containerEl={containerRef}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            keepInViewPort={keepInViewPort}
            flip={false}
            zIndex={zIndexPopper || 90}
          >
            <FlyoverContentContainer
              ref={flyoverContainerRef}
              width="330px"
              maxHeight="500px"
              showCaret={showCaret}
              className="suppress-flyover-outside-click"
            >
              {innerElement}
              <CloseButton
                data-qaid="flyover-close-button"
                onClick={closeFlyover}
              >
                <CloseIcon />
              </CloseButton>
            </FlyoverContentContainer>
          </Popper>
        </Portal>
      )}
    </>
  );
};

const SimpleFlyoverWrapper: React.FC<Props> = props => (
  <FlyoverProvider>
    <SimpleFlyover {...props} />
  </FlyoverProvider>
);

export default SimpleFlyoverWrapper;
