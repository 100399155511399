import React from 'react';
import { useIntl } from 'react-intl';

import RadioGroup from 'app/shared/components/molecules/RadioGroup';

const crewEventSurveyAudienceReactionOptions = (intl: any) => {
  return [
    {
      value: 'negative',
      title: intl.formatMessage({
        id: 'admin.crewPortal.crewFeedback.shouldNotBookAgain',
      }),
    },
    {
      value: 'neutral',
      title: intl.formatMessage({
        id: 'admin.crewPortal.crewFeedback.standard',
      }),
    },
    {
      value: 'positive',
      title: intl.formatMessage({
        id: 'admin.crewPortal.crewFeedback.blewAudienceAway',
      }),
    },
  ];
};

interface Props {
  idNamePrefix: string;
  onChange: (value: any) => void;
  selectedValue?: string;
}

const CrewEventSurveyAudienceReaction: React.FC<Props> = ({
  idNamePrefix,
  onChange,
  selectedValue,
}) => {
  const intl = useIntl();
  return (
    <RadioGroup
      name={idNamePrefix}
      options={crewEventSurveyAudienceReactionOptions(intl)}
      selectedValue={selectedValue}
      onChange={onChange}
    />
  );
};

export default CrewEventSurveyAudienceReaction;
