import React, { useEffect } from 'react';

import DatePicker from 'app/shared/components/atoms/DatePicker';
import GenericFormContainer from 'app/shared/components/atoms/GenericFormContainer';
import FormGroup from 'app/shared/components/molecules/FormGroup';
import CrewCustomHeader from 'app/admin/components/organisms/CrewUnavailabilityDates/CrewDatePickerCustomHeader';

interface FormProps {
  formikProps: any;
  setFormSubmitAction?: (func: Function) => void;
  setDisplayConfirmation?: (set: boolean) => void;
  setIsSubmitting?: (set: boolean) => void;
  isSubmitting: boolean;
}

const CrewUnavailabilityDatesForm: React.FC<FormProps> = ({
  formikProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  isSubmitting,
}) => {
  useEffect(() => {
    setFormSubmitAction && setFormSubmitAction(() => formikProps.submitForm);
    setDisplayConfirmation && setDisplayConfirmation(formikProps.dirty);
    setIsSubmitting && setIsSubmitting(formikProps.isSubmitting);
  }, [
    formikProps.isSubmitting,
    formikProps.submitForm,
    formikProps.dirty,
    setFormSubmitAction,
    setDisplayConfirmation,
    setIsSubmitting,
  ]);

  useEffect(() => {
    if (Object.keys(formikProps.errors).length > 0 && isSubmitting) {
      setIsSubmitting && setIsSubmitting(false);
    }
  }, [formikProps.errors, isSubmitting, setIsSubmitting]);

  const { setFieldValue, values, errors, touched } = formikProps;

  const errorMsgForField = (field: string, touched: any, errors: any) =>
    touched[field] && errors[field] ? errors[field] : null;

  return (
    <GenericFormContainer dataQaId="crew-availability-datepicker-edit-form">
      <FormGroup
        label=""
        data-qaid="crew-availability-datepicker-form-dates"
        errorMsg={errorMsgForField('dates', touched, errors)}
      >
        <DatePicker
          values={values.dates}
          onChange={values => setFieldValue('dates', values)}
          numberOfMonthsToDisplay={6}
          customizedHeader={CrewCustomHeader}
          hasBlackOutDatesStyling={true}
        />
      </FormGroup>
    </GenericFormContainer>
  );
};

export default CrewUnavailabilityDatesForm;
