import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { dateFormatter } from 'app/shared/utils/datetime';
import { DetailHeading as Heading } from 'app/admin/components/atoms/DetailContent';
import { None } from 'app/admin/components/atoms/None';

interface Props {
  crewLoadInAt?: string;
  artistLoadInAt?: string;
  guestsArriveAt?: string;
  startsAt?: string;
  endsAt?: string;
  timezone?: string;
  displayHeading?: boolean;
}

const MainContainer = styled.div`
  width: 100%;
`;

const EventTimeRow = styled.div`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.4px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
`;

const EventTime = styled.div`
  width: 40%;
`;

const EventTimeDescription = styled.div`
  width: 60%;
`;

const formatTime = (timeValue: string, timezone: string | undefined) => {
  const timeParts = timeValue.match(/^(\d\d:\d\d:\d\d)$/);
  const timeValueToUse =
    timeParts && timeParts[1] ? `2001-01-01T${timeParts[1]}Z` : timeValue;

  if (timezone) {
    return dateFormatter(
      timeValueToUse,
      'hoursAndMinutesWithSpacedAMPM',
      timezone
    ).toLowerCase();
  }

  return dateFormatter(
    timeValueToUse,
    'hoursAndMinutesWithSpacedAMPM'
  ).toLowerCase();
};

const EventTimes: React.FC<Props> = ({
  crewLoadInAt,
  artistLoadInAt,
  guestsArriveAt,
  startsAt,
  endsAt,
  timezone,
  displayHeading = true,
}) => {
  const intl = useIntl();

  return (
    <MainContainer data-qaid="event-times">
      {displayHeading && (
        <Heading>
          {intl.formatMessage({
            id: 'admin.shared.eventTimes.loadInLoadOutTimes',
          })}
        </Heading>
      )}
      <EventTimeRow>
        <EventTime>
          {crewLoadInAt ? formatTime(crewLoadInAt, timezone) : <None />}
        </EventTime>
        <EventTimeDescription>
          {intl.formatMessage({
            id: 'admin.shared.eventTimes.crewLoadInTime',
          })}
        </EventTimeDescription>
      </EventTimeRow>
      <EventTimeRow>
        <EventTime>
          {artistLoadInAt ? formatTime(artistLoadInAt, timezone) : <None />}
        </EventTime>
        <EventTimeDescription>
          {intl.formatMessage({
            id: 'admin.shared.eventTimes.artistLoadInTime',
          })}
        </EventTimeDescription>
      </EventTimeRow>
      <EventTimeRow>
        <EventTime>
          {guestsArriveAt ? formatTime(guestsArriveAt, timezone) : <None />}
        </EventTime>
        <EventTimeDescription>
          {intl.formatMessage({
            id: 'admin.shared.eventTimes.guestsArriveTime',
          })}
        </EventTimeDescription>
      </EventTimeRow>
      <EventTimeRow>
        <EventTime>
          {startsAt ? formatTime(startsAt, timezone) : <None />}
        </EventTime>
        <EventTimeDescription>
          {intl.formatMessage({
            id: 'admin.shared.eventTimes.startTime',
          })}
        </EventTimeDescription>
      </EventTimeRow>
      <EventTimeRow>
        <EventTime>
          {endsAt ? formatTime(endsAt, timezone) : <None />}
        </EventTime>
        <EventTimeDescription>
          {intl.formatMessage({
            id: 'admin.shared.eventTimes.endsTime',
          })}
        </EventTimeDescription>
      </EventTimeRow>
    </MainContainer>
  );
};

export default EventTimes;
