import { ObjectImageMap } from 'app/typings';
import { getImageSourceMap, isDefaultImage } from 'app/shared/utils/images';

export type AspectRatio = '1:1' | '4:1' | '4:3' | '4:5' | '5:4' | '16:9';

interface GetEventHeaderImageProps {
  images?: ObjectImageMap;
}

interface ImageSourceMap {
  src: string;
  srcMobile?: string;
  srcTablet?: string;
  srcSmallDesktop?: string;
  srcLargeDesktop?: string;
}

interface DetermineEventHeaderImagesProps {
  eventImageSourceMap: ImageSourceMap;
  collectionImageSourceMap?: ImageSourceMap;
}

export function getEventHeaderImage({
  images,
}: GetEventHeaderImageProps): ImageSourceMap {
  return getImageSourceMap({
    objectType: 'Event',
    useCase: 'main',
    images,
  });
}

export function getCollectionHeaderImage({
  images,
}: GetEventHeaderImageProps): ImageSourceMap {
  return getImageSourceMap({
    objectType: 'EventCollection',
    useCase: 'eventMainFallback',
    images,
  });
}

export function determineEventHeaderImageSourceMap({
  eventImageSourceMap,
  collectionImageSourceMap,
}: DetermineEventHeaderImagesProps): {
  imageSourceMap: ImageSourceMap;
  imageSource: string;
} {
  const collectionHasImage =
    collectionImageSourceMap?.src && collectionImageSourceMap.src.length > 0;
  const eventHasDefaultImage = isDefaultImage(eventImageSourceMap.src);
  const imageSource =
    collectionHasImage && eventHasDefaultImage
      ? 'collection'
      : eventHasDefaultImage
      ? 'eventDefault'
      : 'eventCustom';
  const imageSourceMap =
    collectionHasImage && eventHasDefaultImage
      ? collectionImageSourceMap
      : eventImageSourceMap;

  return {
    imageSourceMap,
    imageSource,
  };
}
