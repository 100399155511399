import React from 'react';

import { ContactInfo } from 'app/typings/contactInfos';
import { get } from 'app/shared/utils/get';
import { None } from 'app/admin/components/atoms/None';
import ContactInfoCard from 'app/admin/components/molecules/ContactInfoCard';

interface Props {
  contactInfos: ContactInfo[] | undefined;
}

export const PrimaryContactInfo: React.FC<Props> = ({ contactInfos }) => {
  const none = <None />;

  if (!contactInfos) {
    return none;
  }

  const primaryContactInfo = contactInfos.filter(item => item.position == 1);

  if (primaryContactInfo.length > 0) {
    const contactRelationship = get(
      primaryContactInfo[0],
      'contactRelationship.name',
      undefined
    );
    const firstName = get(primaryContactInfo[0], 'firstName', undefined);
    const lastName = get(primaryContactInfo[0], 'lastName', undefined);
    const email = get(primaryContactInfo[0], 'email', undefined);
    const phoneNumber = get(primaryContactInfo[0], 'phoneNumber', undefined);
    const isDayOfShowContact = get(
      primaryContactInfo[0],
      'isDayOfShowContact',
      undefined
    );

    return (
      <ContactInfoCard
        firstName={firstName}
        lastName={lastName}
        email={email}
        phoneNumber={phoneNumber}
        contactRelationship={contactRelationship}
        isDayOfShowContact={isDayOfShowContact}
      />
    );
  } else {
    return none;
  }
};
