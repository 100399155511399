import React from 'react';
import styled from 'styled-components';

import {
  Artist,
  ArtistApplication,
  Attendee,
  BookingRequest,
  BroadcastEntry,
  Competition,
  Country,
  CuratorGroup,
  EmailMessage,
  EventCollection,
  Offer,
  User,
  Venue,
  VenueApplication,
} from 'app/typings';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import { Responsive } from 'app/shared/components/atoms/ResponsiveManualCSS';

const GRID_WIDTH = 12;

// Union of the interfaces that are supported by the CardGrid
export type CardType =
  | Artist
  | ArtistApplication
  | Attendee
  | BookingRequest
  | BroadcastEntry
  | Competition
  | Country
  | CuratorGroup
  | EmailMessage
  | EventCollection
  | Offer
  | Venue
  | VenueApplication
  | User;

interface Props {
  objectData: CardType[];
  renderCardComponent: (obj: CardType, index: number) => JSX.Element;
  colProps?: object;
  dataQaid?: string;
  divider?: JSX.Element;
  hideDividerOnSize?: string;
  loading?: boolean;
  loadingComponent?: JSX.Element;
}

const StyledGrid = styled(Grid)`
  grid-gap: 15px !important;
`;

const StyledCol = styled(Col)`
  display: flex;
  justify-content: center;
`;

const renderLoadingBlocks = (
  loadingComponent: JSX.Element | undefined,
  colProps: object
) => {
  return Array(GRID_WIDTH)
    .fill(0)
    .map((_: any, i: number) => (
      <Col key={i} {...colProps}>
        {loadingComponent || null}
      </Col>
    ));
};

const responsiveDividerAttrs = (
  index: number,
  colProps: object,
  hideDividerOnSize: string | undefined
) => {
  const sizes = ['xs', 'sm', 'md', 'lg', 'xl'];
  const showOnSizes = sizes.filter(
    size => (index + 1) % (GRID_WIDTH / colProps[size]) === 0
  );

  if (!hideDividerOnSize) {
    return {
      showOnSizes,
    };
  }

  const filteredShowOnSizes = showOnSizes.filter(
    size => size !== hideDividerOnSize
  );

  if (!filteredShowOnSizes.length) {
    return {
      hideUp: hideDividerOnSize,
    };
  }

  return {
    showOnSizes: filteredShowOnSizes,
  };
};

const renderCards = (
  objectData: CardType[],
  renderCardComponent: (obj: CardType, index: number) => JSX.Element,
  colProps: object,
  hideDividerOnSize?: string,
  divider?: JSX.Element
) =>
  objectData.map((obj: CardType, i: number) => (
    <React.Fragment key={i}>
      <StyledCol {...colProps}>{renderCardComponent(obj, i)}</StyledCol>
      {i < objectData.length - 1 && divider && (
        <Responsive {...responsiveDividerAttrs(i, colProps, hideDividerOnSize)}>
          <Col xs={GRID_WIDTH}>{divider}</Col>
        </Responsive>
      )}
    </React.Fragment>
  ));

const CardGrid: React.FC<Props> = ({
  objectData,
  renderCardComponent,
  colProps = { xs: 12, sm: 6, md: 4, lg: 3, xl: 2 },
  dataQaid,
  divider = <DottedLine />,
  hideDividerOnSize,
  loading,
  loadingComponent,
}) => {
  return (
    <StyledGrid data-qaid={dataQaid}>
      {loading
        ? renderLoadingBlocks(loadingComponent, colProps)
        : renderCards(
            objectData,
            renderCardComponent,
            colProps,
            hideDividerOnSize,
            divider
          )}
    </StyledGrid>
  );
};

export default CardGrid;
