import { Event, EventPreview } from 'app/typings';
import {
  determineEventHeaderImageSourceMap,
  getCollectionHeaderImage,
  getEventHeaderImage,
} from 'app/shared/utils/eventImages';
import { useFallbackCollection } from 'app/shared/utils/useFallbackCollection';

export const useEventHeaderImageSourceMap = ({
  event,
}: {
  event?: Event | EventPreview;
}) => {
  const collectionImages = useFallbackCollection({
    eventCollections: event?.eventCollections,
  })?.images;

  if (!event) {
    return null;
  }

  const eventImageSourceMap = getEventHeaderImage({
    images: event.images,
  });

  const collectionImageSourceMap =
    collectionImages &&
    getCollectionHeaderImage({
      images: collectionImages,
    });

  return determineEventHeaderImageSourceMap({
    eventImageSourceMap,
    collectionImageSourceMap,
  });
};
