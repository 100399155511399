import React from 'react';
import { useIntl } from 'react-intl';

import { Event, EventStaffMember } from 'app/typings/events';
import usePermission from 'app/shared/utils/usePermission';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import TruncatedByCharText from 'app/shared/components/atoms/TruncatedByCharText';
import { Overline } from 'app/shared/components/atoms/TypographyManualCSS';
import IconAndTextLink from 'app/shared/components/molecules/IconAndTextLink';
import { eventStaffConfig } from 'app/admin/utils/crew';
import { userFullNameOrEmail } from 'app/admin/utils/users';
import {
  SectionText,
  SectionTitle,
  SectionWithIcon,
} from 'app/admin/components/atoms/CrewEventContent';
import IconInCircle from 'app/admin/components/atoms/IconInCircle';
import { ReactComponent as FireworksPeopleWatch } from 'icons/streamline-regular/entertainment-events-hobbies/fireworks/fireworks-people-watch.svg';
import { ReactComponent as PhoneActionWait } from 'icons/streamline-regular/phones-mobile-devices/phone-actions/phone-actions-wait.svg';

interface EventInfoProps {
  event: Event;
}

const orderOfRolesToDisplay = [
  'crew_lead',
  'crew_emcee',
  'booker',
  'day_of_show_support_1',
  'day_of_show_support_2',
  'day_of_show_support_3',
  'day_of_show_support_4',
  'audio',
  'gear_runner',
  'video_1',
  'video_2',
  'video_3',
  'photo',
];

const staffMemberPhoneNumber = (staffMember: EventStaffMember) => {
  if (staffMember.user?.mobile?.numberBody != null) {
    return staffMember.user?.mobile?.numberBody;
  } else {
    return null;
  }
};

const isStaffMemberRoleFilled = (staffMember: EventStaffMember) =>
  staffMember.user && staffMember.user.id;

const getRoleLabelKeyForRole = (roleName: string) => {
  const eventStaffObject = eventStaffConfig[roleName];
  return eventStaffObject ? eventStaffObject.labelKey : '';
};

const StaffMembersRoles: React.FC<{ staff?: EventStaffMember[] }> = ({
  staff,
}) => {
  if (!staff || staff.length === 0) {
    return null;
  }

  const orderedStaff = orderOfRolesToDisplay.map((roleKey: string) => {
    const staffMemberForRole = staff.find(
      (staffMember: EventStaffMember) => staffMember.role.key === roleKey
    );

    return staffMemberForRole && isStaffMemberRoleFilled(staffMemberForRole) ? (
      <RoleDetails staffMember={staffMemberForRole} />
    ) : null;
  });

  return <>{orderedStaff}</>;
};

const RoleDetails: React.FC<{ staffMember: EventStaffMember }> = ({
  staffMember,
}) => {
  const hasCrewAccessPermission = usePermission('crew.access');
  const intl = useIntl();

  return (
    <Col key={staffMember.role.key} xs={6} sm={4} md={3}>
      <Spacer mt={4} />
      <Overline>
        {intl.formatMessage({
          id: `admin.crewEvent.section.staffInfo.role.${getRoleLabelKeyForRole(
            staffMember.role.key
          )}`,
        })}
      </Overline>
      <Spacer mt={1} />

      {staffMember.user && (
        <TruncatedByCharText
          text={userFullNameOrEmail(staffMember.user) || null}
          truncateLength={18}
        />
      )}
      <Spacer mt={2} />
      {hasCrewAccessPermission && (
        <IconAndTextLink
          icon={PhoneActionWait}
          text={staffMemberPhoneNumber(staffMember) || 'None'}
          url={
            staffMemberPhoneNumber(staffMember)
              ? `tel:${staffMember.user?.mobile?.numberBody}`
              : undefined
          }
          dataQaid={`crew-staff-${staffMember.role.name.toLocaleLowerCase()}-contact-phone-number`}
          buttonStyle="unfilled"
          disabled={!staffMemberPhoneNumber(staffMember)}
        />
      )}
    </Col>
  );
};

const CrewEventStaffInfo: React.FC<EventInfoProps> = ({ event }) => {
  const intl = useIntl();

  return (
    <SectionWithIcon>
      <SectionTitle>
        <IconInCircle
          borderCircle={true}
          circleBackgroundColor="whiteDenim"
          dataQaIdSuffix="fireworks"
          iconColor="purplePills"
          iconName={FireworksPeopleWatch}
        />
        {intl.formatMessage({ id: 'admin.crewEvent.section.staffInfo' })}
      </SectionTitle>
      <SectionText>
        <Grid gap="5px">
          <StaffMembersRoles staff={event?.staff} />
        </Grid>
      </SectionText>
    </SectionWithIcon>
  );
};

export default CrewEventStaffInfo;
