import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { ContactInfo } from 'app/typings';
import { buildFullName } from 'app/shared/utils/string';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import IconAndTextLink from 'app/shared/components/molecules/IconAndTextLink';
import { Subheading } from 'app/admin/components/atoms/CrewEventContent';
import { ReactComponent as EmailActionUnread } from 'icons/streamline-regular/emails/email-actions/email-action-unread.svg';
import { ReactComponent as PhoneActionWait } from 'icons/streamline-regular/phones-mobile-devices/phone-actions/phone-actions-wait.svg';

interface CrewEventContactInfoProps {
  label: string;
  contactInfo?: ContactInfo;
}

const ContactInfoWrapper = styled.div`
  margin-left: 20px;
  margin-bottom: 17px;
`;

const ContactInfoItem = styled.div`
  margin-bottom: 8px;
`;

const CrewEventContactInfo: React.FC<CrewEventContactInfoProps> = ({
  label,
  contactInfo,
}) => {
  const intl = useIntl();

  return (
    <>
      <Subheading>{label}:</Subheading>
      <Spacer mt={4} />
      <ContactInfoWrapper>
        <ContactInfoItem>
          <Subheading>
            {intl.formatMessage({
              id: 'admin.crewEvent.section.contactInfo.name',
            })}
            :
          </Subheading>
          &nbsp;
          {buildFullName(contactInfo?.firstName, contactInfo?.lastName) ||
            intl.formatMessage({ id: 'none' })}
        </ContactInfoItem>
        <ContactInfoItem>
          <Subheading>
            {intl.formatMessage({
              id: 'admin.crewEvent.section.contactInfo.relationship',
            })}
            :
          </Subheading>
          &nbsp;
          {contactInfo?.contactRelationship?.name ||
            intl.formatMessage({ id: 'none' })}
        </ContactInfoItem>
        {contactInfo?.email && (
          <ContactInfoItem>
            <IconAndTextLink
              icon={EmailActionUnread}
              text={contactInfo?.email}
              url={`mailto:${contactInfo?.email}`}
              dataQaid="crew-event-contact-email-address"
              buttonStyle="unfilled"
            />
          </ContactInfoItem>
        )}
        {contactInfo?.phoneNumber && (
          <ContactInfoItem>
            <IconAndTextLink
              icon={PhoneActionWait}
              text={contactInfo?.phoneNumber}
              url={`tel:${contactInfo?.phoneNumber}`}
              dataQaid="crew-event-contact-phone-number"
              buttonStyle="unfilled"
            />
          </ContactInfoItem>
        )}
      </ContactInfoWrapper>
    </>
  );
};

export default CrewEventContactInfo;
