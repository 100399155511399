import * as Yup from 'yup';

import { getSocialUrlsFormSchema } from 'app/shared/utils/socialLinks';

const artistSocialsEditFormSchema = (intl: any) =>
  Yup.object().shape({
    socialUrls: getSocialUrlsFormSchema(intl),
  });

export default artistSocialsEditFormSchema;
