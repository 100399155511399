import * as Yup from 'yup';

const gdprDataRemovalFormSchema = () =>
  Yup.object().shape({
    email: Yup.string()
      .email('Must be a valid email address')
      .required('User email is required'),
  });

export default gdprDataRemovalFormSchema;
