import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { dateFormatter } from 'app/shared/utils/datetime';
import { get } from 'app/shared/utils/get';
import useModal from 'app/shared/utils/useModal';
import usePermission from 'app/shared/utils/usePermission';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import { ListPage, ListPageContext } from 'app/shared/context/ListPage';
import { LoadingBlocks } from 'app/shared/components/atoms/LoadingBlocks';
import TruncatedByCharText from 'app/shared/components/atoms/TruncatedByCharText';
import ConfirmationModal from 'app/shared/components/molecules/ConfirmationModal';
import RoutableModal from 'app/shared/components/molecules/RoutableModal';
import { GlobalError } from 'app/shared/components/pages/Status';
import { getFilterNamesAndOptionsForTitleMapping } from 'app/admin/utils/optionLists';
import { ArchiveTheme } from 'app/admin/graphql/themes/mutationHooks';
import { GetThemes } from 'app/admin/graphql/themes/queryHooks';
import ListingFooter from 'app/admin/components/molecules/ListingFooter';
import ListingNoResults from 'app/admin/components/molecules/ListingNoResults';
import ListingControls from 'app/admin/components/organisms/ListingControls';
import ListingFilter from 'app/admin/components/organisms/ListingFilter';
import ListingHeader from 'app/admin/components/organisms/ListingHeader';
import ListTable from 'app/admin/components/organisms/ListTable';
import ListPageTemplate from 'app/admin/components/templates/ListPage';
import Layout from 'app/admin/layouts/ListPage';

import { sortOptions, staticOptions } from './options';

const pageStateConfig = {
  filterNames: ['archived_status'],
  defaultSort: { by: 'title', direction: 'asc' },
  textSearchParamName: 'theme_search',
};

const AdminThemes: React.FC = () => {
  const intl = useIntl();
  const pageState = useContext(ListPageContext);
  const PER_PAGE = 25;

  const hasEditThemePermission = usePermission('theme.edit');

  const [addThemeModal, toggleAddThemeModal] = useModal();
  const [editThemeModal, toggleEditThemeModal] = useModal();
  const [archiveThemeModal, toggleArchiveThemeModal] = useModal();
  const [themeData, setThemeData] = useState<any>(undefined);

  const filterDropdownOptionsInfoList = [
    {
      filterName: 'archived_status',
      dropdownParams: {
        title: intl.formatMessage({
          id: 'admin.themes.filter.archived',
        }),
        options: staticOptions.archivedStatus,
      },
    },
  ];

  const { loading, error, data, refetch: refetchThemes } = GetThemes({
    themeSearch: pageState.textSearchState,
    archivedStatus:
      pageState.filterListVariable('archived_status') || 'not_archived',
    orderBy: pageState.sortState.by,
    orderDirection: pageState.sortState.direction,
    page: pageState.page,
    perPage: PER_PAGE,
  });

  const archiveThemeAction = ArchiveTheme();

  const handleArchiveTheme = useSubmitAction({
    submitAction: archiveThemeAction,
    submitVariables: () => ({
      id: themeData && themeData.id,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.themes.archiveSuccess',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.themes.archiveFailure',
    }),
    onSuccess: () => {
      refetchThemes();
      archiveThemeModal.hide();
    },
  });

  useEffect(() => {
    pageState.setupFilterLabelTitleMapping(
      getFilterNamesAndOptionsForTitleMapping(filterDropdownOptionsInfoList)
    );
  }, [data]);

  useEffect(() => {
    pageState.updatePageUrl();
  }, [
    pageState.page,
    pageState.sortState,
    pageState.filterState,
    pageState.textSearchState,
    pageState.detailData,
    pageState.detailsModal.isShowing,
  ]);

  if ((!loading && !data) || error) {
    return <GlobalError />;
  }

  const columnsConfig = [
    {
      headerText: intl.formatMessage({
        id: 'shared.title',
      }),
      columnWidth: 210,
    },
    {
      headerText: intl.formatMessage({
        id: 'admin.themes.pastEventsUsingTheme',
      }),
      columnWidth: 200,
    },
    {
      headerText: intl.formatMessage({
        id: 'admin.themes.upcomingEventsUsingTheme',
      }),
      columnWidth: 240,
    },
    {
      headerText: intl.formatMessage({
        id: 'admin.shared.added',
      }),
      columnWidth: 120,
    },
  ];

  const themesData = get(data, 'themes.themes', []);

  const rowValues = (theme: any) => [
    <TruncatedByCharText text={theme.title} truncateLength={20} key={1} />,
    theme.pastEventsWithThemeCount,
    theme.upcomingEventsWithThemeCount,
    theme.createdAt && dateFormatter(theme.createdAt, 'shortMonthDateAndYear'),
  ];

  const toggleEditThemeAndSetThemeData = (themeData: any) => {
    setThemeData(themeData);
    toggleEditThemeModal();
  };

  const toggleArchiveThemeAndSetThemeData = (themeData: any) => {
    setThemeData(themeData);
    toggleArchiveThemeModal();
  };

  const modalsContent = () => (
    <>
      {addThemeModal.isShowing && (
        <RoutableModal
          hide={addThemeModal.hide}
          initialRouteProps={{ refetchThemes }}
          initialRouteName="theme-create"
          dataQaidSuffix="admin-create-theme"
        />
      )}
      {editThemeModal.isShowing && (
        <RoutableModal
          hide={editThemeModal.hide}
          initialRouteProps={{
            ...themeData,
            refetchThemes,
          }}
          initialRouteName="theme-edit"
          dataQaidSuffix="admin-edit-theme"
        />
      )}
    </>
  );

  return (
    <Layout scrollDisabled={pageState.detailsModal.isShowing}>
      <ListPageTemplate
        pageTitle={intl.formatMessage({
          id: 'admin.themes.pageTitle',
        })}
        modalsContent={modalsContent}
      >
        <ListingHeader
          pageTitle={intl.formatMessage({
            id: 'admin.themes.pageTitle',
          })}
          addEntityText={
            hasEditThemePermission
              ? intl.formatMessage({
                  id: 'admin.themes.addTheme',
                })
              : undefined
          }
          onClickAddEntity={
            hasEditThemePermission ? toggleAddThemeModal : undefined
          }
          searchPlaceholder={intl.formatMessage({
            id: 'admin.themes.textSearch.placeholder',
          })}
          searchValue={pageState.textSearchState}
          onSearch={pageState.handleTextSearch}
          dataQaidPrefix="theme"
        />
        <div>
          <ListingControls
            sortOptions={sortOptions}
            orderBy={pageState.sortState.by}
            orderDirection={pageState.sortState.direction}
            totalRecords={data && data.themes.metadata.totalRecords}
            loading={loading}
            onSort={pageState.handleSortChange}
            onReset={pageState.handleResetFilters}
            dataQaidPrefix="theme"
          />
          <ListingFilter
            filterTitle={intl.formatMessage({
              id: 'admin.themes.filterTitle',
            })}
            textSearchString={pageState.textSearchState}
            handleTextSearchLabelClose={pageState.handleTextSearchLabelClose}
            labelTitleMapping={pageState.filterLabelTitleMapping}
            dropdownOptionsInfoList={filterDropdownOptionsInfoList}
            filterState={pageState.filterState}
            handleRemoveFilter={pageState.handleRemoveFilter}
            handleFilterChange={pageState.handleFilterChange}
          />
        </div>

        <ListTable
          columnsConfig={columnsConfig}
          rowValues={rowValues}
          detailsFlyoverInfo={{
            overline: intl.formatMessage({
              id: 'admin.themes.themeNotes',
            }),
            title: (obj: any) => obj.title,
            body: (obj: any) => obj.notes,
            footer: () =>
              intl.formatMessage({
                id: 'admin.themes.themeNotesNote',
              }),
          }}
          onEdit={
            hasEditThemePermission &&
            pageState.filterListVariable('archived_status') != 'archived'
              ? (obj: any) => toggleEditThemeAndSetThemeData({ ...obj })
              : undefined
          }
          onArchive={
            hasEditThemePermission &&
            pageState.filterListVariable('archived_status') != 'archived'
              ? (obj: any) =>
                  toggleArchiveThemeAndSetThemeData({
                    id: obj.id,
                    title: obj.title,
                    upcomingEventsWithThemeCount:
                      obj.upcomingEventsWithThemeCount,
                  })
              : undefined
          }
          data={themesData}
          loadingContent={
            loading ? (
              <LoadingBlocks.Rectangle width="100%" height="300px" />
            ) : null
          }
          noResultsContent={
            <ListingNoResults
              entityName={intl.formatMessage({
                id: 'admin.themes.noResultsEntityName',
              })}
              numResults={get(data, 'themes.themes.length', undefined)}
              loading={loading}
            />
          }
          footerContent={
            <ListingFooter
              numTotalRecords={get(
                data,
                'themes.metadata.totalRecords',
                undefined
              )}
              perPage={PER_PAGE}
              currentPage={pageState.page}
              onPageChange={pageState.handlePageChange}
              loading={loading}
              dataQaidPrefix="theme"
            />
          }
        />
        {archiveThemeModal.isShowing && (
          <>
            {themeData && themeData.upcomingEventsWithThemeCount > 0 ? (
              <ConfirmationModal
                confirmationButtonText={intl.formatMessage({
                  id: 'okay',
                })}
                description={intl.formatMessage(
                  {
                    id: 'admin.themes.cannotArchiveNote',
                  },
                  {
                    title: themeData && themeData.title,
                    upcomingEventsWithThemeCount:
                      themeData &&
                      themeData.upcomingEventsWithThemeCount.toString(),
                  }
                )}
                onConfirm={() => archiveThemeModal.hide()}
                onClose={() => archiveThemeModal.hide()}
              />
            ) : (
              <ConfirmationModal
                onCancel={() => archiveThemeModal.hide()}
                description={intl.formatMessage(
                  {
                    id: 'admin.themes.archiveConfirmation',
                  },
                  {
                    title: themeData && themeData.title,
                  }
                )}
                onConfirm={handleArchiveTheme}
              />
            )}
          </>
        )}
      </ListPageTemplate>
    </Layout>
  );
};

const AdminThemesWrapper: React.FC = () => (
  <ListPage config={pageStateConfig}>
    <AdminThemes />
  </ListPage>
);

export default AdminThemesWrapper;
