export const sortOptions = [
  {
    title: 'Soonest Date of Concert',
    value: {
      by: 'start',
      direction: 'asc',
    },
  },
  {
    title: 'Latest Date of Concert',
    value: {
      by: 'start',
      direction: 'desc',
    },
  },
];

export const dateOptions = () => {
  const opts = [
    {
      title: 'Today',
      value: 'today',
    },
    {
      title: 'Tomorrow',
      value: 'tomorrow',
    },
    {
      title: 'Next 7 Days',
      value: 'next_7_days',
    },
    {
      title: 'Next 14 Days',
      value: 'next_14_days',
    },
    {
      title: 'Next 30 Days',
      value: 'next_30_days',
    },
    {
      title: 'Next 90 Days',
      value: 'next_90_days',
    },
    {
      title: 'Past 7 Days',
      value: 'past_7_days',
    },
    {
      title: 'Past 30 Days',
      value: 'past_30_days',
    },
    {
      title: 'Past 90 Days',
      value: 'past_90_days',
    },
  ];
  return opts;
};

export const statusOptions = [
  {
    title: 'Cancelled',
    value: 'cancelled',
  },
  {
    title: 'Draft',
    value: 'draft',
  },
  {
    title: 'Published',
    value: 'published',
  },
  {
    title: 'Sold Out',
    value: 'sold_out',
  },
  {
    title: 'Tickets Active',
    value: 'tickets_active',
  },
  {
    title: 'Tickets Paused',
    value: 'tickets_paused',
  },
];

export const showFieldsOptions = [
  {
    title: 'Status',
    value: 'status',
  },
  {
    title: 'Artists',
    value: 'artists',
  },
  {
    title: 'Venue',
    value: 'venue',
  },
  {
    title: 'Team',
    value: 'team',
  },
  {
    title: 'Production',
    value: 'production',
  },
  {
    title: 'Guests',
    value: 'guests',
  },
  {
    title: 'Concert Details',
    value: 'details',
  },
];

export const notYetBookedOptions = (intl: any) => {
  return [
    {
      fields: { groupName: 'Artists' },
      title: intl.formatMessage(
        {
          id: 'admin.offerCreate.slotNum',
        },
        {
          num: 1,
        }
      ),
      value: 'slot_1',
    },
    {
      fields: { groupName: 'Artists' },
      title: intl.formatMessage(
        {
          id: 'admin.offerCreate.slotNum',
        },
        {
          num: 2,
        }
      ),
      value: 'slot_2',
    },
    {
      fields: { groupName: 'Artists' },
      title: intl.formatMessage(
        {
          id: 'admin.offerCreate.slotNum',
        },
        {
          num: 3,
        }
      ),
      value: 'slot_3',
    },
    {
      fields: { groupName: 'Artists' },
      title: intl.formatMessage({
        id: 'admin.eventPlanner.filter.options.allArtistPositions',
      }),
      value: 'all_artist_positions',
    },
    {
      fields: { groupName: 'Hosts' },
      title: intl.formatMessage({
        id: 'shared.venue',
      }),
      value: 'venue',
    },
    {
      fields: { groupName: 'Hosts' },
      title: intl.formatMessage({
        id: 'admin.eventPlanner.venueInfo.hasOpenVenueInvitation',
      }),
      value: 'open_venue_offer',
    },
    {
      fields: { groupName: 'Crew' },
      title: intl.formatMessage({
        id: 'admin.crewPortal.crewRole.lead',
      }),
      value: 'crew_lead',
    },
    {
      fields: { groupName: 'Crew' },
      title: intl.formatMessage({
        id: 'admin.crewPortal.crewRole.emcee',
      }),
      value: 'crew_emcee',
    },
    {
      fields: { groupName: 'Crew' },
      title: intl.formatMessage({
        id: 'admin.crewPortal.crewRole.support',
      }),
      value: 'support',
    },
    {
      fields: { groupName: 'Crew' },
      title: intl.formatMessage({
        id: 'admin.crewPortal.crewRole.audioEngineer',
      }),
      value: 'audio',
    },
    {
      fields: { groupName: 'Crew' },
      title: intl.formatMessage({
        id: 'admin.eventPlanner.filter.options.gearRunner',
      }),
      value: 'gear_runner',
    },
    {
      fields: { groupName: 'Crew' },
      title: intl.formatMessage({
        id: 'admin.crewPortal.crewRole.photographer',
      }),
      value: 'photo',
    },
    {
      fields: { groupName: 'Crew' },
      title: intl.formatMessage({
        id: 'admin.crewPortal.crewRole.videographer',
      }),
      value: 'video',
    },
    {
      fields: { groupName: 'Crew' },
      title: intl.formatMessage({
        id: 'admin.eventPlanner.filter.options.allCrewPositions',
      }),
      value: 'all_crew_positions',
    },
  ];
};

export const ticketOptions = [
  {
    title: '0-25',
    value: '0-25',
  },
  {
    title: '26-50',
    value: '26-50',
  },
  {
    title: '51-75',
    value: '51-75',
  },
  {
    title: '76-100',
    value: '76-100',
  },
  {
    title: '101-125',
    value: '101-125',
  },
  {
    title: '126-150',
    value: '126-150',
  },
  {
    title: '151-175',
    value: '151-175',
  },
  {
    title: '176-200',
    value: '176-200',
  },
  {
    title: '200+',
    value: '200+',
  },
];

export const bookingsOptions = (intl: any) => [
  {
    title: intl.formatMessage({
      id: 'admin.eventPlanner.filter.options.hasSelfBookedArtist',
    }),
    value: 'has_self_booked_artist',
  },
];
