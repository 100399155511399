import React, { useState } from 'react';

import { useCurrentTheme } from 'app/shared/theme';
import { makeOpaque } from 'app/shared/utils/colors';
import InfoBox from 'app/shared/components/molecules/Flyover';
import { CrewRoleStatus } from 'app/admin/utils/crew';
import IconInCircle from 'app/admin/components/atoms/IconInCircle';
import CrewRoleFlyover from 'app/admin/components/organisms/CrewRoleFlyover';
import { ReactComponent as Check1 } from 'icons/streamline-regular/interface-essential/form-validation/check-1.svg';
import { ReactComponent as Add } from 'icons/streamline-regular/interface-essential/remove-add/add.svg';

interface CrewRoleCircleProps {
  eventId: number;
  citySlug: string;
  cityTitle: string;
  localStartsAt: string;
  crewLoadInAt?: string;
  cancelled?: boolean;
  staffObject: any;
  roleStatus: CrewRoleStatus;
  refetchEvents: Function;
}

const isEventGreaterThanThirtyDays = (localStartsAt: string) => {
  const today = new Date();
  const eventDateTime = new Date(localStartsAt);
  const oneMonthFromToday = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + 30
  );

  return eventDateTime.getTime() > oneMonthFromToday.getTime();
};

const CrewRoleCircle: React.FC<CrewRoleCircleProps> = ({
  eventId,
  citySlug,
  cityTitle,
  localStartsAt,
  crewLoadInAt,
  cancelled,
  staffObject,
  roleStatus,
  refetchEvents,
}) => {
  const theme = useCurrentTheme();
  const [flyoverClosed, setFlyoverClosed] = useState(false);
  const [flyoverOpen, setFlyoverOpen] = useState(false);

  return (
    <div>
      {roleStatus === CrewRoleStatus.AVAILABLE && (
        <>
          {cancelled ? (
            <IconInCircle
              circleBackgroundColor={theme.colors.macyGrey}
              iconName={Add}
              dataQaIdSuffix="add"
            />
          ) : (
            <InfoBox
              showCaret={false}
              onStateChange={(isOpen: boolean) => {
                if (!isOpen) {
                  setFlyoverOpen(false);
                  setFlyoverClosed(true);
                } else {
                  setFlyoverOpen(true);
                  setFlyoverClosed(false);
                }
              }}
              triggerElement={
                <IconInCircle
                  circleBackgroundColor="orangeCrush"
                  iconName={Add}
                  dataQaIdSuffix="add"
                />
              }
              shouldFlyoverClose={flyoverClosed}
              shouldFlyoverOpen={flyoverOpen}
              innerContent={
                <CrewRoleFlyover
                  eventId={eventId}
                  citySlug={citySlug}
                  cityTitle={cityTitle}
                  localStartsAt={localStartsAt}
                  crewLoadInAt={crewLoadInAt}
                  initialState="activateRoleForm"
                  staffObject={staffObject}
                  onSuccess={() => {
                    setFlyoverOpen(false);
                    setFlyoverClosed(true);
                    refetchEvents && refetchEvents();
                  }}
                  onSuccessWithoutRefetch={() => {
                    setFlyoverOpen(false);
                    setFlyoverClosed(true);
                  }}
                />
              }
            />
          )}
        </>
      )}
      {roleStatus === CrewRoleStatus.UNAVAILABLE && (
        <IconInCircle
          circleBackgroundColor="macyGrey"
          iconName={Add}
          dataQaIdSuffix="unavailable"
        />
      )}
      {roleStatus === CrewRoleStatus.NOT_APPLICABLE && (
        <IconInCircle
          circleBackgroundColor={makeOpaque(theme.colors.macyGrey, 0.5)}
          iconName="notApplicable"
          iconColor="blueSmoke"
          iconSize={16}
          isStreamlineIcon={false}
          dataQaIdSuffix="not-applicable"
        />
      )}
      {roleStatus === CrewRoleStatus.FILLED && (
        <IconInCircle
          circleBackgroundColor={
            cancelled ? theme.colors.macyGrey : 'blueMagic'
          }
          dataQaIdSuffix="none"
        />
      )}
      {roleStatus === CrewRoleStatus.FILLED_BY_CURRENT_USER && (
        <>
          {cancelled ? (
            <IconInCircle
              circleBackgroundColor={theme.colors.macyGrey}
              iconName={Check1}
              dataQaIdSuffix="check-1"
            />
          ) : (
            <InfoBox
              showCaret={false}
              onStateChange={(isOpen: boolean) => {
                if (!isOpen) {
                  setFlyoverOpen(false);
                  setFlyoverClosed(true);
                } else {
                  setFlyoverOpen(true);
                  setFlyoverClosed(false);
                }
              }}
              triggerElement={
                <IconInCircle
                  circleBackgroundColor="blueChristmas"
                  iconName={Check1}
                  dataQaIdSuffix="check-1"
                />
              }
              shouldFlyoverClose={flyoverClosed}
              shouldFlyoverOpen={flyoverOpen}
              innerContent={
                <CrewRoleFlyover
                  eventId={eventId}
                  citySlug={citySlug}
                  cityTitle={cityTitle}
                  localStartsAt={localStartsAt}
                  initialState="manageRoleOption"
                  staffObject={staffObject}
                  isSwappable={!isEventGreaterThanThirtyDays(localStartsAt)}
                  onSuccess={() => {
                    setFlyoverOpen(false);
                    setFlyoverClosed(true);
                    refetchEvents && refetchEvents();
                  }}
                />
              }
            />
          )}
        </>
      )}
    </div>
  );
};

export default CrewRoleCircle;
