import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { User } from 'app/typings';
import { Artist } from 'app/typings/artists';
import { Event } from 'app/typings/events';
import { useAnalyticsContext } from 'app/shared/utils';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import { AuthContext } from 'app/shared/context/Auth';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { SubTitle, Title } from 'app/shared/components/atoms/ModalTitle';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { CreateOfferForArtists } from 'app/admin/graphql/offers/mutationHooks';

import OfferCreateForm from './OfferCreateForm';
import OfferCreateFormSchema from './OfferCreateFormSchema';

interface Props {
  contentProps: any;
  setButtonConfigs?: (buttonConfigs: any) => void;
  setGoBackAction?: (func: Function) => void;
  setGoForwardAction?: (func: Function) => void;
  setFormSubmitAction?: (func: Function) => void;
  setDisplayConfirmation?: (set: boolean) => void;
  setIsSubmitting?: (set: boolean) => void;
  setConfirmationDescription?: (confirmationDescription: string) => void;
  setConfirmationButtonText?: (confirmationButtonText: string) => void;
  setCancellationButtonText?: (cancellationButtonText: string) => void;
  setConfirmationCustomContent?: (customContent: JSX.Element) => void;
  hide: VoidFunction;
}

const formStepPageTitleKeys = {
  step1: 'admin.offerCreate.titleSelectArtists',
  step2: 'admin.offerCreate.titleSelectDates',
  step3: 'admin.offerCreate.titleSummary',
};

const MainContainer = styled.div`
  ${({ theme }) => css`
    padding: 0px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    ${theme.media.xs`
      padding: 0px 5px 20px 5px;
    `}
  `}
`;

const HeaderContainer = styled.div`
  padding-bottom: 10px;
`;

export interface InvalidEventInfo {
  invalidEventIdsAndPositions: object;
  invalidEventIdsAndArtistIds: object;
}

const defaultInvalidEventInfo = {
  invalidEventIdsAndPositions: {},
  invalidEventIdsAndArtistIds: {},
};

const getInvalidEventIdsAndRelatedValues = (
  errorKey: string,
  validationErrors: object,
  errorFormat: string
) => {
  const errorStrRegex = new RegExp(`${errorKey}:([0-9_,]+)`);

  // @ts-ignore
  const matchValues = (validationErrors.general_errors || '').match(
    errorStrRegex
  );

  if (matchValues) {
    return matchValues[1]
      .split(',')
      .reduce((obj: any, eventIdAndValue: string) => {
        let eventId, value;
        if (errorFormat == 'eventIdBeforeValue') {
          [eventId, value] = eventIdAndValue.split('_');
        } else {
          [value, eventId] = eventIdAndValue.split('_');
        }
        const values = obj[eventId] || [];
        return {
          ...obj,
          [eventId]: values.concat([value]).sort(),
        };
      }, {});
  } else {
    return {};
  }
};

const getInvalidEventInfo = (validationErrors: object) => ({
  invalidEventIdsAndPositions: getInvalidEventIdsAndRelatedValues(
    'invalid_event_positions',
    validationErrors,
    'eventIdBeforeValue'
  ),
  invalidEventIdsAndArtistIds: getInvalidEventIdsAndRelatedValues(
    'invalid_artist_events',
    validationErrors,
    'valueBeforeEventId'
  ),
});

export const getEventPositionsSubmitVariable = (
  events: Event[],
  positions: number[],
  invalidEventInfo: InvalidEventInfo
) => {
  const info = [];
  for (const event of events) {
    for (const position of positions) {
      if (
        !(
          invalidEventInfo.invalidEventIdsAndPositions[event.id.toString()] &&
          invalidEventInfo.invalidEventIdsAndPositions[
            event.id.toString()
          ].includes(position.toString())
        )
      ) {
        info.push({
          eventId: event.id,
          position,
        });
      }
    }
  }
  return info;
};

export const getArtistIdsSubmitVariable = (
  artists: Artist[],
  events: Event[],
  invalidEventInfo: InvalidEventInfo
) => {
  const selectedEventIds = events.map((event: Event) => event.id.toString());
  return artists
    .filter(
      (artist: Artist) =>
        !Object.keys(invalidEventInfo.invalidEventIdsAndArtistIds).some(
          (eventId: string) => {
            const artistIds =
              invalidEventInfo.invalidEventIdsAndArtistIds[eventId];
            return (
              selectedEventIds.includes(eventId) &&
              artistIds.includes(artist.id.toString())
            );
          }
        )
    )
    .map((artist: Artist) => artist.id);
};

const OfferCreate: React.FC<Props> = ({
  contentProps,
  setButtonConfigs,
  setGoBackAction,
  setGoForwardAction,
  setFormSubmitAction,
  setDisplayConfirmation,
  setConfirmationDescription,
  setConfirmationButtonText,
  setCancellationButtonText,
  setConfirmationCustomContent,
  setIsSubmitting,
  hide,
}) => {
  const intl = useIntl();

  const [formStep, setFormStep] = useState<string>(
    contentProps.initialFormStep || 'step1'
  );
  const [subtitle, setSubtitle] = useState<string>('');
  const [invalidEventInfo, setInvalidEventInfo] = useState<InvalidEventInfo>(
    defaultInvalidEventInfo
  );
  const [
    hasValidationErrorOverMaxOfferSize,
    setHasValidationErrorOverMaxOfferSize,
  ] = useState<boolean>(false);

  const { trackAnalyticsEvent } = useAnalyticsContext();
  const { user } = useContext(AuthContext);
  const hideStep1 = contentProps.initialFormStep === 'step2';
  useEffect(() => {
    if (hideStep1) {
      const description = intl.formatMessage({
        id: 'admin.offerCreate.confirmationDescription',
      });
      const subDescription = intl.formatMessage({
        id: 'admin.offerCreate.confirmationSubDescription',
      });
      const customContent = <span>{subDescription}</span>;
      setConfirmationButtonText &&
        setConfirmationButtonText('CANCEL INVITATION');
      setCancellationButtonText &&
        setCancellationButtonText('CONTINUE WITH BOOKING');
      setConfirmationDescription && setConfirmationDescription(description);
      setConfirmationCustomContent &&
        setConfirmationCustomContent(customContent);
    }
  }, [
    hideStep1,
    intl,
    setCancellationButtonText,
    setConfirmationButtonText,
    setConfirmationDescription,
    setConfirmationCustomContent,
  ]);

  const formInitialValues = {
    name: contentProps.offerName || '',
    city: contentProps.event?.city || contentProps.city,
    artists: contentProps.artists || [],
    recommendedArtists: [],
    events: contentProps.event ? [contentProps.event] : [],
    positions: contentProps.position ? [contentProps.position] : [],
  };
  const createOfferForArtistsAction = CreateOfferForArtists();

  const handleCreateOffer = useSubmitAction({
    submitAction: createOfferForArtistsAction,
    submitVariables: values => ({
      artistIds: getArtistIdsSubmitVariable(
        values.artists,
        values.events,
        invalidEventInfo
      ),
      recommendedArtistIds: values.recommendedArtists.map(
        (artist: Artist) => artist.id
      ),
      eventPositions: getEventPositionsSubmitVariable(
        values.events,
        values.positions,
        invalidEventInfo
      ),
      name: values.name,
      cityId: values.city ? values.city.id : null,
      attribution: contentProps.attribution,
      availableSpecificDateArtistIds:
        contentProps.availableSpecificDateArtistIds,
      availableGeneralArtistIds: contentProps.availableGeneralArtistIds,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.offerCreate.successMessage',
    }),
    successMsgValuesForDisplay: {
      name: (values: any) => values.name,
    },
    failureMsg: intl.formatMessage({
      id: 'admin.offerCreate.failureMessage',
    }),
    onSuccess: () => {
      contentProps.refetchEvents && contentProps.refetchEvents();
      if (contentProps.onSuccessCreateAvailabilityOffer) {
        contentProps.onSuccessCreateAvailabilityOffer();
      }
      hide();
    },
    onValidationError: validationErrors => {
      const invalidEventInfo = getInvalidEventInfo(validationErrors);
      setInvalidEventInfo(invalidEventInfo);
      trackInvalidEventList(invalidEventInfo, user);
    },
  });

  const trackInvalidEventList = (
    invalidEventInfo: InvalidEventInfo,
    user: User | null
  ) => {
    const invalidEventIdsAndArtistIds =
      invalidEventInfo.invalidEventIdsAndArtistIds;
    if (invalidEventIdsAndArtistIds) {
      Object.keys(invalidEventIdsAndArtistIds).forEach(eventId => {
        (invalidEventIdsAndArtistIds[eventId] as string[]).forEach(artistId => {
          trackInvalidEvent(
            user?.email,
            'invalid_artist_events',
            eventId,
            artistId,
            null
          );
        });
      });
    }

    const invalidEventIdsAndPositions =
      invalidEventInfo.invalidEventIdsAndPositions;
    if (invalidEventIdsAndPositions) {
      Object.keys(invalidEventIdsAndPositions).forEach(eventId => {
        (invalidEventIdsAndPositions[eventId] as string[]).forEach(position => {
          trackInvalidEvent(
            user?.email,
            'invalid_event_positions',
            eventId,
            null,
            position
          );
        });
      });
    }
  };

  const trackInvalidEvent = (
    email: string | undefined,
    validationErrorType: string,
    eventId: string,
    artistId: string | null,
    position: string | null
  ) => {
    trackAnalyticsEvent('Performance Offer Creation Validation Error', {
      email,
      validation_error_type: validationErrorType,
      event_id: eventId,
      artist_id: artistId,
      position,
    });
  };

  const title = `${intl.formatMessage({
    id: 'admin.offerCreate.title',
  })} - ${intl.formatMessage({
    id: formStepPageTitleKeys[formStep],
  })}`;

  return (
    <ModalContentContainer>
      <MainContainer>
        <HeaderContainer>
          <Title>{title}</Title>
          {subtitle && <SubTitle>{subtitle}</SubTitle>}
        </HeaderContainer>
        <GenericForm
          formInitialValues={formInitialValues}
          renderFormComponent={(renderProps: any) => (
            <OfferCreateForm
              formikProps={renderProps.formikProps}
              modalRef={contentProps.modalRef}
              setButtonConfigs={setButtonConfigs}
              setGoBackAction={setGoBackAction}
              setGoForwardAction={setGoForwardAction}
              setFormSubmitAction={setFormSubmitAction}
              setDisplayConfirmation={setDisplayConfirmation}
              setIsSubmitting={setIsSubmitting}
              invalidEventInfo={invalidEventInfo}
              setInvalidEventInfo={setInvalidEventInfo}
              defaultInvalidEventInfo={defaultInvalidEventInfo}
              hasValidationErrorOverMaxOfferSize={
                hasValidationErrorOverMaxOfferSize
              }
              setHasValidationErrorOverMaxOfferSize={
                setHasValidationErrorOverMaxOfferSize
              }
              formStep={formStep}
              hideStep1={hideStep1}
              setFormStep={setFormStep}
              setSubtitle={setSubtitle}
              creatingOfferForSingleEvent={
                contentProps.creatingOfferForSingleEvent
              }
            />
          )}
          onSubmit={handleCreateOffer}
          formSchema={OfferCreateFormSchema(intl)}
          dataQaId="offer-create-form"
          paddingTop="0"
        />
      </MainContainer>
    </ModalContentContainer>
  );
};

export default OfferCreate;
