import React from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import SEO from 'app/shared/components/molecules/SEO';
import AcknowledgeUserAgreement from 'app/admin/components/templates/AcknowledgeUserAgreement';
import Layout from 'app/admin/layouts/AcknowledgeUserAgreement';

const BodyText = styled.p`
  text-align: left;
  margin-bottom: 30px;
`;

const TableContainer = styled.div`
  margin-bottom: 30px;
`;

const Table = styled.table`
  border: 1px solid black;
  border-collapse: collapse;
`;

const TableLabel = styled.td`
  font-weight: 600;
  padding: 15px 30px 15px 15px;
  border: 1px solid black;
`;

const TableValue = styled.td`
  padding: 15px 30px 15px 15px;
  border: 1px solid black;
`;

const downloadUrl =
  'https://sofar-eu-live.s3.eu-west-1.amazonaws.com/documents/Sofar_Commercial_Curator_Agreement_2022.pdf';

const AdminCuratorAgreement2: React.FC = () => {
  const intl = useIntl();
  const location = useLocation();

  return (
    <Layout>
      <SEO title="Admin Curator Agreement 2" />
      <AcknowledgeUserAgreement
        location={location}
        agreementName="curator_2"
        actions={['acknowledge', 'decline']}
        agreementTitle={intl.formatMessage({
          id: 'admin.curatorAgreement2.title',
        })}
        agreementIntroContent={`Hello! We’ve updated our Curator terms and conditions
      for organizing commercial (ticketed) Sofar shows. Please read the updated
      terms and conditions <a href="${downloadUrl}" target="_blank" style="color:#10AD52;">here</a>.
      We have included the commercial summary of terms below for your reference.`}
      >
        <>
          <BodyText>
            Click <b>&quot;I Agree to Terms&quot;</b> below to indicate that you
            have read and agree to the terms of the commercial curator agreement
            and summary below.
          </BodyText>
          <BodyText>
            Click <b>&quot;I&#39;m Not Interested&quot;</b> below to indicate
            that you&#39;re not interested in organizing commercial shows.
          </BodyText>
          <BodyText>
            <b>Please note:</b> Putting on any Sofar show, commercial or
            non-commercial, is completely optional. If you click ‘not
            interested,’ you can still access the platform with permissions for
            non-commercial shows. As a reminder, all curators are required to
            follow the Safe Show Requirements and Community Guidelines found on
            the curator handbook.
          </BodyText>
          <BodyText>
            <b>
              This Commercial Summary is subject to the Sofar Sounds Curator
              Terms and Conditions (“Terms”). Unless otherwise defined herein,
              capitalised terms in this Commercial Summary have the meaning set
              out in the Terms.
            </b>
          </BodyText>
          <TableContainer>
            <Table>
              <tbody>
                <tr>
                  <TableLabel>Effective Date</TableLabel>
                  <TableValue>January 1, 2022</TableValue>
                </tr>
                <tr>
                  <TableLabel>Parties</TableLabel>
                  <TableValue>
                    <p>
                      1. Sofar Sounds Limited, with registered address at 5th
                      Floor Roundhouse, 212 Regents Park Road, London, United
                      Kingdom, NW1 8AW and with company number 07561876 / Sofar
                      Sounds LLC, with registered address 251 Little Falls
                      Drive, Wilmington, DE USA 19808 (“us”),
                    </p>
                    <p>AND</p>
                    <p>2. You</p>
                  </TableValue>
                </tr>
                <tr>
                  <TableLabel>Commission due to Sofar</TableLabel>
                  <TableValue>15% percent of Gross Sales Value</TableValue>
                </tr>
              </tbody>
            </Table>
          </TableContainer>
        </>
      </AcknowledgeUserAgreement>
    </Layout>
  );
};

export default AdminCuratorAgreement2;
