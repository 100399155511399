import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { City } from 'app/typings/cities';
import { errorMsgForField } from 'app/shared/utils/form';
import {
  FormGroupContainer,
  FormSectionTitle,
} from 'app/shared/components/atoms/FormContent';
import GenericFormContainer from 'app/shared/components/atoms/GenericFormContainer';
import { Textfield } from 'app/shared/components/atoms/Textfield';
import FormGroup from 'app/shared/components/molecules/FormGroup';
import CitySelectorManualCSS from 'app/shared/components/organisms/CitySelectorManualCSS';
import { NumberTextfield } from 'app/admin/components/molecules/Textfield';
import CompetitionContentEditFormFields from 'app/admin/components/organisms/CompetitionContentEdit/CompetitionContentEditFormFields';
import CompetitionSharingEditFormFields from 'app/admin/components/organisms/CompetitionSharingEdit/CompetitionSharingEditFormFields';

interface FormProps {
  formikProps: any;
  validationErrors?: object;
}

const TemplateNameTextfield = styled(Textfield)`
  display: block;
  width: 75%;
`;

const TitleTextfield = styled(Textfield)`
  display: block;
  width: 100%;
`;

const CompetitionTemplateEditForm: React.FC<FormProps> = ({
  formikProps,
  validationErrors = {},
}) => {
  const intl = useIntl();

  const { setFieldValue, touched, errors, values } = formikProps;

  return (
    <GenericFormContainer dataQaId="competition-template-edit-form">
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.competitionTemplateEdit.form.templateName',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="competition-template-edit-form-template-name-formgroup"
          label={intl.formatMessage({
            id: 'admin.competitionTemplateEdit.form.templateName',
          })}
          errorMsg={
            errorMsgForField('templateName', touched, errors) ||
            // @ts-ignore
            validationErrors.template_name
          }
          required
        >
          <TemplateNameTextfield
            data-qaid="competition-template-edit-form-template-name-field"
            id="templateName"
            name="templateName"
            value={values.templateName}
            onChange={(e: any) => {
              setFieldValue('templateName', e.target.value);
            }}
            maxLength={50}
          />
        </FormGroup>
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.competitionTemplateEdit.form.title',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="competition--template-edit-form-title-formgroup"
          label={intl.formatMessage({
            id: 'admin.competitionTemplateEdit.form.title',
          })}
          errorMsg={errorMsgForField('title', touched, errors)}
        >
          <TitleTextfield
            data-qaid="competition--template-edit-form-title-field"
            id="title"
            name="title"
            value={values.title}
            onChange={(e: any) => {
              setFieldValue('title', e.target.value);
            }}
            maxLength={200}
          />
        </FormGroup>
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.competitionTemplateEdit.form.city',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="competition-template-edit-form-select-city-formgroup"
          label={intl.formatMessage({
            id: 'admin.competitionTemplateEdit.form.city',
          })}
          errorMsg={errorMsgForField('city', touched, errors)}
        >
          <CitySelectorManualCSS
            parentContainer="competition-template-edit-form-select-city-formgroup"
            data-qaid="competition-template-edit-form-select-city"
            disableTopCities
            disableClosestCities
            initialValue={values.city}
            value={values.city}
            onChange={(city: City) => {
              setFieldValue('city', city);
            }}
          />
        </FormGroup>
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.competitionTemplateEdit.form.maxNumberOfWinners',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="competition-template-edit-form-max-winners-formgroup"
          label={intl.formatMessage({
            id: 'admin.competitionTemplateEdit.form.maxNumberOfWinnersNote',
          })}
          errorMsg={errorMsgForField('maxWinners', touched, errors)}
        >
          <NumberTextfield
            data-qaid="competition-template-edit-form-max-winners-field"
            id="maxWinners"
            name="maxWinners"
            value={values.maxWinners}
            onChange={(e: any) => {
              setFieldValue('maxWinners', e.target.value);
            }}
            maxLength={4}
          />
        </FormGroup>
      </FormGroupContainer>
      <CompetitionContentEditFormFields
        setFieldValue={setFieldValue}
        touched={touched}
        errors={errors}
        values={values}
      />
      <CompetitionSharingEditFormFields
        setFieldValue={setFieldValue}
        setTouched={formikProps.setTouched}
        touched={touched}
        errors={errors}
        values={values}
      />
    </GenericFormContainer>
  );
};

export default CompetitionTemplateEditForm;
