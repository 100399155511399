import React from 'react';
import styled, { css } from 'styled-components';

import { makeOpaque } from 'app/shared/utils/colors';
import { Col } from 'app/shared/components/atoms/GridManualCSS';
import { DeleteIcon, EditIcon } from 'app/shared/components/atoms/IconLibrary';
import LazyImgixImage from 'app/shared/components/atoms/LazyImgixLoad';
import { EventDate } from 'app/admin/components/molecules/EventPlannerUtils';

import { imageThumbnailStyles } from './ImageThumbnailStyles';

interface Props {
  imageUrl?: string;
  imageStyle?: string;
  loading?: boolean;
  displayEditIcon?: boolean;
  onEditIconClick?: VoidFunction;
  displayDeleteIcon?: boolean;
  toggleDeleteImageConfirmationModal?: VoidFunction;
  toggleViewLargeImageModal?: VoidFunction;
  detailsDate?: string;
}

interface IconContainerProps {
  imageStyle?: string;
}

const EditIconContainer = styled.div<IconContainerProps>`
  ${({ imageStyle }) => css`
    position: absolute;
    top: ${imageStyle == 'circle' ? '-5px' : '0px'};
    right: ${imageStyle == 'circle'
      ? '-5px'
      : imageStyle == 'square'
      ? '-20px'
      : '0px'};
  `}}
`;

const DeleteIconContainer = styled.span<IconContainerProps>`
  ${({ imageStyle, theme }) => css`
    position: absolute;
    top: 0;
    right: ${imageStyle == 'circle' ? '0px' : '17px'};
    background-color: ${makeOpaque(theme.colors.backToBlack, 0.82)};
    padding: 5px;
    border-radius: 6px;
    cursor: pointer;
  `}}
`;

export const DetailsImage: React.FC<Props> = ({
  imageUrl,
  imageStyle,
  loading = false,
  displayEditIcon = false,
  onEditIconClick,
  displayDeleteIcon = false,
  toggleDeleteImageConfirmationModal,
  toggleViewLargeImageModal,
  detailsDate,
}) => {
  const thumbnailStyle = imageStyle && imageThumbnailStyles[imageStyle];
  const ThumbnailComponent = thumbnailStyle && thumbnailStyle.component;
  const ThumbnailWrapper = thumbnailStyle && thumbnailStyle.wrapper;
  const thumbnailAspectRatio = thumbnailStyle && thumbnailStyle.aspectRatio;
  const ThumbnailLoading = thumbnailStyle && thumbnailStyle.loadingComponent;

  return (
    <>
      {thumbnailStyle && (
        <Col {...thumbnailStyle.colSizes}>
          <ThumbnailWrapper data-qaid="thumbnail-wrapper">
            {detailsDate ? (
              <EventDate localStartsAt={detailsDate} displayCalendar={true} />
            ) : displayDeleteIcon || !toggleViewLargeImageModal ? (
              <ThumbnailComponent suppressClickable={true}>
                {!loading ? (
                  imageUrl && (
                    <LazyImgixImage
                      src={imageUrl}
                      sizes="100%"
                      aspectRatio={thumbnailAspectRatio}
                      dataQaid="details-header-image"
                    />
                  )
                ) : (
                  <ThumbnailLoading width="100%" height="100%" />
                )}
              </ThumbnailComponent>
            ) : (
              <ThumbnailComponent onClick={toggleViewLargeImageModal}>
                {!loading ? (
                  imageUrl && (
                    <LazyImgixImage
                      src={imageUrl}
                      sizes="100%"
                      aspectRatio={thumbnailAspectRatio}
                      dataQaid="details-header-image"
                    />
                  )
                ) : (
                  <ThumbnailLoading width="100%" height="100%" />
                )}
              </ThumbnailComponent>
            )}
            {!loading && displayDeleteIcon && (
              <DeleteIconContainer
                imageStyle={imageStyle}
                onClick={toggleDeleteImageConfirmationModal}
              >
                <DeleteIcon iconSize={18} />
              </DeleteIconContainer>
            )}
            {!loading && displayEditIcon && onEditIconClick && (
              <EditIconContainer imageStyle={imageStyle}>
                <EditIcon
                  iconSize={14}
                  onClick={() => onEditIconClick()}
                  dataQaid="image-edit-icon"
                />
              </EditIconContainer>
            )}
          </ThumbnailWrapper>
        </Col>
      )}
    </>
  );
};
