import styled, { css } from 'styled-components';

const ToggleText = styled.span`
  ${({ theme }) => css`
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 1.5px;
    color: ${theme.colors.green600};
    cursor: pointer;
  `}
`;

export default ToggleText;
