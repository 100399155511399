import React from 'react';
import { useIntl } from 'react-intl';

import { ObjectImagePurpose } from 'app/typings';
import { FormGroupContainer } from 'app/shared/components/atoms/FormContent';
import { FormGroupLabel } from 'app/shared/components/atoms/TypographyManualCSS';
import ImageList from 'app/shared/components/molecules/ImageList';
import { getCurrentImageForEachRatio } from 'app/admin/utils/images';

import {
  CollectionBasicInfoFormSectionHeader,
  SectionHeaderProps,
} from './components';

interface ContentProps {
  isNewCollection: boolean;
  images?: {
    header: ObjectImagePurpose;
  };
}

interface SectionProps extends ContentProps, SectionHeaderProps {}

const ImageContent: React.FC<ContentProps> = ({ isNewCollection, images }) => {
  const { formatMessage } = useIntl();

  if (isNewCollection) {
    return (
      <FormGroupLabel>
        {formatMessage({
          id: 'admin.collectionBasicInfo.form.imageDisclaimer.create',
        })}
      </FormGroupLabel>
    );
  }

  const imagesForDisplay = getCurrentImageForEachRatio(images?.header);

  if (imagesForDisplay.length > 0) {
    return <ImageList images={imagesForDisplay} />;
  }

  return (
    <FormGroupLabel>
      {formatMessage({
        id: 'admin.collectionBasicInfo.form.imageDisclaimer.edit',
      })}
    </FormGroupLabel>
  );
};

export const ImageSection: React.FC<SectionProps> = ({
  titleIntlId,
  descriptionIntlId,
  isNewCollection,
  images,
}) => {
  return (
    <>
      <CollectionBasicInfoFormSectionHeader
        titleIntlId={titleIntlId}
        descriptionIntlId={descriptionIntlId}
      />
      <FormGroupContainer>
        <ImageContent isNewCollection={isNewCollection} images={images} />
      </FormGroupContainer>
    </>
  );
};
