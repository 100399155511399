import * as Yup from 'yup';

const offerCreateFormSchema = (intl: any) =>
  Yup.object().shape({
    name: Yup.string().required(
      intl.formatMessage({
        id: 'form.requiredField',
      })
    ),
    city: Yup.string()
      .nullable()
      .required(
        intl.formatMessage({
          id: 'form.requiredField',
        })
      ),
  });

export default offerCreateFormSchema;
