import React, { useContext, useState } from 'react';
import { useIntl } from 'react-intl';

import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import { AuthContext } from 'app/shared/context/Auth';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { SendEmailTest } from 'app/admin/graphql/emailTest/mutationHooks';

import EmailTestForm from './EmailTestForm';
import EmailTestFormSchema from './EmailTestFormSchema';

interface EmailTestProps {
  emailTest: any;
}

const EmailTest: React.FC<EmailTestProps> = ({ emailTest }) => {
  const intl = useIntl();
  const { user } = useContext(AuthContext);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formInitialValues = {
    toEmail: user ? user.email : '',
    useCase: 'default',
  };

  const sendEmailTestAction = SendEmailTest();

  const handleSendEmailTest = useSubmitAction({
    beforeSubmit: () => setIsSubmitting(true),
    submitAction: sendEmailTestAction,
    submitVariables: (values: any) => ({
      email: emailTest.key,
      toEmail: values.toEmail,
      useCase: values.useCase,
    }),
    successMsg: intl.formatMessage(
      {
        id: 'admin.emailTest.successMessage',
      },
      {
        emailTestName: emailTest.name,
      }
    ),
    successMsgValuesForDisplay: {
      toEmail: (values: any) => values.toEmail,
    },
    failureMsg: intl.formatMessage({
      id: 'admin.emailTest.failureMessage',
    }),
    onSuccess: () => {
      setIsSubmitting(false);
    },
  });

  return (
    <GenericForm
      formInitialValues={formInitialValues}
      renderFormComponent={(renderProps: any) => (
        <EmailTestForm
          formikProps={renderProps.formikProps}
          isSubmitting={isSubmitting}
          emailTest={emailTest}
        />
      )}
      onSubmit={handleSendEmailTest}
      formSchema={EmailTestFormSchema(intl)}
      setIsSubmitting={setIsSubmitting}
      dataQaId="email-test-send-form"
      paddingTop="10px"
    />
  );
};

export default EmailTest;
