import React, { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { MainImage } from 'app/typings';
import { NotifyContext } from 'app/shared/context/Notify';
import { FormGroupContainer } from 'app/shared/components/atoms/FormContent';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import {
  createVenuePhoto,
  updateVenuePhoto,
} from 'app/admin/graphql/venues/mutationHooks';
import { DetailsImage } from 'app/admin/components/molecules/DetailsImage';
import { DetailsTitle } from 'app/admin/components/molecules/DetailsTitle';

import VenuePhotoUploadForm from './VenuePhotoUploadForm';
import VenuePhotoUploadFormSchema from './VenuePhotoUploadFormSchema';

interface Props {
  contentProps?: any;
  callbacks?: any;
  setFormSubmitAction: (func: Function) => void;
  setDisplayConfirmation: (set: boolean) => void;
  setIsSubmitting: (set: boolean) => void;
  setDisplaySubmitButton: (set: boolean) => void;
  setButtonConfigs?: (buttonConfigs: any) => void;
  navigateTo: (routeData: object) => void;
  hide: VoidFunction;
}

const DetailsFormMainContainer = styled.div`
  ${({ theme }) => css`
    padding: 10px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: left;
    border-radius: 3px;

    ${theme.media.md`
      padding: 20px 0px 0px 20px;
    `}
  `}
`;

const LeftBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    width: 100%;
    ${theme.media.xl`
        width: 80%;
      `};
  `}
`;

const StyledFormGroupContainer = styled(FormGroupContainer)`
  ${({ theme }) => css`
    ${theme.media.xs`
      width: 340px;
    `};

    ${theme.media.md`
      width: 750px;
    `};
  `}
`;

const VenueSetupPhotoUploader: React.FC<Props> = ({
  contentProps,
  setButtonConfigs,
  setIsSubmitting,
  setFormSubmitAction,
  navigateTo,
}) => {
  const intl = useIntl();

  const formInitialValues = {
    title: contentProps.venuePhoto?.title,
    description: contentProps.venuePhoto?.description,
    venuePhoto: contentProps.venuePhoto,
  };

  const notifyContext = useContext(NotifyContext);

  const [cropAction, setCropAction] = useState<
    () => Promise<Record<string, any>>
  >(
    () =>
      async function() {
        return {};
      }
  );

  return (
    <>
      <ModalContentContainer>
        <DetailsFormMainContainer>
          <LeftBox>
            <Grid>
              <DetailsImage
                imageUrl={contentProps.headerImageUrl}
                imageStyle="calendar"
                displayEditIcon={false}
                displayDeleteIcon={false}
              />
              <Col xs={5} sm={7} md={2} lg={2}>
                <DetailsTitle title={contentProps.venueName} />
              </Col>
            </Grid>
          </LeftBox>
          <Spacer mt={7} />
          <StyledFormGroupContainer>
            <GenericForm
              formInitialValues={formInitialValues}
              renderFormComponent={(renderProps: any) => {
                return (
                  <VenuePhotoUploadForm
                    venuePhotoToEdit={contentProps.venuePhoto}
                    formikProps={renderProps.formikProps}
                    modalRef={contentProps.modalRef}
                    //@ts-ignore
                    setCropAction={setCropAction}
                    setButtonConfigs={setButtonConfigs}
                    setFormSubmitAction={setFormSubmitAction}
                    setIsSubmitting={setIsSubmitting}
                  />
                );
              }}
              onSubmit={async (values: any) => {
                try {
                  const savedImage: MainImage = await cropAction();

                  const isEditingPhoto = contentProps.venuePhoto;

                  const requestBody = {
                    photoId: contentProps.venuePhoto?.id,
                    title: values.title,
                    description: values.description,
                    image: savedImage && {
                      contentType: savedImage.contentType,
                      filename: savedImage.filename,
                      id: savedImage.id,
                      size: savedImage.size,
                    },
                  };

                  const response = isEditingPhoto
                    ? await updateVenuePhoto(requestBody)
                    : await createVenuePhoto({
                        venueId: contentProps.venueId,
                        title: values.title,
                        description: values.description,
                        image: requestBody.image,
                      });

                  if (response.data) {
                    notifyContext.addMessage(
                      intl.formatMessage({
                        id:
                          'admin.venueBasicInfo.venuePhotoUpload.successMessage',
                      })
                    );
                    navigateTo({
                      routeName: 'venue-details',
                      routeProps: {
                        id: contentProps.venueId,
                        defaultOpenSection: 'photos',
                      },
                    });
                  } else {
                    notifyContext.addMessage(
                      intl.formatMessage({
                        id:
                          'admin.venueBasicInfo.venuePhotoUpload.errorMessage',
                      })
                    );
                  }

                  /* eslint-disable-next-line prettier/prettier */
                } catch (e) {
                  if (e instanceof Error) {
                    notifyContext.addMessage(
                      intl.formatMessage(
                        {
                          id:
                            'admin.venueBasicInfo.venuePhotoUpload.failureMessage',
                        },
                        {
                          description: e.message,
                        }
                      )
                    );
                  }
                }
              }}
              formSchema={VenuePhotoUploadFormSchema(intl)}
              dataQaId="venue-photo-upload-form"
            />
            <Spacer mt={8} />
          </StyledFormGroupContainer>
        </DetailsFormMainContainer>
      </ModalContentContainer>
    </>
  );
};

export default VenueSetupPhotoUploader;
