import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { errorMsgForField } from 'app/shared/utils/form';
import {
  FormGroupContainer,
  FormSectionTitle,
} from 'app/shared/components/atoms/FormContent';
import GenericFormContainer from 'app/shared/components/atoms/GenericFormContainer';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Textarea } from 'app/shared/components/atoms/Textarea';
import { Textfield } from 'app/shared/components/atoms/Textfield';
import FormGroup from 'app/shared/components/molecules/FormGroup';

interface FormProps {
  formikProps: any;
}

const BannerMessageTextarea = styled(Textarea)`
  display: block;
  width: 100%;
  height: 100px;
`;

const BannerLinkTextfield = styled(Textfield)`
  display: block;
  width: 60%;
`;

const SiteBannerEditForm: React.FC<FormProps> = ({ formikProps }) => {
  const intl = useIntl();

  const { setFieldValue, touched, errors, values } = formikProps;

  return (
    <GenericFormContainer dataQaId="site-banner-edit-form">
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.siteBanner.form.message',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          label={intl.formatMessage({
            id: 'admin.siteBanner.form.message',
          })}
          data-qaid="site-banner-edit-form-banner-message-formgroup"
          errorMsg={errorMsgForField('bannerMessage', touched, errors)}
          required
        >
          <BannerMessageTextarea
            data-qaid="site-banner-edit-form-banner-message-field"
            id="bannerMessage"
            name="bannerMessage"
            value={values.bannerMessage}
            onChange={(e: any) => {
              setFieldValue('bannerMessage', e.target.value);
            }}
            maxLength={150}
          />
        </FormGroup>
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.siteBanner.form.link',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="site-banner-edit-form-link-text-formgroup"
          label={intl.formatMessage({
            id: 'admin.siteBanner.form.linkText',
          })}
          errorMsg={errorMsgForField('bannerLinkText', touched, errors)}
          required
        >
          <BannerLinkTextfield
            data-qaid="site-banner-edit-form-link-text-field"
            id="bannerLinkText"
            name="bannerLinkText"
            value={values.bannerLinkText}
            onChange={(e: any) => {
              setFieldValue('bannerLinkText', e.target.value);
            }}
            maxLength={50}
          />
        </FormGroup>
        <Spacer mb={8} />
        <FormGroup
          data-qaid="site-banner-edit-form-link-formgroup"
          label={intl.formatMessage({
            id: 'admin.siteBanner.form.linkUrl',
          })}
          errorMsg={errorMsgForField('bannerLink', touched, errors)}
          required
        >
          <Textfield
            data-qaid="site-banner-edit-form-link-field"
            id="bannerLink"
            name="bannerLink"
            value={values.bannerLink}
            onChange={(e: any) => {
              setFieldValue('bannerLink', e.target.value);
            }}
            maxLength={250}
          />
        </FormGroup>
      </FormGroupContainer>
    </GenericFormContainer>
  );
};

export default SiteBannerEditForm;
