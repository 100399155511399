import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import styled, { css } from 'styled-components';

import { ContentWrapper } from 'app/shared/components/atoms/FlyoverContent';
import { Spacer } from 'app/shared/components/atoms/Spacer';

interface ManageRoleOptionProps {
  updateFlyoverState: Function;
  isSwappable: boolean;
}

const StyledLink = styled.a`
  ${({ theme }) => css`
    color: ${theme.colors.green600};
    font-size: 14px;
    font-weight: normal;

    &:hover {
      cursor: pointer;
      text-decoration: none;
    }
  `}
`;

const ManageRoleOption: React.FC<ManageRoleOptionProps> = ({
  updateFlyoverState,
  isSwappable,
}) => {
  return (
    <ContentWrapper>
      {isSwappable ? (
        <StyledLink
          data-qaid="swap-role-link"
          onClick={() => {
            updateFlyoverState('swapShiftWithAvailableCrewOption');
          }}
        >
          <FormattedHTMLMessage id="admin.crewPortal.crewRole.swapShiftWithAvailableCrew" />
        </StyledLink>
      ) : (
        <StyledLink
          data-qaid="remove-role-link"
          onClick={() => {
            updateFlyoverState('removeRoleForm');
          }}
        >
          <FormattedHTMLMessage id="admin.crewPortal.crewRole.removeFromRole" />
        </StyledLink>
      )}
      <Spacer mt={4} />
    </ContentWrapper>
  );
};

export default ManageRoleOption;
