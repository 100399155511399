import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { Email } from 'app/typings/emails';
import { Checkbox } from 'app/shared/components/atoms/CheckboxManualCSS';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import { FormGroupContainer } from 'app/shared/components/atoms/FormContent';
import GenericFormContainer from 'app/shared/components/atoms/GenericFormContainer';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { H3, H5 } from 'app/shared/components/atoms/TypographyManualCSS';
import FromEmailSettingsEditFormFields from 'app/admin/components/organisms/FromEmailSettingsEditFormFields';

interface FormProps {
  formikProps: any;
  emailGroupsAndEmails: any;
  emailGroupLabels: any;
  cityCachedSlug?: string;
  eventOrganizedAs?: string;
}

const EmailGroupContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 35px;
`;

const EmailGroupLabel = styled(H5)`
  margin-bottom: 5px;
`;

const CheckboxContainer = styled.div`
  ${({ theme }) => css`
    padding: ${theme.ruler[1]}px 0px;
  `}
`;

const FormTitle = styled(H3)`
  margin: 0px 0px;
`;

const StyledDottedLine = styled(DottedLine)`
  margin: 20px 0px 25px 0px;
`;

interface EmailGroupProps {
  setFieldValue: any;
  values: any;
  emailGroupLabel: string;
  emails: Email[];
}

const EmailGroup: React.FC<EmailGroupProps> = ({
  setFieldValue,
  values,
  emailGroupLabel,
  emails,
}) => {
  emails = emails.map((email: any) => ({
    id: email.id,
    isDisabled: email.isDisabled,
    emailName: email.emailName,
    emailGroup: email.emailGroup,
  }));

  return (
    <EmailGroupContainer>
      <EmailGroupLabel>{emailGroupLabel}</EmailGroupLabel>
      {emails.map((email: any, index: number) => (
        <CheckboxContainer key={index}>
          <Checkbox
            id={email.id}
            data-qaid={`event-email-${email.id}-setting`}
            checked={values.eventEmailSettings[`${email.id}`]}
            name={email.id}
            onChange={e =>
              setFieldValue(`eventEmailSettings.${email.id}`, e.target.checked)
            }
          >
            {email.emailName}
          </Checkbox>
        </CheckboxContainer>
      ))}
    </EmailGroupContainer>
  );
};

const EventEmailSettingsEditForm: React.FC<FormProps> = ({
  formikProps,
  emailGroupsAndEmails,
  emailGroupLabels,
  cityCachedSlug,
  eventOrganizedAs,
}) => {
  const intl = useIntl();

  const { setFieldValue, touched, errors, values } = formikProps;

  return (
    <GenericFormContainer dataQaId="event-email-settings-email-form">
      <FormTitle>
        {intl.formatMessage({
          id: 'admin.eventPlanner.emails.manageEmailSettingsTitle',
        })}
      </FormTitle>
      <StyledDottedLine />
      <Spacer mb={10} />
      <FromEmailSettingsEditFormFields
        setFieldValue={setFieldValue}
        touched={touched}
        errors={errors}
        values={values}
        cityCachedSlug={cityCachedSlug}
        overrideHelpTextType={
          eventOrganizedAs == 'o_and_o' ? 'event_o_and_o' : 'event_curator'
        }
      />
      <Spacer mb={6} />
      <FormGroupContainer>
        {intl.formatMessage({
          id: 'admin.eventPlanner.emails.manageEmailSettingsBelow',
        })}
        {Object.keys(emailGroupLabels).map(
          (emailGroup: string, index: number) =>
            emailGroupsAndEmails[emailGroup] && (
              <EmailGroup
                key={index}
                setFieldValue={setFieldValue}
                values={values}
                emailGroupLabel={emailGroupLabels[emailGroup]}
                emails={emailGroupsAndEmails[emailGroup]}
              />
            )
        )}
      </FormGroupContainer>
    </GenericFormContainer>
  );
};

export default EventEmailSettingsEditForm;
