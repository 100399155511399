import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Competition } from 'app/typings/competitions';
import { dateFormatter } from 'app/shared/utils/datetime';
import { snakecaseToTitleCase } from 'app/shared/utils/string';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import { GenericLink } from 'app/shared/components/atoms/LinkManualCSS';
import {
  DetailHeading as Heading,
  DetailSubheading as Subheading,
  DetailValueContainer as ValueContainer,
} from 'app/admin/components/atoms/DetailContent';
import { None } from 'app/admin/components/atoms/None';
import { EventLink, UserLink } from 'app/admin/components/molecules/EntityLink';

interface Props {
  sectionData: Competition;
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const fullDateFormat = () =>
  'shortWeekdayShortMonthDateHoursAndMinutesWithTimezone';

const SectionBasicInfo: React.FC<Props> = ({ sectionData }) => {
  const intl = useIntl();

  return (
    <MainContainer data-qaid="section-overview">
      <Grid cols={4}>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={1}>
          <Heading>
            {intl.formatMessage({
              id: 'admin.competitionBasicInfo.heading.closingDate',
            })}
          </Heading>
          <ValueContainer>
            {sectionData.closesAt &&
              dateFormatter(
                sectionData.closesAt,
                fullDateFormat(),
                sectionData.city?.timezone
              )}{' '}
            {intl.formatMessage({
              id: 'admin.competitionBasicInfo.heading.closingDateNote',
            })}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'shared.status',
            })}
          </Heading>
          <ValueContainer>
            {snakecaseToTitleCase(sectionData.status)}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'shared.city',
            })}
          </Heading>
          <ValueContainer>
            {sectionData.city && sectionData.city.title}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'shared.concert',
            })}
          </Heading>
          <ValueContainer>
            {sectionData.event ? (
              <EventLink event={sectionData.event} truncateLength={30} />
            ) : (
              <None />
            )}
          </ValueContainer>
        </Col>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={2}>
          <Heading>
            {intl.formatMessage({
              id: 'admin.competitionBasicInfo.heading.entries',
            })}
          </Heading>
          <ValueContainer>{sectionData.entriesCount}</ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.competitionBasicInfo.heading.winners',
            })}
          </Heading>
          <ValueContainer>{sectionData.winnersCount}</ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.competitionBasicInfo.heading.maxWinners',
            })}
          </Heading>
          <ValueContainer>{sectionData.maxWinners}</ValueContainer>
        </Col>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={3}>
          <Heading>
            {intl.formatMessage({
              id: 'admin.competitionBasicInfo.heading.uid',
            })}
          </Heading>
          <Subheading>
            {intl.formatMessage({
              id: 'admin.competitionBasicInfo.heading.uidSubheader',
            })}
          </Subheading>
          <ValueContainer>
            <GenericLink url={`/comp/${sectionData.uid}`}>
              {sectionData.uid}
            </GenericLink>
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.shared.creator',
            })}
          </Heading>
          <ValueContainer>
            {sectionData.creator ? (
              <UserLink user={sectionData.creator} truncateLength={30} />
            ) : (
              <None />
            )}
          </ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.shared.dateCreated',
            })}
          </Heading>
          <ValueContainer>
            {sectionData.createdAt &&
              dateFormatter(sectionData.createdAt, 'shortMonthDateAndYear')}
          </ValueContainer>
        </Col>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={4}></Col>
      </Grid>
    </MainContainer>
  );
};

export default SectionBasicInfo;
