import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { fromEntries } from 'app/shared/utils/object';
import { LinkButton } from 'app/shared/components/atoms/Button';
import { Checkbox } from 'app/shared/components/atoms/CheckboxManualCSS';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import { AlertIcon, CloseIcon } from 'app/shared/components/atoms/IconLibrary';
import { Icon } from 'app/shared/components/atoms/IconManualCSS';
import { Popper } from 'app/shared/components/atoms/PopperManualCSS';
import { Radio } from 'app/shared/components/atoms/Radio';
import { Textfield } from 'app/shared/components/atoms/Textfield';
import { Overline } from 'app/shared/components/atoms/TypographyManualCSS';
import DateInput from 'app/admin/components/atoms/DateInput';

interface DropdownOption {
  title: string;
  value: string;
  selected?: boolean;
  fields?: object;
}

interface OriginProps {
  vertical?: string;
  horizontal?: string;
}

interface Props {
  title: string;
  options: DropdownOption[];
  typeaheadFilterComponent?: React.ComponentType<any>;
  selectedValues: string[];
  onApply(values: string[]): void;
  groupBy?: string;
  groupById?: string;
  groupNames?: string[];
  searchBar?: boolean;
  disabled?: boolean;
  filterDependentOn?: string;
  dependentFilterSelectedValues?: string[];
  dependentFilterErrorIntlId?: string;
  customDisplayButton?: boolean;
  submitLabel?: string;
  isActive?: boolean;
  anchorOrigin?: OriginProps;
  transformOrigin?: OriginProps;
  hasDateInput?: boolean;
  selectSingleOption?: boolean;
  renderTrigger?: Function;
  asRadio?: boolean;
}

interface IconLinkProps {
  isOpen?: boolean;
}

interface DateInputValue {
  day?: number;
  month?: number;
  year?: number;
}

const CloseButton = styled.div`
  ${({ theme }) => css`
    i {
      font-size: 12px;
    }
    i::before {
      color: ${theme.colors.blueSmoke};
      cursor: pointer;
      font-size: 12px;
    }
    display: flex;
    flex-direction: column;
  `}
`;

const DropdownTrigger = styled(LinkButton)`
  ${({ theme }) => css`
    padding: 8px 20px;
    font-weight: 700;
    border-radius: 20px;
    background-color: ${theme.colors.silverSprings};
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    text-align: left;
    white-space: nowrap;

    &:focus {
      background-color: ${theme.colors.silverSprings};
      border: none;
    }

    &:hover {
      background-color: ${theme.colors.silverSprings};
      border: none;
    }
  `}
`;

const StyledLinkButton = styled(LinkButton)`
  ${({ theme }) => css`
    padding: 0;
    font-weight: 700;
    &:focus {
      background: none;
      border: none;
    }

    &:hover {
      border: none;
    }

    ${theme.media.xs`
      height: 50px;
    `}
  `}
`;

const DropdownWrapper = styled.div`
  display: inline-block;
`;

const Content = styled.div<{ wide: boolean }>`
  ${({ theme, wide }) => css`
    background-color: white;

    ${theme.media.xs`
      position: fixed;
      margin: 1.75rem 1rem;
      left: 0;
      top: 0;
      margin: 0;
      width: 100%;
      height: 100%;
    `}

    ${theme.media.md`
      position: relative;
      width: ${wide ? '290' : '240'}px;
      height: auto;
      padding: 0px;
      border-radius: 8px;
      box-shadow: 0 14px 28px 0px rgba(0, 0, 0, 0.25);
      z-index: 10;
      overflow: hidden;
    `}
  `}
`;

const TypeaheadFilterContainer = styled.div`
  ${({ theme }) => css`
    padding-top: ${theme.ruler[2]}px;
    padding-bottom: ${theme.ruler[5]}px;
  `}
`;

const OptionsContainer = styled.div`
  ${({ theme }) => css`
    overscroll-behavior: contain;
    position: relative;

    ${theme.media.xs`
      height: 100%;
      padding: ${theme.ruler[1]}px ${theme.ruler[4]}px;
    `}

    ${theme.media.md`
      height: auto;
      max-height: 200px;
      padding: ${theme.ruler[1]}px ${theme.ruler[4]}px;
    `}
  `}
`;

const DateInputAndOptionsContainer = styled.div<{ compact: boolean }>`
  ${({ compact, theme }) => css`
    overflow: auto;
    position: relative;

    ${theme.media.xs`
      max-height: calc(100% - 100px);
    `}

    ${theme.media.md`
      max-height: ${compact ? '200' : '500'}px;
    `}
  `}
`;

export const OptionContainer = styled.div`
  ${({ theme }) => css`
    padding: ${theme.ruler[2]}px 0px;
  `}
`;

const OptionsHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: ${theme.ruler[4]}px;
    padding-bottom: ${theme.ruler[2]}px;
  `}
`;

const OptionsFooter = styled.div`
  ${({ theme }) => css`
    width: 100%;
    border-top: 1px solid #f5f5f5;
    text-align: center;

    ${theme.media.xs`
      position: fixed;
      bottom: 0;
    `}

    ${theme.media.md`
      position: relative;
    `}
  `}
`;

const OptionGroupHeader = styled(Overline)`
  ${({ theme }) => css`
    margin: ${theme.ruler[4]}px 0px ${theme.ruler[4]}px 0px;
    color: ${theme.colors.green600};
  `}
`;

const OptionsSearchContainer = styled.div`
  ${({ theme }) => css`
    padding: ${theme.ruler[1]}px ${theme.ruler[4]}px;
  `}
`;

const OptionsSearchError = styled.div`
  ${({ theme }) => css`
    padding: ${theme.ruler[1]}px ${theme.ruler[4]}px;
  `}
  display: flex;
  flex-direction: row;
`;

const OptionsSearchErrorText = styled.div`
  margin-top: -5px;
  margin-left: 12px;
`;

const StyledIcon = styled(Icon)`
  ${({ theme }) => css`
  ${theme.media.xs`
    margin-bottom: 3px;
    padding-top: 11px;
  `}
  ${theme.media.sm`
    margin-bottom: 3px;
    padding-top: 11px;
  `}
  ${theme.media.md`
    margin-bottom: 3px;
    padding-top: 11px;
  `}
  ${theme.media.lg`
    margin-bottom: 5px;
    padding-top: 11px;
  `}
  `}
`;

const StyledTextfield = styled(Textfield)`
  ${({ theme }) => css`
    ${theme.media.xs`
      font-size: 16px;
    `};

    ${theme.media.md`
      font-size: 14px;
    `};
  `}
`;

const CaretIcon = styled(Icon)`
  margin-left: 8px;
  margin-right: 0px !important;
  margin-bottom: 4px;
`;

const StyledLabel = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.green600};
  `}
`;

const StyledDateInputWrapper = styled.div`
  margin: 16px;
`;

const IconLink = styled.a<IconLinkProps>`
  ${({ theme, isOpen }) => css`
    cursor: pointer;
    display: flex;
    flex-grow: 0;
    flex-direction: column;
    align-self: flex-end;
    align-items: center;
    justify-content: flex-end;
    ${theme.media.lg`
    padding-bottom: 5px;
  `}
    font-size: 10px;
    line-height: 150%;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: ${theme.colors.green600};
    height: ${theme.ruler[14]}px;
    width: ${theme.ruler[14]}px;
    border-radius: 10px;
    background: ${isOpen ? '#def2e7' : theme.colors.whiteDenim};
    margin: '6px 5px 0 0';
    i::before {
      color: ${theme.colors.green600};
    }
  `}
`;

const groupArray = function(arr: DropdownOption[], key: string) {
  return arr.reduce(function(vals: any, obj: DropdownOption) {
    if (obj.fields && obj.fields[key]) {
      (vals[obj.fields[key]] = vals[obj.fields[key]] || []).push(obj);
    }
    return vals;
  }, {});
};

const DropdownOptions: React.FC<Props> = ({
  title,
  options,
  typeaheadFilterComponent,
  selectedValues,
  onApply,
  groupBy,
  groupById,
  groupNames,
  searchBar,
  disabled,
  filterDependentOn,
  selectSingleOption = false,
  dependentFilterSelectedValues = [],
  dependentFilterErrorIntlId = 'admin.venueDirectory.needsCityOrBusinessOwnerFilter',
  customDisplayButton,
  submitLabel,
  isActive = false,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'right',
  },
  hasDateInput = false,
  renderTrigger,
  asRadio,
}) => {
  const intl = useIntl();
  const ref = React.useRef<HTMLDivElement>(null);
  const [active, setActive] = useState(isActive);
  const [optionState, setOptionState] = useState(
    fromEntries(selectedValues.map((val: string) => [val, true]))
  );
  const [searchQuery, setSearchQuery] = useState(null);

  const TypeaheadFilterComponent = typeaheadFilterComponent || undefined;

  const resetOptionState = useCallback(() => {
    setSearchQuery(null);
    setOptionState(
      fromEntries(selectedValues.map((val: string) => [val, true]))
    );
  }, [selectedValues]);

  // For date input initialize.
  const originalDateInputStartDate: DateInputValue = {
    day: undefined,
    month: undefined,
    year: undefined,
  };
  const originalDateInputEndDate: DateInputValue = {
    day: undefined,
    month: undefined,
    year: undefined,
  };

  const getOriginalDate = (isStartDate: boolean) => {
    let datesReturned = null;
    let candidateDate = null;
    const inputDate: DateInputValue = {
      day: undefined,
      month: undefined,
      year: undefined,
    };
    if (hasDateInput && selectedValues.some(sv => sv.includes('-'))) {
      const selectedValue = selectedValues[0];
      if (selectedValue) {
        if (selectedValue.includes('--')) {
          const dates = selectedValue.split('--');
          if (isStartDate) {
            candidateDate = dates[0];
          } else {
            candidateDate = dates[1];
          }
          datesReturned = candidateDate.split('-');
        } else {
          if (!isStartDate) {
            return inputDate;
          }
          datesReturned = selectedValue.split('-');
        }
        return {
          day: +datesReturned[2],
          month: +datesReturned[1],
          year: +datesReturned[0],
        };
      }
      return inputDate;
    }
    return inputDate;
  };

  const getOriginalStartDate = () => getOriginalDate(true);
  const getOriginalEndDate = () => getOriginalDate(false);

  const [dateInputStartDate, setDateInputStartDate] = useState(
    getOriginalStartDate()
  );
  const [dateInputEndDate, setDateInputEndDate] = useState(
    getOriginalEndDate()
  );

  const renderOptions = (
    options: DropdownOption[],
    title: string,
    selectSingleOption = false
  ) => {
    return options.map((opt: DropdownOption, index: number) => (
      <OptionContainer key={index} data-qaid="option-container">
        {asRadio ? (
          <Radio
            id={`${title}-option-${index}`}
            name={title}
            value={`${title}-option-${index}`}
            checked={optionState[opt.value]}
            onChange={() => {
              setOptionState({
                [opt.value]: true,
              });
            }}
          >
            {opt.title}
          </Radio>
        ) : (
          <Checkbox
            id={`${title}-option-${index}`}
            name={`${title}-option-${index}`}
            data-qaid={`${title}-option-${index}`}
            onChange={(event: any) => {
              if (event.target.checked) {
                setDateInputStartDate(originalDateInputStartDate);
                setDateInputEndDate(originalDateInputEndDate);
              }
              setOptionState({
                ...(selectSingleOption ? {} : optionState),
                [opt.value]: event.target.checked,
              });
            }}
            checked={
              optionState[opt.value] !== undefined
                ? optionState[opt.value]
                : selectSingleOption
                ? false
                : selectedValues.includes(opt.value)
            }
          >
            {opt.title}
          </Checkbox>
        )}
      </OptionContainer>
    ));
  };

  const shouldValueBeChecked = (
    currentOption: DropdownOption,
    options: DropdownOption[]
  ): boolean => {
    // If select all is clicked and selectedValues has all options, then do something different
    const selectedValuesHasAllOptions: boolean = options.every(option =>
      selectedValues.includes(option.value)
    );

    if (selectedValuesHasAllOptions) {
      // Uncheck select All when one option is unchecked
      if (currentOption.title === 'Select All') {
        return !!optionState[currentOption.value];
      }
      return !!optionState[currentOption.value];
    } else {
      return optionState[currentOption.value] !== undefined
        ? !!optionState[currentOption.value]
        : !!selectedValues.includes(currentOption.value);
    }
  };

  const renderOptionsWithSelectAll = (
    options: DropdownOption[],
    title: string
  ) => {
    return options.map((opt: DropdownOption, index: number) => (
      <OptionContainer key={index} data-qaid="option-container">
        <Checkbox
          id={`${title}-option-${index}`}
          name={`${title}-option-${index}`}
          onChange={(event: any) => {
            // When select All is checked or unchecked
            if (event.target.name === `${title}-option-0`) {
              // When checked, check all boxes in that group
              if (event.target.checked) {
                const defaultOptionState = {
                  ...optionState,
                };
                options.map(optionField => {
                  defaultOptionState[optionField.value] = true;
                });
                setOptionState(defaultOptionState);
              } else {
                // When unchecked, uncheck all options in that group
                const defaultOptionState = {
                  ...optionState,
                };
                options.map(optionField => {
                  delete defaultOptionState[optionField.value];
                });
                setOptionState(defaultOptionState);
              }
            } else {
              // When any other option other than selectAll is checked/unchecked
              const defaultOptionState = {
                ...optionState,
              };
              const selectedValuesHasAllOptions: boolean = options.every(
                option => optionState[option.value]
              );

              // If selectAll is checked then on unchecking any option should uncheck selectAll
              if (selectedValuesHasAllOptions) {
                const selectAllOption = options.find(option =>
                  option.value.endsWith('select-all')
                );
                if (selectAllOption) {
                  delete defaultOptionState[selectAllOption.value];
                }
              }
              setOptionState({
                ...defaultOptionState,
                [opt.value]: event.target.checked,
              });
            }
          }}
          checked={shouldValueBeChecked(opt, options)}
        >
          {opt.title}
        </Checkbox>
      </OptionContainer>
    ));
  };

  const renderSelectErrorMessage = (intlId: string) => {
    return (
      <OptionContainer key="dropdown-select-error" data-qaid="option-container">
        <OptionsSearchError>
          <AlertIcon iconSize={20} />
          <OptionsSearchErrorText>
            {intl.formatMessage({
              id: intlId,
            })}
          </OptionsSearchErrorText>
        </OptionsSearchError>
      </OptionContainer>
    );
  };

  const filterBySearch = (
    options: DropdownOption[],
    searchQuery: string | null
  ) => {
    if (searchQuery === null) {
      return options;
    }
    return options.filter((option: DropdownOption) => {
      return (
        option.title.toLowerCase().indexOf(searchQuery.trim().toLowerCase()) !==
        -1
      );
    });
  };

  const renderGroupedOptions = (
    options: DropdownOption[],
    groupBy: string,
    groupById?: string,
    groupNames?: string[],
    filterDependentOn?: string,
    dependentFilterSelectedValues?: string[]
  ) => {
    let groupedOptions = groupArray(options, groupBy);

    // Do this only if filterDependentOn exists and dependent filter has a value selected
    if (
      !!filterDependentOn &&
      !!groupById &&
      dependentFilterSelectedValues &&
      // @ts-ignore
      dependentFilterSelectedValues.length
    ) {
      groupedOptions = Object.keys(groupedOptions)
        .filter(groupedKey =>
          // @ts-ignore
          dependentFilterSelectedValues.includes(
            groupedOptions[groupedKey][0].fields[groupById]
          )
        )
        .reduce((obj, key) => {
          return {
            ...obj,
            [key]: groupedOptions[key],
          };
        }, {});
    }

    const sortedGroups = Object.entries(groupedOptions).sort((a: any, b: any) =>
      groupNames
        ? groupNames.indexOf(a[0]) - groupNames.indexOf(b[0])
        : a[0].localeCompare(b[0])
    );

    // Do this only if filterDependentOn exists and dependent filter has a value selected
    if (
      !!filterDependentOn &&
      dependentFilterSelectedValues &&
      // @ts-ignore
      dependentFilterSelectedValues.length
    ) {
      sortedGroups.map(sortedGroup => {
        // @ts-ignore
        sortedGroup[1].unshift({
          fields: {
            cityGroupName: sortedGroup[0],
          },
          title: 'Select All',
          value: `${sortedGroup[0].toLowerCase()}-select-all`,
        });
      });

      return sortedGroups.map((group: any, index: number) => (
        <React.Fragment key={index}>
          <OptionGroupHeader data-qaid="group-header">
            {group[0]}
          </OptionGroupHeader>
          {renderOptionsWithSelectAll(group[1], `group-${group[0]}`)}
        </React.Fragment>
      ));
    }

    return sortedGroups.map((group: any, index: number) => (
      <React.Fragment key={index}>
        <OptionGroupHeader data-qaid="group-header">
          {group[0]}
        </OptionGroupHeader>
        {renderOptions(group[1], `group-${group[0]}`)}
      </React.Fragment>
    ));
  };

  const getSubmitLabel = () => {
    if (submitLabel) {
      return submitLabel;
    }
    return intl.formatMessage({
      id: 'admin.dropdownOption.submit.applyFilter',
    });
  };

  useEffect(() => {
    const handleDocumentClick = (event: Event) => {
      if (
        ref.current &&
        !ref.current.contains(event.target as Node) &&
        !(event.target as HTMLElement).classList.contains(
          'suppress-outside-click'
        )
      ) {
        resetOptionState();
        setActive(false);
      }
    };
    document.addEventListener('mousedown', handleDocumentClick);
  }, [resetOptionState]);

  useEffect(resetOptionState, [selectedValues]);

  if (options.length === 0 && !TypeaheadFilterComponent) {
    return null;
  }

  const renderDropdownTitleButton = () => {
    if (customDisplayButton) {
      return (
        <IconLink
          isOpen={active}
          onClick={() => {
            setActive(!active);
          }}
          data-qaid="displayIconLink"
        >
          <StyledIcon name="display" size="14px" />
          <StyledLabel>
            {intl.formatMessage({
              id: 'list.displayFields',
            })}
          </StyledLabel>
        </IconLink>
      );
    } else if (renderTrigger) {
      return renderTrigger(() => setActive(!active));
    }
    return (
      <DropdownTrigger
        data-qaid="dropdown-button"
        disabled={disabled}
        onClick={() => {
          setActive(!active);
        }}
      >
        {title} <CaretIcon name="caretDown" size="12px" />
      </DropdownTrigger>
    );
  };

  const getDateInputUpdatedVal = (
    currentVal: DateInputValue,
    datePart: string,
    dateVal: number
  ): DateInputValue => {
    const retVal = { ...currentVal };
    switch (datePart) {
      case 'day':
        retVal.day = dateVal;
        return retVal;
      case 'month':
        retVal.month = dateVal;
        return retVal;
      case 'year':
        retVal.year = dateVal;
        return retVal;
    }
    return retVal;
  };

  const checkOffAllOptions = () => {
    const checkOffOptionState = {
      ...optionState,
    };
    options.map(optionField => {
      checkOffOptionState[optionField.value] = false;
    });
    setOptionState(checkOffOptionState);
  };

  const handleDateInputValueChange = (
    isStartDate: boolean,
    datePart: string,
    dateVal: number
  ) => {
    if (isStartDate) {
      setDateInputStartDate(
        getDateInputUpdatedVal(dateInputStartDate, datePart, dateVal)
      );
    } else {
      setDateInputEndDate(
        getDateInputUpdatedVal(dateInputEndDate, datePart, dateVal)
      );
    }
    checkOffAllOptions();
  };

  const padZero = (num: number, places: number) =>
    String(num).padStart(places, '0');

  const parseDateInputVal = (customDate: DateInputValue) => {
    if (
      customDate.day !== undefined &&
      customDate.month !== undefined &&
      customDate.year !== undefined
    ) {
      const concatenatedDate = [
        customDate.year,
        padZero(customDate.month, 2),
        padZero(customDate.day, 2),
      ].join('-');
      const parsedDate = Date.parse(concatenatedDate);
      if (!isNaN(parsedDate)) {
        return concatenatedDate;
      }
    }
    return '';
  };

  const isEmptyInputDate = (inputDate: any) => {
    return (
      inputDate.day == undefined &&
      inputDate.month == undefined &&
      inputDate.year == undefined
    );
  };

  const disableDateInputSubmit = () => {
    const parsedStartDate = parseDateInputVal(dateInputStartDate);
    const parsedEndDate = parseDateInputVal(dateInputEndDate);
    const dateRegex = /\d{4}-\d{2}-\d{2}/g;
    if (
      isEmptyInputDate(dateInputStartDate) &&
      !isEmptyInputDate(dateInputEndDate)
    ) {
      return true;
    }
    if (
      (parsedStartDate === '' || !parsedStartDate.match(dateRegex)) &&
      !isEmptyInputDate(dateInputStartDate)
    ) {
      return true;
    }
    if (
      (parsedEndDate === '' || !parsedEndDate.match(dateRegex)) &&
      !isEmptyInputDate(dateInputEndDate)
    ) {
      return true;
    }
    if (Date.parse(parsedEndDate) < Date.parse(parsedStartDate)) {
      return true;
    }
    return false;
  };

  const getAppliedVal = () => {
    if (hasDateInput) {
      const parsedStartDate = parseDateInputVal(dateInputStartDate);
      if (parsedStartDate !== '') {
        const parsedEndDate = parseDateInputVal(dateInputEndDate);
        if (parsedEndDate !== '') {
          return [`${parsedStartDate}--${parsedEndDate}`];
        }
        return [parsedStartDate];
      } else {
        // remove custom date input when select options are used.
        const optionValues = options.map(o => o.value);
        for (const optionKey of Object.keys(optionState)) {
          if (!optionValues.includes(optionKey)) {
            optionState[optionKey] = false;
          }
        }
      }
    }

    return Object.entries(optionState)
      .filter((entry: any) => entry[1])
      .map((entry: any) => entry[0]);
  };

  return (
    <DropdownWrapper ref={ref} data-qaid="dropdown-wrapper">
      {renderDropdownTitleButton()}
      {active && (
        <Popper
          offset={{ vertical: 10 }}
          anchorEl={ref}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          keepInViewPort={false}
          zIndex={null}
        >
          <Content
            wide={!!TypeaheadFilterComponent}
            data-qaid="dropdown-container"
          >
            <OptionsHeader>
              <Overline>{title}</Overline>
              {!customDisplayButton && (
                <CloseButton
                  onClick={() => {
                    setActive(false);
                    resetOptionState();
                  }}
                >
                  <CloseIcon iconSize={12} />
                </CloseButton>
              )}
            </OptionsHeader>
            <DateInputAndOptionsContainer compact={hasDateInput}>
              {hasDateInput && (
                <StyledDateInputWrapper>
                  <DottedLine />
                  <DateInput
                    label={intl.formatMessage({
                      id: 'admin.dropdownOption.dateInput.startDate',
                    })}
                    day={dateInputStartDate.day}
                    month={dateInputStartDate.month}
                    year={dateInputStartDate.year}
                    handleValueChange={handleDateInputValueChange}
                  />
                  <DateInput
                    label={intl.formatMessage({
                      id: 'admin.dropdownOption.dateInput.endDate',
                    })}
                    day={dateInputEndDate.day}
                    month={dateInputEndDate.month}
                    year={dateInputEndDate.year}
                    handleValueChange={handleDateInputValueChange}
                  />
                  <DottedLine />
                </StyledDateInputWrapper>
              )}
              {!filterDependentOn && searchBar && (
                <OptionsSearchContainer data-qaid="search-container">
                  <StyledTextfield
                    placeholder="Please type"
                    onChange={(event: any) => {
                      setSearchQuery(event.target.value);
                    }}
                    value={searchQuery || ''}
                    autoFocus={true}
                  />
                </OptionsSearchContainer>
              )}
              <OptionsContainer data-qaid="options-container">
                {TypeaheadFilterComponent ? (
                  <TypeaheadFilterContainer>
                    <TypeaheadFilterComponent
                      setSelectedValue={(value: string) => {
                        setOptionState({
                          ...optionState,
                          [value]: true,
                        });
                      }}
                    />
                  </TypeaheadFilterContainer>
                ) : !!filterDependentOn &&
                  !dependentFilterSelectedValues.length ? (
                  renderSelectErrorMessage(dependentFilterErrorIntlId)
                ) : groupBy ? (
                  renderGroupedOptions(
                    filterBySearch(options, searchQuery),
                    groupBy,
                    groupById,
                    groupNames,
                    filterDependentOn,
                    dependentFilterSelectedValues
                  )
                ) : (
                  renderOptions(
                    filterBySearch(options, searchQuery),
                    title,
                    selectSingleOption
                  )
                )}
              </OptionsContainer>
            </DateInputAndOptionsContainer>
            <OptionsFooter>
              <StyledLinkButton
                disabled={hasDateInput && disableDateInputSubmit()}
                onClick={() => {
                  resetOptionState();
                  setActive(false);
                  onApply(getAppliedVal());
                }}
                data-qaid="options-submit-button"
              >
                {getSubmitLabel()}
              </StyledLinkButton>
            </OptionsFooter>
          </Content>
        </Popper>
      )}
    </DropdownWrapper>
  );
};

export default DropdownOptions;
