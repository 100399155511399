import React, { useEffect } from 'react';
import styled from 'styled-components';

import { ToolTip, ToolTipContainer } from 'app/shared/components/atoms/ToolTip';
import IconInCircle from 'app/admin/components/atoms/IconInCircle';
import CalendarWithContent from 'app/admin/components/organisms/CalendarWithContent';
import { ReactComponent as Check1 } from 'icons/streamline-regular/interface-essential/form-validation/check-1.svg';
import { ReactComponent as Add } from 'icons/streamline-regular/interface-essential/remove-add/add.svg';

interface EventInfo {
  eventId: number;
  eventDate: string;
  toolTipText?: string;
}

interface Props {
  eventsInfo: EventInfo[];
  selectedEventIds: number[];
  onSelect: (eventId: number) => void;
  onUnselect: (eventId: number) => void;
  setEventIdsDisplayable?: (eventIds: number[]) => void;
  onMonthChange?: (
    firstDateOfMonthPeriod: string,
    lastDateOfMonthPeriod: string
  ) => void;
  disableSelectPastDates?: boolean;
  topContent?: any;
}

const DateContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-top: 3px;
  padding-bottom: 5px;
`;

const IconWrapperContainer = styled.div`
  margin: 0px 5px 5px 0px;
  cursor: pointer;
  overflow: visible;
`;

interface IconToolTipProps {
  renderToRight: boolean;
}

const IconToolTip = styled(ToolTip)<IconToolTipProps>`
  font-size: 8px;
  left: ${props => (props.renderToRight ? '100%' : '0%')};
  // Arrow below
  &:after {
    left: ${props => (props.renderToRight ? '32%' : '67%')};
  }
`;

interface IconWrapperProps {
  children: any;
  onClick: VoidFunction;
  toolTipText?: string;
  iconNum: number;
}

const IconWrapper: React.FC<IconWrapperProps> = ({
  children,
  onClick,
  toolTipText,
  iconNum,
}) => {
  return (
    <IconWrapperContainer onClick={onClick}>
      {toolTipText ? (
        <ToolTipContainer>
          {children}
          <IconToolTip renderToRight={!!(iconNum % 2 == 0)}>
            {toolTipText}
          </IconToolTip>
        </ToolTipContainer>
      ) : (
        <>{children}</>
      )}
    </IconWrapperContainer>
  );
};

interface EventIconProps {
  onClick: VoidFunction;
  toolTipText?: string;
  eventNum: number;
}

const EventAvailable: React.FC<EventIconProps> = ({
  onClick,
  toolTipText,
  eventNum,
}) => {
  return (
    <IconWrapper onClick={onClick} toolTipText={toolTipText} iconNum={eventNum}>
      <IconInCircle
        circleBackgroundColor="orangeCrush"
        iconName={Add}
        iconSize={12}
        circleSize={28}
        dataQaIdSuffix="available"
      />
    </IconWrapper>
  );
};

const EventSelected: React.FC<EventIconProps> = ({
  onClick,
  toolTipText,
  eventNum,
}) => {
  return (
    <IconWrapper onClick={onClick} toolTipText={toolTipText} iconNum={eventNum}>
      <IconInCircle
        circleBackgroundColor="blueChristmas"
        iconName={Check1}
        iconSize={12}
        circleSize={28}
        dataQaIdSuffix="selected"
      />
    </IconWrapper>
  );
};

const getDateContent = (
  eventsInfoList: EventInfo[] | undefined,
  selectedEventIds: number[],
  onSelect: (eventId: number) => void,
  onUnselect: (eventId: number) => void
) => {
  if (!eventsInfoList) {
    return null;
  }
  return (
    <DateContentContainer>
      {eventsInfoList.map((eventInfo: EventInfo, index: number) => {
        if (selectedEventIds.includes(eventInfo.eventId)) {
          return (
            <EventSelected
              onClick={() => onUnselect(eventInfo.eventId)}
              toolTipText={eventInfo.toolTipText}
              eventNum={index}
              key={index}
            />
          );
        } else {
          return (
            <EventAvailable
              onClick={() => onSelect(eventInfo.eventId)}
              toolTipText={eventInfo.toolTipText}
              eventNum={index}
              key={index}
            />
          );
        }
      })}
    </DateContentContainer>
  );
};

const getCalendarContent = (
  eventsByDateKey: any,
  selectedEventIds: number[],
  onSelect: (eventId: number) => void,
  onUnselect: (eventId: number) => void
) =>
  Object.keys(eventsByDateKey).reduce(
    (obj, dateKey) => ({
      ...obj,
      [dateKey]: getDateContent(
        eventsByDateKey[dateKey],
        selectedEventIds,
        onSelect,
        onUnselect
      ),
    }),
    {}
  );

const getDateKey = (dateStr: string) => dateStr.replace(/T.+/, '');

// Limitation of fixed-height date tiles in calendar - long-term we may work with design to revisit this
// so we can handle any number of events on a date
const maxNumEventsPerDate = 6;

const getEventsByDateKey = (eventsInfo: EventInfo[]) =>
  eventsInfo.reduce((obj, eventInfo) => {
    const dateKey = getDateKey(eventInfo.eventDate);
    const eventInfoList = obj[dateKey] || [];
    const newEventInfoList =
      eventInfoList.length < maxNumEventsPerDate
        ? eventInfoList
            .concat([eventInfo])
            .sort((a: EventInfo, b: EventInfo) => a.eventDate > b.eventDate)
        : eventInfoList;
    return {
      ...obj,
      [dateKey]: newEventInfoList,
    };
  }, {});

const EventCalendarSelector: React.FC<Props> = ({
  eventsInfo,
  selectedEventIds,
  onSelect,
  onUnselect,
  setEventIdsDisplayable,
  onMonthChange,
  disableSelectPastDates = false,
  topContent,
}) => {
  const eventsByDateKey = getEventsByDateKey(eventsInfo);

  const calendarContent = getCalendarContent(
    eventsByDateKey,
    selectedEventIds,
    onSelect,
    onUnselect
  );

  useEffect(() => {
    const eventIds = Object.values(eventsByDateKey)
      .flat()
      // @ts-ignore
      .map((eventInfo: EventInfo) => eventInfo.eventId);
    setEventIdsDisplayable && setEventIdsDisplayable(eventIds);
  }, []);

  return (
    <CalendarWithContent
      content={calendarContent}
      onMonthChange={onMonthChange}
      disablePastDates={disableSelectPastDates}
      topContent={topContent}
    />
  );
};

export default EventCalendarSelector;
