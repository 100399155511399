import styled, { css } from 'styled-components';

const CrewEventSurveyCard = styled.div<any>`
  ${({ theme, color }) => css`
    background: ${color || theme.colors.silverSprings};
    width: 100%;
    position: relative;
    border-radius: ${theme.borderRadius.default};
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
  `}
`;

const CrewEventSurveyCardTitle = styled.div<any>`
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
`;

const CrewEventSurveyCardSubTitle = styled.div<any>`
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  font-style: italic;
  margin-bottom: 16px;
  margin-top: -12px;
`;

export default CrewEventSurveyCard;
export { CrewEventSurveyCardSubTitle, CrewEventSurveyCardTitle };
