import React, { useCallback, useEffect, useState } from 'react';
import {
  GoogleMap,
  InfoWindow,
  Marker,
  MarkerClusterer,
} from '@react-google-maps/api';
import styled, { css } from 'styled-components';

import { useGoogleMapsApi } from 'app/shared/utils/useGoogleMapsApi';
import { withShadow } from 'app/shared/components/styleUtils/manualCSS/withShadow';
import { CloseIcon } from 'app/shared/components/atoms/IconLibrary';

export interface MarkerData {
  id: number;
  latitude: number;
  longitude: number;
  data?: object;
}

interface Props {
  'data-qaid'?: string;
  infoWindowStyle?: object;
  displayInfoWindows?: boolean;
  mapContainerStyle?: object;
  markers: MarkerData[];
  onMarkerClick?: (data: any) => void;
  renderClusterInfoWindowContent?: (
    clusterDataProps: any
  ) => JSX.Element | undefined;
  renderInfoWindowContent?: (markerDataProps: any) => JSX.Element | undefined;
  fetchMarkerData?: (inputData: object) => any;
}

const Container = styled.div`
  width: 100%;
  position: relative;
`;

const CloseButton = styled.div`
  ${({ theme }) => css`
    position: absolute;
    cursor: pointer;
    top: 15px;
    right: 15px;
    font-size: 12px;
    border-radius:

    i::before {
      color: ${theme.colors.blueSmoke};
    }
  `}
`;

const InfoWindowCloseButton = styled(CloseButton)`
  ${({ theme }) => css`
    top: 5px;
    right: 5px;
    padding: 4px 6px;
    border-radius: 15px;
    line-height: 16px;
    background-color: ${theme.colors.whiteDenim};
  `}
`;

const InfoWindowContentWrapper = styled.div`
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 2;
`;

const ClusterMakersPanelWrapper = styled(InfoWindowContentWrapper)`
  ${({ theme }) => css`
    ${withShadow({ depth: 4 })}
    background-color: ${theme.colors.whiteDenim};
    z-index: 1;
    width: 240px;
    height: 620px;
    border-radius: 12px;
  `}
`;

const MapWithMarkers: React.FC<Props> = ({
  'data-qaid': dataQaid,
  displayInfoWindows = false,
  infoWindowStyle = {},
  mapContainerStyle = {},
  markers,
  onMarkerClick,
  renderClusterInfoWindowContent,
  renderInfoWindowContent,
  fetchMarkerData,
}) => {
  const googleMapsScriptInitialized = useGoogleMapsApi({
    libraries: ['places'],
  });
  const [selectedMarkerId, setSelectedMarkerId] = useState<number | undefined>(
    undefined
  );
  const [selectedClusterCoordinates, setSelectedClusterCoordinates] = useState<
    any | undefined
  >(undefined);
  const [map, setMap] = useState<any | undefined>(undefined);
  const markerDataProps =
    fetchMarkerData &&
    fetchMarkerData({
      id: selectedMarkerId,
      skip: selectedMarkerId === undefined,
    });

  const defaultMapContainerStyle = { width: '100%', height: '650px' };
  const defaultInfoWindowStyle = {
    maxWidth: 420,
    maxHeight: 650,
    disableAutoPan: true,
    pixelOffset:
      googleMapsScriptInitialized &&
      new (globalThis as any).google.maps.Size(0, -30),
  };

  const infoWindowOptions = {
    ...defaultInfoWindowStyle,
    ...infoWindowStyle,
  };

  const resetMapBounds = useCallback(
    (loadedMap?: any) => {
      const mapToReset = loadedMap || map;
      const bounds = new (globalThis as any).google.maps.LatLngBounds();
      markers.map(marker => {
        bounds.extend(
          new (globalThis as any).google.maps.LatLng(
            marker.latitude,
            marker.longitude
          )
        );
      });
      mapToReset && mapToReset.fitBounds(bounds);
      loadedMap && setMap(loadedMap);
    },
    [map, markers]
  );

  useEffect(() => {
    googleMapsScriptInitialized && resetMapBounds();
  }, [googleMapsScriptInitialized, markers, resetMapBounds]);

  const handleClusterClick = (e: any) => {
    if (e.map.zoom >= 20) {
      setSelectedClusterCoordinates({
        lat: e.getMarkers()[0].position.lat(),
        lng: e.getMarkers()[0].position.lng(),
      });
    }
  };

  const renderClusterMakersPanel = () => {
    const markersInCluster = markers.filter(
      marker =>
        marker.latitude === selectedClusterCoordinates.lat &&
        marker.longitude === selectedClusterCoordinates.lng
    );

    if (!renderClusterInfoWindowContent) {
      return null;
    }

    return (
      <ClusterMakersPanelWrapper>
        <CloseButton
          onClick={() => {
            setSelectedClusterCoordinates(undefined);
          }}
        >
          <CloseIcon />
        </CloseButton>
        {renderClusterInfoWindowContent({
          markersInCluster,
          setSelectedMarkerId,
          onMarkerClick,
        })}
      </ClusterMakersPanelWrapper>
    );
  };

  return (
    <>
      {googleMapsScriptInitialized && markers && markers.length > 0 && (
        <Container data-qaid={dataQaid}>
          <GoogleMap
            id="google-map"
            mapContainerStyle={{
              ...defaultMapContainerStyle,
              ...mapContainerStyle,
            }}
            onLoad={resetMapBounds}
          >
            <MarkerClusterer
              onClick={handleClusterClick}
              options={{
                imagePath: `${process.env.PUBLIC_PATH}m`,
              }}
            >
              {clusterer =>
                markers.map((marker: MarkerData, index: number) => {
                  const iconOptions =
                    selectedMarkerId === marker.id
                      ? {
                          icon:
                            'http://maps.google.com/mapfiles/ms/icons/green-dot.png',
                        }
                      : {};
                  return (
                    <Marker
                      icon="http://maps.google.com/mapfiles/ms/icons/red-dot.png"
                      key={index}
                      position={{ lat: marker.latitude, lng: marker.longitude }}
                      clusterer={clusterer}
                      onClick={() => {
                        setSelectedMarkerId(marker.id);
                        onMarkerClick && onMarkerClick(marker);
                      }}
                      {...iconOptions}
                    >
                      {marker.id === selectedMarkerId &&
                        displayInfoWindows &&
                        renderInfoWindowContent && (
                          <InfoWindow
                            position={{
                              lat: marker.latitude,
                              lng: marker.longitude,
                            }}
                            options={infoWindowOptions}
                            onCloseClick={() => {
                              setSelectedMarkerId(undefined);
                              resetMapBounds();
                            }}
                          >
                            {renderInfoWindowContent(marker)}
                          </InfoWindow>
                        )}
                    </Marker>
                  );
                })
              }
            </MarkerClusterer>
          </GoogleMap>
          {selectedMarkerId && !displayInfoWindows && renderInfoWindowContent && (
            <InfoWindowContentWrapper>
              <InfoWindowCloseButton
                onClick={() => {
                  setSelectedMarkerId(undefined);
                }}
              >
                <CloseIcon />
              </InfoWindowCloseButton>
              {renderInfoWindowContent(markerDataProps)}
            </InfoWindowContentWrapper>
          )}
          {selectedClusterCoordinates && renderClusterMakersPanel()}
        </Container>
      )}
    </>
  );
};

export default MapWithMarkers;
