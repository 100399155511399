import { IntlShape } from 'react-intl';

export const sortOptions = (intl: IntlShape) => [
  {
    title: intl.formatMessage({
      id: 'admin.eventCollections.sort.mostRecentlyAdded',
    }),
    value: {
      by: 'created_at',
      direction: 'desc',
    },
  },
  {
    title: intl.formatMessage({
      id: 'admin.eventCollections.sort.leastRecentlyAdded',
    }),
    value: {
      by: 'created_at',
      direction: 'asc',
    },
  },
  {
    title: intl.formatMessage({
      id: 'admin.eventCollections.sort.collectionNameAtoZ',
    }),
    value: {
      by: 'title',
      direction: 'asc',
    },
  },
  {
    title: intl.formatMessage({
      id: 'admin.eventCollections.sort.collectionNameZtoA',
    }),
    value: {
      by: 'title',
      direction: 'desc',
    },
  },
  {
    title: intl.formatMessage({
      id: 'admin.eventCollections.sort.totalConcertsAscending',
    }),
    value: {
      by: 'events_count',
      direction: 'asc',
    },
  },
  {
    title: intl.formatMessage({
      id: 'admin.eventCollections.sort.totalConcertsDescending',
    }),
    value: {
      by: 'events_count',
      direction: 'desc',
    },
  },
];

export const staticOptions = (intl: IntlShape) => ({
  collectionType: [
    {
      value: 'manual',
      title: intl.formatMessage({
        id: 'admin.eventCollections.filter.collectionType.manual',
      }),
    },
    {
      value: 'automatic',
      title: intl.formatMessage({
        id: 'admin.eventCollections.filter.collectionType.auto',
      }),
    },
  ],
  status: [
    {
      value: 'active',
      title: intl.formatMessage({ id: 'shared.active' }),
    },
    {
      value: 'inactive',
      title: intl.formatMessage({ id: 'shared.inactive' }),
    },
    {
      value: 'archived',
      title: intl.formatMessage({ id: 'shared.archived' }),
    },
  ],
});
