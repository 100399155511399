import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { get } from 'app/shared/utils/get';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { CreateCuratorGroup } from 'app/admin/graphql/curatorGroups/mutationHooks';
import { DetailsFormMainContainer } from 'app/admin/components/atoms/DetailContent';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

import CuratorGroupBasicInfoForm from './CuratorGroupBasicInfoForm';
import CuratorGroupBasicInfoFormSchema from './CuratorGroupBasicInfoFormSchema';

interface Props {
  contentProps?: any;
  setFormSubmitAction?: (func: Function) => void;
  setDisplayConfirmation?: (set: boolean) => void;
  setIsSubmitting?: (set: boolean) => void;
  navigateTo: (routeData: object) => void;
}

const CuratorGroupCreate: React.FC<Props> = ({
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  navigateTo,
}) => {
  const intl = useIntl();

  const [validationErrors, setValidationErrors] = useState<object>({});

  const formInitialValues = {
    isNewCuratorGroup: true,
    name: '',
    city: null,
    curatorGroupType: '',
  };

  const createCuratorGroupAction = CreateCuratorGroup();

  const handleCreateCuratorGroup = useSubmitAction({
    submitAction: createCuratorGroupAction,
    submitVariables: (values: any) => ({
      name: values.name,
      cityId: values.city ? values.city.id : undefined,
      curatorGroupType: values.curatorGroupType,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.curatorGroupCreate.successMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.curatorGroupCreate.failureMessage',
    }),
    onSuccess: (response: any) => {
      navigateTo({
        routeName: 'curator-group-details',
        routeProps: {
          id: get(
            response.data,
            'createCuratorGroup.curatorGroup.id',
            undefined
          ),
        },
      });
    },
    onValidationError: validationErrors => {
      setValidationErrors(validationErrors);
    },
  });

  return (
    <ModalContentContainer>
      <DetailsFormMainContainer>
        <DetailsHeader
          title={intl.formatMessage({
            id: 'admin.curatorGroupCreate.title',
          })}
          isNewForm={true}
        />
        <GenericForm
          formInitialValues={formInitialValues}
          renderFormComponent={(renderProps: any) => (
            <CuratorGroupBasicInfoForm
              formikProps={renderProps.formikProps}
              validationErrors={validationErrors}
              isNewCuratorGroup={true}
            />
          )}
          onSubmit={handleCreateCuratorGroup}
          formSchema={CuratorGroupBasicInfoFormSchema(intl)}
          setFormSubmitAction={setFormSubmitAction}
          setDisplayConfirmation={setDisplayConfirmation}
          setIsSubmitting={setIsSubmitting}
          dataQaId="curator-group-create-form"
        />
      </DetailsFormMainContainer>
    </ModalContentContainer>
  );
};

export default CuratorGroupCreate;
