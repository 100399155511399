import styled, { css } from 'styled-components';

import { InputProps } from 'app/typings/input';
import { withTextfieldStyle } from 'app/shared/components/styleUtils/manualCSS/withTextfieldStyle';

export const Textarea = styled.textarea<InputProps>`
  ${withTextfieldStyle};

  ${({ theme }) => css`
    height: auto;
    min-height: ${theme.dimensions.input.height};
    resize: none;
    padding: 16px;
  `}
`;
