import React from 'react';
import styled from 'styled-components';

import { SimpleKeyValue } from 'app/typings/generics';
import { None } from 'app/admin/components/atoms/None';

interface Props {
  keysAndValues: SimpleKeyValue[];
  keyColWidth?: string;
}

const InfoListContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 3px;
  padding-bottom: 15px;
`;

const InfoRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding-bottom: 7px;
`;

interface InfoLeftProps {
  width: string;
}

const InfoLeft = styled.div<InfoLeftProps>`
  font-weight: 600;
  white-space: nowrap;
  width: ${props => props.width};
`;

const InfoRight = styled.div`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.4px;
  text-align: right;
  padding-left: 10px;
  width: 50px;
`;

export const SimpleKeyValueInfoList: React.FC<Props> = ({
  keysAndValues,
  keyColWidth = '50px',
}) => {
  return (
    <InfoListContainer>
      {keysAndValues.map(obj => (
        <InfoRow key={obj.key}>
          <InfoLeft width={keyColWidth}>{obj.key}</InfoLeft>
          <InfoRight>
            {obj.value || Number(obj.value) == 0 ? obj.value : <None />}
          </InfoRight>
        </InfoRow>
      ))}
    </InfoListContainer>
  );
};
