export enum UrgentEmailKeys {
  GUEST_CUSTOM = 'GuestCustom',
  GUEST_VENUE_CHANGED = 'GuestVenueChanged',
  GUEST_EVENT_CANCELLED = 'GuestEventCancelled',
}

enum RecipientType {
  UNCONFIRMED = 'unconfirmed_guest',
  CONFIRMED = 'confirmed_guest',
  ALL = 'all',
}

export const urgentEmailTypeOptions = [
  {
    title: 'Guest Custom Email',
    value: UrgentEmailKeys.GUEST_CUSTOM,
  },
  {
    title: 'Guest Venue Changed Email',
    value: UrgentEmailKeys.GUEST_VENUE_CHANGED,
  },
  {
    title: 'Guest Concert Cancelled Email',
    value: UrgentEmailKeys.GUEST_EVENT_CANCELLED,
  },
];

export const getRecipientTypeOptions = (
  totalAttendeesConfirmedCount: number,
  totalAttendeesUnconfirmedCount: number,
  totalAttendeesCount: number
) => [
  {
    title: `Unconfirmed - ${totalAttendeesUnconfirmedCount} user(s)`,
    value: RecipientType.UNCONFIRMED,
  },
  {
    title: `Confirmed - ${totalAttendeesConfirmedCount} user(s)`,
    value: RecipientType.CONFIRMED,
  },
  {
    title: `All - ${totalAttendeesCount} user(s)`,
    value: RecipientType.ALL,
  },
];
