import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { errorMsgForField } from 'app/shared/utils/form';
import { PrimaryButton } from 'app/shared/components/atoms/Button';
import GenericFormContainer from 'app/shared/components/atoms/GenericFormContainer';
import { Textfield } from 'app/shared/components/atoms/Textfield';
import Select from 'app/shared/components/molecules/SelectManualCSS';

import EmailTestFormGroup from './EmailTestFormGroup';

export const EMAIL_ON_ACID_ADDRESS =
  'sofar.runme.0.all@previews.emailonacid.com';

interface FormProps {
  formikProps: any;
  isSubmitting?: boolean;
  emailTest: any;
}

const MainContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: ${theme.colors.silverSprings};
    width: 350px;
    padding-bottom: 10px;
    margin-bottom: 35px;

    ${theme.media.md`
      flex-direction: row;
      width: 1020px;
    `}
  `}
`;

const EmailName = styled.div`
  ${({ theme }) => css`
    font-size: 20px;
    font-weight: 600;
    width: 100%;
    padding: 20px 30px 0px 15px;

    ${theme.media.md`
      width: 300px;
      padding: 90px 30px 0px 30px;
    `}
  `}
`;

const FormFields = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding: 20px 15px 0px 15px;

    ${theme.media.md`
      width: auto;
      padding: 20px 30px 0px 30px;
    `}
  `}
`;

const FormField = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding-bottom: 20px;

    ${theme.media.md`
      width: 400px;
    `}
  `}
`;

const SubmitButton = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding: 20px 15px 20px 15px;

    ${theme.media.md`
      width: auto;
      padding: 45px 30px 0px 30px;
    `}
  `}
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  ${({ theme }) => css`
    width: 100%;

    ${theme.media.md`
      width: 150px;
    `}
  `}
`;

const SubmittingMessage = styled.div`
  ${({ theme }) => css`
    font-size: 16px;
    font-weight: bold;
    color: ${theme.colors.green900};
    height: 50px;
    padding-top: 10px;
  `}
`;

const EmailTestForm: React.FC<FormProps> = ({
  formikProps,
  isSubmitting,
  emailTest,
}) => {
  const intl = useIntl();

  const defaultUseCaseName = emailTest.useCases.filter(
    (e: any) => e.key == 'default'
  )[0].name;

  const { submitForm, setFieldValue, touched, errors, values } = formikProps;

  const handleClickEmailClientEmulatorLink = () => {
    setFieldValue('toEmail', EMAIL_ON_ACID_ADDRESS);
  };

  return (
    <GenericFormContainer dataQaId="email-test-form">
      <MainContainer>
        <EmailName>{emailTest.name}</EmailName>
        <FormFields>
          <FormField>
            <EmailTestFormGroup
              label={intl.formatMessage({
                id: 'admin.shared.emails.to',
              })}
              errorMsg={errorMsgForField('toEmail', touched, errors)}
              onClickEmailClientEmulatorLink={
                handleClickEmailClientEmulatorLink
              }
            >
              <Textfield
                data-qaid="to-email"
                name="toEmail"
                id={`toEmail_${emailTest.key}`}
                value={values.toEmail}
                onChange={(e: any) => {
                  setFieldValue('toEmail', e.target.value);
                }}
                hasError={!!errorMsgForField('toEmail', touched, errors)}
                maxLength={100}
              />
            </EmailTestFormGroup>
          </FormField>
          <FormField>
            <EmailTestFormGroup
              label={intl.formatMessage({
                id: 'admin.emailTest.useCase',
              })}
              errorMsg={errorMsgForField('useCase', touched, errors)}
            >
              <Select
                data-qaid="use-case"
                name="useCase"
                id={`useCase_${emailTest.key}`}
                onChange={option => {
                  setFieldValue('useCase', option.value);
                }}
                options={emailTest.useCases.map((e: any) => ({
                  value: e.key,
                  label: e.name,
                }))}
                getOptionLabel={(opt: any) => opt.label}
                hasError={!!errorMsgForField('useCase', touched, errors)}
                placeholder={defaultUseCaseName}
                initialWidth="400px"
              />
            </EmailTestFormGroup>
          </FormField>
        </FormFields>
        <SubmitButton>
          {isSubmitting ? (
            <SubmittingMessage>
              {intl.formatMessage({
                id: 'admin.emailTest.sendingEmail',
              })}
            </SubmittingMessage>
          ) : (
            <StyledPrimaryButton
              data-qaid="email-test-btn"
              onClick={submitForm}
              loading={isSubmitting}
              type="submit"
              block
            >
              {intl.formatMessage({
                id: 'admin.emailTest.submit',
              })}
            </StyledPrimaryButton>
          )}
        </SubmitButton>
      </MainContainer>
    </GenericFormContainer>
  );
};

export default EmailTestForm;
