import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { CuratorGroup } from 'app/typings/curatorGroups';
import {
  getCurrencyRoundedFormInitialValue,
  getCurrencySubmitVariable,
} from 'app/shared/utils/form';
import { get } from 'app/shared/utils/get';
import useModal from 'app/shared/utils/useModal';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { UpdateCuratorGroup } from 'app/admin/graphql/curatorGroups/mutationHooks';
import { DetailsFormMainContainer } from 'app/admin/components/atoms/DetailContent';
import CuratorGroupUpdateConfirmationModal from 'app/admin/components/organisms/CuratorGroupDetails/CuratorGroupUpdateConfirmationModal';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

import CuratorGroupBasicInfoForm from './CuratorGroupBasicInfoForm';
import CuratorGroupBasicInfoFormSchema from './CuratorGroupBasicInfoFormSchema';

interface Props {
  contentProps: CuratorGroup;
  setFormSubmitAction: (func: Function) => void;
  setDisplayConfirmation: (set: boolean) => void;
  setIsSubmitting: (set: boolean) => void;
  navigateTo: (routeData: object) => void;
}

const CuratorGroupBasicInfoEdit: React.FC<Props> = ({
  contentProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  navigateTo,
}) => {
  const intl = useIntl();

  const [validationErrors, setValidationErrors] = useState<object>({});

  const curatorGroupName = get(contentProps, 'name', '');

  const formInitialValues = {
    isNewCuratorGroup: false,
    name: curatorGroupName,
    curatorGroupType: get(contentProps, 'curatorGroupType', ''),
    status: get(contentProps, 'status', ''),
    eventPrice: getCurrencyRoundedFormInitialValue(contentProps, 'eventPrice'),
    currencySymbol: get(contentProps, 'currencySymbol', '$'),
    city: get(contentProps, 'city', {}),
  };

  const [formValues, setFormValues] = useState(formInitialValues);

  const [
    curatorGroupConfirmationModal,
    toggleCuratorGroupConfirmationModal,
  ] = useModal();

  const updateCuratorGroupAction = UpdateCuratorGroup();

  const navigateToCuratorGroupDetailsBasicInfo = (
    curatorGroup: CuratorGroup
  ) => {
    navigateTo({
      routeName: 'curator-group-details',
      routeProps: {
        defaultOpenSection: 'basicInfo',
        ...curatorGroup,
        shouldRefetchCuratorGroup: true,
      },
    });
  };

  const handleUpdateCuratorGroup = useSubmitAction({
    submitAction: updateCuratorGroupAction,
    submitVariables: (values: any) => ({
      curatorGroupId: get(contentProps, 'id', undefined),
      name: values.name,
      curatorGroupType: values.curatorGroupType,
      status: values.status,
      eventPrice:
        values.curatorGroupType == 'commercial'
          ? getCurrencySubmitVariable(values.eventPrice, null)
          : null,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.curatorGroupBasicInfo.form.successMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.curatorGroupBasicInfo.form.failureMessage',
    }),
    onSuccess: response => {
      navigateToCuratorGroupDetailsBasicInfo(
        response.data.updateCuratorGroup.curatorGroup
      );
    },
    onValidationError: validationErrors =>
      setValidationErrors(validationErrors),
  });

  const handleConfirmCuratorGroupUpdate = (values: any) => {
    const oldEventPrice =
      get(contentProps, 'eventPrice', '') ||
      get(contentProps, 'eventPrice', '') == 0
        ? get(contentProps, 'eventPrice', '')
        : null;
    const newEventPrice = getCurrencySubmitVariable(values.eventPrice, null);

    if (oldEventPrice !== newEventPrice) {
      setFormValues(values);
      toggleCuratorGroupConfirmationModal();
    } else {
      handleUpdateCuratorGroup(values);
    }
  };

  const handleCancelCuratorGroupUpdate = () => {
    curatorGroupConfirmationModal.hide();
    navigateToCuratorGroupDetailsBasicInfo(contentProps);
  };

  return (
    <>
      <ModalContentContainer>
        <DetailsFormMainContainer>
          <DetailsHeader title={curatorGroupName} />
          <GenericForm
            formInitialValues={formInitialValues}
            renderFormComponent={(renderProps: any) => (
              <CuratorGroupBasicInfoForm
                formikProps={renderProps.formikProps}
                validationErrors={validationErrors}
              />
            )}
            onSubmit={handleConfirmCuratorGroupUpdate}
            formSchema={CuratorGroupBasicInfoFormSchema(intl)}
            setFormSubmitAction={setFormSubmitAction}
            setDisplayConfirmation={setDisplayConfirmation}
            setIsSubmitting={setIsSubmitting}
            dataQaId="curator-group-basic-info-section-edit-form"
          />
        </DetailsFormMainContainer>
      </ModalContentContainer>
      {curatorGroupConfirmationModal.isShowing && (
        <CuratorGroupUpdateConfirmationModal
          cancelCuratorGroupUpdate={handleCancelCuratorGroupUpdate}
          updateCuratorGroup={() => handleUpdateCuratorGroup(formValues)}
        />
      )}
    </>
  );
};

export default CuratorGroupBasicInfoEdit;
