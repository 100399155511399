import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { ManualCSSColors } from 'app/shared/theme';
import { useCurrentTheme } from 'app/shared/theme';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Spinner } from 'app/shared/components/atoms/SpinnerManualCSS';
import { StreamlineIcon } from 'app/shared/components/atoms/StreamlineIcon';
import IconInCircle from 'app/admin/components/atoms/IconInCircle';
import { TagElement } from 'app/admin/components/molecules/TagElementList';
import { ReactComponent as Pencil } from 'icons/streamline-regular/interface-essential/edit/pencil.svg';

interface Props {
  sectionsConfig: object;
  defaultOpenSection?: string;
}

interface SectionConfig {
  title: string;
  icon: any;
  iconColor: ManualCSSColors;
  sectionComponent: React.ComponentType<any>;
  displayEditIcon: boolean;
  loading: any;
  data: any;
  extraData?: object;
  callbacks: any;
  iconCircle?: boolean;
  titleTags?: any[];
}

const MainContainer = styled.div`
  width: 100%;
  padding: 50px 0px 30px 0px;
  display: flex;
  flex-direction: column;
  align-items: left;
`;

const InfoSectionWrapper = styled.div``;

const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
`;

const InfoSectionHeader = styled.div`
  ${({ theme }) => css`
    padding: 8px 20px 8px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;

    ${theme.media.xs`
      padding: 0px 5px 0px 0px;
    `};

    ${theme.media.md`
      padding: 8px 20px 8px 20px;
    `};
  `}
`;

const InfoSectionIcon = styled.a`
  ${({ theme }) => css`
    cursor: pointer;
    padding: 5px 5px 5px 0px;

    ${theme.media.md`
    padding: 5px 5px 5px 5px;
    `}
  `}
`;

const InfoSectionTitle = styled.a`
  cursor: pointer;
  display: inline-block;
  padding: 5px 5px 5px 7px;
  font-weight: 600;
`;

const SectionContainer = styled.div`
  ${({ theme }) => css`
    padding: 15px 15px 45px 48px;

    ${theme.media.md`
      padding: 15px 15px 45px 25px;
    `};
  `}
`;

const PointerA = styled.a`
  cursor: pointer;
`;

const StyledTagElement = styled(TagElement)`
  ${({ theme }) => css`
    margin-bottom: 0;
    border-radius: 2px;

    ${theme.media.xs`
      margin-bottom: 5px;
      margin-top: -5px;
    `};

    ${theme.media.sm`
      margin-bottom: 0px;
      margin-top: 0px;
    `};
  `}
`;

const TagElementsWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    position: relative;

    ${theme.media.xs`
      position: absolute;
      flex-direction: row;
      width: 85%;
      justify-content: flex-end;
      align-self: flex-start;
    `};

    ${theme.media.sm`
      position: relative;
      width: unset;
      align-self: center;
    `};
  `}
`;

const stringToDashcase = (str: string) => {
  return str.replace(/\s+/g, '-').toLowerCase();
};

const renderTagElements = (tagElements: any[] = []) => {
  return (
    <TagElementsWrapper>
      <Spacer ml={4} />
      {tagElements.map((tagElement: any, i: number) => (
        <React.Fragment key={i}>
          <StyledTagElement
            tagElementColor={tagElement.tagElementColor}
            textColor="#fff"
            data-qaid={`tag-element-${i}`}
          >
            {tagElement.name}
          </StyledTagElement>
          <Spacer mr={1} />
        </React.Fragment>
      ))}
    </TagElementsWrapper>
  );
};

const renderInfoSection = (
  sectionKey: string,
  sectionConfig: SectionConfig,
  handleToggleSection: Function,
  sectionState: any,
  theme: any,
  index: number,
  isLastSection?: boolean
) => {
  const SectionComponent = sectionConfig.sectionComponent;
  return (
    <InfoSection key={index}>
      <DottedLine />
      <InfoSectionHeader>
        <InfoSectionIcon
          data-qaid={`${stringToDashcase(
            sectionConfig.title
          )}-accordion-toggle-icon`}
          onClick={() => {
            handleToggleSection(sectionKey);
          }}
        >
          {sectionConfig.iconCircle ? (
            <IconInCircle
              circleBackgroundColor="none"
              iconSize={20}
              iconName={sectionConfig.icon}
              iconColor={sectionConfig.iconColor}
              borderCircle={true}
              dataQaIdSuffix="accordion"
            />
          ) : (
            <StreamlineIcon
              icon={sectionConfig.icon}
              size={40}
              stroke={theme.colors[sectionConfig.iconColor || 'whiteDenim']}
            />
          )}
        </InfoSectionIcon>
        <InfoSectionTitle
          data-qaid={`${stringToDashcase(sectionConfig.title)}-section-title`}
          onClick={() => {
            handleToggleSection(sectionKey);
          }}
        >
          {sectionConfig.title}
        </InfoSectionTitle>
        {sectionState[sectionKey] == 'open' &&
          sectionConfig.displayEditIcon &&
          !sectionConfig.loading &&
          sectionConfig.callbacks.toggleSectionEditModal && (
            <PointerA
              onClick={sectionConfig.callbacks.toggleSectionEditModal}
              data-qaid={`${stringToDashcase(
                sectionConfig.title
              )}-section-edit-icon`}
            >
              <StreamlineIcon
                icon={Pencil}
                size={13}
                stroke={theme.colors.green600}
              />
            </PointerA>
          )}
        {sectionConfig.titleTags &&
          !sectionConfig.loading &&
          renderTagElements(sectionConfig.titleTags)}
      </InfoSectionHeader>
      {sectionState[sectionKey] == 'open' && (
        <SectionContainer>
          {sectionConfig.loading ? (
            <Spinner />
          ) : (
            <SectionComponent
              sectionData={sectionConfig.data}
              extraData={sectionConfig.extraData}
              callbacks={sectionConfig.callbacks}
            />
          )}
        </SectionContainer>
      )}
      {isLastSection && <DottedLine />}
    </InfoSection>
  );
};

const defaultSectionState = (
  sectionsConfig: object,
  defaultOpenSection?: string
) =>
  Object.keys(sectionsConfig).reduce(
    (obj, key) => ({
      ...obj,
      [key]:
        defaultOpenSection && defaultOpenSection === key ? 'open' : 'closed',
    }),
    {}
  );

const scrollToRef = (ref: any) => {
  if (ref) {
    ref.scrollIntoView && ref.scrollIntoView();
  }
};

const AccordionSections: React.FC<Props> = ({
  sectionsConfig,
  defaultOpenSection,
}) => {
  const theme = useCurrentTheme();

  const [sectionState, setSectionState] = useState(
    defaultSectionState(sectionsConfig, defaultOpenSection)
  );

  const sectionRefs = useRef({}).current;

  const handleToggleSection = (sectionKey: string) => {
    let newState = defaultSectionState(sectionsConfig);
    newState[sectionKey] =
      sectionState[sectionKey] == 'open' ? 'closed' : 'open';
    setSectionState(newState);
  };

  const currentlyOpenSectionKey = Object.keys(sectionState).find(
    (key: string) => sectionState[key] === 'open'
  );

  useEffect(() => {
    if (Object.keys(sectionState).length > 0) {
      // @ts-ignore
      scrollToRef(sectionRefs[currentlyOpenSectionKey]);
    }
  }, [currentlyOpenSectionKey, defaultOpenSection, sectionRefs, sectionState]);

  return (
    <MainContainer>
      {Object.entries(sectionsConfig).map(
        ([sectionKey, sectionConfig], index) => (
          <InfoSectionWrapper
            key={index}
            ref={el => (sectionRefs[sectionKey] = el)}
          >
            {renderInfoSection(
              sectionKey,
              sectionConfig,
              handleToggleSection,
              sectionState,
              theme,
              index,
              index + 1 === Object.keys(sectionsConfig).length
            )}
          </InfoSectionWrapper>
        )
      )}
    </MainContainer>
  );
};

export default AccordionSections;
