import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Artist } from 'app/typings/artists';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import ArtistCircleThumbnailAndText from 'app/admin/components/molecules/ArtistCircleThumbnailAndText';
import ArtistTypeahead from 'app/admin/components/molecules/ArtistTypeahead';

interface Props {
  setFieldValue: any;
  touched: any;
  errors: any;
  values: any;
}

const MainContainer = styled.div`
  padding: 30px 0px 10px 0px;
`;

const SelectLabel = styled.div`
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 12px;
`;

const SelectContainer = styled.div`
  max-width: 500px;
  padding-bottom: 18px;
`;

const ArtistContainer = styled.div`
  height: 80px;
  padding-bottom: 20px;
`;

const ArtistMergeSelectArtists: React.FC<Props> = ({
  setFieldValue,
  values,
}) => {
  const intl = useIntl();

  return (
    <>
      <DottedLine />
      <MainContainer>
        <SelectLabel>
          {intl.formatMessage({
            id: 'admin.artistMerge.modal.artist1',
          })}
        </SelectLabel>
        <SelectContainer>
          <ArtistTypeahead
            setSelectedArtist={(artist: Artist | undefined) => {
              if (
                !(values.artist2 && artist && values.artist2.id == artist.id)
              ) {
                setFieldValue('artist1', artist);
              }
            }}
            clearFieldOnSelect={true}
            showArtistEmails={true}
          />
        </SelectContainer>
        <ArtistContainer>
          {values.artist1 && (
            <ArtistCircleThumbnailAndText
              artist={values.artist1}
              onRemove={() => {
                setFieldValue('artist1', undefined);
              }}
            />
          )}
        </ArtistContainer>
        <SelectLabel>
          {intl.formatMessage({
            id: 'admin.artistMerge.modal.artist2',
          })}
        </SelectLabel>
        <SelectContainer>
          <ArtistTypeahead
            setSelectedArtist={(artist: Artist | undefined) => {
              if (
                !(values.artist1 && artist && values.artist1.id == artist.id)
              ) {
                setFieldValue('artist2', artist);
              }
            }}
            clearFieldOnSelect={true}
            showArtistEmails={true}
          />
        </SelectContainer>
        <ArtistContainer>
          {values.artist2 && (
            <ArtistCircleThumbnailAndText
              artist={values.artist2}
              onRemove={() => {
                setFieldValue('artist2', undefined);
              }}
            />
          )}
        </ArtistContainer>
      </MainContainer>
    </>
  );
};

export default ArtistMergeSelectArtists;
