import styled, { css } from 'styled-components';

import { withSpacing } from 'app/shared/components/styleUtils/manualCSS/withSpacing';
import { H3 } from 'app/shared/components/atoms/TypographyManualCSS';

export const FormSectionTitle = styled(H3)`
  text-align: left;
  margin-bottom: 15px;

  ${withSpacing};
`;

export const FormGroupContainer = styled.div`
  ${({ theme }) => css`
    max-width: 700px;
    padding: 20px;

    ${theme.media.md`
      padding: 30px;
    `}

    background-color: ${theme.colors.silverSprings};
    margin-bottom: 35px;
  `}
`;

export const FormHelpText = styled.div`
  font-size: 16px;
  font-style: italic;
  line-height: 24px;
  padding-bottom: 17px;
`;
