import React, { createContext, useMemo, useState } from 'react';

interface Props {
  children: any;
}

interface FlyoverContextValues {
  isOpen: boolean;
  openFlyover: () => void;
  closeFlyover: () => void;
}

export const FlyoverContext = createContext<FlyoverContextValues>({
  isOpen: false,
  openFlyover: () => {
    throw new Error('FlyoverContext should be used under provider');
  },
  closeFlyover: () => {
    throw new Error('FlyoverContext should be used under provider');
  },
});

const FlyoverProvider: React.FC<Props> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openFlyover = () => {
    setIsOpen(true);
  };

  const closeFlyover = () => {
    setIsOpen(false);
  };

  const contextValue = useMemo(
    () => ({
      isOpen,
      openFlyover,
      closeFlyover,
    }),
    [isOpen]
  );

  return (
    <FlyoverContext.Provider value={contextValue}>
      {children}
    </FlyoverContext.Provider>
  );
};

export default FlyoverProvider;
