import React from 'react';
import styled, { css } from 'styled-components';

import { Radio } from 'app/shared/components/atoms/Radio';
import { Spacer } from 'app/shared/components/atoms/Spacer';

interface RadioGroupOption {
  title: string;
  value: any;
  selected?: boolean;
  disabled?: boolean;
  note?: string;
}

interface Props {
  name: string;
  options: RadioGroupOption[];
  selectedValue: any;
  onChange: (value: any) => void;
  disabled?: boolean;
  spaceAboveGroup?: boolean;
  spaceBetweenOptions?: number;
  subfields?: object;
}

const RadioGroupContainer = styled.div`
  width: 100%;
`;

const StyledRadio = styled(Radio)`
  white-space: nowrap;
`;

const Note = styled.div`
  font-size: 12px;
  white-space: nowrap;
  margin: 5px 0px 0px 31px;
`;

const SubfieldContainer = styled.div<{ indented: boolean }>`
  ${({ indented }) => css`
    margin-left: ${indented ? '30px' : '0'};
  `}
`;

const renderSpacer = (
  optionIndex: number,
  spaceAboveGroup: boolean,
  spaceBetweenOptions?: number
) => {
  if (optionIndex == 0 && !spaceAboveGroup) {
    return;
  }

  return <Spacer mt={spaceBetweenOptions || 4} />;
};

const RadioGroup: React.FC<Props> = ({
  name,
  options,
  selectedValue,
  onChange,
  spaceAboveGroup = true,
  spaceBetweenOptions,
  disabled = false,
  subfields = {},
}) => {
  if (options.length === 0) {
    return null;
  }

  return (
    <RadioGroupContainer data-qaid={`${name}-radio-group-container`}>
      {options.map((opt: RadioGroupOption, index: number) => (
        <React.Fragment key={index}>
          {renderSpacer(index, spaceAboveGroup, spaceBetweenOptions)}
          <StyledRadio
            id={`${name}-option-${index}`}
            data-qaid={`${name}-option-${index}`}
            name={`${name}-option-${index}`}
            value={opt.value}
            checked={opt.value === selectedValue}
            onChange={() => {
              onChange(opt.value);
            }}
            disabled={opt.disabled || disabled || undefined}
          >
            {opt.title}
          </StyledRadio>
          {opt.note && <Note>{opt.note}</Note>}
          {opt.value === selectedValue && subfields[opt.value] && (
            <SubfieldContainer indented={subfields[opt.value].indented}>
              {subfields[opt.value].component}
            </SubfieldContainer>
          )}
        </React.Fragment>
      ))}
    </RadioGroupContainer>
  );
};

export default RadioGroup;
