import React, { useCallback, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { fromEntries } from 'app/shared/utils/object';
import useModal from 'app/shared/utils/useModal';

interface Props {
  config: ListPageConfig;
  analytics?: object;
  children: any;
}

interface SortValue {
  by: string;
  direction: string;
}

interface HandleRemoveOrphanDependentFiltersProps {
  parentFilter: string;
  dependentFilter: string;
  dependentFilterOptions: any[];
}

interface ListPageConfig {
  filterNames: string[];
  defaultSort: SortValue;
  defaultDisplayFields?: string[];
  defaultViewMode?: string;
  viewModes?: string[];
  textSearchParamName?: string;
  idParamName?: string;
  genericModalIdParamName?: string;
  useDefaultSort?: boolean;
}

interface OneClickFilterStateValues {
  isEnabled?: boolean;
}

export interface ListPageContextValues {
  page: number;
  sortState: SortValue;
  displayFields: string[] | undefined | null;
  viewModeState: string | undefined | null;
  filterState: { [key: string]: string[] };
  filterListVariable: (variableName: string) => string | undefined;
  filterLabelTitleMapping: object;
  oneClickFilterState: OneClickFilterStateValues;
  textSearchState: string | undefined | null;
  detailsModal: any;
  detailData: object;
  genericModal: any;
  genericData: object;
  scrollPosition: number;
  handlePageChange: (p: number) => void;
  handleSortChange: (sortValue: SortValue) => void;
  handleDisplayFieldsChange: (fields: string[]) => void;
  handleViewModeChange: (viewMode: string) => void;
  handleFilterChange: (filterName: string, opts?: string[]) => void;
  handleRemoveFilter: (labelsToRemove: Map<string, string[]>) => void;
  handleRemoveOrphanDependentFilters: (
    params: HandleRemoveOrphanDependentFiltersProps
  ) => void;
  handleOneClickFilterChange: (isEnabled: boolean) => void;
  handleTextSearch: (searchString: string) => void;
  handleTextSearchLabelClose: () => void;
  toggleDetailsModalAndSetDetailData: (detailData: any) => void;
  toggleGenericModalAndSetGenericData: (genericData: any) => void;
  handleResetFilters: () => void;
  refetchListPage: () => void;
  setupFilterLabelTitleMapping: (filterNamesAndOptions: object) => void;
  setOnAnyFilterChange: (onAnyFilterChange: Function) => void;
  setRefetchListPage: (refetchListPageFunc: Function) => void;
  setViewModes: (viewModes: string[]) => void;
  updateDetailsModal: () => void;
  updateGenericModal: () => void;
  updateScrollPositionOnPage: () => void;
  updatePageUrl: () => void;
}

export const ListPageContext = React.createContext<ListPageContextValues>({
  page: 1,
  sortState: { by: '', direction: '' },
  displayFields: undefined,
  viewModeState: undefined,
  filterState: {},
  filterListVariable: () => undefined,
  filterLabelTitleMapping: {},
  oneClickFilterState: {},
  textSearchState: undefined,
  detailsModal: undefined,
  detailData: {},
  genericModal: undefined,
  genericData: {},
  scrollPosition: 0,
  handlePageChange: () => undefined,
  handleSortChange: () => undefined,
  handleViewModeChange: () => undefined,
  handleFilterChange: () => undefined,
  handleRemoveFilter: () => undefined,
  handleRemoveOrphanDependentFilters: () => undefined,
  handleOneClickFilterChange: () => undefined,
  handleTextSearch: () => undefined,
  handleTextSearchLabelClose: () => undefined,
  toggleDetailsModalAndSetDetailData: () => undefined,
  toggleGenericModalAndSetGenericData: () => undefined,
  handleResetFilters: () => undefined,
  refetchListPage: () => undefined,
  setupFilterLabelTitleMapping: () => undefined,
  setOnAnyFilterChange: () => undefined,
  setRefetchListPage: () => undefined,
  setViewModes: () => undefined,
  updateDetailsModal: () => undefined,
  updateGenericModal: () => undefined,
  updateScrollPositionOnPage: () => undefined,
  updatePageUrl: () => undefined,
  handleDisplayFieldsChange: () => undefined,
});

export const ListPage: React.FC<Props> = ({ config, analytics, children }) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  //////////////////////////////
  // PAGING
  //////////////////////////////

  const initialPageState = parseInt(searchParams.get('page') || '1', 10);

  const [page, setPage] = useState(initialPageState);

  //////////////////////////////
  // SORTING
  //////////////////////////////

  const initialSortStateValue = () => {
    if (config.useDefaultSort) {
      return config.defaultSort;
    }

    return searchParams.get('order_by')
      ? {
          by: searchParams.get('order_by'),
          direction: searchParams.get('order_direction'),
        }
      : config.defaultSort;
  };

  const initialSortState = initialSortStateValue();

  const [sortState, setSortState] = useState(initialSortState);

  //////////////////////////////
  // WHICH FIELDS TO DISPLAY ON LISTING CARDS
  //////////////////////////////

  const paramDisplayFields = searchParams.get('display_fields');
  const initialDisplayFields =
    paramDisplayFields && paramDisplayFields !== null
      ? paramDisplayFields.split(',')
      : config.defaultDisplayFields
      ? config.defaultDisplayFields
      : null;

  const [displayFields, setDisplayFields] = useState(initialDisplayFields);

  //////////////////////////////
  // VIEW MODE
  //////////////////////////////32l

  const initialViewModeState = searchParams.get('view_mode')
    ? searchParams.get('view_mode')
    : config.defaultViewMode;

  const [viewModeState, setViewModeState] = useState(initialViewModeState);
  const [viewModesState, setViewModesState] = useState(config.viewModes);

  //////////////////////////////
  // FILTERING
  //////////////////////////////

  const parseParam = useCallback(
    (fieldName: string) => {
      const paramStr = searchParams.get(fieldName);
      return paramStr
        ? // If param value (paramStr) contains a space, that's means it's a free-text-like value
          // such as a venue address (generated by the event planner venue filter) like:
          //   Mixdeity Studios (575 Boulevard SE, Atlanta, GA 30312, USA), Atlanta
          // so we know that any commas are NOT delimiters for a list of values (e.g. 75,12,802,19)
          // but rather are a part of the value itself, and that there is only 1 value (not a list)
          paramStr.includes(' ')
          ? [paramStr]
          : paramStr.split(',').filter((v: string) => v !== '')
        : [];
    },
    [searchParams]
  );

  const emptyFilterState = () =>
    config.filterNames.reduce((obj, key) => ({ ...obj, [key]: [] }), {});
  const initialFilterState = () =>
    config.filterNames.reduce(
      (obj, key) => ({ ...obj, [key]: parseParam(key) }),
      {}
    );
  const [filterState, setFilterState] = useState(initialFilterState());

  const filterListVariable = (variableName: string) =>
    filterState[variableName] && filterState[variableName].length > 0
      ? filterState[variableName].join(',')
      : undefined;

  const [filterLabelTitleMapping, setFilterLabelTitleMapping] = useState<any>(
    null
  );

  const [onFilterChangeFunc, setOnFilterChangeFunc] = useState<
    Function | undefined
  >(undefined);

  const [refetchListPageFunc, setRefetchListPageFunc] = useState<
    Function | undefined
  >(undefined);

  const initialOneClickFilterState = { isEnabled: false };

  const [oneClickFilterState, setOneClickFilterState] = useState(
    initialOneClickFilterState
  );

  //////////////////////////////
  // TEXT SEARCH
  //////////////////////////////

  const initialTextSearchState =
    config.textSearchParamName && searchParams.get(config.textSearchParamName)
      ? searchParams.get(config.textSearchParamName)
      : undefined;

  const [textSearchState, setTextSearchState] = useState(
    initialTextSearchState
  );

  //////////////////////////////
  // DETAILS MODAL
  //////////////////////////////

  const detailId = config.idParamName
    ? parseInt(searchParams.get(config.idParamName) || '', 10) || undefined
    : undefined;
  const [detailsModal, toggleDetailsModal] = useModal();
  const [detailData, setDetailData] = useState<any>(
    detailId ? { id: detailId } : undefined
  );

  //////////////////////////////
  // GENERIC MODAL
  //////////////////////////////

  const genericModalId = config.genericModalIdParamName
    ? parseInt(searchParams.get(config.genericModalIdParamName) || '', 10) ||
      undefined
    : undefined;
  const [genericModal, toggleGenericModal] = useModal();
  const [genericData, setGenericData] = useState<any>(
    genericModalId ? { id: genericModalId } : undefined
  );

  //////////////////////////////
  // SCROLLING (for maintaining previous position on list page when closing modals)
  //////////////////////////////

  const [scrollPosition, setScrollPosition] = useState<number>(0);

  //////////////////////////////
  // HANDLERS
  //////////////////////////////

  const handlePageChange = (p: number) => {
    setPage(p);
  };

  const handleSortChange = (sortValue: SortValue) => {
    setSortState(sortValue);
    setPage(1);
  };

  const handleDisplayFieldsChange = (fields: string[]) => {
    setDisplayFields(fields);
  };

  const handleViewModeChange = (viewMode: string) => {
    const defaultViewModes = ['list'];
    if ((viewModesState || defaultViewModes).includes(viewMode)) {
      setViewModeState(viewMode);
    }
  };

  const setViewModes = (viewModes: string[]) => {
    setViewModesState(viewModes);
    if (viewModeState && !viewModes.includes(viewModeState)) {
      setViewModeState(config.defaultViewMode);
    }
  };

  const updateFilterState = (filterName: string, opts: string[]) => {
    const newFilterState = { ...filterState, [filterName]: opts };
    setFilterState(newFilterState);
    if (analytics) {
      // @ts-ignore
      analytics.filterPayload(newFilterState);
    }
    setPage(1);
  };

  const handleFilterChange = (filterName: string, opts?: string[]) => {
    if (opts) {
      return () => {
        updateFilterState(filterName, opts);
        onFilterChangeFunc && onFilterChangeFunc();
      };
    }
    return (opts: string[]) => {
      updateFilterState(filterName, opts);
      onFilterChangeFunc && onFilterChangeFunc();
    };
  };

  const handleRemoveFilter = (labelsToRemove: Map<string, string[]>) => {
    const newFilterState = { ...filterState };
    for (let [key, values] of labelsToRemove) {
      newFilterState[key] = newFilterState[key].filter(
        (opt: string) => !values.includes(opt)
      );
    }
    setFilterState(newFilterState);
    if (analytics) {
      // @ts-ignore
      analytics.filterPayload(newFilterState);
    }
    setPage(1);
    onFilterChangeFunc && onFilterChangeFunc();
  };

  /*
    handleRemoveOrphanDependentFilters is used for e.g.:

    On pages with both a business_owner filter and city filter, a user might apply a city filter selecting
    cities that don't belong to a business_owner and then apply a business_owner filter.  When that happens, we remove
    all city filters for cities with no business_owners and only leave those city filters that belong to a
    selected business_owner.

    Example: user selects Buenos Aires and London on the city filter. Then user applies a UK business_owner filter.
    In that case we remove the Buenos Aires city filter and leave the London one. Otherwise Buenos Aires
    wouldn't be displayed on the cities filter, which is now displaying cities grouped by business_owners and the
    cities filter is dependent on business_owners.
  */

  const handleRemoveOrphanDependentFilters = ({
    parentFilter,
    dependentFilter,
    dependentFilterOptions,
  }: HandleRemoveOrphanDependentFiltersProps) => {
    const parentFilterIsApplied =
      filterListVariable(parentFilter) &&
      filterListVariable(parentFilter).length > 1;

    const dependentFilterValues =
      filterListVariable(dependentFilter) &&
      filterListVariable(dependentFilter).split(',');

    const dependentFilterOptionValues = dependentFilterOptions.map(
      (option: any) => option.value
    );

    const hasOrphanDependentFilterValues =
      dependentFilterValues &&
      dependentFilterOptionValues &&
      dependentFilterOptionValues.length > 0 &&
      dependentFilterValues.find(
        (value: any) => !dependentFilterOptionValues.includes(value)
      );

    if (parentFilterIsApplied && hasOrphanDependentFilterValues) {
      const labelsToRemove = new Map<string, string[]>();
      const dependentFilterValuesToRemove =
        dependentFilterValues &&
        dependentFilterValues.filter(
          (value: any) => !dependentFilterOptionValues.includes(value)
        );
      labelsToRemove.set(
        dependentFilter,
        dependentFilterValuesToRemove as string[]
      );
      handleRemoveFilter(labelsToRemove);
    }
  };

  const handleOneClickFilterChange = (isEnabled: boolean) => {
    setOneClickFilterState({ isEnabled });
    setPage(1);
  };

  const handleTextSearch = (searchString: string, allowEmpty = false) => {
    if (searchString || allowEmpty) {
      setTextSearchState(searchString);
      setPage(1);
      if (analytics) {
        // @ts-ignore
        analytics.inputPayload(searchString);
      }
    }
  };

  const handleTextSearchLabelClose = () => {
    setTextSearchState(undefined);
    setPage(1);
    if (analytics) {
      // @ts-ignore
      analytics.inputPayload(undefined);
    }
  };

  const toggleDetailsModalAndSetDetailData = (detailData: any) => {
    setDetailData(detailData);
    setScrollPosition(document.documentElement.scrollTop);
    toggleDetailsModal();
  };

  const toggleGenericModalAndSetGenericData = (genericData: any) => {
    setGenericData(genericData);
    setScrollPosition(document.documentElement.scrollTop);
    toggleGenericModal();
  };

  const handleResetFilters = () => {
    setFilterState(emptyFilterState());
    setTextSearchState(undefined);
    setSortState(config.defaultSort);
    setPage(1);
    onFilterChangeFunc && onFilterChangeFunc();
    setOneClickFilterState(initialOneClickFilterState);
  };

  const setupFilterLabelTitleMapping = (filterNamesAndOptions: object) => {
    const optToKeyVal = (opt: any) => [opt.value, opt.title];

    const mappingValues = Object.keys(filterNamesAndOptions).reduce(
      (result: object, filterName: string) => {
        result[filterName] = fromEntries(
          filterNamesAndOptions[filterName].map(optToKeyVal)
        );
        return result;
      },
      {}
    );

    setFilterLabelTitleMapping(mappingValues);
  };

  const refetchListPage = () => {
    refetchListPageFunc && refetchListPageFunc();
  };

  const setRefetchListPage = (
    refetchListPageFuncToSet: Function | undefined
  ) => {
    // When you store a function inside React state, you must store it inside an anonymous function,
    // otherwise React gets confused b/c passing just a function into a state setter has special meaning
    setRefetchListPageFunc(
      refetchListPageFuncToSet ? () => refetchListPageFuncToSet : undefined
    );
  };

  const setOnAnyFilterChange = (onAnyFilterChange: Function | undefined) => {
    // When you store a function inside React state, you must store it inside an anonymous function,
    // otherwise React gets confused b/c passing just a function into a state setter has special meaning
    setOnFilterChangeFunc(
      onAnyFilterChange ? () => onAnyFilterChange() : undefined
    );
  };

  const updateDetailsModal = () => {
    if (detailData && detailData.id && !detailsModal.isShowing) {
      toggleDetailsModal();
    }
  };

  const updateGenericModal = () => {
    if (genericData && genericData.id && !genericModal.isShowing) {
      toggleGenericModal();
    }
  };

  const updateScrollPositionOnPage = () => {
    if (!detailsModal.isShowing && !genericModal.isShowing) {
      document.documentElement.scrollTop = scrollPosition || 0;
    }
  };

  const updatePageUrl = () => {
    if (!location || !navigate) {
      return;
    }

    const searchParams = new URLSearchParams();

    searchParams.append('page', page.toString());

    searchParams.append('order_by', sortState.by || config.defaultSort.by);
    searchParams.append(
      'order_direction',
      sortState.direction || config.defaultSort.direction
    );

    if (displayFields !== null && displayFields !== undefined) {
      searchParams.append('display_fields', displayFields.join(','));
    }

    if (viewModeState) {
      searchParams.append('view_mode', viewModeState);
    }

    const selectedFilters: object = fromEntries(
      Object.entries(filterState).filter(
        (entry: any) => entry[1] && entry[1].length > 0
      )
    );
    Object.keys(selectedFilters).forEach(key =>
      searchParams.append(key, selectedFilters[key].toString())
    );

    if (config.textSearchParamName && textSearchState) {
      searchParams.append(config.textSearchParamName, textSearchState);
    }

    if (
      config.idParamName &&
      detailData &&
      detailData.id &&
      detailsModal.isShowing
    ) {
      searchParams.append(config.idParamName, detailData.id);
    }

    if (
      config.genericModalIdParamName &&
      genericData &&
      genericData.id &&
      genericModal.isShowing
    ) {
      searchParams.append(config.genericModalIdParamName, genericData.id);
    }

    if (searchParams.toString() !== location.search) {
      navigate({ search: searchParams.toString() });
    }
  };

  return (
    <ListPageContext.Provider
      value={{
        page,
        // @ts-ignore
        sortState,
        displayFields,
        viewModeState,
        filterState,
        filterListVariable,
        filterLabelTitleMapping,
        oneClickFilterState,
        textSearchState,
        detailsModal,
        detailData,
        genericModal,
        genericData,
        scrollPosition,
        handlePageChange,
        handleSortChange,
        handleDisplayFieldsChange,
        handleViewModeChange,
        handleFilterChange,
        handleRemoveFilter,
        handleRemoveOrphanDependentFilters,
        handleOneClickFilterChange,
        handleTextSearch,
        handleTextSearchLabelClose,
        toggleDetailsModalAndSetDetailData,
        toggleGenericModalAndSetGenericData,
        handleResetFilters,
        refetchListPage,
        setupFilterLabelTitleMapping,
        setRefetchListPage,
        setOnAnyFilterChange,
        setViewModes,
        updateDetailsModal,
        updateGenericModal,
        updateScrollPositionOnPage,
        updatePageUrl,
      }}
    >
      {children}
    </ListPageContext.Provider>
  );
};
