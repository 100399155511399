import * as Yup from 'yup';

import {
  eventMerchandisingDescriptionMaxLength,
  eventMerchandisingHeadlineMaxLength,
} from 'app/admin/utils/events';
import { removeHTMLTagsAndReplaceEscapedEntities } from 'app/admin/components/molecules/RichTextEditor/helper';

export const CollectionBasicInfoFormSchema = (intl: any) =>
  Yup.object().shape({
    title: Yup.string()
      .trim()
      .required(intl.formatMessage({ id: 'form.requiredField' })),
    cachedSlug: Yup.string().when('isNewCollection', {
      is: false,
      then: Yup.string()
        .trim()
        .required(intl.formatMessage({ id: 'form.requiredField' })),
    }),
    description: Yup.string()
      .nullable()
      .test('length', 'Please enter less than 225 characters', function(
        this,
        value
      ) {
        if (!value) {
          return true;
        }
        // Ugly workaround, but the rich text editor component escapes some base entities
        // by default, so we need to replace escaped characters and strip html tags to
        // count for characters correctly so this count matches the count the rich text
        // editor component is doing
        const charactersWithoutHTMLTags = removeHTMLTagsAndReplaceEscapedEntities(
          value
        );
        return charactersWithoutHTMLTags.length <= 225;
      }),
    status: Yup.string().when('isNewCollection', {
      is: false,
      then: Yup.string().required(
        intl.formatMessage({ id: 'form.requiredField' })
      ),
    }),
    eventHeadline: Yup.string()
      .nullable()
      .max(eventMerchandisingHeadlineMaxLength),
    eventDescription: Yup.string()
      .nullable()
      .max(eventMerchandisingDescriptionMaxLength),
    whatToExpect: Yup.string()
      .nullable()
      .test('length', 'Please enter less than 500 characters', function(
        this,
        value
      ) {
        if (!value) {
          return true;
        }
        // Ugly workaround, but the rich text editor component escapes some base entities
        // by default, so we need to replace escaped characters and strip html tags to
        // count for characters correctly so this count matches the count the rich text
        // editor component is doing
        const charactersWithoutHTMLTags = removeHTMLTagsAndReplaceEscapedEntities(
          value
        );
        return charactersWithoutHTMLTags.length <= 500;
      }),
    events: Yup.array()
      .of(Yup.object())
      .nullable(),
  });
