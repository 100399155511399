import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { Artist } from 'app/typings/artists';
import { Event } from 'app/typings/events';
import { dateFormatter } from 'app/shared/utils/datetime';
import { getNoNeighborhoodYetText } from 'app/shared/utils/events';
import { sortEntriesByKey } from 'app/shared/utils/object';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import { FormSectionTitle } from 'app/shared/components/atoms/FormContent';
import { AlertIcon } from 'app/shared/components/atoms/IconLibrary';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Overline } from 'app/shared/components/atoms/TypographyManualCSS';
import ArtistCircleThumbnailAndText from 'app/admin/components/molecules/ArtistCircleThumbnailAndText';

import { InvalidEventInfo } from './index';

interface Props {
  setFieldValue: any;
  touched: any;
  errors: any;
  values: any;
  invalidEventInfo: InvalidEventInfo;
  hasValidationErrorOverMaxOfferSize: boolean;
  maxNumEvents: number;
  maxNumArtists: number;
}

const MainContainer = styled.div`
  padding: 20px 0px 10px 0px;
`;

const ValidationErrorGenericMessage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 730px;
  padding-bottom: 30px;
`;

const ValidationErrorGenericMessageIcon = styled.div`
  padding-top: 6px;
`;

const ValidationErrorGenericMessageText = styled.div`
  font-weight: 600;
  line-height: 1.5em;
  padding-left: 20px;
`;

const SummaryContainer = styled.div`
  ${({ theme }) => css`
    max-width: 700px;
    padding: 30px 30px 10px 30px;
    background-color: ${theme.colors.silverSprings};
    margin-bottom: 25px;
  `}
`;

const SlotsContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 30px;
`;

const SlotsLabel = styled.div`
  font-weight: 600;
`;

const SlotsValue = styled.div`
  padding-left: 10px;
`;

const ArtistsEventsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const ArtistsContainer = styled.div``;

const ArtistsLabel = styled.div`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  font-weight: 600;
  padding-bottom: 20px;
`;

const EventsContainer = styled.div`
  padding-left: 50px;
`;

const EventsLabel = styled.div`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  font-weight: 600;
  padding-bottom: 20px;
`;

const DateAndEvents = styled.div`
  padding-bottom: 10px;
`;

const EventContainer = styled.div`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.4px;
  padding-bottom: 3px;
`;

const Neighborhood = styled.span``;

const EventError = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.redRedWine};
    padding-left: 5px;
  `}
`;

const getDateKey = (dateStr: string) => dateStr.replace(/T.+/, '');

interface EventInfo {
  eventId: number;
  neighborhoodTitle: string;
}

const getNeighborhoodText = (event: Event, intl: any) =>
  event.venue?.neighborhood?.title ||
  (!event.neighborhood?.title && getNoNeighborhoodYetText(intl)) ||
  (event.neighborhood && event.neighborhood.title);

const ensureNoNeighborhoodEventInfosAreLastInList = (
  eventInfos: EventInfo[],
  intl: any
) => {
  // Under a given date, 'No Neighborhood Yet' events must always appear at the
  // end of the list, even though the rest of the list is alphabetized
  const noNeighborhoodText = getNoNeighborhoodYetText(intl);
  const noNeighborhoodEventInfos = eventInfos.filter(
    (eventInfo: EventInfo) => eventInfo.neighborhoodTitle == noNeighborhoodText
  );
  if (noNeighborhoodEventInfos.length > 0) {
    eventInfos = eventInfos
      .filter(
        (eventInfo: EventInfo) =>
          eventInfo.neighborhoodTitle != noNeighborhoodText
      )
      .concat(noNeighborhoodEventInfos);
  }
  return eventInfos;
};

const getEventInfoByDate = (events: Event[], intl: any) => {
  const eventInfoByDate = events.reduce((obj, event) => {
    const dateKey = getDateKey(event.localStartsAt);
    const eventInfos = obj[dateKey] || [];
    let newEventInfos = eventInfos
      .concat([
        {
          eventId: event.id,
          neighborhoodTitle: getNeighborhoodText(event, intl),
        },
      ])
      .sort((a: EventInfo, b: EventInfo) =>
        a.neighborhoodTitle > b.neighborhoodTitle ? 1 : -1
      );
    newEventInfos = ensureNoNeighborhoodEventInfosAreLastInList(
      newEventInfos,
      intl
    );
    return {
      ...obj,
      [dateKey]: newEventInfos,
    };
  }, {});
  return sortEntriesByKey(eventInfoByDate);
};

// Incoming date is already in local time, so don't convert it to a different time zone -
// we need to trick dateFormatter by telling it timezone is UTC
const dateFormat = () => 'longWeekdayShortMonthDateAndYear';

const artistTitleFromArtistId = (artistId: number, artists: Artist[]) => {
  const matchingArtist = artists.find(
    (artist: Artist) => artist.id == artistId
  );
  return matchingArtist ? matchingArtist.title : '';
};

const getArtistTitlesFromArtistIds = (artistIds: string[], artists: Artist[]) =>
  artistIds.map((artistId: string) =>
    artistTitleFromArtistId(Number(artistId), artists)
  );

interface ValidationErrorProps {
  errorText: string;
}

const ValidationError: React.FC<ValidationErrorProps> = ({ errorText }) => {
  return (
    <ValidationErrorGenericMessage>
      <ValidationErrorGenericMessageIcon>
        <AlertIcon />
      </ValidationErrorGenericMessageIcon>
      <ValidationErrorGenericMessageText>
        {errorText}
      </ValidationErrorGenericMessageText>
    </ValidationErrorGenericMessage>
  );
};

const OfferCreateFormStep3: React.FC<Props> = ({
  values,
  invalidEventInfo,
  hasValidationErrorOverMaxOfferSize,
  maxNumEvents,
  maxNumArtists,
}) => {
  const intl = useIntl();

  const slotsText = values.positions
    .map((position: number) =>
      intl.formatMessage(
        {
          id: 'admin.offerCreate.slotNum',
        },
        {
          num: position,
        }
      )
    )
    .join(', ');

  const numSlotsText =
    values.positions.length == 1
      ? '1 slot'
      : `${values.positions.length} slots`;

  const artists = values.artists.sort((a: Artist, b: Artist) =>
    a.title > b.title ? 1 : -1
  );

  const eventInfoByDate = getEventInfoByDate(values.events, intl);

  const invalidEventIdsAndPositions =
    invalidEventInfo.invalidEventIdsAndPositions;
  const invalidEventIdsAndArtistIds =
    invalidEventInfo.invalidEventIdsAndArtistIds;

  const hasValidationErrorInvalidEvents =
    Object.keys(invalidEventIdsAndPositions).length > 0 ||
    Object.keys(invalidEventIdsAndArtistIds).length > 0;

  return (
    <>
      <DottedLine />
      <MainContainer>
        {hasValidationErrorInvalidEvents && (
          <ValidationError
            errorText={intl.formatMessage({
              id: 'admin.offerCreate.someOfYourSelections',
            })}
          />
        )}
        {hasValidationErrorOverMaxOfferSize && (
          <ValidationError
            errorText={intl.formatMessage(
              {
                id: 'admin.offerCreate.youHaveExceededTheMaxium',
              },
              {
                maxNumEvents,
                maxNumArtists,
                numSlotsText,
              }
            )}
          />
        )}
        <FormSectionTitle>
          {intl.formatMessage({
            id: 'admin.offerCreate.summary',
          })}
        </FormSectionTitle>
        <SummaryContainer>
          <SlotsContainer>
            <SlotsLabel>
              {intl.formatMessage({
                id: 'admin.offerCreate.slotsOffered',
              })}
            </SlotsLabel>
            <SlotsValue>{slotsText}</SlotsValue>
          </SlotsContainer>
          <ArtistsEventsContainer>
            <SlotsContainer>
              <ArtistsContainer>
                <ArtistsLabel>
                  {intl.formatMessage({
                    id: 'admin.shared.artistsInvited',
                  })}{' '}
                  ({values.artists.length})
                </ArtistsLabel>
                {artists.map((artist: Artist, i: number) => (
                  <React.Fragment key={i}>
                    <ArtistCircleThumbnailAndText artist={artist} />
                    <Spacer mt={4} />
                  </React.Fragment>
                ))}
              </ArtistsContainer>
              <EventsContainer>
                <EventsLabel>
                  {intl.formatMessage({
                    id: 'admin.offerCreate.eventsOffered',
                  })}{' '}
                  ({values.events.length})
                </EventsLabel>
                {Object.keys(eventInfoByDate).map(
                  (dateKey: string, i: number) => (
                    <DateAndEvents key={i}>
                      <Overline spaceAfter={2}>
                        {dateFormatter(dateKey, dateFormat())}
                      </Overline>
                      {eventInfoByDate[dateKey].map(
                        (eventInfo: EventInfo, j: number) => (
                          <EventContainer key={j}>
                            <Neighborhood>
                              {eventInfo.neighborhoodTitle}
                            </Neighborhood>
                            {invalidEventIdsAndPositions[
                              eventInfo.eventId.toString()
                            ] && (
                              <EventError>
                                (
                                {invalidEventIdsAndPositions[
                                  eventInfo.eventId.toString()
                                ].length == 1
                                  ? intl.formatMessage(
                                      {
                                        id:
                                          'admin.offerCreate.slotNotAvailable',
                                      },
                                      {
                                        num: invalidEventIdsAndPositions[
                                          eventInfo.eventId.toString()
                                        ][0].toString(),
                                      }
                                    )
                                  : intl.formatMessage(
                                      {
                                        id:
                                          'admin.offerCreate.slotsNotAvailable',
                                      },
                                      {
                                        numList: invalidEventIdsAndPositions[
                                          eventInfo.eventId.toString()
                                        ].join(', '),
                                      }
                                    )}
                                )
                              </EventError>
                            )}
                            {invalidEventIdsAndArtistIds[
                              eventInfo.eventId.toString()
                            ] && (
                              <EventError>
                                (
                                {intl.formatMessage(
                                  {
                                    id:
                                      'admin.offerCreate.artistsAlreadyOnThisConcert',
                                  },
                                  {
                                    artistNames: getArtistTitlesFromArtistIds(
                                      invalidEventIdsAndArtistIds[
                                        eventInfo.eventId.toString()
                                      ],
                                      values.artists
                                    ).join(', '),
                                  }
                                )}
                                )
                              </EventError>
                            )}
                          </EventContainer>
                        )
                      )}
                      <Spacer mt={4} />
                    </DateAndEvents>
                  )
                )}
              </EventsContainer>
            </SlotsContainer>
          </ArtistsEventsContainer>
        </SummaryContainer>
      </MainContainer>
    </>
  );
};

export default OfferCreateFormStep3;
