import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { ArrowLeftIcon } from 'app/shared/components/atoms/IconLibrary';
import { LoadingError as BaseLoadingError } from 'app/shared/components/atoms/LoadingError';
import { Spinner } from 'app/shared/components/atoms/SpinnerManualCSS';

export const HeaderWrapper = styled.div`
  ${({ theme }) => css`
    ${theme.media.xs`
      padding: 50px 30px 10px 30px;
    `};

    ${theme.media.md`
      padding: 16px 16px 0px 16px;
    `};
  `}
`;

export const ContentWrapper = styled.div`
  ${({ theme }) => css`
    ${theme.media.xs`
      padding: 8px 30px 24px 30px;
      max-height: 720px;
      overflow: scroll;
    `};

    ${theme.media.md`
      padding: 8px 16px 24px 16px;
      max-height: 300px;
      overflow: scroll;
    `};
  `}
`;

export const FlyoverNote = styled.div`
  font-size: 14px;
  line-height: 140%;
  font-style: italic;
  padding-top: 10px;
`;

interface FlyoverBackLinkContainerProps {
  noPadding?: boolean;
}

const FlyoverBackLinkContainer = styled.div<FlyoverBackLinkContainerProps>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  padding: ${props => (props.noPadding ? '0px' : '12px 0px 0px 16px')};
  cursor: pointer;
`;

const BackLinkArrow = styled.div``;

const BackLinkText = styled.div`
  ${({ theme }) => css`
    font-size: 10px;
    line-height: 150%;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: ${theme.colors.primary};
    padding-top: 2px;
    padding-left: 6px;
  `}
`;

interface FlyoverBackLinkProps {
  linkTextType?: string;
  noPadding?: boolean;
  onClickButton?: () => void;
}

export const FlyoverBackLink: React.FC<FlyoverBackLinkProps> = ({
  linkTextType = 'back',
  noPadding = false,
  onClickButton = () => {},
}) => {
  const intl = useIntl();

  const linkTexts = {
    back: 'form.back',
    cancel: 'form.cancel',
  };

  return (
    <FlyoverBackLinkContainer noPadding={noPadding} onClick={onClickButton}>
      <BackLinkArrow>
        <ArrowLeftIcon iconSize={10} />
      </BackLinkArrow>
      <BackLinkText>
        {intl.formatMessage({
          id: linkTexts[linkTextType],
        })}
      </BackLinkText>
    </FlyoverBackLinkContainer>
  );
};

const FlyoverLoadingIndicatorContainer = styled.div`
  padding: 15px 16px 24px 16px;
  width: 100%;
`;

const CenteredSpinner = styled(Spinner)`
  margin-left: 45%;
`;

export const FlyoverLoadingIndicator: React.FC = () => {
  return (
    <FlyoverLoadingIndicatorContainer>
      <CenteredSpinner size="30px" />
    </FlyoverLoadingIndicatorContainer>
  );
};

interface FlyoverLoadingErrorProps {
  whatsBeingLoaded: string;
}

export const FlyoverLoadingError: React.FC<FlyoverLoadingErrorProps> = ({
  whatsBeingLoaded,
}) => {
  return (
    <BaseLoadingError
      whatsBeingLoaded={whatsBeingLoaded}
      containerSize="small"
    />
  );
};
