import React from 'react';

import EventListWithLoadMore from 'app/admin/components/organisms/EventListWithLoadMore';

import StaffedEvent from './StaffedEvent';

interface Props {
  userId: number;
  queryDataHook: any; // e.g. GetUserStaffedEventsUpcoming
  eventFieldKey: string; // e.g. 'upcomingStaffedEvents'
  noEventsMessage: string;
  scope: string;
}

const StaffedEvents: React.FC<Props> = ({
  userId,
  queryDataHook,
  eventFieldKey,
  noEventsMessage,
  scope,
}) => {
  return (
    <>
      <EventListWithLoadMore
        queryDataHook={queryDataHook}
        queryExtraVariables={{ id: userId }}
        endpointKey="user"
        eventFieldKey={eventFieldKey}
        perPage={12}
        eventComponent={StaffedEvent}
        eventComponentExtraProps={{ scope }}
        noEventsMessage={noEventsMessage}
      />
    </>
  );
};

export default StaffedEvents;
