import React from 'react';

import { Property } from 'app/typings/properties';
import { IconList } from 'app/admin/components/atoms/IconList';

export const PropertyGroupList: React.FC<any> = ({ propertyElements }) => {
  let allPropertyGroupIds = propertyElements.map(
    (property: Property) => property.propertyGroupId
  );
  allPropertyGroupIds = [...new Set(allPropertyGroupIds)];
  const propertyGroupOne = propertyElements.filter(
    (property: Property) => property.propertyGroupId === allPropertyGroupIds[0]
  );
  const propertyGroupTwo = propertyElements.filter(
    (property: Property) => property.propertyGroupId === allPropertyGroupIds[1]
  );
  const propertyGroupThree = propertyElements.filter(
    (property: Property) => property.propertyGroupId === allPropertyGroupIds[2]
  );
  return (
    <div data-qaid="properties-group">
      <IconList iconList={propertyGroupOne} iconGroup={true} />
      <IconList iconList={propertyGroupTwo} iconGroup={true} />
      <IconList iconList={propertyGroupThree} iconGroup={true} />
    </div>
  );
};
