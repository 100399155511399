import * as Yup from 'yup';

import { getSocialUrlsFormSchema } from 'app/shared/utils/socialLinks';

const cityBasicInfoFormSchema = (intl: any) =>
  Yup.object().shape({
    titleEn: Yup.string().required(
      intl.formatMessage({
        id: 'form.requiredField',
      })
    ),
    countryId: Yup.number()
      .typeError(
        intl.formatMessage({
          id: 'form.requiredField',
        })
      )
      .required(
        intl.formatMessage({
          id: 'form.requiredField',
        })
      ),
    latitude: Yup.string()
      .trim()
      .required(
        intl.formatMessage({
          id: 'form.requiredField',
        })
      )
      .matches(/^(-?\d+\.\d+)*$/, {
        message: intl.formatMessage({
          id: 'admin.cityBasicInfo.form.latitudeError',
        }),
      }),
    longitude: Yup.string()
      .trim()
      .required(
        intl.formatMessage({
          id: 'form.requiredField',
        })
      )
      .matches(/^(-?\d+\.\d+)*$/, {
        message: intl.formatMessage({
          id: 'admin.cityBasicInfo.form.longitudeError',
        }),
      }),
    cityStatus: Yup.string().when('isNewCity', {
      is: false,
      then: Yup.string().required(
        intl.formatMessage({
          id: 'form.requiredField',
        })
      ),
    }),
    cityOperatingModel: Yup.string()
      .typeError(
        intl.formatMessage({
          id: 'form.requiredField',
        })
      )
      .required(
        intl.formatMessage({
          id: 'form.requiredField',
        })
      ),
    sofarOperatedMarketType: Yup.string().nullable(),
    cachedSlug: Yup.string()
      .when('isNewCity', {
        is: false,
        then: Yup.string().required(
          intl.formatMessage({
            id: 'form.requiredField',
          })
        ),
      })
      .matches(/^[a-z0-9-]+$/, {
        message: intl.formatMessage({
          id: 'admin.cityBasicInfo.form.cachedSlugError',
        }),
      }),
    nearbySecondaryCities: Yup.array()
      .of(Yup.object())
      .ensure(),
    socialUrls: getSocialUrlsFormSchema(intl),
  });

export default cityBasicInfoFormSchema;
