import * as Yup from 'yup';

const competitionTemplateCreateFormSchema = (intl: any) =>
  Yup.object().shape({
    templateName: Yup.string().required(
      intl.formatMessage({
        id: 'form.requiredField',
      })
    ),
  });

export default competitionTemplateCreateFormSchema;
