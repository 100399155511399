import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { scrollToTop } from 'app/shared/utils/modal';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { Title } from 'app/shared/components/atoms/ModalTitle';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { MergeArtists } from 'app/admin/graphql/artistMerges/mutationHooks';

import ArtistMergeForm from './ArtistMergeForm';
import ArtistMergeFormSchema from './ArtistMergeFormSchema';

interface Props {
  contentProps: any;
  setButtonConfigs?: (buttonConfigs: any) => void;
  setGoBackAction?: (func: Function) => void;
  setGoForwardAction?: (func: Function) => void;
  setFormSubmitAction?: (func: Function) => void;
  setDisplayConfirmation?: (set: boolean) => void;
  setIsSubmitting?: (set: boolean) => void;
  hide: VoidFunction;
}

const formViewPageTitleKeys = {
  selectArtists: 'admin.artistMerge.modal.titleSelectArtists',
  selectArtistsFromSet: 'admin.artistMerge.modal.titleSelectArtistsFromSet',
  mergeArtists: 'admin.artistMerge.modal.titleMergeArtists',
};

const MainContainer = styled.div`
  ${({ theme }) => css`
    padding: 0px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    ${theme.media.xs`
      padding: 0px 5px 20px 5px;
    `}
  `}
`;

const HeaderContainer = styled.div`
  padding-bottom: 10px;
`;

const ArtistMerge: React.FC<Props> = ({
  contentProps,
  setButtonConfigs,
  setGoBackAction,
  setGoForwardAction,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  hide,
}) => {
  const intl = useIntl();

  const [validationErrors, setValidationErrors] = useState<object>({});

  const [formView, setFormView] = useState<string>(
    contentProps.duplicatedArtistTitle
      ? 'selectArtistsFromSet'
      : 'selectArtists'
  );

  const formInitialValues = {
    artistsSelectedFromSet: !!contentProps.duplicatedArtistTitle,
    artist1: undefined,
    artist2: undefined,
    titleArtistId: undefined,
    titleOther: '',
    title: '',
    cachedSlugArtistId: undefined,
    cachedSlug: '',
    descriptionArtistId: undefined,
    descriptionOther: '',
    description: null,
    emailArtistId: undefined,
    emailOther: '',
    email: '',
    homeCityDescriptionArtistId: null,
    mainImageArtistId: null,
    urlFacebookArtistId: undefined,
    urlFacebookOther: '',
    urlFacebook: null,
    urlTwitterArtistId: undefined,
    urlTwitterOther: '',
    urlTwitter: null,
    urlWebsiteArtistId: undefined,
    urlWebsiteOther: '',
    urlWebsite: null,
    urlSoundcloudArtistId: undefined,
    urlSoundcloudOther: '',
    urlSoundcloud: null,
    urlYoutubeArtistId: undefined,
    urlYoutubeOther: '',
    urlYoutube: null,
    urlInstagramArtistId: undefined,
    urlInstagramOther: '',
    urlInstagram: null,
    urlBandcampArtistId: undefined,
    urlBandcampOther: '',
    urlBandcamp: null,
    urlSpotifyArtistId: undefined,
    urlSpotifyOther: '',
    urlSpotify: null,
    urlSupportArtistId: undefined,
    urlSupportOther: '',
    urlSupport: null,
  };

  const mergeArtistsAction = MergeArtists();

  const handleMergeArtists = useSubmitAction({
    submitAction: mergeArtistsAction,
    submitVariables: (values: any) => ({
      artistId1: values.artist1.id,
      artistId2: values.artist2.id,
      title: values.title,
      cachedSlug: values.cachedSlug,
      description: values.description,
      email: values.email,
      homeCityDescriptionArtistId: values.homeCityDescriptionArtistId,
      mainImageArtistId: values.mainImageArtistId,
      socialUrls: {
        urlFacebook: values.urlFacebook,
        urlTwitter: values.urlTwitter,
        urlWebsite: values.urlWebsite,
        urlSoundcloud: values.urlSoundcloud,
        urlYoutube: values.urlYoutube,
        urlInstagram: values.urlInstagram,
        urlBandcamp: values.urlBandcamp,
        urlSpotify: values.urlSpotify,
        urlSupport: values.urlSupport,
      },
    }),
    successMsg: intl.formatMessage({
      id: 'admin.artistMerge.modal.successMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.artistMerge.modal.failureMessage',
    }),
    onSuccess: () => {
      contentProps.refetchDuplicateArtists();
      hide();
    },
    onValidationError: validationErrors => {
      setValidationErrors(validationErrors);
      scrollToTop(contentProps.modalRef);
    },
  });

  const title = intl.formatMessage({
    id: formViewPageTitleKeys[formView],
  });

  return (
    <ModalContentContainer>
      <MainContainer>
        <HeaderContainer>
          <Title>{title}</Title>
        </HeaderContainer>
        <GenericForm
          formInitialValues={formInitialValues}
          renderFormComponent={(renderProps: any) => (
            <ArtistMergeForm
              formikProps={renderProps.formikProps}
              modalRef={contentProps.modalRef}
              setButtonConfigs={setButtonConfigs}
              setGoBackAction={setGoBackAction}
              setGoForwardAction={setGoForwardAction}
              setFormSubmitAction={setFormSubmitAction}
              setDisplayConfirmation={setDisplayConfirmation}
              setIsSubmitting={setIsSubmitting}
              validationErrors={validationErrors}
              formView={formView}
              setFormView={setFormView}
              duplicatedArtistTitle={contentProps.duplicatedArtistTitle}
            />
          )}
          onSubmit={handleMergeArtists}
          formSchema={ArtistMergeFormSchema(intl)}
          dataQaId="artist-merge-form"
          paddingTop="0"
        />
      </MainContainer>
    </ModalContentContainer>
  );
};

export default ArtistMerge;
