import { css } from 'styled-components';

export interface WithFlexProps {
  flex?: number;
  flexDirection?: string;
  flexWrap?: string;
  flexFlow?: string;
  justifyContent?: string;
  alignItems?: string;
  alignContent?: string;
  gap?: number;
}

export const withFlex = ({
  flex,
  flexDirection,
  flexWrap,
  flexFlow,
  justifyContent,
  alignItems,
  alignContent,
  gap,
}: WithFlexProps) => css`
  ${(flex ||
    flexDirection ||
    flexWrap ||
    flexFlow ||
    justifyContent ||
    alignItems ||
    alignContent ||
    gap) &&
    css`
      display: flex;

      ${flex && `flex: ${flex};`}
      ${flexDirection && `flex-direction: ${flexDirection};`}
      ${flexWrap && `flex-wrap: ${flexWrap};`}
      ${flexFlow && `flex-flow: ${flexFlow};`}
      ${justifyContent && `justify-content: ${justifyContent};`}
      ${alignItems && `align-items: ${alignItems};`}
      ${alignContent && `align-content: ${alignContent};`}

      ${({ theme }) => css`
        ${gap &&
          css`
            gap: ${theme.ruler[gap]}px;
          `}}
      `}
    `}
`;
