import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { City } from 'app/typings/cities';
import { User } from 'app/typings/users';
import { useCurrentTheme } from 'app/shared/theme';
import { dateFormatter } from 'app/shared/utils/datetime';
import usePermission from 'app/shared/utils/usePermission';
import { Badge } from 'app/shared/components/atoms/Badge';
import DottedLine from 'app/shared/components/atoms/DottedLine';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import TruncatedByCharText from 'app/shared/components/atoms/TruncatedByCharText';
import { Overline } from 'app/shared/components/atoms/TypographyManualCSS';
import { H6 } from 'app/shared/components/atoms/TypographyManualCSS';
import { userFullNameOrEmail } from 'app/admin/utils/users';
import ProgressBar from 'app/admin/components/atoms/ProgressBar';
import { AdminListingCard } from 'app/admin/components/molecules/EventPlannerUtils';
import { KeyAboveValueInfoList } from 'app/admin/components/molecules/KeyAboveValueInfoList';
import { KeyValueInfoList } from 'app/admin/components/molecules/KeyValueInfoList';
import { TagElement } from 'app/admin/components/molecules/TagElementList';
import { CardControls } from 'app/admin/components/organisms/CardControls';
import { ReactComponent as ConcertDj } from 'icons/streamline-regular/entertainment-events-hobbies/concerts-night-clubs/concert-dj.svg';

interface OfferCardProps {
  id: number;
  name: string;
  city: City;
  status: string;
  index: number;
  onShowDetails: Function;
  createdBy?: User;
  createdAt?: string;
  numArtistsAccepted: number;
  numArtistsBooked: number;
  numArtistsDeclined: number;
  numArtistsOpen: number;
  numArtistsTotal: number;
  numEventPositionsBooked: number;
  numEventPositionsTotal: number;
  numPerformancesCurrentlyAccepted: number;
}

const StyledBadge = styled(Badge)`
  width: 20px;
  height: 20px;
  font-size: 12px !important;
  display: inline-flex;
`;

const KeyValueInfoListContainer = styled.div`
  height: auto;
`;

const OfferTitle = styled.div`
  height: 40px;
  width: 100%;
  overflow: hidden;
`;

const StyledTag = styled(TagElement)`
  text-align: center;
  padding: 1px 5px;
  margin-top: -16px;
  margin-right: 4px;
  height: 18px;
  border-radius: 0px 0px 2px 2px;
  @media screen and (min-width: 1200px) and (max-width: 1300px) {
    font-size: 9px;
  }
`;

const TagsWrapper = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 0px 25px;
`;

const OfferCard: React.FC<OfferCardProps> = ({
  id,
  name,
  index,
  status,
  onShowDetails,
  city,
  createdBy,
  createdAt,
  numArtistsAccepted,
  numArtistsBooked,
  numArtistsDeclined,
  numArtistsOpen,
  numArtistsTotal,
  numEventPositionsBooked,
  numEventPositionsTotal,
  numPerformancesCurrentlyAccepted,
}) => {
  const intl = useIntl();
  const theme = useCurrentTheme();
  const hasAccessUsersPermission = usePermission('user.list.access');
  const valueExists = (value: any) => value !== undefined && value !== null;

  const artistKeysAndValuesList = [
    {
      key: intl.formatMessage({
        id: 'admin.offerDirectory.listingCard.artistsInvited',
      }),
      value:
        (valueExists(numArtistsTotal) && numArtistsTotal.toString()) || '-',
    },
    {
      key: intl.formatMessage({
        id: 'admin.offerDirectory.listingCard.artistsAvailable',
      }),
      value:
        (valueExists(numArtistsAccepted) && numArtistsAccepted.toString()) ||
        '-',
    },
    {
      key: intl.formatMessage({
        id: 'admin.offerDirectory.listingCard.artistsBooked',
      }),
      value:
        (valueExists(numArtistsBooked) && numArtistsBooked.toString()) || '-',
    },
    {
      key: intl.formatMessage({
        id: 'admin.offerDirectory.listingCard.artistsNoResponse',
      }),
      value: (valueExists(numArtistsOpen) && numArtistsOpen.toString()) || '-',
    },
    {
      key: intl.formatMessage({
        id: 'admin.offerDirectory.listingCard.artistsUnavailable',
      }),
      value:
        (valueExists(numArtistsDeclined) && numArtistsDeclined.toString()) ||
        '-',
    },
  ];

  const performanceKeysAndValuesList = [
    {
      key: intl.formatMessage({
        id: 'admin.offerDirectory.listingCard.bookedSlots',
      }),
      value:
        (valueExists(numEventPositionsBooked) &&
          numEventPositionsBooked.toString()) ||
        '-',
    },
    {
      key: intl.formatMessage({
        id: 'admin.offerDirectory.listingCard.totalSlots',
      }),
      value:
        (valueExists(numEventPositionsTotal) &&
          numEventPositionsTotal.toString()) ||
        '-',
    },
  ];

  const actionsKeysAndValuesList = [
    {
      key: intl.formatMessage({
        id: 'admin.offerDirectory.listingCard.actionsNeeded',
      }),
      value:
        (valueExists(numPerformancesCurrentlyAccepted) &&
          numPerformancesCurrentlyAccepted.toString()) ||
        '0',
      renderValue: (value: string) => (
        <StyledBadge color={parseInt(value) > 0 ? 'warning' : 'default'}>
          {value}
        </StyledBadge>
      ),
    },
    {
      key: 'City',
      value: city && city.title,
    },
  ];

  const keysAboveValuesList = [
    {
      key: intl.formatMessage({
        id: 'admin.shared.createdAt',
      }),
      value: createdAt
        ? dateFormatter(new Date(createdAt), 'shortMonthDateAndYear')
        : '',
    },
    {
      key: intl.formatMessage({
        id: 'admin.shared.createdBy',
      }),
      value: createdBy ? userFullNameOrEmail(createdBy) || '' : 'None',
      url:
        createdBy && hasAccessUsersPermission
          ? `/admin/users?user_id=${createdBy.id}`
          : undefined,
    },
  ];

  const eventPlannerOfferUrl = (offerId: number) => {
    return `/admin/concert-planner?offer_id=${offerId}`;
  };

  const onShowDetailsParams = {
    id,
    name,
    city,
    status,
    index,
    numArtistsTotal,
    numEventPositionsTotal,
  };

  const controlsInfoList = [
    {
      href: eventPlannerOfferUrl(id) || '',
      icon: ConcertDj,
      tipText: intl.formatMessage({
        id: 'admin.offerDirectory.listingCard.viewInConcertPlanner',
      }),
    },
  ];

  const percentageProgress =
    numEventPositionsBooked !== undefined &&
    numEventPositionsTotal !== undefined
      ? (numEventPositionsBooked / numEventPositionsTotal) * 100.0
      : 0;

  return (
    <AdminListingCard data-qaid="offer-card">
      <TagsWrapper>
        {status === 'closed' && (
          <StyledTag
            tagElementColor={theme.colors.greenOnions}
            textColor="#fff"
          >
            Closed
          </StyledTag>
        )}
      </TagsWrapper>
      <Overline>
        {intl.formatMessage({
          id: 'admin.offerDirectory.listingCard.inviteName',
        })}
      </Overline>
      <OfferTitle>
        <H6>
          <TruncatedByCharText text={name} truncateLength={32} />
        </H6>
      </OfferTitle>
      <Spacer mt={1} />
      <KeyValueInfoListContainer>
        <KeyValueInfoList keysAndValues={actionsKeysAndValuesList} />
      </KeyValueInfoListContainer>
      <Spacer mt={2} />
      <DottedLine />
      <Overline>
        {intl.formatMessage({ id: 'admin.offerDirectory.listingCard.artists' })}
      </Overline>
      <KeyValueInfoListContainer>
        <KeyValueInfoList keysAndValues={artistKeysAndValuesList} />
      </KeyValueInfoListContainer>
      <DottedLine />
      <Overline>
        {intl.formatMessage({
          id: 'admin.offerDirectory.listingCard.bookingProgress',
        })}
      </Overline>
      <Spacer mt={1} />
      <div>
        <ProgressBar
          percentage={percentageProgress}
          width="100%"
          height="10px"
          borderRadius="5px"
          borderWidth="1px"
          color="macyGrey"
          unfilledColor="silverSprings"
        />
      </div>
      <Spacer mt={2} />
      <KeyValueInfoListContainer>
        <KeyValueInfoList keysAndValues={performanceKeysAndValuesList} />
      </KeyValueInfoListContainer>
      <DottedLine />
      <KeyAboveValueInfoList keysAndValues={keysAboveValuesList} />
      <DottedLine />
      <CardControls
        controlsInfoList={controlsInfoList}
        onClickShowDetails={
          onShowDetails &&
          (() => {
            onShowDetails(onShowDetailsParams);
          })
        }
        dataQaidPrefix="offers"
      />
    </AdminListingCard>
  );
};

export default OfferCard;
