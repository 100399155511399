import * as Yup from 'yup';

import { isHour2AfterHour1 } from 'app/admin/utils/datetime';
import { getNotesFormSchema } from 'app/admin/utils/string';

const venueEventSetupEditFormSchema = (intl: any) =>
  Yup.object().shape({
    recommendedCrewCount: Yup.number()
      .nullable()
      .min(
        0,
        intl.formatMessage(
          {
            id: 'form.fieldValueMustExceedMin',
          },
          {
            field: 'Recommended Team',
            min: 0,
          }
        )
      )
      .max(
        6,
        intl.formatMessage(
          {
            id: 'form.fieldValueCanNotExceedMax',
          },
          {
            field: 'Recommended Team',
            max: 6,
          }
        )
      )
      .typeError(
        intl.formatMessage({
          id: 'form.pleaseEnterANumber',
        })
      ),
    internalVenueNotes: Yup.string().nullable(),
    suppliesNeededNotes: getNotesFormSchema(intl),
    mcNotes: getNotesFormSchema(intl, 1000),
    checkInProcessNotes: getNotesFormSchema(intl),
    loadInNotes: getNotesFormSchema(intl, 1000),
    timingNotes: getNotesFormSchema(intl),
    stageSetupNotes: getNotesFormSchema(intl, 2000),
    merchTablePlacementNotes: getNotesFormSchema(intl),
    audioNotes: getNotesFormSchema(intl, 1000),
    bathroomNotes: getNotesFormSchema(intl),
    gearStorage: Yup.string().nullable(),
    noiseRestrictions: Yup.string().nullable(),
    hasTapWater: Yup.boolean().nullable(),
    localCrewLoadInAt: Yup.string()
      .nullable()
      .required(
        intl.formatMessage({
          id: 'form.requiredField',
        })
      ),
    localArtistLoadInAt: Yup.string()
      .nullable()
      .required(
        intl.formatMessage({
          id: 'form.requiredField',
        })
      ),
    localGuestsArriveAt: Yup.string()
      .nullable()
      .required(
        intl.formatMessage({
          id: 'form.requiredField',
        })
      ),
    localStartsAt: Yup.string()
      .nullable()
      .required(
        intl.formatMessage({
          id: 'form.requiredField',
        })
      )
      .test(
        'less-than-local-ends-at',
        intl.formatMessage({
          id: 'admin.eventBasicInfo.startsAtLaterThanEndsAtError',
        }),
        function(value: string) {
          const localEndsAt = this.parent.localEndsAt;
          if (!localEndsAt) {
            return true;
          }
          if (value) {
            return isHour2AfterHour1(value, localEndsAt);
          }
          return false;
        }
      ),
    localEndsAt: Yup.string()
      .test(
        'greater-than-local-starts-at',
        intl.formatMessage({
          id: 'admin.eventBasicInfo.endsAtEarlierThanStartsAtError',
        }),
        function(value: string) {
          const localStartsAt = this.parent.localStartsAt;
          if (!value || !localStartsAt) {
            return true;
          }
          if (localStartsAt) {
            return isHour2AfterHour1(localStartsAt, value);
          }
          return false;
        }
      )
      .nullable(),
  });

export default venueEventSetupEditFormSchema;
