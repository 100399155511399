import React, { useState } from 'react';
import { getTimezoneOffset, zonedTimeToUtc } from 'date-fns-tz';
import moment from 'moment';
import { useIntl } from 'react-intl';

import { Competition } from 'app/typings';
import { dateFormatter } from 'app/shared/utils/datetime';
import { get } from 'app/shared/utils/get';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { UpdateCompetition } from 'app/admin/graphql/competitions/mutationHooks';
import { DetailsFormMainContainer } from 'app/admin/components/atoms/DetailContent';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

import CompetitionBasicInfoForm from './CompetitionBasicInfoForm';
import CompetitionBasicInfoFormSchema from './CompetitionBasicInfoFormSchema';
import { getClosesAt } from './Utils';

interface Props {
  contentProps?: Competition;
  setFormSubmitAction: (func: Function) => void;
  setDisplayConfirmation: (set: boolean) => void;
  setIsSubmitting: (set: boolean) => void;
  navigateTo: (routeData: object) => void;
}

const CompetitionBasicInfoEdit: React.FC<Props> = ({
  contentProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  navigateTo,
}) => {
  const intl = useIntl();

  const [validationErrors, setValidationErrors] = useState<object>({});

  const closesAt = contentProps?.closesAt;
  const timezone = contentProps?.city?.timezone || '';

  const utcOffsetMs = getTimezoneOffset(timezone, closesAt);
  const utcOffsetHours = utcOffsetMs / 1000 / 60 / 60;

  const formInitialValues = {
    isNewCompetition: false,
    title: get(contentProps, 'title', ''),
    closesAtDate: closesAt ? moment(closesAt).utcOffset(utcOffsetHours) : '',
    closesAtTime: dateFormatter(
      closesAt || '',
      "yyyy-MM-dd'T'HH:mm:ss",
      timezone
    ),
    closesAt: '',
    uid: get(contentProps, 'uid', ''),
    city: get(contentProps, 'city', '') || '',
    maxWinners: get(contentProps, 'maxWinners', ''),
  };

  const updateCompetitionAction = UpdateCompetition();

  const handleUpdateCompetition = useSubmitAction({
    submitAction: updateCompetitionAction,
    submitVariables: (values: any) => ({
      competitionId: get(contentProps, 'id', undefined),
      title: values.title,
      closesAt: zonedTimeToUtc(
        getClosesAt(values.closesAtDate, values.closesAtTime),
        values.city.timezone
      ),
      uid: values.uid,
      cityId: values.city ? values.city.id : undefined,
      maxWinners: Number(values.maxWinners),
    }),
    successMsg: intl.formatMessage({
      id: 'admin.competitionBasicInfo.form.successMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.competitionBasicInfo.form.failureMessage',
    }),
    onSuccess: response => {
      navigateTo({
        routeName: 'competition-details',
        routeProps: {
          defaultOpenSection: 'basicInfo',
          ...response.data.updateCompetition.competition,
        },
      });
    },
    onValidationError: validationErrors =>
      setValidationErrors(validationErrors),
    validationErrorKeysForDisplay: { uid: 'slug' },
  });

  return (
    <ModalContentContainer>
      <DetailsFormMainContainer>
        <DetailsHeader title={get(contentProps, 'title', '')} />
        <GenericForm
          formInitialValues={formInitialValues}
          renderFormComponent={(renderProps: any) => (
            <CompetitionBasicInfoForm
              formikProps={renderProps.formikProps}
              validationErrors={validationErrors}
            />
          )}
          onSubmit={handleUpdateCompetition}
          formSchema={CompetitionBasicInfoFormSchema(intl)}
          setFormSubmitAction={setFormSubmitAction}
          setDisplayConfirmation={setDisplayConfirmation}
          setIsSubmitting={setIsSubmitting}
          dataQaId="competition-overview-section-edit-form"
        />
      </DetailsFormMainContainer>
    </ModalContentContainer>
  );
};

export default CompetitionBasicInfoEdit;
