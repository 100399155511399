import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { City, Country } from 'app/typings';
import { Artist } from 'app/typings/artists';
import { errorMsgForField } from 'app/shared/utils/form';
import usePermission from 'app/shared/utils/usePermission';
import { GetCountries } from 'app/shared/graphql/countries/queryHooks';
import { Checkbox } from 'app/shared/components/atoms/CheckboxManualCSS';
import {
  FormGroupContainer,
  FormSectionTitle,
} from 'app/shared/components/atoms/FormContent';
import GenericFormContainer from 'app/shared/components/atoms/GenericFormContainer';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import { DeleteIcon } from 'app/shared/components/atoms/IconLibrary';
import { LoadingError } from 'app/shared/components/atoms/LoadingError';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Textarea } from 'app/shared/components/atoms/Textarea';
import { Textfield } from 'app/shared/components/atoms/Textfield';
import FormGroup from 'app/shared/components/molecules/FormGroup';
import Select from 'app/shared/components/molecules/SelectManualCSS';
import CitySelectorManualCSS from 'app/shared/components/organisms/CitySelectorManualCSS';
import EditFormLoadingBlocks from 'app/admin/components/atoms/EditFormLoadingBlocks';
import ArtistTypeahead from 'app/admin/components/molecules/ArtistTypeahead';
import { ArtistLink } from 'app/admin/components/molecules/EntityLink';

import { birthYearOptions, genderOptions } from './options';

interface FormProps {
  formikProps: any;
  numContactInfos?: number;
  validationErrors?: object;
  associatedArtists?: Artist[];
}

const StyledTextfield = styled(Textfield)`
  ${({ theme }) => css`
    display: block;
    width: 100%;
    ${theme.media.lg`
        width: 48%;
      `};
  `}
`;

const LatLongTextfield = styled(Textfield)`
  display: block;
  width: 150px;
`;

const GeneralNotesTextarea = styled(Textarea)`
  display: block;
  width: 100%;
  height: 110px;
`;

const AssociatedArtistContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const AssociatedArtistDisplayContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: flex-start;
`;

const AssociatedArtistRemoveMsgContainer = styled.div`
  font-size: 14px;
  margin-top: -9px;
  margin-bottom: 12px;
`;

const ArtistContainer = styled.div`
  font-size: 14px;
  letter-spacing: 0.4px;
  padding-top: 4px;
  margin-bottom: 12px;
`;

const ArtistInactive = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.blueSmoke};
  `}
`;

const ArtistRemoveContainer = styled.div`
  padding-left: 9px;
`;

const NoAssociatedArtists = styled.div`
  font-size: 14px;
  letter-spacing: 0.1px;
  margin-bottom: 25px;
`;

const AddAssociatedArtistContainer = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  max-width: 500px;
`;

const AddAssociatedArtist = styled.div`
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.1px;
  margin-bottom: 10px;
`;

const MultipleAssociatedArtistsWarning = styled.div`
  font-size: 12px;
  font-weight: 500;
  margin-top: 15px;
  margin-bottom: 8px;
`;

const renderAssociatedArtists = (
  associatedArtists: Artist[] | undefined,
  artistIdsToUnassociate: number[],
  removeAssociatedArtist: Function,
  intl: any
) => {
  if (associatedArtists && associatedArtists.length > 0) {
    return associatedArtists.map((artist: Artist, i: number) => (
      <AssociatedArtistContainer key={i}>
        <AssociatedArtistDisplayContainer>
          {artistIdsToUnassociate.includes(artist.id) ? (
            <>
              <ArtistContainer>
                <ArtistInactive>{artist.title}</ArtistInactive>
              </ArtistContainer>
              <ArtistRemoveContainer>
                <DeleteIcon iconSize={12} onClick={() => {}} disabled={true} />
              </ArtistRemoveContainer>
            </>
          ) : (
            <>
              <ArtistContainer>
                <ArtistLink artist={artist} truncateLength={35} />
              </ArtistContainer>
              <ArtistRemoveContainer>
                <DeleteIcon
                  iconSize={12}
                  onClick={() => removeAssociatedArtist(artist.id)}
                />
              </ArtistRemoveContainer>
            </>
          )}
        </AssociatedArtistDisplayContainer>
        {artistIdsToUnassociate.includes(artist.id) && (
          <AssociatedArtistRemoveMsgContainer>
            {intl.formatMessage({
              id: 'admin.userBasicInfo.form.unassociateWarning',
            })}
          </AssociatedArtistRemoveMsgContainer>
        )}
      </AssociatedArtistContainer>
    ));
  } else {
    return (
      <NoAssociatedArtists>
        {intl.formatMessage({
          id: 'admin.userBasicInfo.form.noAssociatedArtists',
        })}
      </NoAssociatedArtists>
    );
  }
};

const UserBasicInfoForm: React.FC<FormProps> = ({
  formikProps,
  validationErrors = {},
  associatedArtists,
}) => {
  const intl = useIntl();

  const hasEditArtistMemberPermission = usePermission(
    'artist.artistMember.edit'
  );

  const {
    loading: loadingCountries,
    error: errorCountries,
    data: dataCountries,
  } = GetCountries({
    skipPagination: true,
  });

  if (errorCountries) {
    return <LoadingError whatsBeingLoaded="this form" />;
  }

  if (loadingCountries || !dataCountries) {
    return <EditFormLoadingBlocks />;
  }

  const { setFieldValue, touched, errors, values } = formikProps;

  const resolveCountryByCountryCode = (
    countryData: Country[],
    countryCode: string
  ): Country | undefined =>
    countryData.find(country => country && country.countryCode === countryCode);

  const addAssociatedArtist = (artistId: number | undefined) => {
    setFieldValue('artistIdToAssociate', artistId);
  };

  const removeAssociatedArtist = (artistId: number) => {
    if (!values.artistIdsToUnassociate.includes(artistId)) {
      setFieldValue(
        'artistIdsToUnassociate',
        values.artistIdsToUnassociate.concat([artistId])
      );
    }
  };

  return (
    <GenericFormContainer dataQaId="user-about-section-edit-form">
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.userBasicInfo.userName',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="user-basic-info-form-first-name-formgroup"
          label={intl.formatMessage({
            id: 'admin.userBasicInfo.firstName',
          })}
          errorMsg={errorMsgForField('firstName', touched, errors)}
        >
          <StyledTextfield
            data-qaid="user-basic-info-form-first-name-field"
            id="firstName"
            name="firstName"
            value={values.firstName}
            onChange={(e: any) => {
              setFieldValue('firstName', e.target.value);
            }}
            maxLength={200}
          />
        </FormGroup>
        <Spacer mb={6} />
        <FormGroup
          data-qaid="user-basic-info-form-last-name-formgroup"
          label={intl.formatMessage({
            id: 'admin.userBasicInfo.lastName',
          })}
          errorMsg={errorMsgForField('lastName', touched, errors)}
        >
          <StyledTextfield
            data-qaid="user-basic-info-form-last-name-field"
            id="lastName"
            name="lastName"
            value={values.lastName}
            onChange={(e: any) => {
              setFieldValue('lastName', e.target.value);
            }}
            maxLength={200}
          />
        </FormGroup>
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.userBasicInfo.userHomeCity',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="user-basic-info-form-city-formgroup"
          label={intl.formatMessage({
            id: 'shared.homeCity',
          })}
          errorMsg={errorMsgForField('homeCity', touched, errors)}
        >
          <CitySelectorManualCSS
            parentContainer="admin-user-basic-info-form"
            disableTopCities
            disableClosestCities
            showInactiveCities
            initialValue={values.homeCity}
            value={values.homeCity}
            data-qaid="user-basic-info-form-city-selector"
            onChange={(city: City) => {
              setFieldValue('homeCity', city);
            }}
          />
        </FormGroup>
        <Spacer mb={6} />
        <FormGroup
          data-qaid="user-basic-info-form-city-other-formgroup"
          label={intl.formatMessage({
            id: 'admin.userBasicInfo.cityOther',
          })}
          errorMsg={errorMsgForField('cityOther', touched, errors)}
        >
          <StyledTextfield
            data-qaid="user-basic-info-form-city-other-field"
            id="cityOther"
            name="cityOther"
            value={values.cityOther}
            onChange={(e: any) => {
              setFieldValue('cityOther', e.target.value);
            }}
            maxLength={100}
          />
        </FormGroup>
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'shared.email',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="user-basic-info-form-email-formgroup"
          label={intl.formatMessage({
            id: 'shared.email',
          })}
          errorMsg={
            errorMsgForField('email', touched, errors) ||
            // @ts-ignore
            validationErrors.email
          }
          required
        >
          <StyledTextfield
            data-qaid="user-basic-info-form-email-field"
            id="email"
            name="email"
            value={values.email}
            onChange={(e: any) => {
              setFieldValue('email', e.target.value);
            }}
            maxLength={100}
          />
        </FormGroup>
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.userBasicInfo.heading.emailSettings',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup data-qaid="user-basic-info-form-email-setting-unlucky-formgroup">
          <Checkbox
            data-qaid="user-basic-info-form-email-setting-unlucky-field"
            id="user-basic-info-form-email-setting-unlucky-field"
            name="isUnluckyEmailEnabled"
            checked={values.isUnluckyEmailEnabled}
            onChange={() => {
              setFieldValue(
                'isUnluckyEmailEnabled',
                !values.isUnluckyEmailEnabled
              );
            }}
          >
            {intl.formatMessage({
              id: 'admin.userBasicInfo.emailSettingUnlucky',
            })}
          </Checkbox>
        </FormGroup>
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.userBasicInfo.mobile',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          label={intl.formatMessage({
            id: 'admin.userBasicInfo.mobile',
          })}
          data-qaid="user-basic-info-form-mobile-formgroup"
          errorMsg={
            errorMsgForField('mobile', touched, errors) ||
            // @ts-ignore
            validationErrors.mobile
          }
        >
          <Grid>
            <Col xs={12} md={4}>
              <Select
                data-qaid="user-basic-info-form-country-field"
                searchable
                getOptionLabel={(opt: any) => opt.title}
                defaultValue={
                  dataCountries &&
                  dataCountries.countries &&
                  dataCountries.countries.countries &&
                  resolveCountryByCountryCode(
                    dataCountries.countries.countries,
                    values.countryCode
                  )
                }
                options={
                  dataCountries &&
                  dataCountries.countries &&
                  dataCountries.countries.countries
                    ? dataCountries.countries.countries
                    : []
                }
                placeholder={intl.formatMessage({
                  id: 'admin.userBasicInfo.countryPlaceholder',
                })}
                onChange={country =>
                  setFieldValue(
                    'countryCode',
                    country && country.countryCode ? country.countryCode : ''
                  )
                }
              />
            </Col>
            <Col xs={12} md={6}>
              <Textfield
                data-qaid="user-basic-info-form-mobile-field"
                type="text"
                name="mobile"
                value={values.mobile}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                placeholder="352 781 160 4792"
              />
            </Col>
          </Grid>
        </FormGroup>
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.userBasicInfo.latitudeAndLongitude',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="user-basic-info-form-latitude-formgroup"
          label={intl.formatMessage({
            id: 'admin.userBasicInfo.latitude',
          })}
          errorMsg={errorMsgForField('latitude', touched, errors)}
        >
          <LatLongTextfield
            data-qaid="user-basic-info-form-latitude-field"
            id="latitude"
            name="latitude"
            value={values.latitude}
            onChange={(e: any) => {
              setFieldValue('latitude', e.target.value);
            }}
            maxLength={12}
          />
        </FormGroup>
        <Spacer mb={6} />
        <FormGroup
          data-qaid="user-basic-info-form-longitude-formgroup"
          label={intl.formatMessage({
            id: 'admin.userBasicInfo.longitude',
          })}
          errorMsg={errorMsgForField('longitude', touched, errors)}
        >
          <LatLongTextfield
            data-qaid="user-basic-info-form-longitude-field"
            id="longitude"
            name="longitude"
            value={values.longitude}
            onChange={(e: any) => {
              setFieldValue('longitude', e.target.value);
            }}
            maxLength={12}
          />
        </FormGroup>
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.userBasicInfo.gender',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="user-basic-info-form-gender-formgroup"
          label={intl.formatMessage({
            id: 'admin.userBasicInfo.gender',
          })}
          errorMsg={errorMsgForField('gender', touched, errors)}
        >
          <Select
            data-qaid="user-basic-info-form-gender-field"
            name="gender"
            id="gender"
            onChange={option => {
              setFieldValue('gender', option);
            }}
            options={genderOptions.gender}
            getOptionLabel={(opt: any) => opt.label}
            hasError={!!errorMsgForField('gender', touched, errors)}
            placeholder={intl.formatMessage({
              id: 'admin.userBasicInfo.genderPlaceholder',
            })}
            defaultValue={values.gender}
            initialWidth="250px"
          />
        </FormGroup>
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.userBasicInfo.birthYear',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="user-basic-info-form-birth-year-formgroup"
          label={intl.formatMessage({
            id: 'admin.userBasicInfo.birthYearWithExplanation',
          })}
          errorMsg={errorMsgForField('birthYear', touched, errors)}
        >
          <Select
            data-qaid="user-basic-info-form-birth-year-field"
            name="birthYear"
            id="birthYear"
            onChange={option => {
              setFieldValue('birthYear', option);
            }}
            options={birthYearOptions().birthYear}
            getOptionLabel={(opt: any) => opt.label}
            hasError={!!errorMsgForField('birthYear', touched, errors)}
            placeholder={intl.formatMessage({
              id: 'admin.userBasicInfo.birthYearPlaceholder',
            })}
            defaultValue={values.birthYear}
            initialWidth="250px"
          />
        </FormGroup>
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.userBasicInfo.generalNotes',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="user-basic-info-form-general-notes-formgroup"
          label={intl.formatMessage({
            id: 'admin.userBasicInfo.generalNotesWithExplanation',
          })}
          errorMsg={errorMsgForField('generalNotes', touched, errors)}
        >
          <GeneralNotesTextarea
            data-qaid="user-basic-info-form-general-notes-field"
            id="generalNotes"
            name="generalNotes"
            value={values.generalNotes}
            onChange={(e: any) => {
              setFieldValue('generalNotes', e.target.value);
            }}
            maxLength={300}
          />
        </FormGroup>
      </FormGroupContainer>
      <FormSectionTitle>
        {intl.formatMessage({
          id: 'admin.userBasicInfo.acquisition',
        })}
      </FormSectionTitle>
      <FormGroupContainer>
        <FormGroup
          data-qaid="user-basic-info-form-how-heard-about-formgroup"
          label={intl.formatMessage({
            id: 'admin.userBasicInfo.howHeardAbout',
          })}
          errorMsg={errorMsgForField('howHeardAbout', touched, errors)}
        >
          <StyledTextfield
            data-qaid="user-basic-info-form-how-heard-about-field"
            id="howHeardAbout"
            name="howHeardAbout"
            value={values.howHeardAbout}
            onChange={(e: any) => {
              setFieldValue('howHeardAbout', e.target.value);
            }}
            maxLength={200}
          />
        </FormGroup>
        <Spacer mb={6} />
      </FormGroupContainer>
      {hasEditArtistMemberPermission && (
        <>
          <FormSectionTitle>
            {intl.formatMessage({
              id: 'admin.userBasicInfo.form.associatedArtists',
            })}
          </FormSectionTitle>
          <FormGroupContainer>
            {renderAssociatedArtists(
              associatedArtists,
              values.artistIdsToUnassociate,
              removeAssociatedArtist,
              intl
            )}
            <FormGroup
              data-qaid="associated-artists-formgroup"
              label=""
              // @ts-ignore
              errorMsg={validationErrors.artist_id}
            >
              <AddAssociatedArtistContainer>
                <AddAssociatedArtist>
                  {intl.formatMessage({
                    id: 'admin.userBasicInfo.form.addArtist',
                  })}
                </AddAssociatedArtist>
                <ArtistTypeahead
                  setSelectedArtist={(artist: Artist | undefined) => {
                    addAssociatedArtist(artist && artist.id);
                  }}
                />
              </AddAssociatedArtistContainer>
            </FormGroup>
            <MultipleAssociatedArtistsWarning>
              {intl.formatMessage({
                id: 'admin.userBasicInfo.form.multipleArtistsWarning',
              })}
            </MultipleAssociatedArtistsWarning>
          </FormGroupContainer>
        </>
      )}
    </GenericFormContainer>
  );
};

export default UserBasicInfoForm;
