import styled, { css } from 'styled-components';

const ThumbnailCircle = styled.div`
  ${({ theme }) => css`
    width: ${theme.ruler[12]}px;
    height: ${theme.ruler[12]}px;
    border-radius: ${theme.ruler[10]}px;
    background-color: #c4c4c4;
    overflow: hidden;
    cursor: pointer;
    margin-right: 16px;
  `}
`;

export default ThumbnailCircle;
