import React from 'react';
import { utcToZonedTime } from 'date-fns-tz';
import moment from 'moment';
import { useIntl } from 'react-intl';

import { EventCollection, EventPreview, Property } from 'app/typings';
import { dateFormatter } from 'app/shared/utils/datetime';
import {
  getDateSubmitVariable,
  getTimeSubmitVariable,
} from 'app/shared/utils/datetime';
import {
  getCurrencyFormInitialValue,
  getCurrencyRoundedFormInitialValue,
  getCurrencySubmitVariable,
} from 'app/shared/utils/form';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import GenericForm from 'app/shared/components/atoms/GenericForm';
import { ModalContentContainer } from 'app/shared/components/molecules/RoutableModal/ModalContentContainer';
import { UpdateEvent } from 'app/admin/graphql/events/mutationHooks';
import { DetailsFormMainContainer } from 'app/admin/components/atoms/DetailContent';
import { DetailsHeader } from 'app/admin/components/organisms/DetailsHeader';

import EventBasicInfoEditForm from './EventBasicInfoEditForm';
import EventBasicInfoEditFormSchema from './EventBasicInfoEditFormSchema';

interface Props {
  contentProps?: EventPreview;
  setFormSubmitAction: (func: Function) => void;
  setDisplayConfirmation: (set: boolean) => void;
  setIsSubmitting: (set: boolean) => void;
  navigateTo: (routeData: object) => void;
}

const getPrimaryEventCollectionId = (eventCollections?: EventCollection[]) =>
  eventCollections?.find(
    (eventCollection: EventCollection) => eventCollection.isPrimaryCollection
  );

const getMerchandisingProperties = (merchandisingProperties?: any[]) =>
  merchandisingProperties?.reduce(
    (obj: any, property: Property) => ({
      ...obj,
      [property.propertyGroupName || '']: property.id,
    }),
    {}
  ) || {};

const EventBasicInfoEdit: React.FC<Props> = ({
  contentProps,
  setFormSubmitAction,
  setDisplayConfirmation,
  setIsSubmitting,
  navigateTo,
}) => {
  const intl = useIntl();

  const localStartsAt = contentProps?.localStartsAt || '';
  const cityTitle = contentProps?.city?.title;
  const neighborhoodTitle = contentProps?.neighborhood?.title;
  const venueId = contentProps?.venue?.id;
  const eventId = Number(contentProps?.id);
  const status = contentProps?.status;
  const timingNotes = contentProps?.timingNotes || '';
  const eventOrganizedAs = contentProps?.eventOrganizedAs;
  const cityHasTieredPrice = contentProps?.city?.hasTieredPrice || false;
  const cityTicketPrice = contentProps?.cityTicketPrice;
  const localOnSaleAt: moment.Moment | '' = contentProps?.onSaleAt
    ? moment(
        utcToZonedTime(
          new Date(contentProps.onSaleAt),
          contentProps.city.timezone || ''
        )
      )
    : '';
  const isSpecialEvent = contentProps?.isSpecialEvent || false;
  const specialEventUrl = contentProps?.specialEventUrl;
  const eventFormatExperimentId =
    contentProps?.eventFormatExperiment?.id || null;
  const externalEventNotes = contentProps?.externalEventNotes || ''; // Textarea field needs a string input
  const eventCollections = contentProps?.eventCollections;

  const getDateFormInitialValue = (dateTimeStr?: string) =>
    dateTimeStr && dateTimeStr.split('T')[0];

  const getTimeFormInitialValue = (time: string | undefined) => {
    if (!time || !contentProps) {
      return undefined;
    }

    return dateFormatter(
      time,
      'yearMonthDateHoursMinutesAndSeconds',
      contentProps.city.timezone
    );
  };

  const formInitialValues = {
    eventType: contentProps?.type,
    eventDate: getDateFormInitialValue(localStartsAt),
    onSaleAt: localOnSaleAt,
    localCrewLoadInAt: getTimeFormInitialValue(contentProps?.crewLoadInAt),
    localArtistLoadInAt: getTimeFormInitialValue(contentProps?.artistLoadInAt),
    localGuestsArriveAt: getTimeFormInitialValue(contentProps?.guestsArriveAt),
    localStartsAt: getTimeFormInitialValue(contentProps?.startsAt),
    localEndsAt: getTimeFormInitialValue(contentProps?.endsAt),
    timingNotes,
    capacity: contentProps?.venue?.capacity,
    numTicketsAvailableForSale: contentProps?.numTicketsAvailableForSale,
    ticketPrice: getCurrencyRoundedFormInitialValue(contentProps, 'eventPrice'),
    currencySymbol: contentProps?.currencySymbol || '$',
    businessOwnerId: contentProps?.businessOwner?.id || null,
    sofarOperatedMarketType: contentProps?.sofarOperatedMarketType,
    formatType: contentProps?.formatType,
    eventArtistCompensation: getCurrencyFormInitialValue(
      contentProps,
      'eventArtistCompensation'
    ),
    artistCompensation: contentProps?.artistCompensation || '',
    attendeeFlow: contentProps?.attendeeFlow,
    isFilmed: contentProps?.isFilmed || false,
    theme: contentProps?.theme?.title,
    primaryEventCollectionId: getPrimaryEventCollectionId(eventCollections),
    headline: contentProps?.headline,
    merchandisingProperties: getMerchandisingProperties(
      contentProps?.merchandisingProperties
    ),
    competition: contentProps?.competition,
    competitionsToUnassociate: [],
    dayOfWeek: new Date(localStartsAt),
    cityTitle,
    cityHasTieredPrice,
    cityTicketPrice,
    isSpecialEvent,
    specialEventUrl,
    isEventFormatExperiment: !!eventFormatExperimentId,
    eventFormatExperimentId,
    externalEventNotes,
  };

  const updateEventAction = UpdateEvent();

  const handleUpdateEvent = useSubmitAction({
    submitAction: updateEventAction,
    submitVariables: (values: typeof formInitialValues) => ({
      eventId,
      eventDate: getDateSubmitVariable(values.eventDate),
      onSaleAt: getDateSubmitVariable(
        values.onSaleAt && values.onSaleAt.toISOString()
      ),
      localCrewLoadInAt: getTimeSubmitVariable(values.localCrewLoadInAt),
      localArtistLoadInAt: getTimeSubmitVariable(values.localArtistLoadInAt),
      localGuestsArriveAt: getTimeSubmitVariable(values.localGuestsArriveAt),
      localStartsAt: getTimeSubmitVariable(values.localStartsAt),
      localEndsAt: getTimeSubmitVariable(values.localEndsAt),
      timingNotes: values.timingNotes,
      numTicketsAvailableForSale: values.numTicketsAvailableForSale?.toString(),
      ticketPrice:
        values.attendeeFlow == 'buy'
          ? getCurrencySubmitVariable(values.ticketPrice, null)
          : undefined,
      businessOwnerId:
        eventOrganizedAs === 'o_and_o' ? values.businessOwnerId : null,
      sofarOperatedMarketType:
        eventOrganizedAs === 'o_and_o' ? values.sofarOperatedMarketType : null,
      formatType: values.formatType,
      eventArtistCompensation: getCurrencySubmitVariable(
        values.eventArtistCompensation
      ),
      isFilmed: values.isFilmed,
      theme: values.theme ? values.theme : null,
      primaryEventCollectionId: values.primaryEventCollectionId,
      headline: values.headline,
      merchandisingProperties: Object.values(
        values.merchandisingProperties
      ).filter(n => n),
      competitionsToUnassociate: values.competitionsToUnassociate,
      isSpecialEvent: values.isSpecialEvent,
      specialEventUrl: values.specialEventUrl,
      eventFormatExperimentId:
        eventOrganizedAs === 'o_and_o' && values.isEventFormatExperiment
          ? values.eventFormatExperimentId
          : null,
      externalEventNotes: values.externalEventNotes,
    }),
    successMsg: intl.formatMessage({
      id: 'admin.eventBasicInfo.successMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.eventBasicInfo.failureMessage',
    }),
    onSuccess: (response: any) => {
      navigateTo({
        routeName: 'event-details',
        routeProps: {
          defaultOpenSection: 'basicInfo',
          ...response.data.updateEvent.event,
        },
      });
    },
  });

  return (
    <ModalContentContainer>
      <DetailsFormMainContainer>
        <DetailsHeader
          title={cityTitle}
          subTitle={neighborhoodTitle}
          loading={!(!!cityTitle || !!localStartsAt)}
          imageStyle="calendar"
          detailsDate={localStartsAt}
        />
        <GenericForm
          formInitialValues={formInitialValues}
          renderFormComponent={(renderProps: any) => (
            <EventBasicInfoEditForm
              formikProps={renderProps.formikProps}
              venueId={venueId}
              eventId={eventId}
              status={status}
              eventOrganizedAs={eventOrganizedAs}
              eventCollections={eventCollections}
            />
          )}
          onSubmit={handleUpdateEvent}
          formSchema={EventBasicInfoEditFormSchema(intl, venueId)}
          setFormSubmitAction={setFormSubmitAction}
          setDisplayConfirmation={setDisplayConfirmation}
          setIsSubmitting={setIsSubmitting}
          dataQaId="event-basic-info-edit-form"
        />
      </DetailsFormMainContainer>
    </ModalContentContainer>
  );
};

export default EventBasicInfoEdit;
