import * as Yup from 'yup';

const guestlistVipEditFormSchema = (intl: any) =>
  Yup.object().shape({
    fullName: Yup.string().required(
      intl.formatMessage({
        id: 'form.requiredField',
      })
    ),
    email: Yup.string()
      .email(
        intl.formatMessage(
          {
            id: 'form.fieldMustBeValidEmail',
          },
          {
            field: 'Email',
          }
        )
      )
      .required(
        intl.formatMessage({
          id: 'form.requiredField',
        })
      ),
    reference: Yup.string().required(
      intl.formatMessage({
        id: 'form.requiredField',
      })
    ),
    ticketsCount: Yup.string()
      .nullable()
      .required(
        intl.formatMessage({
          id: 'form.requiredField',
        })
      )
      .matches(/^[1-9][0-9]?$/, {
        message: intl.formatMessage({
          id: 'admin.guestlist.vipEdit.ticketsCountError',
        }),
      }),
  });

export default guestlistVipEditFormSchema;
