import React from 'react';

import { Badge } from 'app/shared/components/atoms/Badge';
import { Icon } from 'app/shared/components/atoms/IconManualCSS';

const Ellipsis: React.FC = () => (
  <Badge>
    <Icon name="ellipsisHorizontal" />
  </Badge>
);

export default Ellipsis;
