import React from 'react';

import { LoadingBlocks } from 'app/shared/components/atoms/LoadingBlocks';

const EditFormLoadingBlocks: React.FC = () => {
  return (
    <div data-qaid="loading-blocks">
      <LoadingBlocks.Rectangle height="400px" width="50%" mb={1} />
    </div>
  );
};

export default EditFormLoadingBlocks;
