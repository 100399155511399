import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import * as Yup from 'yup';

import { EventAttendee } from 'app/typings/eventAttendees';
import { Charge, Event } from 'app/typings/events';
import { Ticket } from 'app/typings/tickets';
import { currencyFormatter } from 'app/shared/utils/currencyFormatter';
import { dateFormatter } from 'app/shared/utils/datetime';
import { get } from 'app/shared/utils/get';
import { UseSubmitAction as useSubmitAction } from 'app/shared/utils/useSubmitAction';
import { UpdateTickets } from 'app/shared/graphql/tickets/mutationHooks';
import {
  ContentWrapper,
  FlyoverBackLink,
  FlyoverLoadingIndicator,
  FlyoverNote,
} from 'app/shared/components/atoms/FlyoverContent';
import { EditIcon } from 'app/shared/components/atoms/IconLibrary';
import { MenuItem } from 'app/shared/components/atoms/Menu';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Textfield } from 'app/shared/components/atoms/Textfield';
import {
  Body2,
  Overline,
} from 'app/shared/components/atoms/TypographyManualCSS';
import RadioGroup from 'app/shared/components/molecules/RadioGroup';
import Select from 'app/shared/components/molecules/SelectManualCSS';
import { eventAttendeeFullName } from 'app/admin/utils/guestlist';
import {
  RefundEventAttendee,
  TransferEventAttendee,
  UpdateEventAttendeeTickets,
} from 'app/admin/graphql/eventAttendees/mutationHooks';
import { GetEventAttendeeForAdmin } from 'app/admin/graphql/eventAttendees/queryHooks';
import {
  LoadingError,
  LoadingIndicator,
} from 'app/admin/components/atoms/GuestlistLoading';
import FlyoverFooter from 'app/admin/components/molecules/FlyoverFooter';
import { GuestlistAttendeeDetailsHeader } from 'app/admin/components/molecules/GuestlistAttendeeDetailsHeader';
import { NumberTextfield } from 'app/admin/components/molecules/Textfield';

import { EventsForTransfer } from './EventsForTransfer';

interface Props {
  eventAttendeeId?: number;
  event?: Event;
  onSuccess: Function;
}

const InputWrapper = styled.div`
  display: flex;
`;

const IconWrapper = styled.div`
  display: inline-block;
  cursor: pointer;
  padding-left: 4px;
`;

const SelectNumTicketsToRemoveWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 15px;
`;

const SelectNumTicketsWrapper = styled.div`
  margin-top: 10px;
`;

const RefundAmountWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 15px;
`;

const ValidationErrors = styled(FlyoverNote)`
  ${({ theme }) => css`
    color: ${theme.colors.redRedWine};
    margin-top: 7px;
  `}
`;

const ValidationError = styled.div`
  margin-top: 3px;
`;

const TicketHeader = styled.span`
  font-size: 14px;
  font-weight: 600;
`;

const EditTicketHeader = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

const TicketFields = styled.ul`
  margin-top: 4px;
  font-size: 14px;
  padding-inline-start: 24px;
`;

const EditTicketMessage = styled.div`
  font-size: 14px;
  font-style: italic;
  padding-top: 16px;
`;

const actions = {
  selectTransferEvent: 'admin.guestlist.attendeeActions.actionTransfer',
  selectRefundTickets: 'admin.guestlist.attendeeActions.actionRefund',
  selectNumTickets: 'admin.guestlist.attendeeActions.actionNumTickets',
  selectGuestInfo: 'admin.guestlist.attendeeActions.actionGuestInfo',
};

const actionOptions = (
  attendeeFlow: string | undefined,
  eventAttendee: EventAttendee,
  intl: any
) => {
  const actionKeys = Object.keys(actions).filter(
    (key: string) =>
      !(
        (key === 'selectRefundTickets' && attendeeFlow === 'apply') ||
        (key === 'selectGuestInfo' && eventAttendee.tickets.length === 0)
      )
  );
  return actionKeys.map((key: string) => ({
    title: intl.formatMessage({
      id: actions[key],
    }),
    value: key,
  }));
};

const maxNumTicketsPerOrder = 10;

const maxNumTicketsForThisAttendee = (
  eventAttendee?: EventAttendee,
  event?: Event
) => {
  if (
    eventAttendee &&
    (eventAttendee.currentState == 'applied' ||
      eventAttendee.currentState == 'guestlisted')
  ) {
    return 2;
  } else if (event && eventAttendee && eventAttendee.ticketsCount) {
    return eventAttendee.ticketsCount + event.remainingSpaces;
  } else {
    return 0;
  }
};

const numTicketsOptions = (eventAttendee?: EventAttendee, event?: Event) => {
  const maxNumTickets = maxNumTicketsForThisAttendee(eventAttendee, event);
  const maxNum = Math.min(maxNumTickets, maxNumTicketsPerOrder);
  if (maxNum == 0) {
    return [];
  } else {
    const numList = [...Array(maxNum + 1).keys()];
    numList.shift();
    return numList.map((n: number) => ({
      title: n.toString(),
      value: n,
    }));
  }
};

const numTicketsToRemoveOptions = (eventAttendee?: EventAttendee) => {
  const maxNum = (eventAttendee && eventAttendee.ticketsCount) || 0;
  const numList = [...Array(maxNum + 1).keys()];
  return numList.map((n: number) => ({
    title: n.toString(),
    value: n,
  }));
};

const renderValidationErrors = (
  validationErrors: object,
  extraContentAfterError?: string
) =>
  Object.keys(validationErrors).length > 0 && (
    <ValidationErrors>
      {Object.keys(validationErrors).map((error: string, index: number) => (
        <ValidationError key={index}>
          {validationErrors[error]}
          {extraContentAfterError || ''}
        </ValidationError>
      ))}
    </ValidationErrors>
  );

const stripeUrl = (chargeUid: string) =>
  `https://dashboard.stripe.com/payment/${chargeUid}`;

const externalRedirect = (url: string) => {
  window.open(url, '_blank');
};

const GuestlistAttendeeActions: React.FC<Props> = ({
  eventAttendeeId,
  event,
  onSuccess,
}) => {
  const intl = useIntl();
  const [actionState, setActionState] = useState('selectAction');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [actionValue, setActionValue] = useState('selectTransferEvent');
  const [newTransferEvent, setNewTransferEvent] = useState<Event | undefined>(
    undefined
  );
  const [numTicketsToRemove, setNumTicketsToRemove] = useState<number>(0);
  const [refundAmount, setRefundAmount] = useState<string>('');
  const [numTickets, setNumTickets] = useState(1);
  const [validationErrors, setValidationErrors] = useState<object>({});
  const [ticketState, setTicketState] = useState<{
    id?: number;
    index?: number;
    firstName?: string;
    lastName?: string;
    email?: string;
    primary?: boolean;
  }>({});
  const [ticketValues, setTicketValues] = useState<{
    firstName?: string;
    lastName?: string;
    email?: string;
  }>({});
  const selectTransferContainerRef = useRef<any>();

  const { data, loading, error } = GetEventAttendeeForAdmin({
    id: eventAttendeeId,
  });

  const eventAttendee = get(data, 'eventAttendee', undefined);

  const numTicketsCurrent = eventAttendee ? eventAttendee.ticketsCount : 1;

  const fullName = eventAttendeeFullName(eventAttendee, intl);

  const amountPaid = currencyFormatter(
    (eventAttendee && eventAttendee.totalTicketCost) || 0,
    (event && event.currencySymbol) || ''
  );

  const newTransferEventDate =
    newTransferEvent &&
    dateFormatter(newTransferEvent.localStartsAt, 'shortMonthDateAndYear');
  const newTransferEventLocation =
    newTransferEvent && newTransferEvent.venue?.neighborhood?.title
      ? newTransferEvent.venue.neighborhood.title
      : newTransferEvent && newTransferEvent.city.title;

  const validateRefundAmount = () => {
    if (/[^\d.]/.test(refundAmount)) {
      setValidationErrors({
        general: intl.formatMessage({
          id: 'admin.guestlist.attendeeActions.refundAmountValidationInvalid',
        }),
      });
      return false;
    } else if (
      Math.round(Number(refundAmount) * 100) >
      (eventAttendee.totalTicketCost || 0)
    ) {
      setValidationErrors({
        general: intl.formatMessage(
          {
            id: 'admin.guestlist.attendeeActions.refundAmountValidationTooHigh',
          },
          {
            amountPaid,
          }
        ),
      });
      return false;
    }
    return true;
  };

  const transferEventAttendeeAction = TransferEventAttendee();

  const handleTransferEventAttendee = useSubmitAction({
    submitAction: transferEventAttendeeAction,
    submitVariables: () => ({
      eventAttendeeId,
      newEventId: newTransferEvent && newTransferEvent.id,
    }),
    successMsg: intl.formatMessage(
      {
        id:
          numTicketsCurrent == 1
            ? 'admin.guestlist.attendeeActions.transferSuccessMessageSingleGuest'
            : 'admin.guestlist.attendeeActions.transferSuccessMessage',
      },
      {
        name: fullName,
        ticketsCount: numTicketsCurrent.toString(),
        date: newTransferEventDate,
        location: newTransferEventLocation,
      }
    ),
    failureMsg: intl.formatMessage({
      id: 'admin.guestlist.attendeeActions.transferFailureMessage',
    }),
    onSuccess: () => {
      setIsSubmitting(false);
      onSuccess();
    },
    onValidationError: validationErrors => {
      setIsSubmitting(false);
      setValidationErrors(validationErrors);
    },
  });

  const refundEventAttendeeAction = RefundEventAttendee();

  const handleRefundEventAttendee = useSubmitAction({
    submitAction: refundEventAttendeeAction,
    submitVariables: () => ({
      eventAttendeeId,
      numTicketsToRemove,
      refundAmount: Math.round(Number(refundAmount) * 100),
    }),
    successMsg: intl.formatMessage(
      {
        id:
          numTicketsToRemove == numTicketsCurrent
            ? 'admin.guestlist.attendeeActions.refundSuccessMessageMoveToCantGo'
            : numTicketsToRemove == 1
            ? 'admin.guestlist.attendeeActions.refundSuccessMessageSingleTicket'
            : 'admin.guestlist.attendeeActions.refundSuccessMessage',
      },
      {
        name: fullName,
        amount: currencyFormatter(
          Number(refundAmount) * 100,
          (event && event.currencySymbol) || ''
        ),
        numTickets: numTicketsToRemove.toString(),
      }
    ),
    failureMsg: intl.formatMessage({
      id: 'admin.guestlist.attendeeActions.refundFailureMessage',
    }),
    onSuccess: (response: any) => {
      setIsSubmitting(false);
      const chargeUids = get(
        response,
        'data.refundEventAttendee.eventAttendee.order.charges',
        []
      ).map((charge: Charge) => charge.uid);
      externalRedirect(stripeUrl(chargeUids[0]));
      onSuccess();
    },
  });

  const updateEventAttendeeTicketsAction = UpdateEventAttendeeTickets();

  const handleUpdateEventAttendeeTickets = useSubmitAction({
    submitAction: updateEventAttendeeTicketsAction,
    submitVariables: () => ({
      eventAttendeeId,
      numTickets,
    }),
    successMsg: intl.formatMessage(
      {
        id: 'admin.guestlist.attendeeActions.numTicketsSuccessMessage',
      },
      {
        name: fullName,
        numTickets: numTickets.toString(),
      }
    ),
    failureMsg: intl.formatMessage({
      id: 'admin.guestlist.attendeeActions.numTicketsFailureMessage',
    }),
    onSuccess: () => {
      setIsSubmitting(false);
      onSuccess();
    },
  });

  const updateTicketsAction = UpdateTickets();
  const submitValues = { ...ticketState, ...ticketValues };

  const handleUpdateTickets = useSubmitAction({
    submitAction: updateTicketsAction,
    submitVariables: () => ({
      tickets: [
        {
          id: submitValues.id,
          firstName: submitValues.firstName,
          lastName: submitValues.lastName,
          email: submitValues.email,
        },
      ],
    }),
    successMsg: intl.formatMessage({
      id: 'admin.guestlist.attendeeActions.updateTicketSuccessMessage',
    }),
    failureMsg: intl.formatMessage({
      id: 'admin.guestlist.attendeeActions.updateTicketFailureMessage',
    }),
    onSuccess: () => {
      setIsSubmitting(false);
      setActionState('selectGuestInfo');
    },
  });

  useEffect(() => {
    if (eventAttendee) {
      setNumTickets(numTicketsCurrent);
    }
  }, [eventAttendee, numTicketsCurrent]);

  if (loading) {
    return <LoadingIndicator />;
  }

  if ((!loading && !data) || error) {
    return <LoadingError />;
  }

  const SelectAction = () => {
    const options = actionOptions(
      event && event.attendeeFlow,
      eventAttendee,
      intl
    );

    return (
      <>
        <ContentWrapper>
          <Overline>
            {intl.formatMessage({
              id: 'admin.guestlist.attendeeActions.chooseAnAction',
            })}
          </Overline>
          <RadioGroup
            name="action"
            options={options}
            selectedValue={actionValue}
            onChange={value => setActionValue(value)}
          />
        </ContentWrapper>
        <FlyoverFooter
          buttonText={intl.formatMessage({
            id: 'admin.guestlist.next',
          })}
          onClickButton={() => {
            setActionState(actionValue);
          }}
        />
      </>
    );
  };

  const SelectTransferEvent = () => {
    return (
      <>
        <ContentWrapper ref={selectTransferContainerRef}>
          <Overline>
            {intl.formatMessage({
              id: 'admin.guestlist.attendeeActions.selectANewConcert',
            })}
          </Overline>
          <FlyoverNote>
            {intl.formatMessage({
              id:
                'admin.guestlist.attendeeActions.transferRequestsAtLeastXHours',
            })}
          </FlyoverNote>
          <EventsForTransfer
            currentEventId={event && event.id}
            city={event && event.city}
            eventType={event && event.type}
            attendeeFlow={event && event.attendeeFlow}
            minRemainingSpaces={numTicketsCurrent}
            selectedEvent={newTransferEvent}
            setEvent={(event: Event) => setNewTransferEvent(event)}
            containerRef={selectTransferContainerRef}
          />
          <Spacer mt={8} />
        </ContentWrapper>
        <FlyoverFooter
          buttonText={intl.formatMessage({
            id: 'admin.guestlist.next',
          })}
          buttonDisabled={!newTransferEvent}
          onClickButton={() => {
            setActionState('confirmTransfer');
          }}
        />
      </>
    );
  };

  const ConfirmTransfer = () => {
    return (
      <>
        <ContentWrapper>
          <FlyoverNote>
            {intl.formatMessage(
              {
                id:
                  numTicketsCurrent == 1
                    ? 'admin.guestlist.attendeeActions.confirmationTransferSingleGuest'
                    : 'admin.guestlist.attendeeActions.confirmationTransfer',
              },
              {
                ticketsCount: numTicketsCurrent.toString(),
                date: newTransferEventDate,
                location: newTransferEventLocation,
              }
            )}
          </FlyoverNote>
          {renderValidationErrors(validationErrors)}
        </ContentWrapper>
        <FlyoverFooter
          buttonText={intl.formatMessage({
            id: 'admin.guestlist.update',
          })}
          onClickButton={() => {
            setIsSubmitting(true);
            handleTransferEventAttendee();
          }}
        />
      </>
    );
  };

  const SelectRefundTickets = () => {
    const options = numTicketsToRemoveOptions(eventAttendee);

    return (
      <>
        <ContentWrapper>
          <Overline>
            {intl.formatMessage({
              id: 'admin.guestlist.attendeeActions.ticketsToBeReleased',
            })}
          </Overline>
          <SelectNumTicketsToRemoveWrapper>
            <Select
              placeholder=""
              data-qaid="select-num-tickets-to-remove"
              value={{
                title: numTicketsToRemove.toString(),
                value: numTicketsToRemove,
              }}
              options={options}
              onChange={opt => setNumTicketsToRemove(opt.value)}
              getOptionLabel={(opt: any) => opt.title}
              renderOption={(opt, props) => (
                <MenuItem
                  key={opt.value}
                  {...props}
                  className="suppress-outside-click"
                >
                  <Body2 as="span" className="suppress-outside-click">
                    {opt.title}
                  </Body2>
                </MenuItem>
              )}
              initialWidth="120px"
            />
          </SelectNumTicketsToRemoveWrapper>
          <Overline>
            {intl.formatMessage(
              {
                id: 'admin.guestlist.attendeeActions.amountToBeRefunded',
              },
              {
                currencySymbol: (event && event.currencySymbol) || '',
              }
            )}
          </Overline>
          <RefundAmountWrapper>
            <NumberTextfield
              key="refundAmount"
              data-qaid="refund-amount"
              id="refundAmount"
              name="refundAmount"
              value={refundAmount}
              onChange={(e: any) => {
                setRefundAmount(e.target.value);
                setValidationErrors({});
              }}
              maxLength={6}
            />
          </RefundAmountWrapper>
          {renderValidationErrors(validationErrors)}
        </ContentWrapper>
        <FlyoverFooter
          buttonText={intl.formatMessage({
            id: 'admin.guestlist.next',
          })}
          buttonDisabled={!refundAmount}
          onClickButton={() => {
            if (validateRefundAmount()) {
              setActionState('confirmRefund');
              setValidationErrors({});
            }
          }}
        />
      </>
    );
  };

  const ConfirmRefund = () => {
    return (
      <>
        <ContentWrapper>
          <Overline>
            {intl.formatMessage({
              id: 'admin.guestlist.attendeeActions.refund',
            })}
          </Overline>
          <FlyoverNote>
            {intl.formatMessage(
              {
                id:
                  numTicketsToRemove == 0
                    ? 'admin.guestlist.attendeeActions.confirmationRefundWhenYouGoNotChanged'
                    : numTicketsToRemove == numTicketsCurrent
                    ? 'admin.guestlist.attendeeActions.confirmationRefundWhenYouGoMoveToCantGo'
                    : 'admin.guestlist.attendeeActions.confirmationRefundWhenYouGo',
              },
              {
                name: fullName,
                ticketsCountOld: numTicketsCurrent.toString(),
                ticketsCountNew: (
                  numTicketsCurrent - numTicketsToRemove
                ).toString(),
              }
            )}
          </FlyoverNote>
          <FlyoverNote>
            {intl.formatMessage(
              {
                id:
                  Number(refundAmount) == 0
                    ? 'admin.guestlist.attendeeActions.confirmationRefundAmountPaidNotChanged'
                    : 'admin.guestlist.attendeeActions.confirmationRefundAmountPaid',
              },
              {
                amountPaidOld: amountPaid,
                amountPaidNew: currencyFormatter(
                  (eventAttendee.totalTicketCost || 0) -
                    Number(refundAmount) * 100,
                  (event && event.currencySymbol) || ''
                ),
              }
            )}
          </FlyoverNote>
          <FlyoverNote>
            {intl.formatMessage({
              id:
                'admin.guestlist.attendeeActions.confirmationRefundActualRefund',
            })}
          </FlyoverNote>
        </ContentWrapper>
        <FlyoverFooter
          buttonText={intl.formatMessage({
            id: 'admin.guestlist.attendeeActions.goToStripe',
          })}
          onClickButton={() => {
            setIsSubmitting(true);
            handleRefundEventAttendee();
          }}
        />
      </>
    );
  };

  const SelectNumTickets = () => {
    const options = numTicketsOptions(eventAttendee, event);

    return (
      <>
        <>
          <ContentWrapper>
            <Overline>
              {intl.formatMessage({
                id: 'admin.guestlist.attendeeActions.selectNumberOfTickets',
              })}
            </Overline>
            <SelectNumTicketsWrapper>
              <InputWrapper>
                <Select
                  placeholder=""
                  data-qaid="select-num-tickets"
                  value={{
                    title: numTickets.toString(),
                    value: numTickets,
                  }}
                  options={options}
                  onChange={opt => setNumTickets(opt.value)}
                  getOptionLabel={(opt: any) => opt.title}
                  renderOption={(opt, props) => (
                    <MenuItem
                      key={opt.value}
                      {...props}
                      className="suppress-outside-click"
                    >
                      <Body2 as="span" className="suppress-outside-click">
                        {opt.title}
                      </Body2>
                    </MenuItem>
                  )}
                  initialWidth="120px"
                />
              </InputWrapper>
            </SelectNumTicketsWrapper>
          </ContentWrapper>
          <FlyoverFooter
            buttonText={intl.formatMessage({
              id: 'admin.guestlist.next',
            })}
            buttonDisabled={numTickets === numTicketsCurrent}
            onClickButton={() => {
              setActionState('confirmNumTickets');
            }}
          />
        </>
      </>
    );
  };

  const ConfirmNumTickets = () => {
    return (
      <>
        <ContentWrapper>
          <FlyoverNote>
            {intl.formatMessage(
              {
                id: 'admin.guestlist.attendeeActions.confirmationNumTickets',
              },
              {
                numTicketsCurrent: numTicketsCurrent.toString(),
                numTickets: numTickets.toString(),
              }
            )}
          </FlyoverNote>
        </ContentWrapper>
        <FlyoverFooter
          buttonText={intl.formatMessage({
            id: 'admin.guestlist.update',
          })}
          onClickButton={() => {
            setIsSubmitting(true);
            handleUpdateEventAttendeeTickets();
          }}
        />
      </>
    );
  };

  const SelectGuestInfo = () => {
    return (
      <ContentWrapper>
        {eventAttendee.tickets
          .sort((a: Ticket, b: Ticket) => (a.id > b.id ? 1 : -1))
          .map((ticket: Ticket, index: number) => (
            <div key={index}>
              <TicketHeader>
                {intl.formatMessage({
                  id: 'admin.guestlist.attendeeActions.ticket',
                })}{' '}
                {index + 1}{' '}
                <IconWrapper
                  onClick={() => {
                    setTicketValues({});
                    setTicketState({ ...ticket, index });
                    setActionState('editGuestInfo');
                  }}
                >
                  <EditIcon iconSize={14} />
                </IconWrapper>
              </TicketHeader>
              <TicketFields>
                <li>
                  {ticket.firstName || ticket.lastName
                    ? `${ticket.firstName} ${ticket.lastName}`.trim()
                    : intl.formatMessage({
                        id: 'admin.guestlist.attendeeActions.unknownName',
                      })}
                </li>
                <li>
                  {ticket.email
                    ? ticket.email
                    : intl.formatMessage({
                        id: 'admin.guestlist.attendeeActions.unknownEmail',
                      })}
                </li>
              </TicketFields>
            </div>
          ))}
      </ContentWrapper>
    );
  };

  const validateTicketValues = () => {
    if (Object.keys(ticketValues).length === 0) {
      return false;
    }
    if (ticketState.primary) {
      if (
        [
          ticketValues.email,
          ticketValues.firstName,
          ticketValues.lastName,
        ].some((val: string | undefined) => val === '')
      ) {
        return false;
      }
    }
    const emailSchema = Yup.string().email();
    if (
      ticketValues.email !== '' &&
      !emailSchema.isValidSync(ticketValues.email)
    ) {
      return false;
    }
    return true;
  };

  const handleTicketValueChange = (field: string) => {
    return (e: SyntheticEvent<HTMLInputElement>) => {
      if (
        (e.target as HTMLInputElement).value.trim() !== '' ||
        (e.target as HTMLInputElement).value === ''
      ) {
        setTicketValues({
          ...ticketValues,
          [field]: (e.target as HTMLInputElement).value,
        });
      }
    };
  };

  const EditGuestInfo = () => {
    return (
      <>
        <ContentWrapper>
          <EditTicketHeader>
            {ticketState &&
              ticketState.index !== undefined &&
              `${intl.formatMessage({
                id: 'admin.guestlist.attendeeActions.ticket',
              })} ${ticketState.index + 1}`}
          </EditTicketHeader>
          <Spacer mt={2} />
          <Overline>
            {intl.formatMessage({
              id: 'admin.guestlist.attendeeActions.firstName',
            })}
          </Overline>
          <Spacer mt={2} />
          <Textfield
            value={
              (ticketValues.firstName !== undefined
                ? ticketValues.firstName
                : ticketState.firstName) || ''
            }
            onChange={handleTicketValueChange('firstName')}
            maxLength={75}
          />
          <Spacer mt={2} />
          <Overline>
            {intl.formatMessage({
              id: 'admin.guestlist.attendeeActions.lastName',
            })}
          </Overline>
          <Spacer mt={2} />
          <Textfield
            value={
              (ticketValues.lastName !== undefined
                ? ticketValues.lastName
                : ticketState.lastName) || ''
            }
            onChange={handleTicketValueChange('lastName')}
            maxLength={75}
          />
          <Spacer mt={2} />
          <Overline>
            {intl.formatMessage({
              id: 'shared.email',
            })}
          </Overline>
          <Spacer mt={2} />
          <InputWrapper>
            <Textfield
              value={
                (ticketValues.email !== undefined
                  ? ticketValues.email
                  : ticketState.email) || ''
              }
              onChange={handleTicketValueChange('email')}
              maxLength={100}
            />
          </InputWrapper>
          <Spacer mt={2} />
          <EditTicketMessage>
            {intl.formatMessage({
              id: 'admin.guestlist.attendeeActions.updateTicketMessage',
            })}
          </EditTicketMessage>
        </ContentWrapper>
        <FlyoverFooter
          buttonText={intl.formatMessage({
            id: 'admin.guestlist.update',
          })}
          buttonDisabled={!validateTicketValues()}
          onClickButton={() => {
            setIsSubmitting(true);
            handleUpdateTickets();
          }}
          isSubmitting={isSubmitting}
        />
      </>
    );
  };

  const MainContents = () => {
    if (isSubmitting) {
      return <FlyoverLoadingIndicator />;
    } else {
      switch (actionState) {
        // See comment where we call MainContents regarding how we call the components below
        case 'selectAction':
          return SelectAction();
        case 'selectTransferEvent':
          return SelectTransferEvent();
        case 'confirmTransfer':
          return ConfirmTransfer();
        case 'selectRefundTickets':
          return SelectRefundTickets();
        case 'confirmRefund':
          return ConfirmRefund();
        case 'selectNumTickets':
          return SelectNumTickets();
        case 'confirmNumTickets':
          return ConfirmNumTickets();
        case 'selectGuestInfo':
          return SelectGuestInfo();
        case 'editGuestInfo':
          return EditGuestInfo();
        default:
          return SelectAction();
      }
    }
  };

  const previousActionState = () => {
    switch (actionState) {
      case 'selectAction':
        return 'selectAction';
      case 'selectTransferEvent':
        return 'selectAction';
      case 'confirmTransfer':
        return 'selectTransferEvent';
      case 'selectRefundTickets':
        return 'selectAction';
      case 'confirmRefund':
        return 'selectRefundTickets';
      case 'selectNumTickets':
        return 'selectAction';
      case 'confirmNumTickets':
        return 'selectNumTickets';
      case 'selectGuestInfo':
        return 'selectAction';
      case 'editGuestInfo':
        return 'selectGuestInfo';
      default:
        return 'selectAction';
    }
  };

  return (
    <div data-qaid="guestlist-attendee-actions">
      {actionState != 'selectAction' && (
        <FlyoverBackLink
          onClickButton={() => {
            setActionState(previousActionState());
          }}
        />
      )}
      <GuestlistAttendeeDetailsHeader
        eventAttendee={eventAttendee}
        currencySymbol={event && event.currencySymbol}
        displayExtraInfo={true}
        intl={intl}
      />
      {// We use function style - MainContents() - instead of component style - <MainContents />
      // because using component style breaks the refundAmount input field by removing focus
      // after every char is entered - using function style all the way through to the place
      // we render that input field fixes this, no one quite knows why
      MainContents()}
    </div>
  );
};

export default GuestlistAttendeeActions;
