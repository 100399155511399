import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { Navigate } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { useTShirtSizeBreakpoint as useBreakpoint } from 'app/shared/utils/useBreakpoint';
import { AuthContext } from 'app/shared/context/Auth';
import { Logo } from 'app/shared/components/atoms/LogoManualCSS';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import Hero from 'app/shared/components/molecules/Hero';
import SEO from 'app/shared/components/molecules/SEO';
import { getLogoWidth } from 'app/admin/utils/logos';
import Layout from 'app/admin/layouts/Admin';

const BodyContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: ${theme.colors.whiteDenim};
    margin-top: 60px;

    ${theme.media.xs`
        padding: 40px 0;
      `}
  `}
`;

const TextWrapper = styled.div`
  ${({ theme }) => css`
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    color: ${theme.colors.whiteDenim};
  `}
`;

const StyledLink = styled.a`
  ${({ theme }) => css`
    text-decoration: underline !important;
    text-decoration-color: ${theme.colors.whiteDenim} !important;
    display: in-line;
    color: ${theme.colors.whiteDenim};
  `}
`;

const getHeroBody = (isMobile: boolean, intl: any) => {
  if (isMobile) {
    return intl.formatMessage({ id: 'admin.mainPortal.mobileCopy' });
  } else {
    return intl.formatMessage({ id: 'admin.mainPortal.desktopCopy' });
  }
};

const AdminMainPortal: React.FC = () => {
  const intl = useIntl();

  const currentBreakpoint = useBreakpoint();
  const isMobile = currentBreakpoint === 'xs' || currentBreakpoint === 'sm';

  const { user } = useContext(AuthContext);

  if (user && !user.isInternal) {
    return <Navigate to="/dashboard?authorize_msg=1" />;
  }

  const imgMap = {
    xs:
      'https://images.prismic.io/sofarlive/c7d09d1c-8752-4aaa-acf3-a376645f7bf6_concert-portal.png?auto=compress,format',
    sm:
      'https://images.prismic.io/sofarlive/c7d09d1c-8752-4aaa-acf3-a376645f7bf6_concert-portal.png?auto=compress,format',
    md:
      'https://images.prismic.io/sofarlive/c7d09d1c-8752-4aaa-acf3-a376645f7bf6_concert-portal.png?auto=compress,format',
    lg:
      'https://images.prismic.io/sofarlive/c7d09d1c-8752-4aaa-acf3-a376645f7bf6_concert-portal.png?auto=compress,format',
    xl:
      'https://images.prismic.io/sofarlive/c7d09d1c-8752-4aaa-acf3-a376645f7bf6_concert-portal.png?auto=compress,format',
  };

  return (
    <>
      <Layout data-qaid="admin-main-portal" noNavbarMargin={true}>
        <SEO title="Admin Main Portal" />
        <Hero
          data-qaid="header-hero"
          jumbo={true}
          tint={true}
          img={imgMap}
          greyscale={true}
          align="center"
        >
          <Logo
            circle={true}
            invert
            width={getLogoWidth(isMobile, '60px', '70px')}
          />
          <Spacer mb={4} />
          <Hero.Title>
            <b>
              {intl.formatMessage({ id: 'admin.mainPortal.welcomeHeader' })}
            </b>
          </Hero.Title>
          <Hero.Body>{getHeroBody(isMobile, intl)}</Hero.Body>
          <BodyContainer>
            <TextWrapper>
              <span>
                {intl.formatMessage({ id: 'admin.mainPortal.backTo' })}{' '}
              </span>
              <StyledLink href="/">
                {intl.formatMessage({ id: 'admin.mainPortal.sofarHome' })}
              </StyledLink>
            </TextWrapper>
          </BodyContainer>
        </Hero>
      </Layout>
    </>
  );
};

export default AdminMainPortal;
