import styled, { css } from 'styled-components';

import { Overline } from 'app/shared/components/atoms/TypographyManualCSS';

export const SectionWithIcon = styled.div<{ inverted?: boolean }>`
  ${({ theme, inverted = false }) => css`
    background-color: ${inverted
      ? theme.colors.blueMagic
      : theme.colors.silverSprings};
    border-radius: 16px;
    color: ${inverted ? theme.colors.whiteDenim : undefined};

    ${theme.media.xs`
      font-size: 14px;
      padding: 16px;
    `}
    ${theme.media.md`
      max-width: 800px;
      font-size: 16px;
      padding: 30px;
    `}
  `}
`;

export const SectionTitle = styled.h3<{ inverted?: boolean }>`
  ${({ theme, inverted = false }) => css`
    align-items: center;
    color: ${inverted ? theme.colors.whiteDenim : undefined};
    column-gap: 8px;
    display: flex;
    flex-direction: row;
    font-weight: 600;
    margin: 0;
  `}
`;

export const SectionText = styled.div`
  ${({ theme }) => css`
    ${theme.media.xs`
      font-size: 14px;
    `}
    ${theme.media.md`
      font-size: 16px;
    `}
  `}
`;

export const Subheading = styled.span`
  font-weight: 600;
`;

export const Link = styled.a`
  ${({ theme }) => css`
    color: ${theme.colors.green600};
  `}
`;

export const InlineHtml = styled.div`
  display: inline;
`;

export const CircledOverline = styled(Overline)`
  ${({ theme }) => css`
    background-color: ${theme.colors.purplePills};
    border-radius: 10px;
    padding: 2px 10px;
  `}
`;
