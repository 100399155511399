import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Competition } from 'app/typings/competitions';
import { Col, Grid } from 'app/shared/components/atoms/GridManualCSS';
import IconAndTextLink from 'app/shared/components/molecules/IconAndTextLink';
import BooleanSetting from 'app/admin/components/atoms/BooleanSetting';
import {
  DetailHeading as Heading,
  DetailValueContainer as ValueContainer,
} from 'app/admin/components/atoms/DetailContent';
import { None } from 'app/admin/components/atoms/None';
import { ReactComponent as Camera1 } from 'icons/streamline-regular/images-photography/cameras/camera-1.svg';

interface Props {
  sectionData: Competition;
  callbacks: { [name: string]: Function };
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const SectionSharing: React.FC<Props> = ({ sectionData, callbacks }) => {
  const intl = useIntl();

  return (
    <MainContainer data-qaid="section-sharing">
      <Grid cols={4}>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={1}>
          <BooleanSetting
            value={sectionData.turnOnOptIn}
            headingText={intl.formatMessage({
              id: 'admin.competitionSharing.heading.dataSharingOptIn',
            })}
          />
          <Heading>
            {intl.formatMessage({
              id: 'admin.competitionSharing.heading.optInCopy',
            })}
          </Heading>
          <ValueContainer>{sectionData.optInCopy || <None />}</ValueContainer>
        </Col>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={2}>
          <Heading>
            {intl.formatMessage({
              id: 'admin.competitionSharing.heading.facebookShareTitle',
            })}
          </Heading>
          <ValueContainer>{sectionData.shareTitle || <None />}</ValueContainer>
          <Heading>
            {intl.formatMessage({
              id: 'admin.competitionSharing.heading.facebookShareCopy',
            })}
          </Heading>
          <ValueContainer>{sectionData.shareCopy || <None />}</ValueContainer>
          <IconAndTextLink
            icon={Camera1}
            text={intl.formatMessage({
              id: 'admin.competitionSharing.heading.manageFacebookShareImage',
            })}
            onClick={() => callbacks.toggleEditShareImageModal()}
          />
        </Col>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={3}></Col>
        <Col xs={12} sm={12} md={1} lg={1} xl={1} key={4}></Col>
      </Grid>
    </MainContainer>
  );
};

export default SectionSharing;
