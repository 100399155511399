import * as Yup from 'yup';

const countryBasicInfoFormSchema = (intl: any) =>
  Yup.object().shape({
    titleEn: Yup.string().required(
      intl.formatMessage({
        id: 'form.requiredField',
      })
    ),
    continentId: Yup.number()
      .typeError(
        intl.formatMessage({
          id: 'form.requiredField',
        })
      )
      .required(
        intl.formatMessage({
          id: 'form.requiredField',
        })
      ),
    countryCode: Yup.string()
      .when('isNewCountry', {
        is: true,
        then: Yup.string().required(
          intl.formatMessage({
            id: 'form.requiredField',
          })
        ),
      })
      .matches(/^[A-Z][A-Z]$/, {
        message: intl.formatMessage({
          id: 'admin.countryBasicInfo.form.countryCodeError',
        }),
      }),
    currency: Yup.string()
      .trim()
      .matches(/^[a-z][a-z][a-z]$/, {
        message: intl.formatMessage({
          id: 'admin.countryBasicInfo.form.currencyError',
        }),
      }),
    exchangeRateWithUsd: Yup.string()
      .trim()
      .matches(/^\d+\.\d+$/, {
        message: intl.formatMessage({
          id: 'admin.countryBasicInfo.form.exchangeRateWithUsdError',
        }),
      }),
  });

export default countryBasicInfoFormSchema;
